import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentHolidayWidgetItem } from '../../../../model/payment-holiday-widget/payment-holiday-widget';
import { ContentService } from '@backbase/universal-ang/content';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { OpenbudgetPlannerService } from '@wss/service/openbudgetplanner.service'; 
import { appConfig } from "../../../../config/wss-app-constants";
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'cohort-third',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './cohort-third.component.html',
    providers: [ ContentService ]
})

export class CohortThirdComponent implements OnInit {
    paymentHolidayWidgetItem$: Observable<PaymentHolidayWidgetItem | undefined> = this.bbContentService.getContent<PaymentHolidayWidgetItem>('paymentHolidayWidgetItem');
    portalName: string = '';

    constructor(
        private readonly bbContentService: ContentService,
        private openbudgetPlannerService: OpenbudgetPlannerService,
        @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
        private tealiumService: TealiumUtagService) { }

    ngOnInit() {
        this.portalName = this.pageConfig.portalName || 'blackhorse';
        this.tealiumService.view(
            {
                JourneyName: 'Payment holiday',
                JourneyStepName: 'Cohort Third',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
    }

    openBudgetTool(){
        this.openbudgetPlannerService.openBudgetPlanner();
    }
}