import { Injectable } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MessageService } from '../../../service/message.service';
import { DatastoreService } from '../../../service/datastore.service';

@Injectable({
  providedIn: 'root',
})
export class AccoutSummaryService {

  constructor(
    private httpService: HttpService,
    private messageService: MessageService,
    private datastoreService: DatastoreService) { }

  getAccountSummary(): Observable<any> {
    return this.httpService.getAccountDetails()
      .pipe(
        map((data) => { this.datastoreService.setAccountDetails(data) }),
        catchError(error => {
          this.messageService.setMessage(error.error);
          this.messageService.setServiceError(error);
          return error;
        })
      );
  }

  getMailPreference(): Observable<any> {
    return this.httpService.getMailPreference(null)
      .pipe(
        map((data) => {
          let acctNum = this.datastoreService.getAccountNo();
          let currentAcctObj = this.datastoreService.getAccountList().filter((account: any) => acctNum === account.account_number)[0];
          currentAcctObj = { ...currentAcctObj, ...data };
          this.datastoreService.setCurrentAccountMailPreference(currentAcctObj);
          return currentAcctObj;
        }),
        catchError(error => {
          this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
          return error;
        })
      );
  }

  getMailboxMsg(): Observable<any> {
    return this.httpService.getMailboxMsg()
      .pipe(
        map((data) => { this.datastoreService.setMailboxMsg(data) }),
        catchError(error => {
          this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
          return error;
        })
      );
  }

}