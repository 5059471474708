import { Component, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { BalloonRefinanceItem } from '@wss/model/balloon-refinance/balloon-refinance';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'bb-br-cta-eoc',
  templateUrl: './br-cta-eoc.component.html'
})
export class BrCtaEocComponent implements OnInit {
  brCtaDDComponent$: Observable<BalloonRefinanceItem | undefined> = this.bbContentService.getContent<BalloonRefinanceItem>('balloonRefinanceWidgetItem');
  accountDetails: any;

  constructor(
    private bbContentService: ContentService,
    private dataStoreService: DatastoreService,
    private tealiumService: TealiumUtagService
  ) { }

  ngOnInit(): void {
    this.accountDetails = this.dataStoreService.getAccountDetails();
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'CTA out of eoc period',
        CanonicalPath: window.location.pathname + window.location.hash
      }
      );
  }

}
