<div class="payment-holiday-container" *ngIf="paymentHolidayWidgetItem$ | async as paymentHolidayWidgetItem">
    <div class="container-column mt-30px term-ext-delined mb-90px">
        <div class="container-column">
            <span class="payment-failed-icon term-declied"></span>
        </div>
        <div class="container-column declined-top mt-13px" test_id="topSectionText">
            <span><bb-content-html [html]="paymentHolidayWidgetItem.termExtensionDeclined.topSectionText"></bb-content-html></span>
            <div class="brand-contact-details">
                <span><bb-content-html [html]="paymentHolidayWidgetItem.termExtensionDeclined.topSectionTextPartTwo"></bb-content-html></span>
                <span *ngIf="paymentHolidayWidgetItem.brandContactDetails"><bb-content-html [html]="paymentHolidayWidgetItem.brandContactDetails.brandWiseCfaNumber"></bb-content-html></span>
            </div>
            <span><bb-content-html [html]="paymentHolidayWidgetItem.termExtensionDeclined.topSectionTextPartThree"></bb-content-html></span>
        </div>
        <div class="white-container-rounded">
            <div class="container-column inner save-time-wrapper" test_id="budgetToolSectionText">
                <bb-content-html class="text-center" [html]="paymentHolidayWidgetItem.termExtensionDeclined.budgetToolSectionText"></bb-content-html>
                    <button role="button" tabindex="0" class="wss-button" (click)="openBudgetTool()" test_id="budgetToolButtonText">{{paymentHolidayWidgetItem.termExtensionDeclined.budgetToolButtonText}}</button>
            </div>
        </div>
        <div class="responsive-row-term mt-21px">
            <div class="container-column" test_id="ourDecisionSection">
                <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionDeclined.ourDecisionSection"></bb-content-html>
            </div>
            <div class="container-column" test_id="adviceInfoSection">
                <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionDeclined.adviceInfoSection"></bb-content-html>
            </div>
        </div>

    </div>
</div>

<!-- 
    <div class="payment-holiday-container">
    <div class="container-column mt-30px term-ext-delined mb-90px">
        <div class="container-column">
            <span class="term-declied"></span>
        </div>
        <span class="text-semibold text-center mt-13px">We're sorry. We're not able to offer you a Term
            Extension.</span>
        <span class="mt-28px">Our decision has been made using information obtained from TransUnion Credit Reference
            Agency.</span>
        <br>
        <span class="text-semibold text-bigger">We're here to help you</span>
        <span class="mt-22px">We're sorry to hear you're still having money worries. To give you the right support, we
            need to speak to you. Call us on <a title="0344 824 8888" tabindex="0">0344 824 8888</a>.</span>
        <br>
        <span>Make sure you have all details on your income and outgoings ready before you call. This may include wage
            slips, bills and bank statements.</span>
        <br>
        <div class="white-container-rounded">
            <div class="container-column inner save-time-wrapper">
                <span class="save-time text-semibold text-center">Save time on your call</span>
                <span class="text-center mt-17px">Before calling, we recommend you fill in our Budgeting Tool, which
                    will save you time when you speak to us.</span>
                <button role="button" tabindex="0" class="wss-button">Launch Budgeting Tool</button>
            </div>
        </div>
        <div class="responsive-row-term mt-21px">
            <div class="container-column">
                <span class="text-semibold text-bigger">How we made our decision</span>
                <span class="mt-19px">Our decision has been made using information obtained from TransUnion credit
                    reference agency.</span>
                <br>
                <span>If you wish to know more about the information that they hold about you, you can contact the
                    agency directly by telephone, email or through their website. Please note, that they may charge you
                    a fee for providing this information.</span>
                <br>
                <div class="container-column communication-wrapper">
                    <div class="container-row">
                        <span class="material-icons">phone</span>
                        <span><a title="0330 024 7574" tabindex="0">0330 024 7574</a></span>
                    </div>
                    <br>
                    <div class="container-row">
                        <span class="material-icons">alternate_email</span>
                        <span><a title="consumer@transunion.co.uk" tabindex="0">consumer@transunion.co.uk</a></span>
                    </div>
                    <br>
                    <div class="container-row">
                        <span class="material-icons">devices</span>
                        <span><a title="https://www.transunion.co.uk/consumer/consumer-enquiries"
                                tabindex="0">https://www.transunion.co.uk/consumer/consumer-enquiries</a></span>
                    </div>
                </div>
                <br>
                <span>Postal Address:</span>
                <span>One Park Lane</span>
                <span>Leeds</span>
                <span>West Yorkshire</span>
                <span>LS3 1EP</span>
            </div>
            <div class="container-column">
                <span class="text-semibold text-bigger mt-21px advice">Independent help and advice​</span>
                <br>
                <span class="text-semibold">Get impartial and free advice to help you deal with money worries.</span>
                <br>
                <ul class="print-doc-steps" role="list">
                    <li role="listitem">The Financial Conduct Authority website has advice to help people who have money worries because
                        of the Coronavirus pandemic. <a title="Visit the FCA website" tabindex="0" role="link">Visit the
                            FCA website</a></li>
                    <li role="listitem">The Money Advice Service website can provide information to help you decide the best way to take
                        things forward and keep your payments on track. It also suggests organisations that can help you
                        keep on top of your money. You can get help by telephone and online. <a tabindex="0"
                            title="Visit the Money Advice Service website" role="link">Visit the Money Advice Service
                            website</a></li>
                </ul>
            </div>
        </div>

    </div>
</div>
 -->