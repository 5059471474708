import { Component, Input } from '@angular/core';


@Component({
  selector: 'bb-wss-common-stepper',
  templateUrl: 'stepper.component.html'
})

export class StepperComponent {
  @Input() currentStep: number = 1;
  @Input() stepperLabel: any = {};
  @Input() totalSteps: number = 3;
  
  constructor() { }

}