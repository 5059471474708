import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { DatastoreService } from "@wss/service/datastore.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContentService } from '@backbase/universal-ang/content';
import { Observable, Subject } from "rxjs";
import { getPortalName } from '@wss/common/util/util';
import { ContactPreferenceWidgetItem } from "@wss/model/content/contact-preference";
import { Router } from "@angular/router";
import { finalize, takeUntil } from "rxjs/operators";
import { ContactPreferenceService } from "../../services/contact-preference.service";
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'bb-contact-preference-landing',
    templateUrl: 'contact-preference-landing.component.html',
    providers: [ContentService]
})

export class ContactPreferenceLandingComponent implements OnInit, OnDestroy {

    contactForm: FormGroup;
    isExpansionPanelOpen: boolean = false;
    portalName: any;
    submitted: boolean = false;
    isFormValid: boolean = false;
    serviceError: boolean = false;
    customerDetail: any;
    personalDetails: any = { fullname: "", shortName: "" };
    loginResponse: any = {};
    accountDetails: any;
    accountsList: any;
    isThirdPartyConsent: boolean = true;

    contactPreferenceWidgetItem$: Observable<ContactPreferenceWidgetItem | undefined> = this.bbContentService.getContent<ContactPreferenceWidgetItem>('contactPreferenceWidgetItem');
    contactPreferenceWidgetItem: any;
    readonly destroy$ = new Subject();

    constructor(
        private readonly bbContentService: ContentService,
        private router: Router,
        private formBuilder: FormBuilder,
        private dataStoreService: DatastoreService,
        private contactPreferenceService: ContactPreferenceService,
        private tealiumService: TealiumUtagService,
        public changeDetectorRef: ChangeDetectorRef) {
        this.contactForm = this.createContactForm();
        this.contentModification();
    }

    ngOnInit(): void {
        this.portalName = getPortalName();
        this.customerDetail = this.dataStoreService.getCustomerDetail();
        this.personalDetails.fullname = this.customerDetail?.individual?.first_name + " " + this.customerDetail?.individual?.surname;
        this.personalDetails.shortName = this.customerDetail?.individual?.first_name[0] + this.customerDetail?.individual?.surname[0];
        this.loginResponse = this.dataStoreService.getCustomerLogin();
        this.accountDetails = this.dataStoreService.getAccountDetails();
        this.accountsList = this.dataStoreService.getAccountList();
        this.isThirdPartyConsent = this.isThirdPartyConsentEnable();
        this.populateContactForm();
        this.tealiumService.view(
            {
                JourneyName: 'Contact Preference',
                JourneyStepName: 'Landing Page',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.controlExpansionPanel();
    }


    contentModification() {
        this.contactPreferenceWidgetItem$.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            this.contactPreferenceWidgetItem = response;
            this.contactPreferenceWidgetItem.contactPreferenceLanding.expansionPanelPara1 = this.replaceBrandPlaceholder(this.contactPreferenceWidgetItem.contactPreferenceLanding.expansionPanelPara1, this.portalName);
            this.contactPreferenceWidgetItem.contactPreferenceLanding.expansionPanelPara2 = this.replaceBrandPlaceholder(this.contactPreferenceWidgetItem.contactPreferenceLanding.expansionPanelPara2, this.portalName);
            this.changeDetectorRef.detectChanges();
        })
    }

    createContactForm(): FormGroup {
        return this.formBuilder.group({
            AccountNumber: ['', Validators.required],
            Channel: ['SYSTEM', Validators.required],
            UpdateMarketingEmail: ['', Validators.required],
            UpdateMarketingSMS: ['', Validators.required],
            UpdateMarketingPhone: ['', Validators.required],
            UpdateMarketingPost: ['', Validators.required],
            UpdatedBy: ['WSS', Validators.required],
            ClientKey: ['', Validators.required],
            UpdateOnlineAccount: ['', Validators.required],
            UpdateThirdPartyConsent: ['', Validators.required],
            UpdatePixelTracking: ['', Validators.required]
        });
    }

    get f() { return this.contactForm.controls; }

    populateContactForm() {
        let accNo = this.accountDetails['account_number'];
        let clientKey = this.accountDetails['chrCIS_Key'];
        this.contactPreferenceService.getContactPreference(accNo, clientKey).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            let formObj: any = {};
            for (const [key, value] of Object.entries(response[0])) {
                formObj[key] = value == null ? "" : value;
            }
            this.contactForm.patchValue(formObj);
        })
    }

    controlExpansionPanel(): void {
        this.isFormValid = Object.values(this.contactForm.value).includes(true) || Object.values(this.contactForm.value).includes(false);
        this.isExpansionPanelOpen = Object.values(this.contactForm.value).includes(true);
        this.contactForm.valueChanges.subscribe((value: any) => {
            this.isExpansionPanelOpen = Object.values(this.contactForm.value).includes(true);
            this.isFormValid = Object.values(this.contactForm.value).includes(true) || Object.values(this.contactForm.value).includes(false);
            this.changeDetectorRef.detectChanges();
        });
    }

    onSubmit() {
        this.submitted = true;
        let accountNumbers: string = "";
        this.accountsList.forEach((account: any, index: any) => {
            accountNumbers += this.accountsList.length - 1 == index ? account.account_number : account.account_number + ','
        })
        this.contactForm.patchValue({
            AccountNumber: accountNumbers.toString(),
            ClientKey: this.accountDetails['chrCIS_Key'],
            UpdatedBy: 'WSS'
        });
        let cdfmObj = { ...this.contactForm.value };
        delete cdfmObj["UpdatePixelTracking"];
        let isCdfmCaptured = Object.values(cdfmObj).includes(true) || Object.values(cdfmObj).includes(false);
        this.submitContactPreference(this.contactForm.value, isCdfmCaptured);
    }

    onSkip() {
        this.contactPreferenceService.openModalFromService(this.contactPreferenceWidgetItem?.continueOrExitPopUp);
    }

    submitContactPreference(reqObj: any, isCdfmCaptured: boolean) {
        this.contactPreferenceService.saveContactPreference(reqObj, false).pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (isCdfmCaptured) {
                this.updateUserDetails(true, this.loginResponse);
                this.dataStoreService.setContactPreferenceBannerShown(false);
                this.dataStoreService.setContactPreferencePopupShown(false);
            } else {
                this.dataStoreService.setContactPreferencePopupShown(false);
                this.router.navigate(['/contact-preference/success']);
            }
        },
            (error: any) => {
                this.showError();
            });
    }

    updateUserDetails(marketingFlag: boolean, loginResponse: any) {
        loginResponse["date_Marketing"] = new Date().toISOString();
        loginResponse["marketing_Flag"] = marketingFlag;
        this.contactPreferenceService.UpdateUserDetails(loginResponse).pipe(takeUntil(this.destroy$),
            finalize(() => {
                this.router.navigate(['/contact-preference/success']);
            })
        ).subscribe();
    }

    isThirdPartyConsentEnable() {
        if (['internationalmotors', 'jaguar', 'landrover'].includes(this.portalName)) {
            return false;
        } else {
            return true
        }
    }

    showError(): void {
        this.dataStoreService.setErrorServer();
        this.serviceError = true;
        window.scroll(0, 0)
        this.changeDetectorRef.detectChanges();
    }

    replaceBrandPlaceholder(content: any, brandName: any) {
        return content.replace(/[\[\]']+/g, '').replace(/brand name/gi, brandName);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
