import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { BalloonRefinanceService } from '@wss/balloon-refinance-widget/src/services/balloon-refinance.service';
import { BalloonRefinanceGetdetailsItem, ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bb-brg-post-decline',
  templateUrl: './brg-post-decline.component.html'
})
export class BrgPostDeclineComponent implements OnInit {

  brgItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');
  proposalDetails: ProposalRequest;
  readonly destroy$ = new Subject();
  vehicleType: string = '';

  constructor(
    private readonly bbContentService: ContentService,
    private datastore: DatastoreService,
    private brService: BalloonRefinanceService,
    private router: Router,
    private tealiumService: TealiumUtagService
  ) { 
    this.proposalDetails = this.datastore.getProposalRequest();
  }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'Proposal - Post decline',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.vehicleType = this.datastore.getCheckVehicleType();
    this.datastore.setBrHideStepper(true);
  }

  getProposalData() {    
    this.brService.getProposalDetails().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.proposalDetails = this.datastore.getProposalRequest();
      this.getDecision();
    }, 
    (error: any) => {
    });
  }

  getDecision() {
    this.brService.getDecision(this.proposalDetails?.proposal_id, this.proposalDetails?.palisCustomerId, this.vehicleType)
    .pipe(takeUntil(this.destroy$))
    .subscribe((res: any) => {
      this.router.navigate(['/balloon-refinance-getdetails/decline']);
    })
  }

}
