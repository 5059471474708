import {Component, OnInit, OnDestroy, HostListener} from "@angular/core";
import {ItemModel, RouterService} from "@backbase/foundation-ang/core";
import {RegistrationStoreModelService} from "../../../../registration-store/src/registration-store-model.service";
import {Observable, Subject} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContentService as OldContentService} from "../../../../service/content.service";
import {ContentData} from '../../../../config/wss-app-constants';
import {RegisterDatastoreService} from '../../service/register-datastore.service';
import {RegisterService} from '../../service/register.service';
import {RequestService} from '../../../../service/request.service';
import {takeUntil} from 'rxjs/operators';
import { RegistrationWidgetItem } from "@wss/model/registrationToken";
import { ContentService } from "@backbase/universal-ang/content";
import { TealiumUtagService } from '@wss/service/utag.service';
import { autoTabPress } from "@wss/common/util/util";


@Component({
  templateUrl: './otp-sms.component.html',
  selector: 'bb-ws-user-verify-otp',
  providers: [ContentService]
})
export class OtpSmsComponent implements OnInit, OnDestroy {
  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    if (e.srcElement.maxLength === e.srcElement.value.length) {
      e.preventDefault();
      autoTabPress();
    }
  }
  
  otpData: Observable<number | undefined> = this.registrationService.otpObservable;
  otp: number | undefined;
  otpInput: FormGroup;
  disabled = true;
  otp1 = false;
  otp2 = false;
  otp3 = false;
  otp4 = false;

  readonly destroy$ = new Subject();

  registrationWidgetItem$: Observable<RegistrationWidgetItem | undefined> = this.bbContentService.getContent<RegistrationWidgetItem>('registrationWidgetItem');

  ngOnInit(): void {
    this.tealiumService.view({'page_type': 'registration_verify_mobile'});
    this.otpData.subscribe(value => {
      this.otp = value;
    })
    this.handleOtpInput();
    this.tealiumService.view(
      {
        JourneyName: 'Registration',
        JourneyStepName: 'Verify OTP',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();  
    this.destroy$.complete();    
  }

  constructor(
    private itemModel: ItemModel,
    private registrationService: RegistrationStoreModelService,
    private router: RouterService, 
    private contentService: OldContentService, 
    private registerstoreService: RegisterDatastoreService,
    private registerService: RegisterService,
    private requestService: RequestService,
    formBuilder: FormBuilder,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
  ) {
    this.itemModel.property('userDetailsTitle').pipe(takeUntil(this.destroy$)).subscribe(value => this.registrationService.updateTitle(value as string));
    this.registrationService.updatedStepper(2);
    this.otpInput = formBuilder.group({
      otp1: ['', Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(9),
      ])],
      otp2: ['', Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(9),
      ])],
      otp3: ['', Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(9),
      ])],
      otp4: ['', Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(9),
      ])],
    });    
  }

  checkIfAllNumbersEnterd() {
    if(this.otp1 && this.otp2 && this.otp3 && this.otp4){
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  handleOtpInput() {
    const otp1 = this.otpInput.get('otp1');
    otp1?.valueChanges.forEach(
      (value: number) => {
        if(value < 10 && value != null){
          this.otp1 = true;
        } else {
          this.otp1 = false;
        }
        this.checkIfAllNumbersEnterd();
      }
    );
    const otp2 = this.otpInput.get('otp2');
    otp2?.valueChanges.forEach(
      (value: number) => {
        if(value < 10 && value != null){
          this.otp2 = true;
        } else {
          this.otp2 = false;
        }
        this.checkIfAllNumbersEnterd();
      }
    );

    const otp3 = this.otpInput.get('otp3');
    otp3?.valueChanges.forEach(
      (value: number) => {
        if(value < 10 && value != null){
          this.otp3 = true;
        } else {
          this.otp3 = false;
        }
        this.checkIfAllNumbersEnterd();
      }
    );

    const otp4 = this.otpInput.get('otp4');
    otp4?.valueChanges.forEach(
      (value: number) => {
        if(value < 10 && value != null){

          this.otp4 = true;
        } else {
          this.otp4 = false;
        }
        this.checkIfAllNumbersEnterd();
      }
    );
  }

  checkLength(value:any) : boolean{
    if(value.length === 1) {
      return false;
    } else {
      return true;
    }    
  }

  submit() {
    let otp = ''+ this.otpInput.get('otp1')?.value + 
                  this.otpInput.get('otp2')?.value + 
                  this.otpInput.get('otp3')?.value + 
                  this.otpInput.get('otp4')?.value;
    this.requestService.setReqVerifyOTP(otp);    
    this.registerService.getCheckOTP().pipe(takeUntil(this.destroy$)).subscribe(() => {     
      this.router.navigate(['enter-email']);
    },      
    (error:any) => {
      this.router.navigate(['passcode-error']);
    });    
  }
}
