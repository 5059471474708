import { Component, OnInit } from '@angular/core';
import { RoutableWidget } from "@backbase/foundation-ang/core";
import { PreferenceLandingComponent } from './components/preference-landing/preference-landing.component';
import { AccountSelectionComponent } from './components/account-selection/account-selection.component';
import { PreferenceResultComponent } from './components/preference-result/preference-result.component';
import { ManagePreferenceComponent } from './components/manage-preference/manage-preference.component';
import { ConfimrPreferenceComponent } from './components/confirm-preference/confirm-preference.component';
import { DatastoreService } from '../../service/datastore.service';

@Component({
  selector: 'bb-digital-inbox-widget',
  templateUrl: './digital-inbox-widget.component.html'
})

@RoutableWidget({
  routes: [
    { path: '', redirectTo: 'landing', pathMatch: 'full' },
    { path: 'landing', component: PreferenceLandingComponent },
    { path: 'account-selection', component: AccountSelectionComponent },
    { path: 'preference-result', component: PreferenceResultComponent },
    { path: 'manage-preference', component: ManagePreferenceComponent },
    { path: 'confirm-preference', component: ConfimrPreferenceComponent }
  ]
})
export class DigitalInboxWidgetComponent implements OnInit {

  constructor(private datastoreService: DatastoreService) { 
  }
  ngOnInit(): void {
    this.datastoreService.sessionInvalid();
  }

}
