import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';
import { ComplaintWidgetItem } from '@wss/model/complaint';
import { WSSAppConstant } from '@wss/config/wss-app-constants';
import { RouterService } from '@backbase/foundation-ang/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { MessageService } from '@wss/service/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanComponentDeactivate } from '@wss/complaints-widget/guard/deactivation.guard';
import { checkFormIsEmpty, getComplaintsCanDeactivate } from '@wss/common/util/util';
import { DatastoreService } from '@wss/service/datastore.service';
import { PAGE_CONFIG, PageConfig } from '@backbase/foundation-ang/web-sdk';

@Component({
  selector: 'bb-complaint-get-started',
  templateUrl: 'complaint-get-started.component.html',
  providers: [ContentService],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class ComplaintGetStartedComponent implements OnInit, CanComponentDeactivate {
  formId: string = "concernSelectionForm";
  submitted: boolean = false;
  concernSelectionForm: any;

  svgInfoIcon = WSSAppConstant.SVG_INFO_ICON;
  changeJourney: boolean = false;
  modalRef: any;
  @ViewChild("content") modalContentRef: any;
  brandsForAdditionalInfoMotorCommission: any;
  showAdditionalInfo: boolean = false;
  portalName: string = this.pageConfig.portalName || 'blackhorse';

  constructor(
    private fb: FormBuilder,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    private router: RouterService,
    private formDataService: DataService,
    private messageService: MessageService,
    public modalService: NgbModal,
    private datastoreService: DatastoreService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig
  ) {
  }

  complaintWidgetItem$: Observable<ComplaintWidgetItem | undefined> = this.bbContentService.getContent<ComplaintWidgetItem>('complaintWidgetItem');
  complaintWidgetItem = new ComplaintWidgetItem();

  @HostListener("window:beforeunload")
  canDeactivate() {
    return getComplaintsCanDeactivate(this.formDataService.formData, this.modalService);
  }

  ngOnInit(): void {
    this.initConcernSelectionForm();
    this.tealiumService.view({
      JourneyName: 'Complaints Digitisation',
      JourneyStepName: 'Get started page',
      CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    });
    const savedData = this.formDataService.getFormData(this.formId);
    if (Object.keys(savedData).length !== 0) {
      this.concernSelectionForm.setValue(savedData);
    }
    this.messageService.setCurrentStep(0);

    this.complaintWidgetItem$.subscribe((pageItem: any) => {
      this.complaintWidgetItem = pageItem;
      this.brandsForAdditionalInfoMotorCommission = this.complaintWidgetItem.complaintGetStarted.brandsForAdditionalInfoMotorCommission?.split(', ');
      if (this.brandsForAdditionalInfoMotorCommission?.indexOf(this.portalName) === -1) {
        this.showAdditionalInfo = false;
      } else {
        this.showAdditionalInfo = true;
      }
      this.datastoreService.setComplaintsGetStartedContent(this.complaintWidgetItem.complaintGetStarted);
      this.changeDetectorRef.detectChanges();
    });
  }

  initConcernSelectionForm() {
    this.concernSelectionForm = this.fb.group({
      concernType: ["", [Validators.required]]
    })
  }

  handleCancelBtn() {
    this.router.navigate(['/help-centre/get-in-touch']);
  }

  handleNextBtn() {
    this.submitted = true;
    if (this.concernSelectionForm.valid) {
      this.formDataService.saveFormData(this.formId, this.concernSelectionForm.value);
      this.router.navigate(['relate-to']);
    }
  }

  handleChangeEventRadioBtn() {
    if (Object.keys(this.formDataService.formData).length > 1
      && !checkFormIsEmpty(this.formDataService.formData.complaintRelateToSelectionForm)) {
      this.modalRef = this.modalService.open(this.modalContentRef,
        {
          backdrop: "static",
          centered: true
        });
    }
  }

  closeJourneyChangePopup() {
    this.formDataService.deleteAllFormData();
  }

  stayBack() {
    if (this.concernSelectionForm.get('concernType').value == 'Vehicle') {
      this.concernSelectionForm.get('concernType').setValue('FinanceAgreement');
    } else {
      this.concernSelectionForm.get('concernType').setValue('Vehicle');
    }
    this.concernSelectionForm.get('concernType').updateValueAndValidity();
  }
}
