import { Component, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { BalloonRefinanceItem } from '@wss/model/balloon-refinance/balloon-refinance';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-br-cta-dd-calling',
  templateUrl: './br-cta-dd-calling.component.html'
})
export class BrCtaDdCallingComponent implements OnInit {
  brCtaDDComponent$: Observable<BalloonRefinanceItem | undefined> = this.bbContentService.getContent<BalloonRefinanceItem>('balloonRefinanceWidgetItem');
  constructor(
    private bbContentService: ContentService,
    private tealiumService: TealiumUtagService
  ) { }

  ngOnInit(): void {
    this.tealiumService.view({ tealium_event:'Balloon_Refinance_CTA_DD_Calling', URI: '/dashboard#/balloon-refinance/cta-dd-calling' });
  }

}
