
import { PortalContentMock } from "@backbase/foundation-ang/web-sdk";
import { BrandContactDetailsItem } from "./common/brand-contant-details";

export class StaticPagesWidgetItem {
    // cookiesPolicy: CookiesPolicyItem = new CookiesPolicyItem();
    brandContactDetails: BrandContactDetailsItem = new BrandContactDetailsItem();
}

export class CookiePolicyItem {
    cookiesPolicyIntro: string = '';
    cookiePolicy: CookiePolicyContentItem = new CookiePolicyContentItem();
}

export class CookiePolicyContentItem {
    pageTitle: string = '';
    strictlyNecessaryCategory: string = '';
    functionalCategory: string = '';
    performanceCategory: string = '';
    marketingCategory: string = '';
    performanceAndMarketingCookies: string = '';
    backToPageText: string = '';
}

export class PrivacyPolicyItem {
    pageTitle: string = '';
    backToPageText: string = '';
    privacyPolicyIntro: string = '';
    personalInfoAndLaw: string = '';
    whoWeAre: string = '';
    howLawProtectsYou: string = '';
    servingYouAsCustomerTitle: string = '';
    servingYouAsCustomerContent: string = '';
    businessImprovementTitle: string = '';
    businessImprovementContent: string = '';
    managingOperationsTitle: string = '';
    managingOperationsContent: string = '';
    managingSecurityRiskCrimeTitle: string = '';
    managingSecurityRiskCrimeContent: string = '';
    businessManagementTitle: string = '';
    businessManagementContent: string = '';
    processingSpecialCategoriesTitle: string = '';
    processingSpecialCategoriesContent: string = '';
    groupsPersonalInformation: string = '';
    consentPersonalInformation: string = '';
    whatTheyDo: string = '';
    contactPersonalInformation: string = '';
    socioDemographicPersonalInformation: string = '';
    transactionalPersonalInformation: string = '';
    contractualPersonalInformation: string = '';
    locationalPersonalInformation: string = '';
    behaviouralPersonalInformation: string = '';
    communicationsPersonalInformation: string = '';
    technicalPersonalInformation: string = '';
    socialRelationshipsPersonalInformation: string = '';
    openDataPublicRecordsPersonalInformation: string = '';
    usageDataPersonalInformation: string = '';
    documentaryDataPersonalInformation: string = '';
    specialCategoryDataPersonalInformation: string = '';
    consentsPersonalInformation: string = '';
    nationalIdentifierPersonalInformation: string = '';
    whereWeCollectPersonalInformation: string = '';
    howLongWeKeepYourInformation: string = '';
    chooseNotToGivePersonalInformation: string = '';
    cookiesAndSimilarTrackingTechnologies: string = '';
    howToComplain: string = '';
    howToWithdrawConsent: string = '';
    lettingKnowIfPersonalInformationIsIncorrect: string = '';
    getCopyOfPersonalInformation: string = '';
    wantToStopUsingPersonalInformation: string = '';
    whoWeShareYourPersonalInformation: string = '';
    workoutWhatMarketingYouReceive: string = '';
    yourInformationToMakeAutomatedDecisions: string = '';
    creditReferenceAgencies: string = '';
    fraudPreventionAgencies: string = '';
    sendingDataOutsideUK: string = '';
    backToTopText: string = '';
}

export class TermsConditionsItem {
    termsAndConditionsIntro: string = '';
    termsAndConditions: TermsConditionsContentItem = new TermsConditionsContentItem();
}

export class TermsConditionsContentItem {
    pageTitle: string = '';
    backToPageText: string = '';
    termsAndConditionsIntro: string = '';
    termsAndConditionsContent: string = '';
}