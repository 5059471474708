<div class="container-column enter-billing-address" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
    <div class="container-column">
        <div class="container-row section-title-container">
            <span class="section-title" test_id="directDebitPageName">
                {{paymentsWidgetItem.enterBillingAddress.pageTitle}}
            </span>
        </div>
    </div>

    <div class="para-inline" test_id="headerText">
        <bb-content-html [html]="paymentsWidgetItem.enterBillingAddress.headerText">
        </bb-content-html>
    </div>
    <div class="para-inline" test_id="billingAddress">
        <form [formGroup]="enterBillingAddressForm">
            <div class="first-name mt-10px">
                <label class="subgroup mb-5px" test_id="firstName" test_id="firstName">
                    {{paymentsWidgetItem.enterBillingAddress.firstName}}
                </label>
                <input type="text" class="form-control" formControlName="firstName" placeholder="" [maxLength]="50" />
                <div role="alert" class="invalid-feedback" [ngClass]="{'show-error': !validFirstName}"
                    test_id="invalidEmailError">
                    <bb-content-html [html]="paymentsWidgetItem.enterBillingAddress.requiredFieldError">
                    </bb-content-html>
                </div>
            </div>
            <div class="last-name mt-12px">
                <label class="subgroup mb-5px" test_id="lastName" test_id="lastName">
                    {{paymentsWidgetItem.enterBillingAddress.lastName}}
                </label>
                <input type="text" class="form-control" formControlName="lastName" placeholder="" [maxLength]="50" />
                <div role="alert" class="invalid-feedback" [ngClass]="{'show-error': !validLastName}"
                    test_id="invalidEmailError">
                    <bb-content-html [html]="paymentsWidgetItem.enterBillingAddress.requiredFieldError">
                    </bb-content-html>
                </div>
            </div>
            <div class="first-line-address mt-12px">
                <label class="subgroup mb-5px" test_id="firstLineOfAddress" test_id="firstLineOfAddress">
                    {{paymentsWidgetItem.enterBillingAddress.firstLineOfAddress}}
                </label>
                <input type="text" class="form-control" formControlName="firstLineOfAddress" placeholder="" [maxLength]="50" />
                <div role="alert" class="invalid-feedback" [ngClass]="{'show-error': !validFirstLineOfAddress}"
                    test_id="invalidEmailError">
                    <bb-content-html [html]="paymentsWidgetItem.enterBillingAddress.requiredFieldError">
                    </bb-content-html>
                </div>
            </div>
            <div class="second-line-address mt-12px">
                <label class="subgroup mb-5px" test_id="secondLineOfAddress" test_id="secondLineOfAddress">
                    {{paymentsWidgetItem.enterBillingAddress.secondLineOfAddress}}
                </label>
                <input type="text" class="form-control" formControlName="secondLineOfAddress" placeholder="" [maxLength]="50" />
            </div>
            <div class="town-city mt-12px">
                <label class="subgroup mb-5px" test_id="townOrCity" test_id="townOrCity">
                    {{paymentsWidgetItem.enterBillingAddress.townOrCity}}
                </label>
                <input type="text" class="form-control" formControlName="townOrCity" placeholder="" [maxLength]="50"/>
                <div role="alert" class="invalid-feedback" [ngClass]="{'show-error': !validTownOrCity}"
                    test_id="invalidEmailError">
                    <bb-content-html [html]="paymentsWidgetItem.enterBillingAddress.requiredFieldError">
                    </bb-content-html>
                </div>
            </div>
            <div class="post-code mt-12px">
                <label class="subgroup mb-5px" test_id="postCode" test_id="postCode">
                    {{paymentsWidgetItem.enterBillingAddress.postCode}}
                </label>
                <input type="text" class="form-control" id="postcode" name="postcode" formControlName="postCode"
                    aria-describedby="postcode" accesskey="m">
                <div role="alert" class="invalid-feedback" [ngClass]="{'show-error': !validPostcode}"
                    test_id="invalidEmailError">
                    <bb-content-html [html]="paymentsWidgetItem.enterBillingAddress.requiredFieldError">
                    </bb-content-html>
                </div>
            </div>
        </form>
    </div>
    <div class="container-row back-btn mt-10px">
        <button role="button" tabindex="0" class="wss-button mb-15px" (click)="submitForm()" id="next-btn"
            title="{{paymentsWidgetItem.enterBillingAddress.nextButtonText}}" [disabled]="enterBillingAddressForm.invalid">
            {{paymentsWidgetItem.enterBillingAddress.nextButtonText}}
        </button>
    </div>
    <div class="mobile-center mb-20px">
        <bb-content-html [html]="paymentsWidgetItem.enterBillingAddress.backLink"></bb-content-html>
    </div>
</div>