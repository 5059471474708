<section class="custom-form budget-page content-margin" *ngIf="budgetFormItem$ | async as budgetFormItem">
    <form [formGroup]="incomeForm" formInvlidControlScroll>
        <div class="content-wrapper-container">
          <div class="container-row" *ngIf="serviceError">
            <bb-wss-banner-message></bb-wss-banner-message>
          </div>
            <div class="container-column mx-3">
                <div class="d-flex section-title-container">
                    <span class="section-title" test_id="incomeDetailpageName">
                        {{budgetFormItem.incomeDetail.pageName}}</span>
                </div>
                <div class="customer-support-text mt-4">
                    <div>
                        <div class="bullet-point" test_id="incomeDetailBulletListTextBelow">
                            <bb-content-html [html]="budgetFormItem.incomeDetail.incomeBulletList"></bb-content-html>
                        </div>
                        <!---pound panel section start----->
                        <div class="round-panel">

                          <div class="form-group">
                            <div class="form-group-heading" test_id="incomeDetailincomeFromWork">
                              {{budgetFormItem.incomeDetail.incomeFromWork}}
                            </div>

                            <div class="col-12">
                              <div class="radio mb-0">
                                  <label class="form-check-label">
                                      <input type="radio" class="form-check-input"
                                           formControlName="isIncomeWork" value="yes"><span
                                          class="label" test_id="incomeDetailincomeFromWorkRadioYes">Yes</span>
                                  </label>
                              </div>
                              <div class="radio">
                                  <label class="form-check-label">
                                      <input type="radio" class="form-check-input"
                                           formControlName="isIncomeWork" value="no"><span
                                          class="label" test_id="incomeDetailincomeFromWorkRadioNo">No</span>
                                  </label>
                              </div>
                            </div>

                          <div *ngIf="submitted && work_support_info.invalid &&
                            work_support_info.errors">
                                <small class="text-danger"
                                    *ngIf="work_support_info.hasError('required')"
                                    test_id="incomeDetailincomeFromWorkError">
                                    Please select an answer.
                                </small>
                          </div>

                          <div  *ngIf="work_support_info?.value == 'yes'" formGroupName="incomeWorkAdditionalInfo">
                            <div>
                                <div class="amount-group">
                                    <label class="subgroup" test_id="incomeDetailLabelContent">
                                        {{budgetFormItem.incomeDetail.amountTakeHomePay}}
                                    </label>
                                    <div class="position-relative">
                                        <span class="currency">£</span>
                                        <input type="text"
                                            class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                            formControlName="amount"
                                            fiveDigitValidation="incomeWorkAdditionalInfo"
                                            test_id="incomeDetailamountTakeHomePayText"
                                            onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                            [ngClass]="{'is-invalid':submitted && work_amount.errors }"
                                            >
                                        <div *ngIf="submitted && work_amount.invalid &&
                                        work_amount.errors && (work_amount.errors.required || work_amount.errors.pattern)">
                                            <small class="text-danger" test_id="incomeDetailamountTakeHomePayError">
                                                Please enter an amount e.g. £123 or £123.23.​
                                            </small>
                                        </div>
                                        <div *ngIf="work_amount.errors && !work_amount.errors.pattern
                                        && work_amount.errors.fiveDigitError">
                                            <small class="text-danger" test_id="incomeDetailamountTakeHomePayFiveDigitError">
                                                {{budgetFormItem.incomeDetail.fiveDigitError}}​​
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label class="subgroup">
                                        <!---How Often-->
                                        {{budgetFormItem.incomeDetail.howOften}}
                                    </label>
                                    <div class="select-wrapper"> 
                                        <select class="form-control"
                                            test_id="incomeDetailHowOftenSelect"
                                            fiveDigitValidation="incomeWorkAdditionalInfo"
                                            formControlName="frequency"
                                            [ngClass]="{'is-invalid':submitted &&  work_frequency.errors }">
                                            <option selected [ngValue]="null">Please select</option>
                                            <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="submitted && work_frequency.invalid &&
                                    work_frequency.errors">
                                        <small class="text-danger"
                                            *ngIf="work_frequency.hasError('required')"
                                            test_id="incomeDetailHowOftenError">

                                            Please select an answer from the list
                                        </small>
                                    </div>
                                </div>
                            </div>
                          </div>
                          </div>

                          <div class="form-group separator">
                            <div class="form-group-heading" test_id="incomeDetailincomeFromPensionText">
                              {{budgetFormItem.incomeDetail.incomeFromPension}}
                            </div>
                            <div class="col-12">
                              <div class="radio mb-0">
                                  <label class="form-check-label">
                                      <input type="radio" class="form-check-input"
                                          formControlName="isIncomePension"
                                           value="yes"
                                          test_id="incomeDetailincomeFromPensionRadioYes"><span
                                          class="label">Yes</span>
                                  </label>
                              </div>
                              <div class="radio">
                                  <label class="form-check-label">
                                      <input type="radio" class="form-check-input"
                                          formControlName="isIncomePension"
                                           value="no"
                                          test_id="incomeDetailincomeFromPensionRadioNo"><span
                                          class="label">No</span>
                                  </label>
                              </div>
                          </div>
                          <div *ngIf="submitted && pension_support_info.invalid &&
                          pension_support_info.errors">
                              <small class="text-danger"
                                  *ngIf="pension_support_info.hasError('required')"
                                  test_id="incomeDetailincomeFromPensionErrorText">
                                  Please select an answer.
                              </small>
                          </div>
                          <div  *ngIf="pension_support_info.value=='yes'" formGroupName="incomePensionAdditionalInfo">
                                <div>
                                    <div class="amount-group">
                                        <label class="subgroup" test_id="incomeDetailLabelContent"
                                            test_id="incomeDetailincomeFromPensionenterAmount">
                                            {{budgetFormItem.incomeDetail.enterAmount}}
                                        </label>
                                        <div class="position-relative">
                                            <span class="currency">£</span>
                                            <input type="text" formControlName="amount"
                                                class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                                fiveDigitValidation="incomePensionAdditionalInfo"
                                                test_id="incomeDetailincomeFromPensionInput"
                                                onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                                [ngClass]="{'is-invalid':submitted && pension_amount.errors }"

                                              >
                                            <div *ngIf="submitted && pension_amount.invalid &&
                                            pension_amount.errors && (pension_amount.errors.required || pension_amount.errors.pattern)"
                                                test_id="incomeDetailincomeFromPensionErrorMsg">
                                                <small class="text-danger">
                                                    Please enter an amount e.g. £123 or £123.23.​
                                                </small>
                                            </div>
                                            <div *ngIf="pension_amount.errors && !pension_amount.errors.pattern &&
                                            pension_amount.errors.fiveDigitError">
                                                <small class="text-danger" test_id="incomeDetailincomeFromPensionFiveDigitError">
                                                    {{budgetFormItem.incomeDetail.fiveDigitError}}​​
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label class="subgroup">
                                            {{budgetFormItem.incomeDetail.howOften}}
                                        </label>
                                        <div class="select-wrapper">
                                            <select class="form-control"
                                                test_id="incomeDetailincomeFromPensionhowOften"
                                                fiveDigitValidation="incomePensionAdditionalInfo"
                                                formControlName="frequency"
                                                [ngClass]="{'is-invalid':submitted && pension_frequency.errors }"
                                                >
                                                <option selected [value]="null">Please select</option>
                                                <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                            </select>
                                        </div>
                                        <div *ngIf="submitted && pension_frequency.invalid &&
                                        pension_frequency.errors">
                                            <small class="text-danger"
                                                *ngIf="pension_frequency.hasError('required')"
                                                test_id="incomeDetailincomeFromPensionerror">
                                                Please select an answer from the list
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                            <!---income from pension section end-->
                            <!---income from benifit section start-->


                            <div class="form-group separator">

                              <div class="form-group-heading" test_id="incomeDetailincomeFromBenefit">
                                {{budgetFormItem.incomeDetail.incomeFromBenefit}}
                            </div>
                            <div class="col-12">
                                <div class="radio mb-0">
                                    <label class="form-check-label">
                                        <input type="radio"
                                            class="form-check-input" name="isIncomeBenefits"
                                            formControlName="isIncomeBenefits" value="yes"
                                            test_id="incomeDetailincomeFromBenefitRadioYes"><span
                                            class="label">Yes</span>
                                    </label>
                                </div>
                                <div class="radio">
                                    <label class="form-check-label">
                                        <input type="radio"
                                            class="form-check-input" name="isIncomeBenefits"
                                            formControlName="isIncomeBenefits" value="no"
                                            test_id="incomeDetailincomeFromBenefitRadioNo"><span
                                            class="label">No</span>
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="submitted && benifit_support_info.invalid &&
                            benifit_support_info.errors">
                                <small class="text-danger"
                                    *ngIf="benifit_support_info.hasError('required')"
                                    test_id="incomeDetailincomeFromBenefitError">
                                    Please select an answer.
                                </small>
                            </div>

                            <div *ngIf="benifit_support_info.value=='yes'" formGroupName="incomeBenefitsAdditionalInfo">

                                <div
                                    class="form-group inner-icon right-icon login-input-field">
                                    <div class="amount-group">
                                        <label class="subgroup" test_id="incomeDetailLabelContent"
                                            test_id="incomeDetailincomeFromBenefitAmountText">
                                            {{budgetFormItem.incomeDetail.enterAmount}}
                                        </label>
                                        <div class="position-relative">
                                            <span class="currency">£</span>
                                            <input type="text" formControlName="amount"
                                                class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                                fiveDigitValidation="incomeBenefitsAdditionalInfo"
                                                test_id="incomeDetailincomeFromBenefitInput"
                                                onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                                [ngClass]="{'is-invalid':submitted && benifit_amount.errors  }"

                                              >
                                            <div *ngIf="submitted && benifit_amount.invalid &&
                                            benifit_amount.errors && (benifit_amount.errors.required || benifit_amount.errors.pattern)">
                                                <small class="text-danger" test_id="incomeDetailincomeFromBenefitError">
                                                    Please enter an amount e.g. £123 or £123.23.​
                                                </small>
                                            </div>
                                            <div *ngIf="benifit_amount.errors && !benifit_amount.errors.pattern
                                            && benifit_amount.errors.fiveDigitError">
                                                <small class="text-danger" test_id="incomeDetailincomeFromBenefitFiveDigitError">
                                                    {{budgetFormItem.incomeDetail.fiveDigitError}}​​
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div><label class="subgroup">{{budgetFormItem.incomeDetail.howOften}}</label></div>
                                    <div class="select-wrapper">
                                        <select class="form-control"
                                            test_id="incomeDetailFormControlSelect1"
                                            fiveDigitValidation="incomeBenefitsAdditionalInfo"
                                            formControlName="frequency"
                                            [ngClass]="{'is-invalid':submitted && benifit_frequency.errors  }"
                                            >
                                            <option selected [ngValue]="null">Please select</option>
                                            <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="submitted && benifit_frequency.invalid &&
                                    benifit_frequency.errors">
                                        <small class="text-danger"
                                            *ngIf="benifit_frequency.hasError('required')"
                                            test_id="incomeDetailFromBenefitHowOftenError">
                                            Please select an answer from the list
                                        </small>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <!---income from benifit section end-->
                            <!---other income section start-->
                            <div class="form-group separator">
                                <div class="form-group-heading" test_id="incomeDetailotherIncome">
                                    {{budgetFormItem.incomeDetail.otherIncome}}
                                </div>
                                <div class="col-12">
                                    <div class="radio mb-0">
                                        <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="isOtherIncome"
                                                formControlName="isOtherIncome" (change)="changeOtherIncome($event)"
                                                value="yes" test_id="incomeDetailotherIncomeRadioYes"><span
                                                class="label">Yes</span>
                                        </label>
                                    </div>
                                    <div class="radio">
                                        <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="isOtherIncome"
                                                formControlName="isOtherIncome" (change)="changeOtherIncome($event)"
                                                value="no"><span class="label"
                                                test_id="incomeDetailotherIncomeRadioNo">No</span>
                                        </label>
                                    </div>
                                </div>

                                <div *ngIf="submitted && is_other_income.invalid">
                                    <small class="text-danger"
                                        test_id="incomeDetailotherIncomeError">
                                        Please select an answer.
                                    </small>
                                </div>

                                <div formArrayName="Income_Other_additional_info"
                                    class="form-group inner-icon right-icon login-input-field"
                                    *ngIf="otherIncomeOtherFields || lengthofAdditioObj >0">

                                    <div *ngFor="let item of incomeForm.get('Income_Other_additional_info').controls; let itemIndex =index"
                                        [formGroupName]="itemIndex" class="form-group" [class.separator]="itemIndex > 0">

                                        <div class="amount-group">
                                            <div class="mt-2" *ngIf="itemIndex>0">
                                                <span class="form-group-heading" test_id="incomeDetailotherIncomeIndex">
                                                    Other Income {{utilservice.convertNumToString(itemIndex+1)}}
                                                </span>
                                                <span (click)="deleteOtherIncome(itemIndex)"
                                                    class="text-right float-right position-relative cursor-default delete-button">
                                                    <span class="cfa-delete-icon"></span>Delete
                                                </span>
                                            </div>
                                            <div><label class="subgroup" test_id="incomeDetailotherIncomeText">Type of income</label>
                                            </div>
                                            <div class="select-wrapper">
                                                <select class="form-control" required="required"
                                                    formControlName="sub_category"
                                                    test_id="incomeDetailotherIncomeSelect"
                                                    [ngClass]="{'is-invalid':submitted && incomeForm.controls.Income_Other_additional_info.controls[itemIndex].controls.sub_category.invalid }"
                                            >
                                                    <option selected [value]="null">Please select</option>
                                                    <option *ngFor="let type of typeOfIncome" [value]="type">{{type}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div
                                                *ngIf="submitted && incomeForm.controls.Income_Other_additional_info.controls[itemIndex].controls.sub_category.invalid">
                                                <small class="text-danger" test_id="incomeDetailotherIncomeErrormsg">
                                                    Please select an answer from the list
                                                </small>
                                            </div>
                                            <div>
                                                <label class="subgroup"
                                                    test_id="incomeDetailotherIncomeAmount">
                                                    {{budgetFormItem.incomeDetail.enterAmount}}
                                                </label>
                                                <div class="position-relative">
                                                    <span class="currency">£</span>
                                                    <input type="text"
                                                        class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                                        formControlName="amount"
                                                        amountValidation
                                                        dependentField="incomeDetailotherIncomeRadioYes"
                                                        fiveDigitValidation="Income_Other_additional_info"
                                                        indexVal = "{{itemIndex}}"
                                                        required="required"
                                                        pattern="^(?:\d*\.\d{1,2}|\d+)$"
                                                        test_id="incomeDetailotherIncomeInput"
                                                        onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                                        [ngClass]="{'is-invalid':submitted && incomeForm.controls.Income_Other_additional_info.controls[itemIndex].controls.amount.errors }"
                                              >

                                                    <div
                                                        *ngIf="submitted && incomeForm.controls.Income_Other_additional_info.controls[itemIndex].controls.amount.invalid && incomeForm.controls.Income_Other_additional_info.controls[itemIndex].controls.amount.errors &&
                                                        (incomeForm.controls.Income_Other_additional_info.controls[itemIndex].controls.amount.errors.required || incomeForm.controls.Income_Other_additional_info.controls[itemIndex].controls.amount.errors.pattern)">
                                                        <small class="text-danger"
                                                            test_id="incomeDetailotherIncomeAmountError">
                                                            Please enter an amount e.g. £123 or £123.23.​
                                                        </small>
                                                    </div>
                                                    <div *ngIf="incomeForm.controls.Income_Other_additional_info.controls[itemIndex].controls.amount.errors && !incomeForm.controls.Income_Other_additional_info.controls[itemIndex].controls.amount.errors.pattern
                                                        && incomeForm.controls.Income_Other_additional_info.controls[itemIndex].controls.amount.errors.fiveDigitError">
                                                        <small class="text-danger" test_id="incomeDetailincomeFromBenefitFiveDigitError">
                                                            {{budgetFormItem.incomeDetail.fiveDigitError}}​​
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div><label class="subgroup"
                                                    test_id="incomeDetailotherIncomehowOften">{{budgetFormItem.incomeDetail.howOften}}</label>
                                            </div>
                                        <div class="select-wrapper">

                                            <select class="form-control"
                                                formControlName="frequency"
                                                required="required"
                                                fiveDigitValidation="Income_Other_additional_info"
                                                indexVal = "{{itemIndex}}"
                                                test_id="incomeDetailotherIncomehowOftenSelect"
                                                [ngClass]="{'is-invalid':submitted &&  incomeForm.controls.Income_Other_additional_info.controls[itemIndex].controls.frequency.invalid }"
                                                >
                                                <option selected [value]="null">Please select</option>
                                                <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                            </select>
                                        </div>
                                            <div
                                                *ngIf="submitted && incomeForm.controls.Income_Other_additional_info.controls[itemIndex].controls.frequency.invalid">
                                                <small class="text-danger"
                                                    test_id="incomeDetailotherIncomehowOftenSelectError">
                                                    Please select an answer from the list
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="(otherIncome.controls.length == maximumIncomeEntries)">
                                        <div class="icon_info_circle position-absolute">
                                        </div>
                                        <div class="error-banner-section ">
                                          <bb-content-html [html]="budgetFormItem.commonDetail.maxCountReachedError"></bb-content-html>
                                        </div>
                                    </div>

                                    <button type="button" *ngIf= "(otherIncome.controls.length > 0 ) && (otherIncome.controls.length < maximumIncomeEntries)" class="wss-button-secondary pl-5 add-button" (click)="addother()"

                                        test_id="incomeDetailotherIncomeAddButton">

                                        <span class="material-icons add-icon">
                                            add
                                        </span>
                                        {{budgetFormItem.incomeDetail.addbutton}}
                                    </button>
                                </div>
                            </div>
                            <!---other income section end-->
                        </div>
                        <!--round panel end-->
                        <div class="container-row content-center margin-top-20">
                            <button type="submit"  class="wss-button btn btn-dif-view" (click)="goToNextPage()"
                                test_id="incomeDetailContinueButtonText">
                                <!--Continue -->{{budgetFormItem.incomeDetail.continuebuttonText}}
                            </button>
                        </div>


                        <div class="container-row content-center margin-top-20">
                          <button #button type="button" bbButton class="wss-button-secondary wss-button btn-dif-view" type="button"
                            (click)="openModal(budgetFormItem)" >
                            {{budgetFormItem.saveExitModal.saveAndExitButtonText}}
                          </button>
                        </div>
                        <div class="container-row content-center margin-top-20" (click)="goBackToPersonalInfo()"
                            test_id="incomeDetailotherIncomeBackButton">
                            <a>
                              {{budgetFormItem.incomeDetail.backbuttonText}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
  </section>
