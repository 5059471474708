<section id="br-getdetails-container" class="content-margin">
    <div id="balloon-refinance-getdetails-cont" class="content-wrapper-container-brgd" [ngClass]="{'stepper-success': isReturnJourney}" *ngIf="balloonRefinanceGetdetailsWidgetItem$ | async as brGetdetailsWidgetItem">
    <div *ngIf="((deviceType === 'Tablet') || (deviceType === 'Desktop')) && !hideStepper">
        <div class="container-step">
            <ng-template [ngIf]="currentStep">
                <bb-stepper-ui [className]="'stepper-ui'">
                    <bb-stepper-step-ui [isActive]="currentStep >= 1" [isChecked]="currentStep > 1 || (isReturnJourney && currentStep != 1)" [isCurrent]="currentStep == 1"
                        ariaLabel="Step 1 out of 5" label={{brGetdetailsWidgetItem.balloonRefinanceGetdetailsTitle.step1Title}} class="stepper-label-position">
                    </bb-stepper-step-ui>
                    <bb-stepper-step-ui [isActive]="currentStep >= 2" [isChecked]="currentStep > 2 || (isReturnJourney && currentStep != 2)" [isCurrent]="currentStep == 2"
                        ariaLabel="Step 2 out of 5" label={{brGetdetailsWidgetItem.balloonRefinanceGetdetailsTitle.step2Title}}>
                    </bb-stepper-step-ui>
                    <bb-stepper-step-ui [isActive]="currentStep >= 3" [isChecked]="currentStep > 3 || (isReturnJourney && currentStep != 3)" [isCurrent]="currentStep == 3"
                        ariaLabel="Step 3 out of 5" label={{brGetdetailsWidgetItem.balloonRefinanceGetdetailsTitle.step3Title}}>
                    </bb-stepper-step-ui>
                    <bb-stepper-step-ui [isActive]="currentStep >= 4" [isChecked]="currentStep > 4 || (isReturnJourney && currentStep != 4)" [isCurrent]="currentStep == 4"
                        ariaLabel="Step 4 out of 5" label={{brGetdetailsWidgetItem.balloonRefinanceGetdetailsTitle.step4Title}}>
                    </bb-stepper-step-ui>
                    <bb-stepper-step-ui [isActive]="currentStep >= 5" [isChecked]="currentStep > 5 || (isReturnJourney && currentStep != 5)" [isCurrent]="currentStep == 5"
                        ariaLabel="Step 5 out of 5" label={{brGetdetailsWidgetItem.balloonRefinanceGetdetailsTitle.step5Title}}>
                    </bb-stepper-step-ui>
                </bb-stepper-ui>
            </ng-template>
        </div>
    </div>
    <div *ngIf="deviceType === 'Mobile' && !hideStepper">
        <div class="container-step">
            <ng-template [ngIf]="currentStep">
                <bb-stepper-ui [className]="'stepper-ui'">
                    <bb-stepper-step-ui [isActive]="currentStep >= 1" [isChecked]="currentStep > 1 || (isReturnJourney && currentStep != 1)" [isCurrent]="currentStep == 1"
                        ariaLabel="Step 1 out of 5" label="" class="stepper-label-position">              
                    </bb-stepper-step-ui>
                    <bb-stepper-step-ui [isActive]="currentStep >= 2" [isChecked]="currentStep > 2 || (isReturnJourney && currentStep != 2)" [isCurrent]="currentStep == 2"
                        ariaLabel="Step 2 out of 5" label="">
                    </bb-stepper-step-ui>
                    <bb-stepper-step-ui [isActive]="currentStep >= 3" [isChecked]="currentStep > 3 || (isReturnJourney && currentStep != 3)" [isCurrent]="currentStep == 3"
                        ariaLabel="Step 3 out of 5" label="">
                    </bb-stepper-step-ui>
                    <bb-stepper-step-ui [isActive]="currentStep >= 4" [isChecked]="currentStep > 4 || (isReturnJourney && currentStep != 4)" [isCurrent]="currentStep == 4"
                        ariaLabel="Step 4 out of 5" label="">
                    </bb-stepper-step-ui>
                    <bb-stepper-step-ui [isActive]="currentStep >= 5" [isChecked]="currentStep > 5 || (isReturnJourney && currentStep != 5)" [isCurrent]="currentStep == 5"
                        ariaLabel="Step 5 out of 5" label="">
                    </bb-stepper-step-ui>
                </bb-stepper-ui>
            </ng-template>
        </div>
    </div>
    <div class="overflow-hidden">
        <div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
</section>