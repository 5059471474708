import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule, APP_INITIALIZER} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppInitService} from './app-init.service';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {BackbaseCoreModule} from '@backbase/foundation-ang/core';
import {environment} from '../environments/environment';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ContainersModule} from '@backbase/universal-ang/containers';
import {MegaMenuNavigationContainerModule} from '@backbase/universal-ang/navigation';
import {LayoutContainerModule} from '@backbase/universal-ang/layouts';
import {MatStepperModule} from '@angular/material/stepper';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {
  ButtonModule,
  HeaderModule,
  InputEmailModule,
  InputNumberModule,
  InputTextModule,
  InputValidationMessageModule,
  LogoModule,
  StepperModule
} from '@backbase/ui-ang';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ContentService } from '@wss/service/content.service';
import { RegisterService } from '@wss/registration-widget/src/service/register.service';
import { RegistrationWidgetModule } from '@wss/registration-widget';
import { RegistrationStoreModule } from '@wss/registration-store';
import { LoginWidgetModule } from '@wss/login-widget';
import { AccountSummaryWidgetModule } from '@wss/account-summary-widget';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PaymentWidgetModule } from '@wss/payment-widget';
import { NavigationWidgetModule } from '@wss/navigation-widget';
import { TransactionWidgetModule } from '@wss/transaction-widget';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderWidgetModule } from '@wss/header-widget';
import { WSSCommonModule } from '@wss/common/common.module';
import { HeaderContentWidgetModule } from '@wss/header-content-widget';
import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';
import { HelpCentreWidgetModule } from '@wss/help-centre-widget';
import { PaymentHolidayWidgetModule } from '@wss/payment-holiday-widget';
import { BnNgIdleService } from 'bn-ng-idle';
import { FooterWidgetModule } from '@wss/footer-widget';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { wssInterceptor } from '../../../../libs/service/http.interceptor';
import { WelcomeWidgetModule } from '@wss/welcome-widget';
import { DigitalInboxWidgetModule } from '@wss/digital-inbox-widget';
import { InboxMsgWidgetModule } from '@wss/inbox-msg-widget';
import { NavigationStepperWidgetModule } from '@backbase/universal-ang/navigation';
import { BudgetPersonalInfoWidgetModule } from '@wss/budget-cover-widgets/budget-personal-info-widget';
import { BudgetIncomeWidgetModule } from '@wss/budget-cover-widgets/budget-income-widget';
import { BudgetAccomodationCostModule } from '@wss/budget-cover-widgets/budget-accomodation-cost';
import { EndOfContractWidgetModule } from '@wss/end-of-contract-widget';
import { ServiceExceptionWidgetModule } from '@wss/service-exception-widget';

import { BudgetMoneyOwedModule } from '@wss/budget-cover-widgets/budget-money-owed';
import { BudgetEssentialLivingCostModule } from '@wss/budget-cover-widgets/budget-essential-living-cost';
import { BudgetEOMWidgetModule } from '@wss/budget-cover-widgets/budget-eom';
import { HandBackChecklistWidgetModule } from '@wss/handback-checklist-widget';
import { bbNavigationStepperExtWidgetModule } from '@wss/budget-cover-widgets/budget-stepper-navigation-custom';
import { CarbonIoffsetWidgetModule } from '@wss/carbon-ioffset-widget';
import { CookiePolicyWidgetModule } from '@wss/cookie-policy-widget';
import { TermsConditionsWidgetModule } from '@wss/terms-conditions-widget';
import { PrivacyPolicyWidgetModule } from '@wss/privacy-policy-widget';
import { ContactPreferenceWidgetModule } from '@wss/contact-preference-widget/src/contact-preference-widget.module';
import { balloonRefinanceReducer } from '@wss/store/balloonRefinance/balloonRefinance.reducer';
import { BalloonRefinanceEffects } from '@wss/store/balloonRefinance/balloonRefinance.effect';
import { BalloonRefinanceStoreModule } from '@wss/store/balloonRefinance/balloonRefinance.store.module';
import { BalloonRefinanceWidgetModule } from '@wss/balloon-refinance-widget';
import { BalloonRefinanceGetdetailsWidgetModule } from '@wss/balloon-refinance-getdetails-widget';
import { ComplaintWidgetModule  } from '@wss/complaints-widget';

export function initializeAppTheme(appInitService: AppInitService) {
  return () => appInitService.Init();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    HttpClientModule,
    BackbaseCoreModule.forRoot({
      features: {
        THEME_V2: true
      },
      assets: {
        assetsStaticItemName: environment.assetsStaticItemName,
      }
    }),
    HelpCentreWidgetModule,
    ContainersModule,
    MegaMenuNavigationContainerModule,
    LayoutContainerModule,
    HeaderModule,
    LogoModule,
    StepperModule,
    InputNumberModule,
    InputValidationMessageModule,
    InputEmailModule,
    ButtonModule,
    InputTextModule,
    MatStepperModule,
    MatBadgeModule,
    MatTabsModule,
    MatMenuModule,
    MatCheckboxModule,
    HandBackChecklistWidgetModule,
    RouterModule.forRoot([
    ], { initialNavigation: 'disabled', useHash: true, scrollPositionRestoration: "enabled", relativeLinkResolution: 'legacy' }),
    MatFormFieldModule,
    RegistrationWidgetModule,
    RegistrationStoreModule,
    LoginWidgetModule,
    AccountSummaryWidgetModule,
    PaymentWidgetModule,
    NavigationWidgetModule,
    TransactionWidgetModule,
    NgbModalModule,
    HeaderWidgetModule,
    WSSCommonModule,
    HeaderContentWidgetModule,
    ContentWidgetModule,
    ContentModule,
    PaymentHolidayWidgetModule,
    FooterWidgetModule,
    WelcomeWidgetModule,
    DigitalInboxWidgetModule,
    InboxMsgWidgetModule,
    NavigationStepperWidgetModule,
    BudgetPersonalInfoWidgetModule,
    BudgetAccomodationCostModule,
    EndOfContractWidgetModule,
    BudgetIncomeWidgetModule,
    BudgetAccomodationCostModule,
    BudgetMoneyOwedModule,
    BudgetEssentialLivingCostModule,
    BudgetEOMWidgetModule,
    ServiceExceptionWidgetModule,
    bbNavigationStepperExtWidgetModule,
    CarbonIoffsetWidgetModule,
    CookiePolicyWidgetModule,
    TermsConditionsWidgetModule,
    PrivacyPolicyWidgetModule,
    ContactPreferenceWidgetModule,
    BalloonRefinanceWidgetModule,
    BalloonRefinanceStoreModule,
    BalloonRefinanceGetdetailsWidgetModule,
    ComplaintWidgetModule,
    StoreModule.forRoot(balloonRefinanceReducer),
    EffectsModule.forRoot([BalloonRefinanceEffects])
  ],
  providers: [
    AppInitService,
    { provide: APP_INITIALIZER,
      useFactory: initializeAppTheme,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: wssInterceptor,
      multi: true
    },
    RegisterService,
    BnNgIdleService,
    ContentService,
    Title,
    ...environment.mockProviders || []
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
