<div class="main-container registration-container-wrapper" role="application" [attr.style]="registrationContainerStyle">
  <div class="header-container" role="heading">
    <div class="logo-icon-container" role="banner">
      <div class="logo"></div>
      <a class="float-right" (click)="toLogin()">Login<mat-icon class="align-middle">exit_to_app</mat-icon></a>
    </div>

    <div class="container-step">
      <ng-template [ngIf]="currentStep">
        <bb-stepper-ui [className]="'stepper-ui'">
          <bb-stepper-step-ui [isActive]="currentStep >= 1" [isChecked]="currentStep > 1" [isCurrent]="currentStep == 1" 
            ariaLabel="Step 1 out of 4" label="Your details" class="stepper-label-position">
            <bb-header-ui label="hello"></bb-header-ui>
          </bb-stepper-step-ui>
          <bb-stepper-step-ui [isActive]="currentStep >= 2" [isChecked]="currentStep > 2" [isCurrent]="currentStep == 2" 
            ariaLabel="Step 2 out of 4" label="Authenticate">
          </bb-stepper-step-ui>
          <bb-stepper-step-ui [isActive]="currentStep >= 3" [isChecked]="currentStep > 3" [isCurrent]="currentStep == 3" 
            ariaLabel="Step 3 out of 4" label="Create account">
          </bb-stepper-step-ui>
          <bb-stepper-step-ui [isActive]="currentStep >= 4" [isChecked]="currentStep > 4" [isCurrent]="currentStep == 4"
            ariaLabel="Step 4 out of 4" label="Preferences" *ngIf="isContactPreferenceFeatureOn">
          </bb-stepper-step-ui>
        </bb-stepper-ui>
      </ng-template>
    </div>
  </div>

  <div *ngIf="portalName !== 'blackhorse'" class="main-content">
    <div class="background-container">
      <div class="registration-container">
        <div class="registration-page-header text-center">{{title | async}}</div>
        <div class="registration-content" role="main">
          <bb-router-outlet></bb-router-outlet>
        </div>
      </div>
    </div>
  </div>

  <!-- Blackhorse UI -->
  <div *ngIf="portalName === 'blackhorse'" class="registration-screen-background-container">
    <div class="registration-screen-background-section1"></div>
    <div class="registration-screen-background-section2"></div>
    <div class="registration-screen-background-section3"></div>
    <div class="registration-container">
      <div class="registration-page-header text-center">{{title | async}}</div>
      <div class="registration-content" role="main">
        <bb-router-outlet></bb-router-outlet>
      </div>
    </div>
  </div>

</div>