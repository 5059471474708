import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { MessageService } from '../../../../service/message.service';
import { RouterService } from "@backbase/foundation-ang/core";
import { DatastoreService } from '../../../../service/datastore.service';
import { PaymentHolidayService } from '../../services/payment-holiday.service';
import { PCPOptions } from '@wss/model/payment-holiday-widget/payment-holiday-widget';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { WSSAppConstant } from '../../../../config/wss-app-constants';
import { getDeviceType } from '@wss/common/util/util';

@Component({
    selector: 'pcp-options',
    templateUrl: './pcp-options.component.html'
})

export class PCPOptionsComponent implements OnInit {

    @Input("currentMonthly") currentMonthly: any;
    @Input("pcpOptions") pcpOptions: PCPOptions = new PCPOptions();
    @Output("showError") showError = new EventEmitter<number>();

    listActive: boolean = true;
    optionSelected: string = '';
    modagResponse: any = {};
    selectedQuote: any = {};
    eligibleOptionCount: number = 0;
    svgInfoIcon = WSSAppConstant.SVG_INFO_ICON;
    popoverSelf: any;
    tooltipCloseStyle: string = "font-size: 18px; margin-top: -10px !important;";
    popoverArrowStyle: string = '';
    deviceType: string = "Mobile";

    constructor(
        private router: RouterService,
        private messageService: MessageService,
        private datastore: DatastoreService,
        private paymentHolidayService: PaymentHolidayService,
        config: NgbPopoverConfig,
        private changeDetectorRef: ChangeDetectorRef) {
        config.placement = 'bottom';
        config.container = "body";
    }

    ngOnInit() {
        this.handleErrors();
        this.caculateModagQuote();
    }

    caculateModagQuote(){
        this.paymentHolidayService.caculateModagQuote(null, null).subscribe((res: any) => {
            this.modagResponse = res;
            if(this.modagResponse && this.modagResponse.modag_quote_response && this.modagResponse.modag_quote_response.modag_quotes){
                this.modagResponse.modag_quote_response.modag_quotes = this.getEligibleQuotes(this.modagResponse.modag_quote_response.modag_quotes);
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    getEligibleQuotes(resQuotes: any[]){
        let eligibleQuotes: any[] = [];
        let currentMonthlyRental: any = this.currentMonthly;
        let current10Value: any = 0;
        let current25Value: any = 0;
        let newRentalValue: any = 0;
        let reducedCurrentValue: any = 0;
        current10Value = currentMonthlyRental - (currentMonthlyRental * 0.10);
        current25Value = currentMonthlyRental - 25;
        if(current10Value < current25Value){
            reducedCurrentValue = current10Value;
        }
        else{
            reducedCurrentValue = current25Value;
        }
        for(var i = 0; i < resQuotes.length; i++){
            newRentalValue = parseFloat(resQuotes[i].new_rental_sets[0].new_rental_value);
            if((newRentalValue <= reducedCurrentValue) && (newRentalValue > 0)){
                if(i === 0){
                    resQuotes[i].optionMonth = 12;
                }
                if(i === 1){
                    resQuotes[i].optionMonth = 18;
                }
                if(i === 2){
                    resQuotes[i].optionMonth = 24;
                }
                resQuotes[i].total_Payable = parseFloat(resQuotes[i].total_Payable);
                resQuotes[i].new_rental_sets.map((rentalSet: any) => {
                    rentalSet.new_number_of_rentals = parseInt(rentalSet.new_number_of_rentals);
                });
                eligibleQuotes.push(resQuotes[i]);
            }
        }
        this.eligibleOptionCount = eligibleQuotes.length;
        return eligibleQuotes;
    }

    toggleView(view: string){
        if(view === 'list'){
            this.listActive = true;
        }
        else{
            this.listActive = false;
        }
    }

    selectOption(option: string, quote: any){
        this.optionSelected = option; 
        this.selectedQuote = quote;
    }

    finalizeQuote(){
        //let term = this.optionSelected === 'option1'? '12': this.optionSelected === 'option2'? '18': '24';
        let term: any = 0;
        this.selectedQuote.new_rental_sets.forEach((rental: any)   => {
            term += rental.new_number_of_rentals;
        });
        this.datastore.updateBureauCallFailCount(0);
        this.datastore.setSelectedTermExtensionQuote(this.selectedQuote);
        this.datastore.setRequestedTerm(term);
        this.router.navigate(['finalize-agreement']);
    }

    // goBack(){
    //     this.router.navigate(['your-options']);
    // }

    handleErrors() {
        this.messageService.getServiceError().subscribe((error: any) => {
            this.showError.emit(error.status);
        });
    }

    openPopover(popover: any, event: MouseEvent, viewType: string) {
        popover.open();
        this.popoverSelf = popover;
        this.deviceType = getDeviceType();
        if ((viewType === 'list') && (this.deviceType === 'Mobile')) {
            this.popoverArrowStyle = 'left: 66% !important';
        }
        else if ((viewType === 'list') && (this.deviceType === 'Tablet')) {
            this.popoverArrowStyle = 'left: 48% !important';
        }
        else if ((viewType === 'list') && (this.deviceType === 'Desktop')) {
            this.popoverArrowStyle = 'left: 48% !important';
        }
        else if ((viewType === 'grid') && (this.deviceType === 'Mobile')) {
            this.popoverArrowStyle = 'left: 10% !important';
        }
    }

    closePopover() {
        this.popoverSelf.close();
    }
}