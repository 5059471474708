<div class="container-row expansion-border-hidden" role="row">
    <mat-accordion class="accordion mat-accordion-override w-100 term-ext" role="accordion">
        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override" test_id="faqAnswer1">
            <mat-expansion-panel-header class="mat-panel-header-override">
                <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="faqQuestion1">
                    {{faqAccordion.faqQuestion1}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <bb-content-html [html]="faqAccordion.faqAnswer1"></bb-content-html>
        </mat-expansion-panel>
    
        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override" test_id="faqAnswer2">
            <mat-expansion-panel-header class="mat-panel-header-override">
                <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="faqQuestion2">
                    {{faqAccordion.faqQuestion2}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <bb-content-html [html]="faqAccordion.faqAnswer2"></bb-content-html>
    
        </mat-expansion-panel>
    
        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override" test_id="faqAnswer3">
            <mat-expansion-panel-header class="mat-panel-header-override">
                <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="faqQuestion3">
                    {{faqAccordion.faqQuestion3}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <bb-content-html [html]="faqAccordion.faqAnswer3"></bb-content-html>
        </mat-expansion-panel>
    
        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override" test_id="faqAnswer4">
            <mat-expansion-panel-header class="mat-panel-header-override">
                <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="faqQuestion4">
                    {{faqAccordion.faqQuestion4}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <bb-content-html [html]="faqAccordion.faqAnswer4"></bb-content-html>
        </mat-expansion-panel>
    </mat-accordion>
    </div>
    
<!-- <div class="container-row expansion-border-hidden" role="row">
<mat-accordion class="accordion mat-accordion-override term-ext" role="accordion">
    <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
        <mat-expansion-panel-header class="mat-panel-header-override">
            <mat-panel-title class="mat-expansion-panel-header-title-override">
                {{content[0]?.willpmtchange}}?
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>{{content[0]?.mayhvchanged}}.</p>
        <p>{{content[0]?.wemovedur}}.</p>
        <p>{{content[0]?.wehvsent}} <a (click)="navigateTo('myaccount')" role="navigation" title="{{content[0]?.accountpage}}" tabindex="0">{{content[0]?.accountpage}}</a></p>
        <p>{{content[0]?.ifthisdata}} <a class="faq-link" title="{{content[0]?.helpno}}" tabindex="0">{{content[0]?.helpno}}</a></p>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
        <mat-expansion-panel-header class="mat-panel-header-override">
            <mat-panel-title class="mat-expansion-panel-header-title-override">
                {{content[0]?.icancelled}}?
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>{{content[0]?.weneedtoset}} <a (click)="navigateTo('/payments/direct-debit')" role="navigation" title="{{content[0]?.updatebd}}" tabindex="0">{{content[0]?.updatebd}}</a></p>
        <p>{{content[0]?.ifitsless}} <a (click)="navigateTo('/payments/payment-menu')" role="navigation" title="{{content[0]?.makeapayemnt}}" tabindex="0">{{content[0]?.makeapayemnt}}</a></p>
        <p>{{content[0]?.ifurnotsure}}.</p>

    </mat-expansion-panel>

    <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
        <mat-expansion-panel-header class="mat-panel-header-override">
            <mat-panel-title class="mat-expansion-panel-header-title-override">
                {{content[0]?.imissed}}?
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>{{content[0]?.ifumissed}} <a title="{{content[0]?.helpno}}" tabindex="0">{{content[0]?.helpno}}</a> {{content[0]?.assoonas}}</p>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
        <mat-expansion-panel-header class="mat-panel-header-override">
            <mat-panel-title class="mat-expansion-panel-header-title-override">
                {{content[0]?.whtisimpact}}?
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>{{content[0]?.impactoftaking}}.</p>
        <p>{{content[0]?.paythisextra}}.</p>
        <p>{{content[0]?.wewillwrite}}.</p>
    </mat-expansion-panel>
</mat-accordion>
</div> -->
