<div class="container-column" *ngIf="headerContentWidgetItem$ | async as headerWidgetItem">
  <div class="change-password-container">
    <div class="change-password-wrapper">
      <!-- Banner for server error -->
      <div class="reg-row" role="alert" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
      </div> 
      <!-- End of banner -->
      <div class="title-header padding-left-right-style" *ngIf="!changePasswordFailedFlag">
        <span class="section-label-title" test_id="which_account_label_id">
          {{headerWidgetItem.changePassword.pageTitle}}
        </span>
        <hr />
      </div>
      <div class="change-password-form-container" *ngIf="!changePasswordFailedFlag">

        <div class="reg-row padding-left-right-style">
          <p class="youre-almost-done">
            {{headerWidgetItem?.changePassword?.choosePass}}
          </p>
        </div>

        <form [formGroup]="resetPasswordForm" (ngSubmit)="onUpdatePasswordSubmit()" class="change-password-form-wrapper">
          
          <!-- TCC Old password entry -->
          <div class="reg-row-col padding-left-right">
            <bb-input-password-ui label="{{headerWidgetItem?.changePassword?.enterCurrentPassword}}" placeholder="{{headerWidgetItem.changePassword.changeOldPasswordPlaceholder}}" formControlName="oldPassword"
              autocomplete="new-password" [aria-describedby]="'password-error'" [showVisibilityControl]="true" data-cs-mask>
            </bb-input-password-ui>
          </div>

          <div class="invalid-feedback padding-left-right" [ngClass]="{'show-error': showOldPasswordEntryError}">
            {{headerWidgetItem?.changePassword?.invalidOldPassword}}
          </div>
          <!-- TCC old password entry end -->

          <div class="reg-row-col padding-left-right password-screen-margin-top">
            <bb-input-password-ui label="{{headerWidgetItem?.changePassword?.enterNewPassword}}" placeholder="{{headerWidgetItem.changePassword.changePasswordPlaceholder}}" formControlName="password"
              autocomplete="new-password" [aria-describedby]="'password-error'" [showVisibilityControl]="true" data-cs-mask>
            </bb-input-password-ui>
          </div>

          <bb-wss-common-password-rules [containsAtleastEightChars]="atLeastContainsEightChars"
            [containsAtLeastOneLowerCase]="atLeastOneLowerCase" [containsAtLeastOneNumber]="atLeastOneNumber"
            [containsAtLeastOneUpperCase]="atLeastOneUpperCase"
            [passwordRules]="headerWidgetItem.changePassword.passwordRules">
          </bb-wss-common-password-rules>

          <div class="invalid-feedback padding-left-right" [ngClass]="{'show-error': showPasswordError}">
          {{headerWidgetItem?.changePassword?.invalidPassword}}
          </div>

          <div class="reg-row padding-left-right password-screen-margin-top">
            <bb-input-password-ui label="{{headerWidgetItem?.changePassword?.enterConfirmedPassword}}" placeholder=" {{headerWidgetItem.changePassword.confirmPasswordPlaceholder}}"
              formControlName="confirmPassword" autocomplete="new-password" onPaste="return false"
              [aria-describedby]="'password-error'" [showVisibilityControl]="true" [disabled]="passwordEntered" data-cs-mask>
            </bb-input-password-ui>
          </div>
          <div class="invalid-feedback padding-left-right" [ngClass]="{'show-error confirm-password': !passwordMatch}">
            {{headerWidgetItem?.changePassword?.passwordMatchError}}
          </div>
          <div class="reg-row password-submit-button">
            <button id="change-password-btn" bbButton type="submit"
              aria-label="Favourite" class="wss-button" [disabled]="buttonDisabled || passwordEntered"
              title="{{headerWidgetItem?.changePassword?.changePassword}}">
              {{headerWidgetItem?.changePassword?.changePassword}}
            </button>
          </div>
          <div  *ngIf="!isAccountArchived" class="d-flex justify-content-center help-blink">
            <bb-content-html [html]="headerWidgetItem.changePassword.needHelpLink"></bb-content-html>
          </div>
        </form>
        
      </div>

      <!-- Password invalid container -->
      <div class="change-password-success-container" *ngIf="changePasswordFailedFlag">
        <div class="account-lock">
          <div class="security-fail-icon"></div>
          <h4>
            <span *ngIf="!sameAsCurrentPasswordFlag">{{headerWidgetItem?.changePassword?.changePasswordFailed}}</span>
            <span *ngIf="sameAsCurrentPasswordFlag">{{headerWidgetItem?.changePassword?.sameAsCurrentPwdText}}</span>
          </h4>
          <p>
            <span *ngIf="!sameAsCurrentPasswordFlag">{{headerWidgetItem?.changePassword?.changePasswordTryAgain}}</span>
          </p>
          <div  *ngIf="sameAsCurrentPasswordFlag" class="d-flex justify-content-center help-blink">
            <bb-content-html [html]="headerWidgetItem.changePassword.sameAsCurrentPwdTryAgain"></bb-content-html>
          </div>
        </div>
        <div class="row margin-top-between-rows login-page-button text-center">
          <button id="try-again-btn" test_id="password_try_again"
            aria-label="Favourite" class="wss-button-login"
            (click)="onTryAgainSubmit()" 
            title="{{headerWidgetItem?.changePassword?.btnTryAgain}}">
            {{headerWidgetItem?.changePassword?.btnTryAgain}}
          </button>
        </div>
      </div>
      <!-- End of Invalid  container -->

    </div>
  </div>
</div>