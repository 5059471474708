<div class="reg-contact-preference">
    <div class="reg-row" role="alert" *ngIf="serviceError">         
        <bb-wss-banner-message [showRegistrationLink]="showEmailLink"></bb-wss-banner-message>
    </div>
    <form [formGroup]="contactForm" novalidate (ngSubmit)="onSubmit(false)">
        <section class="plr-30">
                <div>
                    <bb-content-html [html]="registrationWidgetItem?.contactPreference.pageHeaderText"></bb-content-html>
                </div> 
                <div>
                    <p class="section-heading"> 
                        {{ registrationWidgetItem?.contactPreference.yourChoice }} 
                    </p>
                </div>
                <div class="form-group">
                    <div class="flex-row">
                        <div>
                            <label> {{ registrationWidgetItem?.contactPreference.emailLabel }} </label>
                        </div>
                        <div>
                            <div class="toggle-btn-layer" >
                                <input type="radio" id="toggle-on-email" class="toggle toggle-left" [value]="true"
                                    name="UpdateMarketingEmail" formControlName="UpdateMarketingEmail">
                                <label for="toggle-on-email" class="toggle-btn btn-yes" >
                                    <span>
                                        <span class="check-mark-icon" *ngIf="contactForm.value.UpdateMarketingEmail != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                    </span>
                                </label>
                                <input type="radio" id="toggle-off-email" class="toggle toggle-right" [value]="false"
                                    name="UpdateMarketingEmail" formControlName="UpdateMarketingEmail">
                                <label for="toggle-off-email" class="toggle-btn btn-no" >
                                    <span class="v-line" *ngIf="contactForm.value.UpdateMarketingEmail == '' "></span>
                                    <span>
                                        <span class="check-mark-icon" *ngIf="f.UpdateMarketingEmail?.valid && contactForm.value?.UpdateMarketingEmail == false "><span id="check-icon" class="material-icons">check</span></span>
                                        No
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="flex-row">
                        <div>
                            <label> {{ registrationWidgetItem?.contactPreference.textMessageLabel }} </label>
                        </div>
                        <div>
                            <div class="toggle-btn-layer" >
                                <input type="radio" id="toggle-on-textMessage" class="toggle toggle-left" [value]="true"
                                    name="UpdateMarketingSMS" formControlName="UpdateMarketingSMS">
                                <label for="toggle-on-textMessage" class="toggle-btn btn-yes" >
                                    <span>
                                        <span class="check-mark-icon"
                                            *ngIf="contactForm.value.UpdateMarketingSMS != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                    </span>
                                </label>
                                <input type="radio" id="toggle-off-textMessage" class="toggle toggle-right"
                                    [value]="false" name="UpdateMarketingSMS" formControlName="UpdateMarketingSMS">
                                <label for="toggle-off-textMessage" class="toggle-btn btn-no" >
                                    <span class="v-line" *ngIf="contactForm.value.UpdateMarketingSMS == '' "></span>
                                    <span>
                                        <span class="check-mark-icon"
                                            *ngIf="f.UpdateMarketingSMS?.valid && contactForm.value?.UpdateMarketingSMS == false "><span id="check-icon" class="material-icons">check</span></span>
                                        No
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="flex-row">
                        <div>
                            <label> {{ registrationWidgetItem?.contactPreference.phoneLabel }} </label>
                        </div>
                        <div>
                            <div class="toggle-btn-layer" >
                                <input type="radio" id="toggle-on-phone" class="toggle toggle-left" [value]="true"
                                    name="UpdateMarketingPhone" formControlName="UpdateMarketingPhone">
                                <label for="toggle-on-phone" class="toggle-btn btn-yes" >
                                    <span>
                                        <span class="check-mark-icon"
                                            *ngIf="contactForm.value.UpdateMarketingPhone != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                    </span>
                                </label>
                                <input type="radio" id="toggle-off-phone" class="toggle toggle-right" [value]="false"
                                    name="UpdateMarketingPhone" formControlName="UpdateMarketingPhone">
                                <label for="toggle-off-phone" class="toggle-btn btn-no" >
                                    <span class="v-line" *ngIf="contactForm.value.UpdateMarketingPhone == '' "></span>
                                    <span>
                                        <span class="check-mark-icon"
                                            *ngIf="f.UpdateMarketingPhone?.valid && contactForm.value?.UpdateMarketingPhone == false "><span id="check-icon" class="material-icons">check</span></span>
                                        No
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="flex-row">
                        <div>
                            <label> {{ registrationWidgetItem?.contactPreference.postLabel }} </label>
                        </div>
                        <div>
                            <div class="toggle-btn-layer" >
                                <input type="radio" id="toggle-on-post" class="toggle toggle-left" [value]="true"
                                    name="UpdateMarketingPost" formControlName="UpdateMarketingPost">
                                <label for="toggle-on-post" class="toggle-btn btn-yes" >
                                    <span>
                                        <span class="check-mark-icon"
                                            *ngIf="contactForm.value.UpdateMarketingPost != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                    </span>
                                </label>
                                <input type="radio" id="toggle-off-post" class="toggle toggle-right" [value]="false"
                                    name="UpdateMarketingPost" formControlName="UpdateMarketingPost">
                                <label for="toggle-off-post" class="toggle-btn btn-no" >
                                    <span class="v-line" *ngIf="contactForm.value.UpdateMarketingPost == '' "></span>
                                    <span>
                                        <span class="check-mark-icon"
                                            *ngIf="f.UpdateMarketingPost?.valid && contactForm.value?.UpdateMarketingPost == false "><span id="check-icon" class="material-icons">check</span></span>
                                        No
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="flex-row">
                        <div>
                            <label> {{ registrationWidgetItem?.contactPreference.onlineAccountLabel }} </label>
                        </div>
                        <div>
                            <div class="toggle-btn-layer" >
                                <input type="radio" id="toggle-on-onlineAccount" class="toggle toggle-left"
                                    [value]="true" name="UpdateOnlineAccount" formControlName="UpdateOnlineAccount">
                                <label for="toggle-on-onlineAccount" class="toggle-btn btn-yes" >
                                    <span>
                                        <span class="check-mark-icon"
                                            *ngIf="contactForm.value.UpdateOnlineAccount != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                    </span>
                                </label>
                                <input type="radio" id="toggle-off-onlineAccount" class="toggle toggle-right"
                                    [value]="false" name="UpdateOnlineAccount" formControlName="UpdateOnlineAccount">
                                <label for="toggle-off-onlineAccount" class="toggle-btn btn-no" >
                                    <span class="v-line" *ngIf="contactForm.value.UpdateOnlineAccount == '' "></span>
                                    <span>
                                        <span class="check-mark-icon"
                                            *ngIf="f.UpdateOnlineAccount?.valid && contactForm.value?.UpdateOnlineAccount == false "><span id="check-icon" class="material-icons">check</span></span>
                                        No
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <bb-content-html [html]="registrationWidgetItem?.contactPreference.weMayShowMessages">
                    </bb-content-html>
                </div>

                <div class="form-group" *ngIf="isThirdPartyConsent">
                    <div class="flex-row">
                        <div>
                            <label> {{ registrationWidgetItem?.contactPreference.thirdPartyLabel }}
                            </label>
                        </div>
                        <div>
                            <div class="toggle-btn-layer">
                                <input 
                                    type="radio" 
                                    id="toggle-on-thirdPartyOffer"
                                    class="toggle toggle-left"
                                    [value]="true" 
                                    name="UpdateThirdPartyConsent" 
                                    formControlName="UpdateThirdPartyConsent">
                                <label for="toggle-on-thirdPartyOffer" class="toggle-btn btn-yes">
                                    <span>
                                        <span class="check-mark-icon"
                                            *ngIf="contactForm.value.UpdateThirdPartyConsent != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                    </span>
                                </label>
                                <input 
                                    type="radio" 
                                    id="toggle-off-thirdPartyOffer" 
                                    class="toggle toggle-right"
                                    [value]="false" 
                                    name="UpdateThirdPartyConsent" 
                                    formControlName="UpdateThirdPartyConsent">
                                <label for="toggle-off-thirdPartyOffer" class="toggle-btn btn-no">
                                    <span class="v-line" *ngIf="contactForm.value.UpdateThirdPartyConsent == '' "></span>
                                    <span>
                                        <span class="check-mark-icon"
                                            *ngIf="f.UpdateThirdPartyConsent?.valid && contactForm.value?.UpdateThirdPartyConsent == false "><span id="check-icon" class="material-icons">check</span></span>
                                        No
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isThirdPartyConsent">
                    <bb-content-html
                        [html]="registrationWidgetItem?.contactPreference.thirdPartyBelowTxt">
                    </bb-content-html>
                </div>

                <div class="section-heading">
                    <bb-content-html
                        [html]="registrationWidgetItem?.contactPreference.dataCollectHeading">
                    </bb-content-html>
                    <hr />
                </div>

                <div>
                    <bb-content-html [html]="registrationWidgetItem?.contactPreference.dataCollectInfo">
                    </bb-content-html>
                </div>

                <div class="form-group">
                    <div class="flex-row">
                        <div>
                            <label> {{ registrationWidgetItem?.contactPreference.doYouAgreeLabel }} </label>
                        </div>
                        <div>
                            <div class="toggle-btn-layer">
                                <input 
                                    type="radio" 
                                    id="toggle-on-doYouAgree" 
                                    class="toggle toggle-left"
                                    [value]="true" 
                                    name="UpdatePixelTracking" 
                                    formControlName="UpdatePixelTracking">
                                <label for="toggle-on-doYouAgree" class="toggle-btn btn-yes">
                                    <span>
                                        <span class="check-mark-icon"
                                            *ngIf="contactForm.value.UpdatePixelTracking != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                    </span>
                                </label>
                                <input 
                                    type="radio" 
                                    id="toggle-off-doYouAgree" 
                                    class="toggle toggle-right"
                                    [value]="false" 
                                    name="UpdatePixelTracking" 
                                    formControlName="UpdatePixelTracking">
                                <label for="toggle-off-doYouAgree" class="toggle-btn btn-no">
                                    <span class="v-line" *ngIf="contactForm.value.UpdatePixelTracking == '' "></span>
                                    <span>
                                        <span class="check-mark-icon"
                                            *ngIf="f.UpdatePixelTracking?.valid && contactForm.value?.UpdatePixelTracking == false "><span id="check-icon" class="material-icons">check</span></span>
                                        No
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <section class="plr-30">
            <div class="accordion-section">
                <div class="transparent-accordion">
                    <mat-accordion class="accordion mat-accordion-override margin-top-10">
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override"
                            [expanded]="isExpansionPanelOpen" >
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override"
                                    test_id="contactPreferenceExpansionPanelTitle1">
                                    <bb-content-html
                                        [html]="registrationWidgetItem?.contactPreference.expansionPanelTitle1">
                                    </bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p test_id="contactPreferenceExpansionPanelPara1">
                                <bb-content-html [html]="registrationWidgetItem?.contactPreference.expansionPanelPara1">
                                </bb-content-html>
                            </p>
                            <p test_id="contactPreferenceExpansionPanelPara2" class="paragraph" *ngIf="isThirdPartyConsent">
                                <bb-content-html [html]="registrationWidgetItem?.contactPreference.expansionPanelPara2">
                                </bb-content-html>
                            </p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>     
        </section>
        <section class="plr-30">
            <div class="actions">
                <button type="submit" bbButton class="wss-button" [disabled]="!isFormValid">
                    <bb-content-html [html]="registrationWidgetItem?.contactPreference.nextButtonText">
                    </bb-content-html>
                </button> 
                <a class="link-text" (click)="onSubmit(true)">
                    {{ registrationWidgetItem?.contactPreference.skipThisStep }}
                </a>
            </div>          
        </section>
    </form>
</div> 