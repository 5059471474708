import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { MatIconModule } from '@angular/material/icon';
import { NgbModalModule, NgbPopoverModule }  from '@ng-bootstrap/ng-bootstrap';
import { EndOfContractWidgetComponent } from './end-of-contract-widget.component';
import { WSSCommonModule } from '../../common/common.module';
import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';
import { EocLandingComponent } from './components/eoc-landing/eoc-landing.component';
import { EocOptionDetailsComponent } from './components/eoc-option-details/eoc-option-details.component';
import { RouterModule } from '@angular/router';
import { EocKeepYourVehicleComponent } from './components/eoc-keep-your-vehicle/eoc-keep-your-vehicle.component';
import { EocPartExchangeComponent } from './components/eoc-part-exchange/eoc-part-exchange.component';
import { EocReturnVehicleComponent } from './components/eoc-return-vehicle/eoc-return-vehicle.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { EocConfirmationComponent } from './components/eoc-confirmation/eoc-confirmation.component';
import { EocContactDetailsComponent } from './components/eoc-contact-details/eoc-contact-details.component';
import { FormsModule } from '@angular/forms';
import { MatRadioModule} from '@angular/material/radio';
import { EocReturnVehicleConfirmationComponent } from './components/eoc-return-vehicle-confirmation/eoc-return-vehicle-confirmation.component';
import { EocPartExchangeConfirmationComponent } from './components/eoc-part-exchange-confirmation/eoc-part-exchange-confirmation.component';
import { GoodConditionGuideUrlComponent } from './components/good-condition-guide-url/good-condition-guide-url.component';
import { EocHpComponent } from './components/eoc-hp/eoc-hp.component';
import { EocGetInTouchComponent } from './components/eoc-get-in-touch/eoc-get-in-touch.component';
import { ManageContractComponent } from './components/manage-contract/manage-contract.component';
import { ReactiveFormsModule } from '@angular/forms';
import { VoluntaryTermination } from './components/voluntary-termination/voluntary-termination.component';
import { EocTrafficLight } from './components/eoc-traffic-light/eoc-traffic-light.component';
import { EocVoluntaryTerminationCtaPage } from './components/eoc-voluntary-termination-CTA-page/eoc-voluntary-termination-CTA-page.component';

@NgModule({
  declarations: [
    EndOfContractWidgetComponent,
    EocLandingComponent,
    EocOptionDetailsComponent,
    EocKeepYourVehicleComponent,
    EocPartExchangeComponent,
    EocReturnVehicleComponent,
    EocConfirmationComponent,
    EocContactDetailsComponent,
    EocReturnVehicleConfirmationComponent,
    EocPartExchangeConfirmationComponent,
    EocHpComponent,
    EocGetInTouchComponent,
    VoluntaryTermination,
    ManageContractComponent,
    EocTrafficLight,
    EocVoluntaryTerminationCtaPage
  ],
  imports: [
    CommonModule,
    BackbaseCoreModule.withConfig({
      classMap: { EndOfContractWidgetComponent }
    }),
    MatIconModule,
    NgbModalModule,
    NgbPopoverModule,
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule,
    RouterModule.forRoot([], { initialNavigation: 'disabled', useHash: true, relativeLinkResolution: 'legacy' }),
    RouterModule,
    MatExpansionModule,
    FormsModule,
    MatRadioModule,
    ReactiveFormsModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class EndOfContractWidgetModule { }