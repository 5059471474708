export interface IManageContractSummary{
    additionalInfoData: ManageContractAdditionalInfo
}

export class ManageContractSummary implements IManageContractSummary {
    additionalInfoData: ManageContractAdditionalInfo = {} as ManageContractAdditionalInfo;

    constructor(){}
}
export interface IManageContractAdditionalInfo{
    startDate: string,
    endDate: string,
    contractLength: string,
    paymentMade: string,
    monthlyPayment: string,
    apr: string;
    annualMileage: string;
    optionalFinalPayment: string;
}
export class ManageContractAdditionalInfo implements IManageContractAdditionalInfo{
    startDate = '';
    endDate = '';
    contractLength = '';
    paymentMade = '';
    monthlyPayment = '';
    apr = '';
    annualMileage = '';
    optionalFinalPayment = '';
}