import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { BudgetFormItem } from '@wss/model/budget-form-widget/budgetForm'
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { saveAndExitService } from '@wss/budget-cover-widgets/components/save-and-exit-modal/save-and-exit.service';
import { UtilService } from '@wss/budget-cover-widgets/shared/service/util.service';
import { BudgetService } from '@wss/budget-cover-widgets/shared/service/budget.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { BudgetPlanner } from '@wss/budget-cover-widgets/shared/model/budget.model';
import { budgetForm, patternMap } from '@wss/config/wss-app-constants';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-budget-income-widget',
  templateUrl: './budget-income-widget.component.html',
  providers: [ContentService]
})
export class BudgetIncomeWidgetComponent implements OnInit {

  incomeForm: any;
  submitted: boolean = false;
  otherIncomeOtherFields: boolean = false;
  maximumIncomeEntries: number = budgetForm.maximumAdditionInfoEntries;

  frequencies: Array<string> = budgetForm.frequencyList;
  typeOfIncome: Array<string> =  budgetForm.typeOfIncome;
  numberWithtwoDecimalRegExp: RegExp = new RegExp(patternMap.amountwithtwodecimalPattern);
  storeData: any;
  serviceError: boolean = false;

  constructor(

    private fb: FormBuilder,
    private readonly bbContentService: ContentService,
    private route: ActivatedRoute,
    public router: Router,
    private modalService: saveAndExitService,
    public utilservice: UtilService,
    private budgetService: BudgetService,
    private datastoreService: DatastoreService,
    private changeDetectorRef: ChangeDetectorRef,
    private tealiumService: TealiumUtagService
  ) {
  }


  budgetFormItem$: Observable<BudgetFormItem | undefined> = this.bbContentService.getContent<BudgetFormItem>('BudgetFormItem');

  ngOnInit(): void {
    this.getServiceData();
    this.initIncomeForm();
    this.tealiumService.view(
      {
        JourneyName: 'Budget Cover',
        JourneyStepName: 'Income',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  };

  initIncomeForm() {
    this.incomeForm = this.fb.group({

      isIncomeWork: ["", [Validators.required]],
      isIncomePension: ["", [Validators.required]],
      isIncomeBenefits: ["", [Validators.required]],
      isOtherIncome: ["", [Validators.required]],
      current_page:["2"],
      incomeWorkAdditionalInfo: this.addtioninfoGroup("Income", "Work"),
      incomeBenefitsAdditionalInfo: this.addtioninfoGroup("Income", "Benefits"),
      incomePensionAdditionalInfo: this.addtioninfoGroup("Income", "Pension"),
      status:'Draft',
      Income_Other_additional_info: this.fb.array([])
    })



    this.changegroup('isIncomeWork', 'incomeWorkAdditionalInfo', 'Work');
    this.changegroup('isIncomePension', 'incomePensionAdditionalInfo', 'Pension');
    this.changegroup('isIncomeBenefits', 'incomeBenefitsAdditionalInfo', 'Benefits');

    this.updateRadio('isIncomeWork', 'Income', 'Work');
    this.updateRadio('isIncomePension', 'Income', 'Pension');
    this.updateRadio('isIncomeBenefits', 'Income', 'Benefits');
    this.updateRadio('isOtherIncome', 'Income', 'Other');

    this.addDataArray();

    this.updateFormGroup('incomeWorkAdditionalInfo', 'Income', 'Work');
    this.updateFormGroup('incomePensionAdditionalInfo', 'Income', 'Pension');
    this.updateFormGroup('incomeBenefitsAdditionalInfo', 'Income', 'Benefits');
  }

  //add other to create dynamic array object

  addtioninfoGroup(type: string, category: string): FormGroup {
    return new FormGroup({
      "type": new FormControl(type),
      "category": new FormControl(category),
      "sub_category": new FormControl(null),
      "amount": new FormControl('', [Validators.required, Validators.pattern(this.numberWithtwoDecimalRegExp)]),
      "frequency": new FormControl(null, Validators.required),
      "behind_payments_amount": new FormControl(''),
      "is_include_arrears": new FormControl(null)
    })
  }

  get otherIncome() {
    return this.incomeForm.get('Income_Other_additional_info') as FormArray
  }

  get work_support_info() {
      return this.incomeForm.get('isIncomeWork');
  }

  get work_amount() {
    if (this.incomeForm.contains('isIncomeWork') && this.incomeForm.contains('incomeWorkAdditionalInfo')) {
      return this.incomeForm.get('incomeWorkAdditionalInfo.amount');
    }
  }

  get work_frequency() {
    if (this.incomeForm.contains('isIncomeWork') && this.incomeForm.contains('incomeWorkAdditionalInfo')) {
      return this.incomeForm.get('incomeWorkAdditionalInfo.frequency');
    }
  }

  get lengthofAdditioObj() {
    var id = (this.incomeForm.controls['Income_Other_additional_info']) ?
      this.incomeForm.controls['Income_Other_additional_info'].controls.length
      : '0';

    return id;
  }

  get pension_support_info() {
    return this.incomeForm.get('isIncomePension');
  }

  get pension_amount() {
    return this.incomeForm.get('incomePensionAdditionalInfo.amount');
  }

  get is_other_income(){
    return this.incomeForm.get('isOtherIncome')
  }

  get pension_frequency() {
    return this.incomeForm.get('incomePensionAdditionalInfo.frequency');
  }

  get benifit_support_info() {
    return this.incomeForm.get('isIncomeBenefits');
  }

  get benifit_amount() {
    return this.incomeForm.get('incomeBenefitsAdditionalInfo.amount');
  }

  get benifit_frequency() {
    return this.incomeForm.get('incomeBenefitsAdditionalInfo.frequency');
  }

  get otherIncomeModule(): FormGroup {
    return this.addtioninfoGroup("Income", "Other")
  }

  get support_info() {
    return this.incomeForm.get('username');
  }

  get password() {
    return this.incomeForm.get('password');
  }

  changegroup(control: string, groupName: string, category: string) {
    this.incomeForm.get(control).valueChanges.subscribe((value: string) => {
      if (value == "yes") {

        this.incomeForm.addControl(groupName, this.addtioninfoGroup("Income", category));
      }
      else if (value == "no") {
          this.incomeForm.removeControl(groupName);
      }
      else {
      }
    })
  }

  changeOtherIncome(e: any) {
    if (e.target.value == "yes") {
      this.otherIncomeOtherFields = true;
      if (this.lengthofAdditioObj == 0) {
        this.addother()
      }
    }
    else {
      this.otherIncomeOtherFields = false;
      (this.incomeForm.get('Income_Other_additional_info') as FormArray).clear()
    }
  }

  get OtherIncomesForms(): FormArray {
    return this.incomeForm.get('addOtherIncomes') as FormArray
  }

  addDataArray() {

    let pageObjects = this.utilservice.selectMatchingInfo('Income', 'Other')

    if (pageObjects && pageObjects.length > 0 && this.storeData.current_page >= 2) {
      for (let i = 0; i < pageObjects.length; i++) {
        this.addother();
      }
    }

    this.incomeForm.controls['Income_Other_additional_info'].patchValue(pageObjects)
  }

  addother() {
    (this.incomeForm.get('Income_Other_additional_info') as FormArray).push(this.otherIncomeModule)
  }

  deleteOtherIncome(i: number) {
    (this.incomeForm.get('Income_Other_additional_info') as FormArray).removeAt(i)
  }

  goBackToPersonalInfo() {
    this.router.navigate(['budget-form/1']);
    window.scroll(0, 0);
  }

  goToNextPage() {
    this.submitted = true;
    if (this.incomeForm.valid) {
      this.updateDetail()
    }
  }

  navigateToNextpage(){
    window.scroll(0, 0);
    this.router.navigate(['budget-form/3']);
  }

  updateDetail() {
    this.budgetService.updateBudgetDetail(this.incomeForm.value, 'Income').subscribe((budgetResponse: BudgetPlanner) => {
      this.datastoreService.setBudgetDetail(budgetResponse);
      this.navigateToNextpage()
    }, (error: any) => {
      this.showError();
    });
  }

  openModal(budgetFormItem: object) {
    this.submitted = true;
    window.scroll(0, 0);
    if (this.incomeForm.valid) {
      this.modalService.openModalFromService(budgetFormItem, this.incomeForm.value, 'Income')
    }
  }

  getServiceData() {
    this.storeData = this.datastoreService.getBudgetDetail();//this.utilservice.mockgetData()
    /**
     * If user will redirect this page manually then
     * we are checking that user have already gone through this page
     * earlier and save the data
     * If not then we are redirecting to the budget home page
     */
    if(!this.storeData.current_page) {
      this.router.navigate(['help-centre/money-worries']);
    }
  }

  showError(): void {
    this.datastoreService.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  updateRadio(control: string, type: string, category: string) {
    let pageObjects = this.utilservice.selectMatchingInfo(type, category)
    if (this.storeData.current_page >= 2 && pageObjects.length > 0) {
      this.incomeForm.get(control).patchValue('yes')
    }
    else if (this.storeData.current_page >= 2 && pageObjects.length == 0) { //
      this.incomeForm.get(control).patchValue('no')
    }
    else {
      this.incomeForm.get(control).patchValue('')
    }
  }

  updateFormGroup(formGroup: string, type: string, category: string) {
    let pageObjects = this.utilservice.selectMatchingInfo(type, category)
    if (pageObjects.length > 0 && this.storeData.current_page >= 2) {
      this.incomeForm.get(formGroup).patchValue(pageObjects[0])
    }
  }
}
