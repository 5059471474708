<div *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <div class="login-container">
    <span class="section-label-size" test_id="loginWidgetItemtitleNameContent">
      {{loginWidgetItem.forgotUserNameVerifyOTP.pageHeaderText}}
    </span>
    <hr />
    <div class="back-to-login">
      <a href="#/user-login">
        <span class="material-icons">keyboard_backspace</span>
        {{loginWidgetItem.forgotUserNameVerifyOTP.backToLoginText}}
      </a>
    </div>
    <div class="reg-row" role="row" test_id="pageHeaderText">
      <bb-content-html [html]="loginWidgetItem.forgotUserNameVerifyOTP.infoText"></bb-content-html>
    </div>
    <form [formGroup]="otpInput">
      <div class="reg-row" role="row">
        <div class="row-arranged">
          <input type="number" class="otp-input-box" formControlName="otp1"
            onKeyPress="if(this.value.length ==1 ) return false;" id="otp1" [maxLength]="1" tabindex="1" min="0"
            oninput="validity.valid||(value='');" (keyup)="onInput($event)" />
          <input type="number" class="otp-input-box" formControlName="otp2"
            onKeyPress="if(this.value.length ==1 ) return false;" id="otp2" [maxLength]="1" tabindex="2" min="0"
            oninput="validity.valid||(value='');" (keyup)="onInput($event)" />
          <input type="number" class="otp-input-box" formControlName="otp3"
            onKeyPress="if(this.value.length ==1 ) return false;" id="otp3" [maxLength]="1" tabindex="3" min="0"
            oninput="validity.valid||(value='');" (keyup)="onInput($event)" />
          <input type="number" class="otp-input-box" formControlName="otp4"
            onKeyPress="if(this.value.length ==1 ) return false;" id="otp4" [maxLength]="1" tabindex="4" min="0"
            oninput="validity.valid||(value='');" (keyup)="onInput($event)" />
        </div>
      </div>
      <div class="reg-row mt-20px">
        <div class="code-expire-container reg-row" test_id="codeExpiresText">
          <bb-content-html [html]="loginWidgetItem.forgotUserNameVerifyOTP.codeExpiresText"></bb-content-html>
        </div>
      </div>
      <div class="reg-row" role="row">
        <div class="otp-margin-left text-center">
          <button id="verify-otp-btn" bbButton type="button" aria-label="verify-otp-btn" class="wss-button btn-full-width btn-no-bottom-margin"
            (click)="submit()" [disabled]="disabled" test_id="verifyButtonText">
            {{loginWidgetItem.forgotUserNameVerifyOTP.verifyButtonText}}
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="register-link no-para-margin justify-content-center" id="needHelpLinkContent"
    test_id="loginWidgetItemneedHelpLinkContent">
    <bb-content-html [html]="loginWidgetItem.forgotUserNameVerifyOTP.needHelpLink"></bb-content-html>
  </div>
</div>