import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from "@angular/router";
import { RoutableWidget, RouterService } from "@backbase/foundation-ang/core";
import { from, Observable } from 'rxjs';
import { PaymentDebitCardComponent } from "../src/components/payment-debit-card/payment-debit-card.component";
import { PaymentMenuComponent } from "../src/components/payment-menu/payment-menu.component";
import { PaymentResultComponent } from "../src/components/payment-result/payment-result.component"
import { PaymentSettlementComponent } from "../src/components/payment-settlement/payment-settlement.component";
import { DirectDebitComponent } from './components/direct-debit/direct-debit.component';
import { AddDirectDebitComponent } from './components/add-direct-debit/add-direct-debit.component';
import { DirectDebitResultComponent } from './components/direct-debit-result/direct-debit-result.component';
import { DatastoreService } from '../../service/datastore.service';
import { ChangePaymentComponent } from './components/change-payment/change-payment.component';
import { ConfirmChangePaymentComponent } from './components/confirm-change-payment/confirm-change-payment.component';
import { ChangePaymentResultComponent } from './components/change-payment-result/change-payment-result.component';
import { ServiceExceptionComponent } from './components/service-exception/service-exception.component';
import { OverPaymentComponent } from './components/over-payment/over-payment.component';
import { OverPaymentOptionsComponent } from './components/over-payment-options/over-payment-options.component';
import { MessageService } from '@wss/service/message.service';
import { ConfirmBillingAddressComponent } from './components/confirm-billing-address/confirm-billing-address.component';
import { EnterBillingAddressComponent } from './components/enter-billing-address/enter-billing-address.component';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { ContentService } from '@backbase/universal-ang/content';
import { PaymentSelectionComponent } from './components/payment-selection/payment-selection.component';

@Component({
  selector: 'bb-payment-widget',
  templateUrl: 'payment-widget.component.html',
  providers: [RouterService, ContentService]
})
@RoutableWidget({
  routes: [
    { path: '', redirectTo: 'payment-menu', pathMatch: 'full' },
    { path: 'payment-menu', component: PaymentMenuComponent },
    { path: 'payment-selection', component: PaymentSelectionComponent},
    { path: 'payment-debit-card', component: PaymentDebitCardComponent },
    { path: 'payment-result-process', component: PaymentResultComponent },
    { path: 'payment-settlement', component: PaymentSettlementComponent },
    { path: 'direct-debit', component: DirectDebitComponent },
    { path: 'add-direct-debit', component: AddDirectDebitComponent },
    { path: 'direct-debit-result', component: DirectDebitResultComponent },
    { path: 'change-payment', component: ChangePaymentComponent },
    { path: 'confirm-change-payment', component: ConfirmChangePaymentComponent },
    { path: 'change-payment-result', component: ChangePaymentResultComponent },
    { path: 'service-exception', component: ServiceExceptionComponent },
    { path: 'over-payment', component: OverPaymentComponent },
    { path: 'over-payment-options', component: OverPaymentOptionsComponent },
    { path: 'enter-billing-address', component: EnterBillingAddressComponent },
    { path: 'confirm-billing-address', component: ConfirmBillingAddressComponent }
  ],
})
export class PaymentWidgetComponent implements OnInit {
  currentStep: number = 0;
  paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
  stepperLabel: any = [];

  constructor(
    private router: Router,
    public datastoreService: DatastoreService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly bbContentService: ContentService,
  ) { }

  ngOnInit(): void {
    this.datastoreService.sessionInvalid();
    this.paymentsWidgetItem$.subscribe((paymentsWidgetItem: any) => {
      this.stepperLabel = [paymentsWidgetItem.paymentDebitCard.stepperLabelOne, paymentsWidgetItem.paymentDebitCard.stepperLabelTwo, paymentsWidgetItem.paymentDebitCard.stepperLabelThree];
      this.changeDetectorRef.detectChanges();
    });
    this.messageService.setActiveNav("Payments Nav Selection");
    this.updateStepper();
  }

  updateStepper() {
    this.messageService.getCurrentStep().subscribe(step => {
      this.currentStep = step;
      this.datastoreService.setStepperStep(step);
      this.changeDetectorRef.detectChanges();
    });
  }
}
