
<bb-navigation-stepper-widget>
  <ng-template bbNavigationStepperContainerCustomizable let-hostRef let-links="context">
    <div  *ngIf="this.datastoreService.getBudgetFormStatus == 'Draft' || this.datastoreService.getBudgetFormStatus == '' ">
    <bb-stepper-ui>
      <bb-stepper-step-ui
        #routerLinkActive="bbRouterLinkActive"
        *ngFor="let link of removeLastEntry(links) let i = index"
        bbRouterLinkActive
        [isChecked]="i<=this.datastoreService.getCurrentPageBudgetForm"
        aria-current="link"
        [bbRouterLink]="link.route"
        [isActive]="hostRef.isStepActive(i)"
        [label]="link.title"
        [attr.dataActive]="hostRef.setLinkActive(routerLinkActive.isActive, i)"
      ></bb-stepper-step-ui>
    </bb-stepper-ui>
  </div>
  </ng-template>
</bb-navigation-stepper-widget>
