import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { RouterService } from '@backbase/foundation-ang/core';
import { appConfig, RuntimeEnv } from '@wss/config/wss-app-constants';
import { PrivacyPolicyItem } from '@wss/model/content/static-pages';
import { injectBrandDetailIntoPage } from '@wss/common/util/util';
import { FooterWidgetItem } from '@wss/model/content/footer-widget';

@Component({
  selector: 'bb-privacy-policy-widget',
  templateUrl: './privacy-policy-widget.component.html',
  providers: [ ContentService ]
})
export class PrivacyPolicyWidgetComponent implements OnInit, OnDestroy {
  privacyPolicyWidgetItem$: Observable<PrivacyPolicyItem|undefined> = this.bbContentService.getContent<PrivacyPolicyItem>('privacyPolicyWidgetItem');
  privacyPolicyWidgetItem: PrivacyPolicyItem = new PrivacyPolicyItem();
  footerWidgetItem$: Observable<FooterWidgetItem|undefined> = this.bbContentService.getContent<FooterWidgetItem>('footerWidgetItem');
  footerWidgetItem:FooterWidgetItem = new FooterWidgetItem();
  portalName: any;

  constructor(
    private readonly bbContentService: ContentService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.renderer.addClass(document.body, 'no-nav-bar');
    this.renderer.addClass(document.body, 'static-page');
  }
  
  ngOnInit(): void {
    this.getPortalName();
    this.footerWidgetItem$.subscribe((footerWidgetItem: FooterWidgetItem| undefined)=> {
      if(footerWidgetItem){
        this.footerWidgetItem = footerWidgetItem;
        this.addBrandDetails();
      }
      this.changeDetectorRef.detectChanges();
    });
    this.privacyPolicyWidgetItem$.subscribe((privacyPolicyWidgetItem: PrivacyPolicyItem| undefined)=> {
      if(privacyPolicyWidgetItem){
        this.privacyPolicyWidgetItem = privacyPolicyWidgetItem;
        this.addBrandDetails();
      }
      this.changeDetectorRef.detectChanges();
    })
  }

  addBrandDetails() {
    if (this.privacyPolicyWidgetItem && this.footerWidgetItem.footerDetails){
      this.privacyPolicyWidgetItem = injectBrandDetailIntoPage(this.privacyPolicyWidgetItem, this.footerWidgetItem.footerDetails.brandContactDetails);
    }
  }

  getPortalName() {
    if (RuntimeEnv.backbase) {
      this.portalName = window.BB.config.portalName;
    }
    this.changeDetectorRef.detectChanges();
  }

  backToTop() {
    window.scroll(0, 0);
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'no-nav-bar');
    this.renderer.removeClass(document.body, 'static-page');
  }
}
