import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { injectBrandDetailIntoPage } from '@wss/common/util/util';
import { HelpCentreItem } from '@wss/model/helpCentre';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Observable } from 'rxjs';
import { MessageService } from '@wss/service/message.service';

@Component({
  selector: 'bb-make-payment',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './make-payment.component.html',
  styles: [],
  providers: [ContentService]
})
export class MakePaymentComponent implements OnInit {

  helpCentreWidgetItem$: Observable<HelpCentreItem | undefined> = this.bbContentService.getContent<HelpCentreItem>('helpCentreWidgetItem');
  helpCentreWidgetItem: any = new HelpCentreItem();

  constructor(
    private readonly bbContentService: ContentService,
    private changeDetectorRef: ChangeDetectorRef,
    private tealiumService: TealiumUtagService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.helpCentreWidgetItem$.subscribe((pageItem: any) => {
      this.helpCentreWidgetItem = pageItem;
      this.helpCentreWidgetItem.helpCentreMakePayment.myPaymentsAccordionContent.forEach((element: any) => {
        element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
      });
      this.helpCentreWidgetItem.helpCentreMakePayment.payDebitCardAccordionContent.forEach((element: any) => {
        element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
      });
      this.helpCentreWidgetItem.helpCentreMakePayment.directDebitAccordionContent.forEach((element: any) => {
        element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
      });
      this.helpCentreWidgetItem.helpCentreMakePayment.overpaymentsAccordionContent?.forEach((element: any) => {
        element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
      });
      this.helpCentreWidgetItem.helpCentreMakePayment.settlementsAccordionContent.forEach((element: any) => {
        element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
      });
      this.helpCentreWidgetItem.helpCentreMakePayment.accountCompletionAccordionContent.forEach((element: any) => {
        element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
      });
      this.messageService.setActiveNav("Help centre internal");
      localStorage.removeItem("makePaymentFAQsRedirection");
      this.changeDetectorRef.detectChanges();
    });

      this.tealiumService.view(
        {
          JourneyName: 'Help Centre',
          JourneyStepName: 'Make Payment',
          CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
        }
      );
    }
}
