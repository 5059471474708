<section class="cta-screen text-center col-md-7 col-12 p-40px" *ngIf="brgItem$ | async as brgItem">
    <ng-container *ngIf="getErrorType">
        <div class="acc-locked-icon mb-20px mt-40px"></div>    
        <h1 class="cta-screen__title font-size-18 mb-20px">{{brgItem.balloonRefinanceGetdetailsConnectionFailure.titleServer}}</h1>
        <div class="mb-30px">
            <bb-content-html [html]="brgItem.balloonRefinanceGetdetailsConnectionFailure.bodyTextServer"></bb-content-html>
            <span class="text-semibold">{{brgItem.balloonRefinanceGetdetailsConnectionFailure.textBeforeLastSubmitDate}} {{lastDateOfDecision | date: 'dd MMM yyyy'}} 
                {{brgItem.balloonRefinanceGetdetailsConnectionFailure.textAfterLastSubmitDate}}</span>
        </div>
        <div class="d-flex flex-column align-items-center">
            <button id="wss-brg-connection-failure-exit-to-home-btn" class="wss-button mt-0" test_id="primaryButtonServer" routerLink="/myaccount" *ngIf="brgItem.balloonRefinanceGetdetailsConnectionFailure.primaryButtonTextServer">
                {{brgItem.balloonRefinanceGetdetailsConnectionFailure.primaryButtonTextServer}}
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="!getErrorType">
        <div class="timeout-icon mb-20px mt-40px"></div>    
        <h1 class="cta-screen__title font-size-18 mb-20px">{{brgItem.balloonRefinanceGetdetailsConnectionFailure.title}}</h1>
        <div class="mb-30px">
            <bb-content-html [html]="brgItem.balloonRefinanceGetdetailsConnectionFailure.bodyText"></bb-content-html>
            <span class="text-semibold">{{brgItem.balloonRefinanceGetdetailsConnectionFailure.textBeforeLastSubmitDate}} {{lastDateOfDecision | date: 'dd MMM yyyy'}} 
                {{brgItem.balloonRefinanceGetdetailsConnectionFailure.textAfterLastSubmitDate}}</span>
        </div>
        <div class="d-flex flex-column align-items-center">
            <button id="wss-brg-connection-failure-try-again-btn" class="wss-button mt-0" test_id="primaryButton" (click)="tryAgain(loaderModal)" *ngIf="brgItem.balloonRefinanceGetdetailsConnectionFailure.primaryButtonText">
                {{brgItem.balloonRefinanceGetdetailsConnectionFailure.primaryButtonText}}
            </button>
        </div>
    </ng-container> 
    <ng-template #loaderModal let-modal>
        <div class="modal-header horse-logo pt-40px">
            <span class="model-logo-img"></span>
        </div>
        <div class="modal-body loader-modal pb-40px" role="dialog">
            <div class="loader"></div>
            <div class="text-center">
                <bb-content-html [html]="brgItem.balloonRefinanceGetdetailsSummary.loaderContent"></bb-content-html>
            </div>
        </div>
    </ng-template>
</section>