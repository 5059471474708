import { Component, OnInit, Input } from '@angular/core';
import { Contract } from '../../model/account-summary';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { RouterService } from '@backbase/foundation-ang/core';
import { MyAgreement } from '@wss/model/content/dashboard';

@Component({
  selector: 'bb-wss-contract',
  templateUrl: 'contract.component.html'
})
export class ContractComponent implements OnInit {
  @Input() svgInfoIcon: string = '';
  @Input() contractData: Contract = new Contract();
  @Input() iconColor: any;

  @Input() myAgreementContent: MyAgreement = new MyAgreement();
  popoverSelf: any;
  popoverArrowStyle: string = '';
  tooltipCloseStyle: string = "font-size: 18px; margin-top: -10px !important;";

  constructor(private modalService: NgbModal, config: NgbPopoverConfig, private router: RouterService) {
    config.placement = 'bottom';
    config.container = "body";
  }

  ngOnInit(): void {
  }

  openModal(modalContent: any) {
    this.modalService.open(modalContent, { centered: true, windowClass: 'account-summary-modal' });
  }

  getInTouchLink() {
    this.router.navigate(['get-in-touch']);
    window.scroll(0, 0);
  }

  openPopover(popover: any, event: MouseEvent) {
    popover.open();
    this.popoverSelf = popover;
  }

  closePopover() {
    this.popoverSelf.close();
  }

}