<div class="reg-container margin-questions" *ngIf="registrationWidgetItem$ | async as registrationWidgetItem" test_id="pageHeaderText">
  <div class="reg-row" role="alert" *ngIf="serviceError">         
    <bb-wss-banner-message [errorCode]="errorCode"></bb-wss-banner-message>
  </div>
  <bb-content-html [html]="registrationWidgetItem.passcodeError.pageHeaderText"></bb-content-html>
  <div class="text-center">
    <button id="bb-button-to" bbButton type="submit" aria-label="Favourite" (click)="submit()"
      [className]="'wss-button'" test_id="resendCodeButtonText">{{registrationWidgetItem.passcodeError.resendCodeButtonText}}
    </button>
  </div>
  <div class="need-help-link text-center mb-25px" test_id="needHelpLink">
    <bb-content-html [html]="registrationWidgetItem.passcodeError.needHelpLink"></bb-content-html>
  </div>
</div>
