import { BrandContactDetailsItem } from "../content/common/brand-contant-details";
import { BalloonRefinanceGetAQuote } from "../balloon-refinance/balloon-refinance";

export class BalloonRefinanceGetdetailsItem {

    balloonRefinanceGetdetailsTitle = new BalloonRefinanceGetdetailsTitleItem();
    balloonRefinanceGetdetailsVehicle = new BalloonRefinanceGetdetailsVehicleItem();
    balloonRefinanceGetdetailsAbout = new BalloonRefinanceGetdetailsAboutItem();
    balloonRefinanceGetdetailsEmployment = new BalloonRefinanceGetdetailsEmploymentItem();
    balloonRefinanceGetdetailsFinance = new BalloonRefinanceGetdetailsFinanceItem();
    balloonRefinanceGetdetailsBank = new BalloonRefinanceGetdetailsBankItem();
    balloonRefinanceGetdetailsSummary = new BalloonRefinanceGetDetailsSummaryItem();
    balloonRefinanceGetdetailsNavBtns = new CommonNavigation();
    balloonRefinanceGetdetailsConnectionFailure = new BalloonRefinanceGetDetailsConnectionFailure();
    balloonRefinanceGetdetailsConnectionFailureRedirect = new BalloonRefinanceGetDetailsConnectionFailureRedirect();
    balloonRefinanceGetdetailsReferral = new BalloonRefinanceGetdetailsReferral();
    balloonRefinanceGetdetailsAccept = new BalloonRefinanceGetdetailsAccept();
    balloonRefinanceGetDetailsDeclined = new BalloonRefinanceGetDetailsDeclined();
    brgPostDeclined = new BrgPostDecline();
    brandContactDetails = new BrandContactDetailsItem();
    brgModAgNextStep = new BrgModAgNextStep();
    balloonRefinanceCommonDetails = new BalloonRefinanceCommonDetailsItem()
}

interface WSSAccordion {
    title: string,
    content: string
}

export interface creditDealerPlan {
    carDealer: string,
    bikeDealer: string
}

class BalloonRefinanceGetdetailsTitleItem {
    step1Title: string = '';
    step2Title: string = '';
    step3Title: string = '';
    step4Title: string = '';
    step5Title: string = '';
    page1Title: string = '';
    page2Title: string = '';
    page3Title: string = '';
    page4Title: string = '';
    page5Title: string = ''; 
    inforText: string = '';
    errorMessageCommon: string = '';
    compareDOBDateErr: string = '';
    payloadErr: string = '';
    pleaseSelect: string = '';
    alphanumericAndHypenError: string = '';
    alphanumericHypenApostropheError: string = '';
    alphanumericError: string = '';
    alphanumericHypenForwardSlashError: string = '';
    alphanumericHypenApostropheForwardSlashError: string = '';
    alphabeticError: string = '';
    alphabeticHypenBackslashError: string = '';
    alphabeticHypenError: string = '';
    alphabeticHypenApostropheError: string = '';
    noHouseNameOrHouseNumberErrorMessage: string = '';
    employerNameInvalidCharsError: string = '';
}

class BalloonRefinanceGetdetailsVehicleItem {
    textAfterPageTitle: string = '';
    sectionAccountTitle: string = '';
    vehicleMake: string = '';
    brvInformationMake: string = '';
    vehicleModel: string = '';
    brvInformationModel: string = '';
    vehicleRegistration: string = '';
    extraDetailsHeader: string = '';
    currentMileageQuestion: string = '';
    brvInformationMileage: string = '';
    currentMileageErrorMessage: string = '';
    mileagePlaceholder: string = '';
    vehicleUseQuestion: string = '';
    brvInformationVehicleUse: string = '';
    vehicleUseErrorMessage: string = '';
    brvInformationReg: string = '';
    textGetInTouch: string = ''; 
    linkGetInTouch: string = '';
}

class BalloonRefinanceGetdetailsAboutItem {
    textAfterPageTitle: string = '';
    sectionPersonalTitle: string = '';
    lblTitle: string = '';
    braInformationTitle: string = '';
    lblFirstName: string = '';
    braInformationFirstName: string = '';
    lblSurname: string = '';
    braInformationSurname: string = '';
    lblDOB: string = '';
    lblDayOfBirth: string = '';
    lblMonthOfBirth: string = '';
    lblYearOfBirth: string = '';
    braInformationDOB: string = '';
    getInTouch: string = '';
    sectionExtraTitle: string = '';
    lblNationality: string = '';
    braInformationNationality: string = '';
    placeholderNationality: string = '';
    nationalityError: string = '';
    lblMarital: string = '';
    braInformationMarital: string = '';
    placeholderMarital: string = '';
    maritalError: string = '';
    maritalOptionMarried: string = '';
    maritalOptionCoHabiting: string = '';
    maritalOptionSingle: string = '';
    maritalOptionSeparated: string = '';
    maritalOptionDivorced: string = '';
    maritalOptionWidowed: string = '';
    lblDependants: string = '';
    braInformationDependants: string = '';
    placeholderDependants: string = '';
    summaryDependants: string = '';
    dependantsError: string = '';
    lblResidential: string = '';
    braInformationResidential: string = '';
    placeholderResidential: string = '';
    residentialOptionsOwner: string = '';
    residentialOptionsTenant: string = '';
    residentialOptionsParents: string = '';
    residentialOptionsOther: string = '';
    residentialError: string = '';
    addressDetailsTitle: string = '';
    textAfterAddressTitle: string = '';
    lblCurrentAddress: string = '';
    braInforCurrentAddress: string = '';
    lblLiveAddress: string = '';
    braInforLiveAddress: string = '';
    liveAddressError: string = '';
    lblMoveLive: string = '';
    braInforMoveLive: string = '';
    moveLiveMonthError: string = '';
    moveLiveYearError: string = '';
    successTextTerm: string = '';
    warningTextTerm: string = '';
    prevAddressLbl: string = '';
    braInforPrevAddress: string = '';
    prevAddressError: string = '';
    manualAddress: string = '';
    monthDefaultText: string = '';
    yearDefaultText: string = '';
    currentNewAddressLbl: string = '';
    braInforCurrentNewAddress: string = '';
    moveAddressLbl: string = '';
    braInforMoveAddress: string = '';
    warningYearsTerm: string = '';
    searchPostCoderrorMessage: string = '';
    searchByPC: string = '';
    warningLimit: string = '';
    dateSelectError: string = '';
    compareCurrentDateError: string = '';
    confirmPrevAddressLbl: string = '';
    lblMoveCurrent: string = '';
    braInforMoveCurrent: string = '';
    lblMoveWas: string = '';
    InforAddressPost: string = '';
    currentAddressError: string = '';
    lblConfirmAddress: string = '';
    braInforConfirmAddress: string = '';
    moveCmnLbl: string = '';
    braInforMoveCmn: string = '';
    updateAddressError: string = '';
    fullAddError: string = '';
    searchAddressButtonError: string = '';
    addressErrorText: string = '';
}

class BalloonRefinanceGetdetailsEmploymentItem {
    textAfterPageTitle: string = '';
    sectionEmploymentTitle: string = '';
    lblCurrentEmployment: string = '';
    breInforCurrentEmployment: string = '';
    currentEmploymentYes: string = '';
    currentEmploymentNo: string = '';
    currentEmploymentEmployed: string = '';
    currentEmploymentUnemployed: string = '';
    currentEmploymentError: string = '';
    lblSituationType: string = '';
    breInforSituation: string = '';
    situtaionTypeStudent: string = '';
    situtaionTypeHome: string = '';
    situtaionTypeRetired: string = '';
    situtaionTypeUnemploy: string = '';
    situtaionTypeDisabilityBenefits: string = '';
    situtaionTypeError: string = '';
    lblEmploymentType: string = '';
    breInforType: string = '';
    employmentTypePermanant: string = '';
    employmenetTypeTemporary: string = '';
    employmentTypeError: string = '';
    lblEmploymentTenure: string = '';
    breInforTenure: string = '';
    employmentTenureHalfTime: string = '';
    employmentTenureFullTime: string = '';
    employmentTenureError: string = '';
    lblEmploymentSector: string = '';
    breInforSector: string = '';
    employmentSectorPrivate: string = '';
    employmentSectorGovernment: string = '';
    employmentSectorSelfEmployed: string = '';
    empoymentSectorMilitary: string = '';
    employmentSectorError: string = '';
    lblOccupation: string = '';
    breInforOccupation: string = '';
    occupationPlaceholderText: string = '';
    occupationError: string = '';
    employerDetailsHeader: string = '';
    employerName: string = '';
    employerNamePlaceholderText = '';
    brvInfoEmployerName: string = '';
    employerNameError: string = '';
    employmentStartQuestion: string = '';
    day: string = '';
    month: string = '';
    year: string = '';
    brvInfoEmploymentStartDate: string = '';
    employmentStartDateErrorMessage: string = '';
    employerAddressQuestion: string = '';
    breInfoEmployerAddress: string = '';
    buildingName: string = '';
    buildingNamePlaceHolderText: string = '';
    buildingNameErrorMessage: string = '';
    postCode: string = '';
    postCodePlaceholderText: string = '';
    postCodeErrorMessage: string = '';
    invalidPostcodeErrorMessage: string = '';
    searchButtonText: string = '';
    searchAddressErrorMessage: string = '';
    enterFullAddress: string = '';
    houseNumber: string = '';
    houseNumberPlaceholderText: string = '';
    houseNumberErrorMessage: string = '';
    flatNumber: string = '';
    flatNumberPlaceholderText: string = '';
    flatNumberErrorMessage: string = '';
    houseNumberFlatNumberErrorMessage: string = '';
    street: string = '';
    streetPlaceholderText: string = '';
    streetErrorMessage: string = '';
    district: string = '';
    districtPlaceholderText: string = ''
    districtErrorMessage: string = '';
    town: string = '';
    townPlaceholderText: string = '';
    townErrorMessage: string = '';
    county: string = '';
    countyPlaceholderText: string = ''
    countyErrorMessage: string = '';
    youCouldAlsoText: string = '';
    searchByPostcode: string = '';
}

class BalloonRefinanceGetdetailsFinanceItem {
    textAfterPageTitle: string = '';
    grossIncome: string = '';
    brfInformationIncome: string = '';
    mortgageRental: string = '';
    brfInformationMortgage: string = '';
    expenseTotal: string = '';
    brfInformationExpense: string = '';
    expectedChange: string = '';
    brfInformationExpected: string = '';
    expectNone: string = '';
    expectRetire: string = '';
    expectRedund: string = '';
    expectReduct: string = '';
    expectCareer: string = '';
    expectFamily: string = '';
    expectOther: string = '';    
}

class BalloonRefinanceGetdetailsBankItem {
    textAfterPageTitle: string = '';
    lblAccountName: string = '';
    brbInformationAccountName: string = '';
    accountNamePlaceholder: string = '';
    accountNameError: string = '';
    accountNameInvalidCharsError: string = '';
    bankAccountWarning: string = '';
    bankAccountSuccess: string = '';
    lblSortCode: string = '';
    brbInformationSortCode: string = '';
    sortCodePlaceholder: string = '';
    sortCodeError: string = '';
    lblAccountNumber: string = '';
    brbInformationAccountNumber: string = '';
    accountNumberPlaceholder: string = '';
    accountNumberError: string = '';
    lblLengthOfAccount: string = '';
    brbInformationLengthOfAccount: string = '';
    lblYearsOfAccount: string = '';
    placeholderYearsOfAccount: string = '';
    lblMonthsOfAccount: string = '';
    placeholderMonthsOfAccount: string = '';
    lengthOfAccountError: string = '';
}

class BalloonRefinanceGetDetailsSummaryItem {
    pagetitle: string = '';
    textBelowHeader: string = '';
    reviewYourAnswersHeader: string = '';
    quoteAccordionHeader: string = '';
    quoteTitle: string = '';
    quoteNewDetailsHeader: string = "";
    quoteAgreementTitle: string = "";
    quoteAgreementText: string = "";
    quoteAgreementPopoverText: string = "";
    quoteMonthlyTitle: string = "";
    quoteMonthPopoverText: string = "";
    quoteTermTitle: string = "";
    quoteTermText: string = "";
    quoteTermPopoverText: string = "";
    quoteAPRTitle: string = "";
    quoteAPRPercentage: number = 0;
    quoteAPRPopoverText: string = "";
    quoteOptionalFinalPaymentTitle: string = "";
    quoteRefinanceAmountTitle: string = "";
    quoteRefinanceAmountPopoverText: string = "";
    quoteTotalInterestTitle: string = "";
    quoteTotalInterestPopoverText: string = "";
    quoteTotalAmountTitle: string = "";
    quoteTotalAmountPopoverText: string = "";
    quoteOptionFeeText: string = "";
    yourVehicleAccordionHeader: string = '';
    vehicleMakeLabel: string = '';
    modelLabel: string = '';
    registrationLabel: string = '';
    mileageLabel: string = '';
    vehicleUseLabel: string = '';
    editButtonText: string = '';
    aboutYouAccordionHeader: string= '';
    personalDetailsHeader: string = '';
    fullNameLabel: string = '';
    dobLabel: string = '';
    nationalityLabel: string = '';
    maritialstatutsLabel: string = '';
    dependantsLabel: string = '';
    residentialStatusLabel: string = '';
    currentAddressHeader: string = '';
    previousAddressHeader: string = '';
    moveInDateLabel: string = '';
    
    employmentAccordionHeader: string = '';
    employmentStatusLabel: string = '';
    unemploymedSituationLabel: string = '';
    contractTypeLabel: string = '';
    timeLabel: string = '';
    sectorLabel: string = '';
    occupationLabel: string = '';
    employerLabel: string = '';
    startDateLabel: string = '';
    employerAddressLabel: string = '';
    financeAccordionHeader: string = '';
    annualIncomeLabel: string = '';
    shareOfMortgageLabel: string = '';
    dependantCareCostLabel: string = '';
    changeInCircumstancesLabel: string = '';
    bankAccountAccordionHeader: string = '';
    accountHolderLabel: string = '';
    sortCodeLabel: string = '';
    accountNumberLabel: string = '';
    accountHeldForLabel: string = '';
    aboutCreditSearchHeader: string = '';
    creditSearchDetails: string = '';
    doYouAgreeText: string = '';
    checkboxText: string = '';
    confirmationCheckboxError: string = '';
    loaderContent: string = '';
    callbackUrlText: string = '';
    modagFailedErrorText: string = '';
}

class CommonNavigation {
    primaryButtonText: string = "";
    secondaryButtonText: string = "";
    linkText: string = "";
    modalHeader: string = "";
    modalContentFirstPara: string = "";
    modalContentFirstParaAgreementPage: string = "";
    modalContentSecondParaBeforeDate: string = "";
    modalContentSecondParaAfterDate: string = "";
    modalContentSecondParaAfterDateAgreementPage: string = "";
    modalContentConfirm: string = "";
    modalPrimaryButtonText: string = "";
    modalSecondaryButtonText: string = "";
    cancelButtonText: string = "";
    saveAndReviewButtonText: string = "";
    submitButtonText: string = "";
    startButtonText: string = "";
    iDontWantToProceedButtonText: string = "";
    areYouSureExitHeader: string = "";
    exitQuoteLostPara: string = "";
    exitToEocOptionsPageButtonText: string = "";
    backToApplicationButtonText: string = "";
}

export interface BalloonRefinanceStep {
    current_page: number;
}

export interface Address{
    county: string | null,
    district: string | null,
    flat_number: any | null,
    house_name: string | null,
    house_number: any | null,
    post_code: string | null,
    post_town: string | null,
    street_name: string | null
}

export interface ProposalRequest {
    customer_id: string | null,
    account_number: string | null,
    palis_proposal_number: string | null,
    proposal_date: string | null,
    proposal_updated: string | null,
    proposal_status: string | null,
    replace_existing_account: boolean | null,
    proposed_term: number | null,
    vehicle_use: number | null,
    mileage: number | null,
    resident: string | null,
    marital: string | null,
    change_in_circumstances: number | null,
    perm_employee: boolean | null,
    full_time: boolean | null,
    employment_type: number | null,
    occupation: string | null,
    employer_address_1: string | null,
    employer_address_2: string | null,
    employer_address_3: string | null,
    employer_address_4: string | null,
    gross_annual_income: number | null,
    mortgage_rental_expenditure: number | null,
    other_expenditure: number | null,
    bank_sort_code: string | null,
    bank_account_number: string | null,
    bank_account_name: string | null,
    years_with_bank: number | null,
    months_with_bank: number | null,
    months_at_employment: number | null,
    years_at_employment: number | null,
    employer_name: string | null,
    county: string | null,
    district: string | null,
    flat_number: string | null,
    house_name: string | null,
    house_number: number | string | null,
    postcode: string | null,
    street: string | null,
    town: string | null,
    nationality: string | null,
    no_of_dependants: any | null,
    proposal_id: any | null,
    palisCustomerId: any | null,
    IsDecision_Changed: boolean | null,
    IsRejectedByCustomer: boolean | null
}

export interface addressList {
    id: number,
    address_type: string,
    account_number: string,
    moving_in_date: string,
    months_at_address: number,
    years_at_address: number,
    is_address_updated: boolean | null,
    address_detail: addressDetails;
}

export interface addressSummaryList {
    address_number: number,
    house_name: string | null,
    house_number: string | null,
    flat_number: string | null,
    street: string | null,
    district: string | null,
    town: string | null,
    postcode: string | null,
    county: string | null,
    overseas: boolean,
    months_at_address: number,
    years_at_address: number
}

export interface addressDetails {
    house_name: string | null,
    house_number: string | null,
    flat_number: string | null,
    street_name: string | null,
    district: string | null,
    post_town: string | null,
    county: string | null,
    post_code: string | null
}

export class addressClass implements addressList {
    id = 0;
    address_type = 'residence';
    account_number = '';
    moving_in_date = '';
    months_at_address = 0;
    years_at_address = 0;
    is_address_updated = null;
    address_detail = new addressDetailsClass();
}

export class addressDetailsClass implements addressDetails {
    house_name = '';
    house_number = '';
    flat_number = '';
    street_name = '';
    district = '';
    post_town = '';
    county = '';
    post_code = '';
}

export class addressSummaryClass implements addressSummaryList {
    address_number = 0;
    house_name = '';
    house_number = '';
    flat_number = '';
    street = '';
    district = '';
    town = '';
    postcode = '';
    county = '';
    overseas = false;
    months_at_address = 0;
    years_at_address = 0;
}

export class BalloonRefinanceGetDetailsConnectionFailure {
    title: string = "";
    titleServer: string = "";
    bodyText: string = "";
    bodyTextServer: string = "";
    primaryButtonText: string = "";
    primaryButtonTextServer: string = "";
    textBeforeLastSubmitDate: string = "";
    textAfterLastSubmitDate: string = "";
}

export class BalloonRefinanceGetdetailsReferral {
    pageHeader: string = '';
    pageSubheader: string = '';
    ourProgressHeader: string = '';
    stepOneText: string = '';
    stepTwoText: string = '';
    stepThreeText: string = '';
    contentBelowOurProgress: string = '';
    backToAccountButtonText: string = '';
    needHelpHeader: string = '';
    needHelpText: string = '';
    giveUsACallText: string = '';
}

export class BalloonRefinanceGetdetailsAccept {
    pageTitle: string = '';
    textAfterPageTitle: string = '';
    summaryTitle: string = '';
    totalAmount: string = '';
    newRental: string = '';
    totalTerm: string = '';
    monthsTxt: string = '';
    agreementType: string = '';
    agreementName: string = '';
    aprText: string = '';
    paymentSchedule: string = '';
    firstPaymentStart: string = '';
    firstPaymentEnd: string = '';
    secondPaymentStart: string = '';
    secondPaymentMiddle: string = '';
    secondPaymentEnd: string = '';
    finalPaymentStart: string = '';
    finalPaymentEnd: string = '';  
    accordionHeader: string = '';
    PCEHeading: string = '';
    PCETextToReview: string = '';
    PCETextHasReviewed: string = '';
    PCENotes: string = '';
    PCEIntroTitle: string = '';
    PCEIntroTxtPara1Start: string = '';
    PCEIntroTxtPara1End: string = '';
    PCEIntroTxtFollowParas: string = '';
    PCEModagTitle: string = '';
    PCEModagText: string = '';
    PCEGoodsTitle: string = '';
    PCEGoodsTextHeader: string = '';
    PCEGoodsText: string = '';
    PCEGoodsTextMotorbike: string = '';
    PCEFailureTitle: string = '';
    PCEFailureTextHeader: string = '';
    PCEFailureText: string = '';
    PCEPCCITitle: string = '';
    PCEPCCIText: string = '';
    PCEFinalTitle: string = '';
    PCEFinalPara1Start: string = '';
    PCEOn: string = '';
    PCEFinalPara1End: string = '';
    PCEFinalFollowPara: string = '';
    docRefPCE: string = '';
    modagHeading: string = '';
    modagTextToReview: string = '';
    modagTextHasReviewed: string = '';
    acceptButton: string = '';
    doYouAgreeText: string = '';
    checkboxText: string = '';
    confirmationCheckboxError: string = '';
    getInTouchText: string = '';
    pcciContactTitle: string = '';
    proposalNo: string = '';
    creditor: string = '';
    address: string = '';
    addressFullStop: string = '';
    creditorAddress: string = '';
    PCCIHeading: string = '';
    PCCITextToReview: string = '';
    PCCITextHasReviewed: string = '';
    pcciKeyTitle: string = '';
    pcciTypeCreditTitle: string = '';
    pcciTypeCreditTxt: string = '';
    pcciAmountCreditTitle: string = '';
    pcciAmountCreditTxt: string = '';
    hirePurchaseTitle: string = '';
    pcciDurationCreditTitle: string = '';
    pcciRepaymentsTitle: string = '';
    pcciAmountPayTitle: string = '';
    pcciAmountPayTxt: string = '';
    pcciRepayStart1: string = '';
    pcciRepayStart2: string = '';
    pcciRepayEnd2: string = '';
    pcciRepayStart3: string = '';
    pcciCostCreditTitle: string = '';
    pcciInterestCreditTitle: string = '';
    pcciInterestCreditTxt: string = '';
    pcciAPRTitle: string = '';
    pcciAPRTxt: string = '';
    pcciAPRTxtExtra: string = '';
    pcciRelatedCostTitle: string = '';
    pcciRelatedCostTxt: string = '';
    pcciMissingPaymentsTitle: string = '';
    pcciMissingPaymentsTxt: string = '';
    pcciLegalAspectsTitle: string = '';
    pcciRightWithdrawalTitle: string = '';
    pcciRightWithdrawalTxt: string = '';
    pcciEarlyRepaymentTitle: string = '';
    pcciEarlyRepaymentTxt: string = '';
    pcciCreditReferenceTitle: string = '';
    pcciCreditReferenceTxt: string = '';
    pcciDraftCreditTitle: string = '';
    pcciDraftCreditTxt: string = '';
    docRefPCCI: string = '';
    nameOfBrand: string = "";
    agreementNumber: string = "";
    regulationDisclaimer: string = "";
    creditorNameAndAddress: string = "";
    creator: string = "";
    customerNameAndAddress: string = "";
    customerName: string = "";
    customerAddress: string = "";
    financialInformationGoods: string = "";
    goodsDescription: string = "";
    amountOfCreditLbl: string = "";
    hirePurchaseChargesLbl: string = "";
    creditFacilityFeeLbl: string = "";
    purchaseFeeLbl: string = "";
    totalChargeOfCreditLbl: string = "";
    totalAmountPayableLbl: string = "";
    aprLbl: string = "";
    interestRateLbl: string = "";
    durationLbl: string = "";
    letterA: string = "";
    letterB: string = "";
    letterC: string = "";
    letterD: string = "";
    letterE: string = "";
    letterF: string = "";
    numberI: string = "";
    numberII: string = "";
    numberIII: string = "";
    chargesAddNo: string = "";
    amountAddNo: string = "";
    extrasAddNo: string = "";
    perAnnumTxt: string = "";
    financialInformationInsuranceProduct: string = "";
    insuranceProductDescription: string = "";
    insuranceProductTextAfterTable: string = "";
    yourRepayments: string = "";
    oneTxt: string = "";
    repaymentOn: string = "";
    ofTxt: string = "";
    goodsInBrackets: string = "";
    insuranceExtrasInBrackets: string = "";
    totalInBrackets: string = "";
    repaymentAt: string = "";
    monthlyInterval: string = "";
    eachOf: string = "";
    repayment: string = "";
    docRefModag: string = '';
    afterAgreementDateText: string = "";
    importantInformation: string = "";
    modagWithdrawalTitle: string = "";
    modagWithdrawalTxt: string = "";
    modagRepaymentsTitle: string = "";
    modagRepaymentsTxt: string = "";
    modagEarlyRepayTitle: string = "";
    modagEarlyRepayStart: string = "";
    modagEarlyRepayEnd: string = "";
    modagExtraTitle: string = "";
    modagExtraTxt: string = "";
    modagMissTitle: string = "";
    modagMissTxt: string = "";
    modagTerminateTitle: string = "";
    modagTerminateStart: string = "";
    modagTerminateEnd: string = "";
    modagReposTitle: string = "";
    modagReposTxt: string = "";
    modagSuperTitle: string = "";
    modagSuperTxt: string = "";
    modagCompliantTitle: string = "";
    modagCompliantStart: string = "";
    modagCompliantMiddle: string = "";
    modagOmbudsmanTxt: string = "";
    modagOmbudsmanLnk: string = "";
    modagCompliantEnd: string = "";
    termsAndCondition: string = "";
    termsAndCondTxt1Start: string = "";
    termsAndCondTxt1End: string = "";
    termsAndCondTxtFollow: string = "";
}

export class BalloonRefinanceGetDetailsDeclined {
    declineHeader: string = '';
    declineParaPolicy: string = '';
    declineTextPolicy: string = '';    
    declineParaAfford: string = '';
    declineTextAfford: string = '';
    declineParaBureau: string = '';
    declineTextBureau: string = '';
    declineParaAuth: string = '';
    declineTextAuth: string = '';
    linkToCreditAgency: string = '';
    nextStepsHeader: string = '';
    nextStepsText: string = '';
    nextStepsAuthText: string = '';
    otherOptions: string = '';
    accordianHeaderText: string = '';
    creditAgenciesTitle: string = '';
    creditAgenciesText: string = '';
    moneyWorryTitle: string = '';
    moneyWorryText: string = '';
    updateOptionsTitle: string = '';
    updateOptionsText: string = '';
    declineAccordions: WSSAccordion[] = [];
}

export class BalloonRefinanceGetDetailsConnectionFailureRedirect {
    title: string = '';
    bodyText: string = '';
    primaryButtonText: string = '';
}

export class BrgPostDecline {
    title: string = "";
    bodyText: string = "";
    primaryButtonText: string = "";
    linkText: string = "";
}

export class BrgModAgNextStep {
    pageTitle: string = "";
    headerText: string = "";
    firstInstallmentDate: string = "";
    firstInstallmentAmount: string = "";
    description: string = "";
    buttonText: string = ""
}

export class BalloonRefinanceCommonDetailsItem {
    balloonRefinanceCommonAPR = "";
}

