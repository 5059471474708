<section id="brg-referral" *ngIf="brgItem$ | async as brgItem">
    <section class="content-width-max mt-40px">
        <div class="card text-center">
            <div class="timeout-icon mb-20px mt-25px"></div>
            <div test_id="pageHeader">
                <bb-content-html [html]="brgItem.balloonRefinanceGetdetailsReferral.pageHeader"></bb-content-html>
            </div>
            <div test_id="pageHeader">
                <bb-content-html [html]="brgItem.balloonRefinanceGetdetailsReferral.pageSubheader"></bb-content-html>
            </div>
        </div>
        <div test_id="ourProgressHeader" class="mt-25px mb-15px section-sub-title">
            {{brgItem.balloonRefinanceGetdetailsReferral.ourProgressHeader}}
        </div>
        <div test_id="contentBelowOurProgress" class="mb-25px">
            <bb-content-html [html]="brgItem.balloonRefinanceGetdetailsReferral.contentBelowOurProgress"></bb-content-html>
        </div>
        <div class="vertical-stepper">
            <div class="step completed">
                <div class="v-stepper">
                    <div class="circle">
                        <span class="material-icons-outlined color-white">description</span>
                    </div>
                    <div class="v-line"></div>
                </div>
                <div class="step-label text-semibold" test_id="stepOneText">
                    {{brgItem.balloonRefinanceGetdetailsReferral.stepOneText}}
                </div>
            </div>
            <div class="step completed">
                <div class="v-stepper">
                    <div class="circle">
                        <span class="material-icons-outlined color-white">settings</span>
                    </div>
                    <div class="v-line gray-line"></div>
                </div>
                <div class="step-label text-semibold" test_id="stepTwoText">
                    {{brgItem.balloonRefinanceGetdetailsReferral.stepTwoText}}
                </div>
            </div>
            <div class="step empty">
                <div class="v-stepper">
                    <div class="circle">
                        <span class="material-icons-outlined color-white">message</span>
                    </div>
                    <div class="v-line"></div>
                </div>
                <div class="step-label text-semibold" test_id="stepThreeText">
                    {{brgItem.balloonRefinanceGetdetailsReferral.stepThreeText}}
                </div>
            </div>
        </div>
        <div test_id="needHelpHeader" class="mt-25px mb-15px section-sub-title">
            {{brgItem.balloonRefinanceGetdetailsReferral.needHelpHeader}}
        </div>
        <div test_id="needHelpText" class="mb-p-0 para-inline">
            <bb-content-html [html]="brgItem.balloonRefinanceGetdetailsReferral.needHelpText"></bb-content-html>
            <a aria-label="Get in touch link" routerLink="/help-centre/get-in-touch" id="wss-brg-referral-getInTouchLink">{{brgItem.balloonRefinanceGetdetailsReferral.giveUsACallText}}</a>
        </div>
        <button class="wss-button" routerLink="/myaccount" test_id="backToAccountButtonText" id="wss-brg-referral-backToAccountBtn">
            {{brgItem.balloonRefinanceGetdetailsReferral.backToAccountButtonText}}
        </button>
    </section>
</section>