<div class="hc-make-payment-page" *ngIf="helpCentreWidgetItem">
	<div class="container-column">
		<div class="help-centre-title">
			<span class="section-label-title" test_id="helpCentreMakePaymentPageName">
				{{helpCentreWidgetItem.helpCentreMakePayment.pageName}}</span>
		</div>
		<div class="customer-support-text margin-top-30">
			<div test_id="helpCentreMakePaymentPageHeaderBelowText">
				<bb-content-html [html]="helpCentreWidgetItem.helpCentreMakePayment.pageHeaderBelowText"></bb-content-html>
			</div>
			
			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="helpCentreMakePaymentFirstHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreMakePayment.firstHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<!-- Dynamic accordion -->
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreMakePayment.myPaymentsAccordionContent" [testIDText]="'helpCentreMyPaymentsAccordion'"></bb-common-expansion-panel>
				</div>
			</div>

			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="helpCentreMakePaymentsecondHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreMakePayment.secondHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<!-- Dynamic accordion -->
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreMakePayment.payDebitCardAccordionContent" [testIDText]="'helpCentrePayDebitCardAccordion'"></bb-common-expansion-panel>
				</div>
			</div>

			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="helpCentreMakePaymentthirdHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreMakePayment.thirdHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<!-- Dynamic accordion -->
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreMakePayment.directDebitAccordionContent" [testIDText]="'helpCentreDirectDebitAccordion'"></bb-common-expansion-panel>
				</div>
			</div>

			<div class="margin-top-30" *ngIf="helpCentreWidgetItem.helpCentreMakePayment.fourthHeaderTitle">
				<div class="title-header margin-top-20" test_id="helpCentreMakePaymentfourthHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreMakePayment.fourthHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<!-- Dynamic accordion -->
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreMakePayment.overpaymentsAccordionContent" [testIDText]="'helpCentreOverpaymentsAccordion'"></bb-common-expansion-panel>
				</div>
			</div>

			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="helpCentreMakePaymentfifthHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreMakePayment.fifthHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<!-- Dynamic accordion -->
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreMakePayment.settlementsAccordionContent" [testIDText]="'helpCentreSettlementsAccordion'"></bb-common-expansion-panel>
				</div>
			</div>

			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="helpCentreMakePaymentsixthHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreMakePayment.sixthHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<!-- Dynamic accordion -->
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreMakePayment.accountCompletionAccordionContent" [testIDText]="'helpCentreAccountCompletionAccordion'"></bb-common-expansion-panel>
				</div>
			</div>
		</div>
	</div>
</div>