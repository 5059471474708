import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { ServiceExceptionWidgetComponent } from './service-exception-widget.component';
import { WSSCommonModule } from '../../common/common.module';
import { ContentModule, ContentWidgetModule } from '@backbase/universal-ang/content';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ServiceExceptionWidgetComponent],
  imports: [
    CommonModule,
    BackbaseCoreModule.withConfig({
      classMap: { ServiceExceptionWidgetComponent }
    }),
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule,
    MatIconModule
  ]
})
export class ServiceExceptionWidgetModule { }