import { Component, OnInit, Input } from '@angular/core';
import { Arrear } from '../../model/account-summary';
import { MessageService } from '../../../../service/message.service';
import { BannerMessage } from '../../../../model/bannerMessage';
import { ArrearMessage } from '@wss/model/content/dashboard'; 

@Component({
  selector: 'bb-wss-account-in-arrear',
  templateUrl: 'account-in-arrear.component.html'
})
export class AccountInArrearComponent implements OnInit {
  @Input() arrearData: Arrear = new Arrear();
  @Input() arrearMessage: ArrearMessage = new ArrearMessage(); 
 
  inArrears: boolean = false;

  bannerMessage: BannerMessage = new BannerMessage();
 
  constructor(private messageService: MessageService) { }

  ngOnInit(): void { 
    if (this.arrearData.inArrears) {
      this.inArrears = true
      this.bannerMessage.closeIconClass = "clear";
      this.bannerMessage.infoTypeClass = "icon_warning_amber vertical-align-center";
      this.bannerMessage.leftSectionColorClass = "message-warning";  
      this.bannerMessage.titleText = `${this.arrearMessage.titleTextBeforeData}${this.arrearData.arrearAmount}.${this.arrearMessage.titleTextAfterData}`; 
      this.bannerMessage.subtitleText = this.arrearMessage.subTitleText 
      this.bannerMessage.isBannerClosed = false;
      this.messageService.setBannerMessage(this.bannerMessage);
    }
  }
}