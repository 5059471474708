<div class="login-container" *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <span class="section-label-size" test_id="which_account_label_id">
    <!-- {{content[0].titleName}} -->
    {{loginWidgetItem.attemptExceeded.pageHeaderText}}
  </span>
  <hr/>
  <div class="account-lock text-centeracc-locked-icon" test_id="lockContent">
    <div class="acc-locked-icon"></div>

    <p test_id="attemptExceededmaxAttemptContent">
      <!-- {{content[0].max_attempt}} -->
      <!-- {{loginWidgetItem.attemptExceeded.maxAttempt}}  -->
      <bb-content-html [html]="loginWidgetItem.attemptExceeded.maxAttempt"></bb-content-html>
    </p>
    <span test_id="attemptExceededmaxAttemptprotectTextContent">
      <span class="child-para-inline mr-2">
        <bb-content-html [html]="loginWidgetItem.attemptExceeded.protectText"></bb-content-html>
      </span>
      <span class="child-para-inline">
        <bb-content-html [html]="loginWidgetItem.attemptExceeded.resetText"></bb-content-html>
      </span>
    </span>

    <br>
    <!-- <span class="section-label-size" test_id="which_account_label_id">{{content[0].helpTitle}}</span> -->
    <span class="title-section-page" test_id="attemptExceededmaxAttempthelpTitleContent">
      <bb-content-html [html]="loginWidgetItem.attemptExceeded.helpTitle"></bb-content-html>
      <!-- {{loginWidgetItem.attemptExceeded.helpTitle}}  -->
    </span>
    
    <span test_id="attemptExceededmaxAttemptnewdetailCustomerContent">

      <span class="child-para-inline mr-2">
        <bb-content-html [html]="loginWidgetItem.attemptExceeded.newdetailCustomer"></bb-content-html>
      </span>

      <span class="child-para-inline ">
        <bb-content-html [html]="loginWidgetItem.attemptExceeded.createlinkAccount"></bb-content-html>
      </span>
    </span>
    <br>
    <br>
    <span test_id="attemptExceededmaxAttemptcreatelinkAccountContent">
      <span class="child-para-inline">
        <bb-content-html [html]="loginWidgetItem.attemptExceeded.agreemntTextlabel"></bb-content-html>
      </span>
      <span class="child-para-inline" *ngIf="loginWidgetItem.brandContactDetails">
        <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html>
      </span>

      <span class="child-para-inline">
        <bb-content-html [html]="loginWidgetItem.attemptExceeded.getlinkTouch"></bb-content-html>
      </span>

    </span>
    <br>



    <span test_id="attemptExceededmaxAttemptgetTouchContent">
      <span class="child-para-inline mr-1">
        <bb-content-html [html]="loginWidgetItem.attemptExceeded.getTouch"></bb-content-html>
      </span>
      <span class="child-para-inline">
        <bb-content-html [html]="loginWidgetItem.attemptExceeded.fortextHelp"></bb-content-html>
      </span>

    </span>
    <br>
    <br>



    <div class="row margin-top-between-rows" style="justify-content: center;"
      test_id="attemptExceededmaxAttempttryAgainContent">
      <bb-content-html [html]="loginWidgetItem.attemptExceeded.forlinkHelp"></bb-content-html>
    </div>


  </div>
</div>