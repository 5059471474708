<div class="container-column" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
<div class="direct-debit-container">
    <div class="container-column direct-debit-wrapper">
        <div>
            <bb-wss-banner-message *ngIf="!isAmendmentAllowed || isWithIn3days"></bb-wss-banner-message>
        </div>
        <div class="container-row section-title-container">
            <span class="section-title" test_id="directDebitPageName">
                {{paymentsWidgetItem.directDebit.directDebitPageName}}
            </span>
        </div>
        <div class="left-container">
            <div class="container-column active-debit-card" *ngIf="activeDirectDebitFound"
                aria-describedby="activeDirectDebit">
                <div class="container-column">
                    <span class="wss-direct-debit-icon-img active-card" alt="Change Bank Icon"></span>
                    <span class="card-info text-center para-inline dd-card-info" test_id="activeDirectDebitAgreementText">
                        <bb-content-html [html]="paymentsWidgetItem.directDebit.activeDirectDebitAgreementText"></bb-content-html>
                    </span>
                </div>
                <div class="container-row content-center">
                    <button role="button" class="wss-button-secondary add-button button-width-padding dd-change-bank" test_id="changeBankDetailsButtonText"
                    (click)="addOrUpdateDebitDetail()" tabindex="0" [disabled]="isWithIn3days || !isAmendmentAllowed"
                    title="{{paymentsWidgetItem.directDebit.changeBankDetailsButtonText}}">
                    {{paymentsWidgetItem.directDebit.changeBankDetailsButtonText}}
                </button>
                </div>
                
            </div>
            <div class="container-column active-debit-card no-card" *ngIf="!activeDirectDebitFound"
                aria-describedby="deactiveDirectDebit">
                <div class="container-column">
                    <span class="wss-add-icon add-image" alt="Add Direct Debit Icon"></span>
                    <span class="card-info text-center para-inline" test_id="inactiveDirectDebitAgreementText">
                        <bb-content-html [html]="paymentsWidgetItem.directDebit.inactiveDirectDebitAgreementText"></bb-content-html>
                    </span>
                </div>
                <div class="container-row content-center">
                    <button role="button" class="wss-button add-button" test_id="addNewBankDetailsButtonText" [disabled]="isWithIn3days || !isAmendmentAllowed"
                    (click)="addOrUpdateDebitDetail()" tabindex="0" title="{{paymentsWidgetItem.directDebit.addNewBankDetailsButtonText}}">
                    {{paymentsWidgetItem.directDebit.addNewBankDetailsButtonText}}
                </button>
                </div>
            </div>
            <div role="navigation" class="mt-3 para-inline" test_id="activeDirectDebitFAQText">
                <bb-content-html *ngIf="activeDirectDebitFound" [html]="paymentsWidgetItem.directDebit.activeDirectDebitFAQText"></bb-content-html>
                <bb-content-html *ngIf="!activeDirectDebitFound" [html]="paymentsWidgetItem.directDebit.inactiveDirectDebitFAQText"></bb-content-html>
            </div>
        </div>
        <div class="right-container">
            <div class="container-column next-payment mb-110px">
                <bb-wss-next-payment [nextPaymentContent]="paymentsWidgetItem.directDebit.nextPaymentContent" [accountDetail]="accountDetail" [nextPaymentData]="nextPaymentData">
                </bb-wss-next-payment>
            </div>
        </div>
    </div>
</div>
</div>