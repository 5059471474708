import { HttpHeaders } from '@angular/common/http';
import { headerData, appConfig, RuntimeEnv } from '@wss/config/wss-app-constants';
import { isNotEmpOrNullOrUndef } from './util';

export function getHeadersRegister(getCalls: boolean, customer: string, auth?: boolean): HttpHeaders {
  let headers = new HttpHeaders();
  if (auth) {
    headers = headers.set('observe', 'response');
  } else if (getCalls) {
    if (isNotEmpOrNullOrUndef(customer)) {
      headers = headers.set('x-customer-id', customer);
    }
    let token: any = getTokenRegister();
    headers = headers.set('x-authorization', token);
  }
  return headers;
}

function getTokenRegister(): any {
  let getToken: any = sessionStorage.getItem('tokenReqRegister');
  if (isNotEmpOrNullOrUndef(getToken)) {
    return getToken;
  } else {
    return '';
  }
}

export function getHeaders(getCalls: boolean, customer: string, auth?: boolean): HttpHeaders {
  let headers = new HttpHeaders();
  if (auth) {
    headers = headers.set('observe', 'response');
  } else if (getCalls) {
    if (isNotEmpOrNullOrUndef(customer)) {
      headers = headers.set('x-customer-id', customer);
    }
    let token: any = getToken();
    headers = headers.set('x-authorization', token);
  }
  return headers;
}

export function getHeadersSession(getCalls: boolean, customer: string, auth?: boolean): HttpHeaders {
  if (localStorage['loggedIn'] === 'false') {
    sessionStorage.removeItem('tokenReq');
    location.href = appConfig.loginURL;
  }
  let headers = new HttpHeaders();
  if (auth) {
    headers = headers.set('observe', 'response');
  } else if (getCalls) {
    if (isNotEmpOrNullOrUndef(customer)) {
      headers = headers.set('x-customer-id', customer);
    }
    let token: any = getToken();
    headers = headers.set('x-authorization', token);
  }
  return headers;
}


function getToken(): any {
  let getToken: any = sessionStorage.getItem('tokenReq');
  if (isNotEmpOrNullOrUndef(getToken)) {
    return getToken;
  } else {
    return '';
  }
}

export function getHeadersLogin(): HttpHeaders {
  let headers = new HttpHeaders();
  let token: any = getToken();
  headers = headers.set('Content-Type', headerData.contentType);
  headers = headers.set('observe', 'response');
  headers = headers.set('x-authorization', token);
  return headers;
}

export function getHeadersPayment(getCalls: boolean, customerData: any, auth?: boolean): HttpHeaders {
  let headers = new HttpHeaders();
  if (auth) {
    headers = headers.set('observe', 'response');
  } else if (getCalls) {
    if (isNotEmpOrNullOrUndef(customerData.customerId)) {
      headers = headers.set('x-customer-id', customerData.customerId);
    }
    if (isNotEmpOrNullOrUndef(customerData.companyNumber)) {
      headers = headers.set('x-company-number', customerData.companyNumber);
    }
    if (isNotEmpOrNullOrUndef(customerData.accountNumber)) {
      headers = headers.set('x-account-number', customerData.accountNumber);
    }
    let token: any = getToken();
    headers = headers.set('x-authorization', token);
  }
  return headers;
}

export function getCompanyNumber(): string {
  if (RuntimeEnv.backbase) {
    let companyNo: any = appConfig.brandDetails.find(({ brandName }) => brandName === window.BB.config.portalName);
    return companyNo.companyNumber;
  } else {
    return appConfig.companyId;
  }
}

export function getCompanyTitle(): string {
  if (RuntimeEnv.backbase) {
    let companyNo: any = appConfig.brandDetails.find(({ brandName }) => brandName === window.BB.config.portalName);
    return companyNo.pageTile;
  } else {
    return appConfig.pageTile;
  }
}