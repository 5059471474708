import { Component, OnInit, Input } from '@angular/core';
import { PaperlessBanner } from '@wss/model/content/dashboard';
import { RouterService } from "@backbase/foundation-ang/core";
import { DatastoreService } from '@wss/service/datastore.service';
import { getPortalName } from '@wss/common/util/util';

@Component({
  selector: 'bb-wss-paperless-account-info',
  templateUrl: 'paperless-account-info.component.html'
})
export class PaperlessAccountInfoComponent implements OnInit {
  @Input() paperlessBanner: PaperlessBanner = new PaperlessBanner();
  showPaperlessBanner: boolean = true;
  portalName: string = getPortalName();

  constructor(
    private router: RouterService,
    private datastore: DatastoreService) { }

  ngOnInit(): void {
  }

  paperlessAccountClick() {
    this.datastore.setPreferenceLaunchThrough("banners");
    this.router.navigate(['/digital-inbox']);
  }

  closeBanner() {
    this.showPaperlessBanner = false;
  }
}