
<div class="container">
  <h2>Welcome</h2>
  <hr/>

<div class="account-lock">

  <p>You have successful logged in</p>
  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" style="fill:#13669A" viewBox="0 0 24 24"><path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6zM18 20H6V10h12v10z"/></svg>

  <br/><br/>
  <h4>We are unable to log you in at this time</h4>
  <p>
    Could you please call customer services on 0800 123 456
  </p> -->
</div>


 
</div>
