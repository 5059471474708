import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { BalloonRefinanceItem } from '@wss/model/balloon-refinance/balloon-refinance';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-br-cta-out-of-hours',
  templateUrl: 'br-cta-out-of-hours.component.html'
})
export class BrCtaOutOfHoursComponent implements OnInit {
  BrCtaOutOfHoursComponent$: Observable<BalloonRefinanceItem | undefined> = this.bbContentService.getContent<BalloonRefinanceItem>('balloonRefinanceWidgetItem');
  constructor(
    private router: Router,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService
  ) { }
  ngOnInit(): void {
    this.tealiumService.view({ tealium_event:'Balloon_Refinance_CTA_Out_Hours', URI: '/dashboard#/balloon-refinance/cta-out-of-hours' });
  }

  navigateToHome(): void {
    this.router.navigate(['/myaccount']);
  }
}
