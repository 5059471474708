<div class="static-pages-header-wrapper" test_id="welcome-header">
    <div class="d-flex header-wrapper-login responsive-header">
      <div class="logo logo-login-style"></div>
      <div *ngIf="(portalName === 'landrover' || portalName === 'jaguar' )" class="logo-text"></div>
    </div>

    <div class="static-pages-widget" *ngIf="cookiePolicyWidgetItem$ | async as cookiePolicyWidgetItem">
        <div class="static-page-container">
            <div class="section-title-container static-page-title">
                <span class="section-title" test_id="cookiesPolicyPageTitle">
                    {{cookiePolicyWidgetItem.cookiePolicy.pageTitle}}</span>
            </div>
            <div>
                <bb-content-html [html]="cookiePolicyWidgetItem.cookiesPolicyIntro"></bb-content-html>
            </div>
            <div class="category-wrapper">
                <div class="necessary-category category-item">
                    <bb-content-html [html]="cookiePolicyWidgetItem.cookiePolicy.strictlyNecessaryCategory"></bb-content-html>
                </div>
                <div class="functional-category category-item">
                    <bb-content-html [html]="cookiePolicyWidgetItem.cookiePolicy.functionalCategory"></bb-content-html>
                </div>
                <div class="performance-category category-item">
                    <bb-content-html [html]="cookiePolicyWidgetItem.cookiePolicy.performanceCategory"></bb-content-html>
                </div>
                <div class="marketing-category category-item">
                    <bb-content-html [html]="cookiePolicyWidgetItem.cookiePolicy.marketingCategory"></bb-content-html>
                </div>
            </div>
            <div class="keep-in-mind-part">
                <bb-content-html [html]="cookiePolicyWidgetItem.cookiePolicy.performanceAndMarketingCookies"></bb-content-html>
            </div>
        </div>
    </div>
</div>