<div *ngIf="endOfContractWidgetItem$ | async as endOfContractWidgetItem">
    <div class="section-title-container">
        <h1 class="section-label-title" test_id="pageName">
            {{ endOfContractWidgetItem.endOfContractKeepVehicle.pageName }}</h1>
    </div>
    <div role="alert" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div>
        <div>
            <div class="m-tb-20">
                <div class="m-tb-20" test_id="textBelowPageHeaderFirstSection">
                    {{ endOfContractWidgetItem.endOfContractKeepVehicle.textBelowPageHeaderFirstSection }}
                    {{ eocDetails.final_payment_amount }}.</div>
                <div class="m-tb-20" test_id="textBelowPageHeaderSecondSection">{{
                    endOfContractWidgetItem.endOfContractKeepVehicle.textBelowPageHeaderSecondSection }}</div>
            </div>
            <div>
                <div test_id="howItWorksText">{{ endOfContractWidgetItem.endOfContractKeepVehicle.howItWorksText }}
                </div>
                <div class="keep-your-vehicle">
                    <ol>
                        <li class="m-tb-20" test_id="howItWorksFirstPoint">
                            <bb-content-html
                                [html]="endOfContractWidgetItem.endOfContractKeepVehicle.howItWorksFirstPoint">
                            </bb-content-html>
                        </li>
                        <li class="m-tb-20">
                            <div class="font-weight-bold" test_id="howItWorksSecondPointHeader">
                                {{ endOfContractWidgetItem.endOfContractKeepVehicle.howItWorksSecondPointHeader
                                }}
                            </div>
                            <span test_id="howItWorksSecondPointText">
                                {{ endOfContractWidgetItem.endOfContractKeepVehicle.howItWorksSecondPointText }} {{
                                eocDetails.final_payment_date }}.</span>
                        </li>
                        <li class="m-tb-20" test_id="howItWorksThirdPoint">
                            <bb-content-html
                                [html]="endOfContractWidgetItem.endOfContractKeepVehicle.howItWorksThirdPoint">
                            </bb-content-html>
                        </li>
                        <li class="m-tb-20" test_id="howItWorksFourthPoint">
                            <bb-content-html
                                [html]="endOfContractWidgetItem.endOfContractKeepVehicle.howItWorksFourthPoint">
                            </bb-content-html>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div>
            <div class="mb-5 eoc-container">
                <div class="mb-45px">
                    <h3 test_id="whatYouNeedToKnowText">{{
                        endOfContractWidgetItem.endOfContractKeepVehicle.whatYouNeedToKnowText }}</h3>
                    <div class="container-row expansion-border-hidden transparent-accordion" role="row">
                        <mat-accordion class="accordion mat-accordion-override w-100 term-ext" role="accordion">
                            <mat-expansion-panel
                                class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                                <mat-expansion-panel-header class="mat-panel-header-override">
                                    <mat-panel-title class="mat-expansion-panel-header-title-override"
                                        test_id="firstAccordionHeader">
                                        <strong>{{ endOfContractWidgetItem.endOfContractKeepVehicle.firstAccordionHeader
                                            }}</strong>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="margin-top-10" test_id="firstAccordionContent">
                                    <bb-content-html
                                        [html]="endOfContractWidgetItem.endOfContractKeepVehicle.firstAccordionContent">
                                    </bb-content-html>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel
                                class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                                <mat-expansion-panel-header class="mat-panel-header-override">
                                    <mat-panel-title class="mat-expansion-panel-header-title-override"
                                        test_id="secondAccordionHeader">
                                        <strong>{{
                                            endOfContractWidgetItem.endOfContractKeepVehicle.secondAccordionHeader
                                            }}</strong>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="margin-top-10" test_id="secondAccordionContent">
                                    <bb-content-html
                                        [html]="endOfContractWidgetItem.endOfContractKeepVehicle.secondAccordionContent">
                                    </bb-content-html>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel
                                class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                                <mat-expansion-panel-header class="mat-panel-header-override">
                                    <mat-panel-title class="mat-expansion-panel-header-title-override"
                                        test_id="thirdAccordionHeader">
                                        <strong> {{
                                            endOfContractWidgetItem.endOfContractKeepVehicle.thirdAccordionHeader
                                            }}</strong>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="margin-top-10" test_id="thirdAccordionContent">
                                    <bb-content-html
                                        [html]="endOfContractWidgetItem.endOfContractKeepVehicle.thirdAccordionContent">
                                    </bb-content-html>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel
                                class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                                <mat-expansion-panel-header class="mat-panel-header-override">
                                    <mat-panel-title class="mat-expansion-panel-header-title-override"
                                        test_id="fourthAccordionHeader">
                                        <strong> {{
                                            endOfContractWidgetItem.endOfContractKeepVehicle.fourthAccordionHeader }}
                                        </strong>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="margin-top-10" test_id="fourthAccordionContent">
                                    <bb-content-html
                                        [html]="endOfContractWidgetItem.endOfContractKeepVehicle.fourthAccordionContent">
                                    </bb-content-html>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>

                <div>
                    <!-- <div class="eoc-container"> -->
                    <div class="card">
                        <div class="card-content text-center">
                            <div class="p-mb-10px" test_id="makeYourSelectionContent">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractKeepVehicle.makeYourSelectionContent">
                                </bb-content-html>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button class="wss-button" (click)="navigateToPage(confirmModal)"
                                    test_id="keepMyVehicleButtonText">
                                    <!-- [disabled]="!withinNinetyDays || fileTagFlag || !inGoodsScope" -->
                                    {{ endOfContractWidgetItem.endOfContractKeepVehicle.keepMyVehicleButtonText }}
                                </button>
                            </div>
                            <!-- <span *ngIf="!withinNinetyDays">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractKeepVehicle.optionDisableInfoText">
                                </bb-content-html>
                            </span> -->
                            <a routerLink="/eoc-options/landing" test_id="backToOptionsLinkText">
                                {{ endOfContractWidgetItem.endOfContractKeepVehicle.backToOptionsLinkText }}</a>
                        </div>
                        <div class="card-footer" test_id="haveQuestionsText">
                            <span class="margin-top-10 text-center">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractKeepVehicle.haveQuestionsText">
                                </bb-content-html>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <ng-template #confirmModal let-modal>
            <div class="modal-body">
                <div class="container-row">
                    <div class="container-column">
                        <div class="flex-row">
                            <div>
                                <span>
                                    <strong> {{ endOfContractWidgetItem.endOfContractKeepVehicle.justToConfirm }}
                                    </strong>
                                </span>
                            </div>
                            <div>
                                <span (click)="modal.dismiss()" class="material-icons">clear</span>
                            </div>
                        </div>
                        <div test_id="confirmPopupText">
                            <bb-content-html [html]="endOfContractWidgetItem.endOfContractKeepVehicle.confirmPopupText">
                            </bb-content-html>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button class="wss-button" (click)="confirmSelection()" test_id="confirmButtonText">
                                {{ endOfContractWidgetItem.endOfContractKeepVehicle.confirmButtonText }}
                            </button>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button class="wss-button-secondary" (click)="modal.dismiss()" test_id="backButtonText">
                                {{ endOfContractWidgetItem.endOfContractKeepVehicle.backButtonText }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>