<div class="manage-contract container-column" *ngIf="endOfContractWidgetItem">
    <div class="mb-10px">
        <div class="manage-your-contract-wrapper" *ngIf="endOfContractWidgetItem.manageContract">
            <div class="title-header">
                <span class="section-label-title" test_id="pageTitle">
                    {{ endOfContractWidgetItem.manageContract?.pageTitle}}
                </span>
                <hr />
            </div>
            <div class="contract-summary-column">
                <div class="contract-summary-row agreement-margin-top">
                    <div class="container-row">
                            <span class="wss-assignment-icon"></span>
                    </div>
                    <div class="contract-summary-column contract-summary-pad-left mb-p-7">
                        <span *ngIf="(!isPcpToHp && !isLPAgreementFlag)" test_id="agreement_type_value_id">{{accountDetails?.scheme_description}}</span>
                        <span *ngIf="isLPAgreementFlag" test_id="agreement_type_value_id">{{endOfContractWidgetItem.manageContract.leasePurchaseTxt}}</span>
                        <span *ngIf="isPcpToHp" test_id="agreement_type_value_id">{{ endOfContractWidgetItem.manageContract?.hpSchemeDescriptionText }}</span>
                        <span test_id="agreement_number_id" data-cs-mask>{{accountDetails?.account_number}}</span>
                    </div>
                </div>
                <div class="contract-summary-row agreement-margin-top-Vehicle">
                    <div class="container-row">
                            <span class="wss-vpn-key-icon"></span>
                    </div>
                    <div class="contract-summary-column contract-summary-pad-left mb-p-7">
                            <span test_id="vehicle_type_value_id">{{accountDetails?.goods_description}} - {{accountDetails?.registration_no}}</span>
                    </div>
                </div>
            </div>
            <div>
                <bb-common-contract-details [additionalInfoData]="contractSummary.additionalInfoData"
                    [svgInfoIcon]="svgInfoIcon" [iconColor]="svgIconColor" [additionalInfoContent]="endOfContractWidgetItem.manageContract"
                    [accountDetail]="accountDetails" [isLPAgreementFlag]="isLPAgreementFlag"></bb-common-contract-details>
            </div>
            <div class="mt-20px mb-20px" test_id="manageContractNote">
                <bb-content-html [html]="endOfContractWidgetItem.manageContract.manageContractNote"></bb-content-html> 
            </div>
            <bb-eoc-traffic-light></bb-eoc-traffic-light>
            <h2 class="font-size-20 mb-15px" test_id="duringYourContractText">{{ endOfContractWidgetItem.manageContract?.duringYourContractText }}</h2>
            <div class="transparent-accordion">
                <bb-common-expansion-panel
                    [accordionContent]="isPcpJourney ? endOfContractWidgetItem.manageContract.duringContractAccordionPcp : endOfContractWidgetItem.manageContract.duringContractAccordionHp"
                    testIDText="duringContractAccordion">
                </bb-common-expansion-panel>
            </div>
            <div class="mt-30px mb-20px">
                <h2 class="font-size-20 mb-15px" test_id="atTheEndOfContractText">{{ endOfContractWidgetItem.manageContract?.atTheEndOfContractText }}</h2>
                <div class="transparent-accordion">
                    <bb-common-expansion-panel
                    [accordionContent]="isPcpJourney ? endOfContractWidgetItem.manageContract.atTheEndContractAccordionPcp : endOfContractWidgetItem.manageContract.atTheEndContractAccordionHp"
                    testIDText="atTheEndContractAccordion">
                </bb-common-expansion-panel>
                </div>    
            </div>
            <button class="wss-button-secondary" [routerLink]="['/help-centre/landing']" test_id="faqButton">{{endOfContractWidgetItem.manageContract?.faqButton}}</button>
        </div>
    </div>
</div>