<section class="cta-screen text-center col-md-7 col-12 p-40px" *ngIf="brCtaDDComponent$ | async as brWidgetItem">
    <div class="service-dateerror margin-1rem-auto mt-40px"></div>
    <div class="font-size-18 mb-25px">
        <bb-content-html [html]="brWidgetItem.balloonRefinanceCtaEoc.title"></bb-content-html>
    </div>
    <div class=" mb-25px mb-p-0">
        <div class="para-inline">
            <bb-content-html [html]="brWidgetItem.balloonRefinanceCtaEoc.bodyText"></bb-content-html>
            <span>{{accountDetails?.earliest_contact_date}}</span>
        </div>
        <bb-content-html [html]="brWidgetItem.balloonRefinanceCtaEoc.bodyTextAfterDate"></bb-content-html>
    </div>
    <div class="d-flex flex-column align-items-center">
        <button class="wss-button mt-0" test_id="primaryButton" [routerLink]="['/eoc-options/landing']">
            {{brWidgetItem.balloonRefinanceCtaEoc.primaryButtonText}}
        </button>
    </div>
</section>