import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { WSSCommonModule } from '../../common/common.module';
import {
  InputRadioGroupModule
} from "@backbase/ui-ang";
import { NgbModalModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { PaymentHolidayWidgetComponent } from './payment-holiday-widget.component';
import { PaymentHolidayLandingComponent }  from './components/payment-holiday-landing/payment-holiday-landing.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TriageQuestionComponent } from './components/triage-question/triage-question.component';
import { CohortFirstComponent } from './components/cohort-first/cohort-first.component';
import { CohortThirdComponent } from './components/cohort-third/cohort-third.component';
import { YourOptionsComponent } from './components/your-options/your-options.component';
import { PaymentHolidayAccordionComponent } from './components/common/payment-holiday-accordion/payment-holiday-accordion.component';
import { TermExtensionLandingComponent } from './components/term-extension-landing/term-extension-landing.component';
import { CurrentAgreementComponent } from './components/current-agreement/current-agreement.component';
import { FinalizeAgreementComponent } from './components/finalize-agreement/finalize-agreement.component';
import { HPOptionsComponent } from './components/hp-options/hp-options.component';
import { PCPOptionsComponent } from './components/pcp-options/pcp-options.component';
import { TermExtensionDeclinedComponent } from './components/term-extension-declined/term-extension-declined.component';
import { TermExtensionResultComponent } from './components/term-extension-result/term-extension-result.component';
import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';

@NgModule({
  declarations: [
    PaymentHolidayWidgetComponent,
    PaymentHolidayLandingComponent,
    PaymentHolidayAccordionComponent,
    TriageQuestionComponent,
    CohortFirstComponent,
    CohortThirdComponent,
    YourOptionsComponent,
    TermExtensionLandingComponent,
    CurrentAgreementComponent,
    FinalizeAgreementComponent,
    TermExtensionResultComponent,
    TermExtensionDeclinedComponent,
    HPOptionsComponent,
    PCPOptionsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputRadioGroupModule,
    NgbModalModule,
    BackbaseCoreModule.withConfig({
      classMap: { PaymentHolidayWidgetComponent }
    }),
    MatExpansionModule,
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule,
    NgbPopoverModule
  ]
})
export class PaymentHolidayWidgetModule { }