<section class="cta-screen text-center col-md-7 col-12 p-40px" *ngIf="brCtaDDComponent$ | async as brWidgetItem">
    <div class="service-dateerror margin-1rem-auto mt-40px"></div>
    <div class="font-size-18 mb-25px">
        <bb-content-html [html]="brWidgetItem.balloonRefinanceCtaDdCalling.title"></bb-content-html>
    </div>
    <div class=" mb-25px mb-p-0">
        <bb-content-html [html]="brWidgetItem.balloonRefinanceCtaDdCalling.bodyText"></bb-content-html>
    </div>
    <div class="d-flex flex-column align-items-center">
        <button class="wss-button mt-0" test_id="primaryButton" [routerLink]="['/myaccount']">
            {{brWidgetItem.balloonRefinanceCtaDdCalling.primaryButtonText}}
        </button>
        <button class="wss-button-secondary" *ngIf="brWidgetItem.balloonRefinanceCtaDdCalling.secondaryButtonText">
            {{brWidgetItem.balloonRefinanceCtaDdCalling.secondaryButtonText}}
        </button>
    </div>
</section>