import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { ContentService } from '@backbase/universal-ang/content';
import { RuntimeEnv } from '@wss/config/wss-app-constants';
import { CarbonIoffsetContentItem, CarbonIoffsetItem } from '@wss/model/carbon-ioffset';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable } from 'rxjs';
import { CarbonIoffsetBanner } from '../../model/account-summary';

@Component({
  selector: 'bb-wss-carbon-ioffset',
  templateUrl: 'carbon-ioffset.component.html'
})
export class CarbonOffsetComponent implements OnInit {
    @Input() carbonIoffsetBanner: CarbonIoffsetBanner = new CarbonIoffsetBanner();
    carbonIoffsetItem$: Observable<CarbonIoffsetItem | undefined> = this.bbContentService.getContent<CarbonIoffsetItem>('carbonIoffsetItem');  

    constructor(
        private router: RouterService,
        private datastore: DatastoreService,
        private readonly bbContentService: ContentService,
        private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit(): void {
    }
}