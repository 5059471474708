import { Component, OnInit } from '@angular/core';
import { RoutableWidget, RouterService } from "@backbase/foundation-ang/core";
import { DatastoreService } from '@wss/service/datastore.service';
import { ServiceExceptionComponent } from './components/service-exception/service-exception.component';
import { MailboxComponent } from './components/mailbox/mailbox.component';

@Component({
  selector: 'bb-inbox-msg-widget',
  templateUrl: 'inbox-msg-widget.component.html',
  providers: [RouterService]
})

@RoutableWidget({
  routes: [
    { path: '', redirectTo: 'mailbox', pathMatch: 'full' },
    { path: 'mailbox', component: MailboxComponent },
    { path: 'service-exception', component: ServiceExceptionComponent }    
  ],
})

export class InboxMsgWidgetComponent implements OnInit {
  
  constructor (
    public datastoreService: DatastoreService
  ) { }

  ngOnInit(): void {
    this.datastoreService.sessionInvalid();
  }
  
}
