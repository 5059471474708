import { Component, OnInit, Input } from '@angular/core';
import { WSSAppConstant } from '@wss/config/wss-app-constants';

@Component({
  selector: 'bb-warning-alert',
  templateUrl: './warning-alert.component.html',
  styles: [
  ]
})
export class WarningAlertComponent  {
  @Input() warningContent: any;
  svgWarningIcon = WSSAppConstant.SVG_WARNING_ICON;

}
