import {Injectable} from "@angular/core";
import {Store, StoreFactory} from "@backbase/foundation-ang/store";
import {RegistrationState} from "./state";
import {
  RegistrationActions,
  SearchUserAction,
  SearchUserSuccessAction,
  UpdateOtpAction,
  UpdateTitleAction,
  UpdateStepAction
} from "./actions";
import {userRegistrationReducer} from "./reducer";
import {Observable} from "rxjs";
import {UserRegistrationEffects} from "./effects";
import {UserSearchRequest} from "../../registration-widget/src/user-search-request";
import {userFound, userSelectRequest, titleSelector, otpSelector, stepSelector} from "./selectors";
import {OtpGenerationResponse, User} from "./user";


@Injectable()
export class RegistrationStoreModelService {
  private readonly store: Store<RegistrationState, RegistrationActions> = this.storeFactory.createStore(
    'user-registration',
    userRegistrationReducer,
    [UserRegistrationEffects],
  );

  constructor(private readonly storeFactory: StoreFactory) {}

  userRequests: Observable<UserSearchRequest | undefined> = this.store.select(userSelectRequest);
  users: Observable<Array<User> | undefined> = this.store.select(userFound);
  titleObservable: Observable<string | undefined> = this.store.select(titleSelector);
  otpObservable: Observable<number | undefined> = this.store.select(otpSelector);
  stepObservable: Observable<number | undefined> = this.store.select(stepSelector);

  searchUsers(userRequest: UserSearchRequest) {
    this.store.dispatch(new SearchUserAction(userRequest));
  }

  updateUsersInStore(action: SearchUserSuccessAction) {
    this.store.dispatch(action);
  }

  updateOtpInStore(action: UpdateOtpAction) {
    this.store.dispatch(action);
  }

  updateTitle(title: string) {
    this.store.dispatch(new UpdateTitleAction(title))
  }

  updatedStepper(step: number) {
    this.store.dispatch(new UpdateStepAction(step));
  }
}
