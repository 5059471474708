<div
    [ngClass]="accountDetail?.scheme_code !== hpType && !isPcpToHpBalloonRefinanceFlag ? 'speedometer-top-flex-wrapper': 'speedometer-top-flex-wrapper speedometer-top-only-hp'">
    <div class="speedometer-top-menu-wrapper">
        <div class="speedometer-top-menu-item border-right border-bottom">
            <span class="speedometer-item-circle-paid-so-far"></span>
            <span class="para-inline" test_id="paid_so_far_legend_id">
                <bb-content-html [html]="speedometerContent.amountPaid"></bb-content-html>
            </span>
            <!-- <span test_id="paid_so_far_legend_id" class="paid-so-far-text">{{content?.paid}}</span> -->
        </div>
        <div class="speedometer-top-menu-item border-bottom">
            <span class="speedometer-item-circle-amount-left"></span>
            <span class="para-inline" test_id="amount_legend_id">
                <bb-content-html [html]="speedometerContent.amountLeft"></bb-content-html>
            </span>
            <!-- <span test_id="amount_legend_id" class="paid-so-far-text">{{content?.aleft}}</span> -->
        </div>
        <div class="speedometer-top-menu-item border-left border-bottom" *ngIf="(accountDetail?.scheme_code !== hpType && !isPcpToHpBalloonRefinanceFlag) || isLPAgreementFlag">
            <span class="speedometer-item-circle-optional-final-payment"></span>
            <span class="para-inline" test_id="optional_final_payment_legend_id" *ngIf="accountDetail?.scheme_code !== hpType && !isPcpToHpBalloonRefinanceFlag">
                <bb-content-html [html]="speedometerContent.finalPayment"></bb-content-html>
            </span>
            <span class="para-inline" test_id="optional_final_payment_legend_lp_id" *ngIf="isLPAgreementFlag">
                <bb-content-html [html]="speedometerContent.finalPaymentLP"></bb-content-html>
            </span>
            <!-- <span test_id="optional_final_payment_legend_id" class="paid-so-far-text">{{content?.optionalp}}</span> -->
        </div>
    </div>
    <div class="speedometer-payment-wrapper">
        <div class="speedometer-canvas">
            <div id="speedometer_id"></div>
            <div class="container-column content-center position-absolute">
                <span class="speedometer-animated-car"></span>
                <!-- <span test_id="remaining_amount_label_id" class="remaining-amount-label">{{content?.remaining}}</span> -->
                <span class="para-inline" test_id="remaining_amount_label_id">
                    <bb-content-html [html]="speedometerContent.outstandingBalance"></bb-content-html>
                </span>
                <strong test_id="remaining_amount_value_id" class="remaining-amount-value">&pound;
                    {{speedometerData?.remainingAmount}}</strong>
                <span class="para-inline margin-top-10" test_id="subject_charges_id">
                    <bb-content-html [html]="speedometerContent.subjectNote"></bb-content-html>
                </span>
                <!-- <span test_id="subject_charges_id" class="section-note" tabindex="0" title="{{content?.subject}}" > <a>*{{content?.subject}}</a></span> -->
            </div>
        </div>
        <div class="speedometer-row speedometer-bottom-item-height">
            <div class="speedometer-bottom-menu-item border-right border-top">
                <span class="material-icons last-payment-margin">insert_chart_outlined</span>
                <span class="para-inline" test_id="last_payment_label_id">
                    <bb-content-html [html]="speedometerContent.lastPayment"></bb-content-html>
                </span>
                <!-- <span><strong test_id="last_payment_label_id">{{content?.lastp}}</strong></span> -->
                <span test_id="last_payment_value_id"
                    class="bottom-last-item">&pound;{{speedometerData?.lastPaymentAmount}}</span>
            </div>
            <div class="speedometer-bottom-menu-item border-top">
                <span class="calendar_today date-received-margin"></span>
                <span class="para-inline" test_id="date_received_label_id">
                    <bb-content-html [html]="speedometerContent.dateRecieved"></bb-content-html>
                </span>
                <!-- <span><strong test_id="date_received_label_id">{{content?.dreceived}}</strong></span> -->
                <span test_id="date_received_value_id" class="bottom-last-item">{{speedometerData?.dateReceived}}</span>
            </div>
        </div>
    </div>
</div>