<section class="cta-screen text-center col-md-7 col-12 p-40px" *ngIf="BrCtaOutOfHoursComponent$ | async as brWidgetItem">
    <div class="timeout-icon mb-20px"></div>
    <h1 class="cta-screen__title font-size-18 mb-20px">{{brWidgetItem.balloonRefinanceCtaOutOfHours.title}}</h1>
    <div class="mb-30px">
        <bb-content-html [html]="brWidgetItem.balloonRefinanceCtaOutOfHours.bodyText"></bb-content-html>
    </div>
    <div class="d-flex flex-column align-items-center">
        <button id="wss-br-out-of-hours-back-to-home-btn" class="wss-button" test_id="primaryButton" (click)="navigateToHome()" *ngIf="brWidgetItem.balloonRefinanceCtaOutOfHours.primaryButtonText">
            {{brWidgetItem.balloonRefinanceCtaOutOfHours.primaryButtonText}}
        </button>
    </div>
</section>