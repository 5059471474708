import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@wss/service/http.service';
import { DirectDebitResponse, DirectDebit } from '../models/direct-debit';
import { Card, CardPaymentDetail, PayPageResponseDetail } from '../models/make-payment';
import { MessageService } from '@wss/service/message.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { catchError, map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { DueDate, changePaymentEmailTemplate } from '../models/change-payment';
import { smsTemplateId as directDebitsms, changeDirectDebitTemplate } from '../models/direct-debit';
import { processAccountNumber, getPortalName } from '@wss/common/util/util';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  portalName: any;

  constructor(private httpService: HttpService,
    private messageService: MessageService,
    private datastoreService: DatastoreService, private router: Router) { }

  addOrUpdateDirectDebit(directDebitRequest: DirectDebit): Observable<DirectDebitResponse | any> {
    return this.httpService.addOrUpdateDirectDebit(directDebitRequest).pipe(catchError(error => {
      this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
      return error;
    }));
  }

  getBankAccountDetail(directDebitRequest: any): Observable<any> {
    return this.httpService.getBankAccountDetail(directDebitRequest).pipe(catchError(error => {
      this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
      return error;
    }));
  }

  getAccountDetail(): Observable<any> {
    return this.httpService.getAccountDetails().
      pipe(map(data => {
        this.datastoreService.setAccountDetails(data);
      }));
  }
  getCardsDetail(): Observable<Card | any> {
    return this.httpService.getCardsDetail()
      .pipe(
        map((data) => {
          this.datastoreService.setPaymentDetails(data);
        }),
        catchError(error => {
          if (error.error[0]?.reasonCode === "CUSTOMER_NOT_FOUND") {
            error.error[0].reasonCode = "NO_CARD_STORE";
          }
          this.messageService.setServiceError(error.error[0]);
          return error;
        })
      );
  }

  postCardPaymentDetail(): Observable<CardPaymentDetail[] | any> {
    return this.httpService.postCardPaymentDetail()
      .pipe(
        map((data) => {
          this.datastoreService.setCardPaymentDetails(data);
        }),
        catchError(error => {
          this.messageService.setServiceError(error.error[0]);
          localStorage.removeItem('paymentUpdateDetails');
          localStorage.setItem('paymentErrorDetails', JSON.stringify(error.error[0]));
          this.datastoreService.setpaymentErrorDetails(error.error[0]);
          return error;
        })
      );
  }

  sendEmailConfirmation(): Observable<any> {
    return this.httpService.sendEmailConfirmation()
      .pipe(
        map((data) => {
        }),
        catchError(error => {
          return error;
        })
      );
  }

  sendChangePaymentEmailConfirmation(): Observable<any> {
    let dueDate = this.datastoreService.getDueDate();
    let customerDetail = this.datastoreService.getCustomerDetail();
    let customerNameFormat = customerDetail.individual.first_name.charAt(0).toUpperCase() + customerDetail.individual.first_name.slice(1).toLowerCase();
    let templateObj = {
      email_title: changePaymentEmailTemplate.email_title,
      email_subject: changePaymentEmailTemplate.email_subject,
      listAttributes: {
        "customerName": customerNameFormat,
        "newpaymentDay": dueDate.new_due_day,
        "nextpaymentDate": dueDate.next_payment_date,
        "extraAmount": dueDate.lost_interest
      }
    };
    return this.httpService.sendEmailNotification(templateObj)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(error => {
          this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
          return error;
        })
      );
  }

  sendDirectDebitEmailConfirmation(): Observable<any> {
    let directDebitRequest = this.datastoreService.getDirectDebitResponse();
    let customerDetail = this.datastoreService.getCustomerDetail();
    let customerNameFormat = customerDetail.individual.first_name.charAt(0).toUpperCase() + customerDetail.individual.first_name.slice(1).toLowerCase();

    let templateObj = {
      email_title: changeDirectDebitTemplate.email_title,
      email_subject: changeDirectDebitTemplate.email_subject,
      listAttributes: {
        "customerName": customerNameFormat,
        "nextPaymentDate": directDebitRequest.next_payment_due_date
      }
    };
    return this.httpService.sendEmailNotification(templateObj)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(error => {
          this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
          return error;
        })
      );
  }

  getSettlementDetail(): Observable<any> {
    return this.httpService.getSettlementDetail()
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(error => {
          this.messageService.setServiceError(error);
          return error;
        })
      );
  }

  sendPaymentSMS(smsType: string) {
    let listAttributeValue: any = {};
    if (smsType === 'PR'){
      listAttributeValue = {
        "PayAmount": sessionStorage.getItem("transaction_value"),
        "AccountNo": processAccountNumber(this.datastoreService.getAccountNo()),        
        "AuthCode": this.datastoreService.getPaymentSuccessReciptNumber()
      }
    } else {
      listAttributeValue = {
        "AccountNo": processAccountNumber(this.datastoreService.getAccountNo())
      }
    }
    let templateObj = {
      mobileNo: this.datastoreService.getRecipientMobileNumber(),
      templateId: this.getSmsTemplateIdByPortal(smsType),
      // SettlementSmsConfirmationID.sms_template_id
      listAttributes: listAttributeValue
    };
    return this.httpService.sendSMSConfirmation(templateObj)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(error => {
          this.messageService.setServiceError(error.error[0]);
          return error;
        })
      );
  }

  sendDueDateSMS() {
    let dueDate = this.datastoreService.getDueDate();
    let mobileNo = this.datastoreService.getRecipientMobileNumber();
    let templateObj = {
      mobileNo: mobileNo,
      templateId: this.getSmsTemplateIdByPortal('CPD'),
      // smsTemplateId
      listAttributes: {
        "AccountNo": processAccountNumber(this.datastoreService.getAccountNo()),
        "NewDueDate": dueDate.new_due_day,
        "LostInterest": dueDate.lost_interest.substring(1),
      }
    };
    return this.httpService.sendSMSConfirmation(templateObj)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(error => {
          this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
          return error;
        })
      );
  }

  updatebankDetailSMS() {
    let templateObj = {
      mobileNo: this.datastoreService.getRecipientMobileNumber(),
      templateId: this.getSmsTemplateIdByPortal('DD'),
      // directDebitsms
      listAttributes: {
        "AccountNo": processAccountNumber(this.datastoreService.getAccountNo())
      }
    };

    return this.httpService.sendSMSConfirmation(templateObj)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(error => {
          this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
          return error;
        })
      );
  }

  getPostSettlementResponse(newSettlementFlag?: any): Observable<any> {
    return this.httpService.postSettlementAmount(newSettlementFlag)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(error => {
          this.messageService.setServiceError(error);
          return error;
        })
      );
  }

  calculateLostInterest(newDueDate: string): Observable<DueDate | any> {
    return this.httpService.calculateLostInterest(newDueDate).
      pipe(map(data => {
        this.datastoreService.setDueDate(data);
        return data;
      }), catchError(error => {
        this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      }));
  }

  dueDates(newDueDate: DueDate): Observable<DueDate | any> {
    return this.httpService.dueDates(newDueDate).
      pipe(map(data => {
        this.datastoreService.setDueDate(data);
        return data;
      }), catchError(error => {
        this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      }));
  }

  updatePaymentResultDetails(postResult: any): Observable<PayPageResponseDetail | any> {
    return this.httpService.updatePaymentResultDetails(postResult).
      pipe(map(data => {
        return data;
      }), catchError(error => {
        this.messageService.setServiceError(error.error[0]);
        return error;
      }));
  }

  getOverpaymentResponse(): Observable<any> {
    return this.httpService.getOverpaymentResponse()
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(error => {
          this.messageService.setServiceError(error.error[0]);
          return error;
        })
      );
  }

  postOverpaymentResponse(): Observable<any> {
    return this.httpService.postOverpaymentResponse()
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(error => {
          this.messageService.setServiceError(error);
          return error;
        })
      );
  }

  getSmsTemplateIdByPortal(componentType: any) {
    this.portalName = getPortalName();
    if (this.portalName) {
      let portalCode = '';
      if (this.portalName === 'blackhorse') {
        portalCode = 'BH';
      } else if (this.portalName === 'suzukifinance') {
        portalCode = 'SUZ';
      } else if (this.portalName === 'mitsubishishogun') {
        portalCode = 'MIT';
      } else if (this.portalName === 'landrover') {
        portalCode = 'LRV';
      } else if (this.portalName === 'jaguar') {
        portalCode = 'JAG';
      } else if (this.portalName === 'internationalmotors') {
        portalCode = 'IMF';
      } else if (this.portalName === 'halifax') {
        portalCode = 'HX';
      } else if (this.portalName === 'lloyds') {
        portalCode = 'LLOY';
      } else if (this.portalName === 'bankofscotland') {
        portalCode = 'BOS';
      } else if (this.portalName === 'astonmartin') {
        portalCode = 'AMFS';
      }
      if (componentType === 'CPD') {
        return portalCode + "DUE01";
      } else if (componentType === 'DD') {
        return portalCode + "BANK01";
      } else if (componentType === 'PR') {
        return portalCode + "PAY01";
      } else if (componentType === 'SPR') {
        return portalCode + "PAY02";
      }
    }
    return "";
  }

  fetchOverpaymentResponse() {
    this.httpService.fetchOverpaymentResponse();
  }

  refreshAccountData() {
    this.getAccountDetail()
    .subscribe((res: any) => {})
  }

  sendSettlementQuoteEmail(settlement_figure: any): Observable<any> {
    return this.httpService.sendSettlementQuoteEmail(settlement_figure)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(error => {
          this.messageService.setServiceError(error);
          return error;
        })
      );
  }

  sendSettlementQuote3rdPartyEmail(settlement_figure: any, thirdPartyEmail: any): Observable<any> {
    return this.httpService.sendSettlementQuote3rdPartyEmail(settlement_figure, thirdPartyEmail)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(error => {
          this.messageService.setServiceError(error);
          return error;
        })
      );
  }

}