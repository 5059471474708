<div class="container-column" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
    <div class="payment-row section-title-container" test_id="pageName">
        <span class="section-title" test_id="make_payment_label_id">{{paymentsWidgetItem.paymentMenu.pageSelectionName}}</span>
    </div>
    <div class="payment-column">
        <div class="payment-row mt-5px">
            <span test_id="pageText">
                <bb-content-html [html]="paymentsWidgetItem.paymentMenu.pageSelectionHeaderText"></bb-content-html>
            </span>
        </div>
    </div>
    <form [formGroup]="paymentSelectionForm">
        <div class="payment-selection-container">
            <div class="container margin-top-10">
                <div>
                    <div class="radio mb-10px">
                        <label class="form-check-label" [ngClass]="{disabled: paymentButtonDisabled}">
                            <input type="radio" class="form-check-input" formControlName="paymentType"
                                value="regularPayment">
                            <span class="label"
                                test_id="regularPaymentRadioText">{{paymentsWidgetItem.paymentMenu.regularPaymentRadioText}}</span>
                        </label>
                        <span class="whats-this">
                            <a (click)="openInfo('regularPayment')">{{paymentsWidgetItem.paymentMenu.explainThisText}}
                                <span *ngIf="!regularArrowDown"
                                    class="material-icons material-icons-color-arrear">keyboard_arrow_up</span>
                                <span *ngIf="regularArrowDown"
                                    class="material-icons material-icons-color-arrear">keyboard_arrow_down</span>
                            </a>
                        </span>
                        <div [ngClass]="regularArrowDown ? 'payment-type-info' : 'payment-type-info show-info'">
                            <span class="tooltip-arrow-up"></span>
                            <span class="para-inline">
                                <bb-content-html [html]="paymentsWidgetItem.paymentMenu.regularPaymentInfo">
                                </bb-content-html>
                            </span>
                        </div>
                    </div>
                    <div class="radio mb-10px">
                        <label class="form-check-label" [ngClass]="{disabled: overPaymentButtonDisabled}">
                            <input type="radio" class="form-check-input" formControlName="paymentType"
                                value="overpayment">
                            <span class="label"
                                test_id="overpaymentRadioText">{{paymentsWidgetItem.paymentMenu.overpaymentRadioText}}</span>
                        </label>
                        <span class="whats-this">
                            <a (click)="openInfo('overPayment')">{{paymentsWidgetItem.paymentMenu.explainThisText}}
                                <span *ngIf="!overpaymentArrowDown"
                                    class="material-icons material-icons-color-arrear">keyboard_arrow_up</span>
                                <span *ngIf="overpaymentArrowDown"
                                    class="material-icons material-icons-color-arrear">keyboard_arrow_down</span>
                            </a>
                        </span>
                        <div [ngClass]="overpaymentArrowDown ? 'payment-type-info' : 'payment-type-info show-info'">
                            <span class="tooltip-arrow-up"></span>
                            <span class="para-inline">
                                <bb-content-html [html]="paymentsWidgetItem.paymentMenu.overPaymentInfo">
                                </bb-content-html>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-row margin-top-20">
            <button type="submit" class="wss-button btn btn-dif-view" (click)="goToNextPage()"
                test_id="proceedButtonText" [disabled]="!paymentSelectionForm.valid">
                {{paymentsWidgetItem.paymentMenu.proceedButtonText}}
            </button>
        </div>
    </form>
</div>