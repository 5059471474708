import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccoutSummaryService } from '@wss/account-summary-widget/src/services/account-summary.service';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable, Subject } from 'rxjs';
import { EndOfContractService } from '../../services/end-of-contract.service';
import { MessageService } from '@wss/service/message.service';
import { takeUntil } from 'rxjs/operators';
import { isErrServer } from '@wss/common/util/util';
import { appConfig } from '@wss/config/wss-app-constants';
import { BannerMessage } from 'libs/model/bannerMessage';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-eoc-return-vehicle',
  templateUrl: 'eoc-return-vehicle.component.html'
})
export class EocReturnVehicleComponent implements OnInit {

  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');
  withinNinetyDays: boolean = false;
  readonly destroy$ = new Subject();
  serviceError: boolean = false;
  fileTagFlag: boolean = false;
  inGoodsScope: boolean = false;
  dealersDetails: any;
  bannerMessage: BannerMessage = new BannerMessage();
  endOfContractWidgetItem: any;
  isAgreementInArrears: boolean = false;

  eocDetails: any = {
    //hardcode inorder to test
    // max_total_mileage: "5000",
    // mileage_charge: "£0.15",
    // final_payment_date: "15/09/2021",
    // in_arrears: false,
    // months_in_arrears: 1,
    // file_tag: '99',
    // goods_code: '001',
    // customer_intention: 'P'
  };
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private readonly bbContentService: ContentService,
    private eocService: EndOfContractService,
    private datastoreService: DatastoreService,
    private messageService: MessageService,
    private tealiumService: TealiumUtagService,
    public changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'End of Contract Return Vehicle Landing',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.eocDetails = this.datastoreService.getAccountDetails();
    this.withinNinetyDays = this.eocService.isContractWithinNintyDays();
    this.fileTagFlag = appConfig.fileTags.includes(this.eocDetails.file_tag);
    this.inGoodsScope = appConfig.goodsCode.includes(this.eocDetails.goods_code);
    this.isAgreementInArrears = !(!this.eocDetails?.in_arrears || (this.eocDetails?.in_arrears && this.eocDetails?.months_in_arrears == 0));
    this.dealersDetails = this.datastoreService.getDealersDetails();
    if (!this.dealersDetails?.dlr_type) {
      this.getDealersDetails();
    }
    this.handleErrors();
  }

  openModal(modalContent: any) {
    this.modalService.open(modalContent, { centered: true, windowClass: 'eoc-modal' });
  }

  confirmSelection() {
    this.modalService.dismissAll();
    this.datastoreService.setEocRoute("contact-details");
    this.messageService.setActiveNav("End of contract");
    this.router.navigate(['/eoc-options/contact-details']);
    window.scroll(0, 0);
  }

  handleErrors() {
    let eocServiceErr = this.messageService.getEOCServiceErrorObj();
    if (eocServiceErr?.code) {
      this.showError(eocServiceErr?.code || eocServiceErr?.status)
    }
  }


  showError(errStatus: number): void {
    if (isErrServer(errStatus) || errStatus == 506) {
      this.bannerMessage.closeIconClass = 'clear';
      this.bannerMessage.infoTypeClass = 'error-icon';
      this.bannerMessage.leftSectionColorClass = 'message-failure';
      this.endOfContractWidgetItem$.subscribe((res: any) => {
        this.endOfContractWidgetItem = res;
        this.bannerMessage.titleText = this.endOfContractWidgetItem?.endOfContractReturnVehicle.eocErrorbannerMessage.titleTextBeforeData;
        this.bannerMessage.subtitleText = this.endOfContractWidgetItem?.endOfContractReturnVehicle.eocErrorbannerMessage.subtitleTextBeforeData + this.endOfContractWidgetItem?.brandContactDetails.brandWiseContactNumber + this.endOfContractWidgetItem?.endOfContractReturnVehicle.eocErrorbannerMessage.subtitleTextAfterData;
        this.bannerMessage.isBannerClosed = false;
        this.messageService.setBannerMessage(this.bannerMessage);
        this.serviceError = true;
        window.scroll(0, 0);
      }
      )
    }
    else {
      this.serviceError = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  getDealersDetails() {
    this.eocService.getDealersDetails(this.eocDetails?.dealer_number)
      .subscribe((res: any) => {
        this.dealersDetails = res;
        this.changeDetectorRef.detectChanges();
      }, (error: any) => {
      })
  }

  navigateToPage(modalContent: any) {
    if (this.isAgreementInArrears || this.eocDetails?.customer_intention === 'F' || this.eocDetails?.customer_intention === 'T' || this.eocDetails?.customer_intention === 'P' || this.eocDetails?.customer_intention === 'E') {
      this.datastoreService.setEocRoute("get-in-touch");
      this.messageService.setActiveNav("End of contract");
      this.router.navigate(['/eoc-options/get-in-touch']);
    } else if (this.eocDetails?.customer_intention === 'R') {
      this.datastoreService.setEocRoute("/return-vehicle-confirmation");
      this.messageService.setActiveNav("End of contract");
      this.router.navigate(['/eoc-options/return-vehicle-confirmation']);
    } else if (!this.withinNinetyDays) {
      this.datastoreService.setEocRoute("get-in-touch");
      this.messageService.setActiveNav("End of contract");
      this.router.navigate(['/eoc-options/get-in-touch']);
    } else {
      this.openModal(modalContent);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.modalService.dismissAll();
  }

}
