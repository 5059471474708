import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { PaymentWidgetComponent } from './payment-widget.component';
import { RouterModule } from "@angular/router";
import {
  ButtonModule,
  HeaderModule,
  InputEmailModule,
  InputNumberModule,
  InputPasswordModule,
  InputTextModule,
  InputValidationMessageModule,
  StepperModule,
  InputRadioGroupModule
} from "@backbase/ui-ang";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { PaymentDebitCardComponent } from './components/payment-debit-card/payment-debit-card.component';
import { PaymentMenuComponent } from './components/payment-menu/payment-menu.component';
import { MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentResultComponent } from './components/payment-result/payment-result.component';
import { PaymentSettlementComponent } from './components/payment-settlement/payment-settlement.component';
import { DirectDebitComponent } from './components/direct-debit/direct-debit.component';
import { AddDirectDebitComponent } from './components/add-direct-debit/add-direct-debit.component';
import { DirectDebitResultComponent } from './components/direct-debit-result/direct-debit-result.component';
import { WSSCommonModule } from '../../common/common.module';
import { ChangePaymentComponent } from './components/change-payment/change-payment.component';
import { ConfirmChangePaymentComponent } from './components/confirm-change-payment/confirm-change-payment.component';
import { ChangePaymentResultComponent } from './components/change-payment-result/change-payment-result.component';
import { ServiceExceptionComponent } from './components/service-exception/service-exception.component';
import { OverPaymentComponent } from './components/over-payment/over-payment.component';
import { OverPaymentOptionsComponent } from './components/over-payment-options/over-payment-options.component';
import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';
import { EnterBillingAddressComponent } from './components/enter-billing-address/enter-billing-address.component';
import { ConfirmBillingAddressComponent } from './components/confirm-billing-address/confirm-billing-address.component';
import { PaymentSelectionComponent } from './components/payment-selection/payment-selection.component';


@NgModule({
  declarations: [
    PaymentWidgetComponent,
    PaymentMenuComponent,
    PaymentSelectionComponent,
    PaymentDebitCardComponent, 
    PaymentResultComponent, 
    PaymentSettlementComponent,
    DirectDebitComponent,
    AddDirectDebitComponent,
    DirectDebitResultComponent,
    ChangePaymentComponent,
    ConfirmChangePaymentComponent,
    ChangePaymentResultComponent,
    ServiceExceptionComponent,
    OverPaymentComponent,
    OverPaymentOptionsComponent,
    EnterBillingAddressComponent,
    ConfirmBillingAddressComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputRadioGroupModule,
    BackbaseCoreModule.withConfig({
      classMap: { PaymentWidgetComponent }
    }),
    RouterModule.forRoot([], { initialNavigation: 'disabled', useHash: true, relativeLinkResolution: 'legacy' }),
    HeaderModule,
    RouterModule,
    MatDialogModule,
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PaymentWidgetModule { }