import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { DigitalInboxWidgetItem } from '@wss/model/content/digital-inbox';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'preference-result',
  templateUrl: './preference-result.component.html',
  providers: [ ContentService ]
})
export class PreferenceResultComponent implements OnInit {

  digitalInboxWidgetItem$: Observable<DigitalInboxWidgetItem | undefined> = this.contentService.getContent<DigitalInboxWidgetItem>('digitalInboxWidgetItem');
  //availablePreferences: any[] = ['post', 'paperless', 'multi'];
  selectedPreference: string = '';
  preferenceLaunchThrough: string = ''; //banners/profile
  currentAccount: any = {};
  otherAccounts: any[] = [];

  constructor(
    private readonly contentService: ContentService,
    private changeDetectorRef: ChangeDetectorRef,
    private tealiumService: TealiumUtagService,
    private datastore: DatastoreService
    ) {}

  ngOnInit(): void {
    this.tealiumService.view (
      {
        JourneyName: 'Digital Inbox',
        JourneyStepName: 'Preference Result',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    //this.selectedPreference = this.availablePreferences[2];
    this.currentAccount = this.datastore.getCurrentAccountMailPreference();
    this.otherAccounts = this.datastore.getOtherAccountsMailPreference();
    this.preferenceLaunchThrough = this.datastore.getPreferenceLaunchThrough();
    if(this.otherAccounts.length){
      this.selectedPreference = this.datastore.availablePreferences[2];
    }
    else if(this.currentAccount.is_paperless){
      this.selectedPreference = this.datastore.availablePreferences[1];
    }
    else{
      this.selectedPreference = this.datastore.availablePreferences[0];
    }
    this.changeDetectorRef.detectChanges();
  }

  backToHome(){
    location.href = "#/myaccount";
  }

}