<section class="cta-screen text-center col-md-7 col-12 p-40px" *ngIf="brgItem$ | async as brgItem">
    <div class="warning-icon-with-border mb-20px mt-40px"></div>
    <h1 class="cta-screen__title font-size-18 mb-20px">{{brgItem.balloonRefinanceGetdetailsConnectionFailureRedirect.title}}</h1>
    <div class="mb-30px">
        <bb-content-html [html]="brgItem.balloonRefinanceGetdetailsConnectionFailureRedirect.bodyText"></bb-content-html>
        <span class="text-semibold">{{brgItem.balloonRefinanceGetdetailsConnectionFailure.textBeforeLastSubmitDate}} {{lastDateOfDecision | date: 'dd MMM yyyy'}} 
            {{brgItem.balloonRefinanceGetdetailsConnectionFailure.textAfterLastSubmitDate}}</span>
    </div>
    <div class="d-flex flex-column align-items-center">
        <button id="wss-brg-connection-failure-redirect-exit-to-home-btn" class="wss-button mt-0" test_id="primaryButton" routerLink="/myaccount" *ngIf="brgItem.balloonRefinanceGetdetailsConnectionFailureRedirect.primaryButtonText">
            {{brgItem.balloonRefinanceGetdetailsConnectionFailureRedirect.primaryButtonText}}
        </button>
    </div>
</section>