import {Component, OnInit} from '@angular/core';
import {ItemModel, RouterService} from "@backbase/foundation-ang/core";
import {RegistrationStoreModelService} from "../../../../registration-store/src/registration-store-model.service";
import {ContentService as OldContentService} from "../../../../service/content.service";
import {ContentData} from '../../../../config/wss-app-constants';
import { RegistrationWidgetItem } from '@wss/model/registrationToken';
import { Observable } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-wss-questions-fail',
  templateUrl: './questions-fail.component.html',
  providers: [ ContentService ]
})

export class QuestionsFailComponent implements OnInit {

  registrationWidgetItem$: Observable<RegistrationWidgetItem | undefined> = this.bbContentService.getContent<RegistrationWidgetItem>('registrationWidgetItem');

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Registration',
        JourneyStepName: 'Questions Fail',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
  }

  constructor(
    private router: RouterService,
    private itemModel: ItemModel,
    private contentService: OldContentService,
    private registrationService: RegistrationStoreModelService,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
  ) {
    this.itemModel.property('userDetailsTitle').subscribe(value => this.registrationService.updateTitle(value as string));
    this.registrationService.updatedStepper(2);
  }

  passwordSet() {
    this.router.navigate(['user-detail']);
  }
}
