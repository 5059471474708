import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatastoreService } from '@wss/service/datastore.service';

@Component({
  selector: 'bb-complaint-data-retention',
  templateUrl: 'complaint-data-retention.component.html',
  providers: [ContentService],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class ComplaintDataRetentionComponent implements OnInit {
  popupContent: any = {};
  
  constructor(
    public modal: NgbActiveModal,
    private dataStoreService: DatastoreService
  ) { }

  ngOnInit(): void {
    this.popupContent = this.dataStoreService.getComplaintsGetStartedContent();
  }

  leave() {
    this.modal.close(true);
  }

  stay() {
    this.modal.close(false);
  }
}
