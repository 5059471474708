<div class="mail-preference-container">
    <div class="container-row" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="container-column">
        <div class="container-row section-title-container">
            <span class="section-title" test_id="pageName">{{digitalInboxWidgetItem.confirmPreference.pageName}}</span>
        </div>
        <div class="multiple-accounts-container mt-30px">
            <div class="container-row multiple-accounts-card section-card-border-color">
                <div class="column-blue section-icon-background-color"></div>
                <div class="car-flex-width section-icon-color">
                    <span class="wss-car-icon"></span>
                </div>
                <div class="container-column agreement-text-column">
                    <div class="mt-9px">
                        <bb-content-html *ngIf="currentAccount.is_paperless" class="para-inline" [html]="digitalInboxWidgetItem.confirmPreference.selectedPreferencePaperlessText"></bb-content-html>
                        <bb-content-html *ngIf="!currentAccount.is_paperless" class="para-inline" [html]="digitalInboxWidgetItem.confirmPreference.selectedPreferencePostText"></bb-content-html>
                    </div>
                    <div class="container-column">
                            <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.confirmPreference.agreementNumberText"></bb-content-html>
                        <span test_id="loginWidgetAccountNumberContent" data-cs-mask>
                            {{currentAccount.account_number}}
                        </span>
                    </div>
                    <div class="container-column" test_id="loginWidgetregistrationNo">
                            <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.confirmPreference.vehicleAndRegistrationText"></bb-content-html>
                        <span test_id="vehicle_n_registration_value_id" data-cs-mask>
                            {{currentAccount.goods_description}} {{currentAccount.registration_no}}
                        </span>
                    </div>
                    
                </div>
                <div class="arrow-column">
                    <span test_id="right_arrow_id" class="arrow-pull-left"></span>
                </div>
            </div>
        </div>
        <div class="container-column mt-20px" *ngIf="selectedPreference === 'multi'">
            <bb-content-html [html]="digitalInboxWidgetItem.confirmPreference.otherAgreementsText"></bb-content-html>
            <div class="multiple-accounts-container multi-agreement mt-20px">
                <div class="container-row multiple-accounts-card section-card-border-color" *ngFor="let account of otherAccounts">
                    <div class="column-blue section-icon-background-color"></div>
                    <div class="car-flex-width section-icon-color">
                        <span class="wss-car-icon"></span>
                    </div>
                    <div class="container-column agreement-text-column">
                        <div class="mt-9px">
                            <bb-content-html *ngIf="account.is_paperless" class="para-inline" [html]="digitalInboxWidgetItem.confirmPreference.selectedPreferencePaperlessText"></bb-content-html>
                            <bb-content-html *ngIf="!account.is_paperless" class="para-inline" [html]="digitalInboxWidgetItem.confirmPreference.selectedPreferencePostText"></bb-content-html>
                        </div>
                        <div class="container-column">
                                <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.confirmPreference.agreementNumberText"></bb-content-html>
                            <span test_id="loginWidgetAccountNumberContent" data-cs-mask>
                                {{account.account_number}}
                            </span>
                        </div>
                        <div class="container-column" test_id="loginWidgetregistrationNo">
                                <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.confirmPreference.vehicleAndRegistrationText"></bb-content-html>
                            <span test_id="vehicle_n_registration_value_id" data-cs-mask>
                                {{account.goods_description}} {{account.registration_no}}
                            </span>
                        </div>
                        
                    </div>
                    <div class="arrow-column">
                        <span test_id="right_arrow_id" class="arrow-pull-left"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-column mt-20px">
            <bb-content-html *ngIf="selectedPreference === 'paperless'" [html]="digitalInboxWidgetItem.confirmPreference.textBelowAccountBoxSingleAgmtPaperless"></bb-content-html>
            <bb-content-html *ngIf="selectedPreference === 'post'" [html]="digitalInboxWidgetItem.confirmPreference.textBelowAccountBoxSingleAgmtPost"></bb-content-html>
            <bb-content-html *ngIf="selectedPreference === 'multi'" [html]="digitalInboxWidgetItem.confirmPreference.textBelowAccountBoxMultiAgmt"></bb-content-html>
        </div>
        
        <div class="container-column desktop-btn-links">
            <div class="container-row desktop-button-center">
                <button *ngIf="selectedPreference === 'paperless'" (click)="confirmPreference()" class="wss-button" tabindex="0" role="button">{{digitalInboxWidgetItem.confirmPreference.confirmBtnSingleAgmtPaperlessText}}</button>
                <button *ngIf="selectedPreference === 'post'" (click)="confirmPreference()" class="wss-button" tabindex="0" role="button">{{digitalInboxWidgetItem.confirmPreference.confirmBtnSingleAgmtPostText}}</button>
                <button *ngIf="selectedPreference === 'multi'" (click)="confirmPreference()" class="wss-button" tabindex="0" role="button">{{digitalInboxWidgetItem.confirmPreference.confirmBtnMultiAgmtText}}</button>
            </div>

            <div class="flex-horizontal-center text-center">
                <bb-content-html [html]="digitalInboxWidgetItem.confirmPreference.pageBottomLinks"></bb-content-html>
                <!-- <bb-content-html *ngIf="(selectedPreference === 'paperless') || (selectedPreference === 'multi')" [html]="digitalInboxWidgetItem.confirmPreference.pageBottomLinks"></bb-content-html>
                <a class="pad-link" *ngIf="selectedPreference === 'post'" (click)="confirmPreference()">{{digitalInboxWidgetItem.confirmPreference.comfirmLinkTextPost}}</a> -->
                
            </div>
            <!-- <div class="flex-horizontal-center text-center mt-9px" *ngIf="selectedPreference === 'post'">
                <bb-content-html  [html]="digitalInboxWidgetItem.confirmPreference.needHelpLinkTextPost"></bb-content-html>
            </div> -->
        </div>
    </div>
</div>