<div class="payment-holiday-container" *ngIf="paymentHolidayWidgetItem$ | async as paymentHolidayWidgetItem">
    <div class="container-column">
        <div class="container-row section-title-container">
            <span class="section-title" test_id="pageName">{{paymentHolidayWidgetItem.paymentHolidayLanding.pageName}}</span>
        </div>
        <div class="container-column holiday-ends" test_id="pageContentText">
            <bb-content-html [html]="paymentHolidayWidgetItem.paymentHolidayLanding.pageContentText"></bb-content-html>
        </div>
        <div class="responsive-row">
            <div class="container-column not-ready">
                <div class="container-column align-middle">
                    <div class="container-column text-center mt-13px" test_id="restartPaymentSectionTextBeforeButton">
                        <bb-content-html
                            [html]="paymentHolidayWidgetItem.paymentHolidayLanding.restartPaymentSectionTextBeforeButton">
                        </bb-content-html>
                    </div>
                    <div class="container-row check-your-options d-flex justify-content-center" (click)="checkYourOptions()">
                        <button role="button" tabindex="0"
                            class="wss-button" test_id="restartPaymentsSectionButtonText">{{paymentHolidayWidgetItem.paymentHolidayLanding.restartPaymentsSectionButtonText}}</button>
                    </div>
                </div>
                <div class="if-you-prefer">
                    <div class="align-middle" test_id="restartPaymentSectionTextAfterButton">
                        <bb-content-html
                            [html]="paymentHolidayWidgetItem.paymentHolidayLanding.restartPaymentSectionTextAfterButton">
                        </bb-content-html>
                    </div>
                </div>
            </div>
            <div class="container-column">
                <span class="when-you-start" test_id="faqTitleText">
                    <bb-content-html
                            [html]="paymentHolidayWidgetItem.paymentHolidayLanding.faqTitleText">
                        </bb-content-html>
                </span>
                <payment-holiday-accordion [faqAccordion]="paymentHolidayWidgetItem.paymentHolidayLanding.faqAccordion">
                </payment-holiday-accordion>
            </div>
        </div>
    </div>
</div>
<!-- <div class="payment-holiday-container">
    <div class="container-column">
        <div class="container-row section-title-container">
            <span class="section-title">{{content[0]?.pmtholiday}}</span>
        </div>
        <div class="container-row holiday-ends">
            <span class="text-semibold">{{content[0]?.holidayends}}</span>
        </div>
        <div class="container-column">
            <span class="text-semibold">{{content[0]?.wewillretart}}.</span>
            <br>
            <span>{{content[0]?.ifyoucan}}.</span>
            <br>
            <span>{{content[0]?.ifyouare}}.</span>
            <br>
            <span>{{content[0]?.scrolldown}}</span>
            <br>
        </div>
        <div class="responsive-row">
            <div class="container-column not-ready">
                <div class="container-column align-middle">
                    <div class="container-column">
                        <span class="text-semibold text-center">{{content[0]?.notready}}?</span>
                        <span class="text-center">{{content[0]?.wecanpersonalize}}.</span>
                    </div>
                    <div class="container-row check-your-options" (click)="checkYourOptions()">
                        <button role="button" tabindex="0" class="wss-button">{{content[0]?.chkuroptions}}</button>
                    </div>
                </div>
                <div class="if-you-prefer">
                    <div class="align-middle">
                        <span>{{content[0]?.ifuprefer}}? {{content[0]?.ucan}} <a class="faq-link" tabindex="0"
                                title="{{content[0]?.git}}">{{content[0]?.git}}</a>
                            {{content[0]?.forhelpnsupport}}</span>
                    </div>

                </div>
            </div>
            <div class="container-column">
                <span class="when-you-start text-semibold">{{content[0]?.whenustart}}</span>
                <payment-holiday-accordion [paymentHolidayAccordion]="paymentHolidayWidgetItem.paymentHolidayAccordion"></payment-holiday-accordion>
            </div>
        </div>
    </div>
</div> -->