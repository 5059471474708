<section class="continue-exit-popup">
    <div class="continue-exit-popup-header">
        <div class="flex-end">
            <div class="mt-2">
                <span class="material-icons" (click)="onContinue()">clear</span>
            </div>
        </div>
    </div>
    <div class="continue-exit-popup-body">
        <h4>
            <bb-content-html [html]="fromParent.alertMessage"> </bb-content-html>
        </h4>
    </div>
    <div class="modal-footer">
        <button type="button" class="wss-button btn-dif-view btn btn-primary btn-md mr-md-3 my-2" (click)="onExit()">
            {{ fromParent.yesButtonText }}
        </button>
        <button type="button" class="wss-button-secondary btn-dif-view btn btn-primary btn-md mr-md-3 my-2"
            (click)="onContinue()">
            {{ fromParent.noButtonText }}
        </button>
    </div>
</section>