import { ServiceException } from '@wss/model/service-exception';
import { BrandContactDetailsItem } from '@wss/model/content/common/brand-contant-details';

export class PaymentHolidayWidgetItem{
    paymentHolidayLanding = new PaymentHolidayLanding();
    triageQuestions = new TriageQuestions();
    yourOptions = new YourOptions();
    cohortFirst = new CohortFirst();
    cohortThird = new CohortThird();
    termExtensionLanding = new TermExtensionLanding();
    currentAgreement = new CurrentAgreement();
    finalizeAgreement = new FinalizeAgreement();
    termExtensionResult = new TermExtensionResult();
    termExtensionDeclined = new TermExtensionDeclined();
    brandContactDetails = new BrandContactDetailsItem();
}

export class PaymentHolidayLanding{
    pageName: string = 'End of payment holiday';
    pageContentText: string = `When your payment holiday comes to an end, your monthly payments will start again. Your monthly payment amount will be the same as before your payment holiday.
    If you can start making payments again, this is your best option as you'll pay less in the long run.
    If you're worried about restarting payments and think you may need more support, we're here to help.
    Scroll down for frequently asked questions.`;
    restartPaymentSectionTextBeforeButton: string = "Are you ready to restart payments?";
    restartPaymentsSectionButtonText: string = "Explore your options";
    restartPaymentSectionTextAfterButton: string = "Would you prefer to speak to someone? You can get in touch for help and support choosing your next steps.";
    faqTitleText: string = "Frequently asked questions";
    faqAccordion = new FAQAccordion();
}
export class FAQAccordion{
    faqQuestion1: string = "Will my payment date change?";
    faqAnswer1: string = `Your payment date may have changed because you had a payment holiday.

    We moved your payment date forward by 30 calendar days each time a payment holiday was applied.
    
    We have sent you a letter with your new payment date. You'll also find this information on your account homepage
    
    If this date does not work for you, you can discuss it with us by calling 0800 000 000.`;

    faqQuestion2: string = "I cancelled my Direct Debit. What do I need to do?";
    faqAnswer2: string = "";

    faqQuestion3: string = "What if I missed a payment?";
    faqAnswer3: string = "";

    faqQuestion4: string = "What is the impact of taking a payment holiday?";
    faqAnswer4: string = "";
}
export class TriageQuestions{
    question1: string = 'Can you make your payments without impacting your ability to pay for essential living costs?';
    question1Option1: string = "Yes, I could pay";
    question1Option2: string = "No, I couldn't pay";
    question2: string = 'What is your current employment status?';
    question2Option1: string = "Employed";
    question2Option2: string = "Furloughed";
    question2Option3: string = "Self-employed";
    question2Option4: string = "Unemployed";
    question2Option5: string = "Retired";
    question3: string = 'What impact has Covid-19 had on your income?';
    question3Option1: string = "No impact";
    question3Option2: string = "Some impact – it's lower";
    question3Option3: string = "Big impact – it's much lower";
    question3Option4: string = "No income";
    triageQuestionsUntimeWarningLabelText: string = "We're sorry, you can't make a term extension right now​";
    triageQuestionsUntimeWarningInfoText: string = "Please try again between the hours of 8:00am and 10:00pm​";
    triageQuestionsBackToHomeButtonText: string = "Back to Home";
}
export class YourOptions{
    pageNameIfTermNotAvailabe: string = 'We need to speak to you';
    pageNameIfTermAvailable: string = 'Next steps';
    paymentHolidayOfferText: string = `We're sorry to hear that you are still having money worries We may be able to extend your payment holiday. Scroll down to explore your options`;
    termExtensionOfferText: string = `We're sorry to hear that you are still having money worries We may be able to offer you a Term Extension or extend your Payment Holiday. Scroll down to explore your options`;
    termExtensionOptionTitle: string = 'Option 1 Term Extension';
    termExtensionOptionContentText: string = "Term extension body";
    termExtensionOptionButtonText: string = "Learn more about Term Extension";
    paymentHolidayOptionTitleIfTermAvailable: string = 'Option 2 Extend your payment holiday';
    paymentHolidayOptionTitleIfTermNotAvailable: string = 'Extending your payment holiday You may be able to extend your payment holiday';
    paymentHolidayOptionContentText: string = "Payment holiday body";
    paymentHolidayOptionContentTextPartTwo: string = "";
    paymentHolidayOptionContentTextPartThree: string = "";
    paymentHolidayOptionContentTextPartFour: string = "";
    otherOptionsTextTitle: string = "";
    otherOptionsText: string = ``;
    otherOptionsTextPartTwo: string = "";
    adviceContentText: string = ``;
    withIn8DaysException: ServiceException = new ServiceException();
}
export class CohortFirst{
    pageName: string = "Restart your payments";
    pageText: string = "Your answer shows that you are ready to restart your monthly payments. Your next payment will be taken on ";
    faqTitleText: string = "Frequently asked questions"
    faqAccordion = new FAQAccordion();
}
export class CohortThird{
    pageName: string = 'We need to speak to you';
    pageContentText: string = `We're sorry to hear that income is still being impacted by coronavirus. We're here to help.`;
    pageContentTitleText: string = "To give you the right support, we need to speak to you. Use our online budgeting tool first to work out what you can afford to pay. You can then call us, on 0344 824 8888";
    pageContentTextPartTwo: string = "so we can talk it through";
    pageContentTextPartThree: string = "Make sure you have all details on your income and outgoings ready before you call. This may include wage slips, bills and bank statements.";
    budgetToolInfoText: string = `Save time on your call
    Before calling, we recommend you fill in our budgeting tool, which will save you time when you speak to us.`;
    budgetToolButtonText: string = 'Launch Budgeting Tool';
    adviceInfoText: string = `Independent help and advice
    Get impartial and free advice to help you deal with money worries
    
    The Financial Conduct Authority website has advice to help people who have money worries because of the Coronavirus pandemic. Visit the FCA website
    The Money Advice Service website can provide information to help you decide the best way to take things forward and keep your payments on track. It also suggests organisations that can help you keep on top of your money. You can get help by telephone and online. Visit the Money Advice Service website`;

}

export class TermExtensionLanding{
 pageName: string = 'Term Extension';
 pageContentText: string = `Here's a few things you should consider before choosing a Term Extension.`;
 accor1TitleText: string = `Will a Term Extension cost me more?`;
 accor1ContentText: string = `You will pay more as you will take longer to pay back the amount borrowed. There may also be additional costs when you choose a Term Extension.

 If you keep your vehicle for longer, you will need to pay for your insurance and road tax for longer
 If you have a warranty or breakdown cover, it may not cover you for the extended time that you will have the vehicle. You may also need to pay for additional MOTs or services
 The value of the vehicle will reduce over time. As you take longer to pay back the finance, the vehicle may be worth less than the outstanding balance`;
 accor2TitleText: string = `Will my agreement change?`;
 accor2ContentTextPCP: string = `
 You will need to sign an agreement to agree to the changes to your payments.

 Your monthly payments will be lower because the term of your agreement will be longer

 If you have already taken a payment holiday before taking a Term Extension, any extra amounts added for taking a payment holiday will no longer be due.`;
 accor2ContentTextHP: string = '';
 accor3TitleText: string = `How much can I afford to pay each month?`;
 accor3ContentTextPCP: string = ``;
 accor3ContentTextHP: string = ``;
 startApplicationButtonText: string = 'Start application';
 pageBottomLink: string = 'Go back';
}

export class  CurrentAgreement{
    pageName: string = 'Term Extension';
    pageTextHP: string = `To help you decide what your new monthly payment amount could be, we've created a handy tool. Enter an amount so you can see the impact it will have on your agreement. Your current agreement`;
    pageTextPCP: string = `To help you decide what your new monthly payment amount could be, we've created a handy tool. Select the option that works best for you. Your current agreement`;
    monthlyPaymentLabel: string = `Current monthly payment amount:`;
    noOfPaymentsLeftLabel: string = `Number of payments left on agreement:`;
    optionalPaymentAmountLabel: string = `Final optional payment amount*:`;
    optionalPaymentDateLabel: string = `Final optional payment amount date:`;
    aprLabel: string = `APR:`;
    totalOutstandingLabel: string = `Total amount outstanding:`;
    hpOptions: HPOptions = new HPOptions();
    pcpOptions: PCPOptions = new PCPOptions();
}

export class HPOptions{
    sectionTitle: string = 'Your new agreement';
    amountFieldLabel: string = 'How much can you afford to pay each month?';
    currencySymbol: string = '£';
    amountFieldPlaceholder: string = 'Enter amount';
    amountFieldErrorMsg: string = 'Amount is required';
    showQuoteButton: string = 'Show quote';
    recalculateButton: string = 'Recalculate quote';
    newQuoteSectionTitle: string = `Your quote for a modified agreement:`;

    newMonthlyPayment: string = 'New monthly payment amount:';
    newNumOfPayments: string = 'Number of payments left on agreement will be:';
    newApr: string = 'APR:';
    newOutstanding: string = 'Total amount outstanding will be:';

    paymentTooLow: string = `We're sorry. The amount you have entered would make your agreement too long for the age of your vehicle.`;
    paymentTooLowPartTwo: string = '';
    paymentTooLowPartThree: string = '';
    amountTooLow: string = `Your quote for a modified agreement:</span>
    <span class="mt-21px">We're sorry. The amount you have entered is too low because it would extend your agreement by more than 24 months.`;
    amountTooLowPartTwo: string = '';
    amountTooLowPartThree: string = '';
    amountTooHigh: string = `We're sorry. The amount you have entered is higher than your current monthly payment. Please try a lower amount.`;
    amountTooHighPartTwo: string = '';
    nextPaymentWillBe: string = 'The next payment would be';
    willBeTakenOn: string = 'and would be taken on';
    finalPaymentWillBe: string = 'The final payment on your agreement would be';
    amountMayDefer: string = 'This is a quote. The actual amounts may differ slightly if you proceed.';
    nextButton: string = 'Next';
    pageBottomLink: string = "Choose another option";
}

export class PCPOptions{
    sectionNote: string = `*This is the same as the Guaranteed Future Value of the vehicle for your goods return
    option.`;
    sectionTitle: string = 'Your quotes:';
    option: string = 'Option';
    monthTerm: string = '-month Term Extension';
    months: string = 'months';
    select: string = 'Select';
    gridMonthRow: string = '-month';

    newMonthlyPayment: string = 'New monthly payment amount:';
    newNumOfPayments: string = 'Number of payments left on agreement will be:';
    newApr: string = 'APR:';
    newOutstanding: string = 'Total amount outstanding will be:';
    newFinalPaymentAmount: string = "Final optional payment amount will be:";
    finalAmountTooltip: string = 'This is the same as the Guaranteed Future Value of the vehicle for your goods return option.';
    newFinalPaymentDate: string = "Final optional payment amount date will be:";

    nextPaymentWillBe: string = 'The next payment would be';
    willBeTakenOn: string = 'and would be taken on';
    finalPaymentWillBe: string = 'The final payment on your agreement would be';
    amountMayDefer: string = `This is a quote. The actual amounts may differ slightly if you proceed. If you are happy with the option
    you have chosen, click 'Next' to progress to the application.`;
    nextButton: string = 'Next';
    pageBottomLink: string = "Go back to your options";  
}

export class FinalizeAgreement{
    pageName: string = 'Term Extension';
    pageText: string = `Before you apply, please take time to consider the details on this page.`;
    sectionTextBeforeAccordion: string = `We will run a credit search We will run a search on your credit file to understand your financial history.`;
    accordionTitle: string = 'How we use credit reference agencies';
    accordionContent: string = `To process your application, we will supply your personal information to Credit Reference`;
    sectionTextAfterAccordion: string = `Affordability`;
    textBeforeCustomerEmail: string = 'The email address we have for you is:';
    textAfterCustomerEmail: string = 'Can we send the new agreement to this email address?';
    radioLabelYes: string = 'Yes';
    radioLabelNo: string = 'No';
    emailAddressLabel: string = 'Email address';
    emailAddressPlaceholder: string = 'eg. name@domain.co.uk';
    cEmailAddressLabel: string = 'Confirm email address';
    cEmailAddressPlaceholder: string = 'eg. name@domain.co.uk';
    letterConfirmationNote: string = `*If your agreement is not regulated by the Consumer Credit Act, you will receive a Term Extension confirmation letter by post. You will need to sign the amendment confirmation slip and return to us.`;
    applyTermSectionTitle: string = 'Apply for a Term Extension';
    applyTermSectionContent: string = 'By checking the box below, I understand and agree that:';
    mandatoryCheckboxAgreeText: string = 'I understand and agree.';
    applyTermBtnText: string = "Apply for a Term Extension";
    termExtensionRequestProcessingText: string = `We are connecting to the Credit Reference Agency. This may take up to 30 seconds.`;
    couldNotConnectCreditAgencyException: ServiceException = couldNotConnect;
    unableToDoOnlineException: ServiceException = unableTodoOnline;
    emailRequiredErrorMsg: string = "Email is required";
    emailInvalidErrorMsg: string = "Email must be a valid email address";
    cEmailRequiredErrorMsg: string = "Confirm email is required";
    cEmailMustMatchErrorMsg: string = "Confirm email must match";
}

export class TermExtensionResult{
    pageTopSuccessTxt: string = `Good news. We're pleased to offer you a Term Extension.`;
    nextStepsSectionTitle: string = 'Next steps';
    nextStepsNote1: string = ``;
    nextStepsNote2TextBeforeReturnBydate: string = `You'll need to read, sign and
    send your agreement to us by `;
    nextStepsNote2TextAFterReturnBydate: string = `. This is so your modified agreement will
    start on your next payment date. If you do not send your agreement back to us by this date
    it may not be processed in time before your next monthly repayment is due.`;
    nextStepsNote3: string = `Your modified agreement will
    start on your next payment due date.`;
    tooltipLinkText: string = `Agreements not regulated by the Consumer Credit Act`;
    tooltipContent: string = `*If your agreement is not regulated by the Consumer Credit Act, you will receive a Term Extension confirmation letter by post. You will need to sign the amendment confirmation slip and return to us.`;
    whatWeWillSendText: string = `What we'll send you`;
    whatYouNeedToDoTitleText: string = 'What you need to do';
    whatYouNeedToDoTextBeforeReturnbydate: string = `You'll need to read, sign and send your agreement to us by`;
    whatYouNeedToDoTextAfterReturnbydate: string = `This is so your new agreement will start on your next payment date. If you do not send your
    agreement back to us by this date it may not be processed in time before your next monthly repayment
    is due.`;
    whatYouNeedToDoTextAfterReturnbydatePartOne: string = '';
    whatYouNeedToDoTextAfterReturnbydatePartTwo: string = '';
    whatYouNeedToDoTextAfterReturnbydatePartThree: string = '';
    backToHomeButtonText: string = 'Back to Home';
}

export class TermExtensionDeclined{
    topSectionText: string = `We're sorry. We're not able to offer you a Term Extension.`;
    // pageContentText: string = `Our decision has been made using information obtained from TransUnion Credit Reference
    // Agency.`;
    topSectionTextPartTwo: string = "We're sorry to hear you're still having money worries. To give you the right support, we need to speak to you. Call us on";
    topSectionTextPartThree: string = "Make sure you have all details on your income and outgoings ready before you call. This may include wage slips, bills and bank statements.";
    budgetToolSectionText: string = `Save time on your call 
    Before calling, we recommend you fill in our Budgeting Tool, which
    will save you time when you speak to us.`;
    budgetToolButtonText: string = 'Launch Budgeting Tool';
    ourDecisionSection: string = `How we made our decision`;
    adviceInfoSection: string = `Independent help and advice​`;
}

const couldNotConnect = new ServiceException();
couldNotConnect.imageClass = "service-timeout";
couldNotConnect.errorInfoText = `We were not able to connect with the Credit Reference Agency. Please wait 30 seconds and click the button below to retry.`;
couldNotConnect.buttonText = 'Resubmit';
couldNotConnect.componentUri = '#/payment-holidays/finalize-agreement';
couldNotConnect.cancelLink = 'Cancel';

const unableTodoOnline = new ServiceException();
unableTodoOnline.imageClass = "service-wallet-error";
unableTodoOnline.errorInfoText = `We were not able to connect with the Credit Reference Agency. Please try again later today.`;
unableTodoOnline.buttonText = 'Back to Home';
unableTodoOnline.componentUri = '#/myaccount';
unableTodoOnline.cancelLink = '';

// export class BrandContactDetailsItem {
//     brandWiseAddress: string = "";
//     brandWiseContactNumber: string = "";
//     brandWiseContactEmail: string = "";
//     brandWiseCfaNumber: string = "";
//     portalName: string = "";
//     mainCollectionsLineNumber: string = "";
//     legalNumber: string = "";
// }

export const paymentHolidayContent = new PaymentHolidayWidgetItem();



