<div class="login-container" *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <div class="reg-row" role="alert" *ngIf="serviceError">         
    <bb-wss-banner-message [errorCode]="errorCode"></bb-wss-banner-message>
  </div>
  <div class="mt-20px">
    <bb-wss-common-icon [className]="'security-fail-icon'" [imgText]="'Security Fail'"></bb-wss-common-icon>
  </div>
  <div class="col3 text-center" role="alert" test_id="pageHeaderText">
    <bb-content-html [html]="loginWidgetItem.forgotUserNamePassCodeError.pageHeaderText"></bb-content-html>
  </div>
  <div class="col3 text-center" role="alert" test_id="pageDescriptionText">
    <bb-content-html [html]="loginWidgetItem.forgotUserNamePassCodeError.pageDescriptionText"></bb-content-html>
  </div>
  <div class="text-center">
    <button bbButton type="button" aria-label="reSendButton" [className]="'wss-button btn-full-width'"
      (click)="submit()"
      test_id="tryAgainButtonText">{{loginWidgetItem.forgotUserNamePassCodeError.resendButtonText}}
    </button>
    <button bbButton type="button" aria-label="loginButton" [className]="'wss-button-secondary btn-full-width'"
      (click)="navigateToLogin()"
      test_id="loginButtonText">{{loginWidgetItem.forgotUserNamePassCodeError.loginButtonText}}
    </button>
  </div>
</div>