<form *ngIf="displayMileageCalculator && endOfContractWidgetItem" (ngSubmit)="calcFutureMilesButtonPress()">
  <div class="card traffic-light-card mb-20px">
    <h3 class="card-header">{{endOfContractWidgetItem.eocTrafficLight.trafficLightTitle}}</h3>
    <bb-content-html id="traffic-light-description"
      [html]="endOfContractWidgetItem.eocTrafficLight.trafficLightCalculationExplaination">
    </bb-content-html>
    <div class="traffic-light-container">
      <svg width="300px" height="150px" viewBox="0 0 300 150" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.99895 150.47C2.23794 150.469 -0.00871124 148.23 0.0833123 145.47C0.903587 120.873 7.76813 96.8275 20.104 75.4707C32.4401 54.1137 49.8402 36.1508 70.7394 23.1463C73.0837 21.6875 76.146 22.5146 77.5262 24.906L150.016 150.5L4.99895 150.47Z"
          fill="#549C30" />
        <path
          d="M77.5007 24.9226C76.1197 22.531 76.9355 19.4647 79.372 18.1645C101.088 6.57644 125.349 0.497418 150.016 0.500001C174.683 0.502583 198.942 6.58684 220.656 18.1798C223.092 19.4803 223.908 22.5462 222.527 24.9375L150.016 150.5L77.5007 24.9226Z"
          fill="#FFBF00" />
        <path
          d="M222.5 24.9224C223.881 22.5308 226.944 21.7039 229.288 23.1634C250.184 36.1722 267.58 54.1386 279.912 75.4979C292.243 96.8573 299.103 120.904 299.918 145.502C300.009 148.261 297.762 150.5 295.001 150.5H150.016L222.5 24.9224Z"
          fill="#B81B0E" />
        <line [attr.x1]="xPoint1" [attr.y1]="yPoint1" [attr.x2]="xPoint2" [attr.y2]="yPoint2" stroke="black"
          stroke-width="3" stroke-linecap="round" opacity="0.7" />
      </svg>
      <h4 class="mt-10px card-header-sub-title">Please enter your current mileage</h4>
      <input min="0" type="number" id="eoc-traffic-light-input" class="form-control form-control-lg rounded-2 amount-input"
        [(ngModel)]="currentMileageInputValue" placeholder={{endOfContractWidgetItem.eocTrafficLight.trafficLightInputPlaceholder}}>
      <div id="traffic-light-content-box" class="card-content pb-0" [ngSwitch]="trafficLightColour">
        <bb-content-html *ngSwitchCase="'green'"
          [html]="endOfContractWidgetItem.eocTrafficLight.trafficLightMainParagraphNoLiability"></bb-content-html>
        <bb-content-html *ngSwitchCase="'amber'"
          [html]="endOfContractWidgetItem.eocTrafficLight.trafficLightMainParagraphPossibleLiability"></bb-content-html>
        <bb-content-html *ngSwitchCase="'red'"
          [html]="endOfContractWidgetItem.eocTrafficLight.trafficLightMainParagraphWithLiability"></bb-content-html>
        <bb-content-html *ngSwitchDefault
          [html]="endOfContractWidgetItem.eocTrafficLight.trafficLightMainParagraphDefault">
        </bb-content-html>
      </div>
      <button type="submit" id="eoc-traffic-light-button"
        class="wss-button">{{endOfContractWidgetItem.eocTrafficLight.trafficLightButtonText}}</button>
    </div>
  </div>
</form>