<div class="next-payment-container">
    <div class="section-sub-title">
        <span>
            <!-- {{content[0]?.title}} -->
            <!-- {{nextPaymentContent.title}} -->
            <bb-content-html [html]="nextPaymentContent?.title">
            </bb-content-html>
        </span>
    </div>
    <div class="container-column mt-17px">
        <div class="container-row" *ngIf="nextPaymentData?.isAmountActive">
            <span class="material-icons" title="Amount due">alarm_on</span>
            <div class="container-column margin-left-20">
                <span class="para-inline">
                    <!-- class="text-semibold" -->
                    <!-- {{content[0]?.amount}} -->
                    <bb-content-html [html]="nextPaymentContent?.amount">
                    </bb-content-html>
                </span>
                <span>{{nextPaymentData?.next_payment_amount}}</span>
            </div>
        </div>
        <div [ngClass]="nextPaymentData?.isAmountActive?'container-row mt-14px': 'container-row'"
            *ngIf="nextPaymentData?.isDateActive">
            <span class="event-icon" title="Due date"></span>
            <div class="container-column margin-left-20">
                <span class="para-inline">
                    <!-- class="text-semibold" -->
                    <!-- {{content[0]?.ddue}} -->
                    <bb-content-html [html]="nextPaymentContent?.ddue">
                    </bb-content-html>
                </span>
                <span>{{nextPaymentData?.next_payment_date}}</span>                
                <a href="javascript:void(0)" role="navigation" tabindex="0" title="Change payment date" (click)="navigateTo()" *ngIf="nextPaymentData?.isChangeDateLinkActive">
                    <!-- {{content[0]?.cpd}} -->
                    {{nextPaymentContent?.cpd}}
                </a>
            </div>
        </div>
    </div>
</div>