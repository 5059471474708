import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';
import { LoginWidgetItem } from '@wss/model/login-widget/login-widget';
import { Router } from '@angular/router';
import { RegisterService } from '@wss/registration-widget/src/service/register.service';
import { takeUntil } from 'rxjs/operators';
import { isErrServer } from '@wss/common/util/util';
import { DatastoreService } from '@wss/service/datastore.service';

@Component({
  selector: 'bb-wss-unable-identify-customer',
  templateUrl: './unable-identify-customer.component.html',
  providers: [ContentService],
})
export class UnableIdentifyCustomerComponent implements OnInit {
  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>(
    'loginWidgetItem',
  );

  ngOnInit(): void {
    this.tealiumService.view({
      JourneyName: 'Login',
      JourneyStepName: 'Unable to Identify Customer',
      CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    });
  }

  constructor(
    public router: Router,
    private tealiumService: TealiumUtagService,
    private readonly bbContentService: ContentService,
  ) {}

  backToLoginCall() {
    this.router.navigate(['#/user-login']);
  }

}
