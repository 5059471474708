import { Injectable } from '@angular/core';
import { HttpService } from '@wss/service/http.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DatastoreService } from '@wss/service/datastore.service';
import { MessageService } from '@wss/service/message.service';
import { ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { StorageService } from '@wss/service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class BalloonRefinanceService {

  constructor(
    private httpService: HttpService,
    private datatstore: DatastoreService,
    private msgService: MessageService,
    private storageService: StorageService
  ) { }

  postProposalDetails(updateProposal: ProposalRequest) {
    return this.httpService.postProposalDetails(updateProposal)
    .pipe(
      map((res: any) => {      
        this.datatstore.setProposalRequest(res);
        return res;
      },
      catchError((error: any) => {
        this.msgService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      }))
    )
  }

  getProposalDetails() {
    return this.httpService.getProposalDetails()
    .pipe(
      map((res: any) => {
        this.datatstore.setProposalRequest(res);
        return res;
      },
      catchError((error: any) => {
        this.msgService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      }))
    )
  }

  getAdditionalAddressDetails() {
    return this.httpService.getAdditionalAddress()
    .pipe(
      map((res: any) => {
        return res;
      },
      catchError((error: any) => {
        this.msgService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      }))
    )
  }

  postCustomerComments(payload: any) {
    return this.httpService.postCustomerComments(payload)
    .pipe(
      map((res: any) => {
        return res;
      },
      catchError((error: any) => {
        this.msgService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      }))
    )
  }
  
  getGfvData(payload: any) {
    return this.httpService.getGfv(payload)
    .pipe(
      map((res: any) => {
        this.storageService.setLocalStorage('gfvDetails', JSON.stringify(res));
        return res;
      },
      catchError((error: any) => {
        this.msgService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      }))
    )
  }

  postCustomer(payload: any, vehicleType: string) {
    return this.httpService.postCustomer(payload, vehicleType)
    .pipe(
      map((res: any) => {      
        this.datatstore.setProposalCustomerDetails(res);
        return res;
      },
      catchError((error: any) => {
        this.msgService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      }))
    )
  }

  getDecision(proposalId: any, palisCustomerId: any, vehicleType: string) {
    return this.httpService.getDecision(proposalId, palisCustomerId, vehicleType)
    .pipe(
      map((res: any) => {
        this.datatstore.setDecisionData(res);
        return res;
      },
      catchError((error: any) => {
        this.msgService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      }))
    )
  }

  quoteData: any = {
    refinanceAmount: 5000,
    totalAmount: 5295,
    apr: "5.9%",
    monthlyPayment: 0,
    noOfInstallments: 0
  }

  setQuoteData(data: any) {
    this.quoteData = data;
  }

  getQuoteData() {
    return this.quoteData;
  }

  getUserDetails(): Observable<any> {
    return this.httpService.getUserDetails()
    .pipe(
      map((res: any) => {
        return res
      },
      catchError((err: any) => {
        return err
      })
      )
    )
  }

  getVehicleById(id: string): Observable<any> {
    return this.httpService.getVehicleById(id)
    .pipe(
      map((res:any) => {
        return res
      },
      catchError((err: any) => {
        return err
      }))
    )
  }

}
