<div class="multiple-accounts-container section-background-color " *ngIf="loginWidgetItem$ | async as loginWidgetItem">

    <div class="label-style">
        <span class="section-label-size" test_id="loginWidgetlogTittleContent" *ngIf="previousPage == 'forgotPassword'">
            <!-- {{content[0].login}} -->
            
            <!-- {{loginWidgetItem.multipleAccount.logTittle}} -->
            
            <bb-content-html [html]="loginWidgetItem.multipleAccount.logTittle"></bb-content-html>

        </span>
        <span class="section-label-size" test_id="loginWidgetlogTittleContent" *ngIf="previousPage == 'forgotUsername'">
            <p>Forgot Username?</p>
        </span>
        <hr/>
        <div test_id="loginWidgetchooseTextContent">
            <!-- {{content[0].choose}}: -->
            <!-- {{loginWidgetItem.multipleAccount.chooseText}} -->
            <bb-content-html [html]="loginWidgetItem.multipleAccount.chooseText"></bb-content-html>
        </div>
        <div class="container-row multiple-accounts-card section-card-border-color  margin-top-20" 
        *ngFor="let acc of nonCompletedAccountList;let i = index;">
     
           <div class="column-blue section-icon-background-color"></div>
            <div class="car-flex-width section-icon-color">
               <span class="wss-car-icon"></span>
            </div>
            <div class="container-column agreement-text-column no-para-margin" (click)="selectAccNo(acc.account_number)">
                <div class="container-column">
                    <strong test_id="loginWidgetagreementTextContent">
                        <!-- {{content[0].agreement}} -->
                        <bb-content-html [html]="loginWidgetItem.multipleAccount.agreementText"></bb-content-html>
                    </strong>
                    <span test_id="loginWidgetAccountNumberContent" data-cs-mask>
                        {{acc.account_number}}
                    </span>
                </div>
                <div class="container-column" test_id="loginWidgetregistrationNo">
                    <strong test_id="loginWidgetvehicletextContent"><a ></a>
                        <!-- {{content[0].vehicle}} -->
                        <bb-content-html [html]="loginWidgetItem.multipleAccount.vehicletext"></bb-content-html>
                    </strong>
                    <span test_id="vehicle_n_registration_value_id" data-cs-mask>
                        {{acc.goods_description}} {{acc.registration_no}}
                    </span>
                </div>
            </div>
            <div class="arrow-column" (click)="selectAccNo(acc.account_number)">
                <span test_id="right_arrow_id" class="arrow-pull-left"></span>
            </div>
           
           
        </div>

        <div class="container-row multiple-accounts-card section-card-border-color  margin-top-20 ribbon-box" 
        *ngFor="let acc of completedAccountList;let i = index;">
       
           <div class="column-blue section-icon-background-color bor-rad-l-8"></div>
            <div class="car-flex-width section-icon-color">
               <span class="wss-car-icon"></span>
            </div>
            <div class="container-column agreement-text-column no-para-margin" (click)="selectAccNo(acc.account_number)">
                <div class="container-column">
                    <strong test_id="loginWidgetagreementTextContent">
                        <!-- {{content[0].agreement}} -->
                        <bb-content-html [html]="loginWidgetItem.multipleAccount.agreementText"></bb-content-html>
                    </strong>
                    <span test_id="loginWidgetAccountNumberContent" data-cs-mask>
                        {{acc.account_number}}
                    </span>
                </div>
                <div class="container-column" test_id="loginWidgetregistrationNo">
                    <strong test_id="loginWidgetvehicletextContent"><a ></a>
                        <!-- {{content[0].vehicle}} -->
                        <bb-content-html [html]="loginWidgetItem.multipleAccount.vehicletext"></bb-content-html>
                    </strong>
                    <span test_id="vehicle_n_registration_value_id" data-cs-mask>
                        {{acc.goods_description}} {{acc.registration_no}}
                    </span>
                </div>
            </div>
            <div class="arrow-column" (click)="selectAccNo(acc.account_number)">
                <span test_id="right_arrow_id" class="arrow-pull-left"></span>
            </div>
            <!-- to be removed -->
            <div class="ribbon ribbon-top-right">
                <span>Completed</span>
            </div>
              <!-- to be removed -->
        </div>
        <div class="register-link no-para-margin" style="justify-content: center;" test_id="loginWidgetbacklinkContent">           
            <bb-content-html [html]="loginWidgetItem.multipleAccount.backLoginlink"></bb-content-html>
        </div>
        <div class="register-link" style="justify-content: center;" test_id="loginWidgetneedHelplinkContent">
            <bb-content-html [html]="loginWidgetItem.multipleAccount.needHelplink"></bb-content-html>
        </div>
    </div>

</div>