<div class="hc-my-agreement-page" *ngIf="helpCentreWidgetItem">
	<div class="container-column">
		<div class="help-centre-title">
			<span class="section-label-title" test_id="helpCentreAgreementPageName">
				{{helpCentreWidgetItem.helpCentreAgreement.pageName}}</span>
		</div>
		<div class="customer-support-text margin-top-30">
			<div test_id="helpCentreAgreementPageHeaderBelowText">
				<bb-content-html [html]="helpCentreWidgetItem.helpCentreAgreement.pageHeaderBelowText"></bb-content-html>
			</div>

			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="helpCentreAgreementfirstHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreAgreement.firstHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreAgreement.myAgreementFirstAccordion" testIDText="helpCentreAgreementFirstAccordion"></bb-common-expansion-panel>
				</div>
			</div>

			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="helpCentreAgreementsecondHeaderTitle">
					<h3><strong>
						<bb-content-html [html]="helpCentreWidgetItem.helpCentreAgreement.secondHeaderTitle"></bb-content-html>
					</strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreAgreement.myAgreementSecondAccordion" testIDText="helpCentreAgreementSecondAccordion"></bb-common-expansion-panel>
				</div>
			</div>

			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="helpCentreAgreementthirdHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreAgreement.thirdHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreAgreement.myAgreementThirdAccordion" testIDText="helpCentreAgreementThirdAccordion"></bb-common-expansion-panel>
				</div>
			</div>

			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="helpCentreAgreementfourthHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreAgreement.fourthHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreAgreement.myAgreementFourthAccordion" testIDText="helpCentreAgreementFourthAccordion"></bb-common-expansion-panel>
				</div>
			</div>

			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="helpCentreAgreementFifthHeaderTitle" id="eoc-faq">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreAgreement.fifthHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreAgreement.myAgreementFifthAccordion" testIDText="helpCentreAgreementFifthAccordion"></bb-common-expansion-panel>
				</div>
			</div>
		</div>
	</div>
</div>