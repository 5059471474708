<div *ngIf="endOfContractWidgetItem$ | async as endOfContractWidgetItem">
    <div class="section-title-container">
        <div class="title-container-padding">
        <h1 class="section-label-title" test_id="pageName">
            {{endOfContractWidgetItem.endOfContractPartExchange.pageName}}</h1>
        </div>
    </div>
    <div>
        <div role="alert" *ngIf="serviceError">
            <bb-wss-banner-message></bb-wss-banner-message>
        </div>
        <div class="eoc-container">
            <div class="mt-5px mb-20px" test_id="textBelowPageHeader">
                <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchange.textBelowPageHeader">
                </bb-content-html>
                <div class="para-inline">
                    <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchange.settlementTextBeforeBrand">
                    </bb-content-html>
                    <ng-container *ngIf="endOfContractWidgetItem.brandContactDetails">
                        <bb-content-html [html]="endOfContractWidgetItem.brandContactDetails.portalName"></bb-content-html>
                    </ng-container>
                    <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchange.settlementTextAfterBrand">
                    </bb-content-html>
                </div>
            </div>
            <!-- <div class="mb-45px"> -->
            <div test_id="howItWorksText">{{endOfContractWidgetItem.endOfContractPartExchange.howItWorksText}}
            </div>
            <div class="part-exchange">
                <ol class="pl-15px">
                    <li class="m-tb-20">
                        <div class="font-weight-bold" test_id="howItWorksFirstPointHeader">
                            {{endOfContractWidgetItem.endOfContractPartExchange.howItWorksFirstPointHeader}}</div>
                        <div test_id="howItWorksFirstPointContent">
                            <bb-content-html
                                [html]="endOfContractWidgetItem.endOfContractPartExchange.howItWorksFirstPointContent">
                            </bb-content-html>
                        </div>
                    <li class="m-tb-20">
                        <div class="font-weight-bold" test_id="howItWorksSecondPointHeader">
                            {{endOfContractWidgetItem.endOfContractPartExchange.howItWorksSecondPointHeader}}</div>
                        <div test_id="howItWorksSecondPointContent">
                            <bb-content-html
                                [html]="endOfContractWidgetItem.endOfContractPartExchange.howItWorksSecondPointContent">
                            </bb-content-html>
                        </div>
                    </li>
                    <li class="m-tb-20">
                        <div class="font-weight-bold" test_id="howItWorksThirdPointHeader">{{
                            endOfContractWidgetItem.endOfContractPartExchange.howItWorksThirdPointHeader }}</div>
                        <div test_id="howItWorksThirdPointBeforeData">{{
                            endOfContractWidgetItem.endOfContractPartExchange.howItWorksThirdPointBeforeData
                            }}&nbsp;<span>£{{settlementData?.settlement_figure}}</span>
                            {{ endOfContractWidgetItem.endOfContractPartExchange.howItWorksThirdPointBetweenData
                            }}&nbsp;<span>{{settlementData?.settlement_expiry_date}}</span>
                            {{ endOfContractWidgetItem.endOfContractPartExchange.howItWorksThirdPointAfterData }}</div>

                    </li>
                    <li class="m-tb-20">
                        <div class="font-weight-bold" test_id="howItWorksFourthPointHeader">
                            {{endOfContractWidgetItem.endOfContractPartExchange.howItWorksFourthPointHeader}}</div>
                        <div test_id="howItWorksFourthPointContent">
                            <bb-content-html
                                [html]="endOfContractWidgetItem.endOfContractPartExchange.howItWorksFourthPointContent">
                            </bb-content-html>
                        </div>
                    </li>
                </ol>
            </div>

        </div>
        <div class="mb-5 eoc-container">
            <div class="mb-45px">
                <h2 test_id="whatYouNeedToKnowText" class="font-size-20 mb-25px pt-10px">
                    {{ endOfContractWidgetItem.endOfContractPartExchange.whatYouNeedToKnowText }}</h2>
                <div class="container-row expansion-border-hidden transparent-accordion" role="row">
                    <mat-accordion class="accordion mat-accordion-override w-100 term-ext" role="accordion">
                        <mat-expansion-panel
                            class="mat-expansion-panel-override mat-expansion-panel-spacing-override  p-1">
                            <mat-expansion-panel-header class="mat-panel-header-override">
                                <mat-panel-title class="mat-expansion-panel-header-title-override"
                                    test_id="firstAccordionHeader">
                                    <strong> {{ endOfContractWidgetItem.endOfContractPartExchange.firstAccordionHeader
                                        }} </strong>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="margin-top-10" test_id="firstAccordionDetails">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractPartExchange.firstAccordionDetails">
                                </bb-content-html>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel
                            class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                            <mat-expansion-panel-header class="mat-panel-header-override">
                                <mat-panel-title class="mat-expansion-panel-header-title-override"
                                    test_id="secondAccordionHeader">
                                    <strong> {{ endOfContractWidgetItem.endOfContractPartExchange.secondAccordionHeader
                                        }} </strong>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="margin-top-10" test_id="secondAccordionDetails">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractPartExchange.secondAccordionDetails">
                                </bb-content-html>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel
                            class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                            <mat-expansion-panel-header class="mat-panel-header-override">
                                <mat-panel-title class="mat-expansion-panel-header-title-override"
                                    test_id="thirdAccordionHeader">
                                    <strong> {{ endOfContractWidgetItem.endOfContractPartExchange.thirdAccordionHeader
                                        }}</strong>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="margin-top-10" test_id="thirdAccordionDetails">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractPartExchange.thirdAccordionDetails">
                                </bb-content-html>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel
                            class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                            <mat-expansion-panel-header class="mat-panel-header-override">
                                <mat-panel-title class="mat-expansion-panel-header-title-override"
                                    test_id="fourthAccordionHeader">
                                    <strong> {{ endOfContractWidgetItem.endOfContractPartExchange.fourthAccordionHeader
                                        }} </strong>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="margin-top-10" test_id="fourthAccordionDetails">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractPartExchange.fourthAccordionDetails">
                                </bb-content-html>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel
                            class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                            <mat-expansion-panel-header class="mat-panel-header-override">
                                <mat-panel-title class="mat-expansion-panel-header-title-override"
                                    test_id="fifthAccordionHeader">
                                    <strong> {{ endOfContractWidgetItem.endOfContractPartExchange.fifthAccordionHeader
                                        }} </strong>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="margin-top-10" test_id="fifthAccordionDetails">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractPartExchange.fifthAccordionDetails">
                                </bb-content-html>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            <div class="card">
                <div class="card-content text-center">
                    <div class="p-mb-10px" test_id="wantToPartExchangeVehicleText">
                        <bb-content-html
                            [html]="endOfContractWidgetItem.endOfContractPartExchange.wantToPartExchangeVehicleText">
                        </bb-content-html>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class="wss-button" (click)="navigateToPage(confirmModal)"
                            test_id="partExchangeVehicleButtonText">
                            <!-- [disabled]="!withinNinetyDays || fileTagFlag || !inGoodsScope" -->
                            {{ endOfContractWidgetItem.endOfContractPartExchange.partExchangeVehicleButtonText }}
                        </button>
                    </div>
                    <!-- <span *ngIf="!withinNinetyDays">
                        <bb-content-html
                            [html]="endOfContractWidgetItem.endOfContractPartExchange.optionDisableInfoText">
                        </bb-content-html>
                    </span> -->
                    <a routerLink="/eoc-options/landing"
                        test_id="backToOptionLink">{{endOfContractWidgetItem.endOfContractPartExchange.backToOptionLink}}</a>
                </div>
                <div class="card-footer" test_id="haveQuestionsText">
                    <span class="margin-top-10 text-center">
                        <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchange.haveQuestionsText">
                        </bb-content-html>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <ng-template #confirmModal let-modal>
        <div class="modal-body">
            <div class="container-row">
                <div class="container-column">
                    <div test_id="confirmPopupText">
                        <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchange.confirmPopupText">
                        </bb-content-html>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class="wss-button" (click)="confirmSelection()" test_id="confirmButtonText">
                            {{ endOfContractWidgetItem.endOfContractPartExchange.confirmButtonText }}
                        </button>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class="wss-button-secondary" (click)="modal.dismiss()" test_id="backButtonText">
                            {{ endOfContractWidgetItem.endOfContractPartExchange.backButtonText }}
                        </button>
                    </div>
                </div>
                <span (click)="modal.dismiss()" class="material-icons">clear</span>
            </div>

        </div>
    </ng-template>
</div>