<div class="login-container" *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <span class="section-label-size" test_id="which_account_label_id">

    {{loginWidgetItem.accountLock.titleText}}
  </span>
  <hr />

  <div class="account-lock" test_id="loginWidgetItempaymentwarningContent">

    <div class="payment-warning-icon-div">
      <div class="acc-locked-icon"></div>
    </div>

    <span test_id="loginWidgetItemunableLoginContent" class="account-locked-message">
      <bb-content-html [html]="loginWidgetItem.accountLock.unableLogin"></bb-content-html>
    </span>
    <span class="contact-number-text " test_id="brandWiseContactNumber" *ngIf="loginWidgetItem.brandContactDetails">
      <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html>
    </span>
    <div class="row margin-top-between-rows" style="justify-content: center;"
      test_id="loginWidgetItemaccountLockContent">
      <!-- <a href="#" style="margin: auto;" tabindex="0" title="{{content[0].need_help}}">
    {{content[0].need_help}}
  </a> -->
      <bb-content-html [html]="loginWidgetItem.accountLock.needhelpLink"></bb-content-html>
    </div>

    <br>

    <span test_id="loginWidgettouchLinkContent">

      <span class="child-para-inline mr-2">
        <bb-content-html [html]="loginWidgetItem.accountLock.callService"></bb-content-html>
      </span>
      <span class="child-para-inline ">
        <bb-content-html [html]="loginWidgetItem.accountLock.touchLink"></bb-content-html>
      </span>
    </span>
    <br>
    
  </div>




  <!-- <form class="form" role="form" autocomplete="off" id="formLogin" novalidate="" method="POST">
        <div class="form-group">
            <label for="uname1">Email address</label>
            <input type="text" class="form-control form-control-lg rounded-2" style="width:100%;height:40px" name="uname1" id="uname1" required="" placeHolder="Email address here">
            <div class="invalid-feedback">Oops, you missed this one.</div>
        </div>
        <div class="form-group">
            <label>Password</label>
            <input type="password" class="form-control form-control-lg rounded-2" style="width:100%; height:40px" id="pwd1" required="" autocomplete="new-password"  placeHolder="Password here">
            <div class="invalid-feedback">Enter your password too!</div>
        </div>
        <p>
          <a href="#">
            Forgetten your password
          </a>
        </p>
        <div class="row margin-top-between-rows">
          <button id="bb-button-to" bbButton type="button" aria-label="Favourite" (click)="submit()"
                  [className]="'wss-button-login'">Login
          </button>
        </div>
        <div class="register-link">
          <a href="#">Not registered yet?</a>
        </div>
  
    </form> -->


</div>