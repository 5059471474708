<section class="d-flex flex-row flex-start pt-20px" *ngIf="((deviceType === 'Tablet') || (deviceType === 'Desktop')) && (!isBrReturnJourney && !isQuoteReturnJourney)">
    <div class="mr-auto link-margin ml-3x">
        <a *ngIf="!isAgreementPage" [routerLink]="[previousPageLink]" routerLinkActive="router-link-active" [id]="linkTextId" test_id="linkTextId">{{content.linkText}}</a>
    </div>
    <button class="wss-button-secondary mr-3 mt-0" (click)="openModal(confirmModal)" [id]="secondaryButtonTestId" test_id="secondaryButtonTestId" [disabled]="isSecondaryDisabled">
        <span *ngIf="!isAgreementPage">{{content.secondaryButtonText}}</span>
        <span *ngIf="isAgreementPage && !isWithin14To6DaysOfExpiryDate">{{content.secondaryButtonText}}</span>
        <span *ngIf="isAgreementPage && isWithin14To6DaysOfExpiryDate">{{content.iDontWantToProceedButtonText}}</span>
    </button>
    <button class="wss-button mt-0" (click)="onContinue()" [id]="primaryButtonTestId" test_id="primaryButtonTestId" [disabled]="isPrimaryDisabled">
        <span *ngIf="isAgreementPage">{{content.startButtonText}}</span>
        <span *ngIf="currentPage != 6 && !isAgreementPage">{{content.primaryButtonText}}</span>
        <span *ngIf="currentPage === 6">{{content.submitButtonText}}</span>
    </button>  
</section>
<section class="d-flex justify-content-end" *ngIf="((deviceType === 'Tablet') || (deviceType === 'Desktop')) && (isBrReturnJourney || isQuoteReturnJourney)">
    <button class="wss-button-secondary mt-20px-imp mr-3" (click)="onCancel()" [id]="secondaryButtonTestId">{{content.cancelButtonText}}</button>
    <button class="wss-button" [disabled]="saveAndReviewBtnDisable" (click)="onContinue()" [id]="primaryButtonTestId">{{content.saveAndReviewButtonText}}</button>
</section>
<section class="d-flex flex-column" *ngIf="deviceType === 'Mobile' && (!isBrReturnJourney && !isQuoteReturnJourney)">
    <button class="wss-button mb-0" (click)="onContinue()" [id]="primaryButtonTestId" [disabled]="isPrimaryDisabled">
        <span *ngIf="isAgreementPage">{{content.startButtonText}}</span>
        <span *ngIf="currentPage != 6 && !isAgreementPage">{{content.primaryButtonText}}</span>
        <span *ngIf="currentPage === 6">{{content.submitButtonText}}</span>
    </button>  
    <button class="wss-button-secondary" (click)="openModal(confirmModal)" [id]="secondaryButtonTestId" [disabled]="isSecondaryDisabled">
        <span *ngIf="!isAgreementPage">{{content.secondaryButtonText}}</span>
        <span *ngIf="isAgreementPage && !isWithin14To6DaysOfExpiryDate">{{content.secondaryButtonText}}</span>
        <span *ngIf="isAgreementPage && isWithin14To6DaysOfExpiryDate">{{content.iDontWantToProceedButtonText}}</span>
    </button>
    <div class="mr-auto m-auto ml-3x">
        <a *ngIf="!isAgreementPage" [routerLink]="[previousPageLink]" routerLinkActive="router-link-active" [id]="linkTextId" test_id="linkTextId">{{content.linkText}}</a>
    </div>
</section>
<section class="d-flex flex-column" *ngIf="deviceType === 'Mobile' && (isBrReturnJourney || isQuoteReturnJourney)">
    <button class="wss-button  mb-0" [disabled]="saveAndReviewBtnDisable" (click)="onContinue()" [id]="primaryButtonTestId">{{content.saveAndReviewButtonText}}</button>
    <button class="wss-button-secondary" (click)="onCancel()" [id]="secondaryButtonTestId">{{content.cancelButtonText}}</button>
</section>
<ng-template #confirmModal let-modal>
    <div class="modal-body save-exit-modal-border">
        <div class="container-row">
            <div class="container-column mr-auto">
                <div test_id="confirmPopupText">
                    <div *ngIf="!isWithin14To6DaysOfExpiryDate">
                        <div class="content-text-bold" [id]="modalHeaderTestId">
                            <bb-content-html [html]="content.modalHeader"></bb-content-html>    
                        </div>
                        <div *ngIf="!isAgreementPage" [id]="modalFirstParagraphTestId">
                            <bb-content-html [html]="content.modalContentFirstPara"></bb-content-html>
                        </div>
                        <div *ngIf="isAgreementPage" [id]="modalFirstParagraphTestId">
                            <bb-content-html [html]="content.modalContentFirstParaAgreementPage"></bb-content-html>
                        </div>
                        <div class="para-inline mb-20px" [id]="modalSecondParagraphTestId">
                            {{content.modalContentSecondParaBeforeDate}} 
                                <span *ngIf="!isAgreementPage; else isAgreementPageBlock"> {{lastDateOfDecision | date: 'dd MMM yyyy'}} {{content.modalContentSecondParaAfterDate}} </span>
                                <ng-template #isAgreementPageBlock> {{lastDateToSignAgreement | date: 'dd MMM yyyy'}} {{content.modalContentSecondParaAfterDateAgreementPage}}</ng-template>
                        </div>
                        <div class="content-text-bold" [id]="modalConfirmTestId">
                            <bb-content-html [html]="content.modalContentConfirm"></bb-content-html>
                        </div>
                    </div>
                    <div *ngIf="isAgreementPage && isWithin14To6DaysOfExpiryDate">
                        <div class="content-text-bold" [id]="modalHeaderTestId">
                            <bb-content-html [html]="content.areYouSureExitHeader"></bb-content-html>    
                        </div>
                        <div [id]="modalFirstParagraphTestId">
                            <bb-content-html [html]="content.exitQuoteLostPara"></bb-content-html>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isWithin14To6DaysOfExpiryDate">
                    <button class="wss-button mr-20px" (click)="onPrimaryButtonOnModalClick();modal.dismiss()" [id]="modalPrimaryButtonTestId" test_id="modalPrimaryButtonTestId">{{content.modalPrimaryButtonText}}</button>
                    <button class="wss-button-secondary" (click)="modal.dismiss()" [id]="modalSecondaryButtonTestId" test_id="modalSecondaryButtonTestId">{{content.modalSecondaryButtonText}}</button>
                </div>
                <div *ngIf="isAgreementPage && isWithin14To6DaysOfExpiryDate">
                    <button class="wss-button mr-20px" (click)="onPrimaryButtonOnModalClick();modal.dismiss()" [id]="modalPrimaryButtonTestId" test_id="modalPrimaryButtonTestId">{{content.exitToEocOptionsPageButtonText}}</button>
                    <div>
                        <button class="wss-button-secondary" (click)="modal.dismiss()" [id]="modalSecondaryButtonTestId" test_id="modalSecondaryButtonTestId">{{content.backToApplicationButtonText}}</button>
                    </div>
                </div>
            </div>
            <div>
                <span (click)="modal.dismiss()" class="material-icons material-icon-grey cursor-link">clear</span>
            </div>
        </div>

    </div>
</ng-template>