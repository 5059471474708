import { Component, OnInit, ChangeDetectorRef, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransactionService } from './services/transaction.service';
import { Transaction } from './model/transactions';
import { appConfig } from '../../config/wss-app-constants';
import * as moment from 'moment';
import { DatastoreService } from '../../service/datastore.service';
import { getDate, isErrServer } from '../../common/util/util';
import { MessageService } from '../../service/message.service';
import { BannerMessage } from 'libs/model/bannerMessage';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { TransactionWidgetItem } from 'libs/model/content/transaction';
import { RouterService } from "@backbase/foundation-ang/core";
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-transaction-widget',
  templateUrl: 'transaction-widget.component.html',
  providers: [ ContentService ]
})
export class TransactionWidgetComponent implements OnInit, OnDestroy {
  transactions: Transaction[] = [];
  isLoaderActive: boolean = false;
  requestStatus: any = {
    success: false,
    show: false
  };
  isRequestedToday: boolean = false;
  acccountDetail: any = {};

  unsortedTransactions: Transaction[] = [];
  lastSequenceNo: number = 0;
  totalNoOfRecords: number = 0;
  currentPage: number = 0;

  modalRef: any;

  @ViewChild('scrollFrame') scrollFrame: any;

  private scrollContainer: any;
  bannerMessage: BannerMessage = new BannerMessage();
  serviceError: boolean = false;
  apiCallCompleted: boolean = false;

  transactionWidgetItem$: Observable<TransactionWidgetItem | undefined> = this.bbContentService.getContent<TransactionWidgetItem>('transactionWidgetItem');

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private transactionService: TransactionService,
    private datastore: DatastoreService,
    private messageService: MessageService,
    private renderer: Renderer2,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService
    // private router: RouterService   // commented as its not used in this component
  ) {
    this.handleErrors();
  }

  ngOnInit(): void {
    this.datastore.sessionInvalid();
    this.tealiumService.view(
      {
        JourneyName: 'Transaction',
        JourneyStepName: 'Landing Page',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
    this.acccountDetail = this.datastore.getAccountDetails();
    this.getTransactions();
    this.getRequestedStatement();
    this.messageService.setActiveNav("Transactions");
    this.renderer.addClass(document.body, 'transaction-summary');
  }

  ngAfterViewInit() {
    if (this.scrollFrame) {
      this.scrollContainer = this.scrollFrame.nativeElement;
    }
  }

  ngAfterViewChecked() {
    if (this.isLoaderActive) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  showError(errStatus: number): void {
    if(isErrServer(errStatus)){
      this.datastore.setErrorServer();
      this.serviceError = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  getTransactions() {
    let records: Transaction[] = [];
    this.transactionService.getTransactions(0, this.lastSequenceNo).subscribe(() => {
      records = this.datastore.getTransactions();
      if (records.length) {
        this.totalNoOfRecords = records.length;
        this.unsortedTransactions = records.sort((item1: any, item2: any) => item2.transaction_date.getTime() - item1.transaction_date.getTime());
        if (records.length < appConfig.NoOfTransactions) {
          this.transactions = this.unsortedTransactions.slice(this.lastSequenceNo, records.length);
        }
        else {
          this.transactions = this.unsortedTransactions.slice(this.lastSequenceNo, appConfig.NoOfTransactions);
        }
        this.currentPage = 1;
      }
      this.apiCallCompleted = true;
      this.changeDetectorRef.detectChanges();
    },
    (error: any) => {
      this.showError(error.status);
    });
  }

  getAccountDetails() {
    this.transactionService.getAccountDetails().subscribe(() => {
      this.acccountDetail = this.datastore.getAccountDetails();
      this.changeDetectorRef.detectChanges();
    },
    (error: any) => {
      this.showError(error.status);
    });
  }

  loadMoreRecords() {
    ++this.currentPage;
    let trans: Transaction[] = [];
    let nextPageRecords = appConfig.NoOfTransactions * this.currentPage;
    this.lastSequenceNo += appConfig.NoOfTransactions;
    if ((appConfig.NoOfTransactions * this.currentPage) <= this.totalNoOfRecords) {
      trans = this.unsortedTransactions.slice(this.lastSequenceNo, nextPageRecords);
    } else {
      trans = this.unsortedTransactions.slice(this.lastSequenceNo);
    }
    this.transactions = [...this.transactions, ...trans];
  }

  openModal(content: any) {
   this.modalRef =  this.modalService.open(content);
  }

  checkUrProfile(){
    if(this.modalRef){
      this.modalRef.close();
      this.messageService.setProfileActive(true);
    }
  }

  requestStatement() {
    this.transactionService.requestStatement().subscribe(result => {
      if (result && result.request_id && result.request_id.trim().length !== 0) {
        this.requestStatus.show = this.requestStatus.success = true;
        this.isRequestedToday = true;
      }
      this.changeDetectorRef.detectChanges();
    },
    (error: any) => {
      this.showError(error.status);
    });
  }

  getRequestedStatement() {
    this.transactionService.getRequestedStatement().subscribe((result: any) => {
      if (result && result.length) {
        result.sort((item1: any, item2: any) => getDate(item2.date_statement_requested).getTime() - getDate(item1.date_statement_requested).getTime());
        this.isRequestedToday = result[0].date_statement_requested === moment().format('DD/MM/YYYY') ? true : false;
      }
      this.changeDetectorRef.detectChanges();
    },
    (error: any) => {
      this.showError(error.status);
    });
  }

  closeStatusBox() {
    this.requestStatus.show = false;
  }

  handleErrors(){
    this.messageService.getServiceError().subscribe(error => {
      if (error && error.reasonCode && error.reasonCode === 'REQUEST_STATEMENT_ERROR') {
        this.requestStatus.success = false;
        this.requestStatus.show = true;
        this.isRequestedToday = false;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'transaction-summary');
  }

}
