<div class="eoc-landing" >
    <div class="container-column">
        <div class="section-title-container">
            <h1 class="section-label-title" test_id="pageName">Credit search</h1>
        </div>
        <div>
            <p>You're almost there We just need you to confirm following:</p>
            <h2 class="mb-20px">We need to do a search</h2>
            <p>We will run a search on your credit file to understand your financial history. This will help us understand if a term extension is right for you.</p>
            <p>This credit referance agency will then record a search on your credit file and other leaders will be able to see this search. Leader may take credit searched into account when making lending decisions.</p>
            
            <div class="container-row expansion-border-hidden transparent-accordion mb-3" role="row">
                <mat-accordion class="accordion mat-accordion-override w-100 term-ext" role="accordion">
                    <mat-expansion-panel
                        class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                        <mat-expansion-panel-header class="mat-panel-header-override">
                            <mat-panel-title class="mat-expansion-panel-header-title-override"
                                test_id="firstAccordionHeader">
                                <strong>How we use Credit Reference Agencies</strong>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="margin-top-10" test_id="firstAccordionContent">
                            
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div>
                <h2 class="mb-20px">Your affordability</h2>
                <p>You should only apply for a referance if you are sure that you can make the monthly payments shown in the quotes for the rest of your agreement term.</p>
            </div>
            <div class="card p-3">
                <p><strong>Do you understand and agree to the following?</strong></p>
                <ul>
                    <li class="mb-20px">I agree to my details being shared with Credit Reference Agencies.</li>
                    <li class="mb-20px">I have read the points above and want to submit an application to extend the term of my agreement.</li>
                </ul>
                <div>
                    <input type="checkbox" name="agreeCheckbox" id="agreeCheckbox">
                    <label for="agreeCheckbox" class="ml-1">Yes, I understand and agree.</label>
                </div>

            </div>
            
            <div class="text-center">
                <button class="wss-button" (click)="navigate('preview')">Submit</button>
                <button class="wss-button-secondary" (click)="navigateToHome()">Save & Exit</button>
                <div class="text-center" (click)="navigate('personal-details')">
                    <a>Back a step</a>
                </div>
            </div>
        </div>
    </div>
</div>
