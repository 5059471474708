import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, Input, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MessageService } from '../../../service/message.service';
import { BannerMessage } from '../../../model/bannerMessage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { appConfig } from '@wss/config/wss-app-constants';
import { Router } from '@angular/router';
import { EndOfContractService } from '@wss/end-of-contract-widget/src/services/end-of-contract.service';
import { DatastoreService } from '@wss/service/datastore.service';
declare var document: any;
declare var window: any;

@Component({
    selector: 'bb-wss-banner-message',
    templateUrl: 'banner-message.component.html'
})
export class MessageBannerComponent implements OnInit {
    @Input() bannerLinkText: string = '';
    @Input() showBannerLink: boolean = false;
    @Input() showRegistrationLink: boolean = false;
    @Input() eocLinkText: any;
    @Input() eocBrContinueJourneyText: any;
    @Input() eocDetails: any;
    @Input() eocFlag: any;
    @Input() archiveFlag: any;
    @Input() linkText: any;
    @Input() routeUrlInBanner: any;
    @Input() bannerType: any;
    @Input() inArrears: any; 
    @Input() arrearData: any;
    @Input() errorCode: any;
    @Input() contactPreferenceFlag: boolean = false
    @Input() resumeBrApplicationLink: any;
    @Input() isResumableBrApplication: any;
    @Input() isEocLandingPageBanner: any;
    @Input() isBrLandingPageBanner: any;
    @Input() resumeBrApplicationLinkText: any;
    @Input() continueApplicationText: any;
    @Input() eocBrReturnAndSignText: any;
    @Input() proposalStatus: any;
    @Input() isDecisionChanged:any;
    @Input() reviewYourApplicationResultText: any;
    @Input() eocOptionBannerLinkId: string = '';
    @Input() hasExtendedOffline: boolean = false;

    messageData: BannerMessage = new BannerMessage();
    titleText: SafeHtml = '';
    subtitleText: SafeHtml = '';
    anchors: any;
    modalText: string = '';
    getErrorTxt: string = appConfig.errorEmailRegistrationLink;
    getPleaseText: string = appConfig.errorPleaseText;
    accountDetail: any = {};
    isBalloonRefinanceBanner: boolean = false;
    isIntentionSelectionBanner: boolean = false;

    datastore: any;
    bannerMessage: any;
    constructor(
        private messageService: MessageService,
        private sanitizer: DomSanitizer,
        private elementRef: ElementRef,
        private modalService: NgbModal,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private dataStoreService: DatastoreService,
        private eocService: EndOfContractService
        ) { } 

    ngOnInit(): void {
        this.messageData = this.messageService.getBannerMessage();
        this.titleText = this.messageData?.titleText;
        this.subtitleText = this.messageData?.subtitleText;
        this.checkEocBannerType();
        this.changeDetectorRef.detectChanges();
    }

    public ngAfterViewInit() {
        this.anchors = this.elementRef.nativeElement.querySelectorAll('a');
        this.anchors.forEach((anchor: HTMLAnchorElement) => {
            anchor.addEventListener('click', this.linkClicked)
        })
    }

    linkClicked(event: any) {
        let modalText = '';
        for (let item of event.target.attributes) {
            if (item.name === 'data') {
                modalText = item.value;
                break;
            }
        }
        if (event.target.innerText.indexOf('Make a payment') === 0 && event.target.hash === '#/payments/payment-debit-card') {
            localStorage.setItem('redirectionFlag', 'arrearsBanner');
        }
        if (modalText !== '') {
            window["modalText"] = modalText;
            document.getElementById("bannerMsgId").click();
        }
        else {
            return;
        }
    }

    openModal(modalContent: any) {
        let text = window["modalText"];
        delete window["modalText"];
        if (text && text !== '') {
            this.modalText = text;
            this.modalService.open(modalContent, { centered: true, windowClass: 'account-summary-modal' });
        }
    }

    navigateTo() {
        if(!this.contactPreferenceFlag) {
            this.messageService.setActiveNav("End of contract");
        }
        this.router.navigate([this.routeUrlInBanner])
    }

    navigateToHelpcenter() {
        this.dataStoreService.setArrearsBannerRoute(true)
        this.router.navigate(['/help-centre/manage-account'])
    }

    setBalloonRefinanceFlag() {
        if (this.isBalloonRefinanceBanner) {
            this.eocService.setIsBalloonRefinanceBannerClosed();
        }
    }

    closeBanner() {
        if (this.isBalloonRefinanceBanner) {
            this.eocService.setIsBalloonRefinanceBannerClosed();
        }
        if (this.contactPreferenceFlag) {
            this.dataStoreService.setContactPreferenceBannerShown(false);
        }
        if (this.eocFlag) {
            this.dataStoreService.setisEocBrResumeAppBannerClosed(true);
        }
        if (this.isEocLandingPageBanner) {
            this.dataStoreService.setIsEocLandingPageResumeBrAppBannerClosed(true);
        }
        if (this.isBrLandingPageBanner) {
            this.dataStoreService.setIsBrLandingPageResumeBrAppBannerClosed(true);
        }
        this.messageData.isBannerClosed = !this.messageData.isBannerClosed;
    }

    checkEocBannerType() {
        switch (this.bannerType) {
            case appConfig.eocHPBanner: {
                this.isBalloonRefinanceBanner = true;
                break;
            }
            case appConfig.eocIntentionSelectedBanner: {
                this.isIntentionSelectionBanner = true;
                break;
            }
            default:
                break;
        }
    }
}