<div class="payment-holiday-container term-ext-container" *ngIf="paymentHolidayWidgetItem$ | async as paymentHolidayWidgetItem">
    <div class="container-column" test_id="pageContentText">
        <div class="container-row section-title-container">
            <span class="section-title" test_id="pageName">{{paymentHolidayWidgetItem.termExtensionLanding.pageName}}</span>
        </div>
        <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionLanding.pageContentText"></bb-content-html>
        <br>
        <div class="container-row expansion-border-hidden">
            <mat-accordion class="accordion mat-accordion-override w-100 term-ext">
                <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override" test_id="accor1ContentText">
                    <mat-expansion-panel-header class="mat-panel-header-override">
                        <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="accor1TitleText">
                            {{paymentHolidayWidgetItem.termExtensionLanding.accor1TitleText}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionLanding.accor1ContentText"></bb-content-html>
                </mat-expansion-panel>

                <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override" test_id="accor2ContentText">
                    <mat-expansion-panel-header class="mat-panel-header-override">
                        <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="accor2TitleText">
                            {{paymentHolidayWidgetItem.termExtensionLanding.accor2TitleText}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionLanding.accor2ContentTextPCP" *ngIf="accountDetail?.scheme_code === typePCP"></bb-content-html>
                    <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionLanding.accor2ContentTextHP" *ngIf="accountDetail?.scheme_code === typeHP"></bb-content-html>
                </mat-expansion-panel>

                <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override" test_id="accor3ContentText">
                    <mat-expansion-panel-header class="mat-panel-header-override">
                        <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="accor3TitleText">
                            {{paymentHolidayWidgetItem.termExtensionLanding.accor3TitleText}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionLanding.accor3ContentTextPCP" *ngIf="accountDetail?.scheme_code === typePCP"></bb-content-html>
                    <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionLanding.accor3ContentTextHP" *ngIf="accountDetail?.scheme_code === typeHP"></bb-content-html>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="container-row start-app">
            <button role="button" tabindex="0" class="wss-button" (click)="currentAgreement()" test_id="startApplicationButtonText">{{paymentHolidayWidgetItem.termExtensionLanding.startApplicationButtonText}}</button>
        </div>
        <div class="flex-horizontal-center mb-90px" test_id="pageBottomLink">
            <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionLanding.pageBottomLink"></bb-content-html>
        </div>

    </div>
</div>

<!-- 
    <div class="payment-holiday-container term-ext-container" *ngIf="paymentHolidayWidgetItem$ | async as paymentHolidayWidgetItem">
    <div class="container-column">
        <div class="container-row section-title-container">
            <span class="section-title">Term Extension</span>
        </div>
        <span class="mt-35px text-semibold">Here's a few things you should consider before choosing a Term Extension.</span>
        <br>
        <div class="container-row expansion-border-hidden">
            <mat-accordion class="accordion mat-accordion-override term-ext">
                <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                    <mat-expansion-panel-header class="mat-panel-header-override">
                        <mat-panel-title class="mat-expansion-panel-header-title-override">
                            Will a Term Extension cost me more?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                    <span class="text-semibold">You will pay more as you will take longer to pay back the amount borrowed.</span>
                    <span> There may also be additional costs when you choose a Term Extension.</span>
                    </p>
                    <ul role="list">
                        <li role="listitem"><span class="text-semibold">If you keep your vehicle for longer</span>, you will need to pay for your insurance and road tax for longer</li>
                        <li role="listitem"><span class="text-semibold">If you have a warranty or breakdown cover</span>, it may not cover you for the extended time that you will have the vehicle. You may also need to pay for additional MOTs or services</li>
                        <li role="listitem"><span class="text-semibold">The value of the vehicle will reduce over time</span>. As you take longer to pay back the finance, the vehicle may be worth less than the outstanding balance</li>
                    </ul>
                </mat-expansion-panel>

                <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                    <mat-expansion-panel-header class="mat-panel-header-override">
                        <mat-panel-title class="mat-expansion-panel-header-title-override">
                            Will my agreement change?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul role="list">
                        <li role="listitem">You will need to sign an agreement to agree to the changes to your payments.</li>
                        <li role="listitem">Your monthly payments will be lower because the term of your agreement will be longer</li>
                        <ng-container *ngIf="accountDetail?.scheme_code === typePCP">
                            <li role="listitem">The Guaranteed Future Value (GFV) of your vehicle under your goods return option will change. We will reduce the GFV as your agreement will end at a later date, so the expected value of the vehicle at the end of your agreement will be less</li>
                            <li role="listitem">Your permitted maximum mileage will increase on a pro rata basis as your agreement will be longer. For example, if you have a 3 year agreement with a permitted maximum mileage of 30,000 miles and you extend by 12 months, you will have 10,000 miles added to your allowance. This would increase your permitted maximum mileage to 40,000 miles</li>
                        </ng-container>
                        <li role="listitem">If you have already taken a payment holiday before taking a Term Extension, any extra amounts added for taking a payment holiday will no longer be due.</li>
                    </ul>
                </mat-expansion-panel>

                <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                    <mat-expansion-panel-header class="mat-panel-header-override">
                        <mat-panel-title class="mat-expansion-panel-header-title-override">
                            How much can I afford to pay each month?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngIf="accountDetail?.scheme_code === typeHP">On the next screen, you'll be asked how much you think you can pay each month.</p>
                    <p *ngIf="accountDetail?.scheme_code === typePCP">On the next screen, you'll be given some options to extend your term. When considering your options:</p>
                    <ul role="list">
                        <li role="listitem"><span class="text-semibold">Make sure that you can still pay essential living costs</span> such as mortgage/rent payments, utilities, council tax and food</li>
                        <li role="listitem"><span class="text-semibold">Remember to consider what you can afford longer term.</span> Make sure you take into account other lending including any temporary payment arrangements you have made with other companies and how that may affect your finances at a later date</li>
                        <li role="listitem"><span class="text-semibold">Pay priority debts before non-priority debts.</span> The Money Advice Service website has a useful <a class="faq-link" title="How to prioritise your debts" tabindex="0" role="link">How to prioritise your debts</a> page. It is also a good idea to speak to your other lenders to discuss your options</li>
                    </ul>
                    <p>The Financial Conduct Authority website has advice to help people who have money worries because of the Coronavirus pandemic. <a class="faq-link" (click)="openFCA()" title="Visit the FCA website" tabindex="0" role="link">Visit the FCA website</a></p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="container-row start-app">
            <button role="button" tabindex="0" class="wss-button" (click)="currentAgreement()">Start application</button>
        </div>
        <div class="flex-horizontal-center mb-90px">
            <span><a class="faq-link" (click)="goBack()" title="Go back" tabindex="0" role="navigation">Go back</a></span>
        </div>

    </div>
</div>
 -->