import {Component, OnInit} from '@angular/core';
import {UserRepoService} from "../../service/user-repo.service";
import {RouterService} from "@backbase/foundation-ang/core";
//import {ContentService as OldContentService} from "../../../../service/content.service";
import {ContentData} from '../../../../config/wss-app-constants';
import { Observable } from "rxjs";
import { ContentService } from '@backbase/universal-ang/content';
import {LoginWidgetItem} from '../../../../model/login-widget/login-widget';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-wss-link-expired',
  templateUrl: './link-expired.component.html',
  providers: [ ContentService ]
})

export class LinkExpiredComponent implements OnInit {
  // contentTxt: string = ContentData.LoginLinkExpired;
  // content: Array<any> = [];
  // contentFolder: string = ContentData.Login;
  
  ngOnInit(): void {
    //this.getStaticContent(this.contentTxt, this.contentFolder);
    this.tealiumService.view(
      {
        JourneyName: 'Login',
        JourneyStepName: 'Link Expired',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');

  constructor(
    private router: RouterService,
    private userRepoService: UserRepoService,
    private readonly bbContentService: ContentService,
    //private contentService: OldContentService,
    private tealiumService: TealiumUtagService,
  ) {
    this.userRepoService.updateScreenTitle('Authenticate');
  }   

  // getStaticContent(contenttxt:string, contentfolder:string): void{ 
  //   this.contentService.getStaticContent(contenttxt, contentfolder).subscribe((content) => {      
  //     this.content = content;
  //   });
  // }

  submit() {
    this.userRepoService.updateScreenTitle('Create An Account');
    this.router.navigate(['forgot-password']);
  }

}
