import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContentService } from '@backbase/universal-ang/content';
import { RegistrationWidgetItem } from "@wss/model/registrationToken";
import { RegistrationStoreModelService } from "@wss/registration-store/src/registration-store-model.service";
import { appConfig } from '@wss/config/wss-app-constants';
import { Observable, Subject } from "rxjs";
import { getPortalName } from '@wss/common/util/util';
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { RegisterService } from "../../service/register.service";
import { DatastoreService } from "@wss/service/datastore.service";
import { isErrServer } from '../../../../common/util/util';
import { RegisterDatastoreService } from "../../service/register-datastore.service";
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'bb-contact-preferece',
    templateUrl: './contact-preference.component.html',
    providers: [ContentService]
})

export class ContactPreferenceComponent implements OnInit, OnDestroy {

    contactForm: FormGroup;
    isExpansionPanelOpen: boolean = false;
    portalName: any;
    submitted: boolean = false;
    isFormValid: boolean = false;
    serviceError: boolean = false;
    showEmailLink: boolean = false;
    accountDetails: any;
    isThirdPartyConsent: boolean = true;
    authorisation: any;

    registrationWidgetItem$: Observable<RegistrationWidgetItem | undefined> = this.bbContentService.getContent<RegistrationWidgetItem>('registrationWidgetItem');
    registrationWidgetItem: any;

    readonly destroy$ = new Subject();

    constructor(private readonly bbContentService: ContentService,
        private formBuilder: FormBuilder,
        private registrationService: RegistrationStoreModelService,
        private registerstoreService: RegisterDatastoreService,
        private registerService: RegisterService,
        private datastoreService: DatastoreService,
        private tealiumService: TealiumUtagService,
        private router: Router,
        public changeDetectorRef: ChangeDetectorRef
    ) {
        this.registrationService.updatedStepper(4);
        this.registrationService.updateTitle('Marketing choices');
        this.contactForm = this.createContactForm();
    }

    ngOnInit(): void {
        this.portalName = getPortalName();
        this.contentModification();
        this.controlExpansionPanel();
        this.accountDetails = this.registerstoreService.getAccountDetails();
        this.isThirdPartyConsent = this.isThirdPartyConsentEnable();
        this.tealiumService.view(
            {
                JourneyName: 'Registration',
                JourneyStepName: 'Contact Preference',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
    }

    contentModification() {
        this.registrationWidgetItem$.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            this.registrationWidgetItem = response;
            this.registrationWidgetItem.contactPreference.expansionPanelPara1 = this.replaceBrandPlaceholder(this.registrationWidgetItem.contactPreference.expansionPanelPara1, this.portalName);
            this.registrationWidgetItem.contactPreference.expansionPanelPara2 = this.replaceBrandPlaceholder(this.registrationWidgetItem.contactPreference.expansionPanelPara2, this.portalName);
            this.changeDetectorRef.detectChanges();
        });
    }

    createContactForm(): FormGroup {
        return this.formBuilder.group({
            AccountNumber: ['', Validators.required],
            Channel: ['SYSTEM', Validators.required],
            UpdateMarketingEmail: ['', Validators.required],
            UpdateMarketingSMS: ['', Validators.required],
            UpdateMarketingPhone: ['', Validators.required],
            UpdateMarketingPost: ['', Validators.required],
            UpdatedBy: ['WSS', Validators.required],
            ClientKey: ['', Validators.required],
            UpdateOnlineAccount: ['', Validators.required],
            UpdateThirdPartyConsent: ['', Validators.required],
            UpdatePixelTracking: ['', Validators.required],
            UserId: ['', Validators.required ],
            Alias: ['', Validators.required ]
        });
    }

    get f() { return this.contactForm.controls; }


    controlExpansionPanel(): void {
        this.isFormValid = Object.values(this.contactForm.value).includes(true) || Object.values(this.contactForm.value).includes(false);
        this.isExpansionPanelOpen = Object.values(this.contactForm.value).includes(true);
        this.contactForm.valueChanges.subscribe((value: any) => {
            this.isExpansionPanelOpen = Object.values(this.contactForm.value).includes(true);
            this.isFormValid = Object.values(this.contactForm.value).includes(true) || Object.values(this.contactForm.value).includes(false);
        });
    }

    isThirdPartyConsentEnable() {
        if (['internationalmotors', 'jaguar', 'landrover'].includes(this.portalName)) {
            return false;
        } else {
            return true;
        }
    }

    onSubmit(isOnSkip: boolean) {
        this.submitted = true;
        let accounts: any = this.getAccountsByBrand(appConfig.brandCompanyDetails, this.accountDetails, this.portalName);
        this.contactForm.patchValue({
            AccountNumber: accounts.accountNumbers,
            ClientKey: accounts.cifIds[0],
            UserId: this.registerstoreService.getUserId(),
            Alias: this.registerstoreService.getEmail()
        });
        this.createAccount(this.contactForm.value, isOnSkip);
    }

    createAccount(reqObj: any, isOnSkip: boolean) {
        this.registerService.getCustomerConfirm().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.serviceError = false;
            if (!isOnSkip) {
                this.submitContactPreference(reqObj);
            } else {
                this.router.navigate(['credential-created']);
            }
        },
        (error: any) => {
            this.showError(error.status, error.error.code);
        });
    }

    submitContactPreference(reqObj: any) {
        this.registerService.saveContactPreference(reqObj, true).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            this.router.navigate(['credential-created']);
        },
            (error: any) => {
                this.showError(error.status, error.error.code);
            });
    }
    
    showError(errStatus: number, errCode: string): void {
        if (isErrServer(errStatus)) {
            if (errCode === '002') {
                this.showEmailLink = true;
                this.datastoreService.setErrorScreen(appConfig.errorEmailRegistration);
            } else {
                this.showEmailLink = false;
                this.datastoreService.setErrorServer();
            }
            this.serviceError = true;
            this.changeDetectorRef.detectChanges();
        }
    }
    
    getAccountsByBrand(companydetails: any, accountDetails: any, currentBrand: any) {
        let accounts: any = [];
        let cifIds: any = [];
        let brandObj: any = companydetails.filter((company: any) => { return currentBrand == company.brandName });
        accountDetails.filter((account: any) => {
            if (brandObj[0].companyNumber.includes(account.company_number)) {
                accounts.push(account.AccountNumber);
                cifIds.push(account.chrCIS_Key)
            }
        })
        return { accountNumbers: accounts.toString(), cifIds: cifIds };
    }

    replaceBrandPlaceholder(content: any, brandName: any) {
       return content.replace(/[\[\]']+/g, '').replace(/brand name/gi, brandName); 
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}