<div class="content-wrapper-container inbox-margin-left-right" *ngIf="inboxMsgWidgetItem$ | async as inboxMsgWidgetItem">
    <div class="reg-row plr-10pt" role="alert" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="reg-row mrl-12px" role="alert" *ngIf="showArchiveBanner">
        <bb-wss-banner-message [archiveFlag]="true"></bb-wss-banner-message>
    </div>
    <div class="section-title-container mb-20px mrl-12px" test_id="pageTitle">        
        <div style="display: flex">
            <div class="section-title width-95prct">{{ inboxMsgWidgetItem.inboxMsgComponent.pageTitle }}</div> 
            <div class="material-icons mt-10px wss-icon-bg msg-details-close" (click)="closeMsgDetails();" *ngIf="readDetails">chevron_left</div>
        </div>
    </div>
    <ng-container *ngIf="!isAccountCompleted && !readDetails && currentPreference">
        <div *ngIf="currentPreference.is_paperless; else switch_pref" class="container-row mrl-12px">  
            <a href="javascript:void(0)" (click)="toPreference()"> 
                <bb-content-html [html]="inboxMsgWidgetItem.inboxMsgComponent.preferenceManage"></bb-content-html>
            </a>    
        </div>
        <ng-template  #switch_pref>
            <div *ngIf="!isAccountArchived" class="container-row mrl-12px">                        
                <a href="javascript:void(0)" (click)="toPreference()"> 
                    <bb-content-html [html]="inboxMsgWidgetItem.inboxMsgComponent.preferenceSwitch"></bb-content-html>
                </a>
            </div>
        </ng-template>
    </ng-container>
</div>
<div class="content-wrapper-container inbox-margin-left-right" *ngIf="readDetails">
    <div class="mrl-12px cont-msg-details">
        <div class="modal-header-msginbox" test_id="inboxMsg-header">
            <div class="msg-title"> {{modalMsg.name}}
            </div>                        
            <div class="msg-details-date" *ngIf="(currentDate(todayDate) !== replaceString(modalMsg.document_date))">{{replaceString(modalMsg.document_date)}}</div>
            <div class="msg-details-date" *ngIf="(currentDate(todayDate) === replaceString(modalMsg.document_date))">{{ inboxMsgWidgetItem.inboxMsgComponent.todayText }}</div>                        
        </div>
        <div class="d-flex justify-content-center">
            <span class="wss-pdf-icon clickable" alt="PDF Doc"></span>
        </div>
        <div class="btn-doc d-flex justify-content-center">
            <button (click)="downloadInboxDocument(modalMsg)" bbButton type="button" aria-label="download doc" class="wss-button" test_id="pdf-doc">
                {{ inboxMsgWidgetItem.inboxMsgComponent.viewLetterBtnText }}
            </button>
        </div>
        <div>
        </div>
    </div>                
</div>
<div class="mt-15px" *ngIf="!readDetails">
    <mat-tab-group animationDuration="0ms">
        <mat-tab [label]="getCount">
            <div>
                <div class="mtb-12px">
                    <div class="div-inbox-menu mrl-12px" *ngIf="countInbox > 0">
                        <div>
                            <label class="checkbox-mailbox">
                                <input type="checkbox" id="inboxAllChk" [disabled]="(countReadInbox < 1)" (click)="isCheckedInbox('inboxReadMsg')"/> 
                                <span>{{ inboxMsgWidgetItem.inboxMsgComponent.selectText }}</span>                                            
                            </label>                                        
                        </div>
                        <div class='pl-5px'>
                            <button mat-button [matMenuTriggerFor]="inboxMenu" class="btn-inbox-popup">
                                <span class="material-icons arrow-btn-inbox">expand_more</span>                               
                                <span class="txt-inbox-popup">{{ inboxMsgWidgetItem.inboxMsgComponent.menuHeader }}</span>
                            </button>
                            <mat-menu #inboxMenu="matMenu">
                                <button mat-menu-item (click) = "markUnread()">{{ inboxMsgWidgetItem.inboxMsgComponent.unreadText }}</button>
                                <button mat-menu-item (click) = "moveArchive()">{{ inboxMsgWidgetItem.inboxMsgComponent.archiveText }}</button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <div class="no-msg" *ngIf="noRecords">
                    <bb-content-html [html]="inboxMsgWidgetItem.inboxMsgComponent.errNoRecords"></bb-content-html>
                </div>
                <div class="inbox-msg-list" *ngIf="!noRecords">
                    <ng-container *ngFor="let msglist of docsMailbox;">
                        <div class="msg-details" *ngIf="!msglist.is_archived && !msglist.is_read">
                            <div class="cont-indicator">
                                <div class="new-msg-indicator">
                                </div>
                            </div>
                            <div class="msg-content clickable" (click)="openMsgDetails(msglist)">
                                <div *ngIf="msglist.is_priority" class="text-msg-priority">{{ inboxMsgWidgetItem.inboxMsgComponent.readAction }}</div>
                                <div><a href="javascript:void(0)" class="text-msg-tile">{{msglist.name}}</a></div>                                            
                            </div>
                            <div class="msg-date" *ngIf="(currentDate(todayDate) !== replaceString(msglist.document_date))">{{replaceString(msglist.document_date)}}</div>                                        
                            <div class="msg-date" *ngIf="(currentDate(todayDate) === replaceString(msglist.document_date))">{{ inboxMsgWidgetItem.inboxMsgComponent.todayText }}</div>
                        </div>
                        <div class="msg-details msg-read" *ngIf="!msglist.is_archived && msglist.is_read">                                        
                            <div class="cont-checkbox">
                                <label class="checkbox-mailbox">                                    
                                    <input type="checkbox" name="inboxReadMsg" value="{{msglist.guid}}" (change)="chkAllSelect($event,'#inboxAllChk','inbox')"/>
                                    <span></span>                                                                                        
                                </label>                                   
                            </div>
                            <div class="msg-content-checkbox clickable" (click)="openMsgDetails(msglist)">
                                <div *ngIf="msglist.is_priority" class="text-msg-priority">{{ inboxMsgWidgetItem.inboxMsgComponent.readAction }}</div>
                                <div>{{msglist.name}}</div>
                            </div>
                            <div class="msg-date" *ngIf="(currentDate(todayDate) !== replaceString(msglist.document_date))">{{replaceString(msglist.document_date)}}</div>                                        
                            <div class="msg-date" *ngIf="(currentDate(todayDate) === replaceString(msglist.document_date))">{{ inboxMsgWidgetItem.inboxMsgComponent.todayText }}</div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </mat-tab>
        <mat-tab label='{{ inboxMsgWidgetItem.inboxMsgComponent.tab2Text }}'>
            <div>
                <div class="mtb-12px" *ngIf="countArchive > 0">
                    <div class="div-inbox-menu mrl-12px">
                        <div>
                            <label class="checkbox-mailbox">
                                <input type="checkbox" id="archiveAllChk" (click)="isCheckedArchive('archiveMsg')"/> 
                                <span>{{ inboxMsgWidgetItem.inboxMsgComponent.selectText }}</span>                                                                                      
                            </label>                               
                        </div>
                        <div class='pl-5px'>
                            <button mat-button [matMenuTriggerFor]="archiveMenu" class="btn-inbox-popup">
                                <span class="material-icons arrow-btn-inbox">expand_more</span>                                
                                <span class="txt-inbox-popup">{{ inboxMsgWidgetItem.inboxMsgComponent.menuHeader }}</span>
                            </button>
                            <mat-menu #archiveMenu="matMenu" class="mv-inbox">
                                <button mat-menu-item (click) = "moveToInbox()">{{ inboxMsgWidgetItem.inboxMsgComponent.moveInboxText }}</button>                                    
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <div class="inbox-msg-list" *ngIf="countArchive > 0">
                    <ng-container *ngFor="let msglist of docsMailbox;">
                        <div class="msg-details msg-read" *ngIf="msglist.is_archived">
                            <div class="cont-checkbox">
                                <label class="checkbox-mailbox">
                                    <input type="checkbox" name="archiveMsg" value="{{msglist.guid}}" (change)="chkAllSelect($event,'#archiveAllChk','archive')"> 
                                    <span></span>                                                                                      
                                </label>                                   
                            </div>
                            <div class="msg-content-checkbox clickable" (click)="openMsgDetails(msglist)">
                                {{msglist.name}}
                            </div>
                            <div class="msg-date" *ngIf="(currentDate(todayDate) !== replaceString(msglist.document_date))">{{replaceString(msglist.document_date)}}</div>                                        
                            <div class="msg-date" *ngIf="(currentDate(todayDate) === replaceString(msglist.document_date))">{{ inboxMsgWidgetItem.inboxMsgComponent.todayText }}</div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>