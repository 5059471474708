import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';
import { ComplaintWidgetItem } from '@wss/model/complaint';
import { WSSAppConstant } from '@wss/config/wss-app-constants';
import { RouterService } from '@backbase/foundation-ang/core';
import { MessageService } from '@wss/service/message.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { injectComplaintsAgreementNumberIntoPage } from '@wss/common/util/util';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'bb-complaint-submission',
  templateUrl: 'complaint-submission.component.html',
  providers: [ContentService],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class ComplaintSubmissionComponent implements OnInit, OnDestroy {
  svgInfoIcon = WSSAppConstant.SVG_INFO_ICON;
  complaintConcernType: string = '';
  accountDetail: any;

  constructor(
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    private router: RouterService,
    private messageService: MessageService,
    private dataStore: DatastoreService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private formDataService: DataService
  ) {
    this.complaintConcernType = sessionStorage.getItem('ConcernType') as string;
  }

  complaintWidgetItem$: Observable<ComplaintWidgetItem | undefined> = this.bbContentService.getContent<ComplaintWidgetItem>('complaintWidgetItem');
  complaintWidgetItem = new ComplaintWidgetItem();

  ngOnInit(): void {
    this.messageService.setCurrentStep(0);
    this.accountDetail = this.dataStore.getAccountDetails();
    this.renderer.addClass(document.body, 'complaints-submission');
    this.tealiumService.view(
      {
        JourneyName: 'Complaints Digitisation',
        JourneyStepName: 'Submission page',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      });
    this.complaintWidgetItem$.subscribe((pageItem: any) => {
      this.complaintWidgetItem = pageItem;
      this.complaintWidgetItem.complaintSubmission.submissionResultText = injectComplaintsAgreementNumberIntoPage(this.complaintWidgetItem.complaintSubmission.submissionResultText, this.accountDetail?.account_number);
      this.changeDetectorRef.detectChanges();
    });
    this.formDataService.deleteAllFormData();
  }

  backToHomeClick() {
    this.messageService.setActiveNav("My account");
    this.router.navigate(['/../myaccount']);
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'complaints-submission');
  }
}
