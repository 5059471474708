import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { appConfig } from 'libs/config/wss-app-constants';

@Injectable({
  providedIn: 'root',
})
export class OpenbudgetPlannerService {
  constructor(private router: Router) {}

  openBudgetPlanner() {
    this.router.navigate(['help-centre/money-worries']);
    window.scroll(0, 0);
  }

  redirectToOldBudgetToolLink(portalName: String) {
    if (portalName.indexOf('jaguar') !== -1 || portalName.indexOf('landrover') !== -1) {
      return window.open(appConfig.jagaurBudgetToolLink, undefined, undefined);
    }
    return window.open(appConfig.otherBrandBudgetToolLink, undefined, undefined);
  }
}
