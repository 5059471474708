import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { DatastoreService } from '../../../../service/datastore.service';
import { MessageService } from '../../../../service/message.service';
import { ServiceException } from '../../../../model/service-exception';
import { DueDate } from '../../models/change-payment';
import { PaymentService } from '../../services/payment.service';
import { isErrServer } from '../../../../common/util/util';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'confirm-change-payment',
    templateUrl: './confirm-change-payment.component.html',
    providers: [ContentService]
})

export class ConfirmChangePaymentComponent implements OnInit {
    serviceException: ServiceException = new ServiceException();
    dueDate: DueDate = new DueDate();
    serviceError: boolean = false;

    checkbox: any = {
        checked: false
    }

    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');

    constructor(
        private router: RouterService,
        private datastoreService: DatastoreService,
        private changeDetectorRef: ChangeDetectorRef,
        private messageService: MessageService,
        private paymentService: PaymentService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService) { }

    ngOnInit() {
        this.dueDate = this.datastoreService.getDueDate();
        this.handleErrors();
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Confirm Change Payment',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.messageService.setCurrentStep(0);
    }

    changePaymentDate() {
        this.paymentService.dueDates(this.dueDate).subscribe(data => {
            if (data.lost_interest.length !== 0) {
                try {
                    this.paymentService.sendChangePaymentEmailConfirmation().subscribe((res: any) => {
                    });
                }
                catch (error: any) {
                    this.showError(error.status);
                }
                this.router.navigate(['change-payment-result']);
            }
        });
    }

    handleErrors() {
        this.messageService.getServiceError().subscribe((error: any) => {
            this.showError(error.status);
        });
    }

    showError(errStatus: number): void {
        if (isErrServer(errStatus)) {
            this.datastoreService.setErrorServer();
            this.serviceError = true;
            this.changeDetectorRef.detectChanges();
        }
    }

    navigateToCP() {
        this.router.navigate(['change-payment']);
    }
}