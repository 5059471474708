
import { PortalContentMock } from "@backbase/foundation-ang/web-sdk";
import { BrandContactDetailsItem } from "./common/brand-contant-details";
export class WelcomeWidgetItem {
    welcomeDetails: WelcomeWidgetData = new WelcomeWidgetData();
    brandContactDetails: BrandContactDetailsItem = new BrandContactDetailsItem();
}

export class WelcomeWidgetData {
    welcomeTitlePartOne: string = '';
    welcomeTitlePartTwo: string = '';
    welcomeContent: string = '';
    loginButtonText: string = '';
    registrationButtonText: string = '';
    yourNewOnlineService: string = '';
    youWillStillBeAbleTo: string = '';
    makePaymentOrGetSettlement: string = '';
    changePaymentDay: string = '';
    keepYourAccountDetailsUpdated: string = '';
    haveAccessToAgreementInformation: string = '';
    whatsNewAndImproved: string = '';
    makeOverpayment: string = '';
    dedicatedHelpCentre: string = '';
    viewTransactionHistory: string = '';
    newAgreementDashboard: string = '';
}

export const welcomeWidgetItemContentRef = PortalContentMock.addStructuredContent<WelcomeWidgetItem>('WelcomeWidgetItem', {
    welcomeDetails: {
        welcomeTitlePartOne: "Welcome to your",
        welcomeTitlePartTwo: "online accounts",
        welcomeContent:  "We recently launched a new online service. To access it, all customers must create a new online account on their first visit.",
        loginButtonText: "Log in",
        registrationButtonText: "Create an account",
        yourNewOnlineService: "Your new online service",
        youWillStillBeAbleTo: "You'll still be able to:",
        makePaymentOrGetSettlement: "Make a payment or get settlement",
        changePaymentDay: "Change payment day",
        keepYourAccountDetailsUpdated: "Keep your account details updated",
        haveAccessToAgreementInformation: "Have 24/7 access to agreement information",
        whatsNewAndImproved: "What's new and improved:",
        makeOverpayment: "Make an overpayment",
        dedicatedHelpCentre: "A dedicated help centre",
        viewTransactionHistory: "View transaction history",
        newAgreementDashboard: "New Agreement dashboard",
    },
    brandContactDetails: {
        brandWiseAddress: "",
        brandWiseContactNumber: "",
        brandWiseContactEmail: "",
        brandWiseBankDetails: "",
        brandWiseCfaNumber: "",
        brandWiseFreeTRSNumber: "",
        portalName: "",
        mainCollectionsLineNumber: "",
        legalNumber: "",
        budgetToolLink: "",
        privacyPolicyLink: "",
        brandWiseAccessibilityNumber: ""
    }
});


// export const headerWidgetContent = new HeaderWidgetItem();