<div class="container-column mt-26px hp-options" test_id="hpOptions" *ngIf="paymentHolidayWidgetItem$ | async as paymentHolidayWidgetItem">
    <bb-content-html class="para-inline" [html]="hpOptions.sectionTitle"></bb-content-html>
    <div class="container-column mt-28px amount-form-container">
        <div class="container-column amount-inner-container">
            <form [formGroup]="amountForm" class="form" role="form" autocomplete="off">
                <div class="amount-input" test_id="amountFieldInput">
                    <label test_id="amountFieldLabel">{{hpOptions.amountFieldLabel}}</label>
                    <span class="pound-position" (click)="amountField.focus()" test_id="currencySymbol">{{hpOptions.currencySymbol}}</span>
                    <input #amountField type="text" [allow]="'Number,.'" class="form-control form-control-lg rounded-2" formControlName="amount"
                    placeholder="{{hpOptions.amountFieldPlaceholder}}" 
                    tabindex="0" 
                    accesskey="a">
                    <span class="error-text" *ngIf="!amountForm.controls.amount.valid && amountForm.controls.amount.touched" test_id="amountFieldErrorMsg">
                        {{hpOptions.amountFieldErrorMsg}}
                    </span>
                </div>
            </form>
            <div class="container-row desktop-btn-center">
                <button role="button" tabindex="0" class="wss-button" (click)="calculateQuote()"
                    [disabled]="!amountForm.controls.amount.valid" test_id="calculateQuote">
                    <ng-container *ngIf="((amountForm.controls.amount.valid || !amountForm.controls.amount.valid) && !quoteCalculated)">{{hpOptions.showQuoteButton}}</ng-container>
                    <ng-container *ngIf="quoteCalculated">{{hpOptions.recalculateButton}}</ng-container>
                </button>
            </div>
        </div>
        <div class="container-column calculated-quote" *ngIf="quoteCalculated && !lowVehicleAge">
            <div class="container-column inner" test_id="newQuoteSectionTitle">
                <bb-content-html [html]="hpOptions.newQuoteSectionTitle"></bb-content-html>
                <div class="container-column text-small paragraph">
                    <div class="container-column mt-22px" test_id="newMonthlyPayment">
                        <bb-content-html [html]="hpOptions.newMonthlyPayment"></bb-content-html>
                        <span class="text-semibold" test_id="new_rental_value">£{{quote?.new_rental_sets[0]?.new_rental_value}}</span>
                    </div>
                    <div class="container-column mt-16px" test_id="newNumOfPayments">
                        <bb-content-html [html]="hpOptions.newNumOfPayments"></bb-content-html>
                        <span class="text-semibold" test_id="new_number_of_rentals">{{quote?.new_rental_sets[1]?.new_number_of_rentals + quote?.new_rental_sets[0]?.new_number_of_rentals}}</span>
                    </div>
                    <div class="container-column mt-12px" test_id="newApr">
                        <bb-content-html [html]="hpOptions.newApr"></bb-content-html>
                        <span class="text-semibold" test_id="quoteAPR">{{quote?.APR}}%</span>
                    </div>
                    <div class="container-column mt-14px" test_id="newOutstanding">
                        <bb-content-html [html]="hpOptions.newOutstanding"></bb-content-html>
                        <span class="text-semibold" test_id="total_Payable">£{{quote?.total_Payable}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-column calculated-quote" *ngIf="lowVehicleAge">
            <div class="container-column inner paragraph" test_id="calculatedQuote">
                <div *ngIf="error === 'paymenttoolow'">
                    <span><bb-content-html [html]="hpOptions.paymentTooLow"></bb-content-html></span>
                    <div class="brand-contact-details">
                        <span><bb-content-html [html]="hpOptions.paymentTooLowPartTwo"></bb-content-html></span>
                        <span *ngIf="paymentHolidayWidgetItem.brandContactDetails.brandWiseCfaNumber">
                            <bb-content-html [html]="paymentHolidayWidgetItem.brandContactDetails.brandWiseCfaNumber"></bb-content-html>
                        </span>
                        <span><bb-content-html [html]="hpOptions.paymentTooLowPartThree"></bb-content-html></span>
                    </div>
                </div>
                <div *ngIf="error === 'amounttoolow'">
                    <span><bb-content-html [html]="hpOptions.amountTooLow"></bb-content-html></span>
                    <div class="brand-contact-details">
                        <span><bb-content-html [html]="hpOptions.amountTooLowPartTwo"></bb-content-html></span>
                        <span *ngIf="paymentHolidayWidgetItem.brandContactDetails.brandWiseCfaNumber">
                            <bb-content-html [html]="paymentHolidayWidgetItem.brandContactDetails.brandWiseCfaNumber"></bb-content-html>
                        </span>
                        <span><bb-content-html [html]="hpOptions.amountTooLowPartThree"></bb-content-html></span>
                    </div>
                </div>
                <div *ngIf="error === 'amounttohigh'">
                    <span><bb-content-html [html]="hpOptions.amountTooHigh"></bb-content-html></span>
                    <div class="brand-contact-details">
                        <span><bb-content-html [html]="hpOptions.amountTooHighPartTwo"></bb-content-html></span>
                        <span class="brand-number" *ngIf="paymentHolidayWidgetItem.brandContactDetails.brandWiseCfaNumber">
                            <bb-content-html [html]="paymentHolidayWidgetItem.brandContactDetails.brandWiseCfaNumber"></bb-content-html>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-column final-payment mt-17px" *ngIf="quoteCalculated && !lowVehicleAge">
            <div class="container-column" test_id="amountMayDefer">
                <p class="mt-18px" test_id="nextPaymentWillBe">{{hpOptions.nextPaymentWillBe}} <span class="text-semibold" test_id="new_rental_value">£{{quote?.new_rental_sets[0]?.new_rental_value}}</span> {{hpOptions.willBeTakenOn}}<span class="text-semibold" test_id="new_rental_date">{{quote?.new_rental_sets[0]?.new_rental_date}}</span>.</p>
                <p test_id="finalPaymentWillBe">{{hpOptions.finalPaymentWillBe}} <span class="text-semibold" test_id="new_rental_value">£{{quote?.new_rental_sets[2]?.new_rental_value}}</span> {{hpOptions.willBeTakenOn}} <span class="text-semibold" test_id="new_rental_date">{{quote?.new_rental_sets[2]?.new_rental_date}}</span>.</p>
                <bb-content-html [html]="hpOptions.amountMayDefer"></bb-content-html>
            </div>
        </div>
    </div>
    <div class="container-row continue desktop-btn-center" *ngIf="quoteCalculated && !lowVehicleAge">
        <button role="button" tabindex="0" class="wss-button" (click)="finalizeQuote()" [disabled]="!(quoteCalculated && !lowVehicleAge)" test_id="nextButton">{{hpOptions.nextButton}}</button>
    </div>
    <div class="flex-horizontal-center mt-16px" [ngClass]="quoteCalculated? 'flex-horizontal-center mt-16px': 'flex-horizontal-center'" test_id="pageBottomLink">
        <bb-content-html [html]="hpOptions.pageBottomLink"></bb-content-html>
    </div>
</div>

<!-- 
    <div class="container-column mt-26px hp-options">
    <span class="text-semibold text-bigger">Your new agreement</span>
    <div class="container-column mt-28px amount-form-container">
        <div class="container-column amount-inner-container">
            <form [formGroup]="amountForm" class="form" role="form" autocomplete="off">
                <div class="amount-input">
                    <label>How much can you afford to pay each month?</label>
                    <span class="pound-position">£</span>
                    <input type="text" [allow]="'Number,.'" class="form-control form-control-lg rounded-2" formControlName="amount"
                        placeholder="Enter amount" tabindex="0" accesskey="a" aria-describedby="Enter amount">
                    <span class="error-text" *ngIf="!amountForm.controls.amount.valid && amountForm.controls.amount.touched">
                        Amount is required
                    </span>
                </div>
            </form>
            <div class="container-row desktop-btn-center">
                <button role="button" tabindex="0" class="wss-button" (click)="calculateQuote()"
                    [disabled]="!amountForm.controls.amount.valid">
                    <ng-container *ngIf="((amountForm.controls.amount.valid || !amountForm.controls.amount.valid) && !quoteCalculated)">Show quote</ng-container>
                    <ng-container *ngIf="quoteCalculated">Recalculate quote</ng-container>
                </button>
            </div>
        </div>
        <div class="container-column calculated-quote" *ngIf="quoteCalculated && !lowVehicleAge">
            <div class="container-column inner">
                <span class="text-semibold mt-16px">Your quote for a modified agreement:</span>
                <div class="container-column text-small">
                    <div class="container-column mt-22px">
                        <span>New monthly payment amount:</span>
                        <span class="text-semibold">£{{quote?.new_rental_sets[0]?.new_rental_value}}</span>
                    </div>
                    <div class="container-column mt-16px">
                        <span>Number of payments left on agreement will be:</span>
                        <span class="text-semibold">{{quote?.new_rental_sets[1]?.new_number_of_rentals + quote?.new_rental_sets[0]?.new_number_of_rentals}}</span>
                    </div>
                    <div class="container-column mt-12px">
                        <span>APR:</span>
                        <span class="text-semibold">{{quote?.APR}}%</span>
                    </div>
                    <div class="container-column mt-14px">
                        <span>Total amount outstanding will be:</span>
                        <span class="text-semibold">£{{quote?.total_Payable}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-column calculated-quote" *ngIf="lowVehicleAge">
            <div class="container-column inner" *ngIf="error === 'paymenttoolow'">
                <span class="text-semibold mt-16px">Your quote for a modified agreement:</span>
                <span class="mt-21px">We're sorry. The amount you have entered would make your agreement too long for the age of your vehicle.</span>
                <br>
                <ul role="list">
                    <li role="listitem">If you can afford to pay more, please try another amount.</li>
                    <br>
                    <li role="listitem">If you can't afford to pay more, call us on <a tabindex="0" title="0800 000 000">0800 000 000</a> so we can discuss options to help you. To give you the best support when you call us, we advise you to fill in our online <a tabindex="0" title="Budgeting Tool" role="link">Budgeting Tool</a> before calling.</li>
                </ul>
            </div>

            <div class="container-column inner" *ngIf="error === 'amounttoolow'">
                <span class="text-semibold mt-16px">Your quote for a modified agreement:</span>
                <span class="mt-21px">We're sorry. The amount you have entered is too low because it would extend your agreement by more than 24 months.</span>
                <br>
                <ul role="list">
                    <li role="listitem">If you can afford to pay more, please try another amount.</li>
                    <br>
                    <li role="listitem">If you can't afford to pay more, call us on <a title="0800 000 000" tabindex="0">0800 000 000</a> so we can discuss options to help you. To give you the best support when you call us, we advise you to fill in our online <a tabindex="0" title="Budgeting Tool" role="link">Budgeting Tool</a> before calling.</li>
                </ul>
            </div>

            <div class="container-column inner" *ngIf="error === 'amounttohigh'">
                <span class="text-semibold mt-16px">Your quote for a modified agreement:</span>
                <span class="mt-21px">We're sorry. The amount you have entered is higher than your current monthly payment. Please try a lower amount.</span>
                <br>
                <span class="mb-20px">If you want to increase your monthly payment, please call us on <a title="0800 000 000" tabindex="0">0800 000 000</a>.</span>
            </div>
        </div>
        <div class="container-column final-payment mt-17px" *ngIf="quoteCalculated && !lowVehicleAge">
            <div class="container-column">
                <p class="mt-18px">The next payment would be <span class="text-semibold">£{{quote?.new_rental_sets[0]?.new_rental_value}}</span> and would be taken on <span class="text-semibold">{{quote?.new_rental_sets[0]?.new_rental_date}}</span>.</p>
                <p>The final payment on your agreement would be <span class="text-semibold">£{{quote?.new_rental_sets[2]?.new_rental_value}}</span> and would be taken on <span class="text-semibold">{{quote?.new_rental_sets[2]?.new_rental_date}}</span>.</p>
                <p class="text-semibold">This is a quote. The actual amounts may differ slightly if you proceed.</p>
            </div>
        </div>
    </div>
    <div class="container-row continue desktop-btn-center" *ngIf="quoteCalculated && !lowVehicleAge">
        <button role="button" tabindex="0" class="wss-button" (click)="finalizeQuote()" [disabled]="!(quoteCalculated && !lowVehicleAge)">Next</button>
    </div>
    <div class="flex-horizontal-center mt-16px" [ngClass]="quoteCalculated? 'flex-horizontal-center mt-16px': 'flex-horizontal-center'">
        <span><a (click)="goBack()" role="navigation" title="Choose another option" tabindex="0">Choose another option</a></span>
    </div>
</div>
 -->