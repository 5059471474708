import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';
import { LoginWidgetItem } from '@wss/model/login-widget/login-widget';
import { Router } from '@angular/router';
import { RegisterService } from '@wss/registration-widget/src/service/register.service';
import { takeUntil } from 'rxjs/operators';
import { isErrServer } from '@wss/common/util/util';
import { DatastoreService } from '@wss/service/datastore.service';

@Component({
  selector: 'bb-wss-passcode-error',
  templateUrl: './passcode-error.component.html',
  providers: [ContentService],
})
export class PasscodeErrorComponent implements OnInit {
  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>(
    'loginWidgetItem',
  );
  readonly destroy$ = new Subject();
  serviceError: boolean = false;
  errorCode: any;

  ngOnInit(): void {
    this.tealiumService.view({
      JourneyName: 'Login',
      JourneyStepName: 'Passcode Error',
      CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    });
  }

  constructor(
    public router: Router,
    private tealiumService: TealiumUtagService,
    private readonly bbContentService: ContentService,
    private registerService: RegisterService,
    private cmndatastoreService: DatastoreService,
    private refChange: ChangeDetectorRef
  ) {}

  showError(errStatus: number): void {
    if(isErrServer(errStatus)){
      this.cmndatastoreService.setErrorServer();
      this.serviceError = true;
      this.refChange.detectChanges();
    }
  }

  submit() {
    this.registerService.getForgotEmailOTP().pipe(takeUntil(this.destroy$)).subscribe(() => { 
      this.serviceError = false;    
      this.router.navigate(['verify-otp']);
    },
    (error:any) => {
      this.errorCode = error.error?.code ? error.error.code : error.status;
      this.showError(error.status);      
    });
  }

  navigateToLogin() {
    this.router.navigate(['user-login']);
  }
}
