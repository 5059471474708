import { Component, OnInit, ChangeDetectorRef, HostListener, OnDestroy } from '@angular/core';
import { WSSAppConstant, ContentData } from '../../config/wss-app-constants';
import { NextPayment } from '../../model/nextPayment';
import { DatastoreService } from '../../service/datastore.service';
import { RoutableWidget, RouterService } from "@backbase/foundation-ang/core";
import { MessageService } from 'libs/service/message.service';
import { BannerMessage } from 'libs/model/bannerMessage';
import { isErrServer } from 'libs/common/util/util';
import { Observable, Subject } from 'rxjs';
import { DashboardWidgetItem } from '@wss/model/content/dashboard';
import { ContentService } from '@backbase/universal-ang/content';
import { takeUntil } from 'rxjs/operators';
import { EocLandingComponent } from './components/eoc-landing/eoc-landing.component';
import { EocOptionDetailsComponent } from './components/eoc-option-details/eoc-option-details.component';
import { EocKeepYourVehicleComponent } from './components/eoc-keep-your-vehicle/eoc-keep-your-vehicle.component';
import { EocConfirmationComponent } from './components/eoc-confirmation/eoc-confirmation.component';
import { EocPartExchangeComponent } from './components/eoc-part-exchange/eoc-part-exchange.component';
import { EocReturnVehicleComponent } from './components/eoc-return-vehicle/eoc-return-vehicle.component';
import { EocContactDetailsComponent } from './components/eoc-contact-details/eoc-contact-details.component';
import { EocReturnVehicleConfirmationComponent } from './components/eoc-return-vehicle-confirmation/eoc-return-vehicle-confirmation.component';
import { EocPartExchangeConfirmationComponent } from './components/eoc-part-exchange-confirmation/eoc-part-exchange-confirmation.component';
import { EocHpComponent } from './components/eoc-hp/eoc-hp.component';
import { EocGetInTouchComponent } from './components/eoc-get-in-touch/eoc-get-in-touch.component';
import { ManageContractComponent } from './components/manage-contract/manage-contract.component';
import { VoluntaryTermination } from './components/voluntary-termination/voluntary-termination.component';
import { EocVoluntaryTerminationCtaPage } from './components/eoc-voluntary-termination-CTA-page/eoc-voluntary-termination-CTA-page.component';
import { VoluntaryTerminationPageAccessRouteGuard } from '@wss/route-guards/end-of-contract/voluntary-termination-route-guard/voluntary-termination-page-access';

@Component({
  selector: 'bb-end-of-contract-widget',
  templateUrl: 'end-of-contract-widget.component.html',
  providers: [ContentService]
})

@RoutableWidget({
  routes: [
    { path: '', redirectTo: 'landing', pathMatch: 'full' },
    { path: 'landing', component: EocLandingComponent },
    { path: 'optionDetails', component: EocOptionDetailsComponent },
    { path: 'keep-your-vehicle', component: EocKeepYourVehicleComponent },
    { path: 'part-exchange', component: EocPartExchangeComponent },
    { path: 'return-vehicle', component: EocReturnVehicleComponent },
    { path: 'confirmation', component: EocConfirmationComponent },
    { path: 'contact-details', component: EocContactDetailsComponent },
    { path: 'get-in-touch', component: EocGetInTouchComponent },
    { path: 'return-vehicle-confirmation', component: EocReturnVehicleConfirmationComponent },
    { path: 'part-exchange-confirmation', component: EocPartExchangeConfirmationComponent },
    { path: 'eoc-hp', component: EocHpComponent },
    { path: 'manage-contract', component: ManageContractComponent },
    { path: 'voluntary-termination', component: VoluntaryTermination, canActivate: [VoluntaryTerminationPageAccessRouteGuard] },
    { path: 'cta-voluntary-termination', component: EocVoluntaryTerminationCtaPage }
  ]
})
export class EndOfContractWidgetComponent implements OnInit, OnDestroy {

  constructor(private dataStoreService: DatastoreService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.dataStoreService.setEocRoute(window.location.href.substr(window.location.href.lastIndexOf('/') + 1));
    this.messageService.setActiveNav("End of contract");
    this.dataStoreService.sessionInvalid();
  }

  ngOnDestroy(): void { }

}
