import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'wss-currency-input',
  templateUrl: './currency-input.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})

export class CurrencyInputComponent {

  @Input() currencySymbol: any;
  @Input() controlName: string = '';
  @Input() submitForm: boolean = false;
  @Input() errors: any;
  @Input() errorMsg: any;
  @Input() showError: boolean = true;  
  @Input() maxLn?: number = 6;
  @Input() ariaLabel: string = '';
    
  constructor() { }
}
