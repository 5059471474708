import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BalloonRefinanceService } from '@wss/balloon-refinance-widget/src/services/balloon-refinance.service';
import { BalloonRefinanceGetdetailsItem, ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BalloonRefinanceGetdetailsService } from '../../services/balloon-refinance-getdetails.service';

@Component({
  selector: 'bb-brg-connection-failure',
  templateUrl: './brg-connection-failure.component.html'
})
export class BrgConnectionFailureComponent implements OnInit {
  brgItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');
  apiCallCounter: number = 2;
  readonly destroy$ = new Subject();
  vehicleType: any;
  accountDetails: any;
  getErrorType: boolean;
  creditCheckData: any;
  serviceError: boolean = false;
  decisionData: any;
  timerCounter: number = 0;
  timer: number[] = [15000, 15000, 30000];
  vehicleTypeCheck: string = '';
  proposalData: ProposalRequest;
  lastDateOfDecision: any;

  constructor(
    private readonly bbContentService: ContentService,
    private datastore: DatastoreService,
    private balloonRefinanceSrv: BalloonRefinanceService,
    private modalService: NgbModal,
    private router: Router,
    private tealiumService: TealiumUtagService,
    private changeDetectorRef: ChangeDetectorRef,
    private brgService: BalloonRefinanceGetdetailsService
  ) {
    this.getErrorType = this.datastore.getErrorBrServer();
    this.proposalData = this.datastore.getProposalRequest();
  }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'Proposal - Connection failure',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.accountDetails = this.datastore.getAccountDetails();
    this.datastore.setBrHideStepper(true);
    this.datastore.setVehicleType();
    this.datastore.setCheckVehicleType();
    this.vehicleTypeCheck = this.datastore.getCheckVehicleType();
    this.lastDateOfDecision = this.datastore.getLastDateForEocDecision(this.accountDetails?.final_payment_date);
  }

  tryAgain(modalContent: any): void {
    if (this.apiCallCounter > 0 ) {
      this.saveProposalCustomer(modalContent)
    } else {
      this.router.navigate(['/balloon-refinance-getdetails/connection-failure-redirect'])
    }
  }

  saveProposalCustomer(modalContent: any) {
    const payload = this.datastore.getProposalCustomerPayload();
    this.showLoader(modalContent)
    this.balloonRefinanceSrv.postCustomer(payload, this.vehicleTypeCheck).pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.creditCheckData = this.datastore.getProposalCustomerDetails();
        if(res.proposal?.decision?.decision_status === 'INCOMPLETE'){
          this.postProposalData();
          this.hideLoader();
          this.router.navigate(['/balloon-refinance/cta-expired-eoc-period']);
        } else {
          this.postCustomersComments();
          if(res.proposal?.decision?.decision_message !== null && res.proposal?.decision?.decision_message.includes('PENDING')){
            this.getDecision(res.proposal.proposal_id, res.palis_customer_id, this.vehicleTypeCheck)
          } else {
            this.routingResponse(res.proposal?.decision);
          }
        }
      }, 
    (error: any) => {
      this.hideLoader();
      if(this.apiCallCounter === 0) {
        this.router.navigate(['/balloon-refinance-getdetails/connection-failure-redirect']);
      }
    });
    this.apiCallCounter--;
  }

  getDecision = (proposalId: any, palisCustomerId: any, vehicleType: string) => {
    this.balloonRefinanceSrv.getDecision(proposalId, palisCustomerId, vehicleType).pipe(takeUntil(this.destroy$)).subscribe((res:any) => {
      this.decisionData = res;
      if(this.timerCounter < 3 && res.decision_status === 'REFERRED' && res.decision_message.includes('PENDING')) {
        this.setTimer(this.timer[this.timerCounter], proposalId, palisCustomerId, this.vehicleTypeCheck);
        this.timerCounter++;
      } else {
        let curmPayload = this.brgService.curmCommentsBuilder(this.accountDetails, this.decisionData)
        this.balloonRefinanceSrv.postCustomerComments(curmPayload).pipe(takeUntil(this.destroy$)).subscribe();
        this.postProposalData();
        this.routingResponse(res);
        this.hideLoader();
      }
    }, 
    (error: any) => {
    });
  }

  postProposalData() {
    const updatedProposalData = this.brgService.updateProposalData(this.proposalData, this.creditCheckData, this.decisionData)
    this.balloonRefinanceSrv.postProposalDetails(updatedProposalData).subscribe((res: any) => {
      this.proposalData = res;
    })
  }

  showLoader(modalContent: any) {
    this.modalService.open(modalContent, { centered: true, windowClass: 'account-summary-modal' });
  }

  hideLoader() {
    this.modalService.dismissAll();
  }

  postCustomersComments() {
    let payload = this.brgService.createCustomerComments(this.accountDetails, this.creditCheckData)
    this.balloonRefinanceSrv.postCustomerComments(payload).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
    }, 
    (error: any) => {
      this.showError();
    });
  }

  showError(): void {
    this.datastore.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  setTimer(timer: number, proposalId: any, palisCustomerId: any, vehicleType: string) {
    setTimeout(() => {
      this.getDecision(proposalId, palisCustomerId, vehicleType);
    }, timer);
  }

  routingResponse(res: any){
    const routeUrl = this.brgService.getRouting(res);
    this.router.navigate([routeUrl]);
  }

}