import { Component, OnInit, Input } from '@angular/core';
import { WSSAppConstant } from '@wss/config/wss-app-constants';

@Component({
  selector: 'bb-info-alert',
  templateUrl: './info-alert.component.html',
  styles: [
  ]
})
export class InfoAlertComponent {
  @Input() infoContent: any;
  svgInfoIcon = WSSAppConstant.SVG_INFO_ICON;
}
