import { Component, OnInit } from '@angular/core';
import { RoutableWidget } from '@backbase/foundation-ang/core';
import { HelpCentreLandingComponent } from './help-centre-landing/help-centre-landing.component';
import { DatastoreService } from '../../service/datastore.service';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { ComplaintsComponent } from './complaints/complaints.component';
import { GetHelpSupportComponent } from './help-support/get-help-support.component';
import { MoneyWorriesComponent } from './money-worries/money-worries.component';
import { ManageAccountComponent } from './manage-account/manage-account.component';
import { MyAgreementFaqsComponent } from './my-agreement/my-agreement-faqs.component';
import { MakePaymentComponent } from './make-payment/make-payment.component';
import { PaymentHolidayFaqsComponent } from './payment-holiday-faqs/payment-holiday-faqs.component';
import { BudgetWidgetComponent} from './budget-home/budget-home.component';
import { MessageService } from '@wss/service/message.service';
import { VehicleComplaintsComponent } from './vehicle-complaints/vehicle-complaints.component';

@Component({
  selector: 'bb-help-centre-widget',
  templateUrl: 'help-centre-widget.component.html',
  styles: [
  ]
})

@RoutableWidget({
  routes: [
    {path: '', redirectTo: 'landing', pathMatch: 'full'},
    {path: 'landing', component: HelpCentreLandingComponent},
    {path: 'get-in-touch', component: GetInTouchComponent},
    {path: 'complaints', component: ComplaintsComponent},
    {path: 'get-help-support', component: GetHelpSupportComponent},
    {path: 'money-worries', component: MoneyWorriesComponent},
    {path: 'manage-account', component: ManageAccountComponent},
    {path: 'my-agreement', component: MyAgreementFaqsComponent},
    {path: 'make-payment', component: MakePaymentComponent},
    {path: 'payment-holiday-faqs', component: PaymentHolidayFaqsComponent},
    {path: 'budget-home', component:BudgetWidgetComponent },
    {path: 'vehicle-complaints', component:VehicleComplaintsComponent}
  ],
})
export class HelpCentreWidgetComponent implements OnInit {

  constructor(
    private datastoreService: DatastoreService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.datastoreService.sessionInvalid();
    this.messageService.setActiveNav("Help centre internal");
  }

}
