import { Component, OnInit, Input } from '@angular/core';
import { FAQAccordion } from '../../../../../model/payment-holiday-widget/payment-holiday-widget';

@Component({
  selector: 'payment-holiday-accordion',
  templateUrl: 'payment-holiday-accordion.component.html'
})
export class PaymentHolidayAccordionComponent implements OnInit {

  @Input("faqAccordion") faqAccordion: FAQAccordion = new FAQAccordion();

  constructor() { }

  ngOnInit(): void {
  }

}