<div *ngIf="endOfContractWidgetItem$ | async as endOfContractWidgetItem">
    <form [formGroup]="eocContactForm">
        <div class="container-column" *ngIf="endOfContractWidgetItem.endOfContractContactDetails">
            <div class="section-title-container">
                <h1 class="section-label-title" test_id="pageName">
                    {{ endOfContractWidgetItem.endOfContractContactDetails.pageName }}</h1>
            </div>
            <!-- <div class="reg-row plr-10pt" role="alert" *ngIf="serviceError" >
            <bb-wss-banner-message></bb-wss-banner-message>
        </div> -->
            <div role="alert" *ngIf="serviceError">
                <bb-wss-banner-message></bb-wss-banner-message>
            </div>
            <div>
                <div test_id="textBelowPageHeader">{{
                    endOfContractWidgetItem.endOfContractContactDetails.textBelowPageHeader }}​</div>
                <div class="card">
                    <div class="card-content">
                        <div class="mb-10" test_id="collectionTextBeforeData">
                            {{ endOfContractWidgetItem.endOfContractContactDetails.collectionTextBeforeData }} <span
                                class="font-weight-bold">{{ customerDetail?.contact_details?.mobile_phone_number
                                }}</span>
                            {{ endOfContractWidgetItem.endOfContractContactDetails.collectionTextAfterData }}</div>
                        <div test_id="contactNumberQuestionText" class="mt-25px">
                            <bb-content-html
                                [html]="endOfContractWidgetItem.endOfContractContactDetails.contactNumberQuestionText">
                            </bb-content-html>
                        </div>
                        <mat-radio-group aria-label="Select an option" style="display: flex; flex-direction: column"
                            formControlName="isCorrectNumber" color="primary">
                            <!-- [(ngModel)]="isCorrectNumber" -->
                            <mat-radio-button value="yes" test_id="yesText">{{
                                endOfContractWidgetItem.endOfContractContactDetails.yesText }}</mat-radio-button>
                            <mat-radio-button value="no" test_id="noText">{{
                                endOfContractWidgetItem.endOfContractContactDetails.noText }}</mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="eocContactForm.get('isCorrectNumber').value === 'no'">
                            <div test_id="anotherContactNumberText" class="mt-25px">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractContactDetails.anotherContactNumberText">
                                </bb-content-html>
                            </div>
                            <div class="mobile-field">
                            <div class="form-group">
                                <input type="text" class="form-control" formControlName="newContactNo"
                                    (ngModelChange)="showHideError()" placeholder="Enter mobile number"
                                    pattern="[+0-9]*" maxlength="13" minlength="10" 
                                    [ngClass]="{ 'is-invalid': hasMobileNumberError}">
                                <!--  maxlength="14" minlength="11" [(ngModel)]="newContactNo"-->
                                <span class="error-text" *ngIf="hasMobileNumberError">
                                    <bb-content-html
                                        [html]="endOfContractWidgetItem.endOfContractContactDetails.mobileNumberErrorMessageText">
                                    </bb-content-html>
                                </span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-column service-unavailable-container">
                    <div class="container-row back-btn">
                        <button class="wss-button" 
                            [disabled]="!eocContactForm.get('isCorrectNumber').value || (eocContactForm.get('isCorrectNumber').value === 'no' && eocContactForm.controls.newContactNo.invalid) || hasMobileNumberError"
                            (click)="confirm()"
                            test_id="continueButtonText">
                            <!-- !isCorrectNumber || (isCorrectNumber === 'no' && !newContactNo) -->
                            {{ endOfContractWidgetItem.endOfContractContactDetails.continueButtonText }}
                        </button>
                    </div>
                </div>

                <div class="text-center">
                    <a routerLink="/eoc-options/return-vehicle" test_id="cancelLinkText">
                        {{ endOfContractWidgetItem.endOfContractContactDetails.cancelLinkText }}
                    </a>
                </div>
            </div>
        </div>
    </form>
</div>