<div class="hc-money-worries-page" *ngIf="helpCentreWidgetItem$ | async as helpCentreWidgetItem">
  <!-- work in progress for returning customer -->
  <section id="bannerMsgId" class="container-row banner-message-box" *ngIf="hasBudgetWithDraft">
    <div ng-reflect-ng-class="container-row section1 message" class="container-row section1 budget-banner-section">
      <div class=" vertical-align-center">
        <div class="icon_info_circle "></div>
      </div>
    </div>
    <div ng-reflect-ng-class="container-column section2" class="container-column section2">
      <div class="container-column section2-inner">
        <div>
          <bb-content-html [html]="helpCentreWidgetItem.helpCentreMoneyWorries.budgetBannerReturningCustomerText"></bb-content-html>
          <ul>
            <li><a (click)="returnToForm()" title="Return to form">{{helpCentreWidgetItem.helpCentreMoneyWorries.budgetBannerReturnToFormLinkText}}</a></li>
            <li><a (click)="startNewForm()" title="Start a new Form">{{helpCentreWidgetItem.helpCentreMoneyWorries.budgetBannerNewFormLinkText}}</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-row section3" (click)="hideBudgetBanner()"><span
        class="material-icons clear-icon">clear</span>
    </div>
  </section>
  <!-- work in progress for returning customer -->


  <div class="container-column">
    <div class="help-centre-title">
      <span class="section-label-title" test_id="helpCentreMoneyWorriespageName">
        {{helpCentreWidgetItem.helpCentreMoneyWorries.pageName}}</span>
    </div>

    <div class="customer-support-text margin-top-30">
      <div test_id="helpCentreMoneyWorriespageHeaderBelowText">
        <bb-content-html [html]="helpCentreWidgetItem.helpCentreMoneyWorries.pageHeaderBelowText"></bb-content-html>
      </div>

      <div>
        <div class="help-centre-support transparent-accordion container-row margin-top-30">
          <bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreMoneyWorries.moneyWorriesTopAccordion" [testIDText]="'helpCentreMoneyWorriesTopAccordion'"></bb-common-expansion-panel>
        </div>
      </div>

      <div class="margin-top-30">
        <div test_id="helpCentreMoneyWorriesstepOneTitle">
          <h3><strong>
              <bb-content-html [html]="helpCentreWidgetItem.helpCentreMoneyWorries.stepOneTitle"></bb-content-html>
            </strong></h3>
        </div>
        <div class="margin-top-20" test_id="helpCentreMoneyWorriesstepOneContent">
          <bb-content-html [html]="helpCentreWidgetItem.helpCentreMoneyWorries.stepOneContent"></bb-content-html>
        </div>
        <div class="margin-top-20" test_id="helpCentreMoneyWorriesFormContent">
          <div class="container-column money-worries-help-form">
            <div class="container-column" aria-describedby="Fill form">
              <div class="container-row">
                <bb-content-html [html]="helpCentreWidgetItem.helpCentreMoneyWorries.helpFormTitle"></bb-content-html>
              </div>
              <div class="container-row">
                <bb-content-html [html]="helpCentreWidgetItem.helpCentreMoneyWorries.helpFormTextBelow">
                </bb-content-html>
              </div>
              <div class="container-column margin-top-10">
                <button class="wss-button btn btn-dif-view" test_id="helpFormButtonText"
                  (click)="launchBudgetingTool()">
                  {{ helpCentreWidgetItem.helpCentreMoneyWorries.helpFormButtonText }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="margin-top-30">
        <div class="margin-top-20" test_id="helpCentreMoneyWorriesstepTwoTitle">
          <h3><strong>
              <bb-content-html [html]="helpCentreWidgetItem.helpCentreMoneyWorries.stepTwoTitle"></bb-content-html>
            </strong></h3>
        </div>
        <div class="margin-top-20" test_id="helpCentreMoneyWorriesstepTwoContent">
					<span><bb-content-html [html]="helpCentreWidgetItem.helpCentreMoneyWorries.stepTwoContent"></bb-content-html></span>
					<span class="contact-number-text margin-top-10">
						<span class="cfa-call-icon"></span>
						<bb-content-html [html]="helpCentreWidgetItem.helpCentreMoneyWorries.callUsOn"></bb-content-html>
						<strong><bb-content-html *ngIf= "accountDetails.is_vulnerable" [html]="helpCentreWidgetItem.helpCentreMoneyWorries.vulnerableCustomerContact"></bb-content-html></strong>
						<strong><bb-content-html *ngIf= "!accountDetails.is_vulnerable" [html]="helpCentreWidgetItem.brandContactDetails.brandWiseCfaNumber"></bb-content-html></strong>
					</span>
					<ul class="margin-top-10">
						<li>
							<bb-content-html [html]="helpCentreWidgetItem.helpCentreMoneyWorries.contactLineAvailableTimeText"></bb-content-html>
						</li>
						<li>
							<span class="para-inline margin-top-10">
								<bb-content-html [html]="helpCentreWidgetItem.helpCentreMoneyWorries.callUsOnTRSText"></bb-content-html>
								<strong><bb-content-html *ngIf= "accountDetails.is_vulnerable" [html]="helpCentreWidgetItem.helpCentreMoneyWorries.vulnerableCustomerFreeTRSNumber"></bb-content-html></strong>
								<strong><bb-content-html *ngIf= "!accountDetails.is_vulnerable" [html]="helpCentreWidgetItem.brandContactDetails.brandWiseFreeTRSNumber"></bb-content-html></strong>
							</span>
						</li>
					</ul>
				</div>
      </div>


      <div class="margin-top-30"> 
        <div class="margin-top-30" test_id="helpCentreMoneyWorriesstepThreeContent">
          <bb-content-html [html]="helpCentreWidgetItem.helpCentreMoneyWorries.stepThreeContent"></bb-content-html>
        </div>

        <div class="help-centre-support transparent-accordion container-row margin-top-30">
          <bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreMoneyWorries.moneyWorriesAccordion" testIDText="'helpCentreMoneyWorriesAccordion'"></bb-common-expansion-panel>
        </div>
      </div>
    </div>
  </div>
</div>
