import { BrandContactDetailsItem } from '@wss/model/content/common/brand-contant-details';

export interface CustomerToken {
    token: string;
    user: boolean;
}

export interface userToken {
    token: string;
    user: boolean;
    first_name: string; 
    initials: string;
    last_digits_mobile_phone: string;
}

export class userDetails implements userToken {
    token: string = '';
    user: boolean = true;
    first_name: string = '';
    initials: string  = '';
    last_digits_mobile_phone: string = '';

    constructor(){}
}

export interface validOTP {
    result: string;
}

export interface OTPVerify {
    user_token: string;
    user_id?: string;
}

export interface RegistrationWidgetItem {
    userSearch: UserSearchItem;
    userDetail: UserDetailItem;
    userCredentials: UserCredentialsItem;
    userPassword: UserPasswordItem;
    noUser: NoUserItem;
    credentialCreated: CredentialCreatedItem;
    passcodeError: PasscodeErrorItem;
    questionsFail: QuestionsFailItem;
    securityFail: SecurityFailItem;
    securityIntro: SecurityIntroItem;
    securityQuestions: SecurityQuestionsItem;
    verifySms: VerifySmsItem;
    verifyOtp: VerifyOtpItem;
    createAccountFaq: RegistrationCreateAccountFaq;
    contactPreference: ContactPreference;
    brandContactDetails: BrandContactDetailsItem;
}

export interface UserSearchItem {
    pageHeaderText: string;
    postCodeChangeFaqsText: string;
    needHelpLink: string;
    nextButtonText: string;
    whatsDobTitle: string;
    dateError: string;
    postcodeError: string;
    needLittleMoreDetails: string;
    whereToFindAgreementNumber: string;
    whereToFindAgreementNumberPopupContent: string;
    whereToFindAgreementNumberPopupContentPartTwo: string;
    EnterValidAgreementNumber: string;
    day: string;
    month: string;
    year: string;
    postcode: string;
    email: string;
    enter: string;
    password: string;
    enterPassword: string;
    confirmPassword: string;
    confirmPasswordHere: string;
    confirmEmailAddress: string;
}

export interface UserDetailItem {
    pageName: string;
    stepperOneText: string;
    stepperTwoText: string;
    stepperThreeText: string;
    welcomeText: string;
    getInTouchText: string;
    authenticateHelpText: string;
    helpToConfirmYourIdentity: string;
    verifyByTextMessageText: string;
    weWillSendYouCodeText: string;
    verifyBySecurityQuestionsText: string; 
    weWillAskSecurityQuestionsText: string;
    helpSupportVerifyingAccountText: string;
}

export interface UserCredentialsItem {
    pageHeaderText: string;
    invalidEmailError: string;
    emailMismatchError: string
    needHelpLink: string;
    nextButtonText: string;
}

export interface UserPasswordItem {
    pageHeaderText: string;
    invalidPasswordError: string;
    passwordMismatchError: string
    needHelpLink: string;
    createOnlineAccountButtonText: string;
    nextButtonText: string;
    passwordRules:passwordRules
}

export interface passwordRules {
    must : string;
    mucharseightst:string;
    uppercase:string;
    onechar:string;
    onenum:string;
    special:string;
  }

export interface NoUserItem {
    pageHeaderText: string;
    needHelpLink: string;
    tryAgainButtonText: string;
}

export interface CredentialCreatedItem {
    pageHeaderText: string;
    loginButtonText: string;
}

export interface PasscodeErrorItem {
    pageHeaderText: string;
    needHelpLink: string;
    resendCodeButtonText: string;
}

export interface QuestionsFailItem {
    pageHeaderText: string;
    needHelpLink: string;
    tryDifferentOptionButtonText: string;
}

export interface SecurityFailItem {
    pageHeaderTextOne: string;
    pageHeaderTextTwo: string;
    pageHeaderTextTimeout: string;
    backToAuthenticationOptionButtonText: string;
    needHelpLink: string;
    backToAuthenticationOptionLink: string;
}

export interface SecurityIntroItem {
    pageHeaderText: string;
    needHelpLink: string;
    confirmButtonText: string;
}

export interface SecurityQuestionsItem {
    pageName: string;
    pageHeaderText: string;
    needHelpLink: string;
    submitAnswersButtonText: string;
}

export interface VerifyOtpItem {
    pageHeaderText: string;
    codeExpiresText: string;
    needHelpLink: string;
    verifyButtonText: string;
}

export interface VerifySmsItem {
    pageHeaderTextOne: string;
    pageHeaderTextTwo: string;
    needHelpLink: string;
    nextButtonText: string;
}

export interface RegistrationCreateAccountFaq {
    pageName: string;
    pageHeaderText: string;
    // firstAccordionOptionOneTitle: string;
    // firstAccordionOptionOneContent: string;
    // firstAccordionOptionTwoTitle: string;
    // firstAccordionOptionTwoContent: string;
    // firstAccordionOptionThreeTitle: string;
    // firstAccordionOptionThreeContent: string;
    // firstAccordionOptionFourTitle: string;
    // firstAccordionOptionFourContent: string;
    // firstAccordionOptionFiveTitle: string;
    // firstAccordionOptionFiveContent: string;
    // firstAccordionOptionSixTitle: string;
    // firstAccordionOptionSixContent: string;
    // firstAccordionOptionSevenTitle: string;
    // firstAccordionOptionSevenContent: string;
    // firstAccordionOptionEightTitle: string;
    // firstAccordionOptionEightContent: string;
    // firstAccordionOptionNineTitle: string;
    // firstAccordionOptionNineContent: string;
    forHelpAndSupportText: string;
    // firstAccordionOptionThreeContentPartTwo: string;
    // firstAccordionOptionThreeContentPartThree: string;
    // firstAccordionOptionThreeContentPartFour: string;
    // firstAccordionOptionThreeContentPartFive: string;
    // firstAccordionOptionThreeContentPartSix: string;
    // firstAccordionOptionThreeContentPartSeven: string;
    // firstAccordionOptionSixContentPartTwo: string;
    createAccountAccordionContent: any[];
}

// export class BrandContactDetailsItem {
//     brandWiseAddress: string = "";
//     brandWiseContactNumber: string = "";
//     brandWiseContactEmail: string = "";
//     brandWiseCfaNumber: string = "";
//     portalName: string = "";
//     mainCollectionsLineNumber: string = "";
//     legalNumber: string = "";
// }

export interface ForgotUsernameOTPVerify {
    alias: string;
}

export interface ContactPreference {
    pageHeaderText: string;
    yourChoice: string; 
    emailLabel: string;
    textMessageLabel: string;
    phoneLabel: string;
    postLabel: string;
    onlineAccountLabel: string;
    weMayShowMessages: string;
    thirdPartyLabel: string;
    thirdPartyBelowTxt: string;
    dataCollectHeading: string;
    dataCollectInfo: string;
    doYouAgreeLabel: string;
    expansionPanelTitle1: string;
    expansionPanelPara1: string;
    expansionPanelPara2: string;
    nextButtonText: string;
    skipThisStep: string;
} 