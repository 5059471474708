import {ActionTypes, RegistrationActions} from './actions';
import {initialState, RegistrationState} from './state';


export const userRegistrationReducer = (state = initialState, action: RegistrationActions): RegistrationState => {
  switch (action.type) {
    case ActionTypes.SearchUser: {
      return {
        ...state,
        userSearchRequest: action.payload
      }
    }
    case ActionTypes.SearchUserSuccess: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case ActionTypes.UpdateTitle: {
      return {
        ...state,
        title: action.title,
      }
    }
    case ActionTypes.UpdateOtp: {
      return {
        ...state,
        otp: action.payload,
      }
    }
    case ActionTypes.UpdateStep: {
      return {
        ...state,
        step: action.payload,
      }
    }
    default: {
      return state;
    }
  }
};
