<div *ngIf="endOfContractWidgetItem$ | async as endOfContractWidgetItem">
    <div class="text-center">
        <div class="tablet-icon"></div>
        <div class="font-weight-bold" test_id="optionSelectionText">
            <p>{{ endOfContractWidgetItem.endOfContractKeepVehicleConfirmation.optionSelectionText }}</p>
        </div>
    </div>
    <div class="eoc-container">
        <div class="card">
            <div class="card-content">
                <h3 test_id="nextStepText">{{ endOfContractWidgetItem.endOfContractKeepVehicleConfirmation.nextStepText }}</h3>
                <ol>
                    <li class="m-tb-20">
                        <div class="font-weight-bold" test_id="firstStepHeaderBeforeData">
                            {{ endOfContractWidgetItem.endOfContractKeepVehicleConfirmation.firstStepHeaderBeforeData }}
                            <span>{{ eocDetails.final_payment_amount }}</span> {{ endOfContractWidgetItem.endOfContractKeepVehicleConfirmation.textBetweenAmountAndDate }} <span>{{ eocDetails.final_payment_date }}.</span>
                            {{ endOfContractWidgetItem.endOfContractKeepVehicleConfirmation.firstStepHeaderAfterData }}
                        </div>
                        <span test_id="firstStepContent">{{ endOfContractWidgetItem.endOfContractKeepVehicleConfirmation.firstStepContent }}</span>
                    </li>
                    <li class="m-tb-20">
                        <div test_id="secondStepContent">
                            <bb-content-html [html]="endOfContractWidgetItem.endOfContractKeepVehicleConfirmation.secondStepContent"></bb-content-html>
                        </div>
                    </li>
                    <li class="m-tb-20">
                        <div test_id="thirdStepContent">
                            <bb-content-html [html]="endOfContractWidgetItem.endOfContractKeepVehicleConfirmation.thirdStepContent"></bb-content-html>
                        </div>
                    </li>
                </ol>
            </div>
        </div>
        <div>
            <div class="margin-top-20 p-sm-1" test_id="thingsNeedToKnowContent">
                <bb-content-html [html]="endOfContractWidgetItem.endOfContractKeepVehicleConfirmation.thingsNeedToKnowContent"></bb-content-html>
            </div>
            <div test_id="ifYouChangeYourMindContent">
                <bb-content-html [html]="endOfContractWidgetItem.endOfContractKeepVehicleConfirmation.ifYouChangeYourMindContent"></bb-content-html>
            </div>
            <div class="d-flex">
                <button class="wss-button-secondary" (click)="backToHomepage()" test_id="backToHomepage">
                    {{ endOfContractWidgetItem.endOfContractKeepVehicleConfirmation.backToHomepage }}
                </button>
            </div>
        </div>
    </div>
    <!-- <div class="eoc-container d-flex justify-content-end">
        <div class="container-section">
            <div class="m-tb-20" test_id="ifYouChangeYourMindContent">
                <bb-content-html [html]="endOfContractWidgetItem.endOfContractKeepVehicleConfirmation.ifYouChangeYourMindContent"></bb-content-html>
            </div>
            <button class="wss-button-secondary" (click)="backToHomepage()" test_id="backToHomepage">
                {{ endOfContractWidgetItem.endOfContractKeepVehicleConfirmation.backToHomepage }}
            </button>
        </div>
    </div> -->
</div>