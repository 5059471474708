import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { RouterService } from '@backbase/foundation-ang/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ContactPreferencePopUp } from '@wss/model/content/dashboard';
import { DatastoreService } from '@wss/service/datastore.service';
import { getPortalName, getDeviceType } from '@wss/common/util/util';
declare var document: any;

@Component({
  selector: 'bb-wss-contact-preference-popup',
  templateUrl: 'contact-preference-popup.component.html',
  providers: [NgbModalConfig, NgbModal],
})
export class ContactPreferencePopUpComponent implements AfterViewInit {
  @ViewChild('contactPreferenceActionPopUp') contactPreferenceActionPopUp: any;
  
  portalName: string = getPortalName();
  deviceType: string = getDeviceType();
  isAndroid: boolean = false;

  @Input()
  contactPreferencePopupContent: ContactPreferencePopUp = new ContactPreferencePopUp();

  modalRef: any;

  constructor(
    private router: RouterService,
    private modalService: NgbModal,
    private datastoreService: DatastoreService,
    private changeDetectorRef: ChangeDetectorRef,
    config: NgbModalConfig,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngAfterViewInit(): void {
    this.isAndroid = /(android)/i.test(navigator.userAgent) || (this.deviceType === 'Mobile');
    this.modalRef = this.modalService.open(this.contactPreferenceActionPopUp, { centered: true, windowClass: this.isAndroid ? 'interstitial-modal-withscroll': 'interstitial-modal' });
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewChecked() {
      setTimeout(() => {
        if (document.querySelector('ngb-modal-window')) {
          document.querySelector('ngb-modal-window').scrollTop = 1000;
        }
      }, 300);
  }

  onModalClose() {
    this.datastoreService.setContactPreferencePopupShown(false);
    this.modalRef.close();
  }

  updateChoices() {
    this.modalRef.close();
    this.router.navigate(['/contact-preference/landing']);
  }
}
