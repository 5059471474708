<div class="get-help-support-container" *ngIf="helpCentreWidgetItem$ | async as helpCentreWidgetItem">
	<div class="container-column">
		<div class="help-centre-title">
			<span class="section-label-title" test_id="pageName">
				{{helpCentreWidgetItem.helpCentreCoronavirusSupport.pageName}}</span>
		</div>
		<div class="customer-support-text margin-top-30">
			<div test_id="pageHeaderBelowText">
				<bb-content-html [html]="helpCentreWidgetItem.helpCentreCoronavirusSupport.pageHeaderBelowText"></bb-content-html>
			</div>
			<!-- <div class="margin-top-10">
				<button class="wss-button-secondary get-help-support-btn" (click)="callMoneyWorries()" test_id="endOfPaymentHolidayOptionButton">
					{{helpCentreWidgetItem.helpCentreCoronavirusSupport.endOfPaymentHolidayOptionButton}}
				</button>
			</div> -->

			<div class="margin-top-20">
				<div test_id="firstContent">
					<span class="contact-number-text">
						<bb-content-html [html]="helpCentreWidgetItem.helpCentreCoronavirusSupport.firstContent"></bb-content-html>
						<bb-content-html [html]="helpCentreWidgetItem.brandContactDetails.brandWiseCfaNumber"></bb-content-html>
					</span>
					<div class="margin-top-10"><bb-content-html [html]="helpCentreWidgetItem.helpCentreCoronavirusSupport.saveTimeOnYourCallText"></bb-content-html></div>
				</div>
				<div class="margin-top-10">
					<button id="launchBudgetToolButton" class="wss-button-secondary get-help-support-btn" test_id="launchBudgetToolButton"
						(click)="launchBudgetingTool()">
						{{helpCentreWidgetItem.helpCentreCoronavirusSupport.launchBudgetToolButton}}
					</button>
				</div>
			</div>

			<div class="margin-top-20" test_id="secondContent">
				<bb-content-html [html]="helpCentreWidgetItem.helpCentreCoronavirusSupport.secondContent"></bb-content-html>
			</div>

		</div>
	</div>
</div>