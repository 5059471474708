<div class="content-margin carbon-offset-wrapper" test_id="carbon-offset" *ngIf="carbonIoffsetWidgetItem$ | async as carbonIoffsetWidgetItem">
    <div class="carbon-offset-widget">
        <div class="carbon-container">
            <div class="header-title section-title-container title-class">
                <span class="section-label-title" test_id="pageName">
                    {{carbonIoffsetWidgetItem.carbonIoffset.pageTitle}}</span>
            </div>
            <div class="header-text">
                <bb-content-html [html]="carbonIoffsetWidgetItem.carbonIoffset.carbonOffsettingInfo"></bb-content-html>
            </div>
            <div class="container-row content-center button-link">
                <button role="button" class="wss-button btn-dif-view" title="{{carbonIoffsetWidgetItem.carbonIoffset.tellMeMoreButtonText}}"
                    test_id="login_button_id" id="tellMeMoreButton"
                (click)="openModal(content)">{{carbonIoffsetWidgetItem.carbonIoffset.tellMeMoreButtonText}}</button>
            </div>
            <div class="container-row content-center things-to-know">
                <bb-content-html [html]="carbonIoffsetWidgetItem.carbonIoffset.thingsToKnowText"></bb-content-html>
            </div>
        </div>
    </div>

    <ng-template #content let-modal>
        <div class="container-column request-statement-modal carbon-offset-modal">
            <div class="container-row modal-header-c" test_id="carbonPopupTitle">
                <span class="carbon-popup-title">
                    <bb-content-html [html]="carbonIoffsetWidgetItem.carbonIoffset.carbonPopupTitle"></bb-content-html>
                </span>
                <span class="material-icons" (click)="modal.dismiss('Cross click');">clear</span>
            </div>
            <div class="container-column modal-body-c" test_id="carbonPopupText" id="carbonPopupText">
                <bb-content-html [html]="carbonIoffsetWidgetItem.carbonIoffset.carbonPopupText"></bb-content-html> 
            </div>
            <div class="container-row modal-footer-c space-between" test_id="takeMeToSiteButtonText">
                <button class="wss-button btn-dif-view take-me-to-site" id="takeMeToSiteButton"
                    (click)="takeMeToSiteCall();modal.dismiss('Cross click');">
                    {{carbonIoffsetWidgetItem.carbonIoffset.takeMeToSiteButtonText}}
                </button>
                <button class="wss-button-secondary btn-dif-view take-me-back" id="takeMeBackButton"
                    (click)="modal.dismiss('Cross click');">
                    {{carbonIoffsetWidgetItem.carbonIoffset.takeMeBackButtonText}}
                </button>
            </div>
        </div>
    </ng-template>
</div>