import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ContentData } from 'libs/config/wss-app-constants';
// import { ContentService } from 'libs/service/content.service';


@Component({
  selector: 'bb-wss-common-password-rules',
  templateUrl: 'password-rules.component.html'
})

export class PasswordRulesComponent implements OnInit {
    @Input()
    containsAtleastEightChars = "disabled";
    @Input()
    containsAtLeastOneUpperCase = "disabled";
    @Input()
    containsAtLeastOneLowerCase = "disabled";
    @Input()
    containsAtLeastOneNumber = "disabled"; 
    @Input()
    buttonDisabled = true;
    @Input() passwordRules :any;
    // contentTxt: string = ContentData.LoginPasswordSet;
    // content: Array<any> = [];
    // contentFolder: string = ContentData.Login;
  
  constructor(public changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    // this.getStaticContent(this.contentTxt, this.contentFolder);
    this.changeDetectorRef.detectChanges();
  }  

  // getStaticContent(contenttxt:string, contentfolder:string): void{ 
  //   this.contentService.getStaticContent(contenttxt, contentfolder).subscribe((content) => {      
  //     this.content = content;
  //     this.changeDetectorRef.detectChanges();
  //   });
  // }

}