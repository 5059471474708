import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { bbNavigationStepperExtWidgetComponentCustom } from './budget-stepper-navigation-custom-widget.component';
import { NavigationStepperWidgetModule } from '@backbase/universal-ang/navigation';

@NgModule({
  declarations: [bbNavigationStepperExtWidgetComponentCustom],
  imports: [
    BackbaseCoreModule.withConfig({
      classMap: { bbNavigationStepperExtWidgetComponentCustom }
    }),
    NavigationStepperWidgetModule,
    CommonModule
  ]
})
export class bbNavigationStepperExtWidgetModule { }
