import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { HelpCentreItem } from '@wss/model/helpCentre';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Observable } from 'rxjs';
import { injectApiDataIntoPage, injectBrandDetailIntoPage } from '@wss/common/util/util';
import { DatastoreService } from '@wss/service/datastore.service';

@Component({
  selector: 'bb-manage-account',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './manage-account.component.html',
  styles: [],
  providers: [ContentService]
})
export class ManageAccountComponent implements OnInit, AfterViewInit, OnDestroy {

  isOpen: boolean = false;
  helpCentreWidgetItem$: Observable<HelpCentreItem | undefined> = this.bbContentService.getContent<HelpCentreItem>('helpCentreWidgetItem');
  helpCentreWidgetItem: any = new HelpCentreItem();
  digitalInboxStartDate: any = "";
  isRouteFromArrearBanner: boolean = false;
  isContactPreferenceFeatureOn: boolean = false;

  constructor(
    private readonly bbContentService: ContentService,
    private datastore: DatastoreService,
    private changeDetectorRef: ChangeDetectorRef,
    private tealiumService: TealiumUtagService
  ) { }

  ngOnInit(): void {
    this.digitalInboxStartDate = this.datastore.getCurrentAccountMailPreference() ? this.datastore.getCurrentAccountMailPreference().digital_inbox_start_date : "";
    if (this.digitalInboxStartDate && this.digitalInboxStartDate.length) {
      this.digitalInboxStartDate = this.digitalInboxStartDate.split(" ");
      this.digitalInboxStartDate = this.digitalInboxStartDate.length > 1 ? this.digitalInboxStartDate[0] : "";
    }
    this.helpCentreWidgetItem$.subscribe((pageItem: any) => {
      this.helpCentreWidgetItem = pageItem;
      this.helpCentreWidgetItem.accountDetails = injectApiDataIntoPage(this.helpCentreWidgetItem.accountDetails, { digitalInboxStartDate: this.digitalInboxStartDate });
      this.helpCentreWidgetItem.accountDetails.myPersonalDetailsAccordionContent.forEach((element: any) => {
        element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
      });
      this.helpCentreWidgetItem.accountDetails.navigatingOnlineAccountAccordionContent.forEach((element: any) => {
        element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
      });
      this.helpCentreWidgetItem.accountDetails.understandingAccountAccordionContent.forEach((element: any) => {
        element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
      });
      this.helpCentreWidgetItem.accountDetails.goPaperlessFaqsAccordionContent.forEach((element: any) => {
        element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
      });
      this.helpCentreWidgetItem.accountDetails.marketingChoicesFaqsAccordionContent?.forEach((element: any) => {
        element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
      });
      this.changeDetectorRef.detectChanges();
    });
    this.isRouteFromArrearBanner = this.datastore.getArrearsBannerRoute();
    this.isContactPreferenceFeatureOn = this.datastore.getIsContactPreferenceFeature();
    this.tealiumService.view(
      {
        JourneyName: 'Help Centre',
        JourneyStepName: 'Manage Account',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

  ngAfterViewInit() {
    if (this.isRouteFromArrearBanner) {
      this.scrollToOpenedExpansionPanel();
    }
  }

  scrollToOpenedExpansionPanel() {
    this.isOpen = true
    document.getElementById('accountDetailsthirdHeaderTitle')?.scrollIntoView()
  }

  ngOnDestroy() {
    this.datastore.setArrearsBannerRoute(false)
    this.isRouteFromArrearBanner = false
  }
}
