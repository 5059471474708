import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { ContentService } from '@backbase/universal-ang/content';
import { HelpCentreItem } from '@wss/model/helpCentre';
import { TealiumUtagService } from '@wss/service/utag.service';
import { appConfig, ContentData, WSSAppConstant } from 'libs/config/wss-app-constants';
import { Observable } from 'rxjs';
import { OpenbudgetPlannerService } from '@wss/service/openbudgetplanner.service';

@Component({
  selector: 'bb-get-help-support',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './get-help-support.component.html',
  styles: [],
  providers: [ContentService],
})
export class GetHelpSupportComponent implements OnInit {
  contentTxt: string = ContentData.HelpCentreLanding;
  content: Array<any> = [];
  contentFolder: string = ContentData.HelpCentre;
  getInTouchFlag: boolean = true;
  portalName: string = '';

  helpCentreWidgetItem$: Observable<HelpCentreItem | undefined> = this.bbContentService.getContent<HelpCentreItem>(
    'helpCentreWidgetItem',
  );

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private readonly bbContentService: ContentService,
    private openbudgetPlannerService: OpenbudgetPlannerService,
    private router: Router,
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
    private tealiumService: TealiumUtagService
  ) {}

  

    ngOnInit(): void {
        this.portalName = this.pageConfig.portalName || 'blackhorse';
        this.tealiumService.view(
            {
              JourneyName: 'Help Centre',
              JourneyStepName: 'Get Help & Support',
              CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
    }

    launchBudgetingTool() {
        this.openbudgetPlannerService.openBudgetPlanner();
    }

    callMoneyWorries(){
        this.router.navigate(['help-centre/money-worries']);
    }
}
