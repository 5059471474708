import { Component, Input, OnInit,ChangeDetectorRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { patternMap } from '@wss/config/wss-app-constants';
import { DatastoreService } from '../../../../service/datastore.service';
import { LoginService } from "../../service/login.service";
import { Router } from "@angular/router";
import { isErrServer } from '../../../../common/util/util';
import { LoginWidgetItem } from '../../../../model/login-widget/login-widget';
import { Observable } from "rxjs";
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from "@wss/service/utag.service";

@Component({
  selector: 'bb-wss-password-reset',
  templateUrl: './password-reset.component.html',
  providers: [ ContentService ]
})

export class PasswordResetComponent implements OnInit {

  resetPasswordForm: FormGroup;

  @Input()
  atLeastContainsEightChars = "disabled";
  @Input()
  atLeastOneUpperCase = "disabled";
  @Input()
  atLeastOneLowerCase = "disabled";
  @Input()
  atLeastOneNumber = "disabled"; 
  @Input()
  buttonDisabled = true;
  authorisation: any;

  serviceError: boolean = false;
  hasErrors = false;
  showPasswordError: boolean = false;
  passwordMatch: boolean = true;

  upperCaseLetterformat = patternMap.uppercasePattern;
  lowerCaseLetterformat = patternMap.lowercasePattern;
  numberformat = patternMap.numberPattern;
  validCharsFormat = patternMap.validSpecialCharsPattern; 

  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');
  passwordEntered: boolean = true;
  validSpecialChars: boolean = true;

  constructor(              
    private formBuilder: FormBuilder,             
    private datastoreService: DatastoreService,
    private loginService: LoginService,
    private readonly bbContentService: ContentService,
    private router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    private tealiumService: TealiumUtagService,
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Login',
        JourneyStepName: 'Password Reset',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.getAuthorised();
    this.handlePasswordChange();
    this.changeDetectorRef.detectChanges();
  }

  getAuthorised(): void {    
    this.datastoreService.setPwdReset(true);
    this.loginService.getAuthorisation().subscribe((data: any) => {
      this.authorisation = data;
      let token = this.authorisation.headers.get('x-authorization');
      this.setHeaders(token);
      this.datastoreService.setPwdReset(false);
      this.getTokenFwdPwd();
    },
    (error: any) => {
      this.showError(error.status);
    });
  }

  setHeaders(token: any): void {
    this.datastoreService.setToken(token); 
  }

  enableButtonWhenPasswordsAreEqual(){
    if(this.resetPasswordForm?.get('confirmPassword')?.value === this.resetPasswordForm?.get('password')?.value){
      this.buttonDisabled = false;
      this.passwordMatch = true;
    } else {
      this.buttonDisabled = true;
      if (this.resetPasswordForm?.get('confirmPassword')?.value.length > 0) {
        this.passwordMatch = false;
      }
    }
  }

  checkIfButtonCanBeEnabled() {
    if(this.atLeastOneNumber === 'done' &&
      this.atLeastOneLowerCase === 'done' &&
      this.atLeastOneUpperCase === 'done' &&
      this.validSpecialChars === true &&
      this.atLeastContainsEightChars === 'done'
    ){
      this.passwordEntered = false;
      this.enableButtonWhenPasswordsAreEqual();
    } else {
      this.passwordEntered = true;   
    }
  }

  onSubmit() {
    let cfPassword = btoa(unescape(encodeURIComponent(this.resetPasswordForm?.get('confirmPassword')?.value)));
    this.loginService.confirmPwdLink(cfPassword, true).subscribe(() => {
      let pwdStatus = this.datastoreService.getConfirmPwdStatus();
      if(pwdStatus === 'Updated'){
        this.router.navigate(['password-successful']);
      } else {
        this.router.navigate(['link-expired']);
      }
    },      
    (error:any) => {        
      this.router.navigate(['link-expired']);
    });     
  }

  handlePasswordChange() {
    const password = this.resetPasswordForm?.get('password');
    const confirmPassword = this.resetPasswordForm?.get('confirmPassword');
    password?.valueChanges.forEach(
      (value: string) => {
        this.isLengthEightChars(value);        
        this.hasOneUpperCaseChar(value);
        this.hasOneLowerCaseChar(value);
        this.hasOneNumber(value);
        this.hasValidChars(value);
        this.checkIfButtonCanBeEnabled();
        if (value.length > 3 && (this.atLeastContainsEightChars === 'disabled' || this.atLeastOneLowerCase === 'disabled'
           || this.atLeastOneNumber === 'disabled' || this.atLeastOneUpperCase === 'disabled' || this.validSpecialChars === false)) {
          this.showPasswordError = true;
        } else {
          this.showPasswordError = false;
        }
      }
    );

    confirmPassword?.valueChanges.forEach(
      (value: string) => {
        this.checkIfButtonCanBeEnabled();
        if (value.length > 3 && value !== this.resetPasswordForm.controls['password'].value) {
          this.passwordMatch = false;
        } else {
          this.passwordMatch = true;
        }
      }
    );
  }

  private hasOneNumber(value: string) {
    if (value && value.match(this.numberformat)) {
      this.atLeastOneNumber = "done";
    } else {
      this.atLeastOneNumber = "disabled";
    }
  }

  private hasOneLowerCaseChar(value: string) {
    if (value && value.match(this.lowerCaseLetterformat)) {
      this.atLeastOneLowerCase = "done";
    } else {
      this.atLeastOneLowerCase = "disabled";
    }
  }

  private hasOneUpperCaseChar(value: string) {
    if (value && value.match(this.upperCaseLetterformat)) {
      this.atLeastOneUpperCase = "done";
    } else {
      this.atLeastOneUpperCase = "disabled";
    }
  }

  private isLengthEightChars(value: string) {
    if ((value.length >= 8) && (value.length <= 35)) {
      this.atLeastContainsEightChars = "done";
    } else {
      this.atLeastContainsEightChars = "disabled";
    }
  }

  private hasValidChars(value: string) {
    if (value && value.match(this.validCharsFormat)) {
      this.validSpecialChars = true;
    } else {
      this.validSpecialChars = false;
    }
  }

  getTokenFwdPwd() {
    let getURI = location.href;
    let tokenTemp = getURI.indexOf('=');
    let tokenPwd = getURI.substring(tokenTemp+1);
    this.datastoreService.setFwdPwdToken(tokenPwd);
    this.loginService.validatePwdLink().subscribe(() => {
      let validLink = this.datastoreService.getVdResetLink();
      if (validLink !== 'Valid') {
        this.router.navigate(['link-expired']);
      }
    },
    (error: any) => {
      this.showError(error.status);
    });
  }

  showError(errStatus: number): void {
    if(isErrServer(errStatus)){
      this.datastoreService.setErrorServer();
      this.serviceError = true;
      this.changeDetectorRef.detectChanges();
    }
  }

}
