import { Component, OnInit, Input } from '@angular/core';
import { AdditionalInfo } from '../../model/account-summary';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { RouterService } from '@backbase/foundation-ang/core';
import { AdditionalInfoContent } from '@wss/model/content/dashboard';
import { appConfig } from '@wss/config/wss-app-constants';
import { getDeviceType } from '@wss/common/util/util';

@Component({
  selector: 'bb-wss-additional-info',
  templateUrl: 'additional-info.component.html'
})
export class AdditionalInfoComponent implements OnInit {
  @Input() svgInfoIcon: string = '';
  @Input() additionalInfoData: AdditionalInfo = new AdditionalInfo();
  @Input() additionalInfoContent: AdditionalInfoContent = new AdditionalInfoContent();
  @Input() accountDetail: any;
  @Input() isPcpToHpBalloonRefinanceFlag: any;
  @Input() iconColor: any;
  @Input() isLPAgreementFlag: any;
  

  popoverArrowStyle: string = '';
  popoverSelf: any;
  tooltipCloseStyle: string = "font-size: 18px; margin-top: -10px !important;";
  hpType: number = appConfig.hpCustomer;
  pcpCustomerType: number = appConfig.pcpCustomer;
  deviceType: string = "Mobile";
  balloonPcpCustomer: boolean = true;


  constructor(
    config: NgbPopoverConfig,
    private router: RouterService,
  ) {
    config.placement = 'bottom';
    config.container = "body";
  }

  ngOnInit(): void {
      if (this.accountDetail && this.accountDetail.scheme_code != this.pcpCustomerType 
        || (this.accountDetail.scheme_code == this.pcpCustomerType && this.accountDetail.final_payment_amount == "£0")) {
        this.balloonPcpCustomer = false;
      } else {
        this.balloonPcpCustomer = true;
      }
  }

  openPopover(popover: any, event: any, id: string) {
    popover.open();
    this.popoverSelf = popover;
    this.deviceType = getDeviceType();
    if((id === 'aprPopId') && (this.deviceType === 'Mobile')){
      this.popoverArrowStyle = 'left: 12% !important';
    }
    else if((id === 'aprPopId') && (this.deviceType === 'Tablet')){
      this.popoverArrowStyle = 'left: 9% !important';
    }
    else if((id === 'aprPopId') && (this.deviceType === 'Desktop')){
      this.popoverArrowStyle = 'left: 73% !important';
    }
    else if((id === 'ofpPopId') && (this.deviceType === 'Mobile')){
      this.popoverArrowStyle = 'left: 33% !important';
    }
    else if((id === 'ofpPopId') && (this.deviceType === 'Tablet')){
      this.popoverArrowStyle = 'left: 30% !important';
    }
    else if((id === 'ofpPopId') && (this.deviceType === 'Desktop')){
      this.popoverArrowStyle = 'left: 73% !important';
    }
  }

  closePopover() {
    this.popoverSelf.close();
  }

  helpCentreLink() {
    this.router.navigate(['/../help-centre']);
    window.scroll(0, 0);
  }

}