<div class="payment-holiday-container" *ngIf="paymentHolidayWidgetItem$ | async as paymentHolidayWidgetItem">
    <div class="container-column your-options">
        <div class="container-row section-title-container">
            <span class="section-title" test_id="paymentHolidayPageName">{{paymentHolidayWidgetItem.cohortFirst.pageName}}</span>
        </div>
        <div class="container-row holiday-ends">
            <span class="para-inline" test_id="paymentHolidayPageText">
                <bb-content-html [html]="paymentHolidayWidgetItem.cohortFirst.pageText"></bb-content-html>
                {{accountDetail?.next_payment_date}}.
            </span>
        </div>
        <div class="container-column mb-10px" test_id="faqTitleText">
            <bb-content-html [html]="paymentHolidayWidgetItem.cohortFirst.faqTitleText"></bb-content-html>
        </div>
        <payment-holiday-accordion [faqAccordion]="paymentHolidayWidgetItem.cohortFirst.faqAccordion"></payment-holiday-accordion>
    </div>
</div>