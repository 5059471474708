import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';


import { WSSCommonModule } from '../../common/common.module';
import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';
import { RouterModule } from '@angular/router';
import { BalloonRefinanceGetdetailsWidgetComponent } from "./balloon-refinance-getdetails-widget.component";
import { BalloonRefinanceGetdetailsAboutComponent } from './components/balloon-refinance-getdetails-about/balloon-refinance-getdetails-about.component';
import { BalloonRefinanceGetdetailsBankComponent } from './components/balloon-refinance-getdetails-bank/balloon-refinance-getdetails-bank.component';
import { BalloonRefinanceGetdetailsEmploymentComponent } from './components/balloon-refinance-getdetails-employment/balloon-refinance-getdetails-employment.component';
import { BalloonRefinanceGetdetailsFinanceComponent } from './components/balloon-refinance-getdetails-finance/balloon-refinance-getdetails-finance.component'
import { BalloonRefinanceGetdetailsVehicleComponent } from './components/balloon-refinance-getdetails-vehicle/balloon-refinance-getdetails-vehicle.component';
import { StepperModule } from "@backbase/ui-ang";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BalloonRefinanceGetdetailsSummaryComponent } from './components/balloon-refinance-getdetails-summary/balloon-refinance-getdetails-summary.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrgConnectionFailureComponent } from './components/brg-connection-failure/brg-connection-failure.component';
import { BrgReferralComponent } from './components/brg-referral/brg-referral.component';
import { BrgConnectionFailureRedirectComponent } from './components/brg-connection-failure-redirect/brg-connection-failure-redirect.component';
import { BrgAcceptComponent } from './components/brg-accept/brg-accept.component';
import { BrgDeclineComponent } from './components/brg-decline/brg-decline.component';
import { BrgPostDeclineComponent } from './components/brg-post-decline/brg-post-decline.component';
import { MatIconModule } from '@angular/material/icon';
import { BrgModAgNextStepComponent } from './components/brg-mod-ag-next-step/brg-mod-ag-next-step.component';

@NgModule({
  declarations: [
    BalloonRefinanceGetdetailsWidgetComponent,
    BalloonRefinanceGetdetailsAboutComponent,
    BalloonRefinanceGetdetailsBankComponent,
    BalloonRefinanceGetdetailsEmploymentComponent,
    BalloonRefinanceGetdetailsFinanceComponent,
    BalloonRefinanceGetdetailsVehicleComponent,
    BalloonRefinanceGetdetailsSummaryComponent,
    BrgConnectionFailureComponent,
    BrgReferralComponent,
    BrgAcceptComponent,
    BrgDeclineComponent,
    BrgConnectionFailureRedirectComponent,
    BrgPostDeclineComponent,
    BrgModAgNextStepComponent
],
  imports: [
    CommonModule,
    BackbaseCoreModule.withConfig({
      classMap: { BalloonRefinanceGetdetailsWidgetComponent }
    }),
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule,
    RouterModule.forRoot([], { initialNavigation: 'disabled', useHash: true }),
    RouterModule,
    StepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatIconModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class BalloonRefinanceGetdetailsWidgetModule { }