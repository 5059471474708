import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { ContentService } from '@backbase/universal-ang/content';
import { LoginWidgetItem } from '../../../../model/login-widget/login-widget';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-wss-change-email-max-attempts',
  templateUrl: './change-email-max-attempts.component.html',
  providers: [ ContentService ]
})

export class ChangeEmailMaxAttemptsComponent implements OnInit {

  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');


  constructor(
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
  ) {
  }   

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Login',
        JourneyStepName: 'Change Email Max Attempts',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

}
