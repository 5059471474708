import { PortalContentMock } from "@backbase/foundation-ang/web-sdk";
import { ServiceException } from "@wss/model/service-exception";

export class DigitalInboxWidgetItem{
    preferenceLanding: PreferenceLanding = new PreferenceLanding();
    preferenceResult: PreferenceResult = new PreferenceResult();
    accountSelection: AccountSelection = new AccountSelection();
    managePreference: ManagePreference = new ManagePreference();
    confirmPreference: ConfirmPreference = new ConfirmPreference();
    brandContactDetails: any = {};
}

export class PreferenceLanding{
    pageName: string = '';
    pageTopText: string = '';
    accordionTitleText: string = '';
    accordionContentText: string = '';
    emailText: string = '';
    importantText: string = '';
    buttonText: string = '';
    pageBottomLink: string = '';
}

export class PreferenceResult{
    pageName: string = '';
    managePreferencePageName: string = '';
    successCardText: string = '';
    successTextManagePaperlessSingleAgmt: string = '';
    successTextManagePostSingleAgmt: string = '';
    successTextManagePreferenceMultiAgmt: string = '';
    rememberText: string = '';
    rememberTextManagePaperlessSingleAgmt: string = '';
    rememberTextManagePostSingleAgmt: string = '';
    rememberTextManagePreferenceMultiAgmt: string = '';
    buttonText: string = '';
    needHelpLink: string = '';
}

export class AccountSelection{
    pageName: string = '';
    pageTopText: string = '';
    agreementNumberText: string = '';
    vehicleAndRegistrationText: string = '';
    otherAgreementsText: string = '';
    buttonText: string = '';
    pageBottomLink: string = '';
    preferenceUpdateException: ServiceException = new ServiceException();
}

export class ManagePreference{
    pageName: string = '';
    pageTopText: string = '';
    agreementNumberText: string = '';
    vehicleAndRegistrationText: string = '';
    selectedPreferencePostText: string = '';
    selectedPreferencePaperlessText: string = '';
    optionByPostText: string = '';
    optionPaperlessText: string = '';
    otherAgreementsText: string = '';
    textAboveOption: string = '';
    textBelowOption: string = '';
    accordionTitleText: string = '';
    accordionContentText: string = '';
    importantInfoText: string = '';
    nextButtonText: string = '';
    pageBottomLinks: string = '';
}

export class ConfirmPreference{
    pageName: string = '';
    agreementNumberText: string = '';
    vehicleAndRegistrationText: string = '';
    selectedPreferencePostText: string = '';
    selectedPreferencePaperlessText: string = '';
    textBelowAccountBoxSingleAgmtPaperless: string = '';
    textBelowAccountBoxSingleAgmtPost: string = '';
    textBelowAccountBoxMultiAgmt: string = '';
    otherAgreementsText: string = '';
    confirmBtnSingleAgmtPaperlessText: string = '';
    confirmBtnSingleAgmtPostText: string = '';
    confirmBtnMultiAgmtText: string = '';
    comfirmLinkTextPost: string = '';
    needHelpLinkTextPost: string = '';
    pageBottomLinks: string = '';
    preferenceUpdateException: ServiceException = new ServiceException();
}

export const digitalInboxWidgetItemContentRef = PortalContentMock.addStructuredContent<DigitalInboxWidgetItem>('digitalInboxWidgetItem', {
    preferenceLanding: {
        pageName: 'Change letter and statement preferences',
        pageTopText: 'By going paperless, you can:',
        accordionTitleText: `How does it work?`,
        accordionContentText: `We’ll send an email to tell you when there are new documents to view in your Digital Inbox`,
        emailText: 'Your email address',
        importantText: 'Important information',
        buttonText: 'Go paperless',
        pageBottomLink: 'Back to Home'
    },
    preferenceResult: {
        pageName: 'Change letter and statement preferences',
        managePreferencePageName: 'Letter and statement preferences',
        successCardText: "Your letter and statement preferences have been changed.",
        successTextManagePaperlessSingleAgmt: `You have chosen the paperless option for your letters and statements.`,
        successTextManagePostSingleAgmt: `You have chosen to receive your letters and statements by post.`,
        successTextManagePreferenceMultiAgmt: `Your settings have been updated!`,
        rememberText: 'Remember:',
        rememberTextManagePaperlessSingleAgmt: `Remember:`,
        rememberTextManagePostSingleAgmt: `Remember:`,
        rememberTextManagePreferenceMultiAgmt: `Remember:`,
        buttonText: 'Go paperless',
        needHelpLink: `Need help?`
    },
    accountSelection: {
        pageName: 'Change letter and statement preferences',
        pageTopText: `What we send to you online will include:`,
        agreementNumberText: 'Your agreement number',
        vehicleAndRegistrationText: 'Your vehicle & registration',
        otherAgreementsText: `Would you also like to go paperless for your other agreements?
        Please select:`,
        buttonText: 'Go paperless',
        pageBottomLink: 'Back to Home',
        preferenceUpdateException: {} as any
    },
    managePreference: {
        pageName: 'Letter and statement preferences',
        pageTopText: `You're currently getting your letters and statements:`,
        agreementNumberText: 'Your agreement number',
        vehicleAndRegistrationText: 'Your vehicle & registration',
        selectedPreferencePostText: 'Letter and Statement preference: By post',
        selectedPreferencePaperlessText: 'Letter and Statement preference: Paperless',
        optionByPostText: 'By post',
        optionPaperlessText: 'Paperless',
        otherAgreementsText: 'Your preferences for your other agreements:',
        textAboveOption: '',
        textBelowOption: `Select to receive your letters and statements 'By post' or 'Paperless' above. Click 'Next' to continue.`,
        accordionTitleText: 'How does paperless work?',
        accordionContentText: `We'll send an email to tell you`,
        importantInfoText: 'Important information',
        nextButtonText: 'Next',
        pageBottomLinks: 'Back to Home'
    },
    confirmPreference: {
        pageName: 'Change letter and statement preferences',
        agreementNumberText: 'Your agreement number',
        vehicleAndRegistrationText: 'Your vehicle & registration',
        selectedPreferencePostText: 'Letter and Statement preference: By post',
        selectedPreferencePaperlessText: 'Letter and Statement preference: Paperless',
        textBelowAccountBoxSingleAgmtPaperless: 'You have chosen to receive your letters and statements paperless',
        textBelowAccountBoxSingleAgmtPost: 'You have chosen to receive your letters and statements by post',
        textBelowAccountBoxMultiAgmt: `If you are happy with your preferences, please click 'Confirm' below`,
        otherAgreementsText: 'Your preferences for your other agreements:',
        confirmBtnSingleAgmtPaperlessText: 'Go paperless',
        confirmBtnSingleAgmtPostText: 'Go back to choose paperless',
        confirmBtnMultiAgmtText: 'Confirm',
        comfirmLinkTextPost: 'I would like to receive letters and statements by post',
        needHelpLinkTextPost: 'Need help?',
        pageBottomLinks: 'Back to Home',
        preferenceUpdateException: {} as any
    },
    brandContactDetails: {}
});