import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { RouterService } from '@backbase/foundation-ang/core';
import { appConfig, RuntimeEnv } from '@wss/config/wss-app-constants';
import { TealiumUtagService } from '@wss/service/utag.service';
import { CarbonIoffsetItem } from '@wss/model/carbon-ioffset';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '@wss/service/http.service';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { MessageService } from '@wss/service/message.service';
import { CarbonOffsetService } from './carbon-offset.service';

@Component({
  selector: 'bb-carbon-ioffset-widget',
  templateUrl: './carbon-ioffset-widget.component.html',
  providers: [ ContentService ]
})
export class CarbonIoffsetComponent implements OnInit, OnDestroy {
  carbonIoffsetWidgetItem$: Observable<CarbonIoffsetItem|undefined> = this.bbContentService.getContent<CarbonIoffsetItem>('carbonIoffsetWidgetItem');
  modalRef: any;
  readonly destroy$ = new Subject();

  constructor(
    private readonly bbContentService: ContentService,
    // private router: RouterService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private tealiumService: TealiumUtagService,
    private modalService: NgbModal,
    private carbonOffsetService: CarbonOffsetService
  ) {
  }
  
  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Carbon Offset',
        JourneyStepName: 'Landing page',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

  takeMeToSiteCall() {
    this.carbonOffsetService.postCustomerConsent().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
    });
    window.open(appConfig.carbonOffsetLink);
  }  

  openModal(content: any) {
    this.modalRef =  this.modalService.open(content);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
