import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { BudgetFormItem } from '@wss/model/budget-form-widget/budgetForm'
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { saveAndExitService } from '@wss/budget-cover-widgets/components/save-and-exit-modal/save-and-exit.service';
import { UtilService } from '@wss/budget-cover-widgets/shared/service/util.service';
import { BudgetService } from '@wss/budget-cover-widgets/shared/service/budget.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { BudgetPlanner } from '@wss/budget-cover-widgets/shared/model/budget.model';
import { TealiumUtagService } from '@wss/service/utag.service';
@Component({
  selector: 'bb-budget-personal-info-widget',
  templateUrl: './budget-personal-info-widget.component.html',
  providers: [ContentService]
})
export class BudgetPersonalInfoWidgetComponent implements OnInit {
  personalInfoForm: any;
  submitted: boolean = false;
  numberGroup: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  formdata: any;
  serviceError: boolean = false

  constructor(
    private fb: FormBuilder,
    private readonly bbContentService: ContentService,
    private route: ActivatedRoute,
    private router: Router,
    private utilservice: UtilService,
    private modalService: saveAndExitService,
    private datastoreService: DatastoreService,
    private budgetService: BudgetService,
    private changeDetectorRef: ChangeDetectorRef,
    private tealiumService: TealiumUtagService
  ) {}

  budgetFormItem$: Observable<BudgetFormItem | undefined> = this.bbContentService.getContent<BudgetFormItem>('BudgetFormItem');

  ngOnInit(): void {
    this.getServiceData();
    this.initPersonalForm();
    this.tealiumService.view(
      {
        JourneyName: 'Budget Cover',
        JourneyStepName: 'Personal Info',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

  initPersonalForm(){
    this.personalInfoForm = this.fb.group({
      nr_of_dependents: [this.formdata?.nr_of_dependents, [Validators.required]],
      nr_of_vehicles_lending: [this.formdata?.nr_of_vehicles_lending, [Validators.required]],
      nr_of_adults: [this.formdata?.nr_of_adults, [Validators.required]],
      additional_info: this.fb.array([]),
      current_page:["1"],
      status:'Draft'
    })
  }

  get numberofDependent() {
    return this.personalInfoForm.get('nr_of_dependents')
  }

  get numberofVehicle() {
    return this.personalInfoForm.get('nr_of_vehicles_lending')
  }

  get numberofAdult() {
    return this.personalInfoForm.get('nr_of_adults')
  }

  goToNextPage() {
    this.submitted = true;
    //call post/put service call here
    if (this.personalInfoForm.valid) {
       if(this.formdata && this.formdata.id) {
        this.updatePersonaData();
       }
       else {
        this.postPersonaData();
       }
    }
  }


  routeToNextPage(){
    this.router.navigate(['budget-form/2']);
  }


  updatePersonaData() {
    this.budgetService.updateBudgetDetail(this.personalInfoForm.value,  'Personal').subscribe((budgetResponse: BudgetPlanner) => {
      this.datastoreService.setBudgetDetail(budgetResponse);
      this.routeToNextPage()
    }, (error: any) => {
      this.showError();
    });
  }

  postPersonaData() {
    this.budgetService.postBudgetDetail(this.personalInfoForm.value, 'Personal').subscribe((budgetResponse: BudgetPlanner) => {
      this.datastoreService.setBudgetDetail(budgetResponse);
      this.routeToNextPage()
    }, (error: any) => {
      this.showError();
    });
  }

  goBackToBudgetHome() {
    this.router.navigate(['help-centre/budget-home']);
    window.scroll(0, 0);
  }

  openModal(budgetFormItem: object) {
    this.submitted = true;
    window.scroll(0, 0);
    if (this.personalInfoForm.valid) {
      this.modalService.openModalFromService(budgetFormItem, this.personalInfoForm.value, 'Personal')
    }
  }

  getServiceData() {
    this.formdata = this.datastoreService.getBudgetDetail() // this.utilService.mockData()
  }

  showError(): void {
    this.datastoreService.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }
}
