import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentService {

  constructor(private httpService: HttpService) { }

  getStaticContent(contenttxt:string, contentfolder:string): Observable<any[]>{
    return this.httpService.getStaticContent(contenttxt, contentfolder);
  }
}