<ng-container *ngIf="helpCentreWidgetItem">
    <div class="help-centre-widget-container">
        <div class="help-centre-title">
            <span class="section-label-title">
                {{helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsPageHeader}}</span>
        </div>
        <div class="title-header margin-top-20">
            <h3>
                <strong>
                    <bb-content-html
                        [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsFirstTitle"></bb-content-html>
                </strong>
            </h3>
        </div>
        <div class="customer-support-text margin-top-10">
            <div>
                <bb-content-html
                    [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsInitialInstructions"></bb-content-html>
            </div>
        </div>
        <div class="title-header margin-top-20">
            <h3>
                <strong>
                    <bb-content-html
                        [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsContactUsAccordionTitle"></bb-content-html>
                </strong>
            </h3>
            <div class="complaints-header" *ngIf="!accountDetail.in_arrears && isComplaintFeatureEnabled">
                <div class="title-header margin-top-10"
                    *ngIf="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsHeaderText"
                    test_id="helpCentreVehicleComplaintsHeaderTitle">
                    <div class="complaints-header-text">
                        <bb-content-html
                            [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsHeaderText"></bb-content-html>
                    </div>
                </div>
                <div class="content-center margin-bottom"
                    *ngIf="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsMakeAComplaintButton">
                    <button id="makeAComplaintButton" class="wss-button button-width-padding"
                        test_id="helpCentreVehicleComplaintsMakeAComplaintButtonText" (click)="onMakeComplaintClick()">
                        {{helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsMakeAComplaintButton}}
                    </button>
                </div>
                <div class="complaints-header-text margin-top-10" *ngIf="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsOtherWaysToComplaintText">
                    <bb-content-html
                        [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsOtherWaysToComplaintText"></bb-content-html>
                </div>
            </div>
        </div>
        <div class="help-centre-support margin-top-30">
            <bb-common-expansion-panel
                [accordionContent]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsContactUsAccordion"></bb-common-expansion-panel>
        </div>
        <div class="customer-support-text margin-top-10">
            <div>
                <bb-content-html
                    [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintConfirmationParagraph"></bb-content-html>
            </div>
        </div>
        <div class="title-header margin-top-20">
            <h3>
                <strong>
                    <bb-content-html
                        [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsSupportingEvidenceTitle"></bb-content-html>
                </strong>
            </h3>
        </div>
        <div class="customer-support-text margin-top-10">
            <div>
                <bb-content-html
                    [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsSupportingEvidenceParagraph"></bb-content-html>
            </div>
        </div>
        <div class="title-header margin-top-20">
            <h3>
                <strong>
                    <bb-content-html
                        [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsResolvingTitle"></bb-content-html>
                </strong>
            </h3>
        </div>
        <div class="customer-support-text margin-top-10">
            <div>
                <bb-content-html
                    [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsResolvingParagraph"></bb-content-html>
            </div>
        </div>
        <div class="title-header margin-top-20">
            <h3>
                <strong>
                    <bb-content-html
                        [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsCommonQuestionsTitle"></bb-content-html>
                </strong>
            </h3>
        </div>
        <div class="help-centre-support margin-top-30">
            <bb-common-expansion-panel
                [accordionContent]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsCommonQuestionsAccordion"></bb-common-expansion-panel>
        </div>
    </div>
</ng-container>