import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {OtpGenerationResponse, User} from "../../../registration-store/src/user";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, map, tap} from "rxjs/operators";
import {UserSearchRequest} from '../user-search-request';
import {MessageService} from '../../../service/message.service';
import {SearchUserSuccessAction} from "../../../registration-store/src/actions";
import {OtpGenerationRequest} from "../otp-generation-request";

@Injectable()
export class UserSearchService {
  private userSearchUrl = 'api/users';
  private otpGenerationApi = 'api/users';
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  }

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
  ) {}

  getUsers(userSearchRequest: UserSearchRequest): Observable<User[]> {
    return this.http.get<User[]>(`${this.userSearchUrl}/?dateOfBirth=${userSearchRequest.dateOfBirth}&postCode=${userSearchRequest.postCode}`)
      .pipe(
        tap(_ => {
          this.log(`fetched users: ${userSearchRequest.postCode}`);
        }),
        catchError(this.handleError<User[]>('searchUsers',[]))
      );
  }


  generateOtp(otpGenerationRequest: OtpGenerationRequest): Observable<OtpGenerationResponse|undefined> {
    return this.http.post<OtpGenerationResponse>(`${this.otpGenerationApi}/?registration_option=OTP`, otpGenerationRequest)
      .pipe(
        tap(_ => {
          this.log(`generated otp for user: ${otpGenerationRequest.user_id}`);
        }),
        catchError(this.handleError<OtpGenerationResponse>('searchUsers',undefined))
      );
  }

  generateOtpGet(otpGenerationRequest: OtpGenerationRequest): Observable<OtpGenerationResponse|undefined> {
    return this.http.get<OtpGenerationResponse>(`${this.otpGenerationApi}/?user_id=${otpGenerationRequest.user_id}`)
      .pipe(
        tap(_ => {
          this.log(`generated otp for user: ${otpGenerationRequest.user_id}`);
        }),
        catchError(this.handleError<OtpGenerationResponse>('searchUsers',undefined))
      );
  }

  searchUser(userSearchRequest: UserSearchRequest): Observable<SearchUserSuccessAction> {
    return this.http.get<User[]>(`${this.userSearchUrl}/?dateOfBirth=${userSearchRequest.dateOfBirth}&postCode=${userSearchRequest.postCode}`)
      .pipe(
        map(value =>
          new SearchUserSuccessAction(value)
        )
      );
  }


  private handleError<T>(operation = 'operation', result?: T ) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }


  private log(message: string) {
    this.messageService.add(`UserSearchService: ${message}`);
  }

}
