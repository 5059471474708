import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { WelcomeWidgetItem } from '@wss/model/content/welcome-widget';
import { Observable } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { RouterService } from '@backbase/foundation-ang/core';
import { appConfig, RuntimeEnv } from '@wss/config/wss-app-constants';
import { TealiumUtagService } from '@wss/service/utag.service';
import { ActivatedRoute } from '@angular/router';

declare let document: any;
@Component({
  selector: 'bb-welcome-widget',
  templateUrl: './welcome-widget.component.html',
  providers: [ ContentService ]
})
export class WelcomeWidgetComponent implements OnInit, OnDestroy {
  welcomeWidgetItem$: Observable<WelcomeWidgetItem|undefined> = this.bbContentService.getContent<WelcomeWidgetItem>('welcomeWidgetItem');
  welcomeWidgetItem:WelcomeWidgetItem = new WelcomeWidgetItem();
  portalName: any;
  redirectionPageList: any[] = ['settlement', 'makePaymentFAQs', 'getInTouch'];
  redirectionParam: any = '';

  constructor(
    private readonly bbContentService: ContentService,
    private routerAct: ActivatedRoute,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private tealiumService: TealiumUtagService
  ) {
    this.renderer.addClass(document.body, 'no-nav-bar');
    this.renderer.addClass(document.body, 'welcome-page');
  }
  
  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Welcome',
        JourneyStepName: 'Landing Page',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
    this.getPortalName();
    this.welcomeWidgetItem$.subscribe((welcomeWidgetItem: WelcomeWidgetItem| undefined)=> {
      if(welcomeWidgetItem){
        this.welcomeWidgetItem = welcomeWidgetItem;
      }
    });
    this.routerAct.queryParams.subscribe(params => {
      if(this.redirectionPageList.indexOf(params["redirectTo"]) !== -1){
        this.redirectionParam = params["redirectTo"];
      }
    });
    document.getElementById("appLoader").style.display = "none";
  }

  getPortalName() {
    if (RuntimeEnv.backbase) {
      this.portalName = window.BB.config.portalName;
      // "blackhorse"
    }
    this.changeDetectorRef.detectChanges();
  }

  // navigate(url: string) {
  //   this.router.navigate([url]);
  // }

  navigateToLogin() {
    if(this.redirectionParam){
      location.href = appConfig.loginURL + `#/user-login?redirectTo=${this.redirectionParam}`;
    }
    else{
      location.href = appConfig.loginURL;
    } 
  }

  navigateToRegistration() {
    location.href = appConfig.regURL;
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'no-nav-bar');
    this.renderer.removeClass(document.body, 'welcome-page');
  }
}
