<div class="container-column" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
    <div class="change-payment-container">
        <div class="container-row" *ngIf="serviceError">
            <bb-wss-banner-message></bb-wss-banner-message>
        </div>
        <div class="container-row" *ngIf="isBannerActive">
            <bb-wss-banner-message></bb-wss-banner-message>
        </div>
        <div class="container-column">
            <div class="container-row section-title-container">
                <span class="section-title" test_id="changePaymentDatePageName">
                    <!-- {{content[0]?.cpd}} -->
                    {{paymentsWidgetItem.changePaymentResult.changePaymentDatePageName}}
                </span>
            </div>

            <div class="container-column active-debit-card">
                <div class="container-column content-center" test_id="paymentSuccessUpdateInfoText">
                    <span class="wss-success-icon change-payment-success"></span>
                    <!-- <span class="card-info">{{content[0]?.successupdate}}</span>
                    <span class="text-center">{{content[0]?.willreflect}}</span> -->
                    <span class="container-row content-center change-payment-success-message">
                        <bb-content-html [html]="paymentsWidgetItem.changePaymentResult.paymentSuccessUpdateInfoText">
                        </bb-content-html>
                    </span>
                </div>
                <div class="container-row back-to-direct-debit back-to-cp cp-success">
                    <a role="link" title="{{paymentsWidgetItem.changePaymentResult.smsConfirmLinkText}}" tabindex="0"
                        test_id="smsConfirmLinkText" (click)="sendSMSClick()">
                        <!-- {{content[0]?.smsconfirm}} -->
                        {{paymentsWidgetItem.changePaymentResult.smsConfirmLinkText}}
                    </a>
                </div>
            </div>
            <div class="container-row mt-20px mb-120px content-center">
                <button id="change-payment-result-btn" role="button" tabindex="0" class="wss-button" test_id="backToHomeButtonText"
                    (click)="navigateTo()">
                    <!-- {{content[0]?.retruntoas}} -->
                    {{paymentsWidgetItem.changePaymentResult.backToHomeButtonText}}
                </button>
            </div>
        </div>
    </div>
</div>