import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MessageService } from '../../../../service/message.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterService } from "@backbase/foundation-ang/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceException } from '../../../../model/service-exception';
import { DatastoreService } from '../../../../service/datastore.service';
import { isErrServer, getErrorImageClass, MustMatch } from '../../../../common/util/util';
import { appConfig } from '../../../../config/wss-app-constants';
import { PaymentHolidayService } from '../../services/payment-holiday.service';
import { Observable } from 'rxjs';
import { PaymentHolidayWidgetItem } from '../../../../model/payment-holiday-widget/payment-holiday-widget';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'finalize-agreement',
    templateUrl: './finalize-agreement.component.html',
    providers: [ ContentService ]
})

export class FinalizeAgreementComponent implements OnInit, AfterViewChecked{
    emailForm!: FormGroup;
    checkbox: any = {
        checked: false
    };
    serviceException: ServiceException = new ServiceException();

    @ViewChild("processingContent") modalContentRef: any;

    selectedQuote: any = {};
    customerDetail: any = {};
    accountDetail: any = {};
    serviceError: boolean = false;
    paymentHolidayWidgetItem$: Observable<PaymentHolidayWidgetItem | undefined> = this.bbContentService.getContent<PaymentHolidayWidgetItem>('paymentHolidayWidgetItem');
    paymentHolidayWidgetItem: PaymentHolidayWidgetItem = new PaymentHolidayWidgetItem();
    modelRef: any;
    loggedInEmail: string = "";

    get f() { return this.emailForm.controls; }

    constructor(
        private modalService: NgbModal,
        private router: RouterService,
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private datastore: DatastoreService,
        private changeDetectorRef: ChangeDetectorRef,
        private paymentHolidayService: PaymentHolidayService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService) { }

    ngOnInit() {
        this.emailForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            comfirmEmail: ['', [Validators.required, Validators.email]],
            emailToBeSent: ['', [Validators.required]]
        },{
            validator: MustMatch('email', 'comfirmEmail')
        });
        this.loggedInEmail = this.datastore.getLoggedInEmail();
        this.selectedQuote = this.datastore.getSelectedTermExtensionQuote();
        this.customerDetail = this.datastore.getCustomerDetail();
        this.accountDetail = this.datastore.getAccountDetails();
        this.accountDetail.scheme_code = parseInt(this.accountDetail.scheme_code);
        this.handleErrors();
        this.messageService.setCurrentStep(3);
        this.paymentHolidayWidgetItem$.subscribe((paymentHolidayWidgetItem: any) => {
            this.paymentHolidayWidgetItem = paymentHolidayWidgetItem;
        });
        this.tealiumService.view(
            {
                JourneyName: 'Payment holiday',
                JourneyStepName: 'Finalize Agreement',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
    }

    ngAfterViewChecked() {
        if(this.modalContentRef){
            this.handleCallToAction();
        }
    }

    applyForTermExt(modalContent: any) {
        let termExtFormDetail: any;
        let termExtQuoteRequest: any = {
            "modag_Type": this.accountDetail.scheme_code === appConfig.hpCustomer ? "T" : this.accountDetail.scheme_code === appConfig.pcpCustomer ? "P" : "",
            "is_No_Circ_Changes": true
        };
        termExtFormDetail = this.datastore.getTermExtFormDetail();
        if (termExtFormDetail) {
            termExtQuoteRequest["email_Address"] = termExtFormDetail.emailToBeSent === 'Yes' ? this.loggedInEmail : termExtFormDetail.email;
            termExtQuoteRequest["is_Email_Accepted"] = termExtFormDetail.emailToBeSent === 'Yes' ? true : false;
        }
        else {
            termExtFormDetail = {
                emailToBeSent: this.emailForm.value.emailToBeSent,
                email: this.emailForm.value.email,
                comfirmEmail: this.emailForm.value.comfirmEmail
            };
            this.datastore.setTermExtFormDetail(termExtFormDetail);
            termExtQuoteRequest["email_Address"] = this.emailForm.value.emailToBeSent === 'Yes' ? this.loggedInEmail : this.emailForm.value.email;
            termExtQuoteRequest["is_Email_Accepted"] = this.emailForm.value.emailToBeSent === 'Yes' ? true : false;
        }
        if (this.accountDetail.scheme_code === appConfig.hpCustomer) {
            termExtQuoteRequest.requested_Rental = this.datastore.getRequestedRental();
        }
        else if (this.accountDetail.scheme_code === appConfig.pcpCustomer) {
            termExtQuoteRequest.requested_Term = this.datastore.getRequestedTerm();
        }

        this.modelRef = this.modalService.open(modalContent, { windowClass: 'save-card-modal' });
        this.paymentHolidayService.creditBureauSearch().subscribe((res: any) => {
            this.modalService.dismissAll();
            if (res && res.result === "SUCCESS") {
                this.paymentHolidayService.submitModagQuote(termExtQuoteRequest).subscribe((response: any) => {
                    if (response
                        && response.modag_quote_response
                        && response.modag_quote_response.modag_quotes
                        && response.modag_quote_response.modag_quotes.length) {
                        this.messageService.setCurrentStep(0);
                        this.router.navigate(['term-extension-result']);
                    }
                });
            }
            else {
                this.messageService.setCurrentStep(0);
                this.router.navigate(['term-extension-declined']);
            }
        });
    }

    couldNotContactCreditAgency() {
        this.serviceException.imageClass = getErrorImageClass(this.paymentHolidayWidgetItem.finalizeAgreement.couldNotConnectCreditAgencyException.errorImageType.toString());
        this.serviceException.errorInfoText = this.paymentHolidayWidgetItem.finalizeAgreement.couldNotConnectCreditAgencyException.errorInfoText;
        this.serviceException.buttonText = this.paymentHolidayWidgetItem.finalizeAgreement.couldNotConnectCreditAgencyException.buttonText;
        this.serviceException.componentUri = this.paymentHolidayWidgetItem.finalizeAgreement.couldNotConnectCreditAgencyException.componentUri;
        this.serviceException.cancelLink = this.paymentHolidayWidgetItem.finalizeAgreement.couldNotConnectCreditAgencyException.cancelLink;
        this.serviceException.featurePage = 'TermExtension';
        this.serviceException.brandContactDetails = this.paymentHolidayWidgetItem.brandContactDetails;
        this.messageService.setServiceException(this.serviceException);
        this.router.navigate(['/payments/service-exception']);
    }

    unableToDoOnline() {
        this.serviceException.imageClass = getErrorImageClass(this.paymentHolidayWidgetItem.finalizeAgreement.unableToDoOnlineException.errorImageType.toString());

        this.serviceException.errorInfoText = this.paymentHolidayWidgetItem.finalizeAgreement.unableToDoOnlineException.errorInfoText;
        this.serviceException.buttonText = this.paymentHolidayWidgetItem.finalizeAgreement.unableToDoOnlineException.buttonText;
        this.serviceException.componentUri = this.paymentHolidayWidgetItem.finalizeAgreement.unableToDoOnlineException.componentUri;
        this.serviceException.cancelLink = this.paymentHolidayWidgetItem.finalizeAgreement.unableToDoOnlineException.cancelLink;
        this.serviceException.brandContactDetails = this.paymentHolidayWidgetItem.brandContactDetails;
        this.messageService.setServiceException(this.serviceException);
        this.router.navigate(['/payments/service-exception']);
    }

    // cancel() {
    //     this.router.navigate(['your-options']);
    // }

    handleCallToAction() {
        let bureauCallStatus = this.datastore.getBureauCallAction();
        if (bureauCallStatus === 'Resubmit') {
            let failcount = this.datastore.getBureauCallFailCount();
            if (failcount > 2) {
                this.unableToDoOnline();
            }
            else {
                this.applyForTermExt(this.modalContentRef);
            } 
        }
    }

    handleErrors() {
        this.messageService.getServiceError().subscribe((error: any) => {
            this.modalService.dismissAll();
            if (error && error.message === 'An error has happened.') {
                this.couldNotContactCreditAgency();
            }
            else {
                this.showError(error.status);
            }
        });
    }

    showError(errStatus: number): void {
        if (isErrServer(errStatus)) {
            this.datastore.setErrorServer();
            this.serviceError = true;
            this.changeDetectorRef.detectChanges();
        }
    }
}