import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ItemModel, RouterService } from '@backbase/foundation-ang/core';
import { BannerMessage } from '@wss/model/bannerMessage';
import { MessageService } from '@wss/service/message.service';
import { patternMap } from '@wss/config/wss-app-constants';
import { LoginService } from '../../service/login.service';
import { takeUntil } from 'rxjs/operators';
import { RegisterDatastoreService } from '@wss/registration-widget/src/service/register-datastore.service';
import { RegisterService } from '@wss/registration-widget/src/service/register.service';
import { Observable, Subject } from 'rxjs';
import { isErrServer, isNotEmpOrNullOrUndef } from '@wss/common/util/util';
import { DatastoreService } from '@wss/service/datastore.service';
import { RegistrationStoreModelService } from '@wss/registration-store/src/registration-store-model.service';
import { RequestService } from '@wss/service/request.service';
import { LoginWidgetItem } from '@wss/model/login-widget/login-widget';
import { ContentService } from '@backbase/universal-ang/content';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bb-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss'],
  providers: [ContentService],
})
export class ForgotUsernameComponent implements OnInit {
  forgotUsernameForm!: FormGroup;
  submitted: boolean = false;
  invalidDate: boolean = false;
  serviceError: boolean = false;
  bannerMessage: BannerMessage = new BannerMessage();
  hasPostCodeError: boolean = false;
  hasDOBError: boolean = false;
  multipleUser: boolean = false;
  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>(
    'loginWidgetItem',
  );
  readonly destroy$ = new Subject();
  authorisation: any;
  errorCode: any;
  encryptedDob: any;
  encryptedPostcode: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: RouterService,
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private datastoreService: RegisterDatastoreService,
    private registerService: RegisterService,
    private refChange: ChangeDetectorRef,
    private cmndatastoreService: DatastoreService,
    private readonly bbContentService: ContentService,
    private requestService: RequestService,
    private routerAct: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.forgotUsernameForm = this.formBuilder.group({
      dateOfBirth: ['', Validators.compose([Validators.required, Validators.pattern(patternMap.dobPattern)])],
      postcode: ['', Validators.compose([Validators.required, Validators.pattern(patternMap.postCode)])],
    });
    this.routerAct.queryParams.subscribe(param => {
      if (param['date_of_birth'] && param['post_code']) {
        this.encryptedDob = param['date_of_birth'];
        this.encryptedPostcode = param['post_code'];
      }
    });
    this.getAuthorised();
  }

  get f() {
    return this.forgotUsernameForm.controls;
  }

  getCustomer() {
    this.registerService
      .getCustomerDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.serviceError = false;
          if (!isNotEmpOrNullOrUndef(this.datastoreService.getMobileUser())) {
            this.router.navigate(['unable-identify-customer']);
          } else {
            this.router.navigate(['verify-sms']);
          }
        },
        (error: any) => {
          this.errorCode = error.error?.code ? error.error.code : error.status;
          this.showError(error.status);
        },
      );
  }

  setHeaders(token: any): void {
    this.datastoreService.setToken(token);
  }

  getAuthorised(): void {
    this.registerService
      .getAuthorisation()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: any) => {
          this.serviceError = false;
          this.authorisation = data;
          let token = this.authorisation.headers.get('x-authorization');
          this.setHeaders(token);
          if (this.encryptedDob && this.encryptedPostcode) {
            this.registerService.getCustomersData(this.encryptedDob, this.encryptedPostcode).subscribe((val: any) => {
              this.forgotUsernameForm.controls['dateOfBirth'].patchValue(val.dateOfBirth);
              this.forgotUsernameForm.controls['postcode'].patchValue(val.postCode);
              this.getCustomerData();
            },
              (error: any) => {
                this.router.navigate(['no-user-found']);
              });
          }
        },
        (error: any) => {
          this.errorCode = error.error?.code ? error.error.code : error.status;
          this.showError(error.status);
        },
      );
  }

  buildParam() {
    let dateofBirth = this.forgotUsernameForm.get('dateOfBirth')?.value;
    let postcodeVal = this.forgotUsernameForm.get('postcode')?.value;
    let qString = 'date_of_birth=' + dateofBirth + '&post_code=' + postcodeVal;
    this.datastoreService.setParamCust(qString);
  }

  getCustomerData() {
    this.multipleUser = this.datastoreService.getMultiUser();
    if (this.datastoreService.getCustomerStatus() && !this.multipleUser) {
      this.refChange.detectChanges();
      this.getCustomer();
    } else {
      this.router.navigate(['unable-identify-customer']);
    }
  }

  submitForm() {
    this.buildParam();
    this.registerService
      .getCustomers()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.getCustomerData();
        },
        () => {
          this.router.navigate(['no-user-found']);
        },
      );

  }

  showErrorBanner() {
    this.bannerMessage.closeIconClass = 'clear';
    this.bannerMessage.infoTypeClass = 'error-icon';
    this.bannerMessage.leftSectionColorClass = 'message-failure';
    this.bannerMessage.titleText =
      'Account number enter does not match with Date of birth or Post code. Please try again.';
    //this.bannerMessage.subtitleText = this.endOfContractWidgetItem?.endOfContractKeepVehicle.eocErrorbannerMessage.subtitleTextBeforeData + this.endOfContractWidgetItem?.brandContactDetails.brandWiseContactNumber + this.endOfContractWidgetItem?.endOfContractKeepVehicle.eocErrorbannerMessage.subtitleTextAfterData;
    this.bannerMessage.isBannerClosed = false;
    this.messageService.setBannerMessage(this.bannerMessage);
    this.serviceError = true;
    window.scroll(0, 0);
    this.changeDetectorRef.detectChanges();
  }

  showError(errStatus: number): void {
    if (isErrServer(errStatus)) {
      this.cmndatastoreService.setErrorServer();
      this.serviceError = true;
      this.refChange.detectChanges();
    }
  }

  showHideError() {
    const postCodeValue = this.forgotUsernameForm.get('postcode')?.value;
    const dobValue = this.forgotUsernameForm.get('dateOfBirth')?.value;

    const postCodeRegex = patternMap.postCode;
    const dobRegex = patternMap.dobPattern;

    if (!postCodeValue || postCodeValue?.match(postCodeRegex)) {
      this.hasPostCodeError = false;
    } else {
      this.hasPostCodeError = true;
    }

    if (!dobValue || dobRegex.test(dobValue)) {
      this.hasDOBError = false;
    } else {
      this.hasDOBError = true;
      return;
    }
  }

  onKeyPress(event: any) {
    this.forgotUsernameForm.controls['dateOfBirth'].setValue(
      event.target.value
        .replace(/^(\d\d)(\d+)$/g, '$1/$2')
        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
        .replace(/[^\d\/]/g, '')
    );
  }
}
