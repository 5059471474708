export class DirectDebit{
    bank_account_holder_name: string = '';
    bank_account_borrower: string = '';
    bank_account_number: string = '';
    bank_sortcode: string  = '';
    current_payment_method: string = '';
}

export class DirectDebitResponse{
    bank_account_holder_name: string = '';
    bank_account_borrower: string = '';
    bank_account: BankAccount = new BankAccount();
    next_payment_due_date: string  = '';
    new_payment_due_day: string = '';
}

export class BankAccount{
    bank_account_number: string  = '';
    bank_sortcode: string  = '';
    bank_name: string = '';
    branch_title: string = '';
    bank_address_line1: string = '';
    bank_address_line2: string = '';
    bank_address_line3: string = '';
    bank_address_line4: string = '';
    bank_address_line5: string = '';
    bank_address_line6: string = '';
    bank_address_postcode: string = '';
}

export class Error{
    code: string = '';
    reason_code: string = '';
    message: string = '';
}

export const smsTemplateId: string = "BHBANK01";
export const changeDirectDebitTemplate: any = {
    email_title: "ChangeDirectDebit",
    email_subject: "ChangeDirectDebit"
};