import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { MatIconModule } from '@angular/material/icon';
import { NgbModalModule, NgbPopoverModule }  from '@ng-bootstrap/ng-bootstrap';
import { AccountSummaryWidgetComponent } from './account-summary-widget.component';
import { MyAgreementComponent } from './components/my-agreement/my-agreement.component';
import { ContractComponent } from './components/contract/contract.component';
import { SpeedometerComponent } from './components/speedometer/speedometer.component';
// import { NextPaymentComponent } from './components/next-payment/next-payment.component';
import { AdditionalInfoComponent } from './components/additional-info/additional-info.component';
import { AccountInArrearComponent } from './components/account-in-arrear/account-in-arrear.component';
import { WSSCommonModule } from '../../common/common.module';
import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';
import { PaperlessAccountInfoComponent } from './components/paperless-account-info/paperless-account-info.component';
import { PaperlessAccountPopUpComponent } from './components/paperless-account-popup/paperless-account-popup.component';
import { CarbonOffsetComponent } from './components/carbon-ioffset/carbon-ioffset.component';
import { ContactPreferencePopUpComponent } from './components/contact-preference-popup/contact-preference-popup.component';



@NgModule({
  declarations: [
    AccountInArrearComponent,
    AccountSummaryWidgetComponent,
    MyAgreementComponent,
    ContractComponent,
    SpeedometerComponent,
    // NextPaymentComponent,
    AdditionalInfoComponent,
    PaperlessAccountInfoComponent,
    PaperlessAccountPopUpComponent,
    CarbonOffsetComponent,
    ContactPreferencePopUpComponent
  ],
  imports: [
    CommonModule,
    BackbaseCoreModule.withConfig({
      classMap: { AccountSummaryWidgetComponent }
    }),
    MatIconModule,
    NgbModalModule,
    NgbPopoverModule,
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule
  ]
})
export class AccountSummaryWidgetModule { }