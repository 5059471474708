<div class="additional-info-container">
    <div class="section-sub-title">
        <span class="para-inline" test_id="sectionTitle">
            <bb-content-html [html]="additionalInfoContent.sectionTitle"></bb-content-html>
        </span>
        <!-- <span test_id="additional_info_label_id">{{content?.additional}}</span> -->
    </div>
    <div class="container-column additional-info-content-margin">
        <div class="container-row border-bottom bg-white content-center additional-info-item-height">
            <span class="info-item-left">
                <!-- <strong class="flex-width additional-info-item-pull-right"
                test_id="received_date_label_id">{{content?.received}}</strong> -->
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="startDate">
                    <bb-content-html [html]="additionalInfoContent.startDate"></bb-content-html>
                </span>
            </span>
            <span class="flex-width" test_id="received_date_value_id">{{additionalInfoData?.startDate}}</span>
        </div>
        <div class="container-row border-bottom content-center additional-info-item-height">
            <span class="info-item-left">
                <div class="flex-width additional-info-item-pull-right content-center">
                    <!-- <strong test_id="apr_label_id">{{content?.apr}}</strong> -->
                    <span class="para-inline" test_id="apr">
                        <bb-content-html [html]="additionalInfoContent.apr"></bb-content-html>
                    </span>
                    <svg id="aprPopId" [ngbPopover]="popContentApr" autoClose="outside" triggers="manual" #pApr="ngbPopover"
                        (click)="openPopover(pApr, $event, 'aprPopId')" popoverClass="popover-apr" title="info-icon"
                        class="svg-margin-left info-icon-top" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        viewBox="0 0 24 24">
                        <path [attr.fill]="iconColor" [attr.d]="svgInfoIcon" />
                    </svg>
                    <!-- <span class="wss-payment-info-icon"></span> -->
                    <!-- <span [ngbPopover]="popContentApr" autoClose="outside" triggers="manual" #pApr="ngbPopover"
                    (click)="openPopover(pApr, $event)" popoverClass="popover-apr"  class="wss-payment-info-icon"></span> -->
                </div>
            </span>
            <span class="flex-apr-value" test_id="apr_value_id">{{additionalInfoData?.apr}}</span>
        </div>
        <div class="container-row border-bottom bg-white content-center additional-info-item-height" *ngIf="balloonPcpCustomer && !isPcpToHpBalloonRefinanceFlag">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="mileage">
                    <bb-content-html [html]="additionalInfoContent.mileage"></bb-content-html>
                </span>

                <!-- <strong class="flex-width additional-info-item-pull-right"
                test_id="annual_mileage_label_id">{{content?.mileage}}</strong> -->
            </span>
            <span class="flex-width" test_id="annual_mileage_value_id">{{additionalInfoData?.annualMileage}}</span>
        </div>
        <div class="container-row border-bottom additional-ofp-row" *ngIf="(balloonPcpCustomer && !isPcpToHpBalloonRefinanceFlag) || isLPAgreementFlag">
            <span class="info-item-left">
                <span class="additional-info-item-pull-right para-inline" test_id="finalPayment" *ngIf="balloonPcpCustomer && !isPcpToHpBalloonRefinanceFlag">
                    <bb-content-html [html]="additionalInfoContent.finalPayment"></bb-content-html>
                </span>
                <span class="additional-info-item-pull-right para-inline" test_id="finalPayment_lp" *ngIf="isLPAgreementFlag">
                    <bb-content-html [html]="additionalInfoContent.finalPaymentLP"></bb-content-html>
                </span>
                <!-- <strong class="additional-info-item-pull-right flex-ofp-label"
                    test_id="ofp_label_id">{{content?.optionalp}}</strong> -->
                <span class="flex-ofp-label" *ngIf="balloonPcpCustomer && !isPcpToHpBalloonRefinanceFlag">
                    <svg
                    id="ofpPopId" 
                    [ngbPopover]="popContentOfp" 
                    autoClose="outside" 
                    triggers="manual" 
                    #pOfp="ngbPopover"
                    (click)="openPopover(pOfp, $event, 'ofpPopId')" 
                    popoverClass="popover-ofp"
                    title="info-icon" 
                    class="info-icon-top"
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                        <path [attr.fill]="iconColor" [attr.d]="svgInfoIcon" />
                    </svg>
   
                    <!-- <span  [ngbPopover]="popContentOfp"   autoClose="outside"   triggers="manual"   #pOfp="ngbPopover"
                        (click)="openPopover(pOfp, $event)"   popoverClass="popover-ofp"   class="wss-payment-info-icon"
                         ></span> -->
                </span>
            </span>
            <span class="flex-ofp-value"
                test_id="ofp_value_id">&pound;{{additionalInfoData?.optionaFinalPayment}}</span>
        </div>
        <div class="container-row border-bottom bg-white content-center additional-info-item-height" *ngIf="isLPAgreementFlag">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="finalPaymentDateTxt">
                    <strong>{{additionalInfoContent.finalPaymentDateTxt}}</strong>
                </span>
            </span>
            <span class="flex-width" test_id="finalPaymentDateVal">{{additionalInfoData?.finalPayment}}</span>
        </div>
    </div>


</div>
<ng-template #popContentApr>
    <span class="tooltip-arrow-up" [attr.style]="popoverArrowStyle"></span>
    <div class="container-row modal-body">
        <div class="content" test_id="aprTooltipContent">
            <bb-content-html [html]="additionalInfoContent.aprTooltipContent"></bb-content-html>
            <!-- <span class="apr-tooltip-text"><a class="underline">{{content?.aprTooltipTitle}}</a>
                <br>{{content?.aprTooltipTextPart1}}<br>{{content?.aprTooltipTextPart2}}
                <a (click)="helpCentreLink()" class="faq-link">{{content?.helpCenter}}</a>.</span> -->
        </div>
        <div class="tooplip-close">
            <span (click)="closePopover()" class="material-icons clear-icon"
                [attr.style]="tooltipCloseStyle">clear</span>
        </div>
    </div>
</ng-template>

<ng-template #popContentOfp>
    <span class="tooltip-arrow-up" [attr.style]="popoverArrowStyle"></span>
    <div class="container-row modal-body pover-over-class">
        <div class="content" test_id="ofpTooltipContent">
            <bb-content-html [html]="additionalInfoContent.ofpTooltipContent"></bb-content-html>
            <!-- <span class="ofp-tooltip-text"><a class="underline">{{content?.ofpToolipTitle}}</a>
                <br>{{content?.ofpToolipText}}
                <a (click)="helpCentreLink()" class="faq-link">{{content?.helpCenter}}</a>.</span> -->
        </div>
        <div class="tooplip-close">
            <span (click)="closePopover()" class="material-icons clear-icon"
                [attr.style]="tooltipCloseStyle">clear</span>
        </div>
    </div>
</ng-template>