<div *ngIf="endOfContractWidgetItem$ | async as endOfContractWidgetItem">
    <div role="alert" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="section-title-container">
        <h1 class="section-label-title" test_id="pageName">
            {{ endOfContractWidgetItem.endOfContractReturnVehicle.pageName }}</h1>
    </div>

    <div class="m-tb-20 " test_id="textBelowPageHeader">
        {{ endOfContractWidgetItem.endOfContractReturnVehicle.textBelowPageHeader }}
    </div>

    <div class="m-tb-20" test_id="howItWorksText">{{endOfContractWidgetItem.endOfContractReturnVehicle.howItWorksText}}
    </div>
    <div class="keep-your-vehicle">
        <ol>
            <li class="m-tb-20">
                <div class="font-weight-bold" test_id="howItWorksFirstPointHeader">
                    {{endOfContractWidgetItem.endOfContractReturnVehicle.howItWorksFirstPointHeader}}</div>
                <div test_id="howItWorksFirstPointContent">
                    <bb-content-html
                        [html]="endOfContractWidgetItem.endOfContractReturnVehicle.howItWorksFirstPointContent">
                    </bb-content-html>
                </div>
            </li>
            <li class="m-tb-20">
                <div class="font-weight-bold" test_id="howItWorksSecondPointHeader">
                    {{endOfContractWidgetItem.endOfContractReturnVehicle.howItWorksSecondPointHeader}}</div>
                <div class="para-inline" test_id="howItWorksSecondPointContentBeforeData">
                    <bb-content-html
                        [html]="endOfContractWidgetItem.endOfContractReturnVehicle.howItWorksSecondPointContentBeforeData">
                    </bb-content-html>
                    <!-- <span test_id="goodConditionguideLink"><a routerLink="/help-centre/get-in-touch">{{endOfContractWidgetItem.endOfContractReturnVehicle.goodConditionguideLink}}</a>.</span> -->
                    <bb-good-condition-guide-url
                        [linkText]="endOfContractWidgetItem.endOfContractReturnVehicle?.goodConditionguideLink"
                        [vehicleType]="dealersDetails?.dlr_type"></bb-good-condition-guide-url>.
                </div>
            </li>
            <li class="m-tb-20">
                <div class="font-weight-bold" test_id="howItWorksThirdPointHeader">
                    {{endOfContractWidgetItem.endOfContractReturnVehicle.howItWorksThirdPointHeader}}</div>
                <div test_id="howItWorksThirdPointContent">
                    <bb-content-html
                        [html]="endOfContractWidgetItem.endOfContractReturnVehicle.howItWorksThirdPointContent">
                    </bb-content-html>
                </div>
            </li>
        </ol>
    </div>


    <div class="mb-5 eoc-container">
        <div class="mb-45px">
            <h3 test_id="whatYouNeedToKnowText">{{
                endOfContractWidgetItem.endOfContractReturnVehicle.whatYouNeedToKnowText
                }}</h3>
            <div class="container-row expansion-border-hidden transparent-accordion" role="row">
                <mat-accordion class="accordion mat-accordion-override w-100 term-ext" role="accordion">
                    <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                        <mat-expansion-panel-header class="mat-panel-header-override">
                            <mat-panel-title class="mat-expansion-panel-header-title-override"
                                test_id="firstAccordionHeader">
                                <strong> {{ endOfContractWidgetItem.endOfContractReturnVehicle.firstAccordionHeader }}​
                                </strong>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="mb-20px margin-top-10" test_id="firstAccordionFirstSectionBeforeData">​{{
                            endOfContractWidgetItem.endOfContractReturnVehicle.firstAccordionFirstSectionBeforeData }}
                            {{eocDetails.max_total_mileage}} ​{{
                            endOfContractWidgetItem.endOfContractReturnVehicle.firstAccordionFirstSectionAfterData }}​
                        </div>
                        <div class="mb-20px margin-top-10" test_id="firstAccordionSecondSectionBeforeData">​{{
                            endOfContractWidgetItem.endOfContractReturnVehicle.firstAccordionSecondSectionBeforeData }}
                            {{eocDetails.mileage_charge}}{{ endOfContractWidgetItem.endOfContractReturnVehicle?.mileageChargeUnit }}​ ​{{
                            endOfContractWidgetItem.endOfContractReturnVehicle.firstAccordionSecondSectionAfterData }}​
                        </div>
                        <!-- <<<<<<< HEAD -->
                    </mat-expansion-panel>

                <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                    <mat-expansion-panel-header class="mat-panel-header-override">
                        <mat-panel-title class="mat-expansion-panel-header-title-override"
                            test_id="secondAccordionHeader">
                            <strong>{{ endOfContractWidgetItem.endOfContractReturnVehicle.secondAccordionHeader }}​ </strong>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="para-inline margin-top-10 mb-20px" test_id="secondAccordionFirstSectionBeforeData">
                        <bb-content-html
                            [html]=" endOfContractWidgetItem.endOfContractReturnVehicle.secondAccordionFirstSectionBeforeData">
                        </bb-content-html>
                        <span test_id="goodConditionguideLink">
                            <bb-good-condition-guide-url
                                [linkText]="endOfContractWidgetItem.endOfContractReturnVehicle?.goodConditionguideLink"
                                [vehicleType]="dealersDetails?.dlr_type"></bb-good-condition-guide-url>
                        </span>
                        <bb-content-html
                            [html]=" endOfContractWidgetItem.endOfContractReturnVehicle.secondAccordionFirstSectionAfterData">
                        </bb-content-html>
                    </div>
                        <div class="mt-20px margin-top-10" test_id="secondAccordionSecondSectionDetails">
                                <bb-content-html
                                    [html]="endOfContractWidgetItem.endOfContractReturnVehicle.secondAccordionSecondSectionDetails">
                                </bb-content-html>
                        </div>
                </mat-expansion-panel>

                    <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                        <mat-expansion-panel-header class="mat-panel-header-override">
                            <mat-panel-title class="mat-expansion-panel-header-title-override"
                                test_id="thirdAccordionHeader">
                                <strong> {{ endOfContractWidgetItem.endOfContractReturnVehicle.thirdAccordionHeader
                                    }}</strong>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="margin-top-10" test_id="thirdAccordionDetails">
                            <bb-content-html
                                [html]="endOfContractWidgetItem.endOfContractReturnVehicle.thirdAccordionDetails">
                            </bb-content-html>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                        <mat-expansion-panel-header class="mat-panel-header-override">
                            <mat-panel-title class="mat-expansion-panel-header-title-override"
                                test_id="fourthAccordionHeader">
                                <strong> {{ endOfContractWidgetItem.endOfContractReturnVehicle.fourthAccordionHeader }}
                                </strong>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="margin-top-10" test_id="fourthAccordionDetails">
                            <bb-content-html
                                [html]="endOfContractWidgetItem.endOfContractReturnVehicle.fourthAccordionDetails">
                            </bb-content-html>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                        <mat-expansion-panel-header class="mat-panel-header-override">
                            <mat-panel-title class="mat-expansion-panel-header-title-override"
                                test_id="fifthAccordionHeader">
                                <strong> {{ endOfContractWidgetItem.endOfContractReturnVehicle.fifthAccordionHeader }}
                                </strong>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="margin-top-10" test_id="fifthAccordionDetails">
                            <bb-content-html
                                [html]="endOfContractWidgetItem.endOfContractReturnVehicle.fifthAccordionDetails">
                            </bb-content-html>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>


        <div class="card">
            <div class="card-content text-center">
                <div class="p-mb-10px" test_id="wantToReturnVehicleText">
                    <bb-content-html
                        [html]="endOfContractWidgetItem.endOfContractReturnVehicle.wantToReturnVehicleText">
                    </bb-content-html>
                </div>
                <div class="d-flex justify-content-center">
                    <button class="wss-button" (click)="navigateToPage(confirmModal)" test_id="returnVehicleButtonText">
                        <!-- [disabled]="!withinNinetyDays || fileTagFlag || !inGoodsScope" -->
                        {{ endOfContractWidgetItem.endOfContractReturnVehicle.returnVehicleButtonText }}
                    </button>
                </div>
                <!-- <span *ngIf="!withinNinetyDays">
                    <bb-content-html [html]="endOfContractWidgetItem.endOfContractReturnVehicle.optionDisableInfoText">
                    </bb-content-html>
                </span> -->
                <a routerLink="/eoc-options/landing" test_id="backToOptionLink">{{
                    endOfContractWidgetItem.endOfContractReturnVehicle.backToOptionLink }}</a>
            </div>
            <div class="card-footer" test_id="haveQuestionsText">
                <span class="margin-top-10 text-center">
                    <bb-content-html [html]="endOfContractWidgetItem.endOfContractReturnVehicle.haveQuestionsText">
                    </bb-content-html>
                </span>
            </div>
        </div>

    </div>



    <ng-template #confirmModal let-modal>
        <div class="modal-body">
            <div class="container-row">
                <div class="container-column">
                    <div class="mb-20" test_id="confirmPopupText">
                        <bb-content-html [html]="endOfContractWidgetItem.endOfContractReturnVehicle.confirmPopupText">
                        </bb-content-html>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class="wss-button" (click)="confirmSelection()" test_id="confirmButtonText">
                            {{ endOfContractWidgetItem.endOfContractReturnVehicle.confirmButtonText }}
                        </button>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class="wss-button-secondary" (click)="modal.dismiss()" test_id="backButtonText">
                            {{ endOfContractWidgetItem.endOfContractReturnVehicle.backButtonText }}
                        </button>
                    </div>
                </div>
                <span (click)="modal.dismiss()" class="material-icons">clear</span>
            </div>

        </div>
    </ng-template>
</div>