import { Injectable } from '@angular/core';
import { creditDealerPlan } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';


@Injectable({ providedIn: 'root' })
export class DealerplanService {

  private creditcheckPlans: creditDealerPlan = { carDealer: '', bikeDealer: '' };

  constructor() {    
  }

  setDealerPlan(carPlan: string, bikePlan: string) {
    this.creditcheckPlans.carDealer = carPlan;
    this.creditcheckPlans.bikeDealer = bikePlan;
  }

  getDealerPlan() {
    return this.creditcheckPlans;
  }

}
