<div class="eoc-landing" >
    <div class="container-column">
        <div>
            <div class="success-icon"></div>
            <div class="text-center">
                <p><strong>Thank you, your new agreement is in place.</strong></p>
            </div>
            
            <div class="card p-3">
                <p><strong>What happens next</strong></p>
                <ul>
                    <li class="mb-20px">We will send copy of your new agreement to your digital inbox.</li>
                    <li class="mb-20px">Your first payment of the new agreement will be collected by direct debt on 01/06/2022</li>
                </ul>

                <p><strong>Keep in mind</strong></p>
                <ul>
                    <li class="mb-20px">Please do not delete your direct debit.</li>
                </ul>

                <button class="wss-button" (click)="navigate('')">Exit to my account</button>
            </div>
            
            <button class="wss-button-secondary">Save & Exit</button>
        </div>
    </div>
</div>
