import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { BalloonRefinanceGetdetailsItem, ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Observable, Subject } from 'rxjs';
import { injectAccountsDataIntoPage } from '@wss/common/util/util';
import { takeUntil } from 'rxjs/operators';
import { BalloonRefinanceService } from '@wss/balloon-refinance-widget/src/services/balloon-refinance.service';

@Component({
  selector: 'bb-brg-decline',
  templateUrl: './brg-decline.component.html'
})
export class BrgDeclineComponent implements OnInit {
  brgDeclineItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');
  readonly destroy$ = new Subject();
  commonNavTestID: string = 'wss-brg-decline';
  brDeclineItemAccordion: any;
  decisionData: any;
  declineReason: string = '';
  accountDetails: any;
  serviceError: boolean = false;
  openContactAgencies: boolean = false;
  openMoneyWorry: boolean = false;
  pdfjsLib: any;
  proposalDetails: ProposalRequest;
  vehicleType: string = '';
  isDeclinePage: boolean = true;

  constructor(
    private datastore: DatastoreService,
    private readonly bbContentService: ContentService,
    private router: Router,
    private tealiumService: TealiumUtagService,
    private changeDetector: ChangeDetectorRef,
    private balloonRefinanceSrv: BalloonRefinanceService,
    private viewportScroller: ViewportScroller
  ) { this.proposalDetails = this.datastore.getProposalRequest(); }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'Proposal - Decline',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.datastore.setBrgDeclinePage(true);
    this.datastore.setBrHideStepper(true);
    this.decisionData = this.datastore.getDecisionData();
    if (!this.decisionData) {
      this.vehicleType = this.datastore.getCheckVehicleType();
      this.getDecision();
    } else {
      this.accountDetails = this.datastore.getAccountDetails();
      this.brgDeclineItem$.subscribe(content => {
        this.brDeclineItemAccordion = injectAccountsDataIntoPage(content?.balloonRefinanceGetDetailsDeclined.declineAccordions, this.accountDetails)
        this.changeDetector.detectChanges();
      });
      this.pdfToText(this.decisionData?.decline_letter);
    }
  }

  pdfToText(letter: any) {
    //@ts-expect-error
    this.pdfjsLib = window['pdfjsLib'];
    let self = this;
    let pdfData = atob(letter);
    if(this.pdfjsLib) {
      let loadingTask = this.pdfjsLib.getDocument({ data: pdfData });    
      loadingTask.promise.then(function (pdf: any) {
        const pageNumber = 1;
        pdf.getPage(pageNumber).then(function (page: any) {
          page.getTextContent().then(function (textContent: any) {
            const text = textContent.items.map((item: any) => item.str).join(' ');
            if (!text.includes('responsible') && text.includes('agencies')) {
              self.declineReason = 'A';
              self.changeDetector.detectChanges();
            } else if (text.includes('responsible')) {
              self.declineReason = 'B';
              self.changeDetector.detectChanges();
            } else if (text.includes('lending')) {
              self.declineReason = 'P';
              self.changeDetector.detectChanges();
            } else if (text.includes('identity')) {
              self.declineReason = 'I';
              self.changeDetector.detectChanges();
            }
          })
        });
      }, function (reason: any) {
        console.error(reason);
      });
    }   
  }

  viewOtherOptions() {
    this.router.navigate(['/eoc-options/landing'])
  }

  updateCtAgency() {
    this.openContactAgencies = false;
    this.openMoneyWorry = false;
  }

  openContactAgency(elemt: string) {
    this.openContactAgencies = true;
    this.viewportScroller.scrollToAnchor(elemt);
  }

  getDecision() {
    this.balloonRefinanceSrv.getDecision(this.proposalDetails?.proposal_id, this.proposalDetails?.palisCustomerId, this.vehicleType)
    .pipe(takeUntil(this.destroy$))
    .subscribe((res: any) => {
      this.decisionData = this.datastore.getDecisionData();      
      this.accountDetails = this.datastore.getAccountDetails();
      this.brgDeclineItem$.subscribe(content => {
        this.brDeclineItemAccordion = injectAccountsDataIntoPage(content?.balloonRefinanceGetDetailsDeclined.declineAccordions, this.accountDetails)
        this.changeDetector.detectChanges();
      });
      this.pdfToText(this.decisionData?.decline_letter);
    })
  }
}
