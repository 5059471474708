import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { MessageService } from '../../../../service/message.service';
import { ServiceException } from '../../../../model/service-exception';
import { DatastoreService } from '../../../../service/datastore.service';
import { injectBrandDetailIntoPage } from '@wss/common/util/util';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'service-exception',
    templateUrl: './service-exception.component.html'
})

export class ServiceExceptionComponent implements OnInit {

    serviceException: ServiceException = new ServiceException();

    constructor(
        private router: RouterService,
        private changeDetectorRef: ChangeDetectorRef,
        private messageService: MessageService,
        private tealiumService: TealiumUtagService,
        private datastore: DatastoreService
        ) {}

    ngOnInit() {
        this.tealiumService.view(
            {
                JourneyName: 'Inbox Message',
                JourneyStepName: 'Service Exception',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
              }
        );
        this.serviceException = this.messageService.getServiceException();
        this.serviceException = injectBrandDetailIntoPage(this.serviceException, this.serviceException.brandContactDetails);
        this.changeDetectorRef.detectChanges();
    }

    navigateTo(componentUri: string) {
        if (this.serviceException.featurePage === 'TermExtension') {
            let failcount = this.datastore.getBureauCallFailCount();
            this.datastore.updateBureauCallFailCount(failcount + 1);
            this.datastore.setBureauCallAction('Resubmit');
            location.href = '#/payment-holidays/finalize-agreement';
        }
        else{
            location.href = componentUri;
        }
    }
}