import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable, Subject } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';
import { ComplaintWidgetItem } from '@wss/model/complaint';
import { WSSAppConstant } from '@wss/config/wss-app-constants';
import { RouterService } from '@backbase/foundation-ang/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { MessageService } from '@wss/service/message.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { RequestService } from '@wss/service/request.service';
import { HttpService } from '@wss/service/http.service';
import { getComplaintsCanDeactivate, getComplaintsEmailAddress, injectBrandDetailIntoPage, injectComplaintsEmailAddressIntoPage, injectComplaintsMailSubjectIntoPage, isErrServer } from '@wss/common/util/util';
import { finalize, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanComponentDeactivate } from '@wss/complaints-widget/guard/deactivation.guard';
declare var document: any;

@Component({
  selector: 'bb-complaint-your-contact-details',
  templateUrl: 'complaint-your-contact-details.component.html',
  providers: [ContentService],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class ComplaintYourContactDetailsComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  formId: string = "complaintYourContactDetailsForm";
  submitted: boolean = false;
  complaintYourContactDetailsForm: any;
  textAreaOneRemainingCharacter: any = 0;
  textAreaSecondRemainingCharacter: any = 0;
  textAreaThirdRemainingCharacter: any = 0;
  svgInfoIcon = WSSAppConstant.SVG_INFO_ICON;
  complaintConcernType: string = '';
  customerDetail: any;
  preferredTime: any;
  preferredValue: string = '';
  complaintsFormData: any = [];
  complaintWidgetItem: any;
  complaintsEmailAddress: string = "";
  accountDetail: any = {};
  timeInterval: any;
  complaintsTellUsEmailAddressTextForFinanceAgreement: any;
  complaintsTellUsEmailAddressText: any;
  serviceError: boolean = false;
  readonly destroy$ = new Subject();
  customerEmail: any;

  constructor(
    private fb: FormBuilder,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    private router: RouterService,
    private formDataService: DataService,
    private messageService: MessageService,
    private dataStore: DatastoreService,
    private requestService: RequestService,
    private httpService: HttpService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal
  ) {
    this.complaintConcernType = sessionStorage.getItem('ConcernType') as string;
    this.complaintYourContactDetailsForm = this.fb.group({
      complaintPreferredContactMethod: ["", [Validators.required]],
      complaintPreferredContactNumber: [""],
      complaintPreferredContactTime: [""],
      needsAdditionalSupport: ["", [Validators.required]],
      agreeCheckbox: [""],
      evidenceDocumentsShared: ["", [Validators.required]]
    })
  }

  complaintWidgetItem$: Observable<ComplaintWidgetItem | undefined> = this.bbContentService.getContent<ComplaintWidgetItem>('complaintWidgetItem');

  @HostListener("window:beforeunload")
  canDeactivate() {
    return getComplaintsCanDeactivate(this.formDataService.formData, this.modalService);
  }

  ngOnInit(): void {
    if (this.complaintConcernType == 'Vehicle') {
      this.messageService.setCurrentStep(4);
    } else {
      this.messageService.setCurrentStep(3);
    }
    this.customerDetail = this.dataStore.getCustomerDetail();
    this.accountDetail = this.dataStore.getAccountDetails();
    this.tealiumService.view({
      JourneyName: 'Complaints Digitisation',
      JourneyStepName: 'Your contact details - ' + this.complaintConcernType,
      CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    });
    const savedData = this.formDataService.getFormData(this.formId);
    if (Object.keys(savedData).length !== 0) {
      this.complaintYourContactDetailsForm.setValue(savedData);
    }
    this.customerEmail = this.dataStore.getLoggedInEmail();
    this.changegroup();
    this.handleErrors();
    this.complaintWidgetItem$.subscribe((pageItem: any) => {
      this.complaintWidgetItem = pageItem;
      this.complaintWidgetItem.complaintTellUs = injectBrandDetailIntoPage(this.complaintWidgetItem.complaintTellUs, this.complaintWidgetItem.brandContactDetails);
      this.complaintsTellUsEmailAddressTextForFinanceAgreement = injectComplaintsEmailAddressIntoPage(this.complaintWidgetItem.complaintTellUs.complaintsTellUsEmailAddressTextForFinanceAgreement, this.complaintWidgetItem.brandContactDetails.brandWiseServicingComplaintsEmail, this.complaintConcernType, this.accountDetail?.account_number);
      this.complaintsTellUsEmailAddressText = injectComplaintsMailSubjectIntoPage(this.complaintWidgetItem.complaintTellUs.complaintsTellUsEmailAddressText, this.complaintConcernType, this.accountDetail?.account_number);
      this.timeInterval = setInterval(() => {
        this.complaintsTellUsEmailAddressText = injectComplaintsMailSubjectIntoPage(this.complaintWidgetItem.complaintTellUs.complaintsTellUsEmailAddressText, this.complaintConcernType, this.accountDetail?.account_number);
        this.complaintsTellUsEmailAddressTextForFinanceAgreement = injectComplaintsEmailAddressIntoPage(this.complaintWidgetItem.complaintTellUs.complaintsTellUsEmailAddressTextForFinanceAgreement, this.complaintWidgetItem.brandContactDetails.brandWiseServicingComplaintsEmail, this.complaintConcernType, this.accountDetail?.account_number);
        this.changeDetectorRef.detectChanges();
      }, 1000);
      if (this.complaintConcernType == 'Vehicle') {
        this.complaintsEmailAddress = getComplaintsEmailAddress(this.complaintWidgetItem.brandContactDetails.brandWiseSqdComplaintsEmail);
      } else {
        this.complaintsEmailAddress = getComplaintsEmailAddress(this.complaintWidgetItem.brandContactDetails.brandWiseServicingComplaintsEmail);
      }
    });
  }

  get complaintPreferredContactMethodFormControl() {
    return this.complaintYourContactDetailsForm.get('complaintPreferredContactMethod');
  }

  get complaintPreferredContactNumberFormControl() {
    return this.complaintYourContactDetailsForm.get('complaintPreferredContactNumber');
  }

  get evidenceDocumentsSharedFormValue() {
    return this.complaintYourContactDetailsForm.get('evidenceDocumentsShared');
  }

  get complaintYourContactDetailsFormControl() {
    return this.complaintYourContactDetailsForm.controls;
  }

  changegroup() {
    this.complaintYourContactDetailsForm.get('complaintPreferredContactMethod').valueChanges.subscribe((value: string) => {
      if (value == 'Phone') {
        this.complaintYourContactDetailsForm.get('complaintPreferredContactNumber').setValidators([Validators.required]);
      } else {
        this.complaintYourContactDetailsForm.get('complaintPreferredContactNumber').setValue("");
        this.complaintYourContactDetailsForm.get('complaintPreferredContactTime').setValue("");
        this.complaintYourContactDetailsForm.get('complaintPreferredContactNumber').setValidators([]);
        this.complaintYourContactDetailsForm.get('complaintPreferredContactTime').setValidators([]);
      }
      this.complaintYourContactDetailsForm.get('complaintPreferredContactNumber').updateValueAndValidity();
      this.complaintYourContactDetailsForm.get('complaintPreferredContactTime').updateValueAndValidity();
    });

    this.complaintYourContactDetailsForm.get('complaintPreferredContactNumber').valueChanges.subscribe((value: string) => {
      if (value != "") {
        this.complaintYourContactDetailsForm.get('complaintPreferredContactTime').setValidators([Validators.required]);
        setTimeout(() => {
          this.preferredTime = document.getElementById("preferredContactTime");
          if (value == 'Mobile') {
            this.preferredValue = 'mobileLabel';
          }
          else if (value == 'Home phone') {
            this.preferredValue = 'homePhoneLabel';
          }
          else {
            this.preferredValue = 'workPhoneLabel';
          }
          if (this.preferredTime && this.preferredValue) {
            document.getElementById(this.preferredValue).appendChild(this.preferredTime);
          }
        }, 0);
      }
      this.complaintYourContactDetailsForm.get('complaintPreferredContactTime').updateValueAndValidity();
    });
  }

  handlePreviousBtn() {
    this.formDataService.saveFormData(this.formId, this.complaintYourContactDetailsForm.value);
    if (this.complaintConcernType == 'Vehicle') {
      this.router.navigate(['about-vehicle']);
    } else {
      this.router.navigate(['tell-us']);
    }
  }

  handleNextBtn() {
    this.submitted = true;
    if (this.complaintYourContactDetailsForm.valid) {
      this.formDataService.saveFormData(this.formId, this.complaintYourContactDetailsForm.value);
      this.sendComplaintsEmail();
    }
  }

  sendComplaintsEmail() {
    this.httpService.sendComplaintFulfilmentEmail(this.complaintConcernType, this.complaintWidgetItem.complaintContactDetails.complaintsRecipientEmailAddress, this.complaintsEmailAddress)
      .subscribe((data) => {
        this.httpService.sendComplaintAcknowledgementEmail(this.complaintConcernType, this.complaintsEmailAddress, this.customerEmail)
          .pipe(finalize(() => {
            this.router.navigate(['complaint-submission']);
          }))
          .subscribe();
      },
        (error) => {
          this.messageService.setServiceError(error);
        });
  }

  showError(errStatus: number): void {
    if (isErrServer(errStatus)) {
      this.dataStore.setErrorServer();
      this.serviceError = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  handleErrors() {
    this.messageService.getServiceError().pipe(takeUntil(this.destroy$)).subscribe((error: any) => {
      if (isErrServer(error?.error) || isErrServer(error?.status)) {
        this.showError(error.status);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }
}
