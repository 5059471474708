import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { DatastoreService } from '../../../../service/datastore.service';
import { NextPayment } from 'libs/model/nextPayment';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { WSSAppConstant, appConfig } from '../../../../config/wss-app-constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getDigitSuffix, getErrorImageClass, isErrServer } from '../../../../common/util/util';
import { PaymentService } from '../../services/payment.service';
import { DueDate } from '../../models/change-payment';
import { MessageService } from '../../../../service/message.service';
import { ServiceException } from '../../../../model/service-exception';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'change-payment',
    templateUrl: './change-payment.component.html',
    providers: [ContentService]
})

export class ChangePaymentComponent implements OnInit {

    nextPaymentData: NextPayment = new NextPayment();
    changeDateForm: FormGroup = new FormGroup({});
    typeHP: number = appConfig.hpCustomer;
    typePCP: number = appConfig.pcpCustomer;
    serviceError: boolean = false;
    svgInfoIcon: string = WSSAppConstant.SVG_INFO_ICON;
    days: Array<any> = [
        { day: 1, selected: false },
        { day: 2, selected: false },
        { day: 3, selected: false },
        { day: 4, selected: false },
        { day: 5, selected: false },
        { day: 6, selected: false },
        { day: 7, selected: false },
        { day: 8, selected: false },
        { day: 9, selected: false },
        { day: 10, selected: false },
        { day: 11, selected: false },
        { day: 12, selected: false },
        { day: 13, selected: false },
        { day: 14, selected: false },
        { day: 15, selected: false },
        { day: 16, selected: false },
        { day: 17, selected: false },
        { day: 18, selected: false },
        { day: 19, selected: false },
        { day: 20, selected: false },
        { day: 21, selected: false },
        { day: 22, selected: false },
        { day: 23, selected: false },
        { day: 24, selected: false },
        { day: 25, selected: false },
        { day: 26, selected: false },
        { day: 27, selected: false },
        { day: 28, selected: false },
        { day: 29, selected: false },
        { day: 30, selected: false },
        { day: 31, selected: false }
    ];
    selectedDate: any = {};
    accountDetail: any = {};
    serviceException: ServiceException = new ServiceException();

    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
    paymentsWidgetItem: PaymentsWidgetItem = new PaymentsWidgetItem();


    constructor(
        private router: RouterService,
        private datastoreService: DatastoreService,
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private paymentService: PaymentService,
        private messageService: MessageService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService) { }

    ngOnInit() {
        this.datastoreService.sessionInvalid();
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Change Payment',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.accountDetail = this.datastoreService.getAccountDetails();
        if (this.accountDetail) {
            this.accountDetail.scheme_code = parseInt(this.accountDetail.scheme_code);
        }
        this.nextPaymentData.next_payment_date = this.accountDetail?.next_payment_date;
        this.selectedDate = { day: parseInt(this.nextPaymentData.next_payment_date?.split("/")[0].trim()), selected: false };
        this.nextPaymentData.isAmountActive = false;
        this.nextPaymentData.isChangeDateLinkActive = false;

        this.changeDateForm = this.formBuilder.group({
            paymentDate: ['', Validators.required]
        });
        this.handleErrors();
        this.paymentsWidgetItem$.subscribe((paymentsWidgetItem: any) => {
            this.paymentsWidgetItem = paymentsWidgetItem;
        });
        this.messageService.setCurrentStep(0);
    }
    
    navigateToConfirm() {
        let paymentDate = this.changeDateForm.value.paymentDate.substring(0, this.changeDateForm.value.paymentDate.length - 2);
        this.paymentService.calculateLostInterest(paymentDate).subscribe((dueDate: DueDate) => {
            if (dueDate.lost_interest.length !== 0) {
                this.router.navigate(['confirm-change-payment']);
            }
        });
    }

    openDatepicker(datepickerhtml: any) {
        this.modalService.open(datepickerhtml, { centered: true, windowClass: 'change-payment-modal' });
    }


    changeDate(selectedDay: any) {
        this.days.forEach(day => {
            if (day.day === selectedDay.day) {
                day.selected = true;
            }
            else {
                day.selected = false;
            }
        });
    }

    checkForSelectEnable(): boolean {
        let selected = this.days.filter(day => day.selected === true);
        if (selected.length) {
            return true;
        }
        else {
            return false;
        }
    }

    checkForLastDates(): boolean {
        let result = false;
        let selected = this.days.filter(day => (((day.day === 29) || (day.day === 30) || (day.day === 31)) && day.selected === true));
        if (selected.length) {
            result = true;
        }
        return result;
    }

    selectDate() {
        this.days.forEach(day => {
            if (day.selected) {
                this.selectedDate = day;
                this.updatePaymentDate();
            }
        });
    }

    updatePaymentDate() {
        let date = this.selectedDate.day || "";
        if (date !== "") {
            this.changeDateForm.controls["paymentDate"].patchValue(getDigitSuffix(this.selectedDate.day));
        }
    }

    isFormDateValid(): boolean {
        const val = this.changeDateForm.value.paymentDate.trim();
        if (val.toLowerCase() === 'select a day') {
            return false;
        }
        else {
            const selectedDate = this.days.filter(day => getDigitSuffix(day.day) === val);
            if (selectedDate.length) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    handleErrors() {
        this.messageService.getServiceError().subscribe((error: any) => {
            if (error && error.message === 'DUE DATE CHANGED IN LAST 12 MONTHS') {
                this.showHPLimitError();
            }
            else if (error && error.message === 'ONLY 1 CHANGE ALLOWED FOR PCP') {
                this.showPCPLimitError();
            }
            else if (error && error.message === 'ACCOUNT ALREADY BEING AMENDED') {
                this.showSameDayModificationError();
            }
            else if (error && error.message === 'DIRECT DEBIT CALLED FOR') {
                this.showDDCallingError();
            }
            else {
                this.showError(error.status);
            }
        });
    }

    showError(errStatus: number): void {
        if (isErrServer(errStatus)) {
            this.datastoreService.setErrorServer();
            this.serviceError = true;
            this.changeDetectorRef.detectChanges();
        }
    }

    showHPLimitError() {
        this.serviceException.imageClass = getErrorImageClass(this.paymentsWidgetItem.changePayment.hpLimitException.errorImageType.toString());
        this.serviceException.errorInfoText = this.paymentsWidgetItem.changePayment.hpLimitException.errorInfoText;
        this.serviceException.buttonText = this.paymentsWidgetItem.changePayment.hpLimitException.buttonText;
        this.serviceException.componentUri = this.paymentsWidgetItem.changePayment.hpLimitException.componentUri;
        this.serviceException.cancelLink = this.paymentsWidgetItem.changePayment.hpLimitException.cancelLink;
        this.messageService.setServiceException(this.serviceException);
        this.router.navigate(['service-exception']);
    }

    showPCPLimitError() {
        this.serviceException.imageClass = getErrorImageClass(this.paymentsWidgetItem.changePayment.pcpLimitException.errorImageType.toString());
        this.serviceException.errorInfoText = this.paymentsWidgetItem.changePayment.pcpLimitException.errorInfoText;
        this.serviceException.buttonText = this.paymentsWidgetItem.changePayment.pcpLimitException.buttonText;
        this.serviceException.componentUri = this.paymentsWidgetItem.changePayment.pcpLimitException.componentUri;
        this.serviceException.cancelLink = this.paymentsWidgetItem.changePayment.pcpLimitException.cancelLink;
        this.messageService.setServiceException(this.serviceException);
        this.router.navigate(['service-exception']);
    }

    showDDCallingError() {
        this.serviceException.imageClass = getErrorImageClass(this.paymentsWidgetItem.changePayment.ddCallingException.errorImageType.toString());
        this.serviceException.errorInfoText = this.paymentsWidgetItem.changePayment.ddCallingException.errorInfoText;
        this.serviceException.buttonText = this.paymentsWidgetItem.changePayment.ddCallingException.buttonText;
        this.serviceException.componentUri = this.paymentsWidgetItem.changePayment.ddCallingException.componentUri;
        this.serviceException.cancelLink = this.paymentsWidgetItem.changePayment.ddCallingException.cancelLink;
        this.messageService.setServiceException(this.serviceException);
        this.router.navigate(['service-exception']);
    }

    showSameDayModificationError() {
        this.serviceException.imageClass = getErrorImageClass(this.paymentsWidgetItem.changePayment.sameDayModificationException.errorImageType.toString());
        this.serviceException.errorInfoText = this.paymentsWidgetItem.changePayment.sameDayModificationException.errorInfoText;
        this.serviceException.buttonText = this.paymentsWidgetItem.changePayment.sameDayModificationException.buttonText;
        this.serviceException.componentUri = this.paymentsWidgetItem.changePayment.sameDayModificationException.componentUri;
        this.serviceException.cancelLink = this.paymentsWidgetItem.changePayment.sameDayModificationException.cancelLink;
        this.messageService.setServiceException(this.serviceException);
        this.router.navigate(['service-exception']);
    }
}