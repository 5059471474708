import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wss-page-title',
  templateUrl: './page-title.component.html'
})
export class PageTitleComponent implements OnInit {

  @Input() pageTitle: string = '';
  @Input() pageName: string = '';
  pageFullTitle: string = '';
 
  ngOnInit(): void {   
    this.pageFullTitle = 'pt'+this.pageTitle;
  }
}