import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { HelpCentreWidgetComponent } from './help-centre-widget.component';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { WSSCommonModule } from 'libs/common/common.module';
import { HelpCentreLandingComponent } from './help-centre-landing/help-centre-landing.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BackbaseUiAngExamplesModule, ButtonModule, HeaderModule, InputDatepickerModule, InputPasswordModule, InputValidationMessageModule } from '@backbase/ui-ang';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { ContentModule, ContentWidgetModule } from '@backbase/universal-ang/content';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { ComplaintsComponent } from './complaints/complaints.component';
import { GetHelpSupportComponent } from './help-support/get-help-support.component';
import { MyAgreementFaqsComponent } from './my-agreement/my-agreement-faqs.component';
import { MoneyWorriesComponent } from './money-worries/money-worries.component';
import { ManageAccountComponent } from './manage-account/manage-account.component';
import { MakePaymentComponent } from './make-payment/make-payment.component';
import { PaymentHolidayFaqsComponent } from './payment-holiday-faqs/payment-holiday-faqs.component';
import { NgbModalModule }  from '@ng-bootstrap/ng-bootstrap';
import { BudgetWidgetComponent } from './budget-home/budget-home.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { VehicleComplaintsComponent } from './vehicle-complaints/vehicle-complaints.component';

@NgModule({
  declarations: [
    HelpCentreWidgetComponent,
    HelpCentreLandingComponent,
    GetInTouchComponent,
    ComplaintsComponent,
    GetHelpSupportComponent,
    MyAgreementFaqsComponent,
    MoneyWorriesComponent,
    ManageAccountComponent,
    MakePaymentComponent,
    PaymentHolidayFaqsComponent,
    BudgetWidgetComponent,
    VehicleComplaintsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    BackbaseUiAngExamplesModule,
    ButtonModule,
    HeaderModule,  
    InputValidationMessageModule,
    InputDatepickerModule,
    HttpClientModule,
    ButtonModule,
    HeaderModule,
    InputPasswordModule,
    RouterModule.forRoot([], { initialNavigation: 'disabled', useHash: true, relativeLinkResolution: 'legacy' }),
    RouterModule,
    BackbaseCoreModule.withConfig({
      classMap: { HelpCentreWidgetComponent }
    }),
    MatDialogModule,
    MatTabsModule,
    MatMenuModule,
    WSSCommonModule,
    MatExpansionModule,
    ContentWidgetModule,
    ContentModule,
    NgbModalModule
  ]
})
export class HelpCentreWidgetModule { }