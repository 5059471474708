import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Observable, Subject} from "rxjs";
import { ContentService } from '@backbase/universal-ang/content';
import { HelpCentreItem } from '@wss/model/helpCentre';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-payment-holiday-faqs',
  templateUrl: './payment-holiday-faqs.component.html',
  providers: [ ContentService ]
})

export class PaymentHolidayFaqsComponent implements OnInit, OnDestroy {
  readonly destroy$ = new Subject();

  helpCentreWidgetItem$: Observable<HelpCentreItem | undefined> = this.bbContentService.getContent<HelpCentreItem>('helpCentreWidgetItem');
  
  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Help Centre',
        JourneyStepName: 'Payment Holiday FAQs',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();    
  }

  constructor(
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService
  ) {}
}
