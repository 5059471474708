<!-- *ngIf="digitalInboxWidgetItem$ | async as digitalInboxWidgetItem" -->
<div class="mail-preference-container">
    <div class="container-column">
        <div class="container-row section-title-container">
            <span class="section-title" test_id="pageName">{{digitalInboxWidgetItem.preferenceLanding.pageName}}</span>
        </div>
        <ng-container *ngIf="deviceType === 'Mobile'">
            <div class="responsive-row-mail">
                <div class="container-column">
                    <div class="container-row mt-16px">
                        <bb-content-html [html]="digitalInboxWidgetItem.preferenceLanding.pageTopText"></bb-content-html>
                    </div>
                    <!-- <ng-container *ngIf="deviceType === 'Desktop'">
                        <div>
                            <bb-content-html [html]="digitalInboxWidgetItem.preferenceLanding.accordionTitleText"></bb-content-html>
                            <bb-content-html [html]="digitalInboxWidgetItem.preferenceLanding.accordionContentText"></bb-content-html>
                        </div>
                    </ng-container> -->
                    <!-- <ng-container *ngIf="deviceType !== 'Desktop'"> -->
                        <div class="container-row transparent-accordion expansion-border-hidden">
                            <mat-accordion class="accordion mat-accordion-override w-100 finalize" role="accordion">
                                <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override" test_id="accordionContent">
                                    <mat-expansion-panel-header class="mat-panel-header-override">
                                        <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="accordionTitle">
                                            <bb-content-html [html]="digitalInboxWidgetItem.preferenceLanding.accordionTitleText"></bb-content-html>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <bb-content-html [html]="digitalInboxWidgetItem.preferenceLanding.accordionContentText"></bb-content-html>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    <!-- </ng-container> -->
                </div>
                <div class="container-column">
                    <div class="container-column mt-16px">
                        <bb-content-html class="para-last-p-mb-0" [html]="digitalInboxWidgetItem.preferenceLanding.emailText"></bb-content-html>
                        <span data-cs-mask><strong>{{loggedInEmail}}</strong></span>
                    </div>
                    <div class="container-row mt-17px">
                        <bb-content-html [html]="digitalInboxWidgetItem.preferenceLanding.importantText"></bb-content-html>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="deviceType !== 'Mobile'">
            <div class="container-column non-mobiles">
                <div class="container-row mt-16px">
                    <div class="container-row">
                        <bb-content-html [html]="digitalInboxWidgetItem.preferenceLanding.pageTopText"></bb-content-html>
                    </div>
                    <div class="container-column">
                        <bb-content-html class="para-last-p-mb-0" [html]="digitalInboxWidgetItem.preferenceLanding.emailText"></bb-content-html>
                        <span data-cs-mask><strong>{{loggedInEmail}}</strong></span>
                    </div>
                    
                </div>
                <div class="container-row">
                    <ng-container *ngIf="deviceType === 'Desktop'">
                        <div>
                            <bb-content-html [html]="digitalInboxWidgetItem.preferenceLanding.accordionTitleText"></bb-content-html>
                            <bb-content-html [html]="digitalInboxWidgetItem.preferenceLanding.accordionContentText"></bb-content-html>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="deviceType !== 'Desktop'">
                        <div class="container-row transparent-accordion expansion-border-hidden">
                            <mat-accordion class="accordion mat-accordion-override w-100 finalize" role="accordion">
                                <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override" test_id="accordionContent">
                                    <mat-expansion-panel-header class="mat-panel-header-override">
                                        <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="accordionTitle">
                                            <bb-content-html [html]="digitalInboxWidgetItem.preferenceLanding.accordionTitleText"></bb-content-html>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <bb-content-html [html]="digitalInboxWidgetItem.preferenceLanding.accordionContentText"></bb-content-html>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </ng-container>
                    <div class="container-row">
                        <bb-content-html [html]="digitalInboxWidgetItem.preferenceLanding.importantText"></bb-content-html>
                    </div>
                </div>
            </div>
        </ng-container>
        
        
        
        <div class="container-column desktop-btn-links">
            <div class="container-row desktop-button-center">
                <button (click)="navigateToAccountSelection()" class="wss-button" tabindex="0" role="button">{{digitalInboxWidgetItem.preferenceLanding.buttonText}}</button>
            </div>
            <div class="flex-horizontal-center text-center">
                <bb-content-html [html]="digitalInboxWidgetItem.preferenceLanding.pageBottomLink"></bb-content-html>
            </div>
        </div>
    </div>
</div>