import {Component, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {ItemModel, RouterService} from "@backbase/foundation-ang/core";
import {RegistrationStoreModelService} from "../../../../registration-store/src/registration-store-model.service";
import {ContentData} from '../../../../config/wss-app-constants';
import {ContentService as OldContentService} from "../../../../service/content.service";
import {RequestService} from '../../../../service/request.service';
import {RegisterService} from '../../service/register.service';
import {RegisterDatastoreService} from '../../service/register-datastore.service';
import {Observable, Subject} from "rxjs";
import {takeUntil} from 'rxjs/operators';
import {isErrServer} from '../../../../common/util/util';
import {DatastoreService} from '../../../../service/datastore.service';
import { RegistrationWidgetItem } from '@wss/model/registrationToken';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-wss-security-questions',
  templateUrl: './security-questions.component.html',
  providers: [ ContentService ]
})

export class SecurityQuestionsComponent implements OnInit, OnDestroy {
  answerIIQ: any[] = [];
  submitBtn:boolean = true;
  questionList :any[] = [];

  serviceError: boolean = false;

  readonly destroy$ = new Subject();

  registrationWidgetItem$: Observable<RegistrationWidgetItem | undefined> = this.bbContentService.getContent<RegistrationWidgetItem>('registrationWidgetItem');
  errorCode: any;

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Registration',
        JourneyStepName: 'Security Questions',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
    // this.getStaticContent(this.contentTxt, this.contentFolder);
    this.getQuestions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();  
    this.destroy$.complete();    
  }

  constructor(
    private router: RouterService,
    private itemModel: ItemModel,
    private registrationService: RegistrationStoreModelService,
    private contentService: OldContentService,
    private registerService: RegisterService,
    private requestService: RequestService,
    private registerstoreService: RegisterDatastoreService,
    private refChange: ChangeDetectorRef,
    private cmndatastoreService: DatastoreService,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
  ) {
    this.itemModel.property('userDetailsTitle').pipe(takeUntil(this.destroy$)).subscribe(value => this.registrationService.updateTitle(value as string));
    this.registrationService.updatedStepper(2);
  }

  showError(err: any, errStatus: number, errCode: string): void {
    if (errCode === '1021') {
      this.registerstoreService.setIIQattempt(true);
      this.router.navigate(['security-fail']);
    } else if ((errCode === '1011')) {
      this.registerstoreService.setIIQtimeout(true);
      this.router.navigate(['security-fail']);
    } else if (errCode === '002') {
      this.getErrorsFalse();
      this.cmndatastoreService.setErrorServer();
      this.serviceError = true;
      this.refChange.detectChanges();      
    } else if (errCode === '1012') {
      this.getErrorsFalse();
      this.router.navigate(['security-fail']);
    } else {
      this.getErrorsFalse();
      if (isErrServer(errStatus)) {
        this.cmndatastoreService.setErrorServer();
        this.serviceError = true;
        this.refChange.detectChanges();
      }
    }
  }

  getErrorsFalse(){
    this.registerstoreService.setIIQattempt(false);
    this.registerstoreService.setIIQtimeout(false);
  }
  
  getQuestions() {    
    this.registerService.getQuestionIIQ().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.serviceError = false;
      this.questionList = this.registerstoreService.getQuestionList();
      this.refChange.detectChanges();
    },
    (error:any) => {
      this.errorCode = error.error?.code ? error.error.code : error.status;
      this.showError(error, error.status, error.error.code);      
    });
  }

  getOptions(question:string, optValue:string){    
    let ansTemp = {"question_id": question, "selected_answer_id": optValue};
    if(this.answerIIQ.length === 0){
      this.answerIIQ.push(ansTemp);
    } else if (this.answerIIQ.length === 1) {     
      if(this.answerIIQ[0].question_id === ansTemp.question_id){
        this.answerIIQ[0].selected_answer_id = ansTemp.selected_answer_id;
      } else {
        this.answerIIQ.push(ansTemp);
      }
    } else {
      for(let i=0; i<this.answerIIQ.length; i++){ 
        if(this.answerIIQ[i].question_id === ansTemp.question_id){
          this.answerIIQ[i].selected_answer_id = ansTemp.selected_answer_id
        } 
      }
    }
    if(this.questionList.length === this.answerIIQ.length) {
      this.submitBtn = false;
    }
    this.requestService.setQuestionAns(this.answerIIQ);    
  }

  submitAnswer() {
    this.requestService.setReqIIQ();
    this.registerService.getQuestionIIQVerify().pipe(takeUntil(this.destroy$)).subscribe(() => {
      if(this.registerstoreService.getStatusIIQ() === 'Verified') {
        const questionIIQ: any = this.registerstoreService.getQuestionIIQ();
        this.registerstoreService.setUserToken(questionIIQ.user_token);
        this.router.navigate(['enter-email']);
      } else {
        this.answerIIQ = [];
        this.submitBtn = true;
        this.questionList = this.registerstoreService.getQuestionList();
        this.refChange.detectChanges();
      }      
    },
    (error:any) => {
      this.showError(error, error.status, error.error.code);      
    });    
  }

}

