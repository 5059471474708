<div class="eoc-landing" >
    <div class="container-column">
        <div class="section-title-container">
            <h1 class="section-label-title" test_id="pageName">Your new agreement</h1>
        </div>
        <div>
            <p><strong>Please check through the following sections carefully and decide if you would like to continue.</strong></p>
            <p>When you are ready, check the box to confirm you agree and choose to start your agreement. We'll then be able to refinance and confirm the start date.</p>
            
            <h2 class="mb-20px">Your agreement</h2>
            <p class="mb-0">New agreeent type:</p>
                <p><strong>Hire Purchase (HP)</strong></p>

                <p class="mb-0">New monthly payment:</p>
                <p><strong class="gbp">{{quoteData.monthlyPayment}}</strong></p>

                <p class="mb-0">Term:</p>
                <p><strong>{{quoteData.noOfInstallments | number: '1.0-0'}} months</strong></p>

                <p class="mb-0">APR:</p>
                <p><strong>5.9%</strong></p>

                <p class="mb-0">Final balloon payment:</p>
                <p><strong class="gbp">0</strong></p>

                <p class="mb-0">Refinance amount:</p>
                <p><strong class="gbp">{{quoteData.refinanceAmount}}</strong></p>

                <p class="mb-0">Total amount outstanding:</p>
                <p><strong class="gbp mb-3">{{quoteData.totalAmount}}</strong></p>

                <h2 class="mb-20px">Information you need to check</h2>
                
                <div class="container-row expansion-border-hidden transparent-accordion mb-3">
                    <mat-accordion class="accordion mat-accordion-override w-100 term-ext" role="accordion">
                        <mat-expansion-panel
                            class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                            <mat-expansion-panel-header class="mat-panel-header-override">
                                <mat-panel-title class="mat-expansion-panel-header-title-override"
                                    test_id="firstAccordionHeader">
                                    <strong>Credit information</strong>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="margin-top-10" test_id="firstAccordionContent">
                                
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel
                            class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                            <mat-expansion-panel-header class="mat-panel-header-override">
                                <mat-panel-title class="mat-expansion-panel-header-title-override"
                                    test_id="firstAccordionHeader">
                                    <strong>Agreement features</strong>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="margin-top-10" test_id="firstAccordionContent">
                                
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel
                            class="mat-expansion-panel-override mat-expansion-panel-spacing-override p-1">
                            <mat-expansion-panel-header class="mat-panel-header-override">
                                <mat-panel-title class="mat-expansion-panel-header-title-override"
                                    test_id="firstAccordionHeader">
                                    <strong>Title</strong>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="margin-top-10" test_id="firstAccordionContent">
                                
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>

                <h2 class="mb-20px">Your new payment schedule</h2>
                <p>You will pay the first payment of <span class="gbp">329.20</span> on 01/06/2022.</p>
                <p>Followed by 16 monthly payments of <span class="gbp">320.</span></p>
            
            <div class="card p-3">
                <p><strong>Do you understand and agree to the terms of your new agreement?</strong></p>
                <ul>
                    <li class="mb-20px">I agree to the new terms and the payment arrangement.</li>
                </ul>
                <div>
                    <input type="checkbox" name="agreeCheckbox" id="agreeCheckbox">
                    <label for="agreeCheckbox" class="ml-1">Yes, I understand and agree.</label>
                </div>
            </div>
            
            <div class="text-center">
                <button class="wss-button" (click)="navigate('confirmation')">Start my agreement</button>
                <button class="wss-button-secondary" (click)="navigateToHome()">Save & Exit</button>
                <div class="text-center" (click)="navigate('credit-search')">
                    <a>Back a step</a>
                </div>
            </div>
        </div>
    </div>
</div>
