import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable } from 'rxjs';
import { EndOfContractService } from '../../services/end-of-contract.service';
import { appConfig } from '@wss/config/wss-app-constants';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'bb-eoc-get-in-touch',
    templateUrl: 'eoc-get-in-touch.component.html'
})
export class EocGetInTouchComponent implements OnInit {

    endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');
    accountDetails: any = {};
    withinNinetyDays: boolean = false;
    isAgreementInArrears: boolean = false;
    today: Date = new Date();
    finalPaymentDate: any;
    constructor(
        private router: Router,
        private readonly bbContentService: ContentService,
        private datastoreService: DatastoreService,
        private tealiumService: TealiumUtagService,
        private eocService: EndOfContractService
    ) {
    }

    ngOnInit(): void {
        this.tealiumService.view (
            {
                JourneyName: 'End of Contract',
                JourneyStepName: 'Get in touch',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
              }
          );
          
        this.accountDetails = this.datastoreService.getAccountDetails();
        this.finalPaymentDate = new Date(this.eocService.getFormattedDate(this.accountDetails?.final_payment_date))
        this.withinNinetyDays = this.finalPaymentDate.getTime() < this.today.getTime() ? true : this.eocService.isContractWithinNintyDays();
        this.isAgreementInArrears = !(!this.accountDetails?.in_arrears || (this.accountDetails?.in_arrears && this.accountDetails?.months_in_arrears == 0));
    }


}
