import { Injectable } from '@angular/core';
@Injectable({
  providedIn: "root"
})

export class TealiumUtagService {
  scriptSrc ='';

  constructor() {
    (window as any).utag_cfg_ovrd = { noview : true };
    (window as any).utag_data = {};
  }

  getScript( src: string, callback: () => void ) {
    const d = document;
    const fn = () => {};
    const o = { callback: callback || fn};
    let s: any;
    let t: any;

    if ( typeof src === 'undefined') {
      return;
    }

    s = d.createElement('script');
    s.language = 'javascript';
    s.type = 'text/javascript';
    s.rel = "preload";
    s.as="script";
    s.async = 1;
    s.charset = 'utf-8';
    s.src = src;
    if( typeof o.callback === 'function') {      
      s.addEventListener('load', () => {o.callback(); }, false);
    }
    t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  }

  setConfig(config: {account: string, profile: string, environment: string}) {
    if( config.account !== undefined && config.profile !== undefined && config.environment !== undefined ) {      
      this.scriptSrc = 'https://tags.tiqcdn.com/utag/' + config.account + '/' + config.profile + '/' + config.environment + '/utag.js';
    }
  }

  track(tealiumEvent: string, data?: any) {
    if( this.scriptSrc === '') {
      return;
    }
    if( (window as any).utag === undefined) {
      this.getScript( this.scriptSrc, () => {
        (window as any).utag.track( tealiumEvent, data);
      });
    } else {
      (window as any).utag.track( tealiumEvent, data);
    }
  }

  view(data?: any) {
    this.track('view', data);
  }

  link(data?: any) {
    this.track('link', data);
  }
}