<div class="reg-container" *ngIf="registrationWidgetItem$ | async as registrationWidgetItem">
  <div class="reg-row" role="row" test_id="pageHeaderText">
    <bb-content-html [html]="registrationWidgetItem.verifyOtp.pageHeaderText"></bb-content-html>
  </div>
  <form [formGroup]="otpInput">
    <div class="reg-row" role="row">
      <div class="row-arranged">       
        <input type="number" class="otp-input-box" formControlName="otp1" onKeyPress="if(this.value.length ==1 ) return false;" 
          id="otp1" [maxLength]="1" tabindex="1" min="0" oninput="validity.valid||(value='');"/>
        <input type="number" class="otp-input-box" formControlName="otp2" onKeyPress="if(this.value.length ==1 ) return false;" 
          id="otp2" [maxLength]="1" tabindex="2" min="0" oninput="validity.valid||(value='');"/>
        <input type="number" class="otp-input-box" formControlName="otp3" onKeyPress="if(this.value.length ==1 ) return false;" 
          id="otp3" [maxLength]="1" tabindex="3" min="0" oninput="validity.valid||(value='');"/>
        <input type="number" class="otp-input-box" formControlName="otp4" onKeyPress="if(this.value.length ==1 ) return false;" 
          id="otp4" [maxLength]="1" tabindex="4" min="0" oninput="validity.valid||(value='');"/>
      </div>
    </div>
    <div class="reg-row">
      <div class="code-expire-container reg-row" test_id="codeExpiresText">
        <bb-content-html [html]="registrationWidgetItem.verifyOtp.codeExpiresText"></bb-content-html>
      </div>
    </div>
    <div class="reg-row" role="row">
      <div class="otp-margin-left text-center">
        <button id="verify-otp-btn" bbButton type="button" aria-label="Favourite" class="wss-button" (click)="submit()" [disabled]="disabled" test_id="verifyButtonText">
          {{registrationWidgetItem.verifyOtp.verifyButtonText}}
        </button>
      </div>
    </div>
    <div class="reg-row" role="row">      
      <div class="margin-all-auto help-mgn need-help-link text-center mb-25px" test_id="needHelpLink">
        <bb-content-html [html]="registrationWidgetItem.verifyOtp.needHelpLink"></bb-content-html>
      </div>
    </div>
  </form>
</div>
