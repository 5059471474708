import { Action } from '@backbase/foundation-ang/store';
import {OtpGenerationResponse, User} from "./user";
import {UserSearchRequest} from "../../registration-widget/src/user-search-request";


export enum ActionTypes {
  SearchUser = '[Registration Store] Search Users',
  SearchUserSuccess = '[Registration Store] Search Users Success',
  UpdateTitle = '[Registration Store] Update Screen Subtitle',
  UpdateOtp = '[Registration Store] Update Otp for User',
  UpdateStep = '[Registration Store] Update Step for User',
}

export class SearchUserAction implements Action {
  readonly type = ActionTypes.SearchUser;
  constructor(public payload: UserSearchRequest) {}
}

export class UpdateTitleAction implements Action {
  readonly type = ActionTypes.UpdateTitle;
  constructor(public title: string) {}
}

export class SearchUserSuccessAction implements Action {
  readonly type = ActionTypes.SearchUserSuccess;
  constructor(public payload: User[]) {}
}

export class UpdateOtpAction implements Action {
  readonly type = ActionTypes.UpdateOtp;
  constructor(public payload?: number) {}
}

export class UpdateStepAction implements Action {
  readonly type = ActionTypes.UpdateStep;
  constructor(public payload?: number) {}
}

export type RegistrationActions = SearchUserAction | UpdateTitleAction | SearchUserSuccessAction | UpdateOtpAction | UpdateStepAction;
