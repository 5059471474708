<ng-template #paperlessActionPopUp let-modal aria-describedby="paperlessActionPopupContent">   
 
        <div class="container-column paperless-popup-modal">
                <div class="item-close">
                    <span class="material-icons" (click)="onModalClose()">clear</span>
                </div>
                <div class="scrolling-content" (scroll)="scrollContent()">
                <div class="container-row justify-content-center">
                    <span class="paperless-popup-img"></span>
                </div>
                <div class="container-column  paperless-popup-content justify-content-center" test_id="paperLessPopupBodyText">
                    <bb-content-html class="bullets-style" [html]="paperlessPopupContent.paperLessPopupBodyText">
                    </bb-content-html>
                
                </div>
                <div class="container-column paperless-footer">
                    <button *ngIf="portalName === 'jaguar'" class="btn wss-button" data-dismiss="modal" aria-label="Close" (click)="paperlessAccountClick()"
                        test_id="paperLessPopupButtonText">
                        {{paperlessPopupContent.paperLessPopupButtonText}}
                    </button>
                    <button *ngIf="portalName !== 'jaguar'" class="wss-button" data-dismiss="modal" aria-label="Close" (click)="paperlessAccountClick()"
                        test_id="paperLessPopupButtonText">
                        {{paperlessPopupContent.paperLessPopupButtonText}}
                    </button>
                    <span>
                        <a class="" data-dismiss="modal" aria-label="Close" (click)="onModalClose()"
                        test_id="paperLessPopupLinkText">
                        {{paperlessPopupContent.paperLessPopupLinkText}}
                    </a>
                </span>
                </div>
                </div>
                <div class="container-row down-arrow">
                    <span (click)="scrollToBottom()" *ngIf="isDownArrowNeeded && isAndroid"><em class="scroll-arrow-down"></em></span>
                </div>
        </div>

     
</ng-template>