<ng-container *ngIf="endOfContractWidgetItem">
  <div class="mb-10px margin-top-10 help-centre-widget-container" *ngIf="endOfContractWidgetItem.voluntaryTermination">
    <div class="eoc-vt-header">
      <span class="section-label-title" test_id="pageTitle">
        {{ endOfContractWidgetItem.voluntaryTermination.pageTitle}}
      </span>
    </div>
    <div class="margin-top-10">
      <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.openingParagraph"></bb-content-html>
    </div>

    <div class="card mt-0 mb-0">
      <div class="card-content">
        <h3 class="font-size-20 mb-15px margin-top-10" test_id="voluntaryTerminationWhatIsVTTitle">
          {{ endOfContractWidgetItem.voluntaryTermination.canIEndEarlyHeader }}
        </h3>
        <div class="margin-top-10">
          <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.canIEndEarlyParagraph">
          </bb-content-html>
        </div>
      </div>
    </div>

    <h2 class="font-size-20 mb-15px margin-top-10" test_id="voluntaryTerminationWhatIsVTTitle">
      {{ endOfContractWidgetItem.voluntaryTermination.howItWorksHeader }}
    </h2>
    <div class="margin-top-10">
      <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.howItWorksParagraph"></bb-content-html>
    </div>
    <h2 class="font-size-20 mb-15px margin-top-10" test_id="voluntaryTerminationWhatIsVTTitle">
      {{ endOfContractWidgetItem.voluntaryTermination.whatYouNeedToKnowHeading }}
    </h2>
    <div class="accordion margin-top-10">
      <bb-common-expansion-panel
        [accordionContent]="endOfContractWidgetItem.voluntaryTermination.whatYouNeedToKnowAccordion">
      </bb-common-expansion-panel>
    </div>
    <h2 class="font-size-20 mb-15px margin-top-10" test_id="voluntaryTerminationWhatIsVTTitle">
      {{ endOfContractWidgetItem.voluntaryTermination.frequentlyAskedQuestionsHeader }}
    </h2>
    <div class="accordion margin-top-10">
      <bb-common-expansion-panel
        [accordionContent]="endOfContractWidgetItem.voluntaryTermination.frequentlyAskedQuestionsAccordion">
      </bb-common-expansion-panel>
    </div>

    <bb-eoc-traffic-light></bb-eoc-traffic-light>

    <!-- The following code has been commented out on the 8/11/24, this is due to the API end point for the request pack button not yet being comppleted. This code WILL be used So keeping it untill next release. -->
    <!-- <div class="card">
      <div class="card-content text-center">
        <div class="p-mb-10px" test_id="wantToVoluntaryTerminate">
          <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.doYouWantToVoluntaryTerminateHeading">
          </bb-content-html>
        </div>
        <div class="p-mb-10px">
          <bb-content-html [html]="this.liabilityStatusContent">
          </bb-content-html>
        </div>
        <div class="d-flex justify-content-center">
          <button class="wss-button" (click)="openModal(content)" test_id="requestVoluntaryTerminationPack">
            {{ endOfContractWidgetItem.voluntaryTermination.requestPackButtonText }}
          </button>
        </div>
      </div>
      <div class="card-footer" test_id="haveQuestionsText">
        <span class="margin-top-10 text-center">
          <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.getInTouch">
          </bb-content-html>
        </span>
      </div>
    </div>

  <ng-template #content let-modal>
    <div class="container-column request-statement-modal">
      <div class="container-row modal-header-c" test_id="voluntaryTerminationModalTitle">
        <span class="heading">
          <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.modalPopupHeading">
          </bb-content-html>
        </span>
        <span class="material-icons" (click)="modal.dismiss('Cross click');">clear</span>
      </div>
      <div class="container-column modal-body-c" test_id="voluntaryTerminationModalText" id="voluntaryTerminationModalText">
        <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.modalPopupText">
        </bb-content-html>

      </div>
      <div class="container-row modal-footer-c" test_id="voluntaryTerminationModalSubmit">
        <button class="wss-button"
          (click)="requestVoluntaryTerminationPack();modal.dismiss('Cross click');">{{ endOfContractWidgetItem.voluntaryTermination.requestPackButtonText }}</button>
      </div>
    </div>
  </ng-template> -->
  </div>
</ng-container>
