import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {RegistrationStoreModelService} from "../../../../registration-store/src/registration-store-model.service";
import {Router} from "@angular/router";
import {ItemModel} from "@backbase/foundation-ang/core";
import {ContentService as OldContentService} from "../../../../service/content.service";
import {ContentData} from '../../../../config/wss-app-constants';
import {RegisterService} from '../../service/register.service';
import {Observable, Subject} from "rxjs";
import {takeUntil} from 'rxjs/operators';
import {isErrServer} from '../../../../common/util/util';
import {DatastoreService} from '../../../../service/datastore.service';
import { RegistrationWidgetItem } from '@wss/model/registrationToken';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-wss-passcode-error',
  templateUrl: './passcode-error.component.html',
  providers: [ ContentService ]
})

export class PasscodeErrorComponent implements OnInit, OnDestroy {
  
  serviceError: boolean = false;

  readonly destroy$ = new Subject();

  registrationWidgetItem$: Observable<RegistrationWidgetItem | undefined> = this.bbContentService.getContent<RegistrationWidgetItem>('registrationWidgetItem');
  errorCode: any;
  
  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Registration',
        JourneyStepName: 'Passcode Error',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();  
    this.destroy$.complete();    
  }

  constructor(
    private itemModel: ItemModel,
    private registrationService: RegistrationStoreModelService,
    private router: Router,
    private contentService: OldContentService,
    private registerService: RegisterService,
    private refChange: ChangeDetectorRef,
    private cmndatastoreService: DatastoreService,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
  ) {
    this.itemModel.property('codeExpired').pipe(takeUntil(this.destroy$)).subscribe(value => this.registrationService.updateTitle(value as string));    
  }

  showError(errStatus: number): void {
    if(isErrServer(errStatus)){
      this.cmndatastoreService.setErrorServer();
      this.serviceError = true;
      this.refChange.detectChanges();
    }
  }

  submit() {
    this.registerService.getCustomerOTP().pipe(takeUntil(this.destroy$)).subscribe(() => {   
      this.serviceError = false;  
      this.router.navigate(['verify-otp']);
    },
    (error:any) => {
      this.errorCode = error.error?.code ? error.error.code : error.status;
      this.showError(error.status);      
    });
  }
}
