import { Component, OnInit, ChangeDetectorRef, HostListener, OnDestroy } from '@angular/core';
import { WSSAppConstant, ContentData } from '../../config/wss-app-constants';
import { NextPayment } from '../../model/nextPayment';
import { DatastoreService } from '../../service/datastore.service';
import { RoutableWidget, RouterService } from "@backbase/foundation-ang/core";
import { MessageService } from 'libs/service/message.service';
import { BannerMessage } from 'libs/model/bannerMessage';
import { isErrServer } from 'libs/common/util/util';
import { Observable, Subject } from 'rxjs';
import { DashboardWidgetItem } from '@wss/model/content/dashboard';
import { ContentService } from '@backbase/universal-ang/content';
import { takeUntil } from 'rxjs/operators';
import { BrLandingComponent } from './components/br-landing/br-landing.component';
import { BrUpdateVehicleDetailsComponent } from './components/br-update-vehicle-details/br-update-vehicle-details.component';
import { UpdatePersonalDetailsComponent } from './components/update-personal-details/update-personal-details.component';
import { BrGetQuoteComponent } from './components/br-get-quote/br-get-quote.component';
import { BrCreditSearchComponent } from './components/br-credit-search/br-credit-search.component';
import { BrAgreementPreviewComponent } from './components/br-agreement-preview/br-agreement-preview.component';
import { BrConfirmationComponent } from './components/br-confirmation/br-confirmation.component';
import { BrCtaArrearsComponent } from './components/br-cta-arrears/br-cta-arrears.component';
import { BrCtaOutOfHoursComponent } from './components/br-cta-out-of-hours/br-cta-out-of-hours.component';
import { BrCtaDdCallingComponent } from './components/br-cta-dd-calling/br-cta-dd-calling.component';
import { BrCtaEocComponent } from './components/br-cta-eoc/br-cta-eoc.component';
import { BrGetStartedComponent } from './components/br-get-started/br-get-started.component';
import { CtaExpiredEocPeriodComponent } from './components/cta-expired-eoc-period/cta-expired-eoc-period.component';

@Component({
  selector: 'bb-balloon-refinance-widget',
  templateUrl: 'balloon-refinance-widget.component.html',
  providers: [ContentService]
})

@RoutableWidget({
  routes: [
    { path: '', redirectTo: 'landing', pathMatch: 'full' },
    { path: 'landing', component: BrLandingComponent},
    { path: 'vehicle-details', component: BrUpdateVehicleDetailsComponent},
    { path: 'personal-details', component: UpdatePersonalDetailsComponent},
    { path: 'get-a-quote', component: BrGetQuoteComponent},
    { path: 'credit-search', component: BrCreditSearchComponent},
    { path: 'preview', component: BrAgreementPreviewComponent},
    { path: 'confirmation', component: BrConfirmationComponent},
    { path: 'cta-arrears', component: BrCtaArrearsComponent},
    { path: 'cta-out-of-hours', component: BrCtaOutOfHoursComponent},
    { path: 'cta-dd-calling', component: BrCtaDdCallingComponent},
    { path: 'cta-eoc', component: BrCtaEocComponent},
    { path: 'cta-expired-eoc-period', component: CtaExpiredEocPeriodComponent},
    { path: 'get-started', component: BrGetStartedComponent}
  ]
})
export class BalloonRefinanceWidgetComponent implements OnInit, OnDestroy {

  constructor(private dataStoreService: DatastoreService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.dataStoreService.setEocRoute(window.location.href.substr(window.location.href.lastIndexOf('/') + 1));
    //this.messageService.setActiveNav("End of contract");
    this.dataStoreService.sessionInvalid();
  }

  ngOnDestroy(): void { }

}
