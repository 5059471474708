<div class="login-container" *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <span class="section-label-size" test_id="which_account_label_id">
  {{loginWidgetItem.changeEmailMaxAttempts.pageHeaderText}}
  </span>
  <hr/>
  <div class="account-lock text-centeracc-locked-icon" test_id="lockContent">
    <div class="acc-locked-icon"></div>
    <span test_id="attemptExceededmaxAttemptprotectTextContent">
        <bb-content-html [html]="loginWidgetItem.changeEmailMaxAttempts.maxAttemptsText"></bb-content-html>
    </span>
  </div>
</div>