<div *ngIf="complaintWidgetItem">
  <form [formGroup]="complaintTellUsSelectionForm" formInvlidControlScroll>
    <div class="container-column">
      <div class="help-centre-title">
        <span *ngIf="complaintConcernType == 'Vehicle' || ''" class="section-label-title"
          test_id="makeComplaintPageName">
          {{complaintWidgetItem.complaintRelateTo.pageTitle}}
        </span>
        <span *ngIf="complaintConcernType == 'FinanceAgreement'" class="section-label-title"
          test_id="makeComplaintPageName">
          {{complaintWidgetItem.complaintRelateTo.financeAgreementPageTitle}}
        </span>
      </div>
      <div class="sub-heading mt-30px" test_id="pageHeaderBelowText">
        <bb-content-html [html]="complaintWidgetItem.complaintTellUs.pageHeaderBelowText"></bb-content-html>
      </div>

      <div class="form-group mt-10px" *ngIf="(complaintConcernType == 'Vehicle' || '') && complaintRelateToSelectionFormData.concernsRaiseWithDealerOption !== 'No'">
        <div test_id="dealerAskedYouToContactLabel">
          <strong>{{complaintWidgetItem.complaintTellUs.dealerAskedYouToContactLabel}}</strong>
        </div>

        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="dealerAskedYouToContactOption" id="complaintTellUsYesOption"
              [ngClass]="{'has-Error-Radio': submitted && complaintTellUsSelectionForm.get('dealerAskedYouToContactOption')?.errors && complaintTellUsSelectionForm.get('dealerAskedYouToContactOption')?.invalid && complaintTellUsSelectionForm.get('dealerAskedYouToContactOption')?.errors?.required}"
              value="Yes"><span class="label"
              test_id="concernsRaiseWithDealerYesSelection">{{complaintWidgetItem.complaintTellUs.yesText}}</span>
          </label>
        </div>
        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="dealerAskedYouToContactOption" id="complaintTellUsNoOption"
              [ngClass]="{'has-Error-Radio': submitted && complaintTellUsSelectionForm.get('dealerAskedYouToContactOption')?.errors && complaintTellUsSelectionForm.get('dealerAskedYouToContactOption')?.invalid && complaintTellUsSelectionForm.get('dealerAskedYouToContactOption')?.errors?.required}"
              value="No"><span class="label"
              test_id="concernsRaiseWithDealerNoSelection">{{complaintWidgetItem.complaintTellUs.noText}}</span>
          </label>
        </div>

        <div *ngIf="submitted && complaintTellUsSelectionForm.get('dealerAskedYouToContactOption')?.errors?.required">
          <small class="text-danger" test_id="dealerAskedYouToContactOptionError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
          </small>
        </div>
      </div>

      <div class="form-group mt-20px" *ngIf="(complaintConcernType == 'Vehicle' || '') && complaintRelateToSelectionFormData.concernsRaiseWithDealerOption !== 'No'">
        <div test_id="dealerAskedYouToContactLabel">
          <strong>{{complaintWidgetItem.complaintTellUs.dealerOfferResolutionLabel}}</strong>
        </div>

        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="dealerOfferedYouResolution" id="dealerOfferedYouResolutionYesOption"
              [ngClass]="{'has-Error-Radio': submitted && complaintTellUsSelectionForm.get('dealerOfferedYouResolution')?.errors && complaintTellUsSelectionForm.get('dealerOfferedYouResolution')?.invalid && complaintTellUsSelectionForm.get('dealerOfferedYouResolution')?.errors?.required}"
              value="Yes"><span class="label"
              test_id="concernsRaiseWithDealerYesSelection">{{complaintWidgetItem.complaintTellUs.yesText}}</span>
          </label>
        </div>
        <div class="form-group form-group-textarea mt-10px ps-30"
          *ngIf="dealerOfferedYouResolutionFormControl?.value == 'Yes'">
          <bb-content-html [html]="complaintWidgetItem.complaintTellUs.dealersResolutionInfo"></bb-content-html>
          <div class="mb-10px">
            <span class="mb-10px">{{500-textAreaOneRemainingCharacter}}
              {{complaintWidgetItem.complaintTellUs.characterReaminingLabel}}</span>
          </div>
          <textarea
            [ngClass]="{'has-Error': submitted && complaintTellUsSelectionForm.get('moreInfoAboutDealersResolution').errors && (complaintTellUsSelectionForm.get('moreInfoAboutDealersResolution').invalid || complaintTellUsSelectionForm.get('moreInfoAboutDealersResolution').errors.required)}"
            class="form-control" id="alternativeConcatPersonInfoTextarea"
            formControlName="moreInfoAboutDealersResolution" rows="3" maxlength="500"></textarea>
          <div *ngIf="submitted && complaintTellUsSelectionFormControl.moreInfoAboutDealersResolution.errors?.required">
            <small class="text-danger" test_id="moreInfoAboutDealersResolution">
              {{ complaintWidgetItem.complaintRelateTo.pleaseEnterDetailsErrorMessage }}
            </small>
          </div>
          <div *ngIf="invalidDealerInfo">
            <small class="text-danger" test_id="moreInfoAboutDealersResolution">
              {{complaintWidgetItem.complaintRelateTo.specialCharactersAllowedText}}
            </small>
          </div>
        </div>
        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="dealerOfferedYouResolution" id="dealerOfferedYouResolutionNoOption"
              [ngClass]="{'has-Error-Radio': submitted && complaintTellUsSelectionForm.get('dealerOfferedYouResolution')?.errors && complaintTellUsSelectionForm.get('dealerOfferedYouResolution')?.invalid && complaintTellUsSelectionForm.get('dealerOfferedYouResolution')?.errors?.required}"
              value="No"><span class="label"
              test_id="concernsRaiseWithDealerNoSelection">{{complaintWidgetItem.complaintTellUs.noText}}</span>
          </label>
        </div>
        <div class="form-group form-group-textarea mt-10px ps-30" *ngIf="dealerOfferedYouResolutionFormControl?.value == 'No'">
          <bb-content-html [html]="complaintWidgetItem.complaintTellUs.tellUsWhatDealerSaidText"></bb-content-html>
          <div class="mb-10px">
            <span class="mb-10px">{{500-textAreaOneRemainingCharacter}} {{complaintWidgetItem.complaintTellUs.characterReaminingLabel}}</span>
          </div>
          <textarea [ngClass]="{'has-Error': submitted && complaintTellUsSelectionForm.get('moreInfoAboutDealersResolution').errors && (complaintTellUsSelectionForm.get('moreInfoAboutDealersResolution').invalid || complaintTellUsSelectionForm.get('moreInfoAboutDealersResolution').errors.required)}"
            class="form-control" id="alternateContactPersonInfoTextarea" formControlName="moreInfoAboutDealersResolution" rows="3" maxlength="500"></textarea>
          <div *ngIf="submitted && complaintTellUsSelectionFormControl.moreInfoAboutDealersResolution.errors?.required">
            <small class="text-danger" test_id="dealersResolutionEnterDetailsErrorMessage">
              {{ complaintWidgetItem.complaintRelateTo.pleaseEnterDetailsErrorMessage }}
            </small>
          </div>
          <div *ngIf="invalidDealerInfo">
            <small class="text-danger" test_id="dealersResolutionSpecialCharactersAllowedText">
              {{complaintWidgetItem.complaintRelateTo.specialCharactersAllowedText}}
            </small>
          </div>

        </div>
        <div *ngIf="submitted && complaintTellUsSelectionForm.get('dealerOfferedYouResolution')?.errors?.required">
          <small class="text-danger" test_id="dealerOfferedYouResolutionError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
          </small>
        </div>
      </div>

      <div class="form-group form-group-textarea mt-10px">
        <div *ngIf="complaintConcernType == 'Vehicle' || ''">
          <bb-content-html [html]="complaintWidgetItem.complaintTellUs.issueYouHaveExperiencedLabel"></bb-content-html>
        </div>
        <div *ngIf="complaintConcernType == 'FinanceAgreement'">
          <bb-content-html [html]="complaintWidgetItem.complaintTellUs.issueYouHaveHadLabel"></bb-content-html>
        </div>
        
        <div class="mb-10px">
          <span class="mb-10px">{{3000-textAreaSecondRemainingCharacter}}
            {{complaintWidgetItem.complaintTellUs.characterReaminingLabel}}</span>
        </div>
        <textarea formControlName="issueYouHaveExperiencedTextArea" class="form-control"
          [ngClass]="{'has-Error': submitted && complaintTellUsSelectionForm.get('issueYouHaveExperiencedTextArea')?.errors && complaintTellUsSelectionForm.get('issueYouHaveExperiencedTextArea')?.invalid && complaintTellUsSelectionForm.get('issueYouHaveExperiencedTextArea')?.errors?.required}"
          id="issueYouHaveExperiencedTextarea" rows="4" maxlength="3000"></textarea>
        <div *ngIf="submitted && complaintTellUsSelectionForm.get('issueYouHaveExperiencedTextArea')?.errors?.required">
          <small class="text-danger" test_id="issueYouHaveExperiencedError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseEnterDetailsErrorMessage }}
          </small>
        </div>
        <div *ngIf="invalidUserExperienceText">
          <small class="text-danger" test_id="issueYouHaveExperiencedTextArea">
            {{complaintWidgetItem.complaintRelateTo.specialCharactersAllowedText}}
          </small>
        </div>
      </div>

      <div class="form-group form-group-textarea mt-10px">
        <label>{{complaintWidgetItem.complaintTellUs.summaryOfImpactLabel}}</label>
        <div class="mb-10px">
          <span>{{1500-textAreaThirdRemainingCharacter}}
            {{complaintWidgetItem.complaintTellUs.characterReaminingLabel}}</span>
        </div>
        <textarea formControlName="summaryOfImpactTextarea" class="form-control"
          [ngClass]="{'has-Error': submitted && complaintTellUsSelectionForm.get('summaryOfImpactTextarea')?.errors && complaintTellUsSelectionForm.get('summaryOfImpactTextarea')?.invalid && complaintTellUsSelectionForm.get('summaryOfImpactTextarea')?.errors?.required}"
          id="summaryOfImpactTextarea" rows="4" maxlength="1500"></textarea>
        <div *ngIf="submitted && complaintTellUsSelectionForm.get('summaryOfImpactTextarea')?.errors?.required">
          <small class="text-danger" test_id="summaryOfImpactError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseEnterDetailsErrorMessage }}
          </small>
        </div>
        <div *ngIf="invalidSummaryImpactText">
          <small class="text-danger" test_id="summaryOfImpactTextarea">
            {{complaintWidgetItem.complaintRelateTo.specialCharactersAllowedText}}
          </small>
        </div>
      </div>

      <div class="form-group form-group-textarea mt-10px">
        <label>{{complaintWidgetItem.complaintTellUs.howWouldYouLikeToResolveComplaintLabel}}</label>
        <div class="mb-10px">
          <span>{{500-textAreaFourRemainingCharacter}}
            {{complaintWidgetItem.complaintTellUs.characterReaminingLabel}}</span>
        </div>
        <textarea formControlName="howWouldYouLikeToResolveComplaintTextarea" class="form-control"
          [ngClass]="{'has-Error': submitted && complaintTellUsSelectionForm.get('howWouldYouLikeToResolveComplaintTextarea')?.errors && complaintTellUsSelectionForm.get('howWouldYouLikeToResolveComplaintTextarea')?.invalid && complaintTellUsSelectionForm.get('howWouldYouLikeToResolveComplaintTextarea')?.errors?.required}"
          id="howWouldYouLikeToResolveComplaintTextarea" rows="3" maxlength="500"></textarea>
        <div
          *ngIf="submitted && complaintTellUsSelectionForm.get('howWouldYouLikeToResolveComplaintTextarea')?.errors?.required">
          <small class="text-danger" test_id="howWouldYouLikeToResolveComplaintError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseEnterDetailsErrorMessage }}
          </small>
        </div>
        <div *ngIf="invalidResolveComplaintText">
          <small class="text-danger" test_id="resolveComplaintTextarea">
            {{complaintWidgetItem.complaintRelateTo.specialCharactersAllowedText}}
          </small>
        </div>
      </div>

      <div class="complaints-btn-group margin-top-20">
        <button class="wss-button-secondary" test_id="previousButton" id="complaintTellUsPreviousButton" (click)="handlePreviousBtn()">
          {{complaintWidgetItem.complaintTellUs.secondaryButtonText}}
        </button>
        <button class="wss-button" test_id="handleNextBtn" id="complaintTellUsNextButton"
          (click)="handleNextBtn()">{{complaintWidgetItem.complaintTellUs.primaryButtonText}}</button>
      </div>
    </div>
  </form>
</div>