<div class="reg-container" *ngIf="registrationWidgetItem$ | async as registrationWidgetItem" test_id="pageHeaderText">
  <h1 style="text-align:center;">X</h1>
  <bb-content-html [html]="registrationWidgetItem.questionsFail.pageHeaderText"></bb-content-html>
  <div style="text-align: center;">
    <button id="bb-button-to" bbButton type="button" aria-label="Favourite" (click)="passwordSet()"
            [className]="'wss-button-primary'" test_id="tryDifferentOptionButtonText">{{registrationWidgetItem.questionsFail.tryDifferentOptionButtonText}}
    </button>
  </div>
  <div class="need-help-link text-center mb-25px" test_id="needHelpLink">
    <bb-content-html [html]="registrationWidgetItem.questionsFail.needHelpLink"></bb-content-html>
  </div>
</div>
