<div class="container-column popup-modal">
  <div class="container-row modal-header-c" test_id="closeIcon">
    <span class="material-icons" (click)="stay()">clear</span>
  </div>
  <div class="container-column modal-body-c" test_id="unsavedChangesText" id="unsavedChangesText">
    <bb-content-html [html]="popupContent.unsavedChangesText">
    </bb-content-html>
  </div>
  <div class="container-row modal-footer-c" test_id="buttonsContainer">
    <button class="wss-button" (click)="leave()" id="popupLeaveButton">
      {{popupContent.leaveButtonText}}</button>
    <button class="wss-button-secondary" (click)="stay()" id="popupStayButton">
      {{popupContent.stayBackButtonText}}</button>
  </div>
</div>