import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { HttpService } from '../../../service/http.service';
import { MessageService } from '../../../service/message.service';
import { ContinueOrExitComponent } from '../components/continue-or-exit/continue-or-exit.component';

@Injectable({
  providedIn: 'root',
})
export class ContactPreferenceService {
  constructor(
    private httpService: HttpService,
    private messageService: MessageService,
    private modalService: NgbModal,
  ) { }

  openModalFromService(item: any) {
    const modalRef = this.modalService.open(ContinueOrExitComponent, {
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'md',
    });
    modalRef.componentInstance.fromParent = item;
  }

  saveContactPreference(reqObj: any, isRegistrationFlow: boolean): Observable<any> {
    return this.httpService.saveContactPreference(reqObj, isRegistrationFlow);
  }

  UpdateUserDetails(updateRequestData: any): Observable<any> {
    return this.httpService.updateUserDetails(updateRequestData);
  }

  getContactPreference(accNo: any, clientKey: any) {
    return this.httpService.getContactPreference(accNo, clientKey)
  }

}
