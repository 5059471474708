import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewChecked, HostListener } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { PaymentService } from '../../services/payment.service';
import { DatastoreService } from 'libs/service/datastore.service';
import { MessageService } from 'libs/service/message.service';
import { BannerMessage } from 'libs/model/bannerMessage';
import { injectEmailAddress, isErrServer, MustMatch, injectBrandDetailIntoPage } from 'libs/common/util/util';
import * as moment from 'moment';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable, forkJoin, Subject  } from 'rxjs';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { TealiumUtagService } from '@wss/service/utag.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { patternMap } from '@wss/config/wss-app-constants';
import { StorageService } from '@wss/service/storage.service';

declare var document: any;

@Component({
    selector: 'payment-settlement',
    templateUrl: './payment-settlement.component.html',
    styles: [
    ],
    providers: [ContentService]

})

export class PaymentSettlementComponent implements OnInit, OnDestroy, AfterViewChecked {
    settlementData: any;
    amountExceedflag: boolean = false;
    settlementQuoteWarningFlag = false;
    accountSummary: any;
    failCount: any = 0;
    bannerMessage: BannerMessage = new BannerMessage();
    serviceError: boolean = false;
    settleAgreementButtonDisable: boolean = true;
    getNewSettlementCalledAlready: boolean = false;
    today = moment().format('DD/MM/YYYY');
    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
    newSettlementCallDay: string = '';
    loggedInAcct: string | null = '';
    settlementNotAllowed: boolean = false;
    localTimeHour = new Date().getHours();
    twoSettlementsMadeWithin48hours: boolean = false;
    allowSettlements: boolean = false;
    modalRef: any;
    requestStatus: any = {
        success: false,
        show: false
    };
    customerDetail: any;
    paymentSettlementWidgetItem: any = {};
    showConfirmation: boolean = false;
    showEmailPopup: boolean = false;
    showSendEmailAgainMessage: boolean = false;
    emailForm!: FormGroup;
    readonly destroy$ = new Subject();
    is3rdPartyEmailSent: boolean = false;
    withdrawalPeriodWithIn20Days: boolean = false;

    constructor(
        private router: RouterService,
        private formBuilder: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private paymentService: PaymentService,
        private datastoreService: DatastoreService,
        private messageService: MessageService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService,
        private modalService: NgbModal,
        private storageService: StorageService
    ) { }

    ngOnInit() {
        this.datastoreService.sessionInvalid();
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Settlement',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.messageService.setActiveNav("Settlement Nav Selection");
        this.emailForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern(patternMap.emailPattern)]],
        comfirmEmail: ['', [Validators.required, Validators.pattern(patternMap.emailPattern)]],
        emailToBeSent: ['', [Validators.required]]
        },{
            validator: MustMatch('email', 'comfirmEmail')
        });
        this.emailForm.controls['comfirmEmail'].disable();
        this.failCount = this.datastoreService.getFailCountValue();
        this.handleErrors();
        this.accountSummary = this.datastoreService.getAccountDetails();
        this.messageService.setActiveNav("Settlement");
        this.checkLocalTime();
        this.messageService.setCurrentStep(0);
        this.customerDetail = this.datastoreService.getCustomerDetail();
        this.paymentsWidgetItem$.subscribe((paymentsWidgetItem: any) => {
            this.paymentSettlementWidgetItem = injectEmailAddress(paymentsWidgetItem.paymentSettlement, this.storageService.getLocalStorage('loggedInEmail'));
            this.paymentSettlementWidgetItem.emailInvalidErrorMsg = this.paymentSettlementWidgetItem.emailInvalidErrorMsg.replace(`title=\"javascript:void(0);\"`, '');
            this.paymentSettlementWidgetItem = injectBrandDetailIntoPage(this.paymentSettlementWidgetItem, paymentsWidgetItem.brandContactDetails);
            this.changeDetectorRef.detectChanges();
        });
        document.getElementById("appLoader").style.display = "none";
        document.querySelector("body").classList.remove("block-ui");
        localStorage.removeItem('settlementRedirectionFlag');
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    @HostListener('document:visibilitychange')
    tabChange(): void {
        if (document.visibilityState === 'visible') {
            setTimeout(() => {
                this.clearRadioDefaultFocus();
            }, 50); 
        }
    }

    ngAfterViewChecked(){
        this.clearRadioDefaultFocus(); 
    }

    clearRadioDefaultFocus(){
        if(document.querySelector(".bb-input-radio-group__radio")){
            document.querySelector(".bb-input-radio-group__radio").classList.remove("focus");
        } 
    }

    checkConfirmBoxDisability(){
        if(this.emailForm.controls['email'].valid){
            this.emailForm.controls['comfirmEmail'].enable();
        }
        else{
            this.emailForm.controls['comfirmEmail'].disable();
        }
    }

    get f() { return this.emailForm.controls; }

    getSettlementData() {
        this.getSettlementDetail();
        this.onSettlementWarningCheck();
        this.newSettlementCallDay = moment().format('DD/MM/YYYY');
        if (this.accountSummary) {
            this.loggedInAcct = this.accountSummary.account_number;
            const inceptionDateCalculation = (new Date(Date.now() - 24 * 60 * 60 * 1000).getTime()
                - new Date(this.accountSummary?.inception_date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")).getTime()) / (1000 * 3600 * 24); //NOSONAR
            if (this.accountSummary?.allow_settlement_figure && this.accountSummary?.allow_payment && !this.accountSummary?.is_ppi_complaint && inceptionDateCalculation > 20) {
                this.settlementQuoteWarningFlag = false;
                this.twoSettlementsMadeWithin48hours = false;
                this.settlementNotAllowed = false;
                this.allowSettlements = true;
            } else {
                this.checkIfInceptionDateWithInWithdrawalPeriod(inceptionDateCalculation);
            }
        }
        this.getNewSettlementCheck();
    }

    checkIfInceptionDateWithInWithdrawalPeriod(inceptionDateCalculation: any) {
        if(inceptionDateCalculation <= 20){
            this.withdrawalPeriodWithIn20Days = true;
        }
        else{
            if (this.accountSummary?.date_suspended_to === "") {
                this.twoSettlementsMadeWithin48hours = true;
                this.settlementQuoteWarningFlag = false;
            } else {
                this.settlementQuoteWarningFlag = true;
                this.twoSettlementsMadeWithin48hours = false;
            }
        }
        this.settlementNotAllowed = false;
        this.allowSettlements = false;
    }

    checkLocalTime() {
        if ((this.localTimeHour < 22) && (this.localTimeHour >= 8)) {
            this.settlementNotAllowed = false;
            this.getSettlementData();
        }
        else {
            this.settlementQuoteWarningFlag = false;
            this.settlementNotAllowed = true;
            this.twoSettlementsMadeWithin48hours = false;
            this.allowSettlements = false;
        }
    }

    getNewSettlementCheck() {
        if (localStorage.getItem("newSettlementCalledAcc") != null || localStorage.getItem("newSettlementCalledAcc") != undefined) {
            let storedAccountNumbers: any = localStorage.getItem("newSettlementCalledAcc")?.split(', ');
            storedAccountNumbers = storedAccountNumbers.filter((item: string) => item === this.accountSummary?.account_number)
            if (localStorage.getItem("newSettlementCalledTodayAlready") === 'yes' &&
                localStorage.getItem("newSettlementCalledDate") === this.today &&
                storedAccountNumbers.length === 1) {
                this.getNewSettlementCalledAlready = true;
            } else {
                this.getNewSettlementCalledAlready = false;
            }
        } else {
            this.getNewSettlementCalledAlready = false;
        }
    }

    onAmountExceedCheck() {
        this.amountExceedflag = false;
        if (parseInt(this.settlementData?.settlement_figure) > 30000) {
            this.amountExceedflag = true;
        }
    }
    onSettlementWarningCheck() {
        this.settlementQuoteWarningFlag = false;
        if (this.settlementData?.settlement_figure === '') {
            this.settlementQuoteWarningFlag = true;
            this.settlementNotAllowed = false;
            this.twoSettlementsMadeWithin48hours = false;
            this.allowSettlements = false;
        }
    }
    backToMyAccountClick() {
        this.router.navigate(['/../myaccount']);
    }
    settleAgreementClick() {
        localStorage.setItem('redirectionFlag', 'settlement');
        localStorage.setItem('settlementAmount', this.settlementData?.settlement_figure);
        if (this.failCount > 2) {
            this.router.navigate(['payment-result-process']);
        } else {
            this.router.navigate(['payment-debit-card']);
        }
    }

    onMakePaymentClick() {
        localStorage.setItem('redirectionFlag', 'settlement');
        if (this.failCount > 2) {
            this.router.navigate(['payment-result-process']);
        } else {
            this.router.navigate(['payment-debit-card']);
        }
    }

    showError(errStatus: number): void {
        if (isErrServer(errStatus)) {
            this.datastoreService.setErrorServer();
            this.serviceError = true;
            this.changeDetectorRef.detectChanges();
        }
    }

    getNewSettlementAmount(newSettlementFlag?: any) {
        window.scrollTo(0, 0);
        this.paymentService.getPostSettlementResponse(newSettlementFlag ? newSettlementFlag : '').pipe(takeUntil(this.destroy$)).subscribe(response => {
            this.datastoreService.setSettlementResponse(response);
            localStorage.setItem("newSettlementCalledDate", this.today);
            if (localStorage.getItem("newSettlementCalledAcc") == null) {
                localStorage.setItem("newSettlementCalledAcc", this.accountSummary?.account_number);
            } else {
                localStorage.setItem("newSettlementCalledAcc", localStorage.getItem("newSettlementCalledAcc") + ', ' + this.accountSummary?.account_number);
            }
            if (this.today == this.newSettlementCallDay && this.loggedInAcct == this.accountSummary?.account_number) {
                localStorage.setItem("newSettlementCalledTodayAlready", "yes");
            } else {
                localStorage.setItem("newSettlementCalledTodayAlready", "no");
            }
            this.getNewSettlementCheck();
            this.settlementData = response;
            let localDate = this.settlementData?.settlement_expiry_date.split(' ');
            this.settlementData.settlement_expiry_date = localDate[0];
            localStorage.setItem('settlementExpiryDate', localDate[0]);
            if (this.settlementData.settlement_figure) {
                this.settleAgreementButtonDisable = false;
                this.onAmountExceedCheck();
            }
            this.changeDetectorRef.detectChanges();
        },
            (error: any) => {
                this.showError(error.status);
            });
    }

    getSettlementDetail() {
        this.paymentService.getSettlementDetail().pipe(takeUntil(this.destroy$))
            .subscribe(
                data => {
                    this.datastoreService.setSettlementDetails(data);
                    this.settlementData = data;
                    let localDate = this.settlementData?.settlement_expiry_date.split(' ');
                    this.settlementData.settlement_expiry_date = localDate[0];
                    localStorage.setItem('settlementExpiryDate', localDate[0]);
                    if (this.settlementData.settlement_figure) {
                        this.settleAgreementButtonDisable = false;
                        this.onAmountExceedCheck();
                    }
                    this.changeDetectorRef.detectChanges();
                },
                (error: any) => { }
            );
    }

    handleErrors() {
        this.messageService.getServiceError().pipe(takeUntil(this.destroy$)).subscribe((error: any) => {
            this.closeWithoutNavigation();
            if (error?.error[0].code == 17001) {
                this.getNewSettlementAmount();
            }
            else if (isErrServer(error?.error[0].code)) {
                this.showError(error.status);
            }
            else if(isErrServer(error?.status)){
                this.showError(error.status);
            }
        });
    }

    getInTouchCall() {
        this.router.navigate(['get-in-touch']);
        window.scroll(0, 0);
    }

    backToHomeClick() {
        this.messageService.setActiveNav("My account");
        this.router.navigate(['/../myaccount']);
    }

    sendSettlementEmail() {
        this.showEmailPopup = false;
        if(this.settlementData.settlement_figure && this.emailForm.value.emailToBeSent === 'No'){
            this.paymentService.sendSettlementQuoteEmail(this.settlementData.settlement_figure).pipe(takeUntil(this.destroy$)).subscribe((data) => {
                if(data && data.recipient_email_address === this.datastoreService.getLoggedInEmail()){
                    this.showConfirmation = true;
                }
            });
        }
        else if(this.settlementData.settlement_figure && this.emailForm.value.emailToBeSent === 'Yes'){
            forkJoin(
                [
                    this.paymentService.sendSettlementQuoteEmail(this.settlementData.settlement_figure),
                    this.paymentService.sendSettlementQuote3rdPartyEmail(this.settlementData.settlement_figure, this.emailForm.value.email)
                ]
              )
              .pipe(takeUntil(this.destroy$))
              .subscribe((result: any) => {
                if(result.length === 2 
                    && result[0].recipient_email_address === this.datastoreService.getLoggedInEmail() 
                    && result[1].recipient_email_address === this.emailForm.value.email){
                    this.is3rdPartyEmailSent = true;
                    this.showConfirmation = true;
                }
            })
        }
    }

    closeSendEmailAgainPopup() {
        this.showSendEmailAgainMessage = false;
    }

    closeEmailPopup() {
        if (this.modalRef) {
            this.modalRef.close();
        }
        this.showConfirmation = false;
        this.showEmailPopup = false;
        this.router.navigate(['/../myaccount']);
    }

    openModal(content: any) {
        this.showEmailPopup = true;
        this.modalRef = this.modalService.open(content, { windowClass: 'settlement-email', backdrop: 'static'});
        this.emailForm.reset();
        this.emailForm.controls["emailToBeSent"].patchValue('');
    }

    closeWithoutNavigation(){
        if (this.modalRef) {
            this.modalRef.close();
        }
        this.showConfirmation = false;
        this.showEmailPopup = false;
    }

}

