<div class="container-column" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">

<div class="direct-debit-container">
    <div class="container-row" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="container-column">
        <div class="container-row section-title-container">
            <span class="section-title" test_id="addDirectDebitPageName">
                <!-- {{content[0]?.dd}} -->
                {{paymentsWidgetItem.addDirectDebit.addDirectDebitPageName}}
            </span>
        </div>
        <div class="container-row direct-debit-short-note" *ngIf="withIn8Days" aria-describedby="shortCodeNote">
           <!-- class="wss-additonal-info-icon" -->
            <span >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <path fill="#333333" [attr.d]="svgInfoIcon" />
                </svg>
            </span>
            <span test_id="directDebitFAQInfoText">
                <!-- {{content[0]?.canupdatedetailpnin8days}}<span class="debit-faq ml-1"><span role="navigation"
                    tabindex="0" title="Direct Debit FAQs">{{content[0]?.ddfaq}}.</span></span> -->
                <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.directDebitFAQInfoText"></bb-content-html>
                <span class="debit-faq ml-1" test_id="directDebitFAQInfoLinkText" *ngIf="paymentsWidgetItem.addDirectDebit.directDebitFAQInfoLinkText">
                    <span role="navigation" tabindex="0" title="Direct Debit FAQs">{{paymentsWidgetItem.addDirectDebit.directDebitFAQInfoLinkText}}</span>
                </span>
            </span>
        
        </div>
        <div class="container-column fieldset-container">
            <div class="container-column">
                <form [formGroup]="debitDetailForm" class="form" autocomplete="off" id="debitDetailForm"
                    aria-describedby="directDebitForm">
                    <div class="container-row add-or-update-label">
                        <span *ngIf="!activeDirectDebitFound" class="para-inline"><h2 test_id="inactiveDirectDebitLabelText">
                            <!-- {{content[0]?.enternddd}} -->
                            <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.inactiveDirectDebitLabelText"></bb-content-html>
                        </h2></span>
                        <span *ngIf="activeDirectDebitFound" class="para-inline"><h2 test_id="activeDirectDebitLabelText">
                            <!-- {{content[0]?.uddd}} -->
                            <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.activeDirectDebitLabelText"></bb-content-html>
                        </h2></span>
                    </div>
                    <div class="container-column form-group">
                        <label test_id="accountNameLabelText" class="para-inline">
                            <!-- {{content[0]?.acctname}} -->
                            <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.accountNameLabelText"></bb-content-html>
                        </label>
                        <!-- <input accesskey="n" formControlName="accountName" (focusout)="validateBankDetails()"
                            type="text" maxlength="20" placeholder="{{content[0]?.eacctname}}" class="form-control" /> -->                            
                        <input accesskey="n" formControlName="accountName" (focusout)="validateBankDetails()"
                        type="text" maxlength="20" placeholder="{{paymentsWidgetItem.addDirectDebit.accountNamePlaceholderText}}" class="form-control" test_id="accountNamePlaceholderText"/>
                        <!-- <input formControlName="accountName" (focusout)="validateBankDetails()" (keypress)="onlyAlphabets($event)" type="text" maxlength="20" placeholder="{{content[0]?.eacctname}}" class="form-control" /> -->
                        <div class="error-text" test_id="accountNameErrorMessageText"
                            *ngIf="!debitDetailForm.controls.accountName.valid && debitDetailForm.controls.accountName.touched">
                            <!-- {{content[0]?.accountNameErrMsg}} -->
                            {{paymentsWidgetItem.addDirectDebit.accountNameErrorMessageText}}
                        </div>
                    </div>
                    <div class="container-column form-group">
                        <label test_id="accountNumberLabelText" class="para-inline">
                            <!-- {{content[0]?.acctnum}} -->
                            <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.accountNumberLabelText"></bb-content-html>
                        </label>
                        <input #acctNum accesskey="b" formControlName="accountNumber" type="text" [allow]="'Number'" test_id="accountNumberPlaceholderText"
                            (focusout)="validateBankDetails()" minlength="8" maxlength="8"
                            placeholder="{{paymentsWidgetItem.addDirectDebit.accountNumberPlaceholderText}}" class="form-control" />
                        <!-- <input #acctNum accesskey="b" formControlName="accountNumber" type="text" [allow]="'Number'"
                            (focusout)="validateBankDetails()" minlength="8" maxlength="8"
                            placeholder="{{content[0]?.eacctnum}}" class="form-control" /> -->
                        <div class="error-text" test_id="accountNumberErrorMessageText"
                            *ngIf="!debitDetailForm.controls.accountNumber.valid  && debitDetailForm.controls.accountNumber.touched">
                            <!-- {{content[0]?.accountNumberErrMsg}} -->
                            {{paymentsWidgetItem.addDirectDebit.accountNumberErrorMessageText}}
                        </div>
                    </div>
                    <div class="container-column form-group sort-code-box">
                        <label test_id="sortcodeLabelText" class="para-inline">
                            <!-- {{content[0]?.sortcode}} -->
                            <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.sortcodeLabelText"></bb-content-html>
                        </label>
                        <div class="container-row sort-code">
                            <input accesskey="o" autoFocusNext="2" formControlName="sortCodePart1"
                                (focusout)="validateBankDetails()" type="text" [allow]="'Number'" maxlength="2"
                                minlength="2" class="form-control" />
                            <input accesskey="t" autoFocusNext="2" formControlName="sortCodePart2"
                                (focusout)="validateBankDetails()" type="text" [allow]="'Number'" maxlength="2"
                                minlength="2" class="form-control" />
                            <input accesskey="r" formControlName="sortCodePart3" (focusout)="validateBankDetails()"
                                type="text" [allow]="'Number'" maxlength="2" minlength="2" class="form-control" />
                            <span></span>
                        </div>
                    </div>
                    <!-- Valid Bank Detail -->
                    <div class="container-column bank-detail" *ngIf="bankDetailStatus === 'valid'">
                        <div class="container-row name">
                            <span>{{bankAccount?.bank_name}}</span>
                        </div>
                        <div class="container-column address">
                            <span>{{bankAccount?.bank_address_line1}}</span>
                            <span>{{bankAccount?.bank_address_line2}}</span>
                            <span>{{bankAccount?.bank_address_line3}}</span>
                        </div>
                        <div class="container-row msg-box">
                            <div class="container-row">
                                <span class="material-icons">check_circle_outline</span>
                            </div>
                            <div class="container-row">
                                <span class="account-found para-inline" test_id="bankDetailsFoundInfoText">
                                    <!-- {{content[0]?.acctfound}} -->
                                    <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.bankDetailsFoundInfoText"></bb-content-html>
                                </span>
                            </div>
                        </div>
                        <span role="navigation" class="mb-10px para-inline" test_id="directDebitQuestionsFAQText">
                            <!-- {{content[0]?.hvquestion}}? {{content[0]?.plsread}} <a
                                class="faq-link" tabindex="0"
                                title="Direct Debit FAQs">{{content[0]?.ddfaq}}.</a> -->
                            <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.directDebitQuestionsFAQText"></bb-content-html> 
                            <a class="faq-link" tabindex="0" *ngIf="paymentsWidgetItem.addDirectDebit.directDebitQuestionsFAQLinkText" title="Direct Debit FAQs" test_id="directDebitQuestionsFAQLinkText">
                                {{paymentsWidgetItem.addDirectDebit.directDebitQuestionsFAQLinkText}}.</a>
                        </span>
                    </div>
                    <!-- Invalid Bank Detail -->
                    <div class="container-column bank-detail invalid-bank-detail"
                        *ngIf="bankDetailStatus === 'invalid'">
                        <div class="container-row msg-box">
                            <div class="container-row">
                                <span class="material-icons">error_outline</span>
                            </div>
                            <div class="container-row">
                                <span class="account-found para-inline" test_id="bankDetailsInvalidWarningInfoText">
                                    <!-- {{content[0]?.invalidbd}} -->
                                    <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.bankDetailsInvalidWarningInfoText"></bb-content-html>
                                </span>
                            </div>
                        </div>
                        <div class="container-row direct-debit-short-note error-note">
                            <!-- class="wss-additonal-info-icon" -->
                            <span >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                    <path fill="#333333" [attr.d]="svgInfoIcon" />
                                </svg>
                            </span>
                            <span class="regular-font para-inline" test_id="bankDetailsInvalidAdditionalInfoText">
                                <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.bankDetailsInvalidAdditionalInfoText"></bb-content-html>
                            </span>

                        </div>
                    </div>
                    <div class="container-row please-confirm-note">
                        <span test_id="confirmFollowingText" class="para-inline">
                            <!-- {{content[0]?.pconfirm}} -->
                            <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.confirmFollowingText"></bb-content-html>
                        </span>
                    </div>
                    <div class="container-column checkbox-container">
                        <div class="container-row dif-view">
                            <div (click)="checkboxes.first = !checkboxes.first"
                                [ngClass]="checkboxes?.first? 'custom-checkbox-debit': 'custom-checkbox-debit-unchecked'">
                                <span></span>
                            </div>
                            <span class="checkbox-notes para-inline" test_id="confirmAccountHolderText">
                                <!-- {{content[0]?.iamacctholder}} -->
                                <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.confirmAccountHolderText"></bb-content-html>
                            </span>
                        </div>
                        <div class="container-row dif-view">
                            <div (click)="checkboxes.second = !checkboxes.second"
                                [ngClass]="checkboxes?.second? 'custom-checkbox-debit': 'custom-checkbox-debit-unchecked'">
                                <span></span>
                            </div>
                            <span class="checkbox-notes para-inline" test_id="confirmSignatoryText">
                                <!-- {{content[0]?.acctrequiresignatory}} -->
                                <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.confirmSignatoryText"></bb-content-html>
                            </span>
                        </div>
                        <div class="container-row dif-view">
                            <div (click)="checkboxes.third = !checkboxes.third"
                                [ngClass]="checkboxes.third? 'custom-checkbox-debit': 'custom-checkbox-debit-unchecked'">
                                <span></span>
                            </div>
                            <span class="checkbox-notes para-inline" test_id="confirmAuthorisedText">
                                <!-- {{content[0]?.imatoauthorise}} -->
                                <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.confirmAuthorisedText"></bb-content-html>
                            </span>
                        </div>
                    </div>
                    <!-- <div role="button" class="container-row debit-btn comfirm btn-dif-view"
                        *ngIf="debitDetailForm.valid && checkedCheckboxes() && !(bankDetailStatus !== 'valid')"
                        (click)="addOrUpdateDebitDetail(modalhtml)" title="Confirm my bank details" tabindex="0">
                        <span>{{content[0]?.confirmad}}</span>
                    </div>
                    <div role="button" class="container-row debit-btn comfirm btn-disabled btn-dif-view"
                        *ngIf="!debitDetailForm.valid || !checkedCheckboxes() || (bankDetailStatus !== 'valid')"
                        title="Confirm my bank details" tabindex="0">
                        <span>{{content[0]?.confirmad}}</span>
                    </div> -->
                    <div class="container-row">
                        <!-- <button role="button" class="wss-button comfirm btn-dif-view" [disabled]="!debitDetailForm.valid || !checkedCheckboxes() || (bankDetailStatus !== 'valid')"
                        (click)="addOrUpdateDebitDetail(modalhtml)" tabindex="0" title="{{content[0]?.confirmad}}">{{content[0]?.confirmad}}</button> -->
                        <button id="directDebitConfirmButton" role="button" test_id="directDebitConfirmButtonText" class="wss-button comfirm btn-dif-view" [disabled]="!debitDetailForm.valid || !checkedCheckboxes() || (bankDetailStatus !== 'valid')"
                        (click)="addOrUpdateDebitDetail(modalhtml)" tabindex="0" title="{{paymentsWidgetItem.addDirectDebit.directDebitConfirmButtonText}}">{{paymentsWidgetItem.addDirectDebit.directDebitConfirmButtonText}}</button>
                    </div> 
                    <!-- <div role="button" class="container-row debit-btn back btn-dif-view"
                        (click)="navigateTo('payments')" title="Back to Make a payment" tabindex="0">
                        <span>{{content[0]?.backtodd}}</span>
                    </div> -->
                    <div class="container-row">
                        <!-- <button role="button" class="wss-button-secondary back btn-dif-view"
                        (click)="navigateTo('payments')" tabindex="0" title="{{content[0]?.backtodd}}">{{content[0]?.backtodd}}</button> -->
                        <button id="add-dd-backToPaymentButton" role="button" class="wss-button-secondary back btn-dif-view" test_id="backToPaymentButtonText"
                        (click)="navigateTo('payments')" tabindex="0" title="{{paymentsWidgetItem.addDirectDebit.backToPaymentButtonText}}">{{paymentsWidgetItem.addDirectDebit.backToPaymentButtonText}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<ng-template #modalhtml let-modal>
    <div class="container-column request-statement-modal direct-debit-exception-modal">
        <div class="container-row modal-header-c">
            <span class="heading" test_id="sameDetailsHeadingText">
                <!-- {{modalContent[0]?.samedetail}} -->
                {{paymentsWidgetItem.addDirectDebit.sameDetailsHeadingText}}
            </span>
            <span class="material-icons" (click)="modal.dismiss('Cross click');">clear</span>
        </div>
        <div class="container-column modal-body-c" test_id="confirmAuthorisedText">
            <!-- <span>
                {{modalContent[0]?.samedetailcurrently}}.
            </span>
            <br>
            <div class="container-column">
                <span>{{modalContent[0]?.telephone}}:</span>
                <span>012345678910</span>
                <span>{{modalContent[0]?.openingtimes}}:</span>
                <div class="inner-container">
                    <span>{{modalContent[0]?.mondaytofriday}}</span>
                    <span>{{modalContent[0]?.sat}}</span>
                    <span>{{modalContent[0]?.sun}}</span>
                </div>
            </div> -->
            <bb-content-html [html]="paymentsWidgetItem.addDirectDebit.confirmAuthorisedText"></bb-content-html>
        </div>
        <div class="container-column mt-24px modal-footer-c">
            <div class="wss-button change-debit-detail-button" (click)="modal.dismiss('Cross click');">
                <span class="" test_id="sameDetailsChangeDirectDebitButtonText">
                    <!-- {{modalContent[0]?.cdddetail}} -->
                    {{paymentsWidgetItem.addDirectDebit.sameDetailsChangeDirectDebitButtonText}}
                </span>
            </div>
            <div class="container-row back-to-direct-debit">
                <span (click)="closePopUp();modal.dismiss('Cross click');" test_id="sameDetailsBackToDirectDebitText">
                    <!-- {{modalContent[0]?.backtodd}} -->
                    {{paymentsWidgetItem.addDirectDebit.sameDetailsBackToDirectDebitText}}
                </span>
            </div>
        </div>
    </div>
</ng-template>
</div>