import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MessageService } from '../../../../service/message.service';
import { ServiceException } from '../../../../model/service-exception';
import { TealiumUtagService } from '@wss/service/utag.service';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable, Subject } from 'rxjs';
import { RouterService } from '@backbase/foundation-ang/core';
import { encodeHTML, validEmailCheck } from '@wss/common/util/util';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatastoreService } from '@wss/service/datastore.service';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { PaymentService } from '../../services/payment.service';

@Component({
    selector: 'bb-confirm-billing-address',
    templateUrl: './confirm-billing-address.component.html',
    providers: [ContentService]
})

export class ConfirmBillingAddressComponent implements OnInit {

    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
    customerDetail: any = {};
    customerAddress: any = {};
    personalDetails: any = {};
    addressChanged: boolean = false;
    modalRef: any;
    processingContentValue: any;
    saveCardFlagValue: any;
    accountDetail: any = this.dataStore.getAccountDetails();
    readonly destroy$ = new Subject();
    selectedPaymentCardDetail: any;
    postRequestData: any;
    todaysDate = new Date();
    payableAmount: any;
    serviceError: boolean = false;
    postCodeInvalidMessage: string = 'Please enter the correct postcode';
    specialCharacterNotAllowedMessage: string = 'Special character are not allowed';
    private ngUnsubscribe = new Subject<void>();

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private messageService: MessageService,
        private tealiumService: TealiumUtagService,
        private dataStore: DatastoreService,
        private readonly bbContentService: ContentService,
        private router: RouterService,
        private modalService: NgbModal,
        private datePipe: DatePipe,
        public paymentService: PaymentService) { }

    ngOnInit() {
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Confirm Billing Address',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
                
        this.addressChanged = this.dataStore.getAddressChangeNotification();
        this.customerDetail = this.addressChanged ? this.dataStore.getChangedAddressDetail() : this.dataStore.getCustomerDetail();
        if (this.customerDetail) {
            this.customerAddress = this.customerDetail?.addresses?.address_detail;
            if (this.customerAddress?.district != null) {
                this.customerAddress.district = this.customerAddress?.district;
            }
            let firstName = this.customerDetail?.individual?.first_name;
            let surname = this.customerDetail?.individual?.surname;
            if (firstName?.length > 18) {
                firstName = firstName.substr(0, 18);
            }
            if (surname?.length > 40) {
                surname = surname.substr(0, 40);
            }
            this.personalDetails.fullname = firstName + ' ' + surname;

            this.personalDetails.fullname = this.personalDetails.fullname.toLowerCase();
        }
        this.messageService.setCurrentStep(2);
        this.payableAmount = sessionStorage.getItem('transaction_value');
        this.paymentsWidgetItem$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((paymentsWidgetItem: any) => {
            this.postCodeInvalidMessage = paymentsWidgetItem?.confirmBillingAddress.invalidPostCodeErrorMessage;
            this.specialCharacterNotAllowedMessage = paymentsWidgetItem?.confirmBillingAddress.specialCharacterErrorMessage;
        });
        this.changeDetectorRef.detectChanges();
    }

    proceedNextClick() {
        this.onPaymentSubmit('', '', '');
    }

    onPaymentSubmit(processingContent: any, saveCardFlag: any, settlementContent: any) {
        this.processingContentValue = processingContent;
        this.saveCardFlagValue = settlementContent;

        if (this.accountDetail?.date_suspended_to === "" || this.accountDetail?.allow_payment === true) {
            this.getPostPaymentRequest();
        } else {
            this.router.navigate(['payment-result-process']);
        }
    }

    getPostPaymentRequest() {
        this.onModalOpen(this.processingContentValue);
        let registryValue: boolean = false;
        let tokenNumber: any;
        let apiURL: string = location.href.substring(0, location.href.lastIndexOf("#") + 1) + "/payments/payment-result-process";
        let returnUrl: any = "";
        if (this.selectedPaymentCardDetail && this.saveCardFlagValue === '') {
            registryValue = false;
            tokenNumber = this.selectedPaymentCardDetail.token_number;
            returnUrl = "";
        } else if (this.saveCardFlagValue === 'save') {
            registryValue = true;
            tokenNumber = "";
            returnUrl = apiURL;
        } else {
            registryValue = false;
            tokenNumber = "";
            returnUrl = apiURL;
        }
        let dateOfBirth: any = '';
        let postCode: any = '';
        let surName: any = '';
        if (this.customerDetail) {
            dateOfBirth = this.datePipe.transform(this.customerDetail.individual.date_of_birth, 'dd/MM/yyyy');
            postCode = this.customerDetail.addresses.address_detail.post_code;
            surName = this.customerDetail.individual.surname;
        }

        this.postRequestData = {
            "transaction_value": this.payableAmount,
            "transaction_date": this.datePipe.transform(this.todaysDate, 'dd/MM/yyyy'),
            "verification_number": sessionStorage.getItem('cvv_value'),
            "token_number": tokenNumber,
            "register_token": registryValue,
            "one_off_card_payment": false,
            "return_url": returnUrl,
            "customer": {
                "email_address": validEmailCheck(this.customerDetail.contact_details.email_address) 
                    ? this.customerDetail.contact_details.email_address : this.dataStore.getLoggedInEmail(),
                "first_name": this.customerDetail?.individual?.first_name,
                "surname": this.customerDetail?.individual?.surname,
                "date_of_birth": this.datePipe.transform(this.customerDetail.individual.date_of_birth, 'dd/MM/yyyy'),
                "mobile_telephone": this.customerDetail.contact_details.mobile_phone_number
                    ? this.customerDetail.contact_details.mobile_phone_number
                    : this.customerDetail.contact_details.home_phone_number
                        ? this.customerDetail.contact_details.home_phone_number
                        : this.customerDetail.contact_details.work_phone_number,
                "address": {
                    "address_line_1": this.customerAddress.flat_number ? this.customerAddress.flat_number : ""
                        + this.customerAddress.house_name ? this.customerAddress.house_name : " ",
                    "address_line_2": this.customerAddress.street_name ? this.customerAddress.street_name : " ",
                    "city": this.customerAddress.district ? this.customerAddress.district : " ",
                    "post_code": this.customerAddress.post_code ? this.customerAddress.post_code : " "
                }
            }
        }
        this.dataStore.setPaymentRequestPayLoad(this.postRequestData);
        setTimeout(() => {
            this.postCardsPayment();
        }, 100);
    }

    postCardsPayment() {
        this.serviceError = false;
        this.paymentService.postCardPaymentDetail().pipe(takeUntil(this.destroy$)).subscribe(() => {
            let data = this.dataStore.getCardPaymentDetails();
            if (data) {
                data.transaction_value = this.postRequestData.transaction_value;
            }
            localStorage.removeItem('paymentErrorDetails');
            localStorage.setItem('paymentUpdateDetails', JSON.stringify(data));
            this.dataStore.setCardPaymentUpdateData(data);
            let postURLData = this.dataStore.getPostDataUrl();
            if (data) {
                this.changeDetectorRef.markForCheck();
                this.onModalClose();
                this.dataStore.setVerifoneUrl(postURLData);
                this.router.navigate(['payment-result-process']);
            }
        },
            (error: any) => {
                this.onModalClose();
                this.handleErrors();
            });
    }

    handleErrors() {
        let errorCode = this.dataStore.getpaymentErrorDetails().code;
        if (errorCode === 100038) {
            this.dataStore.setErrorScreen(this.postCodeInvalidMessage);
            this.serviceError = true;
            this.changeDetectorRef.detectChanges();
        } else if (errorCode === 125) {
            this.dataStore.setErrorScreen(this.specialCharacterNotAllowedMessage);
            this.serviceError = true;
            this.changeDetectorRef.detectChanges();
        } else {
            this.router.navigate(['payment-result-process']);
        }
    }

    onModalOpen(processingContent: any) {
        this.modalRef = this.modalService.open(processingContent, { windowClass: 'save-card-modal' });
    }

    onModalClose() {
        this.modalRef.close();
    }

    changeAddressClick() {
        this.router.navigate(['enter-billing-address']);
    }
}