<!-- <div class="container-column paperless-info-card" aria-describedby="paperlessaccountinfo" *ngIf="showPaperlessInfo">
    <div class="modal-header">
        <span class="wss-checked-icon checked-icon-width"></span>
        <span class="modal-title header-title-font" id="exampleModalLabel" test_id="paperLessAccountInfoHeaderText">
            <bb-content-html [html]="paperlessAccountContent.paperLessAccountInfoHeaderText">
            </bb-content-html>
        </span>
        <span class="close" data-dismiss="modal" aria-label="Close" (click)="onModalClose()">
            <span aria-hidden="true" class="material-icons">clear</span>
        </span>
    </div>
    <div class="modal-body body-text-padding" test_id="paperLessAccountInfoBodyText">
        <bb-content-html [html]="paperlessAccountContent.paperLessAccountInfoBodyText">
        </bb-content-html>
    </div>
    <div class="modal-footer">
        <button class="wss-button paperLessAccountInfoButton" data-dismiss="modal" aria-label="Close" tabindex="0"
            (click)="paperlessAccountClick()" test_id="paperLessAccountInfoButtonText">
            {{paperlessAccountContent.paperLessAccountInfoButtonText}}
        </button>
    </div>
</div> -->
<div class="paperless-banner-wrapper" *ngIf="showPaperlessBanner">
    <div class=container-column>
        <div class="container-row">
            <div class="container-row inner-items">
                <div class="item-icon">
                    <span class="wss-checked-icon checked-icon-width"></span>
                </div>
                <div class="item-text" test_id="paperlessBannerText">
                    <bb-content-html class="para-last-p-mb-0" [html]="paperlessBanner.paperlessBannerText">
                    </bb-content-html>
                </div>
                <div class="item-close">
                    <span class="material-icons" (click)="closeBanner()" id="closePaperlessBanner">clear</span>
                </div>
            </div>
        </div>
        <div class="container-row btn-right">
            <button *ngIf="portalName === 'jaguar'" class="btn paperLessAccountInfoButton wss-button" tabindex="0"
                (click)="paperlessAccountClick()" id="paperlessBannerBtn" test_id="paperlessButtonText">
                {{paperlessBanner.paperlessButtonText}}
            </button>
            <button *ngIf="portalName !== 'jaguar'" class="paperLessAccountInfoButton wss-button" tabindex="0"
                (click)="paperlessAccountClick()" id="paperlessBannerBtn" test_id="paperlessButtonText">
                {{paperlessBanner.paperlessButtonText}}
            </button>
        </div>
    </div>
</div>
