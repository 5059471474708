<div class="payment-holiday-container" *ngIf="paymentHolidayWidgetItem$ | async as paymentHolidayWidgetItem">
    <div class="container-column your-options">
        <div class="container-row section-title-container">
            <span class="section-title"
                *ngIf="availableAdvice === 'PaymentHoliday'" test_id="pageNameIfTermNotAvailabe">{{paymentHolidayWidgetItem.yourOptions.pageNameIfTermNotAvailabe}}</span>
            <span class="section-title" *ngIf="availableAdvice === 'TermExtension'" test_id="pageNameIfTermAvailable">{{paymentHolidayWidgetItem.yourOptions.pageNameIfTermAvailable}}</span>
        </div>
        <div class="container-row holiday-ends" test_id="paymentHolidayOffer">
            <bb-content-html [html]="paymentHolidayWidgetItem.yourOptions.paymentHolidayOfferText" *ngIf="availableAdvice === 'PaymentHoliday'"></bb-content-html>
            <bb-content-html [html]="paymentHolidayWidgetItem.yourOptions.termExtensionOfferText" *ngIf="availableAdvice === 'TermExtension'"></bb-content-html>
        </div>
        <div class="responsive-row offered-option">
            <!-- Term Extension starts-->
            <div class="container-column not-ready option" *ngIf="availableAdvice === 'TermExtension'">
                <div class="container-row option-title">
                    <div class="container-column align-middle text-center" test_id="termExtensionOptionTitle">
                        <bb-content-html class="mt-13px" [html]="paymentHolidayWidgetItem.yourOptions.termExtensionOptionTitle"></bb-content-html>
                    </div>
                </div>
                <div class="container-column option-body align-middle" test_id="termExtensionOptionContentText">
                    <bb-content-html class="mt-13px" [html]="paymentHolidayWidgetItem.yourOptions.termExtensionOptionContentText"></bb-content-html>
                    <div class="container-row">
                        <button role="button" tabindex="0" class="wss-button"
                            (click)="navigateToTermExt()" test_id="termExtensionOptionButtonText">{{paymentHolidayWidgetItem.yourOptions.termExtensionOptionButtonText}}</button>
                    </div>
                </div>
            </div>
            <!-- Term Extension ends -->

            <!-- Payment Holiday starts -->
            <div
                [ngClass]="availableAdvice === 'TermExtension'? 'container-column not-ready option mt-30px': 'container-column not-ready option'">
                <div class="container-row option-title">
                    <div class="container-column align-middle text-center" test_id="paymentHolidayOptionTitle">
                        <ng-container *ngIf="availableAdvice === 'PaymentHoliday'">
                            <bb-content-html class="mt-13px" [html]="paymentHolidayWidgetItem.yourOptions.paymentHolidayOptionTitleIfTermNotAvailable"></bb-content-html>
                        </ng-container>
                        <ng-container *ngIf="availableAdvice === 'TermExtension'">
                            <bb-content-html class="mt-13px" [html]="paymentHolidayWidgetItem.yourOptions.paymentHolidayOptionTitleIfTermAvailable"></bb-content-html>
                        </ng-container>

                    </div>
                </div>
                <div class="container-column option-body align-middle" test_id="paymentHolidayOptionContentText">
                    <span><bb-content-html class="mt-13px" [html]="paymentHolidayWidgetItem.yourOptions.paymentHolidayOptionContentText"></bb-content-html></span>
                    <div class="brand-contact-details">
                        <span><bb-content-html [html]="paymentHolidayWidgetItem.yourOptions.paymentHolidayOptionContentTextPartTwo"></bb-content-html></span>
                        <span *ngIf="paymentHolidayWidgetItem.brandContactDetails"><strong><bb-content-html [html]="paymentHolidayWidgetItem.brandContactDetails.brandWiseCfaNumber"></bb-content-html></strong></span>
                        <span><bb-content-html [html]="paymentHolidayWidgetItem.yourOptions.paymentHolidayOptionContentTextPartThree"></bb-content-html></span>
                    </div>
                    <span *ngIf="paymentHolidayWidgetItem.yourOptions.paymentHolidayOptionContentTextPartFour">
                        <bb-content-html [html]="paymentHolidayWidgetItem.yourOptions.paymentHolidayOptionContentTextPartFour"></bb-content-html>
                    </span>
                </div>
            </div>
            <!-- Payment Holiday Ends -->
        </div>
        <div class="responsive-row mt-25px">
            <div class="container-column" test_id="otherOptionsText">
                <bb-content-html [html]="paymentHolidayWidgetItem.yourOptions.otherOptionsTextTitle"></bb-content-html>
                <div class="brand-contact-details">
                    <span><bb-content-html [html]="paymentHolidayWidgetItem.yourOptions.otherOptionsText"></bb-content-html></span>
                    <span *ngIf="paymentHolidayWidgetItem.brandContactDetails"><bb-content-html [html]="paymentHolidayWidgetItem.brandContactDetails.brandWiseCfaNumber"></bb-content-html></span>
                </div>
                <span><bb-content-html [html]="paymentHolidayWidgetItem.yourOptions.otherOptionsTextPartTwo"></bb-content-html></span>
            </div>
            <div class="container-column" test_id="adviceContentText">
                <bb-content-html [html]="paymentHolidayWidgetItem.yourOptions.adviceContentText"></bb-content-html>
            </div>
        </div>
    </div>
</div>

<!-- <div class="payment-holiday-container">
    <div class="container-column your-options">
        <div class="container-row section-title-container">
            <span class="section-title"
                *ngIf="availableAdvice === 'PaymentHoliday'">{{content[0]?.weneedtospeak}}</span>
            <span class="section-title" *ngIf="availableAdvice === 'TermExtension'">{{content[0]?.uroptions}}</span>
        </div>
        <div class="container-row holiday-ends">
            <span class="text-semibold">{{content[0]?.wersorry}}.</span>
        </div>
        <div class="container-column">
            <span *ngIf="availableAdvice === 'PaymentHoliday'">{{content[0]?.offerpmtholiday}}.</span>
            <span *ngIf="availableAdvice === 'TermExtension'">{{content[0]?.offertermext}}.</span>
            <br>
            <span>{{content[0]?.ifuneed}} <a title="{{content[0]?.git}}" tabindex="0"
                    role="navigation">{{content[0]?.git}}</a>.</span>
            <br>
        </div>
        <div class="responsive-row">
             Term Extension starts-->
            <!-- <div class="container-column not-ready option" *ngIf="availableAdvice === 'TermExtension'">
                <div class="container-row option-title">
                    <div class="container-column align-middle">
                        <span class="text-semibold text-center">{{content[0]?.option1}}</span>
                        <span class="text-center">{{content[0]?.termextension}}</span>
                    </div>
                </div>
                <div class="container-column option-body align-middle">
                    <span class="note text-semibold">{{content[0]?.whtthismeans}}:</span>
                    <ul class="what-it-means" role="list">
                        <li role="listitem">{{content[0]?.permreduce}}</li>
                        <li role="listitem">{{content[0]?.signan}}</li>
                        <li role="listitem">{{content[0]?.nolonger}}</li>
                    </ul>
                    <span class="note text-semibold keep-in-mind">{{content[0]?.kim}}:</span>
                    <ul class="what-it-means kimul" role="list">
                        <li role="listitem">{{content[0]?.upaymore}}</li>
                        <li role="listitem">{{content[0]?.wewillchk}}.</li>
                    </ul>
                    <span class="text-semibold">{{content[0]?.nextsteps}}</span>
                    <span>{{content[0]?.calculateurself}}.</span>
                    <div class="container-row">
                        <button role="button" tabindex="0" class="wss-button"
                            (click)="navigateToTermExt()">{{content[0]?.learnnapply}}</button>
                    </div>
                </div>
            </div> -->
            <!-- Term Extension ends -->

            <!-- Payment Holiday starts -->
            <!-- <div
                [ngClass]="availableAdvice === 'TermExtension'? 'container-column not-ready option mt-30px': 'container-column not-ready option'">
                <div class="container-row option-title">
                    <div class="container-column align-middle">
                        <ng-container *ngIf="availableAdvice === 'PaymentHoliday'">
                            <span class="text-semibold text-center">{{content[0]?.extendinguph}}</span>
                            <span class="text-center">{{content[0]?.abletoextendph}}.</span>
                        </ng-container>
                        <ng-container *ngIf="availableAdvice === 'TermExtension'">
                            <span class="text-semibold text-center">{{content[0]?.option2}}</span>
                            <span class="text-center">{{content[0]?.extendurpmt}}</span>
                        </ng-container>

                    </div>
                </div>
                <div class="container-column option-body align-middle">
                    <span class="note text-semibold">{{content[0]?.whtthismeans}}:</span>
                    <ul class="what-it-means" role="list">
                        <li role="listitem">{{content[0]?.tempstop}}</li>
                        <li role="listitem">{{content[0]?.extpmtholiday}}</li>
                        <li role="listitem">{{content[0]?.hvtoend}}</li>
                    </ul>
                    <span class="note text-semibold keep-in-mind">{{content[0]?.kim}}:</span>
                    <ul class="what-it-means kimul" role="list">
                        <li role="listitem">{{content[0]?.umustrequest}}</li>
                        <li role="listitem">{{content[0]?.pmtholidaymuststart}}</li>
                    </ul>
                    <span class="text-semibold">{{content[0]?.thidoptionmaynot}} <a title="0800 000 000"
                            tabindex="0">0800 000 000</a>
                        {{content[0]?.qoutingpo}}</span>
                    <br>
                    <span class="text-semibold">{{content[0]?.makesure}}.</span>
                </div>
            </div> -->
            <!-- Payment Holiday Ends -->
        <!--</div>
        <div class="responsive-row mt-25px">
            <div class="container-column">
                <span class="other-options text-semibold">{{content[0]?.otheroptions}}</span>
                <div class="container-column mt-21px there-may-be">
                    <p>{{content[0]?.ifudontwish}} <a title="{{content[0]?.helpno}}"
                            tabindex="0">{{content[0]?.helpno}}</a>.</p>
                </div>
                <ul role="list">
                    <li role="listitem">{{content[0]?.voltermination}}.</li>
                    <li role="listitem">{{content[0]?.ifuprefer}}.</li>
                </ul>
            </div>
            <div class="container-column">
                <span class="other-options text-semibold">{{content[0]?.advice}}</span>
                <br>
                <span class="text-semibold">{{content[0]?.getimpartial}}.</span>
                <ul class="mt-20px mb-108px advice-list" role="list">
                    <li role="listitem">{{content[0]?.pandemic}}. <a (click)="openFCA()"
                            title="{{content[0]?.visitfca}}" tabindex="0" role="link">{{content[0]?.visitfca}}</a></li>
                    <li role="listitem">{{content[0]?.mastext}}. <a title="{{content[0]?.visitmas}}" tabindex="0"
                            role="link">{{content[0]?.visitmas}}</a></li>
                </ul>
            </div>
        </div>
    </div>
</div> -->