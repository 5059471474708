import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { injectBrandContactContent, injectBrandDetailIntoPage } from '@wss/common/util/util';
import { HelpCentreItem } from '@wss/model/helpCentre';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'bb-my-agreement-faqs',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './my-agreement-faqs.component.html',
    styles: [],
    providers: [ContentService]
})
export class MyAgreementFaqsComponent implements OnInit {

    helpCentreWidgetItem$: Observable<HelpCentreItem | undefined> = this.bbContentService.getContent<HelpCentreItem>('helpCentreWidgetItem');
    helpCentreWidgetItem = new HelpCentreItem();

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService
    ) { }

    ngOnInit(): void {
        this.tealiumService.view(
            {
                JourneyName: 'Help Centre',
                JourneyStepName: 'My Agreement',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.updateBrandDetails();
    }

    updateBrandDetails() {
        this.helpCentreWidgetItem$.subscribe((pageItem: any) => {
            this.helpCentreWidgetItem = pageItem;
            this.helpCentreWidgetItem.helpCentreAgreement = injectBrandContactContent(this.helpCentreWidgetItem.helpCentreAgreement, this.helpCentreWidgetItem.brandContactDetails);
            this.helpCentreWidgetItem.helpCentreAgreement.myAgreementFirstAccordion?.forEach((element: any) => {
                element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
            });
            this.helpCentreWidgetItem.helpCentreAgreement.myAgreementSecondAccordion?.forEach((element: any) => {
                element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
            });
            this.helpCentreWidgetItem.helpCentreAgreement.myAgreementThirdAccordion?.forEach((element: any) => {
                element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
            });
            this.helpCentreWidgetItem.helpCentreAgreement.myAgreementFourthAccordion?.forEach((element: any) => {
                element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
            });
            this.helpCentreWidgetItem.helpCentreAgreement.myAgreementFifthAccordion?.forEach((element: any) => {
                element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails); //NOSONAR
            });
            this.changeDetectorRef.detectChanges();
        })
    }
}
