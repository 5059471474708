<div class="container-column service-unavailable-container">
    <div class="container-row" *ngIf="serviceException?.imageClass !== ''">
        <span class="{{serviceException?.imageClass}}"></span>
    </div>
    <div class="container-row text-center content">
        <bb-content-html [html]="serviceException.errorInfoText"></bb-content-html>
    </div>
    <div *ngIf="serviceException?.componentUri !== ''" class="container-row back-btn" >
        <button id="service-ex-btn" tabindex="0" role="button" class="wss-button" (click)="navigateTo(serviceException.componentUri)">{{serviceException?.buttonText}}</button>
    </div>
    <div class="flex-horizontal-center mt-10px" *ngIf="serviceException?.cancelLink">
        <bb-content-html [html]="serviceException.cancelLink"></bb-content-html>
    </div>
</div>

<!-- 
    <div class="container-column service-unavailable-container">
    <div class="container-row" *ngIf="serviceException?.imageClass !== ''">
        <span class="{{serviceException?.imageClass}}"></span>
    </div>
    
    <span class="text-center" *ngIf="serviceException?.phraseBold !== ''">{{serviceException?.phraseBold}}</span>
    <span *ngIf="serviceException?.phraseRegular !== ''">{{serviceException?.phraseRegular}}</span>
    <span *ngIf="serviceException?.subPhraseRegular !== ''">{{serviceException?.subPhraseRegular}}</span>
    <span class="text-center" *ngIf="serviceException?.helpNote !== ''" [innerHTML]="helpText"></span>
    <div class="container-column" *ngIf="serviceException?.callUs">
        <span>{{content[0]?.tel}}:</span>
        <span>{{content[0]?.phoneno}}</span>
        <span>{{content[0]?.opntimes}}:</span>
        <div class="inner-container">
            <span>{{content[0]?.mtof}}</span>
            <span>{{content[0]?.sat}}</span>
            <span>{{content[0]?.sun}}</span>
        </div>
    </div>
    <span class="text-center mt-10px" *ngIf="serviceException?.beforeButtonText !== ''">{{serviceException?.beforeButtonText}}</span>
    <div *ngIf="serviceException?.componentUri !== ''" [ngClass]="serviceException?.callUs? 'container-row': 'container-row mt-25px'" >
        <button tabindex="0" role="button" class="wss-button" (click)="navigateTo(serviceException.componentUri)">{{serviceException?.buttonText}}</button>
    </div>
    <div class="flex-horizontal-center mt-10px" *ngIf="serviceException?.cancelButton">
        <a (click)="cancel()">Cancel</a>
    </div>
</div>
 -->