import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
// import { ContentData } from '../../../../config/wss-app-constants';
// import { ContentService as OldContentService } from '../../../../service/content.service';
import { MessageService } from '../../../../service/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendSMSComponent } from '../../../../common/components/send-sms/send-sms.component';
import { SMSModal } from '../../../../model/sms-modal';
import { RouterService } from '@backbase/foundation-ang/core';
import { isErrServer } from '../../../../common/util/util';
import { DatastoreService } from '../../../../service/datastore.service';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { SendSMS } from '@wss/model/send-sms';
import { BannerMessage } from '../../../../model/bannerMessage';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'change-payment-result',
    templateUrl: './change-payment-result.component.html',
    providers: [ContentService]
})

export class ChangePaymentResultComponent implements OnInit {
    // contentTxt: string = ContentData.ChangePaymentResult;
    // content: Array<any> = [];
    // contentFolder: string = ContentData.MakePayment;

    smsModal: SMSModal = new SMSModal();
    serviceError: boolean = false;

    sendSMS: SendSMS = new SendSMS();
    bannerMessage: BannerMessage = new BannerMessage();
    smsResponse: any;
    isBannerActive: boolean = false;

    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
    paymentsWidgetItem: PaymentsWidgetItem = new PaymentsWidgetItem();

    constructor(
        // private contentService: OldContentService,
        private changeDetectorRef: ChangeDetectorRef,
        private messageService: MessageService,
        private modalService: NgbModal,
        private router: RouterService,
        private datastoreService: DatastoreService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService) { }

    ngOnInit() {
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Change Payment Result',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        // this.getStaticContent(this.contentTxt, this.contentFolder);
        this.handleErrors();
        this.paymentsWidgetItem$.subscribe((paymentsWidgetItem: any) => {
            this.paymentsWidgetItem = paymentsWidgetItem;
        });
        this.messageService.setCurrentStep(0);
    }

    // getStaticContent(contenttxt: string, contentfolder: string): void {
    //     this.contentService.getStaticContent(contenttxt, contentfolder).subscribe((content: any[]) => {
    //         this.content = content;
    //         this.changeDetectorRef.detectChanges();
    //     });
    // }

    sendSMSClick() {
        // this.smsModal.title = this.content[0].smstitle;
        // this.smsModal.subtitle = this.content[0].smssubtitle;
        this.sendSMS.sendSMSTitleText = this.paymentsWidgetItem.changePaymentResult.changePaymentSendSms.sendSMSTitleText;
        this.sendSMS.sendSMSTopBodyText = this.paymentsWidgetItem.changePaymentResult.changePaymentSendSms.sendSMSTopBodyText;
        this.sendSMS.sendSMSHelpNoteText = this.paymentsWidgetItem.changePaymentResult.changePaymentSendSms.sendSMSHelpNoteText;
        this.sendSMS.mobileNumberLabelText = this.paymentsWidgetItem.changePaymentResult.changePaymentSendSms.mobileNumberLabelText;
        this.sendSMS.mobileNumberPlaceholderText = this.paymentsWidgetItem.changePaymentResult.changePaymentSendSms.mobileNumberPlaceholderText;
        this.sendSMS.sendSMSButtonText = this.paymentsWidgetItem.changePaymentResult.changePaymentSendSms.sendSMSButtonText;
        this.sendSMS.mobileNumberErrorMessageText = this.paymentsWidgetItem.changePaymentResult.changePaymentSendSms.mobileNumberErrorMessageText;
        this.sendSMS.smsType = "CPD";
        this.messageService.setSendSMSContentData(this.sendSMS);
        const modalRef = this.modalService.open(SendSMSComponent, { windowClass: 'save-card-modal' });
        modalRef.componentInstance.smsModal = this.smsModal;
        this.messageService.getSMSResponse().subscribe((response: any) => {
            this.handleSMSResponse(response);
        });
    }

    handleErrors() {
        this.messageService.getServiceError().subscribe((error: any) => {
            this.showError(error.status);
        });
    }

    showError(errStatus: number): void {
        if (isErrServer(errStatus)) {
            this.datastoreService.setErrorServer();
            this.serviceError = true;
            this.changeDetectorRef.detectChanges();
        }
    }

    navigateTo() {
        //this.router.navigate(['myaccount']);
        location.href = '#/myaccount';
    }

    handleSMSResponse(response: any) {
        this.bannerMessage = new BannerMessage();
        this.smsResponse = response;
        this.isBannerActive = false;
        this.changeDetectorRef.detectChanges();
        this.bannerMessage.closeIconClass = "clear";
        this.bannerMessage.iconPositionClass = "mgn-top-15px";
        this.bannerMessage.isBannerClosed = false;
        if (this.smsResponse.code === "OK") {
            this.bannerMessage.materialIconClass = "check_circle_outline";
            this.bannerMessage.leftSectionColorClass = "message-success";
            this.bannerMessage.titleText = `${this.paymentsWidgetItem.changePaymentResult.smsSuccessBanner.titleTextBeforeData}`;
        } else {
            this.bannerMessage.infoTypeClass = 'error-icon';
            this.bannerMessage.leftSectionColorClass = "message-failure";
            this.bannerMessage.titleText = `${this.paymentsWidgetItem.changePaymentResult.smsFailureBanner.titleTextBeforeData}`;
        }
        this.messageService.setBannerMessage(this.bannerMessage);
        this.isBannerActive = true;
        this.changeDetectorRef.detectChanges();
    }
}