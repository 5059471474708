import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserRepoService} from "../../service/user-repo.service";
import {ContentData} from '../../../../config/wss-app-constants';
import {ItemModel, RouterService} from "@backbase/foundation-ang/core";
//import {ContentService as OldContentService} from "../../../../service/content.service";
import { ContentService } from '@backbase/universal-ang/content';
import {LoginWidgetItem} from '../../../../model/login-widget/login-widget';
import { Observable } from "rxjs";
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-wss-account-locked',
  templateUrl: './account-locked.component.html',
  providers: [ ContentService ]
})

export class AccountLockedComponent implements OnInit {

  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');

  constructor(
    private router: Router,
    private itemModel: ItemModel,
    
    private userRepoService: UserRepoService,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
  ) { }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Login',
        JourneyStepName: 'Account Locked',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

  submit() {
    this.router.navigate(['/']);
  }

}
