<div class="container-footer"  >
  <div class="row pt-15px">
    <!-- *ngIf="footerWidgetItem$ | async as foooterWidgetItem" -->
    <div class="col-sm-6 footer-links">
      <!-- <a href="#">Cookie policy</a> | <a href="#">Terms & conditions</a> | <a href="#">Privacy policy</a> -->
      <!-- <a href="#" #FooterCookieLink>
        <bb-content key="FooterCookieLink"></bb-content>
      </a> | 
      <a href="#" #TermsAndConditionsLink>
        <bb-content key="TermsAndConditionsLink"></bb-content>
      </a> | 
      <a href="#" #FooterPrivacyLink>
        <bb-content key="FooterPrivacyLink"></bb-content>
      </a> -->
      <span >
        <bb-content-html [html]="footerWidgetItem.footerDetails.linkFooter"></bb-content-html>
      </span>
    </div>
    <div class="col-sm-6 footer-text no-para-margin">
      <!-- Black Horse Registered in England and Wales with registered number 661204, Registered Office: 25 Gresham Street,
      London EC2V 7HN -->
      
      <div *ngIf="isBrEligibleBrand">
        <bb-content-html [html]="footerWidgetItem.footerDetails.brandName"></bb-content-html>
        <p>
          <span test_id="registeredOffice">
            {{footerWidgetItem.footerDetails.registeredOffice}}
          </span>
          <span [ngClass]="deviceType === 'Mobile' || deviceType === 'Tablet' || (deviceType === 'Desktop' && isLoggedIn) ? 'd-block' : ''" test_id="registeredNumber">
            {{footerWidgetItem.footerDetails.registeredNumber}}
          </span>
        </p>
      </div>
      <bb-content-html [html]="footerWidgetItem.footerDetails.textFooter"></bb-content-html>
    </div>
  </div>
</div>