import { Component, OnInit } from '@angular/core';
import { FooterWidgetItem } from '@wss/model/content/footer-widget';
import { Observable } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';

@Component({
  selector: 'bb-footer-widget',
  templateUrl: './footer-widget.component.html',
  providers: [ ContentService ]
})
export class FooterWidgetComponent implements OnInit {

  constructor(
    private readonly bbContentService: ContentService) { 
    
  }
  footerWidgetItem$: Observable<FooterWidgetItem|undefined> = this.bbContentService.getContent<FooterWidgetItem>('footerWidgetItem');
  footerWidgetItem:FooterWidgetItem = new FooterWidgetItem();
  ngOnInit(): void {
    this.footerWidgetItem$.subscribe((footerWidgetItem: FooterWidgetItem| undefined)=> {
      if(footerWidgetItem){
        this.footerWidgetItem = footerWidgetItem;
      }
    })
  }

}
