import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { WelcomeWidgetComponent } from './welcome-widget.component';
import { WSSCommonModule } from '../../common/common.module';
import { ContentModule, ContentWidgetModule } from '@backbase/universal-ang/content';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [WelcomeWidgetComponent],
  imports: [
    CommonModule,
    BackbaseCoreModule.withConfig({
      classMap: { WelcomeWidgetComponent }
    }),
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule,
    MatIconModule
  ]
})
export class WelcomeWidgetModule { }