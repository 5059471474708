import { Injectable } from '@angular/core';
import { budgetPageName } from '@wss/config/wss-app-constants';
import { DatastoreService } from '@wss/service/datastore.service';
import { BudgetPlanner } from '../model/budget.model';


@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private datastoreService: DatastoreService
  ) { }

  requstlistObject: any = {};
   convertNumToString(i: number) {
    const arr = ["one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten"];
    return arr[i - 1]
  }

  convertAmountValueFromObject(obj: Array<any>) {
    var objectArray = obj
    for (let i = 0; i < objectArray.length; i++) {
      if (objectArray[i].amount) {
        objectArray[i].amount = objectArray[i].amount.replace('£', '').replace(',', '')
      }
      if (objectArray[i].behind_payments_amount) {
        objectArray[i].behind_payments_amount = objectArray[i].behind_payments_amount.replace('£', '').replace(',', '')
      }
    }
    return objectArray
  }

  //accomodation
  selectMatchingInfoByType(type: string) {
    let selectedObjects = [];
    let budgetObject: any = this.datastoreService.getBudgetDetail()//this.mockgetData()
    if (budgetObject && budgetObject.additional_info) {
      selectedObjects = budgetObject.additional_info.filter(function (obj: any) {
        return (obj.type === type && obj.category != 'Others');
      })
    }
   return this.convertAmountValueFromObject(selectedObjects)
  }


  creditorsValue(type: string, returnValue: string, index: number) {
    let matchingInfo = []
    matchingInfo = this.selectMatchingInfoByType(type)

    if (returnValue == "amount") {
      return (matchingInfo.length > 0 && matchingInfo[index - 1] && matchingInfo[index - 1][returnValue]) ? this.displayAmount(matchingInfo[index - 1][returnValue], false) : 'N/A';
    }
    else {
      return (matchingInfo.length > 0 && matchingInfo[index - 1] && matchingInfo[index - 1][returnValue]) ? matchingInfo[index - 1][returnValue] : 'N/A';
    }
  }

  selectMatchingInfo(type: string, category: string) {
    let selectedObjects = [];
    let budgetObject: any = this.datastoreService.getBudgetDetail()//this.mockgetData()
    if (budgetObject && budgetObject.additional_info) {
      selectedObjects = budgetObject.additional_info.filter(function (obj: any) {
        return (obj.type === type && obj.category == category);
      })
    }
    return this.convertAmountValueFromObject(selectedObjects)
  }

  selectMatchingInfoBySubCategory(type: string, category: string, sub_category: string) {
    let selectedObjects = [];
    let budgetObject: any = this.datastoreService.getBudgetDetail()//this.mockgetData()
    if (budgetObject && budgetObject.additional_info) {
      selectedObjects = budgetObject.additional_info.filter(function (obj: any) {
        return (obj.type === type && obj.sub_category == sub_category);
      })
    }
    return this.convertAmountValueFromObject(selectedObjects)
  }

  getArrearsContribution(type: string, category: string, index: number) {
    let matchingInfo = []
    matchingInfo = this.selectMatchingInfoByType(type)

    if (matchingInfo.length > 0 && matchingInfo[index - 1] && matchingInfo[index - 1].behind_payments_amount) {
      return 'yes'
    }
    else if (matchingInfo.length > 0 && matchingInfo[index - 1] && !matchingInfo[index - 1].behind_payments_amount) {
      return 'no'
    }
    else {
      return 'N/A'
    }

  }


  returnValueByMatchingGroup(type: string, category: string, returnValue: string, isIncome:boolean, subCategoryValue?: string) {
    let selectedObjects = [];
    let filterOtherObject = [];

    let budgetObject: any = this.datastoreService.getBudgetDetail()//this.mockgetData()

    if (budgetObject && budgetObject.additional_info) {
      selectedObjects = budgetObject.additional_info.filter(function (obj: any) {
        return (obj.type === type && obj.category == category);
      })
    }
    if (!subCategoryValue && ((returnValue == 'amount' && selectedObjects.length > 0) || (returnValue == 'frequency' && selectedObjects.length > 0))) {

      if (selectedObjects.length == 1) {
        if (returnValue == "amount") { 
          return this.displayAmount(selectedObjects[0][returnValue], isIncome)
        }
        else {
         
          return selectedObjects[0][returnValue]
        }
      }
      else {
        return this.concateMultipleValues(selectedObjects, returnValue)
      }

    }
    else if (subCategoryValue && (category == "Other" || category == "Insurance") && ((returnValue == 'amount') || (returnValue == 'frequency'))) {
      filterOtherObject = selectedObjects.filter(function (obj: any) {
        return (obj.sub_category === subCategoryValue && obj.category === category);
      })
      return this.concateMultipleValues(filterOtherObject, returnValue)
    }
    else {
      return "N/A"
    }

  }

  concateMultipleValues(filterOtherObject: any, returnValue: string) {
    let valueStringAddition = "";
    if (filterOtherObject.length > 1) {
      for (let i = 0; i < filterOtherObject.length; i++) {
        if (returnValue == "amount") {
          valueStringAddition = valueStringAddition + (valueStringAddition ? ', ' : '') + this.displayAmount(filterOtherObject[i][returnValue], false)
        }
        else {
          valueStringAddition = valueStringAddition + (valueStringAddition ? ', ' : '') + filterOtherObject[i][returnValue];
        }
      }
    }
    else {
      if (returnValue == "amount") {
        valueStringAddition = filterOtherObject.length > 0 ? this.displayAmount(filterOtherObject[0][returnValue], false) : 'N/A'
      }
      else {
        valueStringAddition = filterOtherObject.length > 0 ? filterOtherObject[0][returnValue] : 'N/A'
      }
    }
    return valueStringAddition
  }

  mvCalculationForIncome(amount: string, frequency: string, isIncome: boolean) {
    if (amount && frequency) {
      let amountList = amount.split(', ')
      let frequencyList = frequency.split(', ')

      let mvAmountValue = "";
      for (var i = 0; i < amountList.length; i++) {
        if (isIncome) {
          mvAmountValue = mvAmountValue + (mvAmountValue ? ', ' : '') + this.mvConversionIncome(frequencyList[i], this.convertNumber(amountList[i]))?.toString()
        }
        else {
          mvAmountValue = mvAmountValue + (mvAmountValue ? ', ' : '') + this.mvConversionNoNIncome(frequencyList[i], this.convertNumber(amountList[i]))?.toString()
        }
      }
      return mvAmountValue === '£0' ? 'N/A' : mvAmountValue;
    }
    else {
      return 'N/A'
    }
  }


  setCurrentPage(currentPageNumber: string, currentPageInStore: string) {
    let storePage: number = isNaN(parseInt(currentPageInStore)) ? 0 : parseInt(currentPageInStore)
    let currentPage = parseInt(currentPageNumber) > storePage ? parseInt(currentPageNumber) : parseInt(currentPageInStore)

    return currentPage;
  }

  removePageDatafromStoreObject(obj: BudgetPlanner, pageType: string) {

    var filteredObject = obj.additional_info?.filter(function (addition, index, arr) {
      return addition.type != pageType;
    });

    obj.additional_info = filteredObject;

    return obj
  }

  isPrimitive(arg: any) {
    var type = typeof arg;
    return arg == null || (type != "object" && type != "function")
  }


  formatObject(additionInfoObject: any) {
    if (additionInfoObject.behind_payments_amount) {
      additionInfoObject.behind_payments_amount = this.formateAmount(additionInfoObject.behind_payments_amount)
    }


    if (additionInfoObject.amount) {
      additionInfoObject.amount = this.formateAmount(additionInfoObject.amount)
    }

    if (additionInfoObject.is_include_arrears && additionInfoObject.is_include_arrears != null) {
      additionInfoObject.is_include_arrears = Boolean(additionInfoObject.is_include_arrears)
    }

    return additionInfoObject;
  }

  mapPageData(filteredBudgetObject: any, pageData: any, currentPage: string) {

    for (const property in pageData) {
      if (currentPage == "Personal") {
        if (this.isPrimitive(pageData[property]) && (property != "current_page")) {
          filteredBudgetObject[property] = pageData[property];
        }
      }
      else if (Array.isArray(pageData[property]) && pageData[property].length > 0) {
        for (let i = 0; i < pageData[property].length; i++) {
          filteredBudgetObject.additional_info.push(this.formatObject(pageData[property][i]))
        }
      }
      else if (typeof pageData[property] == 'object' && !Array.isArray(pageData[property])) {
        if (pageData[property] && pageData[property].type) {
          filteredBudgetObject.additional_info.push(this.formatObject(pageData[property]))
        }
      }

      filteredBudgetObject.status = pageData.status;
    }

    filteredBudgetObject.current_page = this.setCurrentPage(pageData.current_page, filteredBudgetObject.current_page)
    return filteredBudgetObject;
  }

  createRequestObject(pageData: object, pageType: string) {
    let budgetData: any = this.datastoreService.getBudgetDetail()//this.mockgetData()
    let filteredBudgetObject = this.removePageDatafromStoreObject(budgetData, pageType)

    return this.mapPageData(filteredBudgetObject, pageData, pageType)
  }

  formateAmount(amount: any) {
    var amountvalue = amount || 0;
    return amountvalue.toString().replace('£', '').replace(',', '');
  }

  displayAmount(amount: any, isIncome:boolean) {
    var amountvalue = amount || 0;
    amountvalue = this.formateAmount(amountvalue)
    return '£' + this.getRoundedValue(amountvalue.toLocaleString(), isIncome);
  }

  convertobjectToString() {
    let obj: any = this.mapMailObject();
    let stringIfyObject = "";
    let optionalfield = "Salary:|Benefits:|Pension:"//These are duplicate object needs to be removed from service
    for (const property in obj) {
      stringIfyObject = stringIfyObject + property + ":" + obj[property] + '|';
    }

    stringIfyObject = stringIfyObject + optionalfield
    return stringIfyObject
  }

  createMailobj(emailid: any) {
    let obj = {
      "list_attributes": this.convertobjectToString(),
      "account_number": '',
      "recipient_email_address": emailid,
      "email_title": "MotorFinanceOnlineBudgetForm",
      "email_subject": "MotorFinanceOnlineBudgetForm"
    }

    return obj
  }




  mapMailObject() {
    let budgetData: any = this.datastoreService.getBudgetDetail()//this.mockgetData()
    let submitBudgetData: any = this.datastoreService.getSubmitBudgetDetail();
    this.requstlistObject['ID'] = this.datastoreService.getCurrentBudgetFormId();
    this.requstlistObject.AccountNumber = this.getAccountNo;
    this.requstlistObject.VehicleRegistrationNumber = this.getVehicleRegistrationNumber();
    this.requstlistObject.NoOfAdults = budgetData['nr_of_adults'];
    this.requstlistObject.NoOfDependents = budgetData['nr_of_dependents'];
    this.requstlistObject.NoOfVehicles = budgetData['nr_of_vehicles_lending'];

    this.requstlistObject.Salary = this.returnValueByMatchingGroup(budgetPageName.INCOME, "Work", "amount", true);
    this.requstlistObject.SalaryFrequency = this.returnValueByMatchingGroup(budgetPageName.INCOME, "Work", "frequency", true);
    this.requstlistObject.Benefits = this.returnValueByMatchingGroup(budgetPageName.INCOME, "Benefits", "amount", true);
    this.requstlistObject.BenefitsFrequency = this.returnValueByMatchingGroup(budgetPageName.INCOME, "Benefits", "frequency", true);
    this.requstlistObject.Pension = this.returnValueByMatchingGroup(budgetPageName.INCOME, "Pension", "amount", true);
    this.requstlistObject.PensionFrequency = this.returnValueByMatchingGroup(budgetPageName.INCOME, "Pension", "frequency", true);
    this.requstlistObject.Mortgage = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Mortgage", "amount", false);
    this.requstlistObject.MortgageFrequency = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Mortgage", "frequency", false);
    this.requstlistObject.Rent = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Rent", "amount", false);
    this.requstlistObject.RentFrequency = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Rent", "frequency", false);
    this.requstlistObject.WaterRate = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Water", "amount", false);
    this.requstlistObject.CouncilTax = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Council tax", "amount", false);
    this.requstlistObject.Gas = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Energy And Gas", "amount", false);
    this.requstlistObject.Clothing = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Clothing", "amount", false);
    this.requstlistObject.Healthcare = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Healthcare", "amount", false);
    this.requstlistObject.Leisure = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Leisure", "amount", false);
    this.requstlistObject.MotorRunningCosts = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Motor Running Cost", "amount",false);
    this.requstlistObject.Travel = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Travel", "amount", false);
    this.requstlistObject.Internet = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Internet", "amount", false);
    this.requstlistObject.TV = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "TV Licence", "amount", false);
    this.requstlistObject.Food = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Grocieries", "amount", false);
    this.requstlistObject.Dependants = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Dependants", "amount", false);
    this.requstlistObject.Telephone = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Mobile Phone", "amount", false);
    this.requstlistObject.OtherLiving = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Other", "amount", false);
    this.requstlistObject.AccommodationCosts = submitBudgetData.rent_motgage === null ? 'N/A' : this.displayAmount(submitBudgetData.rent_motgage, false);
    this.requstlistObject.EssentialLivingCosts = submitBudgetData.household_bills === '0'  ? 'N/A' : this.displayAmount(submitBudgetData.household_bills, false);
    this.requstlistObject.OtherCosts = submitBudgetData.living_costs === '0'  ? 'N/A' : this.displayAmount(submitBudgetData.living_costs, false);
    this.requstlistObject.Creditors = submitBudgetData.finance_payments === '0'  ? 'N/A' : this.displayAmount(submitBudgetData.finance_payments, false);
    this.requstlistObject.NetIncomeValue = submitBudgetData.disposable_income === '0' ? 'N/A' : this.displayAmount(submitBudgetData.disposable_income, false);

    this.requstlistObject.TotalOutgoings = this.totalOutGoingSum();
    this.requstlistObject.NetIncomeSign = "";
    var morgageArrears: any = this.selectMatchingInfo(budgetPageName.LIVINGCOST, "Mortgage")
    this.requstlistObject.MortgageArrears = (morgageArrears.length > 0 && morgageArrears[0].behind_payments_amount)
      ? this.displayAmount(morgageArrears[0].behind_payments_amount, true) : 'N/A';
    this.requstlistObject.MortgageArrearsContribution = (this.requstlistObject.MortgageArrears != 'N/A') ? 'Yes' : 'No';
    var rentArrears: any = this.selectMatchingInfo(budgetPageName.LIVINGCOST, "Rent")
    this.requstlistObject.RentArrears = (rentArrears.length > 0 && rentArrears[0].behind_payments_amount)
      ? this.displayAmount(rentArrears[0].behind_payments_amount, true) : 'N/A';
    this.requstlistObject.RentArrearsContribution = (this.requstlistObject.RentArrears != 'N/A') ? 'Yes' : 'No';;

    this.requstlistObject.WaterRateFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Water", "frequency", false);
    this.requstlistObject.CouncilTaxFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Council tax", "frequency", false);
    this.requstlistObject.GasFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Energy And Gas", "frequency", false);
    this.requstlistObject.ClothingFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Clothing", "frequency", false);
    this.requstlistObject.HealthcareFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Healthcare", "frequency", false);
    this.requstlistObject.LeisureFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Leisure", "frequency", false);
    this.requstlistObject.MotorRunningCostsFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Motor Running Cost", "frequency", false);
    this.requstlistObject.TravelFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Travel", "frequency", false);
    this.requstlistObject.InternetFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Internet", "frequency", false);
    this.requstlistObject.TVFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "TV Licence", "frequency", false);
    this.requstlistObject.FoodFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Grocieries", "frequency", false);
    this.requstlistObject.DependantsFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Dependants", "frequency", false);
    this.requstlistObject.TelephoneFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Mobile Phone", "frequency", false);
    this.requstlistObject.OtherLivingFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Other", "frequency", false);
    this.requstlistObject.SalaryMV = this.incomeMVCalculation(budgetPageName.INCOME, "Work");
    this.requstlistObject.BenefitsMV = this.incomeMVCalculation(budgetPageName.INCOME, "Benefits");
    this.requstlistObject.PensionMV = this.incomeMVCalculation(budgetPageName.INCOME, "Pension");

    this.requstlistObject.OtherIncome = this.returnValueByMatchingGroup(budgetPageName.INCOME, "Other", "amount", true);
    this.requstlistObject.OtherIncomeFrequency = this.returnValueByMatchingGroup(budgetPageName.INCOME, "Other", "frequency", true);
    this.requstlistObject.OtherIncomeMV = this.mvCalculationForIncome(this.requstlistObject.OtherIncome, this.requstlistObject.OtherIncomeFrequency, true);
    this.requstlistObject.AggregatedOtherIncomeMV = this.requstlistObject.OtherIncomeMV === 'N/A' ? 'N/A' : this.displayAmount(this.formateMultipleAmount(this.requstlistObject.OtherIncomeMV), true)
    this.requstlistObject.TotalIncome = this.totalIncome()

    this.requstlistObject.MortgageMV = this.nonIncomeMVCalculation(budgetPageName.LIVINGCOST, "Mortgage");
    this.requstlistObject.RentMV = this.nonIncomeMVCalculation(budgetPageName.LIVINGCOST, "Rent");
    this.requstlistObject.WaterRateMV = this.nonIncomeMVCalculation(budgetPageName.SPENDING, "Water");
    this.requstlistObject.CouncilTaxMV = this.nonIncomeMVCalculation(budgetPageName.SPENDING, "Council tax");
    this.requstlistObject.GasMV = this.nonIncomeMVCalculation(budgetPageName.SPENDING, "Energy And Gas");
    this.requstlistObject.ClothingMV = this.nonIncomeMVCalculation(budgetPageName.SPENDING, "Clothing");
    this.requstlistObject.HealthcareMV = this.nonIncomeMVCalculation(budgetPageName.SPENDING, "Healthcare");
    this.requstlistObject.LeisureMV = this.nonIncomeMVCalculation(budgetPageName.SPENDING, "Leisure");
    this.requstlistObject.OtherLivingMV = this.mvCalculationForIncome(this.requstlistObject.OtherLiving, this.requstlistObject.OtherLivingFrequency, false)
    this.requstlistObject.MotorRunningCostsMV = this.nonIncomeMVCalculation(budgetPageName.SPENDING, "Motor Running Cost");
    this.requstlistObject.TelephoneMV = this.nonIncomeMVCalculation(budgetPageName.SPENDING, "Mobile Phone");
    this.requstlistObject.TravelMV = this.nonIncomeMVCalculation(budgetPageName.SPENDING, "Travel");
    this.requstlistObject.InternetMV = this.nonIncomeMVCalculation(budgetPageName.SPENDING, "Internet");
    this.requstlistObject.TVMV = this.nonIncomeMVCalculation(budgetPageName.SPENDING, "TV Licence");
    this.requstlistObject.FoodMV = this.nonIncomeMVCalculation(budgetPageName.SPENDING, "Grocieries");
    this.requstlistObject.DependantsMV = this.nonIncomeMVCalculation(budgetPageName.SPENDING, "Dependants");
    this.requstlistObject.GroundRent = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Other", "amount", false, "Ground rent");
    this.requstlistObject.ServiceCharge = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Other", "amount",false, "Service charges");
    this.requstlistObject.HousingRepairs = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Other", "amount",false, "Repairs");
    this.requstlistObject.HousingMaintenance = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Other", "amount",false, 'Maintenance');
    this.requstlistObject.HousingOther = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Other", "amount",false, 'Other');
    this.requstlistObject.ServiceChargeFrequency = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Other", "frequency",false, "Service charges");
    this.requstlistObject.GroundRentFrequency = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Other", "frequency",false, "Ground rent");
    this.requstlistObject.HousingRepairsFrequency = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Other", "frequency",false, "Repairs");
    this.requstlistObject.HousingMaintenanceFrequency = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Other", "frequency",false, 'Maintenance');
    this.requstlistObject.HousingOtherFrequency = this.returnValueByMatchingGroup(budgetPageName.LIVINGCOST, "Other", "frequency",false, 'Other');
    this.requstlistObject.ServiceChargeMV = this.mvCalculationForIncome(this.requstlistObject.ServiceCharge, this.requstlistObject.ServiceChargeFrequency, false)
    this.requstlistObject.GroundRentMV = this.mvCalculationForIncome(this.requstlistObject.GroundRent, this.requstlistObject.GroundRentFrequency, false)
    this.requstlistObject.HousingRepairsMV = this.mvCalculationForIncome(this.requstlistObject.HousingRepairs, this.requstlistObject.HousingRepairsFrequency, false)
    this.requstlistObject.HousingMaintenanceMV = this.mvCalculationForIncome(this.requstlistObject.HousingMaintenance, this.requstlistObject.HousingMaintenanceFrequency, false)
    this.requstlistObject.HousingOtherMV = this.mvCalculationForIncome(this.requstlistObject.HousingOther, this.requstlistObject.HousingOtherFrequency, false)
    this.requstlistObject.BuildingsFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "frequency",false, "Buildings insurance");
    this.requstlistObject.ContentsFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "frequency",false, "Contents insurance");
    this.requstlistObject.CombinedFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "frequency",false, "Combined insurance");
    this.requstlistObject.CarFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "frequency",false, "Car insurance");
    this.requstlistObject.PetFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "frequency",false, "Pet insurance");
    this.requstlistObject.LifeFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "frequency",false, "Life assurance");
    this.requstlistObject.OtherInsuranceFrequency = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "frequency",false, "Other insurance");
    this.requstlistObject.Buildings = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "amount",false, "Buildings insurance");
    this.requstlistObject.Contents = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "amount",false, "Contents insurance");
    this.requstlistObject.Combined = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "amount",false, "Combined insurance");
    this.requstlistObject.Car = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "amount",false, "Car insurance");
    this.requstlistObject.Pet = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "amount",false, "Pet insurance");
    this.requstlistObject.Life = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "amount",false, "Life assurance");
    this.requstlistObject.OtherInsurance = this.returnValueByMatchingGroup(budgetPageName.SPENDING, "Insurance", "amount",false, "Other insurance");
    this.requstlistObject.BuildingsMV = this.mvCalculationForIncome(this.requstlistObject.Buildings, this.requstlistObject.BuildingsFrequency, false)
    this.requstlistObject.ContentsMV = this.mvCalculationForIncome(this.requstlistObject.Contents, this.requstlistObject.ContentsFrequency, false)

    this.requstlistObject.CombinedMV = this.mvCalculationForIncome(this.requstlistObject.Combined, this.requstlistObject.CombinedFrequency, false)//   this.nonIncomeMVCalculation(budgetPageName.SPENDING, "Insurance", 0, "Combined insurance");
    this.requstlistObject.CarMV = this.mvCalculationForIncome(this.requstlistObject.Car, this.requstlistObject.CarFrequency, false)
    this.requstlistObject.PetMV = this.mvCalculationForIncome(this.requstlistObject.Pet, this.requstlistObject.PetFrequency, false)
    this.requstlistObject.LifeMV = this.mvCalculationForIncome(this.requstlistObject.Life, this.requstlistObject.LifeFrequency, false)
    this.requstlistObject.OtherInsuranceMV = this.mvCalculationForIncome(this.requstlistObject.OtherInsurance, this.requstlistObject.OtherInsuranceFrequency, false)
    this.requstlistObject.Creditor1Type = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'category', 1);
    this.requstlistObject.Creditor1Amount = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'amount', 1);
    this.requstlistObject.Creditor1Frequency = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'frequency', 1);
    this.requstlistObject.Creditor1MV = this.nonIncomeMVCalculation(budgetPageName.MONEYYOUOWN, "", 1);
    this.requstlistObject.Creditor1Arrears = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'behind_payments_amount', 1);
    this.requstlistObject.Creditor1ArrearsContribution = this.getArrearsContribution(budgetPageName.MONEYYOUOWN, 'category', 1);
    this.requstlistObject.Creditor2Type = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'category', 2);
    this.requstlistObject.Creditor2Amount = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'amount', 2);
    this.requstlistObject.Creditor2Frequency = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'frequency', 2);
    this.requstlistObject.Creditor2MV = this.nonIncomeMVCalculation(budgetPageName.MONEYYOUOWN, "", 2);
    this.requstlistObject.Creditor2Arrears = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'behind_payments_amount', 2);
    this.requstlistObject.Creditor2ArrearsContribution = this.getArrearsContribution(budgetPageName.MONEYYOUOWN, 'category', 2);
    this.requstlistObject.Creditor3Type = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'category', 3);
    this.requstlistObject.Creditor3Amount = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'amount', 3);
    this.requstlistObject.Creditor3Frequency = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'frequency', 3);
    this.requstlistObject.Creditor3MV = this.nonIncomeMVCalculation(budgetPageName.MONEYYOUOWN, "", 3);
    this.requstlistObject.Creditor3Arrears = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'behind_payments_amount', 3);
    this.requstlistObject.Creditor3ArrearsContribution = this.getArrearsContribution(budgetPageName.MONEYYOUOWN, 'category', 3);
    this.requstlistObject.Creditor4Type = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'category', 4);
    this.requstlistObject.Creditor4Amount = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'amount', 4);
    this.requstlistObject.Creditor4Frequency = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'frequency', 4);
    this.requstlistObject.Creditor4MV = this.nonIncomeMVCalculation(budgetPageName.MONEYYOUOWN, "", 4);
    this.requstlistObject.Creditor4Arrears = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'behind_payments_amount', 4);
    this.requstlistObject.Creditor4ArrearsContribution = this.getArrearsContribution(budgetPageName.MONEYYOUOWN, 'category', 4);
    this.requstlistObject.Creditor5Type = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'category', 5);
    this.requstlistObject.Creditor5Amount = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'amount', 5);
    this.requstlistObject.Creditor5Frequency = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'frequency', 5);
    this.requstlistObject.Creditor5MV = this.nonIncomeMVCalculation(budgetPageName.MONEYYOUOWN, "", 5);
    this.requstlistObject.Creditor5Arrears = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'behind_payments_amount', 5);
    this.requstlistObject.Creditor5ArrearsContribution = this.getArrearsContribution(budgetPageName.MONEYYOUOWN, 'category', 5);
    this.requstlistObject.Creditor6Type = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'category', 6);
    this.requstlistObject.Creditor6Amount = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'amount', 6);
    this.requstlistObject.Creditor6Frequency = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'frequency', 6);
    this.requstlistObject.Creditor6MV = this.nonIncomeMVCalculation(budgetPageName.MONEYYOUOWN, "", 6);
    this.requstlistObject.Creditor6Arrears = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'behind_payments_amount', 6);
    this.requstlistObject.Creditor6ArrearsContribution = this.getArrearsContribution(budgetPageName.MONEYYOUOWN, 'category', 6);
    this.requstlistObject.Creditor7Type = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'category', 7);
    this.requstlistObject.Creditor7Amount = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'amount', 7);
    this.requstlistObject.Creditor7Frequency = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'frequency', 7);
    this.requstlistObject.Creditor7MV = this.nonIncomeMVCalculation(budgetPageName.MONEYYOUOWN, "", 7);
    this.requstlistObject.Creditor7Arrears = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'behind_payments_amount', 7);
    this.requstlistObject.Creditor7ArrearsContribution = this.getArrearsContribution(budgetPageName.MONEYYOUOWN, 'category', 7);
    this.requstlistObject.Creditor8Type = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'category', 8);
    this.requstlistObject.Creditor8Amount = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'amount', 8);
    this.requstlistObject.Creditor8Frequency = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'frequency', 8);
    this.requstlistObject.Creditor8MV = this.nonIncomeMVCalculation(budgetPageName.MONEYYOUOWN, "", 8);
    this.requstlistObject.Creditor8Arrears = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'behind_payments_amount', 8);
    this.requstlistObject.Creditor8ArrearsContribution = this.getArrearsContribution(budgetPageName.MONEYYOUOWN, 'category', 8);
    this.requstlistObject.Creditor9Type = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'category', 9);
    this.requstlistObject.Creditor9Amount = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'amount', 9);
    this.requstlistObject.Creditor9Frequency = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'frequency', 9);
    this.requstlistObject.Creditor9MV = this.nonIncomeMVCalculation(budgetPageName.MONEYYOUOWN, "", 9);
    this.requstlistObject.Creditor9Arrears = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'behind_payments_amount', 9);
    this.requstlistObject.Creditor9ArrearsContribution = this.getArrearsContribution(budgetPageName.MONEYYOUOWN, 'category', 9);
    this.requstlistObject.Creditor10Type = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'category', 10);
    this.requstlistObject.Creditor10Amount = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'amount', 10);
    this.requstlistObject.Creditor10Frequency = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'frequency', 10);
    this.requstlistObject.Creditor10MV = this.nonIncomeMVCalculation(budgetPageName.MONEYYOUOWN, "", 10);
    this.requstlistObject.Creditor10Arrears = this.creditorsValue(budgetPageName.MONEYYOUOWN, 'behind_payments_amount', 10);
    this.requstlistObject.Creditor10ArrearsContribution = this.getArrearsContribution(budgetPageName.MONEYYOUOWN, 'category', 10);
    this.requstlistObject.AggregatedLivingExpenses = this.aggregatorLivingExpenses(this.requstlistObject.ClothingMV, this.requstlistObject.HealthcareMV, this.requstlistObject.LeisureMV, this.requstlistObject.OtherLivingMV)
    this.requstlistObject.AggregatedInsuranceAmount = this.aggregatorInsuranceAmount(this.requstlistObject.BuildingsMV, this.requstlistObject.ContentsMV, this.requstlistObject.CarMV, this.requstlistObject.CombinedMV, this.requstlistObject.PetMV, this.requstlistObject.LifeMV, this.requstlistObject.OtherInsuranceMV)
    this.requstlistObject.AggregatedCarHomeMaintenance = this.aggregatorCarHomeMaintainceAmount(this.requstlistObject.GroundRentMV, this.requstlistObject.ServiceChargeMV, this.requstlistObject.HousingRepairsMV, this.requstlistObject.HousingMaintenanceMV, this.requstlistObject.HousingOtherMV, this.requstlistObject.MotorRunningCostsMV)
    this.requstlistObject.EntertainmentCostMV = this.aggregatorEntertainmentCostAmount(this.requstlistObject.InternetMV, this.requstlistObject.TVMV)

    return this.requstlistObject;
  }

  aggregatorEntertainmentCostAmount(InternetMV: string, TVMV: string) {
    let internetMV: any = InternetMV || 0;
    let tvmv: any = TVMV || 0;
    let totalValue = this.convertNumber(this.formateMultipleAmount(internetMV))
                     + this.convertNumber(this.formateMultipleAmount(tvmv));
    return totalValue === 0 ? 'N/A' : this.displayAmount(totalValue, false);
  }

  aggregatorCarHomeMaintainceAmount(GroundRentMV: string, ServiceChargeMV: string, HousingRepairsMV: string, HousingMaintenanceMV: string, HousingOtherMV: string, MotorRunningCostsMV: string) {
    let groundRentMV: any = GroundRentMV || 0;
    let serviceChargeMV: any = ServiceChargeMV || 0;
    let housingRepairsMV: any = HousingRepairsMV || 0;
    let housingMaintenanceMV: any = HousingMaintenanceMV || 0;
    let housingOtherMV: any = HousingOtherMV || 0;
    let motorRunningCostsMV: any = MotorRunningCostsMV || 0;

    let totalValue =  this.convertNumber(this.formateMultipleAmount(groundRentMV))
                      + this.convertNumber(this.formateMultipleAmount(serviceChargeMV))
                      + this.convertNumber(this.formateMultipleAmount(housingRepairsMV))
                      + this.convertNumber(this.formateMultipleAmount(housingMaintenanceMV))
                      + this.convertNumber(this.formateMultipleAmount(housingOtherMV))
                      + this.convertNumber(this.formateMultipleAmount(motorRunningCostsMV))

    return totalValue === 0 ? 'N/A' : this.displayAmount(totalValue, false);
  }

  aggregatorInsuranceAmount(BuildingsMV: string, ContentsMV: string, CarMV: string, CombinedMV: string, PetMV: string, LifeMV: string, OtherInsuranceMV: string) {
    let buildingsMV: any = BuildingsMV || 0;
    let contentsMV: any = ContentsMV || 0;
    let carMV: any = CarMV || 0;
    let combinedMV: any = CombinedMV || 0;
    let petMV: any = PetMV || 0;
    let lifeMV: any = LifeMV || 0;
    let otherInsuranceMV: any = OtherInsuranceMV || 0;

    let totalValue =  this.convertNumber(this.formateMultipleAmount(buildingsMV))
                      + this.convertNumber(this.formateMultipleAmount(contentsMV))
                      + this.convertNumber(this.formateMultipleAmount(carMV))
                      + this.convertNumber(this.formateMultipleAmount(combinedMV))
                      + this.convertNumber(this.formateMultipleAmount(petMV))
                      + this.convertNumber(this.formateMultipleAmount(lifeMV))
                      + this.convertNumber(this.formateMultipleAmount(otherInsuranceMV))

    return totalValue === 0 ? 'N/A' : this.displayAmount(totalValue, false);
  }

  aggregatorLivingExpenses(ClothingMV: string, HealthcareMV: string, LeisureMV: string, OtherLivingMV: string) {
    let clothing: any = ClothingMV || 0;
    let healthcare: any = HealthcareMV || 0;
    let leisure: any = LeisureMV || 0;
    //let telephone:any = TelephoneMV || 0;
    let otherLivingMV:any = OtherLivingMV || 0;

    let totalValue = this.convertNumber(this.formateMultipleAmount(clothing))
                     + this.convertNumber(this.formateMultipleAmount(healthcare))
                     + this.convertNumber(this.formateMultipleAmount(leisure))
                     + this.convertNumber(this.formateMultipleAmount(otherLivingMV))
                    // + this.convertNumber(this.formateMultipleAmount(telephone))

    return totalValue === 0 ? 'N/A' : this.displayAmount(totalValue, false);
  }


  convertNumber(amount: any) {
    let amountValue = parseFloat(this.formateAmount(amount))
    amountValue = isNaN(amountValue) ? 0 : amountValue;
    return amountValue;
  }

  incomeMVCalculation(type: string, category: string) {
    let formGroup = this.selectMatchingInfo(type, category)
    if (formGroup.length == 0) {
      return 'N/A'
    }
    let frequency = formGroup[0].frequency
    let amountVal = formGroup[0].amount;

    return this.mvConversionIncome(frequency, amountVal);
  }

  mvConversionIncome(frequency: string, amountVal: any) {
    let amount;
    switch (frequency) {
      case 'Monthly':
        amount = amountVal.toString()
        break;
      case '4 weekly':
        amount = amountVal
        break;
      case 'Weekly':
        amount = amountVal * 4
        break;
      case 'Annually':
        amount = amountVal / 12
        break;
      case 'Fortnightly':
        amount = amountVal * 2
        break;
    }
    return this.displayAmount(amount, true);
  }

  totalIncome(): string {
    let pension = this.formateAmount(this.requstlistObject.PensionMV)
    let benefits = this.formateAmount(this.requstlistObject.BenefitsMV)
    let salary = this.formateAmount(this.requstlistObject.SalaryMV)
    let other = this.formateMultipleAmount(this.requstlistObject.OtherIncomeMV)

    salary = isNaN(parseFloat(salary)) ? 0 : parseFloat(salary);
    benefits = isNaN(parseFloat(benefits)) ? 0 : parseFloat(benefits);
    pension = isNaN(parseFloat(pension)) ? 0 : parseFloat(pension);

    let totalIncome = salary + benefits + pension + other
    return totalIncome === 0 ? 'N/A' : '£' + (this.getRoundedValue(totalIncome, true)).toString();
  }

  formateMultipleAmount(values: string): number {
    var amount: number = 0;
    if (values) {
      let amountArray = []
      amountArray = values.split(", ");
      for (let i = 0; i < amountArray.length; i++) {
        let amountIndex = this.formateAmount(amountArray[i])
        amount = amount + (isNaN(parseFloat(amountIndex)) ? 0 : parseFloat(amountIndex));
      }
    }
    return amount
  }


  totalOutGoingSum(): string {
    let AccommodationCosts = isNaN(parseFloat(this.formateAmount(this.requstlistObject.AccommodationCosts))) ? 0: parseFloat(this.formateAmount(this.requstlistObject.AccommodationCosts));
    let EssentialLivingCosts = isNaN(parseFloat(this.formateAmount(this.requstlistObject.EssentialLivingCosts))) ? 0: parseFloat(this.formateAmount(this.requstlistObject.EssentialLivingCosts))
    let OtherCosts = isNaN(parseFloat(this.formateAmount(this.requstlistObject.OtherCosts))) ? 0: parseFloat(this.formateAmount(this.requstlistObject.OtherCosts))
    let Creditors = isNaN(parseFloat(this.formateAmount(this.requstlistObject.Creditors))) ?  0: parseFloat(this.formateAmount(this.requstlistObject.Creditors))

    let totalSum = AccommodationCosts + EssentialLivingCosts + OtherCosts + Creditors

    return totalSum === 0 ? 'N/A' : '£' + (this.getRoundedValue(totalSum, false)).toString();
  }

  getVehicleRegistrationNumber() {
    let myAccount = this.datastoreService.getMyAccount();
    return myAccount.myAgreementData['registrationNo']
  }


  nonIncomeMVCalculation(type: string, category?: string, index?: number, sub_category?: string) {
    let formGroup = [];
    let itemIndex = index ? index - 1 : 0;
    if (category && !sub_category) {
      formGroup = this.selectMatchingInfo(type, category)
    }
    else if (category && sub_category) {
      formGroup = this.selectMatchingInfoBySubCategory(type, category, sub_category)
    }
    else {
      formGroup = this.selectMatchingInfoByType(type)
    }

    if (formGroup.length == 0 || !formGroup[itemIndex]) {
      return 'N/A'
    }


    let frequency = formGroup[itemIndex].frequency
    let amountVal = formGroup[itemIndex].amount;
    return this.mvConversionNoNIncome(frequency, amountVal)
  }

  mvConversionNoNIncome(frequency: string, amountVal: any) {
    let amount;
    switch (frequency) {
      case 'Monthly':
        amount = amountVal.toLocaleString()
        break;
      case '4 weekly':
        amount = (amountVal * 13) / 12
        break;
      case 'Weekly':
        amount = (amountVal * 52) / 12
        break;
      case 'Annually':
        amount = amountVal / 12
        break;
      case 'Fortnightly':
        amount = (amountVal * 26) / 12
        break;
    }
    return this.displayAmount(amount, false);
  }


  get getAccountNo() {
    return this.datastoreService.getAccountNo();
  }

  getRoundedValue(amount:any, isIncome:boolean) {
    return isIncome ? Math.floor(amount) : Math.ceil(amount);
  }

  mockgetData() {
    return {
    }
  }
}

