


<div class="modal-header pt-2">
 <button type="button" class="close exit-btn" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h5 class="">{{fromParent.saveExitModal.modalHeading}}</h5>
    <bb-content-html [html]="fromParent.saveExitModal.modalBodyText">
    </bb-content-html>
    {{fromParent.saveExitModal.modalBodySubText}}
</div>
<div class="modal-footer">
  <button type="button" class="wss-button btn-dif-view btn btn-primary btn-md mr-md-3 my-2" (click)="saveAndExit()">{{fromParent.saveExitModal.saveAndExitButtonText}} </button>
  <button type="button" class="wss-button-secondary btn-dif-view btn btn-primary btn-md mr-md-3 my-2" (click)="activeModal.dismiss('Close')">{{fromParent.saveExitModal.backButtonText}} </button>
</div>
