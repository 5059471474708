import {Component, OnInit } from '@angular/core';
import {User} from '../../user';
import {UserRepoService} from "../../service/user-repo.service";
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-ws-user-successful',
  templateUrl: './user-successful-component.html',
})

export class UserSuccessfulComponent implements OnInit {
  user:User|undefined;

  ngOnInit() {
    this.tealiumService.view(
      {
        JourneyName: 'Login',
        JourneyStepName: 'User Successful',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

  constructor(private userRepoService: UserRepoService, private tealiumService: TealiumUtagService) {
    this.userRepoService.updateScreenTitle('Authenticate');
  }
}
