import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { WSSAppConstant, ContentData, appConfig } from '../../../../config/wss-app-constants';
import { LoginService } from '../../service/login.service';
import { AccountList } from '../../../../model/accountList';
import { Account } from '../../../../model/account';
//import { ContentService as OldContentService } from "../../../../service/content.service";
import { DatastoreService } from '../../../../service/datastore.service';
import { isErrServer, isNotEmpOrNullOrUndef } from '../../../../common/util/util';
import { ContentService } from '@backbase/universal-ang/content';
import {LoginWidgetItem} from '../../../../model/login-widget/login-widget';
import { Observable } from "rxjs";
import { TealiumUtagService } from '@wss/service/utag.service';
import { Router } from '@angular/router';


@Component({
  selector: 'bb-wss-multiple-accounts',
  templateUrl: 'multiple-accounts.component.html',
  styles: [
  ],
  providers: [ ContentService ]
})

export class MultipleAccountsComponent implements OnInit {
  accounts = {};
  accountList: AccountList[] = [];
  nonCompletedAccountList: AccountList[] = [];
  completedAccountList: AccountList[] = [];
  accountCount: number = 0;
  accountNo: string = '';
  serviceError: boolean = false;
  previousPage: string = "";

  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');

  constructor(
    private loginService: LoginService,
    private datastoreService: DatastoreService,
    private readonly bbContentService: ContentService,
    public changeDetectorRef: ChangeDetectorRef,
    private tealiumService: TealiumUtagService,
    private router: Router
    ) {
  }

  ngOnInit(): void {
    this.datastoreService.sessionInvalid();
    this.tealiumService.view(
      {
        JourneyName: 'Login',
        JourneyStepName: 'Multiple Accounts',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.accountList = this.datastoreService.getAccountList();
    this.previousPage = this.loginService.getLinkType();
    this.completedAccountList = this.accountList.filter((x:any)=>{return x.completion_date});
    this.nonCompletedAccountList = this.accountList.filter((x:any)=>{return !x.completion_date});
    this.accountCount = this.datastoreService.getAccountCount();     
    // this.accountCount = this.accountList.length;
    this.changeDetectorRef.detectChanges();
  }

  selectAccNo(accountNo: string): void {
    this.datastoreService.setAccountNo(accountNo);
    let account = this.accountList.filter(account => account.account_number === accountNo)[0];
      if(isNotEmpOrNullOrUndef(account.completion_date)){
        this.datastoreService.setIsAccountCompleted();
      }
    if(this.previousPage == 'forgotUsername') {
      this.router.navigate(['verify-sms'])
    } else {
      location.href = appConfig.dashboardURL;
    }
      if(isNotEmpOrNullOrUndef(account.archive_date)){
        this.datastoreService.setIsAccountArchived(true);
        location.href = appConfig.mailboxURL;
      }
      else{        
        this.datastoreService.setIsAccountArchived(false);    
        location.href = appConfig.dashboardURL;
      }      
  }

  showError(errStatus: number): void {
    if(isErrServer(errStatus)){
      this.datastoreService.setErrorServer();
      this.serviceError = true;
      this.changeDetectorRef.detectChanges();
    }
  }
}