<div *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <div class="login-container">
    <span class="section-label-size" test_id="loginWidgetItemtitleNameContent">
      <span>{{loginWidgetItem.forgotUserNameVerifySMS.pageHeaderText}}</span>
    </span>
    <hr />
    <div class="back-to-login">
      <a href="#/user-login">
        <span class="material-icons">keyboard_backspace</span> 
        {{loginWidgetItem.forgotUserNameVerifySMS.backToLoginText}}
      </a>
    </div>
    <div *ngIf="codeFail" role="row" test_id="pageHeaderTextOne">
    </div>
    <div class="reg-row" role="alert" *ngIf="serviceError">
      <bb-wss-banner-message [errorCode]="errorCode"></bb-wss-banner-message>
    </div>
    <div *ngIf="!codeFail" role="row" test_id="pageHeaderTextTwo">
      <bb-content-html [html]="loginWidgetItem.forgotUserNameVerifySMS.infoText"></bb-content-html>
    </div>
    <div role="row" test_id="pageHeaderTextTwo" class="your-mobile-number">
      <bb-content-html [html]="loginWidgetItem.forgotUserNameVerifySMS.mobileNumberHeaderText"></bb-content-html>
    </div>
    <div class="phone-number forgot-username">
      **** *** {{ mobileNo | slice : 1:4 }}
    </div>
    <div class="text-center">
      <button id="verify-sms-btn" bbButton type="button" aria-label="sendButton" class="wss-button btn-full-width" (click)="submit()"
        test_id="nextButtonText">
        {{loginWidgetItem.forgotUserNameVerifySMS.sendButtonText}}
      </button>
    </div>
    <div role="row" test_id="notYourMobileNumber">
      <span class="contact-number-text">
        <bb-content-html [html]="loginWidgetItem.forgotUserNameVerifySMS.mobileNumberNotRegisteredInfoText">
        </bb-content-html>
        <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html>
      </span>
    </div>
  </div>
  <div class="register-link no-para-margin justify-content-center" id="needHelpLinkContent"
    test_id="loginWidgetItemneedHelpLinkContent">
    <bb-content-html [html]="loginWidgetItem.forgotUserNameVerifySMS.needHelpLink"></bb-content-html>
  </div>
</div>