<div class="login-container" *ngIf="loginWidgetItem$ | async as loginWidgetItem">

  <span  class="section-label-size" test_id="which_account_texttitle_id">

    {{loginWidgetItem.linkExpire?.titleText}}
  </span>
<hr/>
  <br />
  <div class="account-lock">
    <div class="div-wrap-icon">
      <div class="timeout-icon"></div>
    </div>

    <br /><br />
    <h4 class="para-inline" test_id="loginWidgetItemlinkExpireContent">
      
      <bb-content-html [html]="loginWidgetItem.linkExpire.linkExpiredlink"></bb-content-html>
    </h4>
    <span test_id="loginWidgetItemlinkExpiretryAgainContent">
      <!-- trygain link is forgot pass -->
     <span class="child-para-inline mr-2"><bb-content-html [html]="loginWidgetItem.linkExpire.tryAgain"></bb-content-html></span> 


     <span class="child-para-inline "><bb-content-html [html]="loginWidgetItem.linkExpire.newLink"></bb-content-html></span> 
    </span>

  </div>
  <div id="link-expired-btn" class="margin-top-between-rows login-page-button text-center" test_id="loginWidgetItemchangePassContent">
    <button id="bb-button-to" bbButton type="submit" aria-label="Favourite" (click)="submit()"
      [className]="'wss-button'" class="button-width-padding" title=" {{loginWidgetItem.linkExpire.changePass}}">

      {{loginWidgetItem.linkExpire.changePass}}
    </button>
  </div>
  <div class="row margin-top-between-rows" style="justify-content: center;"
    test_id="loginWidgetItemlinkExpireneedHelpContent">

    <bb-content-html [html]="loginWidgetItem.linkExpire.needHelp"></bb-content-html>
  </div>
</div>