import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpService} from '../../../service/http.service';
import { Account } from '../../../model/account';
import { catchError, map} from 'rxjs/operators';
import { DatastoreService } from '../../../service/datastore.service';
import { MessageService } from '../../../service/message.service';
import { FwdPwd, LinkValid } from '../../../model/forgotPassword';
import { CustomerToken } from "@wss/model/registrationToken";
import { RegisterDatastoreService } from "@wss/registration-widget/src/service/register-datastore.service";

@Injectable({providedIn: 'root'})
export class LoginService {
  linkType: string = "forgotPassword";
  constructor(
    private httpService: HttpService,
    private messageService: MessageService,
    private datastoreService: DatastoreService,
    private registerDataStoreService: RegisterDatastoreService
    ) {    
  }

  getAuthorisation(): Observable<any> {   
    return this.httpService.getAuthorisation();
  }

  login(email:string, password:string): Observable<any> {   
    return this.httpService.login(email, password);
  }

  UpdateContact(updateRequestData: any): Observable<any> {
    return this.httpService.updateContactDetail(updateRequestData);
  }

  UpdateUserDetails(updateRequestData: any): Observable<any> {
    return this.httpService.updateUserDetails(updateRequestData);
  }

  getAccounts(): Observable<Account[] | any>{
    return this.httpService.getAccounts()
    .pipe(
      map((data) => {this.datastoreService.setAccounts(data)}),
      catchError(error => {   
        this.messageService.setMessage(error.error);  
        return error;   
      })
    );
  }

  getCustomerId(): Observable<any>{
    return this.httpService.getCustomerId()
    .pipe(
      map((data) => {this.datastoreService.setCustomerLogin(data)}),
      catchError(error => {   
        this.messageService.setMessage(error.error);  
        return error;
      })
    );
  }

  getCustomerDetail(customerId: string): Observable<any>{
    return this.httpService.getCustomerDetail(customerId)
    .pipe(
      map((data) => {this.datastoreService.setCustomerDetail(data)}),
      catchError(error => {   
        this.messageService.setMessage(error.error);  
        return error;
      })
    );
  }

  getDecryptEmail(email: string): Observable<any>{
    return this.httpService.loginDecrypt(email)
    .pipe(
      map((data) => { return data}),
      catchError(error => {   
        this.messageService.setMessage(error.error);  
        return error;
      })
    );
  }

  getPwdLink(value: string): Observable<FwdPwd | any>{
    return this.httpService.getPwdLink(value)
    .pipe(
      map((data) => {this.datastoreService.setFwdPwdData(data)})
    )
  }

  validatePwdLink(): Observable<LinkValid | any>{
    return this.httpService.validatePwdLink()
    .pipe(
      map((data) => {this.datastoreService.setValidResetLink(data)}),
      catchError(error => {   
        this.messageService.setMessage(error.error);  
        return error;
      })
    );
  }

  confirmPwdLink(value: string, passwordToken?: boolean): Observable<any>{
    return this.httpService.confirmPwdLink(value, passwordToken)
    .pipe(
      map((data) => {this.datastoreService.setConfirmPwdData(data)})     
    )
  }

  checkPwd(password: string): Observable<any>{
    return this.httpService.checkPwd(password)
    .pipe(
      map((data) => {this.datastoreService.setConfirmPwdData(data)})     
    )
  }

  getDefaultConfiguration() {
    return this.httpService.getApplicationDefaultConfiguration();
  }

  setLinkType(type: string) {
    this.linkType = type;
  }

  getLinkType() {
    return this.linkType;
  }
  
  getCustomers(): Observable<CustomerToken[]|any>{    
    return this.httpService.getCustomers()
    .pipe(
      map((data) => {this.registerDataStoreService.setCustomerToken(data)})
    )
  }
}
