import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { MessageService } from '../../../../service/message.service';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { PaymentHolidayWidgetItem } from '../../../../model/payment-holiday-widget/payment-holiday-widget';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'payment-holiday-landing',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './payment-holiday-landing.component.html',
    providers: [ ContentService ]
})

export class PaymentHolidayLandingComponent implements OnInit {
    paymentHolidayWidgetItem$: Observable<PaymentHolidayWidgetItem | undefined> = this.bbContentService.getContent<PaymentHolidayWidgetItem>('paymentHolidayWidgetItem');
    
    constructor(
        private router: RouterService,
        private messageService: MessageService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService) { }

    ngOnInit() {
        this.tealiumService.view(
            {
                JourneyName: 'Payment holiday',
                JourneyStepName: 'Landing Page',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
    }

    checkYourOptions(){
        this.messageService.setCurrentStep(1);
        this.router.navigate(['triage-questions']);
    }

}