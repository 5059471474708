<div  [ngClass]="{'mt-25px' : defaultTopMargin}">
    <div class="lbl-cont">
        <div *ngIf="!showLblAddress && !showLblRvAddress" class="lbl-text-cont">
           {{lblContent}} 
        </div>
        <div *ngIf="showLblAddress" class="lbl-cont">
            <div *ngIf="lblAddress.house_name !== ' '" class="lbl-text-cont">
                {{lblContent}} <span class="text-capitalize">{{lblAddress.house_name | lowercase}} ?</span>
            </div>
            <div *ngIf="lblAddress.street_name !== ' ' && lblAddress.house_name === ' '" class="lbl-text-cont">
                {{lblContent}} <span class="text-capitalize">{{lblAddress.house_number}} {{lblAddress.street_name | lowercase}} ?</span>
            </div>
        </div>
        <div *ngIf="showLblRvAddress" class="lbl-cont">
            <div *ngIf="lblAddress.house_name !== ' '" class="lbl-text-cont">
                {{lblWas}} <span class="text-capitalize">{{lblAddress.house_name | lowercase}}</span> {{lblContent}} 
            </div>
            <div *ngIf="lblAddress.street_name !== ' ' && lblAddress.house_name === ' '" class="lbl-text-cont">
                {{lblWas}} <span class="text-capitalize">{{lblAddress.street_name | lowercase}}</span> {{lblContent}} 
            </div>
        </div>
        <div class="infor-title-cont" *ngIf="inforContent">
            <a href="javascript:void(0)" (click)=" expandCollapse()">{{lblInforContent}} 
            <span [id]="idExpand" [ngClass]="showInfor? 'material-icons v-middle d-none': 'material-icons v-middle d-inline'">expand_more</span>
            <span [id]="idCollapse" [ngClass]="showInfor? 'material-icons v-middle d-inline': 'material-icons v-middle d-none'">expand_less</span></a>
        </div>
    </div>
    <div [id]="idInforPanel" [ngClass]="showInfor? 'infor-panel d-block': 'infor-panel d-none'">
        <bb-content-html [html]="inforContent"></bb-content-html>
    </div>
</div>