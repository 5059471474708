<section class="cta-screen text-center col-md-7 col-12 p-40px" *ngIf="brgItem$ | async as brgItem">
    <div class="wss-unable-to-continue-icon mb-20px mt-40px"></div>
    <h1 class="cta-screen__title font-size-18 mb-20px" test_id="title">{{brgItem.brgPostDeclined.title}}</h1>
    <div class="mb-30px">
        <bb-content-html [html]="brgItem.brgPostDeclined.bodyText"></bb-content-html>
    </div>
    <div class="d-flex flex-column align-items-center">
        <button id="wss-brg-post-decline-view-result-btn" class="wss-button mt-0" test_id="primaryButton" *ngIf="brgItem.brgPostDeclined.primaryButtonText" (click)="getProposalData()">
            {{brgItem.brgPostDeclined.primaryButtonText}}
        </button>
        <div test_id="linkText" id="wss-brg-post-decline-back-to-options-link">
            <bb-content-html [html]="brgItem.brgPostDeclined.linkText"></bb-content-html>
        </div>
    </div>
</section>