import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { DatastoreService } from "@wss/service/datastore.service";
import { Observable } from "rxjs";

@Injectable()
export class ComplaintsGuard implements CanActivate {
    accountDetails: any = {}
    complaintFeatureFlag: any;

    constructor(private datastoreService: DatastoreService ) {
        this.accountDetails = this.datastoreService.getAccountDetails();
        this.complaintFeatureFlag = this.datastoreService.getComplaintsFeature();
    }

    canActivate(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot | undefined): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if(currentState && currentState.url.indexOf('complaint') > 0 && (this.accountDetails?.in_arrears || !this.complaintFeatureFlag)){
           return false;
        }
        return true;
    }
}