import { PortalContentMock } from "@backbase/foundation-ang/web-sdk";
import { BrandContactDetailsItem } from '@wss/model/content/common/brand-contant-details';

export class HelpCentreItem {
  accountDetails: HelpCentreAccountDetailsItem = new HelpCentreAccountDetailsItem();
  helpCentreLanding: HelpCentreLandingItem = new HelpCentreLandingItem();
  helpCentreMakePayment: HelpCentreMakePaymentItem = new HelpCentreMakePaymentItem();
  helpCentreAgreement: HelpCentreAgreementItem = new HelpCentreAgreementItem();
  helpCentreMoneyWorries: HelpCentreMoneyWorriesItem = new HelpCentreMoneyWorriesItem();
  helpCentreCoronavirusSupport: HelpCentreCoronavirusSupportItem = new HelpCentreCoronavirusSupportItem();
  helpCentreComplaints: HelpCentreComplaintsItem = new HelpCentreComplaintsItem();
  helpCentreVehicleComplaints: HelpCentreVehicleComplaintsItem = new HelpCentreVehicleComplaintsItem();
  getInTouchPage: GetInTouchItem = new GetInTouchItem();
  paymentHolidayFaqs: PaymentHolidayFaqsItem = new PaymentHolidayFaqsItem();
  brandContactDetails: BrandContactDetailsItem = new BrandContactDetailsItem();
  budgetHomeDetail: BudgetHomeItem = new BudgetHomeItem();
  motorCommissionComplaintsContactDetails: string = '';
}

export class HelpCentreAccountDetailsItem {
  pageName: string = "";
  pageHeaderBelowText: string = "";
  firstHeaderTitle: string = "";
  myPersonalDetailsAccordionContent: any[] = [];
  secondHeaderTitle: string = "";
  navigatingOnlineAccountAccordionContent: any[] = [];
  thirdHeaderTitle: string = "";
  understandingAccountAccordionContent: any[] = [];
  fourthHeaderTitle: string = "";
  goPaperlessFaqsAccordionContent: any[] = [];
  fifthHeaderTitle: string = "";
  marketingChoicesFaqsAccordionContent: any[] = [];
}

export class HelpCentreLandingItem {
    pageName: string = "";
    pageHeaderBelowText: string = "";
    mainAccordionContent: any[] = [];
    makePaymentTitle: string = "";
    yourAgreementTitle: string = "";
    managingAccountTitle: string = "";
    moneyWorriesTitle: string = "";
    vehicleComplaintsTitle: string = "";
    secondHeaderTitle: string = "";
    secondHeaderBelowText: string = "";
    coronavirusHelpSupportTitle: string = "";
    coronavirusHelpSupportText: string = "";
    coronavirusHelpSupportButtonText: string = "";
    getInTouchTitle: string = "";
    getInTouchText: string = "";
    getInTouchButtonText: string = "";
    viewTheGuideTitle: string = "";
    viewTheGuideText: string = "";
    viewTheGuideButtonText: string = "";
    viewTheGuideLink: string = '';
}

export class HelpCentreMakePaymentItem {
  pageName: string = "";
  pageHeaderBelowText: string = "";
  firstHeaderTitle: string = "";
  myPaymentsAccordionContent: any[] = [];
  secondHeaderTitle: string = "";
  payDebitCardAccordionContent: any[] = [];
  thirdHeaderTitle: string = "";
  directDebitAccordionContent: any[] = [];
  fourthHeaderTitle: string = "";
  overpaymentsAccordionContent: any[] = [];
  fifthHeaderTitle: string = "";
  settlementsAccordionContent: any[] = [];
  sixthHeaderTitle: string = "";
  accountCompletionAccordionContent: any[] = [];
}

export class HelpCentreAgreementItem {
  pageName: string = "";
  pageHeaderBelowText: string = "";
  firstHeaderTitle: string = "";
  myAgreementFirstAccordion: any[] = [];
  secondHeaderTitle: string = "";
  myAgreementSecondAccordion: any[] = [];
  thirdHeaderTitle: string = "";
  myAgreementThirdAccordion: any[] = [];
  fourthHeaderTitle: string = "";
  myAgreementFourthAccordion: any[] = [];
  fifthHeaderTitle: string = "";
  myAgreementFifthAccordion: any[] = [];
  seeCopyOfYourAgreementText: string = "";
  contactNumberLineOpenText: string = "";
}

export class HelpCentreMoneyWorriesItem {
  pageName: string = "";
  pageHeaderBelowText: string = "";
  firstHeaderTitle: string = "";
  firstHeaderTextBelow: string = "";
  moneyWorriesTopAccordion: any[] = [];
  stepOneTitle: string = "";
  stepOneContent: string = "";
  launchBudgetingToolButtonText: string = "";
  stepTwoTitle: string = "";
  stepTwoContent: string = "";
  stepThreeTitle: string = "";
  stepThreeContent: string = "";
  moneyWorriesAccordion: any[] = [];
  callUsOn: string = "";
  contactLineAvailableTimeText: string = "";
  callUsOnTRSText: string = "";
  helpFormTitle: string = "";
  helpFormTextBelow: string = "";
  helpFormButtonText: string = "";
  vulnerableCustomerContact: string = "";
  vulnerableCustomerFreeTRSNumber: string = "";
  budgetBannerReturningCustomerText: string = "";
  budgetBannerReturnToFormLinkText: string = "";
  budgetBannerNewFormLinkText: string = "";
}

export class HelpCentreCoronavirusSupportItem {
  pageName: string = "";
  pageHeaderBelowText: string = "";
  endOfPaymentHolidayOptionButton: string = "";
  firstContent: string = "";
  launchBudgetToolButton: string = "";
  secondContent: string = "";
  saveTimeOnYourCallText: string = "";
}

export class HelpCentreComplaintsItem {
  pageName: string = "";
  pageHeaderBelowText: string = "";
  firstHeaderTitle: string = "";
  optionOneTitle: string = "";
  optionOneCallUsText: string = "";
  optionOneListContent: string = "";
  optionTwoTitle: string = "";
  optionTwoComplaintAddress: string = "";
  optionTwoListContent: string = "";
  secondContent: string = "";
  thirdContent: string = "";
  byPostAddress: string = "";
  byTelephone: string = "";
  byOnline: string = "";
  finalContent: string = "";
  complaintsGenericHeaderText: string = "";
  complaintsHeaderText: string = "";
  makeAComplaintButton: string = "";
  complaintsAccordionContent: any[] = [];
  motorCommissionComplaintsHeader: string = "";
  showMoreLabel: string = "";
  motorCommissionComplaintsShowMoreText: string = "";
}

export class HelpCentreVehicleComplaintsItem {
  helpCentreVehicleComplaintsPageHeader : string = "";
  helpCentreVehicleComplaintsFirstTitle : string = "";
  helpCentreVehicleComplaintsInitialInstructions: string = "";
  helpCentreVehicleComplaintsContactUsAccordionTitle: string = "";
  helpCentreVehicleComplaintsContactUsAccordion: any[] = [];
  helpCentreVehicleComplaintConfirmationParagraph: string = "";
  helpCentreVehicleComplaintsSupportingEvidenceTitle: string = "";
  helpCentreVehicleComplaintsSupportingEvidenceParagraph: string = "";
  helpCentreVehicleComplaintsResolvingTitle: string = "";
  helpCentreVehicleComplaintsResolvingParagraph: string = "";
  helpCentreVehicleComplaintsCommonQuestionsTitle: string = "";
  helpCentreVehicleComplaintsCommonQuestionsAccordion: any[] = [];
  helpCentreVehicleComplaintsHeaderText: string = "";
  helpCentreVehicleComplaintsMakeAComplaintButton: string = "";
  helpCentreVehicleComplaintsOtherWaysToComplaintText: string = "";
}

export class GetInTouchItem {
  pageName: string = "";
  pageHeaderBelowText: string = "";
  frequentlyAskedQuestionsText: any = "";
  getHelpAndSupportButtonText: string = "";
  thirdHeaderTitle: string = "";
  writeToUsAddress: string = "";
  includeTelephoneNumberText: string = "";
  talkToUsText: string = "";
  callUsText: string = "";
  customerContactUsDetails: string = "";
  queriesLabel: string = "";
  complaintsLabel: string = "";
  brandsToShowMotorCommissionInfoBox: string = "";
  brandsToShowMotorCommissionShowMore: string = "";
  brandsToShowMotorCommissionHeaderText: string = "";
}

export class PaymentHolidayFaqsItem {
  pageName: string = "";
  pageHeaderText: string = "";
  firstAccordionOptionOneTitle: string = "";
  firstAccordionOptionOneContent: string = "";
  firstAccordionOptionTwoTitle: string = "";
  firstAccordionOptionTwoContent: string = "";
  firstAccordionOptionThreeTitle: string = "";
  firstAccordionOptionThreeContent: string = "";
  firstAccordionOptionFourTitle: string = "";
  firstAccordionOptionFourContent: string = "";
  firstAccordionOptionFiveTitle: string = "";
  firstAccordionOptionFiveContent: string = "";
  firstAccordionOptionSixTitle: string = "";
  firstAccordionOptionSixContent: string = "";
}


export class BudgetHomeItem {
  pageName: string = "";
  pageHeaderBelowText: string = "";
  keepInMindSubHeadingText: string = "";
  keepInMindBulletListHTML: string = "";
  backbuttonText: string = "";
  continuebuttonText: string = "";
}