import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { BalloonRefinanceGetdetailsItem } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Observable } from 'rxjs';
import { BalloonRefinanceGetdetailsService } from '@wss/balloon-refinance-getdetails-widget/src/services/balloon-refinance-getdetails.service';

@Component({
  selector: 'bb-brg-mod-ag-next-step',
  templateUrl: './brg-mod-ag-next-step.component.html'
})
export class BrgModAgNextStepComponent implements OnInit {

  brgWidgetItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');
  modagData: any;
  firstPaymentDate: string = "";
  firstPaymentAmount: any;

  constructor(
    private datastore: DatastoreService, 
    private readonly bbContentService: ContentService, 
    private tealiumService: TealiumUtagService,
    private balloonRefinanceGDSrv: BalloonRefinanceGetdetailsService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'Proposal - Modified agreement next steps',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.datastore.setBrHideStepper(true);
    this.setDetails();

  }  

  setDetails() {
    let modagDataTemp = this.datastore.getCalculatedModagDetails();
    if(modagDataTemp){
      this.modagData = modagDataTemp;
      this.getModagData();
    } else {
      this.getModagDetails();
    }   
  }

  getModagDetails() {
    this.balloonRefinanceGDSrv.getModagQuote().subscribe((res: any) => {
      if(res) {
        this.modagData = res.modag_responses;
        this.getModagData();        
      }
      this.changeDetectorRef.detectChanges();
    });
  }  

  getModagData() {
    this.firstPaymentAmount = this.modagData?.modag_quote_response.modag_quotes[0].new_rental_sets[0].new_rental_value;
    this.firstPaymentDate = this.modagData?.modag_quote_response.modag_quotes[0].new_rental_sets[0].new_rental_date;
  }
}