<section class="contact-preference-landing">
    <div>
        <div class="reg-row mt-3" role="alert" *ngIf="serviceError">         
            <bb-wss-banner-message></bb-wss-banner-message>
          </div>
        <div class="common-header-details">
            <div class="d-flex justify-content-center">
                <div class="circle-bg-profile text-center">
                    <span class="circle-name-profile caps-title" data-cs-mask>{{ personalDetails.shortName }}</span>
                </div>
            </div>
            <div class="d-flex justify-content-center header-name-common caps-title">{{ personalDetails.fullname | lowercase }}</div>
        </div>
        <div class="title-header">
            <span class="section-label-title">
                <bb-content-html [html]="contactPreferenceWidgetItem?.contactPreferenceLanding.pageName">
                </bb-content-html>
            </span>
            <hr />
        </div>
        <div>
            <bb-content-html [html]="contactPreferenceWidgetItem?.contactPreferenceLanding.updatedInfo">
            </bb-content-html>
        </div>
        <div>
            <strong>
                <bb-content-html [html]="contactPreferenceWidgetItem?.contactPreferenceLanding.getInTouchHeading">
                </bb-content-html>
            </strong>
        </div>
        <div class="contact-preference-card">
            <form [formGroup]="contactForm" novalidate (ngSubmit)="onSubmit()">
                <div>
                    <p class="section-heading"> 
                        {{ contactPreferenceWidgetItem?.contactPreferenceLanding.yourChoicesLabel }} 
                    </p>
                </div>
                <section>
                    <div class="form-group">
                        <div class="flex-row">
                            <div>
                                <label> {{ contactPreferenceWidgetItem?.contactPreferenceLanding.emailLabel }} </label>
                            </div>
                            <div>
                                <div class="toggle-btn-layer">
                                    <input 
                                        type="radio" 
                                        id="toggle-on-email" 
                                        class="toggle toggle-left" 
                                        [value]="true"
                                        name="UpdateMarketingEmail" 
                                        formControlName="UpdateMarketingEmail">
                                    <label for="toggle-on-email" class="toggle-btn btn-yes">
                                        <span>
                                            <span class="check-mark-icon" *ngIf="contactForm.value.UpdateMarketingEmail != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                        </span>
                                    </label>
                                    <input 
                                        type="radio"
                                        id="toggle-off-email"
                                        class="toggle toggle-right"
                                        [value]="false"
                                        name="UpdateMarketingEmail" 
                                        formControlName="UpdateMarketingEmail">
                                    <label for="toggle-off-email" class="toggle-btn btn-no">
                                        <span class="v-line" *ngIf="contactForm.value.UpdateMarketingEmail == '' "></span>
                                        <span>
                                            <span class="check-mark-icon" *ngIf="f.UpdateMarketingEmail?.valid && contactForm.value?.UpdateMarketingEmail == false "><span id="check-icon" class="material-icons">check</span></span>
                                            No
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="flex-row">
                            <div>
                                <label> {{ contactPreferenceWidgetItem?.contactPreferenceLanding.textMessageLabel }}
                                </label>
                            </div>
                            <div>
                                <div class="toggle-btn-layer">
                                    <input 
                                        type="radio" 
                                        id="toggle-on-textMessage" 
                                        class="toggle toggle-left"
                                        [value]="true" 
                                        name="UpdateMarketingSMS" 
                                        formControlName="UpdateMarketingSMS">
                                    <label for="toggle-on-textMessage" class="toggle-btn btn-yes">
                                        <span>
                                            <span class="check-mark-icon"
                                                *ngIf="contactForm.value.UpdateMarketingSMS != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                        </span>
                                    </label>
                                    <input 
                                        type="radio" 
                                        id="toggle-off-textMessage" 
                                        class="toggle toggle-right"
                                        [value]="false" 
                                        name="UpdateMarketingSMS" 
                                        formControlName="UpdateMarketingSMS">
                                    <label for="toggle-off-textMessage" class="toggle-btn btn-no">
                                        <span class="v-line" *ngIf="contactForm.value.UpdateMarketingSMS == '' "></span>
                                        <span>
                                            <span class="check-mark-icon"
                                                *ngIf="f.UpdateMarketingSMS?.valid && contactForm.value?.UpdateMarketingSMS == false "><span id="check-icon" class="material-icons">check</span></span>
                                            No
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="flex-row">
                            <div>
                                <label> {{ contactPreferenceWidgetItem?.contactPreferenceLanding.phoneLabel }} </label>
                            </div>
                            <div>
                                <div class="toggle-btn-layer">
                                    <input 
                                        type="radio" 
                                        id="toggle-on-phone" 
                                        class="toggle toggle-left" 
                                        [value]="true"
                                        name="UpdateMarketingPhone" 
                                        formControlName="UpdateMarketingPhone">
                                    <label for="toggle-on-phone" class="toggle-btn btn-yes">
                                        <span>
                                            <span class="check-mark-icon"
                                                *ngIf="contactForm.value.UpdateMarketingPhone != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                        </span>
                                    </label>
                                    <input 
                                        type="radio" 
                                        id="toggle-off-phone" 
                                        class="toggle toggle-right"
                                        [value]="false" 
                                        name="UpdateMarketingPhone" 
                                        formControlName="UpdateMarketingPhone">
                                    <label for="toggle-off-phone" class="toggle-btn btn-no">
                                        <span class="v-line" *ngIf="contactForm.value.UpdateMarketingPhone == '' "></span>
                                        <span>
                                            <span class="check-mark-icon"
                                                *ngIf="f.UpdateMarketingPhone?.valid && contactForm.value?.UpdateMarketingPhone == false "><span id="check-icon" class="material-icons">check</span></span>
                                            No
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="flex-row">
                            <div>
                                <label> {{ contactPreferenceWidgetItem?.contactPreferenceLanding.postLabel }} </label>
                            </div>
                            <div>
                                <div class="toggle-btn-layer">
                                    <input 
                                        type="radio" 
                                        id="toggle-on-post" 
                                        class="toggle toggle-left" 
                                        [value]="true"
                                        name="UpdateMarketingPost" 
                                        formControlName="UpdateMarketingPost">
                                    <label for="toggle-on-post" class="toggle-btn btn-yes">
                                        <span>
                                            <span class="check-mark-icon"
                                                *ngIf="contactForm.value.UpdateMarketingPost != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                        </span>
                                    </label>
                                    <input 
                                        type="radio" 
                                        id="toggle-off-post" 
                                        class="toggle toggle-right" 
                                        [value]="false"
                                        name="UpdateMarketingPost" 
                                        formControlName="UpdateMarketingPost">
                                    <label for="toggle-off-post" class="toggle-btn btn-no">
                                        <span class="v-line" *ngIf="contactForm.value.UpdateMarketingPost == '' "></span>
                                        <span>
                                            <span class="check-mark-icon"
                                                *ngIf="f.UpdateMarketingPost?.valid && contactForm.value?.UpdateMarketingPost == false "><span id="check-icon" class="material-icons">check</span></span>
                                            No
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="flex-row">
                            <div>
                                <label> {{ contactPreferenceWidgetItem?.contactPreferenceLanding.onlineAccountLabel }}
                                </label>
                            </div>
                            <div>
                                <div class="toggle-btn-layer">
                                    <input 
                                        type="radio" 
                                        id="toggle-on-onlineAccount" 
                                        class="toggle toggle-left"
                                        [value]="true"
                                        name="UpdateOnlineAccount" 
                                        formControlName="UpdateOnlineAccount">
                                    <label for="toggle-on-onlineAccount" class="toggle-btn btn-yes">
                                        <span>
                                            <span class="check-mark-icon"
                                                *ngIf="contactForm.value.UpdateOnlineAccount != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                        </span>
                                    </label>
                                    <input 
                                        type="radio" 
                                        id="toggle-off-onlineAccount" 
                                        class="toggle toggle-right"
                                        [value]="false" 
                                        name="UpdateOnlineAccount" 
                                        formControlName="UpdateOnlineAccount">
                                    <label for="toggle-off-onlineAccount" class="toggle-btn btn-no">
                                        <span class="v-line" *ngIf="contactForm.value.UpdateOnlineAccount == '' "></span>
                                        <span>
                                            <span class="check-mark-icon"
                                                *ngIf="f.UpdateOnlineAccount?.valid && contactForm.value?.UpdateOnlineAccount == false "><span id="check-icon" class="material-icons">check</span></span>
                                            No
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <bb-content-html
                            [html]="contactPreferenceWidgetItem?.contactPreferenceLanding.onlineAccountBelowTxt">
                        </bb-content-html>
                    </div>
                    <div class="form-group" *ngIf="isThirdPartyConsent" >
                        <div class="flex-row">
                            <div>
                                <label> {{ contactPreferenceWidgetItem?.contactPreferenceLanding.thirdPartyLabel }}
                                </label>
                            </div>
                            <div>
                                <div class="toggle-btn-layer">
                                    <input 
                                        type="radio" 
                                        id="toggle-on-thirdPartyOffer"
                                        class="toggle toggle-left"
                                        [value]="true" 
                                        name="UpdateThirdPartyConsent" 
                                        formControlName="UpdateThirdPartyConsent">
                                    <label for="toggle-on-thirdPartyOffer" class="toggle-btn btn-yes">
                                        <span>
                                            <span class="check-mark-icon"
                                                *ngIf="contactForm.value.UpdateThirdPartyConsent != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                        </span>
                                    </label>
                                    <input 
                                        type="radio" 
                                        id="toggle-off-thirdPartyOffer" 
                                        class="toggle toggle-right"
                                        [value]="false" 
                                        name="UpdateThirdPartyConsent" 
                                        formControlName="UpdateThirdPartyConsent">
                                    <label for="toggle-off-thirdPartyOffer" class="toggle-btn btn-no">
                                        <span class="v-line" *ngIf="contactForm.value.UpdateThirdPartyConsent == '' "></span>
                                        <span>
                                            <span class="check-mark-icon"
                                                *ngIf="f.UpdateThirdPartyConsent?.valid && contactForm.value?.UpdateThirdPartyConsent == false "><span id="check-icon" class="material-icons">check</span></span>
                                            No
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  *ngIf="isThirdPartyConsent" >
                        <bb-content-html
                            [html]="contactPreferenceWidgetItem?.contactPreferenceLanding.thirdPartyBelowTxt">
                        </bb-content-html>
                    </div>

                    <div class="section-heading">
                        <bb-content-html
                            [html]="contactPreferenceWidgetItem?.contactPreferenceLanding.dataCollectHeading">
                        </bb-content-html>
                        <hr />
                    </div>

                    <div>
                        <bb-content-html [html]="contactPreferenceWidgetItem?.contactPreferenceLanding.dataCollectInfo">
                        </bb-content-html>
                    </div>

                    <div class="form-group">
                        <div class="flex-row">
                            <div>
                                <label> {{ contactPreferenceWidgetItem?.contactPreferenceLanding.doYouAgreeLabel }} </label>
                            </div>
                            <div>
                                <div class="toggle-btn-layer">
                                    <input 
                                        type="radio" 
                                        id="toggle-on-doYouAgree" 
                                        class="toggle toggle-left"
                                        [value]="true" 
                                        name="UpdatePixelTracking" 
                                        formControlName="UpdatePixelTracking">
                                    <label for="toggle-on-doYouAgree" class="toggle-btn btn-yes">
                                        <span>
                                            <span class="check-mark-icon"
                                                *ngIf="contactForm.value.UpdatePixelTracking != '' "><span id="check-icon" class="material-icons">check</span></span> Yes
                                        </span>
                                    </label>
                                    <input 
                                        type="radio" 
                                        id="toggle-off-doYouAgree" 
                                        class="toggle toggle-right"
                                        [value]="false" 
                                        name="UpdatePixelTracking" 
                                        formControlName="UpdatePixelTracking">
                                    <label for="toggle-off-doYouAgree" class="toggle-btn btn-no">
                                        <span class="v-line" *ngIf="contactForm.value.UpdatePixelTracking == '' "></span>
                                        <span>
                                            <span class="check-mark-icon"
                                                *ngIf="f.UpdatePixelTracking?.valid && contactForm.value?.UpdatePixelTracking == false "><span id="check-icon" class="material-icons">check</span></span>
                                            No
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section class="accordion-section">
                    <div class="transparent-accordion">
                        <mat-accordion class="accordion mat-accordion-override margin-top-10">
                            <mat-expansion-panel
                                class="mat-expansion-panel-override mat-expansion-panel-spacing-override"
                                [expanded]="isExpansionPanelOpen">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="mat-expansion-panel-header-title-override"
                                        test_id="contactPreferenceExpansionPanelTitle1">
                                        <bb-content-html
                                            [html]="contactPreferenceWidgetItem?.contactPreferenceLanding.expansionPanelTitle1">
                                        </bb-content-html>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p test_id="contactPreferenceExpansionPanelPara1">
                                    <bb-content-html
                                        [html]="contactPreferenceWidgetItem?.contactPreferenceLanding.expansionPanelPara1">
                                    </bb-content-html>
                                </p>
                                <p test_id="contactPreferenceExpansionPanelPara2" class="paragraph"  *ngIf="isThirdPartyConsent" >
                                    <bb-content-html
                                        [html]="contactPreferenceWidgetItem?.contactPreferenceLanding.expansionPanelPara2">
                                    </bb-content-html>  
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </section>
                <section>
                    <div class="actions">
                        <button type="submit" bbButton class="wss-button" [disabled]="!isFormValid">
                            <bb-content-html
                                [html]="contactPreferenceWidgetItem?.contactPreferenceLanding.nextButtonText">
                            </bb-content-html>
                        </button>
                        <a href="javascript:void(0)" (click)="onSkip()">
                            {{ contactPreferenceWidgetItem?.contactPreferenceLanding.skipThisStep }}                            
                        </a>
                    </div>
                </section>
            </form>
        </div>
    </div>
</section>