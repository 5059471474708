import {Component, Input, OnInit, OnDestroy} from "@angular/core";
import {NavigationStart, Router} from "@angular/router";
import {ItemModel} from "@backbase/foundation-ang/core";
import {RegistrationStoreModelService} from "../../../../registration-store/src/registration-store-model.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContentService as OldContentService} from "../../../../service/content.service";
import {RegisterDatastoreService} from '../../service/register-datastore.service';
import {Observable, Subject} from "rxjs";
import {takeUntil} from 'rxjs/operators';
import {RegistrationWidgetItem} from "@wss/model/registrationToken";
import {ContentService} from "@backbase/universal-ang/content";
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  templateUrl: './user-credentials.component.html',
  selector: 'bb-ws-user-credential-email',
  providers: [ContentService]
})
export class UserCredentialsComponent implements OnInit, OnDestroy {

  @Input()
  disabled = true;
  emailForm: FormGroup;  
  validEmailFormat = (/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i); //NOSONAR
  validEmail = false;
  validConfirmEmail = false;
  emailMatch = false;
  showEmailErrors = false;
  showConfirmEmailErrors = false;

  readonly destroy$ = new Subject();

  registrationWidgetItem$: Observable<RegistrationWidgetItem | undefined> = this.bbContentService.getContent<RegistrationWidgetItem>('registrationWidgetItem');

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Registration',
        JourneyStepName: 'User Email',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
    this.emailEnteredByUser();
  }

  ngOnDestroy(): void {
    this.destroy$.next();  
    this.destroy$.complete();    
    this.router.navigate(['user-detail']);
  }

  constructor(
    private itemModel: ItemModel,
    private registrationService: RegistrationStoreModelService,
    private router: Router,
    private contentService: OldContentService,
    private registerstoreService: RegisterDatastoreService,
    formBuilder: FormBuilder,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService
  ) {
    this.itemModel.property('createAccount').pipe(takeUntil(this.destroy$)).subscribe(value => this.registrationService.updateTitle(value as string));
    this.registrationService.updatedStepper(3);
    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          this.router.navigate(['user-detail']);
        }
      }
    });
    this.emailForm = formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.validEmailFormat)]],
      confirmEmail: ['', [Validators.required, Validators.pattern(this.validEmailFormat)]],
    });
  }

  get f() {return this.emailForm.controls;}

  submit() {
    this.registerstoreService.setEmail(this.emailForm.get('email')?.value.toLowerCase());
    this.router.navigate(['enter-pwd']);
  }

  checkIfSubmitButtonCanBeEnabled() {
    if(this.validConfirmEmail && this.validEmail && this.emailForm.get('email')?.value === this.emailForm.get('confirmEmail')?.value){
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  emailEnteredByUser() {
    const email = this.emailForm.get('email');
    email?.valueChanges.forEach(
      (value: string) => {
        if(value && value.length > 2 && this.validEmailFormat.test(value)){
          this.validEmail = true;
          this.showEmailErrors = false;
        } else {
          this.validEmail = false;
          this.showEmailErrors = true;
        }
        this.checkIfSubmitButtonCanBeEnabled();
      }
    );
    const confirmEmail = this.emailForm.get('confirmEmail');
    confirmEmail?.valueChanges.forEach(
      (value: string) => {
        if(value && value.length > 2 && this.validEmailFormat.test(value)){
          this.validConfirmEmail = true;
          this.showConfirmEmailErrors = false;
          if(value === this.emailForm.controls['email'].value) {
            this.emailMatch = true;
          }
          else {
            this.emailMatch = false;
            this.showConfirmEmailErrors = true;
          }
        } else {
          this.validConfirmEmail = false;
          this.showConfirmEmailErrors = true;
        }
        this.checkIfSubmitButtonCanBeEnabled();
      }
    );
  }
}
