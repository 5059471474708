<div *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <div class="login-container" *ngIf="loginWidgetItem$ | async as loginWidgetItem">
    <span class="section-label-size" test_id="loginWidgetItemtitleNameContent">
      {{loginWidgetItem.recoverLoginDetails.titleName}}
    </span>
    <hr />
    <div class="back-to-login">
      <a href="#/user-login">
        <span class="material-icons">keyboard_backspace</span> 
        {{loginWidgetItem.recoverLoginDetails.backToLoginText}}
      </a>
    </div>
    <div class="sub-header">
      <bb-content-html [html]="loginWidgetItem.recoverLoginDetails.headerText"></bb-content-html>
    </div>
    <div class="recover-selection">
      <div id="forgot-password" class="reg-row group-11-row-height verify-by-sms-margin-top clickable" (click)="recoverDetails('password')">
        <div class="group-11 rectangle-round-border rectangle-2-round-border">
          <div class="border-line"></div>
          <div class="icon-wrapper">
            <span class="material-icons material-icons-outlined">lock_outlined</span>
          </div>
          <div class="recover-details">
            <div class="recover-details-text" test_id="forgotPasswordText">
              <bb-content-html [html]="loginWidgetItem.recoverLoginDetails.forgotPasswordText"></bb-content-html>
            </div>
          </div>
          <div class="click-arrow margin-all-auto">
            <span class="material-icons cherovon-right-material">chevron_right</span>
          </div>
        </div>
      </div>
      <div id="forgot-email" class="reg-row group-11-row-height margin-top-security-questions clickable" (click)="recoverDetails('username')">
        <div class="group-11 rectangle-round-border rectangle-2-round-border">
          <div class="border-line"></div>
          <div class="icon-wrapper">
            <span class="material-icons material-icons-outlined">account_circle_outlined</span>
          </div>
          <div class="recover-details">
            <div class="recover-details-text" test_id="forgotUsernameText">
              <bb-content-html [html]="loginWidgetItem.recoverLoginDetails.forgotUsernameText"></bb-content-html>
            </div>
          </div>
          <div class="click-arrow margin-all-auto">
            <span class="material-icons cherovon-right-material">chevron_right</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="register-link no-para-margin justify-content-center"
    id="needHelpLinkContent" test_id="loginWidgetItemneedHelpLinkContent">
    <bb-content-html [html]="loginWidgetItem.recoverLoginDetails.loginFAQLink"></bb-content-html>
  </div>
</div>