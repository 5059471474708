import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { DigitalInboxWidgetItem } from '@wss/model/content/digital-inbox';
import { RouterService } from "@backbase/foundation-ang/core";
import { injectApiDataIntoPage } from '@wss/common/util/util';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable, forkJoin, Subject } from 'rxjs';
import { isErrServer, getDate, getErrorImageClass } from 'libs/common/util/util';
import { MessageService } from 'libs/service/message.service';
import { takeUntil } from 'rxjs/operators';
import { DigitalInboxService } from '../../services/digital-inbox.service';
import { ServiceException } from "@wss/model/service-exception";
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'confirm-preference',
  templateUrl: './confirm-preference.component.html',
  providers: [ ContentService ]
})
export class ConfimrPreferenceComponent implements OnInit, OnDestroy {

  digitalInboxWidgetItem$: Observable<DigitalInboxWidgetItem | undefined> = this.contentService.getContent<DigitalInboxWidgetItem>('digitalInboxWidgetItem');

  //availablePreferences: any[] = ['post', 'paperless', 'multi'];
  selectedPreference: string = "";
  currentAccount: any = {}//{is_paperless: false};
  otherAccounts: any[] = [];//[{is_paperless: true}, {is_paperless: false}];
  customerEmail: string = '';
  digitalInboxWidgetItem: DigitalInboxWidgetItem = new DigitalInboxWidgetItem();

  readonly destroy$ = new Subject();
  serviceError: boolean = false;
  serviceException: ServiceException = new ServiceException();

  constructor(
    private readonly contentService: ContentService,
    private router: RouterService,
    private datastore: DatastoreService,
    private messageService: MessageService,
    private digitalInboxService: DigitalInboxService,
    private tealiumService: TealiumUtagService,
    private changeDetectorRef: ChangeDetectorRef
    ) {}

  ngOnInit(): void {
    this.tealiumService.view (
      {
        JourneyName: 'Digital Inbox',
        JourneyStepName: 'Confirm Preference',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.customerEmail = this.datastore.getLoggedInEmail();
    this.digitalInboxWidgetItem$.subscribe((digitalInboxWidgetItem: any) => {
      this.digitalInboxWidgetItem = digitalInboxWidgetItem;
      this.digitalInboxWidgetItem.confirmPreference = injectApiDataIntoPage(this.digitalInboxWidgetItem.confirmPreference, {customerEmail: this.customerEmail});
      this.changeDetectorRef.detectChanges();
    });
    this.currentAccount = this.datastore.getCurrentAccountMailPreference();
    this.otherAccounts = this.datastore.getOtherAccountsMailPreference();
    if(this.otherAccounts.length){
      this.selectedPreference = this.datastore.availablePreferences[2];
    }
    else if(this.currentAccount.is_paperless){
      this.selectedPreference = this.datastore.availablePreferences[1];
    }
    else{
      this.selectedPreference = this.datastore.availablePreferences[0];
    }
    this.handleErrors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  confirmPreference(){
    let selectedAccounts = [this.currentAccount];
    //this.otherAccounts = this.otherAccounts.filter((account: any) => account.checked === true);
    selectedAccounts = [...selectedAccounts, ...this.otherAccounts];
    forkJoin(
      selectedAccounts.map(account =>
        this.digitalInboxService.saveMailPreference(account.account_number, {"is_paperless": account.is_paperless})
      )
    )
    .pipe(takeUntil(this.destroy$))
    .subscribe((result: any) => {
      this.datastore.setIsPreferenceUpdated();
      this.router.navigate(['preference-result']);
    },
    (error: any) => {
      this.showError(error?.status);
    });
  }

  backToManagePreference(){
    this.router.navigate(['manage-preference']);
  }

  showError(errStatus: number): void {
    this.showPreferenceUpdateError();
    // if(isErrServer(errStatus)){
    //   this.datastore.setErrorServer();
    //   this.serviceError = true;
    //   this.changeDetectorRef.detectChanges();
    // }
  }

  handleErrors() {
    this.messageService.getServiceError().pipe(takeUntil(this.destroy$)).subscribe((error: any) => {
      this.showPreferenceUpdateError();
      // if (error) {
      //   this.showError(error?.status || error?.code);
      // }
    });
  }

  showPreferenceUpdateError() {
    this.serviceException.imageClass = getErrorImageClass(this.digitalInboxWidgetItem.confirmPreference.preferenceUpdateException.errorImageType.toString());
    this.serviceException.errorInfoText = this.digitalInboxWidgetItem.confirmPreference.preferenceUpdateException.errorInfoText;
    this.serviceException.buttonText = this.digitalInboxWidgetItem.confirmPreference.preferenceUpdateException.buttonText;
    this.serviceException.componentUri = this.digitalInboxWidgetItem.confirmPreference.preferenceUpdateException.componentUri;
    this.messageService.setServiceException(this.serviceException);
    this.router.navigate(['/payments/service-exception']);
  }

}