<section id="br-getdetails-vehicle" *ngIf="balloonRefinanceGetdetailsVehicleItem$ | async as brGetdetailsVehicleItem">
    <div role="alert" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <wss-page-title [pageTitle]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsTitle.page1Title" [pageName]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsTitle.step1Title">
    </wss-page-title>
    <section class="content-width-max"> 
        <div class="mt-25px-mb-30px" test_id="brvTextAfterPageTitle"> 
            <bb-content-html [html]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.textAfterPageTitle"></bb-content-html>
        </div>
        <form [formGroup]="vehicleDetailsForm">
        <div class="card p-20px mb-10px">
            <div class="card-content text-left mb-15px section-sub-title" test_id="brvSectionAccountTitle">
                {{brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.sectionAccountTitle}}              
            </div>
            <wss-infor-tool 
                [idExpand]="idExpandArr[0]"    
                [idCollapse]="idCollapseArr[0]"   
                [idInforPanel]="idPanelArr[0]"                
                [lblContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.vehicleMake"
                [lblInforContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsTitle.inforText"
                [inforContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.brvInformationMake"
                [defaultTopMargin]="defaultTopMargin">                
            </wss-infor-tool>
            <div class="mt-15px">
                <input type="text" class="form-control wss-form-input-normal mb-0" id="vehicleMake" value="" formControlName="vehicleMake" tabindex="-1" readonly aria-label="Vehicle make">
            </div>
            <wss-infor-tool 
                [idExpand]="idExpandArr[1]"    
                [idCollapse]="idCollapseArr[1]"   
                [idInforPanel]="idPanelArr[1]"                
                [lblContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.vehicleModel"
                [lblInforContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsTitle.inforText"
                [inforContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.brvInformationModel">                
            </wss-infor-tool>
            <div class="mt-15px"> 
                <input type="text" class="form-control wss-form-input-normal mb-0" id="vehicleModel" value="" formControlName="vehicleModel" tabindex="-1" readonly aria-label="Vehicle model"> 
            </div>
            <wss-infor-tool
                [idExpand]="idExpandArr[2]"    
                [idCollapse]="idCollapseArr[2]"   
                [idInforPanel]="idPanelArr[2]"                
                [lblContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.vehicleRegistration"
                [lblInforContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsTitle.inforText"
                [inforContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.brvInformationReg">                
            </wss-infor-tool>
            <div class="mt-15px">
                <input type="text" class="form-control wss-form-input-normal mb-0" id="vehicleReg" value="" formControlName="vehicleReg" tabindex="-1" readonly aria-label="Vehicle registration"> 
            </div>
            <div class="mt-20px pb-25px br-btm-grey">
                {{brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.textGetInTouch}} <a href="javascript:void(0)" (click)="toGetinTouch()" id="wss-Brg-Vehicle-Gettouch-Link">{{brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.linkGetInTouch}}</a>                
            </div>
                <div test_id="extraDetailsHeader" class="mt-20px mb-15px section-sub-title">
                    {{brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.extraDetailsHeader}}
                </div>
                <div class="w-100">
                    <wss-infor-tool
                        [idExpand]="idExpandArr[3]"
                        [idCollapse]="idCollapseArr[3]"
                        [idInforPanel]="idPanelArr[3]"
                        [lblContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.currentMileageQuestion"
                        [lblInforContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsTitle.inforText"
                        [inforContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.brvInformationMileage"
                        [defaultTopMargin]="defaultTopMargin">                
                    </wss-infor-tool>
                    <input type="number" class="form-control wss-form-input-small mb-0 mt-20px" id="currentMileage" formControlName="mileage" placeholder="{{brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.mileagePlaceholder}}" aria-label="Input your current mileage">
                    <div class="mb-p-0 mt-5px text-danger" *ngIf="this.submitted && vehicleDetailsForm.controls.mileage.errors">
                        <bb-content-html [html]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.currentMileageErrorMessage"></bb-content-html>
                    </div>
                </div>
                <div class="w-100">
                    <wss-infor-tool
                        [idExpand]="idExpandArr[4]"
                        [idCollapse]="idCollapseArr[4]"
                        [idInforPanel]="idPanelArr[4]"
                        [lblContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.vehicleUseQuestion"
                        [lblInforContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsTitle.inforText"
                        [inforContent]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.brvInformationVehicleUse">                
                    </wss-infor-tool>
                    <div class="mt-20px">
                        <div class="d-flex" *ngFor="let item of vehicleUses">
                            <input type="radio" name="vehicle_use" class="mr-2" [id]="item.value" [value]="item.value" [checked]="proposalData.vehicle_use == item.value" formControlName="vehicle_use">
                            <label [for]="item.value">{{ item.title }}</label>
                        </div>
                    </div>
                    <div class="mb-p-0 mt-5px text-danger" *ngIf="this.submitted && vehicleDetailsForm.controls.vehicle_use.errors">
                        <bb-content-html [html]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsVehicle.vehicleUseErrorMessage"></bb-content-html>
                    </div>
                </div>    
            </div>
        </form>
        <div class="mb-40px">
            <wss-common-nav-buttons 
                [content]="brGetdetailsVehicleItem.balloonRefinanceGetdetailsNavBtns"
                [accountsDetails]="accountDetails"
                [previousPageLink]="previousPageLink"
                [lastDateOfDecision]="lastDateOfDecision"
                [testIdText]="commonNavTestID"
                (primaryButtonClicked)="onSaveButtonClick($event)"
                (continueButtonClicked)="onContinue($event)">
            </wss-common-nav-buttons>
        </div>
    </section>
</section>
