import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserDetailService } from '../../services/update-detail.services';
import { MessageService } from '@wss/service/message.service';
import { LoginService } from '@wss/login-widget/src/service/login.service';
import { Observable, Subject } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { headerContentWidgetItem } from '@wss/model/content/update-contact-details';
import { DatastoreService } from '@wss/service/datastore.service';
import { BannerMessage } from '@wss/model/bannerMessage';
import { TealiumUtagService } from '@wss/service/utag.service';
import { takeUntil } from 'rxjs/operators';
import { StorageService } from '@wss/service/storage.service';

@Component({
  selector: 'bb-wss-user-email',
  templateUrl: './user-email.component.html',
  providers: [ContentService]
})
export class UserEmailComponent implements OnInit, OnDestroy {

  @Input()
  disabled = true;
  emailForm: FormGroup;
  errorMsg: string = '';
  successStatus: boolean = false;
  showForm: boolean = true;
  customerDetail: any = this.storageService.getLocalStorage('customerDetail');
  personalDetails : any = {fullname:"",shortName:""};
  brandName: string = window.BB?.config?.portalName;
  loginAttempt = 0;
  validEmailFormat = (/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i); //NOSONAR
  validEmail = false;
  validConfirmEmail = false;
  emailMatch = false;
  showEmailErrors = false;
  showConfirmEmailErrors = false;
  existingEmail = false;
  existingConfirmEmail = false;
  isAccountArchived:boolean = false;
  currentEmail = this.datastoreService.getLoggedInEmail(); 
  readonly destroy$ = new Subject();
  
  private loginService: LoginService;
  private nav: Router;
  authorisation: any;
  bannerMessage: BannerMessage = new BannerMessage();
  errorDetails: boolean = false;
  usernameErrorMsg: string = '';
  defaultErrorMsg: string = '';
  
  headerContentWidgetItem$: Observable<headerContentWidgetItem | undefined> = this.bbContentService.getContent<headerContentWidgetItem>('headerContentWidgetItem');

  headerContentWidgetItem: any;

  ngOnInit() : void {
    this.tealiumService.view(
      {
        JourneyName: 'Header',
        JourneyStepName: 'Update Email',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.getAuthorised();
    this.navigationChange();
    this.headerContentWidgetItem$.subscribe(res => {
      this.headerContentWidgetItem = res;
    });
    this.emailEnteredByUser();
    this.changeDetectorRef.detectChanges();
    this.isAccountArchived = this.datastoreService.getIsAccountArchived();
    
  }

  constructor(
    private formBuilder: FormBuilder, 
    private UserDetaillSrvc: UserDetailService,
    public changeDetectorRef: ChangeDetectorRef,
    loginService: LoginService,
    routerAng: Router,
    private datastoreService: DatastoreService,
    private messageService: MessageService,
    private bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    private storageService: StorageService
  ) {
    this.loginService = loginService;
    this.nav = routerAng;
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.validEmailFormat)]],
      confirm_email: ['', [Validators.required, Validators.pattern(this.validEmailFormat)]],
      password: ['',Validators.required],
    });

  }

  get f() {return this.emailForm.controls;}

  getAuthorised(): void {    
    this.datastoreService.setPwdReset(true);
    this.loginService.getAuthorisation().subscribe((data: any) => {
      this.authorisation = data;
      let token = this.authorisation.headers.get('x-authorization');
      this.setHeaders(token);
    },
    (error: any) => {
    });
  }

  checkIfSubmitButtonCanBeEnabled() {
    if(   this.emailForm.get('password')?.value.length > 0 
        && this.validConfirmEmail 
        && this.validEmail 
        && this.emailForm.get('email')?.value === this.emailForm.get('confirm_email')?.value 
        && this.currentEmail !== this.emailForm.get('email')?.value.toLowerCase()
        && this.currentEmail !== this.emailForm.get('confirm_email')?.value.toLowerCase()
      ){ 
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  emailEnteredByUser() {
    const email = this.emailForm.get('email');
    email?.valueChanges.forEach(
      (value: string) => {
        this.currentEmail = this.datastoreService.getLoggedInEmail().toLowerCase();
        this.existingEmail = (value.toLowerCase() === this.currentEmail) ? true : false;
        if(value && value.length > 2 && this.validEmailFormat.test(value)){
          this.validEmail = true;       
          this.showEmailErrors = false;
        } else {
          this.validEmail = false;
          this.showEmailErrors = true;
        }
        this.checkIfSubmitButtonCanBeEnabled();
      }
    );
    const confirmEmail = this.emailForm.get('confirm_email');
    confirmEmail?.valueChanges.forEach(
      (value: string) => {
        this.currentEmail = this.datastoreService.getLoggedInEmail().toLowerCase();
        this.existingConfirmEmail = (value.toLowerCase() === this.currentEmail) ? true : false;
        if(value && value.length > 2 && this.validEmailFormat.test(value)){
          this.validConfirmEmail = true;
          this.showConfirmEmailErrors = false;
          if(value === this.emailForm.controls['email'].value) {
            this.emailMatch = true;
          }
          else {
            this.emailMatch = false;
            this.showConfirmEmailErrors = true;
          }
        } else {
          this.validConfirmEmail = false;
          this.showConfirmEmailErrors = true;
        }
        this.checkIfSubmitButtonCanBeEnabled();
      }
    );
    const password = this.emailForm.get('password');
    password?.valueChanges.forEach(
      (value: string) => {
        this.checkIfSubmitButtonCanBeEnabled();
      }
    );
  }

  setErrorMsg(errorMessage: string) {
    this.bannerMessage.closeIconClass = 'clear';
    this.bannerMessage.infoTypeClass = 'icon-warning-red wss-additonal-info-icon additonalerror-info-icon mgn-top-15px';
    this.bannerMessage.leftSectionColorClass = 'message-failure';
    this.bannerMessage.titleText = errorMessage;
    this.bannerMessage.isBannerClosed = false;
    this.messageService.setBannerMessage(this.bannerMessage);
  }

  setHeaders(token: any): void {
    this.datastoreService.setToken(token);
  }

  onSubmit() {
    this.errorMsg = '';
    this.usernameErrorMsg =  this.headerContentWidgetItem?.updateEmail?.emailInUseBannerMessage;
    this.defaultErrorMsg =  this.headerContentWidgetItem?.updateEmail?.incorrectLoginDetailsBannerMessage;
    
    let changeEmailDetails: any = {};
    changeEmailDetails['oldUsername'] = this.datastoreService.getLoggedInEmail().toLowerCase();
    changeEmailDetails['password'] = btoa(this.emailForm.value.password);
    changeEmailDetails['username'] = this.emailForm.value.email.toLowerCase();

    this.errorDetails = false;
    /* Call API to Change Username */

    this.UserDetaillSrvc.updateEmailAlias(changeEmailDetails).subscribe(
      (r) => {
        this.showForm = false;
        this.errorDetails = false;
        this.successStatus = true;
        this.updateEmailInMainFrame(changeEmailDetails.username)
        this.datastoreService.setUserName(changeEmailDetails.username);
        this.datastoreService.setLoggedInEmail(changeEmailDetails.username);
        this.emailForm.reset({});
        this.changeDetectorRef.markForCheck();
      },
      (error: any) => {
        this.successStatus = false;
        switch (error.error.code) {
          case '002':
            let errLockMsg = error.error.reason_code.indexOf('Account is locked');
            let errRecordMsg = error.error.reason_code.indexOf('Record not found');
            let errFailedMsg = error.error.reason_code.indexOf('Already failed');
            let errNoUserMsg = error.error.reason_code.indexOf('find an user for the alias');
            let errWSSUserNotFoundMsg = error.error.reason_code.indexOf('WSS user not found');
            let errUserExistsMsg = error.error.reason_code.indexOf('logon already exists');
            if (errLockMsg !== -1) {
              this.logout();
              location.href = 'login#/change-email-max-attempts';
            } else if (errRecordMsg !== -1) {
              this.nav.navigate(['account-locked']);
            } else if (errNoUserMsg !== -1 || errWSSUserNotFoundMsg !== -1) {
              this.errorDetails = true;
              this.setErrorMsg(this.defaultErrorMsg);
              this.changeDetectorRef.detectChanges();
            } else if (errFailedMsg !== -1) {
              this.errorDetails = true;
              this.setErrorMsg(this.defaultErrorMsg);
              this.changeDetectorRef.detectChanges();
            } else if (errUserExistsMsg !== -1) {
              this.errorDetails = true;
              this.setErrorMsg(this.usernameErrorMsg);
              this.changeDetectorRef.detectChanges();
            }
              else {
              this.errorDetails = true;
              this.setErrorMsg(this.defaultErrorMsg);
              this.changeDetectorRef.detectChanges();
            };
            break;
          case '001':
            this.nav.navigate(['no-user']);
            break;
          case '1015':
            this.logout();
            this.nav.navigate(['attempts-exceeded']);
            break;
          case '1018':
            this.errorDetails = true;
            this.setErrorMsg(this.usernameErrorMsg);
            this.changeDetectorRef.detectChanges();
            break;
          default:
            this.errorDetails = true;
            this.setErrorMsg(this.defaultErrorMsg);
            this.changeDetectorRef.detectChanges();
        }
        this.messageService.setBannerMessage(this.bannerMessage);
      },
    );
  }

  updateEmailInMainFrame(newEmail: string) {
    this.UserDetaillSrvc.UpdateContact({email_address: newEmail}).pipe(takeUntil(this.destroy$)).subscribe((res:any)=> {
      this.confirmIsEmailUpdated(true, newEmail);
    }, (error: any) => {
      this.confirmIsEmailUpdated(false, newEmail);
    })
  }

  confirmIsEmailUpdated(isEmailUpdated: boolean, newEmail: string) {
    let loginResponse: any = this.datastoreService.getCustomerLogin();
    loginResponse["alias"] = newEmail;
    loginResponse["date_Email_Updated"] = new Date().toISOString();
    loginResponse["is_Email_Updated"] = isEmailUpdated;
    loginResponse['marketing_Display'] = (Number(loginResponse["marketing_Display"]) + 1).toString();
    this.loginService.UpdateUserDetails(loginResponse).pipe(takeUntil(this.destroy$)).subscribe();
  }

  logout() {
    this.datastoreService.sessionLogin();
  }

  navigationChange() {
    this.messageService.getNotify().subscribe(res => {
      this.emailForm.reset({});
      this.successStatus = false;
      this.showForm = true;
    });
  }

  backToMyAccountClick() {
    location.href = '#/myaccount';
  }
  backToMailBoxClick() {
    location.href = '#/mailbox';
  }

  ngOnDestroy() {    
    this.destroy$.next();
    this.destroy$.complete();
  }
}
