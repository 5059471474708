import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { getDeviceType } from '@wss/common/util/util';
import { DatastoreService } from '@wss/service/datastore.service';

@Component({
  selector: 'wss-common-nav-buttons',
  templateUrl: './common-nav-buttons.component.html'
})
export class CommonNavButtonsComponent implements OnInit {

  deviceType: string = 'Mobile';
  primaryButtonTestId: string = "";
  secondaryButtonTestId: string = "";
  linkTextId: string = "";
  modalHeaderTestId: string = "";
  modalFirstParagraphTestId: string = "";
  modalSecondParagraphTestId: string = "";
  modalConfirmTestId: string = "";
  modalPrimaryButtonTestId: string = "";
  modalSecondaryButtonTestId: string = "";
  isBrReturnJourney: boolean = false;
  currentPage: any;

  @Input() content: any;
  @Input() previousPageLink: string = '';
  @Input() accountsDetails: any;
  @Input() lastDateOfDecision: any;
  @Input() testIdText: string = '';
  @Input() isPrimaryDisabled: boolean = false;
  @Input() isAgreementPage: boolean = false;
  @Input() isQuoteReturnJourney: boolean = false;
  @Input() isWithin14To6DaysOfExpiryDate: boolean = false;
  @Input() isSecondaryDisabled: boolean = false;
  @Input() lastDateToSignAgreement: any;
  @Input() saveAndReviewBtnDisable: boolean = false;

  @Output('primaryButtonClicked') primaryButtonClicked = new EventEmitter<any>();
  @Output('continueButtonClicked') continueButtonClicked = new EventEmitter<any>();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.deviceType = getDeviceType();
  }
  constructor(
    private modalService: NgbModal,
    private datastore: DatastoreService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
    ) { 
      this.datastore.brCurrentPageData$.subscribe((res: any) => {
        this.currentPage = res;
      })
    }

  ngOnInit(): void {
    this.deviceType = getDeviceType();
    this.setTestId();
    this.datastore.brReturnJourney$.subscribe((res: any) => {
      this.isBrReturnJourney = res;
      this.changeDetectorRef.detectChanges();
    })
  }

  openModal(modalContent: any) {
    this.modalService.open(modalContent, { centered: this.deviceType === 'Mobile' ? false : true, windowClass: this.isAgreementPage && this.isWithin14To6DaysOfExpiryDate ? 'agreement-modal' : 'eoc-modal' });
  }

  onPrimaryButtonOnModalClick() {
    this.primaryButtonClicked.emit('from common nav')
  }

  onContinue() {
    this.continueButtonClicked.emit();
  }

  setTestId() {
    this.primaryButtonTestId = this.testIdText + 'PrimaryButton';
    this.secondaryButtonTestId = !this.isWithin14To6DaysOfExpiryDate ? this.testIdText + 'SecondaryButton' : this.testIdText + '-DoesNotWishToProceedButton'
    this.linkTextId = this.testIdText + 'Backlink';
    this.modalHeaderTestId = this.testIdText + 'ModalHeader';
    this.modalFirstParagraphTestId = this.testIdText + 'ModalFirstParagraph';
    this.modalSecondParagraphTestId = this.testIdText + 'ModalSecondParagraph';
    this.modalConfirmTestId = this.testIdText + 'ModalConfirm';
    this.modalPrimaryButtonTestId = !this.isWithin14To6DaysOfExpiryDate ? this.testIdText + 'ModalPrimaryButton' : this.testIdText + 'ModalExitToEocOptionsButton';
    this.modalSecondaryButtonTestId = this.testIdText + 'ModalSecondaryButton';
  }

  onCancel() {
    this.router.navigate(['/balloon-refinance-getdetails/summary']);
  }

  onSaveandReview() {
    this.router.navigate(['/balloon-refinance-getdetails/summary']);
  }

}
