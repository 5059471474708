<section class="budget-page custom-form content-margin" *ngIf="budgetFormItem$ | async as budgetFormItem">
     <div class="content-wrapper-container">
     <div class="d-flex section-title-container">
          <span class="section-title" test_id="eomPageHeader">
              {{budgetFormItem.eom.pageName}}
          </span>
     </div>
     <div class="card-box half-width">
          <bb-wss-summary  [budgetSummaryData]="budgetSummaryData" [budgetFormItem]="budgetFormItem"></bb-wss-summary>
          <div class="padding-all-side">
               <p><span><bb-content-html [html]="budgetFormItem.eom.thanksMessagetText"></bb-content-html></span></p>
          </div>
     </div>
     <div class="margin-top-50">
          <div class="title-header">
               <h3><strong>
                    {{budgetFormItem.eom.summaryHeadingText}}
               </strong></h3>
          </div>
          <div class="container-column summary-grid half-width margin-top-10">
               <div class="container-row summary-grid-row odd">
                    <span>{{budgetFormItem.eom.incomeRowText}}</span>
                    <span  >£{{budgetSummaryData.income ? budgetSummaryData.income : 0 }}</span>
                
               </div>
               <div class="container-row summary-grid-row even">
                    <span>{{budgetFormItem.eom.rentOrMortgageRowtext}}</span>
                    <span >£{{budgetSummaryData.rent_motgage ? budgetSummaryData.rent_motgage : 0}}</span>
                  
               </div>
               <div class="container-row summary-grid-row odd">
                    <span>{{budgetFormItem.eom.billsRowText}}</span>
                    <span >£{{budgetSummaryData.household_bills ? budgetSummaryData.household_bills : 0}}</span>
                   
               </div>
               <div class="container-row summary-grid-row even">
                    <span>{{budgetFormItem.eom.livingCostsRowText}}</span>
                    <span>£{{budgetSummaryData.living_costs ? budgetSummaryData.living_costs : 0 }}</span>
                   
               </div>
               <div class="container-row summary-grid-row odd">
                    <span>{{budgetFormItem.eom.paymentsRowText}}</span>
                    <span *ngIf="budgetSummaryData.finance_payments || budgetSummaryData.finance_payments != 'NaN'">£{{budgetSummaryData.finance_payments}}</span>
                    <span *ngIf="!budgetSummaryData.finance_payments">£0</span>
               </div>
           </div>
      </div>
      <div class="margin-top-50">
          <p><span><bb-content-html [html]="budgetFormItem.eom.textBeforeCallButton"></bb-content-html></span></p>
          <span class="contact-number-text margin-top-50">
               <span class="cfa-call-icon"></span>
               <bb-content-html [html]="budgetFormItem.eom.callUsOn"></bb-content-html>
               <strong><bb-content-html *ngIf= "accountDetails.is_vulnerable" [html]="budgetFormItem.eom.vulnerableCustomerContact"></bb-content-html></strong>
               <strong><bb-content-html *ngIf= "!accountDetails.is_vulnerable" [html]="budgetFormItem.brandContactDetails.brandWiseCfaNumber"></bb-content-html></strong>
          </span>
          <ul class="margin-top-10">
               <li>
                    <bb-content-html [html]="budgetFormItem.eom.ifDontCallUs"></bb-content-html>
               </li>
               <li>
                    <bb-content-html [html]="budgetFormItem.eom.contactLineAvailableTimeText"></bb-content-html>
               </li>
               <li>
                    <span class="para-inline margin-top-10">
                         <bb-content-html [html]="budgetFormItem.eom.callUsOnTRSText"></bb-content-html>
                         <strong><bb-content-html *ngIf= "accountDetails.is_vulnerable" [html]="budgetFormItem.eom.vulnerableCustomerFreeTRSNumber"></bb-content-html></strong>
                         <strong><bb-content-html *ngIf= "!accountDetails.is_vulnerable" [html]="budgetFormItem.brandContactDetails.brandWiseFreeTRSNumber"></bb-content-html></strong>	
                    </span>
               </li>
          </ul>
     </div>
     <div class="margin-top-20">
          <span><bb-content-html [html]="budgetFormItem.eom.backToHome"></bb-content-html></span>
     </div>
     <div class="margin-top-50">
          <div class="title-header margin-top-50">
               <h3><strong>
                    <bb-content-html [html]="budgetFormItem.eom.independentHelpText"></bb-content-html>
               </strong></h3>
          </div>
          
          <div class="margin-top-20">
               <bb-content-html [html]="budgetFormItem.eom.independentHelpContent"></bb-content-html>
          </div>
          <div class="transparent-accordion">
             <mat-accordion class="accordion mat-accordion-override margin-top-10">
               <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                    <mat-expansion-panel-header>
                         <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="helpCentreMoneyWorriesfirstAccordionOptionOneTitle">
                              <bb-content-html [html]="budgetFormItem.eom.firstAccordionOptionOneTitle"></bb-content-html>
                         </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p test_id="helpCentreMoneyWorriesfirstAccordionOptionOneContent"><bb-content-html [html]="budgetFormItem.eom.firstAccordionOptionOneContent"></bb-content-html></p>
               </mat-expansion-panel>
          
               <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                    <mat-expansion-panel-header>
                         <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="helpCentreMoneyWorriesfirstAccordionOptionTwoTitle">
                              <bb-content-html [html]="budgetFormItem.eom.firstAccordionOptionTwoTitle"></bb-content-html>
                         </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p test_id="helpCentreMoneyWorriesfirstAccordionOptionTwoContent"><bb-content-html [html]="budgetFormItem.eom.firstAccordionOptionTwoContent"></bb-content-html></p>
               </mat-expansion-panel>
          
               <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                    <mat-expansion-panel-header>
                         <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="helpCentreMoneyWorriesfirstAccordionOptionThreeTitle">
                              <bb-content-html [html]="budgetFormItem.eom.firstAccordionOptionThreeTitle"></bb-content-html>
                         </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p test_id="helpCentreMoneyWorriesfirstAccordionOptionThreeContent"><bb-content-html [html]="budgetFormItem.eom.firstAccordionOptionThreeContent"></bb-content-html></p>
               </mat-expansion-panel>
          
               <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                    <mat-expansion-panel-header>
                         <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="helpCentreMoneyWorriesfirstAccordionOptionFourTitle">
                              <bb-content-html [html]="budgetFormItem.eom.firstAccordionOptionFourTitle"></bb-content-html>
                         </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p test_id="helpCentreMoneyWorriesfirstAccordionOptionFourContent"><bb-content-html [html]="budgetFormItem.eom.firstAccordionOptionFourContent"></bb-content-html></p>
               </mat-expansion-panel>
          </mat-accordion>
       </div>
     </div>
     </div>
</section>
