import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { patternMap } from '@wss/config/wss-app-constants';

@Directive({
  selector: '[fiveDigitValidation]',
})
export class FiveDigitDirective {
  @Input('fiveDigitValidation') fiveDigitValidation: any;
  @Input('indexVal') indexVal: any;
  numberWithtwoDecimalRegExp: RegExp = new RegExp(patternMap.amountwithtwodecimalPattern);

  constructor(private fg: FormGroupDirective) {
    this.fg = fg;
  }

  @HostListener('blur') OnBlur() {
    let formGroup: any = this.fg.form.controls[this.fiveDigitValidation];
    if (this.indexVal) {
      formGroup = formGroup.controls[this.indexVal];
    }

    if (formGroup.controls['frequency'].value && formGroup.controls['amount'].value && !(formGroup.controls['amount'].errors && formGroup.controls['amount'].errors.pattern)) {
      if(this.fg.form.value.current_page === "2") {
        this.checkFiveDigitValidationForAmount(
          formGroup,
          formGroup.controls['frequency'].value,
          formGroup.controls['amount'].value,
        );
      } else {
        this.checkFiveDigitValidationForNonAmountPage(
          formGroup,
          formGroup.controls['frequency'].value,
          formGroup.controls['amount'].value,
        );
      }
    }
  }

  checkFiveDigitValidationForNonAmountPage(formGroup: any, frequency: string, amountVal: any) {
    switch (frequency) {
      case 'Monthly':
        if (Math.round(amountVal).toString().length > 5) {
          this.setErrorOnFail(formGroup);
          break;
        }
        this.unsetErrorOnSuccess(formGroup);
        break;
      case '4 weekly':
        if (Math.round((amountVal * 13) / 12).toString().length > 5) {
          this.setErrorOnFail(formGroup);
          break;
        }
        this.unsetErrorOnSuccess(formGroup);
        break;
      case 'Weekly':
        if (Math.round((amountVal * 52) / 12).toString().length > 5) {
          this.setErrorOnFail(formGroup);
          break;
        }
        this.unsetErrorOnSuccess(formGroup);
        break;
      case 'Annually':
        if (Math.round(amountVal / 12).toString().length > 5) {
          this.setErrorOnFail(formGroup);
          break;
        }
        this.unsetErrorOnSuccess(formGroup);
        break;
      case 'Fortnightly':
        if (Math.round((amountVal * 26) / 12).toString().length > 5) {
          this.setErrorOnFail(formGroup);
          break;
        }
        this.unsetErrorOnSuccess(formGroup);
        break;
    }
  }

  checkFiveDigitValidationForAmount(formGroup: any, frequency: string, amountVal: any) {
    switch (frequency) {
      case 'Monthly':
        if (Math.round(amountVal).toString().length > 5) {
          this.setErrorOnFail(formGroup);
          break;
        }
        this.unsetErrorOnSuccess(formGroup);
        break;
      case '4 weekly':
        if (Math.round(amountVal).toString().length > 5) {
          this.setErrorOnFail(formGroup);
          break;
        }
        this.unsetErrorOnSuccess(formGroup);
        break;
      case 'Weekly':
        if (Math.round(amountVal * 4).toString().length > 5) {
          this.setErrorOnFail(formGroup);
          break;
        }
        this.unsetErrorOnSuccess(formGroup);
        break;
      case 'Annually':
        if (Math.round(amountVal / 12).toString().length > 5) {
          this.setErrorOnFail(formGroup);
          break;
        }
        this.unsetErrorOnSuccess(formGroup);
        break;
      case 'Fortnightly':
        if (Math.round(amountVal * 2).toString().length > 5) {
          this.setErrorOnFail(formGroup);
          break;
        }
        this.unsetErrorOnSuccess(formGroup);
        break;
    }
  }

  setErrorOnFail(formGroup: any) {
    formGroup.controls['amount'].setValidators([this.fiveDigitValidator(), Validators.required , Validators.pattern(this.numberWithtwoDecimalRegExp)]);
    formGroup.controls['amount'].updateValueAndValidity();
  }

  unsetErrorOnSuccess(formGroup: any) {
    if(formGroup.controls['amount'].errors && formGroup.controls['amount'].errors.fiveDigitError) {
      formGroup.controls['amount'].setValidators([Validators.required , Validators.pattern(this.numberWithtwoDecimalRegExp)]);
      formGroup.controls['amount'].updateValueAndValidity();
    }
  }

  fiveDigitValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return { fiveDigitError: true };
    };
  }
}