import {ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy} from '@angular/core';
import {User} from '../../../../registration-store/src/user';
import {Observable, Subject} from "rxjs";
import {ItemModel, RouterService} from "@backbase/foundation-ang/core";
import {RegistrationStoreModelService} from "../../../../registration-store/src/registration-store-model.service";
import {ContentService as OldContentService} from "../../../../service/content.service";
import {ContentData, WSSAppConstant} from '../../../../config/wss-app-constants';
import {RegisterDatastoreService} from '../../service/register-datastore.service';
import {RequestService} from '../../../../service/request.service';
import {takeUntil} from 'rxjs/operators';
import {isNotEmpOrNullOrUndef} from '../../../../common/util/util';
import { RegistrationWidgetItem } from '@wss/model/registrationToken';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-ws-user-detail',
  templateUrl: './user-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ContentService]
})

export class UserDetailsComponent implements OnInit, OnDestroy {
  users: Observable<Array<User> | undefined> = this.registrationService.users;
  firstname: string = '';
  initials: string = '';
  mobileNo: string = ''; 
  mobileValid: boolean = false;

  readonly destroy$ = new Subject();

  registrationWidgetItem$: Observable<RegistrationWidgetItem | undefined> = this.bbContentService.getContent<RegistrationWidgetItem>('registrationWidgetItem');

  ngOnInit() {
    this.tealiumService.view(
      {
        JourneyName: 'Registration',
        JourneyStepName: 'User Details',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();  
    this.destroy$.complete();    
  }

  constructor(
    private itemModel: ItemModel,
    private registrationService: RegistrationStoreModelService,
    private router: RouterService,
    private contentService: OldContentService,
    private registerstoreService: RegisterDatastoreService,
    private requestService: RequestService,
    private tealiumService: TealiumUtagService,
    private readonly bbContentService: ContentService
  ) {   
    this.itemModel.property('userDetailsTitle').pipe(takeUntil(this.destroy$)).subscribe(value => this.registrationService.updateTitle(value as string));
    this.registrationService.updatedStepper(1);
    this.firstname = this.registerstoreService.getFirstNameUser()?.toLowerCase();
    this.initials = this.registerstoreService.getInitialsUser();
    this.mobileNo = this.registerstoreService.getMobileUser();
    if(isNotEmpOrNullOrUndef(this.mobileNo)) {
      this.mobileValid = true;
    }
    this.setReqOtp();
  }

  setReqOtp(): void{
    this.requestService.setReqSendOptions();   
  }

  getVerify(verify:string, uri:string){
    this.registerstoreService.setVerifyType(verify);
    this.router.navigate([uri]);
  }  
}
