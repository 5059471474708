<div class="create-account-faq-container" *ngIf="registrationWidgetItem$ | async as registrationWidgetItem">
    <div class="container-column">
        <div class="create-account-faq-title">
            <span class="section-label-title" test_id="create_account_faq_label_id">
                {{registrationWidgetItem.createAccountFaq.pageName}}</span>
            <hr>
        </div>
        <div class="customer-support-text mt-5px">
            <div class="faq-header-text contact-number-text" test_id="pageHeaderBelowText">
                <span><bb-content-html [html]="pageHeaderText?.pageHeaderText"></bb-content-html></span>
            </div>
            
            <div class="margin-top-30">
                <div class="create-account-faq container-row margin-top-30">
                <!-- Dynamic accordion -->
                <bb-common-expansion-panel [accordionContent]="acordanceContent" [testIDText]="'createAccountFaqAccordion'"></bb-common-expansion-panel>
                </div>
            </div>
        </div>
    </div>
</div>
