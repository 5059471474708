<div class="container-column" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
    <div class="change-payment-container">
        <div class="container-row" *ngIf="serviceError">
            <bb-wss-banner-message></bb-wss-banner-message>
        </div>
        <div class="container-column">
            <div class="container-row section-title-container">
                <span class="section-title" test_id="changePaymentPageName">
                    <!-- {{content[0]?.cpd}} -->
                    {{paymentsWidgetItem.changePayment.changePaymentPageName}}
                </span>
            </div>
            <span class="mt-20px para-inline" *ngIf="accountDetail?.scheme_code === typeHP" test_id="changePaymentHPNoteText">
                <!-- {{content[0]?.hpnote}} -->
                <bb-content-html [html]="paymentsWidgetItem.changePayment.changePaymentHPNoteText">
                </bb-content-html>
            </span>
            <span class="mt-20px para-inline" *ngIf="accountDetail?.scheme_code === typePCP" test_id="changePaymentPCPNoteText">
                <!-- {{content[0]?.pcpnote}} -->
                <bb-content-html [html]="paymentsWidgetItem.changePayment.changePaymentPCPNoteText">
                </bb-content-html>
            </span>
            <div class="mt-20px" test_id="nextPayment">
                <bb-wss-next-payment [nextPaymentContent]="paymentsWidgetItem.changePayment.nextPayment"
                    [accountDetail]="accountDetail" [nextPaymentData]="nextPaymentData">
                </bb-wss-next-payment>
            </div>
            <form [formGroup]="changeDateForm" class="mt-20px change-password-wrapper" autocomplete="off">
                <div class="container-column form-group">
                    <label test_id="newPaymentDateSelectionText" class="para-inline">
                        <!-- {{content[0]?.selectnewpd}} -->
                        <bb-content-html [html]="paymentsWidgetItem.changePayment.newPaymentDateSelectionText">
                        </bb-content-html>
                    </label>
                    <input formControlName="paymentDate" type="text" class="form-control"
                        placeholder="{{paymentsWidgetItem.changePayment.selectADayPlaceHolderText}}" />
                    <!-- {{content[0]?.phselectdate}} -->
                    <span class="wss-calender-today-icon calendar-today-icon" (click)="openDatepicker(datepickerhtml)">
                    </span>
                </div>
            </form>
            <div class="container-row mt-20px">
                <button id="change-payment-btn" role="button" tabindex="0" class="wss-button" test_id="selectDateButtonText"
                    [disabled]="!changeDateForm.valid || !isFormDateValid()" (click)="navigateToConfirm()">
                    <!-- {{content[0]?.selectpd}} -->
                    {{paymentsWidgetItem.changePayment.selectDateButtonText}}
                </button>
                <!-- <button *ngIf="!changeDateForm.valid || !isFormDateValid()" class="wss-button btn-disabled">{{content[0]?.selectpd}}</button> -->
            </div>
            <div class="container-row direct-debit-short-note mb-110px">
                <!-- <span class="wss-additonal-info-icon"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path fill="#333333" [attr.d]="svgInfoIcon" />
                    </svg>
                </span> -->
                <span> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path fill="#333333" [attr.d]="svgInfoIcon" />
                    </svg>
                </span>
                <span test_id="paymentDateChangeInfoText" class="para-inline">
                    <!-- {{content[0]?.plsbeaware}} <a role="link" tabindex="0"
                        title="{{content[0]?.cpdfaq}}">{{content[0]?.cpdfaq}}</a>. -->
                    <bb-content-html [html]="paymentsWidgetItem.changePayment.paymentDateChangeInfoText">
                    </bb-content-html>
                </span>
            </div>
        </div>
    </div>

    <ng-template #datepickerhtml let-modal>
        <div class="container-column request-statement-modal">
            <div class="container-row modal-header-c">
                <span class="heading para-inline" test_id="selectDateLabelText">
                    <!-- {{content[0]?.cdate}} -->
                    <bb-content-html [html]="paymentsWidgetItem.changePayment.selectDateLabelText">
                    </bb-content-html>
                </span>
                <span class="material-icons" (click)="modal.dismiss('Cross click');">clear</span>
            </div>
            <div class="container-row modal-body-c">
                <ng-container *ngFor="let day of days;let i = index;">
                    <div [ngClass]="day.selected === true? 'day active': 'day'" (click)="changeDate(day)">
                        <span>{{day.day}}</span>
                    </div>
                </ng-container>

            </div>
            <div class="container-row direct-debit-short-note" *ngIf="checkForLastDates()">
                <span>
                    <!-- <span class="wss-additonal-info-icon"></span> -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path fill="#333333" [attr.d]="svgInfoIcon" />
                    </svg>
                </span>
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path fill="#333333" [attr.d]="svgInfoIcon" />
                    </svg> -->
                <span test_id="dateSelectionInfoText" class="para-inline">
                    <!-- {{content[0]?.somemnths}}. -->
                    <bb-content-html [html]="paymentsWidgetItem.changePayment.dateSelectionInfoText">
                    </bb-content-html>
                </span>
            </div>
            <div class="container-row modal-footer-c">
                <div class="back-to-direct-debit" (click)="modal.dismiss('Cross click');">
                    <a role="link" title="{{paymentsWidgetItem.changePayment.cancelButtonText}}" tabindex="0">
                        <!-- {{content[0]?.cancel}} -->
                        {{paymentsWidgetItem.changePayment.cancelButtonText}}
                    </a>
                </div>
                <div class="select">
                    <!-- <span class="wss-button"  (click)="modal.dismiss('Cross click');selectDate()">Select</span> -->
                    <button role="button" tabindex="0" class="wss-button" [disabled]="!checkForSelectEnable()"
                        (click)="modal.dismiss('Cross click');selectDate()" test_id="selectButtonText">
                        <!-- {{content[0]?.select}} -->
                        {{paymentsWidgetItem.changePayment.selectButtonText}}
                    </button>
                    <!-- <button *ngIf="" class="wss-button btn-disabled">{{content[0]?.select}}</button> -->
                </div>
            </div>
        </div>
    </ng-template>
</div>