<section class="custom-form budget-home" *ngIf="helpCentreWidgetItem$ | async as helpCentreWidgetItem">
    <div class="content-wrapper-container">
        <div class="container-column mx-3">
            <div class="d-flex section-title-container">
                <span class="section-title" test_id="budgetHomepageName">
                    {{helpCentreWidgetItem.budgetHomeDetail.pageName}}</span>
            </div>
            <div class="customer-support-text mt-4">
                <div test_id="budgetHomeHeaderBelowText">
                    {{ helpCentreWidgetItem.budgetHomeDetail.pageHeaderBelowText}}
                </div>
                <div class="mt-4">
                    <div class="title-header" test_id="budgetHomeSubHeaderText">
                        <h5>
                            <strong>
                                {{helpCentreWidgetItem.budgetHomeDetail.keepInMindSubHeadingText}}
                            </strong>
                        </h5>
                    </div>
                    <div class="mt-4 bullet-point" test_id="budgetHomefirstHeaderTextBelow">
                        <bb-content-html [html]="helpCentreWidgetItem.budgetHomeDetail.keepInMindBulletListHTML">
                        </bb-content-html>
                    </div>

                    <div class="container-row content-center margin-top-20">
                        <button class="wss-button btn btn-dif-view" test_id="budgetHomeButtonText"
                            (click)="startBudgetForm()">
                            {{ helpCentreWidgetItem.budgetHomeDetail.continuebuttonText}}
                        </button>
                    </div>
                    <div class="container-row content-center margin-top-20">
                        <a test_id="budgetHomeBackButtonText" (click)="backButtonToMoneyworries()"
                            class="text-center text-sm-right">
                            {{helpCentreWidgetItem.budgetHomeDetail.backbuttonText}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>