<div class="container-step common-stepper">
    <ng-template [ngIf]="currentStep && totalSteps <= 3">
      <bb-stepper-ui [className]="'stepper-ui'">
        <bb-stepper-step-ui
          [isActive]="currentStep >= 1"
          [isChecked]="currentStep > 1"
          [isCurrent]="currentStep == 1"
          ariaLabel="Step 1 out of 3"
          label="{{stepperLabel[0]}}"
          class="stepper-label-position" 
        >
          <bb-header-ui label="hello"></bb-header-ui>
        </bb-stepper-step-ui>
        <bb-stepper-step-ui
          [isActive]="currentStep >= 2"
          [isChecked]="currentStep > 2"
          [isCurrent]="currentStep == 2"
          ariaLabel="Step 2 out of 3"            
          label="{{stepperLabel[1]}}"
        >
        </bb-stepper-step-ui>
        <bb-stepper-step-ui
          [isActive]="currentStep >= 3"
          [isChecked]="currentStep > 3"
          [isCurrent]="currentStep == 3"
          ariaLabel="Step 3 out of 3"
          label="{{stepperLabel[2]}}"
        ></bb-stepper-step-ui>
      </bb-stepper-ui>
    </ng-template>
    <ng-template [ngIf]="currentStep && totalSteps > 3">
      <bb-stepper-ui [className]="'stepper-ui'">
        <bb-stepper-step-ui
          [isActive]="currentStep >= 1"
          [isChecked]="currentStep > 1"
          [isCurrent]="currentStep == 1"
          ariaLabel="Step 1 out of 4"
          label="{{stepperLabel[0]}}"
          class="stepper-label-position" 
        >
          <bb-header-ui label="hello"></bb-header-ui>
        </bb-stepper-step-ui>
        <bb-stepper-step-ui
          [isActive]="currentStep >= 2"
          [isChecked]="currentStep > 2"
          [isCurrent]="currentStep == 2"
          ariaLabel="Step 2 out of 4"            
          label="{{stepperLabel[1]}}"
        >
        </bb-stepper-step-ui>
        <bb-stepper-step-ui
          [isActive]="currentStep >= 3"
          [isChecked]="currentStep > 3"
          [isCurrent]="currentStep == 3"
          ariaLabel="Step 3 out of 4"
          label="{{stepperLabel[2]}}"
        ></bb-stepper-step-ui>
        <bb-stepper-step-ui
          [isActive]="currentStep >= 4"
          [isChecked]="currentStep > 4"
          [isCurrent]="currentStep == 4"
          ariaLabel="Step 4 out of 4"
          label="{{stepperLabel[3]}}"
        ></bb-stepper-step-ui>
      </bb-stepper-ui>
    </ng-template>
</div>