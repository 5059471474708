import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopoverService {
  constructor() { }

  private state: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getState(): Observable<boolean> {
    return this.state.asObservable();
  }

  setState(val: boolean) {
    return this.state.next(val);
  }
}
