<ng-template #contactPreferenceActionPopUp let-modal aria-describedby="contactPreferencePopupContent">
    <div class="contact-preference-popup">
        <div class="container-column">
            <div class="flex-row">
                <div class="text-semibold">
                    {{contactPreferencePopupContent.contactPreferencePopupTitleText}}                    
                </div>
                <div>
                    <span class="material-icons" (click)="onModalClose()">clear</span>
                </div>
            </div>
            <div class="container-column justify-content-center" test_id="contactPreferencePopupBodyText">
                <bb-content-html [html]="contactPreferencePopupContent.contactPreferencePopupBodyText">
                </bb-content-html>
            </div>
            <div class="container-column">
                <button class="wss-button" data-dismiss="modal" aria-label="Close" (click)="updateChoices()"
                    test_id="contactPreferencePopupButtonText">
                    {{contactPreferencePopupContent.contactPreferencePopupButtonText}}
                </button>
                <span class="text-center">
                    <a data-dismiss="modal" aria-label="Close" (click)="onModalClose()"
                        test_id="contactPreferencePopupLinkText">
                        {{contactPreferencePopupContent.contactPreferencePopupLinkText}}
                    </a>
                </span>
            </div>
        </div>
    </div>
</ng-template>