import { Component, OnInit } from '@angular/core';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-eoc-option-details',
  templateUrl: 'eoc-option-details.component.html' 
})
export class EocOptionDetailsComponent implements OnInit {

  constructor(
    private tealiumService: TealiumUtagService
  ) {}

  ngOnInit(): void {
    this.tealiumService.view (
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'End of Contract Option Details',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

}
