import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isErrServer } from '@wss/common/util/util';
import { appConfig } from '@wss/config/wss-app-constants';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { PaymentService } from '@wss/payment-widget/src/services/payment.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { MessageService } from '@wss/service/message.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EndOfContractService } from '../../services/end-of-contract.service';
import * as moment from 'moment';
import { BannerMessage } from 'libs/model/bannerMessage';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-eoc-part-exchange',
  templateUrl: 'eoc-part-exchange.component.html'
})
export class EocPartExchangeComponent implements OnInit, OnDestroy {


  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');
  settlementData: any;
  serviceError: boolean = false;
  readonly destroy$ = new Subject();
  withinNinetyDays: boolean = false;
  fileTagFlag: boolean = false;
  inGoodsScope: boolean = false;
  isAgreementInArrears: boolean = false;
  accountDetails: any = {
    // final_payment_date: "15/09/2021",
    // in_arrears: true,
    // months_in_arrears: 1,
    // file_tag: '00',
    // goods_code: '001',
    // customer_intention: ''
  };
  bannerMessage: BannerMessage = new BannerMessage();
  endOfContractWidgetItem: any;
  today = moment().format('DD/MM/YYYY');
  newSettlementCallDay: string = '';

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private readonly bbContentService: ContentService,
    private paymentService: PaymentService,
    private datastoreService: DatastoreService,
    private eocService: EndOfContractService,
    public changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private tealiumService: TealiumUtagService
  ) { }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'End of Contract Part Exchange Landing',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.fileTagFlag = appConfig.fileTags.includes(this.accountDetails?.file_tag);
    this.inGoodsScope = appConfig.goodsCode.includes(this.accountDetails?.goods_code);
    this.accountDetails = this.datastoreService.getAccountDetails();
    this.withinNinetyDays = this.eocService.isContractWithinNintyDays();
    this.newSettlementCallDay = moment().format('DD/MM/YYYY');
    this.isAgreementInArrears = !(!this.accountDetails?.in_arrears || (this.accountDetails?.in_arrears && this.accountDetails?.months_in_arrears == 0));
    this.getSettlementDetail();
    this.handleSettlementError();
    this.handleErrors();
  }

  openModal(modalContent: any) {
    this.modalService.open(modalContent, { centered: true, windowClass: 'eoc-modal' });
  }

  confirmSelection() {
    this.modalService.dismissAll();
    this.eocService.savePartExchangeOption()
      .subscribe(res => {
        this.serviceError = false;
        this.datastoreService.setEocRoute("part-exchange-confirmation");
        this.messageService.setActiveNav("End of contract");
        this.router.navigate(['/eoc-options/part-exchange-confirmation']);
        window.scroll(0, 0);
      }, error => {
        this.handleErrors();
      });
  }
  getSettlementDetail() {
    this.paymentService.getSettlementDetail()
      .subscribe(
        data => {
          this.datastoreService.setSettlementDetails(data);
          this.settlementData = data;
          let localDate = this.settlementData?.settlement_expiry_date.split(' ');
          this.settlementData.settlement_expiry_date = localDate[0];
          this.serviceError = false;
          this.changeDetectorRef.detectChanges();
        },
        (error: any) => {
          //this.handleErrors();
        }
      );
  }

  handleSettlementError() {
    this.messageService.getServiceError().subscribe((error: any) => {
      if (error?.error[0].code == 17001) {
        this.getNewSettlementAmount();
      }
    })
  }

  handleErrors() {
    this.messageService.getEOCServiceError().subscribe((error: any) => {
      this.showError(error?.status || error?.code);
    });
  }

  showError(errStatus: number): void {
    if (isErrServer(errStatus) || errStatus == 506) {
      this.bannerMessage.closeIconClass = 'clear';
      this.bannerMessage.infoTypeClass = 'error-icon';
      this.bannerMessage.leftSectionColorClass = 'message-failure';
      this.endOfContractWidgetItem$.subscribe((res: any) => {
        this.endOfContractWidgetItem = res;
        this.bannerMessage.titleText = this.endOfContractWidgetItem?.endOfContractPartExchange.eocErrorbannerMessage.titleTextBeforeData;
        this.bannerMessage.subtitleText = this.endOfContractWidgetItem?.endOfContractPartExchange.eocErrorbannerMessage.subtitleTextBeforeData + this.endOfContractWidgetItem?.brandContactDetails.brandWiseContactNumber + this.endOfContractWidgetItem?.endOfContractPartExchange.eocErrorbannerMessage.subtitleTextAfterData;
        this.bannerMessage.isBannerClosed = false;
        this.messageService.setBannerMessage(this.bannerMessage);
        this.serviceError = true;
        window.scroll(0, 0);
      })
    }
    else {
      this.serviceError = false;
    }
    this.changeDetectorRef.detectChanges();
  }


  navigateToPage(modalContent: any) {
    if (this.isAgreementInArrears || this.accountDetails?.customer_intention === 'F' || this.accountDetails?.customer_intention === 'R' || this.accountDetails?.customer_intention === 'E') {
      this.datastoreService.setEocRoute("get-in-touch");
      this.messageService.setActiveNav("End of contract");
      this.router.navigate(['/eoc-options/get-in-touch']);
    } else if (this.accountDetails?.customer_intention === 'T' || this.accountDetails?.customer_intention === 'P') {
      this.datastoreService.setEocRoute("part-exchange-confirmation");
      this.messageService.setActiveNav("End of contract");
      this.router.navigate(['/eoc-options/part-exchange-confirmation'])
    } else if (!this.withinNinetyDays) {
      this.datastoreService.setEocRoute("get-in-touch");
      this.messageService.setActiveNav("End of contract");
      this.router.navigate(['/eoc-options/get-in-touch']);
    } else {
      this.openModal(modalContent)
    }
  }

  getNewSettlementAmount(newSettlementFlag?: any) {
    window.scrollTo(0, 0);
    this.paymentService.getPostSettlementResponse(newSettlementFlag ? newSettlementFlag : '')
      .subscribe((res: any) => {
        this.datastoreService.setSettlementResponse(res);
        this.settlementData = res;
        if (this.today == this.newSettlementCallDay) {
          sessionStorage.setItem("newSettlementCalledTodayAlready", "yes");
        } else {
          sessionStorage.setItem("newSettlementCalledTodayAlready", "no");
        }
        this.changeDetectorRef.detectChanges();
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.modalService.dismissAll();
  }

}
