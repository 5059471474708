
import { PortalContentMock } from "@backbase/foundation-ang/web-sdk";
import { BrandContactDetailsItem } from "./common/brand-contant-details";
export class FooterWidgetItem{
    footerDetails: FooterWidgetData = new FooterWidgetData() ;
}

export class FooterWidgetData{
    linkFooter: string = '';
    brandName: string = '';
    registeredOffice: string = '';
    registeredNumber: string = '';
    textFooter: string = '';
    brandContactDetails: BrandContactDetailsItem = new BrandContactDetailsItem();
}

export const footerWidgetItemContentRef = PortalContentMock.addStructuredContent<FooterWidgetItem>('FooterWidgetItem', {
    footerDetails: {
        linkFooter:  "Black Horse Registered in England and Wales with registered number 661204, Registered Office: 25 Gresham Street",
        brandName: '',
        registeredOffice: '',
        registeredNumber: '',
        textFooter:  "Black Horse Registered in England and Wales",
        brandContactDetails: {
            brandWiseAddress: "",
            brandWiseContactNumber: "",
            brandWiseContactEmail: "",
            brandWiseBankDetails: "",
            brandWiseCfaNumber: "",
            portalName: "Black Horse",
            mainCollectionsLineNumber: "",
            legalNumber: "",
            budgetToolLink: "",
            brandWiseFreeTRSNumber: "", 
            privacyPolicyLink: "",
            brandWiseAccessibilityNumber: ""
        }
}});


// export const headerWidgetContent = new HeaderWidgetItem();