import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserRepoService} from "../../service/user-repo.service";
//import {ContentData} from '../../../../config/wss-app-constants';
import {ItemModel, RouterService} from "@backbase/foundation-ang/core";
//import {ContentService as OldContentService} from "../../../../service/content.service";
import {LoginWidgetItem} from '../../../../model/login-widget/login-widget';
import { Observable } from "rxjs";
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';
//import {ContentService as OldContentService} from "../../../../service/content.service";

@Component({
  selector: 'bb-wss-no-user',
  templateUrl: './no-user.component.html',
  providers: [ ContentService ]
})

export class NoUserComponent implements OnInit {  
  
  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');

  constructor(
    private router: Router,
    private itemModel: ItemModel,
      private readonly bbContentService: ContentService,
    private userRepoService: UserRepoService,
    private tealiumService: TealiumUtagService,
  ) {  
  }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Login',
        JourneyStepName: 'No User',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

}
