import { Component, Input, OnInit } from '@angular/core';
// import { HttpService } from 'libs/service/http.service';
import { Observable } from 'rxjs';
import { HeaderWidgetItem } from '@wss/model/content/header-widget-profile';
import { ContentService } from '@backbase/universal-ang/content';
@Component({
  selector: 'bb-header-widget',
  templateUrl:'./header-widget.component.html',
  providers: [ ContentService ]
})

export class HeaderWidgetComponent implements OnInit {
  
  // @Input() displayMode: string = '';

  // open:boolean = false;
  // address:any = {};
  // contactDetails:any = {};
  // customer_address:any= {}


  headerWidgetItem$:Observable<HeaderWidgetItem | undefined> = this.bbContentService.getContent<HeaderWidgetItem>('headerWidgetItem');

  headerWidgetItem: any;

  constructor(
    private readonly bbContentService: ContentService) { }

  ngOnInit(): void {
    // this.getUserAddress();
    this.headerWidgetItem$.subscribe((headerWidgetItem: HeaderWidgetItem|undefined)=> {
      this.headerWidgetItem = headerWidgetItem;
    })
  }
}
