
<section class="custom-form budget-money-owed content-margin" *ngIf="budgetFormItem$ | async as budgetFormItem" ><!---*ngIf="budgetFormItem$ | async as budgetFormItem"-->
  <form [formGroup]="moneyOwedForm" formInvlidControlScroll>
      <div class="container-row" *ngIf="serviceError">
          <bb-wss-banner-message></bb-wss-banner-message>
        </div>
      <div class="content-wrapper-container">
          <div class="container-column mx-3">
              <div class="d-flex section-title-container">
                  <span class="section-title" test_id="moneyOwedPageHeader">
                      {{budgetFormItem.moneyOwed.pageName}}
                  </span>
              </div>

              <div class="customer-support-text mt-4">
                  <div test_id="moneyOwedPageHeaderBelowText">
                      <bb-content-html [html]="budgetFormItem.moneyOwed.pageHeaderBelowText"></bb-content-html>
                  </div>

                  <div class="round-panel">
                      <div class="form-group">
                          <div class="form-group-heading" test_id="haveOtherPaymentsText">
                              {{budgetFormItem.moneyOwed.haveOtherPaymentsText}}
                          </div>

                          <div class="col-12">
                              <div class="radio mb-0">
                                  <label class="form-check-label">
                                      <input type="radio" class="form-check-input" name="haveOtherPayments"
                                          formControlName="haveOtherPayments" value="yes" (click)="setIsHaveOtherPayments('yes')">
                                          <span class="label" test_id="haveOtherPaymentsYes" >Yes</span>
                                  </label>
                              </div>
                              <div class="radio">
                                  <label class="form-check-label">
                                      <input type="radio" class="form-check-input" name="haveOtherPayments"
                                          formControlName="haveOtherPayments" value="no" (click)="setIsHaveOtherPayments('no')">
                                          <span class="label" test_id="haveOtherPaymentsNo">No</span>
                                  </label>
                              </div>
                          </div>
                          <div *ngIf="submitted && moneyOwedForm.get('haveOtherPayments')?.invalid &&
                              moneyOwedForm.get('haveOtherPayments')?.errors">
                              <small class="text-danger"
                                  *ngIf="moneyOwedForm.get('haveOtherPayments')?.hasError('required')">
                                  Please select an answer
                              </small>
                          </div>
                          <div *ngIf="isHaveOtherPayments == 'yes' || haveOtherPayments?.value=='yes'"  formArrayName="otherPayments">
                              <div class="form-group separator" *ngFor="let payment of otherPayments.controls; let paymentIndex = index" [formGroupName]="paymentIndex">
                                  <div *ngIf="paymentIndex > 0">
                                      <span class="form-group-heading" test_id="accomodationDetailAnyOtherCostText">
                                          {{budgetFormItem.moneyOwed.otherPaymentHeadingText}}
                                          {{utilService.convertNumToString(paymentIndex+1)}}
                                      </span>
                                      <span class="text-right float-right position-relative cursor-default delete-button" role="button" (click)="deleteOtherPayment(paymentIndex)">
                                          <span class="cfa-delete-icon"></span>
                                          {{budgetFormItem.moneyOwed.removeButton}}
                                      </span>
                                  </div>
                                  <div class="form-group">
                                      <label test_id="" class="subgroup">
                                          {{budgetFormItem.moneyOwed.typeOfCost}}
                                      </label>
                                    <div class="select-wrapper">
                                      <select class="form-control"
                                          test_id=""
                                          formControlName="category"   [ngClass]="{'is-invalid':submitted && payment.get('category')?.errors }">
                                          <option selected [ngValue]="null">Please select</option>
                                          <option *ngFor="let toa of typeOfAccount" [value]="toa">{{toa}}</option>
                                      </select>
                                    </div>
                                      <div *ngIf="submitted && payment.get('category')?.invalid &&
                                          payment.get('category')?.errors">
                                          <small class="text-danger"
                                              *ngIf="payment.get('category')?.hasError('required')">
                                              Please select an answer from the list
                                          </small>
                                      </div>
                                  </div>
                                  <div class="amount-group">
                                      <label test_id="" class="subgroup">
                                          {{budgetFormItem.moneyOwed.amount}}
                                      </label>
                                      <div class="position-relative">
                                          <span class="currency">£</span>
                                          <input type="text"
                                              class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                              formControlName="amount"
                                              fiveDigitValidation="otherPayments"
                                              indexVal = "{{paymentIndex}}"
                                              onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                              [ngClass]="{'is-invalid':submitted && payment.get('amount')?.errors }"
                                              test_id="">
                                      </div>
                                      <div *ngIf="submitted && payment.get('amount')?.invalid &&
                                          payment.get('amount')?.errors">
                                          <small class="text-danger"
                                              *ngIf="payment.get('amount')?.hasError('required')">
                                              Please enter amount
                                          </small>
                                          <small class="text-danger"
                                              *ngIf="payment.get('amount')?.hasError('pattern')">
                                              Please enter an amount e.g. £123 or £123.23
                                          </small>
                                      </div>
                                      <div *ngIf="payment.get('amount')?.errors && !payment.get('amount')?.hasError('pattern') &&
                                          payment.get('amount')?.hasError('fiveDigitError')">
                                          <small class="text-danger">
                                              {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                          </small>
                                      </div>
                                  </div>
                                  <div class="form-group">
                                      <label test_id="" class="subgroup">
                                          {{budgetFormItem.moneyOwed.howOften}}
                                      </label>
                                    <div class="select-wrapper">

                                      <select class="form-control"
                                          test_id=""
                                          fiveDigitValidation="otherPayments"
                                          indexVal = "{{paymentIndex}}"
                                          formControlName="frequency"  [ngClass]="{'is-invalid':submitted && payment.get('frequency')?.errors }">
                                          <option selected [ngValue]="null">Please select</option>
                                          <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                      </select>
                                    </div>
                                      <div *ngIf="submitted && payment.get('frequency')?.invalid &&
                                          payment.get('frequency')?.errors">
                                          <small class="text-danger"
                                              *ngIf="payment.get('frequency')?.hasError('required')">
                                              Please select an answer from the list
                                          </small>
                                      </div>
                                  </div>
                                  <div class="form-group">
                                      <label test_id="">
                                          {{budgetFormItem.moneyOwed.behindByPayment}}
                                      </label>
                                      <div class="col-12">
                                          <div class="radio mb-0">
                                              <label class="form-check-label">
                                                  <input type="radio" class="form-check-input"
                                                       value="yes" formControlName="is_behind_payment" (change)="onBehindWithPaymentsChanged($event, paymentIndex)">
                                                      <span class="label" test_id="areBehindWithPayments" >Yes</span>
                                              </label>
                                          </div>
                                          <div class="radio">
                                              <label class="form-check-label">
                                                  <input type="radio" class="form-check-input"
                                                      formControlName="is_behind_payment" value="no" (change)="onBehindWithPaymentsChanged($event, paymentIndex)">
                                                      <span class="label" test_id="areBehindWithPayments">No</span>
                                              </label>
                                          </div>
                                      </div>
                                      <div *ngIf="submitted && payment.get('is_behind_payment')?.invalid &&
                                          payment.get('is_behind_payment')?.errors">
                                          <small class="text-danger"
                                              *ngIf="payment.get('is_behind_payment')?.hasError('required')">
                                              Please select an answer
                                          </small>
                                      </div>
                                  </div>
                                  <div class="amount-group" *ngIf="payment.get('is_behind_payment')?.value=='yes'">
                                      <label test_id="" class="subgroup"> 
                                          {{budgetFormItem.moneyOwed.howMuchBehindByPayment}}
                                      </label>
                                      <div class="position-relative">
                                          <span class="currency">£</span>
                                          <input type="text"
                                              class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                              formControlName="behind_payments_amount"
                                              onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                              [ngClass]="{'is-invalid':submitted && payment.get('behind_payments_amount')?.errors }"
                                              test_id="">
                                      </div>
                                      <div *ngIf="submitted && payment.get('behind_payments_amount')?.invalid &&
                                          payment.get('behind_payments_amount')?.errors">
                                          <small class="text-danger"
                                              *ngIf="payment.get('behind_payments_amount')?.hasError('required')">
                                              Please enter amount
                                          </small>
                                          <small class="text-danger"
                                              *ngIf="payment.get('behind_payments_amount')?.hasError('pattern')">
                                              Please enter amount e.g. £123 or £123.23
                                          </small>
                                      </div>
                                      <div *ngIf="!(payment.get('behind_payments_amount')?.errors && payment.get('behind_payments_amount')?.hasError('pattern')) && checkingFiveDigitValue(payment.get('behind_payments_amount')?.value)">
                                          <small class="text-danger">
                                              {{budgetFormItem.commonDetail.arrearsFiveDigitError}}​​
                                          </small>
                                      </div>
                                  </div>
                                  <div class="form-group">
                                      <label test_id="">
                                          {{budgetFormItem.moneyOwed.additionalAmountToHelpPay}}
                                      </label>
                                      <div class="col-12">
                                          <div class="radio mb-0">
                                              <label class="form-check-label">
                                                  <input type="radio" class="form-check-input"
                                                      formControlName="is_include_arrears" value="true">
                                                      <span class="label" test_id="is_include_arrears" >Yes</span>
                                              </label>
                                          </div>
                                          <div class="radio">
                                              <label class="form-check-label">
                                                  <input type="radio" class="form-check-input"
                                                      formControlName="is_include_arrears" value="false">
                                                      <span class="label" test_id="is_include_arrears">No</span>
                                              </label>
                                          </div>
                                      </div>
                                      <div *ngIf="submitted && payment.get('is_include_arrears')?.invalid &&
                                          payment.get('is_include_arrears')?.errors">
                                          <small class="text-danger"
                                              *ngIf="payment.get('is_include_arrears')?.hasError('required')">
                                              Please select an answer
                                          </small>
                                      </div>
                                  </div>
                              </div>

                              <div *ngIf="(otherPayments.controls.length == maximumPaymentEntries)">
                                <div class="icon_info_circle position-absolute">
                                </div>
                                <div class="error-banner-section ">
                                  <bb-content-html [html]="budgetFormItem.commonDetail.maxCountReachedError"></bb-content-html>
                                </div>
                              </div>

                              <button *ngIf= "otherPayments.controls.length < maximumPaymentEntries" class="wss-button-secondary pl-5 add-button" type="button" (click)="addOtherPayment()" test_id="">
                                  <span class="material-icons add-icon">
                                      add
                                  </span>
                                  {{budgetFormItem.moneyOwed.addbutton}}
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="margin-top-20">
                      <bb-content-html [html]="budgetFormItem.moneyOwed.submitFormWarningText"></bb-content-html>
                  </div>
                  <div class="container-row content-center margin-top-20">
                      <button class="wss-button btn btn-dif-view" test_id="moneyOwedButtonText" (click)="goToNextPage()">
                          {{budgetFormItem.moneyOwed.continuebuttonText}}
                      </button>
                  </div>
                  <div class="container-row content-center margin-top-20">
                    <button #button bbButton class="wss-button-secondary wss-button btn-dif-view" type="button"
                      (click)="openModal(budgetFormItem)" >
                   {{budgetFormItem.saveExitModal.saveAndExitButtonText}}
                    </button>
                  </div>
                  <div class="container-row content-center margin-top-20">
                      <a  (click)="goBack()" test_id="moneyOwedBackButtonText">
                          {{budgetFormItem.moneyOwed.backbuttonText}}
                      </a>
                  </div>
              </div>
          </div>
      </div>
  </form>
</section>
