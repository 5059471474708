import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpService } from '../../../service/http.service';
import { DatastoreService } from '../../../service/datastore.service';
import { catchError, map} from 'rxjs/operators';
import { getDate } from '../../../common/util/util';
import { MessageService } from '../../../service/message.service';

@Injectable({
  providedIn: 'root',
})
export class InboxMsgService {

  constructor(
    private httpService: HttpService, 
    private datastoreService: DatastoreService,
    private messageService: MessageService) { } 

  updateMailboxMsgPreference(prefReq: any, guidNo: string): Observable<any>{
    return this.httpService.updateMailboxMsgPreference(prefReq, guidNo);
  }

  downloadDocument(guidNo: string): Observable<any>{
    return this.httpService.downloadDocument(guidNo);
  }

  getMailboxMsg(): Observable<any>{
    return this.httpService.getMailboxMsg()
    .pipe(
      map((data) => {this.datastoreService.setMailboxMsg(data)})
    );
  }

}