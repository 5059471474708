import { Component, OnInit, ChangeDetectorRef, Renderer2, OnDestroy, ElementRef } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { DatastoreService } from '@wss/service/datastore.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { InboxMsgService } from '../../services/inbox-msg.service';
import * as moment from 'moment';
import { injectBrandDetailIntoPage, isErrServer, isNotEmpOrNullOrUndef } from '@wss/common/util/util';
import { MessageService } from '@wss/service/message.service';
import { BannerMessage } from 'libs/model/bannerMessage';
import { ContentService } from '@backbase/universal-ang/content';
import { InboxMsgWidgetItem } from '@wss/model/content/inbox-msg';
import { InboxMsgList, InboxMsgRequestList, InboxRequest } from '@wss/model/content/inbox-msg';
import { AccoutSummaryService } from '@wss/account-summary-widget/src/services/account-summary.service';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject, forkJoin } from 'rxjs';
import { ServiceException } from "@wss/model/service-exception";
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'mailbox',
    templateUrl: 'mailbox.component.html',
    providers: [ ContentService, NgbModalConfig, NgbModal ]
  })
  export class MailboxComponent implements OnInit, OnDestroy {
    modalRef: any;
    getCount: string = 'Inbox';  
    countUnreadInbox:number = 0;
    countReadInbox:number = 0;
    countInbox:number = 0;
    countArchive:number = 0;
    todayDate: Date = new Date();
    moment: any = moment;
    allSelected:boolean = false;
    isReadInboxSelected:boolean = false;
    isArchiveSelected:boolean = false;
    listIsUnread: any[] = [];
    listIsArchive: any[] = [];
    listIsInbox: any[] = [];
    docsMailbox: InboxMsgList[] = [];
    msgRequest: InboxRequest = new InboxRequest();
    msgRequestList: InboxMsgRequestList[] = [];
    readonly destroy$ = new Subject();
    currentPreference: any = null;  
    modalMsg: any;
    noRecords: boolean = false;
    archivebannerMessage: BannerMessage = new BannerMessage();
    bannerMessage: BannerMessage = new BannerMessage();
    serviceError: boolean = false;
    apiCallCompleted: boolean = false;
    errorNoMsg: string = 'No documents to view';
    errorMsgNoUpdate: string = 'Unable to mark as unread/archive/move to inbox​';
    errorPhrMsgNoUpdate: string = 'We are sorry, we are not able to perform that action. Please try again later.';
    errorMsgFailedDoc: string = 'Document unable to load or be retrieved​';
    errorPhrMsgFailedDoc: string = 'We are sorry, we are not able to show the letter or statement right now. This is because of a technical error. Please try again later.​';
    isAccountCompleted: boolean = false;
    readDetails: boolean = false;
    serviceException: ServiceException = new ServiceException();
    isAccountArchived:boolean = false;
    inboxMsgWidgetItem$: Observable<InboxMsgWidgetItem | undefined> = this.bbContentService.getContent<InboxMsgWidgetItem>('inboxMsgWidgetItem');
    inboxMsgWidgetItem: any = new InboxMsgWidgetItem();
    showArchiveBanner:boolean = false;

    constructor(
      private changeDetectorRef: ChangeDetectorRef,
      private modalService: NgbModal,
      private inboxMsgService: InboxMsgService,
      private datastore: DatastoreService,
      private messageService: MessageService,
      private renderer: Renderer2,
      private readonly bbContentService: ContentService,
      private router: RouterService,
      private elementRef: ElementRef,
      private accoutSummaryService: AccoutSummaryService,
      private tealiumService: TealiumUtagService,
      config: NgbModalConfig
    ) {    
      config.backdrop = 'static';
      config.keyboard = false;      
    }
  
    ngOnInit(): void {
      this.datastore.sessionInvalid();  
      this.tealiumService.view(
        {
          JourneyName: 'Inbox Message',
          JourneyStepName: 'Mailbox',
          CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
        }
      );
      this.isAccountCompleted = this.datastore.getIsAccountCompleted();
      this.isAccountArchived = this.datastore.getIsAccountArchived();
      
      this.inboxMsgWidgetItem$.subscribe((pageItem: any) => {
        this.inboxMsgWidgetItem = pageItem;         
        if(this.isAccountArchived){
          this.showArchiveBannerMessage(pageItem);
        }
        this.changeDetectorRef.detectChanges();
      });
      this.getMailPreference();
      this.changeDetectorRef.detectChanges();
      this.getMailboxMsg();
      this.changeDetectorRef.detectChanges();
      this.handleErrors();
    }
  
    ngAfterViewInit() {
    }
  
    ngAfterViewChecked() {
    }
  
    showArchiveBannerMessage(res:any) {     
      if (this.isAccountArchived) {
        this.archivebannerMessage.closeIconClass = "clear";
        this.archivebannerMessage.infoTypeClass = "icon_info_blue";
        this.archivebannerMessage.leftSectionColorClass = "message-info";
        this.archivebannerMessage.materialIconClass = "info";
        
        let brandwiseBannerContent = injectBrandDetailIntoPage(res.inboxMsgComponent, res.brandContactDetails);
        this.archivebannerMessage.titleText = brandwiseBannerContent.archiveBannerHeading;
        this.archivebannerMessage.subtitleText = brandwiseBannerContent.archiveBannerMessage;
        
        this.archivebannerMessage.isBannerClosed = false;
        this.messageService.setBannerMessage(this.archivebannerMessage);
        this.showArchiveBanner = true;
        this.changeDetectorRef.detectChanges();
      } else {
        this.showArchiveBanner = false;
      }
      this.changeDetectorRef.detectChanges();
    }
    showError(errStatus: number, errCode?: number, errMsg?: any, errPhMsg?: any): void {
      if(isErrServer(errStatus)){
        if(errCode === 404) {
          this.noRecords = true;        
        } else if (isNotEmpOrNullOrUndef(errMsg)) {
          this.showPreferenceUpdateError(errMsg, errPhMsg);
        } else {
          this.noRecords = false;       
          this.datastore.setErrorServer();
          this.serviceError = true;
        }
        this.changeDetectorRef.detectChanges();
      }
    }  
  
    openMsgDetails(msgdetails: any) {
      this.readDetails = true;
      this.modalMsg = msgdetails;    
      this.changeDetectorRef.detectChanges();
    } 
  
    toPreference() {
      this.datastore.setPreferenceLaunchThrough("profile");
      this.router.navigate(['/digital-inbox/manage-preference']);
    }
  
    getAllCount() {
      this.countInbox = 0;
      this.countReadInbox = 0;
      this.countUnreadInbox = 0;
      this.countArchive = 0;
      if(this.docsMailbox.length > 0) {
        for(var i = 0; i < this.docsMailbox.length; i++) {      
          if((this.docsMailbox[i].is_read === false) && 
          (this.docsMailbox[i].is_archived === false)) {
            this.countUnreadInbox = this.countUnreadInbox + 1;
          }
          if ((this.docsMailbox[i].is_read === true) && 
          (this.docsMailbox[i].is_archived === false)) {
            this.countReadInbox = this.countReadInbox + 1;
          }
          if (this.docsMailbox[i].is_archived === true) {
            this.countArchive = this.countArchive + 1;
          }
        }
        this.countInbox = this.countReadInbox + this.countUnreadInbox;
      }
  
      if(this.countUnreadInbox > 0){
        this.getCount = 'Inbox (' + this.countUnreadInbox + ')';
      } else {
        this.getCount = 'Inbox';
      }   
    }
  
    getCountArchive() {    
      this.countArchive = 0;
      for(let j = 0; j < this.docsMailbox.length; j++) {
        if (this.docsMailbox[j].is_archived === true) {
          this.countArchive = this.countArchive + 1;
        }
      }
    }
  
    getCountReadInbox() {   
      this.countReadInbox = 0; 
      for(var i = 0; i < this.docsMailbox.length; i++) {      
        if ((this.docsMailbox[i].is_read === true) && 
        (this.docsMailbox[i].is_archived === false)) {
            this.countReadInbox = this.countReadInbox + 1;
        }        
      }
      this.countInbox = this.countReadInbox + this.countUnreadInbox;    
    }
  
    getCountInbox(){
      this.countUnreadInbox = 0;
      this.countReadInbox = 0;
      for(var i = 0; i < this.docsMailbox.length; i++) {
        if((this.docsMailbox[i].is_read === false) && 
          (this.docsMailbox[i].is_archived === false)) {
            this.countUnreadInbox = this.countUnreadInbox + 1;
        }
      }
      this.countReadInbox = this.countInbox - this.countUnreadInbox;
      if(this.countUnreadInbox > 0){
        this.getCount = 'Inbox (' + this.countUnreadInbox + ')';
      }
      this.datastore.setMailboxUnreadCount(this.countUnreadInbox);
    }
  
    replaceString(str: string) { 
      let dateString = str.substr(0,10);
      let datePart: any = dateString.split('/');
      let dateObject: any = new Date(+datePart[2], datePart[1] - 1, +datePart[0]); 
      dateObject = moment(dateObject).format('DD/MM/YYYY');
      return dateObject;    
    }
  
    currentDate(date: Date) {
      let dateObject: any = moment(date).format('DD/MM/YYYY');
      return dateObject;
    }
  
    uncheckAll(val: any, chkVal: string, chkAll: string) {
      let chkElemt: any = document.getElementsByName(chkVal); 
      let chkLength: number = chkElemt.length;    
      let chkCount: number = 0;
      for(let l = 0; l < chkLength; l++) {
        if(chkElemt[l].checked){        
          chkCount = chkCount + 1;
        }
      }
      if(chkCount < 1) {
        if(!val.checked) {
          if(this.elementRef.nativeElement.querySelector(chkAll).checked) {
            this.elementRef.nativeElement.querySelector(chkAll).checked = false;
          }
        }
      }    
    }
  
    isCheckedInbox(val: string) {
      let countRInbox: any = document.getElementsByName(val);    
      this.isReadInboxSelected = this.isReadInboxSelected === false ? true : false;        
      for(let chInbox = 0; chInbox < countRInbox.length; chInbox++) {
        /*if(this.isReadInboxSelected){        
          countRInbox[chInbox].checked = this.isReadInboxSelected;
        } else {
          countRInbox[chInbox].checked = this.isReadInboxSelected;
        }*/
        countRInbox[chInbox].checked = this.isReadInboxSelected;
      }
    }
  
    isCheckedArchive(val: string) {
      let countRInbox: any = document.getElementsByName(val);    
      this.isArchiveSelected = this.isArchiveSelected === false ? true : false;        
      for(let chInbox = 0; chInbox < countRInbox.length; chInbox++) {
        countRInbox[chInbox].checked = this.isArchiveSelected;
        /*if(this.isArchiveSelected){        
          countRInbox[chInbox].checked = this.isArchiveSelected;
        } else {
          countRInbox[chInbox].checked = this.isArchiveSelected;
        } */
      }
    }
  
    markUnread() {
      let countRInbox: any = document.getElementsByName('inboxReadMsg'); 
      if(countRInbox.length > 0) { 
        this.countUnreadInbox = 0; 
        this.listIsUnread = [];
        for(let chInbox = 0; chInbox < countRInbox.length; chInbox++) {
          if(countRInbox[chInbox].checked){
            this.listIsUnread.push(countRInbox[chInbox].value);
          }
        }
        this.assignAction(this.listIsUnread, 'is_read', false);
        this.updateMarkAction('is_read');
      }
    }  
  
    updateMarkAction(action: string) {
      this.serviceError = false;
      forkJoin(
        this.msgRequestList.map(reqData =>
          this.inboxMsgService.updateMailboxMsgPreference( reqData.msgReq, reqData.guid)
        )
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((resData: any) => {
        this.serviceError = false;
        this.updateState(resData);
        switch(action) {
          case 'is_read':
            this.actionsMarkUnread();
            break;
          case 'is_archived':
            this.actionsMoveArchive();
            break;
          case 'is_not_archived':
            this.actionsMoveInbox();
            break;
          default:          
        }      
        this.changeDetectorRef.detectChanges();
      },
      (error: any) => {
        this.showError(400,0,this.inboxMsgWidgetItem.inboxMsgComponent.errNoUpdate, this.inboxMsgWidgetItem.inboxMsgComponent.errNoUpdateDetails);
      });
    }
  
    moveArchive() {
      let countRInbox: any = document.getElementsByName('inboxReadMsg');  
      let selChk = this.countSelChecked('inboxReadMsg');
      if(countRInbox.length > 0 && selChk === true) { 
        this.countArchive = 0;
          this.listIsArchive = [];
          for(let chInbox = 0; chInbox < countRInbox.length; chInbox++) {
            if(countRInbox[chInbox].checked){
              this.listIsArchive.push(countRInbox[chInbox].value);
            }
          }
          this.assignAction(this.listIsArchive, 'is_archived', true);
          this.updateMarkAction('is_archived');
        
      }
    }
  
    countSelChecked(elem:string){
      let countChk: any = document.getElementsByName(elem);
      let countChkSel: number = 0;
      for(let chk = 0; chk < countChk.length; chk++){
        if(countChk[chk].checked){
          countChkSel = countChkSel + 1;
        }
      }
      if(countChkSel > 0) {
        return true;
      } else {
        return false;
      }
    }
  
    moveToInbox() {
      let countRInbox: any = document.getElementsByName('archiveMsg'); 
      let selChk = this.countSelChecked('archiveMsg');
      if(countRInbox.length > 0 && selChk === true) { 
        this.countArchive = 0;
        this.listIsInbox = [];
        for(let chInbox = 0; chInbox < countRInbox.length; chInbox++) {
          if(countRInbox[chInbox].checked){
            this.listIsInbox.push(countRInbox[chInbox].value);
          }
        }
        this.assignAction(this.listIsInbox, 'is_archived', false);
        this.updateMarkAction('is_not_archived');
      }
    }
  
    assignAction(arrList: any[], actionUpdate:string, val: boolean){
      this.msgRequestList = [];
      for(let docsList = 0; docsList < this.docsMailbox.length; docsList++) {
        for(let selList = 0; selList < arrList.length; selList++) {
          if(this.docsMailbox[docsList].guid === arrList[selList]) {
            if(actionUpdate === 'is_read'){
              this.msgRequest = {guid: this.docsMailbox[docsList].guid, msgReq: {'is_read': val}}
            }
            if(actionUpdate === 'is_archived'){
              this.msgRequest = {guid: this.docsMailbox[docsList].guid, msgReq: {'is_archived': val}}
            }
            this.msgRequestList.push(this.msgRequest); 
          } 
        }     
      }
      this.changeDetectorRef.detectChanges();
    }
  
    actionsMarkUnread() {
      this.getCountInbox();
      this.getCountArchive();
      this.actionsCmnInbox();
    }
  
    actionsMoveArchive() {
      this.getCountArchive();
      this.getCountReadInbox();
      this.actionsCmnInbox();
    }
  
    actionsMoveInbox() {
      this.getCountArchive();
      this.getCountReadInbox();
      this.isArchiveSelected = false;
    }
  
    actionsCmnInbox() {
      this.isReadInboxSelected = false;
      this.elementRef.nativeElement.querySelector("#inboxAllChk").checked = false;
    }
  
    updateState(rData: any) {
      for (let r = 0; r < rData.length; r++) {
        for(let d = 0; d < this.docsMailbox.length; d++) {
          if(this.msgRequestList[r].guid === this.docsMailbox[d].guid) {
            this.docsMailbox[d].last_viewed_date = rData[r].last_viewed_date;
            this.docsMailbox[d].is_read = rData[r].is_read;
            this.docsMailbox[d].is_archived = rData[r].is_archived;
          }
        }        
      }
    }
  
    downloadInboxDocument(docRecord: any) {
      this.serviceError = false;
      if (isNotEmpOrNullOrUndef(docRecord.guid)) {
        this.inboxMsgService.downloadDocument(docRecord.guid).pipe(takeUntil(this.destroy$)).subscribe(async (res: any) => {
          if(res && isNotEmpOrNullOrUndef(res.document)){
            //for fixing iphone 12 file opening issue
            await this.getMailboxMsg();    
            this.changeDetectorRef.detectChanges(); 
            this.openOrDownloadFile(res.document, "application/pdf", res.name); 
          }
          else{
            this.showError(400,0,this.inboxMsgWidgetItem.inboxMsgComponent.errNoDoc, this.inboxMsgWidgetItem.inboxMsgComponent.errNoDocDetails);
          }
        },
        (error: any) => {
          this.showError(400,0,this.inboxMsgWidgetItem.inboxMsgComponent.errNoDoc, this.inboxMsgWidgetItem.inboxMsgComponent.errNoDocDetails);
        });
      }
    }
  
    openOrDownloadFile(b64pdfstr: any, type: any, filename: any) {
      let blob = null;
      blob = this.b64toBlob(b64pdfstr, type);
  
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([blob], {type: type})
  
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // this.getMailboxMsg();    
        // this.changeDetectorRef.detectChanges();   
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      } 
  
      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = `${filename}.pdf`;
      link.click();
      setTimeout(function(){
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    };
  
    b64toBlob(content: any, contentType: any) {
      contentType = contentType || '';
      const sliceSize = 512;
       // method which converts base64 to binary
      const byteCharacters = window.atob(content); 
  
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {
          type: contentType
      }); // statement which creates the blob
      return blob;
    };
  
    async getMailboxMsg(){
      this.readDetails = false;
      //defect - MFVSTL-18410 (Andriod/Iphone) fix start
      try{
        await this.inboxMsgService.getMailboxMsg().pipe(takeUntil(this.destroy$)).toPromise();
        this.getMailFunctions();
        this.changeDetectorRef.detectChanges();
      }
      catch(error: any){
        this.showError(error?.status, error?.error[0].code);
      }
      //defect - MFVSTL-18410 (Andriod/Iphone) fix end
  
      // this.inboxMsgService.getMailboxMsg().pipe(takeUntil(this.destroy$)).subscribe(() => {
      //   this.serviceError = false;
      //   this.allSelected = false;
      //   this.isReadInboxSelected = false;
      //   this.isArchiveSelected = false;
      //   this.docsMailbox = this.datastore.getMailboxMsgList();
      //   this.getAllCount();
      //   this.changeDetectorRef.detectChanges();
      // },
      // (error: any) => {
      //   this.showError(error?.status, error?.error[0].code);
      // });    
    }
  
    handleErrors() {
      this.messageService.getServiceError().pipe(takeUntil(this.destroy$)).subscribe((error: any) => {
        this.showError(error?.status || error?.code);
      });
    }
  
    closeMsgDetails() {
      this.readDetails = false;
    }
  
    chkAllSelect(event: any, elmId: any, elemval:string) {
      if(!event.checked) {
        if(elemval === 'inbox'){
          this.isReadInboxSelected = false;
        } else {
          this.isArchiveSelected = false;
        }
        this.elementRef.nativeElement.querySelector(elmId).checked = false;
      }
    }
  
    getMailFunctions() {
      this.serviceError = false;
      this.allSelected = false;
      this.isReadInboxSelected = false;
      this.isArchiveSelected = false;
      this.docsMailbox = this.datastore.getMailboxMsgList();
      if(this.docsMailbox.length < 1){
        this.showError(400, 404);
      }
      this.getAllCount();
    }
  
    getMailPreference() {
      this.accoutSummaryService.getMailPreference().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        //this.currentPreference = this.datastore.getCurrentAccountMailPreference()?.is_paperless;
        this.currentPreference = {
          is_paperless: this.datastore.getCurrentAccountMailPreference()?.is_paperless
        };
        this.changeDetectorRef.detectChanges();
      },
      (error: any) => {
          this.showError(400);
      });
    }

    showPreferenceUpdateError(errorTxt: string, errorPhrase: string) {
      this.serviceException.imageClass = 'acc-locked-icon';
      this.serviceException.errorInfoText = errorTxt;
      this.serviceException.buttonText = this.inboxMsgWidgetItem.inboxMsgComponent.mailboxLink;
      this.serviceException.componentUri = '#/mailbox';
      this.serviceException.phraseRegular = errorPhrase;
      this.messageService.setServiceException(this.serviceException);
      this.router.navigate(['/mailbox/service-exception']);
    }  
  
    ngOnDestroy() {    
      this.destroy$.next();
      this.destroy$.complete();
    }
  
  }