<div class="speedometer-summary-wrapper">
    <div class="speedometer-canvas">
        <div id="speedometer_id"></div>
        <div class="container-column content-center speedometer-summary">
            <span class="summary-text" *ngIf="budgetSummaryData.disposable_income == 0">
              {{budgetFormItem.eom.zeroTextBeforeAmount}}
              <br> <strong class="amount-text">&pound;{{budgetSummaryData.disposable_income}}</strong> <br>
              {{budgetFormItem.eom.zeroTextAfterAmount}}
            </span>
            <span class="summary-text" *ngIf="budgetSummaryData.disposable_income < 0">
              {{budgetFormItem.eom.negativeTextBeforeAmount}}
              <br> <strong class="amount-text">&pound;{{budgetSummaryData.disposable_income}}</strong> <br>
              {{budgetFormItem.eom.negativeTextAfterAmount}}
            </span>
            <span class="summary-text" *ngIf="budgetSummaryData.disposable_income > 0">
              {{budgetFormItem.eom.positiveTextBeforeAmount}}
              <br> <strong class="amount-text">&pound;{{budgetSummaryData.disposable_income}}</strong> <br>
              {{budgetFormItem.eom.positiveTextAfterAmount}}
            </span>
        </div>
    </div>
    <div class="speedometer-row speedometer-bottom-item-height">
        <div class="speedometer-bottom-item border-right border-bottom border-top">
            <span class="material-icons last-payment-margin">arrow_downward</span>
            <span class="para-inline">
              <strong>{{budgetFormItem.eom.moneyIn}}</strong>
            </span>
            <span class="bottom-last-item">&pound;{{budgetSummaryData.income}}</span>
        </div>
        <div class="speedometer-bottom-item border-bottom border-top">
            <span class="material-icons date-received-margin">arrow_upward</span>
            <span class="para-inline">
              <strong>{{budgetFormItem.eom.moneyOut}}</strong>
            </span>
            <span class="bottom-last-item">&pound;{{budgetSummaryData.moneyOut}}</span>
        </div>
    </div>
</div>