<div class="login-container password-successful" *ngIf="loginWidgetItem$ | async as loginWidgetItem"> 
  <span class="section-label-size" test_id="which_titletextlabel_id">
    <!-- {{content[0].title}} -->
    {{loginWidgetItem.passwordSuccesful.titleText}}
  </span>
  <hr/>
  <p class="title-section-page text-center"  test_id="loginWidgetItemchangedTextContent">
    <!-- {{content[0].changed}} -->
    <!-- {{loginWidgetItem.passwordSuccesful.changedText}} -->
    <bb-content-html [html]="loginWidgetItem.passwordSuccesful.changedText"></bb-content-html>
  </p>
  <div class="account-lock">
    <div class="success-icon">      
    </div>   
    <p test_id="loginWidgetItememailConfirmContent">
      <!-- {{content[0].email_confirm}} -->
      <!-- {{loginWidgetItem.passwordSuccesful.emailConfirm}} -->
      <bb-content-html [html]="loginWidgetItem.passwordSuccesful.emailConfirm"></bb-content-html>
    </p>
  </div>
 
  <div id="password-successful-btn" class="row margin-top-between-rows login-page-button text-center" test_id="loginWidgetItemtitleTextContent">
    <button id="bb-button-to" bbButton type="submit" aria-label="Favourite" (click)="submit()"
      [className]="'wss-button'" title="{{loginWidgetItem.passwordSuccesful.titleText}}" class="button-width-padding">
      <!-- {{content[0].title}} -->
      {{loginWidgetItem.passwordSuccesful.titleText}}
    </button>
  </div>
  <div class="row margin-top-between-rows" style="justify-content: center;" test_id="loginWidgetItemneedHelpLinkContent">
    <!-- <a href="#" style="margin: auto;" tabindex="0" title="{{content[0].need_help}}">
      {{content[0].need_help}}
    </a> -->
    <bb-content-html [html]="loginWidgetItem.passwordSuccesful.needHelplink"></bb-content-html>
      
  </div>
</div>
