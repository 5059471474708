import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { BudgetAccomodationCostComponent } from './budget-accomodation-cost-widget.component';
import { WSSCommonModule } from '../../../common/common.module';
import {ContentModule} from '@backbase/content-ang';
import {ContentWidgetModule} from '@backbase/universal-ang/content';
import { ModalModule, ButtonModule } from '@backbase/ui-ang';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SaveAndExitComponentModule } from '@wss/budget-cover-widgets/components/save-and-exit-modal/save-and-exit-modal.module';
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [BudgetAccomodationCostComponent],
  imports: [
    CommonModule,
    ContentModule,
    ContentWidgetModule,
    BackbaseCoreModule.withConfig({
      classMap: { BudgetAccomodationCostComponent }
    }),
    WSSCommonModule,
    ModalModule,
    ButtonModule,
    SaveAndExitComponentModule,
    ReactiveFormsModule,
    NgbModalModule
  ]
})
export class BudgetAccomodationCostModule { }
