import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { BannerMessage } from '@wss/model/bannerMessage';
import { LoginWidgetItem } from '@wss/model/login-widget/login-widget';
import { MessageService } from '@wss/service/message.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'bb-recover-login-details',
  templateUrl: './recover-login-details.component.html',
  styleUrls: ['./recover-login-details.component.scss'],
  providers: [ ContentService ]
})
export class RecoverLoginDetailsComponent implements OnInit {
  
  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');

  constructor(public router: Router,
    private readonly bbContentService: ContentService) { }

  ngOnInit(): void {
  }

  recoverDetails(item: string) {
    if (item == 'username') {
      this.router.navigate(['forgot-email']);
    } else {
      this.router.navigate(['forgot-password']);
    }
  }

}
