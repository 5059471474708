import {Component, Input, OnInit, ChangeDetectorRef} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {DatastoreService} from '../../../../service/datastore.service';
import {Router} from "@angular/router";
import { LoginService } from "libs/login-widget/src/service/login.service";
import { ContentService } from '@backbase/universal-ang/content';
import { headerContentWidgetItem } from "@wss/model/content/update-contact-details";
import { TealiumUtagService } from "@wss/service/utag.service";
import { patternMap } from '@wss/config/wss-app-constants';
import { isErrServer } from "@wss/common/util/util";
import { BannerMessage } from '../../../../model/bannerMessage';

@Component({
  selector: 'bb-wss-change-password',
  templateUrl: './change-password.component.html',
  providers: [ ContentService ]
})

export class ChangePasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;

  @Input()
  atLeastContainsEightChars = "close";
  @Input()
  atLeastOneUpperCase = "close";
  @Input()
  atLeastOneLowerCase = "close";
  @Input()
  atLeastOneNumber = "close"; 
  @Input()
  buttonDisabled = true;

  upperCaseLetterformat = patternMap.uppercasePattern;
  lowerCaseLetterformat = patternMap.lowercasePattern;
  numberformat = patternMap.numberPattern;
  validCharsFormat = patternMap.validSpecialCharsPattern;
  customerDetail: any; 
  personalDetails : any = {fullname:"",shortName:""};

  showPasswordError: boolean = false;
  passwordMatch: boolean = true;
  headerContentWidgetItem$: Observable<headerContentWidgetItem | undefined> = this.bbContentService.getContent<headerContentWidgetItem>('headerContentWidgetItem');
  passwordEntered: boolean = true;
  validSpecialChars: boolean = true;
  isAccountArchived:boolean = false;
  modalRef: any;
  showOldPasswordEntryError: boolean = false;
  changePasswordFailedFlag: boolean = false;
  sameAsCurrentPasswordFlag: boolean = false;
  authorisation: any;
  serviceError: boolean = false;
  bannerMessage: BannerMessage = new BannerMessage();


  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Header',
        JourneyStepName: 'Change password',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.getAuthorised();
    this.customerDetail = this.dataStore.getCustomerDetail();
    this.isAccountArchived = this.dataStore.getIsAccountArchived();
    this.personalDetails.fullname = this.customerDetail?.individual?.first_name.toLowerCase() + this.customerDetail?.individual?.surname.toLowerCase();
    this.personalDetails.shortName = this.customerDetail?.individual?.first_name[0] + this.customerDetail?.individual?.surname[0];
    this.refChange.detectChanges();
  }

  getAuthorised(): void {    
    this.datastoreService.setPwdReset(true);
    this.loginService.getAuthorisation().subscribe((data: any) => {
      this.serviceError = false;
      this.authorisation = data;
      let token = this.authorisation.headers.get('x-authorization');
      this.setHeaders(token);
    },
    (error: any) => {
      this.showError(error.status);
    });
  }

  showError(errStatus: number): void {
    if (isErrServer(errStatus)) {
      this.datastoreService.setErrorServer();
      this.serviceError = true;
      this.refChange.detectChanges();
    }
  }

  ngAfterViewChecked() {
    this.handlePasswordChange();
  }

  enableButtonWhenPasswordsAreEqual(){
    if(this.resetPasswordForm.get('confirmPassword')?.value !== "" && 
       this.resetPasswordForm.get('password')?.value !== "" &&
      (this.resetPasswordForm.get('confirmPassword')?.value === this.resetPasswordForm.get('password')?.value)
        && this.resetPasswordForm.get('oldPassword')?.value.length > 0 && !this.showOldPasswordEntryError){
      this.buttonDisabled = false;
      this.passwordMatch = true;
    } else {
      this.buttonDisabled = true;
      if (this.resetPasswordForm.get('confirmPassword')?.value.length > 0) {
        this.passwordMatch = false;
      }
    }
  }

  checkIfButtonCanBeEnabled() {
    if(this.atLeastOneNumber === 'done' &&
      this.atLeastOneLowerCase === 'done' &&
      this.atLeastOneUpperCase === 'done' &&
      this.validSpecialChars === true &&
      this.atLeastContainsEightChars === 'done'
    ){
      this.passwordEntered = false;
      this.enableButtonWhenPasswordsAreEqual();
    } else {
      this.passwordEntered = true;      
    }
  }

  constructor(
    private formBuilder: FormBuilder,      
    private datastoreService: DatastoreService,
    private loginService: LoginService,
    private router: Router,
    private refChange: ChangeDetectorRef,
    private bbContentService : ContentService,
    private dataStore: DatastoreService,
    private tealiumService: TealiumUtagService,
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      oldPassword: ['', Validators.required],
    });
  }


  onUpdatePasswordSubmit() {
    this.validateOldPassword();
  }

  passwordValueCheck(value: any, whichPwd: string) {
    let passwordError = false;
    let minPwdLength = (whichPwd === "newPwd") ? 3 : 0;

    this.isLengthEightChars(value);        
    this.hasOneUpperCaseChar(value);
    this.hasOneLowerCaseChar(value);
    this.hasOneNumber(value);
    this.hasValidChars(value);
    if (value.length > minPwdLength && (this.atLeastContainsEightChars === 'close' || this.atLeastOneLowerCase === 'close' 
      || this.atLeastOneNumber === 'close' || this.atLeastOneUpperCase === 'close' || this.validSpecialChars === false)) {
      passwordError = true;
    }
    switch (whichPwd) {
      case "newPwd":
        this.showPasswordError = (passwordError) ? true : false;
        break;
      case "oldPwd":
        this.showOldPasswordEntryError = (passwordError) ? true : false;
        break;
    }

    this.checkIfButtonCanBeEnabled();
  }

  handlePasswordChange() {
    const password = this.resetPasswordForm.get('password');
    const confirmPassword = this.resetPasswordForm.get('confirmPassword');
    const oldPassword = this.resetPasswordForm.get('oldPassword');

    oldPassword?.valueChanges.forEach(
      (value: string) => {
        this.passwordValueCheck(value, "oldPwd");
      }
    );

    password?.valueChanges.forEach(
      (value: string) => {
        this.passwordValueCheck(value, "newPwd");
      }
    );

    confirmPassword?.valueChanges.forEach(
      (value: string) => {
        this.checkIfButtonCanBeEnabled();
        if (value.length > 3 && value !== this.resetPasswordForm.controls['password'].value) {
          this.passwordMatch = false;
        } else {
          this.passwordMatch = true;
        }
      }
    );
  }

  private hasOneNumber(value: string) {
    if (value && value.match(this.numberformat)) {
      this.atLeastOneNumber = "done";
    } else {
      this.atLeastOneNumber = "close";
    }
  }

  private hasOneLowerCaseChar(value: string) {
    if (value && value.match(this.lowerCaseLetterformat)) {
      this.atLeastOneLowerCase = "done";
    } else {
      this.atLeastOneLowerCase = "close";
    }
  }

  private hasOneUpperCaseChar(value: string) {
    if (value && value.match(this.upperCaseLetterformat)) {
      this.atLeastOneUpperCase = "done";
    } else {
      this.atLeastOneUpperCase = "close";
    }
  }

  private isLengthEightChars(value: string) {
    if ((value.length >= 8) && (value.length <= 35)) {
      this.atLeastContainsEightChars = "done";
    } else {
      this.atLeastContainsEightChars = "close";
    }
  }

  private hasValidChars(value: string) {
    if (value && value.match(this.validCharsFormat)) {
      this.validSpecialChars = true;
    } else {
      this.validSpecialChars = false;
    }
  }

  getTokenFwdPwd() {
    let getURI = location.href;
    let tokenTemp = getURI.indexOf('=');
    let tokenPwd = getURI.substring(tokenTemp+1);
    this.datastoreService.setFwdPwdToken(tokenPwd);
    this.loginService.validatePwdLink().subscribe(() => {
      let validLink = this.datastoreService.getVdResetLink();
      if (validLink !== 'Valid') {
        location.href = 'login#/link-expired';
      }
    });
  }

  backToHomeClick() {
    this.router.navigate(['myaccount']);
  }
  backToMailBoxClick() {
    this.router.navigate(['mailbox']);
  }


  validateOldPassword() {
    //  Subscribe to a "check password" method and verify password..
    let cfOldPassword = btoa(unescape(encodeURIComponent(this.resetPasswordForm.get('oldPassword')?.value)));

    this.loginService.checkPwd(cfOldPassword).subscribe(() => {
      let oldPwdStatus = this.datastoreService.getConfirmPwdStatus();
      if(oldPwdStatus === 'Verified'){
        if (this.passwordIsDifferent()) {
          this.updatePassword();
        }
        else {
          this.changePasswordFailedFlag = true;
          this.sameAsCurrentPasswordFlag = true;
          this.refChange.detectChanges();
        }
      }
      else{
        this.changePasswordFailedFlag = true;
        this.sameAsCurrentPasswordFlag = false;
        this.refChange.detectChanges();
      }
    },
    (error:any) => {
      switch (error.error.code) {
        case '002':
          let errLockMsg = error.error.reason_code.indexOf('Account is locked');
          let errRecordMsg = error.error.reason_code.indexOf('Record not found');
          if (errLockMsg !== -1) {
            this.logout();
            location.href = 'login#/change-email-max-attempts';
          } else if (errRecordMsg !== -1) {
              location.href = 'login#/account-locked';
          } else {
            this.changePasswordFailedFlag = true;
            this.sameAsCurrentPasswordFlag = false;
            this.refChange.detectChanges();
          }
          break;
        case '1015':
          this.logout();
          location.href = 'login#/attempts-exceeded';
          break;
        default:
          this.changePasswordFailedFlag = true;
          this.sameAsCurrentPasswordFlag = false;
          this.refChange.detectChanges();
      }
    });
  }

  passwordIsDifferent() {
    return (this.resetPasswordForm.get('oldPassword')?.value !== this.resetPasswordForm.get('password')?.value)
  }

  updatePassword() {
    // TCC - Update password (code moved from original submit method)
    let cfPassword = btoa(unescape(encodeURIComponent(this.resetPasswordForm.get('confirmPassword')?.value)));

    this.loginService.confirmPwdLink(cfPassword, false).subscribe(() => {
      let pwdStatus = this.datastoreService.getConfirmPwdStatus();
      if(pwdStatus === 'Updated'){
        this.logout();
        location.href = 'login#/password-change-success';
      } else {
        location.href = 'login#/link-expired';
      }
    },      
    (error:any) => {        
      location.href = 'login#/link-expired';
    }); 
  }

  logout() {
    this.dataStore.sessionLogout();
  }

  setHeaders(token: any): void {
    this.datastoreService.setToken(token);
  }

  onTryAgainSubmit() {
    this.resetPasswordForm.reset();
    for (let control in this.resetPasswordForm.controls) {
      this.resetPasswordForm.controls[control].setErrors(null);
    }
    this.changePasswordFailedFlag = false;
    this.showOldPasswordEntryError = false;
    this.sameAsCurrentPasswordFlag = false;
    this.refChange.detectChanges();
  }

}
