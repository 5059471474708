<section id="br-getdetails-bank" *ngIf="balloonRefinanceGetdetailsBankItem$ | async as brGetdetailsBankItem">
    <wss-page-title [pageTitle]="brGetdetailsBankItem.balloonRefinanceGetdetailsTitle.page5Title"
        [pageName]="brGetdetailsBankItem.balloonRefinanceGetdetailsTitle.step5Title">
    </wss-page-title>
    <section class="content-width-max">
        <div class="mt-25px-mb-30px" test_id="brbTextAfterPageTitle">
            <bb-content-html [html]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.textAfterPageTitle">
            </bb-content-html>
        </div>
        <form [formGroup]="bankDetailsForm">
            <div class="card mb-10px p-20px">
                <wss-infor-tool [idExpand]="idExpandArr[0]" [idCollapse]="idCollapseArr[0]"
                    [idInforPanel]="idPanelArr[0]"
                    [lblContent]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.lblAccountName"
                    [lblInforContent]="brGetdetailsBankItem.balloonRefinanceGetdetailsTitle.inforText"
                    [inforContent]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.brbInformationAccountName"
                    [defaultTopMargin]="defaultTopMarginForAccountName">
                </wss-infor-tool>
                <div class="mt-20px">
                    <input type="text" class="form-control mb-0 w-sm-100 w-md-50" placeholder="{{brGetdetailsBankItem.balloonRefinanceGetdetailsBank.accountNamePlaceholder}}"
                        [ngClass]="submitted ? 'ng-touched' : ''" id="accountName" test_id="accountName" formControlName="accountName" maxlength="25" (focusout)="validateAccount()">
                </div>
                <div class="mt-5px text-danger" test_id="brbAccountNameError"
                    *ngIf="submitted && bankDetailsForm.controls.accountName.status === 'INVALID' && !bankDetailsForm.controls.accountName?.errors?.pattern">
                    <bb-content-html [html]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.accountNameError">
                    </bb-content-html>
                </div>                
                <div class="mt-5px text-danger" test_id="brbAccountNameInvalidCharsError"
                *ngIf="bankDetailsForm.controls.accountName?.errors?.pattern">
                    <bb-content-html [html]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.accountNameInvalidCharsError">
                    </bb-content-html>
                </div>

                <wss-infor-tool [idExpand]="idExpandArr[1]" [idCollapse]="idCollapseArr[1]"
                    [idInforPanel]="idPanelArr[1]"
                    [lblContent]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.lblSortCode"
                    [lblInforContent]="brGetdetailsBankItem.balloonRefinanceGetdetailsTitle.inforText"
                    [inforContent]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.brbInformationSortCode">
                </wss-infor-tool>
                <div class="mt-20px">
                    <input type="text" [allow]="'Number'" class="form-control w-sm-100 col-md-3 col-lg-2 mb-0" placeholder="{{brGetdetailsBankItem.balloonRefinanceGetdetailsBank.sortCodePlaceholder}}"
                        [ngClass]="submitted ? 'ng-touched' : ''" id="sortCode" formControlName="sortCode" maxlength="8"
                        (keydown)="inputSortCode($event)" (focusout)="validateAccount()">
                </div>
                <div class="mt-5px text-danger" test_id="brbSortCodeError"
                    *ngIf="submitted && bankDetailsForm.controls.sortCode.status === 'INVALID'">
                    <bb-content-html [html]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.sortCodeError">
                    </bb-content-html>
                </div>
                <wss-infor-tool [idExpand]="idExpandArr[2]" [idCollapse]="idCollapseArr[2]"
                    [idInforPanel]="idPanelArr[2]"
                    [lblContent]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.lblAccountNumber"
                    [lblInforContent]="brGetdetailsBankItem.balloonRefinanceGetdetailsTitle.inforText"
                    [inforContent]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.brbInformationAccountNumber">
                </wss-infor-tool>
                <div class="mt-20px">
                    <input type="text" [allow]="'Number'" class="form-control w-sm-100 col-md-3 col-lg-2 mb-0" placeholder="{{brGetdetailsBankItem.balloonRefinanceGetdetailsBank.accountNumberPlaceholder}}"
                        [ngClass]="submitted ? 'ng-touched' : ''" id="accountNumber" formControlName="accountNumber"
                        maxlength="8" (keyup)="validateAccount()">
                </div>
                <div class="mt-5px text-danger" test_id="brbAccountNameError"
                    *ngIf="submitted && bankDetailsForm.controls.accountNumber.status === 'INVALID'">
                    <bb-content-html [html]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.accountNumberError">
                    </bb-content-html>
                </div>
                <div class="br-warning-message d-flex space-between col-md-5" *ngIf="bankAccountStatus === 'INVALID'">
                    <span class="additonalerror-info-icon"></span>
                    <div><bb-content-html [html]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.bankAccountWarning"></bb-content-html></div>
                </div>
                <div *ngIf="bankAccountStatus === 'VALID'">
                    <div class="br-warning-message d-flex space-between col-md-5">
                        <span class="wss-checked-icon"></span>
                        <div>{{brGetdetailsBankItem.balloonRefinanceGetdetailsBank.bankAccountSuccess}} {{bankName}}</div>
                    </div>
                    <wss-infor-tool [idExpand]="idExpandArr[3]" [idCollapse]="idCollapseArr[3]"
                        [idInforPanel]="idPanelArr[3]"
                        [lblContent]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.lblLengthOfAccount"
                        [lblInforContent]="brGetdetailsBankItem.balloonRefinanceGetdetailsTitle.inforText"
                        [inforContent]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.brbInformationLengthOfAccount">
                    </wss-infor-tool>
                    <div class="flex-r">
                        <div class="mt-20px">
                            <label>{{brGetdetailsBankItem.balloonRefinanceGetdetailsBank.lblYearsOfAccount}}</label>
                            <select required class="form-control w-100 select-color-change" [ngClass]="submitted ? 'ng-touched' : ''"
                                id="accountLengthYear" formControlName="accountLengthYear" (change)="toggleMonthValidation()" aria-label="Account length year">
                                <option [value]="defaultDropdownValue" disabled selected hidden>{{brGetdetailsBankItem.balloonRefinanceGetdetailsBank.placeholderYearsOfAccount}}</option>
                                <option *ngFor="let year of years" [value]="year">{{year}}</option>
                            </select>
                        </div>
                        <div class="mt-20px div-month-select-ml">
                            <label>{{brGetdetailsBankItem.balloonRefinanceGetdetailsBank.lblMonthsOfAccount}}</label>
                            <select required class="form-control w-100 select-color-change" [ngClass]="submitted ? 'ng-touched' : ''"
                                id="accountLengthMonth" formControlName="accountLengthMonth" (change)="toggleMonthValidation()" aria-label="Account length month">
                                <option [value]="defaultDropdownValue" disabled selected hidden>{{brGetdetailsBankItem.balloonRefinanceGetdetailsBank.placeholderMonthsOfAccount}}</option>
                                <option *ngFor="let month of months" [value]="month">{{month}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="mt-5px text-danger" test_id="bankDateDOBError" *ngIf="showDateDOBErrMessage">
                        <bb-content-html [html]="brGetdetailsBankItem.balloonRefinanceGetdetailsTitle.compareDOBDateErr">
                        </bb-content-html>                        
                    </div>
                    <div class="mt-5px text-danger" test_id="brbAccountNameError"
                        *ngIf="submitted && (bankDetailsForm.controls.accountLengthMonth.status === 'INVALID' || bankDetailsForm.controls.accountLengthYear.status === 'INVALID')">
                        <bb-content-html
                            [html]="brGetdetailsBankItem.balloonRefinanceGetdetailsBank.lengthOfAccountError">
                        </bb-content-html>
                    </div>
                </div>
            </div>
        </form>
        <div class="mb-40px">
            <wss-common-nav-buttons [lastDateOfDecision]="lastDateOfDecision" [content]="brGetdetailsBankItem.balloonRefinanceGetdetailsNavBtns"
                [accountsDetails]="accountDetails" [previousPageLink]="previousPageLink"
                (primaryButtonClicked)="onSaveButtonClick($event)" (continueButtonClicked)="onContinue($event)"
                [testIdText]="commonNavTestID">
            </wss-common-nav-buttons>
        </div>
    </section>
</section>