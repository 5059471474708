import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccoutSummaryService } from '@wss/account-summary-widget/src/services/account-summary.service';
import { isErrServer } from '@wss/common/util/util';
import { appConfig } from '@wss/config/wss-app-constants';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { DatastoreService } from '@wss/service/datastore.service';
import { MessageService } from '@wss/service/message.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EndOfContractService } from '../../services/end-of-contract.service';
import { BannerMessage } from 'libs/model/bannerMessage';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-eoc-keep-your-vehicle',
  templateUrl: 'eoc-keep-your-vehicle.component.html'
})
export class EocKeepYourVehicleComponent implements OnInit {

  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');
  // eocDetails: any = {
  //   final_payment_amount: "5000",
  //   final_payment_date: "21/10/2021",
  //   in_arrears: true,
  //   file_tag: '99',
  //   goods_code: '87'
  // };

  eocDetails: any = {};
  withinNinetyDays: boolean = false;
  serviceError: boolean = false;
  readonly destroy$ = new Subject();
  // fileTagFlag: boolean = false;
  // inGoodsScope: boolean = false;
  bannerMessage: BannerMessage = new BannerMessage();
  endOfContractWidgetItem: any;
  isAgreementInArrears: boolean = false;
  public open: boolean = false;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private readonly bbContentService: ContentService,
    private eocService: EndOfContractService,
    private datastoreService: DatastoreService,
    private messageService: MessageService,
    private tealiumService: TealiumUtagService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'Keep Your Vehicle Landing',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.eocDetails = this.datastoreService.getAccountDetails();
    this.withinNinetyDays = this.eocService.isContractWithinNintyDays();
    this.isAgreementInArrears = !(!this.eocDetails?.in_arrears || (this.eocDetails?.in_arrears && this.eocDetails?.months_in_arrears == 0));
    this.handleErrors();
  }

  openModal(modalContent: any) {
    this.modalService.open(modalContent, { centered: true, windowClass: 'eoc-modal' });
  }

  confirmSelection() {
    this.modalService.dismissAll();
    this.eocService.saveKeepYourVehicleOption()
      .subscribe((res) => {
        this.serviceError = false;
        this.datastoreService.setEocRoute("confirmation");
        this.messageService.setActiveNav("End of contract");
        this.router.navigate(['/eoc-options/confirmation']);
        window.scroll(0, 0);
      }, (error) => {
        this.handleErrors();
      });
  }

  handleErrors() {
    this.messageService.getEOCServiceError().pipe(takeUntil(this.destroy$)).subscribe((error: any) => {
      this.showError(error?.status || error?.code);
    });
  }

  // showError(errStatus: number): void {
  //   if (isErrServer(errStatus)) {
  //     this.datastoreService.setErrorServer();
  //     this.serviceError = true;
  //     this.changeDetectorRef.detectChanges();
  //   }
  // }

  showError(errStatus: number): void {
    if (isErrServer(errStatus) || errStatus == 506) {
      this.bannerMessage.closeIconClass = 'clear';
      this.bannerMessage.infoTypeClass = 'error-icon';
      this.bannerMessage.leftSectionColorClass = 'message-failure';
      this.endOfContractWidgetItem$?.subscribe((res: any) => {
        this.endOfContractWidgetItem = res;
        this.bannerMessage.titleText = this.endOfContractWidgetItem?.endOfContractKeepVehicle.eocErrorbannerMessage.titleTextBeforeData;
        this.bannerMessage.subtitleText = this.endOfContractWidgetItem?.endOfContractKeepVehicle.eocErrorbannerMessage.subtitleTextBeforeData + this.endOfContractWidgetItem?.brandContactDetails.brandWiseContactNumber + this.endOfContractWidgetItem?.endOfContractKeepVehicle.eocErrorbannerMessage.subtitleTextAfterData;
        this.bannerMessage.isBannerClosed = false;
        this.messageService.setBannerMessage(this.bannerMessage);
        this.serviceError = true;
        window.scroll(0, 0);
      }
      )
    }
    else {
      this.serviceError = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  navigateToPage(modalContent: any) {
    if (this.isAgreementInArrears || this.eocDetails?.customer_intention === 'R' || this.eocDetails?.customer_intention === 'T' ||
      this.eocDetails?.customer_intention === 'P' || this.eocDetails?.customer_intention === 'E') {
      this.datastoreService.setEocRoute("get-in-touch");
        this.messageService.setActiveNav("End of contract");
      this.router.navigate(['/eoc-options/get-in-touch']);
    } else if (this.eocDetails?.customer_intention === 'F') {
      this.datastoreService.setEocRoute("confirmation");
        this.messageService.setActiveNav("End of contract");
      this.router.navigate(['/eoc-options/confirmation'])
    } else if (!this.withinNinetyDays) {
      this.datastoreService.setEocRoute("get-in-touch");
        this.messageService.setActiveNav("End of contract");
      this.router.navigate(['/eoc-options/get-in-touch']);
    } else {
    this.openModal(modalContent)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.modalService.dismissAll();
  }
  
}
