<div class="payment-holiday-container term-ext-container" *ngIf="paymentHolidayWidgetItem$ | async as paymentHolidayWidgetItem">
    <div class="container-row" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="container-column mb-90px" test_id="pageText">
        <div class="container-row section-title-container">
            <span class="section-title" test_id="pageName">{{paymentHolidayWidgetItem.currentAgreement.pageName}}</span>
        </div>
        <bb-content-html [html]="paymentHolidayWidgetItem.currentAgreement.pageTextHP" *ngIf="accountDetail?.scheme_code === typeHP"></bb-content-html>
        <bb-content-html [html]="paymentHolidayWidgetItem.currentAgreement.pageTextPCP" *ngIf="accountDetail?.scheme_code === typePCP"></bb-content-html>
        <div class="container-column text-small pull-top-mgn">
            <div class="responsive-row-term paragraph">
                <div class="container-column">
                    <div class="container-column mt-13px" test_id="monthlyPaymentLabel">
                        <bb-content-html [html]="paymentHolidayWidgetItem.currentAgreement.monthlyPaymentLabel"></bb-content-html>
                        <span class="text-semibold" test_id="currentMonthlyPmt">£{{currentMonthlyPmt.toFixed(2)}}</span>
                    </div>
                    <div class="container-column mt-13px" test_id="noOfPaymentsLeftLabel">
                        <bb-content-html [html]="paymentHolidayWidgetItem.currentAgreement.noOfPaymentsLeftLabel"></bb-content-html>
                        <span class="text-semibold" test_id="num_payments_left">{{accountDetail?.num_payments_left}}</span>
                    </div>
                    <div class="container-column mt-13px" *ngIf="accountDetail?.scheme_code === typePCP" test_id="optionalPaymentAmountLabel">
                        <bb-content-html [html]="paymentHolidayWidgetItem.currentAgreement.optionalPaymentAmountLabel"></bb-content-html>
                        <span class="text-semibold" test_id="final_payment_amount">{{accountDetail?.final_payment_amount}}</span>
                    </div>
                </div>
                <div class="container-column">
                    <div class="container-column mt-13px" *ngIf="accountDetail?.scheme_code === typePCP" test_id="optionalPaymentDateLabel">
                        <bb-content-html [html]="paymentHolidayWidgetItem.currentAgreement.optionalPaymentDateLabel"></bb-content-html>
                        <span class="text-semibold" test_id="final_payment_date">{{accountDetail?.final_payment_date}}</span>
                    </div>
                    <div class="container-column mt-13px" test_id="aprLabel">
                        <bb-content-html [html]="paymentHolidayWidgetItem.currentAgreement.aprLabel"></bb-content-html>
                        <span class="text-semibold" test_id="apr">{{accountDetail?.apr}}</span>
                    </div>
                    <div class="container-column mt-13px" test_id="totalOutstandingLabel">
                        <bb-content-html [html]="paymentHolidayWidgetItem.currentAgreement.totalOutstandingLabel"></bb-content-html>
                        <span class="text-semibold" test_id="current_balance">{{accountDetail?.current_balance}}</span>
                    </div>
                </div>
            </div>
        </div>
        <hp-options [hpOptions]="paymentHolidayWidgetItem.currentAgreement.hpOptions" [currentMonthly]="currentMonthlyPmt" *ngIf="accountDetail?.scheme_code === typeHP" (showError)="showError($event)"></hp-options>
        <pcp-options [pcpOptions]="paymentHolidayWidgetItem.currentAgreement.pcpOptions" [currentMonthly]="currentMonthlyPmt" *ngIf="accountDetail?.scheme_code === typePCP" (showError)="showError($event)"></pcp-options>
    </div>
</div>

<!-- <div class="payment-holiday-container term-ext-container">
    <div class="container-row" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="container-column mb-90px">
        <div class="container-row section-title-container">
            <span class="section-title">Term Extension</span>
        </div>
        <span class="mt-35px" *ngIf="accountDetail?.scheme_code === typeHP">To help you decide what your new monthly payment amount could be, we've created a handy tool. Enter an amount so you can see the impact it will have on your agreement.</span>
        <span class="mt-35px" *ngIf="accountDetail?.scheme_code === typePCP">To help you decide what your new monthly payment amount could be, we've created a handy tool. Select the option that works best for you.</span>
        <br>
        <span class="text-semibold text-bigger">Your current agreement</span>
        <div class="container-column text-small">
            <div class="responsive-row-term">
                <div class="container-column">
                    <div class="container-column mt-17px">
                        <span>Current monthly payment amount:</span>
                        <span class="text-semibold">£{{currentMonthlyPmt.toFixed(2)}}</span>
                    </div>
                    <div class="container-column mt-16px">
                        <span>Number of payments left on agreement:</span>
                        <span class="text-semibold">{{accountDetail?.num_payments_left}}</span>
                    </div>
                    <div class="container-column mt-13px" *ngIf="accountDetail?.scheme_code === typePCP">
                        <span>Final optional payment amount*:</span>
                        <span class="text-semibold">{{accountDetail?.final_payment_amount}}</span>
                    </div>
                </div>
                <div class="container-column">
                    <div class="container-column mt-13px" *ngIf="accountDetail?.scheme_code === typePCP">
                        <span>Final optional payment amount date:</span>
                        <span class="text-semibold">{{accountDetail?.final_payment_date}}</span>
                    </div>
                    <div class="container-column mt-13px">
                        <span>APR:</span>
                        <span class="text-semibold">{{accountDetail?.apr}}</span>
                    </div>
                    <div class="container-column mt-14px">
                        <span>Total amount outstanding:</span>
                        <span class="text-semibold">{{accountDetail?.current_balance}}</span>
                    </div>
                </div>
            </div>
        </div>
        <hp-options [currentMonthly]="currentMonthlyPmt" *ngIf="accountDetail?.scheme_code === typeHP" (showError)="showError($event)"></hp-options>
        <pcp-options [currentMonthly]="currentMonthlyPmt" *ngIf="accountDetail?.scheme_code === typePCP" (showError)="showError($event)"></pcp-options>
    </div>
</div> -->