<div class="navigation-bar" *ngIf="navigationWidgetItem$ | async as navigationWidgetItem">
    <div [ngClass]="isAccountArchived? ' navigation-bar-disable': 'container-column-more'" >
        <div class="container-row">   

            <div role="button" test_id="my_account_nav_id" class="container-column"
                (click)="selectedNav(navItemList[0], 'myaccount', true);"
                [ngClass]="navItemList[0].isActive? 'container-column navigation-bar-bottom-active': 'container-column menu-inactive'">
                
                <span class="material-icons">person_outline</span>
                <span class="navigation-link para-inline" test_id="my_account_anchor_id" title="Home">                    
                    <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoHome"></bb-content-html>
                </span>

            </div>

            <div role="button" test_id="settlement_nav_id"
                (click)="selectedNav(navItemList[1], 'payments/payment-settlement', true);"
                [ngClass]="navItemList[1].isActive? 'container-column navigation-bar-bottom-active': 'container-column menu-inactive'">
                
                <span class="material-icons">file_download_done</span>
                <span class="navigation-link para-inline" test_id="settlement_anchor_id" title="Settlement">
                    <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoSettlemnt"></bb-content-html>
                </span>

            </div>

            <div role="button" test_id="payments_nav_id" (click)="selectedNav(navItemList[2], 'payments', true);"
                [ngClass]="navItemList[2].isActive? 'container-column navigation-bar-bottom-active': 'container-column menu-inactive'">
                
                <span *ngIf="portalName !== 'internationalmotors'">
                    <span class="payments-icon"></span>  
                </span> 
                <span *ngIf="portalName === 'internationalmotors'">
                    <span [ngClass]="navItemList[2].isActive? 'payments-icon' : 'payments-icon-black'"></span>
                </span>              
                <span class="navigation-link para-inline" test_id="payments_anchor_id" title="Payments">                    
                    <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoPayment"></bb-content-html>
                </span>

            </div>

            <div role="button" test_id="transactions_nav_id"
                (click)="selectedNav(navItemList[3], 'transactions', true);"
                [ngClass]="navItemList[3].isActive? 'container-column navigation-bar-bottom-active': 'container-column menu-inactive'">
                
                <span class="material-icons">clear_all</span>
                <span class="navigation-link  para-inline" test_id="transactions_anchor_id" title="Transactions">                    
                    <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoTransaction"></bb-content-html>
                </span>

            </div>

            <div *ngIf="showEoc" role="button" test_id="eoc_tab_nav_id"
                (click)="selectedNav(navItemList[5], eocNavigationLink, true);"
                [ngClass]="navItemList[5].isActive? 'container-column navigation-bar-bottom-active': 'container-column menu-inactive'">
                
                <span class="material-icons">alarm_on</span>
                <span class="navigation-link  para-inline" test_id="eoc_tab_text_id" title="EOC" id="wss-nav-eoc-link1">                    
                    <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoEOC"></bb-content-html>
                </span>

            </div>
			
            <div role="button" test_id="help_center_nav_id"
                (click)="selectedNav(navItemList[4], 'help-centre', true);"
                [ngClass]="navItemList[4].isActive? 'container-column navigation-bar-bottom-active': 'container-column menu-inactive'">
                
                <span *ngIf="portalName !== 'internationalmotors'">
                    <span class="help-center-icon"></span>                    
                </span>
                <span *ngIf="portalName === 'internationalmotors'">
                    <span [ngClass]="navItemList[4].isActive? 'help-center-icon' : 'help-center-icon-black'"></span>
                </span>
                <span class="navigation-link  para-inline" test_id="help_center_text_id" title="Help centre">
                    <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoHelplink"></bb-content-html>
                </span>

            </div>

            <div class="container-row-more" *ngIf="isMoreActive">
                
                <div  role="button" title="EOC" test_id="eoc_tab_nav_id"
                    (click)="selectedNav(navItemList[5], eocNavigationLink, true);"
                    [ngClass]="navItemList[5].isActive? 'sub-nav navigation-bar-bottom-active': 'sub-nav'" *ngIf="showEoc">
                    
                    <span class="material-icons">alarm_on</span>
                    <span test_id="eoc_tab_text_id" class="text-color sub-nav-text" id="wss-nav-eoc-link2">
                        <span class="navigation-link para-inline">
                            <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoEOC"></bb-content-html>
                        </span>
                    </span>
                    <span class="material-icons chevron">chevron_right</span>

                </div>

                <div role="button" title="Help centre" test_id="help_center_nav_id"
                    (click)="selectedNav(navItemList[4], 'help-centre', true);"
                    [ngClass]="navItemList[4].isActive? 'sub-nav navigation-bar-bottom-active': 'sub-nav'">               
                    
                    <span *ngIf="portalName !== 'internationalmotors'">
                        <span class="wss-help-center-icon"></span>
                    </span>
                    <span *ngIf="portalName === 'internationalmotors'">
                        <span [ngClass]="navItemList[4].isActive? 'wss-help-center-icon' : 'wss-help-center-icon-black'"></span>
                    </span>
                    <span test_id="help_center_text_id" class="text-color sub-nav-text help-text-flex">
                        <span class="navigation-link para-inline">
                            <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoHelplink"></bb-content-html>
                        </span>
                    </span>
                    <span class="material-icons chevron">chevron_right</span>

                </div>                
            </div>
        </div>
    </div>
</div>

<div class="navigation-bar-bottom" *ngIf="navigationWidgetItem$ | async as navigationWidgetItem">
    <div [ngClass]="isAccountArchived? 'container-column-more navigation-bar-disable': 'container-column-more more'" >
        <div class="container-row-more" *ngIf="isMoreActive">

            <div *ngIf="showEoc" role="navigation" title="EOC" test_id="eoc_mobile_nav_id"
                (click)="selectedNav(navItemList[5], eocNavigationLink, true);"
                [ngClass]="navItemList[5].isActive? 'sub-nav navigation-bar-bottom-active': 'sub-nav'">
                
                <span class="material-icons" [ngClass]="portalName === 'internationalmotors'? 'color-white': ''">alarm_on</span>
                <span test_id="eoc_mobile_text_id" class="text-color sub-nav-text" id="wss-nav-eoc-link3">
                    <span class="navigation-link para-inline">
                        <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoEOC"></bb-content-html>
                    </span>
                </span>
                <span class="material-icons chevron">chevron_right</span>

            </div>

            <div role="navigation" title="Help centre" test_id="help_center_nav_id"
                (click)="selectedNav(navItemList[4], 'help-centre', true);"
                [ngClass]="navItemList[4].isActive? 'sub-nav navigation-bar-bottom-active': 'sub-nav'">
                
                <span *ngIf="portalName !== 'internationalmotors'">
                    <span class="wss-help-center-icon"></span>
                </span>
                <span *ngIf="portalName === 'internationalmotors'">
                    <span class="wss-help-center-icon" [ngClass]="navItemList[4].isActive? '' : 'wss-help-center-icon-black'"></span>
                </span>
                <span test_id="help_center_text_id" class="text-color sub-nav-text help-text-flex">
                    <span class="navigation-link para-inline">
                        <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoHelplink"></bb-content-html>
                    </span>
                </span>
                <span class="material-icons chevron">chevron_right</span>

            </div>

        </div>

        <div class="container-row">

            <div role="navigation" test_id="my_account_nav_id" class="container-column"
                (click)="selectedNav(navItemList[0], 'myaccount', true);"
                [ngClass]="navItemList[0].isActive? 'container-column navigation-bar-bottom-active': 'container-column'">
                
                <span class="material-icons">person_outline</span>
                <span class="navigation-link para-inline" test_id="my_account_anchor_id" title="Home">                   
                    <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoHome"></bb-content-html>
                </span>

            </div>

            <div role="navigation" test_id="settlement_nav_id"
                (click)="selectedNav(navItemList[1], 'payments/payment-settlement', true);"
                [ngClass]="navItemList[1].isActive? 'container-column navigation-bar-bottom-active': 'container-column'">
                
                <span class="material-icons">file_download_done</span>
                <span class="navigation-link  para-inline" test_id="settlement_anchor_id" title="Settlement">                    
                    <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoSettlemnt"></bb-content-html>
                </span>

            </div>

            <div role="navigation" test_id="payments_nav_id" (click)="selectedNav(navItemList[2], 'payments', true);"
                [ngClass]="navItemList[2].isActive? 'container-column navigation-bar-bottom-active': 'container-column'">
                
                <span *ngIf="portalName !== 'internationalmotors'" class="payments-icon">                    
                </span> 
                <span *ngIf="portalName === 'internationalmotors'" [ngClass]="navItemList[2].isActive? 'payments-icon' : 'payments-icon-black'">                   
                </span>             
                <span class="navigation-link para-inline" test_id="payments_anchor_id" title="Payments">                    
                    <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoPayment"></bb-content-html>
                </span>

            </div>

            <div role="navigation" test_id="transactions_nav_id"
                (click)="selectedNav(navItemList[3], 'transactions', true);"
                [ngClass]="navItemList[3].isActive? 'container-column navigation-bar-bottom-active': 'container-column'">
                
                <span class="material-icons">clear_all</span>
                <span class="navigation-link para-inline" title="Transactions" test_id="transactions_anchor_id">                    
                    <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoTransaction"></bb-content-html>
                </span>

            </div>

            <div *ngIf="!showEoc" role="navigation" test_id="help_center_nav_id"
                (click)="selectedNav(navItemList[4], 'help-centre', true);"
                [ngClass]="navItemList[4].isActive? 'container-column navigation-bar-bottom-active': 'container-column menu-inactive'">
                 
                <span *ngIf="portalName !== 'internationalmotors'">
                     <span class="wss-help-center-icon"></span>                    
                 </span>
                 <span *ngIf="portalName === 'internationalmotors'">
                     <span [ngClass]="navItemList[4].isActive? 'wss-help-center-icon' : 'wss-help-center-icon-black'"></span>
                 </span>
                <span class="navigation-link  para-inline" test_id="help_center_text_id" title="Help centre">                    
                    <bb-content-html [html]="navigationWidgetItem.navigationBar.linktoHelplink"></bb-content-html>
                </span>

            </div>
            
            <div *ngIf="showEoc" role="navigation" test_id="help_center_nav_id" (click)="showMore()" class="container-column">
                
                <span class="material-icons" *ngIf="!isMoreActive">more_vert</span>
                <span class="material-icons" *ngIf="isMoreActive">clear</span>
                <a class="navigation-link more-text-color" title="More" test_id="more_anchor_id">More</a>
                
            </div>

        </div>
    </div>
</div>