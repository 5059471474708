<!-- *ngIf="digitalInboxWidgetItem$ | async as digitalInboxWidgetItem" -->
<div class="mail-preference-container">
    <div class="container-row" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="container-column">
        <div class="container-row section-title-container">
            <span class="section-title" test_id="pageName">{{digitalInboxWidgetItem.managePreference.pageName}}</span>
        </div>
        <!-- <span class="mt-16px para-inline">You are currently getting your letters and statements:</span> -->
        <bb-content-html class="mt-16px para-inline" [html]="digitalInboxWidgetItem.managePreference.pageTopText"></bb-content-html>
        <div class="multiple-accounts-container mt-13px">
            <div class="container-row multiple-accounts-card section-card-border-color">
                <div class="column-blue section-icon-background-color"></div>
                <div class="car-flex-width section-icon-color">
                    <span class="wss-car-icon"></span>
                </div>
                <div class="container-column agreement-text-column">
                    <!-- *ngIf="otherAccounts?.length === 0" -->
                    <div class="mt-9px">
                        <bb-content-html *ngIf="currentAccount?.is_paperless" class="para-inline" [html]="digitalInboxWidgetItem.managePreference.selectedPreferencePaperlessText"></bb-content-html>
                        <bb-content-html *ngIf="!currentAccount?.is_paperless" class="para-inline" [html]="digitalInboxWidgetItem.managePreference.selectedPreferencePostText"></bb-content-html>
                    </div>
                    <div class="container-column">
                            <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.managePreference.agreementNumberText"></bb-content-html>
                        <span test_id="loginWidgetAccountNumberContent" data-cs-mask>
                            {{currentAccount?.account_number}}
                        </span>
                    </div>
                    <div class="container-column" test_id="loginWidgetregistrationNo">
                            <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.managePreference.vehicleAndRegistrationText"></bb-content-html>
                        <span test_id="vehicle_n_registration_value_id" data-cs-mask>
                            {{currentAccount?.goods_description}} {{currentAccount?.registration_no}}
                        </span>
                    </div>
                    
                </div>
                <div class="arrow-column">
                    <span test_id="right_arrow_id" class="arrow-pull-left"></span>
                </div>
            </div>
        </div>

        <div class="mt-22px container-column">
            <bb-content-html [html]="digitalInboxWidgetItem.managePreference.textAboveOption"></bb-content-html>
        </div>

        <div class="container-row paper-paperless-switch mt-9px" *ngIf="currentAccount">
            <div role="switch" [ngClass]="!currentAccount?.checked? 'container-row paper active': 'container-row paper'" (click)="toggleView(currentAccount,'paper')">
                <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.managePreference.optionByPostText"></bb-content-html>
                <!-- <span class="material-icons" title="List View">list</span> -->
            </div>
            <div role="switch" [ngClass]="currentAccount?.checked? 'container-row paperless active': 'container-row paperless'"
                (click)="toggleView(currentAccount,'paperless')">
                <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.managePreference.optionPaperlessText"></bb-content-html>
                <!-- <span class="material-icons" title="Grid View">view_module</span> -->
            </div>
        </div>
        <div class="container-column mt-30px" *ngIf="otherAccounts?.length">
            <bb-content-html [html]="digitalInboxWidgetItem.managePreference.otherAgreementsText"></bb-content-html>
            <div class="multiple-accounts-container multi-agreement mt-20px">
                <ng-container *ngFor="let account of otherAccounts">
                    <div class="container-row multiple-accounts-card section-card-border-color">
                        <div class="column-blue section-icon-background-color"></div>
                        <div class="car-flex-width section-icon-color">
                            <span class="wss-car-icon"></span>
                        </div>
                        <div class="container-column agreement-text-column">
                            <!-- *ngIf="otherAccounts?.length === 0" -->
                            <div class="mt-9px">
                                <bb-content-html *ngIf="account?.is_paperless" class="para-inline" [html]="digitalInboxWidgetItem.managePreference.selectedPreferencePaperlessText"></bb-content-html>
                                <bb-content-html *ngIf="!account?.is_paperless" class="para-inline" [html]="digitalInboxWidgetItem.managePreference.selectedPreferencePostText"></bb-content-html>
                            </div>
                            <div class="container-column">
                                    <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.managePreference.agreementNumberText"></bb-content-html>
                                <span test_id="loginWidgetAccountNumberContent" data-cs-mask>
                                    {{account?.account_number}}
                                </span>
                            </div>
                            <div class="container-column" test_id="loginWidgetregistrationNo">
                                    <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.managePreference.vehicleAndRegistrationText"></bb-content-html>
                                <span test_id="vehicle_n_registration_value_id" data-cs-mask>
                                    {{account?.goods_description}} {{account?.registration_no}}
                                </span>
                            </div>
                            <!-- <div class="mt-9px" *ngIf="otherAccounts?.length === 0">
                                <bb-content-html *ngIf="account?.is_paperless" class="para-inline" [html]="digitalInboxWidgetItem.managePreference.selectedPreferencePaperlessText"></bb-content-html>
                                <bb-content-html *ngIf="!account?.is_paperless" class="para-inline" [html]="digitalInboxWidgetItem.managePreference.selectedPreferencePostText"></bb-content-html>
                            </div> -->
                        </div>
                        <div class="arrow-column">
                            <span test_id="right_arrow_id" class="arrow-pull-left"></span>
                        </div>
                    </div>
                    <div class="container-row paper-paperless-switch mt-20px">
                        <div role="switch" [ngClass]="!account?.checked? 'container-row paper active': 'container-row paper'" (click)="toggleView(account,'paper')">
                            <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.managePreference.optionByPostText"></bb-content-html>
                        </div>
                        <div role="switch" [ngClass]="account?.checked? 'container-row paperless active': 'container-row paperless'"
                            (click)="toggleView(account,'paperless')">
                            <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.managePreference.optionPaperlessText"></bb-content-html>
                        </div>
                    </div>
                </ng-container>
                
            </div>
        </div>
        <div class="mt-20px container-column">
            <bb-content-html class="" [html]="digitalInboxWidgetItem.managePreference.textBelowOption"></bb-content-html>
        </div>

        <div class="container-column">
            <ng-container *ngIf="deviceType === 'Desktop'">
                <div>
                    <bb-content-html [html]="digitalInboxWidgetItem.managePreference.accordionTitleText"></bb-content-html>
                    <bb-content-html [html]="digitalInboxWidgetItem.managePreference.accordionContentText"></bb-content-html>
                </div>
            </ng-container>
            <ng-container *ngIf="deviceType !== 'Desktop'">
                <div class="container-row transparent-accordion expansion-border-hidden">
                    <mat-accordion class="accordion mat-accordion-override w-100 finalize" role="accordion">
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override" test_id="accordionContent">
                            <mat-expansion-panel-header class="mat-panel-header-override">
                                <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="accordionTitle">
                                    <bb-content-html [html]="digitalInboxWidgetItem.managePreference.accordionTitleText"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <bb-content-html [html]="digitalInboxWidgetItem.managePreference.accordionContentText"></bb-content-html>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-container>
        </div>

        <div class="mt-12px container-column">
            <bb-content-html [html]="digitalInboxWidgetItem.managePreference.importantInfoText"></bb-content-html>
        </div>
        
        <div class="container-column desktop-btn-links">
            <div class="container-row desktop-button-center">
                <button (click)="navigateToConfirmPreference()" class="wss-button" tabindex="0" role="button" [disabled]="!checkIfPreferenceChanged()">{{digitalInboxWidgetItem.managePreference.nextButtonText}}</button>
            </div>
            <div class="flex-horizontal-center text-center">
                <bb-content-html [html]="digitalInboxWidgetItem.managePreference.pageBottomLinks"></bb-content-html>
            </div>
        </div>
    </div>
</div>