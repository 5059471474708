<div *ngIf="endOfContractWidgetItem$ | async as endOfContractWidgetItem">
    <div class="text-center p-40px">
        <div class="get-in-touch-icon"></div>
        <div class="font-weight-bold mb-10px" test_id="title"
            *ngIf="(withinNinetyDays && (isAgreementInArrears || (accountDetails.customer_intention === 'F' || accountDetails.customer_intention === 'T' || accountDetails.customer_intention === 'R' || accountDetails.customer_intention === 'P' || accountDetails.customer_intention === 'E')))">
            <bb-content-html [html]="endOfContractWidgetItem.endOfContractGetInTouch.title">
            </bb-content-html>
        </div>
        <div class="font-weight-bold mb-10px" test_id="title" *ngIf="!withinNinetyDays">
            <bb-content-html [html]="endOfContractWidgetItem.endOfContractGetInTouch.hpOver90DaysTittleText">
            </bb-content-html>
        </div>
        <section class="eoc-container">
            <div *ngIf="(withinNinetyDays && isAgreementInArrears)" class="p-mb-10px">
                <p>{{ endOfContractWidgetItem.endOfContractGetInTouch.subTitle }}</p>
            </div>
            <div *ngIf="!withinNinetyDays" class="p-mb-10px">
                <p>{{ endOfContractWidgetItem.endOfContractGetInTouch.HPOver90DaysSubTitleTextBeforeData }}
                    <span>{{accountDetails.earliest_contact_date}}.</span>
                    {{ endOfContractWidgetItem.endOfContractGetInTouch.HPOver90DaysSubTitleTextAfterData }}
                </p>
            </div>
            <div class="p-mb-10px"
                *ngIf="(withinNinetyDays && (!isAgreementInArrears && (accountDetails.customer_intention === 'F' || accountDetails.customer_intention === 'T' || accountDetails.customer_intention === 'R' || accountDetails.customer_intention === 'P' || accountDetails.customer_intention === 'E')))">
                <bb-content-html
                    [html]="endOfContractWidgetItem.endOfContractGetInTouch.getInTouchDescriptionForIntention">
                </bb-content-html>
            </div>
            <div class="d-flex justify-content-center"
                *ngIf="(withinNinetyDays && (isAgreementInArrears || (accountDetails.customer_intention === 'F' || accountDetails.customer_intention === 'T' || accountDetails.customer_intention === 'R' || accountDetails.customer_intention === 'P' || accountDetails.customer_intention === 'E')))">
                <button class="wss-button" test_id="buttonLabel" routerLink="/help-centre/get-in-touch">
                    {{ endOfContractWidgetItem.endOfContractGetInTouch.buttonLabel }}
                </button>
            </div>
            <div class="d-flex justify-content-center" *ngIf="!withinNinetyDays">
                <button class="wss-button" test_id="buttonLabel" routerLink="/eoc-options/landing">
                    {{ endOfContractWidgetItem.endOfContractGetInTouch.backToOptionsLinkText }}
                </button>
            </div>
            <div
                *ngIf="(withinNinetyDays && (isAgreementInArrears || (accountDetails.customer_intention === 'F' || accountDetails.customer_intention === 'T' || accountDetails.customer_intention === 'R' || accountDetails.customer_intention === 'P' || accountDetails.customer_intention === 'E')))">
                <a routerLink="/eoc-options/landing" test_id="backToOptionsLinkText">
                    {{ endOfContractWidgetItem.endOfContractGetInTouch.backToOptionsLinkText }}
                </a>
            </div>
        </section>
    </div>
</div>