<div *ngIf="registrationWidgetItem$ | async as registrationWidgetItem">
  <div class="reg-container">
    <form [formGroup]="registrationForm">
      <div *ngIf="multipleUser">
        <div class="reg-row" role="row">
          <div class="agr-txt">
            <bb-content-html [html]="registrationWidgetItem.userSearch.needLittleMoreDetails"></bb-content-html>
          </div>
        </div>
        <div class="agr-lbl" role="row">
          <bb-input-text-ui label="What is your Agreement Number?​​" formControlName="agreementno"
            placeholder="Agreement number here" autoComplete="given-name" class="lbl-txt" [maxLength]="9">
          </bb-input-text-ui>
          <div role="alert" class="error-text" *ngIf="!registrationForm.controls.agreementno.valid">
            <bb-content-html [html]="registrationWidgetItem.userSearch.EnterValidAgreementNumber"></bb-content-html>
          </div>
          <div>
            <a [ngbPopover]="popContentAgreement" #pAgreement="ngbPopover" (click)="openPopover(pAgreement, $event)" class="link-text text-underline margin-all-auto help-txt text-align-left"
              autoClose="outside" triggers="manual" popoverClass="popover-apr">
              <bb-content-html [html]="registrationWidgetItem.userSearch.whereToFindAgreementNumber"></bb-content-html>
            </a>
          </div>
        </div>
      </div>
      <div class="reg-row" role="row">
        <div>
          <p class="text-design" test_id="pageHeaderText">
            <bb-content-html [html]="registrationWidgetItem.userSearch.pageHeaderText"></bb-content-html></p>
          <!--<bb-content key="rgSearchTxtTop"></bb-content>-->
        </div>
      </div>
      <div class="reg-row" role="alert" *ngIf="serviceError">
        <bb-wss-banner-message [errorCode]="errorCode"></bb-wss-banner-message>
      </div>      
      <div class="reg-row" role="row">
        <div class="what-is-your-date-of">
          <p class="text-design" test_id="whatsDobTitle">
            <bb-content-html [html]="registrationWidgetItem.userSearch.whatsDobTitle"></bb-content-html></p>
        </div>
      </div>
      <div class="group-7">
        <div class="group-4">
          <bb-input-text-ui label="{{registrationWidgetItem.userSearch.day}}" formControlName="day"
            [aria-describedby]="'input-day-showcase-error'" placeholder="DD" [maxLength]="2" [autoComplete]="'off'"
            (focusout)="showHideError()">
          </bb-input-text-ui>
        </div>
        <div class="group-5">
          <bb-input-text-ui label="{{registrationWidgetItem.userSearch.month}}" formControlName="month"
            [aria-describedby]="'input-month-showcase-error'" placeholder="MM" [maxLength]="2" [autoComplete]="'off'"
            (focusout)="showHideError()">
          </bb-input-text-ui>
        </div>
        <div class="group-6">
          <bb-input-text-ui label="{{registrationWidgetItem.userSearch.year}}" formControlName="year"
            [aria-describedby]="'input-year-showcase-error'" placeholder="YYYY" [maxLength]="4" [autoComplete]="'off'"
            (focusout)="showHideError()">
          </bb-input-text-ui>
        </div>
      </div>
      
      <div role="alert" class="error-text-bold" *ngIf="hasDOBError" test_id="dateError">
        {{registrationWidgetItem.userSearch.dateError}}
      </div>
      <div class="what-is-your-post-co">
        <bb-input-text-ui label="{{registrationWidgetItem.userSearch.postcode}}" formControlName="postcode" placeholder="E.g. CF23 8GF"
          [autoComplete]="'off'" class="lbl-txt" (focusout)="showHideError()">
        </bb-input-text-ui>
        
        <div role="alert" class="error-text" *ngIf="hasPostCodeError" test_id="postcodeError">
          {{registrationWidgetItem.userSearch.postcodeError}}
        </div>
      </div>
      <div class="this-should-be-the-p">
        <div test_id="postCodeChangeFaqsText">
          <bb-content-html [html]="registrationWidgetItem.userSearch.postCodeChangeFaqsText"></bb-content-html>
        </div>
      </div>
      <!-- <div class="reg-row" role="row"> -->
      <div class="text-center" role="row">
        <button id="registration-screen-btn" bbButton type="button" aria-label="Favourite" class="wss-button" (click)="submit()"
          [disabled]="submitBtn" test_id="nextButtonText">{{registrationWidgetItem.userSearch.nextButtonText}}</button>
      </div>
    </form>
    <div class="need-help-link text-center mb-25px" test_id="needHelpLink">
      <bb-content-html [html]="registrationWidgetItem.userSearch.needHelpLink"></bb-content-html>
    </div>

  </div>

  <ng-template #popContentAgreement>
    <span class="tooltip-arrow-up" [attr.style]="popoverArrowStyle"></span>
    <div class="container-row modal-body">
        <div class="content" test_id="whereToFindAgreementNumberPopupContent">
            <bb-content-html [html]="registrationWidgetItem.userSearch.whereToFindAgreementNumberPopupContent"></bb-content-html>
            <span class="contact-number-text no-para-margin">
              <bb-content-html [html]="registrationWidgetItem.userSearch.whereToFindAgreementNumberPopupContentPartTwo"></bb-content-html>
              <bb-content-html [html]="registrationWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html>
            </span>
        </div>
        <div class="tooplip-close">
            <span (click)="closePopover()" class="material-icons clear-icon"
                [attr.style]="tooltipCloseStyle">clear</span>
        </div>
    </div>
  </ng-template>  
</div>

