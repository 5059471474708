import { Component, OnInit, Input } from '@angular/core';
import { SpeedometerData } from '../../model/account-summary';
import { Speedometer } from '@wss/model/content/dashboard';
import { appConfig } from '@wss/config/wss-app-constants';
import { isNotEmpOrNullOrUndef } from '../../../../common/util/util';
declare var d3: any;

@Component({
  selector: 'bb-wss-speedometer',
  templateUrl: 'speedometer.component.html'
})
export class SpeedometerComponent implements OnInit {
  @Input() speedometerData: SpeedometerData = new SpeedometerData();
  @Input() speedometerContent: Speedometer = new Speedometer();
  @Input() accountDetail: any;
  @Input() isPcpToHpBalloonRefinanceFlag: any;
  @Input() isLPAgreementFlag: any;  
 
  hpType: number = appConfig.hpCustomer;

  constructor() { }

  ngOnInit(): void {
    let amountStr: any = this.speedometerData.remainingAmount;
    if(isNotEmpOrNullOrUndef(amountStr)){
      this.speedometerData.remainingAmount = amountStr.replaceAll('£', '');
    }
    this.showSpeedometer();
  }

  showSpeedometer(): void{
    
    // set the dimensions and margins of the graph
    var width = 300,
    height = 340,
    margin = 20;

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    var radius = Math.min(width, height) / 2 - margin;

    // append the svg object to the div called 'my_dataviz'
    var svg = d3.select("#speedometer_id")
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .append("g")
    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    // Create dummy data
    var data = {a: 0, b: this.speedometerData.paidSoFar, c:this.speedometerData.amountleft, d:this.speedometerData.optionaFinalPayment}

    // set the color scale
    var color = d3.scaleOrdinal()
    .domain(data)
    .range(["#FFFFFF", "#44C553", "#e9eaeb", "#FDB734"])

    // Compute the position of each group on the pie:
    var pie = d3.pie()
    .startAngle(-0.7 * Math.PI)
    .endAngle(0.7 * Math.PI)
    .value(function(d: any) {return d.value; })
    .sort(null);
    var data_ready = pie(d3.entries(data))

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
    .selectAll('whatever')
    .data(data_ready)
    .enter()
    .append('path')
    .attr('d', d3.arc()
    .innerRadius(145)         // This is the size of the donut hole
    .outerRadius(radius)
    )
    .attr('fill', function(d: any){ return(color(d.data.key)) })
    .style("opacity", 0.7)
  };

  
}