import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { DatastoreService } from '../../../../service/datastore.service';
// import { ContentData } from '../../../../config/wss-app-constants';
// import { ContentService as OldContentService } from '../../../../service/content.service';
import { MessageService } from '../../../../service/message.service';
import { isNotEmpOrNullOrUndef, getCurrentMonthlyPayment } from '../../../../common/util/util';
import { appConfig } from '../../../../config/wss-app-constants';
import { PaymentHolidayService } from '../../services/payment-holiday.service';
import { isErrServer } from '../../../../common/util/util';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable, Subject } from 'rxjs';
import { PaymentHolidayWidgetItem } from '../../../../model/payment-holiday-widget/payment-holiday-widget';
import { takeUntil } from 'rxjs/operators';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'triage-question',
    templateUrl: './triage-question.component.html',
    providers: [ ContentService ]
})

export class TriageQuestionComponent implements OnInit, OnDestroy {
    // contentTxt: string = ContentData.TriageQuestion;
    // content: Array<any> = [];
    // contentFolder: string = ContentData.PaymentHoliday;
    currentStep: number = 1;
    accountDetail: any;
    serviceError: boolean = false;
    paymentHolidayWidgetItem$: Observable<PaymentHolidayWidgetItem | undefined> = this.bbContentService.getContent<PaymentHolidayWidgetItem>('paymentHolidayWidgetItem');
    readonly destroy$ = new Subject();
    localTimeHours = new Date().getHours();
    triageQuestionsAllowed: boolean = true;

    constructor(
        private router: RouterService,
        private datastoreService: DatastoreService,
        // private contentService: OldContentService,
        private changeDetectorRef: ChangeDetectorRef,
        private paymentHolidayService: PaymentHolidayService,
        private messageService: MessageService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService) { }

    ngOnInit() {
        // this.getStaticContent(this.contentTxt, this.contentFolder);
        this.handleErrors();
        this.accountDetail = this.datastoreService.getAccountDetails();
        // this.currentStep = this.datastoreService.getStepperStep();
        this.changeStep(this.currentStep);
        this.tealiumService.view(
            {
                JourneyName: 'Payment holiday',
                JourneyStepName: 'Triage Questions',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    // getStaticContent(contenttxt: string, contentfolder: string): void {
    //     this.contentService.getStaticContent(contenttxt, contentfolder).subscribe((content: any[]) => {
    //         this.content = content;
    //         this.changeDetectorRef.detectChanges();
    //     });
    // }

    async triageOptionSelect(option: string) {
        switch (option) {
            case "Can pay": {
                this.messageService.setCurrentStep(0);
                this.router.navigate(['cohort-1']);
                break;
            }
            case "Cannot pay": {
                let sysCheckResult = await this.systemCheckLevel1();
                // let sysCheckResult = true;
                if (sysCheckResult) {
                    this.changeStep(2);
                }
                else {
                    this.messageService.setCurrentStep(0);
                    this.router.navigate(['cohort-3']);
                }
                break;
            }
            case "Employed": {
                this.changeStep(3);
                break;
            }
            case "Furloughed": {
                this.changeStep(3);
                break;
            }
            case "Self-employed": {
                this.changeStep(3);
                break;
            }
            case "Unemployed": {
                this.messageService.setCurrentStep(0);
                this.router.navigate(['cohort-3']);
                break;
            }
            case "Retired": {
                this.changeStep(3);
                break;
            }
            case "No impact": {
                this.getEligibleAdvice();
                break;
            }
            case "Some impact": {
                this.getEligibleAdvice();
                break;
            }
            case "Big impact": {
                this.messageService.setCurrentStep(0);
                this.router.navigate(['cohort-3']);
                break;
            }
            case "No income": {
                this.messageService.setCurrentStep(0);
                this.router.navigate(['cohort-3']);
                break;
            }
        }
    }

    changeStep(step: number) {
        this.currentStep = step;
        this.messageService.setCurrentStep(this.currentStep);
        this.changeDetectorRef.detectChanges();
    }

    async systemCheckLevel1(): Promise<boolean> {
        let modagResponse: any;

        //check for ATP
        if (this.accountDetail.is_arrangement) {
            return false;
        }
        //check for litigation
        if (this.accountDetail.is_litigation_indicator) {
            return false;
        }
        //check for acct in arrear for more than 2 months
        if (parseInt(this.accountDetail.months_in_arrears) > 2) {
            return false;
        }
        //check existing modags
        modagResponse = await this.paymentHolidayService.getExistingModags().pipe(takeUntil(this.destroy$)).toPromise();
        if (modagResponse 
            && modagResponse.modag_responses 
            && modagResponse.modag_responses.modag_quote_response
            && modagResponse.modag_responses.modag_quote_response.modag_quotes
            && modagResponse.modag_responses.modag_quote_response.modag_quotes.length) {
            return false;
        }
        else {
            return true;
        }
    }

    async getEligibleAdvice() {
        // let advice = "PaymentHoliday";
        // let advice = "TermExtension";
        if ((this.localTimeHours < 22) && (this.localTimeHours >= 8)) {
            let advice = await this.systemCheckLevel2();
            this.triageQuestionsAllowed = true;
            if (isNotEmpOrNullOrUndef(advice)) {
                this.datastoreService.setAvailableAdvice(advice);
                this.messageService.setCurrentStep(0);
                this.router.navigate(['your-options']);
            }
        } else {
            this.triageQuestionsAllowed = false;
        }
    }

    async systemCheckLevel2(): Promise<string> {
        //let result = "PaymentHoliday";
        let result = "TermExtension";
        this.accountDetail.scheme_code = parseInt(this.accountDetail.scheme_code);
        let customerType = this.accountDetail.scheme_code === appConfig.hpCustomer ? "HP" : this.accountDetail.scheme_code === appConfig.pcpCustomer ? "PCP" : "";
        let arrearCheck = this.accountDetail.in_arrears
        let noOfPaymentLeft = parseInt(this.accountDetail.num_payments_left);
        let modagRes: any = {};
        if (arrearCheck) {
            result = "PaymentHoliday";
            return result;
        }
        if (customerType === 'PCP' && noOfPaymentLeft < 2) {
            result = "PaymentHoliday";
            return result;
        }
        if (customerType === 'HP' && noOfPaymentLeft < 6) {
            result = "PaymentHoliday";
            return result;
        }
        if (customerType === "PCP") {
            modagRes = await this.paymentHolidayService.caculateModagQuote(null, null).pipe(takeUntil(this.destroy$)).toPromise();
            if (modagRes 
                && modagRes.modag_quote_response 
                && modagRes.modag_quote_response.modag_quotes 
                && modagRes.modag_quote_response.modag_quotes.length) {
               let anyEligibleFlag: boolean = this.checkOptionsWithCurrentMonthly(modagRes.modag_quote_response.modag_quotes);
               if(!anyEligibleFlag){
                result = "PaymentHoliday";
               }
               return result;
            }
            else{
                return result;
            }
        }else{
            return result;
        }
    }

    checkOptionsWithCurrentMonthly(resQuotes: any[]): boolean{
        let eligibleFlag: boolean = false;
        let currentMonthlyRental: any = getCurrentMonthlyPayment(this.accountDetail);
        let current10Value: any = 0;
        let current25Value: any = 0;
        let newRentalValue: any = 0;
        let reducedCurrentValue: any = 0;
        current10Value = currentMonthlyRental - (currentMonthlyRental * 0.10);
        current25Value = currentMonthlyRental - 25;
        if(current10Value < current25Value){
            reducedCurrentValue = current10Value;
        }
        else{
            reducedCurrentValue = current25Value;
        }
        for(var i = 0; i < resQuotes.length; i++){
            newRentalValue = parseFloat(resQuotes[i].new_rental_sets[0].new_rental_value);
            if((newRentalValue <= reducedCurrentValue) && (newRentalValue > 0)){
                eligibleFlag = true;
                return eligibleFlag;
            }
        }
        return eligibleFlag;
    }

    handleErrors() {
        this.messageService.getServiceError().pipe(takeUntil(this.destroy$)).subscribe((error: any) => {
            if(error && error.message && error.message === 'AGE OF VEHICLE EXCEEDS MAX LIMIT'){
                this.datastoreService.setAvailableAdvice("PaymentHoliday");
                this.messageService.setCurrentStep(0);
                this.router.navigate(['your-options']);
            }
            else{
                this.showError(error?.status);
            }
        });
    }

    showError(errStatus: number): void {
        if (isErrServer(errStatus)) {
            this.datastoreService.setErrorServer();
            this.serviceError = true;
            this.changeDetectorRef.detectChanges();
        }
    }

    backToHomeClick() {
        this.messageService.setActiveNav("My account");
        this.router.navigate(['/../myaccount']);
    }
}