import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpService } from '@wss/service/http.service';
import { DatastoreService } from '@wss/service/datastore.service';

@Injectable({ providedIn: 'root' })
export class UserDetailService {
  public global_address: Subject<any> = new Subject();
  constructor(
    private httpService: HttpService,
    private datastoreService: DatastoreService
    ) { }

  updateAddress(data: any, formData: any) {
    data.address_detail.post_code = formData.post_code;
    data.addresses.house_name = formData.house_name;
    return this.httpService.updateUserAddress(data);
  }

  updateAddressDate(existData: any, formData: any) {
    const today = this.dateFormat(formData.moving_in_date);
    let addresReq = {
      address_type: 'residence',
      address_detail: {},
      account_number: this.datastoreService.getAccountNo(),
      moving_in_date: ''
    };
    addresReq.moving_in_date = today;
    addresReq.address_detail = existData;
    return this.httpService.updateUserAddress(addresReq);
  }

  UpdateContact(updateRequestData: any): Observable<any> {
    return this.httpService.updateContactDetail(updateRequestData);
  }

  updateEmailAlias(updateRequestData: any): Observable<any> {
    return this.httpService.updateEmailAlias(updateRequestData);
  }

  getUserDetails(): Observable<any>{
  return this.httpService.getUserAddress();
  }
  

  getAddress(data: any): Observable<any> {
    return this.httpService.addressSearch(data);
  }

  dateFormat(selectedDate: any) {
    var dd: any = selectedDate?.day;
    var mm: any = selectedDate?.month;
    var yyyy = selectedDate?.year;
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    const today = dd + '/' + mm + '/' + yyyy;
    return today;
  }

}
