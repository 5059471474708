<div class="container-column" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
    <div class="payment-row section-title-container" test_id="pageName">
        <span class="section-title" test_id="make_payment_label_id">{{paymentsWidgetItem.paymentMenu.pageName}}</span>
    </div>
    <div class="payment-column">
        <div class="payment-row payment-margin-top">
            <span test_id="pageText">
                {{paymentsWidgetItem.paymentMenu.pageText}}
            </span>
        </div>
    </div>
    <!-- Make Payment -->
    <div class="payment-menu-container">
        <div class="container-row arrear-box-height margin-top-20" (click)="onMakePaymentClick()"
            [ngClass]="{disabled: paymentButtonDisabled}" tabindex="0">
            <div class="arrear-info-box text-center">
            </div>
            <div role="button" class="container-row arrear-text-context">
                <div class="container-row arrear-text-margin">
                    <span class="wss-make-payment-icon"></span>
                    <span class="arrear-flex-width" test_id="payment_id">
                        {{paymentsWidgetItem.paymentMenu.option1}}
                    </span>
                </div>
                <div class="container-payment-menu-column">
                    <span id="payments-menu-click" 
                        class="material-icons material-icons-color-arrear arrear-icons-font-size">keyboard_arrow_right</span>
                </div>
            </div>
        </div>
        <!-- Make Payment -->

        <!-- Manage direct debits -->
        <div class="container-row arrear-box-height margin-top-20" (click)="onDirectDebitClick()"
            *ngIf="!directDebitButtonHidden" tabindex="0">
            <div class="arrear-info-box">
            </div>
            <div role="button" class="container-row arrear-text-context">
                <div class="container-row arrear-text-margin">
                    <span class="wss-direct-debit-icon"></span>
                    <span class="arrear-flex-width" test_id="manage_direct_debits_id">
                        {{paymentsWidgetItem.paymentMenu.option2}}
                    </span>
                </div>
                <div class="container-payment-menu-column">
                    <span
                        class="material-icons material-icons-color-arrear arrear-icons-font-size">keyboard_arrow_right</span>
                </div>
            </div>
        </div>
        <!-- Manage direct debits -->

        <!-- Settel in full -->
        <div class="container-row arrear-box-height margin-top-20" (click)="onSettlementClick()"
            [ngClass]="{disabled: settlementButtoneDisabled}" tabindex="0">
            <div class="arrear-info-box">
            </div>
            <div role="button" class="container-row arrear-text-context">
                <div class="container-row arrear-text-margin">
                    <span class="wss-settlement-icon"></span>
                    <span class="arrear-flex-width" test_id="settel_full_id">
                        {{paymentsWidgetItem.paymentMenu.option3}}
                    </span>
                </div>
                <div class="container-payment-menu-column">
                    <span
                        class="material-icons material-icons-color-arrear arrear-icons-font-size">keyboard_arrow_right</span>
                </div>
            </div>
        </div>
        <!-- Settel in full -->

        <!-- Help With Payment -->
        <div class="container-row arrear-box-height margin-top-20" (click)="onPaymentHolidayClick()" tabindex="0">
            <!-- As per new change payment hoilday needs to be enable always. -->
            <!-- [ngClass]="{disabled: paymentHolidayButtonDisabled}" -->
            <div class="arrear-info-box">
            </div>
            <div role="button" class="container-row arrear-text-context">
                <div class="container-row arrear-text-margin">
                    <span class="wss-help-payment-icon"></span>
                    <span class="arrear-flex-width" test_id="settel_full_id">
                        {{paymentsWidgetItem.paymentMenu.option5}}
                    </span>
                </div>
                <div class="container-payment-menu-column">
                    <span
                        class="material-icons material-icons-color-arrear arrear-icons-font-size">keyboard_arrow_right</span>
                </div>
            </div>
        </div>
        <!-- Help With Payment -->
    </div>

    <div class="container-row content-left-align margin-top-20 mb-110px">
        <div class="container-other-payment-column">
            <button id="other-payment-way-btn" class="wss-button-secondary button-width-padding" test_id="other_payment_way_id"
                title="Other way to pay" (click)="otherWayPayClick()">{{paymentsWidgetItem.paymentMenu.buttonText}}</button>
        </div>
        <div class="container-need-help-column" tabindex="0">
            <span class="payment-menu-need-help-link" test_id="needHelpLink">
                <bb-content-html [html]="paymentsWidgetItem.paymentMenu.needHelpLink"></bb-content-html>
            </span>
        </div>
    </div>
</div>