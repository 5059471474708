<div class="payment-holiday-outer">
<bb-wss-common-stepper [currentStep]="currentStep" *ngIf="currentStep !== 0"></bb-wss-common-stepper>
<div class="payment-holidays">
    <div class="content-margin">
        <div class="content-wrapper-container">
            <bb-router-outlet></bb-router-outlet>
        </div>
    </div>
</div>
</div>
