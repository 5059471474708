<div *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <div class="login-container">
    <span class="section-label-size" test_id="loginWidgetItemtitleNameContent">
      {{loginWidgetItem.recoverLoginDetails.titleName}}
    </span>
    <hr />
    <div class="back-to-login">
      <a href="#/user-login">
        <span class="material-icons">keyboard_backspace</span>
        {{loginWidgetItem.recoverLoginDetails.backToLoginText}}
      </a>
    </div>
    <form [formGroup]="forgotUsernameForm">
      <div class="reg-row" role="alert" *ngIf="serviceError">
        <bb-wss-banner-message [errorCode]="errorCode"></bb-wss-banner-message>
      </div>
      <div class="reg-row" role="row">
        <div>
          <bb-content-html [html]="loginWidgetItem.recoverLoginDetails.needMoreDetailsAboutYou"></bb-content-html>
        </div>
      </div>
      <div class="whats-your-dob">
        <bb-input-text-ui label="{{loginWidgetItem.recoverLoginDetails.whatsYourDOB}}​​" formControlName="dateOfBirth"
          placeholder="DD/MM/YYYY" [autoComplete]="'off'" (focusout)="showHideError()" (keyup)="onKeyPress($event)"
          [maxLength]="10">
        </bb-input-text-ui>
        <div role="alert" class="error-text-bold" *ngIf="hasDOBError" test_id="dateError">
          <!-- Please enter valid Date of birth -->
          {{loginWidgetItem.recoverLoginDetails.dateOfBirthError}}
        </div>
      </div>
      <div class="what-is-your-post-co">
        <bb-input-text-ui label="{{loginWidgetItem.recoverLoginDetails.whatsYourPostcode}}" formControlName="postcode"
          placeholder="E.g. CF23 8GF" [autoComplete]="'off'" class="lbl-txt" (focusout)="showHideError()">
        </bb-input-text-ui>
        <div role="alert" class="error-text" *ngIf="hasPostCodeError" test_id="postcodeError">
          {{loginWidgetItem.recoverLoginDetails.postcodeError}}
        </div>
      </div>
      <div class="this-should-be-the-p">
        <div test_id="postCodeChangeFaqsText">
          <bb-content-html [html]="loginWidgetItem.recoverLoginDetails.postcodeHelpText"></bb-content-html>
        </div>
      </div>
      <div class="text-center" role="row">
        <button id="registration-screen-btn" bbButton type="button" aria-label="nextButton"
          class="wss-button btn-no-bottom-margin btn-full-width" (click)="submitForm()" test_id="nextButtonText"
          [disabled]="forgotUsernameForm.invalid">
          {{loginWidgetItem.recoverLoginDetails.nextButton}}
        </button>
      </div>
    </form>
  </div>
  <div class="register-link no-para-margin justify-content-center" id="needHelpLinkContent"
    test_id="loginWidgetItemneedHelpLinkContent">
    <bb-content-html [html]="loginWidgetItem.recoverLoginDetails.needHelpLink"></bb-content-html>
  </div>
</div>