import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'bb-wss-common-icon',
    templateUrl: 'common-icon.component.html'
})

export class CommonIconComponent implements OnInit {
    @Input() className: string = '';
    @Input() imgText?: string = '';

    constructor() { }

    ngOnInit() { }
}

