<section id="br-getdetails-finance" *ngIf="balloonRefinanceGetdetailsFinanceItem$ | async as brGetdetailsFinanceItem">
    <wss-page-title [pageTitle]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsTitle.page4Title" [pageName]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsTitle.step4Title">
    </wss-page-title>
    <section class="content-width-max">
        <div class="mt-25px-mb-30px" test_id="brfTextAfterPageTitle"> 
            <bb-content-html [html]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsFinance.textAfterPageTitle"></bb-content-html>
        </div>
        <form [formGroup]="financeDetailsForm">
        <div class="card p-20px mb-10px">
            <wss-infor-tool 
                [idExpand]="idExpandArr[0]"    
                [idCollapse]="idCollapseArr[0]"   
                [idInforPanel]="idPanelArr[0]"                
                [lblContent]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsFinance.grossIncome"
                [lblInforContent]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsTitle.inforText"
                [inforContent]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsFinance.brfInformationIncome"
                [defaultTopMargin]="defaultTopMarginForGrossIncome">                
            </wss-infor-tool>
            <wss-currency-input
                [currencySymbol]="currencySym"    
                [controlName]="elementId[0]"
                [submitForm]="submitted"
                [errors]="financeDetailsForm.controls.grossIncome.errors"
                [errorMsg]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsTitle.errorMessageCommon"
                [showError]="showInputError"
                ariaLabel ="Gross income">
            </wss-currency-input>
            <wss-infor-tool 
                [idExpand]="idExpandArr[1]"    
                [idCollapse]="idCollapseArr[1]"   
                [idInforPanel]="idPanelArr[1]"                
                [lblContent]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsFinance.mortgageRental"
                [lblInforContent]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsTitle.inforText"
                [inforContent]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsFinance.brfInformationMortgage">                
            </wss-infor-tool>
            <wss-currency-input
                [currencySymbol]="currencySym"    
                [controlName]="elementId[1]"
                [submitForm]="submitted"
                [errors]="financeDetailsForm.controls.mortgageRental.errors"
                [errorMsg]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsTitle.errorMessageCommon"
                [showError]="showInputError"
                ariaLabel ="Mortgage or rental cost">
            </wss-currency-input>
            <wss-infor-tool 
                [idExpand]="idExpandArr[2]"    
                [idCollapse]="idCollapseArr[2]"   
                [idInforPanel]="idPanelArr[2]"                
                [lblContent]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsFinance.expenseTotal"
                [lblInforContent]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsTitle.inforText"
                [inforContent]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsFinance.brfInformationExpense">                
            </wss-infor-tool>
            <wss-currency-input
                [currencySymbol]="currencySym"    
                [controlName]="elementId[2]"
                [submitForm]="submitted"
                [errors]="financeDetailsForm.controls.expenseTotal.errors"
                [errorMsg]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsTitle.errorMessageCommon"
                [showError]="showInputError"
                ariaLabel ="Total expenses">
            </wss-currency-input>
            <wss-infor-tool 
                [idExpand]="idExpandArr[3]"    
                [idCollapse]="idCollapseArr[3]"   
                [idInforPanel]="idPanelArr[3]"                
                [lblContent]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsFinance.expectedChange"
                [lblInforContent]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsTitle.inforText"
                [inforContent]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsFinance.brfInformationExpected">                
            </wss-infor-tool>
            <div class="mt-20px">                
                <select required class="form-control wss-form-input-normal w-100 select-color-change" [ngClass]="submitted ? 'ng-touched' : ''"
                    id="expectedChange" formControlName="expectedChange" aria-label="Expected change">
                    <option value="" disabled selected hidden>{{brGetdetailsFinanceItem.balloonRefinanceGetdetailsTitle.pleaseSelect}}</option>
                    <option *ngFor="let expect of expectedChangeData" [value]="expect.value">{{expect.title}}</option>
                </select>
            </div>
            <div class="mb-p-0 mt-5px text-danger" *ngIf="submitted && financeDetailsForm.controls.expectedChange.errors">
                <bb-content-html [html]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsTitle.errorMessageCommon"></bb-content-html>
            </div>
        </div>
        </form>
        <div class="mb-40px">
            <wss-common-nav-buttons 
                [content]="brGetdetailsFinanceItem.balloonRefinanceGetdetailsNavBtns"
                [accountsDetails]="accountDetails"                
                [previousPageLink]="previousPageLink"
                [lastDateOfDecision]="lastDateOfDecision"
                [testIdText]="commonNavTestID"
                (primaryButtonClicked)="onSaveButtonClick()"
                (continueButtonClicked)="onContinue()">
            </wss-common-nav-buttons>
        </div>
    </section>
</section>
