<div class="mtlr-5pt password-rules-rectangle-3">

    <div class="reg-row-col">          
      <div class="password-rule-group-login">
        <div class="reg-row pwd-must">
          <!-- {{content[0]?.must}} : -->
          <span class="para-inline" >
            <bb-content-html [html]="passwordRules.must"></bb-content-html> :
          </span>
        </div>
        <div class="reg-row">
          <div class="password-rule-group-icon-material password-rule-group-icon-material-left-margin password-check-icon">
            <mat-icon class="password-rule-action-{{ containsAtleastEightChars }}">done</mat-icon>
          </div>
          <div class="password-rule-2-box password-rule-text password-rule-text-left-margin password-check-text">
            <!-- {{content[0]?.charseight}} -->
            <span class="para-inline">
              <bb-content-html [html]="passwordRules.charseight"></bb-content-html> 
            </span>
          </div> 
        </div>
      </div>
      <div class="password-rule-group-3">
        <div class="reg-row">
          <div class="password-rule-group-icon-material password-rule-group-icon-material-left-margin password-check-icon">
            <mat-icon class="password-rule-action-{{ containsAtLeastOneUpperCase }}">done</mat-icon>
          </div>
          <div class="password-rule-2-box password-rule-text password-rule-text-left-margin password-check-text">
            <!-- {{content[0]?.uppercase}} -->
            <span class="para-inline" >
              <bb-content-html [html]="passwordRules.uppercase"></bb-content-html> 
            </span>
          </div>
        </div>
      </div>
      <div class="password-rule-group-3">
        <div class="reg-row">
          <div class="password-rule-group-icon-material password-rule-group-icon-material-left-margin password-check-icon">
            <mat-icon class="password-rule-action-{{ containsAtLeastOneLowerCase  }}">done</mat-icon>
          </div>
          <div class="password-rule-2-box password-rule-text password-rule-text-left-margin password-check-text">
            <!-- {{content[0]?.onechar}} -->
            <span class="para-inline" >
              <bb-content-html [html]="passwordRules.onechar"></bb-content-html> 
            </span>
          </div>
        </div>
      </div>
      <div class="password-rule-group-3">
        <div class="reg-row">
          <div class="password-rule-group-icon-material password-rule-group-icon-material-left-margin password-check-icon">
            <mat-icon class="password-rule-action-{{ containsAtLeastOneNumber }}">done</mat-icon>
          </div>
          <div class="password-rule-2-box password-rule-text password-rule-text-left-margin password-check-text">
            <!-- {{content[0]?.onenum}} -->
            <span class="para-inline" >
              <bb-content-html [html]="passwordRules.onenum"></bb-content-html> 
            </span>
          </div>
        </div>
      </div>          
      <div class="password-rule-group-3">
        <div class="reg-row">
          <div class="password-rule-2-box password-rule-text">
            <!-- {{content[0]?.special}} -->
            <span class="para-inline" >
              <bb-content-html [html]="passwordRules.special"></bb-content-html> 
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>