import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { HelpCentreItem } from '@wss/model/helpCentre';
import { Observable, Subject } from "rxjs";
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { DatastoreService } from '@wss/service/datastore.service';
import { OpenbudgetPlannerService } from '@wss/service/openbudgetplanner.service';
import {Location} from '@angular/common';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-budget-home',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './budget-home.component.html',
  providers: [ContentService]
})
export class BudgetWidgetComponent {

  constructor(
    private readonly bbContentService: ContentService,
    private router: Router,
    private tealiumService: TealiumUtagService
  ) {
  }

  ngOnInit(): void{
    this.tealiumService.view(
      {
        JourneyName: 'Help Centre',
        JourneyStepName: 'Budget Home',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }
 
  helpCentreWidgetItem$: Observable<HelpCentreItem | undefined> = this.bbContentService.getContent<HelpCentreItem>('helpCentreWidgetItem');

  startBudgetForm() {
    this.router.navigate(['/budget-form/1']);
    window.scroll(0, 0);
  }

  backButtonToMoneyworries() {
    this.router.navigate(['help-centre/money-worries']);
    window.scroll(0, 0);
  }
}
