import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@backbase/foundation-ang/store";
import { Store } from "@ngrx/store";
import { BalloonRefinanceService } from "@wss/balloon-refinance-widget/src/services/balloon-refinance.service";
import { from, Observable, of } from "rxjs";
import { switchMap, map, mergeMap } from "rxjs/operators";
import { AppState } from "../state/app.state";
import { ActionTypes, LoadVehicleAction, LoadVehicleSuccessAction } from "./balloonRefinance.action";
import { vehicleInitialState } from "./balloonRefinance.reducer";


@Injectable()
export class BalloonRefinanceEffects {

    constructor(
        private store: Store<AppState>,
        private balloonRefinanceService: BalloonRefinanceService
        ){}

    @Effect()
    loadVehicle$(action$: Actions): Observable<LoadVehicleSuccessAction> {
        return action$.pipe(
            ofType(ActionTypes.LOAD_VEHICLE),
            mergeMap(() => this.balloonRefinanceService.getVehicleById("CK12")
            .pipe(
                map(data => new LoadVehicleSuccessAction(data)),
                //catchError(error => new LoadVehicleFailureAction(error))
            ))
        )
    }
}