import { Component, ViewChild, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { StepperComponent, StepperStepComponent } from '@backbase/ui-ang';
import { DatastoreService } from '@wss/service/datastore.service';

interface links {
  links : [route:string, title:string, route:[], icon:""]
}

@Component({
  selector: 'bb-navigation-stepper-container-customizable',
  templateUrl: './budget-stepper-navigation-custom-widget.component.html',
  providers: [ ContentService ]
})

export class bbNavigationStepperExtWidgetComponentCustom implements OnInit {
  formdata: any;
  linkConst:any;

  constructor(
    private readonly bbContentService: ContentService,
    public datastoreService: DatastoreService,) {
  }

  ngOnInit(): void {
  }



  removeLastEntry(links:links){
    this.linkConst=links;
    if(this.linkConst.length==6){
      return this.linkConst.pop();
    }
    else {
      return this.linkConst
    }
  }

}
