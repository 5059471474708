import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { DigitalInboxWidgetItem } from '@wss/model/content/digital-inbox';
import { Observable } from 'rxjs';
import { DatastoreService } from '@wss/service/datastore.service';
import { getDeviceType } from '@wss/common/util/util';
import { MessageService } from '@wss/service/message.service';
import { injectBrandDetailIntoPage } from '@wss/common/util/util';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'preference-landing',
  templateUrl: './preference-landing.component.html',
  providers: [ ContentService ]
})
export class PreferenceLandingComponent implements OnInit {

  digitalInboxWidgetItem$: Observable<DigitalInboxWidgetItem | undefined> = this.contentService.getContent<DigitalInboxWidgetItem>('digitalInboxWidgetItem');
  digitalInboxWidgetItem: any = new DigitalInboxWidgetItem();
  loggedInEmail: string = '';
  deviceType: string = 'Mobile';

  constructor (
    private readonly contentService: ContentService,
    private datastore: DatastoreService,
    private messageService: MessageService,
    private tealiumService: TealiumUtagService,
    private changeDetectorRef: ChangeDetectorRef
    ) {}

  ngOnInit(): void {
    this.tealiumService.view (
      {
        JourneyName: 'Digital Inbox',
        JourneyStepName: 'Landing page',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.messageService.setActiveNav("");
    this.loggedInEmail = this.datastore.getLoggedInEmail();
    this.deviceType = getDeviceType();
    this.digitalInboxWidgetItem$.subscribe((pageItem: any) => {
      this.digitalInboxWidgetItem = pageItem;
      this.digitalInboxWidgetItem.preferenceLanding = injectBrandDetailIntoPage(this.digitalInboxWidgetItem.preferenceLanding, this.digitalInboxWidgetItem.brandContactDetails);
      this.changeDetectorRef.detectChanges();
  });
  }

  navigateToAccountSelection(){
    location.href = "#/digital-inbox/account-selection";
  }

}