export class SendSMS {
    mobileNumberLabelText: string = "";
    mobileNumberPlaceholderText: string = "";
    mobileNumberErrorMessageText: string = "";
    sendSMSButtonText: string = "";
    sendSMSTitleText: string = "";
    sendSMSSettlementTitleText: string = "";
    sendSMSTopBodyText: string = "";
    sendSMSSettlementTopBodyText: string = "";
    sendSMSHelpNoteText: string = "";
    smsType: string = "";
}