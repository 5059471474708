import { Component, OnInit } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { appConfig } from '../../../../config/wss-app-constants';
import { DatastoreService } from '../../../../service/datastore.service';
import { calculateWorkingDays } from '../../../../common/util/util';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { PaymentHolidayWidgetItem } from '../../../../model/payment-holiday-widget/payment-holiday-widget';
import { ContentService } from '@backbase/universal-ang/content';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'term-extension-result',
    templateUrl: './term-extension-result.component.html',
    providers: [ ContentService ]
})

export class TermExtensionResultComponent implements OnInit {

    accountDetail: any = {};
    docReturnDate: string = '';
    paymentHolidayWidgetItem$: Observable<PaymentHolidayWidgetItem | undefined> = this.bbContentService.getContent<PaymentHolidayWidgetItem>('paymentHolidayWidgetItem');
    popoverSelf: any;
    tooltipCloseStyle: string = "font-size: 18px; margin-top: -10px !important;";
    
    
    constructor(
        private router: RouterService,
        private datastore: DatastoreService,
        private readonly bbContentService: ContentService,
        config: NgbPopoverConfig,
        private tealiumService: TealiumUtagService) {
        config.placement = 'bottom';
        config.container = "body";
    }

    ngOnInit(){
        this.accountDetail = this.datastore.getAccountDetails();
        this.docReturnDate = moment(calculateWorkingDays(moment(this.accountDetail.next_payment_date, 'DD/MM/YYYY'), 
        appConfig.workingDaysCalculation, "previous")).format('DD/MM/YYYY');
        this.tealiumService.view(
            {
                JourneyName: 'Payment holiday',
                JourneyStepName: 'Term Extension Result',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
    }

    openPopover(popover: any, event: MouseEvent) {
        popover.open();
        this.popoverSelf = popover;
    }

    closePopover() {
        this.popoverSelf.close();
    }

    backToDashboard(){
        // this.router.navigate(['/'+appConfig.dashboardURL]);
        location.href = '#/myaccount';
    }
}