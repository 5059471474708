<section class="budget-page custom-form content-margin" *ngIf="budgetFormItem$ | async as budgetFormItem">
    <form [formGroup]="personalInfoForm" formInvlidControlScroll>

        <div class="content-wrapper-container">
            <div class="container-row" *ngIf="serviceError">
              <bb-wss-banner-message></bb-wss-banner-message>
            </div>

            <div class="container-column mx-3">
                <div class="d-flex section-title-container">
                    <span class="section-title" test_id="personalInfopageName">
                        {{budgetFormItem.personalInfo.pageName}}
                    </span>
                </div>
                <div class="customer-support-text mt-4">
                    <div test_id="personalInfopageHeaderBelowText">
                        <bb-content-html [html]="budgetFormItem.personalInfo.pageHeaderBelowText"></bb-content-html>
                    </div>
                    <div class="mt-4">
                        <div class="mt-4 bullet-point" test_id="personalInfoBulletTextBelow">
                            <bb-content-html [html]="budgetFormItem.personalInfo.householdBulletList"></bb-content-html>
                        </div>
                        <div class="round-panel">
                            <div class="form-group ">
                                <label for="numberofAdult" class="form-group-heading">
                                    {{budgetFormItem.personalInfo.noOfAdultHeading}}
                                </label>
                                <div>
                                    <div class="form-group-subdisc">
                                        <!---How Often-->
                                        {{budgetFormItem.personalInfo.noOfAdultSubHeading}}
                                    </div>
                                    <div class="select-wrapper">
                                        <select id="numberofAdult" class="form-control col-md-2 col-sm-2 dropdown-size"
                                            id="exampleFormControlSelect1" formControlName="nr_of_adults"
                                            [ngClass]="{'is-invalid':submitted && numberofAdult.invalid }">
                                            <option selected [ngValue]="" *ngIf="numberofAdult.invalid"></option>
                                            <option *ngFor="let number of numberGroup" [value]="number" >{{number}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="submitted && numberofAdult.invalid">
                                        <small class="text-danger">
                                            Please select an answer from the list
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group separator">
                                <label for="numberofVehicle" class="form-group-heading">
                                    {{budgetFormItem.personalInfo.noOfVehicle}}
                                </label>
                                <div>
                                    <div class="form-group-subdisc">
                                        {{budgetFormItem.personalInfo.noOfVehicleSubHeading}}
                                    </div>
                                    <div class="select-wrapper">
                                        <select id="numberofVehicle" class="form-control col-md-2 col-sm-2 dropdown-size"
                                            id="exampleFormControlSelect1" formControlName="nr_of_vehicles_lending"
                                            [ngClass]="{'is-invalid':submitted && numberofVehicle.invalid }">
                                            <option selected [ngValue]="" *ngIf="numberofVehicle.invalid"></option>
                                            <option *ngFor="let number of numberGroup" [value]="number">{{number}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="submitted && numberofVehicle.invalid">
                                        <small class="text-danger">
                                            Please select an answer from the list
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group separator">
                                <label for="numberofDependent" class="form-group-heading">
                                    {{budgetFormItem.personalInfo.noOfDependent}}
                                </label>
                                <div>
                                    <div class="form-group-subdisc">
                                        <!---How Often-->
                                        {{budgetFormItem.personalInfo.noOfDependentSubHeading}}
                                    </div>
                                    <div class="select-wrapper">
                                        <select id="numberofDependent" class="form-control col-md-2 col-sm-2 dropdown-size"
                                            id="exampleFormControlSelect1" formControlName="nr_of_dependents"
                                            [ngClass]="{'is-invalid':submitted && numberofDependent.invalid }">
                                            <option selected [ngValue]="" *ngIf="numberofDependent.invalid"></option>
                                            <option *ngFor="let number of numberGroup" [value]="number">{{number}}</option>
                                        </select>
                                    </div>

                                    <div *ngIf="submitted && numberofDependent.invalid">
                                        <small class="text-danger">
                                            Please select an answer from the list
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container-row content-center margin-top-20">
                            <button class="wss-button btn-dif-view" (click)="goToNextPage()"
                                test_id="personalInfoButtonText">
                                {{budgetFormItem.personalInfo.continuebuttonText}}
                            </button>

                        </div>
                        <div class="container-row content-center margin-top-20">
                          <button #button bbButton class="wss-button-secondary wss-button btn-dif-view" type="button"
                            (click)="openModal(budgetFormItem)" >
                         {{budgetFormItem.saveExitModal.saveAndExitButtonText}}
                          </button>
                        </div>

                          <div class="container-row content-center margin-top-20">
                            <a (click)="goBackToBudgetHome()">
                                {{budgetFormItem.personalInfo.backbuttonText}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>
