import { Component, Input, OnDestroy, OnInit, Renderer2, ChangeDetectorRef, AfterViewInit, HostListener } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { RoutableWidget, RouterService } from "@backbase/foundation-ang/core";
import { UserLoginComponent } from "./components/user-login/user-login.component";
import { UserSuccessfulComponent } from "./components/user-successful/user-successful-component";
import { AttempsExceededComponent } from "./components/attemps-exceeded/attemps-exceeded.component";
import { AccountLockedComponent } from "./components/security-fail/account-locked.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { CheckEmailComponent } from "./components/check-email/check-email.component";
import { PasswordSuccessfulComponent } from "./components/password-successful/password-successful-component";
import { LinkExpiredComponent } from "./components/link-expired/link-expired.component";
import { PasswordResetComponent } from "./components/password-reset/password-reset.component";
import { MultipleAccountsComponent } from "./components/multiple-accounts/multiple-accounts.component";
import { appConfig, RuntimeEnv } from '../../config/wss-app-constants';
import { LoginFaqsComponent } from './components/login-faqs/login-faqs.component';
import { ForgotUsernameComponent } from './components/forgot-username/forgot-username.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { VerifySmsComponent } from './components/verify-sms/verify-sms.component';
import { UsernameSuccessfulComponent } from './components/username-successful/username-successful.component';
import { NoUserComponent } from './components/no-user/no-user.component';
import { RecoverLoginDetailsComponent } from './components/recover-login-details/recover-login-details.component';
import { NoUserFoundComponent } from './components/no-user-found/no-user-found.component';
import { PasscodeErrorComponent } from './components/passcode-error/passcode-error.component';
import { UnableIdentifyCustomerComponent } from './components/unable-identify-customer/unable-identify-customer.component';
import { ChangeEmailMaxAttemptsComponent } from './components/change-email-max-attempts/change-email-max-attempts.component';
import { PasswordChangeSuccessComponent } from "./components/password-change-success/password-change-success-component";

@Component({
  selector: 'bb-login-widget',
  templateUrl: 'login-widget.component.html',
  providers: [RouterService]
})

@RoutableWidget({
  routes: [
    { path: '', redirectTo: 'user-login', pathMatch: 'full' },
    { path: 'user-login', component: UserLoginComponent },
    { path: 'user-successful', component: UserSuccessfulComponent },
    { path: 'attempts-exceeded', component: AttempsExceededComponent },
    { path: 'account-locked', component: AccountLockedComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'check-email', component: CheckEmailComponent },
    { path: 'password-successful', component: PasswordSuccessfulComponent },
    { path: 'link-expired', component: LinkExpiredComponent },
    { path: 'password-reset', component: PasswordResetComponent },
    { path: 'multiple-accounts', component: MultipleAccountsComponent },
    { path: 'no-user', component: NoUserComponent },
    { path: 'login-faqs', component: LoginFaqsComponent },
    { path: 'forgot-email', component: ForgotUsernameComponent },
    { path: 'verify-otp', component: VerifyOtpComponent },
    { path: 'verify-sms', component: VerifySmsComponent},
    { path: 'username-successful', component: UsernameSuccessfulComponent},
    { path: 'recover-login-details', component: RecoverLoginDetailsComponent},
    { path: 'no-user-found', component: NoUserFoundComponent},
    { path: 'passcode-error', component: PasscodeErrorComponent},
    { path: 'unable-identify-customer', component: UnableIdentifyCustomerComponent},
    { path: 'change-email-max-attempts', component: ChangeEmailMaxAttemptsComponent},
    { path: 'password-change-success', component: PasswordChangeSuccessComponent },
    { path: '**', redirectTo: 'user-login', pathMatch: 'full' }
  ],
})

export class LoginWidgetComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  readonly search: String = 'not registered yet';
  // @HostListener('window:resize', ['$event'])
  // onResize() {
  //   this.getContainterHeight();
  // }
  
  portalName: any;
  loginContainerStyle: string = '';

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.getPortalName();
    // this.getContainterHeight();
  }

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.renderer.addClass(document.body, 'no-nav-bar');
    this.renderer.addClass(document.body, 'user-login-screen');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'no-nav-bar');
    this.renderer.removeClass(document.body, 'user-login-screen');
  }

  getPortalName() {
    if (RuntimeEnv.backbase) {
      this.portalName = window.BB.config.portalName;
      // "blackhorse"
    }
    this.changeDetectorRef.detectChanges();
  }

  // getContainterHeight() {
  //   let topPx = document.getElementsByClassName('login-screen-container')[0].clientHeight + 20;
  //   this.loginContainerStyle = 'min-height:' + topPx + 'px !important;';
  // }
}
