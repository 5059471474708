import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { BudgetFormItem } from '@wss/model/budget-form-widget/budgetForm';
import { ActivatedRoute, Router } from '@angular/router';
import { budgetForm, patternMap } from '../../../config/wss-app-constants'
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { saveAndExitService } from '@wss/budget-cover-widgets/components/save-and-exit-modal/save-and-exit.service';
import { UtilService } from '@wss/budget-cover-widgets/shared/service/util.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { BudgetService } from '@wss/budget-cover-widgets/shared/service/budget.service';
import { BudgetPlanner } from '@wss/budget-cover-widgets/shared/model/budget.model';
import { TealiumUtagService } from '@wss/service/utag.service';
@Component({
  selector: 'bb-accomodation-cost-widget',
  templateUrl: './budget-accomodation-cost-widget.component.html',
  providers: [ContentService]
})
export class BudgetAccomodationCostComponent implements OnInit {
  accommodationForm: any;
  submitted: boolean = false;
  howMuchBehindByPaymentField: string = '';
  maximumHousingCostEntries: number = budgetForm.maximumAdditionInfoEntries;
  frequencies: string[] = budgetForm.frequencyList;
  typeOfCosts: string[] = budgetForm.typeOfCost;
  numberWithtwoDecimalRegExp: RegExp = new RegExp(patternMap.amountwithtwodecimalPattern);
  storeData: any;
  serviceError: boolean = false;

  constructor(
    private readonly bbContentService: ContentService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private modalService: saveAndExitService,
    private datastoreService: DatastoreService,
    public utilService: UtilService,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private tealiumService: TealiumUtagService) {
  }


  budgetFormItem$: Observable<BudgetFormItem | undefined> = this.bbContentService.getContent<BudgetFormItem>('BudgetFormItem');

  ngOnInit(): void {
    this.getServiceData()
    this.inItAccoommodationForm()

    this.changegroup('doYouPayMortgage', 'accomodationAdditionalInfo')

    this.updateRadio('doYouPayMortgage', 'Living costs');
    this.updateRadio('isOtherLivingCost', 'Living costs', 'Other');

    this.addDataArray();
    this.updateFormGroup('accomodationAdditionalInfo', 'Living costs', false)
    this.tealiumService.view(
      {
        JourneyName: 'Budget Cover',
        JourneyStepName: 'Budget Accommodation Cost',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }


  addDataArray() {
    let pageObjects = this.utilService.selectMatchingInfo('Living costs', 'Other')
    if (pageObjects.length > 0 && this.storeData.current_page >= 3) {
      for (let i = 0; i < pageObjects.length; i++) {
        this.addother();
      }
      this.accommodationForm.controls['Living_Cost_Other_additional_info'].patchValue(pageObjects)
    }
  }

  updateFormGroup(formGroup: string, type: string, isOther: boolean) {
    let pageObjects = [];
    pageObjects = this.utilService.selectMatchingInfoByType(type)
    if (pageObjects.length > 0 && this.storeData.current_page >= 3) {
      this.accommodationForm.get(formGroup).patchValue(pageObjects[0])
      this.changeBehindByPaymentValueSelection(pageObjects[0])

      this.accommodationForm.get(formGroup).controls['is_include_arrears'].setValue( this.returnBooleanValue(pageObjects[0].is_include_arrears))
    }
  }

  returnBooleanValue(value:any){
    if(value==null){
      return null
    }
    else{
      return value.toString();
    }
  }

  changeBehindByPaymentValueSelection(obj:any){
   if(obj.behind_payments_amount) {
    this.howMuchBehindByPaymentField = 'yes'
   }
   else {
      this.howMuchBehindByPaymentField = 'no'
   }
  }

  get otherLivingCost() {
    return this.accommodationForm.get('Living_Cost_Other_additional_info') as FormArray
  }

  get isOtherLivingCost() {
    return this.accommodationForm.get('isOtherLivingCost')
  }

  showError(): void {
    this.datastoreService.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  updateRadio(control: string, type: string, category?: string) {
    let pageObjects = [];
    if (this.storeData && this.storeData.additional_info) {
      pageObjects = this.storeData.additional_info.filter(function (obj: any) {
        return category ? (obj.type === type && obj.category === category) : obj.type === type;
      })
    }

    if (pageObjects.length > 0 && this.storeData.current_page >= 3) {
      this.accommodationForm.get(control).patchValue('yes')
    }
    else if (pageObjects.length == 0 && this.storeData.current_page >= 3) { //
      this.accommodationForm.get(control).patchValue('no')
    }
    else {
      this.accommodationForm.get(control).patchValue('')
    }
  }

  deleteOtherIncome(i: number) {
    (this.accommodationForm.get('Living_Cost_Other_additional_info') as FormArray).removeAt(i)
  }



  changegroup(control: string, groupName: string) {
    this.accommodationForm.get(control).valueChanges.subscribe((value: string) => {
      if (value == "yes") {
        this.accommodationForm.addControl(groupName, this.addtioninfoGroup("Living costs", false));
        this.accommodationForm.controls['isOtherLivingCost'].value = '';
        this.accommodationForm.controls['isOtherLivingCost'].setValidators([Validators.required]);
        this.accommodationForm.controls['isOtherLivingCost'].updateValueAndValidity();
      }
      else if (value == "no") {

        this.accommodationForm.controls['isOtherLivingCost'].value = '';
        this.accommodationForm.controls['isOtherLivingCost'].clearValidators();
        this.accommodationForm.controls['isOtherLivingCost'].updateValueAndValidity();


        (this.accommodationForm.get('Living_Cost_Other_additional_info') as FormArray).clear();


        this.howMuchBehindByPaymentField = '';
        this.accommodationForm.controls['accomodationAdditionalInfo'].controls['behind_payments_amount'].value = ""
        this.accommodationForm.controls['accomodationAdditionalInfo'].controls['behind_payments_amount'].updateValueAndValidity();

        if (this.accommodationForm.contains(control)) {
          this.accommodationForm.removeControl(groupName);
        }
      }
      else {
      }
    })
  }

  get hasMorgageFilled() {
    return this.accommodationForm.get('doYouPayMortgage')
  }

  getServiceData() {
    //this.storeData = this.utilService.mockgetData();
    this.storeData = this.datastoreService.getBudgetDetail();
    /**
     * If user will redirect this page manually then
     * we are checking that user have already gone through this page
     * earlier and save the data
     * If not then we are redirecting to the budget home page
     */
    if(!this.storeData.current_page || (this.storeData.current_page && this.storeData.current_page < 2)) {
      this.router.navigate(['help-centre/money-worries']);
    }
  }

  inItAccoommodationForm() {
    this.accommodationForm = this.fb.group({
      doYouPayMortgage: ["", [Validators.required]],
      accomodationAdditionalInfo: this.addtioninfoGroup("Living costs", false),
      isOtherLivingCost: [""],
      current_page: ["3"],
      status:'Draft',
      Living_Cost_Other_additional_info: this.fb.array([])
    })
  }

  addtioninfoGroup(type: string, isOtherGroup: boolean): FormGroup {
    return new FormGroup({
      "type": new FormControl(type),
      "category": new FormControl(isOtherGroup ? 'Other' : '', Validators.required),
      "sub_category": new FormControl(null, !isOtherGroup ? [] : Validators.required),
      "amount": new FormControl('', [Validators.required, Validators.pattern(this.numberWithtwoDecimalRegExp)]),
      "frequency": new FormControl(null, Validators.required),
      "behind_payments_amount": new FormControl('', []),
      "is_include_arrears": new FormControl(null, isOtherGroup ? [] : Validators.required)
    })
  }

  get mortgageOrRent_amount() {
    return this.accommodationForm.get('accomodationAdditionalInfo.amount');
  }

  get mortgageOrRent_frequency() {
    return this.accommodationForm.get('accomodationAdditionalInfo.frequency');
  }

  get mortgageOrRent_category() {
    return this.accommodationForm.get('accomodationAdditionalInfo.category');
  }

  get mortgageOrRent_is_include_arrears() {
    return this.accommodationForm.get('accomodationAdditionalInfo.is_include_arrears');
  }

  get mortgageOrRent_behind_payments_amount() {
    return this.accommodationForm.get('accomodationAdditionalInfo.behind_payments_amount');
  }


  get hasBehindPaymentsAmountFilled() {
    return this.mortgageOrRent_behind_payments_amount.value.length > 0 ? 'yes' : 'no'
  }


  get living_cost_Other_additional_info() {
    return this.accommodationForm.get('Living_Cost_Other_additional_info');
  }



  changeBehindByPayment(e: any) {
    if (e.target.value == "yes") {
      this.howMuchBehindByPaymentField = "yes"

      this.accommodationForm.controls['accomodationAdditionalInfo'].controls['behind_payments_amount'].setValidators([Validators.required, Validators.pattern(this.numberWithtwoDecimalRegExp)]);
      this.accommodationForm.controls['accomodationAdditionalInfo'].controls['behind_payments_amount'].updateValueAndValidity();
    }
    else if (e.target.value == "no") {
      this.howMuchBehindByPaymentField = "no"

      this.accommodationForm.controls['accomodationAdditionalInfo'].controls['behind_payments_amount'].clearValidators()
      this.accommodationForm.controls['accomodationAdditionalInfo'].controls['behind_payments_amount'].value = ""
      this.accommodationForm.controls['accomodationAdditionalInfo'].controls['behind_payments_amount'].updateValueAndValidity();
    }
    else {
      this.howMuchBehindByPaymentField = '';
    }
  }

  changeOtherLivingCost(e: any) {
    if (e.target.value == "yes") {
      if (this.lengthofAdditioObj == 0) {
        this.addother()
      }
    }
    else {
      (this.accommodationForm.get('Living_Cost_Other_additional_info') as FormArray).clear()
    }
  }

  get lengthofAdditioObj() {
    var id = (this.accommodationForm.controls['Living_Cost_Other_additional_info']) ?
      this.accommodationForm.controls['Living_Cost_Other_additional_info'].controls.length
      : '0';

    return id;
  }

  get otherLivingCostModule(): FormGroup {
    return this.addtioninfoGroup("Living costs", true)
  }

  addother() {
    (this.accommodationForm.get('Living_Cost_Other_additional_info') as FormArray).push(this.otherLivingCostModule)
  }

  deleteAccommodationCost(i: number) {
    (this.accommodationForm.get('OtherHousingModule') as FormArray).removeAt(i)
  }

  goBackToIncomePage() {
    this.router.navigate(['budget-form/2']);
    window.scroll(0, 0);
  }

  goToNextPage() {
    this.submitted = true;
    if (this.accommodationForm.valid) {
      this.updateDetail()
    }
  }

  navigateToNextpage(){
    window.scroll(0, 0);
    this.router.navigate(['budget-form/4']);
  }

  updateDetail() {
    this.budgetService.updateBudgetDetail(this.accommodationForm.value, 'Living costs').subscribe((budgetResponse: BudgetPlanner) => {
      this.datastoreService.setBudgetDetail(budgetResponse);
      this.navigateToNextpage()
    }, (error: any) => {
      this.showError();
    });
  }

  openModal(budgetFormItem: object) {

    this.submitted = true;
    window.scroll(0, 0);
    if (this.accommodationForm.valid) {
      this.modalService.openModalFromService(budgetFormItem, this.accommodationForm.value, 'Living costs')
    }
  }

  checkingFiveDigitValue() {
    return Math.round(this.mortgageOrRent_behind_payments_amount.value).toString().length > 5;
  }
}
