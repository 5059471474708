<div class="login-faq-container" *ngIf="loginWidgetItem$ | async as loginWidgetItem">
    <div class="container-column">
        <div class="login-faq-title">
            <span class="section-label-title" test_id="create_account_faq_label_id">
                {{loginWidgetItem.loginFaqs.pageName}}</span>
            <hr>    
        </div>
        <div class="customer-support-text mt-5px">
            <div class="faq-header-text contact-number-text" test_id="pageHeaderText">
                <bb-content-html [html]="loginWidgetItem.loginFaqs.pageHeaderText"></bb-content-html>
                <span test_id="brandWiseContactNumber" *ngIf="loginWidgetItem.brandContactDetails">
                    <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html>
                </span>
            </div>
            
            <div class="margin-top-30">
                <div class="login-faq container-row margin-top-30">
                     <!-- Dynamic accordion -->
                <bb-common-expansion-panel [accordionContent]="acordanceContent" [testIDText]="'loginFaqAccordion'"></bb-common-expansion-panel>
                
                    <!-- <mat-accordion class="accordion mat-accordion-override">
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override">
                                    <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionOneTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                <span><bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionOneContent"></bb-content-html></span>
                                <span class="contact-number-text">
                                    <span><bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionOneContentPartTwo"></bb-content-html></span>
                                    <span test_id="brandWiseContactNumber" *ngIf="loginWidgetItem.brandContactDetails">
                                        <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html>
                                    </span>
                                </span>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override">
                                    <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionTwoTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionTwoContent"></bb-content-html>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override">
                                    <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionThreeTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionThreeContent"></bb-content-html>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override">
                                    <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionFourTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                <span><bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionFourContent"></bb-content-html></span>
                                <span class="contact-number-text">
                                    <span><bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionFourContentPartTwo"></bb-content-html></span>
                                    <span *ngIf="loginWidgetItem.brandContactDetails">
                                        <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html></span>
                                    <span><bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionFourContentPartThree"></bb-content-html></span>    
                                </span>
                                <span class="contact-number-text">
                                    <span><bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionFourContentPartFour"></bb-content-html></span>
                                    <span *ngIf="loginWidgetItem.brandContactDetails">
                                        <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html></span>
                                    <span><bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionFourContentPartFive"></bb-content-html></span>    
                                </span>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override">
                                    <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionFiveTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionFiveContent"></bb-content-html>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override">
                                    <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionSixTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p class="contact-number-text">
                                <span><bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionSixContent"></bb-content-html></span>
                                <span *ngIf="loginWidgetItem.brandContactDetails">
                                    <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html></span>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override">
                                    <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionSevenTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionSevenContent"></bb-content-html>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override">
                                    <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionEightTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionEightContent"></bb-content-html>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override">
                                    <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionNineTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionNineContent"></bb-content-html>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override">
                                    <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionTenTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p class="contact-number-text">
                                <span><bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionTenContent"></bb-content-html></span>
                                <span *ngIf="loginWidgetItem.brandContactDetails">
                                    <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html></span>
                            </p>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override">
                                    <bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionElevenTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                <span><bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionElevenContent"></bb-content-html></span>
                                <span class="contact-number-text">
                                    <span><bb-content-html [html]="loginWidgetItem.loginFaqs.firstAccordionOptionElevenContentPartTwo"></bb-content-html></span>
                                    <span *ngIf="loginWidgetItem.brandContactDetails">
                                        <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html></span>
                                </span>
                            </p>
                        </mat-expansion-panel>
                    </mat-accordion> -->
                </div>
            </div>
        </div>
    </div>
</div>