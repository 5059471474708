import {NgModule} from '@angular/core';
import {UserRegistrationEffects} from "./effects";
import {BackbaseStoreModule} from '@backbase/foundation-ang/store';
import {UserSearchService} from "../../registration-widget/src/service/user-search.service";

@NgModule({
  declarations: [],
  imports: [
    BackbaseStoreModule
  ],
  providers: [UserRegistrationEffects]
})
export class RegistrationStoreModule { }
