<div class="login-container" *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <div class="mt-20px">
    <bb-wss-common-icon [className]="'security-fail-icon'" [imgText]="'Security Fail'"></bb-wss-common-icon>
  </div>
  <div class="col3 text-center" role="alert" test_id="pageHeaderText">
    <bb-content-html [html]="loginWidgetItem.forgotUserNameUnableIdentifyCustomer.pageHeaderText"></bb-content-html>
  </div>
  <div class="col3 text-center" role="alert" test_id="pageDescriptionText">
    <bb-content-html [html]="loginWidgetItem.forgotUserNameUnableIdentifyCustomer.pageDescriptionText">
    </bb-content-html>
  </div>
  <div class="col3 text-center contact-number-text" role="alert" test_id="pleaseGetInTouchText">
    <bb-content-html [html]="loginWidgetItem.forgotUserNameUnableIdentifyCustomer.pleaseGetInTouch"></bb-content-html>
    <strong class="brand-contact-number">
      <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html>
    </strong>
  </div>
  <div id="login-screen-btn" class="margin-top-between-rows login-page-button text-center"
    test_id="loginWidgetItemlogInContent">
    <button id="bb-button-to" (click)="backToLoginCall()" bbButton type="submit" aria-label="Favourite" [className]="'wss-button btn-full-width'"
      title="{{loginWidgetItem.forgotUserNameUnableIdentifyCustomer.backToLoginButton}}" class="button-width-padding">
      {{loginWidgetItem.forgotUserNameUnableIdentifyCustomer.backToLoginButton}}
    </button>
  </div>
</div>