import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';
import { RegisterDatastoreService } from '@wss/registration-widget/src/service/register-datastore.service';
import { LoginWidgetItem } from '@wss/model/login-widget/login-widget';
import { Router } from '@angular/router';

@Component({
  selector: 'bb-wss-no-user-found',
  templateUrl: './no-user-found.component.html',
  providers: [ContentService],
})
export class NoUserFoundComponent implements OnInit {
  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>(
    'loginWidgetItem',
  );

  ngOnInit(): void {
    this.tealiumService.view({
      JourneyName: 'Login',
      JourneyStepName: 'No User Found',
      CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    });
  }

  constructor(
    public router: Router,
    private tealiumService: TealiumUtagService,
    private readonly bbContentService: ContentService,
  ) {}

  navigateToForgotUsername() {
    this.router.navigate(['forgot-email']);
  }

  navigateToLogin() {
    this.router.navigate(['user-login']);
  }
}
