<section class="custom-form  budget-essential-living-cost content-margin"  *ngIf="budgetFormItem$ | async as budgetFormItem">
    <!----- *ngIf="budgetFormItem$ | async as budgetFormItem"--->
    <form [formGroup]="essentialForm" formInvlidControlScroll>
        <div class="content-wrapper-container">
            <div class="container-row" *ngIf="serviceError">
              <bb-wss-banner-message></bb-wss-banner-message>
            </div>

            <div class="container-column mx-3">
                <div class="d-flex section-title-container">
                    <span class="section-title" test_id="essentiallivingpageName">
                     {{budgetFormItem.essentialCostDetail.pageName}}

                    </span>
                </div>

                <div class="customer-support-text mt-4">
                    <div test_id="essentiallivingHeaderBelowText">

                        <bb-content-html [html]="budgetFormItem.essentialCostDetail.pageHeaderBelowText"></bb-content-html>
                    </div>
                    <div class="round-panel">


                        <!---council tax section start-->
                        <div class="form-group">
                            <div class="form-group-heading form-title" test_id="essentiallivingSubText">

                                {{budgetFormItem.essentialCostDetail.pageHeaderBelowSubText}}
                               </div>
                            <div class="form-group-heading" test_id=" councilTaxText">

                                {{budgetFormItem.essentialCostDetail.councilTaxText}}
                            </div>
                            <div class="col-12">
                                <div class="radio mb-0">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isConcilTax"
                                            formControlName="isConcilTax"
                                            value="yes"><span class="label" test_id="councilTaxYes">Yes</span>
                                    </label>
                                </div>
                                <div class="radio">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isConcilTax"
                                            formControlName="isConcilTax"
                                            value="no"><span class="label" test_id="councilTaxNo">No</span>
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="submitted && councilTax.errors">
                                <small class="text-danger" *ngIf="councilTax.hasError('required')"
                                    test_id="councilTaxErrorMsg">

                                    {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                                </small>
                            </div>
                        </div>

                        <!---how much do you pay start-->
                        <div class="form-group " *ngIf="councilTax?.value == 'yes'" formGroupName="concilTaxAdditionalInfo">
                            <div class="amount-group">
                                <label class="subgroup" class="subgroup" test_id="councilTaxLabelContent">

                                    {{budgetFormItem.essentialCostDetail.amount}}
                                </label>
                                <div class="position-relative">
                                    <span class="currency">£</span>
                                    <input type="text"
                                        class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                        fiveDigitValidation="concilTaxAdditionalInfo"
                                        test_id="councilTaxAmountInput" formControlName="amount"
                                        [ngClass]="{'is-invalid':submitted && councilTaxAmount.errors  }"
                                        onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                        >
                                </div>
                                <div
                                    *ngIf="submitted && councilTaxAmount.invalid && councilTaxAmount.errors && (councilTaxAmount.errors.required || councilTaxAmount.errors.pattern)">
                                    <small class="text-danger" test_id="councilTaxAmountInputerror">

                                        {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}
                                    </small>
                                </div>
                                <div
                                    *ngIf="councilTaxAmount.errors && !councilTaxAmount.errors.pattern && councilTaxAmount.errors.fiveDigitError">
                                    <small class="text-danger" test_id="councilTaxAmountInputFiveDigiterror">
                                        {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                    </small>
                                </div>
                            </div>
                            <div>
                                <label class="subgroup" test_id="councilTaxhowOftenLabelContent">

                                    {{budgetFormItem.essentialCostDetail.howOften}}
                                </label>
                                <div class="select-wrapper">
                                    <select class="form-control" test_id="councilTaxhowOftenDropdown"
                                        fiveDigitValidation="concilTaxAdditionalInfo"
                                        formControlName="frequency">
                                        <option selected [ngValue]="null">Please select</option>
                                        <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                    </select>
                                </div>
                                <div
                                    *ngIf="submitted && councilTaxFrequency.invalid">
                                    <small class="text-danger" test_id="councilTaxDropdownError">

                                        {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <!---how much do you pay end-->
                        <!---council tax section end-->

                        <!---Energy and gas section start-->
                        <div class="form-group separator">
                            <div class="form-group-heading" test_id="energyAndGasText">

                                {{budgetFormItem.essentialCostDetail.energyAndGasText}}
                            </div>
                            <div class="col-12">
                                <div class="radio mb-0">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isEnergyAndGas"
                                            formControlName="isEnergyAndGas"
                                            value="yes"><span class="label" test_id="energyAndGasYes">Yes</span>
                                    </label>
                                </div>
                                <div class="radio">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isEnergyAndGas"
                                            formControlName="isEnergyAndGas"
                                            value="no"><span class="label" test_id="energyAndGasNo">No</span>
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="submitted && isEnergyAndGas.invalid">
                                <small class="text-danger"
                                    test_id="energyAndGasErrorMsg">

                                    {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                                </small>
                            </div>
                        </div>

                        <!---how much do you pay start-->
                        <div class="form-group " *ngIf="isEnergyAndGas?.value == 'yes'" formGroupName="energyAndGasAdditionalInfo">
                            <div class="amount-group">
                                <label class="subgroup" test_id="energyAndGasLabelContent">

                                    {{budgetFormItem.essentialCostDetail.amount}}
                                </label>
                                <div class="position-relative">
                                    <span class="currency">£</span>
                                    <input type="text" formControlName="amount"
                                        class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                        fiveDigitValidation="energyAndGasAdditionalInfo"
                                        test_id="energyAndGasAmountInput"
                                        onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                        [ngClass]="{'is-invalid':submitted && energyAndGasAmount.errors  }"

                                        >
                                </div>
                                <div
                                    *ngIf="submitted && energyAndGasAmount.invalid && energyAndGasAmount.errors && (energyAndGasAmount.errors.required || energyAndGasAmount.errors.pattern)">
                                    <small class="text-danger" test_id="energyAndGasAmountInputerror">
                                        {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}
                                    </small>
                                </div>
                                <div
                                    *ngIf="energyAndGasAmount.errors && !energyAndGasAmount.errors.pattern && energyAndGasAmount.errors.fiveDigitError">
                                    <small class="text-danger" test_id="energyAndGasAmountInputFiveDigitError">
                                        {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                    </small>
                                </div>
                            </div>
                            <div>
                                <label class="subgroup" test_id="energyAndGashowOftenLabelContent">

                                    {{budgetFormItem.essentialCostDetail.howOften}}
                                </label>
                                <div class="select-wrapper">
                                    <select class="form-control" test_id="energyAndGastestDropdown"
                                        fiveDigitValidation="energyAndGasAdditionalInfo"
                                        formControlName="frequency"
                                        [ngClass]="{'is-invalid':submitted && energyAndGasFrequency.invalid  }"
                                        >
                                        <option selected [ngValue]="null">Please select</option>
                                        <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                    </select>
                                </div>
                                <div
                                    *ngIf="submitted && energyAndGasFrequency.invalid">
                                    <small class="text-danger"
                                        test_id="energyAndGasDropdownError">

                                        {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <!---how much do you pay end-->
                        <!---Energy and gas section end-->

                          <!---Water section start-->
                          <div class="form-group separator">
                            <div class="form-group-heading" test_id="waterText">

                                {{budgetFormItem.essentialCostDetail.waterText}}
                            </div>
                            <div class="col-12">
                                <div class="radio mb-0">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isWater"
                                            formControlName="isWater"  value="yes"><span
                                            class="label" test_id="waterYes">Yes</span>
                                    </label>
                                </div>
                                <div class="radio">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isWater"
                                            formControlName="isWater"  value="no"><span
                                            class="label" test_id="waterNo">No</span>
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="submitted && isWater.invalid">
                                <small class="text-danger"
                                    test_id="waterErrorMsg">

                                    {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                                </small>
                            </div>
                        </div>

                        <!---how much do you pay start-->
                        <div class="form-group " *ngIf="isWater?.value == 'yes'" formGroupName="waterAdditionalInfo">
                            <div class="amount-group">
                                <label class="subgroup" test_id="waterLabelContent">

                                    {{budgetFormItem.essentialCostDetail.amount}}
                                </label>
                                <div class="position-relative">
                                    <span class="currency">£</span>
                                    <input type="text"
                                        class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                        fiveDigitValidation="waterAdditionalInfo"
                                        test_id="waterAmountInput" formControlName="amount"
                                        onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                        [ngClass]="{'is-invalid':submitted && waterAmount.errors  }"
                                        >
                                </div>
                                <div
                                    *ngIf="submitted && waterAmount.invalid && waterAmount.errors && (waterAmount.errors.required || waterAmount.errors.pattern)">
                                    <small class="text-danger" test_id="waterAmountInputerror">
                                        {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}
                                    </small>
                                </div>
                                <div *ngIf="waterAmount.errors && !waterAmount.errors.pattern && waterAmount.errors.fiveDigitError">
                                    <small class="text-danger" test_id="waterAmountInputFiveDigitError">
                                        {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                    </small>
                                </div>
                            </div>
                            <div>
                                <label class="subgroup" test_id="essentialCostDetailhowOftenLabelContent">

                                    {{budgetFormItem.essentialCostDetail.howOften}}
                                </label>
                                <div class="select-wrapper">
                                    <select class="form-control" test_id="waterTestDropdown"
                                        fiveDigitValidation="waterAdditionalInfo"
                                        formControlName="frequency"
                                        [ngClass]="{'is-invalid':submitted && waterFrequency.invalid && !waterFrequency.errors.fiveDigitError }"
                                        >
                                        <option selected [ngValue]="null">Please select</option>
                                        <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                    </select>
                                </div>
                                <div
                                    *ngIf="submitted && waterFrequency.invalid && !waterFrequency.errors.fiveDigitError">
                                    <small class="text-danger"
                                        test_id="waterDropdownError">
                                        {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <!---how much do you pay end-->
                        <!---Water section end-->



                        <!---Internet section start-->
                        <div class="form-group separator">
                            <div class="form-group-heading" test_id="internetText">

                                {{budgetFormItem.essentialCostDetail.internetText}}
                            </div>
                            <div class="col-12">
                                <div class="radio mb-0">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isInternet"
                                            formControlName="isInternet"
                                            value="yes"><span class="label" test_id="internetYes">Yes</span>
                                    </label>
                                </div>
                                <div class="radio">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isInternet"
                                            formControlName="isInternet"
                                            value="no"><span class="label" test_id="internetNo">No</span>
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="submitted && isInternet.invalid">
                                <small class="text-danger"
                                    test_id="internetErrorMsg">

                                    {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                                </small>
                            </div>
                        </div>

                        <!---how much do you pay start-->
                        <div class="form-group " *ngIf="isInternet?.value == 'yes'" formGroupName="internetAdditionalInfo">
                            <div class="amount-group">
                                <label class="subgroup" test_id="internetLabelContent">

                                    {{budgetFormItem.essentialCostDetail.amount}}
                                </label>
                                <div class="position-relative">
                                    <span class="currency">£</span>
                                    <input type="text"
                                        class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                        fiveDigitValidation="internetAdditionalInfo"
                                        test_id="internetAmountInput" formControlName="amount"
                                        onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                        [ngClass]="{'is-invalid':submitted && internetAmount.errors }"
                                        >
                                </div>
                                <div
                                    *ngIf="submitted && internetAmount.invalid && internetAmount.errors && (internetAmount.errors.required || internetAmount.errors.pattern)">
                                    <small class="text-danger" test_id="internetAmountInputerror">

                                        {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}
                                    </small>
                                </div>
                                <div
                                    *ngIf="internetAmount.errors && !internetAmount.errors.pattern && internetAmount.errors.fiveDigitError">
                                    <small class="text-danger" test_id="internetAmountInputFiveDigitError">
                                        {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                    </small>
                                </div>
                            </div>
                            <div>
                                <label class="subgroup" test_id="internethowOftenLabelContent">

                                    {{budgetFormItem.essentialCostDetail.howOften}}
                                </label>
                                <div class="select-wrapper">
                                    <select class="form-control" test_id="internetTestDropdown"
                                        fiveDigitValidation="internetAdditionalInfo"
                                        formControlName="frequency"
                                        [ngClass]="{'is-invalid':submitted && internetFrequency.invalid }"
                                        >
                                        <option selected [ngValue]="null">Please select</option>
                                        <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                    </select>
                                </div>
                                <div
                                    *ngIf="submitted && internetFrequency.invalid">
                                    <small class="text-danger"
                                        test_id="internetDropdownError">

                                        {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <!---how much do you pay end-->
                        <!---Internet section end-->

                        <!---Mobile Phone section start-->
                        <div class="form-group separator">
                            <div class="form-group-heading" test_id="mobilePhoneText">

                                {{budgetFormItem.essentialCostDetail.mobilePhoneText}}
                            </div>
                            <div class="col-12">
                                <div class="radio mb-0">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isMobilePhone"
                                            formControlName="isMobilePhone"
                                            value="yes"><span class="label" test_id="mobilePhoneYes">Yes</span>
                                    </label>
                                </div>
                                <div class="radio">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isMobilePhone"
                                            formControlName="isMobilePhone"
                                            value="no"><span class="label" test_id="mobilePhoneNo">No</span>
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="submitted && isMobilePhone.invalid">
                                <small class="text-danger"
                                    test_id="mobilePhoneErrorMsg">

                                    {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                                </small>
                            </div>
                        </div>

                        <!---how much do you pay start-->
                        <div class="form-group " *ngIf="isMobilePhone?.value == 'yes'" formGroupName="mobilePhoneAdditionalInfo">
                            <div class="amount-group">
                                <label class="subgroup" test_id="mobilePhoneLabelContent">

                                    {{budgetFormItem.essentialCostDetail.amount}}
                                </label>
                                <div class="position-relative">
                                    <span class="currency">£</span>
                                    <input type="text"
                                        class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                        fiveDigitValidation="mobilePhoneAdditionalInfo"
                                        test_id="mobilePhoneAmountInput" formControlName="amount"
                                        onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                        [ngClass]="{'is-invalid':submitted &&  mobileAmount.errors }"

                                        >
                                </div>
                                <div
                                    *ngIf="submitted && mobileAmount.invalid && mobileAmount.errors && (mobileAmount.errors.required || mobileAmount.errors.pattern)">
                                    <small class="text-danger" test_id="mobilePhoneAmountInputerror">

                                        {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}
                                    </small>
                                </div>
                                <div
                                    *ngIf="mobileAmount.errors && !mobileAmount.errors.pattern && mobileAmount.errors.fiveDigitError">
                                    <small class="text-danger" test_id="mobilePhoneAmountInputFiveDigitError">
                                        {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                    </small>
                                </div>
                            </div>
                            <div>
                                <label class="subgroup" test_id="mobilePhonehowOftenLabelContent">

                                    {{budgetFormItem.essentialCostDetail.howOften}}
                                </label>
                                <div class="select-wrapper">
                                    <select class="form-control" test_id="mobilePhoneTestDropdown"
                                        fiveDigitValidation="mobilePhoneAdditionalInfo"
                                        formControlName="frequency"
                                        [ngClass]="{'is-invalid':submitted && mobileFrequency.invalid }"
                                        >
                                        <option selected [ngValue]="null">Please select</option>
                                        <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                    </select>
                                </div>
                                <div
                                    *ngIf="submitted && mobileFrequency.invalid">
                                    <small class="text-danger"
                                        test_id="mobilePhoneDropdownError">

                                        {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <!---how much do you pay end-->
                        <!---Mobile Phone section end-->


                        <!---TV licence section start-->
                        <div class="form-group separator">
                            <div class="form-group-heading" test_id=" TVlicenceText">

                                {{budgetFormItem.essentialCostDetail.TVlicenceText}}
                            </div>
                            <div class="col-12">
                                <div class="radio mb-0">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isTVlicence"
                                            formControlName="isTVlicence"
                                            value="yes"><span class="label" test_id="TVlicenceYes">Yes</span>
                                    </label>
                                </div>
                                <div class="radio">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isTVlicence"
                                            formControlName="isTVlicence"
                                            value="no"><span class="label" test_id="TVlicenceNo">No</span>
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="submitted && isTVlicence.invalid">
                                <small class="text-danger"
                                    test_id="TVlicenceErrorMsg">

                                    {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                                </small>
                            </div>
                        </div>

                        <!---how much do you pay start-->
                        <div class="form-group " *ngIf="isTVlicence?.value == 'yes'" formGroupName="TVlicenceAdditionalInfo">
                            <div class="amount-group">
                                <label class="subgroup" test_id="TVlicenceLabelContent">

                                    {{budgetFormItem.essentialCostDetail.amount}}
                                </label>
                                <div class="position-relative">
                                    <span class="currency">£</span>
                                    <input type="text"
                                        class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                        fiveDigitValidation="TVlicenceAdditionalInfo"
                                        test_id="TVlicenceAmountInput" formControlName="amount"
                                        onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                        [ngClass]="{'is-invalid':submitted && tvLicenceAmount.errors }"
                                        >
                                </div>
                                <div
                                    *ngIf="submitted && tvLicenceAmount.invalid && tvLicenceAmount.errors && (tvLicenceAmount.errors.required || tvLicenceAmount.errors.pattern)">
                                    <small class="text-danger" test_id="TVlicenceAmountInputerror">

                                        {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}
                                    </small>
                                </div>
                                <div
                                    *ngIf="tvLicenceAmount.errors && !tvLicenceAmount.errors.pattern && tvLicenceAmount.errors.fiveDigitError">
                                    <small class="text-danger" test_id="tvLicenceAmountInputFiveDigitError">
                                        {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                    </small>
                                </div>
                            </div>
                            <div>
                                <label class="subgroup" test_id="TVlicencehowOftenLabelContent">

                                    {{budgetFormItem.essentialCostDetail.howOften}}
                                </label>
                                <div class="select-wrapper">
                                    <select class="form-control " test_id="TVlicenceTestDropdown"
                                        fiveDigitValidation="TVlicenceAdditionalInfo"
                                        formControlName="frequency"
                                        [ngClass]="{'is-invalid':submitted && tvLicenceFrequency.invalid }"
                                        >
                                        <option selected [ngValue]="null">Please select</option>
                                        <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                    </select>
                                </div>
                                <div
                                    *ngIf="submitted && tvLicenceFrequency.invalid">
                                    <small class="text-danger"
                                        test_id="TVlicenceDropdownError">

                                        {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <!---how much do you pay end-->
                        <!---TV licence section end-->

                       <!---Grocery  section start-->
                       <div class="form-group separator">
                        <div class="form-group-heading" test_id="grocieriesText">

                            {{budgetFormItem.essentialCostDetail.grocieriesText}}
                        </div>
                        <div class="col-12">
                            <div class="radio mb-0">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isGrocieries"
                                        formControlName="isGrocieries"
                                        value="yes"><span class="label" test_id="grocieriesYes">Yes</span>
                                </label>
                            </div>
                            <div class="radio">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isGrocieries"
                                        formControlName="isGrocieries"
                                        value="no"><span class="label" test_id="grocieriesNo">No</span>
                                </label>
                            </div>
                        </div>
                        <div *ngIf="submitted && isGrocieries.invalid">
                            <small class="text-danger"
                                test_id="grocieriesErrorMsg">

                                {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                            </small>
                        </div>

                    </div>

                    <!---how much do you pay start-->
                    <div class="form-group " *ngIf="isGrocieries?.value == 'yes'" formGroupName="grocieriesAdditionalInfo">
                        <div class="amount-group">
                            <label class="subgroup" test_id="grocieriesLabelContent">

                                {{budgetFormItem.essentialCostDetail.amount}}
                            </label>
                            <div class="position-relative">
                                <span class="currency">£</span>
                                <input type="text"
                                    class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                    fiveDigitValidation="grocieriesAdditionalInfo"
                                    test_id="grocieriesAmountInput" formControlName="amount"
                                    onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                    [ngClass]="{'is-invalid':submitted && grocieriesAmount.errors  }"

                                    >
                            </div>
                            <div
                                *ngIf="submitted && grocieriesAmount.invalid && grocieriesAmount.errors && (grocieriesAmount.errors.required || grocieriesAmount.errors.pattern)">
                                <small class="text-danger" test_id="grocieriesAmountInputerror">

                                    {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}
                                </small>
                            </div>
                            <div
                                *ngIf="grocieriesAmount.errors && !grocieriesAmount.errors.pattern && grocieriesAmount.errors.fiveDigitError">
                                <small class="text-danger" test_id="grocieriesAmountInputFiveDigitError">
                                    {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                </small>
                            </div>
                        </div>
                        <div>
                            <label class="subgroup" test_id="grocierieshowOftenLabelContent">

                                {{budgetFormItem.essentialCostDetail.howOften}}
                            </label>
                            <div class="select-wrapper">
                                <select class="form-control " test_id="grocieriesTestDropdown"
                                    fiveDigitValidation="grocieriesAdditionalInfo"
                                    formControlName="frequency"
                                    [ngClass]="{'is-invalid':submitted && grocieriesFrequency.invalid  }"
                                    >
                                    <option selected [ngValue]="null">Please select</option>
                                    <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                </select>
                            </div>
                            <div
                                *ngIf="submitted && grocieriesFrequency.invalid">
                                <small class="text-danger"
                                    test_id="grocieriesDropdownError">

                                    {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}
                                </small>
                            </div>
                        </div>
                    </div>

                    <!---how much do you pay end-->
                    <!---Grocery section end-->
                    <div class="form-group separator">

                        <!---clothing and footware section start-->
                        <div class="form-group-heading" test_id="clothingText">

                            {{budgetFormItem.essentialCostDetail.clothingText}}
                        </div>
                        <div class="col-12">
                            <div class="radio mb-0">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isClothingAndliving"
                                        formControlName="isClothingAndliving" value="yes"
                                        > <span class="label"
                                        test_id="clothingAndlivingYes">Yes</span>
                                </label>
                            </div>
                            <div class="radio">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isClothingAndliving"
                                        formControlName="isClothingAndliving"
                                        value="no"><span class="label" test_id="clothingAndlivingNo">No</span>
                                </label>
                            </div>
                        </div>
                        <div *ngIf="submitted && isClothingAndliving.invalid">
                            <small class="text-danger"

                                test_id="clothingAndlivingErrorMsg">

                           {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                            </small>
                        </div>
                    </div>

                    <!---how much do you pay start-->
                    <div class="form-group "  *ngIf="isClothingAndliving?.value == 'yes'" formGroupName="clothingAndlivingAdditionalInfo">
                        <div class="amount-group">
                            <label class="subgroup" test_id="clothingAndlivingAmountLabel">

                                {{budgetFormItem.essentialCostDetail.amount}}
                            </label>
                            <div class="position-relative">
                                <span class="currency">£</span>
                                <input type="text"
                                    class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                    test_id="clothingAndlivingAmountInput"
                                    fiveDigitValidation="clothingAndlivingAdditionalInfo"
                                    formControlName="amount"
                                    onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                    [ngClass]="{'is-invalid':submitted && clothingAmount.errors  }"

                                    >
                            </div>
                            <div
                                *ngIf="submitted && clothingAmount.invalid && clothingAmount.errors && (clothingAmount.errors.required || clothingAmount.errors.pattern)">
                                <small class="text-danger" test_id="clothingAndlivingAmountInputerror">

                                    {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}
                                </small>
                            </div>
                            <div
                                *ngIf="clothingAmount.errors && !clothingAmount.errors.pattern && clothingAmount.errors.fiveDigitError">
                                <small class="text-danger" test_id="clothingAndlivingAmountInputFiveDigitError">
                                    {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                </small>
                            </div>
                        </div>
                        <div>
                            <label class="subgroup" test_id="clothingAndlivingLabelContent">

                                {{budgetFormItem.essentialCostDetail.howOften}}
                            </label>
                            <div class="select-wrapper">
                                <select class="form-control "
                                    test_id="clothingAndlivinghowOftenDropdown"
                                    fiveDigitValidation="clothingAndlivingAdditionalInfo"
                                    formControlName="frequency"
                                    [ngClass]="{'is-invalid':submitted && clothingFrequency.invalid }"
                                    >
                                    <option selected [ngValue]="null">Please select</option>
                                    <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>

                                </select>
                            </div>
                            <div
                                *ngIf="submitted && clothingFrequency.invalid">
                                <small class="text-danger"
                                    test_id="clothingAndlivingDropdownError">

                                    {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <!---how much do you pay end-->
                    <!---clothing and footware section end-->

                        <!---Insurance section start-->
                        <div class="form-group separator">
                            <div class="form-group-heading" test_id="insuranceText">

                                {{budgetFormItem.essentialCostDetail.insuranceText}}
                            </div>
                          <div class="" test_id="payForInsurance">
                            {{budgetFormItem.essentialCostDetail.payForInsurance}}
                          </div>
                            <div class="col-12">
                                <div class="radio mb-0">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isinsurance"
                                            formControlName="isinsurance" (change)="changeInsurance($event)"
                                            value="yes"><span class="label" test_id="insuranceYes">Yes</span>
                                    </label>
                                </div>
                                <div class="radio">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isinsurance"
                                            formControlName="isinsurance" (change)="changeInsurance($event)"
                                            value="no"><span class="label" test_id="insuranceNo">No</span>
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="submitted && insuranceValue.invalid">
                                <small class="text-danger"
                                    test_id="insuranceErrorMsg">

                                    {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                                </small>
                            </div>
                        </div>

                        <!---how much do you pay start-->
                        <div *ngIf="insuranceValue" formArrayName="addMultipleInsurance" class="form-group">
                            <div *ngFor="let item of insuraceGroup.controls; let itemIndex =index"
                                [formGroupName]="itemIndex" class="form-group" [class.separator]="itemIndex > 0">
                                <div *ngIf="itemIndex>0">
                                    <span class="form-group-heading" test_id="essentialAnyOtherCostText">

                                        {{budgetFormItem.essentialCostDetail.anyOtherInsurance}}
                                        {{utilService.convertNumToString(itemIndex+1)}}
                                    </span>
                                    <span class="text-right float-right position-relative cursor-default delete-button" role="button"
                                        (click)="deleteEssentialCost(itemIndex)">
                                        <span class="cfa-delete-icon"></span>
                                        {{budgetFormItem.essentialCostDetail.removeButton}}
                                    </span>
                                </div>

                                <div class="amount-group">
                                    <div>
                                        <label class="subgroup" test_id="typeOfInsuranceContent">

                                            {{budgetFormItem.essentialCostDetail.typeOfInsurance}}
                                        </label>
                                    <div class="select-wrapper">
                                        <select class="form-control "
                                            test_id="typeOfInsuranceTestDropdown"
                                            formControlName="sub_category" required="required">
                                            <option selected [ngValue]="null">Please select</option>
                                            <option *ngFor="let insurance of typeOfInsurance" [value]="insurance">
                                                {{insurance}}</option>
                                        </select>
                                    </div>
                                        <div
                                            *ngIf="submitted && insuraceGroup.controls[itemIndex].controls.sub_category.invalid">
                                            <small class="text-danger" test_id="typeOfInsuranceDropdownErrormsg">

                                                {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}

                                            </small>
                                        </div>
                                    </div>
                                    <label class="subgroup" test_id="typeOfInsuranceLabelContent">

                                        {{budgetFormItem.essentialCostDetail.amount}}
                                    </label>

                                    <div class="position-relative">
                                        <span class="currency">£</span>
                                        <input type="text"
                                            class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                            test_id="typeOfInsuranceAmountInput"
                                            fiveDigitValidation="addMultipleInsurance"
                                            indexVal = "{{itemIndex}}"
                                            formControlName="amount"
                                            onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                            [ngClass]="{'is-invalid':submitted && insuraceGroup.controls[itemIndex].controls.amount.errors }"
                                            >
                                    </div>
                                    <div
                                        *ngIf="submitted && insuraceGroup.controls[itemIndex].controls.amount.invalid && insuraceGroup.controls[itemIndex].controls.amount.errors &&
                                        (insuraceGroup.controls[itemIndex].controls.amount.errors.required || insuraceGroup.controls[itemIndex].controls.amount.errors.pattern)">
                                        <small class="text-danger" test_id="typeOfInsuranceAmountInputErrormsg">

                                            {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}

                                        </small>
                                    </div>
                                    <div
                                        *ngIf="insuraceGroup.controls[itemIndex].controls.amount.errors && !insuraceGroup.controls[itemIndex].controls.amount.errors.pattern && insuraceGroup.controls[itemIndex].controls.amount.errors.fiveDigitError">
                                        <small class="text-danger" test_id="clothingAndlivingAmountInputFiveDigitError">
                                            {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                        </small>
                                    </div>
                                </div>
                                <div>
                                    <label class="subgroup" test_id="typeOfInsuranceLabelContent">

                                        {{budgetFormItem.essentialCostDetail.howOften}}
                                    </label>
                                    <div class="select-wrapper">
                                        <select class="form-control "
                                            test_id="typeOfInsurancehowOftenDropdown"
                                            fiveDigitValidation="addMultipleInsurance"
                                            indexVal = "{{itemIndex}}"
                                            formControlName="frequency"
                                            [ngClass]="{'is-invalid':submitted && insuraceGroup.controls[itemIndex].controls.frequency.invalid }"
                                            >
                                            <option selected [ngValue]="null">Please select</option>
                                            <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                        </select>
                                    </div>
                                    <div
                                        *ngIf="submitted && insuraceGroup.controls[itemIndex].controls.frequency.invalid">
                                        <small class="text-danger" test_id="howOftenDropdownErrormsg">

                                            {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}

                                        </small>
                                    </div>
                                </div>

                            </div>
                            <div *ngIf="(getMultipleInsurance.controls.length == maximumInsuranceEntries)">
                              <div class="icon_info_circle position-absolute">
                              </div>
                              <div class="error-banner-section ">
                                <bb-content-html [html]="budgetFormItem.commonDetail.maxCountReachedError"></bb-content-html>
                              </div>
                            </div>
                            <button  *ngIf= "(getMultipleInsurance.controls.length > 0 ) && (getMultipleInsurance.controls.length < maximumInsuranceEntries)" type="button" class="wss-button-secondary pl-5 add-button" test_id="otherInsuranceAddButton"
                                (click)="addOtherInsurance()">
                                <span class="material-icons add-icon">
                                    add
                                </span>
                                {{budgetFormItem.essentialCostDetail.addbutton}}
                            </button>

                        </div>

                    </div>
                    <!---how much do you pay end-->

                </div>
                <!----------------------------------5a  Spend on other things end--------------------------------------------------------------------------------------------------->

                <!----------------------------------5b Do you spend on any of these things? started----------------------------------------------------------------------------->
                <div class="round-panel">

                    <div class="form-group">
                        <div class="form-group-heading form-title" test_id="spentOnthingsSubText">

                        {{budgetFormItem.essentialCostDetail.secondFormTitle}}
                        </div>
                        <!---Dependents section start-->
                        <div class="form-group-heading" test_id="dependentText">

                            {{budgetFormItem.essentialCostDetail.dependentText}}
                        </div>
                        <div class="" test_id="dependentSubText">

                            {{budgetFormItem.essentialCostDetail.dependentSubText}}
                        </div>

                        <div class="col-12">
                            <div class="radio mb-0">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isDependent"
                                        formControlName="isDependent" value="yes" ><span
                                        class="label" test_id="dependentRadioYes">Yes</span>
                                </label>

                            </div>
                            <div class="radio">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isDependent"
                                        formControlName="isDependent" value="no"><span
                                        class="label" test_id="dependentRadioNo">No</span>
                                </label>
                            </div>
                        </div>
                        <div *ngIf="submitted && isDependent.invalid">
                            <small class="text-danger"
                                test_id="dependentRadioErrorMsg">

                                {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                            </small>
                        </div>
                    </div>
                    <!---how much do you pay start-->
                    <div class="form-group " *ngIf="isDependent?.value == 'yes'" formGroupName="dependentAdditionalInfo">
                        <div class="amount-group">
                            <label class="subgroup" test_id="dependentLabel">

                                {{budgetFormItem.essentialCostDetail.amount}}
                            </label>
                            <div class="position-relative">
                                <span class="currency">£</span>
                                <input type="text"
                                    class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                    fiveDigitValidation="dependentAdditionalInfo"
                                    test_id="dependentAmountInput" formControlName="amount"
                                    onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                    [ngClass]="{'is-invalid':submitted && dependentAmount.errors}"

                                    >
                            </div>
                            <div
                                *ngIf="submitted && dependentAmount.invalid && dependentAmount.errors && (dependentAmount.errors.required || dependentAmount.errors.pattern)">
                                <small class="text-danger" test_id="dependentAmountInputInputerror">

                                    {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}
                                </small>
                            </div>
                            <div
                                *ngIf="dependentAmount.errors && !dependentAmount.errors.pattern && dependentAmount.errors.fiveDigitError">
                                <small class="text-danger" test_id="dependentAmountInputFiveDigitError">
                                    {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                </small>
                            </div>
                        </div>
                        <div>
                            <label class="subgroup" test_id="dependentLabelContent">

                                {{budgetFormItem.essentialCostDetail.howOften}}
                            </label>
                            <div class="select-wrapper">
                                <select class="form-control " test_id="dependenthowOftenDropdown"
                                    fiveDigitValidation="dependentAdditionalInfo"
                                    formControlName="frequency"
                                    [ngClass]="{'is-invalid':submitted && dependentFrequency.invalid }"
                                    >
                                    <option selected [ngValue]="null">Please select</option>
                                    <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                </select>
                            </div>
                            <div
                                *ngIf="submitted && dependentFrequency.invalid">
                                <small class="text-danger"
                                    test_id="dependentDropdownError">

                                    {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <!---how much do you pay end-->
                    <!---Dependents section end-->

                    <!---Healthcare and personal section start-->
                    <div class="form-group separator">
                        <div class="form-group-heading" test_id="healthcareText">

                            {{budgetFormItem.essentialCostDetail.healthcareText}}
                        </div>
                        <div class="" test_id="healthcaredescriptionSubText">

                            {{budgetFormItem.essentialCostDetail.healthcaredescriptionSubText}}
                        </div>
                        <div class="col-12">
                            <div class="radio mb-0">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isHealthcare"
                                      formControlName="isHealthcare"
                                        value="yes"><span class="label" test_id="healthcareRadioYes">Yes</span>
                                </label>
                            </div>
                            <div class="radio">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isHealthcare"
                                     formControlName="isHealthcare"
                                        value="no"><span class="label" test_id="healthcareRadioNo">No</span>
                                </label>
                            </div>
                            <div *ngIf="submitted && isHealthcare.invalid">
                                <small class="text-danger"
                                    test_id="healthcareRadioErrorMsg">

                                    {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                                </small>
                            </div>
                        </div>
                    </div>

                    <!---how much do you pay start-->
                    <div class="form-group " *ngIf="isHealthcare?.value == 'yes'" formGroupName="healthcareAdditionalInfo">
                        <div class="amount-group">
                            <label class="subgroup" test_id="healthcareLabelContent">

                                {{budgetFormItem.essentialCostDetail.amount}}
                            </label>
                            <div class="position-relative">
                                <span class="currency">£</span>
                                <input type="text"
                                    class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                    test_id="healthcarelAmountInput" name="healthcareAmountInput"
                                    fiveDigitValidation="healthcareAdditionalInfo"
                                    formControlName="amount"
                                    onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                    [ngClass]="{'is-invalid':submitted && healthcareAmount.errors }"
                                    >
                            </div>
                            <div
                                *ngIf="submitted && healthcareAmount.invalid && healthcareAmount.errors && (healthcareAmount.errors.required || healthcareAmount.errors.pattern)">
                                <small class="text-danger" test_id="healthcareAmountInputterror">

                                    {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}
                                </small>
                            </div>
                            <div
                                *ngIf="healthcareAmount.errors && !healthcareAmount.errors.pattern  && healthcareAmount.errors.fiveDigitError">
                                <small class="text-danger" test_id="healthcareAmountInputFiveDigitError">
                                    {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                </small>
                            </div>
                        </div>
                        <div>
                            <label class="subgroup" test_id="healthcarehowOftenLabelContent">

                                {{budgetFormItem.essentialCostDetail.howOften}}
                            </label>
                            <div class="select-wrapper">

                                <select class="form-control " test_id="healthcareHowOftenDropdown"
                                    name="healthcareDropdown" fiveDigitValidation="healthcareAdditionalInfo" formControlName="frequency"
                                    [ngClass]="{'is-invalid':submitted && healthcareFrequency.invalid }"
                                    >
                                    <option selected [ngValue]="null">Please select</option>
                                    <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                </select>
                            </div>
                            <div
                                *ngIf="submitted && healthcareFrequency.invalid">
                                <small class="text-danger"
                                    test_id="healthcareDropdownError">

                                    {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}
                                </small>
                            </div>
                        </div>
                    </div>

                    <!---how much do you pay end-->
                    <!---Healthcare and personal section end-->

                    <!---Leisure section start-->
                    <div class="form-group separator">
                        <div class="form-group-heading" test_id="leisureText">

                            {{budgetFormItem.essentialCostDetail.leisureText}}
                        </div>
                        <div class="" test_id="leisuredescriptionText">

                            {{budgetFormItem.essentialCostDetail.leisureSubText}}
                        </div>
                        <div class="col-12">
                            <div class="radio mb-0">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isLeisure"
                                        formControlName="isLeisure" value="yes"><span
                                        class="label" test_id="leisureRadioYes">Yes</span>
                                </label>
                            </div>
                            <div class="radio">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isLeisure"
                                        formControlName="isLeisure" value="no" ><span
                                        class="label" test_id="leisureRadioNo">No</span>
                                </label>
                            </div>
                            <div *ngIf="submitted && isLeisure.invalid">
                                <small class="text-danger"
                                    test_id="leisureRadioErrorMsg">

                                    {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                                </small>
                            </div>
                        </div>
                    </div>

                    <!---how much do you pay start-->
                    <div class="form-group" *ngIf="isLeisure?.value == 'yes'"  formGroupName="leisureAdditionalInfo">
                        <div class="amount-group">
                            <label class="subgroup" test_id="leisureRadioLabelContent">

                                {{budgetFormItem.essentialCostDetail.amount}}
                            </label>

                            <div class="position-relative">
                                <span class="currency">£</span>
                                <input type="text"
                                    class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                    test_id="leisureRadioAmountInput" name="amount"
                                    fiveDigitValidation="leisureAdditionalInfo"
                                    formControlName="amount"
                                    onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                    [ngClass]="{'is-invalid':submitted && leisureAmount.errors }"

                                    >
                            </div>
                            <div
                                *ngIf="submitted && leisureAmount.invalid && leisureAmount.errors && (leisureAmount.errors.required || leisureAmount.errors.pattern)">
                                <small class="text-danger" test_id="leisureAmountInputerror">

                                    {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}
                                </small>
                            </div>
                            <div
                                *ngIf="leisureAmount.errors && !leisureAmount.errors.pattern && leisureAmount.errors.fiveDigitError">
                                <small class="text-danger" test_id="leisureAmountInputFiveDigitError">
                                    {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                </small>
                            </div>

                        </div>
                        <div>
                            <label class="subgroup" test_id="leisureRadioHowOftenLabelContent">
                                {{budgetFormItem.essentialCostDetail.howOften}}
                            </label>
                            <div class="select-wrapper">
                                <select class="form-control " test_id="leisureRadiohowOftenDropdown"
                                    name="frequency" fiveDigitValidation="leisureAdditionalInfo" formControlName="frequency"
                                    [ngClass]="{'is-invalid':submitted && leisureFrequency.invalid }"
                                    >
                                    <option selected [ngValue]="null">Please select</option>
                                    <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                </select>
                            </div>
                            <div
                                *ngIf="submitted && leisureFrequency.invalid">
                                <small class="text-danger"
                                    test_id="leisureDropdownError">

                                    {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}
                                </small>
                            </div>
                        </div>
                    </div>

                    <!---how much do you pay end-->
                    <!---Leisure section end-->

                    <!---Motor running costs section start-->
                    <div class="form-group separator">
                        <div class="form-group-heading" test_id="motorRunningCostText">

                            {{budgetFormItem.essentialCostDetail.motorRunningCostText}}
                        </div>
                        <div class="" test_id="motorRunningCostSubText">

                            {{budgetFormItem.essentialCostDetail.motorRunningCostSubText}}
                        </div>
                        <div class="col-12">
                            <div class="radio mb-0">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isMotorRunningCost"
                                        formControlName="isMotorRunningCost" value="yes"
                                        ><span class="label"
                                        test_id="motorRunningCostYes">Yes</span>
                                </label>
                            </div>
                            <div class="radio">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isMotorRunningCost"
                                        formControlName="isMotorRunningCost" value="no"
                                        ><span class="label"
                                        test_id="motorRunningCostNo">No</span>
                                </label>
                            </div>
                            <div *ngIf="submitted && isMotorRunningCost.invalid">
                                <small class="text-danger"
                                    test_id="motorRunningCostErrorMsg">

                                    {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                                </small>
                            </div>
                        </div>
                    </div>

                    <!---how much do you pay start-->
                    <div class="form-group" *ngIf="isMotorRunningCost?.value == 'yes'" formGroupName="motorRunningCostAdditionalInfo">
                        <div class="amount-group">
                            <label class="subgroup" test_id="motorRunningCostLabelContent">

                                {{budgetFormItem.essentialCostDetail.amount}}
                            </label>
                            <div class="position-relative">
                                <span class="currency">£</span>
                                <input type="text"
                                    class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                    test_id="motorRunningCostAmountInput" name="amount"
                                    fiveDigitValidation="motorRunningCostAdditionalInfo"
                                    formControlName="amount"
                                    onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                    [ngClass]="{'is-invalid':submitted && motorRunningCostAmount.errors }"
                                    >
                            </div>
                            <div
                                *ngIf="submitted && motorRunningCostAmount.invalid && motorRunningCostAmount.errors && (motorRunningCostAmount.errors.required || motorRunningCostAmount.errors.pattern)">
                                <small class="text-danger" test_id="motorRunningCostInputerror">

                                    {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}
                                </small>
                            </div>
                            <div
                                *ngIf="motorRunningCostAmount.errors && !motorRunningCostAmount.errors.pattern && motorRunningCostAmount.errors.fiveDigitError">
                                <small class="text-danger" test_id="motorRunningCostInputFiveDigitError">
                                    {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                </small>
                            </div>
                        </div>
                        <div>
                            <label class="subgroup" test_id="motorRunningCosthowOftenLabelContent">

                                {{budgetFormItem.essentialCostDetail.howOften}}
                            </label>
                            <div class="select-wrapper">
                                <select class="form-control " test_id="motorRunningCostDropdown"
                                    name="frequency" fiveDigitValidation="motorRunningCostAdditionalInfo" formControlName="frequency"
                                    [ngClass]="{'is-invalid':submitted && motorRunningCostFrequency.invalid }"
                                    >
                                    <option selected [ngValue]="null">Please select</option>
                                    <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                </select>
                            </div>
                            <div
                                *ngIf="submitted && motorRunningCostFrequency.invalid">
                                <small class="text-danger"

                                    test_id="motorRunningCostDropdownError">

                                    {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}
                                </small>
                            </div>
                        </div>
                    </div>

                    <!---how much do you pay end-->
                    <!---motor Running Cost section end-->

                    <!---Travel section start-->
                    <div class="form-group separator">
                        <div class="form-group-heading" test_id="travelText">

                            {{budgetFormItem.essentialCostDetail.travelText}}
                        </div>
                        <div class="" test_id="travelSubText">

                            {{budgetFormItem.essentialCostDetail.travelSubText}}
                        </div>
                        <div class="col-12">
                            <div class="radio mb-0">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isTravel" formControlName="isTravel"
                                        value="yes"><span class="label"
                                        test_id="travelYes">Yes</span>
                                </label>
                            </div>
                            <div class="radio">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="isTravel" formControlName="isTravel"
                                        value="no"><span class="label"
                                        test_id="travelNo">No</span>
                                </label>
                            </div>
                        </div>
                        <div *ngIf="submitted && isTravel.invalid">
                            <small class="text-danger"
                                test_id="travelErrorMsg">

                                {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                            </small>
                        </div>
                    </div>

                    <!---how much do you pay start-->
                    <div class="form-group " *ngIf="isTravel?.value == 'yes'" formGroupName="travelAdditionalInfo">
                        <div class="amount-group">
                            <label class="subgroup" test_id="travelLabelContent">

                                {{budgetFormItem.essentialCostDetail.amount}}
                            </label>
                            <div class="position-relative">
                                <span class="currency">£</span>
                                <input type="text"
                                    class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                    test_id="travelAmountInput" name="amount"
                                    fiveDigitValidation="travelAdditionalInfo"
                                    formControlName="amount"
                                    onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                    [ngClass]="{'is-invalid':submitted && travelAmount.errors }"

                                    >
                            </div>
                            <div
                                *ngIf="submitted && travelAmount.invalid && travelAmount.errors && (travelAmount.errors.required || travelAmount.errors.pattern)">
                                <small class="text-danger" test_id="travelAmountInputerror">

                                    {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}
                                </small>
                            </div>
                            <div
                                *ngIf="travelAmount.errors && !travelAmount.errors.pattern && travelAmount.errors.fiveDigitError">
                                <small class="text-danger" test_id="motorRunningCostInputFiveDigitError">
                                    {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                </small>
                            </div>
                        </div>
                        <div>
                            <label class="subgroup" test_id="TravellhowOftenLabelContent">

                                {{budgetFormItem.essentialCostDetail.howOften}}
                            </label>
                            <div class="select-wrapper">
                                <select class="form-control" test_id="travelDropdown"
                                    name="frequency" fiveDigitValidation="travelAdditionalInfo" formControlName="frequency"
                                    [ngClass]="{'is-invalid':submitted &&  travelFrequency.invalid }"
                                    >
                                    <option selected [ngValue]="null">Please select</option>
                                    <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                </select>
                            </div>
                            <div
                                *ngIf="submitted && travelFrequency.invalid">
                                <small class="text-danger"
                                    test_id="travelDropdownError">

                                    {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}
                                </small>
                            </div>
                        </div>
                    </div>

                    <!---how much do you pay end-->
                    <!---Travel section end-->

                    <!---Other expenses section start-->
                    <div class="form-group separator">
                        <div class="form-group-heading" test_id="otherExpensesText">

                            {{budgetFormItem.essentialCostDetail.otherExpensesText}}
                        </div>
                        <div class="" test_id="otherExpenseSubText">

                            {{budgetFormItem.essentialCostDetail.otherExpenseSubText}}
                        </div>
                        <div class="col-12">
                            <div class="radio mb-0">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" (change)="changeExpense($event)" name="otherExpenses"
                                        formControlName="otherExpenses" value="yes"
                                        ><span class="label"
                                        test_id="otherExpensesYes">Yes</span>
                                </label>
                            </div>
                            <div class="radio">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="otherExpenses"
                                        formControlName="otherExpenses" value="no" (change)="changeExpense($event)"
                                        ><span class="label"
                                        test_id="otherExpensesNo">No</span>
                                </label>
                            </div>
                            <div *ngIf="submitted && otherExpenses.invalid">
                                <small class="text-danger"
                                    test_id="otherExpensesErrorMsg">

                                    {{budgetFormItem.essentialCostDetail.radioButtonErrorMsg}}
                                </small>
                            </div>
                        </div>
                    </div>

                    <!---how much do you pay start-->
                    <div class="form-group "  formArrayName="addMultipleExpense">
                        <div *ngFor="let item of otherExpenseGroup.controls; let itemIndex =index"
                            [formGroupName]="itemIndex" class="form-group" [class.separator]="itemIndex > 0">
                            <div *ngIf="itemIndex>0">
                                <span class="form-group-heading" test_id="addMultipleExpenseText">

                                    {{budgetFormItem.essentialCostDetail.anyOtherExpense}}
                                    {{utilService.convertNumToString(itemIndex+1)}}
                                </span>
                                <span class="text-right float-right position-relative cursor-default delete-button" role="button"
                                    (click)="deleteOtherExpenset(itemIndex)">
                                    <span class="cfa-delete-icon"></span>
                                    {{budgetFormItem.essentialCostDetail.removeButton}}
                                </span>
                            </div>
                            <div class="amount-group">
                                <div>
                                    <label class="subgroup" test_id="typeOfCostContent">

                                        {{budgetFormItem.essentialCostDetail.typeOfCost}}
                                    </label>

                                    <input type="text"
                                        class="form-control pl-4 form-control-lg rounded-2 form-input"
                                        test_id="typeOfExpenseDropdown" name="sub_category"
                                        formControlName="sub_category" maxlength="15" required="required"
                                        [ngClass]="{'is-invalid':submitted && otherExpenseGroup.controls[itemIndex].controls.sub_category.invalid }"
                                >

                                        <div
                                        *ngIf="submitted && otherExpenseGroup.controls[itemIndex].controls.sub_category.invalid">
                                        <small class="text-danger" test_id="addMultipleExpenseErrormsg">

                                          Please enter type of cost

                                        </small>
                                    </div>
                                </div>
                                <label class="subgroup" test_id="typeOfCostContent">

                                    {{budgetFormItem.essentialCostDetail.amount}}
                                </label>
                                <div class="position-relative">
                                    <span class="currency">£</span>
                                    <input type="text"
                                        class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                        test_id="typeOfExpenseAmountInput" name="amount"
                                        fiveDigitValidation="addMultipleExpense"
                                        indexVal = "{{itemIndex}}"
                                        formControlName="amount"
                                        onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                        [ngClass]="{'is-invalid':submitted && otherExpenseGroup.controls[itemIndex].controls.amount.errors }"

                                        >
                                </div>
                                <div
                                    *ngIf="submitted && otherExpenseGroup.controls[itemIndex].controls.amount.invalid && otherExpenseGroup.controls[itemIndex].controls.amount.errors && (otherExpenseGroup.controls[itemIndex].controls.amount.errors.required || otherExpenseGroup.controls[itemIndex].controls.amount.errors.pattern)">
                                    <small class="text-danger" test_id="typeOfExpenseAmountInputErrormsg">

                                        {{budgetFormItem.essentialCostDetail.amountInputErrorMsg}}

                                    </small>
                                </div>
                                <div
                                    *ngIf="otherExpenseGroup.controls[itemIndex].controls.amount.errors && !otherExpenseGroup.controls[itemIndex].controls.amount.errors.pattern && otherExpenseGroup.controls[itemIndex].controls.amount.errors.fiveDigitError">
                                    <small class="text-danger" test_id="typeOfExpenseAmountInputFiveDigitError">
                                        {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                    </small>
                                </div>
                            </div>
                            <div>
                                <label class="subgroup" test_id="otherExpensesLabelContent">

                                    {{budgetFormItem.essentialCostDetail.howOften}}
                                </label>
                                <div class="select-wrapper">
                                    <select class="form-control " test_id="typeOfCosthowOftenDropdown"
                                        name="frequency"
                                        fiveDigitValidation="addMultipleExpense"
                                        indexVal = "{{itemIndex}}" formControlName="frequency"
                                        [ngClass]="{'is-invalid':submitted &&  otherExpenseGroup.controls[itemIndex].controls.frequency.invalid }"
                                        >
                                        <option selected [ngValue]="null">Please select</option>
                                            <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                    </select>
                                </div>
                                <div
                                    *ngIf="submitted && otherExpenseGroup.controls[itemIndex].controls.frequency.invalid">
                                    <small class="text-danger" test_id="typeOfCosthowOftenDropdownErrormsg">

                                        {{budgetFormItem.essentialCostDetail.dropdownErrorMsg}}

                                    </small>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="(getMultipleExpense.controls.length == maximumExpenseEntries)">
                          <div class="icon_info_circle position-absolute">
                          </div>
                          <div class="error-banner-section ">
                            <bb-content-html [html]="budgetFormItem.commonDetail.maxCountReachedError"></bb-content-html>
                          </div>
                        </div>
                        <button type="button" *ngIf= "(getMultipleExpense.controls.length > 0 ) && (getMultipleExpense.controls.length < maximumExpenseEntries)" class="wss-button-secondary pl-5 add-button typeofexpense-button" test_id="addMultipleExpenseButton"
                                (click)="addOtherExpense()">
                                <span class="material-icons add-icon">
                                    add
                                </span>
                                {{budgetFormItem.essentialCostDetail.addOtherCost}}
                            </button>
                    </div>

                    <!---how much do you pay end-->
                    <!---Other Expenses section end-->

                </div>
                <!-------------------------------------------Do you spend on any of these things? ended-------------------------------------------------------------------->


                <div class="container-row content-center margin-top-20">
                    <button class="wss-button btn-dif-view" test_id="essentialCostDetailButtonText"
                        (click)="goToNextPage()">

                        {{budgetFormItem.essentialCostDetail.continuebuttonText}}
                    </button>
                </div>
                <div class="container-row content-center margin-top-20">
                  <button #button bbButton class="wss-button-secondary wss-button btn-dif-view" type="button"
                    (click)="openModal(budgetFormItem)" >
                 {{budgetFormItem.saveExitModal.saveAndExitButtonText}}
                  </button>
                </div>
                <div class="text-center no-para-margin justify-content-center " (click)="goBackToIncomePage()"
                    test_id="essentialCostDetailBackButtonText">
                    <a>
                        {{budgetFormItem.essentialCostDetail.backbuttonText}}
                    </a>
                </div>

            </div>
        </div>
    </form>
</section>
