<div class="container-column mt-22px pcp-options" test_id="sectionNote">
    <bb-content-html [html]="pcpOptions.sectionNote"></bb-content-html>
    <div class="container-row your-options">
        <span class="text-semibold text-bigger" test_id="sectionTitle">{{pcpOptions.sectionTitle}}</span>
        <div class="container-row list-grid">
            <div role="switch" [ngClass]="listActive? 'container-row list active': 'container-row list'" (click)="toggleView('list')">
                <span class="material-icons" title="List View">list</span>
            </div>
            <div role="switch" [ngClass]="!listActive? 'container-row grid active': 'container-row grid'"
                (click)="toggleView('grid')">
                <span class="material-icons" title="Grid View">view_module</span>
            </div>
        </div>
    </div>

    <!-- list view start-->
    
    <div class="container-column" *ngIf="listActive">
        <ng-container *ngIf="eligibleOptionCount < 3">
            <div class="responsive-row-term">
                <ng-container *ngFor="let quote of modagResponse?.modag_quote_response?.modag_quotes;let i = index;">
                    <ng-container *ngIf="(quote?.total_Payable > 0)">
                            <div [ngClass]="i === 0? 'container-column white-container-rounded list-container mt-20px list-top': 'container-column white-container-rounded list-container mt-30px list-top'">
                                <div class="container-column top mt-16px">
                                    <span class="text-semibold text-center" test_id="option">{{pcpOptions.option}} {{i+1}}</span>
                                    <span class="text-center mt-9px" test_id="optionMonth">{{quote?.optionMonth}}{{pcpOptions.monthTerm}}</span>
                                </div>
                                <div class="container-column text-small paragraph">
                                    <div class="container-column" test_id="newMonthlyPayment">
                                        <bb-content-html [html]="pcpOptions.newMonthlyPayment"></bb-content-html>
                                        <span class="text-semibold" test_id="new_rental_value">£{{quote?.new_rental_sets[0]?.new_rental_value}}</span>
                                    </div>
                                    <div class="container-column mt-16px" test_id="newNumOfPayments">
                                        <bb-content-html [html]="pcpOptions.newNumOfPayments"></bb-content-html>
                                        <span class="text-semibold" test_id="new_number_of_rentals">{{quote?.new_rental_sets[1]?.new_number_of_rentals + quote?.new_rental_sets[0]?.new_number_of_rentals}}</span>
                                    </div>
                                    <div class="container-column mt-13px">
                                        <div class="para-inline" test_id="newFinalPaymentAmount">
                                            <bb-content-html [html]="pcpOptions.newFinalPaymentAmount"></bb-content-html>
                                            <span class="flex-ofp-label">
                                                <svg [ngbPopover]="popOfp" 
                                                autoClose="outside" 
                                                triggers="manual" 
                                                #pOfp="ngbPopover"
                                                (click)="openPopover(pOfp, $event, 'list')" 
                                                popoverClass="pcp-popover-list" 
                                                title="info-icon" class="info-icon-top"
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                <path fill="#107EB1" [attr.d]="svgInfoIcon" /></svg>
                                                <!-- <span [ngbPopover]="popOfp" 
                                                autoClose="outside" 
                                                triggers="manual" 
                                                #pOfp="ngbPopover"
                                                (click)="openPopover(pOfp, $event)" 
                                                popoverClass="pcp-popover-ofp" class="wss-payment-info-icon" ></span> -->
                                            </span>
                                                
                                        </div>
                                        <span class="text-semibold" test_id="new_rental_value">£{{quote?.new_rental_sets[2]?.new_rental_value}}</span>
                                    </div>
                                    <div class="container-column mt-13px" test_id="newFinalPaymentDate">
                                        <bb-content-html [html]="pcpOptions.newFinalPaymentDate"></bb-content-html>
                                        <span class="text-semibold" test_id="new_rental_date">{{quote?.new_rental_sets[2]?.new_rental_date}}</span>
                                    </div>
                                    <div class="container-column mt-13px" test_id="newApr">
                                        <bb-content-html [html]="pcpOptions.newApr"></bb-content-html>
                                        <span class="text-semibold" test_id="APR">{{quote?.APR}}%</span>
                                    </div>
                                    <div class="container-column mt-14px" test_id="newOutstanding">
                                        <bb-content-html [html]="pcpOptions.newOutstanding"></bb-content-html>
                                        <span class="text-semibold" test_id="total_Payable">£{{quote?.total_Payable}}</span>
                                    </div>
                                </div>
                                <div class="container-row bottom">
                                    <div role="radio" tabindex="{{i}}" [ngClass]="optionSelected === 'option'+ (i+1)? 'pcp-radio active': 'pcp-radio'"
                                        (click)="selectOption('option'+ (i+1), quote)" test_id="pcpRadio">
                                        <div></div>
                                    </div>
                                    <span test_id="optionMonth">{{pcpOptions.select}} {{quote?.optionMonth}} {{pcpOptions.months}}</span>
                                </div>
                            </div>
                    </ng-container>
                </ng-container>
                </div>
        </ng-container>
        <ng-container *ngIf="eligibleOptionCount === 3">
            <div class="responsive-row-term">
                <ng-container *ngFor="let quote of modagResponse?.modag_quote_response?.modag_quotes;let i = index;">
                    <ng-container *ngIf="(quote?.total_Payable > 0) && (i === 0 || i === 1)">
                            <div [ngClass]="i === 0? 'container-column white-container-rounded list-container mt-20px list-top': 'container-column white-container-rounded list-container mt-30px list-top'">
                                <div class="container-column top mt-16px">
                                    <span class="text-semibold text-center" test_id="option">{{pcpOptions.option}} {{i+1}}</span>
                                    <span class="text-center mt-9px" test_id="optionMonth">{{quote?.optionMonth}}{{pcpOptions.monthTerm}}</span>
                                </div>
                                <div class="container-column text-small paragraph">
                                    <div class="container-column" test_id="newMonthlyPayment">
                                        <bb-content-html [html]="pcpOptions.newMonthlyPayment"></bb-content-html>
                                        <span class="text-semibold" test_id="new_rental_value">£{{quote?.new_rental_sets[0]?.new_rental_value}}</span>
                                    </div>
                                    <div class="container-column mt-16px" test_id="newNumOfPayments">
                                        <bb-content-html [html]="pcpOptions.newNumOfPayments"></bb-content-html>
                                        <span class="text-semibold" test_id="newNumberOfRentals">{{quote?.new_rental_sets[1]?.new_number_of_rentals + quote?.new_rental_sets[0]?.new_number_of_rentals}}</span>
                                    </div>
                                    <div class="container-column mt-13px">
                                        <div class="para-inline" test_id="newFinalPaymentAmount">
                                            <bb-content-html [html]="pcpOptions.newFinalPaymentAmount"></bb-content-html>
                                            <span class="flex-ofp-label">
                                                <svg [ngbPopover]="popOfp" 
                                                autoClose="outside" 
                                                triggers="manual" 
                                                #pOfp="ngbPopover"
                                                (click)="openPopover(pOfp, $event, 'list')" 
                                                popoverClass="pcp-popover-list" 
                                                title="info-icon" class="info-icon-top"
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                <path fill="#107EB1" [attr.d]="svgInfoIcon" /></svg>
                                                 <!-- <span [ngbPopover]="popOfp" 
                                                 autoClose="outside" 
                                                 triggers="manual" 
                                                 #pOfp="ngbPopover"
                                                 (click)="openPopover(pOfp, $event)" 
                                                 popoverClass="pcp-popover-ofp"  class="wss-payment-info-icon" ></span> -->
                                            </span>
                                           
                                        </div>
                                        <span class="text-semibold" test_id="new_rental_value">£{{quote?.new_rental_sets[2]?.new_rental_value}}</span>
                                    </div>
                                    <div class="container-column mt-13px" test_id="newFinalPaymentDate">
                                        <bb-content-html [html]="pcpOptions.newFinalPaymentDate"></bb-content-html>
                                        <span class="text-semibold" test_id="new_rental_date">{{quote?.new_rental_sets[2]?.new_rental_date}}</span>
                                    </div>
                                    <div class="container-column mt-13px" test_id="newApr">
                                        <bb-content-html [html]="pcpOptions.newApr"></bb-content-html>
                                        <span class="text-semibold" test_id="APR">{{quote?.APR}}%</span>
                                    </div>
                                    <div class="container-column mt-14px" test_id="newOutstanding">
                                        <bb-content-html [html]="pcpOptions.newOutstanding"></bb-content-html>
                                        <span class="text-semibold" test_id="total_Payable">£{{quote?.total_Payable}}</span>
                                    </div>
                                </div>
                                <div class="container-row bottom">
                                    <div role="radio" tabindex="{{i}}" [ngClass]="optionSelected === 'option'+ (i+1)? 'pcp-radio active': 'pcp-radio'"
                                        (click)="selectOption('option'+ (i+1), quote)" test_id="pcp-radio">
                                        <div></div>
                                    </div>
                                    <span test_id="optionMonth">{{pcpOptions.select}} {{quote?.optionMonth}} {{pcpOptions.months}}</span>
                                </div>
                            </div>
                    </ng-container>
                </ng-container>
                </div>

                <ng-container *ngFor="let quote of modagResponse?.modag_quote_response?.modag_quotes;let i = index;">
                    <ng-container *ngIf="(quote?.total_Payable > 0) && (i === 2)">
                        <div class="responsive-row-term">
                            <div Class="container-column white-container-rounded list-container mt-30px">
                                <div class="container-column top mt-16px">
                                    <span class="text-semibold text-center" test_id="pcpOptionsOption">{{pcpOptions.option}} 3</span>
                                    <span class="text-center mt-9px" test_id="optionMonth">{{quote?.optionMonth}}{{pcpOptions.monthTerm}}</span>
                                </div>
                                <div class="container-column text-small paragraph">
                                    <div class="container-column" test_id="newMonthlyPayment">
                                        <bb-content-html [html]="pcpOptions.newMonthlyPayment"></bb-content-html>
                                        <span class="text-semibold" test_id="new_rental_value">£{{quote?.new_rental_sets[0]?.new_rental_value}}</span>
                                    </div>
                                    <div class="container-column mt-16px" test_id="newNumOfPayments">
                                        <bb-content-html [html]="pcpOptions.newNumOfPayments"></bb-content-html>
                                        <span class="text-semibold" test_id="new_number_of_rentals">{{quote?.new_rental_sets[1]?.new_number_of_rentals + quote?.new_rental_sets[0]?.new_number_of_rentals}}</span>
                                    </div>
                                    <div class="container-column mt-13px">
                                        <div class="para-inline" test_id="newFinalPaymentAmount">
                                            <bb-content-html [html]="pcpOptions.newFinalPaymentAmount"></bb-content-html>
                                            <span class="flex-ofp-label">
                                                <svg [ngbPopover]="popOfp" 
                                                autoClose="outside" 
                                                triggers="manual" 
                                                #pOfp="ngbPopover"
                                                (click)="openPopover(pOfp, $event, 'list')" 
                                                popoverClass="pcp-popover-list" 
                                                title="info-icon" class="info-icon-top"
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                <path fill="#107EB1" [attr.d]="svgInfoIcon" /></svg>
                                                <!-- <span [ngbPopover]="popOfp" 
                                                autoClose="outside" 
                                                triggers="manual" 
                                                #pOfp="ngbPopover"
                                                (click)="openPopover(pOfp, $event)" 
                                                popoverClass="pcp-popover-ofp" class="wss-payment-info-icon" ></span> -->
                                            </span>
                                        </div>
                                        <span class="text-semibold" test_id="new_rental_value">£{{quote?.new_rental_sets[2]?.new_rental_value}}</span>
                                    </div>
                                    <div class="container-column mt-13px" test_id="newFinalPaymentDate">
                                        <bb-content-html [html]="pcpOptions.newFinalPaymentDate"></bb-content-html>
                                        <span class="text-semibold" test_id="new_rental_date">{{quote?.new_rental_sets[2]?.new_rental_date}}</span>
                                    </div>
                                    <div class="container-column mt-13px" test_id="newApr">
                                        <bb-content-html [html]="pcpOptions.newApr"></bb-content-html>
                                        <span class="text-semibold" test_id="APR">{{quote?.APR}}%</span>
                                    </div>
                                    <div class="container-column mt-14px" test_id="newOutstanding">
                                        <bb-content-html [html]="pcpOptions.newOutstanding"></bb-content-html>
                                        <span class="text-semibold" test_id="total_Payable">£{{quote?.total_Payable}}</span>
                                    </div>
                                </div>
                                <div class="container-row bottom">
                                    <div role="radio" tabindex="{{i}}" [ngClass]="optionSelected === 'option'+ (i+1)? 'pcp-radio active': 'pcp-radio'"
                                        (click)="selectOption('option'+ (i+1), quote)" test_id="pcp-radio">
                                        <div></div>
                                    </div>
                                    <span test_id="optionMonth">{{pcpOptions.select}} {{quote?.optionMonth}} {{pcpOptions.months}}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
        </ng-container> 
    </div>
    <!-- list view end -->

    <!-- grid view start -->
    <div class="container-column grid-container paragraph mt-25px" *ngIf="!listActive && (modagResponse?.modag_quote_response?.modag_quotes?.length !== 0)">
        <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-column top-wrapper w-100': 'container-column'">
            <div class="container-row grid-header">
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row first-column': 'container-row'">
                    <span></span>
                </div>
                <ng-container *ngFor="let quote of modagResponse?.modag_quote_response?.modag_quotes;let i = index">
                    <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row next-column': 'container-row'">
                        <span test_id="optionMonth">{{quote?.optionMonth}}{{pcpOptions.gridMonthRow}}</span>
                    </div>
                </ng-container>
            </div>
            <div class="container-row grid-row">
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row first-column': 'container-row'">
                    <span test_id="newMonthlyPayment"><bb-content-html [html]="pcpOptions.newMonthlyPayment"></bb-content-html></span>
                    
                </div>
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row next-column': 'container-row'" *ngFor="let quote of modagResponse?.modag_quote_response?.modag_quotes;">
                    <span test_id="new_rental_value">£{{quote?.new_rental_sets[0]?.new_rental_value}}</span>
                </div>
            </div>
            <div class="container-row grid-row">
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row first-column': 'container-row'">
                    <span test_id="newNumOfPayments"><bb-content-html [html]="pcpOptions.newNumOfPayments"></bb-content-html></span>
                    
                </div>
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row next-column': 'container-row'" *ngFor="let quote of modagResponse?.modag_quote_response?.modag_quotes;">
                    <span test_id="new_number_of_rentals">{{quote?.new_rental_sets[1]?.new_number_of_rentals + quote?.new_rental_sets[0]?.new_number_of_rentals}}</span>
                </div>
            </div>
            <div class="container-row grid-row">
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row first-column': 'container-row'">
                    <span test_id="newApr"><bb-content-html [html]="pcpOptions.newApr"></bb-content-html></span>
                    
                </div>
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row next-column': 'container-row'" *ngFor="let quote of modagResponse?.modag_quote_response?.modag_quotes;">
                    <span test_id="APR">{{quote?.APR}}%</span>
                </div>
            </div>
            <div class="container-row grid-row">
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row first-column': 'container-row'">
                    <span test_id="newOutstanding"><bb-content-html [html]="pcpOptions.newOutstanding"></bb-content-html></span>
                    
                </div>
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row next-column': 'container-row'" *ngFor="let quote of modagResponse?.modag_quote_response?.modag_quotes;">
                    <span test_id="total_Payable">£{{quote?.total_Payable}}</span>
                </div>
            </div>
            <div class="container-row grid-row">
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row first-column': 'container-row'">
                    <span class="para-inline" test_id="newFinalPaymentAmount">
                            <bb-content-html [html]="pcpOptions.newFinalPaymentAmount"></bb-content-html>
                            <span class="flex-ofp-label">
                                <svg [ngbPopover]="popOfp" 
                                autoClose="outside" 
                                triggers="manual" 
                                #pOfp="ngbPopover"
                                (click)="openPopover(pOfp, $event, 'grid')" 
                                popoverClass="pcp-popover-grid" 
                                title="info-icon" class="info-icon-top"
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                <path fill="#107EB1" [attr.d]="svgInfoIcon" /></svg>
                                <!-- <span [ngbPopover]="popOfp" 
                                autoClose="outside" 
                                triggers="manual" 
                                #pOfp="ngbPopover"
                                (click)="openPopover(pOfp, $event)" 
                                popoverClass="pcp-popover-ofp" class="wss-payment-info-icon" ></span> -->
                            </span>

                            <!-- <span  [ngbPopover]="popContentOfp"   autoClose="outside"   triggers="manual"   #pOfp="ngbPopover"
                        (click)="openPopover(pOfp, $event)"   popoverClass="pcp-popover-ofp"   class="wss-payment-info-icon"
                         ></span> -->
                        </span>
                    
                </div>
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row next-column': 'container-row'" *ngFor="let quote of modagResponse?.modag_quote_response?.modag_quotes;">
                    <span test_id="new_rental_value">£{{quote?.new_rental_sets[2]?.new_rental_value}}</span>
                </div>
            </div>
            <div class="container-row grid-row">
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row first-column': 'container-row'">
                    <span test_id="newFinalPaymentDate"><bb-content-html [html]="pcpOptions.newFinalPaymentDate"></bb-content-html></span>
                    
                </div>
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row next-column': 'container-row'" *ngFor="let quote of modagResponse?.modag_quote_response?.modag_quotes;">
                    <span test_id="new_rental_date">{{quote?.new_rental_sets[2]?.new_rental_date}}</span>
                </div>
            </div>
            <div class="container-row grid-row grid-footer">
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row first-column': 'container-row'">
                    <span></span>
                </div>
                <div [ngClass]="modagResponse?.modag_quote_response?.modag_quotes?.length === 1? 'container-row next-column': 'container-row'" *ngFor="let quote of modagResponse?.modag_quote_response?.modag_quotes;let i = index;">
                    <div class="container-row">
                        <div role="radio" tabindex="{{i}}" [ngClass]="optionSelected === 'option'+ (i+1)? 'pcp-radio active': 'pcp-radio'"
                            (click)="selectOption('option'+ (i+1), quote)" test_id="pcp-radio">
                            <div></div>
                        </div>
                        &nbsp;&nbsp;
                        <span test_id="pcpOptionsSelect">{{pcpOptions.select}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- grid view end -->

    <ng-container *ngIf="optionSelected !== ''">
        <p class="mt-35px" test_id="nextPaymentWillBe">{{pcpOptions.nextPaymentWillBe}}<span class="text-semibold" test_id="new_rental_value">£{{selectedQuote?.new_rental_sets[0]?.new_rental_value}}</span> {{pcpOptions.willBeTakenOn}}
            <span class="text-semibold" test_id="new_rental_date">{{selectedQuote?.new_rental_sets[0]?.new_rental_date}}</span>.</p>
        <p test_id="finalPaymentWillBe">{{pcpOptions.finalPaymentWillBe}} <span class="text-semibold" test_id="new_rental_value">£{{selectedQuote?.new_rental_sets[2]?.new_rental_value}}</span> {{pcpOptions.willBeTakenOn}}
            <span class="text-semibold" test_id="new_rental_date">{{selectedQuote?.new_rental_sets[2]?.new_rental_date}}</span>.</p>
        <br>
        <div test_id="amountMayDefer">
            <bb-content-html [html]="pcpOptions.amountMayDefer"></bb-content-html>
        </div>
    </ng-container>

    <div class="container-row continue">
        <button role="button" tabindex="0" class="wss-button" [disabled]="!(optionSelected !== '')" (click)="finalizeQuote()" test_id="nextButton">{{pcpOptions.nextButton}}</button>
    </div>
    <div class="flex-horizontal-center" test_id="pageBottomLink">
        <bb-content-html [html]="pcpOptions.pageBottomLink"></bb-content-html>
    </div>
    <ng-template #popOfp>
        <span class="tooltip-arrow-up" [attr.style]="popoverArrowStyle"></span>
        <div class="container-row modal-body pover-over-class">
            <div class="content" test_id="finalAmountTooltip">
                <bb-content-html [html]="pcpOptions.finalAmountTooltip"></bb-content-html>
            </div>
            <div class="tooplip-close">
                <span (click)="closePopover()" class="material-icons clear-icon" [attr.style]="tooltipCloseStyle">clear</span>
            </div>
        </div>
    </ng-template>
</div>