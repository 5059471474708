<div class="service-exception-widget">
    <div class="content-margin">
        <div class="content-wrapper-container">
            <div class="container-column">
                <div class="service-exception-title">
                    <span class="section-label-title" test_id="pageName">
                        {{serviceException.panelHeadingText}}</span>
                </div>
            </div>
            <div class="container-row content margin-top-20">
                <bb-content-html [html]="serviceException.errorInfoText"></bb-content-html>
            </div>
            <div *ngIf="serviceException?.componentUri !== ''" class="container-row btn-align" >
                <button tabindex="0" role="button" class="wss-button" (click)="navigateTo(serviceException.componentUri)">{{serviceException?.buttonText}}</button>
            </div>
        </div>
    </div>
</div>