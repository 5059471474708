import { Component, OnInit } from '@angular/core';
import { RoutableWidget } from "@backbase/foundation-ang/core";
import { ContentService } from '@backbase/universal-ang/content';
import { DatastoreService } from '@wss/service/datastore.service';
import { ContactPreferenceLandingComponent } from './components/contact-preference-landing/contact-preference-landing.component';
import { ContactPreferenceSuccessComponent } from './components/contact-preference-success/contact-preference-success.component';

@Component({
  selector: 'bb-contact-preference-widget',
  templateUrl: 'contact-preference-widget.component.html',
  providers: [ContentService]
})

@RoutableWidget({
  routes: [
    { path: '', redirectTo: 'landing', pathMatch: 'full' },
    { path: 'landing', component: ContactPreferenceLandingComponent },
    { path: 'success', component: ContactPreferenceSuccessComponent },
  ]
})

export class ContactPreferenceWidgetComponent implements OnInit {

  constructor(private dataStoreService: DatastoreService) { }

  ngOnInit(): void {
    this.dataStoreService.sessionInvalid();
  }

}
