import { BrandContactDetailsItem } from '@wss/model/content/common/brand-contant-details';

export class ServiceException{
    errorImageType: any = ErrorImageType["Timeout Error"];
    imageClass: any = '';

    // phraseBold: any = '';
    phraseRegular?: any = '';
    // subPhraseRegular: any  = '';

    buttonText: any = '';
    componentUri: any = '';

    // callUs: boolean = true;
    // exceptionType: any = '';
    // helpNote: any = '';
    // beforeButtonText: any = '';
    // cancelButton: any = false;
    // cancelUrl: any = '';
    featurePage: any = '';

    errorInfoText: any = '';
    cancelLink: any = '';
    // errorInfoTextPartTwo: any = '';
    brandContactDetails: BrandContactDetailsItem = new BrandContactDetailsItem();
}

export enum ErrorImageType{
    "Timeout Error",
    "Date error",
    "Payment error"
}