import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { Router } from '@angular/router';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { BalloonRefinanceGetdetailsItem, ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from '@wss/payment-widget/src/services/payment.service';
import { BankAccount } from '@wss/payment-widget/src/models/direct-debit';
import { isEmpOrNullOrUndef, noWhitespaceValidator, validateDateDOB } from '@wss/common/util/util';
import { BalloonRefinanceService } from '@wss/balloon-refinance-widget/src/services/balloon-refinance.service';
import { takeUntil } from 'rxjs/operators';
import { patternMap } from '@wss/config/wss-app-constants';


@Component({
  selector: 'wss-balloon-refinance-getdetails-bank',
  templateUrl: './balloon-refinance-getdetails-bank.component.html'
})

export class BalloonRefinanceGetdetailsBankComponent implements OnInit {

  balloonRefinanceGetdetailsBankItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');

  currentPage: number = 5;
  brGetdetailsWidgetItem: any = new BalloonRefinanceGetdetailsItem();
  previousPageLink: string = '/balloon-refinance-getdetails/finances';
  accountDetails: any;
  commonNavTestID: string = 'wss-Brg-Bank-';
  lastDateOfDecision: any;
  idExpandArr: Array<any> = ['brbExpandAcountName', 'brbExpandSortCode', 'brbExpandAccountNumber', 'brbExpandLengthOfAccount'];
  idCollapseArr: Array<any> = ['brbCollapseAcountName', 'brbCollapseSortCode', 'brbCollapseAccountNumber', 'brbCollapseLengthOfAccount'];
  idPanelArr: Array<any> = ['brbInforAcountName', 'brbInforSortCode', 'brbInforAccountNumber', 'brbInforLengthOfAccount'];
  submitted: boolean = false;
  bankAccount: BankAccount = new BankAccount();
  years: Array<number> = [];
  months: Array<number> = [];
  bankAccountStatus: string = 'NONE';
  bankName: string = '';
  readonly destroy$ = new Subject();
  proposalData: ProposalRequest;
  serviceError: boolean = false;
  isReturnJourney: boolean = false;
  defaultTopMarginForAccountName: boolean = false;
  defaultDropdownValue = "";
  customerDetails: any;
  showDateDOBErrMessage: boolean = false;

  bankDetailsForm!: FormGroup;

  constructor(
    private readonly bbContentService: ContentService,
    private router: Router,
    private datastore: DatastoreService,
    private tealiumService: TealiumUtagService,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private balloonRefinanceSrv: BalloonRefinanceService,
  ) {
    this.proposalData = this.datastore.getProposalRequest();
  }

  ngOnInit(): void {
    this.datastore.sessionInvalid();
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'Proposal - Bank details',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.accountDetails = this.datastore.getAccountDetails();
    this.lastDateOfDecision = this.datastore.getLastDateForEocDecision(this.accountDetails?.final_payment_date);
    this.customerDetails = this.datastore.getCustomerDetail();
    this.setCurentPage();
    this.initBankForm();
    this.buildYearsDropdown();
    this.buildMonthsDropdown();
    this.setFormData();    
    this.datastore.brReturnJourney$.subscribe((res: boolean) => {
      this.isReturnJourney = res;
      this.changeDetectorRef.detectChanges();
    })
  }

  initBankForm() {
    this.bankDetailsForm = this.formBuilder.group({
      accountName: ['', [Validators.required, Validators.pattern(patternMap.bankAccNamePattern), noWhitespaceValidator]],
      sortCode: ['', [Validators.required, Validators.minLength(8)]],
      accountNumber: ['', [Validators.required, Validators.minLength(8)]],
      accountLengthMonth: ['' , Validators.required],
      accountLengthYear: ['' , Validators.required]
    })
  }

  buildMonthsDropdown() {
    this.months = [...Array(12).keys()];
  }

  buildYearsDropdown() {
    this.years = [...Array(100).keys()];
  }

  inputSortCode(e: any) {
    const currentValue = e.target.value;
    if ((currentValue.length === 2 || currentValue.length === 5) && e.keyCode !== 8) {
      e.target.value = `${currentValue}-`
    }
  }

  validBankInputs() {
    return this.bankDetailsForm.value.accountNumber.length === 8 && this.bankDetailsForm.value.sortCode.length === 8 && this.bankDetailsForm.value.accountName.length > 0;
  }

  validateAccount() {
    if (this.validBankInputs()) {
      const accountObject = {
        account_number: this.accountDetails.account_number,
        bank_sortcode: this.bankDetailsForm.value.sortCode.replaceAll('-', ''),
        bank_accno: this.bankDetailsForm.value.accountNumber,
        bank_acc_holdername: this.bankDetailsForm.value.accountName,
        borrower_indicator: this.accountDetails.borrower_indicator,
        payment_indicator: this.accountDetails.method_payment
      }
      this.paymentService.getBankAccountDetail(accountObject).subscribe((response: BankAccount) => {
        if (response && !isEmpOrNullOrUndef(response.bank_account_number)) {
          this.bankAccountStatus = 'VALID';
          if((response.bank_name === '') || (response.bank_name === ' ')) {
            this.bankName = '';
            this.showBankError();
          } else {
            this.bankName = response.bank_name;
          }
          this.bankDetailsForm.controls.sortCode.setErrors(null)
          this.bankDetailsForm.controls.accountNumber.setErrors(null)
        }
        else {
          this.showBankError();
        }
        this.changeDetectorRef.detectChanges();
      },
        (error: any) => {
          this.showBankError();
        });
      this.showBankError();
    }
    return;
  }

  showBankError() {
    this.bankAccountStatus = 'INVALID';
    this.bankDetailsForm.controls.sortCode.setErrors({ incorrect: 'true' })
    this.bankDetailsForm.controls.accountNumber.setErrors({ incorrect: 'true' })
  }

  scrollToFirstInvalid() {
    document.getElementsByClassName('ng-invalid')[1].scrollIntoView({ behavior: 'smooth' })
  }

  setCurentPage() {
    this.datastore.setBRCurrentPage(this.currentPage);
  }

  onSaveButtonClick(e: any) {
    this.router.navigate(['/myaccount']);
  }

  onContinue(e: any) {
    this.submitted = true;
    if ((this.bankDetailsForm.status === 'VALID') && (this.bankName !== '') && (!this.showDateDOBErrMessage)) {
      this.saveDataProposal('/balloon-refinance-getdetails/summary');
    }
    else {
      this.scrollToFirstInvalid();
    }
  }

  saveDataProposal(urlElem: string) {
    this.proposalData.customer_id = this.datastore.getCustomerId();
    this.proposalData.bank_sort_code = this.bankDetailsForm.value.sortCode.replaceAll('-', '');
    this.proposalData.bank_account_number = this.bankDetailsForm.value.accountNumber;
    this.proposalData.bank_account_name = this.bankDetailsForm.value.accountName;
    this.proposalData.years_with_bank = parseInt(this.bankDetailsForm.value.accountLengthYear);
    this.proposalData.months_with_bank = parseInt(this.bankDetailsForm.value.accountLengthMonth);

    this.balloonRefinanceSrv.postProposalDetails(this.proposalData).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.proposalData = this.datastore.getProposalRequest();
      this.isReturnJourney ?
        this.router.navigate(['/balloon-refinance-getdetails/summary']) : this.router.navigate([urlElem])
    },
      (error: any) => {
        this.showError();
    });
  }

  showError(): void {
    this.datastore.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  setFormData() {
    if (this.proposalData.bank_sort_code) {
      const proposalSortCode = this.proposalData.bank_sort_code?.slice(0, 2) + '-' + this.proposalData.bank_sort_code?.slice(2, 4) + '-' + this.proposalData.bank_sort_code?.slice(4, 6);
      this.bankDetailsForm.controls['sortCode'].setValue(proposalSortCode);
    }
    this.bankDetailsForm.controls['accountNumber'].setValue(this.proposalData.bank_account_number);
    this.bankDetailsForm.controls['accountName'].setValue(this.proposalData.bank_account_name);
    if (this.proposalData?.years_with_bank !== null) {
      this.bankDetailsForm.controls['accountLengthYear'].setValue(this.proposalData.years_with_bank);
    }
    if (this.proposalData?.months_with_bank !== null) {
      this.bankDetailsForm.controls['accountLengthMonth'].setValue(this.proposalData.months_with_bank);
    }
    this.validateAccount();
    this.changeDetectorRef.detectChanges();
  }

  checkDateDOB() {
    let yearOfBirth = parseInt(this.customerDetails?.individual.date_of_birth.substring(4, 8));
    let monthOfBirth = parseInt(this.customerDetails?.individual.date_of_birth.substring(2, 4));
    this.showDateDOBErrMessage = validateDateDOB(parseInt(this.bankDetailsForm.controls['accountLengthYear'].value), yearOfBirth, 
    parseInt(this.bankDetailsForm?.controls['accountLengthMonth'].value), monthOfBirth);
    this.bankDetailsForm?.controls['accountLengthMonth'].setValidators([Validators.required]);
    this.bankDetailsForm?.controls['accountLengthMonth'].updateValueAndValidity();
  }

  toggleMonthValidation() {
    this.bankDetailsForm.controls['accountLengthYear'].value === "0" ?
    this.bankDetailsForm?.controls['accountLengthMonth'].setValidators([Validators.required,Validators.min(1)]) 
    : this.checkDateDOB();    
  }
}
