import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BudgetPlanner } from '@wss/budget-cover-widgets/shared/model/budget.model';
import { BudgetService } from '@wss/budget-cover-widgets/shared/service/budget.service';
import { DatastoreService } from '@wss/service/datastore.service';


@Component({
  selector: 'bb-wss-save-and-exit',
  templateUrl: 'save-and-exit-modal.component.html'
})
export class SaveAndExitComponent implements OnInit {
  serviceError: boolean = false;
  budgetDetail: any

  @Input() fromParent: any;
  @Input() selfoobject: any;
  @Input() formValue: any;
  @Input() formType: any;

  public selfoObjects: any

  constructor(public activeModal: NgbActiveModal,
    private budgetService: BudgetService,
    private datastoreService: DatastoreService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) { }


  ngOnInit(): void {
    this.selfoObjects = this.selfoobject
    this.getServiceData()
  }

  saveAndExit() {
    this.updateForms()
    this.activeModal.dismiss('Close')
    window.scroll(0, 0);
  }


  updateForms() {
    if (this.formValue) {
      if (this.budgetDetail && this.budgetDetail.id) {
        this.updatePersonaData();
      }
      else {
        this.postPersonaData();
      }
    }
  }

  postPersonaData() {
    this.budgetService.postBudgetDetail(this.formValue, this.formType).subscribe((budgetResponse: BudgetPlanner) => {
      this.datastoreService.setBudgetDetail(budgetResponse);
      this.router.navigate(['help-centre/money-worries']);
    }, (error: any) => {
      this.showError();
    });
  }

  updatePersonaData() {
    this.budgetService.updateBudgetDetail(this.formValue, this.formType).subscribe((budgetResponse: BudgetPlanner) => {
      this.datastoreService.setBudgetDetail(budgetResponse);
      this.router.navigate(['help-centre/money-worries']);
    }, (error: any) => {
      this.showError();
    });
  }

  getServiceData() {
    this.budgetDetail = this.datastoreService.getBudgetDetail()
  }

  showError(): void {
    this.datastoreService.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

}
