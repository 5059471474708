<section class="balloon-refinance" *ngIf="brWidgetItem">
    <div role="alert" *ngIf="serviceError">
        <bb-wss-banner-message [errorCode]="errorCode"></bb-wss-banner-message>
    </div> 
    <div class="section-title-container">
        <h1 class="section-label-title" test_id="pageTitle">
            {{brWidgetItem.balloonRefinanceGetStarted.pageTitle}}</h1>
    </div>
    <section class="content-width single-column">
        <div class="mb-30px">
            <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.bodyFirstSection"></bb-content-html>
        </div>
        <div class="card card-padding">
            <div class="mb-10px">
                <h2>{{brWidgetItem.balloonRefinanceGetStarted.whatYouWillNeedHeader}}</h2>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.whatYouWillNeedContent"></bb-content-html>
            </div>
            <div class="mb-10px">
                <h2>{{brWidgetItem.balloonRefinanceGetStarted.howWeUseInformationHeader}}</h2>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.howWeUseInformationContent"></bb-content-html>
            </div>
            <div class="dpn-text-area">
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnTitle"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnPersonalInfoSection"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnFullPrivacyNoticeSection"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnHowWeUseInfoSection"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnShareInfoSection"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnCollectInfoSection"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnGiveUsInfoSection"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnRightsOverInfoSection"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnFinancialLinksSection"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnCreditAgenciesSection"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnFraudPreventionSection"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnContactFromUsAndMarketingSection"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnPrivacyNoticeSection"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnContactUsSection"></bb-content-html>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.dpnVersionControlSection"></bb-content-html>
            </div>
            <div>
                <h2>{{brWidgetItem.balloonRefinanceGetStarted.beforeYouContinueHeader}}</h2>
                <bb-content-html [html]="brWidgetItem.balloonRefinanceGetStarted.beforeYouContinueContent"></bb-content-html>
            </div>
        </div>
        <div class="mt-10px mb-40px">
            <wss-common-nav-buttons 
                [content]="brWidgetItem.commonNavigation"
                [accountsDetails]="accountDetails"
                [previousPageLink]="previousPageLink"
                [lastDateOfDecision]="lastDateOfDecision"
                [isPrimaryDisabled]="modagErrorFlag"
                (primaryButtonClicked)="onCommonNavButtonClick($event)"
                (continueButtonClicked)="onContinue($event)"
                [testIdText]="commonNavTestID">
            </wss-common-nav-buttons>
        </div>
    </section>
