<div class="login-container" *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <span class="section-label-size" test_id="loginWidgetItemneedHelpLinkContent">

    <bb-content-html [html]="loginWidgetItem.noaccountUser.titleText"></bb-content-html>
  </span>
  <hr />
  <div class="account-lock" test_id="loginWidgetItemlockContent">
    <div class="payment-warning-icon-div">
      <div class="acc-locked-icon"></div>
    </div>


    <p test_id="loginWidgetItemnoaccountnotMatchUserContent">

      <bb-content-html [html]="loginWidgetItem.noaccountUser.notMatch"></bb-content-html>
    </p>
    <p class="title-section-page text-center" test_id="loginWidgetItemneedHelpLinkContent">
      <!-- {{content[0].help}} -->
      <!-- {{loginWidgetItem.noaccountUser.helpLink}} -->
      <bb-content-html [html]="loginWidgetItem.noaccountUser.helpLink"></bb-content-html>
    </p>

    <span test_id="loginWidgetItemneedonlineLinkContent">
      <span class="child-para-inline">
        <bb-content-html [html]="loginWidgetItem.noaccountUser.onlineLink"></bb-content-html>
      </span>

      <span class="child-para-inline ">
        <bb-content-html [html]="loginWidgetItem.noaccountUser.createlink"></bb-content-html>
      </span>
    </span>

    <br>
    <br>
    <span test_id="loginWidgetItemnewCustContent">

      <span class="child-para-inline">
        <bb-content-html [html]="loginWidgetItem.noaccountUser.newCust"></bb-content-html>
      </span>


      <span class="child-para-inline">
        <bb-content-html [html]="loginWidgetItem.noaccountUser.creatingLink"></bb-content-html>
      </span>
    </span>
    <br>
    <br>
    <span test_id="loginWidgetItemendedAgreeContent">
      <span class="child-para-inline">
        <bb-content-html [html]="loginWidgetItem.noaccountUser.endedAgree"></bb-content-html>
      </span>
      <span class="child-para-inline" *ngIf="loginWidgetItem.brandContactDetails">
        <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html>
      </span>

      <span class="child-para-inline" *ngIf="loginWidgetItem.noaccountUser.touchLink">
        <bb-content-html [html]="loginWidgetItem.noaccountUser.touchLink"></bb-content-html>
      </span>
    </span>

    <br>

    <!-- <span test_id="loginWidgettouchCapslinkContent">
      <span class="child-para-inline">
        <bb-content-html [html]="loginWidgetItem.noaccountUser.touchCapslink"></bb-content-html>
      </span>
      <span class="child-para-inline" *ngIf="loginWidgetItem.brandContactDetails">
        <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html>
      </span>
      <span class="child-para-inline">
        <bb-content-html [html]="loginWidgetItem.noaccountUser.logLink"></bb-content-html>
      </span>
    </span> -->




    <div class="row margin-top-between-rows" style="justify-content: center;">


      <bb-content-html [html]="loginWidgetItem.noaccountUser.needLink"></bb-content-html>

    </div>
  </div>
</div>