import {Component, OnInit, ElementRef, ViewChild, AfterViewChecked, ChangeDetectorRef} from '@angular/core';
import {UserRepoService} from "../../service/user-repo.service";
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {RouterService} from "@backbase/foundation-ang/core";
import { Router } from '@angular/router';
import { Observable } from "rxjs";
//import {ContentService as OldContentService} from "../../../../service/content.service";
import { ContentService } from '@backbase/universal-ang/content';
import {ContentData} from '../../../../config/wss-app-constants';
import {DatastoreService} from '../../../../service/datastore.service';
import {LoginService} from "../../service/login.service";
import {LoginWidgetItem} from '../../../../model/login-widget/login-widget';
import {contentUpdate, isErrServer} from '../../../../common/util/util';
import {HttpService} from 'libs/service/http.service';
import { TealiumUtagService } from '@wss/service/utag.service';



@Component({
  selector: 'bb-wss-forgot-password',
  templateUrl: './forgot-password.component.html',
  providers: [ ContentService ]
})

export class ForgotPasswordComponent implements OnInit, AfterViewChecked {
  //contentTxt: string = ContentData.LoginForgotPassword;
  //content: Array<any> = [];
 // contentFolder: string = ContentData.Login;
  loginForm!: FormGroup;
  submitted = false;
  authorisation: any;
  serviceError: boolean = false;

  /*@ViewChild('txtHeader', { static: false })
  getHeader!: ElementRef;

  @ViewChild('txtMnContent', { static: false })
  getMnContent!: ElementRef;

  @ViewChild('txtAddlContent', { static: false })
  getAddlContent!: ElementRef;

  @ViewChild('txtLink', { static: false })
  getLink!: ElementRef;*/

  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');
  errorCode: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: RouterService,
    private userRepoService: UserRepoService,
    private datastoreService: DatastoreService,
    private loginService: LoginService,
    private readonly bbContentService: ContentService,
    //private contentService: OldContentService,
    private changeDetectorRef: ChangeDetectorRef,
    private httpService: HttpService,
    private tealiumService: TealiumUtagService,
  ) {
    this.userRepoService.updateScreenTitle('Authenticate');
  }  
  
  ngOnInit(): void {
    this.clearPrevSession();
    this.tealiumService.view(
      {
        JourneyName: 'Login',
        JourneyStepName: 'Forgot Password',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    //this.getStaticContent(this.contentTxt, this.contentFolder);
    this.getAuthorised();
    this.datastoreService.sessionLogin();
    this.loginForm= this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      // password: ['', Validators.required],
    });
  }

  ngAfterViewChecked() {
    /*contentUpdate(this.getHeader, 'loginFpSectionHeader');    
    contentUpdate(this.getMnContent, 'loginFpContent');
    contentUpdate(this.getAddlContent, 'loginFpExpire');
    contentUpdate(this.getLink, 'loginFpLink');*/
  }

  clearPrevSession() {
    this.httpService.getLogout().subscribe((data: any) => {      
    });      
  }


  setHeaders(token: any): void {
    this.datastoreService.setToken(token); 
  }

  showError(errStatus: number): void {
    if(isErrServer(errStatus)){
      this.datastoreService.setErrorServer();
      this.serviceError = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  getAuthorised(): void {    
    this.loginService.getAuthorisation().subscribe((data: any) => {
      this.authorisation = data;
      let token = this.authorisation.headers.get('x-authorization');
      this.setHeaders(token);
    },
    (error: any) => {
      this.errorCode = error.error?.code ? error.error.code : error.status;
      this.showError(error.status);
    });
  }

  get f() {return this.loginForm.controls;}

  onSubmit() {
    this.submitted = true;
    if(this.loginForm?.invalid){
      return;
    } else {
      let value = this.loginForm?.get('email')?.value.toLowerCase();
      this.loginService.getPwdLink(value).subscribe(() => {        
        this.router.navigate(['check-email']);
      },      
      (error:any) => {
        this.router.navigate(['check-email']);
      });
    }
  }
}
