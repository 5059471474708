import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bb-common-expansion-panel',
  templateUrl: './common-expansion-panel.component.html',
  styleUrls: ['./common-expansion-panel.component.css']
})
export class CommonExpansionPanelComponent implements OnInit {

  @Input() accordionContent: any[] = [];
  @Input() testIDText: string = '';
  @Input() isDeclinePage?: boolean = false;
  contentText: string = '';
  titleText: string = '';
  accordionId: any;
  newAccordionContent: any;

  constructor() { }

  ngOnInit(): void {
    this.contentText = this.testIDText + 'Content';
    this.titleText = this.testIDText + 'Title';
    if (this.isDeclinePage) {
      this.createUpdatedAccordionContent(this.accordionContent);
    }
  }

  createUpdatedAccordionContent(accordionContent: any[]) {
    this.newAccordionContent = accordionContent.map(obj => ({
      ...obj, id: 'wss-brg-decline-' + obj?.header?.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/\n/g, '-').replace(/ /g, '-') + 'expansion-panel'
    }))
    this.accordionContent = this.newAccordionContent
  };
}
