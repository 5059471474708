<div class="payment-holiday-faqs-container" *ngIf="helpCentreWidgetItem$ | async as helpCentreWidgetItem">
    <div class="container-column">
        <div class="payment-holiday-faqs-title">
            <span class="section-label-title" test_id="create_account_faq_label_id">
                {{helpCentreWidgetItem.paymentHolidayFaqs.pageName}}</span>
        </div>
        <div class="customer-support-text margin-top-30">
            <div test_id="pageHeaderBelowText">
                <bb-content-html [html]="helpCentreWidgetItem.paymentHolidayFaqs.pageHeaderText"></bb-content-html>
            </div>
            
            <div class="margin-top-30">
                <div class="payment-holiday-faqs container-row margin-top-30">
                    <mat-accordion class="accordion mat-accordion-override">
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="firstAccordionOptionOneTitle">
                                    <bb-content-html [html]="helpCentreWidgetItem.paymentHolidayFaqs.firstAccordionOptionOneTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p test_id="firstAccordionOptionOneContent">
                                <bb-content-html [html]="helpCentreWidgetItem.paymentHolidayFaqs.firstAccordionOptionOneContent"></bb-content-html>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="firstAccordionOptionTwoTitle">
                                    <bb-content-html [html]="helpCentreWidgetItem.paymentHolidayFaqs.firstAccordionOptionTwoTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p test_id="firstAccordionOptionTwoContent">
                                <bb-content-html [html]="helpCentreWidgetItem.paymentHolidayFaqs.firstAccordionOptionTwoContent"></bb-content-html>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="firstAccordionOptionThreeTitle">
                                    <bb-content-html [html]="helpCentreWidgetItem.paymentHolidayFaqs.firstAccordionOptionThreeTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p test_id="firstAccordionOptionThreeContent">
                                <bb-content-html [html]="helpCentreWidgetItem.paymentHolidayFaqs.firstAccordionOptionThreeContent"></bb-content-html>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="firstAccordionOptionFourTitle">
                                    <bb-content-html [html]="helpCentreWidgetItem.paymentHolidayFaqs.firstAccordionOptionFourTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p test_id="firstAccordionOptionFourContent">
                                <bb-content-html [html]="helpCentreWidgetItem.paymentHolidayFaqs.firstAccordionOptionFourContent"></bb-content-html>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="firstAccordionOptionFiveTitle">
                                    <bb-content-html [html]="helpCentreWidgetItem.paymentHolidayFaqs.firstAccordionOptionFiveTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p test_id="firstAccordionOptionFiveContent">
                                <bb-content-html [html]="helpCentreWidgetItem.paymentHolidayFaqs.firstAccordionOptionFiveContent"></bb-content-html>
                            </p>
                        </mat-expansion-panel>
                        
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="firstAccordionOptionSixTitle">
                                    <bb-content-html [html]="helpCentreWidgetItem.paymentHolidayFaqs.firstAccordionOptionSixTitle"></bb-content-html>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p test_id="firstAccordionOptionSixContent">
                                <bb-content-html [html]="helpCentreWidgetItem.paymentHolidayFaqs.firstAccordionOptionSixContent"></bb-content-html>
                            </p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>
    </div>
</div>
