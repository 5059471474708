import { NextPayment } from '../../../model/nextPayment';

export interface IAccountSummary{
    arrearData: Arrear;
    myAgreementData: MyAgreement;
    speedometerData: SpeedometerData,
    contractData: Contract,
    nextPaymentData: NextPayment,
    additionalInfoData: AdditionalInfo
}

export class AccountSummary implements IAccountSummary {
    arrearData: Arrear = new Arrear();
    myAgreementData: MyAgreement = new MyAgreement();
    speedometerData: SpeedometerData = new SpeedometerData();
    contractData: Contract = new Contract();
    nextPaymentData: NextPayment = new NextPayment();
    additionalInfoData: AdditionalInfo = new AdditionalInfo();
    carbonIoffsetBanner: CarbonIoffsetBanner = new CarbonIoffsetBanner();
    constructor(){}
  }

export interface IArrear{
    arrearAmount: string;
    isArrangement: boolean;
    inArrears: boolean;
}

export class Arrear implements IArrear{
    arrearAmount: string = '';
    isArrangement: boolean = false;
    inArrears: boolean = false;
}

export interface IMyAgreement{
    agreementType: string;
    vehicleType: string;
    agreementNumber: string;
    registrationNo: string;
}

export class MyAgreement implements IMyAgreement{
    agreementType: string = '';
    vehicleType: string = '';
    agreementNumber: string = '';
    registrationNo: string = '';
}

export interface ISpeedometerData{
    paidSoFar: string;
    amountleft: string;
    optionaFinalPayment: string;
    remainingAmount: string;
    lastPaymentAmount: string;
    dateReceived: string;
}

export class SpeedometerData implements ISpeedometerData{
    paidSoFar: string = '';
    amountleft: string = '';
    optionaFinalPayment: string = '';
    remainingAmount: string = '';
    lastPaymentAmount: string = '';
    dateReceived: string = '';
}

export interface IContract{
    paymentLeft: string;
    originalTerm: string;
}

export class Contract implements IContract{
    paymentLeft: string = '';
    originalTerm: string = '';
}

// export interface INextPayment{
//     amountDue: string;
//     dueDate: string;
// }

// export class NextPayment implements INextPayment{
//     amountDue: string = '';
//     dueDate: string = '';
// }

export interface IAdditionalInfo{
    startDate: string;
    apr: string;
    annualMileage: string;
    optionaFinalPayment: string;
    optionFee: string;
    finalPayment: string;
}

export class AdditionalInfo implements IAdditionalInfo{
    startDate = '';
    apr = '';
    annualMileage = '';
    optionaFinalPayment = '';
    optionFee = '';
    finalPayment = '';
}

export class CarbonIoffsetBanner {
    bannerInfo: string = "";
    bannerLink: string = "";
    brandsToShowCarbonOffsetContent: string = "";
}