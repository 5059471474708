<section class="custom-form budget-accomodation-cost content-margin" *ngIf="budgetFormItem$ | async as budgetFormItem">
  <!--*ngIf="budgetFormItem$ | async as budgetFormItem"-->
  <form [formGroup]="accommodationForm" formInvlidControlScroll>
      <div class="container-row" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
      </div>

      <div class="content-wrapper-container">
          <div class="container-column mx-3">
              <div class="d-flex section-title-container">
                  <span class="section-title" test_id="accomodationDetailpageName">
                      {{budgetFormItem.accomodationDetail.pageName}}
                  </span>
              </div>
              <div class="customer-support-text mt-4">
                  <div test_id="accomodationDetailHeaderBelowText">
                      <!----Great thanks. Now we need to know about how much you spend on where you live.-->
                      <bb-content-html [html]="budgetFormItem.accomodationDetail.pageHeaderBelowText">
                      </bb-content-html>
                  </div>
                  <div class="round-panel">
                      <!---mortgage rent or mortgage payments start-->
                      <div class="form-group">
                          <div class="form-group-heading" test_id="accomodationDetailDoYouPayMortgageText">
                              <!---- Do you pay any rent or mortgage payments for where you live?-->
                              {{budgetFormItem.accomodationDetail.doYouPayMortgageText}}
                          </div>
                          <div class="col-12">
                              <div class="radio mb-0">
                                  <label class="form-check-label">
                                      <input type="radio" class="form-check-input" name="doYouPayMortgage"
                                          formControlName="doYouPayMortgage" value="yes"
                                          ><span class="label"
                                          test_id="accomodationDetailDoYouPayMortgageYes">Yes</span>
                                  </label>
                              </div>
                              <div class="radio">
                                  <label class="form-check-label">
                                      <input type="radio" class="form-check-input" name="doYouPayMortgage"
                                          formControlName="doYouPayMortgage" value="no"
                                          ><span class="label"
                                          test_id="accomodationDetailDoYouPayMortgageNo">No</span>
                                  </label>
                              </div>
                          </div>
                          <div *ngIf="submitted && accommodationForm.get('doYouPayMortgage').invalid &&
                              accommodationForm.get('doYouPayMortgage').errors">
                              <small class="text-danger"
                                  *ngIf="accommodationForm.get('doYouPayMortgage').hasError('required')"
                                  test_id="accommodationFormErrorMsg">
                                  {{budgetFormItem.accomodationDetail.radioButtonErrorMsg}}
                              </small>
                          </div>
                      </div>
                      <!---mortgage rent or mortgage payments end-->
                      <!---how much do you pay start-->
                      <div  *ngIf="hasMorgageFilled?.value == 'yes'" formGroupName="accomodationAdditionalInfo">
                        <div class="form-group separator">
                            <div class="form-group-heading" test_id="accomodationDetailHowMuchDoYouPayText">
                                <!--How much do you pay?-->
                                {{budgetFormItem.accomodationDetail.howMuchDoYouPayText}}
                            </div>
                            <div class="amount-group">
                                <label class="subgroup" test_id="accomodationDetailAmountLabelContent">
                                    {{budgetFormItem.accomodationDetail.amount}}
                                </label>
                                <div class="position-relative">

                                    <span class="currency">£</span>
                                    <input type="text"
                                        class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                        test_id="accomodationDetailAmountInput"
                                        fiveDigitValidation="accomodationAdditionalInfo"
                                        formControlName="amount"
                                        onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                        [ngClass]="{'is-invalid':submitted &&  mortgageOrRent_amount.errors }"

                                        >
                                </div>
                            </div>
                            <div
                                *ngIf="submitted && mortgageOrRent_amount.invalid && mortgageOrRent_amount.errors && (mortgageOrRent_amount.errors.required || mortgageOrRent_amount.errors.pattern)">
                                <small class="text-danger" test_id="howMuchDoYouPayAmountControlerror">
                                    {{budgetFormItem.accomodationDetail.amountInputErrorMsg}}​​
                                </small>
                            </div>
                            <div
                                *ngIf="mortgageOrRent_amount.invalid && !mortgageOrRent_amount.errors.pattern && mortgageOrRent_amount.errors.fiveDigitError">
                                <small class="text-danger" test_id="howMuchDoYouPayAmountControlerror">
                                    {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                </small>
                            </div>
                            <div>
                                <label class="subgroup" test_id="accomodationDetailhowOftenLabelContent">
                                    <!---How Often-->
                                    {{budgetFormItem.accomodationDetail.howOften}}
                                </label>
                                <div class="select-wrapper">
                                    <select class="form-control"
                                        test_id="accomodationDetailhowOftenDropdown"
                                        fiveDigitValidation="accomodationAdditionalInfo"
                                        formControlName="frequency"
                                        [ngClass]="{'is-invalid':submitted &&  mortgageOrRent_frequency.invalid }"
                                        >
                                        <option selected [ngValue]="null">Please select</option>
                                        <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                    </select>
                                </div>
                            </div>
                            <div
                                *ngIf="submitted && mortgageOrRent_frequency.invalid && mortgageOrRent_frequency.errors">
                                <small class="text-danger"
                                    test_id="howOftenSelectControlError">
                                    {{budgetFormItem.accomodationDetail.dropdownErrorMsg}}
                                </small>
                            </div>
                        </div>
                        <!---how much do you pay end-->

                        <!---Is this for mortgage or rent start-->

                        <div class="form-group separator">
                            <div class="form-group-heading" test_id="accomodationDetailmortgageOrRentContent">
                                <!--- Is this for mortgage or rent?--->
                                {{budgetFormItem.accomodationDetail.mortgageOrRent}}
                            </div>
                            <div class="col-12">
                                <div class="radio mb-0">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" value="Mortgage" name="category"
                                            test_id="accomodationDetailmortgage" formControlName="category"><span
                                            class="label">Mortgage</span>
                                    </label>
                                </div>
                                <div class="radio">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" value="Rent" name="category"
                                            test_id="accomodationDetailRent" formControlName="category"><span
                                            class="label">Rent</span>
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="submitted && mortgageOrRent_category.invalid && mortgageOrRent_category.errors">
                                <small class="text-danger"
                                    test_id="mortgageOrRentErrorMsg">
                                    {{budgetFormItem.accomodationDetail.radioButtonErrorMsg}}
                                </small>
                            </div>
                        </div>
                        <!---Is this for mortgage or rent end-->
                        <!--Are you behind by any payment start-->
                        <div class="form-group separator" >
                            <div class="form-group-heading" test_id="accomodationDetailBehindByPaymentText">
                                <!--- Are you behind by any payments?-->
                                {{budgetFormItem.accomodationDetail.behindByPayment}}
                            </div>
                            <div class="col-12">
                                <div class="radio mb-0">
                                    <label class="form-check-label">
                                        <input type="radio"  class="form-check-input"
                                            name="behindByPayment" test_id="accomodationDetailBehindByPaymentRadioYes"
                                            value="yes" [checked]="howMuchBehindByPaymentField=='yes'" (change)="changeBehindByPayment($event)"><span
                                            class="label">Yes</span>
                                    </label>
                                </div>
                                <div class="radio">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input"
                                            name="behindByPayment" test_id="accomodationDetailBehindByPaymentRadioNo"
                                            value="no" [checked]="howMuchBehindByPaymentField =='no'" (change)="changeBehindByPayment($event)"><span
                                            class="label">No</span>
                                    </label>
                                </div>
                            </div>

                            <div *ngIf="submitted && !howMuchBehindByPaymentField">
                              <small class="text-danger"
                                  test_id="behindByPaymentErrorMsg">
                                  {{budgetFormItem.accomodationDetail.radioButtonErrorMsg}}
                              </small>
                            </div>
                            <div class="amount-group" *ngIf="howMuchBehindByPaymentField == 'yes' || mortgageOrRent_behind_payments_amount.value">
                                <label class="subgroup" test_id="accomodationDetailHowMuchBehindContent"
                                    test_id="accomodationDetailhowMuchBehindByPaymentText">
                                    {{budgetFormItem.accomodationDetail.howMuchBehindByPayment}}
                                </label>
                                <div class="position-relative">
                                    <span class="currency">£</span>
                                    <input type="text"
                                        class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                        test_id="accomodationDetailhowMuchBehindByPaymentInput"
                                        formControlName="behind_payments_amount"
                                        onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                                        [ngClass]="{'is-invalid':submitted && mortgageOrRent_behind_payments_amount.errors }"

                                        >
                                </div>
                                <div
                                    *ngIf="submitted && mortgageOrRent_behind_payments_amount.invalid">
                                    <small class="text-danger" test_id="accommodationFormControlerror">
                                        {{budgetFormItem.accomodationDetail.amountInputErrorMsg}}​
                                    </small>
                                </div>
                                <div
                                    *ngIf="!(mortgageOrRent_behind_payments_amount.errors && mortgageOrRent_behind_payments_amount.errors.pattern) && checkingFiveDigitValue()">
                                    <small class="text-danger" test_id="accommodationFormControlFiveDigiterror">
                                        {{budgetFormItem.commonDetail.arrearsFiveDigitError}}​​
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="form-group separator">
                            <div class="mt-2 form-group-heading" test_id="accomodationDetailAmountToPayOffText">
                                <!-- {{budgetFormItem.accomodationDetail.behindByPayment}}-->
                                {{budgetFormItem.accomodationDetail.additionalAmountToPayText}}​​
                            </div>
                            <div class="col-12">
                                <div class="radio mb-0">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="is_include_arrears"
                                            test_id="accomodationDetailbehindByPayment1RadioYes"

                                            formControlName="is_include_arrears" value='true'><span
                                            class="label">Yes</span>
                                    </label>
                                </div>

                                <div class="radio">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="is_include_arrears"
                                            test_id="accomodationDetailbehindByPayment1RadioNo"
                                            formControlName="is_include_arrears" value='false'><span
                                            class="label">No</span>
                                    </label>
                                </div>

                            </div>
                            <div *ngIf="submitted && mortgageOrRent_is_include_arrears.invalid &&
                                mortgageOrRent_is_include_arrears.errors">
                                    <small class="text-danger"
                                        test_id="behindByPaymentInputErrorMsg">
                                        {{budgetFormItem.accomodationDetail.radioButtonErrorMsg}}
                                    </small>
                                </div>
                        </div>

                      </div>
                        <!--Are you behind by any payment end-->
                        <!--Do you have any other costs? start-->
                      <div class="form-group separator" *ngIf="hasMorgageFilled?.value == 'yes'">
                          <div class="form-group-heading " test_id="accomodationDetailAnyOtherCostHeadingText">
                              <!--Do you have any other costs? --->
                              {{budgetFormItem.accomodationDetail.anyOtherCostHeading}}

                          </div>
                          <div class="form-group-description" test_id="accomodationDetailAnyOtherCostDescriptionText">
                              <!--Include things like ground rent, service charges, repairs or maintenence.-->
                              {{budgetFormItem.accomodationDetail.anyOtherCostDescription}}
                          </div>
                          <div class="col-12">
                              <div class="radio mb-0">
                                  <label class="form-check-label">
                                      <input type="radio" class="form-check-input" name="isOtherLivingCost"
                                          test_id="accomodationDetailAnyOtherCostDescriptionRadioYes" value="yes" formControlName="isOtherLivingCost"
                                          (change)="changeOtherLivingCost($event)"
                                          ><span class="label">Yes</span>
                                  </label>
                              </div>
                              <div class="radio">
                                  <label class="form-check-label">
                                      <input type="radio" class="form-check-input" name="isOtherLivingCost" formControlName="isOtherLivingCost"
                                          test_id="accomodationDetailAnyOtherCostDescriptionRadioNo" value="no"
                                          (change)="changeOtherLivingCost($event)"
                                          ><span class="label">No</span>
                                  </label>
                              </div>

                          </div>
                          <div *ngIf="submitted && isOtherLivingCost.invalid">
                            <small class="text-danger"
                                test_id="anyOtherCostDescriptiontErrorMsg">
                                {{budgetFormItem.accomodationDetail.radioButtonErrorMsg}}
                            </small>
                        </div>
                          <div formArrayName="Living_Cost_Other_additional_info"
                                class="form-group inner-icon right-icon login-input-field">

                                <div *ngFor="let item of accommodationForm.get('Living_Cost_Other_additional_info').controls; let itemIndex =index"
                                    [formGroupName]="itemIndex" class="form-group" [class.separator]="itemIndex > 0">

                                    <div class="amount-group">
                                        <div class="mt-2" *ngIf="itemIndex>0">
                                            <span class="form-group-heading" test_id="accomodationDetailAnyOtherCostText">
                                              Housing cost {{utilService.convertNumToString(itemIndex+1)}}
                                            </span>
                                            <span (click)="deleteOtherIncome(itemIndex)"
                                                class="text-right float-right position-relative cursor-default delete-button">
                                                <span class="cfa-delete-icon"></span>Delete
                                            </span>
                                        </div>
                                        <div><label class="subgroup" test_id="accomodationDetailtypeOfCostText">{{budgetFormItem.accomodationDetail.typeOfCost}}</label>
                                        </div>
                                        <div class="select-wrapper">
                                            <select class="form-control"
                                                formControlName="sub_category"
                                                test_id="accomodationDetailtypeOfCostDropdown"
                                                [ngClass]="{'is-invalid':submitted && accommodationForm.controls.Living_Cost_Other_additional_info.controls[itemIndex].controls.sub_category.invalid }"

                                                >
                                                <option selected [value]="null">Please select</option>
                                                <option *ngFor="let type of typeOfCosts" [value]="type">{{type}}
                                                </option>
                                            </select>
                                        </div>
                                        <div
                                            *ngIf="submitted && accommodationForm.controls.Living_Cost_Other_additional_info.controls[itemIndex].controls.sub_category.invalid">
                                            <small class="text-danger" test_id="typeOfCostControlErrormsg">
                                              {{budgetFormItem.accomodationDetail.dropdownErrorMsg}}
                                            </small>
                                        </div>
                                        <div>
                                            <label class="subgroup"
                                                test_id="accomodationDetailAmountContent">
                                                {{budgetFormItem.accomodationDetail.amount}}
                                            </label>
                                            <div class="position-relative">
                                                <span class="currency">£</span>
                                                <input type="number"
                                                    class="form-control amount-field pl-4 form-control-lg rounded-2 form-input"
                                                    formControlName="amount"
                                                    fiveDigitValidation="Living_Cost_Other_additional_info"
                                                    test_id="accomodationDetailTypeOfCostsInput"
                                                    [ngClass]="{'is-invalid':submitted && accommodationForm.controls.Living_Cost_Other_additional_info.controls[itemIndex].controls.amount.errors }"
                                            >

                                                <div
                                                    *ngIf="submitted && accommodationForm.controls.Living_Cost_Other_additional_info.controls[itemIndex].controls.amount.invalid && accommodationForm.controls.Living_Cost_Other_additional_info.controls[itemIndex].controls.amount.errors
                                                    && (accommodationForm.controls.Living_Cost_Other_additional_info.controls[itemIndex].controls.amount.errors.required || accommodationForm.controls.Living_Cost_Other_additional_info.controls[itemIndex].controls.amount.errors.pattern)">
                                                    <small class="text-danger"
                                                        test_id="typeOfCostAmountControlError">
                                                        {{budgetFormItem.accomodationDetail.amountInputErrorMsg}}
                                                    </small>
                                                </div>
                                                <div
                                                    *ngIf="accommodationForm.controls.Living_Cost_Other_additional_info.controls[itemIndex].controls.amount.errors && !accommodationForm.controls.Living_Cost_Other_additional_info.controls[itemIndex].controls.amount.errors.pattern && accommodationForm.controls.Living_Cost_Other_additional_info.controls[itemIndex].controls.amount.errors.fiveDigitError">
                                                    <small class="text-danger"
                                                        test_id="typeOfCostAmountControlError">
                                                        {{budgetFormItem.commonDetail.fiveDigitError}}​​
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                          <label class="subgroup"
                                              test_id="accomodationDetailHowOftenContent">{{budgetFormItem.accomodationDetail.howOften}}</label>
                                        </div>
                                        <div class="select-wrapper">
                                            <select class="form-control"
                                                test_id="accomodationDetailhowOftenDropdown1"
                                                formControlName="frequency"
                                                fiveDigitValidation="Living_Cost_Other_additional_info"
                                                indexVal = "{{itemIndex}}"
                                                required="required"
                                                [ngClass]="{'is-invalid':submitted &&  accommodationForm.controls.Living_Cost_Other_additional_info.controls[itemIndex].controls.frequency.invalid }"
                                                >
                                                <option selected [value]="null">Please select</option>
                                                <option *ngFor="let frq of frequencies" [value]="frq">{{frq}}</option>
                                            </select>
                                        </div>
                                        <div
                                            *ngIf="submitted && accommodationForm.controls.Living_Cost_Other_additional_info.controls[itemIndex].controls.frequency.invalid">
                                            <small class="text-danger"
                                                test_id="SelectFrqControlErrormsg">
                                                {{budgetFormItem.accomodationDetail.dropdownErrorMsg}}
                                            </small>
                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="(otherLivingCost.controls.length == maximumHousingCostEntries)">
                                  <div class="icon_info_circle position-absolute">
                                  </div>
                                  <div class="error-banner-section ">
                                    <bb-content-html [html]="budgetFormItem.commonDetail.maxCountReachedError"></bb-content-html>
                                  </div>
                                </div>
                                <button type="button" *ngIf= "(otherLivingCost.controls.length > 0 ) &&
                                                (otherLivingCost.controls.length < maximumHousingCostEntries)" class="wss-button-secondary pl-5 add-button" (click)="addother()" test_id="accomodationDetailAddButton">
                                  <span class="material-icons add-icon">
                                      add
                                  </span>
                                  {{budgetFormItem.accomodationDetail.addbutton}}
                              </button>
                          </div>
                      </div>

                  </div>
                  <!--Do you have any other ? end-->

                  <div class="container-row content-center margin-top-20">
                      <button class="wss-button btn btn-dif-view" type="submit" test_id="accomodationDetailButtonText"
                          (click)="goToNextPage()">
                          <!--Continue -->{{budgetFormItem.accomodationDetail.continuebuttonText}}
                      </button>
                  </div>
                  <div class="container-row content-center margin-top-20">
                    <button #button bbButton class="wss-button-secondary wss-button btn-dif-view" type="button"
                      (click)="openModal(budgetFormItem)" >
                   {{budgetFormItem.saveExitModal.saveAndExitButtonText}}
                    </button>
                  </div>
                  <div class="container-row content-center margin-top-20" (click)="goBackToIncomePage()"
                      test_id="accomodationDetailBackButtonText">
                      <a>
                          <!--Back a step-->{{budgetFormItem.accomodationDetail.backbuttonText}}
                      </a>
                  </div>
              </div>
          </div>
      </div>
  </form>
</section>
