<div class="bottom-gap-60" *ngIf="endOfContractWidgetItem$ | async as endOfContractWidgetItem">
    <div class="text-center">
        <div  class="tablet-icon"></div>
         <div class="m-tb-20 option-selection-text" test_id="optionSelectionText">
            <bb-content-html [html]="endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.optionSelectionText"></bb-content-html>
        </div>
    </div>
    
        <div class="eoc-container">
            <div class="card">
                <!-- <div class="card-content" test_id="nextStepContent">
                    <bb-content-html [html]=" endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.nextStepContent"></bb-content-html>
                </div> -->
                <div class="card-content">
                    <h3 test_id="nextStepText">{{ endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.nextStepText }}</h3>
                    <ol>
                        <li class="m-tb-20">
                            <div test_id="firstStepContent">
                                <bb-content-html [html]="endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.firstStepContent"></bb-content-html>
                            </div>
                        </li>
                        <li class="m-tb-20">
                            <div class="para-inline" test_id="secondStepContentBeforeData">
                                <bb-content-html [html]="endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.secondStepContentBeforeData"></bb-content-html>
                                <span><a title="Handback guide" class="link-text" (click)="downloadHandbook()"> {{endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.handbackguidelink}}</a>.</span>
                            </div>  
                        </li>
                        <li class="m-tb-20">
                            <div test_id="thirdStepContent">
                                <bb-content-html [html]="endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.thirdStepContent"></bb-content-html>
                            </div>
                        </li>
                    </ol>
                </div>

                <div class="card-footer">
                    <div class="d-flex">
                        <button class="wss-button" test_id="checkDamageChargesButton" (click)="goodConditionGuide()">{{ endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.checkDamageChargesButton }}​</button>
                    </div>
                </div>
            </div>   
            <div class="mt">
                <div class="mt-20px" test_id="whatHappensWhenWeCollectVehicleContentFirstData">
                    <bb-content-html [html]=" endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.whatHappensWhenWeCollectVehicleContentFirstData"></bb-content-html>
                </div>
            </div>

            <div class="para-inline" test_id="whatHappensWhenWeCollectVehicleContentBeforeData">
                <bb-content-html [html]=" endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.whatHappensWhenWeCollectVehicleContentBeforeData"></bb-content-html>
                <bb-good-condition-guide-url [linkText]="endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.goodConditionguideLink" [vehicleType]="accountDetails?.goods_description"></bb-good-condition-guide-url>
                <bb-content-html [html]=" endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.whatHappensWhenWeCollectVehicleContentAfterData"></bb-content-html>
            </div>
    
            <div class="mt-20px" test_id="whatHappensWhenWeCollectVehicleContentSecondData">
                <bb-content-html [html]=" endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.whatHappensWhenWeCollectVehicleContentSecondData"></bb-content-html>
            </div>
            <div class="d-flex">
                <button class="wss-button-secondary" test_id="downloadHandbookButtonText" (click)="downloadHandbook()">{{ endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.downloadHandbookButtonText }}​</button>
            </div>
            <div class="mt-20px" test_id="ifYouChangeYourMindContent">
                 <bb-content-html [html]=" endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.ifYouChangeYourMindContent"></bb-content-html>
            </div>
            <div class="d-flex">
                <button class="wss-button-secondary" (click)="backToHomepage()" test_id="backToHomepage">
                    {{ endOfContractWidgetItem.endOfContractReturnVehicleConfirmation.backToHomepage }}
                </button>
            </div>
</div>