import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { RouterService } from '@backbase/foundation-ang/core';
import { appConfig, RuntimeEnv, brandName } from '@wss/config/wss-app-constants';
import { HandBackChecklistWidgetItem } from '@wss/model/content/handback-checklist-widget';
import { EndOfContractService } from '@wss/end-of-contract-widget/src/services/end-of-contract.service';
import { DatastoreService } from '@wss/service/datastore.service';

@Component({
  selector: 'bb-handback-checklist-widget',
  templateUrl: './handback-checklist-widget.component.html',
  providers: [ ContentService ]
})
export class HandBackChecklistWidgetComponent implements OnInit, OnDestroy {
  handBackChecklistWidgetItem$: Observable<HandBackChecklistWidgetItem|undefined> = this.bbContentService.getContent<HandBackChecklistWidgetItem>('handBackChecklistWidgetItem');
  handBackChecklistWidgetItem: HandBackChecklistWidgetItem = new HandBackChecklistWidgetItem();
  portalName: any;
  accountDetails: any = {};
  dealersDetails: any;
  goodConditionURL: any;
  constructor(
    private readonly bbContentService: ContentService,
    // private router: RouterService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private eocService: EndOfContractService,
    private datastoreService: DatastoreService
  ) {
    this.renderer.addClass(document.body, 'no-nav-bar');
    this.renderer.addClass(document.body, 'handback-checklist-page');
  }
  
  ngOnInit(): void {
    this.accountDetails = this.datastoreService.getAccountDetails();
    this.getPortalName();
    this.handBackChecklistWidgetItem$.subscribe((handBackChecklistWidgetItem: HandBackChecklistWidgetItem| undefined)=> {
      if(handBackChecklistWidgetItem){
        this.handBackChecklistWidgetItem = handBackChecklistWidgetItem;
      }
    })
    this.getDealersDetails();
  }

  getPortalName() {
    if (RuntimeEnv.backbase) {
      this.portalName = window.BB.config.portalName;
      if (this.portalName === brandName.AM) {
        this.portalName = 'aston martin';
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  getDealersDetails() {
    this.eocService.getDealersDetails(this.accountDetails?.dealer_number)
    .subscribe((res: any) => {
      this.dealersDetails = res;
      this.getGoodConditionLink(this.dealersDetails?.dlr_type);
      this.changeDetectorRef.detectChanges();
    }, (error: any) => {
      // this.showError(error?.status);
    })
  }

  getGoodConditionLink(dealerType:any){
    this.goodConditionURL = this.eocService.getGoodConditionLink(this.portalName,dealerType,this.handBackChecklistWidgetItem.eocGoodConditionGuide);
  }
  
  navigateToLogin() {
    location.href = appConfig.loginURL;
  }

  navigateToRegistration() {
    location.href = appConfig.regURL;
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'no-nav-bar');
    this.renderer.removeClass(document.body, 'handback-checklist-page');
  }
}