import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { BannerMessage } from '@wss/model/bannerMessage';
import { LoginWidgetItem } from '@wss/model/login-widget/login-widget';
import { RegisterDatastoreService } from '@wss/registration-widget/src/service/register-datastore.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'bb-username-successful',
  templateUrl: './username-successful.component.html',
  styleUrls: ['./username-successful.component.scss'],
  providers: [ ContentService ]
})
export class UsernameSuccessfulComponent implements OnInit {
  serviceError: boolean = false;
  bannerMessage: BannerMessage = new BannerMessage();
  userAlias: any;
  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');

  constructor(private readonly bbContentService: ContentService,
    public router: Router,
    private registerDatastoreService: RegisterDatastoreService) { }

  ngOnInit(): void {
    this.userAlias = this.registerDatastoreService.getUserAlias();
  }

  submit() {
    this.router.navigate(['user-login']);
  }
}
