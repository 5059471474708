import { BrandContactDetailsItem } from "./content/common/brand-contant-details";

export class ComplaintWidgetItem {
    complaintGetStarted: ComplaintGetStarted = new ComplaintGetStarted();
    complaintRelateTo: ComplaintRelateTo = new ComplaintRelateTo();
    complaintTellUs: ComplaintTellUs = new ComplaintTellUs();
    complaintAboutYourVehicle: ComplaintAboutYourVehicle = new ComplaintAboutYourVehicle();
    complaintContactDetails: ComplaintContactDetails = new ComplaintContactDetails();
    complaintSubmission: ComplaintSubmission = new ComplaintSubmission();
    brandContactDetails: BrandContactDetailsItem = new BrandContactDetailsItem();
}

export class ComplaintGetStarted {
    pageTitle: string = '';
    makeComplaintInfo: string = '';
    consentInfo: string = '';
    concernHeadline: string = '';
    concernSubTitleText: string = '';
    vehicalLabel: string = '';
    vehicalLabelInfoText: string = '';
    financeAgreementLabel: string = '';
    financeAgreementLabelInfoText: string = '';
    getInTouchInfo: string = '';
    primaryButtonText: string = '';
    secondaryButtonText: string = '';
    unsavedChangesText: string = '';
    leaveButtonText: string = '';
    stayBackButtonText: string = '';
    journeyChangeUnsavedChangesText: string = '';
    journeyChangeProceedButtonText: string = '';
    journeyChangeStayButtonText: string = '';
    motorCommissionAdditionalInfoText: string = '';
    brandsForAdditionalInfoMotorCommission: string = '';
}

export class ComplaintRelateTo {
    pageTitle: string = '';
    financeAgreementPageTitle: string = '';
    pageHeaderBelowText: string = '';
    shareDetailWithDealerInfo: string = '';
    changeProfileText: string = '';
    profileLinkText: string = '';
    yourDetailHeaderText: string = '';
    yourNameLabel: string = '';
    addressLabel: string = '';
    mobileLabel: string = '';
    telephoneLabel: string = '';
    telephoneWorkLabel: string = '';
    emailLabel: string = '';
    agreementNumberLabel: string = '';
    vehicalRegistrationLabel: string = '';
    goodDescriptionLabel: string = '';
    disclaimerText: string = '';
    consentCheckboxText: string = '';
    concernsRaiseWithDealerText: string = '';
    yesText: string = '';
    noText: string = '';
    warningTextInfo: string = '';
    speakWithSomeElseLabel: string = '';
    alternativeConcatPersonInfo: string = '';
    complaintRelateToDropdownLable: string = '';
    complaintRelateToDropdownSubText: string = '';
    whatWentWrongLabel: string = '';
    primaryButtonText: string = '';
    secondaryButtonText: string = '';
    specialCharactersAllowedText: string = '';
    pleaseSelectAnOptionErrorMessage: string = '';
    pleaseEnterDetailsErrorMessage: string = '';
    pleaseConfirmDetailsErrorMessage: string = '';
    complaintCategoriesSQD: string = '';
    complaintCategoriesServicing: string = '';
    brandsToHideInfoBoxContent: string = '';
    authoriseToSpeakWithDealer: string = '';
    dealerContactPersonInfo: string = '';
}

export class ComplaintTellUs {
    pageTitle: string = '';
    pageHeaderBelowText: string = '';
    dealerAskedYouToContactLabel: string = '';
    dealerOfferResolutionLabel: string = '';
    yesText: string = '';
    noText: string = '';
    issueYouHaveExperiencedLabel: string = '';
    issueYouHaveHadLabel:string = '';
    summaryOfImpactLabel: string = '';
    howWouldYouLikeToResolveComplaintLabel: string = '';
    characterReaminingLabel: string = '';
    primaryButtonText: string = '';
    secondaryButtonText: string = '';
    dealersResolutionInfo: string = '';
    tellUsWhatDealerSaidText: string = '';
    complaintsTellUsEmailAddressText: string = '';
    complaintsTellUsEmailAddressTextForFinanceAgreement: string = '';
    complaintsEmailAddressForFinanceAgreement: any = '';
}

export class ComplaintAboutYourVehicle {
    pageTitle: string = '';
    pageHeaderBelowText: string = '';
    anyIssuesDiagnosedLabel: string = '';
    whatIssuesDiagnosedLabel: string = '';
    anyGarageOtherThanSupplyingLabel: string = '';
    authoriseToSpeakToGarageText: string = '';
    provideGarageContactDetailLabel: string = '';
    yesText: string = '';
    noText: string = '';
    whatCurrentMileageLabel: string = '';
    whereVehicleLocatedLabel: string = '';
    vehicleDriveableOrSafeLabel: string = '';
    primaryButtonText: string = '';
    secondaryButtonText: string = '';
    numeralsRemainingLabel: string = '';
}

export class ComplaintContactDetails {
    pageHeaderBelowText: string = '';
    preferredContactMethod: string = '';
    preferredContactNumberLabel: string = '';
    preferredContactTimeLabel: string = '';
    emailLabel: string = '';
    phoneLabel: string = '';
    letterLabel: string = '';
    mobileLabel: string = '';
    telephoneLabel: string = '';
    telephoneWorkLabel: string = '';
    morningTimeLabel: string = '';
    afternoonTimeLabel: string = '';
    eveningTimeLabel: string = '';
    workPhoneLabel: string = '';
    homePhoneLabel: string = '';
    bestTimeSelectionText: string = '';
    additionalSupportNeedsLabel: string = '';
    additionalSupportNeedsText: string = '';
    yesText: string = '';
    noText: string = '';
    confirmEmailEvidenceText: string = '';
    confirmEmailEvidenceTextForServicing: string = '';
    primaryButtonText: string = '';
    secondaryButtonText: string = '';
    complaintsRecipientEmailAddress: string = '';
    warningTextInfo: string = '';
}

export class ComplaintSubmission {
    submissionResultText: string = '';
    whatHappensNextText: string = '';
    backToHomeButtonText: string = '';
}