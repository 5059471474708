<div class="acct-smry-row account-summary-title">
    <!-- <span class="section-title caps-title" test_id="my_agreement_label_id">{{content?.welcome}} {{customerDetail?.individual?.first_name}}</span> -->
    <span class="section-title caps-title para-inline" test_id="my_agreement_label_id" data-cs-mask>
        <bb-content-html [html]="greetingText"></bb-content-html>
        {{customerDetail?.individual?.first_name}}</span>
 </div>
 <div class="acct-smry-column">
        <div class="acct-smry-row agreement-margin-top">
            <div class="container-row">
                    <span class="assignment-icon"></span>
            </div>
            <div class="acct-smry-column acct-smry-pad-left">
                <span *ngIf="(!isPcpToHpBalloonRefinanceFlag && !isLPAgreementFlag)" test_id="agreement_type_value_id">{{myAgreementData?.agreementType}}</span>                
                <span *ngIf="isLPAgreementFlag" test_id="agreement_type_value_id">{{leasePurchaseText}}</span>
                <span *ngIf="isPcpToHpBalloonRefinanceFlag" test_id="agreement_type_value_id">{{hpSchemeDescriptionText}}</span>
                <span test_id="agreement_number_id" data-cs-mask>{{myAgreementData?.agreementNumber}}</span>
            </div>
        </div>
        <div class="acct-smry-row agreement-margin-top-Vehicle">
            <div class="container-row">
                    <span class="vpn-key-icon"></span>
            </div>
            <div class="acct-smry-column acct-smry-pad-left">
                <div>
                    <span test_id="vehicle_type_value_id" class="d-inline">{{myAgreementData?.vehicleType}}</span> -  
                    <span test_id="registration_number_id" class="d-inline" data-cs-mask>{{myAgreementData?.registrationNo}}</span>
                </div> 
            </div>
        </div>
 </div>