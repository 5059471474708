import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { PaymentService } from '@wss/payment-widget/src/services/payment.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { MessageService } from '@wss/service/message.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-eoc-part-exchange-confirmation',
  templateUrl: 'eoc-part-exchange-confirmation.component.html'
})

export class EocPartExchangeConfirmationComponent implements OnInit {

  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');
  settlementData: any;
  today = moment().format('DD/MM/YYYY');
  newSettlementCallDay: string = '';
 
  constructor(private router: Router,
    private readonly bbContentService: ContentService,
    private datastoreService: DatastoreService,
    private paymentService: PaymentService,
    private messageService: MessageService,
    private tealiumService: TealiumUtagService,
    public changeDetectorRef: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.tealiumService.view (
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'End of Contract Part Exchange Confirmation',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.newSettlementCallDay = moment().format('DD/MM/YYYY');
    this.settlementData = this.datastoreService.getSettlementDetails();
      this.getSettlementDetail();
      this.handleErrors();
  }

  backToHomepage(){
    this.datastoreService.setEocRoute("landing");
    this.messageService.setActiveNav("End of contract");
    this.router.navigate(['/eoc-options/landing']);
  }

  getSettlementDetail() {
    this.paymentService.getSettlementDetail()
      .subscribe(
        data => {
          this.datastoreService.setSettlementDetails(data);
          this.settlementData = data;
          let localDate = this.settlementData?.settlement_expiry_date.split(' ');
          this.settlementData.settlement_expiry_date = localDate[0];
          this.changeDetectorRef.detectChanges();
        },
        (error: any) => {
          this.handleErrors();
        }
      );
  }

  getNewSettlementAmount(newSettlementFlag?: any) {
    window.scrollTo(0, 0);
    this.paymentService.getPostSettlementResponse(newSettlementFlag? newSettlementFlag: '')
    .subscribe((res: any) => {
      this.datastoreService.setSettlementResponse(res);
      this.settlementData = res;
      if (this.today == this.newSettlementCallDay) {
        sessionStorage.setItem("newSettlementCalledTodayAlready", "yes");
      } else {
          sessionStorage.setItem("newSettlementCalledTodayAlready", "no");
      }
      this.changeDetectorRef.detectChanges();
    })
  }

  handleErrors() {
    this.messageService.getServiceError().subscribe((error: any) => {
      if (error?.error[0].code == 17001) {
        this.getNewSettlementAmount();
      }
    });
  }
  
}
