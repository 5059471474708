import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatastoreService } from '../../../../service/datastore.service';
// import { ContentData } from '../../../../config/wss-app-constants';
// import { ContentService as OldContentService } from '../../../../service/content.service';
import { MessageService } from '../../../../service/message.service';
import { RouterService } from "@backbase/foundation-ang/core";
import { isEmpOrNullOrUndef, getErrorImageClass } from '../../../../common/util/util';
import { ServiceException } from '../../../../model/service-exception';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { PaymentHolidayWidgetItem } from '../../../../model/payment-holiday-widget/payment-holiday-widget';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'your-options',
    templateUrl: './your-options.component.html',
    providers: [ ContentService ]
})

export class YourOptionsComponent implements OnInit {
    // contentTxt: string = ContentData.YourOptions;
    // content: Array<any> = [];
    // contentFolder: string = ContentData.PaymentHoliday;
    currentStep: number = 1;
    availableAdvice: string = "";
    accountDetail: any = {};
    serviceException: ServiceException = new ServiceException();
    paymentHolidayWidgetItem$: Observable<PaymentHolidayWidgetItem | undefined> = this.bbContentService.getContent<PaymentHolidayWidgetItem>('paymentHolidayWidgetItem');
    paymentHolidayWidgetItem: PaymentHolidayWidgetItem = new PaymentHolidayWidgetItem();

    constructor(
        private router: RouterService,
        private datastoreService: DatastoreService,
        // private contentService: OldContentService,
        private changeDetectorRef: ChangeDetectorRef,
        private messageService: MessageService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService) { }

    ngOnInit() {
        this.accountDetail = this.datastoreService.getAccountDetails();
        this.availableAdvice = this.datastoreService.getAvailableAdvice();
        // this.getStaticContent(this.contentTxt, this.contentFolder);
        //this.handleErrors();
        this.messageService.setCurrentStep(0);
        this.paymentHolidayWidgetItem$.subscribe((paymentHolidayWidgetItem: any) => {
            this.paymentHolidayWidgetItem = paymentHolidayWidgetItem;
        });
        this.tealiumService.view(
            {
                JourneyName: 'Payment holiday',
                JourneyStepName: 'Your Options',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
    }

    navigateToTermExt(){
       if(this.checkIfWithin8DaysPayment()){
            this.showLessThan8DaysError();
        }
        else{
            this.messageService.setCurrentStep(1);
            this.router.navigate(['term-extension']);
        } 
    }

    showLessThan8DaysError(){
        this.serviceException.imageClass = getErrorImageClass(this.paymentHolidayWidgetItem.yourOptions.withIn8DaysException.errorImageType.toString());
        // if(this.paymentHolidayWidgetItem.yourOptions.withIn8DaysException.errorImageType.toString() === 'Date error'){
        //     this.serviceException.imageClass = "service-dateerror";
        // }
        // else if(this.paymentHolidayWidgetItem.yourOptions.withIn8DaysException.errorImageType.toString() === 'Timeout Error'){
        //     this.serviceException.imageClass = "service-timeout";
        // }
        // else{
        //     this.serviceException.imageClass = "service-wallet-error";
        // }
       // this.serviceException.imageClass = "service-dateerror";
        // this.serviceException.phraseBold = "We're sorry. You can't apply for a Term Extension right now because your next payment is due within 7 days. Please try again after you have made your next payment.";
        // this.serviceException.helpNote = `If you can't make your next payment, please call us on <a>0800 000 000</a> to discuss options to help you. To give you the best support when you call us, we advise you to fill in our online <a>Budgeting Tool</a> before calling.`;
        this.serviceException.errorInfoText = this.paymentHolidayWidgetItem.yourOptions.withIn8DaysException.errorInfoText;
        //this.serviceException.buttonText = "Back to Home";
        this.serviceException.buttonText = this.paymentHolidayWidgetItem.yourOptions.withIn8DaysException.buttonText;
        // this.serviceException.componentUri = "myaccount";
        this.serviceException.componentUri = this.paymentHolidayWidgetItem.yourOptions.withIn8DaysException.componentUri;
        this.serviceException.cancelLink = this.paymentHolidayWidgetItem.yourOptions.withIn8DaysException.cancelLink;
        this.serviceException.brandContactDetails = this.paymentHolidayWidgetItem.brandContactDetails;
        this.messageService.setServiceException(this.serviceException);
        this.router.navigate(['/payments/service-exception']);
    }

    checkIfWithin8DaysPayment(): boolean {
        let todayDay = new Date().getDate();
        let nextPaymentDay = this.accountDetail.next_payment_date;
        if (!isEmpOrNullOrUndef(nextPaymentDay)) {
            nextPaymentDay = parseInt(nextPaymentDay.split("/")[0]);
            if (todayDay > nextPaymentDay) {
                return false;
            }
            else if ((todayDay < nextPaymentDay) && ((nextPaymentDay - todayDay) < 8)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
}