import {Component, OnInit, OnDestroy} from '@angular/core';
import {NavigationStart, Router} from "@angular/router";
import {UserRepoService} from "../../service/user-repo.service";
import {ContentService as OldContentService} from "../../../../service/content.service";
import {ContentData} from '../../../../config/wss-app-constants';
import {ItemModel} from "@backbase/foundation-ang/core";
import {RegistrationStoreModelService} from "../../../../registration-store/src/registration-store-model.service";
import {Observable, Subject} from "rxjs";
import {takeUntil} from 'rxjs/operators';
import {RegisterDatastoreService} from '../../service/register-datastore.service';
import { RegistrationWidgetItem } from '@wss/model/registrationToken';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-wss-no-user-found',
  templateUrl: './security-fail.component.html',
  providers: [ ContentService ]
})

export class SecurityFailComponent implements OnInit, OnDestroy {
  getAttemptError: boolean = false;
  getTimeoutError: boolean = false;

  readonly destroy$ = new Subject();

  registrationWidgetItem$: Observable<RegistrationWidgetItem | undefined> = this.bbContentService.getContent<RegistrationWidgetItem>('registrationWidgetItem');

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Registration',
        JourneyStepName: 'Security Fail',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
    this.itemModel.property('userDetailsTitle').pipe(takeUntil(this.destroy$)).subscribe(value => this.registrationService.updateTitle(value as string));
    this.getAttemptError = this.registerstoreService.getIIQattempt();
    this.getTimeoutError = this.registerstoreService.getIIQtimeout();
  }

  ngOnDestroy(): void {
    this.destroy$.next();  
    this.destroy$.complete();    
    this.router.navigate(['user-detail']);
  }

  constructor(
    private router: Router,
    private itemModel: ItemModel,
    private contentService: OldContentService,
    private registrationService: RegistrationStoreModelService,
    private userRepoService: UserRepoService,
    private registerstoreService: RegisterDatastoreService,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
  ) {
    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          this.router.navigate(['user-detail']);
        }
      }
    });
    this.userRepoService.updateScreenTitle('Your details');
  }

  submit() {
    this.userRepoService.updateScreenTitle('Create An Account');
    this.router.navigate(['/']);
  }

  tryOption() {
    this.router.navigate(['user-detail']);
  }
}
