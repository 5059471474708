import { Injectable } from '@angular/core';
import { DatastoreService } from '@wss/service/datastore.service';
import { HttpService } from '@wss/service/http.service';
import { MessageService } from '@wss/service/message.service';
import { Observable, of } from 'rxjs';
import { catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CarbonOffsetService {

  constructor(
    private httpService: HttpService,
    private messageService: MessageService,
    private datastoreService: DatastoreService) { }

    postCustomerConsent(): Observable<any> {
        return this.httpService.postCustomerConsents()
        .pipe(
            map((data) => {
                return data;
            }),
            catchError(error => {
                this.messageService.setServiceError(error);
                return error;
            })
        );
    }

}