
import { ActionTypes, BalloonRefinanceActions} from "./balloonRefinance.action"
import { VehicleState } from "./balloonRefinance.state"

export interface UserDetailsState {
    userDetails: {},
    error?: any,
    status?: string
}
export const initialState: UserDetailsState = {
    userDetails: {},
    error: null,
    status: "pending"
}
export const vehicleInitialState: VehicleState = {
    //vehicle: Vehicle,
    error: 'null',
    status: 'pending'
}

export const balloonRefinanceReducer = (state = vehicleInitialState, action: BalloonRefinanceActions): VehicleState => {
    switch (action.type) {
        case ActionTypes.LOAD_VEHICLE:
            {
                return {
                ...state,
                //vehicle: {},
                error: 'null',
                status: 'loading'
                }
            }
        case ActionTypes.LOAD_VEHICLE_SUCCESS: 
            {
                return {
                    ...state,
                    vehicle: action.payload,
                    error: 'null',
                    status: 'success'
                }
            }
    
        default:
            {
                return state
            }
    }
}


