import { Component, OnInit, Input } from '@angular/core';
declare var d3: any;

@Component({
  selector: 'bb-wss-summary',
  templateUrl: 'summary.component.html',
})
export class SummaryComponent implements OnInit {
  @Input()
  budgetSummaryData: any;

  @Input()
  budgetFormItem: any;

  constructor() {}

  ngOnInit(): void {
    this.showSpeedometer();
  }

  showSpeedometer(): void {
    var width = 260,
      height = 300,
      margin = 20;

    var radius = Math.min(width, height) / 2 - margin;
    var svg = d3
      .select('#speedometer_id')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    /**
    * As per the requirement if income and money-out amount will be 0 then
    * we need to display filled circle with gray color so that's why we are setting
    * up the default value.
    */
    var data =
      this.budgetSummaryData.income != '0' ||
      this.budgetSummaryData.moneyOut.toString() != '0'
        ? { a: this.budgetSummaryData.income, b: this.budgetSummaryData.moneyOut }
        : { a: 0, b: 1 };

    var color = d3.scaleOrdinal().domain(data).range(['#107EB1', '#b2beb5']);

    var pie = d3
      .pie()
      .value(function (d: any) {
        return d.value;
      })
      .sort(null);

    var data_ready = pie(d3.entries(data));

    svg
      .selectAll('summary')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', d3.arc().innerRadius(100).outerRadius(radius))
      .attr('fill', function (d: any) {
        return color(d.data.key);
      })
      .style('opacity', 0.7);
  }
}
