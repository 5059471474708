<div class="container-column" *ngIf="headerContentWidgetItem$ | async as headerWidgetItem">
  <div class="pb-40px">
    <div class="user-contact-details-wrapper">
      <div class="title-header">
        <span class="section-label-title" test_id="update_contact_lable">
         {{headerWidgetItem?.updateEmail?.pageTitle}} 
        </span>
        <hr />
        <div *ngIf="errorDetails" test_id="userEmailPasswordConnfirmContent">
          <bb-wss-banner-message>
          </bb-wss-banner-message>
        </div>
        <div *ngIf="showForm">  
          <span class="para-inline contact-number-text" test_id="uc_pageWarnig">
			      <bb-content-html [html]="headerWidgetItem.updateEmail.emailPageHeadingInfo"></bb-content-html>
          </span>
        </div>
        <div *ngIf="showForm">  
          <span class="para-inline contact-number-text" test_id="uc_pageWarnig">
			      <span *ngIf="headerWidgetItem.updateEmail.emailPageHeadingInfoPartTwo">
              <bb-content-html [html]="headerWidgetItem.updateEmail.emailPageHeadingInfoPartTwo"></bb-content-html>
            </span>
          </span>
        </div>

      </div>
      <form [formGroup]="emailForm" *ngIf="showForm" class="mt-30px">
		  <div class="form-group input-field">
          <label for="email" test_id="uc_email">
            {{headerWidgetItem?.updateEmail?.emailLabel}}
          </label>
          <input type="email" class="form-control" id="email" name="email" formControlName="email"
            aria-describedby="email" placeholder="e.g. name@domain.co.uk" required="true" accesskey="m" data-cs-mask>
          <ng-container *ngIf="existingEmail;else invalidEmail">
            <div class="error-text">
              <bb-content-html [html]="headerWidgetItem.updateEmail.existingEmailErrorMessage"></bb-content-html>
            </div>
          </ng-container>
          <ng-template #invalidEmail>
              <ng-container *ngIf="emailForm.controls.email.dirty && !emailForm.controls?.email?.valid;else emailClear">      
                <div class="error-text">
                  <bb-content-html [html]="headerWidgetItem.updateEmail.emailErrorMessage"></bb-content-html>
                </div>
              </ng-container>
          </ng-template>
          <ng-template #emailClear></ng-template>

        </div>
        <div class="form-group input-field">
          <label for="confirmEmail" test_id="uc_email">
            {{headerWidgetItem?.updateEmail?.confirmEmailLabel}}
          </label>
          <input blockPaste type="email" class="form-control" id="confirm_email" name="confirm_email" formControlName="confirm_email"
            aria-describedby="confirm_email" placeholder="e.g. name@domain.co.uk" required="true" accesskey="m" data-cs-mask>
          <ng-container *ngIf="existingConfirmEmail;else invalidConfirmEmail">
            <div class="error-text">
              <bb-content-html [html]="headerWidgetItem.updateEmail.existingEmailErrorMessage"></bb-content-html>
            </div>
          </ng-container>
          <ng-template #invalidConfirmEmail>
              <ng-container *ngIf="emailForm.controls.confirm_email.dirty && !emailForm.controls?.confirm_email?.valid;else confirmEmailMismatch">      
                <div class="error-text">
                  <bb-content-html [html]="headerWidgetItem.updateEmail.emailErrorMessage"></bb-content-html>
                </div>
              </ng-container>
          </ng-template>
          <ng-template #confirmEmailMismatch>
            <ng-container *ngIf="emailForm.controls.confirm_email.dirty && emailForm.controls?.confirm_email?.value !== emailForm.controls?.email?.value;else confirmEmailClear">      
              <div class="error-text">
                <bb-content-html [html]="headerWidgetItem.updateEmail.emailMismatchErrorMessage"></bb-content-html>
              </div>
            </ng-container>
          </ng-template>
          <ng-template #confirmEmailClear></ng-template>

		  
        </div>
        <div *ngIf="showForm">  
          <span class="para-inline contact-number-text" test_id="uc_pageWarnig">
			      <bb-content-html [html]="headerWidgetItem.updateEmail.beforeYouContinue"></bb-content-html>
          </span>
        </div>
        <div *ngIf="showForm">  
          <span class="para-inline contact-number-text" test_id="uc_pageWarnig">
			      <bb-content-html [html]="headerWidgetItem.updateEmail.beforeYouContinuePt2"></bb-content-html>
          </span>
        </div>
        <div class="form-group input-field" test_id="uc_email">

          <bb-input-password-ui placeholder="" formControlName="password" label="{{headerWidgetItem?.updateEmail?.enterPasswordLabel}}"
            autocomplete="current-password" [aria-describedby]="'password-error'" [showVisibilityControl]="true" data-cs-mask>
          </bb-input-password-ui>
        </div>
        <div class="links-container">
          <div class="d-flex justify-content-left contact-details-form-btn">
            <button id="update-email-btn" type="button" class="wss-button wss-button-update-form" title="headerWidgetItem?.updateEmail?.buttoneLabel" test_id="uc_update"
              (click)="onSubmit()" [disabled]="disabled">
              {{headerWidgetItem?.updateEmail?.buttoneLabel}}
            </button>
          </div>
        </div>
      </form>
      <div class="links-container" *ngIf="showForm">
        <div class="d-flex justify-content-center help-blink">
          <bb-content-html [html]="headerWidgetItem.updateEmail.needHelpLink"></bb-content-html>
        </div>
      </div>
      <div class="change-password-success-container result-container" *ngIf="successStatus">
        <div class="request-result-section">
          <div class="trophy-icon-div">
            <span class="success_icon success-icon-style" title="icon-success"></span>
          </div>
          <div class="d-flex justify-content-center request-result-text">
            <span class="para-inline">
                <bb-content-html [html]="headerWidgetItem.updateEmail.successMessage"></bb-content-html>
                <strong>
                  <bb-content-html [html]="headerWidgetItem.brandContactDetails.portalName"></bb-content-html> 
                </strong>
                <bb-content-html [html]="headerWidgetItem.updateEmail.successMessagePt2"></bb-content-html>
            </span>
          </div>
          <div class="d-flex justify-content-center result-info-text">
            <bb-content-html [html]="headerWidgetItem.updateEmail.successinfo"></bb-content-html>
          </div>
          <div *ngIf="!isAccountArchived" class="d-flex justify-content-center ">
            <button type="button" class="wss-button wss-button-update success-back-button" test_id="uc_backtohome"
              (click)="backToMyAccountClick()" title="{{headerWidgetItem?.updateEmail?.backToHomeLabel}}">
              {{headerWidgetItem?.updateEmail?.backToHomeLabel}}
            </button>
          </div>
          <div *ngIf="isAccountArchived" class="d-flex justify-content-center ">
            <button type="button" class="wss-button wss-button-update success-back-button" test_id="uc_backtohome"
              (click)="backToMailBoxClick()" title="{{headerWidgetItem?.updateEmail?.backToMailBoxLabel}}">
              {{headerWidgetItem?.updateEmail?.backToMailBoxLabel}}
            </button>
          </div>

         
        </div>
      </div>
      <div *ngIf="successStatus">
        <div class="d-flex justify-content-center help-blink">
          <bb-content-html [html]="headerWidgetItem.updateEmail.needHelpLink"></bb-content-html>
        </div>
      </div>
    </div>
  </div>
</div>