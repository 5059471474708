<div class="login-container password-successful" *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <div class="account-lock">
    <div class="success-icon"></div>
    <!-- Main text -->
    <p class="title-section-page text-center"  test_id="loginWidgetItemchangedTextContent">
      <bb-content-html [html]="loginWidgetItem.passwordChangeSuccess.changedText"></bb-content-html>
    </p>
    <p test_id="loginWidgetPasswordChangeSuccessContent">
      <!-- Email confirm text -->
      <bb-content-html [html]="loginWidgetItem.passwordChangeSuccess.emailConfirm"></bb-content-html>
    </p>
  </div>
 
  <div id="password-change-success-btn" class="row margin-top-between-rows login-page-button text-center" test_id="loginWidgetPasswordChangeSuccessLoginButtonTextContent">
    <button id="bb-button-to" bbButton type="submit" aria-label="Favourite" (click)="submit()"
      [className]="'wss-button'" title="{{loginWidgetItem.passwordChangeSuccess.btnLoginText}}" class="button-width-padding">
      <!-- Login button -->
      {{loginWidgetItem.passwordChangeSuccess.btnLoginText}}
    </button>
  </div>
</div>
