import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MessageBannerComponent } from './components/banner-message/banner-message.component';
import { FooterComponent } from './components/footer/footer.component';
import { NextPaymentComponent } from './components/next-payment/next-payment.component';
import { AllowCharacterDirective } from './directives/allow-character.directive';
import { AutoFocusNextDirective } from './directives/auto-focus-next.directive';
import { TextLinkRegistrationComponent } from './components/common-textlink/textlink-registration.component';
import { CommonIconComponent } from './common-icon/common-icon.component';
import { SendSMSComponent } from './components/send-sms/send-sms.component';
import {
    ButtonModule,
    HeaderModule,
    InputEmailModule,
    InputNumberModule,
    InputPasswordModule,
    InputTextModule,
    InputValidationMessageModule,
    StepperModule
  } from "@backbase/ui-ang";
import { MatBadgeModule } from '@angular/material/badge';
import { StepperComponent } from './components/stepper/stepper.component';
import { PasswordRulesComponent } from './components/password-rules/password-rules.component';
import { MatIconModule } from '@angular/material/icon';
import { ChangePasswordComponent } from 'libs/header-content-widget/src/components/change-password/change-password.component';
import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';
import { CommonHeaderWidgetComponent } from './components/common-header/common-header.component';
import { CommonContractDetailsComponent } from './components/common-contract-details/common-contract-details.component';
import { GoodConditionGuideUrlComponent } from '@wss/end-of-contract-widget/src/components/good-condition-guide-url/good-condition-guide-url.component';
import { FormInvlidControlScrollDirective } from './directives/form-invalid-scroll.directive';
import { FiveDigitDirective } from './directives/five-digit.directive';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonExpansionPanelComponent } from './components/common-expansion-panel/common-expansion-panel.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { BlockPasteDirective } from './directives/block-paste.directive';
import { CommonNavButtonsComponent } from './components/common-nav-buttons/common-nav-buttons.component';
import { InformationToolComponent } from './components/information-tool/information-tool.component';
import { CurrencyInputComponent } from './components/currency-input/currency-input.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { AddressDisplayComponent } from './components/address-display/address-display.component';
import { ContactAgencyWidgetComponent } from './components/contact-agency/contact-agency.component';
import { PopoverComponent } from './components/popover/popover.component';
import { PopoverDirective } from './directives/popover.directive';
import { InfoAlertComponent } from './components/info-alert/info-alert.component';
import { WarningAlertComponent } from './components/warning-alert/warning-alert.component';

@NgModule({
    imports: [
        CommonModule,
        StepperModule,
        StepperModule,
        InputNumberModule,
        InputValidationMessageModule,
        ButtonModule,
        HeaderModule,
        InputEmailModule,
        InputPasswordModule,
        InputTextModule,
        RouterModule.forRoot([], { initialNavigation: 'disabled', useHash: true, relativeLinkResolution: 'legacy' }),
        RouterModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        ContentWidgetModule,
        ContentModule,
        MatBadgeModule,
        NgbPopoverModule,
        MatExpansionModule
    ],
    declarations: [
        MessageBannerComponent,
        NextPaymentComponent,
        AllowCharacterDirective,
        AutoFocusNextDirective,
        TextLinkRegistrationComponent,
        CommonIconComponent,
        StepperComponent,
        SendSMSComponent,
        PasswordRulesComponent,
        ChangePasswordComponent,
        FooterComponent,
        CommonHeaderWidgetComponent,
        CommonContractDetailsComponent,
        GoodConditionGuideUrlComponent,
        FormInvlidControlScrollDirective,
        FiveDigitDirective,
        CommonExpansionPanelComponent,
        BlockPasteDirective,
        CommonNavButtonsComponent,
        InformationToolComponent,
        PageTitleComponent,
        CurrencyInputComponent,
        AddressDisplayComponent,
        ContactAgencyWidgetComponent,
        PopoverComponent,
        PopoverDirective,
        InfoAlertComponent,
        WarningAlertComponent
    ],
    exports: [
        MessageBannerComponent,
        NextPaymentComponent,
        AllowCharacterDirective,
        AutoFocusNextDirective,
        TextLinkRegistrationComponent,
        CommonIconComponent,
        StepperComponent,
        SendSMSComponent,
        PasswordRulesComponent,
        ChangePasswordComponent,
        FooterComponent,
        CommonHeaderWidgetComponent,
        CommonContractDetailsComponent,
        GoodConditionGuideUrlComponent,
        FormInvlidControlScrollDirective,
        FiveDigitDirective,
        CommonExpansionPanelComponent,
        BlockPasteDirective,
        CommonNavButtonsComponent,
        InformationToolComponent,
        PageTitleComponent,
        CurrencyInputComponent,
        AddressDisplayComponent,
        ContactAgencyWidgetComponent,
        PopoverComponent,
        PopoverDirective,
        InfoAlertComponent,
        WarningAlertComponent
    ]
})
export class WSSCommonModule { }
