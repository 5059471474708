import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PopoverService } from '../service/popover.service';

@Directive({
  selector: '[popoverTrigger]'
})
export class PopoverDirective implements OnInit, OnDestroy{

  @Input()
  popoverTrigger!: TemplateRef<object>;

  private unsubscribe = new Subject();
  private overlayRef!: OverlayRef;

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private vcr: ViewContainerRef,
    private popoverService: PopoverService,
    private renderer: Renderer2,
  ) { }

  @HostListener("click") clickou() {
    this.attachOverlay();
  }
  
  ngOnInit(): void {
    this.createOverlay();
    this.popoverService.getState().subscribe((res: boolean) => {
      if(res) {
        this.renderer.removeClass(document.body, 'nav-bar-top-overlay');
        this.detachOverlay();
      }
    })
  }

  private createOverlay(): void {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo(this.elementRef)
    .withPositions(
      [{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
      }]
    );

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
    });

    this.overlayRef
    .backdropClick()
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(() => {
      this.detachOverlay();
    })
  }

  private attachOverlay(): void {
    if (!this.overlayRef.hasAttached()) {
      const periodSelectorPortal = new TemplatePortal(
        this.popoverTrigger,
        this.vcr
      );
      if (window.scrollY > 0) {
        this.renderer.addClass(document.body, 'nav-bar-top-overlay');
      } else {
        this.renderer.removeClass(document.body, 'nav-bar-topoverlay');
      }
      this.popoverService.setState(false);
      this.overlayRef.attach(periodSelectorPortal);
    }
  }

  private detachOverlay(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  ngOnDestroy(): void {
    this.detachOverlay();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
