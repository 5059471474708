<div *ngIf="endOfContractWidgetItem$ | async as endOfContractWidgetItem">
    <div class="text-center">
        <div class="tablet-icon"></div>
        <div class="m-tb-20 option-selection-text" test_id="optionSelectionText">
            <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchangeConfirmation.optionSelectionText"></bb-content-html>
        </div>
    </div>
    <div class="eoc-container">
        <div class="m-tb-20">
            <div class="card">
                <div class="card-content">
                    <h3 test_id="nextStepText">{{ endOfContractWidgetItem.endOfContractPartExchangeConfirmation.nextStepText }}</h3>
                    <ol>
                        <li class="m-tb-20">
                            <div test_id="firstStepContent">
                                <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchangeConfirmation.firstStepContent"></bb-content-html>
                            </div>
                        </li>
                        <li class="m-tb-20">
                            <div class="font-weight-bold" test_id="secondStepHeader">{{endOfContractWidgetItem.endOfContractPartExchangeConfirmation.secondStepHeader}}</div>
                            <div test_id="secondStepContentBeforeData">
                                {{endOfContractWidgetItem.endOfContractPartExchangeConfirmation.secondStepContentBeforeData}}&nbsp;<span>£{{settlementData?.settlement_figure}}</span>
                                {{endOfContractWidgetItem.endOfContractPartExchangeConfirmation.secondStepContentBetweenData}}&nbsp;<span>{{settlementData?.settlement_expiry_date}}</span>
                            </div>
                        </li>
                        <li class="m-tb-20">
                            <div class="font-weight-bold" test_id="thirdStepHeader">{{endOfContractWidgetItem.endOfContractPartExchangeConfirmation.thirdStepHeader}}</div>
                            <div test_id="thirdStepContent">
                                <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchangeConfirmation.thirdStepContent"></bb-content-html>
                            </div>
                        </li>
                    </ol>
                </div>   
            </div>
        </div>
        <div class="m-tb-20 p-sm-1">
            <h2 test_id="thingsNeedToKnowHeader" class="font-size-20">{{endOfContractWidgetItem.endOfContractPartExchangeConfirmation.thingsNeedToKnowHeader}}</h2>
            <div class="m-tb-20 " test_id="thingsNeedToKnowContent">
               <ul>
                   <li test_id="thingsNeedToKnowFirstData" class="mb-20px">
                       <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchangeConfirmation.thingsNeedToKnowFirstData"></bb-content-html>
                    </li>
                   <li test_id="thingsNeedToKnowSecondData" class="mb-20px">
                        <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchangeConfirmation.thingsNeedToKnowSecondData"></bb-content-html>
                   </li>
                   <li test_id="thingsNeedToKnowThirdData" class="mb-20px">
                        <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchangeConfirmation.thingsNeedToKnowThirdData"></bb-content-html>
                   </li>
                   <li test_id="thingsNeedToKnowFourthPointBeforeData" class="mb-20px para-inline">
                        <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchangeConfirmation.thingsNeedToKnowFourthPointBeforeData"></bb-content-html>
                        <span>{{settlementData?.settlement_expiry_date}},&nbsp;</span>
                        <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchangeConfirmation.thingsNeedToKnowFourthPointAfterData"></bb-content-html>
                   </li>
               </ul>
            </div>
            <div class="m-tb-20" test_id="ifYouChangeYourMindContent">
                <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchangeConfirmation.ifYouChangeYourMindContent"></bb-content-html>
            </div>
            <div class="d-flex">
                <button class="wss-button-secondary" (click)="backToHomepage()">
                    {{endOfContractWidgetItem.endOfContractPartExchangeConfirmation.backToHomepage}}
                </button>
            </div>
        </div>
    </div>
    <!-- <div class="m-tb-20">
        <div class="container-section">
            <div class="m-tb-20" test_id="ifYouChangeYourMindContent">
                <bb-content-html [html]="endOfContractWidgetItem.endOfContractPartExchangeConfirmation.ifYouChangeYourMindContent"></bb-content-html>
            </div>
            <button class="wss-button-secondary" (click)="backToHomepage()">{{endOfContractWidgetItem.endOfContractPartExchangeConfirmation.backToHomepage}}</button>
        </div>
    </div>   -->
</div>