<div class="container-column" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
    <div class="container-column settlements" *ngIf="allowSettlements">
        <div class="payment-row section-title-container title-class">
            <span class="section-title" test_id="make_payment_label_id">
                {{paymentsWidgetItem.paymentSettlement.settleAgreementPageName}}
            </span>
        </div>
        <div class="reg-row plr-10pt" role="alert" *ngIf="serviceError">
            <bb-wss-banner-message></bb-wss-banner-message>
        </div>
        <div class="payment-column settlement-parent">
            <div class="container-column payment-result-content payment-settlement-content-height"
                aria-describedby="settlementAmount">
                <div class="container-column payment-result-section payment-settlement-bg">
                    <div class="trophy-icon-div">
                        <span class="wss-trophy-icon trophy-icon" title="icon-trophy"></span>
                    </div>
                    <div class="container-row payment-result-bottom-info-text" test_id="paybleAmountInfoText">
                        <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.paybleAmountInfoText">
                        </bb-content-html>
                    </div>
                    <div class="settlement-amount">
                        £ {{settlementData?.settlement_figure}}
                    </div>
                    <div class="payment-row button-content content-center send-by-email">
                        <a class="faq-link" (click)="openModal(content)" id="sendSettlementEmailLink">
                            <span class="span-icon mail-icon">
                                <span class="wss-mail-help-icon"></span>
                            </span>
                            <span class="send-by-email-text">{{paymentsWidgetItem.paymentSettlement.sendSettlementEmailLink}}</span>
                        </a>
                        <div class="container-column send-settlement-email-again"
                            *ngIf="showSendEmailAgainMessage">
                            <div class="send-email-again">
                                <div class="container-row modal-header-c" test_id="settlementEmailAgainTitle">
                                    <span class="material-icons" (click)="closeSendEmailAgainPopup()">clear</span>
                                </div>
                                <div class="container-column modal-body-c" test_id="messageToSendEmailAgain" id="settlementEmailInfo">
                                    <bb-content-html [html]="paymentSettlementWidgetItem.messageToSendEmailAgain"></bb-content-html>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <span *ngIf="!amountExceedflag">
                        <div class="container-row content-center margin-top-10 no-margin-bottom">
                            <button class="wss-button back-to-account-btn" test_id="back_account_id"
                                id="settleAgreementButton" [disabled]="settleAgreementButtonDisable"
                                title="{{paymentsWidgetItem.paymentSettlement.settleAgreementButtonText}}"
                                (click)="settleAgreementClick()" test_id="settleAgreementButtonText">
                                <bb-content-html
                                    [html]="paymentsWidgetItem.paymentSettlement.settleAgreementButtonText">
                                </bb-content-html>
                            </button>
                        </div>
                    </span>
                </div>
                <span>
                    <div class="payment-settlement-bottom-info-text margin-top-30">
                        <!-- Commented as both content is similar it seems -->
                        <span class="para-inline" test_id="settleIncludeFeeInfoText">
                            <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.settleIncludeFeeInfoText">
                            </bb-content-html>
                        </span>
                        <span *ngIf="accountSummary.scheme_code == '54'" class="para-inline"
                            test_id="settleIncludeFeePCPInfoText">
                            <bb-content-html
                                [html]="paymentsWidgetItem.paymentSettlement.settleIncludeFeePCPInfoText">
                            </bb-content-html>
                        </span>
                    </div>
                </span>
                <div class="payment-settlement-bottom-info-text margin-top-10" *ngIf="amountExceedflag"
                    test_id="settlementAmountInfoText">
                    <div class="settlement-call-us" test_id="callUsLabelText">
                        <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.callUsLabelText">
                        </bb-content-html>
                    </div>
                    <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.settlementAmountInfoText">
                    </bb-content-html>
                </div>
            </div>
            
            <!-- Payment Settlement -->
            <div class="parent-settlement-container" aria-describedby="settlementInfoDetails">
                <div class="title-class right-column">
                    <span class="section-label-title" test_id="make_payment_label_id">
                        <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.settleAgreementPageName">
                        </bb-content-html>
                    </span>
                </div>
                <div class="payment-row impr-lable-section margin-top-20 no-para-margin">
                    <span class="settle-section-label-title" test_id="make_payment_label_id">
                        <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.importantSettlementInfoLabelText">
                        </bb-content-html>
                    </span>
                </div>
                <div class="payment-row settlement-expiry-date">
                    <span class="settle-expiry-date date-expiry" test_id="settlementAmountExpireInfoText">
                        {{paymentsWidgetItem.paymentSettlement.settlementAmountExpireInfoText}}
                        <div class="expiry-date">
                            <span title="Expiry date" class="event-icon"></span>
                            {{settlementData?.settlement_expiry_date}}.
                        </div>
                    </span>
                </div>
                <div class="payment-row margin-top-20">
                    <span class="settle-expiry-date" test_id="settlementAmountDueDateInfoText">
                        <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.settlementAmountDueDateInfoText">
                        </bb-content-html>
                    </span>
                </div>
                <div class="payment-row margin-top-20">
                    <span class="settle-expiry-date">
                        <span class="new-settlement-amount" test_id="newSettlementAmountInfoText">
                            <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.newSettlementAmountInfoText">
                            </bb-content-html>
                        </span>
                    </span>
                </div>
                <div class="container-row content-center">
                    <button id="newSettlementbutton" class="wss-button-secondary back-to-account-btn"
                        (click)="getNewSettlementAmount(true)"
                        title="{{paymentsWidgetItem.paymentSettlement.newSettlementbuttonText}}"
                        [disabled]="getNewSettlementCalledAlready" test_id="other_payment_way_id"><b>
                            {{paymentsWidgetItem.paymentSettlement.newSettlementbuttonText}}
                        </b></button>
                </div>
            </div>
            <div class="settlement-next-payment-container">
                <div class="your-next-payment-wrapper">
                    <div class="payment-row margin-bottom">
                        <span class="settle-subSection-label-title" test_id="make_payment_label_id">
                            <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.nextPaymentLabelText">
                            </bb-content-html>
                        </span>
                    </div>
                    <div class="container-row border-bottom bg-white content-center additional-info-item-height">
                        <strong class="flex-width additional-info-item-pull-right" test_id="settlementAmountLabelText">
                            <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.settlementAmountLabelText">
                            </bb-content-html>
                        </strong>
                        <span class="flex-width">{{accountSummary?.next_payment_amount}}</span>
                    </div>
                    <div class="container-row border-bottom content-center additional-info-item-height">
                        <strong class="flex-width additional-info-item-pull-right" test_id="settlementDateDueLabelText">
                            <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.settlementDateDueLabelText">
                            </bb-content-html>
                        </strong>
                        <span class="flex-width">{{accountSummary?.next_payment_date}}</span>
                    </div>
                </div>
                <div class="help-and-support">
                    <div class="payment-row help-info">
                        <span class="settle-expiry-date help-support" test_id="settlementfurtherHelpInfoText">
                            <bb-content-html
                                [html]="paymentsWidgetItem.paymentSettlement.settlementfurtherHelpInfoText">
                            </bb-content-html>
                        </span>
                    </div>
                    <div class="container-row content-center margin-bottom-other margin-top-20">
                        <span class="other-way-pay-link other-settlement-link" tabindex="0" title="Other ways to pay"
                            test_id="otherWaytoPayLinkText">
                            <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.otherWaytoPayLinkText">
                            </bb-content-html>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- Settlement Warning -->
    <div class="container-column payment-warning-content" *ngIf="settlementQuoteWarningFlag">
        <span class="wss-failure-icon failure-icon"></span>
        <div class="payment-row margin-top-10">
            <strong class="payment-warning-info" test_id="settlementWarningLabelText">
                <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.settlementWarningLabelText">
                </bb-content-html>
            </strong>
        </div>
        <div class="payment-row">
            <span class="para-inline" test_id="settlementWarningInfoText">
                <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.settlementWarningInfoText">
                </bb-content-html>
            </span>
        </div>

        <div class="payment-row content-center">
            <button class="wss-button btn-width warn-btn-dif-view button-width-padding" test_id="make_payment_id"
                title="{{paymentsWidgetItem.paymentSettlement.backToAccountButtonText}}" id="backToAccountButton"
                (click)="backToMyAccountClick()">
                {{paymentsWidgetItem.paymentSettlement.backToAccountButtonText}}
            </button>
        </div>
    </div>

    <div class="container-column payment-warning-content two-settlements-warning"
        *ngIf="twoSettlementsMadeWithin48hours">
        <div class="payment-warning-icon-div">
            <span class="wss-warning-icon warning-icon" alt="Warning Icon"></span>
        </div>
        <div>
            <strong class="payment-warning-info margin-top-20"
                test_id="twoSettlementsMadeWithin48hoursWarningLabelText">
                <bb-content-html
                    [html]="paymentsWidgetItem.paymentSettlement.twoSettlementsMadeWithin48hoursWarningLabelText">
                </bb-content-html>
            </strong>
        </div>
        <div class="align-center">
            <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                test_id="twoSettlementsMadeWithin48hoursWarningInfoText">
                <bb-content-html
                    [html]="paymentsWidgetItem.paymentSettlement.twoSettlementsMadeWithin48hoursWarningInfoText">
                </bb-content-html>
            </span>
        </div>
        <div class="container-row content-center">
            <button id="backToPaymentsButton" role="button"
                class="wss-button btn-width warn-btn-dif-view button-width-padding" test_id="backToPaymentsButtonText"
                (click)="backToMyAccountClick()"
                title="{{paymentsWidgetItem.paymentSettlement.backToAccountButtonText}}">
                {{paymentsWidgetItem.paymentSettlement.backToAccountButtonText}}
            </button>
        </div>
    </div>

    <div class="container-column payment-warning-content two-settlements-warning"
        *ngIf="withdrawalPeriodWithIn20Days">
        <div class="payment-warning-icon-div">
            <span class="wss-warning-icon warning-icon" alt="Warning Icon"></span>
        </div>
        <div class="align-center">
            <bb-content-html
                    [html]="paymentSettlementWidgetItem?.withdrawalPeriodWithIn20Days">
                </bb-content-html>
        </div>
        <div class="container-row content-center">
            <button id="backToPaymentsButton" role="button"
                class="wss-button btn-width warn-btn-dif-view button-width-padding" test_id="backToPaymentsButtonText"
                (click)="backToMyAccountClick()"
                title="{{paymentsWidgetItem.paymentSettlement.backToAccountButtonText}}">
                {{paymentsWidgetItem.paymentSettlement.backToAccountButtonText}}
            </button>
        </div>
    </div>

    <div class="container payment-warning-content warning-container" *ngIf="settlementNotAllowed"
        aria-describedby="warningPaymentInfo">
        <div class="payment-warning-icon-div">
            <span class="wss-warning-icon warning-icon" alt="Warning Icon"></span>
        </div>
        <div *ngIf="paymentsWidgetItem.paymentSettlement.settlementUntimeWarningLabelText">
            <strong class="payment-warning-info margin-top-20" test_id="settlementUntimeWarningLabelText">
                <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.settlementUntimeWarningLabelText">
                </bb-content-html>
            </strong>
        </div>
        <div *ngIf="paymentsWidgetItem.paymentSettlement.settlementUntimeWarningInfoText">
            <span class="payment-warning-info settlement-untime-warning" test_id="settlementUntimeWarningInfoText">
                <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.settlementUntimeWarningInfoText">
                </bb-content-html>
            </span>
        </div>
        <div class="align-center">
            <span class="settlement-warning-info settlement-info-text account-ineligible-info"
                test_id="settlementHelpAndSupportText">
                <!-- Add in this slot only all -->
                <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.settlementHelpAndSupportText">
                </bb-content-html>
            </span>
        </div>
        <div class="container-row content-center">
            <button role="button" class="wss-button btn-width warn-btn-dif-view button-width-padding"
                test_id="make_payment_id" (click)="backToHomeClick()"
                title="{{paymentsWidgetItem.paymentSettlement.settlementBackToHomeButtonText}}">
                {{paymentsWidgetItem.paymentSettlement.settlementBackToHomeButtonText}}
            </button>
        </div>
    </div>

    <ng-template #content let-modal>
        <div class="container-column request-statement-modal send-settlement-email">
            <div class="send-email-confirmation" *ngIf="showEmailPopup">
                <div class="container-row modal-header-c" test_id="settlementEmailTitle">
                    <span class="popup-header">{{paymentsWidgetItem.paymentSettlement.settlementEmailTitle}}</span>
                    <span class="material-icons" (click)="closeWithoutNavigation();modal.dismiss('Cross click');">clear</span>
                </div>
                <div class="container-column modal-body-c" test_id="settlementEmailInfo" id="settlementEmailInfo">
                    <bb-content-html [html]="paymentSettlementWidgetItem.settlementEmailInfo"></bb-content-html>
                </div>
                <!-- 3rd party email changes start -->
                <div class="container-column mt-14px">
                    <form [formGroup]="emailForm" class="form" role="form" autocomplete="off">
                        <bb-input-radio-group-ui [preselect]="false" [required]="true"
                            formControlName="emailToBeSent" test_id="radioLabels">
                            <bb-input-radio-ui value="Yes" label="{{paymentsWidgetItem.paymentSettlement.radioLabelYes}}" aria-labelledby="{{paymentsWidgetItem.paymentSettlement.radioLabelYes}}" accesskey="y"
                                tabindex="0"></bb-input-radio-ui>
                            <bb-input-radio-ui value="No" label="{{paymentsWidgetItem.paymentSettlement.radioLabelNo}}" aria-labelledby="{{paymentsWidgetItem.paymentSettlement.radioLabelNo}}" accesskey="n"
                                tabindex="1"></bb-input-radio-ui>
                        </bb-input-radio-group-ui>
                        <div class="container-column mt-14px email-field"
                            *ngIf="emailForm?.value?.emailToBeSent === 'Yes'">
                            <div class="form-group finalize-email-form" test_id="emailAddressLabel">
                                <label>{{paymentsWidgetItem.paymentSettlement.emailAddressLabel}}</label>
                                <input type="email" (input)="checkConfirmBoxDisability()" class="form-control  rounded-2" formControlName="email"
                                    placeholder="{{paymentsWidgetItem.paymentSettlement.emailAddressPlaceholder}}" accesskey="e" [ngClass]="{ 'is-invalid': (emailForm.value.emailToBeSent === 'Yes') && f.email.errors && f.email.touched }">
                                    <span *ngIf="f.email.valid && f.email.touched" class="material-icons">done</span>
                                    <div *ngIf="(emailForm.value.emailToBeSent === 'Yes') && f.email.errors && f.email.touched" class="invalid-feedback para-inline">
                                        <div *ngIf="f.email.errors.required">
                                            <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.emailRequiredErrorMsg"></bb-content-html>
                                        </div>
                                        <div *ngIf="f.email.errors.pattern">
                                            <bb-content-html [html]="paymentSettlementWidgetItem.emailInvalidErrorMsg"></bb-content-html>
                                        </div>
                                    </div>
                            </div>
                            <div class="form-group settlement-fg" test_id="cEmailAddressLabel">
                                <label>{{paymentsWidgetItem.paymentSettlement.cEmailAddressLabel}}</label>
                                <input blockPaste type="email" class="form-control  rounded-2"
                                    formControlName="comfirmEmail" placeholder="{{paymentsWidgetItem.paymentSettlement.cEmailAddressPlaceholder}}"
                                    accesskey="d" [ngClass]="{ 'is-invalid': (emailForm.value.emailToBeSent === 'Yes') && f.comfirmEmail.errors && f.comfirmEmail.touched }">
                                    <span *ngIf="f.comfirmEmail.valid && f.comfirmEmail.touched"  class="material-icons">done</span>
                                    <div *ngIf="(emailForm.value.emailToBeSent === 'Yes') && f.comfirmEmail.errors && f.comfirmEmail.touched" class="invalid-feedback para-inline">
                                        <div *ngIf="f.comfirmEmail.errors.required">
                                            <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.cEmailRequiredErrorMsg"></bb-content-html>
                                        </div>
                                        <div *ngIf="f.comfirmEmail.errors.mustMatch">
                                            <bb-content-html [html]="paymentsWidgetItem.paymentSettlement.cEmailMustMatchErrorMsg"></bb-content-html>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- 3rd party email changes end -->
                <div class="container-column modal-footer-c  space-between" test_id="sendSettlementEmailConfirmationButton">
                    <button [disabled]="(emailForm.value.emailToBeSent === '') || ((emailForm.value.emailToBeSent === 'Yes' && !emailForm.valid))" class="wss-button" (click)="sendSettlementEmail()">
                        {{paymentsWidgetItem.paymentSettlement.sendSettlementEmailConfirmationButton}}
                    </button>
                    <button class="wss-button-secondary btn-dif-view take-me-back" id="settlementGoBackButton"
                        (click)="closeWithoutNavigation();modal.dismiss('Cross click');">
                        {{paymentsWidgetItem.paymentSettlement.settlementGoBackButtonText}}
                    </button>
                </div>
            </div>
            <div class="email-sent-confirmation" *ngIf="showConfirmation">
                <div class="container-row modal-header-c" test_id="crossClick">
                    <span class="wss-success-icon success-image" alt="Success Icon"></span>
                    <span class="material-icons" (click)="closeWithoutNavigation();modal.dismiss('Cross click');">clear</span>
                </div>
                <div class="container-column modal-body-c" test_id="postSettlementEmailMessage" id="postSettlementEmailMessage">
                    <bb-content-html *ngIf="!is3rdPartyEmailSent" [html]="paymentSettlementWidgetItem.postSettlementEmailMessage"></bb-content-html>
                    <bb-content-html *ngIf="is3rdPartyEmailSent" [html]="paymentSettlementWidgetItem.postSettlement3rdPartyEmailMessage"></bb-content-html>
                </div>
                <div class="container-row modal-footer-c close-window" test_id="closeThisWindowButton">
                    <button class="wss-button" (click)="closeEmailPopup();modal.dismiss('Cross click');">
                        {{paymentsWidgetItem.paymentSettlement.closeThisWindowButton}}
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</div>
<!-- Settlement Warning -->