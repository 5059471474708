import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { RouterService } from '@backbase/foundation-ang/core';
import { appConfig, RuntimeEnv } from '@wss/config/wss-app-constants';
import { TermsConditionsItem } from '@wss/model/content/static-pages';

@Component({
  selector: 'bb-terms-conditions-widget',
  templateUrl: './terms-conditions-widget.component.html',
  providers: [ ContentService ]
})
export class TermsConditionsWidgetComponent implements OnInit, OnDestroy {
  termsConditionsWidgetItem$: Observable<TermsConditionsItem|undefined> = this.bbContentService.getContent<TermsConditionsItem>('termsConditionsWidgetItem');
  termsConditionsWidgetItem: TermsConditionsItem = new TermsConditionsItem();
  portalName: any;

  constructor(
    private readonly bbContentService: ContentService,
    // private router: RouterService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.renderer.addClass(document.body, 'no-nav-bar');
    this.renderer.addClass(document.body, 'static-page');
  }
  
  ngOnInit(): void {
    this.getPortalName();
    this.termsConditionsWidgetItem$.subscribe((TermsConditionsWidgetItem: TermsConditionsItem| undefined)=> {
      if(TermsConditionsWidgetItem){
        this.termsConditionsWidgetItem = TermsConditionsWidgetItem;
      }
    })
  }

  getPortalName() {
    if (RuntimeEnv.backbase) {
      this.portalName = window.BB.config.portalName;
      // "blackhorse"
    }
    this.changeDetectorRef.detectChanges();
  }

  // navigate(url: string) {
  //   this.router.navigate([url]);
  // }

  navigateToLogin() {
    location.href = appConfig.loginURL;
  }

  navigateToRegistration() {
    location.href = appConfig.regURL;
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'no-nav-bar');
    this.renderer.removeClass(document.body, 'static-page');
  }
}
