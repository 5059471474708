import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { DatastoreService } from '../../service/datastore.service';
import { RoutableWidget } from "@backbase/foundation-ang/core";
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { BalloonRefinanceGetdetailsAboutComponent } from './components/balloon-refinance-getdetails-about/balloon-refinance-getdetails-about.component';
import { BalloonRefinanceGetdetailsBankComponent } from './components/balloon-refinance-getdetails-bank/balloon-refinance-getdetails-bank.component';
import { BalloonRefinanceGetdetailsEmploymentComponent } from './components/balloon-refinance-getdetails-employment/balloon-refinance-getdetails-employment.component';
import { BalloonRefinanceGetdetailsFinanceComponent } from './components/balloon-refinance-getdetails-finance/balloon-refinance-getdetails-finance.component'
import { BalloonRefinanceGetdetailsVehicleComponent } from './components/balloon-refinance-getdetails-vehicle/balloon-refinance-getdetails-vehicle.component';
import { BalloonRefinanceGetdetailsItem } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { getDeviceType } from 'libs/common/util/util';
import { BalloonRefinanceGetdetailsSummaryComponent } from './components/balloon-refinance-getdetails-summary/balloon-refinance-getdetails-summary.component';
import { BrgConnectionFailureComponent } from './components/brg-connection-failure/brg-connection-failure.component';
import { BrgReferralComponent } from './components/brg-referral/brg-referral.component';
import { BrgConnectionFailureRedirectComponent } from './components/brg-connection-failure-redirect/brg-connection-failure-redirect.component';
import { BrgAcceptComponent } from './components/brg-accept/brg-accept.component';
import { BrgDeclineComponent } from './components/brg-decline/brg-decline.component';
import { BrgPostDeclineComponent } from './components/brg-post-decline/brg-post-decline.component';
import { BrgModAgNextStepComponent } from './components/brg-mod-ag-next-step/brg-mod-ag-next-step.component';

@Component({
  selector: 'bb-balloon-refinance-getdetails-widget',
  templateUrl: 'balloon-refinance-getdetails-widget.component.html',
  providers: [ContentService]
})

@RoutableWidget({
  routes: [
    { path: '', redirectTo: 'vehicle', pathMatch: 'full' },
    { path: 'vehicle', component: BalloonRefinanceGetdetailsVehicleComponent},
    { path: 'about-you', component: BalloonRefinanceGetdetailsAboutComponent},
    { path: 'employment', component: BalloonRefinanceGetdetailsEmploymentComponent},
    { path: 'finances', component: BalloonRefinanceGetdetailsFinanceComponent},
    { path: 'bank-account', component: BalloonRefinanceGetdetailsBankComponent},
    { path: 'summary', component: BalloonRefinanceGetdetailsSummaryComponent},
    { path: 'connection-failure', component: BrgConnectionFailureComponent },
    { path: 'connection-failure-redirect', component: BrgConnectionFailureRedirectComponent },
    { path: 'referral', component: BrgReferralComponent },
    { path: 'accept', component: BrgAcceptComponent },
    { path: 'decline', component: BrgDeclineComponent },
    { path: 'post-decline', component: BrgPostDeclineComponent },
    { path: 'mod-ag-next-step', component: BrgModAgNextStepComponent}
  ]
})
export class BalloonRefinanceGetdetailsWidgetComponent implements OnInit, OnDestroy {

  balloonRefinanceGetdetailsWidgetItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');

  currentStep: number = 1;
  deviceType: string = 'Mobile';
  isReturnJourney: boolean = false;
  hideStepper: boolean = false;
  isDeclinePageActive: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.deviceType = getDeviceType();
  }

  constructor(
    private dataStoreService: DatastoreService,
    private bbContentService: ContentService
  ) { }

  ngOnInit(): void {
      this.dataStoreService.sessionInvalid();
      this.deviceType = getDeviceType();
      this.dataStoreService.brCurrentPageData$.subscribe((res: any) => {
        this.currentStep = res;
      });
      this.checkReturnJourney();
      this.checkStepperVisibility()
  }

  checkStepperVisibility() {
    this.dataStoreService.brHideStepper$.subscribe((res: boolean) => {
      this.hideStepper = res
    })
  }

  checkReturnJourney() {
    this.dataStoreService.brReturnJourney$.subscribe((res: boolean) => {
      this.isReturnJourney = res;
    })
  }

  ngOnDestroy(): void {
    this.dataStoreService.setBrReturnJourney(false)
  }
}
