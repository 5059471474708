<div class="reg-container" *ngIf="registrationWidgetItem$ | async as registrationWidgetItem">
  <div class="reg-row padding-left-right" test_id="pageHeaderText">
    <bb-content-html [html]="registrationWidgetItem.userCredentials.pageHeaderText"></bb-content-html>
  </div>
  <form [formGroup]="emailForm">
    <div class="reg-row padding-left-right enter-email inner-icon right-icon">
      <span *ngIf="validEmail" class="material-icons icon-text icon-success">done</span>
      <bb-input-text-ui
        label="{{registrationWidgetItem.userSearch.email}}"
        placeholder="{{registrationWidgetItem.userSearch.enter}}"
        [required]="true"
        [labelClasses]="'email-address'"
        formControlName="email"
        [inputClassName]="'email-address-size'"
        [autoComplete] = "'off'"
      >
      </bb-input-text-ui>
      
      <div role="alert" class="invalid-feedback" [ngClass]="{'show-error': !validEmail && showEmailErrors}" test_id="invalidEmailError">
        <bb-content-html [html]="registrationWidgetItem.userCredentials.invalidEmailError"></bb-content-html>
      </div>
    </div>
    <div class="reg-row padding-left-right confirm-email inner-icon right-icon">
      <span *ngIf="validConfirmEmail" class="material-icons icon-text icon-success">done</span>
      <bb-input-text-ui
        label="{{registrationWidgetItem.userSearch.confirmEmailAddress}}"
        placeholder="{{registrationWidgetItem.userSearch.confirmPasswordHere}}"
        [required]="true"
        [labelClasses]="'email-address'"
        formControlName="confirmEmail"
        [inputClassName]="'email-address-size'"
        [autoComplete] = "'off'"
        onPaste="return false"
      >
      </bb-input-text-ui>
      
      <div role="alert" class="invalid-feedback" [ngClass]="{'show-error': !validConfirmEmail && showConfirmEmailErrors}" test_id="invalidEmailError">
        <bb-content-html [html]="registrationWidgetItem.userCredentials.invalidEmailError"></bb-content-html>
      </div>
      <div role="alert" *ngIf="!emailMatch && validConfirmEmail">
        <div class="invalid-feedback" [ngClass]="{'show-error': !emailMatch && showConfirmEmailErrors}" test_id="emailMismatchError">
          <bb-content-html [html]="registrationWidgetItem.userCredentials.emailMismatchError"></bb-content-html>
        </div>      
      </div>      
    </div>    
    <div class="reg-row padding-left-right flex-horizontal-center">
      <button bbButton type="submit" aria-label="Favourite"
              class="wss-button" id="enter-email-btn" 
              (click)="submit()" [disabled]="disabled" test_id="nextButtonText">
        {{registrationWidgetItem.userCredentials.nextButtonText}}
      </button>
    </div>
  </form>
  <div class="need-help-link text-center mb-25px" test_id="needHelpLink">
    <bb-content-html [html]="registrationWidgetItem.userCredentials.needHelpLink"></bb-content-html>
  </div>
</div>
