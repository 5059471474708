import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from "../../services/payment.service";
import { MessageService } from '../../../../service/message.service';
import { DatastoreService } from '../../../../service/datastore.service';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { isErrServer } from '../../../../common/util/util';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { UtilService } from '@wss/budget-cover-widgets/shared/service/util.service';
import { TealiumUtagService } from '@wss/service/utag.service';
@Component({
  selector: 'payment-debit-card',
  templateUrl: './payment-debit-card.component.html',
  styles: [
  ],
  providers: [DatePipe, ContentService]
})

export class PaymentDebitCardComponent implements OnInit {
  processModalContent: Array<any> = [];
  saveCardModalConetent: Array<any> = [];
  paymentForm: FormGroup = new FormGroup({});
  showMoreCardDetials: boolean = false;
  buttonDisabled: boolean = true;
  newPayLinkDisabled: boolean = true;
  showNewPaymentSection: boolean = true;
  makePaymentButtonDisable: boolean = true;
  showProcess: boolean = false;
  amountEnter: any;
  closeResult: string = '';
  modalRef: any = {};
  validAmountFlag: boolean = false;
  showErrorFlag: boolean = false;
  settlementDirectionFlag: boolean = false;
  overpaymentRedirectionFlag: boolean = false;
  paymentData: any = {};
  settlementRouteFlag: boolean = true;
  saveCardModalOpenFlag: boolean = false;
  poundCssFlag: boolean = false;
  cardDetail: any;
  selectedPaymentCardDetail: any;
  paymentCardRestDetail: any;
  postRequestData: any;
  myDate = new Date();
  customerDetail: any = this.datastoreService.getCustomerDetail();
  showRestCardDetials: boolean = false;
  noAmountFlag: boolean = false;
  accountDetail: any = this.datastoreService.getAccountDetails();
  serviceError: boolean = false;
  processingContentValue: any;
  saveCardFlagValue: any;
  arrearsBannerDirectionFlag: boolean = false;
  arrearsAmount: any = this.datastoreService.hasArrearAmount()
  ? this.utilService.formateAmount(this.datastoreService.getArrearAmount())
  : '';
  validArrearsAmountFlag: boolean = false;

  readonly destroy$ = new Subject();

  paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
  settlementDirectionFlagHP: boolean = false;

  constructor(
    private router: RouterService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public changeDetectorRef: ChangeDetectorRef,
    public paymentService: PaymentService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private datastoreService: DatastoreService,
    private utilService: UtilService,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService
  ) {
  }
  ngOnInit() {
    this.handleErrors();
    this.paymentForm = this.formBuilder.group({
      payableAmount: ['', Validators.compose([
        Validators.pattern('^[0-9 .£]*$')
      ])],
      cvvInput: ['', []]
    });
    this.paymentForm.get('cvvInput')?.disable();
    this.onSettlementPageRedirection();
    this.tealiumService.view(
      {
        JourneyName: 'Payment',
        JourneyStepName: 'Debit Card',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
    this.messageService.setCurrentStep(1);
    this.changeDetectorRef.detectChanges();
    this.paymentForm.controls['payableAmount'].valueChanges.subscribe(value => {
      this.onAmountEnter(value);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();    
  }

  onModalOpen(processingContent: any) {
    this.modalRef = this.modalService.open(processingContent, { windowClass: 'save-card-modal' });
  }

  onModalClose() {
    this.modalRef.close();
  }
  
  onSettlementModalOpen(settlementContent: any) {
    this.modalRef = this.modalService.open(settlementContent, { windowClass: 'save-card-modal' });
  }

  onSettlementConfirmation() {
    this.onSettlementCancellation();
    this.messageService.setActiveNav("Settlement");
    this.router.navigate(['payment-settlement']);
  }

  onSettlementCancellation() {
    this.onModalClose();
  }

  onTakeMyPayment(saveCardContent: any) {
    this.modalRef = this.modalService.open(saveCardContent, { windowClass: 'save-card-modal' });
    this.saveCardModalOpenFlag = true;
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCardExpansion(status: any) {
    if (status === true) {
      this.showMoreCardDetials = true;
      this.paymentCardRestDetail = this.datastoreService.getPaymnetCardRestDetail();
    } else if (status === false) {
      this.showMoreCardDetials = false;
    }
  }

  onAmountEnter(amountValue: any) {
    this.validAmountFlag = false;
    this.noAmountFlag = false;
    this.validArrearsAmountFlag = false;
    if (amountValue.length >= 0 && !this.validAmountFlag) {
      this.newPayLinkDisabled = false;
      this.makePaymentButtonDisable = false;
      this.buttonDisabled = true;
      this.paymentForm.get('cvvInput')?.enable();
    }
    if (amountValue > 30000) {
      this.validAmountFlag = true;
      this.newPayLinkDisabled = true;
      this.makePaymentButtonDisable = true;
      this.paymentForm.get('cvvInput')?.disable();
    }
    if (this.arrearsBannerDirectionFlag && parseFloat(amountValue) > parseFloat(this.arrearsAmount)) {
      this.validArrearsAmountFlag = true;
    }
    let amountFloatValue = parseFloat(amountValue);
    if (amountFloatValue === .0 || amountValue === "." || this.paymentForm.get('payableAmount')?.invalid) {
      this.noAmountFlag = true;
      this.newPayLinkDisabled = true;
      this.makePaymentButtonDisable = true;
      this.paymentForm.get('cvvInput')?.disable();
    }
    if (!amountValue) {
      this.makePaymentButtonDisable = true;
    }
  }
  onCVVEnter(cvvNumber: any) {
    this.buttonDisabled = true;
    if ((!this.validAmountFlag && cvvNumber.length >= 3) && !this.paymentForm.get('cvvInput')?.invalid) {
      this.buttonDisabled = false;
    }
  }
  onSettlementPageRedirection() {
    this.settlementDirectionFlag = false;
    this.overpaymentRedirectionFlag = false;
    this.settlementDirectionFlagHP = false;
    this.arrearsBannerDirectionFlag = false;
    let settlementValue = localStorage.getItem('redirectionFlag');
    let paymentValue = localStorage.getItem('redirectionFlag');
    let arrearsBannerValue = localStorage.getItem('redirectionFlag');
    localStorage.setItem('settlementRedirectionFlag', '');

    var settlementAmountValue;
    if (settlementValue === 'settlement') {
      localStorage.setItem('settlementRedirectionFlag', 'settlementFlag');
      this.settlementDirectionFlag = true;
      settlementAmountValue = localStorage.getItem('settlementAmount');
      if ((this.accountDetail.scheme_code == '1' || this.accountDetail.scheme_code == '01')) {
        this.settlementDirectionFlagHP = true;
      }
    } else if (paymentValue === 'paymentMenu' || arrearsBannerValue === 'arrearsBanner' || paymentValue === 'payByDebitCard') {
      this.settlementDirectionFlag = false;
      settlementAmountValue = '';
      if(arrearsBannerValue === 'arrearsBanner' ||  paymentValue === 'payByDebitCard') {
        this.arrearsBannerDirectionFlag = true;
        this.paymentForm.get('payableAmount')?.patchValue(this.arrearsAmount);
        this.onAmountEnter(this.arrearsAmount);
      }
    } else if (settlementValue === 'overpayment') {
      this.settlementDirectionFlag = false;
      this.overpaymentRedirectionFlag = true;
      settlementAmountValue = localStorage.getItem('settlementAmount');
    }
    if (settlementAmountValue) {
      this.paymentForm.get('payableAmount')?.patchValue(settlementAmountValue);
      this.paymentForm.get('payableAmount')?.disable();
      this.settlementRouteFlag = false;
      this.onAmountEnter(settlementAmountValue);
    }
  }
  getCardsDetails() {
    this.paymentService.getCardsDetail().pipe(takeUntil(this.destroy$)).subscribe(() => {
      let data = this.datastoreService.getPaymentDetails();
      if (data.length > 0) {
        this.showRestCardDetials = true;
        this.showNewPaymentSection = false;
        this.selectedPaymentCardDetail = this.datastoreService.getPaymnetCardFirstDetail();
        if (data.length === 1) {
          this.showRestCardDetials = false;
        }
        this.changeDetectorRef.markForCheck();
      } else {
        this.showNewPaymentSection = true;
      }
    },
      (error: any) => {
        this.showNewPaymentSection = true;
      });
  }

  onCardSelect(selectedCard: any) {
    this.selectedPaymentCardDetail = selectedCard;
    this.showMoreCardDetials = false;
  }

  handleErrors() {
    this.messageService.getServiceError().subscribe((error: any) => {
      if (error && error.reasonCode && (error.reasonCode === 'CUSTOMER_NOT_FOUND' || error.reasonCode === 'TXN_FAILED' || error.reasonCode === 'VERIFONE_ERROR')) {
        this.onModalClose();
        this.router.navigate(['payment-result-process']);
        this.changeDetectorRef.detectChanges();
      }
      else if (error && error.reasonCode && (error.reasonCode === 'NO_CARD_STORE')) {
        this.showNewPaymentSection = true;
      }
      this.showError(error.status);
    });
  }

  showError(errStatus: number): void {
    if (isErrServer(errStatus)) {
      this.datastoreService.setErrorServer();
      this.serviceError = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  onChooseNewAmountLink() {
    this.router.navigate(['over-payment']);
  }

  otherWayPayClick() {
    location.href = "#/help-centre/make-payment";
  }

  next(settlementContent: any) {
    let payAmount = this.paymentForm.get('payableAmount')?.value;
    let filterdPayableAmount = payAmount.replaceAll('£', '').replaceAll(' ', '');
    let outStandingBalance = this.accountDetail ? this.accountDetail.current_balance : "";
    let validStandingBal = Number(outStandingBalance.replace(/[^0-9.-]+/g, ""));
    
    if (filterdPayableAmount > validStandingBal) {
        this.onSettlementModalOpen(settlementContent);
    } else {
      sessionStorage.setItem('transaction_value', filterdPayableAmount);
      sessionStorage.setItem('cvv_value',this.paymentForm.get('cvvInput')?.value);
      this.router.navigate(['confirm-billing-address']);
    }
  }
}

