import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { WSSCommonModule } from 'libs/common/common.module';
import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InboxMsgWidgetComponent } from './inbox-msg-widget.component';
import { ServiceExceptionComponent } from './components/service-exception/service-exception.component';
import { MailboxComponent } from './components/mailbox/mailbox.component';

@NgModule({
  declarations: [
    InboxMsgWidgetComponent,
    MailboxComponent,
    ServiceExceptionComponent
  ],
  imports: [
    CommonModule,
    BackbaseCoreModule.withConfig({
      classMap: { InboxMsgWidgetComponent }
    }),
    RouterModule.forRoot([], { initialNavigation: 'disabled', useHash: true, relativeLinkResolution: 'legacy' }),
    NgbModalModule,
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule,
    MatBadgeModule,
    MatTabsModule,
    MatMenuModule,
    MatCheckboxModule
  ]
})
export class InboxMsgWidgetModule { }