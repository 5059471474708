import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { DigitalInboxWidgetItem } from '@wss/model/content/digital-inbox';
import { Observable, forkJoin, Subject } from 'rxjs';
import { DigitalInboxService } from '../../services/digital-inbox.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { takeUntil } from 'rxjs/operators';
import { isErrServer, getDate, isNotEmpOrNullOrUndef, getErrorImageClass } from 'libs/common/util/util';
import { MessageService } from 'libs/service/message.service';
import { RouterService } from "@backbase/foundation-ang/core";
import { ServiceException } from "@wss/model/service-exception";
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'account-selection',
  templateUrl: './account-selection.component.html',
  providers: [ContentService]
})
export class AccountSelectionComponent implements OnInit, OnDestroy {

  digitalInboxWidgetItem$: Observable<DigitalInboxWidgetItem | undefined> = this.contentService.getContent<DigitalInboxWidgetItem>('digitalInboxWidgetItem');
  currentAccountObj: any = {};
  otherAccounts: any[] = [];
  accountNo: string = this.datastoreService.getAccountNo();
  readonly destroy$ = new Subject();
  serviceError: boolean = false;
  serviceException: ServiceException = new ServiceException();
  digitalInboxWidgetItem: DigitalInboxWidgetItem = new DigitalInboxWidgetItem();

  constructor (
    private readonly contentService: ContentService,
    private digiInboxService: DigitalInboxService,
    private datastoreService: DatastoreService,
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private tealiumService: TealiumUtagService,
    private router: RouterService
    ) {}

  ngOnInit(): void {
    this.tealiumService.view (
      {
        JourneyName: 'Digital Inbox',
        JourneyStepName: 'Account Selection',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.digitalInboxWidgetItem$.subscribe((digitalInboxWidgetItem: any) => {
      this.digitalInboxWidgetItem = digitalInboxWidgetItem;
      this.changeDetectorRef.detectChanges();
    });
    this.otherAccounts = this.datastoreService.getAccountList();
    this.otherAccounts = this.otherAccounts.filter(account => !isNotEmpOrNullOrUndef(account.completion_date));
    this.currentAccountObj = this.otherAccounts.filter(account => account.account_number === this.accountNo)[0];
    this.otherAccounts = this.otherAccounts.filter(account => account.account_number !== this.accountNo).map(account => {
      account.checked = false;
      return { ...account };
    });
    this.getPreferenceForAll();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPreferenceForAll() {
    let today = new Date();
    forkJoin(
      this.otherAccounts.map(account =>
        this.digiInboxService.getMailPreference(account.account_number)
      )
    )
    .pipe(takeUntil(this.destroy$))
    .subscribe((result: any) => {
      for (var i = 0; i < result.length; i++) {
        this.otherAccounts[i] = { ...result[i], ...this.otherAccounts[i] };
      }

      this.otherAccounts = this.otherAccounts.filter((account: any) => !account.is_paperless && (today >= getDate(account.digital_inbox_start_date.split(" ")[0])));
      this.changeDetectorRef.detectChanges();
    },
    (error: any) => {
      this.showError(error?.status);
    });
  }

  updatePreference() {
    let selectedAccounts = [this.currentAccountObj];
    this.otherAccounts = this.otherAccounts.filter((account: any) => account.checked === true);
    selectedAccounts = [...selectedAccounts, ...this.otherAccounts];
    let body: any = {
      "is_paperless": true
    };
    forkJoin(
      selectedAccounts.map(account =>
        this.digiInboxService.saveMailPreference(account.account_number, body)
      )
    )
    .pipe(takeUntil(this.destroy$))
    .subscribe((result: any) => {
      this.datastoreService.setIsPreferenceUpdated();
      this.router.navigate(['preference-result']);
    },
    (error: any) => {
      this.showError(error?.status);
    });

  }

  showError(errStatus: number): void {
    this.showPreferenceUpdateError();
    // if(isErrServer(errStatus)){
    //   this.datastoreService.setErrorServer();
    //   this.serviceError = true;
    //   this.changeDetectorRef.detectChanges();
    // }
  }

  handleErrors() {
    this.messageService.getServiceError().pipe(takeUntil(this.destroy$)).subscribe((error: any) => {
      this.showPreferenceUpdateError();
      // if (error) {
      //   this.showError(error?.status || error?.code);
      // }
    });
  }

  showPreferenceUpdateError() {
    this.serviceException.imageClass = getErrorImageClass(this.digitalInboxWidgetItem.accountSelection.preferenceUpdateException.errorImageType.toString());
    this.serviceException.errorInfoText = this.digitalInboxWidgetItem.accountSelection.preferenceUpdateException.errorInfoText;
    this.serviceException.buttonText = this.digitalInboxWidgetItem.accountSelection.preferenceUpdateException.buttonText;
    this.serviceException.componentUri = this.digitalInboxWidgetItem.accountSelection.preferenceUpdateException.componentUri;
    this.messageService.setServiceException(this.serviceException);
    this.router.navigate(['/payments/service-exception']);
  }

}