<div class="{{displayClass}}">
    <span class="text-capitalize" *ngIf="checkAddress('Flat No')" test_id="flat_number">
        {{addressFlat}}&nbsp;
    </span>
    <span class="text-capitalize" *ngIf="checkAddress('House No')" test_id="house_number">
        {{addressContent.house_number}}&nbsp;
    </span>
    <span class="text-capitalize" *ngIf="checkAddress('House Name')" test_id="house_name">
        {{addressContent.house_name | lowercase}}
    </span>
    <span class="d-block text-capitalize" *ngIf="checkAddress('Street Name')" test_id="street_name">
        {{addressContent.street_name | lowercase}}
    </span>
    <span class="d-block text-capitalize" *ngIf="checkAddress('Post Town')" test_id="post_town">
        {{addressContent.post_town}}
    </span>
    <span class="d-block text-capitalize" *ngIf="checkAddress('District')" test_id="district">
        {{addressContent.district | lowercase}}
    </span>
    <span class="d-block text-capitalize" *ngIf="(checkAddress('County') && checkAddress('District'))" test_id="county">
        {{addressContent.county | lowercase}}
    </span>
    <span class="d-block text-capitalize" *ngIf="(checkAddress('County') && !checkAddress('District'))" test_id="county">
        {{addressContent.county | lowercase}}
    </span>
    <span class="d-block" *ngIf="checkAddress('Post Code')" test_id="post_code">
        {{addressContent.post_code}}
    </span>
</div>