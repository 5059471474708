<div class="payment-holiday-container" *ngIf="paymentHolidayWidgetItem$ | async as paymentHolidayWidgetItem">
    <div class="container-column your-options">
        <div class="container-row section-title-container">
            <span class="section-title" test_id="pageName">{{paymentHolidayWidgetItem.cohortThird.pageName}}</span>
        </div>
        <div class="container-row cohort-three-top-section" test_id="pageContentText">
            <span><bb-content-html [html]="paymentHolidayWidgetItem.cohortThird.pageContentTitleText"></bb-content-html></span>
            <div class="brand-contact-details">
                <span><bb-content-html [html]="paymentHolidayWidgetItem.cohortThird.pageContentText"></bb-content-html></span>
                <span *ngIf="paymentHolidayWidgetItem.brandContactDetails"><bb-content-html [html]="paymentHolidayWidgetItem.brandContactDetails.brandWiseCfaNumber"></bb-content-html></span>
                <span><bb-content-html [html]="paymentHolidayWidgetItem.cohortThird.pageContentTextPartTwo"></bb-content-html></span>
            </div>
            <span><bb-content-html [html]="paymentHolidayWidgetItem.cohortThird.pageContentTextPartThree"></bb-content-html></span>
        </div>
        <div class="responsive-row">
            <div class="container-column not-ready cohort-3">
                <div class="container-column align-middle">
                    <div class="container-column text-center mt-13px" test_id="budgetToolInfoText">
                        <bb-content-html [html]="paymentHolidayWidgetItem.cohortThird.budgetToolInfoText"></bb-content-html>
                    </div>
                    <div class="container-row launch-budget-tool">
                        <button class="wss-button" tabindex="0" role="button" (click)="openBudgetTool()" test_id="budgetToolButtonText">{{paymentHolidayWidgetItem.cohortThird.budgetToolButtonText}}</button>
                    </div>
                </div>
            </div>
            <div class="container-column mt-13px cohort-advice" test_id="adviceInfoText">
                <bb-content-html [html]="paymentHolidayWidgetItem.cohortThird.adviceInfoText"></bb-content-html>
            </div>
        </div>
    </div>
</div>

<!-- <div class="payment-holiday-container">
    <div class="container-column your-options">
        <div class="container-row section-title-container">
            <span class="section-title">{{content[0]?.uroptions}}</span>
        </div>
        <div class="container-row holiday-ends">
            <span class="text-semibold">{{content[0]?.wearesorry}}.</span>
        </div>
        <div class="container-column">
            <span>{{content[0]?.heretohelp}}. <a title="{{content[0]?.useonlinetool}}" tabindex="0"
                    role="link">{{content[0]?.useonlinetool}}</a>. {{content[0]?.ucanthen}} <a
                    title="{{content[0]?.helpno}}" tabindex="0" href="tel:{{content[0]?.helpno}}">{{content[0]?.helpno}}</a>
                {{content[0]?.wecantalk}}</span>
            <br>
            <span>{{content[0]?.wewillneed}}.</span>
            <br>
        </div>
        <div class="responsive-row">
            <div class="container-column not-ready cohort-3">
                <div class="container-column align-middle">
                    <div class="container-column">
                        <span class="text-semibold text-center">{{content[0]?.savetime}}</span>
                        <span class="text-center">{{content[0]?.ucansave}}.</span>
                    </div>
                    <div class="container-row launch-budget-tool">
                        <button class="wss-button" tabindex="0" role="button">{{content[0]?.urincomenoutgoing}}</button>
                    </div>
                </div>
            </div>
            <div class="container-column">
                <span class="when-you-start text-semibold">{{content[0]?.advice}}</span>
                <span class="text-semibold">{{content[0]?.getimpartial}}</span>
                <br>
                <ul class="mb-120px">
                    <li>{{content[0]?.ifuneed}}. <a (click)="openFCA()" title="{{content[0]?.financial}}" tabindex="0"
                            role="link">{{content[0]?.financial}}</a></li>
                    <li>{{content[0]?.formoreinfo}}. <a title="{{content[0]?.moneyadvice}}" tabindex="0"
                            role="link">{{content[0]?.moneyadvice}}</a></li>
                </ul>
            </div>
        </div>
    </div>
</div> -->