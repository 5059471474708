import { Injectable } from '@angular/core';
import ls from 'localstorage-slim';
import { appConfig } from '@wss/config/wss-app-constants';

@Injectable({ providedIn: 'root' })
export class StorageService {

  constructor() {}

  setLocalStorage(itemName: string, data: any) {
    ls.set(itemName, data, {encrypt: true, secret: appConfig.sCode});
  }

  getLocalStorage(itemName: string) {
    let getDetails = ls.get(itemName, {decrypt: true, secret: appConfig.sCode}); 
    return getDetails;
  }

}
