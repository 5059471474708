import { Injectable } from '@angular/core';
import { messageOTP } from '../../../model/messageOtp';
import { QuestionIIQ } from '../../../model/questionIIQ';
import { CustomerToken, userDetails, userToken, OTPVerify, ForgotUsernameOTPVerify } from '../../../model/registrationToken';
import { AuthService } from '../../../service/auth.service';
//import { RouterService } from "@backbase/foundation-ang/core";
import {Router} from "@angular/router";


@Injectable({ 
  providedIn: 'root',
})
export class RegisterDatastoreService {
  
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }  
  
  private email: string = '';
  private password: string = '';
  private token: string = '';
  private customerToken: CustomerToken[] = []
  private customerStatus: boolean = true;
  private responseUser: userToken = new userDetails() ;
  private firstNameUser: string = '';
  private initialsUser: string = '';
  private mobileUser: string = '';
  private verifyType: string = '';
  private multipleUserStatus: boolean = false;
  private messageOTP: any;
  private OTPStatus: string = '';
  private verifyOTP: any;
  private userId: string = '';
  private passcode: string = '';
  private detailsIIQ: any;
  private transactionIdIIQ: string = '';
  private questionAsked: number = 0;
  private questionsCorrect: number = 0; 
  private questionList: any[] = [];
  private statusIIQ: string = '';
  private passCodeIIQ: string = '';
  private custToken: string = '';
  private userToken: string = '';
  private paramCust: string = '';
  private multiResponse: any;
  private multiValid: string = '';
  private iiqAttempt: boolean = false;
  private iiqTimeout: boolean = false;
  private verifyEmailOTP: any;
  private userAlias: any;
  private accountDetails: any;

  setParamCust(value: string) {
    this.paramCust = value
  }

  getParamCust() {
    return this.paramCust;
  }

  setCustomerToken(data: CustomerToken[]) {
    this.customerToken = data;
    if(this.customerToken.length === 1){
      this.setCustToken(this.customerToken[0].token);
      this.setCustomerStatus(this.customerToken[0].user);
    } else {
      this.setMultiUser(true);      
    }         
  }

  getCustomerToken() {
    return this.customerToken;
  }

  setMultipleCustomer(){      
    /*for(let j=0; j<this.customerToken.length; j++){
      if(this.customerToken[j].user === false){
        this.setMultiUser(false);                 
      }
    }
    if(this.multipleUserStatus === false){
      for(let i=0; i<this.customerToken.length; i++){
        if(this.customerToken[i].user === true){
          this.setCustToken(this.customerToken[i].token);
          this.setCustomerStatus(this.customerToken[i].user);                
        }
      }
    }*/
  }

  setMultiUser(value: boolean){
    this.multipleUserStatus = value;    
  }

  getMultiUser() {
    return this.multipleUserStatus;
  }

  setCustToken(value: string) {
    this.custToken = value;
  }

  getCustToken() {
    return this.custToken;
  }

  setCustomerValid(data: any) {
    this.multiResponse = data;
    this.setMultiValid(this.multiResponse.result);
    if(this.multiResponse.result === 'Valid'){
      this.setCustToken(this.multiResponse.token);
      this.setCustomerStatus(this.multiResponse.user);      
    }    
  }

  setMultiValid(value: string) {
    this.multiValid = value;
  }

  getMultiValid() {
    return this.multiValid;
  }  

  setCustomerStatus(value: boolean) {
    this.customerStatus = value;
  }

  getCustomerStatus() {
    return this.customerStatus;
  }

  setUserdetails(data: userToken) {
    this.responseUser = data;
    this.setCustToken(this.responseUser.token);
    this.setFirstNameUser(this.responseUser.first_name);
    this.setInitialsUser(this.responseUser.initials);
    this.setMobileUser(this.responseUser.last_digits_mobile_phone);
  }

  setFirstNameUser(value: string) {
    this.firstNameUser = value;
  }

  getFirstNameUser() {
    return this.firstNameUser;
  }

  setInitialsUser(value: string) {
    this.initialsUser = value;
  }

  getInitialsUser() {
    return this.initialsUser;
  }

  setMobileUser(value: string) {
    this.mobileUser = value;
  }

  getMobileUser() {
    return this.mobileUser;
  }

  setVerifyType(value: string) {
    this.verifyType = value;
  }

  getVerifyType() {
    return this.verifyType;
  }

  setMessageOtp(value: messageOTP) {
    this.messageOTP = value;  
    this.setUserToken(this.messageOTP.user_token);  
    this.setOTPStatus(this.messageOTP.status);
  }

  setUserToken(value: string) {
    this.userToken = value;
  }

  getUserToken() {
    return this.userToken;
  }

  setOTPStatus(value: string) {
    this.OTPStatus = value;
  }

  getOTPStatus() {
    return this.OTPStatus;
  }

  setVerifyOtp(value: OTPVerify) {
    this.verifyOTP = value;
    this.setUserToken(this.verifyOTP.user_token);
    this.setUserId(this.verifyOTP.user_id)
    this.setAccountDetails(this.verifyOTP.accounts)
  }

  setVerifyEmailOtp(value: ForgotUsernameOTPVerify) {
    this.verifyEmailOTP = value;
    this.setUserAlias(this.verifyEmailOTP.alias);
  }

  setPasscode(value: string) {
    this.passcode = value;
  }

  getPasscode(){
    return this.passcode;
  }

  setUserId(value: string) {
    this.userId = value;
  }

  getUserId() {
    return this.userId;
  }

  setEmail(value: string) {
    this.email = value;
  }

  getEmail() {
    return this.email;
  }

  setPwd(value: string) {
    this.password = value;
  }

  getPwd() {
    return this.password;
  }

  setQuestionIIQ(data: QuestionIIQ) {
    this.detailsIIQ = data;
    this.setUserToken(this.detailsIIQ.user_token);
    this.setUserId(this.detailsIIQ.user_id);
    this.setStatusIIQ(this.detailsIIQ.status);
    this.setPassCodeIIQ(this.detailsIIQ.registration.passcode);
    this.setTransactionIdIIQ(this.detailsIIQ.registration.transaction_id);
    this.setQuestionAsked(this.detailsIIQ.registration.questions_asked);
    this.setQuestionsCorrect(this.detailsIIQ.registration.questions_correct);
    this.setQuestionList(this.detailsIIQ.registration.questions);
    this.setAccountDetails(this.detailsIIQ.accounts);
  }

  setStatusIIQ(value: string) {
    this.statusIIQ = value;
  }

  getStatusIIQ() {
    return this.statusIIQ;
  }

  setPassCodeIIQ(value: string) {
    this.passCodeIIQ = value;
  }

  getPassCodeIIQ() {
    return this.passCodeIIQ;
  }

  setTransactionIdIIQ(value: string) {
    this.transactionIdIIQ = value;
  }

  getTransactionIdIIQ() {
    return this.transactionIdIIQ;
  }

  
  setQuestionAsked(value: number) {
    this.questionAsked = value;
  }

  getQuestionAsked() {
    return this.questionAsked;
  }

  setQuestionsCorrect(value: number) {
    this.questionsCorrect = value;
  }

  getQuestionsCorrect() {
    return this.questionsCorrect;
  }

  setQuestionList(value: any[]) {
    this.questionList = value;
  }

  getQuestionList() {
    return this.questionList;
  }

  getQuestionIIQ() {
    return this.detailsIIQ;
  }

  setToken(value: string) {
    this.token = value;
    sessionStorage.setItem('tokenReqRegister', this.token);
  }

  getToken() {
    return this.token;
  }

  sessionInvalid(){
    if(!this.authService.isAuthRegister()) {
      this.router.navigate(['user-search']);
    }
  }

  setIIQattempt(value: boolean) {
    this.iiqAttempt = value;    
  }

  getIIQattempt() {
    return this.iiqAttempt;
  }

  setIIQtimeout(value: boolean) {
    this.iiqTimeout = value;    
  }

  getIIQtimeout() {
    return this.iiqTimeout;
  }

  setUserAlias(value: string) {
    this.userAlias = value;
  }

  getUserAlias() {
    return this.userAlias;
  }

  setAccountDetails(value: any) {
    this.accountDetails = value;
  }

  getAccountDetails() {
    return this.accountDetails;
  }

}