import { Component, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { BalloonRefinanceService } from '@wss/balloon-refinance-widget/src/services/balloon-refinance.service';
import { BalloonRefinanceGetdetailsItem } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'bb-brg-referral',
  templateUrl: './brg-referral.component.html'
})
export class BrgReferralComponent implements OnInit {
  brgItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');
  timer: number[] = [15000, 15000, 30000];
  timerCounter: number = 0;
  readonly destroy$ = new Subject();
  decisionData: any;
  accountsData: any;
  disableRippleEffiect: boolean = true;

  constructor(
    private datastore: DatastoreService,
    private readonly bbContentService: ContentService,
    private balloonRefinanceSrv: BalloonRefinanceService,
    private tealiumService: TealiumUtagService
  ) { }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'Proposal - Referral',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.datastore.setBrHideStepper(true);
    this.decisionData = this.datastore.getDecisionData();
    this.accountsData = this.datastore.getAccountDetails();
  }

}
