import { Action } from "@backbase/foundation-ang/store";
import { Vehicle } from "@wss/balloon-refinance-widget/src/model/vehicle";


export enum ActionTypes {
    LOAD_USERDETAILS = '[balloon refinance page] load userDetails',
    LOAD_USERDETAILS_SUCCESS = '[balloon refinance page] load userDetails success',
    //Vehicle
    LOAD_VEHICLE = '[balloon refinance page] load vehicle',
    LOAD_VEHICLE_SUCCESS = '[balloon refinacnce page] load vehicle success',
    LOAD_VEHICLE_ERROR = '[balloon refinance page] load vehicle error'
}

export class LoadVehicleAction implements Action {
    readonly type = ActionTypes.LOAD_VEHICLE;
    constructor() {}
}

export class LoadVehicleSuccessAction implements Action {
    readonly type = ActionTypes.LOAD_VEHICLE_SUCCESS
    constructor(public payload: Vehicle) {}
}

export type BalloonRefinanceActions = LoadVehicleAction | LoadVehicleSuccessAction;

