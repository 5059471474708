import { Component, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { BalloonRefinanceItem } from '@wss/model/balloon-refinance/balloon-refinance';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-br-cta-arrears',
  templateUrl: 'br-cta-arrears.component.html',
  styles: [
  ]
})

export class BrCtaArrearsComponent implements OnInit {

  brCtaArrearsComponent$: Observable<BalloonRefinanceItem | undefined> = this.bbContentService.getContent<BalloonRefinanceItem>('balloonRefinanceWidgetItem');
  
  constructor(
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService
  ) { }

  ngOnInit(): void {
    this.tealiumService.view({ tealium_event:'Balloon_Refinance_Arrears', URI: '/dashboard#/balloon-refinance/cta-arrears' });
  }

  callButtonHandler(contactNumber: string) {
    window.open('tel:' + contactNumber, '_self');
  }

}
