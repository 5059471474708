<div class="reg-container" *ngIf="registrationWidgetItem$ | async as registrationWidgetItem">
  <div *ngIf="codeFail" role="row" test_id="pageHeaderTextOne">
    <bb-content-html [html]="registrationWidgetItem.verifySms.pageHeaderTextOne"></bb-content-html>
  </div>
  <div class="reg-row" role="alert" *ngIf="serviceError">         
    <bb-wss-banner-message [errorCode]="errorCode"></bb-wss-banner-message>
  </div>
  <div *ngIf="!codeFail" role="row" test_id="pageHeaderTextTwo">
    <bb-content-html [html]="registrationWidgetItem.verifySms.pageHeaderTextTwo"></bb-content-html>
  </div>
  <div class="phone-number">
    **** *** {{ mobileNo | slice : 1:4 }}
  </div>
  <div class="text-center">
    <button id="verify-sms-btn" bbButton type="button" aria-label="Favourite" class="wss-button" (click)="submit()" test_id="nextButtonText">
      <span>{{registrationWidgetItem.verifySms.nextButtonText}}</span>
    </button>
  </div>
  <div class="text-center">
    <div id="verify-sms-link" class="need-help-link text-center mb-25px" test_id="needHelpLink">
      <bb-content-html [html]="registrationWidgetItem.verifySms.needHelpLink"></bb-content-html>
    </div>
  </div>
</div>
