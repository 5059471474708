import { Component, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'wss-infor-tool',
  templateUrl: './information-tool.component.html'
})

export class InformationToolComponent {

  @Input() lblContent: any;
  @Input() lblAddress?: any;
  @Input() lblPrevAddress?: any; 
  @Input() lblWas?: any; 
  @Input() showLblAddress?: boolean = false;
  @Input() showLblRvAddress?: boolean = false;
  @Input() lblInforContent: any;
  @Input() inforContent: any;
  @Input() idExpand: string = '';
  @Input() idCollapse: string = '';
  @Input() idInforPanel: string = '';
  @Input() defaultTopMargin: boolean = true;

  showInfor: boolean = false;

  constructor(
    private elementRef: ElementRef
    ) { }

  expandCollapse() {
    let elemSpanExpand = '#' + this.idExpand;
    let nativeElm = this.elementRef.nativeElement;
    this.showInfor = nativeElm.querySelector(elemSpanExpand).className === 'material-icons v-middle d-inline' ? true : false;
  }

}
