<section class="cta-screen text-center col-md-7 col-12 p-40px" *ngIf="endOfContractWidgetItem">
  <div class="mt-40px margin-1rem-auto" [ngClass]="vtInProgress && isRegulated ? 'service-dateerror' : 'get-in-touch-icon'"></div>
  <div class="font-size-18 mb-25px">
    <bb-content-html
      [html]="vtInProgress && isRegulated ? endOfContractWidgetItem.eocVoluntaryTerminationCtaPage.pageTitleVtInProgress : endOfContractWidgetItem.eocVoluntaryTerminationCtaPage.pageTitleGetInTouch">
    </bb-content-html>
  </div>
  <div class="mb-30px">
    <bb-content-html [html]="ctaMainParagraphContent"></bb-content-html>
  </div>
  <div *ngIf="vtInProgress && isRegulated; else callButtonTemplate" class="d-flex flex-column align-items-center">
    <div class="d-flex flex-column align-items-center">
      <button class="wss-button m-0 d-flex align-items-center justify-content-center" test_id="primaryNavigateButton"
        (click)="navigateToVoluntaryTerminationPage()">
        {{endOfContractWidgetItem.eocVoluntaryTerminationCtaPage.returnToVTPageButtonText}}
      </button>
    </div>
  </div>
  <ng-template #callButtonTemplate>
    <div class="d-flex flex-column align-items-center">
      <button class="wss-button m-0 d-flex align-items-center justify-content-center" test_id="primaryCallButton"
        (click)="callButtonHandler(endOfContractWidgetItem.brandContactDetails.brandWiseContactNumber)">
        <span class="material-icons-outlined mr-1">call</span>
        <bb-content-html [html]="endOfContractWidgetItem.brandContactDetails.brandWiseNumberWithoutLink">
        </bb-content-html>
      </button>

      <div test_id="returnToDashboardLink" class="mt-20px">
        <bb-content-html [html]="!isRegulated ? endOfContractWidgetItem.eocVoluntaryTerminationCtaPage.returnToDashboardText : endOfContractWidgetItem.eocVoluntaryTerminationCtaPage.returnToVTPageText ">
        </bb-content-html>
      </div>
    </div>
  </ng-template>



</section>