import { Component, AfterViewInit, Input, ViewChild, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { PaperLessPopup } from '@wss/model/content/dashboard';
import { RouterService } from "@backbase/foundation-ang/core";
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DatastoreService } from '@wss/service/datastore.service';
import { getPortalName, getDeviceType } from '@wss/common/util/util';
declare var document: any;

@Component({
  selector: 'bb-wss-paperless-account-popup',
  templateUrl: 'paperless-account-popup.component.html',
  providers: [ NgbModalConfig, NgbModal ]
})
export class PaperlessAccountPopUpComponent implements AfterViewInit, AfterViewChecked {
  @Input() accountDetail: any;
  @Input() paperlessPopupContent: PaperLessPopup = new PaperLessPopup();
  showPaperlessPopup: boolean = true;
  modalRef: any;
  @ViewChild('paperlessActionPopUp') paperlessActionPopUp: any;
  portalName: string = getPortalName();
  deviceType: string = getDeviceType();
  isAndroid: boolean = false;
  scrollTopCounter: number = 0;
  isDownArrowNeeded: boolean = true;

  constructor(
    private router: RouterService,
    private modalService: NgbModal,
    private datastore: DatastoreService,
    private changeDetectorRef: ChangeDetectorRef,
    config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngAfterViewInit(): void {
    this.isAndroid = /(android)/i.test(navigator.userAgent) || ((this.portalName === 'mitsubishishogun') && (this.deviceType === 'Mobile'));
    this.modalRef = this.modalService.open(this.paperlessActionPopUp, { centered: true, windowClass: this.isAndroid? 'interstitial-modal-withscroll': 'interstitial-modal' });
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewChecked() {
    if(!((this.portalName === 'mitsubishishogun') && (this.deviceType !== 'Mobile'))){
      setTimeout(() => {
        if (document.querySelector('ngb-modal-window')) {
          document.querySelector('ngb-modal-window').scrollTop = 1000;
          if(this.scrollTopCounter === 0){
            document.querySelector(".scrolling-content").scrollTop = 0;
            ++this.scrollTopCounter;
          }
          this.scrollContent();
        }
      }, 300);
    }
  }

  scrollContent(){
    let scrollTop = document.querySelector(".scrolling-content").scrollTop;
    let scrollHeight = document.querySelector(".scrolling-content").scrollHeight;
    let offsetHeight = document.querySelector(".scrolling-content").offsetHeight;
    if(scrollTop === (scrollHeight - offsetHeight)){
      this.isDownArrowNeeded = false;
    }else{
      this.isDownArrowNeeded = true;
    }
    this.changeDetectorRef.detectChanges();
  }

  scrollToBottom(){
    document.querySelector(".scrolling-content").scrollTop = (document.querySelector(".scrolling-content").scrollHeight -  document.querySelector(".scrolling-content").offsetHeight);
    this.changeDetectorRef.detectChanges();
  }

  paperlessAccountClick() {
    this.modalRef.close();
    this.datastore.setPreferenceLaunchThrough("banners");
    this.router.navigate(['/digital-inbox']);
  }

  onModalClose() {
    this.modalRef.close();
  }
}