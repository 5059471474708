import { Component, Input } from "@angular/core"; 
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap"; 
import { DatastoreService } from "@wss/service/datastore.service";

@Component({
    selector: 'bb-continue-or-exit',
    templateUrl: 'continue-or-exit.component.html',
})

export class ContinueOrExitComponent {
    @Input() fromParent: any;

    constructor(public activeModal: NgbActiveModal, 
        private router: Router, private dataStoreService: DatastoreService) {
    }

    onContinue() {
        this.activeModal.dismiss('Close');
    }

    onExit() {
        this.dataStoreService.setContactPreferencePopupShown(false);
        this.activeModal.dismiss('Close');
        this.router.navigate(['/']);
    }
 
}
