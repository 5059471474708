<div class="container-security" *ngIf="registrationWidgetItem$ | async as registrationWidgetItem">

  <bb-wss-common-icon [className]= "'security-fail-icon'" [imgText] = "'Security Fail'"></bb-wss-common-icon>

  <div class="col3 text-center" role="alert" *ngIf="!getAttemptError && !getTimeoutError" test_id="pageHeaderTextOne">
    <bb-content-html [html]="registrationWidgetItem.securityFail.pageHeaderTextOne"></bb-content-html>
  </div>    
  <div class="col3 text-center" role="alert" *ngIf="getAttemptError" test_id="pageHeaderTextTwo">   
    <bb-content-html [html]="registrationWidgetItem.securityFail.pageHeaderTextTwo"></bb-content-html>
  </div>
  <div class="col3 title-section-page text-center" role="alert" *ngIf="getTimeoutError" test_id="pageHeaderTextTimeout">    
    <bb-content-html [html]="registrationWidgetItem.securityFail.pageHeaderTextTimeout"></bb-content-html>
  </div>

  <div class="text-center" *ngIf="getAttemptError || getTimeoutError">
    <button id="bb-button-to" bbButton type="button" aria-label="Favourite" (click)="tryOption()"
      [className]="'wss-button'" class="button-width-padding" test_id="backToAuthenticationOptionButtonText">
      {{registrationWidgetItem.securityFail.backToAuthenticationOptionButtonText}}
    </button>
  </div>
  <div *ngIf="!getAttemptError && !getTimeoutError" class="text-center" test_id="backToAuthenticationOptionLink">  
    <bb-content-html [html]="registrationWidgetItem.securityFail.backToAuthenticationOptionLink"></bb-content-html>
  </div>

  <div class="need-help-link text-center mb-25px" test_id="needHelpLink">
    <bb-content-html [html]="registrationWidgetItem.securityFail.needHelpLink"></bb-content-html>
  </div>
  
</div>

  