import {Component, Input, OnInit, OnDestroy, ChangeDetectorRef} from "@angular/core";
import {ItemModel, RouterService} from "@backbase/foundation-ang/core";
import {RegistrationStoreModelService} from "../../../../registration-store/src/registration-store-model.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContentService as OldContentService} from "../../../../service/content.service";
import {appConfig, patternMap} from '@wss/config/wss-app-constants';
import {RegisterService} from '../../service/register.service';
import {RequestService} from '../../../../service/request.service';
import {RegisterDatastoreService} from '../../service/register-datastore.service';
import {Observable, Subject} from "rxjs";
import {takeUntil} from 'rxjs/operators';
import {isErrServer} from '../../../../common/util/util';
import {DatastoreService} from '../../../../service/datastore.service';
import {RegistrationWidgetItem} from "@wss/model/registrationToken";
import {ContentService} from "@backbase/universal-ang/content";
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  templateUrl: './user-password.component.html',
  selector: 'bb-ws-user-credential-pwd',
  providers: [ContentService]
})
export class UserPasswordComponent implements OnInit, OnDestroy {

  changePasswordForm: FormGroup;

  @Input()
  atLeastContainsEightChars = "disabled";
  @Input()
  atLeastOneUpperCase = "disabled";
  @Input()
  atLeastOneLowerCase = "disabled";
  @Input()
  atLeastOneNumber = "disabled";
  @Input()
  atLeastOneSpecialChar = "disabled";
  @Input()
  buttonDisabled = true;

  upperCaseLetterformat = patternMap.uppercasePattern;
  lowerCaseLetterformat = patternMap.lowercasePattern;
  numberformat = patternMap.numberPattern;
  validCharsFormat = patternMap.validSpecialCharsPattern; 

  hasErrors = false;
  showPasswordError: boolean = false;
  passwordMatch: boolean = true;
  serviceError: boolean = false;
  showEmailLink: boolean = false;
  validSpecialChars: boolean = true;
  isContactPreferenceFeatureOn: boolean = false;

  readonly destroy$ = new Subject();

  registrationWidgetItem$: Observable<RegistrationWidgetItem | undefined> = this.bbContentService.getContent<RegistrationWidgetItem>('registrationWidgetItem');
  passwordEntered: boolean = true;
  errorCode: any;
  
  ngOnInit(): void {
    this.tealiumService.view({'page_type': 'registration_enter_pwd'});
    this.handlePasswordChange();
    this.isContactPreferenceFeatureOn = this.cmndatastoreService.getIsContactPreferenceFeature() && this.cmndatastoreService.getIsContactPreferenceRegisterFeature(); 
    this.tealiumService.view(
      {
        JourneyName: 'Registration',
        JourneyStepName: 'User Password',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
    this.refChange.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();  
    this.destroy$.complete();    
  }

  checkIfButtonCanBeEnabled() {
    if(this.atLeastOneNumber === 'done' &&
      this.atLeastOneLowerCase === 'done' &&
      this.atLeastOneUpperCase === 'done' && 
      this.validSpecialChars === true &&
      this.atLeastContainsEightChars === 'done'
    ){      
      this.passwordEntered = false;
      this.enableButtonWhenPasswordsAreEqual();
    } else {
      this.passwordEntered = true;
    }
  }

  constructor(private itemModel: ItemModel,
              private registrationService: RegistrationStoreModelService,
              private formBuilder: FormBuilder,  
              private router: RouterService,
              private contentService: OldContentService,
              private registerService: RegisterService,
              private requestService: RequestService,
              private registerstoreService: RegisterDatastoreService,
              private cmndatastoreService: DatastoreService,
              private refChange: ChangeDetectorRef,
              private readonly bbContentService: ContentService,
              private tealiumService: TealiumUtagService,
              ) {                
    this.itemModel.property('createAccount').pipe(takeUntil(this.destroy$)).subscribe(value => this.registrationService.updateTitle(value as string));
    this.registrationService.updatedStepper(3);
    this.changePasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  showError(errStatus: number, errCode: string): void {
    if(isErrServer(errStatus)){
      if(errCode === '002'){
        this.showEmailLink = true;
        this.cmndatastoreService.setErrorScreen(appConfig.errorEmailRegistration);
      } else {
        this.showEmailLink = false;
        this.cmndatastoreService.setErrorServer();
      }      
      this.serviceError = true;
      this.refChange.detectChanges();
    }
  }

  onSubmit() {
    this.registerstoreService.setPwd(btoa(unescape(encodeURIComponent(this.changePasswordForm.get('password')?.value))));
    if(this.registerstoreService.getVerifyType() === 'OTP'){
      this.requestService.setOTPConfirm();
    } else {
      this.requestService.setIIQConfirm();
    }    
    if (this.cmndatastoreService.getIsContactPreferenceFeature() && this.cmndatastoreService.getIsContactPreferenceRegisterFeature() ) {
        this.router.navigate(['contact-preference']);
    } else {
        this.createAccount();
    }
  }

  createAccount() {
    this.registerService.getCustomerConfirm().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.serviceError = false;
      this.router.navigate(['credential-created']);
    },
    (error:any) => {
      this.errorCode = error.error?.code ? error.error.code : error.status;
      this.showError(error.status, error.error.code);      
    });
  }

  enableButtonWhenPasswordsAreEqual(){
    if(this.changePasswordForm.get('confirmPassword')?.value === this.changePasswordForm.get('password')?.value){
      this.buttonDisabled = false;
      this.passwordMatch = true;
    } else {
      this.buttonDisabled = true;
      if (this.changePasswordForm.get('confirmPassword')?.value.length > 0) {
        this.passwordMatch = false;
      }
    }
  }

  handlePasswordChange() {
    const password = this.changePasswordForm.get('password');
    const confirmPassword = this.changePasswordForm.get('confirmPassword');
    password?.valueChanges.forEach(
      (value: string) => {
        this.isLengthEightChars(value);        
        this.hasOneUpperCaseChar(value);
        this.hasOneLowerCaseChar(value);
        this.hasOneNumber(value);
        this.hasValidChars(value);
        this.checkIfButtonCanBeEnabled();
        if (value.length > 3 && (this.atLeastContainsEightChars == 'disabled' || this.atLeastOneLowerCase == 'disabled' 
          || this.atLeastOneNumber == 'disabled' || this.atLeastOneUpperCase == 'disabled' || this.validSpecialChars === false)) {
          this.showPasswordError = true;
        } else {
          this.showPasswordError = false;
        }
      }
    );

    confirmPassword?.valueChanges.forEach(
      (value: string) => {
        this.checkIfButtonCanBeEnabled();
        if (value.length > 3 && value !== this.changePasswordForm.controls['password'].value) {
          this.passwordMatch = false;
        } else {
          this.passwordMatch = true;
        }
      }
    );
  }

  private hasOneNumber(value: string) {
    if (value && value.match(this.numberformat)) {
      this.atLeastOneNumber = "done";
    } else {
      this.atLeastOneNumber = "disabled";
    }
  }

  private hasOneLowerCaseChar(value: string) {
    if (value && value.match(this.lowerCaseLetterformat)) {
      this.atLeastOneLowerCase = "done";
    } else {
      this.atLeastOneLowerCase = "disabled";
    }
  }

  private hasOneUpperCaseChar(value: string) {
    if (value && value.match(this.upperCaseLetterformat)) {
      this.atLeastOneUpperCase = "done";
    } else {
      this.atLeastOneUpperCase = "disabled";
    }
  }

  private isLengthEightChars(value: string) {
    if ((value.length >= 8) && (value.length <= 35)) {
      this.atLeastContainsEightChars = "done";
    } else {
      this.atLeastContainsEightChars = "disabled";
    }
  }

  private hasValidChars(value: string) {
    if (value && value.match(this.validCharsFormat)) {
      this.validSpecialChars = true;
    } else {
      this.validSpecialChars = false;
    }
  }

}
