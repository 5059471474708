import { Injectable } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Observable, of } from 'rxjs';
import { catchError, map} from 'rxjs/operators';
import { MessageService } from '../../../service/message.service';

@Injectable({
  providedIn: 'root',
})
export class DigitalInboxService {

  constructor(
    private httpService: HttpService,
    private messageService: MessageService) { }

  getMailPreference(accountNo: string): Observable<any>{
    return this.httpService.getMailPreference(accountNo)
    .pipe(
      catchError(error => { 
        this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      })
    );
  }

  saveMailPreference(accountNo: string, body: any): Observable<any>{
    return this.httpService.saveMailPreference(accountNo, body)
    .pipe(
      catchError(error => { 
        this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      })
    );
  }

}