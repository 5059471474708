import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from "@angular/router";
import {UserRepoService} from "../../service/user-repo.service";
import {ContentService as OldContentService} from "../../../../service/content.service";
import {ContentData} from '../../../../config/wss-app-constants';
import {ItemModel} from "@backbase/foundation-ang/core";
import {RegistrationStoreModelService} from "../../../../registration-store/src/registration-store-model.service";
import {Observable, Subject} from "rxjs";
import {takeUntil} from 'rxjs/operators';
import {RegisterDatastoreService} from '../../service/register-datastore.service';
import { RegistrationWidgetItem } from '@wss/model/registrationToken';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-wss-no-user-found',
  templateUrl: './no-user.component.html',
  providers: [ ContentService ]
})

export class NoUserComponent implements OnInit, OnDestroy {
  
  readonly destroy$ = new Subject();

  registrationWidgetItem$: Observable<RegistrationWidgetItem | undefined> = this.bbContentService.getContent<RegistrationWidgetItem>('registrationWidgetItem');

  ngOnInit(): void {
    this.datastoreService.sessionInvalid();
    this.tealiumService.view(
      {
        JourneyName: 'Registration',
        JourneyStepName: 'No User',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
    // this.getStaticContent(this.contentTxt, this.contentFolder);
    this.userRepoService.updateScreenTitle('Your details');
  }

  ngOnDestroy(): void {
    this.destroy$.next();  
    this.destroy$.complete();    
  }

  constructor(
    private router: Router,
    private userRepoService: UserRepoService,
    private contentService: OldContentService,
    private itemModel: ItemModel,
    private registrationService: RegistrationStoreModelService,
    private datastoreService: RegisterDatastoreService,
    private tealiumService: TealiumUtagService,
    private readonly bbContentService: ContentService
  ) {
    this.itemModel.property('details').pipe(takeUntil(this.destroy$)).subscribe(value => this.registrationService.updateTitle(value as string));
    this.userRepoService.updateScreenTitle('Your details');    
  }

  submit() {
    this.itemModel.property('searchUserTitle').pipe(takeUntil(this.destroy$)).subscribe(value => this.registrationService.updateTitle(value as string));
    this.router.navigate(['/']);
  }
}


