import { PortalContentMock } from "@backbase/foundation-ang/web-sdk";
import { BrandContactDetailsItem } from '@wss/model/content/common/brand-contant-details';
export class InboxResponse {    
  document_list: InboxMsgList[] = [];
  count_of_documents: number = 0;
  unread_documents_count: number = 0;
}

export class InboxRequest {    
  guid: string = '';
  msgReq: InboxMsgRequest = {};
}

export interface InboxMsgList {
  guid: string;
  name: string;
  pages: string;
  document_date: string;
  last_viewed_date: string;
  is_priority: boolean;
  is_read: boolean;
  is_archived: boolean;
}

export interface InboxMsgState {
  last_viewed_date: string;
  is_archived: boolean;
  is_read: boolean;
}

export interface InboxMsgRequestList {
  guid: string;
  msgReq: InboxMsgRequest;  
}

export interface InboxMsgRequest {
  is_archived?: boolean;
  is_read?: boolean;
}

export class InboxMsgWidgetItem {
  inboxMsgComponent: InboxMsgItem = new InboxMsgItem();
  brandContactDetails: BrandContactDetailsItem = new BrandContactDetailsItem();
}

export class InboxMsgItem {
  pageTitle: string = '';
  preferenceManage: string = '';
  preferenceSwitch:string = '';
  tab1Text: string = '';
  tab2Text: string = '';
  selectText: string = '';
  menuHeader: string = '';
  moveInboxText: string = '';
  unreadText: string = '';
  archiveText: string = '';
  viewLetterBtnText: string = '';
  todayText: string = '';
  errNoUpdate: string = '';
  errNoUpdateDetails: string = '';
  errNoDoc: string = '';
  errNoDocDetails: string = '';
  errNoRecords: string = '';
  mailboxLink: string = '';
  readAction: string = '';
  archiveBannerHeading:string ='';
  archiveBannerMessage:string ='';
}

export const InboxMsgWidgetContentRef = PortalContentMock.addStructuredContent<InboxMsgWidgetItem>('InboxMsgWidgetItem', {
  
  inboxMsgComponent: {
    pageTitle:'Mailbox',  
    preferenceManage: "Manage letter and statement preferences",
    preferenceSwitch:"Switch to paperless: Manage letter and statement preferences",
    tab1Text: 'Inbox',
    tab2Text: 'Archive',
    selectText: 'Select all',
    menuHeader: 'Options',
    moveInboxText: 'Move to inbox',
    unreadText: 'Mark as unread',
    archiveText: 'Archive',
    viewLetterBtnText: 'View letter or statement',
    todayText: 'Today',
    errNoUpdate: 'Unable to mark as unread/archive/move to inbox',
    errNoUpdateDetails: 'We are sorry, we are not able to perform that action. Please try again later.',
    errNoDoc: 'Document unable to load or be retrieved',
    errNoDocDetails: 'We are sorry, we are not able to show the letter or statement right now. This is because of a technical error. Please try again later.​',
    errNoRecords: 'No documents to view',
    mailboxLink: 'Back to Mailbox',
    readAction: 'Please read and action',
    archiveBannerMessage:'what you see in your online account has changed.  However you can still view and download documents in your inbox for up to six years from the date your agreement ended.',
    archiveBannerHeading:'Your agreement with us has been ended'
  },
  brandContactDetails: {
    budgetToolLink:"",
    privacyPolicyLink:"",
    brandWiseFreeTRSNumber:"",
    brandWiseBankDetails: "",
    brandWiseAddress: "",
    brandWiseContactNumber : "",
    brandWiseContactEmail: "",
    brandWiseCfaNumber : "",
    portalName : "",
    mainCollectionsLineNumber : "",
    legalNumber : "",
    brandWiseAccessibilityNumber: ""
}
  
});