import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { Observable } from 'rxjs';
import { EndOfContractService } from '../../services/end-of-contract.service';
import { DatastoreService } from '../../../../service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-eoc-hp',
  templateUrl: './eoc-hp.component.html',
  providers: [ContentService]
})

export class EocHpComponent implements OnInit {

  accountDetails: any = {};
  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');
  endOfContractWidgetItem: EndOfContractItem = new EndOfContractItem();

  constructor(
    private router: Router,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    private datastoreService: DatastoreService
  ) {}

  ngOnInit(): void {
    this.tealiumService.view (
      { tealium_event:'EOC_HP', URI: '/dashboard#/eoc-options/eoc-hp' }
    );
    this.accountDetails = this.datastoreService.getAccountDetails();
    this.endOfContractWidgetItem$.subscribe((endOfContractWidgetItem: any) => {
        this.endOfContractWidgetItem = endOfContractWidgetItem;
    });
  }

  navigateToSettlement() {
    this.router.navigate(['/payments/payment-settlement'])
  }

}
