<div class="reg-container" *ngIf="registrationWidgetItem$ | async as registrationWidgetItem">
  <div class="title-section-page contact-number-text" role="alert" test_id="pageHeaderText">
    <bb-content-html [html]="registrationWidgetItem.noUser.pageHeaderText"></bb-content-html>
    <span *ngIf="registrationWidgetItem.brandContactDetails">
      <bb-content-html [html]="registrationWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html></span>
    <span><bb-content-html [html]="registrationWidgetItem.createAccountFaq.forHelpAndSupportText"></bb-content-html></span>
  </div>
  <div class="text-center">
    <button bbButton type="button" aria-label="Favourite" (click)="submit()"
      [className]="'wss-button'" test_id="tryAgainButtonText">{{registrationWidgetItem.noUser.tryAgainButtonText}}
    </button>
  </div>
  <div class="need-help-link text-center mb-25px" test_id="needHelpLink">
    <bb-content-html [html]="registrationWidgetItem.noUser.needHelpLink"></bb-content-html>
  </div>
</div>
