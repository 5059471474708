import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { DatastoreService } from '../../../../service/datastore.service';
import { BannerMessage } from '../../../../model/bannerMessage';
import { MessageService } from '../../../../service/message.service';
import { NextPayment } from '../../../../model/nextPayment';
import * as moment from 'moment';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { calculateDaysLeft } from '@wss/common/util/util';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'direct-debit',
    templateUrl: './direct-debit.component.html',
    providers: [ ContentService ]
})

export class DirectDebitComponent implements OnInit {

    accountDetail: any = {};
    activeDirectDebitFound: boolean = false;
    isWithIn3days: boolean = false;
    isAmendmentAllowed: boolean = true;
    bannerMessage: BannerMessage = new BannerMessage();
    nextPaymentData: NextPayment = new NextPayment();

    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
    paymentsWidgetItem: PaymentsWidgetItem = new PaymentsWidgetItem();
    daysLeftNextPayment: any;


    constructor(
        private router: RouterService,
        private datastoreService: DatastoreService,
        private changeDetectorRef: ChangeDetectorRef,
        private messageService: MessageService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService
        ) { }

    ngOnInit() {
        this.datastoreService.sessionInvalid();
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Direct Debit',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.accountDetail = this.datastoreService.getAccountDetails();
        this.nextPaymentData.next_payment_amount = this.accountDetail.next_payment_amount;
        this.nextPaymentData.next_payment_date = this.accountDetail.next_payment_date;
        this.activeDirectDebitFound = this.datastoreService.getActiveDirectDebitFound();
        this.daysLeftNextPayment = calculateDaysLeft(this.accountDetail.next_payment_date);
        this.isWithIn3days = this.accountDetail.is_dd_calling || (this.daysLeftNextPayment <= 3 && this.daysLeftNextPayment >= 0);
        this.paymentsWidgetItem$.subscribe((paymentsWidgetItem: any) => {
            this.paymentsWidgetItem = paymentsWidgetItem;
            this.isAmendmentAllowed = !this.accountDetail.is_dd_calling && !this.isAlreadyUpdatedToday();
            if (!this.isAmendmentAllowed || this.isWithIn3days) {
                this.showBannerMessage();
            }
        });
        this.messageService.setCurrentStep(0);
        this.changeDetectorRef.detectChanges();
    }

    addOrUpdateDebitDetail() {
        this.router.navigate(['add-direct-debit']);
    }

    isAlreadyUpdatedToday(): boolean {
        const today = moment().format('DD/MM/YYYY');
        let lastUpdated = localStorage.getItem("lastUpdated") || "";
        const loggedInAcct = this.datastoreService.getAccountNo();
        let prevAcct = "";
        if (lastUpdated.length !== 0) {
            prevAcct = lastUpdated.split("|")[1];
            lastUpdated = lastUpdated.split("|")[0];
        }
        if ((today === lastUpdated) && (loggedInAcct === prevAcct)) {
            return true;
        }
        else {
            return false
        }
    }

    showBannerMessage() {
        this.bannerMessage.closeIconClass = "clear";
        this.bannerMessage.materialIconClass = "error_outline";
        this.bannerMessage.iconPositionClass = "mgn-top-15px";
        this.bannerMessage.leftSectionColorClass = "message-failure";
        
        if (this.isWithIn3days) {
            this.bannerMessage.iconPositionClass = "margin-auto";
            this.bannerMessage.titleText = `${this.paymentsWidgetItem.directDebit.errorBannerWithIn3Days.titleTextBeforeData}`;
            this.bannerMessage.subtitleText = `${this.paymentsWidgetItem.directDebit.errorBannerWithIn3Days.subtitleTextBeforeData}`;
        }
        else {
            this.bannerMessage.titleText = `${this.paymentsWidgetItem.directDebit.errorBannerAlreadyModified.titleTextBeforeData}`;
            this.bannerMessage.subtitleText = `${this.paymentsWidgetItem.directDebit.errorBannerAlreadyModified.subtitleTextBeforeData}`;
        }
        this.bannerMessage.isBannerClosed = false;
        this.messageService.setBannerMessage(this.bannerMessage);
        this.changeDetectorRef.detectChanges();
    }
}