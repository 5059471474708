<div class="static-pages-header-wrapper" test_id="welcome-header">
    <div class="d-flex header-wrapper-login responsive-header">
      <div class="logo logo-login-style"></div>
      <div *ngIf="(portalName === 'landrover' || portalName === 'jaguar' )" class="logo-text"></div>
    </div>

    <div class="static-pages-widget" *ngIf="termsConditionsWidgetItem$ | async as termsConditionsWidgetItem">
        <div class="static-page-container">
            <div class="section-title-container static-page-title">
                <span class="section-title" test_id="cookiesPolicyPageTitle">
                    {{termsConditionsWidgetItem.termsAndConditions.pageTitle}}</span>
            </div>
            <div class="terms-intro">
                <bb-content-html [html]="termsConditionsWidgetItem.termsAndConditionsIntro"></bb-content-html>
            </div>
            <div class="tc white-bg-container sp-white-bg-container">
                <bb-content-html [html]="termsConditionsWidgetItem.termsAndConditions.termsAndConditionsContent"></bb-content-html>
            </div>
        </div>
    </div>
</div>