<div class="payment-holiday-container term-ext-container" *ngIf="paymentHolidayWidgetItem$ | async as paymentHolidayWidgetItem">
    <div class="container-row" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="container-column" test_id="pageText">
        <div class="container-row section-title-container">
            <span class="section-title" test_id="pageName">{{paymentHolidayWidgetItem.finalizeAgreement.pageName}}</span>
        </div>
        <bb-content-html [html]="paymentHolidayWidgetItem.finalizeAgreement.pageText"></bb-content-html>
        <div class="responsive-row-term">
            <div class="container-column" test_id="sectionTextBeforeAccordion">
                <bb-content-html [html]="paymentHolidayWidgetItem.finalizeAgreement.sectionTextBeforeAccordion"></bb-content-html>
                <br>
                <div class="container-row expansion-border-hidden">
                    <mat-accordion class="accordion mat-accordion-override w-100 finalize" role="accordion">
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override" test_id="accordionContent">
                            <mat-expansion-panel-header class="mat-panel-header-override">
                                <mat-panel-title class="mat-expansion-panel-header-title-override" test_id="accordionTitle">
                                    {{paymentHolidayWidgetItem.finalizeAgreement.accordionTitle}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <bb-content-html [html]="paymentHolidayWidgetItem.finalizeAgreement.accordionContent"></bb-content-html>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            <div class="container-column" test_id="sectionTextAfterAccordion">
                <bb-content-html [html]="paymentHolidayWidgetItem.finalizeAgreement.sectionTextAfterAccordion"></bb-content-html>
            </div>


        </div>
        <br>
        <div class="responsive-row-term">
            <div class="container-column" test_id="letterConfirmationNote">
                <div class="container-column email-document">
                    <div class="container-column inner-container">
                        <div class="container-column paragraph" test_id="textCustomerEmail">
                            <bb-content-html [html]="paymentHolidayWidgetItem.finalizeAgreement.textBeforeCustomerEmail"></bb-content-html>
                            {{loggedInEmail}}
                            <bb-content-html [html]="paymentHolidayWidgetItem.finalizeAgreement.textAfterCustomerEmail"></bb-content-html>
                        </div>
                        <div class="container-column mt-19px">
                            <form [formGroup]="emailForm" class="form" role="form" autocomplete="off">
                                <bb-input-radio-group-ui [preselect]="false" [required]="true"
                                    formControlName="emailToBeSent" test_id="radioLabels">
                                    <bb-input-radio-ui value="Yes" label="{{paymentHolidayWidgetItem.finalizeAgreement.radioLabelYes}}" aria-labelledby="{{paymentHolidayWidgetItem.finalizeAgreement.radioLabelYes}}" accesskey="y"
                                        tabindex="0"></bb-input-radio-ui>
                                    <bb-input-radio-ui value="No" label="{{paymentHolidayWidgetItem.finalizeAgreement.radioLabelNo}}" aria-labelledby="{{paymentHolidayWidgetItem.finalizeAgreement.radioLabelNo}}" accesskey="n"
                                        tabindex="1"></bb-input-radio-ui>
                                </bb-input-radio-group-ui>
                                <div class="container-column mt-23px email-field"
                                    *ngIf="emailForm?.value?.emailToBeSent === 'No'">
                                    <div class="form-group finalize-email-form" test_id="emailAddressLabel">
                                        <label>{{paymentHolidayWidgetItem.finalizeAgreement.emailAddressLabel}}</label>
                                        <input type="text" class="form-control  rounded-2" formControlName="email"
                                            placeholder="{{paymentHolidayWidgetItem.finalizeAgreement.emailAddressPlaceholder}}" accesskey="e" [ngClass]="{ 'is-invalid': (emailForm.value.emailToBeSent === 'No') && f.email.errors && f.email.touched }">
                                            <div *ngIf="(emailForm.value.emailToBeSent === 'No') && f.email.errors && f.email.touched && f.email.touched" class="invalid-feedback para-inline">
                                                <div *ngIf="f.email.errors.required">
                                                    <bb-content-html [html]="paymentHolidayWidgetItem.finalizeAgreement.emailRequiredErrorMsg"></bb-content-html>
                                                </div>
                                                <div *ngIf="f.email.errors.email">
                                                    <bb-content-html [html]="paymentHolidayWidgetItem.finalizeAgreement.emailInvalidErrorMsg"></bb-content-html>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="form-group finalize-email-form" test_id="cEmailAddressLabel">
                                        <label>{{paymentHolidayWidgetItem.finalizeAgreement.cEmailAddressLabel}}</label>
                                        <input type="text" class="form-control  rounded-2"
                                            formControlName="comfirmEmail" placeholder="{{paymentHolidayWidgetItem.finalizeAgreement.cEmailAddressPlaceholder}}"
                                            accesskey="d" [ngClass]="{ 'is-invalid': (emailForm.value.emailToBeSent === 'No') && f.comfirmEmail.errors && f.comfirmEmail.touched }">
                                            <div *ngIf="(emailForm.value.emailToBeSent === 'No') && f.comfirmEmail.errors && f.comfirmEmail.touched" class="invalid-feedback para-inline">
                                                <div *ngIf="f.comfirmEmail.errors.required">
                                                    <bb-content-html [html]="paymentHolidayWidgetItem.finalizeAgreement.cEmailRequiredErrorMsg"></bb-content-html>
                                                </div>
                                                <div *ngIf="f.comfirmEmail.errors.mustMatch">
                                                    <bb-content-html [html]="paymentHolidayWidgetItem.finalizeAgreement.cEmailMustMatchErrorMsg"></bb-content-html>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <bb-content-html class="mt-34px" [html]="paymentHolidayWidgetItem.finalizeAgreement.letterConfirmationNote"></bb-content-html>
            </div>
            <div class="container-column" test_id="applyTermSectionTitle">
                <bb-content-html class="mt-20px" [html]="paymentHolidayWidgetItem.finalizeAgreement.applyTermSectionTitle"></bb-content-html>
                <div class="container-column apply-for-term">
                    <div class="container-column inner" test_id="applyTermSectionContent">
                        <bb-content-html [html]="paymentHolidayWidgetItem.finalizeAgreement.applyTermSectionContent"></bb-content-html>
                        <div class="container-row mt-28px">
                            <div (click)="checkbox.checked = !checkbox.checked"
                                [ngClass]="checkbox?.checked? 'custom-checkbox-debit': 'custom-checkbox-debit-unchecked'"
                                role="checkbox" tabindex="0">
                                <span></span>
                            </div>
                            <span class="checkbox-notes" test_id="mandatoryCheckboxAgreeText">
                                <bb-content-html [html]="paymentHolidayWidgetItem.finalizeAgreement.mandatoryCheckboxAgreeText"></bb-content-html>
                            </span>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div class="container-row apply-term mb-90px">
            <ng-container *ngIf="(emailForm.value.emailToBeSent === '') || (emailForm.value.emailToBeSent === 'Yes')">
                <button role="button" tabindex="0" class="wss-button" id="applyBtnId"
                [disabled]="!checkbox.checked || (emailForm.value.emailToBeSent === '')"
                (click)="applyForTermExt(processingContent)">{{paymentHolidayWidgetItem.finalizeAgreement.applyTermBtnText}}</button>
            </ng-container>

            <ng-container *ngIf="emailForm.value.emailToBeSent === 'No'">
                <button role="button" tabindex="0" class="wss-button" id="applyBtnId"
                [disabled]="!(checkbox.checked && emailForm.valid)"
                (click)="applyForTermExt(processingContent)">{{paymentHolidayWidgetItem.finalizeAgreement.applyTermBtnText}}</button>
            </ng-container>
            
        </div>
    </div>
    <ng-template #processingContent let-modal>
        <div class="modal-header horse-logo">
            <span class="model-logo-img"></span>
        </div>
        <div class="modal-body loader-modal" role="dialog">
            <div class="loader"></div>
            <div class="processing-content-text" test_id="termExtensionRequestProcessingText">
                <bb-content-html [html]="paymentHolidayWidgetItem.finalizeAgreement.termExtensionRequestProcessingText"></bb-content-html>
            </div>
        </div>
        <div class="modal-footer">
        </div>
    </ng-template>
</div>



<!-- 
    <div class="payment-holiday-container term-ext-container">
    <div class="container-row" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="container-column">
        <div class="container-row section-title-container">
            <span class="section-title">Term Extension</span>
        </div>
        <span class="mt-35px">Before you apply, please take time to consider the details on this page.</span>
        <br>
        <div class="responsive-row-term">
            <div class="container-column">
                <span class="text-semibold text-bigger">We will run a credit search</span>
                <span class="mt-16px">We will run a search on your credit file to understand your financial history.
                    This will
                    help us understand if a Term Extension is right for you.</span>
                <br>
                <span>The Credit Reference Agency will then record a search on your credit file and other lenders will
                    be able
                    to see this credit search. Lenders may take credit searches into account when making lending
                    decisions.</span>
                <br>
                <div class="container-row expansion-border-hidden">
                    <mat-accordion class="accordion mat-accordion-override finalize" role="accordion">
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header class="mat-panel-header-override">
                                <mat-panel-title class="mat-expansion-panel-header-title-override">
                                    How we use credit reference agencies
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>To process your application, we will supply your personal information to Credit Reference
                                Agencies, including how you use our products and services, and they will give us
                                information
                                about you, such as about your financial history.</p>
                            <p>We do this to assess creditworthiness and product suitability, check your identity,
                                manage your
                                account, trace and recover debts and prevent criminal activity.</p>
                            <p>You can find out more information about how we work with outside companies, the
                                identities of the
                                credit reference agencies, and the ways in which they use and share personal
                                information, in our
                                <a title="full data privacy notice" tabindex="0" role="link">full data privacy
                                    notice</a>.</p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            <div class="container-column">
                <span class="text-semibold text-bigger">Affordability</span>
                <span class="mt-21px">A Term Extension will permanently change your monthly payment amount. You will
                    need to
                    sign an agreement to agree to these changes. You should only apply for a Term Extension if you are
                    sure that
                    you can make the monthly payments shown in the quote for the rest of your agreement term.</span>
                <br>
                <span class="text-semibold text-bigger">How we will send the agreement to you</span>
                <span class="mt-21px">We'll send the Term Extension documents to you by email*. You'll need to read,
                    sign and
                    email the documents to us. If you're not able to send the documents back by email, please call us so
                    we can
                    discuss other options.</span>
                <br>
                <span>If you do not want to receive the documents by email, please call us so we can discuss other
                    options.</span>
            </div>


        </div>
        <br>
        <div class="responsive-row-term">
            <div class="container-column">
                <div class="container-column email-document">
                    <div class="container-column inner-container">
                        <p>The email address we have for you is: {{customerDetail?.contact_details?.email_address}}</p>
                        <span class="text-semibold">Can we send the new agreement to this email address?</span>
                        <div class="container-column mt-19px">
                            <form [formGroup]="emailForm" class="form" role="form" autocomplete="off">
                                <bb-input-radio-group-ui [preselect]="false" [required]="true"
                                    formControlName="emailToBeSent">
                                    <bb-input-radio-ui value="Yes" label="Yes" aria-labelledby="'Yes'" accesskey="y"
                                        tabindex="0"></bb-input-radio-ui>
                                    <bb-input-radio-ui value="No" label="No" aria-labelledby="'No'" accesskey="n"
                                        tabindex="1"></bb-input-radio-ui>
                                </bb-input-radio-group-ui>
                                <div class="container-column mt-23px email-field"
                                    *ngIf="emailForm?.value?.emailToBeSent === 'No'">
                                    <div class="form-group finalize-email-form">
                                        <label>Email address</label>
                                        <input type="text" class="form-control  rounded-2" formControlName="email"
                                            placeholder="eg. name@domain.co.uk" accesskey="e">
                                    </div>
                                    <div class="form-group finalize-email-form">
                                        <label>Confirm email address</label>
                                        <input type="text" class="form-control  rounded-2"
                                            formControlName="comfirmEmail" placeholder="eg. name@domain.co.uk"
                                            accesskey="d">
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <span class="mt-34px">*If your agreement is not regulated by the Consumer Credit Act, you will receive a
                    Term Extension confirmation letter by post. You will need to sign the amendment confirmation slip
                    and return to us.</span>
            </div>
            <div class="container-column">
                <span class="text-semibold text-bigger mt-20px">Apply for a Term Extension</span>
                <div class="container-column apply-for-term mt-32px">
                    <div class="container-column inner">
                        <span class="text-semibold">By checking the box below, I understand and agree that:</span>
                        <ul class="mt-16px" role="list">
                            <li role="listitem">My details will be shared with credit reference agencies. <a
                                    title="Read our full Privacy Notice" role="link" tabindex="0">Read our full Privacy
                                    Notice</a></li>
                            <br>
                            <li role="listitem">I can make the monthly payments shown in the quote for the rest of the
                                agreement term. I also confirm that I do not know of any future change in circumstances
                                that will impact my ability to make the payments (examples of changes in circumstances
                                include retirement, redundancy, reduction in working hours/salary, career change or
                                change in family circumstances).</li>
                            <br>
                            <li role="listitem">I have read all the details above and wish to apply for a Term
                                Extension.</li>
                        </ul>
                        <div class="container-row mt-28px">
                            <div (click)="checkbox.checked = !checkbox.checked"
                                [ngClass]="checkbox?.checked? 'custom-checkbox-debit': 'custom-checkbox-debit-unchecked'"
                                role="checkbox" tabindex="0">
                                <span></span>
                            </div>
                            <span class="checkbox-notes">
                                I understand and agree.
                            </span>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div class="container-row apply-term">
            <button role="button" tabindex="0" class="wss-button" id="applyBtnId"
                [disabled]="!(checkbox.checked && (emailForm.value.emailToBeSent !== ''))"
                (click)="applyForTermExt(processingContent)">Apply for a Term Extension</button>
        </div>
        <div class="flex-horizontal-center mb-90px">
            <span><a (click)="cancel()" title="Cancel" role="navigation" tabindex="0">Cancel</a></span>
        </div>
    </div>
</div>

<ng-template #processingContent let-modal>
    <div class="modal-header horse-logo">
        <span class="model-logo-img"></span>
    </div>
    <div class="modal-body loader-modal" role="dialog">
        <div class="loader"></div>
        <div class="processing-content-text">
            We are connecting to the Credit Reference Agency. This may take up to 30 seconds.
        </div>
    </div>
    <div class="modal-footer">
    </div>
</ng-template>
 -->