import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {PageConfig, PAGE_CONFIG} from '@backbase/foundation-ang/web-sdk';
import {environment} from '../environments/environment';
 
@Injectable()
export class AppInitService {
 
  constructor(
    @Inject (DOCUMENT) private document: Document,
    @Inject (PAGE_CONFIG) private pageConfig: PageConfig
  ) {}
    
  Init() {
    return new Promise((resolve, reject) => {
      this.loadTheme(
        `${this.pageConfig.staticResourcesRoot}/${environment.assetsStaticItemName}/dist/${this.pageConfig.portalName}.css`
      );
      resolve(true);
    })
  }

  loadTheme(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.rel = 'stylesheet';
    style.href = `${styleName}`
    head.appendChild(style);
  }
  
}