<section id="brg-decline" *ngIf="brgDeclineItem$ | async as brgDeclineItem" class="balloon-refinance">
    <div class="reg-row plr-10pt" role="alert" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="container-column">
        <section class="content-width-max">
            <div class="container-column card">
                <div class="text-center section-seperator pt-20px">
                    <div class="warning-icon-with-border mb-10px"></div>
                    <h2 class="content-bold-high">
                        {{brgDeclineItem.balloonRefinanceGetDetailsDeclined.declineHeader}}
                    </h2>
                </div>
                <div class="p-20px decline-screen-policy-mb-0" *ngIf="declineReason === 'P'">
                    <bb-content-html [html]="brgDeclineItem.balloonRefinanceGetDetailsDeclined.declineParaPolicy"></bb-content-html>                                                        
                </div>
                <div class="p-20px decline-screen-policy-mb-0" *ngIf="declineReason === 'A'">
                    <bb-content-html [html]="brgDeclineItem.balloonRefinanceGetDetailsDeclined.declineParaBureau"></bb-content-html>                                       
                </div>
                <div class="p-20px decline-screen-policy-mb-0" *ngIf="declineReason === 'B'">
                    <bb-content-html [html]="brgDeclineItem.balloonRefinanceGetDetailsDeclined.declineParaAfford"></bb-content-html>                                       
                </div>
                <div class="p-20px decline-screen-policy-mb-0" *ngIf="declineReason === 'I'">
                    <bb-content-html [html]="brgDeclineItem.balloonRefinanceGetDetailsDeclined.declineParaAuth"></bb-content-html>                                       
                </div>                
            </div>
            <div class="mt-35px">
                <h2>{{brgDeclineItem.balloonRefinanceGetDetailsDeclined.nextStepsHeader}}</h2>
                <div *ngIf="(declineReason === 'P') || (declineReason === 'A') || (declineReason === 'B')">
                    <bb-content-html [html]="brgDeclineItem.balloonRefinanceGetDetailsDeclined.nextStepsText"></bb-content-html>
                </div>
                <div *ngIf="declineReason === 'I'">                 
                    <bb-content-html [html]="brgDeclineItem.balloonRefinanceGetDetailsDeclined.nextStepsAuthText"></bb-content-html>
                </div>
                <button class="wss-button mt-2px" id="wss-brg-decline-other-options-btn" (click)="viewOtherOptions()">
                    {{brgDeclineItem.balloonRefinanceGetDetailsDeclined.otherOptions}}
                </button>
            </div> 
            <div>
                <h2 class="mb-15px">{{brgDeclineItem.balloonRefinanceGetDetailsDeclined.accordianHeaderText}}</h2>
            </div>
            <div class="br-tp-grey" *ngIf="declineReason === 'I'">
                <div id="updateOptions" class="container-row expansion-border-hidden transparent-accordion top-border-none">
                    <mat-accordion class="accordion mat-accordion-override w-100">
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="mat-expansion-panel-header-title-override" id="updateOptionsTitle">
                                    <bb-content-html [html]="brgDeclineItem.balloonRefinanceGetDetailsDeclined.updateOptionsTitle">
                                    </bb-content-html>                                        
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div id="otherOptionsContent">
                                <bb-content-html [html]="brgDeclineItem.balloonRefinanceGetDetailsDeclined.updateOptionsText">
                                </bb-content-html>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>        
            <div class="w-100" id="brgDeclineAccordionsCont">
                <div id="brgDeclineAccordionsMoneyWorriesClickable" class="container-row expansion-border-hidden transparent-accordion balloon-refinance-accordion-header balloon-refinance-accordion-header-color" (click)="updateCtAgency()">
                    <bb-common-expansion-panel [accordionContent]="brgDeclineItem.balloonRefinanceGetDetailsDeclined.declineAccordions" [isDeclinePage]="isDeclinePage"
                        testIDText="brgDeclineAccordions" class="w-100">
                    </bb-common-expansion-panel>
                </div>
            </div>
            <div>
                <div id="moneyWorry" class="container-row expansion-border-hidden transparent-accordion mb-5 top-border-none balloon-refinance-accordion-header balloon-refinance-accordion-header-color">
                    <mat-accordion class="accordion mat-accordion-override w-100">
                        <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override"
                        (opened)="openMoneyWorry = true" (closed)="openMoneyWorry = false" [expanded]="openMoneyWorry">
                            <mat-expansion-panel-header id="brgDeclineAccordionsContClickable" (click)="updateCtAgency()">
                                <mat-panel-title class="mat-expansion-panel-header-title-override" id="moneyWorryTitle">
                                    <bb-content-html [html]="brgDeclineItem.balloonRefinanceGetDetailsDeclined.moneyWorryTitle">
                                    </bb-content-html>                                        
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div id="moneyWorryContent">
                                <bb-content-html [html]="brgDeclineItem.balloonRefinanceGetDetailsDeclined.moneyWorryText">
                                </bb-content-html>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </section>
    </div>
</section>