import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { HelpCentreItem } from '@wss/model/helpCentre';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'bb-complaints',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './complaints.component.html',
    styles: [],
    providers: [ContentService]
})

export class ComplaintsComponent implements OnInit {
    getInTouchFlag: boolean = true;
    helpCentreWidgetItem: any = new HelpCentreItem();
    helpCentreWidgetItem$: Observable<HelpCentreItem | undefined> = this.bbContentService.getContent<HelpCentreItem>('helpCentreWidgetItem');
    
    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private readonly bbContentService: ContentService,
        private router: Router,
        private tealiumService: TealiumUtagService
    ) { }

    ngOnInit(): void {
        this.tealiumService.view(
        {
            JourneyName: 'Help Centre',
            JourneyStepName: 'Complaints',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
        });
        this.helpCentreWidgetItem$.subscribe((pageItem: any) => {
            this.helpCentreWidgetItem = pageItem;
            this.changeDetectorRef.detectChanges();
        });
    }
}    
