<div class="payment-column" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
    <div class="payment-result-container" *ngIf="!paymentLockout">
        <div class="container-row arrear-box-height margin-top-10" *ngIf="showSMSConfirmFlag">
            <div class="arrear-warning-box">
                <span
                    class="material-icons material-icons-color-arrear arrear-icons-font-size margin-top-20">check_circle_outline</span>
            </div>
            <div class="container-row arrear-result-text-context">
                <div class="container-row arrear-text-margin">
                    <strong class="arrear-flex-width" test_id="arrear_msg_id">
                        {{paymentsWidgetItem.paymentResult.paymentResultSuccessMessageText}}
                    </strong>
                    <span class="material-icons arrear-icons-font-size arrear-flex-width-clear-icon mb-28"
                        (click)="closeSMSInfo('success')">clear</span>
                </div>
            </div>
        </div>
        <div class="container-row arrear-box-height margin-top-10" *ngIf="showSMSNotificationError">
            <div class="arrear-error-box">
                <span
                    class="material-icons material-icons-color-arrear arrear-icons-font-size margin-top-20 error-icon payment-response-icon"></span>
            </div>
            <div class="container-row arrear-result-text-context">
                <div class="container-row arrear-text-margin">
                    <strong class="arrear-flex-width" test_id="arrear_msg_id">{{smsNotificationErrorMessage}}.</strong>
                    <span class="material-icons arrear-icons-font-size arrear-flex-width-clear-icon mb-28"
                        (click)="closeSMSInfo('error')">clear</span>
                </div>
            </div>
        </div>

        <!-- Payment Success Transaction-->
        <div class="container-column payment-result-content margin-bottom-result"
            *ngIf="showResultSection === 'successResult' && showPaymentResultContent && showPaymentSuccessMessage"
            aria-describedby="paymentSuccessInfo">
            <div class="payment-result-section result-page">
                <div class="trophy-icon-div">
                    <span class="wss-success-icon success-icon" alt="Trophy Icon"></span>
                </div>
                <div class="payment-result-text" *ngIf="!settlementDirectionFlag">
                    <b test_id="paymentSuccessConfirmationText">
                        <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentSuccessConfirmationText">
                        </bb-content-html>
                    </b>
                </div>
                <div class="payment-result-text" *ngIf="settlementDirectionFlag">
                    <b test_id="congratulationText">
                        <bb-content-html [html]="paymentsWidgetItem.paymentResult.congratulationText"></bb-content-html>

                    </b>
                </div>
                <div class="payment-result-info-text para-inline" test_id="settlementPaymentInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.settlementPaymentInfoText">
                    </bb-content-html>
                </div>
                <div class="payment-result-info-text para-inline" test_id="settlementPaymentDurationInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.settlementPaymentDurationInfoText">
                    </bb-content-html>
                </div>
            </div>
            <div class="container-cloumn result-middle-section border-bottom">
                <div class="payment-result-middle-item border-right border-bottom">
                    <strong test_id="payment_ref_label_id">
                        <bb-content-html [html]="paymentsWidgetItem.paymentResult.payableAmountLableText">
                        </bb-content-html>
                    </strong>
                    <strong>£ {{paymentTransactionDetail?.transaction_value}}</strong>
                </div>
                <div class="payment-result-middle-item border-left border-bottom">
                    <strong test_id="payment_ref_label_id">
                        <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentReferenceLabelText">
                        </bb-content-html>
                    </strong>
                    <strong>
                        {{paymentTransactionDetail?.receipt_number}}
                    </strong>
                </div>
            </div>
            <div class="container-row payment-result-bottom-info-text result-bottom-height txt-dif-view"
                *ngIf="!settlementDirectionFlag" test_id="paymentMailConfirmationInfoText">
                <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentMailConfirmationInfoText">
                </bb-content-html>
            </div>
            <div class="container-row payment-result-bottom-info-text settle-result-bottom-height txt-dif-view"
                *ngIf="settlementDirectionFlag" test_id="settlementPaymentConfirmationMailInfoText">
                <bb-content-html [html]="paymentsWidgetItem.paymentResult.settlementPaymentConfirmationMailInfoText">
                </bb-content-html>
            </div>
            <div class="container-row content-center container-dif-view">
                <button role="button" class="wss-button back-to-account-btn" test_id="back_account_id"
                    (click)="backToMyAccountClick()"
                    title="{{paymentsWidgetItem.paymentResult.backMyAccountButtonText}}">
                    {{paymentsWidgetItem.paymentResult.backMyAccountButtonText}}
                </button>
            </div>
            <!-- smsSendModal -->
            <div class="container-row content-center container-dif-view" *ngIf="!settlementDirectionFlag">
                <span role="navigation" class="send-sms-link" aria-label="SMS Link Payment"><a test_id="send_sms_id" tabindex="0"
                        (click)="onSmsClick()" title="{{paymentsWidgetItem.paymentResult.smsLinkText}}">
                        {{paymentsWidgetItem.paymentResult.smsLinkText}}
                    </a></span>

            </div>
            <div class="container-row content-center container-dif-view" *ngIf="settlementDirectionFlag">
                <span role="navigation" class="send-sms-link" aria-label="SMS Link on Settlement"><a test_id="send_sms_id" tabindex="0"
                        (click)="onSmsClick()" title="{{paymentsWidgetItem.paymentResult.smsLinkTextForSettlement}}">
                        {{paymentsWidgetItem.paymentResult.smsLinkTextForSettlement}}
                    </a></span>
            </div>
            <!-- smsSendModal -->
        </div>
        <!-- Payment Success Transaction-->

        <!-- Payment Transaction Limit Warning -->
        <div class="container payment-warning-content warning-container"
            *ngIf="showResultSection === 'warningResult' && showPaymentResultContent"
            aria-describedby="warningPaymentInfo">
            <div class="payment-warning-icon-div">
                <span class="wss-warning-icon warning-icon" alt="Warning Icon"></span>
            </div>
            <div *ngIf="paymentsWidgetItem.paymentResult.makePaymentHeaderText">
                <strong class="payment-warning-info margin-top-20" test_id="makePaymentHeaderText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.makePaymentHeaderText">
                    </bb-content-html>
                </strong>
            </div>
            <div *ngIf="paymentsWidgetItem.paymentResult.paymentTransactionLimitWarningInfoText">
                <strong class="payment-warning-info margin-top-20" test_id="paymentTransactionLimitWarningInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentTransactionLimitWarningInfoText">
                    </bb-content-html>
                </strong>
            </div>
            <div *ngIf="paymentsWidgetItem.paymentResult.nextPaymentDurationInfoText">
                <span class="payment-warning-info2" test_id="nextPaymentDurationInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.nextPaymentDurationInfoText">
                    </bb-content-html>
                </span>
            </div>
            <div class="container-row content-center">
                <button role="button" class="wss-button btn-width warn-btn-dif-view" test_id="make_payment_id"
                    (click)="backToMyAccountClick()" title="Back to My account">
                    {{paymentsWidgetItem.paymentResult.goMyAccountButtonInfoText}}
                </button>
            </div>
        </div>
        <!-- Transaction Limit Warning -->

        <!-- Unsuccess Payment Transaction -->
        <div class="container payment-warning-content warning-container"
            *ngIf="showResultSection === 'unsuccessResult' && showPaymentResultContent"
            aria-describedby="unsuccessPaymentInfo">
            <div *ngIf="paymentSuspendedEarlier">
                <div class="payment-warning-icon-div">
                    <span class="wss-warning-icon warning-icon" alt="Warning Icon"></span>
                </div>
                <div *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierLabelText">
                    <strong class="payment-warning-info margin-top-20"
                        test_id="paymentLockoutSuspendedEarlierLabelText">
                        <bb-content-html
                            [html]="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierLabelText">
                        </bb-content-html>
                    </strong>
                </div>
                <div class="align-center"
                    *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierInfoText">
                    <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                        test_id="paymentLockoutSuspendedEarlierInfoText">
                        <bb-content-html
                            [html]="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierInfoText">
                        </bb-content-html>
                    </span>
                </div>
                <div class="align-center"
                    *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierPaymentFromText">
                    <span class="overpayment-warning-info overpayment-info-text account-ineligible-info para-inline"
                        test_id="paymentLockoutSuspendedEarlierPaymentFromText">
                        <bb-content-html
                            [html]="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierPaymentFromText">
                        </bb-content-html>
                        {{ accountDetail.date_suspended_to }}
                    </span>
                </div>
                <div class="align-center"
                    *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierHelpSupportText">
                    <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                        test_id="paymentLockoutSuspendedEarlierHelpSupportText">
                        <bb-content-html
                            [html]="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierHelpSupportText">
                        </bb-content-html>
                    </span>
                </div>
                <div class="container-row content-center">
                    <button role="button" class="wss-button btn-width warn-btn-dif-view button-width-padding"
                        test_id="backToHomeButtonText" (click)="backToMyAccountClick()"
                        title="{{paymentsWidgetItem.paymentResult.backToHomeButtonText}}">
                        {{paymentsWidgetItem.paymentResult.backToHomeButtonText}}
                    </button>
                </div>
            </div>
            <div class="payment-unsuccessful-message" *ngIf="paymentUnsuccessfulDeclineInfo">
                <div class="payment-warning-icon-div">
                    <span class="wss-failure-icon failure-icon" alt="Failure Icon"></span>
                </div>

                <div class="container-row content-center">
                    <strong class="payment-warning-info" test_id="paymentUnsuccessLableText">
                        <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentUnsuccessLableText">
                        </bb-content-html>
                    </strong>
                </div>
                <div class="container-row content-center">
                    <span class="payment-warning-info" test_id="paymentOnlineInfoText">
                        <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentOnlineInfoText">
                        </bb-content-html>
                    </span>
                </div>
                <div class="container-row content-center">
                    <span class="payment-warning-info" test_id="paymentFailureInfoText">
                        <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentFailureInfoText">
                        </bb-content-html>
                    </span>
                </div>

                <div class="container-row content-center">
                    <button role="button" class="wss-button btn-dif-view btn-dif-margin" (click)="makeAPaymentClick()"
                        test_id="make_payment_id" title="Make a Payment" test_id="makePaymentLableText">
                        {{paymentsWidgetItem.paymentResult.makePaymentLableText}}
                    </button>
                </div>
                <div class="container-row content-center">
                    <span role="navigation" class="other-way-pay-link other-link-dif-view other-link-margin"
                        title="Other way to pay" tabindex="0">
                        <span test_id="otherWaytoPayLinkText">
                            <bb-content-html [html]="paymentsWidgetItem.paymentResult.otherWaytoPayLinkText">
                            </bb-content-html>
                        </span>
                    </span>
                </div>

                <div class="container-row content-center margin-bottom-other">
                    <span role="navigation" class="other-way-pay-link help-link-dif-view help-link-margin help-links"
                        tabindex="0" title="Need help?">
                        <span test_id="needHelpLinkText">
                            <bb-content-html [html]="paymentsWidgetItem.paymentResult.needHelpLinkText">
                            </bb-content-html>
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <!-- Unsuccess Payment Transaction -->

        <!-- Cancel Payment Transaction -->
        <div class="container payment-warning-content warning-container"
            *ngIf="showResultSection === 'cancelledResult' && showPaymentResultContent"
            aria-describedby="cancelPaymentInfo">
            <div class="payment-warning-icon-div">
                <span class="wss-warning-icon failure-icon" alt="Failure Icon"></span>
            </div>
            <div class="container-row content-center">
                <div class="payment-warning-info mt-10px" test_id="paymentCancelInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentCancelInfoText">
                    </bb-content-html>
                </div>
            </div>
            <div class="container-row content-center">
                <button role="button" class="wss-button btn-dif-view" (click)="makeAPaymentClick()"
                    test_id="make_payment_id" title="Make a Payment">
                    {{paymentsWidgetItem.paymentResult.makeAPaymentButtonText}}
                </button>
            </div>
            <div class="container-row content-center">
                <button role="button" class="wss-button btn-dif-view" test_id="make_payment_id"
                    (click)="backToMyAccountClick()" title="Back to My account">
                    {{paymentsWidgetItem.paymentResult.goMyAccountButtonInfoText}}
                </button>
            </div>
        </div>
        <!-- Cancel Payment Transaction -->

        <!-- Invalid Card Payment Transaction -->
        <div class="container payment-warning-content warning-container"
            *ngIf="showResultSection === 'invalidCardType' && showPaymentResultContent"
            aria-describedby="cancelPaymentInfo">
            <div class="payment-warning-icon-div">
                <span class="wss-warning-icon failure-icon" alt="Failure Icon"></span>
            </div>
            <div class="container-row content-center"
                *ngIf="paymentsWidgetItem.paymentResult.invalidCardPaymentInfoText">
                <div class="payment-warning-info mt-10px" test_id="invalidCardPaymentInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.invalidCardPaymentInfoText">
                    </bb-content-html>
                </div>
            </div>
            <div class="container-row content-center"
                *ngIf="paymentsWidgetItem.paymentResult.invalidCardPaymentButtonText">
                <button role="button" class="wss-button btn-dif-view" (click)="makeAPaymentClick()"
                    test_id="invalidCardPaymentButtonText" title="Make a Payment">
                    {{paymentsWidgetItem.paymentResult.invalidCardPaymentButtonText}}
                </button>
            </div>

            <div class="container-row content-center margin-bottom-other"
                *ngIf="paymentsWidgetItem.paymentResult.invalidCardPaymentNeedHelpText">
                <span role="navigation" class="other-way-pay-link  help-link-dif-view help-link-margin text-center"
                    tabindex="0" title="Need help?" aria-label="navigationLinks">
                    <span test_id="needHelpLinkText">
                        <bb-content-html [html]="paymentsWidgetItem.paymentResult.invalidCardPaymentNeedHelpText">
                        </bb-content-html>
                    </span>
                </span>
            </div>
        </div>
        <!-- Invalid Card Payment Transaction -->

        <!-- Payment Generic Error -->
        <div class="container payment-warning-content warning-container"
            *ngIf="showResultSection === 'genericError' && showPaymentResultContent"
            aria-describedby="cancelPaymentInfo">
            <div class="payment-warning-icon-div">
                <span class="wss-warning-icon warning-icon" alt="Warning Icon"></span>
            </div>
            <div>
                <strong class="payment-warning-info margin-top-10" test_id="paymentGenericErrorLabelText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentGenericErrorLabelText">
                    </bb-content-html>
                </strong>
            </div>
            <div class="align-center">
                <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                    test_id="paymentGenericErrorInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentGenericErrorInfoText">
                    </bb-content-html>
                </span>
            </div>
            <div class="container-row content-center">
                <button id="backToPaymentsButton" role="button"
                    class="wss-button btn-width warn-btn-dif-view button-width-padding" test_id="makeAPaymentButtonText"
                    (click)="makeAPaymentClick()" title="{{paymentsWidgetItem.paymentResult.makeAPaymentButtonText}}">
                    {{paymentsWidgetItem.paymentResult.makeAPaymentButtonText}}
                </button>
            </div>
        </div>
        <!-- Payment Generic Error -->

        <!-- Overpayment twice Warning -->
        <div class="container payment-warning-content warning-container" *ngIf="paymentMadeToday"
            aria-describedby="warningPaymentInfo">
            <!-- overpayment-warning-content -->
            <div class="payment-warning-icon-div">
                <span class="wss-announcement-icon announcement-icon" alt="Announcment Icon"></span>
            </div>
            <div class="container-row content-center">
                <strong class="payment-warning-info warning-top" test_id="overpaymentPaymentInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.overpaymentPaymentInfoText">
                    </bb-content-html>
                </strong>
            </div>
            <div class="container-row content-center">
                <button role="button" class="wss-button btn-width warn-btn-dif-view" test_id="make_payment_id"
                    (click)="backToMyAccountClick()" title="Back To Home">
                    {{paymentsWidgetItem.paymentResult.backToHomeButtonText}}
                </button>
            </div>
        </div>
        <!-- Overpayment twice Warning -->
    </div>

    <div class="payment-lockout-exceptions" *ngIf="paymentLockout">
        <!-- Payment lockout suspended earlier message -->
        <div class="container payment-warning-content warning-container payment-suspended-earlier-warning-content"
            *ngIf="paymentSuspendedEarlier" aria-describedby="warningPaymentInfo">
            <div class="payment-warning-icon-div">
                <span class="wss-warning-icon warning-icon" alt="Warning Icon"></span>
            </div>
            <div *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierLabelText">
                <strong class="payment-warning-info margin-top-20" test_id="paymentLockoutSuspendedEarlierLabelText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierLabelText">
                    </bb-content-html>
                </strong>
            </div>
            <div class="align-center" *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierInfoText">
                <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                    test_id="paymentLockoutSuspendedEarlierInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierInfoText">
                    </bb-content-html>
                </span>
            </div>
            <div class="align-center"
                *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierPaymentFromText">
                <span class="overpayment-warning-info overpayment-info-text account-ineligible-info para-inline"
                    test_id="paymentLockoutSuspendedEarlierPaymentFromText">
                    <bb-content-html
                        [html]="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierPaymentFromText">
                    </bb-content-html>
                    {{ accountDetail.date_suspended_to }}
                </span>
            </div>
            <div class="align-center"
                *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierHelpSupportText">
                <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                    test_id="paymentLockoutSuspendedEarlierHelpSupportText">
                    <bb-content-html
                        [html]="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedEarlierHelpSupportText">
                    </bb-content-html>
                </span>
            </div>
            <div class="container-row content-center">
                <button role="button" class="wss-button btn-width warn-btn-dif-view button-width-padding"
                    test_id="backToHomeButtonText" (click)="backToMyAccountClick()"
                    title="{{paymentsWidgetItem.paymentResult.backToHomeButtonText}}">
                    {{paymentsWidgetItem.paymentResult.backToHomeButtonText}}
                </button>
            </div>
        </div>
        <!-- Payment lockout suspended message -->

        <!-- Payment lockout blocked earlier message -->
        <div class="container payment-warning-content warning-container payment-blocked-earlier-warning-content"
            *ngIf="paymentBlockedEarlier" aria-describedby="warningPaymentInfo">
            <div class="payment-warning-icon-div">
                <span class="wss-failure-icon failure-icon" alt="Failure Icon"></span>
            </div>
            <div *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutBlockedEarlierLabelText">
                <strong class="payment-warning-info margin-top-20" test_id="paymentLockoutBlockedEarlierLabelText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentLockoutBlockedEarlierLabelText">
                    </bb-content-html>
                </strong>
            </div>
            <div class="align-center" *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutBlockedEarlierInfoText">
                <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                    test_id="paymentLockoutBlockedEarlierInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentLockoutBlockedEarlierInfoText">
                    </bb-content-html>
                </span>
            </div>
            <div class="container-row content-center">
                <button role="button" class="wss-button btn-width warn-btn-dif-view button-width-padding"
                    test_id="backToHomeButtonText" (click)="backToMyAccountClick()"
                    title="{{paymentsWidgetItem.paymentResult.backToHomeButtonText}}">
                    {{paymentsWidgetItem.paymentResult.backToHomeButtonText}}
                </button>
            </div>
        </div>
        <!-- Payment lockout blocked earlier message -->

        <!-- Payment lockout suspended now message -->
        <div class="container payment-warning-content warning-container payment-suspended-now-warning-content"
            *ngIf="paymentSuspendedNow" aria-describedby="warningPaymentInfo">
            <div class="payment-warning-icon-div">
                <span class="wss-warning-icon warning-icon" alt="Warning Icon"></span>
            </div>
            <div *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedNowLabelText">
                <strong class="payment-warning-info margin-top-20" test_id="paymentLockoutSuspendedNowLabelText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedNowLabelText">
                    </bb-content-html>
                </strong>
            </div>
            <div class="align-center" *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedNowInfoText">
                <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                    test_id="paymentLockoutSuspendedNowInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentLockoutSuspendedNowInfoText">
                    </bb-content-html>
                </span>
            </div>
            <div class="container-row content-center">
                <button role="button" class="wss-button btn-width warn-btn-dif-view button-width-padding"
                    test_id="backToHomeButtonText" (click)="backToMyAccountClick()"
                    title="{{paymentsWidgetItem.paymentResult.backToHomeButtonText}}">
                    {{paymentsWidgetItem.paymentResult.backToHomeButtonText}}
                </button>
            </div>
        </div>
        <!-- Payment lockout suspended now message -->

        <!-- Payment lockout blocked now message -->
        <div class="container payment-warning-content warning-container payment-blocked-now-warning-content"
            *ngIf="paymentBlockedNow" aria-describedby="warningPaymentInfo">
            <div class="payment-warning-icon-div">
                <span class="wss-failure-icon failure-icon" alt="Failure Icon"></span>
            </div>
            <div *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutBlockedNowLabelText">
                <strong class="payment-warning-info margin-top-20" test_id="paymentLockoutBlockedNowLabelText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentLockoutBlockedNowLabelText">
                    </bb-content-html>
                </strong>
            </div>
            <div class="align-center" *ngIf="paymentsWidgetItem.paymentResult.paymentLockoutBlockedNowInfoText">
                <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                    test_id="paymentLockoutBlockedNowInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentResult.paymentLockoutBlockedNowInfoText">
                    </bb-content-html>
                </span>
            </div>
            <div class="container-row content-center">
                <button role="button" class="wss-button btn-width warn-btn-dif-view button-width-padding"
                    test_id="backToHomeButtonText" (click)="backToMyAccountClick()"
                    title="{{paymentsWidgetItem.paymentResult.backToHomeButtonText}}">
                    {{paymentsWidgetItem.paymentResult.backToHomeButtonText}}
                </button>
            </div>
        </div>
        <!-- Payment lockout blocked now message -->
    </div>

    <div class="browser-back-wrapper" *ngIf="showBrowserBackPopup">
        <div class="fade modal-backdrop show"></div>
        <div class="d-block fade modal browser-back show">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="container-column browser-back-modal">
                        <div class="container-row modal-header-c" test_id="browserBackHeaderTitle">
                            <span class="material-icons" (click)="closePopup()">clear</span>
                        </div>
                        <div class="container-column modal-body-c" test_id="browserBackHeaderText"
                            id="browserBackHeaderText">
                            <bb-content-html [html]="paymentsWidgetItem.paymentResult.browserBackHeaderText">
                            </bb-content-html>
                        </div>
                        <div class="container-row modal-footer-c" test_id="browserBackButtons">
                            <button class="wss-button-secondary"
                                (click)="closePopup()">{{paymentsWidgetItem.paymentResult.browserBackStayButton}}</button>
                            <button class="wss-button"
                                (click)="proceedClick()">{{paymentsWidgetItem.paymentResult.browserBackProceedButton}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>