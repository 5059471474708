import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MessageService } from '../../../../service/message.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { Observable } from 'rxjs';
import { DatastoreService } from '@wss/service/datastore.service';
import { ContentService } from '@backbase/universal-ang/content';
import { RouterService } from '@backbase/foundation-ang/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'bb-enter-billing-address',
    templateUrl: './enter-billing-address.component.html',
    providers: [ContentService]
})

export class EnterBillingAddressComponent implements OnInit {
    enterBillingAddressForm!: FormGroup;
    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
    customerDetails: any = {};
    validPostcode: boolean = true;
    validTownOrCity: boolean = true;
    validFirstLineOfAddress: boolean = true;
    validLastName: boolean = true;
    validFirstName: boolean = true;
    disabled: boolean = true;
    addressChanged: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private messageService: MessageService,
        private tealiumService: TealiumUtagService,
        private dataStore: DatastoreService,
        private readonly bbContentService: ContentService,
        private router: RouterService) { }

    ngOnInit() {
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Enter Billing Address',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.addressChanged = this.dataStore.getAddressChangeNotification();
        this.customerDetails = this.addressChanged ? this.dataStore.getChangedAddressDetail() : this.dataStore.getCustomerDetail();
        this.enterBillingAddressForm = this.formBuilder.group({
            firstName: [this.addressChanged ? this.customerDetails.individual.first_name : '', Validators.compose([Validators.required])],
            lastName: [this.addressChanged ? this.customerDetails.individual.surname : '', Validators.compose([Validators.required])],
            firstLineOfAddress: [this.addressChanged ? this.customerDetails.addresses.address_detail.house_name : '', Validators.compose([Validators.required])],
            secondLineOfAddress: [this.addressChanged ? this.customerDetails.addresses.address_detail.street_name : ''],
            townOrCity: [this.addressChanged ? this.customerDetails.addresses.address_detail.post_town : '', Validators.compose([Validators.required])],
            postCode: [this.addressChanged ? this.customerDetails.addresses.address_detail.post_code : '', Validators.compose([Validators.required])],
        });
        this.messageService.setCurrentStep(2);
        this.inputEnteredByUser();
        this.changeDetectorRef.detectChanges();
    }

    get f() {
        return this.enterBillingAddressForm.controls;
    }

    submitForm() {
        if(!this.enterBillingAddressForm.invalid) {
            this.customerDetails.addresses.address_detail = {
                county: this.customerDetails.addresses.address_detail.county,
                district: this.enterBillingAddressForm.value.townOrCity,
                flat_number: this.customerDetails.addresses.address_detail.flat_number,
                house_name: this.enterBillingAddressForm.value.firstLineOfAddress,
                house_number: this.customerDetails.addresses.address_detail.house_number,
                overseas_address: this.customerDetails.addresses.address_detail.overseas_address,
                post_code: this.enterBillingAddressForm.value.postCode,
                post_town: this.enterBillingAddressForm.value.townOrCity,
                street_name: this.enterBillingAddressForm.value.secondLineOfAddress
            };
            this.customerDetails.individual = {
                date_of_birth: this.customerDetails.individual.date_of_birth,
                first_name: this.enterBillingAddressForm.value.firstName,
                middle_initials: this.customerDetails.individual.middle_initials,
                surname: this.enterBillingAddressForm.value.lastName,
                title: this.customerDetails.individual.title,
            };
            this.dataStore.setChangedAddressDetail(this.customerDetails);
            this.dataStore.setAddressChangeNotification(true);
            this.router.navigate(['confirm-billing-address']);
        }
    }

    inputEnteredByUser() {
        const first = this.enterBillingAddressForm.get('firstName');
        first?.valueChanges.forEach(
            (value: string) => {
                if (value && value.length > 0) {
                    this.validFirstName = true;
                } else {
                    this.validFirstName = false;
                }
            }
        );

        const last = this.enterBillingAddressForm.get('lastName');
        last?.valueChanges.forEach(
            (value: string) => {
                if (value && value.length > 0) {
                    this.validLastName = true;
                } else {
                    this.validLastName = false;
                }
            }
        );

        const firstLineAddress = this.enterBillingAddressForm.get('firstLineOfAddress');
        firstLineAddress?.valueChanges.forEach(
            (value: string) => {
                if (value && value.length > 0) {
                    this.validFirstLineOfAddress = true;
                } else {
                    this.validFirstLineOfAddress = false;
                }
            }
        );

        const townOrCity = this.enterBillingAddressForm.get('townOrCity');
        townOrCity?.valueChanges.forEach(
            (value: string) => {
                if (value && value.length > 0) {
                    this.validTownOrCity = true;
                } else {
                    this.validTownOrCity = false;
                }
            }
        );

        const postcodeValue = this.enterBillingAddressForm.get('postCode');
        postcodeValue?.valueChanges.forEach(
            (value: string) => {
                if (value && value.length > 0) {
                    this.validPostcode = true;
                } else {
                    this.validPostcode = false;
                }
            }
        );
    }
}