import { ChangeDetectorRef, Component, Inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { HelpCentreItem } from '@wss/model/helpCentre';
import { ContentData, WSSAppConstant } from 'libs/config/wss-app-constants';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TealiumUtagService } from '@wss/service/utag.service';
import { MessageService } from '@wss/service/message.service';
import { injectBrandDetailIntoPage } from '@wss/common/util/util';
import { PAGE_CONFIG, PageConfig } from '@backbase/foundation-ang/web-sdk';
import { DatastoreService } from '@wss/service/datastore.service';

@Component({
    selector: 'bb-get-in-touch',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './get-in-touch.component.html',
    styles: [],
    providers: [ContentService]
})
export class GetInTouchComponent implements OnInit {
    getInTouchFlag: boolean = true;
    svgInfoIcon = WSSAppConstant.SVG_INFO_ICON;
    helpCentreWidgetItem$: Observable<HelpCentreItem | undefined> = this.bbContentService.getContent<HelpCentreItem>('helpCentreWidgetItem');
    helpCentreWidgetItem: any = new HelpCentreItem();
    portalName: string = this.pageConfig.portalName || 'blackhorse';
    accountDetail: any = {};
    isComplaintFeatureEnabled: boolean = true;
    showMoreArrowDown: boolean = false;
    brandsToShowMotorCommissionInfoBox: any = {};
    brandsToShowMotorCommissionShowMore: any = {};
    brandsToShowMotorCommissionHeaderText: any = {};
    showMotorCommissionInfoBox: boolean = false;
    showMotorCommissionShowMore: boolean = false;
    showMotorCommissionHeaderText: boolean = false;

    constructor(
        private readonly bbContentService: ContentService,
        private router: Router,
        private modalService: NgbModal,
        private tealiumService: TealiumUtagService,
        private messageService: MessageService,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
        private renderer: Renderer2,
        private datastoreService: DatastoreService
    ) { }

    ngOnInit(): void {
        this.accountDetail = this.datastoreService.getAccountDetails();
        this.renderer.addClass(document.body, 'get-in-touch');
        this.isComplaintFeatureEnabled = this.datastoreService.getComplaintsFeature();
        this.tealiumService.view(
            {
                JourneyName: 'Help Centre',
                JourneyStepName: 'Get in touch',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.helpCentreWidgetItem$.subscribe((pageItem: any) => {
            this.helpCentreWidgetItem = pageItem;
            this.helpCentreWidgetItem.helpCentreComplaints.complaintsAccordionContent.forEach((element: any) => {
                element = injectBrandDetailIntoPage(element, this.helpCentreWidgetItem.brandContactDetails);
            });
            this.helpCentreWidgetItem.getInTouchPage = injectBrandDetailIntoPage(this.helpCentreWidgetItem.getInTouchPage, this.helpCentreWidgetItem.brandContactDetails);
            this.helpCentreWidgetItem.helpCentreComplaints = injectBrandDetailIntoPage(this.helpCentreWidgetItem.helpCentreComplaints, this.helpCentreWidgetItem.brandContactDetails);
            this.brandsToShowMotorCommissionInfoBox = this.helpCentreWidgetItem.getInTouchPage?.brandsToShowMotorCommissionInfoBox?.split(', ');
            this.brandsToShowMotorCommissionShowMore = this.helpCentreWidgetItem.getInTouchPage?.brandsToShowMotorCommissionShowMore?.split(', ');
            this.brandsToShowMotorCommissionHeaderText = this.helpCentreWidgetItem.getInTouchPage?.brandsToShowMotorCommissionHeaderText?.split(', ');
            this.showMotorCommissionInfoBox = this.brandsToShowMotorCommissionInfoBox?.indexOf(this.portalName) === -1 ? false : true;
            this.showMotorCommissionShowMore = this.brandsToShowMotorCommissionShowMore?.indexOf(this.portalName) === -1 ? false : true;
            this.showMotorCommissionHeaderText = this.brandsToShowMotorCommissionHeaderText?.indexOf(this.portalName) === -1 ? false : true;
            this.changeDetectorRef.detectChanges();
        });
        this.modalService.dismissAll();
        this.messageService.setActiveNav("Help centre internal");
        localStorage.removeItem("getInTouchRedirection");
    }

    onMakeComplaintClick() {
        this.router.navigate(['complaint/get-started']);
    }

    helpSupportCall() {
        this.router.navigate(['help-centre/money-worries']);
    }

    openInfo() {
        this.showMoreArrowDown = !this.showMoreArrowDown;
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(document.body, 'get-in-touch');
    }
}
