<div class="container-column" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
    <div class="container-row arrear-box-height margin-top-10" style="height: auto;" *ngIf="showErrorFlag">
        <div class="arrear-error-box">
            <span class="wss-direct-info-icon"></span>
        </div>
        <div class="container-row arrear-result-text-context">
            <div class="container-row arrear-text-margin">
                <strong class="arrear-flex-width" test_id="arrear_msg_id">
                    {{paymentsWidgetItem.paymentDebitCard.paymentErrorMessage}}<br>
                    {{paymentsWidgetItem.paymentDebitCard.tryAgainText}}
                </strong>
                <span class="material-icons arrear-icons-font-size arrear-flex-width-clear-icon" test_id="tryAgainText">
                    {{paymentsWidgetItem.paymentDebitCard.tryAgainText}}
                </span>
            </div>
        </div>
    </div>

    <div class="payment-column">
        <div class="payment-row section-title-container">
            <span class="section-title mb-5px" test_id="make_payment_label_id">
                {{paymentsWidgetItem.paymentDebitCard.paymentPageName}}
            </span>
        </div>
        <div class="container-row content mt-2px para-inline">
            <bb-content-html [html]="paymentsWidgetItem.paymentDebitCard.acceptVisaAndMastercardDebitCardText">
            </bb-content-html>
        </div>
        <div class="container-row content margin-top-20" *ngIf="arrearsBannerDirectionFlag">
            <bb-content-html [html]="paymentsWidgetItem.paymentDebitCard.lowerArrearsAmountWarningMessage">
            </bb-content-html>
        </div>
    </div>
    
    <div class="payment-column">
        <div class="payment-row payment-margin-top payment-debit-card para-inline">
            <strong *ngIf="!settlementDirectionFlag && !overpaymentRedirectionFlag" test_id="paymentPayableAmountText">
                <bb-content-html [html]="paymentsWidgetItem.paymentDebitCard.paymentPayableAmountText">
                </bb-content-html>
            </strong>
            <strong *ngIf="settlementDirectionFlag && !overpaymentRedirectionFlag"
                test_id="settlementPayableAmountText">
                <bb-content-html [html]="paymentsWidgetItem.paymentDebitCard.settlementPayableAmountText">
                </bb-content-html>
            </strong>
            <strong *ngIf="!settlementDirectionFlag && overpaymentRedirectionFlag"
                test_id="overpaymentPayableAmountText">
                <bb-content-html [html]="paymentsWidgetItem.paymentDebitCard.overpaymentPayableAmountText">
                </bb-content-html>
            </strong>
        </div>
        <form [formGroup]="paymentForm" class="form" role="form" autocomplete="off" id="formPayment" novalidate=""
            method="POST">
            <div class="form-group no-margin make-payment" aria-describedby="payableAmonut">
                <div class="payment-form-content" *ngIf="!settlementDirectionFlag && !overpaymentRedirectionFlag">
                    <span *ngIf="paymentForm.controls.payableAmount?.valid"
                        [ngClass]="poundCssFlag?'poundFocusCss':'pound_icon'"> £ </span>
                    <span *ngIf="!paymentForm.controls.payableAmount?.valid"
                        [ngClass]="!poundCssFlag?'poundErrorFocusCss poundErrorFocusOutCss': 'poundErrorFocusCss'"> £
                    </span>
                    <input #amount type="text" maxlength="8" class="form-control form-control-lg rounded-2 amount-input"
                        formControlName="payableAmount" (focusout)="poundCssFlag = false" (focus)="poundCssFlag = true"
                        placeHolder="Enter amount" accesskey="p">
                </div>
                <div class="payment-form-content disabled-focus"
                    *ngIf="settlementDirectionFlag || overpaymentRedirectionFlag">
                    <span class="poundFocusCss"> £ </span>
                    <input #amount type="text" class="form-control form-control-lg rounded-2 amount-input"
                        formControlName="payableAmount" (ngModelChange)="onAmountEnter(amount.value)"
                        placeHolder="Enter amount">
                </div>
                <ng-container *ngIf="settlementRouteFlag">
                    <div class="error-text" *ngIf="!paymentForm.controls.payableAmount?.valid"
                        test_id="amountFormatValidationMessage">
                        <bb-content-html [html]="paymentsWidgetItem.paymentDebitCard.amountFormatValidationMessage">
                        </bb-content-html>
                    </div>
                </ng-container>
                <div class="error-text" *ngIf="validAmountFlag" test_id="amountExceedValidationMessage">
                    <bb-content-html [html]="paymentsWidgetItem.paymentDebitCard.amountExceedValidationMessage">
                    </bb-content-html>
                </div>
                <div class="error-text" *ngIf="noAmountFlag && paymentForm.controls.payableAmount?.valid"
                    test_id="noAmountValidationMessage">
                    <bb-content-html [html]="paymentsWidgetItem.paymentDebitCard.noAmountValidationMessage">
                    </bb-content-html>
                </div>
                <div class="error-text" *ngIf="validArrearsAmountFlag && arrearsBannerDirectionFlag && !validAmountFlag" test_id="higherArrearsAmountWarningMessage">
                    <bb-content-html [html]="paymentsWidgetItem.paymentDebitCard.higherArrearsAmountWarningMessage">
                    </bb-content-html>
                </div>
            </div>

            <div class="info-content"
                *ngIf="settlementDirectionFlag && !settlementDirectionFlagHP && !overpaymentRedirectionFlag"
                aria-describedby="settlementInfo">
                
                <div class="info-content-text" test_id="settlementOptionalMessage">
                    <bb-content-html [html]="paymentsWidgetItem.paymentDebitCard.settlementOptionalMessage">
                    </bb-content-html>
                </div>
            </div>
            <div class="redirection-link" *ngIf="!settlementDirectionFlag && overpaymentRedirectionFlag"
                aria-describedby="settlementInfo">
                <a class="overpayment-redirection-link" (click)="onChooseNewAmountLink()"
                    test_id="settlementChooseNewAmountLink">
                    {{paymentsWidgetItem.paymentDebitCard.settlementChooseNewAmountLink}}
                </a>
            </div>
        </form>
        
        <div class="container-row content-center diff-view-left-align mt-8px">
            <button role="button" class="wss-button btn-dif-view" test_id="make_payment_id"
                [disabled]="makePaymentButtonDisable" id="payWithDebitCardButton" 
                (click)="next(settlementContent)" title="Take me to make a payment">
                {{paymentsWidgetItem.paymentDebitCard.payWithDebitCardButtonText}}
            </button>
        </div>
        <div class="container-row content-center needHelp-diff-view-left-align mb-40px">
            <span role="navigation" class="other-way-pay-link help-link-dif-view mt-0" tabindex="0" title="Need help?" aria-label="Help Link">
                <span test_id="needHelpLinkText">
                    <bb-content-html [html]="paymentsWidgetItem.paymentDebitCard.needHelpLinkText"></bb-content-html>
                </span>
            </span>
        </div>
    </div>

    
    <!--Request Processing Modal box -->
    <ng-template #processingContent let-modal aria-describedby="processingModalBox">
        <div class="modal-header horse-logo">
            <span class="model-logo-img"></span>
        </div>
        <div class="modal-body loader-modal">
            <div class="loader"></div>
            <div class="processing-content-text" test_id="paymentProcessModalContentInfo1Text">
                {{paymentsWidgetItem.paymentDebitCard.paymentProcessModalContentInfo1Text}}
                <bb-content-html [html]="paymentsWidgetItem.paymentDebitCard.paymentProcessModalContentInfo1Text">
                </bb-content-html>
            </div>
        </div>
        <div class="modal-footer">
        </div>
    </ng-template>
    <!--Request Processing Modal box -->

    <!-- Settlement Confirmation -->
    <ng-template #settlementContent let-modal aria-describedby="settlementModalBox">
        <div class="modal-header">
            <span class="wss-checked-icon"></span>
            <span class="modal-title" id="exampleModalLabel" test_id="settlementPaymenetCardModalHeaderText">
                <bb-content-html [html]="paymentsWidgetItem.paymentDebitCard.settlementPaymenetCardModalHeaderText">
                </bb-content-html>
            </span>
            <span class="close" data-dismiss="modal" aria-label="Close" (click)="onModalClose()">
                <span aria-hidden="true">&times;</span>
            </span>
        </div>
        <div class="modal-body" test_id="settlementPaymentCardModalContentText">
            {{paymentsWidgetItem.paymentDebitCard.settlementPaymentCardModalContentText}}
            <span role="navigation" class="other-way-pay-link help-link-dif-view" title="Need help?">
                <a test_id="need_help_id" (click)="onSettlementConfirmation()">
                    {{paymentsWidgetItem.paymentDebitCard.settlementAgreementLinkText}}
                </a>
            </span>
        </div>
        <div class="modal-footer">
            <span class="btn btn-primary" data-dismiss="modal" aria-label="Close" (click)="onSettlementConfirmation()"
                test_id="settlementPaymentCardYesFooterBttonText">
                {{paymentsWidgetItem.paymentDebitCard.settlementPaymentCardYesFooterBttonText}}
            </span>
        </div>
    </ng-template>
    <!-- Settlement Confirmation -->
</div>