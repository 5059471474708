import { Component, OnInit, ChangeDetectorRef, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { RouterService } from "@backbase/foundation-ang/core";
import { WSSAppConstant, ContentData } from '../../../../config/wss-app-constants';
import { PaymentService } from '../../services/payment.service';
import { DatastoreService } from '../../../../service/datastore.service';
import { ServiceException } from '../../../../model/service-exception';
import { MessageService } from '../../../../service/message.service';
import * as moment from 'moment';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable, Subscription } from 'rxjs';
import { getErrorImageClass } from '@wss/common/util/util';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { Location } from '@angular/common';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'payment-menu',
    templateUrl: './payment-menu.component.html',
    providers: [ContentService]
})

export class PaymentMenuComponent implements OnInit, OnDestroy {
    params: Params = {};
    accountDetail: any = {};
    serviceException: ServiceException = new ServiceException();
    failCount: any = 0;
    paymentButtonDisabled: boolean = false;
    directDebitButtonDisabled: boolean = false;
    settlementButtoneDisabled: boolean = false;
    paymentHolidayButtonDisabled: boolean = false;

    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
    paymentsWidgetItem: PaymentsWidgetItem = new PaymentsWidgetItem();
    directDebitButtonHidden: boolean = false;

    constructor(
        private router: RouterService,
        private datastoreService: DatastoreService,
        public changeDetectorRef: ChangeDetectorRef,
        private messageService: MessageService,
        private readonly bbContentService: ContentService,
        private routerAng: ActivatedRoute,
        private _location: Location,
        private tealiumService: TealiumUtagService,
        private renderer: Renderer2
    ) {
        this.renderer.addClass(document.body, 'payment-menu');
    }

    ngOnInit() {
        this.datastoreService.sessionInvalid();
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Payments Menu',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.failCount = this.datastoreService.getFailCountValue();
        this.accountDetail = this.datastoreService.getAccountDetails();
        this.paymentOptionVisibility();
        this.paymentsWidgetItem$.subscribe((paymentsWidgetItem: any) => {
            this.paymentsWidgetItem = paymentsWidgetItem;
        });
        this.messageService.setCurrentStep(0);
        this.routerAng.queryParams.subscribe(params => {
            if(params['navigate'] === 'makePayment') {
                localStorage.setItem('redirectionFlag', 'payByDebitCard');
                this._location.replaceState('/payments/payment-menu');
            }
        });
    }

    onMakePaymentClick() {
        if (this.paymentButtonDisabled) {
            return;
        } else {
            if (this.failCount > 2) {
                localStorage.setItem('redirectionFlag', 'paymentMenu');
                this.router.navigate(['payment-result-process']);
            } else {
                if(localStorage.getItem('redirectionFlag') !== 'payByDebitCard') {
                    localStorage.setItem('redirectionFlag', 'paymentMenu');
                }
                this.router.navigate(['payment-selection']);
            }
        }
    }

    onSettlementClick() {
        if (this.settlementButtoneDisabled) {
            return;
        } else {
            localStorage.setItem('redirectionFlag', 'settlement');
            this.router.navigate(['payment-settlement']);
        }
    }

    onDirectDebitClick() {
        if (this.directDebitButtonDisabled) {
            return;
        } else {
            let currentTimeHours = new Date().getHours();
            if ((currentTimeHours < 22) && (currentTimeHours >= 8)) {
                if (this.accountDetail && this.accountDetail.method_payment && this.accountDetail.method_payment.toUpperCase() === 'D') {
                    this.datastoreService.setActiveDirectDebitFound(true);
                }
                else {
                    this.datastoreService.setActiveDirectDebitFound(false);
                }
                this.router.navigate(['direct-debit']);
            }
            else {
                this.showOutOfWorkHoursError(this.paymentsWidgetItem.paymentMenu.outOfWorkHoursException);
            }
        }
    }

    showOutOfWorkHoursError(content: any) {
        this.serviceException.imageClass = getErrorImageClass(this.paymentsWidgetItem.paymentMenu.outOfWorkHoursException.errorImageType.toString());
        this.serviceException.errorInfoText = this.paymentsWidgetItem.paymentMenu.outOfWorkHoursException.errorInfoText;
        this.serviceException.buttonText = this.paymentsWidgetItem.paymentMenu.outOfWorkHoursException.buttonText;
        this.serviceException.componentUri = this.paymentsWidgetItem.paymentMenu.outOfWorkHoursException.componentUri;
        this.serviceException.cancelLink = this.paymentsWidgetItem.paymentMenu.outOfWorkHoursException.cancelLink;
        this.messageService.setServiceException(this.serviceException);
        this.router.navigate(['service-exception']);
    }

    onPaymentHolidayClick() {
        if (this.paymentHolidayButtonDisabled) {
            return;
        } else {
            //this.router.navigate(['payment-holidays']);
            // location.href = "#/payment-holidays";
            this.messageService.setActiveNav("Help centre");
            this.router.navigate(['/help-centre/money-worries']); // Removal of modag
        }
    }

    paymentOptionVisibility() {
        var today = moment().format('DD/MM/YYYY');
        var next_due_date = moment(this.accountDetail?.next_payment_date, 'DD/MM/YYYY').format('DD/MM/YYYY');
        var diffTime = moment(next_due_date, 'DD/MM/YYYY').diff(moment(today, 'DD/MM/YYYY'), 'days');

        if (this.accountDetail) {
            this.paymentButtonDisabled = false;
            if (this.accountDetail.is_legal || this.accountDetail.is_litigation_indicator) {
                this.directDebitButtonDisabled = true;
                this.directDebitButtonHidden = true;
                this.settlementButtoneDisabled = true;
                return;
            }
            if (this.accountDetail.in_arrears || this.accountDetail.is_dd_calling || diffTime < 7) {
                this.directDebitButtonDisabled = false;
                this.settlementButtoneDisabled = false;
                return;
            }
            if (this.accountDetail.completion_date !== "") {
                this.paymentButtonDisabled = true;
                this.directDebitButtonDisabled = true;
                this.directDebitButtonHidden = true;
                this.settlementButtoneDisabled = true;
                return;
            }
            if (this.accountDetail.num_payments_left <= 1) {
                this.directDebitButtonDisabled = false;
                this.settlementButtoneDisabled = false;
                return;
            }
        }

    }

    otherWayPayClick() {
        location.href = "#/help-centre/make-payment";
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(document.body, 'payment-menu');
    }
}
