import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentData, patternMap } from '../../../config/wss-app-constants';
import { ContentService as OldContentService } from '../../../service/content.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from '../../../payment-widget/src/services/payment.service';
import { DatastoreService } from '../../../service/datastore.service';
import { SMSModal } from '../../../model/sms-modal';
import { MessageService } from '../../../service/message.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SendSMS } from '@wss/model/send-sms';
import { StorageService } from '@wss/service/storage.service';

@Component({
    selector: 'bb-wss-send-sms',
    templateUrl: './send-sms.component.html',
})
export class SendSMSComponent implements OnInit {
    @Input() smsModal: SMSModal = new SMSModal();

    contentTxt: string = ContentData.SendSMS;
    contentFolder: string = ContentData.Dashboard;
    content: Array<any> = [];

    messageForm!: FormGroup;
    hasMobileNumberError: boolean = false;
    helpNote: SafeHtml = '';
    loggedInCustomerDetails: any;

    sendSMS: SendSMS = new SendSMS();

    constructor(
        private contentService: OldContentService,
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        private paymentService: PaymentService,
        private messageService: MessageService,
        private datastore: DatastoreService,
        private sanitizer: DomSanitizer,
        private storageService: StorageService
    ) { }

    ngOnInit(): void {
        this.messageForm = this.formBuilder.group({
            mobileNumber: ['', Validators.compose([
                Validators.pattern(patternMap.mobileNumber)
            ])]
        });
        this.getStaticContent(this.contentTxt, this.contentFolder);
        this.sendSMS = this.messageService.getSendSMSContentData();
        this.getLoggedInUserMobileNumber();
        this.hasMobileNumberError = false;
        this.helpNote = this.sendSMS?.sendSMSHelpNoteText;
    }

    getStaticContent(contenttxt: string, contentfolder: string): void {
        this.contentService.getStaticContent(contenttxt, contentfolder).subscribe((content) => {
            this.content = content;
            this.changeDetectorRef.detectChanges();
        });
    }

    sendSMSClick() {
        this.datastore.setRecipientMobileNumber(this.messageForm.value.mobileNumber);
        if (this.sendSMS.smsType === 'CPD') {
            this.paymentService.sendDueDateSMS().subscribe(response => {
                this.messageService.setSMSResponse(response);
            });
        }
        else if (this.sendSMS.smsType === 'DD') {
            this.paymentService.updatebankDetailSMS().subscribe(
                response => {
                    this.messageService.setSMSResponse(response);
                }
            );
        }
        else if (this.sendSMS.smsType === 'PR' || this.sendSMS.smsType === 'SPR') {
            this.paymentService.sendPaymentSMS(this.sendSMS.smsType).subscribe(
                response => {
                    this.messageService.setSMSResponse(response);
                }
            );
        }
        this.activeModal.close();
    }

    showHideError() {
        const mobilenumberValue = this.messageForm.get('mobileNumber')?.value;

        const mobileNumberRegex = patternMap.mobileNumber;

        if (!mobilenumberValue || mobilenumberValue?.match(mobileNumberRegex)) {
            this.hasMobileNumberError = false;
        } else {
            this.hasMobileNumberError = true;
            return;
        }

    }

    getLoggedInUserMobileNumber() {
        this.loggedInCustomerDetails = this.storageService.getLocalStorage('customerDetail');
        this.loggedInCustomerDetails = this.loggedInCustomerDetails && JSON.parse(this.loggedInCustomerDetails);
        let userMobileNumber = this.loggedInCustomerDetails?.contact_details?.mobile_phone_number;

        if (userMobileNumber) {
            this.messageForm.get('mobileNumber')?.patchValue(userMobileNumber);
        }
    }

}