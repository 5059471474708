import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@Directive({
  selector: '[formInvlidControlScroll]'
})
export class FormInvlidControlScrollDirective {
    constructor(
      private el: ElementRef,
      private formGroupDir: FormGroupDirective
    ) {}

    @HostListener("ngSubmit") goToNextPage(){
      if(this.formGroupDir.control.invalid){
        this.scrolltoFirstInvalidControl();
      }
    }

    private scrolltoFirstInvalidControl(){
      const firstInvalidControl:HTMLElement = this.el.nativeElement.querySelector(
        "[formControlName].ng-invalid"
      );


      if(firstInvalidControl){

      window.scroll({
        top:this.getTopOffset(firstInvalidControl),
        left:0,
        behavior:"smooth"
      })

      fromEvent(window, "scroll").pipe(
          debounceTime(100),
          take(1)
      )
      .subscribe(()=> firstInvalidControl.focus());
      }
    }

    private getTopOffset(controlEl:HTMLElement):number{
      const lableOffset = 30
      return controlEl.getBoundingClientRect().top + window.scrollY - lableOffset
    }

}

