import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { MatIconModule } from '@angular/material/icon';
import { NgbModalModule, NgbPopoverModule }  from '@ng-bootstrap/ng-bootstrap';
import { WSSCommonModule } from '../../common/common.module';
import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';
import { MatRadioModule} from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { BalloonRefinanceWidgetComponent } from "./balloon-refinance-widget.component";
import { BrLandingComponent } from './components/br-landing/br-landing.component';
import { BrUpdateVehicleDetailsComponent } from './components/br-update-vehicle-details/br-update-vehicle-details.component';
import { UpdatePersonalDetailsComponent } from './components/update-personal-details/update-personal-details.component';
import { BrGetQuoteComponent } from './components/br-get-quote/br-get-quote.component';
import { BrCreditSearchComponent } from './components/br-credit-search/br-credit-search.component';
import { BrAgreementPreviewComponent } from './components/br-agreement-preview/br-agreement-preview.component';
import { BrConfirmationComponent } from './components/br-confirmation/br-confirmation.component';
import { BrCtaArrearsComponent } from './components/br-cta-arrears/br-cta-arrears.component';
import { BrCtaOutOfHoursComponent } from './components/br-cta-out-of-hours/br-cta-out-of-hours.component';
import { BrCtaDdCallingComponent } from './components/br-cta-dd-calling/br-cta-dd-calling.component';
import { BrCtaEocComponent } from './components/br-cta-eoc/br-cta-eoc.component';
import { BrGetStartedComponent } from './components/br-get-started/br-get-started.component';
import { CtaExpiredEocPeriodComponent } from './components/cta-expired-eoc-period/cta-expired-eoc-period.component';

@NgModule({
  declarations: [
    BalloonRefinanceWidgetComponent,
    BrLandingComponent,
    BrUpdateVehicleDetailsComponent,
    UpdatePersonalDetailsComponent,
    BrGetQuoteComponent,
    BrCreditSearchComponent,
    BrAgreementPreviewComponent,
    BrConfirmationComponent,
    BrCtaArrearsComponent,
    BrCtaOutOfHoursComponent,
    BrCtaDdCallingComponent,
    BrCtaEocComponent,
    BrGetStartedComponent,
    CtaExpiredEocPeriodComponent
],
  imports: [
    CommonModule,
    BackbaseCoreModule.withConfig({
      classMap: { BalloonRefinanceWidgetComponent }
    }),
    MatIconModule,
    NgbModalModule,
    NgbPopoverModule,
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule,
    RouterModule.forRoot([], { initialNavigation: 'disabled', useHash: true }),
    RouterModule,
    MatExpansionModule,
    FormsModule,
    MatRadioModule,
    ReactiveFormsModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class BalloonRefinanceWidgetModule { }