export class BannerMessage{
    leftSectionColorClass = '';
    rightSectionColorClass = '';
    infoTypeClass: string = '';
    materialIconClass: string = '';
    iconPositionClass: string = '';
    titleText: string = '';
    subtitleText: string = '';
    closeIconClass: string = '';
    isBannerClosed: boolean = false;
  }