<div class="complaints-page" *ngIf="helpCentreWidgetItem">
	<div class="container-column">
		<div class="help-centre-title">
			<span class="section-label-title" test_id="pageName">
                {{helpCentreWidgetItem?.helpCentreComplaints?.pageName}}</span>
		</div>
		<div class="margin-top-20" test_id="pageHeaderBelowText">
            <bb-content-html [html]="helpCentreWidgetItem.helpCentreComplaints.pageHeaderBelowText"></bb-content-html>
        </div>
		
		<div class="margin-top-20">
			<div class="title-header margin-top-10" test_id="firstHeaderTitle">
                <h3><strong><bb-content-html [html]="helpCentreWidgetItem.helpCentreComplaints.firstHeaderTitle"></bb-content-html></strong></h3></div>
		</div>
		
		<div class="service-promise margin-top-20" test_id="secondContent">
			<bb-content-html [html]="helpCentreWidgetItem.helpCentreComplaints.secondContent"></bb-content-html>
		</div>
		<div class="service-promise margin-top-20">
			<div test_id="thirdContent">
				<bb-content-html [html]="helpCentreWidgetItem.helpCentreComplaints.thirdContent"></bb-content-html>
			</div>
			<div class="margin-top-20" *ngIf="helpCentreWidgetItem.helpCentreComplaints.byPostAddress">
				<span class="span-icon">
					<span class="wss-mail-help-icon"></span>
				</span>
				<span test_id="byPostAddress">
                    <bb-content-html [html]="helpCentreWidgetItem.helpCentreComplaints.byPostAddress"></bb-content-html>
                </span>
			</div>
			<div test_id="finalContent">
				<bb-content-html [html]="helpCentreWidgetItem.helpCentreComplaints.finalContent"></bb-content-html>
			</div>
		</div>
	</div>
</div>