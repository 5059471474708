import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';
import { ComplaintWidgetItem } from '@wss/model/complaint';
import { appConfig, patternMap } from '@wss/config/wss-app-constants';
import { RouterService } from '@backbase/foundation-ang/core';
import { FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { MessageService } from '@wss/service/message.service';
import { getComplaintsCanDeactivate, injectBrandDetailIntoPage, injectComplaintsMailSubjectIntoPage } from '@wss/common/util/util';
import { DatastoreService } from '@wss/service/datastore.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanComponentDeactivate } from '@wss/complaints-widget/guard/deactivation.guard';

@Component({
  selector: 'bb-complaint-about-your-vehicle',
  templateUrl: 'complaint-about-your-vehicle.component.html',
  providers: [ContentService],
  changeDetection: ChangeDetectionStrategy.Default
})

export class ComplaintAboutYourVehicleComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  formId: string = "complaintAboutYourVehicleSelectionForm";
  complaintAboutYourVehicleSelectionForm: any;
  textAreaOneRemainingCharacter: any = 0;
  invalidIssueDiagnosed: boolean = false;
  textAreaTwoRemainingCharacter: any = 0;
  textAreaThreeRemainingCharacter: any = 0;
  submitted: boolean = false;
  invalidVehicleLocated: boolean = false;
  invalidGarageContactDetails: boolean = false;
  inGoodsScope: boolean = false;
  accountDetail: any = {};
  complaintConcernType: string = "Vehicle";
  timeInterval: any;
  complaintsTellUsEmailAddressText: any;
  mileageRemainingNumerals: any = 0;

  constructor(
    private fb: FormBuilder,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    private router: RouterService,
    private formDataService: DataService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef,
    private datastoreService: DatastoreService,
    public modalService: NgbModal
  ) {
  }

  complaintWidgetItem$: Observable<ComplaintWidgetItem | undefined> = this.bbContentService.getContent<ComplaintWidgetItem>('complaintWidgetItem');
  complaintWidgetItem = new ComplaintWidgetItem();
  
  @HostListener("window:beforeunload")
  canDeactivate() {
    return getComplaintsCanDeactivate(this.formDataService.formData, this.modalService);
  }

  ngOnInit(): void {
    this.messageService.setCurrentStep(3);
    this.accountDetail = this.datastoreService.getAccountDetails();
    this.inGoodsScope = appConfig.goodsCodeForVehicle.includes(this.accountDetail?.goods_code);
    this.initConcernSelectionForm();
    this.textboxCharacterChange();
    this.tealiumService.view({
      JourneyName: 'Complaints Digitisation',
      JourneyStepName: 'About your vehicle - ' + this.complaintConcernType,
      CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    });
    const savedData = this.formDataService.getFormData(this.formId);
    if (Object.keys(savedData).length !== 0) {
      this.complaintAboutYourVehicleSelectionForm.setValue(savedData);
    }
    this.complaintWidgetItem$.subscribe((pageItem: any) => {
      this.complaintWidgetItem = pageItem;
      this.complaintWidgetItem.complaintTellUs = injectBrandDetailIntoPage(this.complaintWidgetItem.complaintTellUs, this.complaintWidgetItem.brandContactDetails);
      this.complaintsTellUsEmailAddressText = injectComplaintsMailSubjectIntoPage(this.complaintWidgetItem.complaintTellUs.complaintsTellUsEmailAddressText, this.complaintConcernType, this.accountDetail?.account_number);
      this.timeInterval = setInterval(() => {
        this.complaintsTellUsEmailAddressText = injectComplaintsMailSubjectIntoPage(this.complaintWidgetItem.complaintTellUs.complaintsTellUsEmailAddressText, this.complaintConcernType, this.accountDetail?.account_number);
        this.changeDetectorRef.detectChanges();
      }, 1000);
      this.changeDetectorRef.detectChanges();
    });
  }

  initConcernSelectionForm() {
    this.complaintAboutYourVehicleSelectionForm = this.fb.group({
      anyIssuesDiagnosedOption: ["", [Validators.required]],
      whatAndWhenIssueDiagnosed: [""],
      anyGarageOtherThanSupplyingOption: ["", [Validators.required]],
      authoriseToSpeakToGarage: [""],
      garageContactDetails: [""],
      whereVehicleLocatedTextarea: ["", [Validators.required]],
      mileage: this.inGoodsScope ? ["", [Validators.required]] : [""],
      vehicleDriveableOrSafeSelection: ["", [Validators.required]]
    })
  }

  textboxCharacterChange() {
    this.complaintAboutYourVehicleSelectionForm.valueChanges.subscribe((value: any) => {
      this.textAreaOneRemainingCharacter = value.whatAndWhenIssueDiagnosed.length;
      this.textAreaTwoRemainingCharacter = value.garageContactDetails.length;
      this.textAreaThreeRemainingCharacter = value.whereVehicleLocatedTextarea.length;
      this.mileageRemainingNumerals = value.mileage.length
    });

    this.complaintAboutYourVehicleSelectionForm.controls['whatAndWhenIssueDiagnosed'].valueChanges.forEach(
      (value: string) => {
        if (value && value.length > 0 && !patternMap.textboxPattern.test(value)) {
          this.invalidIssueDiagnosed = true;
        } else {
          this.invalidIssueDiagnosed = false;
        }
      }
    );

    this.complaintAboutYourVehicleSelectionForm.controls['whereVehicleLocatedTextarea'].valueChanges.forEach(
      (value: string) => {
        if (value && value.length > 0 && !patternMap.textboxPattern.test(value)) {
          this.invalidVehicleLocated = true;
        } else {
          this.invalidVehicleLocated = false;
        }
      }
    );

    this.complaintAboutYourVehicleSelectionForm.controls['garageContactDetails'].valueChanges.forEach(
      (value: string) => {
        if (value && value.length > 0 && !patternMap.textboxPattern.test(value)) {
          this.invalidGarageContactDetails = true;
        } else {
          this.invalidGarageContactDetails = false;
        }
      }
    );

    this.complaintAboutYourVehicleSelectionForm.get('anyIssuesDiagnosedOption').valueChanges.subscribe((value: string) => {
      if (value == "Yes") {
        this.complaintAboutYourVehicleSelectionForm.get('whatAndWhenIssueDiagnosed').setValidators([Validators.required, Validators.pattern(patternMap.textboxPattern)]);
      } else {
        this.complaintAboutYourVehicleSelectionForm.get('whatAndWhenIssueDiagnosed').clearValidators();
      }
      this.complaintAboutYourVehicleSelectionForm.get('whatAndWhenIssueDiagnosed').updateValueAndValidity();
    });

    this.complaintAboutYourVehicleSelectionForm.get('anyGarageOtherThanSupplyingOption').valueChanges.subscribe((value: string) => {
      if (value == "Yes") {
        this.complaintAboutYourVehicleSelectionForm.get('authoriseToSpeakToGarage').setValidators([Validators.required]);
      } else {
        this.complaintAboutYourVehicleSelectionForm.get('authoriseToSpeakToGarage').setValue("");
        this.complaintAboutYourVehicleSelectionForm.get('authoriseToSpeakToGarage').clearValidators();
      }
      this.complaintAboutYourVehicleSelectionForm.get('authoriseToSpeakToGarage').updateValueAndValidity();
    });
    
    this.complaintAboutYourVehicleSelectionForm.get('authoriseToSpeakToGarage').valueChanges.subscribe((value: string) => {
      if (value == "Yes") {
        this.complaintAboutYourVehicleSelectionForm.get('garageContactDetails').setValidators([Validators.required, Validators.pattern(patternMap.textboxPattern)]);
      } else {
        this.complaintAboutYourVehicleSelectionForm.get('garageContactDetails').clearValidators();
      }
      this.complaintAboutYourVehicleSelectionForm.get('garageContactDetails').updateValueAndValidity();
    });

    this.complaintAboutYourVehicleSelectionForm.get('mileage').valueChanges.subscribe((value: string) => {
      if (value == "0") {
        this.complaintAboutYourVehicleSelectionForm.get('mileage').setValue("");
      }
    });

  }

  get complaintAboutYourVehicleSelectionFormControl() {
    return this.complaintAboutYourVehicleSelectionForm.controls;
  }

  get any_issues_diagnosed_selection_value() {
    return this.complaintAboutYourVehicleSelectionForm.get('anyIssuesDiagnosedOption');
  }

  get any_garage_other_than_Supplying_selection_value() {
    return this.complaintAboutYourVehicleSelectionForm.get('anyGarageOtherThanSupplyingOption');
  }

  get authoriseToSpeakToGarageFormValue() {
    return this.complaintAboutYourVehicleSelectionForm.get('authoriseToSpeakToGarage');
  }

  handlePreviousBtn() {
    this.formDataService.saveFormData(this.formId, this.complaintAboutYourVehicleSelectionForm.value);
    this.router.navigate(['tell-us']);
  }

  handleNextBtn() {
    this.submitted = true;
    if (this.complaintAboutYourVehicleSelectionForm.valid) {
      this.formDataService.saveFormData(this.formId, this.complaintAboutYourVehicleSelectionForm.value);
      this.router.navigate(['your-contact-details']);
    }
  }

  ngOnDestroy(): void {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }
}
