import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserRepoService} from "../../service/user-repo.service";
//import {ContentService as OldContentService} from "../../../../service/content.service";
import {ContentData} from '../../../../config/wss-app-constants';
import { ContentService } from '@backbase/universal-ang/content';
import {LoginWidgetItem} from '../../../../model/login-widget/login-widget';
import { Observable } from "rxjs";
import {ItemModel, RouterService} from "@backbase/foundation-ang/core";
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-wss-attemps-exceeded',
  templateUrl: './attemps-exceeded.component.html',
  providers: [ ContentService ]
})

export class AttempsExceededComponent implements OnInit {
  
  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Login',
        JourneyStepName: 'Login Attempts Exceeded',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');

  constructor(
    private router: Router,
    private userRepoService: UserRepoService,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
  ) {
    this.userRepoService.updateScreenTitle('Authenticate');
  }

  submit() {
    this.userRepoService.updateScreenTitle('Create An Account');
    this.router.navigate(['/']);
  }

}
