<div class="common-contract-details additional-info-container">
    <div test_id="agreementInfo" class="mt-20px font-size-20">
        <bb-content-html [html]="additionalInfoContent.agreementInfo"></bb-content-html>
    </div>
    <div class="container-column additional-info-content-margin">
        <div class="container-row border-bottom bg-white content-center additional-info-item-height p-2">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="startDate">
                    <bb-content-html [html]="additionalInfoContent.startDate"></bb-content-html>
                </span>
            </span>
            <span class="flex-width">{{accountDetail?.inception_date}}</span>
        </div>
        <div class="container-row border-bottom content-center additional-info-item-height p-2">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="endDate">
                    <bb-content-html [html]="additionalInfoContent.endDate"></bb-content-html>
                </span>
            </span>
            <span class="flex-width">{{accountDetail?.final_payment_date}}</span>
        </div>
        <div class="container-row border-bottom bg-white content-center additional-info-item-height p-2">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="contractLength">
                    <bb-content-html [html]="additionalInfoContent.contractLength"></bb-content-html>
                </span>
            </span>
            <span class="flex-width">
                {{accountDetail?.original_term}} <span class="para-inline"><bb-content-html [html]="additionalInfoContent.monthText"></bb-content-html></span>
        </span>
        </div>
        <div class="container-row border-bottom content-center additional-info-item-height p-2">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="paymentMade">
                    <bb-content-html [html]="additionalInfoContent.paymentMade"></bb-content-html>
                </span>
            </span>
            <span class="flex-width">{{accountDetail?.original_term - accountDetail?.num_payments_left}}</span>
        </div>
        <div class="container-row border-bottom bg-white content-center additional-info-item-height p-2">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="monthlyPayment">
                    <bb-content-html [html]="additionalInfoContent.monthlyPayment"></bb-content-html>
                </span>
            </span>
            <span class="flex-width">{{accountDetail?.next_payment_amount}}</span>
        </div>
        <div class="container-row border-bottom content-center additional-info-item-height p-2">
            <span class="info-item-left">
                <div class="flex-width additional-info-item-pull-right content-center">
                    <!-- <strong test_id="apr_label_id">{{content?.apr}}</strong> -->
                    <span class="para-inline" test_id="apr">
                        <bb-content-html [html]="additionalInfoContent.apr"></bb-content-html>
                        <svg id="aprPopId" [ngbPopover]="popContentApr" autoClose="outside" triggers="manual" #pApr="ngbPopover"
                        (click)="openPopover(pApr, $event, 'aprPopId')" popoverClass="popover-manage-contract" title="info-icon"
                        class="svg-margin-left info-icon-top" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        viewBox="0 0 24 24">
                        <path [attr.fill]="iconColor" [attr.d]="svgInfoIcon" />
                    </svg>
                    </span>
                </div>
            </span>
            <span class="flex-apr-value" test_id="apr_value_id">{{accountDetail?.apr}}</span>
        </div>
        <div class="container-row border-bottom bg-white content-center additional-info-item-height p-2" *ngIf="getHPCustomer()">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="mileage">
                    <bb-content-html [html]="additionalInfoContent.mileage"></bb-content-html>
                </span>
            </span>
            <span class="flex-width" test_id="annual_mileage_value_id">{{accountDetail?.max_annual_mileage}}</span>
        </div>
        <div class="container-row border-bottom content-center additional-info-item-height p-2" *ngIf="isLPAgreementFlag || ((accountDetail?.scheme_code === '54' || accountDetail?.scheme_code === 54) && getHPCustomer())">
            <span class="info-item-left" *ngIf="((accountDetail?.scheme_code === '54' || accountDetail?.scheme_code === 54) && getHPCustomer())">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="mileage">
                    <bb-content-html [html]="additionalInfoContent.optionalFinalPayment"></bb-content-html>
                    <svg
                    id="ofpPopId" 
                    [ngbPopover]="popContentOfp" 
                    autoClose="outside" 
                    triggers="manual" 
                    #pOfp="ngbPopover"
                    (click)="openPopover(pOfp, $event, 'ofpPopId')" 
                    popoverClass="popover-ofp"
                    title="info-icon" 
                    class="svg-margin-left info-icon-top mt-0"
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                        <path [attr.fill]="iconColor" [attr.d]="svgInfoIcon" /></svg>
                </span>
            </span>
            <span class="info-item-left" *ngIf="isLPAgreementFlag">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="mileageLP">
                    <bb-content-html [html]="additionalInfoContent.FinalPaymentLP"></bb-content-html>                    
                </span>
            </span>
            <span class="flex-width" test_id="annual_mileage_value_id" *ngIf="isLPAgreementFlag || ((accountDetail?.scheme_code === '54' || accountDetail?.scheme_code === 54) && getHPCustomer())">{{accountDetail?.final_payment_amount}}</span>            
        </div>
    </div>


</div>
<ng-template #popContentApr>
    <span class="tooltip-arrow-up" [attr.style]="popoverArrowStyle"></span>
    <div class="container-row modal-body">
        <div class="content" test_id="aprTooltipContent">
            <bb-content-html [html]="additionalInfoContent.aprTooltipContent"></bb-content-html>
            
        </div>
        <div class="tooplip-close">
            <span (click)="closePopover()" class="material-icons clear-icon"
                [attr.style]="tooltipCloseStyle">clear</span>
        </div>
    </div>
</ng-template>

<ng-template #popContentOfp>
    <span class="tooltip-arrow-up" [attr.style]="popoverArrowStyle"></span>
    <div class="container-row modal-body pover-over-class">
        <div class="content" test_id="ofpTooltipContent" *ngIf="additionalInfoContent.ofpTooltipContent">
            <bb-content-html [html]="additionalInfoContent.ofpTooltipContent"></bb-content-html>
        </div>
        <div class="tooplip-close">
            <span (click)="closePopover()" class="material-icons clear-icon"
                [attr.style]="tooltipCloseStyle">clear</span>
        </div>
    </div>
</ng-template>