import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'bb-br-credit-search',
  templateUrl: 'br-credit-search.component.html',
  styles: [
  ]
})
export class BrCreditSearchComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigate(path: string): void {
    this.router.navigate(['/balloon-refinance/' + path]);
  }

  navigateToHome(): void {
    this.router.navigate(['/myaccount']);
  }

}
