import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { ItemModel, RoutableWidget, RouterService } from "@backbase/foundation-ang/core";
import { UserSearchComponent } from "./components/user-search/user-search.component";
import { UserDetailsComponent } from "./components/user-detail/user-detail.component";
import { NoUserComponent } from "./components/no-user/no-user.component";
import { RegistrationStoreModelService } from "../../registration-store/src/registration-store-model.service";
import { VerifySmsComponent } from "./components/verify-sms/verify-sms.component";
import { OtpSmsComponent } from "./components/verify-sms/otp-sms.component";
import { UserCredentialsComponent } from "./components/credentials/user-credentials.component";
import { UserPasswordComponent } from "./components/credentials/user-password.component";
import { CreatedCredentialsComponent } from "./components/credentials/created-credentials.component";
import { SecurityFailComponent } from "./components/security-fail/security-fail.component";
import { SecurityIntroComponent } from "./components/security-intro/security-intro.component";
import { SecurityQuestionsComponent } from "./components/security-questions/security-questions.component";
import { QuestionsFailComponent } from "./components/questions-fail/questions-fail.component";
import { PasscodeErrorComponent } from "./components/passcode-error/passcode-error.component";
import { appConfig, RuntimeEnv } from '../../config/wss-app-constants';
import { CreateAccountFaqComponent } from './components/create-account-faq/create-account-faq.component';
import { ContactPreferenceComponent } from './components/contact-preference/contact-preference.component';
import { takeUntil } from 'rxjs/operators';
import { RegisterService } from './service/register.service';
import { DatastoreService } from '@wss/service/datastore.service';

@Component({
  selector: 'bb-registration-widget',
  templateUrl: 'registration-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RegistrationStoreModelService, RouterService]
})
@RoutableWidget({
  routes: [
    { path: '', redirectTo: 'user-search', pathMatch: 'full' },
    { path: 'user-search', component: UserSearchComponent },
    { path: 'user-detail', component: UserDetailsComponent },
    { path: 'no-user', component: NoUserComponent },
    { path: 'verify-sms', component: VerifySmsComponent },
    { path: 'verify-otp', component: OtpSmsComponent },
    { path: 'enter-email', component: UserCredentialsComponent },
    { path: 'enter-pwd', component: UserPasswordComponent },
    { path: 'credential-created', component: CreatedCredentialsComponent },
    { path: 'security-fail', component: SecurityFailComponent },
    { path: 'security-intro', component: SecurityIntroComponent },
    { path: 'security-questions', component: SecurityQuestionsComponent },
    { path: 'questions-fail', component: QuestionsFailComponent },
    { path: 'passcode-error', component: PasscodeErrorComponent },
    { path: 'create-account-faqs', component: CreateAccountFaqComponent },
    { path: 'contact-preference', component: ContactPreferenceComponent }
  ],
})
export class RegistrationWidgetComponent implements OnInit, OnDestroy, AfterViewInit {

  readonly title: Observable<string | undefined>;
  readonly current: Observable<number | undefined>;
  currentStep: number | undefined;
  titlePage: string = '';
  portalName: any;
  registrationContainerStyle: string = '';
  isContactPreferenceFeatureOn: boolean = false;

  readonly destroy$ = new Subject();
  
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.scrollY > 0) {
      this.renderer.addClass(document.body, 'no-top-bar');
    } else {
      this.renderer.removeClass(document.body, 'no-top-bar');
    }
  }

  // @HostListener('window:resize', ['$event'])
  // onResize() {
  //   this.getContainterHeight();
  // }

  constructor(
    private itemModel: ItemModel,
    private registrationService: RegistrationStoreModelService,
    private registerService: RegisterService,
    private cmndatastoreService: DatastoreService,
    private renderer: Renderer2,
  ) {
    this.title = this.registrationService.titleObservable;
    this.current = this.registrationService.stepObservable;
    this.registrationService.updatedStepper(1);
    this.renderer.addClass(document.body, 'no-nav-bar');
    this.getPortalName();
    this.getApplicationConfig(this.portalName);
  }

  ngOnInit(): void {
    this.itemModel.property('searchUserTitle').subscribe(value => this.registrationService.updateTitle(value as string));
    this.current.subscribe(value => this.currentStep = value);
  }

  ngAfterViewInit() {
    // this.getContainterHeight();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'no-nav-bar');
  }

  toLogin() {
    location.href = appConfig.loginURL;
  }

  getPortalName() {
    if (RuntimeEnv.backbase) {
      this.portalName = window.BB.config.portalName;
      // "blackhorse"
    }
  }

  getApplicationConfig(brandName: any) {
    this.registerService.getDefaultConfiguration().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if(response?.propertySources[0]?.source[`mfvs.feature.marketing-preferences.enabled`]) {
        let isFeatureEnabled: boolean = response?.propertySources[0]?.source[`mfvs.feature.marketing-preferences.${brandName}.enabled`];
        let isMPRegisterFeatureEnabled: boolean = response?.propertySources[0]?.source[`mfvs.feature.register-marketing-preferences.${brandName}.enabled`];
        this.isContactPreferenceFeatureOn = isFeatureEnabled && isMPRegisterFeatureEnabled;
        this.cmndatastoreService.setIsContactPreferenceFeature(isFeatureEnabled);
        this.cmndatastoreService.setIsContactPreferenceRegisterFeature(isMPRegisterFeatureEnabled);
      } else {
        this.cmndatastoreService.setIsContactPreferenceFeature(false);
      }
    });
  }

  // getContainterHeight() {
  //   let topPx = document.getElementsByClassName('registration-container')[0].clientHeight + 150;
  //   this.registrationContainerStyle = 'min-height:' + topPx + 'px !important;';
  // }
}
