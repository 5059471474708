import { appConfig, brUrls, proposalStatus, RuntimeEnv } from '../../config/wss-app-constants';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { BrandContactDetailsItem } from '@wss/model/content/common/brand-contant-details';
import * as moment from 'moment';
import { addressClass, ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { ComplaintDataRetentionComponent } from '@wss/complaints-widget/src/components/complaint-data-retention/complaint-data-retention.component';


export function isEmpOrNullOrUndef(value: any) {
  return value === '' || value === undefined || value === null;
}

export function isEmpOrNullOrUndefOrWhiteSpace(value: any) {
  return value === '' || value === undefined || value === null || value === ' ';
}

export function isNotEmpOrNullOrUndef(value: any) {
  return value !== '' && value !== undefined && value !== null;
}
export function isNotEmpOrNullOrUndefOrWhiteSpace(value: any) {
  return value !== '' && value !== undefined && value !== null && value !== ' ';
}


export function getValueNotEND(value: any) {
  if (!isEmpOrNullOrUndef(value)) {
    return value;
  }
}

export function getDate(ddMMyyyy: string) {
  var dateString: any = ddMMyyyy.match(/^(\d{2})\/(\d{2})\/(\d{4})$/); //NOSONAR
  return new Date(dateString[3], dateString[2] - 1, dateString[1]);
}

export function getDatesDifference(fromDate: any, toDate: any) {
  var daysDiff: any = Math.floor(Math.abs(fromDate - toDate) / (1000 * 60 * 60 * 24));
  return daysDiff;
}

export function getDigitSuffix(i: any) {
  i = parseInt(i);
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export function encodeHTML(str: string) {
  return str.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
};

export function isErrServer(errStatus: number): boolean {
  return errStatus === 500 || errStatus === 404 || errStatus === 400 || errStatus === 503;
}

export function contentUpdate(elem: any, elemKey: string) {
  if (elem.nativeElement.innerHTML == '<bb-content key="' + elemKey + '"><!----></bb-content>') {
  } else {
    elem.nativeElement.innerHTML = elem.nativeElement.innerHTML.replace(/\&nbsp;/g, ' ');
  }
}

export function getCurrentMonthlyPayment(accountDetail: any) {
  let currentMonthlyPmt: number = 0;
  let intBalanceOutstanding = accountDetail.current_balance ? parseFloat(accountDetail.current_balance.replace('£', '').replaceAll(',', '')) : 0;
  let intArrs_Plus_Fees = accountDetail.arrears_plus_fees ? parseFloat(accountDetail.arrears_plus_fees.toString().replace('£', '').replaceAll(',', '')) : 0;
  let balloon = accountDetail.final_payment_amount ? parseFloat(accountDetail.final_payment_amount.replace('£', '').replaceAll(',', '')) : 0;
  let intNumPaymentsLeft = accountDetail.num_payments_left ? parseInt(accountDetail.num_payments_left) : 0;
  let optionFee = accountDetail.option_fee ? parseFloat(accountDetail.option_fee.replace('£', '').replaceAll(',', '')) : 0;
  accountDetail.scheme_code = parseInt(accountDetail.scheme_code);

  if (accountDetail.scheme_code === appConfig.hpCustomer) {
    // currentMonthlyPmt = (((intBalanceOutstanding + intArrs_Plus_Fees) - (balloon * 100)) / intNumPaymentsLeft) / 100;
    currentMonthlyPmt = (((intBalanceOutstanding - intArrs_Plus_Fees) - (balloon)) / intNumPaymentsLeft);
  }
  else if (accountDetail.scheme_code === appConfig.pcpCustomer) {
    // currentMonthlyPmt = (((intBalanceOutstanding + intArrs_Plus_Fees) - ((balloon + optionFee) * 100))) / (intNumPaymentsLeft - 1) / 100;
    currentMonthlyPmt = (((intBalanceOutstanding - intArrs_Plus_Fees) - (balloon + optionFee)) / (intNumPaymentsLeft - 1));
  }
  if (currentMonthlyPmt < 0) {
    currentMonthlyPmt = parseFloat(currentMonthlyPmt.toString().substring(1));
  }
  return currentMonthlyPmt;
}

export function getErrorImageClass(contentStr: string) {
  let imgClass = '';
  if (contentStr === 'Date Error') {
    imgClass = "service-dateerror";
  }
  else if (contentStr === 'Timeout Error') {
    imgClass = "service-timeout";
  }
  else if (contentStr === 'Payment Error') {
    imgClass = "service-wallet-error";
  }
  return imgClass;
}

export function getPortalName() {
  if (RuntimeEnv.backbase) {
    return window.BB?.config.portalName;
  }

}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

export function getDeviceType() {
  if (window.matchMedia("only screen and (max-width: 767px)").matches) {
    return "Mobile";
  }
  else if (window.matchMedia("only screen and (min-width: 768px) and (max-width: 1023px)").matches) {
    return "Tablet";
  }
  else {
    return "Desktop";
  }
}

export function getDeviceGroup() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
}

export function injectBrandDetailIntoPage(pageItem: any, brandDetailObj: BrandContactDetailsItem) {
  for (let key in pageItem) {
    if (isNotEmpOrNullOrUndef(pageItem[key]) && (key !== 'brandContactDetails') && (key !== 'errorImageType') && (key !== 'complaintsAccordionContent') && (typeof (pageItem[key]) === 'string')) {
      if (brandDetailObj.brandWiseCfaNumber) {
        pageItem[key] = pageItem[key].replaceAll(`{{brandWiseCfaNumber}}`, brandDetailObj.brandWiseCfaNumber.replaceAll("<p>", "").replaceAll("</p>", ""));
      }
      if (brandDetailObj.brandWiseFreeTRSNumber) {
        pageItem[key] = pageItem[key].replaceAll(`{{brandWiseFreeTRSNumber}}`, brandDetailObj.brandWiseFreeTRSNumber.replaceAll("<p>", "").replaceAll("</p>", ""));
      }
      if (brandDetailObj.budgetToolLink) {
        pageItem[key] = pageItem[key].replaceAll(`{{budgettoollink}}`, brandDetailObj.budgetToolLink.replaceAll("<p>", "").replaceAll("</p>", ""));
      }
      if (brandDetailObj.privacyPolicyLink) {
        pageItem[key] = pageItem[key].replaceAll(`{{privacyPolicyLink}}`, brandDetailObj.privacyPolicyLink.replaceAll("<p>", "").replaceAll("</p>", ""));
      }
      if (brandDetailObj.brandWiseContactNumber) {
        pageItem[key] = pageItem[key].replaceAll(`{{brandContactNumber}}`, brandDetailObj.brandWiseContactNumber.replaceAll("<p>", "").replaceAll("</p>\n", "").replaceAll("</p>", ""));
      }
      if (brandDetailObj.brandWiseBankDetails) {
        pageItem[key] = pageItem[key].replaceAll('{{brandWiseBankDetails}}', brandDetailObj.brandWiseBankDetails.replaceAll("<p>", "").replaceAll("</p>", ""));
      }
      if (brandDetailObj.dpnLink) {
        pageItem[key] = pageItem[key].replaceAll('{{brandWiseDpnLink}}', brandDetailObj.dpnLink.replaceAll("<p>", "").replaceAll("</p>", ""));
      }
      if (brandDetailObj.dpnPersonalInfoSentence) {
        pageItem[key] = pageItem[key].replaceAll('{{dpnPersonalInfoSentence}}', brandDetailObj.dpnPersonalInfoSentence.replaceAll("<p>", "").replaceAll("</p>", ""));
      }
      if (brandDetailObj.dpnContactTelNumber) {
        pageItem[key] = pageItem[key].replaceAll('{{dpnContactTelNumber}}', brandDetailObj.dpnContactTelNumber.replaceAll("<p>", "").replaceAll("</p>\n", ""));
      }
      if (brandDetailObj.brandWiseAccessibilityNumber) {
        pageItem[key] = pageItem[key].replaceAll('{{brandWiseAccessibilityNumber}}', brandDetailObj.brandWiseAccessibilityNumber.replaceAll("<p>", "").replaceAll("</p>\n", ""));
      }
      if (brandDetailObj.brandWiseAddress) {
        pageItem[key] = pageItem[key].replaceAll('{{brandWiseAddress}}', brandDetailObj.brandWiseAddress.replaceAll("<p>", "").replaceAll("</p>", ""));
      }
      if (brandDetailObj.brandWiseSqdComplaintsEmail) {
        pageItem[key] = pageItem[key].replaceAll('{{brandWiseSqdComplaintsEmail}}', brandDetailObj.brandWiseSqdComplaintsEmail.replaceAll("<p>", "").replaceAll("</p>", ""));
      }
      if (brandDetailObj.brandWiseServicingComplaintsEmail) {
        pageItem[key] = pageItem[key].replaceAll('{{complaintsEmailAddressForFinanceAgreement}}', brandDetailObj.brandWiseServicingComplaintsEmail.replaceAll("<p>", "").replaceAll("</p>", ""));
      }
      if (brandDetailObj.brandName) {
        pageItem[key] = pageItem[key].replaceAll('{{brandName}}', brandDetailObj.brandName.replaceAll("<p>", "").replaceAll("</p>", ""));
      }
      if (brandDetailObj.accessibilitySupportNumber) {
        pageItem[key] = pageItem[key].replaceAll('{{accessibilitySupportNumber}}', brandDetailObj.accessibilitySupportNumber.replaceAll("<p>", "").replaceAll("</p>", ""));
      }
      if (brandDetailObj.vehicleComplaintsAddress) {
        pageItem[key] = pageItem[key].replaceAll('{{vehicleComplaintsAddress}}', brandDetailObj.vehicleComplaintsAddress.replaceAll("<p>", "").replaceAll("</p>", ""));
      }
    }
  }
  return pageItem;
}

export function injectApiDataIntoPage(pageItem: any, apiData: any) {
  for (let key in pageItem) {
    if (isNotEmpOrNullOrUndef(pageItem[key]) && (key !== 'brandContactDetails') && (key !== 'errorImageType') && (typeof (pageItem[key]) === 'string')) {
      for (let apiKey in apiData) {
        pageItem[key] = pageItem[key].replaceAll(`{${apiKey}}`, apiData[apiKey]);
      }
    }
  }
  return pageItem;
}

export function injectBrandingIntoAccordion(pageItem: any, brandDetailObj: BrandContactDetailsItem) {
  for (let key in pageItem) { 
    if (isNotEmpOrNullOrUndef(pageItem[key]) && (key !== 'brandContactDetails') && (key !== 'errorImageType') && (key !== 'complaintsAccordionContent') && (Array.isArray(pageItem[key]))) {
      for (const obj in pageItem[key]) {          
        if (brandDetailObj.brandWiseCfaNumber) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll(`{{brandWiseCfaNumber}}`, brandDetailObj.brandWiseCfaNumber.replaceAll("<p>", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll(`{{brandWiseCfaNumber}}`, brandDetailObj.brandWiseCfaNumber.replaceAll("<p>", "").replaceAll("</p>", ""));
        }
        if (brandDetailObj.brandWiseFreeTRSNumber) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll(`{{brandWiseFreeTRSNumber}}`, brandDetailObj.brandWiseFreeTRSNumber.replaceAll("<p>", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll(`{{brandWiseFreeTRSNumber}}`, brandDetailObj.brandWiseFreeTRSNumber.replaceAll("<p>", "").replaceAll("</p>", ""));
        }
        if (brandDetailObj.budgetToolLink) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll(`{{budgettoollink}}`, brandDetailObj.budgetToolLink.replaceAll("<p>", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll(`{{budgettoollink}}`, brandDetailObj.budgetToolLink.replaceAll("<p>", "").replaceAll("</p>", ""));
        }
        if (brandDetailObj.privacyPolicyLink) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll(`{{privacyPolicyLink}}`, brandDetailObj.privacyPolicyLink.replaceAll("<p>", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll(`{{privacyPolicyLink}}`, brandDetailObj.privacyPolicyLink.replaceAll("<p>", "").replaceAll("</p>", ""));
        }
        if (brandDetailObj.brandWiseContactNumber) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll(`{{brandContactNumber}}`, brandDetailObj.brandWiseContactNumber.replaceAll("<p>", "").replaceAll("</p>\n", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll(`{{brandContactNumber}}`, brandDetailObj.brandWiseContactNumber.replaceAll("<p>", "").replaceAll("</p>\n", "").replaceAll("</p>", ""));
        }
        if (brandDetailObj.brandWiseBankDetails) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll('{{brandWiseBankDetails}}', brandDetailObj.brandWiseBankDetails.replaceAll("<p>", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll('{{brandWiseBankDetails}}', brandDetailObj.brandWiseBankDetails.replaceAll("<p>", "").replaceAll("</p>", ""));
        }
        if (brandDetailObj.dpnLink) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll('{{brandWiseDpnLink}}', brandDetailObj.dpnLink.replaceAll("<p>", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll('{{brandWiseDpnLink}}', brandDetailObj.dpnLink.replaceAll("<p>", "").replaceAll("</p>", ""));
        }
        if (brandDetailObj.dpnPersonalInfoSentence) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll('{{dpnPersonalInfoSentence}}', brandDetailObj.dpnPersonalInfoSentence.replaceAll("<p>", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll('{{dpnPersonalInfoSentence}}', brandDetailObj.dpnPersonalInfoSentence.replaceAll("<p>", "").replaceAll("</p>", ""));
        }
        if (brandDetailObj.dpnContactTelNumber) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll('{{dpnContactTelNumber}}', brandDetailObj.dpnContactTelNumber.replaceAll("<p>", "").replaceAll("</p>\n", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll('{{dpnContactTelNumber}}', brandDetailObj.dpnContactTelNumber.replaceAll("<p>", "").replaceAll("</p>\n", ""));
        }
        if (brandDetailObj.brandWiseAccessibilityNumber) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll('{{brandWiseAccessibilityNumber}}', brandDetailObj.brandWiseAccessibilityNumber.replaceAll("<p>", "").replaceAll("</p>\n", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll('{{brandWiseAccessibilityNumber}}', brandDetailObj.brandWiseAccessibilityNumber.replaceAll("<p>", "").replaceAll("</p>\n", ""));
        }
        if (brandDetailObj.brandWiseAddress) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll('{{brandWiseAddress}}', brandDetailObj.brandWiseAddress.replaceAll("<p>", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll('{{brandWiseAddress}}', brandDetailObj.brandWiseAddress.replaceAll("<p>", "").replaceAll("</p>", ""));
        }
        if (brandDetailObj.brandWiseSqdComplaintsEmail) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll('{{brandWiseSqdComplaintsEmail}}', brandDetailObj.brandWiseSqdComplaintsEmail.replaceAll("<p>", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll('{{brandWiseSqdComplaintsEmail}}', brandDetailObj.brandWiseSqdComplaintsEmail.replaceAll("<p>", "").replaceAll("</p>", ""));
        }
        if (brandDetailObj.brandWiseServicingComplaintsEmail) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll('{{complaintsEmailAddressForFinanceAgreement}}', brandDetailObj.brandWiseServicingComplaintsEmail.replaceAll("<p>", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll('{{complaintsEmailAddressForFinanceAgreement}}', brandDetailObj.brandWiseServicingComplaintsEmail.replaceAll("<p>", "").replaceAll("</p>", ""));
        }
        if (brandDetailObj.brandName) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll('{{brandName}}', brandDetailObj.brandName.replaceAll("<p>", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll('{{brandName}}', brandDetailObj.brandName.replaceAll("<p>", "").replaceAll("</p>", ""));
        }
        if (brandDetailObj.accessibilitySupportNumber) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll('{{accessibilitySupportNumber}}', brandDetailObj.accessibilitySupportNumber.replaceAll("<p>", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll('{{accessibilitySupportNumber}}', brandDetailObj.accessibilitySupportNumber.replaceAll("<p>", "").replaceAll("</p>", ""));
        }          
        if (brandDetailObj.vehicleComplaintsAddress) {
          pageItem[key][obj].header = pageItem[key][obj].header.replaceAll('{{vehicleComplaintsAddress}}', brandDetailObj.vehicleComplaintsAddress.replaceAll("<p>", "").replaceAll("</p>", ""));
          pageItem[key][obj].content = pageItem[key][obj].content.replaceAll('{{vehicleComplaintsAddress}}', brandDetailObj.vehicleComplaintsAddress.replaceAll("<p>", "").replaceAll("</p>", ""));
        }                        
      }
    } 
  }
  return pageItem;
}

export function injectAprIntoPage(pageItem: any, balloonRefinanceCommonDetailsItemObject: any) {
  const apr = balloonRefinanceCommonDetailsItemObject.balloonRefinanceCommonAPR?.replaceAll("<p>", "").replaceAll("</p>", "");
  if (!apr) return pageItem;
  for (let key in pageItem) {
    const value = pageItem[key];
    if (shouldReplaceString(value, key)) {
      pageItem[key] = value.replaceAll('{{balloonRefinanceCommonAPR}}', apr);
    } else if (shouldProcessArray(value, key)) {
      pageItem[key] = value.map((obj: any) => replaceInObject(obj, apr));
    }
  }
  return pageItem;
}

function shouldReplaceString(value: any, key: string): boolean {
  return isNotEmpOrNullOrUndef(value) && 
         key !== 'brandContactDetails' && 
         key !== 'errorImageType' && 
         key !== 'complaintsAccordionContent' && 
         typeof value === 'string';
}

function shouldProcessArray(value: any, key: string): boolean {
  return isNotEmpOrNullOrUndef(value) && 
         key !== 'brandContactDetails' && 
         key !== 'errorImageType' && 
         key !== 'complaintsAccordionContent' && 
         Array.isArray(value);
}

function replaceInObject(obj: any, apr: string): any {
  if (obj.header) {
    obj.header = obj.header.replaceAll('{{balloonRefinanceCommonAPR}}', apr);
  }
  if (obj.content) {
    obj.content = obj.content.replaceAll('{{balloonRefinanceCommonAPR}}', apr);
  }
  return obj;
}

export function injectEmailAddress(pageItem: any, email: any) {
  for (let key in pageItem) {
    if (isNotEmpOrNullOrUndef(pageItem[key]) && (key !== 'brandContactDetails') && (key !== 'errorImageType') && (typeof (pageItem[key]) === 'string')) {
      pageItem[key] = pageItem[key].replaceAll(`{{userEmailAddress}}`, email);
    }
  }
  return pageItem;
}

export function injectComplaintNumber(pageItem: any, brandDetailObj: BrandContactDetailsItem) {
  if (isNotEmpOrNullOrUndef(pageItem)) {
    pageItem = pageItem.replaceAll(`{{complaintTelephoneNumber}}`, brandDetailObj.complaintNumber.replaceAll("<p>", "").replaceAll("</p>", ""));
  }
  return pageItem;
}

export function replaceSingleSpaceWithDoubleSpace(strWithSingleSpace: any, brandName: any) {
  if (brandName === "suzukifinance") {
    strWithSingleSpace = strWithSingleSpace.replaceAll("&nbsp", "&nbsp&nbsp&nbsp");
  }
  return strWithSingleSpace;
}

export function calculateWorkingDays(date: any, days: any, calculationType: any) {
  date = moment(date);
  while (days > 0) {
    date = calculationType === "next" ? date.add(1, 'days') : date.subtract(1, 'days');
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1;
    }
  }
  return date;
}

export function processAccountNumber(accNo: any) {
  return "*****" + accNo.substring(5);
}

export function calculateDaysLeft(date: any) {
  var today = moment().format('DD/MM/YYYY');
  var next_due_date = moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
  var diffTime = moment(next_due_date, 'DD/MM/YYYY').diff(moment(today, 'DD/MM/YYYY'), 'days');
  return diffTime;
}

export function autoTabPress() {
  var inputs = document.getElementsByTagName('input');
  for (var i = 0; i < inputs.length; i++) {
    if (document.activeElement && document.activeElement.id == inputs[i].id && i + 1 < inputs.length) {
      inputs[i + 1].focus();
      break;
    }
  }
}

export function suspendedDateDifference(date: any) {
  var suspendedDate = date.split(' ');
  var today = moment().format('DD/MM/YYYY');
  var date_suspended = moment(suspendedDate[0], 'DD/MM/YYYY').format('DD/MM/YYYY');
  var dateDifference = moment(date_suspended, 'DD/MM/YYYY').diff(moment(today, 'DD/MM/YYYY'), 'days');
  return dateDifference;
}

export function getMonthDiff(currentDate: any, selectDate: any) {
  const getYearDiff = (currentDate?.getFullYear() - selectDate?.getFullYear()) * 12;
  const monthDiff = (currentDate?.getMonth() - selectDate?.getMonth()) + getYearDiff;
  return monthDiff;
}

export function getYearDiff(currentDate: any, selectDate: any) {
  const getYearDiff = (currentDate?.getFullYear() - selectDate.getFullYear()) * 12;
  const monthTemp = (currentDate?.getMonth() - selectDate.getMonth()) + getYearDiff;
  const yearDiff = Math.trunc(monthTemp / 12);
  const monthDiff = monthTemp % 12;
  if (monthTemp < 12) {
    return { years: 0, months: monthTemp };
  } else {
    return { years: yearDiff, months: monthDiff };
  }
}

export function injectBrandContactContent(pageItem: any, brandDetailObj: BrandContactDetailsItem) {
  if (brandDetailObj.brandWiseContactNumber) {
    for (let key in pageItem) {
      if (Array.isArray(pageItem[key])) {
        for (const obj in pageItem[key]) {
          if (pageItem[key][obj].content) {
            pageItem[key][obj].content = pageItem[key][obj].content.replaceAll(`{{brandContactNumber}}`, brandDetailObj.brandWiseContactNumber.replaceAll("<p>", "").replaceAll("</p>\n", "").replaceAll("</p>", ""));
          }
        }
      } else if (pageItem[key].content) {
        pageItem[key].content = pageItem[key].content.replaceAll(`{{brandContactNumber}}`, brandDetailObj.brandWiseContactNumber.replaceAll("<p>", "").replaceAll("</p>\n", "").replaceAll("</p>", ""));
      }
    }
  }
  return pageItem;
}

export const injectGoodConditionGuideIntoPage = (pageItem: any, goodConditionGuideUrl: string) => {
  if (goodConditionGuideUrl) {
    for (let key in pageItem) {
      if (Array.isArray(pageItem[key])) {
        for (const obj in pageItem[key]) {
          pageItem[key][obj].content = pageItem[key][obj].content && pageItem[key][obj].content.replaceAll(`{{goodConditionGuideUrl}}`, goodConditionGuideUrl);
        }
      } else if (pageItem[key].content) {
        pageItem[key].content = pageItem[key].content && pageItem[key].content.replaceAll(`{{goodConditionGuideUrl}}`, goodConditionGuideUrl);
      }
    }
  }
  return pageItem;
}

export const injectAccountsDataIntoPage = (pageItem: any, accountsDetails: any) => {
  for (let key in pageItem) {
    if (accountsDetails.apr) {
      pageItem[key].content = pageItem[key].content && pageItem[key].content.replaceAll(`{{apr}}`, accountsDetails.apr);
    }
    if (accountsDetails.completion_date) {
      pageItem[key].content = pageItem[key].content && pageItem[key].content.replaceAll(`{{completionDate}}`, accountsDetails.completion_date);
    }
  }

  return pageItem;
}

export function removeSpace(elem: any): string {
  const getElem = elem?.replace(/\s/g, '');
  return getElem;
}

export function removeControlForm(ctrlElem: string, formElem: FormGroup) {
  if (formElem.contains(ctrlElem)) {
    formElem.removeControl(ctrlElem);
  }
}

export function addControlForm(ctrlElem: string, formElem: FormGroup, frmBuild: FormBuilder) {
  if (!formElem.get(ctrlElem)) {
    formElem.addControl(ctrlElem, frmBuild.control('', []));
  }
}

export function addFormValidator(ctrlElem: string, validator: any, formElem: FormGroup) {
  if (formElem.contains(ctrlElem)) {
    formElem.controls[ctrlElem].setValidators(validator);
    formElem.controls[ctrlElem].updateValueAndValidity();
  }
}

export function resetControlForm(ctrlElem: string, formElem: FormGroup) {
  if (formElem.contains(ctrlElem)) {
    formElem.controls[ctrlElem].setValue('');
  }
}

export function getYears(): any {
  const getYear = new Date().getFullYear();
  const getYearCount = getYear - appConfig.yearStart + 1;
  let yearsList = [...Array(getYearCount).keys()].map(i => i + appConfig.yearStart);
  yearsList = yearsList.reverse();
  return yearsList;
}

export function checkAddressFields(obj: any): addressClass {
  if (((obj.address_detail.house_name === null) || (obj.address_detail.house_name === ' ')) &&
    ((obj.address_detail.house_number === null) || (obj.address_detail.house_number === ' ')) &&
    ((obj.address_detail.flat_number === null) || (obj.address_detail.flat_number === ' '))) {
    const getHouseNoTemp = obj.address_detail.street_name.indexOf(' ');
    const getHouseNo = obj.address_detail.street_name.slice(0, getHouseNoTemp);
    const getStreet = obj.address_detail.street_name.slice(getHouseNoTemp);
    obj.address_detail.house_number = getHouseNo;
    obj.address_detail.street_name = getStreet;
    return obj;
  }
  return obj;
}

export function getHouseDetailsFromStreetName(streetName: any) {
  let houseDetails = {
    street_name: '',
    house_number: ''
  }
  if (/\d/.test(streetName)) {
    let str = streetName.split(' ');
    houseDetails.house_number = streetName.split(' ')[0];
    str.shift();
    str.map((el: any) => {
      houseDetails.street_name += el + " ";
    });
  }
  return houseDetails;
}

export function isPreviouslyStartedBrJourney(proposalData: ProposalRequest): boolean {
  if (proposalData.proposed_term !== null) {
    return true;
  }
  return false;
}

export function setResumeBrApplciationLink(proposalData: ProposalRequest, accountdetails: any, isContractWithin14Days: boolean): string {
  const isOutOfHours = new Date().getHours() >= 22 || new Date().getHours() < 8;
  const inArrears = accountdetails?.in_arrears;
  if (isOutOfHours) {
    return brUrls.ctaOutOfHours;
  }
  if (inArrears) {
    return brUrls.ctaArrears;
  }
  if (proposalData?.proposal_status) {
    return setFromDecision(proposalData)
  }
  if (isContractWithin14Days){
    return brUrls.ctaExpriedEocPeriod;
  }
  if (proposalData) {
    return setFromProposalData(proposalData);
  }
  return '';
}

export function setFromDecision(proposalData: ProposalRequest): string {
  if (proposalData.proposal_status === proposalStatus.accepted) {
    return brUrls.accepted;
  }
  if (proposalData?.proposal_status === proposalStatus.referred) {
    return brUrls.referred;
  }
  if (proposalData?.proposal_status === proposalStatus.declined) {
    return brUrls.declined;
  }
  if (proposalData?.proposal_status === proposalStatus.incomplete) {
    return brUrls.ctaExpriedEocPeriod;
  }
  return '';
}

export function setFromProposalData(proposalData: ProposalRequest): string {
  //always return to employment page if employment_type is not set due to being one of the unemployed types which we cannot persist to wss db due to sensitive data
  if (proposalData?.gross_annual_income !== null && proposalData?.employment_type === null) {
    return brUrls.employment;
  }

  if (proposalData?.bank_account_number !== null) {
    return brUrls.bankAccount;
  }

  if (proposalData?.gross_annual_income !== null) {
    return brUrls.finances;
  }

  if (proposalData?.employment_type !== null || proposalData?.years_at_employment !== null) {
    return brUrls.employment;
  }

  if (proposalData?.marital !== null) {
    return brUrls.aboutYou;
  }

  if (proposalData?.mileage !== null) {
    return brUrls.vehicle;
  }
  if (proposalData?.proposed_term !== null) {
    return brUrls.getAQuote;
  }
  return '';
}

export function validEmailCheck(email: any) {
  let validEmailFormat = (/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i);
  return validEmailFormat.test(email);
}

export function isPcpToHpBalloonRefinance(accountDetail: any): boolean {
  return (accountDetail?.pcp_ind === 0 || accountDetail?.pcp_ind === false) && (accountDetail?.scheme_code === "54" || accountDetail?.scheme_code === appConfig.pcpCustomer);
}

export function splitStringToCharLimitWholeWords(inintalString: string, charSplit: number) {
  let stringParts = {
    firstString: '',
    secondString: ''
  }
  let words = inintalString.split(" ");

  for (let i = 0; i < words.length; i++) {
    if (stringParts.firstString.length + words[i].length <= charSplit) {
      stringParts.firstString += words[i] + " ";
    } else {
      for (let j = i; j < words.length; j++) {
        stringParts.secondString += words[j] + " ";
      }
      break;
    }
  }
  return stringParts;
}

export function reconcileCustomer(reqEmail: string, email: string, reqFname?: string, reqSname?: string, fname?: string, sname?: string): boolean {
  if (reqEmail === email) {
    let cReconcile: boolean = ((reqFname === fname) && (reqSname === sname)) ? true : false;
    return cReconcile;
  } else {
    return false;
  }
}

export function validateDateDOB(yearSel: any, yearDob: any, monthSel: any, monthDob: any): boolean {
  let getDiffYears = new Date().getFullYear() - yearSel;
  let getDiffMonths = (new Date().getMonth() + 1) - monthSel;
  if (getDiffYears < yearDob) {
    return true;
  } else if (getDiffYears === yearDob) {
    if (!isNaN(getDiffMonths)) {
      if (getDiffMonths >= monthDob) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function chkDOBDates(selMn: any, selYr: any, dobMn: any, dobYr: any): boolean {
  if (selYr !== '') {
    if (selYr > dobYr) {
      return false;
    } else if (selYr === dobYr) {
      if (selMn + 1 >= dobMn) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function injectComplaintsEmailAddressIntoPage(complaintsTellUsEmailAddressTextForFinanceAgreement: any,
  complaintsEmailAddressForFinanceAgreement: any, complaintConcernType: string, agreementNumber: string) {
  if (complaintsTellUsEmailAddressTextForFinanceAgreement && complaintsEmailAddressForFinanceAgreement) {
    complaintsTellUsEmailAddressTextForFinanceAgreement = complaintsTellUsEmailAddressTextForFinanceAgreement.replaceAll(`{{complaintsEmailAddressForFinanceAgreement}}`, complaintsEmailAddressForFinanceAgreement.replaceAll("<p>", "").replaceAll("</p>", ""));
    complaintsTellUsEmailAddressTextForFinanceAgreement = injectComplaintsMailSubjectIntoPage(complaintsTellUsEmailAddressTextForFinanceAgreement, complaintConcernType, agreementNumber)
  }
  return complaintsTellUsEmailAddressTextForFinanceAgreement;
}

export function injectComplaintsMailSubjectIntoPage(complaintsTellUsEmailAddressText: any, complaintConcernType: string, agreementNumber: string) {
  let today = moment().format('DD/MMM/YYYY HH:mm');
  const complaintType = complaintConcernType == 'Vehicle' ? 'SQD Complaint - ' : 'Servicing Complaint - ';
  const subjectLine = '?subject=' + agreementNumber + ' - Online ' + complaintType + today;
  complaintsTellUsEmailAddressText = complaintsTellUsEmailAddressText.replace(`{{subjectMail}}`, subjectLine);
  complaintsTellUsEmailAddressText = complaintsTellUsEmailAddressText.replace(`{{subjectMail}}`, '');
  return complaintsTellUsEmailAddressText;
}

export function injectComplaintsAgreementNumberIntoPage(submissionResultText: any, agreementNumber: string) {
  if (submissionResultText && agreementNumber) {
    submissionResultText = submissionResultText.replaceAll(`{{agreementNumber}}`, agreementNumber);
  }
  return submissionResultText;
}

export function getComplaintsEmailAddress(complaintsEmailAddressText: string) {
  let arr: any = complaintsEmailAddressText.match(/href="([^"]*)/)![1];
  let complaintsMail: string = "";
  if (arr.indexOf("{{") > 0) {
    complaintsMail = arr.substring(
      arr.indexOf("mailto:") + 7,
      arr.indexOf("{{")
    );
  } else if (arr.indexOf("co.uk") > 0) {
    complaintsMail = arr.substring(
      arr.indexOf("mailto:") + 7,
      arr.indexOf("co.uk") + 5
    );
  } else if (arr.indexOf(".com") > 0) {
    complaintsMail = arr.substring(
      arr.indexOf("mailto:") + 7,
      arr.indexOf(".com") + 4
    );
  }
  return complaintsMail;
}

export function checkFormIsEmpty(obj: any) {
  for (let key in obj) {
    if (obj[key] !== null && obj[key] != "")
      return false;
  }
  return true;
}

export function getComplaintsCanDeactivate(formData: any, _ngbModal: any) {
  if (Object.keys(formData).length > 1
    && !checkFormIsEmpty(formData.complaintRelateToSelectionForm)) {
    const modalRef = _ngbModal.open(ComplaintDataRetentionComponent, {
      backdrop: "static",
      centered: true
    });

    return modalRef.result;
  }
  return true;
}

export function noWhitespaceValidator(control: FormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { 'whitespace': true };
}

export function atLeastOneFieldRequiredValidator(controlNames: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const controls = controlNames.map(name => control.get(name));
    const atLeastOne = controls.some(control => control && control.value !== null && control.value !== "" && control.value !== " ");
    return atLeastOne ? null : { 'atLeastOneRequired': true };
  }
}

export function getIconColor(): string {
  if(RuntimeEnv.backbase) {
    let companyDetails: any = appConfig.brandDetails.find( ({ brandName }) => brandName === window.BB.config.portalName );
    return companyDetails.iconColor;
  } else {
    return appConfig.defaultIconColor;
  }  
}

export function isLPAgreement(accountDetail: any): boolean {
  return (accountDetail?.company_number === '89' && accountDetail?.scheme_code === 1 && accountDetail?.palis_scheme === 'AP');  
}

export function maskAgreementNumber(agreementNumber: string) {
  let masked = agreementNumber.substring(0, agreementNumber.length - 4).replace(/[a-z\d]/gi, "X") +
    agreementNumber.substring(agreementNumber.length - 4, agreementNumber.length)
  return masked;
}

export function jsonParser(parseValue: any) {
  try {
    return JSON.parse(parseValue);
  } catch (error) {
    return null;
  }
}

export function complaintCategoriesSQD(categories: any) {
  let categoriesSQD = categories.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("\n", "").split('<br />');
  return categoriesSQD;
}

export function complaintCategoriesServicing(categories: any) {
  let categoryArr = categories.split('</p>');
  let subCategoryArr: any = [];
  let categoriesServicing: any = [];
  let objTitle: any;
  categoryArr.forEach((item: any) => {
    if (item.length > 0) {
      categoriesServicing.push(item.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("\n", "").split('<br />'));
    }
  });
  categoryArr.pop();
  categoriesServicing.forEach((element: []) => {
    let subArr: any = [];
    let index = 0;
    element.forEach((data: string) => {
      if (index >= 1) {
        subArr.push(data);
      } else {
        objTitle = data?.split(':')[0];
      }
      index += 1;
    });
    subCategoryArr[objTitle] = subArr;
  });
  return subCategoryArr;
}

export function cleanCurrency(value: string): number {
  const cleanedValue = parseFloat(value.replace(/[,£]/g, ''));    
  if(isNaN(cleanedValue)){
    return 0;
  }
  else{
    return cleanedValue;
  }    
}