import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../service/http.service';
import { MessageService } from '../../../service/message.service';
import { catchError, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class PaymentHolidayService {

  constructor(private httpService: HttpService,
    private messageService: MessageService) { }

  getExistingModags(): Observable<any>{
    return this.httpService.getExistingModags().
      pipe(map(data => {
      return data;
      }), catchError(error => {
        this.messageService.setServiceError(error.error && error.error.length? error.error[0]: error.error);
        return error;
      }));
  }

  caculateModagQuote(requestedRental: any, requestedTerm: any): Observable<any>{
    return this.httpService.caculateModagQuote(requestedRental, requestedTerm).
      pipe(map(data => {
      return data;
      }), catchError(error => {
        this.messageService.setServiceError(error.error && error.error.length? error.error[0]: error.error);
        return error;
      }));
  }

  submitModagQuote(data: any): Observable<any>{
    return this.httpService.submitModagQuote(data).
      pipe(map(data => {
      return data;
      }), catchError(error => {
        this.messageService.setServiceError(error.error && error.error.length? error.error[0]: error.error);
        return error;
      }));
  }

  creditBureauSearch(): Observable<any>{
    return this.httpService.creditBureauSearch().
      pipe(map(data => {
      return data;
      }), catchError(error => {
        this.messageService.setServiceError(error.error && error.error.length? error.error[0]: error.error);
        return error;
      }));
  }

}