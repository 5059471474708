import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { RouterModule } from "@angular/router";
import {
  ButtonModule,
  HeaderModule,
  InputEmailModule,
  InputNumberModule,
  InputTextModule,
  InputValidationMessageModule,
  StepperModule,
  InputRadioGroupModule
} from "@backbase/ui-ang";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MatDialogModule } from '@angular/material/dialog';
import { WSSCommonModule } from '../../common/common.module';

import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';
import { ComplaintWidgetComponent } from './complaint-widget.component';
import { ComplaintGetStartedComponent } from './components/complaint-get-started/complaint-get-started.component';
import { ComplaintRelateToComponent } from './components/complaint-relate-to/complaint-relate-to.component';
import { ComplaintTellUsComponent } from './components/complaint-tell-us/complaint-tell-us.component';
import { ComplaintAboutYourVehicleComponent } from './components/complaint-about-your-vehicle/complaint-about-your-vehicle.component';
import { ComplaintYourContactDetailsComponent } from './components/complaint-your-contact-details/complaint-your-contact-details.component';
import { ComplaintSubmissionComponent } from './components/complaint-submission/complaint-submission.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ComplaintDataRetentionComponent } from './components/complaint-data-retention/complaint-data-retention.component';
import { DeactivationGuard } from '../guard/deactivation.guard';
import { AuthGuard } from '../guard/auth-guard';
import { ComplaintsGuard } from '../guard/complaints-auth-guard.guard';

@NgModule({
  declarations: [
    ComplaintWidgetComponent,
    ComplaintGetStartedComponent,
    ComplaintRelateToComponent,
    ComplaintTellUsComponent,
    ComplaintAboutYourVehicleComponent,
    ComplaintYourContactDetailsComponent,
    ComplaintSubmissionComponent,
    ComplaintDataRetentionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputRadioGroupModule,
    BackbaseCoreModule.withConfig({
      classMap: { ComplaintWidgetComponent }
    }),
    RouterModule.forRoot([], { initialNavigation: 'disabled', useHash: true }),
    HeaderModule,
    RouterModule,
    MatDialogModule,
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule
  ],
  providers: [DeactivationGuard, AuthGuard, ComplaintsGuard]
})
export class ComplaintWidgetModule { }