<div class="handback-checklist footer-padding">
  <div class="handback-checklist-header-wrapper" test_id="welcome-header">
    <div class="d-flex justify-content-start header-wrapper-login responsive-header">
      <div [ngClass]="portalName === 'astonmartin' || 'aston martin'? 'logo logo-login-style-amfix':'logo logo-login-style'"></div>
      <div *ngIf="(portalName === 'landrover' || portalName === 'jaguar' )" class="logo-text"></div>
    </div>

    <div class="handback-checklist-pages-widget"
      *ngIf="handBackChecklistWidgetItem$ | async as handBackChecklistWidgetItem">
      <div class="content-maxwidth">
        <div class="content-wrapper-container">
          <div class="section-title-container">
            <h1 class="section-label-title" test_id="pageName">
              {{handBackChecklistWidgetItem.handbackChecklistDetails.pageName}}</h1>
          </div>


          <div class="handback-checklist-container">
            <div class="m-tb-10" test_id="textBelowPageHeader">
              <bb-content-html [html]="handBackChecklistWidgetItem.handbackChecklistDetails.textBelowPageHeader">
              </bb-content-html>
            </div>

            <h2 test_id="fewWeeksBeforeText">{{handBackChecklistWidgetItem.handbackChecklistDetails.fewWeeksBeforeText}}
            </h2>
            <div class="handback-checklist-details">
              <ol>
                <li class="m-tb-20">
                  <div class="font-weight-bold" test_id="fewWeeksBeforeFirstPointHeader">
                    {{handBackChecklistWidgetItem.handbackChecklistDetails.fewWeeksBeforeFirstPointHeader}}</div>
                  <div class="para-inline" test_id="fewWeeksBeforeFirstPointContentBeforeData">
                    <bb-content-html
                      [html]="handBackChecklistWidgetItem.handbackChecklistDetails.fewWeeksBeforeFirstPointContentBeforeData">
                    </bb-content-html>
                    <span>
                      <a class="good-condition-guide" [href]="goodConditionURL">{{handBackChecklistWidgetItem.handbackChecklistDetails?.goodConditionguideLink}}</a>
                    </span>
                    <bb-content-html
                      [html]="handBackChecklistWidgetItem.handbackChecklistDetails.fewWeeksBeforeFirstPointContentAfterData">
                    </bb-content-html>
                  </div>
                <li class="m-tb-20">
                  <div test_id="fewWeeksBeforeSecondPointContent">
                    <bb-content-html
                      [html]="handBackChecklistWidgetItem.handbackChecklistDetails.fewWeeksBeforeSecondPointContent">
                    </bb-content-html>
                  </div>
                </li>
                <li class="m-tb-20">
                  <div test_id="fewWeeksBeforeThirdPointContent">
                    <bb-content-html
                      [html]="handBackChecklistWidgetItem.handbackChecklistDetails.fewWeeksBeforeThirdPointContent">
                    </bb-content-html>
                  </div>

                </li>
                <li class="m-tb-20">
                  <div test_id="fewWeeksBeforeFourthPointContent">
                    <bb-content-html
                      [html]="handBackChecklistWidgetItem.handbackChecklistDetails.fewWeeksBeforeFourthPointContent">
                    </bb-content-html>
                  </div>
                </li>
                <li class="m-tb-20">
                  <div test_id="fewWeeksBeforeFifthPointContent">
                    <bb-content-html
                      [html]="handBackChecklistWidgetItem.handbackChecklistDetails.fewWeeksBeforeFifthPointContent">
                    </bb-content-html>
                  </div>
                </li>
              </ol>
            </div>

            <div class="m-tb-20">
              <h2 test_id="onTheDayOfCollectionText">
                {{handBackChecklistWidgetItem.handbackChecklistDetails.onTheDayOfCollectionText}}</h2>
              <div class="handback-checklist-details">
                <ol>
                  <li class="m-tb-20">
                    <div test_id="onTheDayOfCollectionFirstPointContent">
                      <bb-content-html
                        [html]="handBackChecklistWidgetItem.handbackChecklistDetails.onTheDayOfCollectionFirstPointContent">
                      </bb-content-html>
                    </div>
                  </li>
                  <li class="m-tb-20">
                    <div test_id="onTheDayOfCollectionSecondPointContent">
                      <bb-content-html
                        [html]="handBackChecklistWidgetItem.handbackChecklistDetails.onTheDayOfCollectionSecondPointContent">
                      </bb-content-html>
                    </div>
                  </li>
                  <li class="m-tb-20">
                    <div test_id="onTheDayOfCollectionThirdPointContentBeforeData">
                      <bb-content-html
                        [html]="handBackChecklistWidgetItem.handbackChecklistDetails.onTheDayOfCollectionThirdPointContentBeforeData">
                      </bb-content-html>
                      <!-- <span *ngIf="handBackChecklistWidgetItem.brandContactDetails.portalName">
                      <bb-content-html [html]="handBackChecklistWidgetItem.brandContactDetails.portalName"></bb-content-html>
                      <bb-content-html
                        [html]="handBackChecklistWidgetItem.handbackChecklistDetails.onTheDayOfCollectionThirdPointContentAfterData">
                      </bb-content-html>
                      </span> -->
                      <span>
                        <div class="font-weight-bold" test_id="portalName">
                        {{portalName | uppercase}}</div>
                        <bb-content-html
                        [html]="handBackChecklistWidgetItem.handbackChecklistDetails.onTheDayOfCollectionThirdPointContentAfterData">
                        </bb-content-html>
                      </span>
                    </div>
                  </li>
                  <li class="m-tb-20">
                    <div test_id="onTheDayOfCollectionFourthPointContent">
                      <bb-content-html
                        [html]="handBackChecklistWidgetItem.handbackChecklistDetails.onTheDayOfCollectionFourthPointContent">
                      </bb-content-html>
                    </div>
                  </li>
                  <li class="m-tb-20">
                    <div test_id="onTheDayOfCollectionFifthPointContent">
                      <bb-content-html
                        [html]="handBackChecklistWidgetItem.handbackChecklistDetails.onTheDayOfCollectionFifthPointContent">
                      </bb-content-html>
                    </div>
                  </li>
                  <li class="m-tb-20">
                    <div test_id="onTheDayOfCollectionSixthPointContent">
                      <bb-content-html
                        [html]="handBackChecklistWidgetItem.handbackChecklistDetails.onTheDayOfCollectionSixthPointContent">
                      </bb-content-html>
                    </div>
                  </li>
                  <li class="m-tb-20">
                    <div test_id="onTheDayOfCollectionSeventhPointContent">
                      <bb-content-html
                        [html]="handBackChecklistWidgetItem.handbackChecklistDetails.onTheDayOfCollectionSeventhPointContent">
                      </bb-content-html>
                    </div>
                  </li>

                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>