
<div class="container-security-intro pad-left" *ngIf="registrationWidgetItem$ | async as registrationWidgetItem" test_id="pageHeaderText">
  <bb-content-html [html]="registrationWidgetItem.securityIntro.pageHeaderText"></bb-content-html>
  <div class="text-center margin-top-between-rows no-margin-left">
    <button bbButton type="button" aria-label="Favourite" id="confirmButtonText"
    class="wss-button" (click)="navigate('security-questions')" test_id="confirmButtonText">
      {{registrationWidgetItem.securityIntro.confirmButtonText}}
    </button>       
  </div>
  <div id="security-intro-need-help-link" class="need-help-link text-center mb-25px" test_id="needHelpLink">
    <bb-content-html [html]="registrationWidgetItem.securityIntro.needHelpLink"></bb-content-html>
  </div>
</div>
