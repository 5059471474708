import { ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { Router } from '@angular/router';
import { TealiumUtagService } from '@wss/service/utag.service';
import { BalloonRefinanceGetdetailsItem, ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { DatastoreService } from '@wss/service/datastore.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { appConfig, patternMap } from '@wss/config/wss-app-constants';
import { takeUntil } from 'rxjs/operators';
import { BalloonRefinanceService } from '@wss/balloon-refinance-widget/src/services/balloon-refinance.service';


@Component({
  selector: 'wss-balloon-refinance-getdetails-vehicle',
  templateUrl: './balloon-refinance-getdetails-vehicle.component.html'
})

export class BalloonRefinanceGetdetailsVehicleComponent implements OnInit {

  balloonRefinanceGetdetailsVehicleItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');
  brGetdetailsVehicleItem: any;
  currentPage: number = 1;
  previousPageLink: string = '/balloon-refinance/get-started';
  accountDetails: any;
  commonNavTestID: string = 'wss-Brg-Vehicle-';
  vehicleUses: any = appConfig.vehicleUses;
  idExpandArr: Array<any> = ['brvExpandMake', 'brvExpandModel', 'brvExpandReg', 'brvExpandMileage', 'brvExpandVehicleUse'];
  idCollapseArr: Array<any> = ['brvCollapseMake', 'brvCollapseModel', 'brvCollapseReg', 'brvCollapseMileage', 'brvCollapseVehicleUse'];
  idPanelArr: Array<any> = ['brvInforMake', 'brvInforModel', 'brvInforReg', 'brvInforMileage', 'brvInforVehicleUse'];
  submitted: boolean =  false;
  make:string = '';
  model:string = '';
  registration:string = '';
  vehicleDetailsForm!: FormGroup;
  lastDateOfDecision: any;
  readonly destroy$ = new Subject();
  proposalData: ProposalRequest;
  serviceError: boolean = false;
  isReturnJourney: boolean = false;
  defaultTopMargin: boolean = false;

  constructor(
    private readonly bbContentService: ContentService,
    private router: Router,
    private datastore: DatastoreService,
    private tealiumService: TealiumUtagService,
    private formBuilder: FormBuilder,
    private balloonRefinanceSrv: BalloonRefinanceService,
    private changeDetectorRef: ChangeDetectorRef
    ) {
      this.proposalData = this.datastore.getProposalRequest();
    }  

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'Proposal - Vehicle details',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.setCurentPage();
    this.accountDetails = this.datastore.getAccountDetails();
    this.lastDateOfDecision = this.datastore.getLastDateForEocDecision(this.accountDetails?.final_payment_date);
    this.initVehicleForm();
    this.getVehicleDetails();
    this.setFormData();
    this.datastore.brReturnJourney$.subscribe((res: boolean) => {
      this.isReturnJourney = res;
      this.changeDetectorRef.detectChanges();
    })
    this.changeDetectorRef.markForCheck();
  }

  initVehicleForm() {
    this.vehicleDetailsForm = this.formBuilder.group({
      vehicleMake: ['', Validators.required],
      vehicleModel: ['', Validators.required],
      vehicleReg: ['', Validators.required],
      mileage: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(1000000), Validators.pattern(patternMap.wholeNumberPattern)])],
      vehicle_use: ['', Validators.required]
    })
  }

  getVehicleDetails() {
    let goodDesc = this.accountDetails?.goods_description;
    this.registration = this.accountDetails?.registration_no;  
    this.make = goodDesc?.split(' ')[0];
    let makeDesc = goodDesc?.indexOf(' ');
    this.model = goodDesc?.substr(makeDesc);
    this.vehicleDetailsForm?.controls['vehicleMake'].setValue(this.make);
    this.vehicleDetailsForm?.controls['vehicleModel'].setValue(this.model);
    this.vehicleDetailsForm?.controls['vehicleReg'].setValue(this.registration);
  }

  setCurentPage() {
    this.datastore.setBRCurrentPage(this.currentPage);
  }

  onSaveButtonClick(e: any) { 
    this.router.navigate(['/myaccount']);
  }

  onContinue(e: any) {
    this.submitted = true;
    if (this.vehicleDetailsForm.status === 'VALID') {
      this.saveDataProposal('/balloon-refinance-getdetails/about-you');
    } else {
      this.scrollToFirstInvalid();
    }    
  }

  scrollToFirstInvalid() {
    document.getElementsByClassName('ng-invalid')[1].scrollIntoView({behavior: 'smooth'});
  }

  toGetinTouch() {
    this.router.navigate(['/help-centre/get-in-touch'])
  }

  saveDataProposal(urlElem: string) {    
    this.proposalData.customer_id = this.datastore.getCustomerId();
    this.proposalData.vehicle_use = this.vehicleDetailsForm.value.vehicle_use,
    this.proposalData.mileage = this.vehicleDetailsForm.value.mileage
    
    this.balloonRefinanceSrv.postProposalDetails(this.proposalData).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.proposalData = this.datastore.getProposalRequest();
      this.isReturnJourney ?  
        this.router.navigate(['/balloon-refinance-getdetails/summary']) : this.router.navigate([urlElem])
    }, 
    (error: any) => {
      this.showError();
    });
  }

  showError(): void {
    this.datastore.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  setFormData() {
    this.vehicleDetailsForm.controls['mileage'].setValue(this.proposalData.mileage);
    this.vehicleDetailsForm.controls['vehicle_use'].setValue(this.proposalData.vehicle_use);
    this.vehicleDetailsForm.updateValueAndValidity();
    this.serviceError = false;
    this.changeDetectorRef.detectChanges();
  }
}
