<div class="container-column" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
    <div class="change-payment-container">
        <div class="container-row" *ngIf="serviceError">
            <bb-wss-banner-message></bb-wss-banner-message>
        </div>
        <div class="container-column">
            <div class="container-row section-title-container">
                <span class="section-title" test_id="changePaymentDatePageName">
                    <!-- {{content[0]?.cpd}} -->
                    {{paymentsWidgetItem.confirmChangePayment.changePaymentDatePageName}}
                </span>
            </div>
            <div class="mt-14px para-inline" test_id="nextPaymentDateInfoText">
                <!-- <span>{{content[0]?.ynextpdateshown}}
                    <a tabindex="0" title="{{content[0]?.cpdfaq}}">{{content[0]?.cpdfaq}}</a>.</span> -->
                <bb-content-html [html]="paymentsWidgetItem.confirmChangePayment.nextPaymentDateInfoText">
                </bb-content-html>
            </div>

            <div class="container-column mt-26px">
                <div class="section-sub-title">
                    <span class="next-pmt para-inline" test_id="nextPaymentLabelText">
                        <!-- {{content[0]?.urnextpmt}} -->
                        <bb-content-html [html]="paymentsWidgetItem.confirmChangePayment.nextPaymentLabelText">
                        </bb-content-html>
                    </span>
                </div>
                <!-- <span class="next-pmt">{{content[0]?.urnextpmt}}</span> -->
                <div class="container-row mt-17px">
                    <span class="event-icon"></span>
                    <div class="container-column margin-left-20">
                        <span class="bold-row" test_id="dueDateLabelText">
                            <!-- {{content[0]?.duedate}} -->
                            {{paymentsWidgetItem.confirmChangePayment.dueDateLabelText}}
                        </span>
                        <span>{{dueDate?.next_payment_date}}</span>
                    </div>
                </div>
                <div class="container-row">
                    <span class="material-icons">swap_vert</span>
                    <div class="container-column margin-left-20">
                        <span class="bold-row" test_id="extraAmountLabelText">
                            <!-- {{content[0]?.interest}} -->
                            {{paymentsWidgetItem.confirmChangePayment.extraAmountLabelText}}
                        </span>
                        <span>{{dueDate?.lost_interest}}</span>
                    </div>
                </div>
                <div class="container-row mt-20px">
                    <div (click)="checkbox.checked = !checkbox.checked"
                        [ngClass]="checkbox?.checked? 'custom-checkbox-debit': 'custom-checkbox-debit-unchecked'">
                        <span></span>
                    </div>
                    <span class="checkbox-notes para-inline" test_id="paybleAmountInfoText">
                        <!-- {{content[0]?.iunderstant}} -->
                        <bb-content-html [html]="paymentsWidgetItem.confirmChangePayment.paybleAmountInfoText">
                        </bb-content-html>
                    </span>
                </div>
            </div>

            <div class="container-row mt-26px">
                <button role="button" tabindex="0" class="wss-button" [disabled]="!checkbox.checked"
                    (click)="changePaymentDate()" id="change-payment-day-btn"
                    title="{{paymentsWidgetItem.confirmChangePayment.confirmNewPayDayButtonText}}">
                    <!-- {{content[0]?.cpdc} -->
                    {{paymentsWidgetItem.confirmChangePayment.confirmNewPayDayButtonText}}
                </button>
                <!-- <button *ngIf="!checkbox.checked" class="wss-button btn-disabled"
                title="{{content[0]?.cpdc}}">{{content[0]?.cpdc}}</button> -->
            </div>
            <div class="container-row back-to-direct-debit back-to-cp mt-20px">
                <a role="link" tabindex="0" (click)="navigateToCP()"
                    title="{{paymentsWidgetItem.confirmChangePayment.selectNewDayLinkText}}">
                    <!-- {{content[0]?.backtocpd}} -->
                    {{paymentsWidgetItem.confirmChangePayment.selectNewDayLinkText}}
                </a>
            </div>
        </div>
    </div>
</div>