import { Component, OnInit } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { appConfig } from '../../../../config/wss-app-constants';
import { DatastoreService } from '../../../../service/datastore.service';
import { MessageService } from '../../../../service/message.service';
import { Observable } from 'rxjs';
import { PaymentHolidayWidgetItem } from '../../../../model/payment-holiday-widget/payment-holiday-widget';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'term-extension-landing',
    templateUrl: './term-extension-landing.component.html',
    providers: [ ContentService ]
})

export class TermExtensionLandingComponent implements OnInit {
    typeHP: number = appConfig.hpCustomer;
    typePCP: number = appConfig.pcpCustomer;
    accountDetail: any = {};
    paymentHolidayWidgetItem$: Observable<PaymentHolidayWidgetItem | undefined> = this.bbContentService.getContent<PaymentHolidayWidgetItem>('paymentHolidayWidgetItem');

    constructor(
        private router: RouterService,
        private datastore: DatastoreService,
        private messageService: MessageService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService) { }

    ngOnInit() {
        this.accountDetail = this.datastore.getAccountDetails();
        this.accountDetail.scheme_code = parseInt(this.accountDetail.scheme_code);
        this.messageService.setCurrentStep(1);
        this.tealiumService.view(
            {
                JourneyName: 'Payment holiday',
                JourneyStepName: 'Term Extension',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
    }

    currentAgreement(){
        this.router.navigate(['current-agreement']);
    }
}