import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {RegistrationStoreModelService} from "../../../../registration-store/src/registration-store-model.service";
import {Router} from "@angular/router";
import {ItemModel} from "@backbase/foundation-ang/core";
import {ContentService as OldContentService} from "../../../../service/content.service";
import {ContentData} from '../../../../config/wss-app-constants';
import {Observable, Subject} from "rxjs";
import {takeUntil} from 'rxjs/operators';
import {isErrServer} from '../../../../common/util/util';
import {DatastoreService} from '../../../../service/datastore.service';
import { RegistrationWidgetItem } from '@wss/model/registrationToken';
import { ContentService } from '@backbase/universal-ang/content';
import { LoginWidgetItem } from '@wss/model/login-widget/login-widget';
import { TealiumUtagService } from '@wss/service/utag.service';
import { injectBrandContactContent } from '@wss/common/util/util';

@Component({
  selector: 'bb-login-faqs',
  templateUrl: './login-faqs.component.html',
  providers: [ ContentService ]
})

export class LoginFaqsComponent implements OnInit, OnDestroy {
  readonly destroy$ = new Subject();
  acordanceContent: any = "";

  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');
  
  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Login',
        JourneyStepName: 'Login FAQs',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.loginWidgetItem$.subscribe((pageItem: any) => {
      this.acordanceContent = injectBrandContactContent(pageItem.loginFaqs.loginAccordionContent, pageItem.brandContactDetails);
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();  
    this.destroy$.complete();    
  }

  constructor(
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
}
