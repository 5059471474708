<div class="content-wrapper" test_id="login-header">
  <div class="content-margin" >
    <div class="common-header-details">
      <div class="d-flex justify-content-center">
        <div class="circle-bg-profile text-center">
          <span class="circle-name-profile caps-title" data-cs-mask>{{personalDetails.shortName}}</span>
        </div>
      </div>
      <div class="d-flex justify-content-center header-name-common caps-title" data-cs-mask>{{personalDetails.fullname}}</div>
    </div>
    <bb-router-outlet></bb-router-outlet>
  </div> 
</div>