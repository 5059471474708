import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { ContentService } from '@backbase/universal-ang/content';
import { HelpCentreItem } from '@wss/model/helpCentre';
import { TealiumUtagService } from '@wss/service/utag.service';
import { appConfig, ContentData, WSSAppConstant } from 'libs/config/wss-app-constants';
import { Observable } from 'rxjs';
import { RouterService } from "@backbase/foundation-ang/core";
import { BudgetService } from '@wss/budget-cover-widgets/shared/service/budget.service';
import { BudgetPlanner } from '@wss/budget-cover-widgets/shared/model/budget.model';
import { DatastoreService } from '@wss/service/datastore.service';
import { OpenbudgetPlannerService } from '@wss/service/openbudgetplanner.service';
import {Location} from '@angular/common';

@Component({
  selector: 'bb-money-worries',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './money-worries.component.html',
  styles: [],
  providers: [ContentService],
})
export class MoneyWorriesComponent implements OnInit {
  portalName: string = '';
  bugdgetListResponse : any;
  hasBudgetWithDraft:boolean = false;
  accountDetails: any = {};
  helpCentreWidgetItem$: Observable<HelpCentreItem | undefined> = this.bbContentService.getContent<HelpCentreItem>(
    'helpCentreWidgetItem',
  );

  constructor(
    private readonly bbContentService: ContentService,
    private datastoreService: DatastoreService,
    private openbudgetPlannerService: OpenbudgetPlannerService,
    private budgetService:BudgetService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private _location: Location,
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
    private tealiumService: TealiumUtagService
  ) { }

  ngOnInit(): void {
    this.portalName = this.pageConfig.portalName || 'blackhorse';
    this.accountDetails = this.datastoreService.getAccountDetails();
    this.getBugetList();
    this.tealiumService.view(
        {
          JourneyName: 'Help Centre',
          JourneyStepName: 'Money Worries',
          CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
        }
    );
  }

  launchBudgetingTool() {
    this.datastoreService.deleteBudgetDetail();
    this.router.navigate(['help-centre/budget-home']);
    window.scroll(0, 0);
  }

  startNewForm(){
    this.datastoreService.deleteBudgetDetail()
    this.router.navigate(['/budget-form/1']);
  }

  returnToForm(){
    let budgetId = this.bugdgetListResponse.budget_planner_list[0].id;
    this.budgetService.fetchBudgetDetailById(budgetId)
    .subscribe((budgetResponse:BudgetPlanner) => {
        this.datastoreService.setBudgetDetail(budgetResponse);
        this.router.navigate(['/budget-form/'+budgetResponse.current_page]);
    },(error: any) => {
      this.showError();
    });
  }

  getBugetList(){
    this.budgetService.fetchbudgetList()
    .subscribe((budgetlist:any) => {
      if(budgetlist.budget_planner_list &&
        budgetlist.budget_planner_list.length > 0  &&
        budgetlist.budget_planner_list[0].status == "Draft" ){
            this.bugdgetListResponse = budgetlist;
            this.hasBudgetWithDraft = true;
            this.changeDetectorRef.detectChanges();
      }
    },(error: any) => {
      this.showError();
    });
  }

  showError(): void {
        this.datastoreService.setErrorServer();
        this.changeDetectorRef.detectChanges();
  }
  hideBudgetBanner(){
    this.hasBudgetWithDraft=false
  }
}
