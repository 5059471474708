import {  ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wss-address-display',
  templateUrl: './address-display.component.html'
})

export class AddressDisplayComponent implements OnInit {

  @Input() addressContent: any;
  @Input() displayClass: any;
  addressFlat: any;
  houseNumber: any;
  houseName: any;
  streetName: any;
  postTown: any;
  district: any;
  county: any;
  postCode: any;

  constructor(
    private changeDetector: ChangeDetectorRef
  ) {}
  
  ngOnInit(): void {
    this.addressFlat = this.addressContent?.flat_number;
    this.houseNumber = this.addressContent?.house_number;
    this.houseName = this.addressContent?.house_name;
    this.streetName = this.addressContent?.street_name;
    this.district = this.addressContent?.district;
    this.county = this.addressContent?.county;
    this.postTown = this.addressContent?.post_town;
    this.postCode = this.addressContent?.post_code;
    this.changeDetector.detectChanges();
  }

  checkAddress(addressElem: string) {
    let addressLine;
    switch (addressElem) {
      case 'Flat No':
        addressLine = this.addressFlat ? this.addressFlat : this.addressContent.flat_number;
        break;
      case 'House No':
        addressLine = this.houseNumber ? this.houseNumber : this.addressContent.house_number;  
        break;
      case 'House Name':
        addressLine = this.houseName ? this.houseName : this.addressContent.house_name
        break;
      case 'Street Name':
        addressLine = this.streetName ? this.streetName: this.addressContent.street_name;
        break;
      case 'Post Town':
        addressLine = this.postTown ? this.postTown : this.addressContent.post_town;
        break;
      case 'District':
          addressLine = this.district ? this.district : this.addressContent.district;
          break;
      case 'County':
        addressLine = this.county ? this.county : this.addressContent.county;
        break;
      case 'Post Code':
        addressLine = this.postCode ? this.postCode : this.addressContent.post_code;
        break;
    }
    if((addressLine !== null) && (addressLine) && (addressLine.length > 0) && (addressLine !== ' ')){
      return true;
    } else {
      return false;
    }
  }
}
