import { Component, OnInit } from '@angular/core';
import { DatastoreService } from '../../../../service/datastore.service';
import { Observable } from 'rxjs';
import { PaymentHolidayWidgetItem } from '../../../../model/payment-holiday-widget/payment-holiday-widget';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'cohort-first',
    templateUrl: './cohort-first.component.html',
    providers: [ContentService]
})

export class CohortFirstComponent implements OnInit {

    accountDetail: any = {};
    paymentHolidayWidgetItem$: Observable<PaymentHolidayWidgetItem | undefined> = this.bbContentService.getContent<PaymentHolidayWidgetItem>('paymentHolidayWidgetItem');

    constructor(
        private datastore: DatastoreService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService) { }

    ngOnInit() {
        this.accountDetail = this.datastore.getAccountDetails();
        this.tealiumService.view(
            {
                JourneyName: 'Payment holiday',
                JourneyStepName: 'Cohort First',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
    }
}