import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BalloonRefinanceService } from '../../services/balloon-refinance.service';

@Component({
  selector: 'bb-br-agreement-preview',
  templateUrl: 'br-agreement-preview.component.html',
  styles: [
  ]
})
export class BrAgreementPreviewComponent implements OnInit {

  constructor(private router: Router, 
    private balloonRefinanceService: BalloonRefinanceService,
    private changeDetector: ChangeDetectorRef) { }
  quoteData: any;
  ngOnInit(): void {
    this.quoteData = this.balloonRefinanceService.getQuoteData();
    this.changeDetector.detectChanges();
  }

  navigate(path: string): void {
    this.router.navigate(['/balloon-refinance/' + path]);
  }

  navigateToHome(): void {
    this.router.navigate(['/myaccount']);
  }

}
