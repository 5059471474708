<div class="mail-preference-container" *ngIf="digitalInboxWidgetItem$ | async as digitalInboxWidgetItem">
    <div class="container-column">
        <div class="container-row section-title-container">
            <span *ngIf="preferenceLaunchThrough === 'banners'" class="section-title" test_id="pageName">{{digitalInboxWidgetItem.preferenceResult.pageName}}</span>
            <span *ngIf="preferenceLaunchThrough === 'profile'" class="section-title" test_id="pageName">{{digitalInboxWidgetItem.preferenceResult.managePreferencePageName}}</span>
        </div>
        <div class="container-column mt-30px white-card">
            <div class="container-column">
                <span class="success_icon settings-updated"></span>
            </div>
            <div class="text-center mt-9px" *ngIf="preferenceLaunchThrough === 'banners'">
                <bb-content-html class="para-last-p-mb-0" [html]="digitalInboxWidgetItem.preferenceResult.successCardText"></bb-content-html>
            </div>
            <div class="text-center mt-9px" *ngIf="preferenceLaunchThrough === 'profile'">
                <bb-content-html *ngIf="selectedPreference === 'paperless'" class="para-last-p-mb-0" [html]="digitalInboxWidgetItem.preferenceResult.successTextManagePaperlessSingleAgmt"></bb-content-html>
                <bb-content-html *ngIf="selectedPreference === 'post'" class="para-last-p-mb-0" [html]="digitalInboxWidgetItem.preferenceResult.successTextManagePostSingleAgmt"></bb-content-html>
                <bb-content-html *ngIf="selectedPreference === 'multi'" class="para-last-p-mb-0" [html]="digitalInboxWidgetItem.preferenceResult.successTextManagePreferenceMultiAgmt"></bb-content-html>
            </div>
        </div>
        <div class="container-row mt-23px">
            <bb-content-html *ngIf="preferenceLaunchThrough === 'banners'" [html]="digitalInboxWidgetItem.preferenceResult.rememberText"></bb-content-html>
            <ng-container *ngIf="preferenceLaunchThrough === 'profile'">
                <bb-content-html *ngIf="selectedPreference === 'paperless'" [html]="digitalInboxWidgetItem.preferenceResult.rememberTextManagePaperlessSingleAgmt"></bb-content-html>
                <bb-content-html *ngIf="selectedPreference === 'post'" [html]="digitalInboxWidgetItem.preferenceResult.rememberTextManagePostSingleAgmt"></bb-content-html>
                <bb-content-html *ngIf="selectedPreference === 'multi'" [html]="digitalInboxWidgetItem.preferenceResult.rememberTextManagePreferenceMultiAgmt"></bb-content-html>
            </ng-container>
        </div>
        <div class="container-row desktop-button-center result">
            <button (click)="backToHome()" class="wss-button" tabindex="0" role="button">{{digitalInboxWidgetItem.preferenceResult.buttonText}}</button>
        </div>
        <div class="flex-horizontal-center text-center result" *ngIf="preferenceLaunchThrough === 'profile'">
            <bb-content-html [html]="digitalInboxWidgetItem.preferenceResult.needHelpLink"></bb-content-html>
        </div>
    </div>
</div>