export class BrandContactDetailsItem {
    brandWiseAddress: any = "";
    brandWiseContactNumber: any = "";
    brandWiseNumberWithoutLink?: any = "";
    brandWiseBikeNumberWithoutLink?: any = "";
    brandWiseContactEmail: string = "";
    brandWiseBankDetails: any= "";
    brandWiseCfaNumber: any = "";
    brandWiseCfaNumberWithoutLink?: any = "";
    brandWiseFreeTRSNumber: any = "";
    portalName: string = "";
    mainCollectionsLineNumber: string = "";
    legalNumber: string = "";
    budgetToolLink: any = "";
    privacyPolicyLink: any = "";
    dpnLink?: any = "";
    brDisclaimerText?: any = "";
    complaintNumber?: any = "";
    dpnPersonalInfoSentence?: any = "";
    dpnContactTelNumber?: any = "";
    brandWiseAccessibilityNumber: any = "";
    brandWiseSqdComplaintsEmail?: any = "";
    brandWiseServicingComplaintsEmail?: any = "";
    brandName?: any = "";
    accessibilitySupportNumber?: any = "";
    vehicleComplaintsAddress?: any = "";
}