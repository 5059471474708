export class DueDate {
    new_due_day: string = '';
    lost_interest: string = '';
    next_payment_date: string = '';
}

export const smsTemplateId: string = "BHDUE01";
export const changePaymentEmailTemplate: any = {
    email_title: "ChangePaymentDay",
    email_subject: "ChangePaymentDay"
};