import {OtpGenerationResponse, User} from "./user";
import {UserSearchRequest} from "../../registration-widget/src/user-search-request";

export interface RegistrationState {
  user?: User;
  users?: Array<User>;
  selectedUserId?: string;
  title?: string;
  userSearchRequest?: UserSearchRequest;
  otp?: number;
  step?: number;
}

export const initialState: RegistrationState = {
  user: undefined,
  users: [],
  selectedUserId: undefined,
  title: undefined,
  userSearchRequest: undefined
}
