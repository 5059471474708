import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { BudgetFormItem } from '@wss/model/budget-form-widget/budgetForm'
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
declare var d3: any;

@Component({
  selector: 'bb-budget-eom-widget',
  templateUrl: './budget-eom-widget.component.html',
  providers: [ContentService]
})
export class BudgetEOMWidgetComponent implements OnInit {
  accountDetails: any = {}; 
  budgetSummaryData: any = {}

  constructor(
    private readonly bbContentService: ContentService,
    private datastoreService: DatastoreService,
    private route: ActivatedRoute,
    private router: Router,
    private tealiumService: TealiumUtagService) {}

    ngOnInit(): void {
      this.accountDetails = this.datastoreService.getAccountDetails();
      this.budgetSummaryData = this.datastoreService.getSubmitBudgetDetail()
      this.calculateMoneyOut();
      /**
       * If status is draft then will not allow to user
       * to redirect manually on Summary Page
       */
      if(isNaN(this.budgetSummaryData.moneyOut)) {
        this.router.navigate(['help-centre/money-worries']);
      }
      this.tealiumService.view(
        {
          JourneyName: 'Budget EOM',
          JourneyStepName: 'Budget Cover EOM',
          CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
        }
      );
    }

  budgetFormItem$: Observable<BudgetFormItem | undefined> = this.bbContentService.getContent<BudgetFormItem>('BudgetFormItem');


  calculateMoneyOut() {
    this.budgetSummaryData.moneyOut = Number(this.budgetSummaryData.rent_motgage) + Number(this.budgetSummaryData.household_bills) + Number(this.budgetSummaryData.living_costs) + Number(this.budgetSummaryData.finance_payments)
  }


}
