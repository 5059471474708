<div *ngIf="complaintWidgetItem">
  <div class="container-column complaint-submission-page">
    <div class="complaint-submission-message" test_id="complaintSuccessUpdateInfoText">
      <div class="mb-25px"><span class="wss-success-icon complaint-submission-success"></span></div>
      <div>
        <span class="complaint-submission-success-message">
          <bb-content-html [html]="complaintWidgetItem.complaintSubmission.submissionResultText"></bb-content-html>
        </span>
      </div>

      <div class="container-row content-center back-home-button">
        <button role="button" class="wss-button btn-width warn-btn-dif-view button-width-padding"
          test_id="backToHomeButtonText" (click)="backToHomeClick()" id="ComplaintSubmissionBackToHomeButton"
          title="{{complaintWidgetItem.complaintSubmission.backToHomeButtonText}}">
          {{complaintWidgetItem.complaintSubmission.backToHomeButtonText}}
        </button>
      </div>
    </div>

    <div class="info-text-wrapper">
      <div class="info-text mt-20px">
        <bb-content-html [html]="complaintWidgetItem.complaintSubmission.whatHappensNextText"></bb-content-html>
      </div>
    </div>
  </div>
</div>