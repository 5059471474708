import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaveAndExitComponent } from './save-and-exit-modal.component';
@Injectable({
  providedIn: 'root',

})

export class saveAndExitService {

  constructor(private modalService: NgbModal) { }
  public saveObject!: object;

  openModalFromService(item: object, pageObject?: object, formtype?: any) {
    this.saveObject = pageObject || {}
    const modalRef = this.modalService.open(SaveAndExitComponent, {
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'md'
    })

    modalRef.componentInstance.fromParent = item;
    modalRef.componentInstance.formValue = pageObject;
    modalRef.componentInstance.formType = formtype;
  }
}

