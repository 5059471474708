import { Injectable } from '@angular/core';
import { DatastoreService } from '@wss/service/datastore.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public concernType: BehaviorSubject<string> = new BehaviorSubject('');
  public formData: { [formId: string]: any } = {};

  constructor(private dataStoreService: DatastoreService) {}

  saveFormData(formId: any, data: any) {
    this.formData[formId] = data;
    if (this.formData['concernSelectionForm']) {
      sessionStorage.setItem('ConcernType', this.formData['concernSelectionForm'].concernType);
      this.concernType.next(sessionStorage.getItem('ConcernType') as string);
    }
    this.dataStoreService.setComplaintsFormData(this.formData);
  }

  getFormData(formId: any) {
    return this.formData[formId] || {};
  }

  getComplaintsFormData() {
    return this.formData;
  }

  getConcernTypeSelectValue() {
    return this.formData['concernSelectionForm'] ? this.formData['concernSelectionForm'].concernType : '';
  }

  deleteAllFormData() {
    const formIds = Object.keys(this.formData);
    formIds && formIds.forEach(formId => {
      delete this.formData[formId];
    })
  }
}
