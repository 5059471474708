<div *ngIf="transactionWidgetItem$ | async as transactionWidgetItem">
    <div class="transaction-top-container">
        <div class="content-margin">
            <div class="content-wrapper-container" *ngIf="apiCallCompleted">
                <div class="reg-row plr-10pt" role="alert" *ngIf="serviceError">
                    <bb-wss-banner-message></bb-wss-banner-message>
                </div>
                <!-- request status success-->
                <div class="container-row request-status" *ngIf="requestStatus?.show == true && requestStatus?.success">
                    <div class="status-1">
                        <span class="material-icons">check_circle_outline</span>
                    </div>
                    <div class="status-2" test_id="PostStatementMessage">
                        <span>
                            <bb-content-html [html]="transactionWidgetItem.transactionComponent.PostStatementMessage">
                            </bb-content-html>
                        </span>
                    </div>
                    <div class="status-3">
                        <span class="material-icons" (click)="closeStatusBox()">clear</span>
                    </div>
                </div>

                <!-- request status failure -->
                <div class="container-row request-status request-status-failure"
                    *ngIf="requestStatus?.show == true && !requestStatus?.success">
                    <div class="status-1 failure-color">
                        <span class="material-icons">error_outline</span>
                    </div>
                    <div class="status-2" test_id="PostStatementError">
                        <span>
                            <bb-content-html [html]="transactionWidgetItem.transactionComponent.PostStatementError">
                            </bb-content-html>
                            <!--<a class="faq-link" tabindex="0" title="{{transactionWidgetItem.transactionComponent.contactUs}}">{{transactionWidgetItem.transactionComponent.contactUs}}</a>.-->
                        </span>
                    </div>
                    <div class="status-3">
                        <span class="material-icons" (click)="closeStatusBox()">clear</span>
                    </div>
                </div>
                <div class="section-title-container mb-20px" test_id="pageTitle">
                    <span class="section-title">{{transactionWidgetItem.transactionComponent.pageTitle}}</span>
                </div>
                <div class="container-column account-balance-section d-inline-block" id="balance-text"
                    test_id="Balance">
                    <bb-content-html [html]="transactionWidgetItem.transactionComponent.Balance"></bb-content-html>
                    <strong class="balance-amount"
                        test_id="current_balance">{{acccountDetail?.current_balance}}</strong>
                </div>
                <div class="container-column question-statement">
                    <div class="container-row transaction-text-long-margin-top transaction-dif-container"
                        test_id="QuestionText">
                        <bb-content-html [html]="transactionWidgetItem.transactionComponent.QuestionText">
                        </bb-content-html>
                        <!--<a class="faq-link" tabindex="0" title="{{transactionWidgetItem.transactionComponent.transactionFAQ}}">{{transactionWidgetItem.transactionComponent.transactionFAQ}}</a>-->
                    </div>
                    <div class="container-row request-statement transaction-dif-container para-inline post-statement">
                        <button id="transactions-btn" class="wss-button-secondary" (click)="openModal(content)"
                            [disabled]="isRequestedToday || !((acccountDetail?.is_ppi_complaint === false) && (acccountDetail?.allow_statement_request === true))">
                            <ng-container *ngIf="!isRequestedToday" test_id="postStatement">
                                {{transactionWidgetItem.transactionComponent.postStatement}}</ng-container>
                            <ng-container *ngIf="isRequestedToday" test_id="StatementPostText">
                                <bb-content-html [html]="transactionWidgetItem.transactionComponent.StatementPostText">
                                </bb-content-html>
                            </ng-container>
                        </button>
                    </div>
                </div>
                <!-- Transactions -->
                <div #scrollFrame class="container-column" *ngIf="transactions?.length !== 0">
                    <div class="container-row transaction-since mt-20px" *ngIf="transactions?.length !== 0"
                        test_id="TransactionDateText">
                        <strong>
                            <span>
                                <bb-content-html
                                    [html]="transactionWidgetItem.transactionComponent.TransactionDateText">
                                </bb-content-html> {{unsortedTransactions[unsortedTransactions.length -
                                1].transaction_date | date: 'dd MMM yyy'}}
                            </span></strong>
                    </div>
                    <div class="container-column statements-grid">
                        <ng-container *ngFor="let transaction of transactions;">
                            <div class="container-row statement-date">
                                <strong>{{transaction?.transaction_date | date: 'dd MMM yyy'}}</strong>
                            </div>
                            <div class="container-row statement-text">
                                <span class="remark">{{transaction?.transaction_description}}</span>
                                <span class="amount">{{transaction?.transaction_value}}</span>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="totalNoOfRecords !== transactions?.length;else allshown"
                        class="container-row statement-down-arrow"
                        [attr.style]="isLoaderActive? 'margin-bottom: 0px;':'margin-bottom: 93px;'">
                        <span (click)="loadMoreRecords()" class="material-icons">expand_more</span>
                    </div>
                    <ng-template #allshown>
                        <div class="container-row statement-down-arrow all-shown"
                            [attr.style]="isLoaderActive? 'margin-bottom: 0px;':'margin-bottom: 93px;'">
                            <span>
                                <bb-content-html [html]="transactionWidgetItem.transactionComponent.AllRecordsShown">
                                </bb-content-html>
                            </span>
                        </div>
                    </ng-template>

                    <div class="container-row statements-loader" [hidden]="!isLoaderActive">
                        <span></span>
                    </div>
                </div>

                <!-- No Transactions -->
                <div class="container-column no-transactions" *ngIf="transactions?.length === 0"
                    test_id="NoTransaction">
                    <span class="not-found"></span>
                    <bb-content-html [html]="transactionWidgetItem.transactionComponent.NoTransaction">
                    </bb-content-html>
                    <bb-content-html [html]="transactionWidgetItem.transactionComponent.needHelp"></bb-content-html>
                </div>
            </div>
        </div>
    </div>

    <ng-template #content let-modal>
        <div class="container-column request-statement-modal">
            <div class="container-row modal-header-c" test_id="postStatementTitle">
                <span class="heading">{{transactionWidgetItem.transactionComponent.postStatementTitle}}</span>
                <span class="material-icons" (click)="modal.dismiss('Cross click');">clear</span>
            </div>
            <div class="container-column modal-body-c" test_id="PostStatementText" id="PostStatementText">
                <bb-content-html [html]="transactionWidgetItem.transactionComponent.PostStatementText">
                </bb-content-html>
                <a (click)="checkUrProfile()" title="{{transactionWidgetItem.transactionComponent.checkProfileLink}}"
                    test_id="checkProfileLink"
                    id="checkout">{{transactionWidgetItem.transactionComponent.checkProfileLink}}</a>
            </div>
            <div class="container-row modal-footer-c" test_id="postStatement">
                <button class="wss-button"
                    (click)="requestStatement();modal.dismiss('Cross click');">{{transactionWidgetItem.transactionComponent.postStatement}}</button>
            </div>
        </div>
    </ng-template>
</div>