import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { RuntimeEnv } from '@wss/config/wss-app-constants';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable } from 'rxjs';
import { EndOfContractService } from '../../services/end-of-contract.service';

@Component({
  selector: 'bb-good-condition-guide-url',
  template: `
    <a [href]='linkRoute' title='Good condition guide'>{{linkText}}</a>
  `,
  providers: [ContentService]
})
export class GoodConditionGuideUrlComponent implements OnInit {
  @Input() linkText: any;
  @Input() vehicleType: any;
  contentItem: any;
  linkRoute: string = '#';
  portalName: string = window.BB?.config?.portalName ? window.BB?.config.portalName : '';
  accountDetails: any;
  dealersDetails: any;
  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');

  constructor(
    private readonly bbContentService: ContentService,
    private eocService: EndOfContractService,
    public changeDetectorRef: ChangeDetectorRef,
    public datastoreService: DatastoreService
  ) { }

  ngOnInit(): void {
    this.accountDetails = this.datastoreService.getAccountDetails();
    this.dealersDetails = this.datastoreService.getDealersDetails();
    if (!this.dealersDetails?.dlr_type) {
      this.getDealersDetails();
    } else {
      this.checkGoodConditionUrl();
    }
  }

  getDealersDetails() {
    this.eocService.getDealersDetails(this.accountDetails?.dealer_number)
      .subscribe((res: any) => {
        this.dealersDetails = res;
        this.checkGoodConditionUrl();
        this.changeDetectorRef.detectChanges();
      }, (error: any) => { })
  }

  checkGoodConditionUrl() {
    this.endOfContractWidgetItem$.subscribe((res: any) => {
      this.contentItem = res;
      this.linkRoute = this.eocService.getGoodConditionLink(this.portalName, this.dealersDetails?.dlr_type, this.contentItem?.eocGoodConditionGuide);
      this.changeDetectorRef.detectChanges();
    })
  }

}
