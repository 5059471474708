import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserSearchRequest } from "../../user-search-request";
import { RegistrationStoreModelService } from "@wss/registration-store/src/registration-store-model.service";
import { Subject, Observable, of } from "rxjs";
import { User } from "@wss/registration-store/src/user";
import { ItemModel, RouterService } from "@backbase/foundation-ang/core";
import { ContentService as OldContentService } from "@wss/service/content.service";
import { MessageService } from '@wss/service/message.service';
import { patternMap } from '@wss/config/wss-app-constants';
import { RegisterService } from '../../service/register.service';
import { RegisterDatastoreService } from '../../service/register-datastore.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { Error } from '@wss/model/error';
import * as moment from 'moment';
import { isNotEmpOrNullOrUndef, isErrServer, autoTabPress } from '@wss/common/util/util';
import { CustomerToken, RegistrationWidgetItem } from '@wss/model/registrationToken';
import { BannerMessage } from '@wss/model/bannerMessage';
import { RequestService } from '@wss/service/request.service';
import { takeUntil } from 'rxjs/operators';
import { ContentService } from '@backbase/universal-ang/content';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { TealiumUtagService } from '@wss/service/utag.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'bb-wss-user-search',
  templateUrl: './user-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ContentService]
})
export class UserSearchComponent implements OnInit, OnDestroy {
  errorCode: any;
  encryptedDob: any;
  encryptedPostcode: any;
  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    if (e.srcElement.maxLength === e.srcElement.value.length) {
      e.preventDefault();
      autoTabPress();
    }
  }

  registrationForm: FormGroup;
  users: Observable<Array<User> | undefined> = this.registrationService.users;
  userSearchRequest: Observable<UserSearchRequest | undefined> = this.registrationService.userRequests;
  userList: CustomerToken[] = [];
  userDetail = {};
  authorisation: any;
  account: string = '';
  multipleUser: boolean = false;
  serviceError: boolean = false;
  error: Error[] = [];
  submitBtn: boolean = true;
  validDate: boolean = false;
  validPostcode: boolean = false;
  customerid: string = '';
  bannerMessage: BannerMessage = new BannerMessage();
  hasPostCodeError: boolean = false;
  hasDOBError: boolean = false;
  maxDOBYearValue: any;
  portalName: string = this.pageConfig.portalName || 'blackhorse';


  readonly destroy$ = new Subject();
  popoverArrowStyle: string = '';
  popoverSelf: any;
  tooltipCloseStyle: string = "font-size: 18px; margin-top: -10px !important;";

  // readonly textContent$ = this.contentService.getStaticContent(this.contentTxt, this.contentFolder);

  registrationWidgetItem$: Observable<RegistrationWidgetItem | undefined> = this.bbContentService.getContent<RegistrationWidgetItem>('registrationWidgetItem');

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Registration',
        JourneyStepName: 'User Search',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.routerAct.queryParams.subscribe(param => {
      if (param['date_of_birth'] && param['post_code']) {
        this.encryptedDob = param['date_of_birth'];
        this.encryptedPostcode = param['post_code'];
      }
    });
    this.getAuthorised();
    this.tealiumService.view({ 'page_type': 'registration_user_search' });
    this.setErrorMsg();
    this.dateByUser();
  }

  ngOnDestroy(): void {
    this.userSearchRequest = of(undefined);
    this.users = of(undefined);
    this.destroy$.next();
    this.destroy$.complete();
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: RouterService,
    private itemModel: ItemModel,
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
    private registrationService: RegistrationStoreModelService,
    private contentService: OldContentService,
    private registerService: RegisterService,
    private datastoreService: RegisterDatastoreService,
    private messageService: MessageService,
    private requestService: RequestService,
    private refChange: ChangeDetectorRef,
    private cmndatastoreService: DatastoreService,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    config: NgbPopoverConfig,
    private routerAct: ActivatedRoute
  ) {
    this.getValidYearDOB();
    this.registerService.getAuthorisation();
    this.registrationForm = this.formBuilder.group({
      agreementno: [
        '',
        Validators.compose([
          Validators.pattern(patternMap.digitsAccount)
        ]),
      ],
      day: [
        '',
        Validators.compose([
          Validators.pattern(patternMap.dayPattern),
          // Validators.min(1),
          // Validators.max(31)
        ]),
      ],
      month: [
        '',
        Validators.compose([
          Validators.pattern(patternMap.monthPattern),
          // Validators.min(1),
          // Validators.max(12)
        ]),
      ],
      year: [
        '',
        Validators.compose([
          // Validators.pattern(patternMap.yearPattern),
          Validators.required, Validators.maxLength(4), Validators.minLength(4), Validators.max(this.maxDOBYearValue), Validators.min(1900)
          // Validators.min(1900),
          // Validators.max(2004)
        ]),
      ],
      postcode: [
        '',
        Validators.compose([
          Validators.pattern(patternMap.postCode)
        ]),
      ],
    });
    this.itemModel.property('searchUserTitle').pipe(takeUntil(this.destroy$)).subscribe((value) => { this.registrationService.updateTitle(value as string); });
    this.registrationService.updatedStepper(1);
  }



  setErrorMsg() {
    this.bannerMessage.closeIconClass = "clear";
    this.bannerMessage.infoTypeClass = "icon_warning_amber mgn-top-15px";
    this.bannerMessage.leftSectionColorClass = "message-warning";
    this.bannerMessage.titleText = 'Customer already registered';
    this.bannerMessage.isBannerClosed = false;
    this.messageService.setBannerMessage(this.bannerMessage);
  }

  showError(errStatus: number): void {
    if (isErrServer(errStatus)) {
      this.cmndatastoreService.setErrorServer();
      this.serviceError = true;
      this.refChange.detectChanges();
    }
  }

  isFieldValid(fieldName: string) {
    const control = this.getControl(fieldName);
    return control && (control.valid || control.untouched);
  }

  private getControl(fieldName: string) {
    return this.registrationForm?.controls[fieldName];
  }

  hasError(field: string, type: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }
    return fieldControl.errors[type];
  }

  hasRequiredError(field: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }
    return fieldControl.errors.required;
  }

  hasMinError(field: string) {
    return this.hasError(field, 'min');
  }

  hasMaxError(field: string) {
    return this.hasError(field, 'max');
  }

  setHeaders(token: any): void {
    this.datastoreService.setToken(token);
  }

  getAuthorised(): void {
    this.registerService.getAuthorisation().pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      this.serviceError = false;
      this.authorisation = data;
      let token = this.authorisation.headers.get('x-authorization');
      this.setHeaders(token);
      if (this.encryptedDob && this.encryptedPostcode) {
        this.registerService.getCustomersData(this.encryptedDob, this.encryptedPostcode).subscribe((val: any) => {
          let dob = val.dateOfBirth.split('/');
          this.registrationForm.controls['day'].patchValue(dob[0]);
          this.registrationForm.controls['month'].patchValue(dob[1]);
          this.registrationForm.controls['year'].patchValue(dob[2]);
          this.registrationForm.controls['postcode'].patchValue(val.postCode);
          this.refChange.detectChanges();
          this.callCustomerDetails();
        },
          (error: any) => {
            this.router.navigate(['no-user']);
          });
      }
    },
      (error: any) => {
        this.errorCode = error.error?.code ? error.error.code : error.status;
        this.showError(error.status);
      });
  }

  checkValidDate(day: string, month: string, year: string): boolean {
    let dateVal = day + '/' + month + '/' + year;
    let currentYear: number = new Date().getFullYear();
    let yearVal = Number(year);
    const dateFormat = 'DD/MM/YYYY';
    if (isNotEmpOrNullOrUndef(day) && isNotEmpOrNullOrUndef(month) && isNotEmpOrNullOrUndef(year) && year.length === 4) {
      if ((currentYear - yearVal) > 17) {
        let dateValid = moment(dateVal, dateFormat);
        return dateValid.isValid();
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkPostcode(postcode: string): boolean {
    if (postcode && postcode.match(patternMap.postCode)) {
      return true;
    } else {
      return false;
    }
  }

  canSubmitButtonEnabled() {
    this.showHideError();
    if (this.validDate && this.validPostcode && !this.hasDOBError) {
      this.submitBtn = false;
    } else {
      this.submitBtn = true;
    }
  }

  dateByUser() {
    const day = this.registrationForm.get('day');
    const month = this.registrationForm.get('month');
    const year = this.registrationForm.get('year');
    const postCode = this.registrationForm.get('postcode');

    day?.valueChanges.forEach(
      (value: string) => {
        this.validDate = this.checkValidDate(value, this.registrationForm.get('month')?.value, this.registrationForm.get('year')?.value);
        this.validPostcode = this.checkPostcode(this.registrationForm.get('postcode')?.value);
        this.canSubmitButtonEnabled();
      }
    );
    month?.valueChanges.forEach(
      (value: string) => {
        this.validDate = this.checkValidDate(this.registrationForm.get('day')?.value, value, this.registrationForm.get('year')?.value);
        this.validPostcode = this.checkPostcode(this.registrationForm.get('postcode')?.value);
        this.canSubmitButtonEnabled();
      }
    );
    year?.valueChanges.forEach(
      (value: string) => {
        this.validDate = this.checkValidDate(this.registrationForm.get('day')?.value, this.registrationForm.get('month')?.value, value);
        this.validPostcode = this.checkPostcode(this.registrationForm.get('postcode')?.value);
        this.canSubmitButtonEnabled();
      }
    );
    postCode?.valueChanges.forEach(
      (value: string) => {
        this.validDate = this.checkValidDate(this.registrationForm.get('day')?.value, this.registrationForm.get('month')?.value, this.registrationForm.get('year')?.value);
        this.validPostcode = this.checkPostcode(value);
        this.canSubmitButtonEnabled();
      }
    );
  }

  getCustomer() {
    this.registerService.getCustomerDetails().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.serviceError = false;
      this.router.navigate(['user-detail']);
    },
      (error: any) => {
        this.showError(error.status);
      });
  }

  buildParam() {
    let dayVal = this.registrationForm.get('day')?.value;
    let monthVal = this.registrationForm.get('month')?.value;
    let yearVal = this.registrationForm.get('year')?.value;
    let postcodeVal = this.registrationForm.get('postcode')?.value;
    dayVal = dayVal.length === 1 ? '0' + dayVal : dayVal;
    monthVal = monthVal.length === 1 ? '0' + monthVal : monthVal;
    let qString = 'date_of_birth=' + dayVal + '/' + monthVal + '/' + yearVal + '&post_code=' + postcodeVal;
    this.datastoreService.setParamCust(qString);
  }

  callCustomerDetails() {
    this.multipleUser = this.datastoreService.getMultiUser();
    this.refChange.detectChanges();
    if (this.multipleUser) {
      this.itemModel.property('details').pipe(takeUntil(this.destroy$)).subscribe((value) => { this.registrationService.updateTitle(value as string); });
    } else {
      this.serviceError = this.datastoreService.getCustomerStatus();
      this.refChange.detectChanges();
      if (this.serviceError === false) {
        this.getCustomer();
      }
    }
  }

  submit() {
    if (!this.multipleUser) {
      this.buildParam();
      this.registerService.getCustomers().pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.callCustomerDetails();
      },
        (error: any) => {
          this.router.navigate(['no-user']);
        });
    } else {
      this.requestService.setMultiCustReq(this.registrationForm.get('agreementno')?.value);
      this.registerService.getCustomersMultiple().pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.datastoreService.setMultiUser(false);
        this.serviceError = false;
        this.refChange.detectChanges();
        if (this.datastoreService.getMultiValid() === 'Invalid') {
          this.router.navigate(['no-user']);
        } else {
          this.serviceError = this.datastoreService.getCustomerStatus();
          this.refChange.detectChanges();
          if (this.serviceError === false) {
            this.getCustomer();
          }
        }
      },
        (error: any) => {
          this.router.navigate(['no-user']);
        });
    }
  }

  /** common method for help ** */
  getContentValue(key: string) {
    return key;
  }

  showHideError() {
    const postCodeValue = this.registrationForm.get('postcode')?.value;
    const dayValue = this.registrationForm.get('day')?.value;
    const monthValue = this.registrationForm.get('month')?.value;
    const yearValue = this.registrationForm.get('year')?.value;

    const postCodeRegex = patternMap.postCode;
    const dayRegex = patternMap.dayPattern;
    const monthRegex = patternMap.monthPattern;
    // const yearRegex = patternMap.yearPattern;

    if (!postCodeValue || postCodeValue?.match(postCodeRegex)) {
      this.hasPostCodeError = false;
    } else {
      this.hasPostCodeError = true;
    }

    if (!dayValue || dayValue?.match(dayRegex)) {
      this.hasDOBError = false;
    } else {
      this.hasDOBError = true;
      return;
    }

    if (!monthValue || monthValue?.match(monthRegex)) {
      this.hasDOBError = false;
    } else {
      this.hasDOBError = true;
      return;
    }

    if (!yearValue || this.registrationForm.get('year')?.valid) {
      this.hasDOBError = false;
    } else {
      this.hasDOBError = true;
      return;
    }
  }

  getValidYearDOB() {
    let currentYear = (new Date()).getFullYear();
    this.maxDOBYearValue = currentYear - 18;
  }

  openPopover(popover: any, event: MouseEvent) {
    popover.open();
    this.popoverSelf = popover;
  }

  closePopover() {
    this.popoverSelf.close();
  }

}
