import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[autoFocusNext]'
})
export class AutoFocusNextDirective {
    @Input() autoFocusNext: string = '';

    constructor() {
    }

    @HostListener('keyup', ['$event']) onKeyUp(event: any) {
        let max = parseInt(this.autoFocusNext);
        if(event.target.value.length === max){
            event.srcElement.nextSibling.focus();
        }
    }
}