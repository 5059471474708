import { ChangeDetectorRef, Component, OnInit, Injectable } from '@angular/core';
// import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ItemModel, RouterService } from '@backbase/foundation-ang/core';
import { ContentService as OldContentService } from "../../../../service/content.service";
import { ContentService } from '@backbase/universal-ang/content';
import { ContentData, WSSAppConstant, patternMap } from '../../../../config/wss-app-constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserDetailService } from '../../services/update-detail.services';
import { MessageService } from '../../../../service/message.service';
import { ServiceException } from '../../../../model/service-exception';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getDigitSuffix, getErrorImageClass, isErrServer, isNotEmpOrNullOrUndef } from '../../../../common/util/util';
import {
  NgbCalendar,
  NgbDateStruct,
  NgbDatepickerConfig,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { headerContentWidgetItem, UpdateContactItem } from '@wss/model/content/update-contact-details';
import { Observable } from 'rxjs';
import { DatastoreService } from '@wss/service/datastore.service';
import { ActivatedRoute } from '@angular/router';
import { TealiumUtagService } from '@wss/service/utag.service';


@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? this.prefixZero(date.day) + this.DELIMITER + this.prefixZero(date.month) + this.DELIMITER + date.year : '';
  }
  prefixZero(day: any) {
    if (parseInt(day) < 10) {
      return '0' + day;
    }
    else {
      return day;
    }
  }
}

@Component({
  selector: 'bb-wss-user-details',
  templateUrl: './user-address.component.html',
  providers: [
    // { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }, 
    ContentService
  ],
})
export class UserDetailComponent implements OnInit {
  showAddress: boolean = true;
  locationForm: FormGroup = new FormGroup({});
  customer_address: any = {};
  public prePopAddres: any = {};
  brandName: string = window?.BB?.config?.portalName;
  serviceException: ServiceException = new ServiceException();

  address: any = {};
  contact_details = {};
  errorMsg: string = '';
  successStatus: boolean = false;
  errorStatus = false;
  showForm: boolean = true;
  errorMsgDate: string = '';
  showFormDate: boolean = false;
  customerDetail: any = {};
  personalDetails: any = {};
  model: any;
  selectedFuture: boolean = false;
  futureDate: string = '';
  calendarSelect: boolean = false;


  headerContentWidgetItem$: Observable<headerContentWidgetItem | undefined> = this.bbContentService.getContent<headerContentWidgetItem>('headerContentWidgetItem');
  headerContentWidgetItem: any;
  constructor(
    private formBuilder: FormBuilder,
    private bbContentService: ContentService,
    private updateSrvc: UserDetailService,
    public changeDetectorRef: ChangeDetectorRef,
    private router: RouterService,
    private messageService: MessageService,
    private modalService: NgbModal,
    private calendar: NgbCalendar,
    private config: NgbDatepickerConfig,
    private dataStore: DatastoreService,
    private route:ActivatedRoute,
    private tealiumService: TealiumUtagService
  ) {
    const current = new Date();
    config.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
    config.outsideDays = 'hidden';
  }

  ngOnInit() {
    this.tealiumService.view(
      {
        JourneyName: 'Header',
        JourneyStepName: 'Update Address',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.route?.params.subscribe(val => {
      // put the code from `ngOnInit` here
      this.refreshPage();
      this.changeDetectorRef.detectChanges();
    });
    this.customerDetail = this.dataStore.getCustomerDetail();
    if (this.customerDetail && this.customerDetail.addresses) {
      this.address = this.customerDetail?.addresses?.address_detail;
      this.address.house_name = this.address?.house_name.toLowerCase();
      this.address.county = this.address?.county.toLowerCase();
      this.address.street_name = this.address?.street_name.toLowerCase();
    }

    this.personalDetails.fullname =
      this.customerDetail?.individual?.first_name + ' ' + this.customerDetail?.individual?.surname;
    this.personalDetails.fullname = this.personalDetails.fullname.toLowerCase();
    this.personalDetails.shortName =
      this.customerDetail?.individual?.first_name[0] + this.customerDetail?.individual?.surname[0];
    this.address = this.customerDetail?.addresses.address_detail;
    this.contact_details = this.customerDetail?.contact_details;
    this.customer_address = this.customerDetail;
    this.locationForm = this.formBuilder.group({
      post_code: ['', Validators.compose([Validators.pattern(patternMap.postCode)])],
      house_name: ['', Validators.required],
      moving_in_date: ['', Validators.required],
    });

    this.messageService.navigationChange.subscribe((isOpen) => {
      this.showAddress = true;
      this.showFormDate = false;
      this.successStatus = false;
      this.errorStatus = false;
    });
    this.headerContentWidgetItem$.subscribe(res => {
      this.headerContentWidgetItem = res;
    });
    this.navigationChange();
    this.changeDetectorRef.detectChanges();
  }

  get f() {
    let selectedDate = this.locationForm.controls['moving_in_date'].value;
    if (isNotEmpOrNullOrUndef(selectedDate) && selectedDate.day && selectedDate.month && selectedDate.year) {
      let today = new Date();
      var dd: any = today.getDate();
      var mm: any = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      var newDD = selectedDate.day;
      var newMM = selectedDate.month;
      var newYYYY = selectedDate.year;
      if (newDD && newMM && newYYYY) {
        newMM = parseInt(newMM);
        newYYYY = parseInt(newYYYY);
        newDD = parseInt(newDD);
        if(newYYYY < 9999){

          if ((newYYYY > yyyy) || (yyyy == newYYYY && (mm < newMM)) || (yyyy == newYYYY && mm == newMM && dd <= newDD)) {
            this.calendarSelect = true;
            return true;
          } else {
            this.calendarSelect = false;
            return false;
          }
        }else{
          return false;
        }
        
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  handleDateSelect(date: any, selectedDate: any) {
    this.calendarSelect = true;
    let today = new Date();
    var dd: any = today.getDate();
    var mm: any = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    let selectedate = dd + '/' + mm + '/' + yyyy;
    let inputFieldDate: any = this.locationForm.value.moving_in_date;
    let inputFieldDateStr = inputFieldDate.day + '/' + inputFieldDate.month + '/' + inputFieldDate.year;
    if (inputFieldDateStr === selectedate) {
      this.selectedFuture = false;
      this.futureDate = '';
    } else {
      this.selectedFuture = true;
     this.futureDate = this.updateSrvc.dateFormat(this.locationForm.value.moving_in_date);
    }
    selectedDate.close();
  }

  onAddrsSubmit() {
    this.updateSrvc.getAddress(this.locationForm.value).subscribe(
      (res) => {
        this.prePopAddres = res;
        if (
          this.prePopAddres != null &&
          (this.prePopAddres?.house_name || this.prePopAddres?.county || this.prePopAddres?.street_name)
        ) {
          this.prePopAddres.house_name = this.prePopAddres?.house_name.toLowerCase();
          this.prePopAddres.county = this.prePopAddres?.county.toLowerCase();
          this.prePopAddres.street_name = this.prePopAddres?.street_name.toLowerCase();
        }

        this.showAddress = false;
        this.showFormDate = true;
        this.errorMsg = '';
        this.changeDetectorRef.markForCheck();
      },
      (err) => {
        this.showAddress = false;
        this.successStatus = false;
        this.errorMsg = err.error?.message;
        this.errorStatus = true;
        this.changeDetectorRef.markForCheck();
      },
    );
  }

  onSubmit() {
    //message fix on success screen depening on date check start
    let today = new Date();
    var dd: any = today.getDate();
    var mm: any = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    let selectedate = dd + '/' + mm + '/' + yyyy;
    let inputFieldDate: any = this.locationForm.value.moving_in_date;
    let inputFieldDateStr = inputFieldDate.day + '/' + inputFieldDate.month + '/' + inputFieldDate.year;

    if (inputFieldDateStr === selectedate) {
      this.selectedFuture = false;
      this.futureDate = '';
    } else {
      this.selectedFuture = true;
      this.futureDate = this.updateSrvc.dateFormat(this.locationForm.value.moving_in_date);
    }
    //end
    this.updateSrvc.updateAddressDate(this.prePopAddres, this.locationForm.value).subscribe(
      (res) => {
        this.showForm = false;
        this.showFormDate = false;
        this.successStatus = true;
        this.errorMsgDate = '';
        this.updateSrvc.getUserDetails().subscribe(res => {
          this.dataStore.setCustomerDetail(res);
        });
        this.changeDetectorRef.markForCheck();
      },
      (err) => {
        this.showForm = false;
        this.showFormDate = false;
        this.showfailueError();
        this.changeDetectorRef.markForCheck();
      },
    );
  }
  backToMyAccountPage(){
    location.href = '#/myaccount';
  }
  refreshPage() {
    this.showForm = false;
    this.showFormDate = false;
    this.successStatus = false;
    this.errorStatus = false;
    this.showAddress = true;
    this.errorMsgDate = '';
    this.locationForm.reset({});
    //this.messageService.getNavigationChange(true);
    // location.href = '#/myaccount';
    this.changeDetectorRef.markForCheck();
  }
  // showArrearError(content: any) {
  //   this.serviceException.imageClass = 'service-dateerror';
  //   this.serviceException.phraseBold = content.notablethistime;
  //   // this.serviceException.phraseRegular = content.timeoutMsg;
  //   // this.serviceException.helpNote = `${content?.pageWarning1} ${this.brandName} ${content?.pageWarning2} <a class="faq-link">${content.pageFaqs}</a>`;
  //   this.serviceException.buttonText = content.backtoAccount;
  //   this.serviceException.componentUri = '/header/update-address';
  //   this.serviceException.callUs = false;
  //   this.serviceException.featurePage = 'update-address';
  //   this.messageService.setServiceException(this.serviceException);
  //   this.router.navigate(['/payments/service-exception']);
  // }
  showfailueError() {
    this.serviceException.imageClass = getErrorImageClass(this.headerContentWidgetItem?.userAddress.failedToUpdateHomeAddressException.errorImageType.toString());
    this.serviceException.errorInfoText = this.headerContentWidgetItem?.userAddress.failedToUpdateHomeAddressException.errorInfoText;
    this.serviceException.buttonText = this.headerContentWidgetItem?.userAddress.failedToUpdateHomeAddressException.buttonText;
    this.serviceException.componentUri = this.headerContentWidgetItem?.userAddress.failedToUpdateHomeAddressException.componentUri;
    this.serviceException.cancelLink = this.headerContentWidgetItem?.userAddress.failedToUpdateHomeAddressException.cancelLink;
    // this.serviceException.featurePage === 'update-address';
    this.serviceException.componentUri = this.headerContentWidgetItem?.userAddress.failedToUpdateHomeAddressException.componentUri;
    this.messageService.setServiceException(this.serviceException);
    this.router.navigate(['/payments/service-exception']);
  }
  

  cancel(log: any) {
    this.calendarSelect = false;
    this.locationForm.controls['moving_in_date'].setValue('');
    log.close();
  }

  navigationChange() {
    this.messageService.getNotify().subscribe(res => {
      this.refreshPage();
      this.changeDetectorRef.markForCheck();
    });
  }

  dateFormat(el: any) {
    let value = el;
    if (el?.length === 8) {
      value = value.replace(/^([\d]{2})([\d]{2})([\d]{4})$/, "$1/$2/$3"); //NOSONAR
      this.locationForm.controls['moving_in_date'].setValue(value);
    }
  }
}
