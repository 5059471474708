import { Component, OnInit, Input, Inject} from '@angular/core';
import { getDeviceType } from '@wss/common/util/util';
import { FooterWidgetItem } from '@wss/model/content/footer-widget';
import { AuthService } from '@wss/service/auth.service';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { appConfig } from '@wss/config/wss-app-constants';

@Component({
  selector: 'wss-footer',
  templateUrl: 'footer.component.html'
})

export class FooterComponent implements OnInit {


  @Input() footerWidgetItem: FooterWidgetItem = new FooterWidgetItem() ;
  deviceType: any;
  isLoggedIn: boolean = false;
  portalName: string = this.pageConfig.portalName;
  isBrEligibleBrand: boolean = false;
  constructor(
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
    private auth: AuthService,
    ) { 
  }

  ngOnInit(): void {
    this.deviceType = getDeviceType();
    this.isLoggedIn = this.auth.isloggedIn();
    this.isBrEligibleBrand = appConfig.brandsEligibleForBalloonRefinance.includes(this.portalName);
  }



}