<div class="reg-container" *ngIf="registrationWidgetItem$ | async as registrationWidgetItem">
  <div class="reg-row" role="alert" *ngIf="serviceError">         
    <bb-wss-banner-message [errorCode]="errorCode" [showRegistrationLink]="showEmailLink"></bb-wss-banner-message>
  </div>
  <div class="reg-row" role="row" test_id="pageHeaderText">
    <bb-content-html [html]="registrationWidgetItem.userPassword.pageHeaderText"></bb-content-html>
  </div>

    <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
      <div class="reg-row-col">
        <bb-input-password-ui
          label="{{registrationWidgetItem.userSearch.password}}"
          placeholder="{{registrationWidgetItem.userSearch.enterPassword}}"
          formControlName="password"
          autocomplete="new-password"
          [aria-describedby]="'password-error'"
          [showVisibilityControl]="true"
        >
        </bb-input-password-ui>
      </div>
      
      <bb-wss-common-password-rules 
          [containsAtleastEightChars]="atLeastContainsEightChars"
          [containsAtLeastOneLowerCase]="atLeastOneLowerCase"
          [containsAtLeastOneNumber]="atLeastOneNumber"
          [containsAtLeastOneUpperCase]="atLeastOneUpperCase"
          [passwordRules]="registrationWidgetItem.userPassword.passwordRules">
        </bb-wss-common-password-rules>

      <div role="alert" class="invalid-feedback" [ngClass]="{'show-error': showPasswordError}" test_id="invalidPasswordError">
        <bb-content-html [html]="registrationWidgetItem.userPassword.invalidPasswordError"></bb-content-html></div>
      <div class="reg-row password-screen-margin-top">
        <bb-input-password-ui
          label="{{registrationWidgetItem.userSearch.confirmPassword}}"
          placeholder="{{registrationWidgetItem.userSearch.enterPassword}}"
          formControlName="confirmPassword"
          autocomplete="new-password"
          onPaste="return false" 
          [aria-describedby]="'password-error'"
          [showVisibilityControl]="true"
          [disabled]="passwordEntered"
        >
        </bb-input-password-ui>
      </div>
      <div role="alert" class="invalid-feedback" [ngClass]="{'show-error confirm-password': !passwordMatch}" test_id="passwordMismatchError">
        <bb-content-html [html]="registrationWidgetItem.userPassword.passwordMismatchError"></bb-content-html>
      </div>
      <div class="reg-row credential-created-top-margin flex-horizontal-center" *ngIf="isContactPreferenceFeatureOn">
        <button bbButton type="submit" aria-label="Favourite" class="wss-button"
          id="enter-pwd-btn" [disabled]="buttonDisabled || passwordEntered" test_id="nextButtonText">{{registrationWidgetItem.userPassword.nextButtonText}}
        </button>
      </div>
      <div class="reg-row credential-created-top-margin flex-horizontal-center" *ngIf="!isContactPreferenceFeatureOn">
        <button bbButton type="submit" aria-label="Favourite" class="wss-button"
          id="enter-pwd-btn" [disabled]="buttonDisabled || passwordEntered" test_id="createOnlineAccountButtonText">{{registrationWidgetItem.userPassword.createOnlineAccountButtonText}}
        </button>
      </div>
    </form>
    <div class="need-help-link text-center mb-25px" test_id="needHelpLink">
      <bb-content-html [html]="registrationWidgetItem.userPassword.needHelpLink"></bb-content-html>
    </div>
</div>
