<div *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <div class="login-container">
    <span class="section-label-size" test_id="which_account_label_id">
      {{loginWidgetItem.checkuserMail.titleText}}
    </span>
    <hr />
    <div>
      <p class="title-section-page" test_id="loginWidgetItemcheckmailTextContent">
        <bb-content-html [html]="loginWidgetItem.checkuserMail.checkmailText"></bb-content-html>
      </p>
      <p test_id="loginWidgetItemsendnoteLinkContent">
        <bb-content-html [html]="loginWidgetItem.checkuserMail.sendnoteLink"></bb-content-html>
      </p>
      <span test_id="loginWidgetItemreceiveEmailContent">
        <span>
          <bb-content-html [html]="loginWidgetItem.checkuserMail.receiveEmail"></bb-content-html>
        </span>
        <span class="child-para-inline">
          <bb-content-html [html]="loginWidgetItem.checkuserMail.trylinkAgain"></bb-content-html>
        </span>
        <span class="child-para-inline" *ngIf="loginWidgetItem.brandContactDetails">
          <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html>
        </span>
        <span class="child-para-inline">
          <bb-content-html [html]="loginWidgetItem.checkuserMail.forHelpAndSupportText"></bb-content-html>
        </span>
        <span class="child-para-inline ">
          <bb-content-html [html]="loginWidgetItem.checkuserMail.checkEnter"></bb-content-html>
        </span>

      </span>
      <br>
      <span lass="margin-top-between-rows" test_id="loginWidgetItemtouchLinkContent">

        <span class="child-para-inline mr-2">
          <bb-content-html [html]="loginWidgetItem.checkuserMail.notWork"></bb-content-html>
        </span>
        <span class="child-para-inline ">
          <bb-content-html [html]="loginWidgetItem.checkuserMail.touchlink"></bb-content-html>
        </span></span>

      <br>
      <div id="check-email-btn" class="margin-top-between-rows login-page-button text-center"
        test_id="loginWidgetItembackLoginContent">
        <button id="bb-button-to" bbButton type="submit" aria-label="Favourite" (click)="submit()"
          [className]="'wss-button'" class="button-width-padding"
          title="{{loginWidgetItem.checkuserMail.backbutnLogin}}">
          {{loginWidgetItem.checkuserMail.backbutnLogin}}
        </button>
      </div>
    </div>
  </div>
  <div class="register-link no-para-margin" test_id="loginWidgetItemhelpLinkContent" id="needHelpLinkContent">
    <bb-content-html [html]="loginWidgetItem.checkuserMail.helpLink"></bb-content-html>
  </div>
</div>