import {RegistrationState} from "./state";
import {UserSearchRequest} from "../../registration-widget/src/user-search-request";
import {OtpGenerationResponse, User} from "./user";


export const userSelectRequest = (state: RegistrationState): UserSearchRequest| undefined => state.userSearchRequest || undefined;
export const userFound = (state: RegistrationState): Array<User> | undefined => state.users || undefined;
export const titleSelector = (state: RegistrationState): string | undefined => state.title || undefined;
export const otpSelector = (state: RegistrationState): number | undefined => state.otp || undefined;
export const stepSelector = (state: RegistrationState): number | undefined => state.step || undefined;
