<div class="welcome-header-wrapper" test_id="welcome-header">
    <div class="d-flex header-wrapper-login responsive-header">
      <div class="logo logo-login-style" [ngStyle]="portalName === 'astonmartin'?{'background-position': 'unset'}:{'background-position': 'center'}"></div>
      <div *ngIf="(portalName === 'landrover' || portalName === 'jaguar' )" class="logo-text"></div>
    </div>

    <!-- <bb-welcome-widget [welcomeWidgetItem]="welcomeWidgetItem"></bb-welcome-widget> -->
    <div class="welcome-widget" *ngIf="welcomeWidgetItem$ | async as welcomeWidgetItem">
        <div class="welcome-container">
            <div class="header-text">
                <span class="brand-contact-details">
                    <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.welcomeTitlePartOne"></bb-content-html>
                    <!-- <bb-content-html [html]="welcomeWidgetItem.brandContactDetails.portalName"></bb-content-html>
                    <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.welcomeTitlePartTwo"></bb-content-html> -->
                </span>
            </div>
            <div>
                <div class="welcome-icon landing-page-icon"></div>
                <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.welcomeContent"></bb-content-html>
            </div>
            <div class="container-row content-center button-link services-button">
                <button id="welcome-login-btn" class="wss-button" title="{{welcomeWidgetItem.welcomeDetails.loginButtonText}}" test_id="login_button_id" 
                    (click)="navigateToLogin()">{{welcomeWidgetItem.welcomeDetails.loginButtonText}}</button>
            </div>
            <div class="container-row content-center button-link">
                <button id="welcome-registration-btn" class="wss-button-secondary" title="{{welcomeWidgetItem.welcomeDetails.registrationButtonText}}" test_id="registration_button_id" 
                    (click)="navigateToRegistration()">{{welcomeWidgetItem.welcomeDetails.registrationButtonText}}</button>
            </div>
        </div>
        <div class="online-services-container">
            <div class="online-services-wrapper">
                <div class="section-title text-center">
                    <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.yourNewOnlineService"></bb-content-html>
                </div>
                <div class="left-container">
                    <div class="container-title no-para-margin">
                        <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.youWillStillBeAbleTo"></bb-content-html>
                    </div>
                    <div class="online-services-item">
                        <span class="services-icon payments-icon"></span>
                        <span class="online-services no-para-margin">
                            <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.makePaymentOrGetSettlement"></bb-content-html>
                        </span>
                    </div>
                    <div class="online-services-item">
                        <span class="services-icon material-icons material-icons-outlined">date_range_outlined</span>
                        <span class="online-services no-para-margin">
                            <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.changePaymentDay"></bb-content-html>
                        </span>
                    </div>
                    <div class="online-services-item">
                        <span class="services-icon material-icons material-icons-outlined">assignment_ind_outlined</span>
                        <span class="online-services no-para-margin">
                            <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.keepYourAccountDetailsUpdated"></bb-content-html>
                        </span>
                    </div>
                    <div class="online-services-item">
                        <span class="services-icon material-icons material-icons-outlined">assignment_turned_in_outlined</span>
                        <span class="online-services no-para-margin">
                            <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.haveAccessToAgreementInformation"></bb-content-html>
                        </span>
                    </div>
                </div>
                <div class="right-container">
                    <div class="container-title no-para-margin">
                        <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.whatsNewAndImproved"></bb-content-html>
                    </div>
                    <div class="online-services-item">
                        <span class="services-icon material-icons material-icons-outlined">credit_card_outlined</span>
                        <span class="online-services no-para-margin">
                            <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.makeOverpayment"></bb-content-html>
                        </span>
                    </div>
                    <div class="online-services-item">
                        <span class="services-icon material-icons material-icons-outlined">sms_failed_outlined</span>
                        <span class="online-services no-para-margin">
                            <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.dedicatedHelpCentre"></bb-content-html>
                        </span>
                    </div>
                    <div class="online-services-item">
                        <span class="services-icon material-icons material-icons-outlined">compare_arrows_outlined</span>
                        <span class="online-services no-para-margin">
                            <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.viewTransactionHistory"></bb-content-html>
                        </span>
                    </div>
                    <div class="online-services-item">
                        <span class="services-icon material-icons material-icons-outlined">all_inbox_outlined</span>
                        <span class="online-services no-para-margin">
                            <bb-content-html [html]="welcomeWidgetItem.welcomeDetails.newAgreementDashboard"></bb-content-html>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>