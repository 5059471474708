import { Injectable } from "@angular/core";
import { Error } from '../model/error';
import { BannerMessage } from '../model/bannerMessage';
import { Subject, Observable } from 'rxjs';
import { ServiceException } from '../model/service-exception';
import { SendSMS } from '@wss/model/send-sms';
import { ServiceExceptionBudget } from "@wss/model/service-exception-budget";


@Injectable({ providedIn: 'root' })
export class MessageService {
  message: string[] = [];
  errMsg: Error[] = [];
  bannerMessage: BannerMessage = new BannerMessage();
  serviceException: ServiceException = <ServiceException>JSON.parse(sessionStorage.getItem("serviceException") || JSON.stringify(new ServiceException()));
  serviceExceptionBudget: ServiceExceptionBudget = <ServiceExceptionBudget>JSON.parse(sessionStorage.getItem("serviceExceptionBudget") || JSON.stringify(new ServiceExceptionBudget()));
  private serviceError = new Subject<Error>();
  private eocServiceError = new Subject<Error>();
  private activeNav = new Subject<string>();
  private content = new Subject<any[]>();
  private smsResponse = new Subject<any[]>();
  private profileActive = new Subject<boolean>();
  private currentStep = new Subject<number>();
  public navigationChange = new Subject<boolean>();
  sendSMSContent: SendSMS = <SendSMS>JSON.parse(sessionStorage.getItem("sendSmsContent") || JSON.stringify(new SendSMS()))
  eocServiceObj: any;

  setMessage(value: Error[]) {
    this.errMsg = value;
  }

  getMessage() {
    return this.errMsg;
  }

  add(message: string) {
    this.message.push(message);
  }

  getBannerMessage() {
    return this.bannerMessage;
  }

  setBannerMessage(bannerMessage: BannerMessage) {
    this.bannerMessage = bannerMessage;
  }

  clear() {
    this.message = [];
  }

  setServiceError(serviceError: Error) {
    this.serviceError.next(serviceError);
  }

  clearServiceError() {
    this.serviceError.next();
  }

  getServiceError(): Observable<Error> {
    return this.serviceError.asObservable();
  }

  getServiceException(): ServiceException {
    return this.serviceException;
  }

  setServiceException(serviceException: ServiceException) {
    sessionStorage.setItem("serviceException", JSON.stringify(serviceException));
    this.serviceException = serviceException;
  }

  getServiceExceptionBudget(): ServiceExceptionBudget {
    return this.serviceExceptionBudget;
  }

  setServiceExceptionBudget(serviceExceptionBudget: ServiceExceptionBudget) {
    sessionStorage.setItem("serviceExceptionBudget", JSON.stringify(serviceExceptionBudget));
    this.serviceExceptionBudget = serviceExceptionBudget;
  }

  setActiveNav(activeNav: string) {
    this.activeNav.next(activeNav);
  }

  getActiveNav(): Observable<string> {
    return this.activeNav.asObservable();
  }

  setContent(content: any[]) {
    this.content.next(content);
  }

  getContent(): Observable<any[]> {
    return this.content.asObservable();
  }

  setSMSResponse(smsResponse: any[]) {
    this.smsResponse.next(smsResponse);
  }

  getSMSResponse(): Observable<any[]> {
    return this.smsResponse.asObservable();
  }

  setProfileActive(isOpen: boolean) {
    this.profileActive.next(true);
  }

  getProfileActive(): Observable<boolean> {
    return this.profileActive.asObservable();
  }

  setCurrentStep(currentStep: number) {
    this.currentStep.next(currentStep);
  }

  getCurrentStep(): Observable<number> {
    return this.currentStep.asObservable();
  }

  getNavigationChange(isOpen: boolean) {
    this.navigationChange.next(true);
  }
  getNotify(): Observable<boolean> {
    return this.navigationChange.asObservable();
  }

  getSendSMSContentData(): SendSMS {
    return this.sendSMSContent;
  }

  setSendSMSContentData(sendSMS: SendSMS) {
    sessionStorage.setItem("sendSmsContent", JSON.stringify(sendSMS));
    this.sendSMSContent = sendSMS;
  }

  setEOCServiceError(serviceError: Error) {
    this.eocServiceError.next(serviceError);
    this.eocServiceObj = serviceError;
  }

  getEOCServiceError(): Observable<Error> {
    return this.eocServiceError.asObservable();
  }

  getEOCServiceErrorObj(): any {
    return this.eocServiceObj;
  }
}
