<mat-accordion class="accordion mat-accordion-override">    
    <ng-container *ngFor="let item of accordionContent;let i = index;">
        <mat-expansion-panel *ngIf="item.header || item.content"  class="mat-expansion-panel-override mat-expansion-panel-spacing-override" id="{{item?.id}}">
            <mat-expansion-panel-header>                
                <mat-panel-title class="mat-expansion-panel-header-title-override" id="{{titleText+(i+1)}}">                    
                    <bb-content-html [html]="item.header"></bb-content-html>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p id="{{contentText+(i+1)}}">
                <bb-content-html [html]="item.content"></bb-content-html>
            </p>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>