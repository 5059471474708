import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from "rxjs";
import { ContentService } from '@backbase/universal-ang/content';
import { HelpCentreItem } from '@wss/model/helpCentre';
import { TealiumUtagService } from '@wss/service/utag.service';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { injectBrandingIntoAccordion } from '@wss/common/util/util';
import { ContentData, WSSAppConstant } from '@wss/config/wss-app-constants';
import { DatastoreService } from '@wss/service/datastore.service';
import { Router } from '@angular/router';
import { DataService } from '@wss/complaints-widget/src/services/data.service';

@Component({
  selector: 'bb-vehicle-complaints',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './vehicle-complaints.component.html',
  styles: [],
  providers: [ContentService]
})
export class VehicleComplaintsComponent implements OnInit {
  contentTxt: string = ContentData.HelpCentreVehicleComplaints;
  content: Array<any> = [];
  contentFolder: string = ContentData.HelpCentre;
  helpCentreContent: string = 'help-centre-vehicle-complaints';
  param: any;
  helpCentreWidgetItem$: Observable<HelpCentreItem | undefined> = this.bbContentService.getContent<HelpCentreItem>('helpCentreWidgetItem');
  helpCentreWidgetItem = new HelpCentreItem();
  portalName: string = '';
  accountDetail: any = {};
  isComplaintFeatureEnabled: boolean = true;
  svgInfoIcon = WSSAppConstant.SVG_INFO_ICON;

  constructor(
    private readonly bbContentService: ContentService,
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
    private tealiumService: TealiumUtagService,
    private changeDetectorRef: ChangeDetectorRef,
    private datastoreService: DatastoreService,
    private router: Router,
    private formDataService: DataService
  ) { }

  ngOnInit(): void {
    this.portalName = this.pageConfig.portalName || 'blackhorse';
    this.accountDetail = this.datastoreService.getAccountDetails();
    this.isComplaintFeatureEnabled = this.datastoreService.getComplaintsFeature();
    this.tealiumService.view(
      {
        JourneyName: 'Help Centre',
        JourneyStepName: 'Vehicle Complaints',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.getContentData();
  }

  getContentData() {
    this.helpCentreWidgetItem$.subscribe((pageItem: any) => {
      this.helpCentreWidgetItem = pageItem;
      this.helpCentreWidgetItem.helpCentreVehicleComplaints = injectBrandingIntoAccordion(this.helpCentreWidgetItem.helpCentreVehicleComplaints, this.helpCentreWidgetItem.brandContactDetails);
      this.changeDetectorRef.detectChanges();
    })
  }

  onMakeComplaintClick() {
    let concernType = {concernType: 'Vehicle'};
    this.formDataService.saveFormData('concernSelectionForm', concernType);
    this.router.navigate(['complaint/relate-to']);
  }
}
