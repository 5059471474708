<div class="hc-manage-account-page" *ngIf="helpCentreWidgetItem">
	<div class="container-column">
		<div class="help-centre-title">
			<span class="section-label-title" test_id="accountDetailspageName">
				{{helpCentreWidgetItem.accountDetails.pageName}}</span>
		</div>
		<div class="customer-support-text margin-top-30">
			<div test_id="accountDetailspageHeaderBelowText">
				<bb-content-html [html]="helpCentreWidgetItem.accountDetails.pageHeaderBelowText"></bb-content-html>
			</div>

			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="accountDetailsfirstHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.accountDetails.firstHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.accountDetails.myPersonalDetailsAccordionContent" [testIDText]="'helpCentreMyPersonalDetailsAccordion'"></bb-common-expansion-panel>
				</div>
			</div>

			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="accountDetailssecondHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.accountDetails.secondHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.accountDetails.navigatingOnlineAccountAccordionContent" [testIDText]="'helpCentreNavigatingOnlineAccountAccordion'"></bb-common-expansion-panel>
				</div>
			</div>

			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="accountDetailsthirdHeaderTitle" id="accountDetailsthirdHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.accountDetails.thirdHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.accountDetails.understandingAccountAccordionContent" [testIDText]="'helpCentreUnderstandingAccountAccordion'"></bb-common-expansion-panel>
				</div>
			</div>

			<div class="margin-top-30">
				<div class="title-header margin-top-20" test_id="accountDetailsFourthHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.accountDetails.fourthHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.accountDetails.goPaperlessFaqsAccordionContent" [testIDText]="'helpCentreGoPaperlessFaqsAccordion'"></bb-common-expansion-panel>
				</div>
			</div>

			<div class="margin-top-30" *ngIf="isContactPreferenceFeatureOn">
				<div class="title-header margin-top-20" test_id="accountDetailsFifthHeaderTitle">
					<h3><strong><bb-content-html [html]="helpCentreWidgetItem.accountDetails.fifthHeaderTitle"></bb-content-html></strong></h3>
				</div>
				<div class="help-centre-support container-row margin-top-30">
					<bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.accountDetails.marketingChoicesFaqsAccordionContent" [testIDText]="'helpCentreGoPaperlessFaqsAccordion'"></bb-common-expansion-panel>
				</div>
			</div>
		</div>
	</div>
</div>