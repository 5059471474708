import { Component } from "@angular/core";
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from "rxjs";
import { ContactPreferenceWidgetItem } from "@wss/model/content/contact-preference";
import { Router } from "@angular/router";

@Component({
    selector: 'bb-contact-preference-success',
    templateUrl: 'contact-preference-success.component.html',
    providers: [ContentService]
})

export class ContactPreferenceSuccessComponent {


    contactPreferenceWidgetItem$: Observable<ContactPreferenceWidgetItem | undefined> = this.bbContentService.getContent<ContactPreferenceWidgetItem>('contactPreferenceWidgetItem');

    constructor(private readonly bbContentService: ContentService, private router: Router) {
    }

    backToHome() {
        this.router.navigate(['/']);
    }

}
