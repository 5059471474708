import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { isErrServer } from '@wss/common/util/util';
import { LoginWidgetItem } from '@wss/model/login-widget/login-widget';
import { RegisterDatastoreService } from '@wss/registration-widget/src/service/register-datastore.service';
import { RegisterService } from '@wss/registration-widget/src/service/register.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { RequestService } from '@wss/service/request.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bb-verify-sms',
  templateUrl: './verify-sms.component.html',
  styleUrls: ['./verify-sms.component.scss'],
  providers: [ ContentService ]
})
export class VerifySmsComponent implements OnInit {

  mobileNo: string = '';
  codeFail: boolean = false;
  btnText: string = '';
  serviceError: boolean = false;
  readonly destroy$ = new Subject();
  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');
  errorCode: any;
  
  constructor(
    private readonly bbContentService: ContentService,
    public router: Router,
    private registerstoreService: RegisterDatastoreService,
    private registerService: RegisterService,
    private cmndatastoreService: DatastoreService,
    private refChange: ChangeDetectorRef,
    private requestService: RequestService  ) {
    this.mobileNo = this.registerstoreService.getMobileUser();
   }

  ngOnInit(): void {
    this.btnText =  this.codeFail ? 'Resend Code'​ : 'Next';
    this.requestService.setReqSendOptions();
  }

  showError(errStatus: number): void {
    if(isErrServer(errStatus)){
      this.cmndatastoreService.setErrorServer();
      this.serviceError = true;
      this.refChange.detectChanges();
    }
  }

  submit() {
    this.registerService.getForgotEmailOTP().pipe(takeUntil(this.destroy$)).subscribe(() => { 
      this.serviceError = false;    
      this.router.navigate(['verify-otp']);
    },
    (error:any) => {
      this.errorCode = error.error?.code ? error.error.code : error.status;
      this.showError(error.status);      
    });
  }

}
