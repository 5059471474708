import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatastoreService } from '@wss/service/datastore.service';
import { WSSAppConstant } from '@wss/config/wss-app-constants';
import { PaymentService } from '../../services/payment.service';
import { BankAccount, DirectDebit, DirectDebitResponse } from '../../models/direct-debit';
import { isEmpOrNullOrUndef, isErrServer } from '@wss/common/util/util';
import * as moment from 'moment';
import { MessageService } from 'libs/service/message.service';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'add-direct-debit',
    templateUrl: './add-direct-debit.component.html',
    providers: [ContentService]
})

export class AddDirectDebitComponent implements OnInit {
    // contentTxt: string = ContentData.AddOrUpdateDirectDebit;
    // content: Array<any> = [];
    // contentFolder: string = ContentData.DirectDebit;

    // modalContentTxt: string = ContentData.DirectDebitException;
    modalContent: Array<any> = [];
    svgInfoIcon = WSSAppConstant.SVG_INFO_ICON;
    accountDetail: any = {};
    activeDirectDebitFound: boolean = false;
    debitDetailForm!: FormGroup;
    bankDetailStatus: string = this.datastoreService.bankDetailStatus[2];
    bankAccount: BankAccount = new BankAccount();
    withIn8Days: boolean = false;


    checkboxes: any = {
        first: false,
        second: false,
        third: false
    }
    serviceError: boolean = false;

    // requestStatus: any = {
    //     invalid: false
    // };

    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');

    constructor(
        private router: RouterService,
        private datastoreService: DatastoreService,
        private modalService: NgbModal,
        // private contentService: OldContentService,
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private paymentService: PaymentService,
        private messageService: MessageService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService
        ) { }

    ngOnInit() {
        this.datastoreService.sessionInvalid();
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Add Direct Debit',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.accountDetail = this.datastoreService.getAccountDetails();
        // this.getStaticContent(this.contentTxt, this.contentFolder);
        this.activeDirectDebitFound = this.datastoreService.getActiveDirectDebitFound();
        this.debitDetailForm = this.formBuilder.group({
            accountName: ['', Validators.required],
            accountNumber: ['', Validators.required],
            sortCodePart1: ['', Validators.required],
            sortCodePart2: ['', Validators.required],
            sortCodePart3: ['', Validators.required]
        });
        this.withIn8Days = this.checkIfWith8Days();
        this.handleErrors();
        this.messageService.setCurrentStep(0);
        this.changeDetectorRef.detectChanges();
    }

    checkIfWith8Days(): boolean {
        let todayDay = new Date().getDate();
        let nextPaymentDay = this.accountDetail.next_payment_date;
        if (!isEmpOrNullOrUndef(nextPaymentDay)) {
            nextPaymentDay = parseInt(nextPaymentDay.split("/")[0]);
            if (todayDay > nextPaymentDay) {
                return false;
            }
            else if ((todayDay < nextPaymentDay) && ((nextPaymentDay - todayDay) < 8)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    validateBankDetails() {
        if (this.debitDetailForm.valid) {
            let directDebitRequest: any = {
                account_number: this.datastoreService.getAccountNo(),
                bank_sortcode: this.debitDetailForm.value.sortCodePart1 + "" + this.debitDetailForm.value.sortCodePart2 + "" + this.debitDetailForm.value.sortCodePart3,
                bank_accno: this.debitDetailForm.value.accountNumber,
                bank_acc_holdername: this.debitDetailForm.value.accountName,
                borrower_indicator: this.accountDetail.borrower_indicator,
                payment_indicator: this.accountDetail.method_payment
            };
            this.paymentService.getBankAccountDetail(directDebitRequest).subscribe((response: BankAccount) => {
                if (response && !isEmpOrNullOrUndef(response.bank_account_number)) {
                    this.bankDetailStatus = this.datastoreService.bankDetailStatus[0];
                    this.bankAccount = response;
                }
                else {
                    this.bankDetailStatus = this.datastoreService.bankDetailStatus[1];
                }
                this.changeDetectorRef.detectChanges();
            });
        }
        else {
            this.bankDetailStatus = this.datastoreService.bankDetailStatus[2];
            this.changeDetectorRef.detectChanges();
        }
    }

    checkedCheckboxes() {
        let isAllSelected = true;
        for (let key in this.checkboxes) {
            if (!this.checkboxes[key]) {
                isAllSelected = false;
            }
        }
        return isAllSelected;
    }

    addOrUpdateDebitDetail(content: any) {
        let directDebitRequest: DirectDebit = {
            bank_account_holder_name: this.debitDetailForm.value.accountName,
            bank_account_borrower: this.accountDetail.borrower_indicator,
            bank_account_number: this.debitDetailForm.value.accountNumber,
            bank_sortcode: this.debitDetailForm.value.sortCodePart1 + "" + this.debitDetailForm.value.sortCodePart2 + "" + this.debitDetailForm.value.sortCodePart3,
            current_payment_method: this.accountDetail.method_payment
        };
        this.paymentService.addOrUpdateDirectDebit(directDebitRequest).subscribe((response: DirectDebitResponse) => {
            if (response && response.bank_account_holder_name && !isEmpOrNullOrUndef(response.bank_account_holder_name.trim())) {
                this.datastoreService.setDirectDebitResponse(response);
                try{
                    this.paymentService.sendDirectDebitEmailConfirmation().subscribe((res: any) => {
                    });
                }
                catch(error){ }
                localStorage.setItem("lastUpdated", moment().format('DD/MM/YYYY') + "|" + this.datastoreService.getAccountNo());
                this.router.navigate(['direct-debit-result']);
            }
            else {
                localStorage.removeItem("lastUpdated");
            }
        });
    }

    closePopUp() {
        this.router.navigate(['direct-debit']);
    }

    handleErrors() {
        this.messageService.getServiceError().subscribe((error: any) => {
            if (error && error.message && ((error.message === 'INVALID BANK DETAILS') || (error.message === 'INVALID BANK SORT CODE'))) {
                this.bankDetailStatus = this.datastoreService.bankDetailStatus[1];
                this.changeDetectorRef.detectChanges();
            }
            else{
                this.bankDetailStatus = this.datastoreService.bankDetailStatus[2];
                this.changeDetectorRef.detectChanges();
                this.showError(error.status);
            }
            
        });
    }

    showError(errStatus: number): void {
        if(isErrServer(errStatus)){
          this.datastoreService.setErrorServer();
          this.serviceError = true;
          this.changeDetectorRef.detectChanges();
        }
      }

    navigateTo(url: string){
      location.href = '#/' + url;
    }


}