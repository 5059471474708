import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { Router } from '@angular/router';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Address, BalloonRefinanceGetdetailsItem, ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { appConfig, patternMap } from '@wss/config/wss-app-constants';
import { HttpService } from '@wss/service/http.service';
import { BalloonRefinanceGetdetailsService } from '../../services/balloon-refinance-getdetails.service';
import { BalloonRefinanceService } from '@wss/balloon-refinance-widget/src/services/balloon-refinance.service';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { getHouseDetailsFromStreetName, getYears, chkDOBDates, noWhitespaceValidator } from '@wss/common/util/util';

@Component({
  selector: 'wss-balloon-refinance-getdetails-employment',
  templateUrl: './balloon-refinance-getdetails-employment.component.html'
})

export class BalloonRefinanceGetdetailsEmploymentComponent implements OnInit {

  balloonRefinanceGetdetailsEmploymentItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');

  currentPage: number = 3;
  brGetdetailsWidgetItem: any = new BalloonRefinanceGetdetailsItem();
  employmentStatusData: any;
  employmentSituationData: any;
  employmentTypeData: any;
  employmentTenureData: any;
  employmentSectorData: any;
  previousPageLink: string = '/balloon-refinance-getdetails/about-you';
  accountDetails: any;
  commonNavTestID: string = 'wss-Brg-Employment-';
  idExpandArr: Array<any> = ['breExpandStatusEmploy','breExpandSituation','breExpandTypeEmploy','breExpandTenureEmploy', 'breExpandSectorEmploy', 'breExpandOccupation', 'breExpandCurrentEmployer', 'breExpandEmpStartDate', 'breExpandEmpAddress'];
  idCollapseArr: Array<any> = ['breCollapseStatusEmploy','breCollapseSituation','breCollapseTypeEmploy','breCollapseTenureEmploy', 'breCollapseSectorEmploy', 'breCollapseOccupation', 'breCollapseCurrentEmployer', 'breCollapseEmpStartDate', 'breExpandEmpAddress'];
  idPanelArr: Array<any> = ['breInforStatusEmploy','breInforSituation','breInforTypeEmploy','breInforTenureEmploy', 'breInforSectorEmploy', 'breInforoccupation', 'breInforCurrentEmployer', 'breInforEmpStartDate', 'breInforEmpAddress'];
  submitted: boolean = false;
  changeSelection: boolean = false;
  lastDateOfDecision: any;
  showFullAddress: boolean = false;

  employmentDetailsForm!: FormGroup;
  monthsData: any = appConfig.monthsData;
  prePopAddres: Address;
  showAddress: boolean = false;
  showSearchAddressError: boolean = false;
  showDateErrorMessage: boolean = false;
  minYear: number = 1950;
  maxYear: number = moment().year();
  classLiveAddress: string = 'p-0';
  proposalData: ProposalRequest;
  serviceError: boolean = false;
  isReturnJourney: boolean = false;
  houseAddressValid: boolean = false;
  readonly destroy$ = new Subject();
  yearsList: any = getYears();
  customerDetails: any;
  showDateDOBErrMessage: boolean = false;
  isAddressSearched: boolean = false;
  isAddressSearchRequired: boolean = false;

  constructor(
    private readonly bbContentService: ContentService,
    private router: Router,
    private datastore: DatastoreService,
    private tealiumService: TealiumUtagService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private brGetDetailsService: BalloonRefinanceGetdetailsService,
    private balloonRefinanceSrv: BalloonRefinanceService
  ) {
    this.proposalData = this.datastore.getProposalRequest();
    this.prePopAddres = this.datastore.getAddressData();
  }

  ngOnInit(): void {
    this.datastore.sessionInvalid();
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'Proposal - Employment',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.datastore.brReturnJourney$.subscribe((res: boolean) => {
      this.isReturnJourney = res;
      this.changeDetectorRef.detectChanges();
    });
    this.getContentData();  
    this.accountDetails = this.datastore.getAccountDetails();
    this.lastDateOfDecision = this.datastore.getLastDateForEocDecision(this.accountDetails?.final_payment_date);
    this.setCurentPage();
    this.initEmploymentForm();
    this.setFormData();
    this.customerDetails = this.datastore.getCustomerDetail();
  }

  getContentData() {
    this.balloonRefinanceGetdetailsEmploymentItem$.subscribe((pageItem: any) => {
      this.brGetdetailsWidgetItem = pageItem;
      this.setRadioQuestions();  
      this.changeDetectorRef.detectChanges();
    })    
  }

  initEmploymentForm() {
    this.employmentDetailsForm = this.fb.group({
      employmentStatus: ['', [Validators.required]],
    })
  }

  setRadioQuestions() {
    this.employmentStatusData = [
      { id: 'employmentStatusYes', value: true , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment?.currentEmploymentYes },
      { id: 'employmentStatusNo', value: false , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment?.currentEmploymentNo }
    ];

    this.employmentSituationData = [
      { id: 'employSituationStudent', value: 4 , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment.situtaionTypeStudent },
      { id: 'employSituationHome', value: 5 , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment.situtaionTypeHome },
      { id: 'employSituationRetired', value: 6 , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment.situtaionTypeRetired },
      { id: 'employSituationUnemploy', value: 7 , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment.situtaionTypeUnemploy },
      { id: 'employSituationDisabilityBenefits', value: 8 , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment.situtaionTypeDisabilityBenefits }
    ]

    this.employmentTypeData = [
      { id: 'employmentTypePermanant', value: true , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment.employmentTypePermanant },
      { id: 'employmenetTypeTemporary', value: false , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment.employmenetTypeTemporary },
    ];

    this.employmentTenureData = [
      { id: 'employmentTypeFullTime', value: true , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment.employmentTenureFullTime },
      { id: 'employmenetTypeHalfTime', value: false , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment.employmentTenureHalfTime },
    ];

    this.employmentSectorData = [
      { id: 'employmentSectorPrivate', value: 0 , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment.employmentSectorPrivate },
      { id: 'employmentSectorGovernment', value: 1 , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment.employmentSectorGovernment },
      { id: 'employmentSectorSelfEmployed', value: 2 , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment.employmentSectorSelfEmployed },
      { id: 'empoymentSectorMilitary', value: 3 , title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsEmployment.empoymentSectorMilitary }
    ]
    this.changeDetectorRef.detectChanges();
  }

  setCurentPage() {
    this.datastore.setBRCurrentPage(this.currentPage);
  }

  onSaveButtonClick(e: any) {
    this.router.navigate(['/myaccount']);
  }

  onContinue(e: any) {
    this.checkContinue();
    this.submitted = true;
    this.checkAddressSearchRequired();
    this.houseAddressValid = this.isAddressValid();    
    if (this.employmentDetailsForm.status === 'VALID' && this.houseAddressValid && !this.showDateErrorMessage && !this.showDateDOBErrMessage && 
    this.checkIsAddressSearchRequiredAndSearched()) {
      this.saveDataProposal('/balloon-refinance-getdetails/finances')
    } else {
      setTimeout(() => {
        this.scrollToFirstInvalid()
      }, 100)
    }
  }

  checkAddressSearchRequired(): void {
    if (this.employmentDetailsForm.controls?.employmentStatus?.value === true && this.showFullAddress === false && this.showAddress === false) {
      this.isAddressSearchRequired = true;
    } else {
      this.isAddressSearchRequired = false;
    }
  }

  checkIsAddressSearchRequiredAndSearched():boolean {
    if(this.isAddressSearchRequired && !this.isAddressSearched) {
      return false
    } else {
      return true;
    }
  }

  isAddressValid() {
    let houseAddressValid = false;
    if(this.showFullAddress) {
      houseAddressValid = ((this.employmentDetailsForm?.controls['house_name'].value && this.employmentDetailsForm?.controls['house_name'].value != "") || 
      (this.employmentDetailsForm?.controls['house_number'].value && this.employmentDetailsForm?.controls['house_number'].value != "")) ? true : false;
    } else {
      houseAddressValid = true;
    }
    return houseAddressValid;
  }

  onEmploymentStatusNo() {
    this.addControlForm('employmentSituation');
    if(this.employmentDetailsForm.contains('employmentType')){
      this.removeControlForm('employmentType');
      this.removeControlForm('employmentTenure');
      this.removeControlForm('employmentSector');
      this.removeControlForm('occupation');
      this.removeControlForm('currentEmployer');
      this.removeControlForm('employmentStartMonth');
      this.removeControlForm('employmentStartYear');
      this.removeControlForm('house_name_search');
      this.removeControlForm('post_code_search');
      this.removeControlForm('house_name');
      this.removeControlForm('house_number');
      this.removeControlForm('flat_number');
      this.removeControlForm('street_name');
      this.removeControlForm('district');
      this.removeControlForm('post_town');
      this.removeControlForm('county');
      this.removeControlForm('post_code');
    }    
  }

  onEmploymentStatusYes() {
    if(this.employmentDetailsForm.contains('employmentSituation')) {
      this.removeControlForm('employmentSituation');
    }
    this.isAddressSearchRequired = true;
    this.addControlForm('employmentType');
    this.addControlForm('employmentTenure');
    this.addControlForm('employmentSector');
    this.addControlForm('occupation');
    this.addControlForm('currentEmployer');
    this.addControlForm('employmentStartMonth');
    this.addControlForm('employmentStartYear');
    this.addControlForm('house_name_search');
    this.addControlForm('post_code_search');
  }

  scrollToFirstInvalid() {
    if(document.getElementsByClassName('ng-invalid').length) {
      document.getElementsByClassName('ng-invalid')[1].scrollIntoView({behavior: 'smooth'});
    }
    
  }

  handleChange(value: any) {
    if(!value) {
      this.onEmploymentStatusNo();
    } else {
      this.onEmploymentStatusYes();
    }
  }

  checkContinue() {
    if(!this.employmentDetailsForm?.controls['employmentStatus'].value) {
      this.addFormValidator('employmentSituation', [Validators.required]);
    } else if (this.employmentDetailsForm?.controls['employmentStatus'].value) {
      this.addFormValidator('employmentType', [Validators.required]);
      this.addFormValidator('employmentTenure', [Validators.required]);
      this.addFormValidator('employmentSector', [Validators.required]);
      this.addFormValidator('occupation', [Validators.required, Validators.pattern(patternMap.alphabeticPattern)]);
      this.addFormValidator('currentEmployer', [Validators.required, Validators.pattern(patternMap.employersNamePattern)]);
      this.addFormValidator('employmentStartMonth', [Validators.required]);
      this.addFormValidator('employmentStartYear', [Validators.required]);      
      
      if (this.showFullAddress) {
        this.removeFormValidator('house_name_search');
        this.addFormValidator('house_name', [Validators.pattern(patternMap.alphanumericHypenApostropheForwardSlashPattern)]);
        this.addFormValidator('house_number', [Validators.pattern(patternMap.alphanumericHypenForwardSlashPattern)]);
        this.addFormValidator('flat_number', [Validators.pattern(patternMap.alphanumericHypenPattern)]);
        this.addFormValidator('street_name', [Validators.required, Validators.pattern(patternMap.alphabeticApostropheHyphenPattern), noWhitespaceValidator]);
        this.addFormValidator('district', [Validators.required, Validators.pattern(patternMap.alphabeticApostropheHyphenPattern)]);
        this.addFormValidator('post_town', [Validators.required, Validators.pattern(patternMap.alphabeticApostropheHyphenPattern)]);
        this.addFormValidator('county', [Validators.required, Validators.pattern(patternMap.alphabeticHyphenPattern)]);
        this.addFormValidator('post_code', Validators.compose([Validators.required, Validators.pattern(patternMap.postCode), Validators.pattern(patternMap.alphanumericPattern)]));
      } else {
        if(this.showAddress){
          this.removeFormValidator('house_name_search');
          this.removeFormValidator('post_code_search');
        } else {
          this.addFormValidator('house_name_search', [Validators.required, Validators.maxLength(20)]);
          this.addFormValidator('post_code_search', Validators.compose([Validators.required, Validators.pattern(patternMap.postCode)]));
        }
      }

    } else {
      this.removeControlForm('employmentSituation');
      this.removeControlForm('employmentType');
      this.removeControlForm('employmentTenure');
      this.removeControlForm('employmentSector');
      this.removeControlForm('occupation');   
    }
  
  }

  toggleFullAddress() {
    this.showFullAddress = !this.showFullAddress;
    if(this.showFullAddress) {
      this.isAddressSearchRequired = false;
      this.addControlForm('house_name');
      this.addControlForm('house_number');
      this.addControlForm('flat_number');
      this.addControlForm('street_name');
      this.addControlForm('district');
      this.addControlForm('post_town');
      this.addControlForm('county');
      this.addControlForm('post_code');
    } else {
      this.isAddressSearchRequired = true;
      this.removeControlForm('house_name');
      this.removeControlForm('house_number');
      this.removeControlForm('flat_number');
      this.removeControlForm('street_name');
      this.removeControlForm('district');
      this.removeControlForm('post_town');
      this.removeControlForm('county');
      this.removeControlForm('post_code')
    }
  }

  searchAddress() {
    this.isAddressSearched = true;
    this.showSearchAddressError = false;
    const payload = {
      house_name: this.employmentDetailsForm.value.house_name_search,
      post_code: this.employmentDetailsForm.value.post_code_search
    }
    this.showAddress = false;
    this.brGetDetailsService.getAddress(payload)
    .subscribe((res: any) => {
      this.prePopAddres = res;
      this.showAddress = true;
      this.changeDetectorRef.markForCheck();
    }, (err: any) => {
      this.showAddress = false;
      this.showSearchAddressError = true;
      this.isAddressSearched = false;
      this.changeDetectorRef.markForCheck();
    })
  }

  addFormValidator(ctrlElem: string, validator: any) {
    this.employmentDetailsForm?.controls[ctrlElem].setValidators(validator);
    this.employmentDetailsForm?.controls[ctrlElem].updateValueAndValidity();
  }

  removeFormValidator(ctrlElem: string){
    if (this.employmentDetailsForm.contains(ctrlElem)) {
      this.employmentDetailsForm?.controls[ctrlElem].clearValidators();
    }
  }

  removeControlForm(ctrlElem: string) {
    if (this.employmentDetailsForm.contains(ctrlElem)) {
      this.employmentDetailsForm.removeControl(ctrlElem);
    }    
  }

  addControlForm(ctrlElem: string) {
    this.employmentDetailsForm.addControl(ctrlElem, this.fb.control('', []));
  }

  saveDataProposal(urlElem: string) { 
    if(this.employmentDetailsForm?.controls['employmentStatus'].value) {
      const employmentPeriod = this.setEmploymentPeriod();   
      this.proposalData.customer_id = this.datastore.getCustomerId();
      this.proposalData.perm_employee = this.employmentDetailsForm.value.employmentType;
      this.proposalData.full_time = this.employmentDetailsForm.value.employmentTenure;
      this.proposalData.employment_type = this.employmentDetailsForm.value.employmentSector;
      this.proposalData.employer_name = this.employmentDetailsForm.value.currentEmployer.replace("’", "'");
      this.proposalData.occupation = this.employmentDetailsForm.value.occupation;
      this.proposalData.months_at_employment = employmentPeriod.months;
      this.proposalData.years_at_employment = employmentPeriod.years;
      this.datastore.setUnemployedStatus(undefined)

      if (this.showFullAddress) {
        this.proposalData.county = this.employmentDetailsForm.value.county;
        this.proposalData.house_name = this.employmentDetailsForm.value.house_name;
        this.proposalData.street = this.employmentDetailsForm.value.street_name;
        this.proposalData.flat_number = this.employmentDetailsForm.value.flat_number;
        this.proposalData.house_number = this.employmentDetailsForm.value.house_number;
        this.proposalData.postcode = this.employmentDetailsForm.value.post_code;
        this.proposalData.town = this.employmentDetailsForm.value.post_town;
        this.proposalData.district = this.employmentDetailsForm.value.district;
      } else {
        this.proposalData.county = this.prePopAddres.county;
        this.proposalData.street = this.prePopAddres.street_name;
        this.proposalData.flat_number = this.prePopAddres.flat_number;
        this.proposalData.postcode = this.prePopAddres.post_code;
        this.proposalData.town = this.prePopAddres.post_town;
        this.proposalData.district = this.prePopAddres.district;
        
        if(this.prePopAddres.house_name === ' ' && this.prePopAddres.house_number === ' ') {
          const houseDetails = getHouseDetailsFromStreetName(this.prePopAddres?.street_name);
          this.proposalData.street = houseDetails?.street_name;
          this.proposalData.house_number = houseDetails?.house_number;
          this.proposalData.house_name = this.prePopAddres.house_name;
        } else { 
          this.proposalData.house_name = this.prePopAddres.house_name;
          this.proposalData.house_number = this.prePopAddres.house_number;
        }
      }
    } else {
      this.datastore.setUnemployedStatus(this.employmentDetailsForm.value.employmentSituation);
      this.proposalData.employment_type = null;
      this.proposalData.perm_employee = null;
      this.proposalData.full_time = null;
      this.proposalData.employer_name = null;
      this.proposalData.occupation = null;
      this.proposalData.months_at_employment = null;
      this.proposalData.years_at_employment = null;
      this.proposalData.county = null;
      this.proposalData.house_name = null;
      this.proposalData.street = null;
      this.proposalData.flat_number = null;
      this.proposalData.house_number = null;
      this.proposalData.postcode = null;
      this.proposalData.town = null;
      this.proposalData.district = null;
    }
    
    this.balloonRefinanceSrv.postProposalDetails(this.proposalData).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.proposalData = this.datastore.getProposalRequest();
      this.isReturnJourney ?
        this.router.navigate(['/balloon-refinance-getdetails/summary']) :
        this.router.navigate([urlElem])
    }, 
    (error: any) => {
      this.showError();
    });
  }

  setFormData() {
    if(this.proposalData.occupation) {
      this.handleChange(true);
    } else {
      this.handleChange(false);
    }
    this.serviceError = false;
    this.changeDetectorRef.detectChanges();
    const empDate = this.getEmploymentPeriod(this.proposalData.months_at_employment, this.proposalData.years_at_employment)
    const unemployedSituation = this.datastore.getUnemployedStatus();
    if (this.proposalData.full_time || this.proposalData.perm_employee || this.proposalData.occupation) {
      this.employmentDetailsForm.controls['employmentStatus'].setValue(true);
      this.employmentDetailsForm.controls['employmentType'].setValue(this.proposalData.perm_employee);
      this.employmentDetailsForm.controls['employmentTenure'].setValue(this.proposalData.full_time);
      this.employmentDetailsForm.controls['currentEmployer'].setValue(this.proposalData.employer_name);
      this.employmentDetailsForm.controls['employmentSector'].setValue(this.proposalData.employment_type);
      this.employmentDetailsForm.controls['occupation'].setValue(this.proposalData.occupation);
      this.employmentDetailsForm.controls['employmentStartMonth'].setValue(empDate?.months);
      this.employmentDetailsForm.controls['employmentStartYear'].setValue(empDate?.years)
      if(this.showFullAddress){
        this.employmentDetailsForm.controls['county'].setValue(this.proposalData.county);
        this.employmentDetailsForm.controls['house_name'].setValue(this.proposalData.house_name);
        this.employmentDetailsForm.controls['street_name'].setValue(this.proposalData.street);
        this.employmentDetailsForm.controls['flat_number'].setValue(this.proposalData.flat_number);
        this.employmentDetailsForm.controls['house_number'].setValue(this.proposalData.house_number);
        this.employmentDetailsForm.controls['post_code'].setValue(this.proposalData.postcode);
        this.employmentDetailsForm.controls['post_town'].setValue(this.proposalData.town);
        this.employmentDetailsForm.controls['district'].setValue(this.proposalData.district);
      } else {
        this.prePopAddres.county = this.proposalData.county;
        this.prePopAddres.district = this.proposalData.district;
        this.prePopAddres.flat_number = this.proposalData.flat_number;
        this.prePopAddres.house_name = this.proposalData.house_name;
        this.prePopAddres.house_number = this.proposalData.house_number;
        this.prePopAddres.post_code = this.proposalData.postcode;
        this.prePopAddres.post_town = this.proposalData.town;
        this.prePopAddres.street_name = this.proposalData.street;
        this.showAddress = true;
        this.removeFormValidator('house_name');
        this.removeFormValidator('post_code');
      }
    } else if (!this.proposalData.employment_type && unemployedSituation !== undefined) {
      this.employmentDetailsForm.controls['employmentStatus'].setValue(false);
      this.employmentDetailsForm.controls['employmentSituation'].setValue(unemployedSituation);
    }
  }

  showError(): void {
    this.datastore.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  setEmploymentPeriod(): any {
    const today = moment([]);
    const dd = '01';
    const mm = this.employmentDetailsForm?.controls['employmentStartMonth'].value;
    const yyyy = this.employmentDetailsForm?.controls['employmentStartYear'].value;
    if(dd && mm && yyyy) {
      const enteredDate = moment([yyyy, mm, dd]);
      const years = today.diff(enteredDate, 'years');
      enteredDate.add(years, 'years');
      const months = today.diff(enteredDate, 'months');
      enteredDate.add(months, 'months');
      return {
        years, 
        months : months <= 0 ? '1' : months
      };
    }
  }

  getEmploymentPeriod(mm: any, yy: any): any {
    if(mm && yy) {
      const currentYear = moment().subtract(yy, 'years').subtract(mm, 'months')
      return {
        years: moment(currentYear).year(),
        months: moment(currentYear).month() < 10 ? '0' + moment(currentYear).month().toString() :  moment(currentYear).month().toString()
      };
    }
  }

  getValidDate() {
    let yearOfBirth = this.customerDetails?.individual.date_of_birth.substring(4, 8);
    let monthOfBirth = parseInt(this.customerDetails?.individual.date_of_birth.substring(2, 4));
    this.showDateDOBErrMessage = chkDOBDates(parseInt(this.employmentDetailsForm?.controls['employmentStartMonth'].value), 
    this.employmentDetailsForm?.controls['employmentStartYear'].value, monthOfBirth, yearOfBirth);   
  }
}