<div class="container-column" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
    <div class="container-column"
        *ngIf="overpaymentData && overpaymentData.length > 0 && !overPaymentWarningFlag && !accountIneligibleFlag && !enableRequestSettlement 
            && !overpaymentNotAllowed && !dueDayExceptionFlag && !overpaymentGenericError && !pesQuoteAmendedAlreadyFlag">
        <div class="payment-row section-title-container">
            <span class="section-title" test_id="make_payment_label_id">
                {{paymentsWidgetItem.overpaymentOptions.overPaymentOptionPageName}}
            </span>
        </div>
        <div class="payment-column over-payment-column margin-bottom" *ngIf="hpCustomerFlag"
            aria-describedby="HPcustomers">
            <div class="payment-row margin-top-10">
                <span class="overpayment-container-label" test_id="make_payment_label_id">
                    <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overpaymentOptionSelectionText">
                    </bb-content-html>
                </span>
            </div>
            <div [ngClass]="overpaymentData && overpaymentData.length > 1 ? 'container-column payment-result-content over-payment hp-pcp-options option-container option-one' : 'container-column payment-result-content over-payment hp-pcp-options option-container option-one balloon-payment'">
                <div class="container-column payment-result-section options">
                    <div *ngIf="overpaymentData && overpaymentData.length > 1" class="container-row internal-section-title-postcard align-center no-para-margin"
                        test_id="overpaymentOption1LabelInfoText">
                        <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overpaymentOption1LabelInfoText">
                        </bb-content-html>
                    </div>
                    <div *ngIf="overpaymentData && overpaymentData.length == 1" class="container-column over-payment-quote">
                        <div class="container-row over-payment-quote-icon">
                            <div class="wss-quote-icon quote-icon"></div>
                        </div>
                        <div class="container-row quote-amount text-center" test_id="lowerMonthlyLabelText">
                            <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.lowerMonthlyLabelText">
                            </bb-content-html>
                        </div>
                    </div>
                </div>
                <div
                    class="container-column payment-result-section overpayment-section-label-title reduce-payment-text">
                    <div class="container-row payment-amount" test_id="newMonthlyPaymentAmountTodayInfoText">
                        {{paymentsWidgetItem.overpaymentOptions.newMonthlyPaymentInfoText}}
                        <span><strong>£{{quoteAmount}}</strong></span>
                        {{paymentsWidgetItem.overpaymentOptions.overpaymentOptionPartDayInfoText}}
                    </div>
                </div>
                <div class="container-column payment-result-section current-next-block">
                    <span class="container-row current-payment-section overpayment-section-label-title">
                        <div class="material-icons">assignment_turned_in</div>
                        <div class="current-payment-title bold-title no-para-margin"
                            test_id="currentPaymentAmountLabelAndValueText">
                            <bb-content-html
                                [html]="paymentsWidgetItem.overpaymentOptions.currentPaymentAmountLabelAndValueText">
                            </bb-content-html>
                        </div>
                        <div class="current-payment-value">
                            {{accountDetails?.next_payment_amount}}
                        </div>
                    </span>
                    <span class="container-row next-payment-section overpayment-section-label-title">
                        <div class="material-icons">add_shopping_cart</div>
                        <div class="next-payment-title bold-title no-para-margin"
                            test_id="newPaymentAmountLabelAndValueText">
                            <bb-content-html
                                [html]="paymentsWidgetItem.overpaymentOptions.newPaymentAmountLabelAndValueText">
                            </bb-content-html>
                        </div>
                        <div class="next-payment-value">
                            {{newPaymentValue}}
                        </div>
                    </span>
                </div>
                <div class="container-column payment-result-section overpayment-section-label-title no-border">
                    <div class="container-row no-change-text no-para-margin" test_id="overpaymentHPOption1PaymentInfoText">
                        <span>
                            <bb-content-html
                                [html]="paymentsWidgetItem.overpaymentOptions.overpaymentHPOption1PaymentBeforeDataInfoText">
                            </bb-content-html>
                            <span class="contact-number-text">
                                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overpaymentHPOption1PaymentBeforeDataInfoTextPartTwo">
                                </bb-content-html>
                                {{currentEndDateValue}}
                            </span>
                        </span>
                        <bb-content-html
                            [html]="paymentsWidgetItem.overpaymentOptions.overpaymentHPOption1PaymentAfterDataInfoText">
                        </bb-content-html>
                    </div>
                    <div class="instruction-container" test_id="permanentAmountChangeInfoText">
                        <!-- add all the content in one slot of this -->
                        <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.permanentAmountChangeInfoText">
                        </bb-content-html>
                    </div>
                    <div class="container-row content-center margin-bottom margin-top-10">
                        <button class="wss-button back-to-account-btn button-width-padding" test_id="back_account_id"
                            (click)="changeOptionClick('P')" id="lowerPaymentButton"
                            title="{{paymentsWidgetItem.overpaymentOptions.lowerPaymentButtonText}}">
                            {{paymentsWidgetItem.overpaymentOptions.lowerPaymentButtonText}}
                        </button>
                    </div>
                    <div class="payment-row overpayment-section-label-title choose-new-amount">
                        <button class="wss-button-secondary overpayment-warn-btn button-width-padding"
                            test_id="make_payment_id" (click)="changeOverpaymentAmount()"
                            title="{{paymentsWidgetItem.overpaymentOptions.newAmountSelectionLinkText}}">
                            {{paymentsWidgetItem.overpaymentOptions.newAmountSelectionLinkText}}
                        </button>
                    </div>
                    <div class="payment-row content-center text-align-left" test_id="needHelpLinkText">
                        <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.needHelpLinkText">
                        </bb-content-html>
                    </div>
                </div>
            </div>
            <div class="container-column payment-result-content over-payment hp-pcp-options option-container" *ngIf="overpaymentData && overpaymentData.length > 1">
                <div class="container-column payment-result-section">
                    <div class="container-row internal-section-title-postcard align-center no-para-margin"
                        test_id="overpaymentOption2LabelInfoText">
                        <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overpaymentOption2LabelInfoText">
                        </bb-content-html>
                    </div>
                </div>
                <div
                    class="container-column payment-result-section overpayment-section-label-title reduce-payment-text">
                    <div class="container-row payment-amount" test_id="agreementNewEndDateAmountTodayInfoText">
                        {{paymentsWidgetItem.overpaymentOptions.agreementNewEndDateInfoText}}
                        <span><strong>£{{quoteAmount}}</strong>
                        </span>
                        {{paymentsWidgetItem.overpaymentOptions.overpaymentOptionPartDayInfoText}}
                    </div>
                </div>
                <div class="container-column payment-result-section current-next-block">
                    <span class="container-row current-payment-section overpayment-section-label-title">
                        <div class="material-icons">assignment_turned_in</div>
                        <div class="current-payment-title bold-title no-para-margin"
                            test_id="endDateWithoutOverpaymentLabelAndValueText">
                            <bb-content-html
                                [html]="paymentsWidgetItem.overpaymentOptions.endDateWithoutOverpaymentLabelAndValueText">
                            </bb-content-html>
                        </div>
                        <!-- One content slot of endPaymentAmountLabelAndValueText for this also -->
                        <div class="current-payment-value">
                            {{currentEndDateValue}}
                        </div>
                    </span>
                    <span class="container-row next-payment-section overpayment-section-label-title">
                        <div class="material-icons">add_shopping_cart</div>
                        <div class="next-payment-title bold-title no-para-margin"
                            test_id="endDateWithOverpaymentLabelAndValueText">
                            <bb-content-html
                                [html]="paymentsWidgetItem.overpaymentOptions.endDateWithOverpaymentLabelAndValueText">
                            </bb-content-html>
                        </div>
                        <div class="next-payment-value">
                            {{newEndDateValue}}
                        </div>
                    </span>
                </div>
                <div class="container-column payment-result-section overpayment-section-label-title no-border">
                    <div class="container-row no-change-text no-para-margin" test_id="overpaymentHPOption2PaymentInfoText">
                        <bb-content-html
                            [html]="paymentsWidgetItem.overpaymentOptions.overpaymentHPOption2PaymentInfoText">
                        </bb-content-html>
                    </div>
                    <div class="instruction-container" test_id="permanentAmountChangeInfoText">
                        <!-- add all the content in one slot of this -->
                        <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.permanentAmountChangeInfoText">
                        </bb-content-html>
                    </div>
                    <div class="container-row content-center margin-bottom margin-top-10">
                        <button class="wss-button back-to-account-btn button-width-padding" test_id="back_account_id"
                            (click)="changeOptionClick('T')" id="termReductionButton"
                            title="{{paymentsWidgetItem.overpaymentOptions.reduceAgreementButtonText}}">
                            {{paymentsWidgetItem.overpaymentOptions.reduceAgreementButtonText}}
                        </button>
                    </div>
                    <div class="payment-row overpayment-section-label-title choose-new-amount">
                        <button class="wss-button-secondary overpayment-warn-btn button-width-padding"
                            test_id="make_payment_id" (click)="changeOverpaymentAmount()"
                            title="{{paymentsWidgetItem.overpaymentOptions.newAmountSelectionLinkText}}">
                            {{paymentsWidgetItem.overpaymentOptions.newAmountSelectionLinkText}}
                        </button>
                    </div>
                    <div class="payment-row content-center text-align-left" test_id="needHelpLinkText">
                        <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.needHelpLinkText">
                        </bb-content-html>
                    </div>
                </div>
            </div>
        </div>
        <div class="payment-column over-payment-column margin-bottom hp-pcp-options" *ngIf="!hpCustomerFlag"
            aria-describedby="PCPcustomers">
            <div class="container-column payment-result-content over-payment">
                <div class="container-column over-payment-quote">
                    <div class="container-row over-payment-quote-icon">
                        <div class="wss-quote-icon quote-icon"></div>
                    </div>
                    <div class="container-row quote-amount text-center" test_id="lowerMonthlyLabelText">
                        <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.lowerMonthlyLabelText">
                        </bb-content-html>
                    </div>
                </div>
                <div
                    class="container-column payment-result-section overpayment-section-label-title reduce-payment-text">
                    <div class="container-row payment-amount" test_id="newMonthyPaymentAmountTodayInfoText">
                        {{paymentsWidgetItem.overpaymentOptions.newMonthyPaymentInfoText}}
                        <span><strong>£{{quoteAmount}}</strong></span>
                        {{paymentsWidgetItem.overpaymentOptions.overpaymentOptionPartDayInfoText}}
                    </div>
                </div>
                <div class="container-column payment-result-section current-next-block">
                    <span class="container-row current-payment-section overpayment-section-label-title">
                        <div class="material-icons">assignment_turned_in</div>
                        <div class="current-payment-title bold-title no-para-margin"
                            test_id="currentPaymentAmountLabelAndValueText">
                            <bb-content-html
                                [html]="paymentsWidgetItem.overpaymentOptions.currentPaymentAmountLabelAndValueText">
                            </bb-content-html>
                        </div>
                        <div class="current-payment-value">
                            {{accountDetails?.next_payment_amount}}
                        </div>
                    </span>
                    <span class="container-row next-payment-section overpayment-section-label-title">
                        <div class="material-icons">add_shopping_cart</div>
                        <div class="next-payment-title bold-title no-para-margin"
                            test_id="newPaymentAmountLabelAndValueText">
                            <bb-content-html
                                [html]="paymentsWidgetItem.overpaymentOptions.newPaymentAmountLabelAndValueText">
                            </bb-content-html>
                        </div>
                        <div class="next-payment-value">
                            {{newPaymentValue}}
                        </div>
                    </span>
                </div>
                <div class="container-column payment-result-section overpayment-section-label-title no-border">
                    <div class="container-row no-change-text no-para-margin" test_id="overpaymentPCPOption1PaymentInfoText">
                        <!-- <bb-content-html
                            [html]="paymentsWidgetItem.overpaymentOptions.overpaymentPCPOption1PaymentInfoText">
                        </bb-content-html> -->

                        <span>
                            <bb-content-html
                                [html]="paymentsWidgetItem.overpaymentOptions.overpaymentPCPOption1PaymentBeforeInfoText">
                            </bb-content-html>
                            <span class="contact-number-text">
                                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overpaymentPCPOption1PaymentBeforeInfoTextPartTwo">
                                </bb-content-html>
                                {{currentEndDateValue}}
                            </span>
                        </span>
                        <bb-content-html
                            [html]="paymentsWidgetItem.overpaymentOptions.overpaymentPCPOption1PaymentAfterInfoText">
                        </bb-content-html>
                        <span *ngIf="isLPAgreementFlag">
                            <bb-content-html
                                [html]="paymentsWidgetItem.overpaymentOptions.overpaymentLPOptionTxt">
                            </bb-content-html>
                        </span>
                    </div>
                    <div class="instruction-container" test_id="permanentAmountChangeInfoText">
                        <!-- add all the content in one slot of this -->
                        <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.permanentAmountChangeInfoText">
                        </bb-content-html>
                    </div>
                    <div class="container-row content-center margin-bottom margin-top-10">
                        <button class="wss-button back-to-account-btn button-width-padding" test_id="back_account_id"
                            (click)="changeOptionClick('P')" id="lowerPaymentButton"
                            title="{{paymentsWidgetItem.overpaymentOptions.lowerPaymentButtonText}}">
                            {{paymentsWidgetItem.overpaymentOptions.lowerPaymentButtonText}}
                        </button>
                    </div>
                    <div class="payment-row overpayment-section-label-title choose-new-amount">
                        <button id="newAmountSelectionButton" class="wss-button-secondary overpayment-warn-btn button-width-padding"
                            test_id="make_payment_id" (click)="changeOverpaymentAmount()"
                            title="{{paymentsWidgetItem.overpaymentOptions.newAmountSelectionLinkText}}">
                            {{paymentsWidgetItem.overpaymentOptions.newAmountSelectionLinkText}}
                        </button>
                    </div>
                    <div class="payment-row content-center" test_id="needHelpLinkText">
                        <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.needHelpLinkText">
                        </bb-content-html>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="over-payment-warning-container" *ngIf="overPaymentWarningFlag && !overpaymentNotAllowed">
        <div class="container-column payment-warning-content over-payment-warning-content">
            <span class="wss-failure-icon failure-icon"></span>
            <div class="payment-row margin-top-10">
                <strong class="over-payment-warning-info" test_id="overPaymentExceedWarningInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overPaymentExceedWarningInfoText">
                    </bb-content-html>
                </strong>
            </div>
            <div class="payment-row margin-top-10">
                <span class="overpayment-warning-info overpayment-info-text" test_id="overpaymentSupportInfoText">
                    <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overpaymentSupportInfoText">
                    </bb-content-html>
                </span>
            </div>
            <div class="payment-row">
                <span class="overpayment-warning-info overpayment-info-text" test_id="morePaymentInfoText">
                    {{paymentsWidgetItem.overpaymentOptions.morePaymentInfoText}}
                    <a (click)="getInTouchCall()" title="{{paymentsWidgetItem.overpaymentOptions.getInTouchLinkText}}"
                        tabindex="0" test_id="getInTouchLinkText">
                        {{paymentsWidgetItem.overpaymentOptions.getInTouchLinkText}}
                    </a>
                </span>
            </div>
        </div>
        <div class="payment-row content-center">
            <button class="wss-button warning-btn overpayment-warn-btn button-width-padding" test_id="make_payment_id"
                (click)="changeOverpaymentAmount()"
                title="{{paymentsWidgetItem.overpaymentOptions.newAmountSelectionLinkText}}">
                {{paymentsWidgetItem.overpaymentOptions.newAmountSelectionLinkText}}
            </button>
        </div>
        <div class="payment-row content-center margin-top-10">
            <a (click)="backToMyAccountClick()"
                title="{{paymentsWidgetItem.overpaymentOptions.overpaymentBackToHomeButtonText}}" tabindex="0"
                test_id="overpaymentBackToHomeButtonText">
                {{paymentsWidgetItem.overpaymentOptions.overpaymentBackToHomeButtonText}}
            </a>
        </div>
    </div>

    <div class="container-column payment-warning-content over-payment-warning-content"
        *ngIf="accountIneligibleFlag && !overpaymentNotAllowed">
        <span class="wss-failure-icon failure-icon"></span>
        <div class="payment-row">
            <strong class="over-payment-warning-info" test_id="accountIneligibleFailureLabelText">
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.accountIneligibleFailureLabelText">
                </bb-content-html>
            </strong>
        </div>
        <div class="payment-row">
            <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                test_id="accountIneligibleFailureInfoText">
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.accountIneligibleFailureInfoText">
                </bb-content-html>
                <a (click)="getInTouchCall()" title="{{paymentsWidgetItem.overpaymentOptions.getInTouchLinkText}}"
                    tabindex="0" test_id="getInTouchLinkText">
                    {{paymentsWidgetItem.overpaymentOptions.getInTouchLinkText}}
                </a>
            </span>

        </div>
        <div class="payment-row content-center">
            <button class="wss-button warning-btn overpayment-warn-btn button-width-padding" test_id="make_payment_id"
                (click)="backToMyAccountClick()"
                title="{{paymentsWidgetItem.overpaymentOptions.overpaymentBackToHomeButtonText}}">
                {{paymentsWidgetItem.overpaymentOptions.overpaymentBackToHomeButtonText}}
            </button>
        </div>
    </div>

    <div class="container-column payment-warning-content over-payment-warning-content"
        *ngIf="dueDayExceptionFlag && !overpaymentNotAllowed">
        <span class="wss-failure-icon failure-icon"></span>
        <div class="payment-row">
            <strong class="over-payment-warning-info" test_id="overPaymentDueDayWarningLabelText">
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overPaymentDueDayWarningLabelText">
                </bb-content-html>
            </strong>
        </div>
        <div class="payment-row" test_id="overPaymentDueDayWarningInfoText">
            <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overPaymentDueDayWarningInfoText">
            </bb-content-html>
        </div>
        <div class="payment-row" test_id="overpayLearnInfoLinkText">
            <a (click)="makePaymentFaqsClick()" title="{{paymentsWidgetItem.overpaymentOptions.overpayLearnInfoLinkText}}" tabindex="0">
                {{paymentsWidgetItem.overpaymentOptions.overpayLearnInfoLinkText}}
            </a>
        </div>
        <div class="payment-row content-center btn-default-width">
            <button class="wss-button warning-btn overpayment-warn-btn button-width-padding " test_id="make_payment_id"
                (click)="backToMyAccountClick()"
                title="{{paymentsWidgetItem.overpaymentOptions.overpaymentBackToHomeButtonText}}">
                {{paymentsWidgetItem.overpaymentOptions.overpaymentBackToHomeButtonText}}
            </button>
        </div>
    </div>

    <div class="container-column payment-warning-content over-payment-warning-content"
        *ngIf="enableRequestSettlement && !overpaymentNotAllowed">
        <div class="trophy-icon-div">
            <span class="wss-trophy-icon trophy-icon"></span>
        </div>
        <div class="payment-row">
            <strong class="over-payment-warning-info" test_id="eligibleSettleAgreementLabelText">
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.eligibleSettleAgreementLabelText">
                </bb-content-html>
            </strong>
        </div>
        <div class="payment-row">
            <span class="overpayment-warning-info overpayment-info-text" test_id="eligibleSettleAgreementInfoText">
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.eligibleSettleAgreementInfoText">
                </bb-content-html>
            </span>
        </div>
        <!-- created one slot for both the content -->
        <div class="payment-row content-center">
            <button class="wss-button warning-btn overpayment-warn-btn button-width-padding" test_id="make_payment_id"
                (click)="requestSettlementClick()"
                title="{{paymentsWidgetItem.overpaymentOptions.settleMyAgreementButtonText}}">
                {{paymentsWidgetItem.overpaymentOptions.settleMyAgreementButtonText}}
            </button>
        </div>
        <div class="payment-row content-center margin-top-20 choose-new-amount">
            <button class="wss-button-secondary overpayment-warn-btn button-width-padding" test_id="make_payment_id"
                (click)="changeOverpaymentAmount()"
                title="{{paymentsWidgetItem.overpaymentOptions.newAmountSelectionLinkText}}">
                {{paymentsWidgetItem.overpaymentOptions.newAmountSelectionLinkText}}
            </button>
        </div>
    </div>

    <div class="container payment-warning-content warning-container overpayment-warning-content"
        *ngIf="overpaymentNotAllowed" aria-describedby="warningPaymentInfo">
        <div class="payment-warning-icon-div">
            <span class="wss-warning-icon warning-icon" alt="Warning Icon"></span>
        </div>
        <div>
            <strong class="payment-warning-info margin-top-10" test_id="overPaymentUntimeWarningLabelText">
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overPaymentUntimeWarningLabelText">
                </bb-content-html>
            </strong>
        </div>
        <div>
            <span class="payment-warning-info pg-inline mb-5px" test_id="overPaymentUntimeWarningInfoText">
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overPaymentUntimeWarningInfoText">
                </bb-content-html>
            </span>
        </div>
        <div class="align-center" *ngIf="paymentsWidgetItem.overpaymentOptions.overPaymentHelpAndSupportText">
            <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                test_id="overPaymentHelpAndSupportText">
                <!-- Add in this slot only all -->
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overPaymentHelpAndSupportText">
                </bb-content-html>
            </span>
        </div>
        <div class="container-row content-center">
            <button role="button" class="wss-button btn-width warn-btn-dif-view button-width-padding"
                test_id="make_payment_id" (click)="backToHomeClick()"
                title="{{paymentsWidgetItem.overpaymentOptions.overpaymentBackToHomeButtonText}}">
                {{paymentsWidgetItem.overpaymentOptions.overpaymentBackToHomeButtonText}}
            </button>
        </div>
    </div>

    <div class="container payment-warning-content warning-container overpayment-warning-content"
        *ngIf="overpaymentGenericError" aria-describedby="warningPaymentInfo">
        <div class="payment-warning-icon-div">
            <span class="wss-warning-icon warning-icon" alt="Warning Icon"></span>
        </div>
        <div>
            <strong class="payment-warning-info margin-top-10" test_id="overPaymentGenericErrorLabelText">
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overPaymentGenericErrorLabelText">
                </bb-content-html>
            </strong>
        </div>
        <div class="align-center">
            <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                test_id="overPaymentGenericErrorInfoText">
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overPaymentGenericErrorInfoText">
                </bb-content-html>
            </span>
        </div>
        <div class="container-row content-center">
            <button id="backToPaymentsButton" role="button" class="wss-button btn-width warn-btn-dif-view button-width-padding"
                test_id="backToPaymentsButtonText" (click)="backToPaymentsClick()"
                title="{{paymentsWidgetItem.overpaymentOptions.backToPaymentsButtonText}}">
                {{paymentsWidgetItem.overpaymentOptions.backToPaymentsButtonText}}
            </button>
        </div>
    </div>

    <div class="container payment-warning-content warning-container overpayment-warning-content"
        *ngIf="pesQuoteAmendedAlreadyFlag" aria-describedby="warningPaymentInfo">
        <div class="payment-warning-icon-div">
            <span class="wss-warning-icon warning-icon" alt="Warning Icon"></span>
        </div>
        <div *ngIf="paymentsWidgetItem.overpaymentOptions.overPaymentAccountAmendedErrorLabelText">
            <strong class="payment-warning-info margin-top-10" test_id="overPaymentAccountAmendedErrorLabelText">
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overPaymentAccountAmendedErrorLabelText">
                </bb-content-html>
            </strong>
        </div>
        <div class="align-center" *ngIf="paymentsWidgetItem.overpaymentOptions.overPaymentAccountAmendedErrorInfoText">
            <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                test_id="overPaymentAccountAmendedErrorInfoText">
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overPaymentAccountAmendedErrorInfoText">
                </bb-content-html>
            </span>
        </div>
        <div class="align-center" *ngIf="paymentsWidgetItem.overpaymentOptions.overPaymentPesQuoteHelpAndSupportText">
            <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                test_id="overPaymentPesQuoteHelpAndSupportText">
                <!-- Add in this slot only all -->
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overPaymentPesQuoteHelpAndSupportText">
                </bb-content-html>
            </span>
        </div>
        <div class="container-row content-center">
            <button id="backToPaymentsButton" role="button" class="wss-button btn-width warn-btn-dif-view button-width-padding"
                test_id="backToPaymentsButtonText" (click)="backToPaymentsClick()"
                title="{{paymentsWidgetItem.overpaymentOptions.backToPaymentsButtonText}}">
                {{paymentsWidgetItem.overpaymentOptions.backToPaymentsButtonText}}
            </button>
        </div>
    </div>

    <div class="container payment-warning-content warning-container overpayment-warning-content"
        *ngIf="pesQuoteSameAmountFlag" aria-describedby="warningPaymentInfo">
        <div class="payment-warning-icon-div">
            <span class="wss-warning-icon warning-icon" alt="Warning Icon"></span>
        </div>
        <div class="align-center" *ngIf="paymentsWidgetItem.overpaymentOptions.overPaymentSameAmountErrorText">
            <span class="overpayment-warning-info overpayment-info-text account-ineligible-info"
                test_id="overPaymentSameAmountErrorText">
                <bb-content-html [html]="paymentsWidgetItem.overpaymentOptions.overPaymentSameAmountErrorText">
                </bb-content-html>
            </span>
        </div>
        <div class="container-row content-center">
            <button id="backToPaymentsButton" role="button" class="wss-button btn-width warn-btn-dif-view button-width-padding"
                test_id="backToPaymentsButtonText" (click)="backToPaymentsClick()"
                title="{{paymentsWidgetItem.overpaymentOptions.backToPaymentsButtonText}}">
                {{paymentsWidgetItem.overpaymentOptions.backToPaymentsButtonText}}
            </button>
        </div>
    </div>
</div>