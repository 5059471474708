<!-- <div class="container-row arrear-box-height">
    <div class="arrear-warning-box">
            <span class="icon_warning_amber"></span>
    </div>
    <div class="container-row arrear-text-context">
        <div class="container-row arrear-text-margin">
        <span class="arrear-flex-width" test_id="arrear_msg_id">
            <ng-container *ngIf="arrearData?.isArrangement">
                {{content?.witharrngmnt?.replace('?', arrearData?.arrearAmount)}}. <a class="faq-link">{{content?.needhelp}}</a>. *{{content?.thismbeaffecting}}. <a class="faq-link">{{content?.learnmore}}</a>
            </ng-container>
            <ng-container *ngIf="!arrearData?.isArrangement">

            </ng-container>
            {{content?.currently}} &pound;{{arrearData?.arrearAmount}} {{content?.arrears}}
        </span>
        <span class="material-icons arrear-icons-font-size arrear-flex-width-clear-icon" (click)="closeArrearBox()">clear</span>
        </div>
        
    </div>
</div> -->
<bb-wss-banner-message [inArrears]="inArrears" [arrearData]="arrearMessage"></bb-wss-banner-message>