import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpService } from '../../../service/http.service';
import { DatastoreService } from '../../../service/datastore.service';
import { Transaction, Statement } from '../model/transactions';
import { catchError, map} from 'rxjs/operators';
import { getDate } from '../../../common/util/util';
import { MessageService } from '../../../service/message.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  dataWithDate: Transaction[] = [];

  constructor(
    private httpService: HttpService, 
    private datastoreService: DatastoreService,
    private messageService: MessageService) { }

  getTransactions(noOfTransactions: number, lastSequenceNo: number): Observable<Transaction[]>{
    return this.httpService.getTransactions(noOfTransactions, lastSequenceNo)
    .pipe(map(data => {
      this.dataWithDate = data.map((transaction) => { 
        transaction.transaction_date =  getDate(transaction.transaction_date);
        let indeofMinus = transaction.transaction_value.indexOf("-");
        transaction.transaction_value = indeofMinus !== -1? transaction.transaction_value.substring(1) + ' CR' : transaction.transaction_value;
        return {...transaction}
      });
      this.datastoreService.setTransactions(this.dataWithDate);
      return data;
    }));
  }

  requestStatement(): Observable<any>{
    return this.httpService.requestStatement().
    pipe(catchError(error => {
      this.messageService.setServiceError(error.error[0]);
      return error;
    }));
  }

  getRequestedStatement(): Observable<any>{
    return this.httpService.getRequestedStatement();
  }

  getAccountDetails(): Observable<any>{
    return this.httpService.getAccountDetails().
    pipe(map(data =>{
      this.datastoreService.setAccountDetails(data);
    }));
  }

}