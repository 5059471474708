import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[allow]'
})
export class AllowCharacterDirective {
    @Input() allow: string = '';
    @Input() separator: string = ','; //default is comma

    constructor() {
    }

    @HostListener('keypress', ['$event']) onKeyPress(evt: any) {
        const allows = this.allow.split(this.separator).map((item) => item.trim());
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        const letters = (charCode <= 122 && charCode >= 97) || (charCode <= 90 && charCode >= 65) ? 'Letter': '';
        const numbers = (charCode <= 57 && charCode >= 48) ? 'Number' : '';
        const space = charCode == 32 ? 'Space' : '';
        const result = allows.includes(evt.key) || allows.includes(letters) || allows.includes(numbers) || allows.includes(space);
        if(!result){
            evt.preventDefault();
        }
      }
}