<div class="balloon-refinance" id="balloon-refinance-getaquote-cont"
    *ngIf="balloonRefinanceWidgetItem">
    <div role="alert" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="container-column">
        <div class="section-title-container">
            <h1 class="section-label-title" test_id="pageName">
                {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.pageTitle}}
            </h1>
        </div>
        <section class="content-width single-column">
            <div class="para-inline mb-20px">
                <div test_id="quoteDetailsContentFirstPara" class="quote-text-bold mb-10px">
                    {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteDetailsContentBeforeData}} <span class="low-underline">{{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteDetailsContentBeforeDataUnderlined}} {{this.refinanceAmount | currency:'GBP':'symbol'}}{{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteDetailsContentAfterDataUnderlined}}</span>
                </div>
                <bb-content-html [html]="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteDetailsContentAfterData">
                </bb-content-html>
            </div>
            <div class="card p-20px section-card">
                <h2 class="section-sub-title mb-20px" test_id="quoteInputTitle">
                    {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteInputTitle}}
                </h2>
                <div class="term-section-cont pb-20px">
                    <div class="quote-input-section">
                        <div class="quote-lbl-align">
                            <div class="input-title mb-15px quote-lbl" test_id="quoteInputLabel">
                                <bb-content-html [html]="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteInputLabel">
                                </bb-content-html>
                            </div>
                        </div>

                        <form [formGroup]="quoteForm">
                            <div class="quote-input-Cont">
                                <div class="amount-form-content">
                                    <div class="term-cont"
                                        [ngClass]="disableBtn && hasBeenTouched ? 'term-cont-disable' : 'term-cont-enable'">
                                        <div (click)="updateTerm('decrement')"
                                            class="d-flex justify-content-center"
                                            [ngClass]="decrementer ? 'term-decrement-cont cursor-link' : 'term-decrement-cont term-decrement-cont--disable cursor-disable'"
                                            (touchstart)="setTimerIncement(termLength, 'decrement');"
                                            (touchend)="clearTimer();"
                                            (mousedown)="setTimerIncement(termLength, 'decrement');"
                                            (mouseup)="clearTimer();" (mouseout)="clearTimer();" accesskey="e">
                                            <span class="material-icons d-flex flex-column justify-content-center color-white">remove</span>
                                        </div>
                                        <div>
                                            <style>
                                                input#wss-br-loanterm::placeholder {
                                                    opacity: 54%;
                                                }
                                            </style>
                                            <input type="text" id="wss-br-loanterm" name="wss-br-loanterm"
                                                value="{{termLength}}" maxLength="2"
                                                class="form-control form-control-lg rounded-2 amount-input"
                                                (keyup)="termLengthHandler($event)" [(ngModel)]="termLength"
                                                formControlName="loanTerm"
                                                onKeyPress="if(this.value.length ==2 ) return false;"
                                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                                autocomplete="off" #loanTermInput tabindex="0"
                                                (keyup.enter)="termLengthHandler($event)" placeholder="eg.12">
                                        </div>
                                        <div (click)="updateTerm('increment')" 
                                            class="d-flex justify-content-center"
                                            [ngClass]="incrementer ? 'term-increment-cont cursor-link' : 'term-increment-cont term-increment-cont--disable cursor-disable'"
                                            (mousedown)="setTimerIncement(termLength, 'increment');"
                                            (mouseup)="clearTimer();" (mouseout)="clearTimer();" accesskey="i"
                                            (touchstart)="setTimerIncement(termLength, 'increment');"
                                            (touchend)="clearTimer();">
                                            <span class="material-icons d-flex flex-column justify-content-center">add</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="disableBtn && hasBeenTouched && !termIn12To60Range && !minValueLimitError && !(ageLimitError && termAccepted < 12 ) && !maxValueLimitError" class="error-text mt-5px"
                                test_id="termInputError">
                                {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.termInputError}}
                            </div>
                            <div *ngIf="disableBtn && (ageLimitError && termAccepted < 12) && !minValueLimitError && !maxValueLimitError" class="error-text mt-20px"
                            test_id="termInputError">
                                {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.vehicleAgeErrorLessThan12Months}}                            
                            </div>
                            <div *ngIf="disableBtn && (ageLimitError && termAccepted >= 12) && !minValueLimitError && !maxValueLimitError && termIn12To60Range" class="error-text mt-20px"
                            test_id="termInputError">
                                {{over12MonthAgeErrorMessage}}                            
                            </div>
                            <div *ngIf="disableBtn && minValueLimitError && !ageLimitError" class="error-text mt-20px"
                            test_id="termInputError">
                                {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.minValueLimitError}}                            
                            </div>
                            <div *ngIf="disableBtn && ageLimitError && minValueLimitError" class="error-text mt-20px"
                            test_id="termInputError">
                                {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.vehicleMinValueAndAgeLimitError}}
                            </div>    
                            <div *ngIf="disableBtn && ageLimitError && maxValueLimitError" class="error-text mt-20px"
                            test_id="termInputError">
                                {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.vehicleMaxValueAndAgeLimitError}}                              
                            </div>
                            <div *ngIf="disableBtn && maxValueLimitError && !minValueLimitError && !ageLimitError" class="error-text mt-20px"
                            test_id="termInputError">
                                {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.maxValueLimitError}}                            
                            </div>
                        </form>
                    </div>
                    <ng-container *ngIf="(!disableBtn && (!hasBeenTouched || !ageLimitError))">
                        <span class="quote-divider"></span>
                        <div class="payment-section">
                            <div test_id="quoteCalculateTextSectionDesktop">
                                {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteCalculateTextSection}}</div>
                            <div class="payment-amount mt-20px" test_id="quoteCalculateAmountDesktop">
                                {{monthlyPayment | currency:'GBP':'symbol'}} {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteCalculatePerMonth}}
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="(!disableBtn && (!hasBeenTouched || !ageLimitError))" class="quote-details pt-20px">
                    <h2 test_id="quoteNewDetailsHeader" class="quote-new-header mb-20px">
                        {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteNewDetailsHeader}}</h2>
                    <div class="quote-items-container">
                        <div class="quote-item">
                            <div class="wss-add-box-icon quote-icons quote-icons-flat"></div>                            
                            <div class="quote-details-item">
                                <span class="quote-details-title" test_id="quoteTotalInterestTitle">
                                    {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteTotalInterestTitle}}
                                    <ng-container *ngIf="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteTotalInterestPopoverText">
                                        <wss-popover [popoverContent]="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteTotalInterestPopoverText" [popOverId]="'quoteTotalInterestPopoverId'"></wss-popover>
                                    </ng-container>
                                </span>
                                <span test_id="quoteTotalInterestText">
                                    {{totalInterest | currency:'GBP':'symbol'}}
                                </span>                                
                            </div>
                        </div>
                        <div class="quote-item">
                            <div class="wss-direct-debit-icon quote-icons quote-icons-refinance"></div>                            
                            <div class="quote-details-item">
                                <span class="quote-details-title" test_id="quoteRefinanceAmountTitle">
                                    {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteRefinanceAmountTitle}}
                                    <ng-container *ngIf="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteRefinanceAmountPopoverText">
                                        <wss-popover [popoverContent]="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteRefinanceAmountPopoverText" [popOverId]="'quoteRefinanceAmountPopoverId'"></wss-popover>
                                    </ng-container>
                                </span>
                                <span test_id="quoteRefinanceAmountText">
                                    {{finalPayment | currency:'GBP':'symbol'}}
                                </span>                                
                            </div>
                        </div>
                        <div class="quote-item">
                            <div class="wss-chart-icon quote-icons quote-icons-flat"></div>
                            <div class="quote-details-item">
                                <span class="quote-details-title" test_id="quoteAPRTitle">
                                    {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteAPRTitle}}
                                    <ng-container *ngIf="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteAPRPopoverText">
                                        <wss-popover [popoverContent]="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteAPRPopoverText" [popOverId]="'quoteAPRPopoverId'"></wss-popover>
                                    </ng-container>
                                </span>
                                <span test_id="quoteAPRText">
                                    {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteAPRPercentage}}%
                                </span>
                            </div>
                        </div>
                        <div class="quote-item">
                            <div class="wss-receipt-icon quote-icons quote-icons-normal"></div>                            
                            <div class="quote-details-item">
                                <span class="quote-details-title" test_id="quoteTotalAmountTitle">
                                    {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteTotalAmountTitle}}
                                    <ng-container *ngIf="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteTotalAmountPopoverText">
                                        <wss-popover [popoverContent]="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteTotalAmountPopoverText" [popOverId]="'quoteTotalAmountPopoverId'"></wss-popover>
                                    </ng-container>
                                </span>
                                <span test_id="quoteTotalAmountText">
                                    {{totalAmount | currency:'GBP':'symbol'}}*
                                </span>                                
                            </div>
                        </div>
                        <div class="quote-item">
                            <div class="wss-calendar-month-icon quote-icons quote-icons-normal"></div>                            
                            <div class="quote-details-item">
                                <span class="quote-details-title" test_id="quoteTermTitle">
                                    {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteTermTitle}}
                                    <ng-container *ngIf="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteTermPopoverText">
                                        <wss-popover [popoverContent]="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteTermPopoverText" [popOverId]="'quoteTermPopoverId'"></wss-popover>
                                    </ng-container>
                                </span>
                                <span test_id="quoteTermText">
                                    {{termLength}} {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteTermText}}
                                </span>                                
                            </div>
                        </div>
                        <div class="quote-item">
                            <div class="wss-car-light-icon quote-icons quote-icons-car"></div>                            
                            <div class="quote-details-item">
                                <span class="quote-details-title" test_id="quoteAgreementTitle">
                                    {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteAgreementTitle}}
                                    <ng-container *ngIf="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteAgreementPopoverText">
                                        <wss-popover [popoverContent]="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteAgreementPopoverText" [popOverId]="'quoteAgreementPopoverId'"></wss-popover>
                                    </ng-container>
                                </span>
                                <span test_id="quoteAgreementText">
                                    {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteAgreementText}}
                                </span>                                
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="isQuoteReturnJourney ? '' : 'pb-30px quote-br-bottom'" test_id="quoteOptionFeeTextSection">
                        {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteOptionFeeTextSection1}} 
                        <a href="javascript:void(0)" (click)="openOptionFeeContent('optionFeeCont')" tabindex="0"
                            class="linkTab">
                            {{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteOptionFeeTextSection2}}</a>
                    </div>
                </div>
                <div *ngIf="!isQuoteReturnJourney">
                    <div class="pt-20px text-semibold" test_id="quoteProceedText">
                        <bb-content-html [html]="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteProceedText"></bb-content-html>
                    </div>
                    <div>
                        <button class="wss-button btn-margin mb-0" (click)="navigate()" test_id="btnApply"
                            [disabled]="disableBtn" accesskey="q" tabindex="0"
                            id="wss-br-quote-apply">{{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteButtonAcceptText}}</button>
                        <button class="wss-button-secondary mb-0" (click)="navigateToHome()" test_id="btnCancel" accesskey="x"
                            tabindex="0"
                            id="wss-br-quote-cancel">{{balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.quoteButtonCancelText}}</button>
                    </div>
                </div>
            </div>
            <div *ngIf="isQuoteReturnJourney">
                <div class="mb-40px">
                    <wss-common-nav-buttons
                        [content]="balloonRefinanceWidgetItem.commonNavigation"
                        [testIdText]="commonNavTestID"
                        [isQuoteReturnJourney]="isQuoteReturnJourney"
                        [saveAndReviewBtnDisable]="saveAndReviewBtnDisable"
                        (continueButtonClicked)="onContinue($event)">
                    </wss-common-nav-buttons>
                </div>
            </div>
            <div class="mt-40px w-100">
                <div class="faq-header">
                    <bb-content-html [html]="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.FAQHeader"></bb-content-html>
                </div>
                <div class="container-row expansion-border-hidden transparent-accordion balloon-refinance-accordion-header balloon-refinance-accordion-header-color">
                    <bb-common-expansion-panel [accordionContent]="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.FAQAccordions"
                        testIDText="FAQAccordions" class="w-100">
                    </bb-common-expansion-panel>
                </div>
            </div>
            <div id="optionFeeCont" class="container-row expansion-border-hidden transparent-accordion top-border-none balloon-refinance-accordion-header balloon-refinance-accordion-header-color">
                <mat-accordion class="accordion mat-accordion-override w-100 ">
                    <mat-expansion-panel class="mat-expansion-panel-override mat-expansion-panel-spacing-override"
                        (opened)="openOptionCont = true" (closed)="openOptionCont = false" [expanded]="openOptionCont">
                        <mat-expansion-panel-header (click)="updateOption()">
                            <mat-panel-title class="mat-expansion-panel-header-title-override" id="optionFeeTitle">
                                <bb-content-html
                                    [html]="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.FAQAccordionOptionFeeTitle">
                                </bb-content-html>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p id="optionFeeContent">
                            <bb-content-html [html]="balloonRefinanceWidgetItem.balloonRefinanceGetAQuote.FAQAccordionOptionFeeText">
                            </bb-content-html>
                        </p>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </section>
    </div>
</div>
