import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { Router } from '@angular/router';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { BalloonRefinanceGetdetailsItem, ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { Observable, Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { patternMap, currencySymbol } from '../../../../config/wss-app-constants';
import { BalloonRefinanceService } from '@wss/balloon-refinance-widget/src/services/balloon-refinance.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wss-balloon-refinance-getdetails-finance',
  templateUrl: './balloon-refinance-getdetails-finance.component.html'
})

export class BalloonRefinanceGetdetailsFinanceComponent implements OnInit {

  balloonRefinanceGetdetailsFinanceItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');

  currentPage: number = 4;
  previousPageLink: string = '/balloon-refinance-getdetails/employment';
  accountDetails: any;
  commonNavTestID: string = 'wss-Brg-Finance-';
  lastDateOfDecision: any;
  idExpandArr: Array<any> = ['brvExpandIncome', 'brvExpandMortgage', 'brvExpandExpense', 'brvExpandExpected'];
  idCollapseArr: Array<any> = ['brvCollapseIncome', 'brvCollapseMortgage', 'brvCollapseExpense', 'brvCollapseExpected'];
  idPanelArr: Array<any> = ['brvInforIncome', 'brvInforMortgage', 'brvInforExpense', 'brvInforExpected'];
  financeDetailsForm!: FormGroup;
  submitted: boolean =  false;
  currencySym: string = currencySymbol.gbp;
  elementId: Array<any> = ['grossIncome', 'mortgageRental', 'expenseTotal']; 
  showInputError: boolean = true;
  brGetdetailsWidgetItem: any = new BalloonRefinanceGetdetailsItem();
  expectedChangeData: any;
  readonly destroy$ = new Subject();
  proposalData: ProposalRequest;
  serviceError: boolean = false;
  isReturnJourney: boolean = false;
  defaultTopMarginForGrossIncome: boolean = false;

  constructor(
    private readonly bbContentService: ContentService,
    private router: Router,
    private datastore: DatastoreService,
    private tealiumService: TealiumUtagService,
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private balloonRefinanceSrv: BalloonRefinanceService,
    ) {
      this.proposalData = this.datastore.getProposalRequest();
    }  

  ngOnInit(): void {
    this.datastore.sessionInvalid();
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'Proposal - Finance details',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    ); 
    this.datastore.brReturnJourney$.subscribe((res: boolean) => {
      this.isReturnJourney = res;
      this.changeDetectorRef.detectChanges();
    });   
    this.accountDetails = this.datastore.getAccountDetails();
    this.lastDateOfDecision = this.datastore.getLastDateForEocDecision(this.accountDetails?.final_payment_date);
    this.setCurentPage();
    this.getContentData();
    this.initFinanceForm();
    this.setFormData();
  }

  setCurentPage() {
    this.datastore.setBRCurrentPage(this.currentPage);
  }

  getContentData() {
    this.balloonRefinanceGetdetailsFinanceItem$.subscribe((pageItem: any) => {
      this.brGetdetailsWidgetItem = pageItem;
      this.setOptionExpectedChange();  
      this.changeDetectorRef.detectChanges();
    })    
  }

  setOptionExpectedChange() {
    this.expectedChangeData = [  
      { value: 0, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsFinance.expectNone },
      { value: 1, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsFinance.expectRetire },
      { value: 2, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsFinance.expectRedund },
      { value: 3, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsFinance.expectReduct },
      { value: 4, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsFinance.expectCareer },
      { value: 5, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsFinance.expectFamily },
      { value: 6, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsFinance.expectOther }
    ];
  }

  initFinanceForm() {
    this.financeDetailsForm = this.formBuilder.group({
      grossIncome: [
        '',
        Validators.compose([
          Validators.required, Validators.pattern(patternMap.wholeNumberPattern), Validators.min(1)  
        ]),
      ],
      mortgageRental: [
        '',
        Validators.compose([
          Validators.required, Validators.pattern(patternMap.wholeNumberPattern)  
        ]),
      ],
      expenseTotal: [
        '',
        Validators.compose([
          Validators.required, Validators.pattern(patternMap.wholeNumberPattern)  
        ]),
      ], 
      expectedChange: ['', Validators.required]  
    })
  }

  onSaveButtonClick() {
    this.router.navigate(['/myaccount']);
  }

  onContinue() {
    this.submitted = true; 
    if (this.financeDetailsForm.status === 'VALID') {
      this.saveDataProposal('balloon-refinance-getdetails/bank-account');
    } else {
      this.scrollToFirstInvalid();
    }     
  }

  scrollToFirstInvalid() {
    document.getElementsByClassName('ng-invalid')[1].scrollIntoView({behavior: 'smooth'});
  }

  saveDataProposal(urlElem: string) {    
    this.proposalData.customer_id = this.datastore.getCustomerId();
    this.proposalData.gross_annual_income = parseInt(this.financeDetailsForm.value.grossIncome);
    this.proposalData.mortgage_rental_expenditure = parseInt(this.financeDetailsForm.value.mortgageRental);
    this.proposalData.other_expenditure = parseInt(this.financeDetailsForm.value.expenseTotal);
    this.proposalData.change_in_circumstances = this.financeDetailsForm.value.expectedChange;
    this.proposalData.replace_existing_account = true;

    this.balloonRefinanceSrv.postProposalDetails(this.proposalData).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.proposalData = this.datastore.getProposalRequest();
      this.isReturnJourney ?  
        this.router.navigate(['/balloon-refinance-getdetails/summary']) : this.router.navigate([urlElem])
    }, 
    (error: any) => {
      this.showError();
    });
  }

  showError(): void {
    this.datastore.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  setFormData() {
    this.financeDetailsForm.controls['grossIncome'].setValue(this.proposalData.gross_annual_income);
    this.financeDetailsForm.controls['mortgageRental'].setValue(this.proposalData.mortgage_rental_expenditure);
    this.financeDetailsForm.controls['expenseTotal'].setValue(this.proposalData.other_expenditure);
    if (this.proposalData?.change_in_circumstances !== null) {
      this.financeDetailsForm.controls['expectedChange'].setValue(this.proposalData.change_in_circumstances);
    }
    this.changeDetectorRef.detectChanges();
  }

}
