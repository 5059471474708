import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';
import { MessageService } from 'libs/service/message.service';

@Component({
  selector: 'bb-eoc-confirmation',
  templateUrl: 'eoc-confirmation.component.html'
})
export class EocConfirmationComponent implements OnInit {

  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');

  // eocDetails: any = {
  //   final_payment_amount: "5000",
  //   final_payment_date: "21/12/2021"
  // }
  eocDetails: any = {};

  constructor(
    private router: Router,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    private datastoreService: DatastoreService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this.tealiumService.view (
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'Keep Vehicle Confirmation',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.eocDetails = this.datastoreService.getAccountDetails();
  }

  backToHomepage() {
    this.datastoreService.setEocRoute("landing");
    this.messageService.setActiveNav("End of contract");
    this.router.navigate(['/eoc-options/landing']);
  }
}
