<div class="eoc-landing" >
    <div class="container-column">
        <div class="section-title-container">
            <h1 class="section-label-title" test_id="pageName">Update your personal details</h1>
        </div>
        <div>
            <p>Thanks. We just need to check few details before we can check your application</p>
            <p>We'll ask you before we go ahead with your credit back.</p>
            <div class="card p-3">
                <h2>Your current address</h2>
                <p>The home address we have in our record is:</p>
                <p>24 Park Crest <br/>Bath <br/>Somerset <br/> BA3 2AX</p>
                <p>
                    <strong>Is this your current home address?</strong>
                </p>
                <div>
                    <input type="radio" name="isCurrentAddress" id="currentAddressYes" value="yes">
                    <label class="align-bottom ml-1" for="plateYes">Yes</label>
                </div>
                <div class="border-bottom pb-3">
                    <input type="radio" name="isCurrentAddress" id="currentAddressNo" value="no">
                    <label class="align-bottom ml-1"for="plateYes">No</label>
                </div>
                <h2 class="mt-3">Your employement</h2>
                <p>We need your last 3 years of employment history</p>
                <p>
                    <strong>Are you currently employed?</strong>
                </p>
                <div>
                    <input type="radio" name="isEmployed" id="employedYes" value="yes">
                    <label class="align-bottom ml-1" for="employedYes">Yes</label>
                </div>
                <div class="pb-3">
                    <input type="radio" name="isEmployed" id="employedNo" value="no">
                    <label class="align-bottom ml-1"for="employedNo">No</label>
                </div>
            </div>
            <button class="wss-button" (click)="navigate('credit-search')">Continue</button>
            <button class="wss-button-secondary" (click)="navigateToHome()">Save & Exit</button>
        </div>
    </div>
</div>
