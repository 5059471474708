<div class="container-column" *ngIf="paymentsWidgetItem$ | async as paymentsWidgetItem">
    <div class="direct-debit-container">
        <div class="container-column">
            <div class="container-row" *ngIf="serviceError">
                <bb-wss-banner-message></bb-wss-banner-message>
            </div>
            <div class="container-row" *ngIf="isBannerActive">
                <bb-wss-banner-message></bb-wss-banner-message>
            </div>

            <div class="container-row section-title-container">
                <span class="section-title" test_id="directDebitResultPageName">
                    {{paymentsWidgetItem.directDebitResult.directDebitResultPageName}}
                </span>
            </div>
            <div class="container-column active-debit-card detail-update-success">
                <div class="container-column" aria-describedby="directDebitChangeUpdateSuccess">
                    <span class="wss-success-icon success-image" alt="Success Icon"></span>
                    <span class="card-info text-center" test_id="directDebitResultUpdatedText">
                        {{paymentsWidgetItem.directDebitResult.directDebitResultUpdatedText}}
                    </span>
                    <span class="text-center para-inline" *ngIf="!checkIfWith8DaysCalenderBased()"
                        test_id="messagePaymentMoreThan7DaysText">
                        <bb-content-html
                            [html]="paymentsWidgetItem.directDebitResult.messageBeforeNewPaymentDueDayText">
                        </bb-content-html>
                        {{directDebitResponse?.new_payment_due_day}}
                        <bb-content-html
                            [html]="paymentsWidgetItem.directDebitResult.messageBetweenNewAndNextPaymentDueText">
                        </bb-content-html>
                        {{directDebitResponse?.next_payment_due_date}}
                    </span>
                    <span class="text-center" test_id="confirmEmailShortlyMessageText">
                        {{paymentsWidgetItem.directDebitResult.confirmEmailShortlyMessageText}}
                    </span>
                    <span role="navigation" (click)="onSmsClick()" test_id="requestSMSConfirmationMessageText">
                        <a tabindex="0"
                            title="{{paymentsWidgetItem.directDebitResult.requestSMSConfirmationMessageText}}">
                            {{paymentsWidgetItem.directDebitResult.requestSMSConfirmationMessageText}}</a>
                    </span>
                </div>
            </div>
            <div class="parent-container">
                <div class="next-payment-container" aria-describedby="nextPaymentInfo">
                    <div class="container-column next-payment">
                        <bb-wss-next-payment
                            [nextPaymentContent]="paymentsWidgetItem.directDebitResult.nextPaymentContent"
                            [accountDetail]="accountDetail" [nextPaymentData]="nextPaymentData">
                        </bb-wss-next-payment>
                    </div>
                </div>
                <div class="container-row mt-15px">
                    <span test_id="directDebitFAQText" class="para-inline">
                        <bb-content-html [html]="paymentsWidgetItem.directDebitResult.directDebitFAQText">
                        </bb-content-html>
                    </span>
                </div>
                <div class="container-column guarantee-note-section" aria-describedby="guarantyInfo">
                    <div class="container-row image-container">
                        <span test_id="directDebitGuaranteeHeadingText">
                            {{paymentsWidgetItem.directDebitResult.directDebitGuaranteeHeadingText}}
                        </span>
                        <span></span>
                    </div>
                    <div class="container-column long-guarantee-note" test_id="directDebitGuaranteeBodyText">
                        <div class="container-row">
                            <span>
                                <bb-content-html
                                    [html]="paymentsWidgetItem.directDebitResult.directDebitGuaranteeBodyText">
                                </bb-content-html>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>