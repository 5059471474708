import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { TransactionWidgetComponent } from './transaction-widget.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { WSSCommonModule } from 'libs/common/common.module';
import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';

@NgModule({
  declarations: [TransactionWidgetComponent],
  imports: [
    CommonModule,
    BackbaseCoreModule.withConfig({
      classMap: { TransactionWidgetComponent }
    }),
    NgbModalModule,
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule
  ]
})
export class TransactionWidgetModule { }