import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from '../../../service/http.service';
import { CustomerToken, userToken, validOTP, OTPVerify, ForgotUsernameOTPVerify } from '../../../model/registrationToken';
import { messageOTP } from '../../../model/messageOtp';
import { catchError, map } from 'rxjs/operators';
import { MessageService } from '../../../service/message.service';
import { RegisterDatastoreService } from './register-datastore.service';
import { QuestionIIQ } from '../../../model/questionIIQ';

@Injectable({ providedIn: 'root' })
export class RegisterService {

  constructor(
    private httpService: HttpService,
    private messageService: MessageService,
    private datastoreService: RegisterDatastoreService) {
  }

  getAuthorisation(): Observable<any> {
    return this.httpService.getAuthorisation();
  }

  getCustomers(): Observable<CustomerToken[] | any> {
    return this.httpService.getCustomers()
      .pipe(
        map((data) => { this.datastoreService.setCustomerToken(data) })
      )
  }

  getCustomersData(dob: string, postcode: string): Observable<any> {
    return this.httpService.getCustomersData(dob, postcode)
      .pipe(
        map((data: any) => {
          this.datastoreService.setCustomerToken(data.customers);
          return data;
        }),
        catchError(error => {
          this.messageService.setMessage(error.error);
          return error;
        })
      )
  }

  getCustomersMultiple(): Observable<any> {
    return this.httpService.getCustomersMultiple()
      .pipe(
        map((data) => { this.datastoreService.setCustomerValid(data) })
      )
  }

  getCustomerDetails(): Observable<userToken | any> {
    return this.httpService.getCustomerDetails()
      .pipe(
        map((data) => { this.datastoreService.setUserdetails(data) }),
        catchError(error => {
          this.messageService.setMessage(error.error);
          return error;
        })
      );
  }

  getCustomerOTP(): Observable<messageOTP | any> {
    return this.httpService.getCustomerOTP()
      .pipe(
        map((data) => { this.datastoreService.setMessageOtp(data) })
      )
  }

  getForgotEmailOTP(): Observable<messageOTP | any> {
    return this.httpService.getForgotEmailOTP()
      .pipe(
        map((data) => { this.datastoreService.setMessageOtp(data) })
      )
  }

  getCheckOTP(): Observable<OTPVerify | any> {
    return this.httpService.getCheckOTP()
      .pipe(
        map((data) => { this.datastoreService.setVerifyOtp(data) })
      )
  }

  getCheckEmailOTP(): Observable<ForgotUsernameOTPVerify | any> {
    return this.httpService.getCheckEmailOTP()
      .pipe(
        map((data) => { this.datastoreService.setVerifyEmailOtp(data) })
      )
  }

  getCustomerConfirm(): Observable<any> {
    return this.httpService.getCustomerConfirm();
  }

  saveContactPreference(reqObj: any, isRegistrationFlow: boolean): Observable<any> {
    return this.httpService.saveContactPreference(reqObj, isRegistrationFlow);
  }

  UpdateUserDetails(updateRequestData: any): Observable<any> {
    return this.httpService.updateUserDetails(updateRequestData);
  }

  getDefaultConfiguration() {
    return this.httpService.getApplicationDefaultConfiguration();
  }

  getQuestionIIQ(): Observable<QuestionIIQ | any> {
    return this.httpService.getQuestionIIQ()
      .pipe(
        map((data) => { this.datastoreService.setQuestionIIQ(data) })
      )
  }

  getQuestionIIQVerify(): Observable<QuestionIIQ | any> {
    return this.httpService.getQuestionIIQVerify()
      .pipe(
        map((data) => { this.datastoreService.setQuestionIIQ(data) })
      )
  }
}