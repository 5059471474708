import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { WSSCommonModule } from '../../../common/common.module';
import {ContentModule} from '@backbase/content-ang';
import {ContentWidgetModule} from '@backbase/universal-ang/content';
import { ModalModule, ButtonModule } from '@backbase/ui-ang';
import { SaveAndExitComponent } from '@wss/budget-cover-widgets/components/save-and-exit-modal/save-and-exit-modal.component';


@NgModule({
  declarations: [SaveAndExitComponent],
  exports:[SaveAndExitComponent],
  imports: [
    CommonModule,
    ContentModule,
    ContentWidgetModule,
    BackbaseCoreModule.withConfig({
      classMap: { SaveAndExitComponent }
    }),
    WSSCommonModule,
    ModalModule,
    ButtonModule
  ]
})
export class SaveAndExitComponentModule { }
