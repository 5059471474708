<div class="payment-holiday-container" *ngIf="paymentHolidayWidgetItem$ | async as paymentHolidayWidgetItem">
    <div class="container-column mt-30px term-ext-result" test_id="pageTopSuccessTxt">
        <div class="container-column">
            <span class="success_icon good-news"></span>
        </div>
        <bb-content-html class="text-center mt-13px" [html]="paymentHolidayWidgetItem.termExtensionResult.pageTopSuccessTxt"></bb-content-html>
        <div class="container-column white-container-rounded mt-37px paragraph">
            <div class="container-column inner" test_id="nextStepsSectionTitle">
                <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionResult.nextStepsSectionTitle"></bb-content-html>
                <div class="container-column mt-27px" test_id="tooltipLinkText">
                    <div class="container-row" test_id="nextStepsNote1">
                        <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionResult.nextStepsNote1"></bb-content-html>
                    </div>
                    <br>
                    <div class="container-row d-inline para-inline" test_id="nextStepsNote2Text">
                        <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionResult.nextStepsNote2TextBeforeReturnBydate"></bb-content-html>
                        {{docReturnDate}}. 
                        <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionResult.nextStepsNote2TextAFterReturnBydate"></bb-content-html>
                    </div>
                    <br>
                    <div class="container-row" test_id="nextStepsNote3">
                        <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionResult.nextStepsNote3"></bb-content-html>
                    </div>
                    <br>
                    <a 
                    [ngbPopover]="popCreditAct" 
                    autoClose="outside" 
                    triggers="manual" 
                    #creditAct="ngbPopover"
                    (click)="openPopover(creditAct, $event)" 
                    popoverClass="popover-credit-act"
                    title="info-icon" 
                    class="info-icon-top"
                    title="{{paymentHolidayWidgetItem.termExtensionResult.tooltipLinkText}}" tabindex="0">{{paymentHolidayWidgetItem.termExtensionResult.tooltipLinkText}}</a> 
                </div>
            </div>
        </div>
        <br><br>
        <div class="responsive-row-term">
            <div class="container-column" test_id="whatWeWillSendText">
                <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionResult.whatWeWillSendText"></bb-content-html>
            </div>
            <div class="container-column" test_id="whatYouNeedToDoText">
                <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionResult.whatYouNeedToDoTitleText"></bb-content-html>
                <div class="mt-18px para-inline" test_id="whatYouNeedToDoTextBeforeReturnbydate">
                    <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionResult.whatYouNeedToDoTextBeforeReturnbydate"></bb-content-html> {{docReturnDate}}.
                </div>
                <br>
                <span><bb-content-html [html]="paymentHolidayWidgetItem.termExtensionResult.whatYouNeedToDoTextAfterReturnbydate"></bb-content-html></span>
                <div class="brand-contact-details">
                    <span><bb-content-html [html]="paymentHolidayWidgetItem.termExtensionResult.whatYouNeedToDoTextAfterReturnbydatePartOne"></bb-content-html></span>
                    <span *ngIf="paymentHolidayWidgetItem.brandContactDetails"><bb-content-html [html]="paymentHolidayWidgetItem.brandContactDetails.brandWiseCfaNumber"></bb-content-html></span>
                    <span><bb-content-html [html]="paymentHolidayWidgetItem.termExtensionResult.whatYouNeedToDoTextAfterReturnbydatePartTwo"></bb-content-html></span>
                    <span *ngIf="paymentHolidayWidgetItem.termExtensionResult.whatYouNeedToDoTextAfterReturnbydatePartThree"><bb-content-html [html]="paymentHolidayWidgetItem.termExtensionResult.whatYouNeedToDoTextAfterReturnbydatePartThree"></bb-content-html></span>
                </div>
            </div>
        </div>

        <br>
        <div class="container-row back-to-myacount mb-100px">
            <button role="button" tabindex="0" class="wss-button" (click)="backToDashboard()" test_id="backToHomeButtonText">{{paymentHolidayWidgetItem.termExtensionResult.backToHomeButtonText}}</button>
        </div>

    </div>
    <ng-template #popCreditAct>
        <span class="tooltip-arrow-up"></span>
        <div class="container-row modal-body pover-over-class">
            <div class="content" test_id="tooltipContent">
                <bb-content-html [html]="paymentHolidayWidgetItem.termExtensionResult.tooltipContent"></bb-content-html>
            </div>
            <div class="tooplip-close">
                <span (click)="closePopover()" class="material-icons clear-icon" [attr.style]="tooltipCloseStyle">clear</span>
            </div>
        </div>
    </ng-template>
</div>

<!-- 
    <div class="payment-holiday-container">
    <div class="container-column mt-30px term-ext-result">
        <div class="container-column">
            <span class="good-news"></span>
        </div>
        <span class="text-semibold text-center mt-13px">Good news. We're pleased to offer you a Term Extension.</span>
        <div class="container-column white-container-rounded mt-37px">
            <div class="container-column inner">
                <span class="text-semibold text-bigger">Next steps</span>
                <div class="container-column mt-27px">
                    <div class="container-row">
                        <span>1.</span>
                        <div class="next-steps-item-right"><span class="text-semibold">We'll email your agreement
                                documents to you*</span>. This email will take up to 1 working day to arrive.</div>
                    </div>
                    <br>
                    <div class="container-row">
                        <span>2.</span>
                        <div class="next-steps-item-right"><span class="text-semibold">You'll need to read, sign and
                                send your agreement to us by {{docReturnDate}}</span>. This is so your modified agreement will
                            start on your next payment date. If you do not send your agreement back to us by this date
                            it may not be processed in time before your next monthly repayment is due.</div>
                    </div>
                    <br>
                    <div class="container-row">
                        <span>3.</span>
                        <div class="next-steps-item-right"><span class="text-semibold">Your modified agreement will
                                start on your next payment due date.</span></div>
                    </div>
                    <br>
                    <a title="Agreements not regulated by the Consumer Credit Act" tabindex="0">Agreements not regulated
                        by the Consumer Credit Act</a>
                </div>
            </div>
        </div>
        <br><br>
        <div class="responsive-row-term">
            <div class="container-column">
                <span class="text-semibold text-bigger">What we'll send you</span>
                <span class="mt-19px">You'll be sent these documents by email:</span>
                <br>
                <div class="container-row">
                    <span class="text-semibold">1.</span>
                    <div class="container-column next-steps-item-right">
                        <span class="text-semibold">Pre-Contract Credit Information</span>
                        <span>This document sets out the key financial details of your agreement. You should read and
                            consider this before you sign the credit agreement.</span>
                    </div>
                </div>
                <br>
                <div class="container-row">
                    <span class="text-semibold">2.</span>
                    <div class="container-column next-steps-item-right">
                        <span class="text-semibold">Pre Contract Explanation</span>
                        <span>This document sets out the key features of the agreement. You should read and consider
                            this before you sign the credit agreement.</span>
                    </div>
                </div>
                <br>
                <div class="container-row">
                    <span class="text-semibold">3.</span>
                    <div class="container-column next-steps-item-right">
                        <span class="text-semibold">Credit agreement</span>
                        <span>You'll need to sign and email back the document marked Finance Copy. Make sure you sign in
                            the space allocated.</span>
                    </div>
                </div>

                <p class="mt-21px"><span class="text-semibold">This email will take up to 1 working day to
                        arrive.</span></p>
                <p>You'll also receive a paper copy of the documents by post. You do not need to sign and return these
                    to us as we will use the signed copy that you send to us by email. The documents are only being sent
                    to you by post so that you have a copy for your records.</p>
            </div>
            <div class="container-column">
                <span class="text-semibold text-bigger">What you need to do</span>
                <span class="text-semibold mt-18px">You'll need to read, sign and send your agreement to us by
                    {{docReturnDate}}.</span>
                <br>
                <span>This is so your new agreement will start on your next payment date. If you do not send your
                    agreement back to us by this date it may not be processed in time before your next monthly repayment
                    is due.</span>
                <br>
                <span>How to email your signed agreement back to us:</span>
                <br>
                <ul role="list" class="print-doc-steps">
                    <li role="listitem">Print the document, sign it and email us a scanned copy or clear photograph of
                        the signed agreement.</li>
                    <br>
                    <li role="listitem">Use a document editing application to add your signature to the document and
                        email it back to us.</li>
                </ul>
                <br>
                <span><span class="text-semibold">You should only sign the documents if you are certain that you can
                        afford the new monthly payments. If this isn’t the case</span>, please call us on <a
                        title="0800 000 000" tabindex="0">0800 000 000</a> to discuss other options. To give you the
                    best support when you call us, we advise you to fill in our online <a title="Budgeting Tool"
                        tabindex="0">Budgeting Tool</a> before calling.</span>
            </div>
        </div>

        <br>
        <div class="container-row back-to-myacount mb-100px">
            <button role="button" tabindex="0" class="wss-button" (click)="backToDashboard()">Back to Home</button>
        </div>

    </div>
</div>
 -->