<section id="br-getdetails-employment" *ngIf="balloonRefinanceGetdetailsEmploymentItem$ | async as brGetdetailsEmploymentItem">
    <div role="alert" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <wss-page-title [pageTitle]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.page3Title" [pageName]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.step3Title">
    </wss-page-title>
    <section class="content-width-max"> 
        <div class="mt-25px-mb-30px" test_id="breTextAfterPageTitle"> 
            <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.textAfterPageTitle"></bb-content-html>
        </div>        
            <div class="card mb-10px p-20px">
                <div class="card-content text-left mb-n5px">                
                    <div test_id="breSectionAccountTitle" class="section-sub-title">               
                        {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.sectionEmploymentTitle}}
                    </div>   
                </div>
                <form [formGroup]="employmentDetailsForm">
                <div class="w-100">
                    <wss-infor-tool 
                        [idExpand]="idExpandArr[0]"    
                        [idCollapse]="idCollapseArr[0]"   
                        [idInforPanel]="idPanelArr[0]"                
                        [lblContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.lblCurrentEmployment"
                        [lblInforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.inforText"
                        [inforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.breInforCurrentEmployment">                
                    </wss-infor-tool>
                    <div class="mt-20px">
                        <div class="d-flex" *ngFor="let item of employmentStatusData">
                            <input type="radio" class="mr-2" name="employmentStatus" [id]="item.id" [value]="item.value"  formControlName="employmentStatus" (change)="handleChange(item.value)" aria-label="Employment status">
                            <label [for]="item" class="text-regular">{{item.title}}</label>
                        </div>
                    </div>
                    <div class="mt-5px text-danger" test_id="breCurrentEmploymentError" *ngIf="submitted && employmentDetailsForm?.controls?.employmentStatus?.status === 'INVALID'">                    
                        <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.currentEmploymentError"></bb-content-html>
                    </div>
                </div>
                 <div *ngIf="employmentDetailsForm?.controls?.employmentStatus?.value === 'false' || employmentDetailsForm?.controls?.employmentStatus?.value === false">
                    <div class="w-100">
                        <wss-infor-tool 
                            [idExpand]="idExpandArr[1]"    
                            [idCollapse]="idCollapseArr[1]"   
                            [idInforPanel]="idPanelArr[1]"                
                            [lblContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.lblSituationType"
                            [lblInforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.inforText"
                            [inforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.breInforSituation">                
                        </wss-infor-tool>
                        <div class="mt-20px">
                            <div class="d-flex" *ngFor="let item of employmentSituationData">
                                <input type="radio" class="mr-2" name="employmentSituation" [id]="item.id" [value]="item.value" formControlName="employmentSituation" aria-label="Employment situation">
                                <label [for]="item" class="text-regular">{{item.title}}</label>
                            </div>
                        </div>
                        <div class="mt-5px text-danger" test_id="breSitutaionTypeError" *ngIf="submitted && employmentDetailsForm?.controls?.employmentSituation?.status === 'INVALID'">                    
                            <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.situtaionTypeError"></bb-content-html>
                        </div>
                    </div>
                </div>
                <div *ngIf="employmentDetailsForm?.controls?.employmentStatus?.value === 'true' || employmentDetailsForm?.controls?.employmentStatus?.value === true">
                    <div class="w-100" >
                        <wss-infor-tool 
                            [idExpand]="idExpandArr[2]"    
                            [idCollapse]="idCollapseArr[2]"   
                            [idInforPanel]="idPanelArr[2]"                
                            [lblContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.lblEmploymentType"
                            [lblInforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.inforText"
                            [inforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.breInforType">                
                        </wss-infor-tool>
                        <div class="mt-20px">
                            <div class="d-flex" *ngFor="let item of employmentTypeData">
                                <input type="radio" class="mr-2" name="employmentType" [id]="item.id" [value]="item.value" formControlName="employmentType" aria-label="Employment type">
                                <label [for]="item" class="text-regular">{{item.title}}</label>
                            </div>
                        </div>
                        <div class="mt-5px text-danger" test_id="breEmploymentTypeError" *ngIf="submitted && employmentDetailsForm?.controls?.employmentType?.status === 'INVALID'">                    
                            <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.employmentTypeError"></bb-content-html>
                        </div>
                    </div>
                    <div class="w-100">
                        <wss-infor-tool 
                            [idExpand]="idExpandArr[3]"    
                            [idCollapse]="idCollapseArr[3]"   
                            [idInforPanel]="idPanelArr[3]"                
                            [lblContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.lblEmploymentTenure"
                            [lblInforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.inforText"
                            [inforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.breInforTenure">                
                        </wss-infor-tool>
                        <div class="mt-20px">
                            <div class="d-flex" *ngFor="let item of employmentTenureData">
                                <input type="radio" class="mr-2" name="employmentTenure" [id]="item.id" [value]="item.value" formControlName="employmentTenure" aria-label="Employment tenure">
                                <label [for]="item" class="text-regular">{{item.title}}</label>
                            </div>
                        </div>
                        <div class="mt-5px text-danger" test_id="breCurrentEmploymentError" *ngIf="submitted && employmentDetailsForm?.controls?.employmentTenure?.status === 'INVALID'">                    
                            <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.employmentTenureError"></bb-content-html>
                        </div>
                    </div>
                    <div class="w-100">
                        <wss-infor-tool 
                            [idExpand]="idExpandArr[4]"    
                            [idCollapse]="idCollapseArr[4]"   
                            [idInforPanel]="idPanelArr[4]"                
                            [lblContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.lblEmploymentSector"
                            [lblInforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.inforText"
                            [inforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.breInforSector">                
                        </wss-infor-tool>
                        <div class="mt-20px">
                            <div class="d-flex" *ngFor="let item of employmentSectorData">
                                <input type="radio" class="mr-2" name="employmentSector" [id]="item.id" [value]="item.value" formControlName="employmentSector" aria-label="Employment sector">
                                <label [for]="item" class="text-regular">{{item.title}}</label>
                            </div>
                        </div>
                        <div class="mt-5px text-danger" test_id="breCurrentEmploymentError" *ngIf="submitted && employmentDetailsForm?.controls?.employmentSector?.status === 'INVALID'">                    
                            <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.employmentSectorError"></bb-content-html>
                        </div>
                    </div>
                    <div class="w-100 br-btm-grey pb-20px">
                        <wss-infor-tool 
                            [idExpand]="idExpandArr[5]"    
                            [idCollapse]="idCollapseArr[5]"   
                            [idInforPanel]="idPanelArr[5]"                
                            [lblContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.lblOccupation"
                            [lblInforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.inforText"
                            [inforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.breInforOccupation">                
                        </wss-infor-tool>
                        <input type="text" class="form-control w-sm-50 mt-20px" maxlength="20" id="occupationInput" name="occupation" formControlName="occupation" maxlength="20"
                        placeholder="{{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.occupationPlaceholderText}}" aria-label="Occupation">
                        <div class="mt-5px text-danger" test_id="breOccupationError" *ngIf="submitted && employmentDetailsForm?.controls?.occupation?.status === 'INVALID' && employmentDetailsForm?.controls?.occupation?.value === ''">
                            <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.occupationError"></bb-content-html>
                        </div>
                        <div class="mt-5px text-danger" test_id="occupationInvalidCharsError" *ngIf="employmentDetailsForm?.controls?.occupation?.errors?.pattern">
                            <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.alphabeticError"></bb-content-html>
                        </div>
                    </div>
                    <div test_id="employerDetailsHeader" class="section-sub-title mt-20px">
                        {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.employerDetailsHeader}}
                    </div>
                    <div>
                        <wss-infor-tool 
                            [idExpand]="idExpandArr[6]"    
                            [idCollapse]="idCollapseArr[6]"   
                            [idInforPanel]="idPanelArr[6]"                
                            [lblContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.employerName"
                            [lblInforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.inforText"
                            [inforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.brvInfoEmployerName">                
                        </wss-infor-tool>
                        <input type="text" class="form-control w-sm-50 mt-20px" id="currentEmployer" formControlName="currentEmployer" maxlength="20"
                        placeholder="{{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.employerNamePlaceholderText}}" aria-label="Current employer">
                        <div class="mt-5px text-danger" test_id="employerNameError" *ngIf="submitted && employmentDetailsForm?.controls?.currentEmployer?.status === 'INVALID' && employmentDetailsForm?.controls?.currentEmployer?.value === ''">
                            <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.employerNameError"></bb-content-html>
                        </div>
                        <div class="mt-5px text-danger" test_id="employerNameInvalidCharsError" *ngIf="employmentDetailsForm?.controls?.currentEmployer?.errors?.pattern">
                            <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.employerNameInvalidCharsError"></bb-content-html>
                        </div>
                    </div>
                    <div>
                        <wss-infor-tool 
                            [idExpand]="idExpandArr[7]"    
                            [idCollapse]="idCollapseArr[7]"   
                            [idInforPanel]="idPanelArr[7]"                
                            [lblContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.employmentStartQuestion"
                            [lblInforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.inforText"
                            [inforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.brvInfoEmploymentStartDate">                
                        </wss-infor-tool>
                        <div class="d-flex mt-12px">
                            <div>
                                <label class="content-text-bold" for="emplymentStartMonth">
                                    {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.month}}
                                </label>
                                <select required id="emplymentStartMonth" placeholder="Month" formControlName="employmentStartMonth" class="form-control month-input select-color-change" (change)="getValidDate()" aria-label="Employment start month">
                                    <option disabled selected hidden value="">Month</option>
                                        <option *ngFor="let month of monthsData" [value]="month.value">{{month.title}}</option>                                        
                                </select>
                                <div class="mt-5px text-danger" test_id="employmentStartMonthErrorMessage" *ngIf="submitted && employmentDetailsForm?.controls?.employmentStartMonth?.status === 'INVALID'">
                                    <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsAbout.moveLiveMonthError">
                                    </bb-content-html>
                                </div> 
                            </div>
                            <div>
                                <label class="content-text-bold" for="emplymentStartYear">
                                    {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.year}}
                                </label>
                                <select id="emplymentStartYear" formControlName="employmentStartYear" class="form-control w-100 year-select" (change)="getValidDate()" aria-label="Employment start year">
                                    <option disabled selected hidden value="">Year</option>
                                    <option *ngFor="let year of yearsList" [value]="year">{{year}}</option>
                                </select>
                                 <div class="mt-5px text-danger" test_id="employmentStartMonthErrorMessage" *ngIf="submitted && employmentDetailsForm?.controls?.employmentStartYear?.status === 'INVALID'">
                                    <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsAbout.moveLiveYearError">
                                    </bb-content-html>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5px text-danger" test_id="employmentStartDateErrorMessage" *ngIf="showDateErrorMessage">                    
                            <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.employmentStartDateErrorMessage"></bb-content-html>
                        </div>
                        <div class="mt-5px text-danger" test_id="empDateDOBError" *ngIf="showDateDOBErrMessage">
                            <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.compareDOBDateErr">
                            </bb-content-html>                        
                        </div>
                    </div>
                    <div>
                        <wss-infor-tool 
                            [idExpand]="idExpandArr[8]"
                            [idCollapse]="idCollapseArr[8]"
                            [idInforPanel]="idPanelArr[8]"
                            [lblContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.employerAddressQuestion"
                            [lblInforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.inforText"
                            [inforContent]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.breInfoEmployerAddress">                
                        </wss-infor-tool>
                        <div *ngIf="!showFullAddress" class="mt-12px">
                            <div class="form-group">
                                <label for="buildingName" test_id="buildingName">
                                    {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.buildingName}}
                                </label>
                                <input type="text" class="form-control" formControlName="house_name_search" id="buildingName" maxlength="20"
                                placeholder="{{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.buildingNamePlaceHolderText}}" aria-label="House number">
                                <div class="mt-5px text-danger" test_id="buildingNameErrorMessage" *ngIf="submitted && employmentDetailsForm?.controls?.house_name_search?.status === 'INVALID'">                    
                                    <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.buildingNameErrorMessage"></bb-content-html>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="postCode" test_id="postCode">
                                    {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.postCode}}
                                </label>
                                <input type="text" class="form-control wss-form-input-normal" formControlName="post_code_search" id="postCode"
                                placeholder="{{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.postCodePlaceholderText}}" aria-label="Postcode">
                                <div class="mt-5px text-danger" test_id="postCodeErrorMessage" *ngIf="submitted && employmentDetailsForm?.controls?.post_code_search?.status === 'INVALID'">                    
                                    <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.postCodeErrorMessage"></bb-content-html>
                                </div>
                            </div>
                            <button class="wss-button mb-0" test_id="searchButtonText" (click)="searchAddress()">
                                {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.searchButtonText}}
                            </button>
                        </div>
                    </div>
                    
                    <div class="mt-5px text-danger" *ngIf="showSearchAddressError && !showFullAddress" test_id="searchAddressErrorMessage">
                        <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.searchAddressErrorMessage"></bb-content-html>
                    </div>
                    <div class="mt-5px text-danger" *ngIf="!isAddressSearched && !employmentDetailsForm?.controls?.post_code_search?.pristine &&
                    !employmentDetailsForm?.controls?.house_name_search?.pristine && submitted && !showSearchAddressError" test_id="notSearchedAddressErrorMessage">
                        <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsAbout.searchAddressButtonError"></bb-content-html>
                    </div>
                    <a  class="d-block text-semibold mt-20px" test_id="enterFullAddress" (click)="toggleFullAddress()" *ngIf="!showFullAddress">
                        {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.enterFullAddress}}
                    </a>
                    <div class="mt-25px col-md-5 address-block p-8px" *ngIf="showAddress && !showFullAddress">
                        <wss-address-display 
                            [addressContent]="prePopAddres" 
                            [displayClass]="classLiveAddress">
                        </wss-address-display>
                    </div>
                    <div *ngIf="showFullAddress">
                        <div class="text-semibold mb-15px mt-15px">
                            <span test_id="youCouldAlsoText">
                                {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.youCouldAlsoText}}
                            </span>
                            <a (click)="toggleFullAddress()" test_id="searchByPostcode">
                                {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.searchByPostcode}}
                            </a>
                        </div>
                        
                        <div class="form-group w-100">
                            <div>
                                <label for="buildingName" test_id="buildingName">
                                    {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.buildingName}}
                                </label>
                                <input type="text" class="form-control wss-form-input-normal" formControlName="house_name" id="buildingName" maxlength="20"
                                placeholder="{{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.buildingNamePlaceHolderText}}" aria-label="Building name or number">
                            </div>
                            <div class="mt-5px text-danger" test_id="buildingNameErrorMessage" 
                            *ngIf="submitted && employmentDetailsForm?.controls?.house_name?.status === 'INVALID' && employmentDetailsForm?.controls?.house_name?.value === ''">       
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.buildingNameErrorMessage"></bb-content-html>
                            </div>
                            <div class="mt-5px text-danger" test_id="buildingNameInvalidCharsMessage" *ngIf="employmentDetailsForm?.controls?.house_name?.errors?.pattern">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.alphanumericHypenApostropheForwardSlashError"></bb-content-html>
                            </div>
                        </div>
                        <div class="form-group w-100">
                            <label for="houseNumber" test_id="houseNumberLabel">
                                {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.houseNumber}}
                            </label>
                            <input type="text" class="form-control wss-form-input-normal" formControlName="house_number" id="houseNumber" maxlength="5"
                            placeholder="{{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.houseNumberPlaceholderText}}" aria-label="House number">
                            <div class="mt-5px text-danger" test_id="houseNumberErrorMessage" 
                            *ngIf="submitted && employmentDetailsForm?.controls?.house_number?.status === 'INVALID' && employmentDetailsForm?.controls?.house_number?.value === ''">     
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.houseNumberErrorMessage"></bb-content-html>
                            </div>
                            <div class="mt-5px text-danger" test_id="houseNumberInvalidCharsMessage" *ngIf="employmentDetailsForm?.controls?.house_number?.errors?.pattern">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.alphanumericHypenForwardSlashError"></bb-content-html>
                            </div>
                            <div class="mt-5px text-danger" test_id="noHouseOrNumberErrorMessage" *ngIf="submitted && !houseAddressValid && (!employmentDetailsForm?.controls?.house_number?.value && !employmentDetailsForm?.controls?.house_name?.value)">
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.noHouseNameOrHouseNumberErrorMessage"></bb-content-html>
                            </div>
                        </div>
                        <div class="form-group w-100">
                            <label for="flatNumber" test_id="flatNumber">
                                {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.flatNumber}}
                            </label>
                            <input type="text" class="form-control wss-form-input-normal" formControlName="flat_number" id="flatNumber" maxlength="16"
                            placeholder="{{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.flatNumberPlaceholderText}}" aria-label="Flat number">
                            <div class="mt-5px text-danger" test_id="flatNumberErrorMessage" 
                            *ngIf="submitted && employmentDetailsForm?.controls?.flat_number?.status === 'INVALID' && employmentDetailsForm?.controls?.flat_number?.value === ''">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.flatNumberErrorMessage"></bb-content-html>
                            </div>
                            <div class="mt-5px text-danger" test_id="flatNumberInvalidCharsMessage" *ngIf="employmentDetailsForm?.controls?.flat_number?.errors?.pattern">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.alphanumericAndHypenError"></bb-content-html>
                            </div>
                        </div>
                        <div class="form-group w-100">
                            <label for="street">
                                {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.street}}
                            </label>
                            <input type="text" class="form-control wss-form-input-normal" formControlName="street_name" id="street" maxlength="40"
                            placeholder="{{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.streetPlaceholderText}}" aria-label="Street name">
                            <div class="mt-5px text-danger" test_id="employmenTownErrorMessage" 
                            *ngIf="submitted && employmentDetailsForm?.controls?.street_name?.status === 'INVALID' && !employmentDetailsForm?.controls?.street_name?.errors?.pattern">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.streetErrorMessage"></bb-content-html>
                            </div>
                            <div class="mt-5px text-danger" test_id="streetNameInvalidCharsErrorMessage" *ngIf="employmentDetailsForm?.controls?.street_name?.errors?.pattern">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.alphabeticHypenApostropheError"></bb-content-html>
                            </div>
                        </div>
                        <div class="form-group w-100">
                            <label for="district">
                                {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.district}}
                            </label>
                            <input type="text" class="form-control wss-form-input-normal" formControlName="district" id="district" maxlength="40"
                            placeholder="{{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.districtPlaceholderText}}" aria-label="District">
                            <div class="mt-5px text-danger" test_id="employmenTownErrorMessage" 
                            *ngIf="submitted && employmentDetailsForm?.controls?.district?.status === 'INVALID' && employmentDetailsForm?.controls?.district?.value === ''">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.districtErrorMessage"></bb-content-html>
                            </div>
                            <div class="mt-5px text-danger" test_id="districtInvalidCharsErrorMessage" *ngIf="employmentDetailsForm?.controls?.district?.errors?.pattern">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.alphabeticHypenApostropheError"></bb-content-html>
                            </div>
                        </div>
                        <div class="form-group w-100">
                            <label for="town">
                                {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.town}}
                            </label>
                            <input type="text" class="form-control wss-form-input-normal" formControlName="post_town" id="town" maxlength="30"
                            placeholder="{{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.townPlaceholderText}}" aria-label="Town/City name">
                            <div class="mt-5px text-danger" test_id="employmenTownErrorMessage" 
                            *ngIf="submitted && employmentDetailsForm?.controls?.post_town?.status === 'INVALID' && employmentDetailsForm?.controls?.post_town?.value === ''">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.townErrorMessage"></bb-content-html>
                            </div>
                            <div class="mt-5px text-danger" test_id="postTownInvalidCharsErrorMessage" *ngIf="employmentDetailsForm?.controls?.post_town?.errors?.pattern">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.alphabeticHypenApostropheError"></bb-content-html>
                            </div>
                        </div>
                        <div class="form-group w-100">
                            <label for="employerName">
                                {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.county}}
                            </label>
                            <input type="text" class="form-control wss-form-input-normal" formControlName="county" id="county" maxlength="16"
                            placeholder="{{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.countyPlaceholderText}}" aria-label="County">
                            <div class="mt-5px text-danger" test_id="employmenCountyErrorMessage" 
                            *ngIf="submitted && employmentDetailsForm?.controls?.county?.status === 'INVALID' && employmentDetailsForm?.controls?.county?.value === ''">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.countyErrorMessage"></bb-content-html>
                            </div>
                            <div class="mt-5px text-danger" test_id="countyInvalidCharsErrorMessage" *ngIf="employmentDetailsForm?.controls?.county?.errors?.pattern">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.alphabeticHypenError"></bb-content-html>
                            </div>
                        </div>
                        <div class="form-group w-100">
                            <div>
                                <label for="postCode" test_id="postCode">
                                    {{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.postCode}}
                                </label>
                                <input type="text" class="form-control wss-form-input-small" formControlName="post_code" id="postCode" minlength="6" maxlength="8"
                                placeholder="{{brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.postCodePlaceholderText}}" aria-label="Postcode">
                            </div>
                            <div class="mt-5px text-danger" test_id="postCodeErrorMessage" 
                            *ngIf="submitted && employmentDetailsForm?.controls?.post_code?.status === 'INVALID' && employmentDetailsForm?.controls?.post_code?.errors?.pattern?.requiredPattern !== '^[a-zA-Z0-9 ]+$'">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsEmployment.postCodeErrorMessage"></bb-content-html>
                            </div>
                            <div class="mt-5px text-danger" test_id="postCodeInvalidCharsErrorMessage" 
                            *ngIf="employmentDetailsForm?.controls?.post_code?.errors?.pattern?.requiredPattern === '^[a-zA-Z0-9 ]+$'">                    
                                <bb-content-html [html]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsTitle.alphanumericError"></bb-content-html>
                            </div>
                        </div>
                    </div>
                </div>   
                </form>         
            </div>
        <div class="mb-40px">
            <wss-common-nav-buttons
                [content]="brGetdetailsEmploymentItem.balloonRefinanceGetdetailsNavBtns"
                [accountsDetails]="accountDetails"
                [previousPageLink]="previousPageLink"
                [lastDateOfDecision]="lastDateOfDecision"
                [testIdText]="commonNavTestID"
                (primaryButtonClicked)="onSaveButtonClick($event)"
                (continueButtonClicked)="onContinue($event)">
            </wss-common-nav-buttons>
        </div>
    </section>
</section>