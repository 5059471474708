<div class="container-column contract-container">
<div class="section-sub-title">
    <span class="para-inline" test_id="contract_label_id">
        <bb-content-html [html]="myAgreementContent.sectionTitle"></bb-content-html>
    </span>
    <!-- <span test_id="contract_label_id">{{content?.agreement}}</span> -->
</div>
<div class="container-column margin-top-17">
    <div class="container-row contract-item">
        <span class="calendar-view-day" title="Payments made"></span>
        <div class="container-column margin-left-20">
            <span class="para-inline" test_id="payments_left_label_id">
                <bb-content-html [html]="myAgreementContent.paymentLeft"></bb-content-html>
            </span>
            <!-- <span test_id="payments_left_label_id" class="text-semibold">{{content?.left}}</span> -->
            <span test_id="payments_left_value_id">{{contractData?.paymentLeft}}</span>
        </div>
    </div>
    <div class="container-row contract-item original-term-margin">
        <span  class="material-icons" title="Contract length">autorenew</span>
        <div class="container-column margin-left-20">
            <!-- <span test_id="original_term_label_id" class="text-semibold">{{content?.term}}</span> -->
            <span class="para-inline" test_id="original_term_label_id">
                <bb-content-html [html]="myAgreementContent.currentLength"></bb-content-html>
            </span>
            <div class="container-column contract-details">
                <!-- <span class="container-row current-length" test_id="original_term_value_id">{{contractData?.originalTerm}} {{content?.mnths}}</span> -->
                <span class="container-row current-length" test_id="original_term_value_id">{{contractData?.originalTerm}} <span class="para-inline"><bb-content-html [html]="myAgreementContent.monthsText"></bb-content-html></span></span> 
                <svg 
                    (click)="openModal(modalContent)"
                    class="info-icon current-length"
                    xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                    <path [attr.fill]="iconColor" [attr.d]="svgInfoIcon" />
                </svg>
                <!-- <span (click)="openModal(modalContent)"  class="wss-payment-info-icon"></span> -->
            </div>
            
        </div>
    </div>
    
</div>

</div>
<!-- <ng-template #popContent>
    <span class="tooltip-arrow-up" [attr.style]="popoverArrowStyle"></span>
    <div class="container-row modal-body pover-over-class">
        <div class="content">{{content?.ofpToolipTitle}}</div>
        <div class="tooplip-close">
            <span (click)="closePopover()" class="material-icons" [attr.style]="tooltipCloseStyle">clear</span>
        </div>
    </div>
</ng-template> -->
<ng-template #modalContent let-modal>
    <div class="modal-body">
        <div class="container-row">
            <div class="container-column" test_id="tooltipContentContract">
                <bb-content-html [html]="myAgreementContent.tooltipContent"></bb-content-html>
                <!-- <span>{{content?.couldmalcTextPart1}}</span>
                <span>{{content?.couldmalcTextPart2}} <a (click)="getInTouchLink()" class="faq-link">{{content?.getInTouch}}</a></span> -->
            </div>
            <span (click)="modal.dismiss()" class="material-icons clear-icon">clear</span>
        </div>
      
    </div>
  </ng-template>