import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { HeaderWidgetComponent } from './header-widget.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BackbaseUiAngExamplesModule, ButtonModule, HeaderModule, InputValidationMessageModule } from '@backbase/ui-ang';
import { HttpClientModule } from '@angular/common/http';
// import { CommonHeaderWidgetComponent } from '../../common/components/common-header/common-header.component';
import { InputDatepickerModule } from '@backbase/ui-ang';
import { WSSCommonModule } from '@wss/common/common.module';


@NgModule({
  declarations: [HeaderWidgetComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    BackbaseUiAngExamplesModule,
    ButtonModule, 
    HeaderModule,
    InputValidationMessageModule,
    InputDatepickerModule,
    HttpClientModule,
    BackbaseCoreModule.withConfig({
      classMap: { HeaderWidgetComponent }
    }),
    WSSCommonModule
  ]
})
export class HeaderWidgetModule { }