import {Actions, Effect, ofType} from "@backbase/foundation-ang/store";
import {Observable, of} from "rxjs";
import {ActionTypes, SearchUserAction, SearchUserSuccessAction} from "./actions";
import {switchMap} from "rxjs/operators";
import {Injectable} from "@angular/core";

@Injectable()
export class UserRegistrationEffects {

  constructor() {}

  @Effect()
  searchUsers(actions$: Actions): Observable<SearchUserSuccessAction> {
    return actions$.pipe(
      ofType(ActionTypes.SearchUser),
      switchMap<SearchUserAction, Observable<SearchUserSuccessAction>>(
        value => of(new SearchUserSuccessAction([]))
      )
    );
  }
}
