import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
// import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { RouterService } from "@backbase/foundation-ang/core";
import { ContentData } from '../../../../config/wss-app-constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserDetailService } from '../../services/update-detail.services';
import { MessageService } from '../../../../service/message.service';
import { ServiceException } from '../../../../model/service-exception';
import { Observable } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { headerContentWidgetItem, UpdateAddressItem } from '@wss/model/content/update-contact-details';
import { getErrorImageClass } from '@wss/common/util/util';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-wss-user-contact',
  templateUrl: './user-contact.component.html',
  providers: [ContentService]
})
export class UserContactComponent implements OnInit {
  serviceException: ServiceException = new ServiceException();
  contactForm: FormGroup = new FormGroup({});
  errorMsg: string = '';
  successStatus: boolean = false;
  showForm: boolean = true;
  customerDetail: any;
  personalDetails: any = { fullname: "", shortName: "" };
  brandName: string = window.BB?.config?.portalName;

  headerContentWidgetItem$: Observable<headerContentWidgetItem | undefined> = this.bbContentService.getContent<headerContentWidgetItem>('headerContentWidgetItem');

  headerContentWidgetItem: any;
  constructor(
    private formBuilder: FormBuilder,
    private UserDetaillSrvc: UserDetailService,
    public changeDetectorRef: ChangeDetectorRef,
    private router: RouterService,
    private messageService: MessageService,
    private bbContentService: ContentService,
    private dataStore: DatastoreService,
    private tealiumService: TealiumUtagService
  ) { }

  ngOnInit() {
    this.tealiumService.view(
      {
        JourneyName: 'Header',
        JourneyStepName: 'Update Contact',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.customerDetail = this.dataStore.getCustomerDetail();
    this.personalDetails.fullname = this.customerDetail?.individual?.first_name + " " + this.customerDetail?.individual?.surname;
    this.personalDetails.fullname = this.personalDetails.fullname.toLowerCase();
    this.personalDetails.shortName = this.customerDetail?.individual?.first_name[0] + this.customerDetail?.individual?.surname[0];

    this.contactForm = this.formBuilder.group({
      mobile_phone_number: ['', Validators.required],
      home_phone_number: ['', Validators.required],
      work_phone_number: ['', Validators.required],
    });

    this.navigationChange();
    this.prePopulate();

    this.headerContentWidgetItem$.subscribe(res => {
      this.headerContentWidgetItem = res;
    });
    this.changeDetectorRef.detectChanges();
  }


  prePopulate() {
    this.customerDetail = this.dataStore.getCustomerDetail();
    if (this.customerDetail?.contact_details?.home_phone_number) {
      this.contactForm.controls['home_phone_number'].setValue(this.customerDetail.contact_details.home_phone_number);
    }
    if (this.customerDetail?.contact_details?.mobile_phone_number) {
      this.contactForm.controls['mobile_phone_number'].setValue(this.customerDetail.contact_details.mobile_phone_number);
    }
    if (this.customerDetail?.contact_details?.work_phone_number) {
      this.contactForm.controls['work_phone_number'].setValue(this.customerDetail.contact_details.work_phone_number);
    }
  }
  onSubmit() {
    this.errorMsg = '';
    let contactDetails: any = {};
    if (this.contactForm.get('home_phone_number')?.value !== this.customerDetail?.contact_details.home_phone_number ) {
      contactDetails['home_phone_number'] = this.contactForm.get('home_phone_number')?.value;
    }
    if (this.contactForm.get('mobile_phone_number')?.value !== this.customerDetail?.contact_details.mobile_phone_number ) {
      contactDetails['mobile_phone_number'] = this.contactForm.get('mobile_phone_number')?.value;
    }
    if (this.contactForm.get('work_phone_number')?.value !== this.customerDetail?.contact_details.work_phone_number ) {
      contactDetails['work_phone_number'] = this.contactForm.get('work_phone_number')?.value;
    }
    this.UserDetaillSrvc.UpdateContact(contactDetails).subscribe(
      (r) => {
        this.UserDetaillSrvc.getUserDetails().subscribe(res => {
          this.dataStore.setCustomerDetail(res);
          this.prePopulate();
        })
        this.showForm = false;
        this.successStatus = true;
        this.contactForm.reset({});
        this.changeDetectorRef.markForCheck();
      },
      (error: any) => {
        if (error.error[0]?.code == 12004) {
          this.showWith24hrs();
        } else {
          this.showfailueError();
        }
        if (error.error[0] == undefined) {
          this.showfailueError();
        }
        this.showForm = false;
        this.successStatus = false;
        this.changeDetectorRef.markForCheck();
      },
    );
  }

  backToMyAccountClick() {
    location.href = '#/myaccount';
  }
  navigationChange() {
    this.messageService.getNotify().subscribe(res => {
      this.contactForm.reset({});
      this.successStatus = false;
      this.showForm = true;
      this.prePopulate();
    });
  }

  showWith24hrs() {
    this.serviceException.imageClass = getErrorImageClass(this.headerContentWidgetItem?.updateContacts.withIn24HrsException.errorImageType.toString());
    this.serviceException.errorInfoText = this.headerContentWidgetItem?.updateContacts.withIn24HrsException.errorInfoText;
    this.serviceException.buttonText = this.headerContentWidgetItem?.updateContacts.withIn24HrsException.buttonText;
    this.serviceException.componentUri = this.headerContentWidgetItem?.updateContacts.withIn24HrsException.componentUri;
    this.serviceException.cancelLink = this.headerContentWidgetItem?.updateContacts.withIn24HrsException.cancelLink;
    this.messageService.setServiceException(this.serviceException);
    this.router.navigate(['/payments/service-exception']);
  }
  showfailueError() {
    this.serviceException.imageClass = getErrorImageClass(this.headerContentWidgetItem?.updateContacts.updateFailureException.errorImageType.toString());
    this.serviceException.errorInfoText = this.headerContentWidgetItem?.updateContacts.updateFailureException.errorInfoText;
    this.serviceException.buttonText = this.headerContentWidgetItem?.updateContacts.updateFailureException.buttonText;
    this.serviceException.componentUri = this.headerContentWidgetItem?.updateContacts.updateFailureException.componentUri;
    this.serviceException.cancelLink = this.headerContentWidgetItem?.updateContacts.updateFailureException.cancelLink;
    this.messageService.setServiceException(this.serviceException);
    this.router.navigate(['/payments/service-exception']);
  }

}