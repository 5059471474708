import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { LoginWidgetComponent } from './login-widget.component';
import { UserLoginComponent } from "./components/user-login/user-login.component";
import { UserSuccessfulComponent } from "./components/user-successful/user-successful-component";
import { AttempsExceededComponent } from "./components/attemps-exceeded/attemps-exceeded.component";
import { AccountLockedComponent } from "./components/security-fail/account-locked.component";
import { PasswordResetComponent } from "./components/password-reset/password-reset.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component"
import { CheckEmailComponent } from "./components/check-email/check-email.component"
import { PasswordSuccessfulComponent } from "./components/password-successful/password-successful-component"
import { LinkExpiredComponent } from "./components/link-expired/link-expired.component"
import { InputPasswordModule } from '@backbase/ui-ang';
import { MatIconModule } from '@angular/material/icon';
import { MultipleAccountsComponent } from './components/multiple-accounts/multiple-accounts.component';
import { NoUserComponent } from './components/no-user/no-user.component';
import { WSSCommonModule } from '../../common/common.module';
import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';
import { ChangeEmailMaxAttemptsComponent } from './components/change-email-max-attempts/change-email-max-attempts.component';
import { PasswordChangeSuccessComponent } from './components/password-change-success/password-change-success-component';


import {
  BackbaseUiAngExamplesModule,
  ButtonModule, HeaderModule,
  InputEmailModule,
  InputNumberModule,
  InputTextModule,
  InputValidationMessageModule,
  StepperModule,
} from "@backbase/ui-ang";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// tslint:disable-next-line:import-blacklist
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { LoginFaqsComponent } from './components/login-faqs/login-faqs.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { ForgotUsernameComponent } from './components/forgot-username/forgot-username.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { VerifySmsComponent } from './components/verify-sms/verify-sms.component';
import { UsernameSuccessfulComponent } from './components/username-successful/username-successful.component';
import { RecoverLoginDetailsComponent } from './components/recover-login-details/recover-login-details.component';
import { NoUserFoundComponent } from './components/no-user-found/no-user-found.component';
import { InputDatepickerModule } from '@backbase/ui-ang';
import { PasscodeErrorComponent } from './components/passcode-error/passcode-error.component';
import { UnableIdentifyCustomerComponent } from './components/unable-identify-customer/unable-identify-customer.component';

@NgModule({
  declarations: [
    LoginWidgetComponent,
    UserLoginComponent,
    PasswordResetComponent,
    ForgotPasswordComponent,
    UserSuccessfulComponent,
    AttempsExceededComponent,
    MultipleAccountsComponent,
    AccountLockedComponent,
    CheckEmailComponent,
    PasswordSuccessfulComponent,
    LinkExpiredComponent,
    NoUserComponent,
    LoginFaqsComponent,
    ForgotUsernameComponent,
    VerifyOtpComponent,
    VerifySmsComponent,
    UsernameSuccessfulComponent,
    RecoverLoginDetailsComponent,
    NoUserFoundComponent,
    PasscodeErrorComponent,
    UnableIdentifyCustomerComponent,
    ChangeEmailMaxAttemptsComponent,
    PasswordChangeSuccessComponent
  ],
  providers: [
  ],
  imports: [
    MatIconModule,
    CommonModule,
    StepperModule,
    InputNumberModule,
    InputValidationMessageModule,
    FormsModule,
    InputNumberModule,
    HttpClientModule,
    InputValidationMessageModule,
    InputEmailModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    InputPasswordModule,
    InputDatepickerModule,
    BackbaseCoreModule.withConfig({
      classMap: { LoginWidgetComponent }
    }),
    RouterModule.forRoot([], { initialNavigation: 'disabled', useHash: true, relativeLinkResolution: 'legacy' }),
    HeaderModule,
    RouterModule,
    MatDialogModule,
    MatExpansionModule,
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule
  ]
})
export class LoginWidgetModule { }
