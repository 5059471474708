import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { appConfig } from '../../../../config/wss-app-constants';
import { DatastoreService } from '../../../../service/datastore.service';
import { isErrServer, getCurrentMonthlyPayment } from '../../../../common/util/util';
import { MessageService } from '../../../../service/message.service';
import { Observable } from 'rxjs';
import { PaymentHolidayWidgetItem } from '../../../../model/payment-holiday-widget/payment-holiday-widget';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'current-agreement',
    templateUrl: './current-agreement.component.html',
    providers: [ ContentService ]
})

export class CurrentAgreementComponent implements OnInit {
    typeHP: number = appConfig.hpCustomer;
    typePCP: number = appConfig.pcpCustomer;
    accountDetail: any = {};
    currentMonthlyPmt: any = 0;
    serviceError: boolean = false;
    paymentHolidayWidgetItem$: Observable<PaymentHolidayWidgetItem | undefined> = this.bbContentService.getContent<PaymentHolidayWidgetItem>('paymentHolidayWidgetItem');

    constructor(
        private datastore: DatastoreService,
        private changeDetectorRef: ChangeDetectorRef,
        private messageService: MessageService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService) { }

    ngOnInit() {
        this.accountDetail = this.datastore.getAccountDetails();
        this.accountDetail.scheme_code = parseInt(this.accountDetail.scheme_code);
        this.currentMonthlyPmt = getCurrentMonthlyPayment(this.accountDetail);
        this.messageService.setCurrentStep(2);
        this.tealiumService.view(
            {
                JourneyName: 'Payment holiday',
                JourneyStepName: 'Current Agreement',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
    }

    showError(errStatus: number): void {
        if(isErrServer(errStatus)){
          this.datastore.setErrorServer();
          this.serviceError = true;
          this.changeDetectorRef.detectChanges();
        }
    }
}