import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MyAgreement } from '../../model/account-summary';;
import { DatastoreService } from '@wss/service/datastore.service';

@Component({
  selector: 'bb-wss-my-agreement',
  templateUrl: 'my-agreement.component.html'
})
export class MyAgreementComponent implements OnInit {
  @Input("greetingText") greetingText: string = '';
  @Input() hpSchemeDescriptionText: string = '';
  @Input() leasePurchaseText: string = '';  

  @Input() myAgreementData: MyAgreement = new MyAgreement();
  @Input() customerDetail: any;
  @Input() isPcpToHpBalloonRefinanceFlag: any;
  @Input() isLPAgreementFlag: any;

  constructor(
    private datastoreService: DatastoreService,
    private changeDetectorRef: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.customerDetail = this.datastoreService.getCustomerDetail()  
    if (this.customerDetail && this.customerDetail.individual && this.customerDetail.individual.first_name) {
      this.customerDetail.individual.first_name = this.customerDetail.individual.first_name.toLowerCase();
    }
    this.changeDetectorRef.detectChanges();
  }

}