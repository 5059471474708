import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { LoginWidgetItem } from '@wss/model/login-widget/login-widget';
import { RegisterService } from '@wss/registration-widget/src/service/register.service';
import { RequestService } from '@wss/service/request.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bb-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss'],
  providers: [ ContentService ]
})
export class VerifyOtpComponent implements OnInit {

  otp: number | undefined;
  otpInput!: FormGroup;
  disabled = true;
  otp1 = false;
  otp2 = false;
  otp3 = false;
  otp4 = false;
  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');

  readonly destroy$ = new Subject();

  constructor(public formBuilder: FormBuilder,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    private registerService: RegisterService,
    private requestService: RequestService,
    private readonly bbContentService: ContentService) { }

  ngOnInit(): void {
    this.otpInput = this.formBuilder.group({
      otp1: ['', Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(9),
      ])],
      otp2: ['', Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(9),
      ])],
      otp3: ['', Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(9),
      ])],
      otp4: ['', Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(9),
      ])],
    });
    this.handleOtpInput();
  }

  checkIfAllNumbersEnterd() {
    if(this.otp1 && this.otp2 && this.otp3 && this.otp4){
      this.disabled = false;
    } else {
      this.disabled = true;
    }
    this.changeDetectorRef.detectChanges();
  }

  handleOtpInput() {
    const otp1 = this.otpInput.get('otp1');
    otp1?.valueChanges.forEach(
      (value: number) => {
        if(value < 10 && value != null){
          this.otp1 = true;
        } else {
          this.otp1 = false;
        }
        this.checkIfAllNumbersEnterd();
      }
    );
    const otp2 = this.otpInput.get('otp2');
    otp2?.valueChanges.forEach(
      (value: number) => {
        if(value < 10 && value != null){
          this.otp2 = true;
        } else {
          this.otp2 = false;
        }
        this.checkIfAllNumbersEnterd();
      }
    );

    const otp3 = this.otpInput.get('otp3');
    otp3?.valueChanges.forEach(
      (value: number) => {
        if(value < 10 && value != null){
          this.otp3 = true;
        } else {
          this.otp3 = false;
        }
        this.checkIfAllNumbersEnterd();
      }
    );

    const otp4 = this.otpInput.get('otp4');
    otp4?.valueChanges.forEach(
      (value: number) => {
        if(value < 10 && value != null){

          this.otp4 = true;
        } else {
          this.otp4 = false;
        }
        this.checkIfAllNumbersEnterd();
      }
    );
  }

  checkLength(value:any) : boolean{
    if(value.length === 1) {
      return false;
    } else {
      return true;
    }    
  }

  onInput(event: any){
    let element;
    if(event.code != 'Backspace') {
      element = event.srcElement.nextElementSibling
    } else if(event.code == 'Backspace') {
      element = event.srcElement.previousElementSibling;
    }

    return element != null ? element.focus() : null; 
  }

  submit() {
    let otp = ''+ this.otpInput.get('otp1')?.value + 
                  this.otpInput.get('otp2')?.value + 
                  this.otpInput.get('otp3')?.value + 
                  this.otpInput.get('otp4')?.value;
    this.requestService.setReqVerifyOTP(otp);    
    this.registerService.getCheckEmailOTP().pipe(takeUntil(this.destroy$)).subscribe(() => {     
      this.router.navigate(['username-successful']);
    },      
    (error:any) => {
      this.router.navigate(['passcode-error']);
    });
  }

  resendCode() {
    this.router.navigate(['verify-sms']);
  }

}
