import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { BudgetEOMWidgetComponent } from './budget-eom-widget.component';
import { WSSCommonModule } from '../../../common/common.module';
import {ContentModule} from '@backbase/content-ang';
import {ContentWidgetModule} from '@backbase/universal-ang/content';
import { ModalModule, ButtonModule } from '@backbase/ui-ang';
import { ReactiveFormsModule } from '@angular/forms';
import { SaveAndExitComponentModule } from '@wss/budget-cover-widgets/components/save-and-exit-modal/save-and-exit-modal.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { SummaryComponent } from './summary/summary.component';

@NgModule({
  declarations: [BudgetEOMWidgetComponent, SummaryComponent],
  imports: [
    CommonModule,
    ContentModule,
    ContentWidgetModule,
    BackbaseCoreModule.withConfig({
      classMap: { BudgetEOMWidgetComponent }
    }),
    WSSCommonModule,
    ModalModule,
    ButtonModule,
    SaveAndExitComponentModule,
    ReactiveFormsModule,
    MatExpansionModule
  ]
})
export class BudgetEOMWidgetModule { }