<div *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <div class="login-container" #loginFpDom>
    <span class="section-label-size" test_id="which_account_label_id">
      {{loginWidgetItem.forgotuserPassword.titleText}}
    </span>
    <hr />
    <div class="reg-row" role="alert" *ngIf="serviceError">
      <bb-wss-banner-message [errorCode]="errorCode"></bb-wss-banner-message>
    </div>
    <p class="title-section-page" #txtHeader test_id="loginWidgetItemforgotuserPasswordContent">
      <bb-content-html [html]="loginWidgetItem.forgotuserPassword.forgotPass"></bb-content-html>
    </p>
    <p #txtMnContent>
      <bb-content-html [html]="loginWidgetItem.forgotuserPassword.dntWorryText"></bb-content-html>
    </p>
    <form [formGroup]="loginForm" class="form" role="form" autocomplete="off" id="formLogin" novalidate="" method="POST"
      (ngSubmit)="onSubmit()">
      <div class="form-group login-input-field" test_id="loginWidgetItememailAaddressContent">
        <label for="uname1">
          <span class="child-para-inline">
            <bb-content-html [html]="loginWidgetItem.forgotuserPassword.emailAaddress"></bb-content-html>
          </span>
        </label>
        <input type="text" class="form-control form-control-lg rounded-2" formControlName="email" required=""
          placeHolder="eg. name@domain.co.uk" [ngClass]="{'is-invalid':submitted && f.email.errors}">
        <div class="invalid-feedback" *ngIf="submitted && f.email.errors!.required">
          {{loginWidgetItem.forgotuserPassword.emailRequired}}
        </div>
        <div class="invalid-feedback" *ngIf="submitted && f.email.errors!.email">
          {{loginWidgetItem.forgotuserPassword.invalidEmail}}
        </div>
      </div>
      <div #txtAddlContent test_id="loginWidgetlinkExpireContent">
        <bb-content-html [html]="loginWidgetItem.forgotuserPassword.linkExpire"></bb-content-html>
      </div>
      <div id="forgot-password-btn" class="margin-top-between-rows login-page-button text-center"
        test_id="loginWidgetBtnContent">
        <button id="bb-button-to" bbButton type="submit" aria-label="Favourite" [className]="'wss-button'"
          class="button-width-padding">
          {{loginWidgetItem.forgotuserPassword.mailBtnText}}
        </button>
      </div>
    </form>
  </div>
  <div class="register-link text-center no-para-margin" test_id="loginWidgetHelplinkContent" id="needHelpLinkContent">
    <bb-content-html [html]="loginWidgetItem.forgotuserPassword.needHelpLink"></bb-content-html>
  </div>
</div>