<!-- *ngIf="digitalInboxWidgetItem$ | async as digitalInboxWidgetItem" -->
<div class="mail-preference-container">
    <div class="container-row" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="container-column">
        <div class="container-row section-title-container">
            <span class="section-title" test_id="pageName">{{digitalInboxWidgetItem.accountSelection.pageName}}</span>
        </div>
        <div class="container-row mt-16px">
            <bb-content-html [html]="digitalInboxWidgetItem.accountSelection.pageTopText"></bb-content-html>
        </div>
        <!-- single agreement start -->
        <div class="multiple-accounts-container mb-10px">
            <div class="container-row multiple-accounts-card section-card-border-color">
                <div class="column-blue section-icon-background-color"></div>
                <div class="car-flex-width section-icon-color">
                    <span class="wss-car-icon"></span>
                </div>
                <div class="container-column agreement-text-column">
                    <div class="container-column">
                            <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.accountSelection.agreementNumberText"></bb-content-html>
                        <span test_id="loginWidgetAccountNumberContent" data-cs-mask>
                            {{currentAccountObj.account_number}}
                            <!-- 8742578986549 -->
                        </span>
                    </div>
                    <div class="container-column" test_id="loginWidgetregistrationNo">
                            <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.accountSelection.vehicleAndRegistrationText"></bb-content-html>
                        <span test_id="vehicle_n_registration_value_id" data-cs-mask>
                            {{currentAccountObj.goods_description}} {{currentAccountObj.registration_no}}
                            <!-- PEUGEOT All-new 2008 SUV - CF03 XCX -->
                        </span>
                    </div>
                </div>
                <div class="arrow-column">
                    <span test_id="right_arrow_id" class="arrow-pull-left"></span>
                </div>
            </div>
        </div>
        
        <!-- multi agreement start -->
        <div class="container-column mt-22px mb-10px" *ngIf="otherAccounts?.length">
            <div class="container-row">
                <bb-content-html [html]="digitalInboxWidgetItem.accountSelection.otherAgreementsText"></bb-content-html>
            </div>
            <div class="multiple-accounts-container multi-agreement">
                <div class="container-row multiple-accounts-card section-card-border-color" *ngFor="let account of otherAccounts">
                    <div class="column-blue section-icon-background-color"></div>
                    <div class="car-flex-width section-icon-color">
                        <span class="wss-car-icon"></span>
                    </div>
                    <div class="container-column agreement-text-column">
                        <div class="container-column">
                                <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.accountSelection.agreementNumberText"></bb-content-html>
                            <span test_id="loginWidgetAccountNumberContent" data-cs-mask>
                                {{account.account_number}}
                            </span>
                        </div>
                        <div class="container-column" test_id="loginWidgetregistrationNo">
                                <bb-content-html class="para-inline" [html]="digitalInboxWidgetItem.accountSelection.vehicleAndRegistrationText"></bb-content-html>
                            <span test_id="vehicle_n_registration_value_id" data-cs-mask>
                                {{account.goods_description}} {{account.registration_no}}
                            </span>
                        </div>
                    </div>
                    <div class="arrow-column">
                        <!-- <span test_id="right_arrow_id" class="arrow-pull-left"></span> -->
                        <div (click)="account.checked = !account.checked"
                        [ngClass]="account?.checked? 'custom-checkbox-debit': 'custom-checkbox-debit-unchecked'">
                        <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="container-column desktop-btn-links">
            <div class="container-row desktop-button-center">
                <button (click)="updatePreference()" class="wss-button" tabindex="0" role="button">{{digitalInboxWidgetItem.accountSelection.buttonText}}</button>
            </div>
            <div class="flex-horizontal-center text-center">
                <bb-content-html [html]="digitalInboxWidgetItem.accountSelection.pageBottomLink"></bb-content-html>
            </div>
        </div>
    </div>
</div>