import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContentService } from '@backbase/content-ang';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bb-voluntary-termination',
  templateUrl: './voluntary-termination.component.html'
})

export class VoluntaryTermination implements OnInit {
  endOfContractWidgetItem: EndOfContractItem = new EndOfContractItem();
  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');
  modalRef: any;
  
  constructor(
    private bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    public changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'Voluntary Termination',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.getContentData();
  }

  getContentData() {
    this.endOfContractWidgetItem$.subscribe((pageItem: any) => {
      this.endOfContractWidgetItem = pageItem;
      this.changeDetectorRef.detectChanges();
    }) 
  }

  openModal(content: any) {
    this.modalRef =  this.modalService.open(content);
   }
}