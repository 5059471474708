import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ContentService } from '@backbase/content-ang';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';
import { appConfig } from '@wss/config/wss-app-constants';


@Component({
  selector: 'bb-eoc-traffic-light',
  templateUrl: './eoc-traffic-light.component.html'
})

export class EocTrafficLight implements OnInit {
  endOfContractWidgetItem: EndOfContractItem = new EndOfContractItem();
  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');
  accountDetails: any = {};  
  trafficLightColour: string = 'default';
  currentMileageInputValue: any;
  displayMileageCalculator: boolean = false;

  // Mileage calc line coords
  xPoint1: number = 150;
  yPoint1: number = 150;
  xPoint2: number = 0;
  yPoint2: number = 0;

  // Mileage calc values for animation calculation
  radius: number = 100;
  degrees: number = 185;
  animationInterval: any;
  targetDegrees: number = 185;

  constructor(
    private datastoreService: DatastoreService,
    private bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'EOC traffic light',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.accountDetails = this.datastoreService.getAccountDetails();
    this.checkDisplayMileageCalculator()
    if(this.displayMileageCalculator){
      this.getContentData();
      this.updatePoints();
    }
  }

  getContentData() {
    this.endOfContractWidgetItem$.subscribe((pageItem: any) => {
      this.endOfContractWidgetItem = pageItem;
      this.changeDetectorRef.detectChanges();
    }) 
  }

  checkDisplayMileageCalculator() {
    if ((this.accountDetails.scheme_code === appConfig.pcpCustomer || this.accountDetails.scheme_code === appConfig.pcpCustomer.toString()) &&
      this.accountDetails.pcp_ind &&
      this.accountDetails.max_total_mileage &&
      (this.accountDetails?.delivery_mileage || this.accountDetails?.delivery_mileage === 0) &&
      (this.accountDetails?.num_payments_left || this.accountDetails?.num_payments_left === 0) &&
      this.accountDetails?.original_term) {
      this.displayMileageCalculator = true;
    }
  }

  calcFutureMilesButtonPress() {
    // this is to check for null values before running calculations.
    if (this.currentMileageInputValue) {
      this.calcCurrentMileage();
    } else {
      this.trafficLightColour = 'default';
      this.selectTargetDegrees()
    }
  }
 
  calcCurrentMileage(){
    if (this.currentMileageInputValue > this.accountDetails?.delivery_mileage) {
      let currentMonthOfTerm = this.accountDetails?.original_term - this.accountDetails?.num_payments_left;
      let proRataMiles = this.accountDetails?.max_total_mileage / this.accountDetails?.original_term * currentMonthOfTerm;
      let ninetyPercentOfProRata = proRataMiles * 0.9;
      let milesDone = this.currentMileageInputValue - this.accountDetails?.delivery_mileage;
      if (milesDone >= ninetyPercentOfProRata && milesDone < proRataMiles) {
        this.trafficLightColour = 'amber';
      } else if (milesDone < ninetyPercentOfProRata) {
        this.trafficLightColour = 'green';
      } else {
        this.trafficLightColour = 'red';
      }
    } else {
      this.trafficLightColour = 'default'
    }
    this.selectTargetDegrees()
    this.changeDetectorRef.detectChanges()
  }
  
  selectTargetDegrees() {
    if (this.trafficLightColour == 'default') {
      this.targetDegrees = 185

    } else if (this.trafficLightColour == 'red') {
      this.targetDegrees = 340

    } else if (this.trafficLightColour == 'amber') {
      this.targetDegrees = 270

    } else if (this.trafficLightColour == 'green') {
      this.targetDegrees = 200
    }
    this.animateToTarget();
  }

  animateToTarget(): void {
    if (this.animationInterval) {
      clearInterval(this.animationInterval); // Clear previous animation
    }
    this.animationInterval = setInterval(() => {
      // Check if we need to keep animating
      if (Math.abs(this.degrees - this.targetDegrees) < 1) {
        this.degrees = this.targetDegrees; // Snap to target when close enough
        clearInterval(this.animationInterval); // Stop the animation
      } else {
        // Move degrees gradually towards targetDegrees
        if (this.degrees < this.targetDegrees) {
          this.degrees += 1; // Increase degrees
        } else {
          this.degrees -= 1; // Decrease degrees
        }
        this.updatePoints(); // Update the points on the line
      }
    }, 10); // Adjust the time interval for smoothness (lower = smoother)
  }

  updatePoints(): void {
    this.xPoint2 = this.xPoint1 + this.radius * Math.cos(this.degrees * (Math.PI / 180));
    this.yPoint2 = this.yPoint1 + this.radius * Math.sin(this.degrees * (Math.PI / 180));
    this.changeDetectorRef.detectChanges();
  }

}
