<div class="eoc-ballon-refinary-container" *ngIf="endOfContractWidgetItem$ | async as endOfContractWidgetItem">
    <div class="container-column" *ngIf="endOfContractWidgetItem.endOfContractHp">
        <div class="container-row section-title-container">
            <span class="section-title"
                test_id="pageName">{{endOfContractWidgetItem.endOfContractHp.pageName}}</span>
        </div>
        <div class="container-column" test_id="pageContentText">
            <div class="section-sub-title font-size-20 margin-top-20">
                <bb-content-html [html]="endOfContractWidgetItem.endOfContractHp.subTitleText">
                </bb-content-html>
            </div>
            <div class="padding-top-10px">
                <span class="para-inline">
                    <bb-content-html
                        [html]="endOfContractWidgetItem.endOfContractHp.agreementEndContentBeforeDataText">
                    </bb-content-html>
                </span>
                <span>{{accountDetails?.final_payment_date}}. </span>
                <span class="flex-width additional-info-item-pull-right para-inline"
                    test_id="agreementEndContentAfterDataText">
                    <bb-content-html
                        [html]="endOfContractWidgetItem.endOfContractHp.agreementEndContentAfterDataText">
                    </bb-content-html>
                </span>
            </div>
            <div class="eoc-agreement-balloon-refinary-text margin-top-20">
                <bb-content-html
                    [html]="endOfContractWidgetItem.endOfContractHp.agreementEndStepsInfoText">
                </bb-content-html>
            </div>
            <div class="margin-top-10">
                <bb-content-html [html]="endOfContractWidgetItem.endOfContractHp.questionsInfoText">
                </bb-content-html>
            </div>
        </div>

        <div class="container-column card mb-40px">
            <div class="card-content text-center">
                <div class="section-sub-title font-size-20 margin-top-10">
                    <span test_id="sectionTitle">
                        <bb-content-html [html]="endOfContractWidgetItem.endOfContractHp.subSubTitleText">
                        </bb-content-html>
                    </span>
                </div>
                <div class="margin-top-10 mb-p-0 mb-5px">
                    <bb-content-html
                        [html]="endOfContractWidgetItem.endOfContractHp.settlementOptionInfoText">
                    </bb-content-html>
                </div>
                <!-- <div> -->
                    <button class="wss-button-secondary"
                        (click)="navigateToSettlement()">{{endOfContractWidgetItem.endOfContractHp.settlementQuoteButtonText}}</button>
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>