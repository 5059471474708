import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MessageService } from '@wss/service/message.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { catchError, map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { BudgetPlanner} from '../model/budget.model';
import { HttpService } from '@wss/service/http.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  portalName: any;

  constructor(private httpService: HttpService,
    private messageService: MessageService,
    private datastoreService: DatastoreService, private router: Router,
    private utilservice: UtilService,
    ) { }

  fetchbudgetList(): Observable<any> {
    return this.httpService.getBudgetList(this.getAccountNo)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(error => {
          this.messageService.setServiceError(error[0]);
          return error;
        })
      );
  }

  fetchBudgetDetailById(budgetId:number): Observable<any> {
    return this.httpService.getBudgetDetailById(this.getAccountNo , budgetId)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(error => {
          this.messageService.setServiceError(error[0]);
          return error;
        })
      );
  }

  postBudgetDetail(formData:BudgetPlanner, currentPage:string): Observable< any> {//currentPage:string
    let finalRequestObject = this.utilservice.createRequestObject(formData, currentPage)

    return this.httpService.saveBudgetDetail(this.getAccountNo, finalRequestObject).pipe(catchError(error => {
      this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
      return error;
    }));
  }



  submitBudgetForm(): Observable< any> {//currentPage:string
    let budgetFormId = this.datastoreService.getCurrentBudgetFormId()

    return this.httpService.submitBudgetForm(this.getAccountNo, budgetFormId).pipe(catchError(error => {
      this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
      return error;
    }));
  }

  sentMail(emailid: any){
    let mailObject = this.utilservice.createMailobj(emailid);

    mailObject.account_number = this.getAccountNo;

    return this.httpService.sendEmail(mailObject).pipe(catchError(error => {
      this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
      return error;
    }));
  }


  updateBudgetDetail(formData:BudgetPlanner, currentPage:string): Observable< any> {//currentPage:string
    let finalRequestObject = this.utilservice.createRequestObject(formData, currentPage)

    let budgetFormId = this.datastoreService.getCurrentBudgetFormId()

    return this.httpService.updateBudgetDetail(this.getAccountNo, budgetFormId, finalRequestObject).pipe(catchError(error => {
      this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
      return error;
    }));
  }

  get getAccountNo(){
    /// To Do : remove this hard code value this is just for Mock Test
    return this.datastoreService.getAccountNo() || '123456789'
  }

}
