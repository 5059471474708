import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ContentService } from '@backbase/universal-ang/content';
import { BudgetFormItem } from '@wss/model/budget-form-widget/budgetForm';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { saveAndExitService } from '@wss/budget-cover-widgets/components/save-and-exit-modal/save-and-exit.service';
import { UtilService } from '@wss/budget-cover-widgets/shared/service/util.service';
import { budgetForm, patternMap } from '@wss/config/wss-app-constants';
import { DatastoreService } from '@wss/service/datastore.service';
import { BudgetService } from '@wss/budget-cover-widgets/shared/service/budget.service';
import { BudgetPlanner } from '@wss/budget-cover-widgets/shared/model/budget.model';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-essential-living-cost-widget',
  templateUrl: './budget-essential-living-cost-widget.component.html',
  providers: [ContentService]
})
export class BudgetEssentialLivingCostComponent implements OnInit {
  modalRef: any;
  requestStatus: any;
  transactionService: any;
  isRequestedToday!: boolean;
  storeData: any;

  constructor(
    private fb: FormBuilder,
    private readonly bbContentService: ContentService,
    private route: ActivatedRoute,
    public utilService:UtilService,
    private datastoreService : DatastoreService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService : BudgetService,
    private modalService: saveAndExitService,
    private tealiumService: TealiumUtagService) {
  }


  essentialForm: any;
  submitted: boolean = false;
  serviceError:boolean = false;
  maximumInsuranceEntries: number = budgetForm.maximumAdditionInfoEntries;
  numberWithtwoDecimalRegExp: RegExp = new RegExp(patternMap.amountwithtwodecimalPattern);
  typeOfInsurance: string[] = budgetForm.typeOfInsurance
  frequencies: Array<string> =  budgetForm.frequencyList;
  maximumExpenseEntries: number =  budgetForm.maximumAdditionInfoEntries;

  budgetFormItem$: Observable<BudgetFormItem | undefined> = this.bbContentService.getContent<BudgetFormItem>('BudgetFormItem');

  ngOnInit(): void {
    this.getServiceData();
    this.initEssentialForm();
    this.tealiumService.view(
      {
        JourneyName: 'Budget Cover',
        JourneyStepName: 'Essential Living',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

  initEssentialForm() {
    this.essentialForm = this.fb.group({
      current_page:["4"],
      status:'Draft',
      isClothingAndliving: ['', [Validators.required]],
      isConcilTax: ['', [Validators.required]],
      isEnergyAndGas: ['', [Validators.required]],
      isGrocieries: ['', [Validators.required]],
      isInternet: ['', [Validators.required]],
      isMobilePhone: ['', [Validators.required]],
      isTVlicence: ['', [Validators.required]],
      isWater: ['', [Validators.required]],
      clothingAndlivingAdditionalInfo: this.addtioninfoGroup("Spending", "Clothing"),
      concilTaxAdditionalInfo: this.addtioninfoGroup("Spending", "Council tax"),
      energyAndGasAdditionalInfo: this.addtioninfoGroup("Spending", "Energy And Gas"),
      grocieriesAdditionalInfo: this.addtioninfoGroup("Spending", "Grocieries"),
      internetAdditionalInfo: this.addtioninfoGroup("Spending", "Internet"),
      mobilePhoneAdditionalInfo: this.addtioninfoGroup("Spending", "Mobile Phone"),
      TVlicenceAdditionalInfo: this.addtioninfoGroup("Spending", "TV Licence"),
      waterAdditionalInfo: this.addtioninfoGroup("Spending", "Water"),


      isDependent: ['', [Validators.required]],
      isHealthcare: ['', [Validators.required]],
      isLeisure: ['', [Validators.required]],
      isMotorRunningCost: ['', [Validators.required]],
      isTravel: ['', [Validators.required]],
      dependentAdditionalInfo: this.addtioninfoGroup("Spending", "Dependants"),
      healthcareAdditionalInfo: this.addtioninfoGroup("Spending", "Healthcare"),
      leisureAdditionalInfo: this.addtioninfoGroup("Spending", "Leisure"),
      motorRunningCostAdditionalInfo: this.addtioninfoGroup("Spending", "Motor Running Cost"),
      travelAdditionalInfo: this.addtioninfoGroup("Spending", "Travel"),


      isinsurance: ['', [Validators.required]],
      typeOfInsuranceDropdown: [null],
      typeOfInsuranceAmountInput: [''],
      addMultipleInsurance: this.fb.array([]),
      otherExpenses: ['', [Validators.required]],
      addMultipleExpense: this.fb.array([]),
    })


    this.changegroup('isClothingAndliving', 'clothingAndlivingAdditionalInfo', 'Clothing');
    this.changegroup('isConcilTax', 'concilTaxAdditionalInfo', 'Council tax');
    this.changegroup('isGrocieries', 'grocieriesAdditionalInfo', 'Grocieries');
    this.changegroup('isEnergyAndGas', 'energyAndGasAdditionalInfo', 'Energy And Gas');
    this.changegroup('isInternet', 'internetAdditionalInfo', 'Internet');
    this.changegroup('isMobilePhone', 'mobilePhoneAdditionalInfo', 'Mobile Phone');
    this.changegroup('isTVlicence', 'TVlicenceAdditionalInfo', 'TV Licence');
    this.changegroup('isWater', 'waterAdditionalInfo', 'Water');
    this.changegroup('isDependent', 'dependentAdditionalInfo', 'Dependants');
    this.changegroup('isHealthcare', 'healthcareAdditionalInfo', 'Healthcare');
    this.changegroup('isLeisure', 'leisureAdditionalInfo', 'Leisure');
    this.changegroup('isMotorRunningCost', 'motorRunningCostAdditionalInfo', 'Motor Running Cost');
    this.changegroup('isTravel', 'travelAdditionalInfo', 'Travel');
     this.updateRadio('isClothingAndliving', 'Spending', 'Clothing');
    this.updateRadio('isConcilTax', 'Spending', 'Council tax');
    this.updateRadio('isGrocieries', 'Spending', 'Grocieries');
    this.updateRadio('isEnergyAndGas', 'Spending', 'Energy And Gas');
    this.updateRadio('isInternet', 'Spending', 'Internet');
    this.updateRadio('isMobilePhone', 'Spending', 'Mobile Phone');
    this.updateRadio('isTVlicence', 'Spending', 'TV Licence');
    this.updateRadio('isWater', 'Spending', 'Water');
    this.updateRadio('isDependent', 'Spending', 'Dependants');
    this.updateRadio('isHealthcare', 'Spending', 'Healthcare');
    this.updateRadio('isLeisure', 'Spending', 'Leisure');
    this.updateRadio('isMotorRunningCost', 'Spending', 'Motor Running Cost');
    this.updateRadio('isTravel', 'Spending', 'Travel');
    this.updateRadio('isinsurance', 'Spending', 'Insurance');
    this.updateRadio('otherExpenses', 'Spending', 'Other');
    this.updateFormGroup('clothingAndlivingAdditionalInfo','Spending', 'Clothing');
    this.updateFormGroup('concilTaxAdditionalInfo', 'Spending', 'Council tax');
    this.updateFormGroup('grocieriesAdditionalInfo','Spending', 'Grocieries');
    this.updateFormGroup('energyAndGasAdditionalInfo','Spending', 'Energy And Gas');
    this.updateFormGroup('internetAdditionalInfo', 'Spending', 'Internet');
    this.updateFormGroup('mobilePhoneAdditionalInfo','Spending', 'Mobile Phone');
    this.updateFormGroup('TVlicenceAdditionalInfo', 'Spending', 'TV Licence');
    this.updateFormGroup('waterAdditionalInfo', 'Spending', 'Water');
    this.updateFormGroup('healthcareAdditionalInfo','Spending', 'Healthcare');
    this.updateFormGroup('dependentAdditionalInfo','Spending', 'Dependants');
    this.updateFormGroup('leisureAdditionalInfo','Spending', 'Leisure');
    this.updateFormGroup('motorRunningCostAdditionalInfo', 'Spending', 'Motor Running Cost');
    this.updateFormGroup('travelAdditionalInfo','Spending', 'Travel');
    this.addDataInsuranceArray('Spending', 'Insurance')
    this.addDataOtherExpenseArray('Spending', 'Other')

  }


  getServiceData() {
    this.storeData = this.datastoreService.getBudgetDetail();
    /**
     * If user will redirect this page manually then
     * we are checking that user have already gone through this page
     * earlier and save the data
     * If not then we are redirecting to the budget home page
     */
    if(!this.storeData.current_page || (this.storeData.current_page && this.storeData.current_page < 3)) {
      this.router.navigate(['help-centre/money-worries']);
    }
  }


  addDataInsuranceArray(type:string, category:string) {
    let pageObjects = this.utilService.selectMatchingInfo(type, category)
    if (pageObjects.length > 0 && this.storeData.current_page >= 4) {
      for (let i = 0; i < pageObjects.length; i++) {
        this.addOtherInsurance();
      }

      this.essentialForm.controls['addMultipleInsurance'].patchValue(pageObjects)
    }
  }

  addDataOtherExpenseArray(type:string, category:string) {
    let pageObjects = this.utilService.selectMatchingInfo(type, category)
    if (pageObjects.length > 0 && this.storeData.current_page >= 4) {
      for (let i = 0; i < pageObjects.length; i++) {
        this.addOtherExpense();
      }
      this.essentialForm.controls['addMultipleExpense'].patchValue(pageObjects)
    }
  }

  get isClothingAndliving() {
    if (this.essentialForm.contains('isClothingAndliving')) {
      return this.essentialForm.get('isClothingAndliving');
    }
  }

  get clothingAmount(){
    return this.essentialForm.get('clothingAndlivingAdditionalInfo.amount')
  }

  get clothingFrequency(){
    return this.essentialForm.get('clothingAndlivingAdditionalInfo.frequency')
  }


  get councilTax() {
    if (this.essentialForm.contains('isConcilTax')) {
      return this.essentialForm.get('isConcilTax');
    }
  }

  get councilTaxAmount() {
      return this.essentialForm.get('concilTaxAdditionalInfo.amount')
  }

  get councilTaxFrequency(){
    return this.essentialForm.get('concilTaxAdditionalInfo.frequency')
  }

  get isGrocieries() {
    if (this.essentialForm.contains('isGrocieries')) {
      return this.essentialForm.get('isGrocieries');
    }
  }
  get grocieriesAmount(){
    return this.essentialForm.get('grocieriesAdditionalInfo.amount')
  }

  get grocieriesFrequency(){
    return this.essentialForm.get('grocieriesAdditionalInfo.frequency')
  }

  get isEnergyAndGas() {
    if (this.essentialForm.contains('isEnergyAndGas')) {
      return this.essentialForm.get('isEnergyAndGas');
    }
  }

  get energyAndGasAmount(){
    return this.essentialForm.get('energyAndGasAdditionalInfo.amount')
  }

  get energyAndGasFrequency(){
    return this.essentialForm.get('energyAndGasAdditionalInfo.frequency')
  }

  get isInternet() {
    if (this.essentialForm.contains('isInternet')) {
      return this.essentialForm.get('isInternet');
    }
  }

  get internetAmount(){
    return this.essentialForm.get('internetAdditionalInfo.amount')
  }

  get internetFrequency(){
    return this.essentialForm.get('internetAdditionalInfo.frequency')
  }

  get isMobilePhone() {
    if (this.essentialForm.contains('isMobilePhone')) {
      return this.essentialForm.get('isMobilePhone');
    }
  }

  get mobileFrequency(){
    return this.essentialForm.get('mobilePhoneAdditionalInfo.frequency')
  }

  get mobileAmount(){
    return this.essentialForm.get('mobilePhoneAdditionalInfo.amount')
  }

  get isTVlicence() {
    if (this.essentialForm.contains('isTVlicence')) {
      return this.essentialForm.get('isTVlicence');
    }
  }

  get tvLicenceFrequency(){
    return this.essentialForm.get('TVlicenceAdditionalInfo.frequency')
  }

  get tvLicenceAmount(){
    return this.essentialForm.get('TVlicenceAdditionalInfo.amount')
  }

  get isWater() {
    if (this.essentialForm.contains('isWater')) {
      return this.essentialForm.get('isWater');
    }
  }

  get waterFrequency(){
    return this.essentialForm.get('waterAdditionalInfo.frequency')
  }

  get waterAmount(){
    return this.essentialForm.get('waterAdditionalInfo.amount')
  }

  get isDependent() {
    if (this.essentialForm.contains('isDependent')) {
      return this.essentialForm.get('isDependent');
    }
  }

  get dependentFrequency(){
    return this.essentialForm.get('dependentAdditionalInfo.frequency')
  }

  get dependentAmount(){
    return this.essentialForm.get('dependentAdditionalInfo.amount')
  }

  get isHealthcare() {
    if (this.essentialForm.contains('isHealthcare')) {
      return this.essentialForm.get('isHealthcare');
    }
  }

  get healthcareFrequency(){
    return this.essentialForm.get('healthcareAdditionalInfo.frequency')
  }

  get healthcareAmount(){
    return this.essentialForm.get('healthcareAdditionalInfo.amount')
  }

  get isLeisure() {
    if (this.essentialForm.contains('isLeisure')) {
      return this.essentialForm.get('isLeisure');
    }
  }

  get leisureFrequency(){
    return this.essentialForm.get('leisureAdditionalInfo.frequency')
  }

  get leisureAmount(){
    return this.essentialForm.get('leisureAdditionalInfo.amount')
  }

  get isMotorRunningCost() {
    if (this.essentialForm.contains('isMotorRunningCost')) {
      return this.essentialForm.get('isMotorRunningCost');
    }
  }

  get motorRunningCostFrequency(){
    return this.essentialForm.get('motorRunningCostAdditionalInfo.frequency')
  }

  get motorRunningCostAmount(){
    return this.essentialForm.get('motorRunningCostAdditionalInfo.amount')
  }

  get isTravel() {
    if (this.essentialForm.contains('isTravel')) {
      return this.essentialForm.get('isTravel');
    }
  }

  get travelFrequency(){
    return this.essentialForm.get('travelAdditionalInfo.frequency')
  }

  get travelAmount(){
    return this.essentialForm.get('travelAdditionalInfo.amount')
  }

  changegroup(control: string, groupName: string, category: string) {
    this.essentialForm.get(control).valueChanges.subscribe((value: string) => {
      if (value == "yes") {
        this.essentialForm.addControl(groupName, this.addtioninfoGroup("Spending", category));
      }
      else if (value == "no") {
        if (this.essentialForm.contains(control)) {
          this.essentialForm.removeControl(groupName);
        }
      }
      else {
      }
    })
  }

  get insuranceValue() {
    return this.essentialForm.get('isinsurance')
  }

  get insuraceGroup() {
    return this.essentialForm.get('addMultipleInsurance')
  }

  get otherExpenses() {
    return this.essentialForm.get('otherExpenses')
  }

  get otherExpenseGroup() {
    return this.essentialForm.get('addMultipleExpense')
  }

  updateRadio(control: string, type: string, category: string) {
    let pageObjects = this.utilService.selectMatchingInfo(type, category);
    if (pageObjects && pageObjects.length > 0 && this.storeData.current_page >= 4) {
      this.essentialForm.get(control).patchValue('yes');
    }
    else if (pageObjects && pageObjects.length == 0 && this.storeData.current_page >= 4) {
      this.essentialForm.get(control).patchValue('no');
    }
    else {
      this.essentialForm.get(control).patchValue('');
    }
  }

  updateFormGroup(formGroup: string, type: string, category: string) {
    let pageObjects = this.utilService.selectMatchingInfo(type, category)
    if (pageObjects.length > 0 && this.storeData.current_page >= 4) {
      this.essentialForm.get(formGroup).patchValue(pageObjects[0])
    }
  }

  addtioninfoGroup(type: string, category: string): FormGroup {
    return new FormGroup({
      "type": new FormControl(type),
      "category": new FormControl(category),
      "sub_category": new FormControl(null),
      "amount": new FormControl('', [Validators.required, Validators.pattern(this.numberWithtwoDecimalRegExp)]),
      "frequency": new FormControl(null, Validators.required),
      "behind_payments_amount": new FormControl(null),
      "is_include_arrears": new FormControl(null),
    })
  }


  get getMultipleInsurance() {
    return this.essentialForm.get('addMultipleInsurance') as FormArray;
  }

  get addMultipleInsurance(): FormGroup {
    return this.fb.group({
      typeOfInsuranceAmountInput: ['', [Validators.required, Validators.pattern(this.numberWithtwoDecimalRegExp)]],
      typeOfInsuranceDropdown: [null, [Validators.required]],
      howOftenDropdown: [null, [Validators.required]]
    })
  }


  changeInsurance(e: any) {
    if (e.target.value == "yes") {
      if (this.lengthofAdditionalInsuranceObj == 0) {
        this.addOtherInsurance()
      }
    }
    else {
      (this.essentialForm.get('addMultipleInsurance') as FormArray).clear()
    }
  }

  get lengthofAdditionalInsuranceObj() {
    var id = (this.essentialForm.controls['addMultipleInsurance']) ?
      this.essentialForm.controls['addMultipleInsurance'].controls.length
      : '0';

    return id;
  }

  addOtherInsurance() {
    (this.essentialForm.get('addMultipleInsurance') as FormArray).push(this.addtioninfoGroup('Spending', 'Insurance'))
  }

  deleteEssentialCost(i: number) {
    (this.essentialForm.get('addMultipleInsurance') as FormArray).removeAt(i)
  }

  /*second form Logic*/

  get getMultipleExpense() {
    return this.essentialForm.get('addMultipleExpense') as FormArray;
  }

  changeExpense(e: any) {
    if (e.target.value == "yes") {
      if (this.lengthofAdditionalExpenseObj == 0) {
        this.addOtherExpense()
      }
    }
    else {
      (this.essentialForm.get('addMultipleExpense') as FormArray).clear()
    }
  }

  get lengthofAdditionalExpenseObj() {
    var id = (this.essentialForm.controls['addMultipleExpense']) ?
      this.essentialForm.controls['addMultipleExpense'].controls.length
      : '0';

    return id;
  }

  addOtherExpense() {
    (this.essentialForm.get('addMultipleExpense') as FormArray).push(this.addtioninfoGroup('Spending','Other'))
  }

  deleteOtherExpenset(i: number) {
    (this.essentialForm.get('addMultipleExpense') as FormArray).removeAt(i)
  }

  goBackToIncomePage() {
    this.router.navigate(['budget-form/3']);
    window.scroll(0, 0);
  }

  goToNextPage() {
    this.submitted = true;
    if (this.essentialForm.valid) {
      this.updateDetail();
    }
  }

  navigateToNextpage(){
    window.scroll(0, 0);
    this.router.navigate(['budget-form/5']);
  }

  updateDetail() {
    this.budgetService.updateBudgetDetail(this.essentialForm.value, 'Spending').subscribe((budgetResponse: BudgetPlanner) => {
      this.datastoreService.setBudgetDetail(budgetResponse);
      this.navigateToNextpage()
    }, (error: any) => {
      this.showError();
    });
  }

  showError(): void {
    this.datastoreService.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  openModal(budgetFormItem: object) {
    this.submitted = true;
    window.scroll(0, 0);
    if (this.essentialForm.valid) {
      this.modalService.openModalFromService(budgetFormItem, this.essentialForm.value, 'Spending')
    }
  }
}
