import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RouterService } from "@backbase/foundation-ang/core";
import { DatastoreService } from '../../../../service/datastore.service';
import { patternMap } from '../../../../config/wss-app-constants';
import { DirectDebitResponse } from '../../models/direct-debit';
import { isEmpOrNullOrUndef, getDigitSuffix, isErrServer } from '../../../../common/util/util';
import { BannerMessage } from '../../../../model/bannerMessage';
import { MessageService } from '../../../../service/message.service';
import { NextPayment } from '../../../../model/nextPayment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from '../../services/payment.service';
import { SendSMSComponent } from '../../../../common/components/send-sms/send-sms.component';
import { SMSModal } from '../../../../model/sms-modal';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { SendSMS } from '@wss/model/send-sms';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'direct-debit-result',
    templateUrl: './direct-debit-result.component.html',
    providers: [ContentService]
})

export class DirectDebitResultComponent implements OnInit {
    // contentTxt: string = ContentData.DirectDebitResult;
    // content: Array<any> = [];
    // contentFolder: string = ContentData.DirectDebit;
    messageForm!: FormGroup;
    //activeDirectDebitFound: boolean = this.datastoreService.activeDirectDebitFound;
    directDebitResponse: DirectDebitResponse = new DirectDebitResponse();
    accountDetail: any = {};
    isWithIn8Days: boolean = false;
    smsModalContent: Array<any> = [];
    smsModal: SMSModal = new SMSModal();
    // messageData: any = {
    //     materialIconClass: 'check_circle_outline',
    //     title: '',
    //     subTitle: '',
    //     height: this.isWithIn8Days? '144px': '124px'
    // };
    bannerMessage: BannerMessage = new BannerMessage();
    nextPaymentData: NextPayment = new NextPayment();
    isBannerActive: boolean = false;
    modalRef: any;
    smsResponse: any;
    serviceError: boolean = false;

    sendSMS: SendSMS = new SendSMS();

    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
    paymentsWidgetItem: PaymentsWidgetItem = new PaymentsWidgetItem();

    constructor(
        private router: RouterService,
        private datastoreService: DatastoreService,
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private paymentService: PaymentService,
        private changeDetectorRef: ChangeDetectorRef,
        private messageService: MessageService,
        private readonly bbContentService: ContentService,
        private tealiumService: TealiumUtagService
    ) { }

    ngOnInit() {
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Direct Debit Result',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.messageForm = this.formBuilder.group({
            mobileNumber: ['', Validators.compose([
                Validators.pattern(patternMap.mobileNumber)
            ])]
        });
        this.directDebitResponse = this.datastoreService.getDirectDebitResponse();
        this.accountDetail = this.datastoreService.getAccountDetails();
        this.isWithIn8Days = this.checkIfWithIn8Days();
        this.nextPaymentData.next_payment_amount = this.accountDetail.next_payment_amount;
        this.nextPaymentData.next_payment_date = this.accountDetail.next_payment_date;
        this.paymentsWidgetItem$.subscribe((paymentsWidgetItem: any) => {
            this.paymentsWidgetItem = paymentsWidgetItem;
            this.showBannerMessage();
        });
        this.handleErrors();
        this.paymentsWidgetItem$.subscribe((paymentsWidgetItem: any) => {
            this.paymentsWidgetItem = paymentsWidgetItem;
        });
        this.messageService.setCurrentStep(0);
    }

    checkIfWithIn8Days(): boolean {
        let result = false;
        if (!isEmpOrNullOrUndef(this.directDebitResponse)
            && !isEmpOrNullOrUndef(this.directDebitResponse.next_payment_due_date)
            && !isEmpOrNullOrUndef(this.directDebitResponse.new_payment_due_day)
            && (parseInt(this.directDebitResponse.next_payment_due_date.split("/")[0]) !== parseInt(this.directDebitResponse.new_payment_due_day))) {
            result = true;
        }
        return result;
    }

    showBannerMessage() {
        this.bannerMessage.closeIconClass = "clear";
        this.bannerMessage.materialIconClass = "check_circle_outline";
        this.bannerMessage.iconPositionClass = "mgn-top-15px";
        this.bannerMessage.leftSectionColorClass = "message-success";
        this.bannerMessage.isBannerClosed = false;
        if (this.isWithIn8Days) {
            this.isBannerActive = true;
            this.nextPaymentData.next_payment_date = this.directDebitResponse.next_payment_due_date;

            this.bannerMessage.titleText = `${this.paymentsWidgetItem.directDebitResult.successBannerWithIn8Days.titleTextBeforeData} ${this.directDebitResponse.next_payment_due_date}`;

            this.bannerMessage.subtitleText = `${this.paymentsWidgetItem.directDebitResult.successBannerWithIn8Days.subtitleTextBeforeData} ${getDigitSuffix(this.directDebitResponse.new_payment_due_day)} ${this.paymentsWidgetItem.directDebitResult.successBannerWithIn8Days.subtitleTextAfterData}`;
        }
        else {
            this.bannerMessage.titleText = `${this.paymentsWidgetItem.directDebitResult.successBannerOutside8Days.titleTextBeforeData}`;
            this.bannerMessage.subtitleText = `${this.paymentsWidgetItem.directDebitResult.successBannerOutside8Days.subtitleTextBeforeData}`;
            this.bannerMessage.isBannerClosed = true;
        }
        this.messageService.setBannerMessage(this.bannerMessage);

        this.changeDetectorRef.detectChanges();
    }

    // onModalClose() {
    //     this.modalRef.close();
    // }
    onSmsClick() {
        this.sendSMS.sendSMSTitleText = this.paymentsWidgetItem.directDebitResult.directDebitSendSms.sendSMSTitleText;
        this.sendSMS.sendSMSTopBodyText = this.paymentsWidgetItem.directDebitResult.directDebitSendSms.sendSMSTopBodyText;
        this.sendSMS.sendSMSHelpNoteText = this.paymentsWidgetItem.directDebitResult.directDebitSendSms.sendSMSHelpNoteText;
        this.sendSMS.mobileNumberLabelText = this.paymentsWidgetItem.directDebitResult.directDebitSendSms.mobileNumberLabelText;
        this.sendSMS.mobileNumberPlaceholderText = this.paymentsWidgetItem.directDebitResult.directDebitSendSms.mobileNumberPlaceholderText;
        this.sendSMS.sendSMSButtonText = this.paymentsWidgetItem.directDebitResult.directDebitSendSms.sendSMSButtonText;
        this.sendSMS.mobileNumberErrorMessageText = this.paymentsWidgetItem.directDebitResult.directDebitSendSms.mobileNumberErrorMessageText;
        // this.smsModal.title = this.content[0].smstitle;
        // this.smsModal.subtitle = this.content[0].smssubtitle;
        this.sendSMS.smsType = "DD";
        this.messageService.setSendSMSContentData(this.sendSMS);
        this.modalRef = this.modalService.open(SendSMSComponent, { windowClass: 'save-card-modal' });
        this.modalRef.componentInstance.smsModal = this.sendSMS;
        this.messageService.getSMSResponse().subscribe((response: any) => {
            this.handleSMSResponse(response);
        });

        // this.modalRef = this.modalService.open(smsDirectDebitModal, { windowClass: 'save-card-modal' });
        // let smsModalContentTxt = ContentData.smsModal;
        // let smsModalContentFolder = ContentData.MakePayment;
        // this.getStaticContent(smsModalContentTxt, smsModalContentFolder);
    }

    checkIfWith8DaysCalenderBased(): boolean {
        let todayDay = new Date().getDate();
        let nextPaymentDay = this.accountDetail.next_payment_date;
        if (!isEmpOrNullOrUndef(nextPaymentDay)) {
            nextPaymentDay = parseInt(nextPaymentDay.split("/")[0]);
            if (todayDay > nextPaymentDay) {
                return false;
            }
            else if ((todayDay < nextPaymentDay) && ((nextPaymentDay - todayDay) < 8)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    handleErrors() {
        this.messageService.getServiceError().subscribe((error: any) => {
            // this.requestStatus.invalid = true;
            if (error && error.message && error.message === 'You have exceeded the maximum number of text messages allowed per day') {
                this.handleSMSResponse({});
            }
            else {
                this.showError(error.status);
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    handleSMSResponse(response: any) {
        this.bannerMessage = new BannerMessage();
        this.smsResponse = response;
        this.isBannerActive = false;
        this.changeDetectorRef.detectChanges();
        this.bannerMessage.closeIconClass = "clear";
        this.bannerMessage.iconPositionClass = "mgn-top-15px";
        this.bannerMessage.isBannerClosed = false;
        if (this.smsResponse.code === "OK") {
            this.bannerMessage.materialIconClass = "check_circle_outline";
            this.bannerMessage.leftSectionColorClass = "message-success";
            this.bannerMessage.titleText = `${this.paymentsWidgetItem.directDebitResult.smsSuccessBanner.titleTextBeforeData}`;
        } else {
            this.bannerMessage.infoTypeClass = 'error-icon';
            this.bannerMessage.leftSectionColorClass = "message-failure";
            this.bannerMessage.titleText = `${this.paymentsWidgetItem.directDebitResult.smsFailureBanner.titleTextBeforeData}`;
        }
        this.messageService.setBannerMessage(this.bannerMessage);
        this.isBannerActive = true;
        this.changeDetectorRef.detectChanges();
    }

    showError(errStatus: number): void {
        if (isErrServer(errStatus)) {
            this.datastoreService.setErrorServer();
            this.serviceError = true;
            this.changeDetectorRef.detectChanges();
        }
    }
}