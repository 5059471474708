import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { DigitalInboxWidgetItem } from '@wss/model/content/digital-inbox';
import { RouterService } from "@backbase/foundation-ang/core";
import { DigitalInboxService } from '../../services/digital-inbox.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isErrServer, isNotEmpOrNullOrUndef, getDeviceType, injectBrandDetailIntoPage } from 'libs/common/util/util';
import { MessageService } from 'libs/service/message.service';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'manage-preference',
  templateUrl: './manage-preference.component.html',
  providers: [ContentService]
})
export class ManagePreferenceComponent implements OnInit, OnDestroy{

  digitalInboxWidgetItem$: Observable<DigitalInboxWidgetItem | undefined> = this.contentService.getContent<DigitalInboxWidgetItem>('digitalInboxWidgetItem');
  digitalInboxWidgetItem: any = new DigitalInboxWidgetItem();
  currentAccount: any = null;//{is_paperless: false};
  otherAccounts: any[] = [];//[{is_paperless: true}, {is_paperless: false}];
  acctNum: any = "";
  readonly destroy$ = new Subject();
  serviceError: boolean = false;
  deviceType: string = 'Mobile';
  currentPreferences: any[] = [];

  constructor(
    private readonly contentService: ContentService,
    private router: RouterService,
    private digitalInboxService: DigitalInboxService,
    private datastoreService: DatastoreService,
    private changeDetectorRef: ChangeDetectorRef,
    private tealiumService: TealiumUtagService,
    private messageService: MessageService
    ) {}

  ngOnInit(): void {
    this.tealiumService.view (
      {
        JourneyName: 'Digital Inbox',
        JourneyStepName: 'Manage Preference',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.messageService.setActiveNav("");
    this.deviceType = getDeviceType();
    this.acctNum = this.datastoreService.getAccountNo();
    this.otherAccounts = this.datastoreService.getAccountList();
    this.otherAccounts = this.otherAccounts.filter(account => !isNotEmpOrNullOrUndef(account.completion_date));
    this.digitalInboxWidgetItem$.subscribe((pageItem: any) => {
      this.digitalInboxWidgetItem = pageItem;
      this.digitalInboxWidgetItem.managePreference = injectBrandDetailIntoPage(this.digitalInboxWidgetItem.managePreference, this.digitalInboxWidgetItem.brandContactDetails);
      this.changeDetectorRef.detectChanges();
    });

    //let acctList = this.datastoreService.getAccountList();
   // this.currentAccount = this.datastoreService.getCurrentAccountMailPreference();
   // this.otherAccounts = acctList.filter((account: any) => account.account_number !== this.acctNum);
    this.getPreferenceForAll();
    this.handleErrors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateToConfirmPreference() {
    // location.href = "#/myaccount";
    this.currentAccount.is_paperless = this.currentAccount.checked;
    this.datastoreService.setCurrentAccountMailPreference(this.currentAccount);
    this.otherAccounts = this.otherAccounts.map((account: any) => {
      return {...account, is_paperless: account.checked};
    });
    this.datastoreService.setOtherAccountsMailPreference(this.otherAccounts);
    this.router.navigate(['confirm-preference']);
  }

  getPreferenceForAll() {
    //let today = new Date();
    forkJoin(
      this.otherAccounts.map(account =>
        this.digitalInboxService.getMailPreference(account.account_number)
      )
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        for (var i = 0; i < result.length; i++) {
          this.otherAccounts[i] = { ...result[i], ...this.otherAccounts[i] };
        }
        this.currentAccount = this.otherAccounts.filter((account: any) => account.account_number === this.acctNum)[0];
        this.otherAccounts = this.otherAccounts.filter((account: any) => account.account_number !== this.acctNum);
        this.currentAccount.checked = this.currentAccount.is_paperless;
        this.currentPreferences.push(this.currentAccount.is_paperless);
        this.otherAccounts = this.otherAccounts.map((account: any) => {
          this.currentPreferences.push(account.is_paperless);
          return {...account, checked: account.is_paperless};
        });

        this.changeDetectorRef.detectChanges();
      },
        (error: any) => {
          this.showError(error?.status);
        });
  }

  checkIfPreferenceChanged(){
    let result = false;
    if(this.currentAccount?.checked !== this.currentPreferences[0]){
      result = true;
    }
    else{
      for(var i = 1; i < this.currentPreferences.length; i++){
        if(this.currentPreferences[i] !== this.otherAccounts[i-1]?.checked){
          result = true;
        }
      }
    }
    return result;
  }

  toggleView(account: any, preference: string) {
    if (preference === 'paper') {
      //account.is_paperless = false;
      account.checked = false;
    }
    else {
      //account.is_paperless = true;
      account.checked = true;
    }
    this.changeDetectorRef.detectChanges();
  }

  showError(errStatus: number): void {
    if (isErrServer(errStatus)) {
      this.datastoreService.setErrorServer();
      this.serviceError = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  handleErrors() {
    this.messageService.getServiceError().pipe(takeUntil(this.destroy$)).subscribe((error: any) => {
      if (error) {
        this.showError(error?.status || error?.code);
      }
    });
  }

}