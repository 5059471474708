import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { BudgetFormItem } from '@wss/model/budget-form-widget/budgetForm';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { BannerMessage } from '@wss/model/bannerMessage';
import { MessageService } from '@wss/service/message.service';
import { saveAndExitService } from '@wss/budget-cover-widgets/components/save-and-exit-modal/save-and-exit.service';
import { UtilService } from '@wss/budget-cover-widgets/shared/service/util.service';
import { budgetForm, patternMap } from '@wss/config/wss-app-constants';
import { BudgetService } from '@wss/budget-cover-widgets/shared/service/budget.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { BudgetPlanner } from '@wss/budget-cover-widgets/shared/model/budget.model';
import { ServiceExceptionBudget } from '@wss/model/service-exception-budget';
import { ItemModel } from '@backbase/foundation-ang/core';
import { TealiumUtagService } from '@wss/service/utag.service';
@Component({
  selector: 'bb-money-owed-widget',
  templateUrl: './budget-money-owed-widget.component.html',
  providers: [ContentService]
})

export class BudgetMoneyOwedComponent implements OnInit {
  serviceException: ServiceExceptionBudget = new ServiceExceptionBudget();
  moneyOwedForm: FormGroup = new FormGroup({});
  haveOtherPaymentsSelected: boolean = false;
  submitted: boolean = false;
  numberWithtwoDecimalRegExp: RegExp = new RegExp(patternMap.amountwithtwodecimalPattern);
  behindWithPayments: boolean = false;
  showCreditorLimitMessage: boolean = false;
  maximumPaymentEntries: number = budgetForm.maximumAdditionInfoEntries;
  bannerMessage: BannerMessage = new BannerMessage();
 // budgetFormItem: BudgetFormItem | undefined;
  serviceError: boolean = false;
  frequencies: string[] = budgetForm.frequencyList;
  typeOfAccount: string[] = budgetForm.typeOfAccount;
  budgetFormItem$: Observable<BudgetFormItem | undefined> = this.bbContentService.getContent<BudgetFormItem>('BudgetFormItem');
  storeData: any;
  isHaveOtherPayments:any;
  emailid: any;

  constructor(
    private readonly bbContentService: ContentService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private router: Router,
    private modalService: saveAndExitService,
    public  utilService: UtilService,
    private datastoreService: DatastoreService,
    private budgetService: BudgetService,
    private itemModel : ItemModel,
    private tealiumService: TealiumUtagService) {

  }

  ngOnInit(): void {
    this.itemModel
      .property('emailId').subscribe(propertyValue => {
        this.emailid = propertyValue || ''
    });
    this.getServiceData();
    this.initMoneyOwedForm();
    this.tealiumService.view(
      {
        JourneyName: 'Budget Cover',
        JourneyStepName: 'Money Owed',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

  getServiceData() {
    this.storeData = this.datastoreService.getBudgetDetail()
       /**
     * If user will redirect this page manually then
     * we are checking that user have already gone through this page
     * earlier and save the data
     * If not then we are redirecting to the budget home page
     */

    /* if(!this.storeData.current_page || (this.storeData.current_page && this.storeData.current_page < 4)) {
      this.router.navigate(['help-centre/money-worries']);
    } */
  }

  updateRadio(control: string, type: string, category?: string) {
    let pageObjects = [];
    if (this.storeData && this.storeData.additional_info) {
      pageObjects = this.storeData.additional_info.filter(function (obj: any) {
        return category ? (obj.type === type && obj.category === category) : obj.type === type;
      })
    }

    if (pageObjects.length > 0 && this.storeData.current_page >= 5) {
      this.moneyOwedForm.get(control)?.patchValue('yes')
    }
    else if (pageObjects.length == 0 && this.storeData.current_page >= 5) { //
      this.moneyOwedForm.get(control)?.patchValue('no')
    }
    else {
      this.moneyOwedForm.get(control)?.patchValue('')
    }
  }

  initMoneyOwedForm() {
    this.moneyOwedForm = this.fb.group({
      current_page:"5",
      status:'Draft',
      haveOtherPayments: ['', [Validators.required]],
      otherPayments: this.fb.array([]),
    });
    this.addDataArray();

    this.updateRadio('haveOtherPayments', 'Money you own');
    this.changegroup('haveOtherPayments', 'otherPayments')
  }

  get haveOtherPayments() {
    return this.moneyOwedForm.get('haveOtherPayments')
  }

  get otherPayments() {
    return this.moneyOwedForm.get('otherPayments') as FormArray;
  }

  get otherPaymentForm() {
    return this.fb.group({
      type: ['Money you own', [Validators.required]],
      category: [null, [Validators.required]],
      sub_category: [""],
      amount: ['', [Validators.required, Validators.pattern(this.numberWithtwoDecimalRegExp)]],
      frequency: [null, [Validators.required]],
      behind_payments_amount: ['', []],
      is_behind_payment: ['',[Validators.required]],
      is_include_arrears: [null, [Validators.required]]

    });
  }

  setIsHaveOtherPayments(value:any){
    this.isHaveOtherPayments = value
  }



  addDataArray() {

    let pageObjects = this.utilService.selectMatchingInfoByType('Money you own')

    if (pageObjects && pageObjects.length > 0 && this.storeData.current_page >= 5) {
      for (let i = 0; i < pageObjects.length; i++) {
        if(pageObjects[i].is_include_arrears != null){
          pageObjects[i].is_include_arrears =  pageObjects[i].is_include_arrears.toString()
        }

        if(pageObjects[i].behind_payments_amount){
          pageObjects[i].is_behind_payment = "yes"
        }
        else{
          pageObjects[i].is_behind_payment = "no"
        }

        this.addOtherPayment();
      }
    }

    this.moneyOwedForm.controls['otherPayments'].patchValue(pageObjects)

  }



  changegroup(control: string, groupName: string) {
    this.moneyOwedForm.get(control)?.valueChanges.subscribe((value: string) => {
      if (value == "yes") {
        this.otherPayments.push(this.otherPaymentForm);
      }
      else if (value == "no") {
        this.otherPayments.clear();
      }
      else {
      }
    })
  }


  onBehindWithPaymentsChanged(e: any, index: number) {
    const otherPaymentForm = this.otherPayments.at(index) as FormGroup;

    if (e.target.value === "yes") {
      otherPaymentForm.addControl('behind_payments_amount', this.fb.control('', []));
      otherPaymentForm.controls["behind_payments_amount"].setValidators([Validators.required, Validators.pattern(this.numberWithtwoDecimalRegExp)]);
      otherPaymentForm.updateValueAndValidity();
    } else {
      if (otherPaymentForm.contains('behind_payments_amount')) {
        otherPaymentForm.removeControl('behind_payments_amount');
      }
    }
  }

  addOtherPayment() {
    this.otherPayments.push(this.otherPaymentForm);

  }

  deleteOtherPayment(index: number) {
    this.otherPayments.removeAt(index);
  }

  goToNextPage() {
    this.submitted = true;

    if (this.moneyOwedForm.valid) {
      this.updateDetail()

      window.scroll(0, 0);
    }
  }

  goBack() {
    this.router.navigate(['budget-form/4']);
    window.scroll(0, 0);
  }

  openModal(budgetFormItem: object) {
    this.submitted = true;
    window.scroll(0, 0);
    if (this.moneyOwedForm.valid) {
      this.modalService.openModalFromService(budgetFormItem,this.moneyOwedForm.value, 'Money you own')
    }
  }



  updateDetail() {
    this.budgetService.updateBudgetDetail(this.moneyOwedForm.value, 'Money you own').subscribe((budgetResponse: BudgetPlanner) => {
      this.datastoreService.setBudgetDetail(budgetResponse);
      let currentTimeHours = this.getTimeFromDateObject(budgetResponse.planner_date);
      (currentTimeHours < 22 && currentTimeHours >= 8) ? this.submitForm() :this.showOutOfWorkHoursError();
    }, (error: any) => {
      this.showError();
    });
  }

  submitForm() {
    this.budgetService.submitBudgetForm().subscribe((detail:any) => {
      if(detail.result === 'Success') {
        this.datastoreService.setSubmitBudgetDetail(detail)
        this.datastoreService.bugdgetStatus = "Completed"
        this.sentMail()
        this.router.navigate(['budget-form/6']);
      } else {
        if(detail.result === 'Error' && detail.code === '316520') {
          this.showMainFrameServiceDownError();
        }
        this.showError();
      }
    }, (error: any) => {
      this.sentMail();
    });
  }


  sentMail(){
    this.budgetService.sentMail(this.emailid).subscribe((budgetResponse: BudgetPlanner) => {

    }, (error: any) => {

    });
  }

  showError(): void {
    this.datastoreService.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  checkingFiveDigitValue(value: any) {
    return Math.round(value).toString().length > 5;
  }

  showOutOfWorkHoursError() {
    this.budgetFormItem$.subscribe((contentRes: any) => {
      this.serviceException.panelHeadingText = contentRes?.outOfHours.heading;
      this.serviceException.errorInfoText = contentRes?.outOfHours.bodyText;
      this.serviceException.buttonText = contentRes?.outOfHours.buttonText;
      this.serviceException.componentUri = contentRes?.outOfHours.componentUri;
      this.messageService.setServiceExceptionBudget(this.serviceException);
      this.router.navigate(['service-exception']);
    })
  }

  showMainFrameServiceDownError() {
    this.budgetFormItem$.subscribe((contentRes: any) => {
      this.serviceException.panelHeadingText = contentRes?.mainFrameSystemDown.heading;
      this.serviceException.errorInfoText = this.replaceBrandContactNumber (contentRes?.mainFrameSystemDown.bodyText, contentRes?.brandContactDetails.brandWiseContactNumber);
      this.serviceException.buttonText = contentRes?.mainFrameSystemDown.buttonText;
      this.serviceException.componentUri = contentRes?.mainFrameSystemDown.componentUri;
      this.messageService.setServiceExceptionBudget(this.serviceException);
      this.router.navigate(['service-exception']);
    })
  }

  getTimeFromDateObject(value: any) {
    let splitDateTime = value.split(' ');
    let time = splitDateTime[1].split(':');
    return time[0];
  }

  replaceBrandContactNumber(htmlText: any, brandContactNumber: any) {
    return htmlText.replace('[CFA number]', brandContactNumber.replace('<p>', '').replace('</p>',''));
  }

}
