import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { PopoverService } from '@wss/common/service/popover.service';
import { getDeviceType } from '@wss/common/util/util';
import { WSSAppConstant } from '@wss/config/wss-app-constants';

declare var document: any;
@Component({
  selector: 'wss-popover',
  templateUrl: './popover.component.html'
})
export class PopoverComponent implements OnInit, OnDestroy {
  
  constructor(
    private popoverService: PopoverService
  ) { }
  
  @Input() popoverContent: any;
  @Input() popOverId: string = '';
  tooltipCloseStyle: string = "font-size: 18px; margin-top: -10px !important;";
  svgInfoIcon: any = WSSAppConstant.SVG_INFO_ICON;

  ngOnInit(): void {
  }

  closePopover() {
    this.popoverService.setState(true);
  }

  ngOnDestroy(): void {
    this.popoverService.setState(true);
  }

}
