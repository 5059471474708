import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { FooterWidgetComponent } from './footer-widget.component';
import { WSSCommonModule } from '../../common/common.module';

@NgModule({
  declarations: [FooterWidgetComponent],
  imports: [
    CommonModule,
    BackbaseCoreModule.withConfig({
      classMap: { FooterWidgetComponent }
    }),
    WSSCommonModule
  ]
})
export class FooterWidgetModule { }