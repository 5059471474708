<div class="eoc-landing" >
    <div class="container-column">
        <div class="section-title-container">
            <h1 class="section-label-title" test_id="pageName">Update your vehicle details</h1>
        </div>
        <div>
            <p>We'll need to check your vehicle details are correct and up to date.</p>
            <div class="card p-3">
                <p>
                    <strong>Is the vehicle registration plate CK12 CXX?</strong>
                </p>
                <div>
                    <input type="radio" name="registrationPlate" id="plateYes" value="yes" 
                    [(ngModel)]="isVehicleRegistrationNoCorrect" (change)="onVehicleRadioChange()">
                    <label class="align-bottom ml-1" for="plateYes">Yes</label>
                </div>
                <div class="border-bottom pb-3">
                    <input type="radio" name="registrationPlate" id="plateNo" value="no" 
                    [(ngModel)]="isVehicleRegistrationNoCorrect" (change)="onVehicleRadioChange()">
                    <label class="align-bottom ml-1" for="plateYes">No</label>
                </div>
                <div class="border-bottom pb-3 pt-3" *ngIf="vehicleData$ | async as vehicleData">
                    <p><strong>Here are the detail:</strong></p>

                    <p class="mb-0">Description:</p>
                    <p><strong>{{vehicleData?.goods_description}}</strong></p>

                    <p class="mb-0">Maximum mileage:</p>
                    <p><strong>{{vehicleData?.max_total_mileage}}</strong></p>

                    <p class="mb-0">Excess mileage charge   :</p>
                    <p><strong>{{vehicleData?.excess_mileage_charge}}</strong></p>
                </div>
                <p class="mt-3">
                    <strong>What's the curret mileage of your vehicle?</strong>
                </p>
                <div class="para-inline">
                    <input type="text" class="form-control w-25 d-inline mr-1">
                    <span>miles</span>
                </div>
            </div>
            <button class="wss-button" (click)="navigate('personal-details')">Continue</button>
            <div class="text-center" routerLink="/balloon-refinance/get-a-quote">
                <a>Back a step</a>
            </div>
        </div>
    </div>
</div>
