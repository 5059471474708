import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { RouterService } from "@backbase/foundation-ang/core";
import { WSSAppConstant, ContentData } from '../../../../config/wss-app-constants';
import { PaymentService } from '../../services/payment.service';
import { DatastoreService } from '../../../../service/datastore.service';
import { ServiceException } from '../../../../model/service-exception';
import { MessageService } from '../../../../service/message.service';
import * as moment from 'moment';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable, Subscription } from 'rxjs';
import { getErrorImageClass } from '@wss/common/util/util';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { Location } from '@angular/common';
import { TealiumUtagService } from '@wss/service/utag.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'payment-selection',
    templateUrl: './payment-selection.component.html',
    providers: [ContentService]
})

export class PaymentSelectionComponent implements OnInit {
    params: Params = {};
    accountDetail: any = {};
    serviceException: ServiceException = new ServiceException();
    failCount: any = 0;
    paymentButtonDisabled: boolean = false;
    overPaymentButtonDisabled: boolean = false;

    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
    paymentsWidgetItem: PaymentsWidgetItem = new PaymentsWidgetItem();
    paymentSelectionForm: any;
    overpaymentArrowDown: boolean = true;
    regularArrowDown: boolean = true;

    constructor(
        private router: RouterService,
        private datastoreService: DatastoreService,
        public changeDetectorRef: ChangeDetectorRef,
        private messageService: MessageService,
        private readonly bbContentService: ContentService,
        private routerAng: ActivatedRoute,
        private _location: Location,
        private tealiumService: TealiumUtagService,
        private fb: FormBuilder
    ) { }

    ngOnInit() {
        this.datastoreService.sessionInvalid();
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Payment Selection',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.paymentSelectionForm = this.fb.group({
            paymentType: ["", [Validators.required]]
        })
        this.failCount = this.datastoreService.getFailCountValue();
        this.accountDetail = this.datastoreService.getAccountDetails();
        this.paymentOptionVisibility();
        this.paymentsWidgetItem$.subscribe((paymentsWidgetItem: any) => {
            this.paymentsWidgetItem = paymentsWidgetItem;
        });
        this.messageService.setCurrentStep(0);
    }

    paymentOptionVisibility() {
        var today = moment().format('DD/MM/YYYY');
        var next_due_date = moment(this.accountDetail?.next_payment_date, 'DD/MM/YYYY').format('DD/MM/YYYY');
        var diffTime = moment(next_due_date, 'DD/MM/YYYY').diff(moment(today, 'DD/MM/YYYY'), 'days');

        if (this.accountDetail) {
            this.paymentButtonDisabled = false;
            if (this.accountDetail.is_legal || this.accountDetail.is_litigation_indicator || this.accountDetail.in_arrears 
                || this.accountDetail.num_payments_left <= 1 || !this.accountDetail.regulated_ind) {
                this.overPaymentButtonDisabled = true;
                return;
            }
            if (this.accountDetail.is_dd_calling || diffTime < 7) {
                this.overPaymentButtonDisabled = false;
                return;
            }
            if (this.accountDetail.completion_date !== "") {
                this.paymentButtonDisabled = true;
                this.overPaymentButtonDisabled = true;
                return;
            }
        }
    }

    onMakePaymentClick() {
        if (this.failCount > 2) {
            localStorage.setItem('redirectionFlag', 'paymentMenu');
            this.router.navigate(['payment-result-process']);
        } else {
            if (localStorage.getItem('redirectionFlag') !== 'payByDebitCard') {
                localStorage.setItem('redirectionFlag', 'paymentMenu');
            }
            this.router.navigate(['payment-debit-card']);
        }
    }

    onOverPaymentClick() {
        const currentDate = new Date().toLocaleDateString('en-GB');
        localStorage.setItem('redirectionFlag', 'overpayment');

        var today = moment().format('DD/MM/YYYY');
        var next_due_date = moment(this.accountDetail?.next_payment_date, 'DD/MM/YYYY').format('DD/MM/YYYY');
        var diffTime = moment(next_due_date, 'DD/MM/YYYY').diff(moment(today, 'DD/MM/YYYY'), 'days');

        if (this.accountDetail?.in_arrears || this.accountDetail?.completion_date > currentDate || this.accountDetail?.is_fraud_indicator ||
            (diffTime <= 7 && diffTime >= 0) || this.accountDetail?.num_payments_left === 1 || this.accountDetail?.is_litigation_indicator) {
            if (diffTime <= 7 && diffTime >= 0) {
                sessionStorage.setItem('overpayment-exception', 'dueDayException');
            } else {
                sessionStorage.setItem('overpayment-exception', 'accountIneligible');
            }
            this.router.navigate(['over-payment-options']);
        } else {
            this.router.navigate(['over-payment']);
        }
    }

    goToNextPage() {
        if (this.paymentSelectionForm.get('paymentType').value && this.paymentSelectionForm.get('paymentType').value == 'regularPayment') {
            this.onMakePaymentClick();
        } else {
            this.onOverPaymentClick();
        }
    }

    openInfo(paymentOption: string) {
        if (paymentOption == "regularPayment") {
            this.regularArrowDown = !this.regularArrowDown;
        } else {
            this.overpaymentArrowDown = !this.overpaymentArrowDown;
        }
    }
}
