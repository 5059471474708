import { Component, OnInit } from '@angular/core';
import { RoutableWidget } from '@backbase/foundation-ang/core';
import { DatastoreService } from '@wss/service/datastore.service';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UserDetailComponent } from './components/user-address/user-address.component';
import { UserContactComponent } from './components/user-contact/user-contact.component';
import { UserEmailComponent } from './components/user-email/user-email.component';

@Component({
  selector: 'bb-header-content-widget',
  templateUrl: './header-content-widget.component.html',
  styles: [
  ]
})

@RoutableWidget({
  routes: [
    { path: '', redirectTo: 'update-contact', pathMatch: 'full' },
    { path: 'update-contact', component: UserContactComponent },
    { path: 'update-email', component: UserEmailComponent},
    { path: 'update-address', component: UserDetailComponent },
    { path: 'change-password', component: ChangePasswordComponent }
  ],
})
export class HeaderContentWidgetComponent implements OnInit {

  customerDetail: any;
  personalDetails: any = { fullname: "", shortName: "" };
  constructor(private dataStore: DatastoreService) { }

  ngOnInit(): void {
    this.customerDetail = this.dataStore.getCustomerDetail();
    this.personalDetails.fullname = this.customerDetail?.individual?.first_name + " " + this.customerDetail?.individual?.surname;
    this.personalDetails.fullname = this.personalDetails.fullname.toLowerCase();
    this.personalDetails.shortName = this.customerDetail?.individual?.first_name[0] + this.customerDetail?.individual?.surname[0];
  }

}
