import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
// import { NextPayment } from '../../model/account-summary';
import { NextPayment } from '../../../model/nextPayment';
// import { ContentService } from '../../../service/content.service';
// as OldContentService 
// import { ContentData } from '../../../config/wss-app-constants';
import { RouterService } from "@backbase/foundation-ang/core";
import { MessageService } from '../../../service/message.service';
import { ServiceException } from '../../../model/service-exception';
// import { ContentService } from '@backbase/universal-ang/content';
// import { Observable } from 'rxjs';
// import { PaymentsWidgetItem } from '@wss/model/payment';
import { getErrorImageClass } from '../../util/util';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { Observable } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';

@Component({
  selector: 'bb-wss-next-payment',
  templateUrl: 'next-payment.component.html',
  providers: [ContentService]
})
export class NextPaymentComponent implements OnInit {
  // contentTxt: string = ContentData.NextPayment;
  // contentFolder: string = ContentData.Dashboard;
  // content: Array<any> = [];

  @Input() nextPaymentData: NextPayment = new NextPayment();
  @Input() accountDetail: any;
  @Input() nextPaymentContent: any;

  serviceException: ServiceException = new ServiceException();
  paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
  paymentsWidgetItem: PaymentsWidgetItem = new PaymentsWidgetItem();

  constructor(
    private router: RouterService,
    // private contentService: ContentService,
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private readonly bbContentService: ContentService
  ) { }
  
  ngOnInit(): void {
    // this.getStaticContent(this.contentTxt, this.contentFolder);
    // this.getOverriddenContent();
    this.paymentsWidgetItem$.subscribe((paymentsWidgetItem: any) => {
      this.paymentsWidgetItem = paymentsWidgetItem;
    });
  }

  // getStaticContent(contenttxt: string, contentfolder: string): void {
  //   this.contentService.getStaticContent(contenttxt, contentfolder).subscribe((content) => {
  //     this.content = content;
  //     this.changeDetectorRef.detectChanges();
  //   });
  // }

  // getOverriddenContent() {
  //   this.messageService.getContent().subscribe(content => {
  //     this.content = content;
  //     this.changeDetectorRef.detectChanges();
  //   });
  // }

  navigateTo() {
    let currentTimeHours = new Date().getHours();
    if ((currentTimeHours < 22) && (currentTimeHours >= 8)) {
      this.withInWorkinHours();
    }
    else {
      this.outsideWorkingHours();
    }
  }

  withInWorkinHours() {
    if (this.accountDetail && this.accountDetail.in_arrears) {
      this.showArrearError();
    }
    else if (this.accountDetail && this.accountDetail.is_dd_calling) {
      this.showDDCallingError();
    }
    else {
      this.router.navigate(['/payments/change-payment']);
    }
  }

  outsideWorkingHours() {
    if (this.accountDetail && this.accountDetail.in_arrears) {
      this.showArrearError();
    }
    else if (this.accountDetail && this.accountDetail.is_dd_calling) {
      this.showDDCallingError();
    }
    else {
      this.showOutOfWorkHoursError();
    }

  }

  showArrearError() {
    this.serviceException.imageClass = getErrorImageClass(this.nextPaymentContent.arrearException.errorImageType.toString());
    // this.serviceException.phraseBold = content.notablethistime;
    // this.serviceException.helpNote = `${content.forfurtherplease} <a class="faq-link">${content.getintouch}</a> ${content.orseeour} <a class="faq-link">${content.chngpmtfaq}.</a>`;
    this.serviceException.errorInfoText = this.nextPaymentContent.arrearException.errorInfoText;
    // this.serviceException.buttonText = content.backtodashboard;
    // this.serviceException.componentUri = content.cpuri;
    this.serviceException.buttonText = this.nextPaymentContent.arrearException.buttonText;
    this.serviceException.componentUri = this.nextPaymentContent.arrearException.componentUri;
    this.serviceException.cancelLink = this.nextPaymentContent.arrearException.cancelLink;
    // this.serviceException.callUs = false;
    this.messageService.setServiceException(this.serviceException);
    this.router.navigate(['/payments/service-exception']);
  }

  showDDCallingError() {
    this.serviceException.imageClass = getErrorImageClass(this.nextPaymentContent.ddCallingException.errorImageType.toString());
    // this.serviceException.phraseBold = content.notablethistime;
    // this.serviceException.phraseRegular = content.ddcalling;
    // this.serviceException.helpNote = `${content.forfurtherplease} <a class="faq-link">${content.getintouch}</a> ${content.orseeour} <a class="faq-link">${content.cpdfaq}.</a>`;
    this.serviceException.errorInfoText = this.nextPaymentContent.ddCallingException.errorInfoText;
    // this.serviceException.buttonText = content.backtodashboard;
    // this.serviceException.componentUri = content.cpuri;
    this.serviceException.buttonText = this.nextPaymentContent.ddCallingException.buttonText;
    this.serviceException.componentUri = this.nextPaymentContent.ddCallingException.componentUri;
    this.serviceException.cancelLink = this.nextPaymentContent.ddCallingException.cancelLink;
    // this.serviceException.callUs = false;
    this.messageService.setServiceException(this.serviceException);
    this.router.navigate(['/payments/service-exception']);
  }

  showOutOfWorkHoursError() {
    this.serviceException.imageClass = getErrorImageClass(this.nextPaymentContent.outOfWorkHoursException.errorImageType.toString());
    // this.serviceException.phraseBold = content.notablethistime;
    // this.serviceException.phraseRegular = content.oowh;
    // this.serviceException.helpNote = `${content.forfurtherplease} <a class="faq-link">${content.getintouch}</a> ${content.orseeour} <a class="faq-link">${content.chngpmtfaq}.</a>`;
    this.serviceException.errorInfoText = this.nextPaymentContent.outOfWorkHoursException.errorInfoText;
    // this.serviceException.buttonText = content.backtodashboard;
    // this.serviceException.componentUri = content.cpuri;
    this.serviceException.buttonText = this.nextPaymentContent.outOfWorkHoursException.buttonText;
    this.serviceException.componentUri = this.nextPaymentContent.outOfWorkHoursException.componentUri;
    this.serviceException.cancelLink = this.nextPaymentContent.outOfWorkHoursException.cancelLink;
    // this.serviceException.callUs = false;
    this.messageService.setServiceException(this.serviceException);
    this.router.navigate(['/payments/service-exception']);
  }

}