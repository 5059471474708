import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { RouterService } from '@backbase/foundation-ang/core';
import { AdditionalInfoContent } from '@wss/model/content/dashboard';
import { appConfig } from '@wss/config/wss-app-constants';
import { getDeviceType, isPcpToHpBalloonRefinance } from '@wss/common/util/util';
import { AdditionalInfo } from '@wss/account-summary-widget/src/model/account-summary';
import { ManageContractAdditionalInfo } from '@wss/model/content/common/contract-details';

@Component({
  selector: 'bb-common-contract-details',
  templateUrl: './common-contract-details.component.html',
  styles: [
  ]
})
export class CommonContractDetailsComponent implements OnInit {
  @Input() svgInfoIcon: string = '';
  @Input() additionalInfoData: ManageContractAdditionalInfo = new ManageContractAdditionalInfo();
  @Input() additionalInfoContent: any;
  @Input() accountDetail: any;
  @Input() iconColor: any;
  @Input() isLPAgreementFlag: any;

  popoverArrowStyle: string = '';
  popoverSelf: any;
  tooltipCloseStyle: string = "font-size: 18px; margin-top: -10px !important;";
  hpType: number = appConfig.hpCustomer;
  deviceType: string = "Mobile";
  isHpAccount: any;

  constructor(
    config: NgbPopoverConfig,
    private router: RouterService,
    public changeDetectorRef: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.isHpAccount = this.getHPCustomer();
  }

  openPopover(popover: any, event: any, id: string) {
    popover.open();
    this.popoverSelf = popover;
    this.deviceType = getDeviceType();
    if((id === 'aprPopId') && (this.deviceType === 'Mobile')){
      this.popoverArrowStyle = 'left: 12% !important';
    }
    else if((id === 'aprPopId') && (this.deviceType === 'Tablet')){
      this.popoverArrowStyle = 'left: 4% !important';
    }
    else if((id === 'aprPopId') && (this.deviceType === 'Desktop')){
      this.popoverArrowStyle = 'left: 48% !important';
    }
    else if((id === 'ofpPopId') && (this.deviceType === 'Mobile')){
      this.popoverArrowStyle = 'left: 33% !important';
    }
    else if((id === 'ofpPopId') && (this.deviceType === 'Tablet')){
      this.popoverArrowStyle = 'left: 28% !important';
    }
    else if((id === 'ofpPopId') && (this.deviceType === 'Desktop')){
      this.popoverArrowStyle = 'left: 48% !important';
    }
  }

  closePopover() {
    this.popoverSelf.close();
  }

  getHPCustomer() {
    if (this.accountDetail?.scheme_code === '01' || this.accountDetail?.scheme_code === appConfig.hpCustomer || isPcpToHpBalloonRefinance(this.accountDetail)) {
      return false;
    } else {
      return true;
    }
  }

}
