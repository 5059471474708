export class SMSModal{
    title: string = '';
    subtitle: string = '';
    smsType: string = '';
    helpNote: string = '';
    content: SMSForm = new SMSForm();
}

export class SMSForm{
    label: string = '';
    placeholder: string = '';
    buttonTxt: string = '';
    errorMsg: string = '';
}