import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageService } from '@wss/service/message.service';
import { Location } from '@angular/common';
import { ServiceExceptionBudget } from '@wss/model/service-exception-budget';

@Component({
  selector: 'bb-service-exception-widget',
  templateUrl: './service-exception-widget.component.html',
})
export class ServiceExceptionWidgetComponent implements OnInit {
  serviceException: ServiceExceptionBudget = new ServiceExceptionBudget();
  constructor(
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef,
    private _location: Location,
  ) {}

  ngOnInit(): void {
    this.serviceException = this.messageService.getServiceExceptionBudget();
    if (this.serviceException.panelHeadingText != '') {
      this.changeDetectorRef.detectChanges();
    } else {
      this._location.back();
    }
  }

  navigateTo(componentUri: string) {
    location.href = componentUri;
  }
}
