import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BackbaseCoreModule} from '@backbase/foundation-ang/core';
import {WSSCommonModule} from '../../common/common.module';
import {RegistrationWidgetComponent} from './registration-widget.component';
import {UserSearchComponent} from "./components/user-search/user-search.component";
import {UserDetailsComponent} from "./components/user-detail/user-detail.component";
import {NoUserComponent} from "./components/no-user/no-user.component";
import {MatIconModule} from '@angular/material/icon';
import {
  BackbaseUiAngExamplesModule,
  ButtonModule,
  HeaderModule,
  
  InputEmailModule,
  InputNumberModule,
  InputPasswordModule,
  InputTextModule,
  InputValidationMessageModule,
  StepperModule
} from "@backbase/ui-ang";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
// tslint:disable-next-line:import-blacklist
import {HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {VerifySmsComponent} from "./components/verify-sms/verify-sms.component";
import {OtpSmsComponent} from "./components/verify-sms/otp-sms.component";
import {UserCredentialsComponent} from "./components/credentials/user-credentials.component";
import {UserPasswordComponent} from "./components/credentials/user-password.component";
import {CreatedCredentialsComponent} from "./components/credentials/created-credentials.component";
import {SecurityIntroComponent} from "./components/security-intro/security-intro.component";
import {SecurityFailComponent} from "./components/security-fail/security-fail.component";
import {SecurityQuestionsComponent} from "./components/security-questions/security-questions.component";
import {QuestionsFailComponent} from "./components/questions-fail/questions-fail.component";
import {PasscodeErrorComponent} from "./components/passcode-error/passcode-error.component";
import {UserSearchService} from "./service/user-search.service";
import {ContentWidgetModule} from '@backbase/universal-ang/content';
import {ContentModule} from '@backbase/content-ang';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { CreateAccountFaqComponent } from './components/create-account-faq/create-account-faq.component';
import { NgbModalModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactPreferenceComponent } from './components/contact-preference/contact-preference.component';

@NgModule({
  declarations: [
    RegistrationWidgetComponent,
    UserSearchComponent,
    UserDetailsComponent,
    NoUserComponent,
    VerifySmsComponent,
    OtpSmsComponent,
    UserCredentialsComponent,
    UserPasswordComponent,
    CreatedCredentialsComponent,
    SecurityIntroComponent,
    SecurityFailComponent,
    SecurityQuestionsComponent,
    QuestionsFailComponent,
    PasscodeErrorComponent,
    CreateAccountFaqComponent,
    ContactPreferenceComponent
  ],
  providers: [
    UserSearchService
  ],
  imports: [
    CommonModule,
    StepperModule,
    InputNumberModule,
    InputValidationMessageModule,
    FormsModule,
    InputNumberModule,
    HttpClientModule,
    InputValidationMessageModule,
    InputEmailModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    WSSCommonModule,
    InputPasswordModule,
    MatIconModule,
    BackbaseUiAngExamplesModule,
    BackbaseCoreModule.withConfig({
      classMap: {RegistrationWidgetComponent}
    }),
    RouterModule.forRoot([], { initialNavigation: 'disabled', useHash: true, relativeLinkResolution: 'legacy' }),
    HeaderModule,
    RouterModule,
    MatDialogModule,
    MatExpansionModule,
    ContentWidgetModule,
    ContentModule,
    NgbModalModule,
    NgbPopoverModule,
  ]
})
export class RegistrationWidgetModule { }
