<div class="login-container" *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <div class="mt-20px">
    <bb-wss-common-icon [className]="'security-fail-icon'" [imgText]="'Security Fail'"></bb-wss-common-icon>
  </div>
  <div class="col3 text-center" role="alert" test_id="pageHeaderText">
    <bb-content-html [html]="loginWidgetItem.forgotUserNameNoUserFound.pageHeaderText"></bb-content-html>
  </div>
  <div class="col3 text-center contact-number-text" role="alert" test_id="pageDescriptionText">
    <bb-content-html [html]="loginWidgetItem.forgotUserNameNoUserFound.pageDescriptionText"></bb-content-html>
    <bb-content-html [html]="loginWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html>
  </div>
  <div class="text-center">
    <button bbButton type="button" aria-label="tryAgainButton" [className]="'wss-button btn-full-width'"
      (click)="navigateToForgotUsername()"
      test_id="tryAgainButtonText">{{loginWidgetItem.forgotUserNameNoUserFound.tryAgainButtonText}}
    </button>
    <button bbButton type="button" aria-label="loginButton" [className]="'wss-button-secondary btn-full-width'"
      (click)="navigateToLogin()"
      test_id="loginButtonText">{{loginWidgetItem.forgotUserNameNoUserFound.loginButtonText}}
    </button>
  </div>
</div>