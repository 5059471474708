import { Injectable } from '@angular/core';
import { Observable, of} from 'rxjs';
import {User} from "../user";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, tap} from "rxjs/operators";
import {UserSearchRequest} from '../user-search-request';
import { MessageService } from '../../../service/message.service';

@Injectable({providedIn: 'root'})
export class UserSearchService {
  private userSearchUrl = 'api/users';
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  }

  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {}

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.userSearchUrl}`)
      .pipe(
        tap(_ => {
          // this.log(`fetched users: ${userSearchRequest}`);
        }),
        catchError(this.handleError<User[]>('searchUsers',[]))
      );
  }

  private handleError<T>(operation = 'operation', result?: T ) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }


  private log(message: string) {
    this.messageService.add(`UserSearchService: ${message}`);
  }

}
