import { Injectable } from '@angular/core';
import { isNotEmpOrNullOrUndef } from '../common/util/util';

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  constructor() {}
  public isAuthRegister(): boolean {
    const token = sessionStorage.getItem('tokenReqRegister');
    if (isNotEmpOrNullOrUndef(token)) {
      return true;
    } else {
      return false;
    }    
  }

  public isloggedIn(): boolean {
    if (localStorage['loggedIn'] !== 'true') {
      return false;
    } else {
      return true;
    }    
  }
}