import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MessageService } from '../../../../service/message.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterService } from "@backbase/foundation-ang/core";
import { DatastoreService } from '../../../../service/datastore.service';
import { PaymentHolidayService } from '../../services/payment-holiday.service';
import { HPOptions, PaymentHolidayWidgetItem } from '@wss/model/payment-holiday-widget/payment-holiday-widget';
import { Observable, Subject } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hp-options',
    templateUrl: './hp-options.component.html',
    providers: [ContentService]
})

export class HPOptionsComponent implements OnInit, OnDestroy {

    @Output("showError") showError = new EventEmitter<number>();
    @Input("currentMonthly") currentMonthly: any;
    @Input("hpOptions") hpOptions: HPOptions = new HPOptions();

    amountForm!: FormGroup;
    quoteCalculated: boolean = false;
    lowVehicleAge: boolean = false;
    errorTypes: string[] = ['paymenttoolow', 'amounttoolow', 'amounttohigh'];
    error: string = "";
    tempCounter: number = 0;
    quote: any = {};
    accountDetails: any = {};
    readonly destroy$ = new Subject();

    paymentHolidayWidgetItem$: Observable<PaymentHolidayWidgetItem | undefined> = this.bbContentService.getContent<PaymentHolidayWidgetItem>('paymentHolidayWidgetItem');
    // paymentHolidayWidgetItem: PaymentHolidayWidgetItem = new PaymentHolidayWidgetItem();


    constructor(
        private router: RouterService,
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private datastore: DatastoreService,
        private paymentHolidayService: PaymentHolidayService,
        private changeDetectorRef: ChangeDetectorRef,
        private readonly bbContentService: ContentService) { }

    ngOnInit() {
        this.accountDetails = this.datastore.getAccountDetails();
        this.amountForm = this.formBuilder.group({
            amount: ['', Validators.required]
        });
        this.handleErrors();
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    caculateModagQuote() {
        this.paymentHolidayService.caculateModagQuote(this.amountForm.value.amount, null).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
            if (res
                && res.modag_quote_response
                && res.modag_quote_response.modag_quotes
                && res.modag_quote_response.modag_quotes.length) {

                this.quote = res.modag_quote_response.modag_quotes[0];
                this.quote.new_rental_sets.map((rentalSet: any) => {
                    rentalSet.new_number_of_rentals = parseInt(rentalSet.new_number_of_rentals);
                });

                let paymentTooLowFlag = this.checkIfPaymentTooLowError();
                if (paymentTooLowFlag) {
                    this.lowVehicleAge = true;
                    this.error = this.errorTypes[1];
                    this.quoteCalculated = false;
                }
                else {
                    this.quoteCalculated = true;
                    this.lowVehicleAge = false;
                }
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    checkIfPaymentTooLowError() {
        let newNoOfRentals = this.quote?.new_rental_sets[1]?.new_number_of_rentals + this.quote?.new_rental_sets[0]?.new_number_of_rentals;
        if (newNoOfRentals > (parseInt(this.accountDetails.num_payments_left) + 24)) {
            return true;
        }
        else {
            return false;
        }
    }

    calculateQuote() {
        if (parseFloat(this.amountForm.value.amount) > this.currentMonthly) {
            this.lowVehicleAge = true;
            this.error = this.errorTypes[2];
            this.quoteCalculated = false;
            this.changeDetectorRef.detectChanges();
        }
        else {
            this.caculateModagQuote();
        }
    }

    handleErrors() {
        this.messageService.getServiceError().pipe(takeUntil(this.destroy$)).subscribe((error: any) => {
            if (error && error.message === 'AGE OF VEHICLE EXCEEDS MAX LIMIT') {
                this.lowVehicleAge = true;
                this.error = this.errorTypes[0];
                this.quoteCalculated = false;
            }
            else {
                this.showError.emit(error.status);
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    finalizeQuote() {
        this.datastore.updateBureauCallFailCount(0);
        this.datastore.setSelectedTermExtensionQuote(this.quote);
        this.datastore.setRequestedRental(this.amountForm.value.amount);
        this.router.navigate(['finalize-agreement']);
    }

    // goBack(){
    //     this.router.navigate(['your-options']);
    // }
}