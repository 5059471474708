<div class="payment-holiday-container" *ngIf="paymentHolidayWidgetItem$ | async as paymentHolidayWidgetItem">
    <div class="triage-questions-wrapper" *ngIf="triageQuestionsAllowed">
        <div class="container-row" *ngIf="serviceError">
            <bb-wss-banner-message></bb-wss-banner-message>
        </div>
        <div class="triage">
            <div class="container-column" *ngIf="currentStep === 1">
                <span class="text-center" test_id="question1">
                    <bb-content-html [html]="paymentHolidayWidgetItem.triageQuestions.question1"></bb-content-html>
                </span>
                <button tabindex="0" role="button" class="wss-button-secondary yes" (click)="triageOptionSelect('Can pay')" test_id="question1Option1">{{paymentHolidayWidgetItem.triageQuestions.question1Option1}}</button>
                <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Cannot pay')" test_id="question1Option2">{{paymentHolidayWidgetItem.triageQuestions.question1Option2}}</button>
            </div>
            <div class="container-column" *ngIf="currentStep === 2">
                <span class="text-center" test_id="question2">
                    <bb-content-html [html]="paymentHolidayWidgetItem.triageQuestions.question2"></bb-content-html>
                </span>
                <button tabindex="0" role="button" class="wss-button-secondary yes" (click)="triageOptionSelect('Employed')" test_id="question2Option1">{{paymentHolidayWidgetItem.triageQuestions.question2Option1}}</button>
                <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Furloughed')" test_id="question2Option2">{{paymentHolidayWidgetItem.triageQuestions.question2Option2}}</button>
                <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Self-employed')" test_id="question2Option3">{{paymentHolidayWidgetItem.triageQuestions.question2Option3}}</button>
                <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Unemployed')" test_id="question2Option4">{{paymentHolidayWidgetItem.triageQuestions.question2Option4}}</button>
                <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Retired')" test_id="question2Option5">{{paymentHolidayWidgetItem.triageQuestions.question2Option5}}</button>
            </div>
            <div class="container-column" *ngIf="currentStep === 3">
                <span class="text-center" test_id="question3">
                    <bb-content-html [html]="paymentHolidayWidgetItem.triageQuestions.question3"></bb-content-html>
                </span>
                <button tabindex="0" role="button" class="wss-button-secondary yes" (click)="triageOptionSelect('No impact')" test_id="question3Option1">{{paymentHolidayWidgetItem.triageQuestions.question3Option1}}</button>
                <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Some impact')" test_id="question3Option2">{{paymentHolidayWidgetItem.triageQuestions.question3Option2}}</button>
                <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Big impact')" test_id="question3Option3">{{paymentHolidayWidgetItem.triageQuestions.question3Option3}}</button>
                <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('No income')" test_id="question3Option4">{{paymentHolidayWidgetItem.triageQuestions.question3Option4}}</button>
            </div>
        </div>
    </div>

    <div class="container triage-warning-content warning-container"
        *ngIf="!triageQuestionsAllowed" aria-describedby="warningPaymentInfo">
        <div class="triage-warning-icon-div">
            <span class="wss-warning-icon warning-icon" alt="Warning Icon"></span>
        </div>
        <div>
            <strong class="triage-warning-info margin-top-20" test_id="triageQuestionsUntimeWarningLabelText">
                <bb-content-html [html]="paymentHolidayWidgetItem.triageQuestions.triageQuestionsUntimeWarningLabelText">
                </bb-content-html>
            </strong>
        </div>
        <div>
            <span class="triage-warning-info" test_id="triageQuestionsUntimeWarningInfoText">
                <bb-content-html [html]="paymentHolidayWidgetItem.triageQuestions.triageQuestionsUntimeWarningInfoText">
                </bb-content-html>
            </span>
        </div>
        <div class="container-row content-center back-home-button">
            <button role="button" class="wss-button btn-width warn-btn-dif-view button-width-padding"
                test_id="make_payment_id" (click)="backToHomeClick()"
                title="{{paymentHolidayWidgetItem.triageQuestions.triageQuestionsBackToHomeButtonText}}">
                {{paymentHolidayWidgetItem.triageQuestions.triageQuestionsBackToHomeButtonText}}
            </button>
        </div>
    </div>
</div>

<!-- <div class="payment-holiday-container">
    <div class="container-row" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div class="triage">
        <div class="container-column" *ngIf="currentStep === 1">
            <span class="text-center text-semibold">{{content[0]?.canumake}}?</span>
            <span class="text-center">{{content[0]?.forexample}}.</span>
            <button tabindex="0" role="button" class="wss-button-secondary yes" (click)="triageOptionSelect('Can pay')">{{content[0]?.ican}}</button>
            <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Cannot pay')">{{content[0]?.icannot}}</button>
        </div>
        <div class="container-column" *ngIf="currentStep === 2">
            <span class="text-center text-semibold">{{content[0]?.whtempstatus}}?</span>
            <button tabindex="0" role="button" class="wss-button-secondary yes" (click)="triageOptionSelect('Employed')">{{content[0]?.employed}}</button>
            <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Furloughed')">{{content[0]?.furloughed}}</button>
            <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Self-employed')">{{content[0]?.selfemployed}}</button>
            <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Unemployed')">{{content[0]?.unemployed}}</button>
            <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Retired')">{{content[0]?.retired}}</button>
        </div>
        <div class="container-column" *ngIf="currentStep === 3">
            <span class="text-center text-semibold">{{content[0]?.covidimpact}}?</span>
            <button tabindex="0" role="button" class="wss-button-secondary yes" (click)="triageOptionSelect('No impact')">{{content[0]?.noimpact}}</button>
            <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Some impact')">{{content[0]?.someimpact}}</button>
            <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('Big impact')">{{content[0]?.bigimpact}}</button>
            <button tabindex="0" role="button" class="wss-button-secondary no" (click)="triageOptionSelect('No income')">{{content[0]?.noincome}}</button>
        </div>
    </div>
</div> -->