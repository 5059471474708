<div class="container-column" *ngIf="headerContentWidgetItem$ | async as headerWidgetItem">
  <div class="pb-40px">
    <div class="user-contact-details-wrapper">
      <div class="title-header">
        <span class="section-label-title" test_id="update_contact_lable">
          {{headerWidgetItem?.updateContacts?.pageTitle}}
        </span>
        <hr />
        <div *ngIf="showForm">  
          <span class="para-inline contact-number-text" test_id="uc_pageWarnig">
            <span class="wss-additonal-info-icon additonal-info-icon-style"></span>
            <bb-content-html [html]="headerWidgetItem.updateContacts.contactPageWarningInfo"></bb-content-html>
            <span *ngIf="headerWidgetItem.updateContacts.contactPageWarningInfoPartTwo">
              <bb-content-html [html]="headerWidgetItem.brandContactDetails.portalName"></bb-content-html>
              <bb-content-html [html]="headerWidgetItem.updateContacts.contactPageWarningInfoPartTwo"></bb-content-html>
            </span>
          </span>
        </div>

        <!-- <div class="container-row mt-3">
          <span class="wss-additonal-info-icon"></span>
          <span class="para-inline" class="pull-item-right note">
            <bb-content-html [html]="headerWidgetItem.updateContacts.contactPageWarningInfo"></bb-content-html>
          </span>
        </div> -->
      </div>
      <form [formGroup]="contactForm" *ngIf="showForm" class="mt-30px">
  <div class="form-group input-field">
          <label for="phonenum" test_id="uc_phonenum">
            {{headerWidgetItem?.updateContacts?.mobileLabel}}
          </label>
          <input type="text" class="form-control" id="phonenum" name="phone" formControlName="mobile_phone_number"
            aria-describedby="mobileNumber" placeholder="Enter mobile number" maxlength="11" minlength="10" pattern="[0-9]*"
            required="true" accesskey="m" data-cs-mask>
          <div class="error-text"
            *ngIf="contactForm.controls.mobile_phone_number.dirty && ((!
            (contactForm.controls.work_phone_number.valid || contactForm.controls?.mobile_phone_number?.valid ||
             contactForm.controls.home_phone_number.valid))) && (contactForm.controls?.work_phone_number?.valid ||
              contactForm.controls.home_phone_number.valid) && !contactForm.controls.mobile_phone_number?.errors?.pattern">
            <bb-content-html [html]="headerWidgetItem.updateContacts.mobileErrorMessage"></bb-content-html>
          </div>
          <div class="error-text"
            *ngIf="(contactForm.controls.mobile_phone_number?.errors?.pattern || contactForm.controls.mobile_phone_number?.errors?.minlength)">
            <bb-content-html [html]="headerWidgetItem.updateContacts.mobileErrorMessage"></bb-content-html>
          </div>
          <!-- <div class="error-text"
            *ngIf="contactForm.controls.mobile_phone_number.dirty && ((
            (!contactForm.controls.work_phone_number.valid && !contactForm.controls?.mobile_phone_number?.valid &&
             !contactForm.controls.home_phone_number.valid))) && !contactForm.controls.mobile_phone_number?.errors?.pattern && !contactForm.controls.mobile_phone_number?.errors?.minlength">
            <bb-content-html [html]="headerWidgetItem.updateContacts.atleastErrorMessage"></bb-content-html>
          </div> -->
        </div>
        <div class="form-group input-field">
          <label for="worknum" test_id="uc_phonenum">
            {{headerWidgetItem?.updateContacts?.homeLabel}}
          </label>
          <input type="text" class="form-control" id="homenum" formControlName="home_phone_number"
            aria-describedby="homeNumber" placeholder="Enter home telephone number" pattern="[0-9]*" maxlength="11" minlength="10"
            required="true" accesskey="h" data-cs-mask>
          <div class="error-text" *ngIf="contactForm.controls.home_phone_number.dirty && ((!
            (contactForm.controls.work_phone_number.valid || contactForm.controls?.mobile_phone_number?.valid ||
             contactForm.controls.home_phone_number.valid))) && !contactForm.controls.home_phone_number?.errors?.pattern && !(!contactForm.controls.mobile_phone_number.valid && !contactForm.controls?.home_phone_number?.valid &&
              !contactForm.controls.work_phone_number.valid)">
           <bb-content-html [html]="headerWidgetItem.updateContacts.homeErrorMessage"></bb-content-html>
          </div>
          <div class="error-text"
            *ngIf="(contactForm.controls.home_phone_number?.errors?.pattern ||contactForm.controls.home_phone_number?.errors?.minlength )">
            <bb-content-html [html]="headerWidgetItem.updateContacts.homeErrorMessage"></bb-content-html>
          </div>
        </div>
        <div class="form-group input-field">
          <label for="worknum" test_id="uc_worknum">
            {{headerWidgetItem?.updateContacts?.workLabel}}
          </label>
          <input type="text" class="form-control" id="worknum" formControlName="work_phone_number"
            aria-describedby="workNumber" placeholder="Enter work number" pattern="[0-9]*" maxlength="11" minlength="10"
            required="true" accesskey="w" data-cs-mask>
          <div class="error-text" *ngIf="contactForm.controls.work_phone_number.dirty && ((!
            (contactForm.controls.work_phone_number.valid || contactForm.controls?.mobile_phone_number?.valid ||
             contactForm.controls.home_phone_number.valid))) && !contactForm.controls.work_phone_number?.errors?.pattern && !(!contactForm.controls.work_phone_number.valid && !contactForm.controls?.mobile_phone_number?.valid &&
              !contactForm.controls.home_phone_number.valid)">
        <bb-content-html [html]="headerWidgetItem.updateContacts.workErrorMessage"></bb-content-html>

          </div>
          <div class="error-text"
            *ngIf="(contactForm.controls.work_phone_number?.errors?.pattern || contactForm.controls.work_phone_number?.errors?.minlength)">
            <bb-content-html [html]="headerWidgetItem.updateContacts.workErrorMessage"></bb-content-html>
          </div>
        </div>
        <div class="links-container">
          <div class="d-flex justify-content-left contact-details-form-btn">
            <!-- <button type="button" class=" wss-button wss-button-update-form" title="headerWidgetItem?.updateContacts?.buttoneLabel" test_id="uc_update"
              (click)="onSubmit()" [disabled]="((!
              (contactForm.controls?.work_phone_number?.valid || contactForm.controls?.mobile_phone_number?.valid ||
               contactForm.controls?.home_phone_number?.valid)) || ( contactForm.controls.work_phone_number?.errors?.minlength ||
               contactForm.controls.home_phone_number?.errors?.minlength || contactForm.controls.mobile_phone_number?.errors?.minlength
                ) 
                || ((contactForm.controls?.work_phone_number?.value?.length > 0 &&
                (!contactForm.controls?.work_phone_number?.valid) ) || (contactForm.controls?.home_phone_number?.value?.length > 0 &&
                (!contactForm.controls?.home_phone_number?.valid) ) || (contactForm.controls?.mobile_phone_number?.value?.length > 0 &&
                (!contactForm.controls?.mobile_phone_number?.valid) )))
              ">
              {{headerWidgetItem?.updateContacts?.buttoneLabel}}
            </button> -->
            <button id="update-contact-btn" type="button" class=" wss-button wss-button-update-form" title="headerWidgetItem?.updateContacts?.buttoneLabel" test_id="uc_update"
              (click)="onSubmit()" [disabled]="(contactForm.controls.mobile_phone_number?.errors?.pattern || contactForm.controls.mobile_phone_number?.errors?.minlength)
              || (contactForm.controls.home_phone_number?.errors?.pattern || contactForm.controls.home_phone_number?.errors?.minlength)
              || (contactForm.controls.work_phone_number?.errors?.pattern || contactForm.controls.work_phone_number?.errors?.minlength)">
              {{headerWidgetItem?.updateContacts?.buttoneLabel}}
            </button>
          </div>
        </div>
      </form>
      <div class="links-container" *ngIf="showForm">
        <div class="d-flex justify-content-center help-blink">
          <bb-content-html [html]="headerWidgetItem.userAddress.needHelpLink"></bb-content-html>
        </div>
      </div>
      <div class="change-password-success-container result-container" *ngIf="successStatus">
        <div class="request-result-section">
          <div class="trophy-icon-div">
            <span class="success_icon success-icon-style" title="icon-success"></span>
          </div>
          <div class="d-flex justify-content-center request-result-text">
            <b>
              <bb-content-html [html]="headerWidgetItem.updateContacts.successMessage"></bb-content-html> 
            </b>
          </div>
          <div class="d-flex justify-content-center result-info-text">
        <bb-content-html [html]="headerWidgetItem.updateContacts.successinfo"></bb-content-html>
          </div>
          <div class="d-flex justify-content-center ">
            <button type="button" class="wss-button wss-button-update success-back-button" test_id="uc_backtohome"
              (click)="backToMyAccountClick()" title="{{headerWidgetItem?.updateContacts?.backToHomeLabel}}">
              {{headerWidgetItem?.updateContacts?.backToHomeLabel}}
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="successStatus">
        <div class="d-flex justify-content-center help-blink">
          <bb-content-html [html]="headerWidgetItem.userAddress.needHelpLink"></bb-content-html>
        </div>
      </div>
    </div>
  </div>
</div>