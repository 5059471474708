<div class="reg-container" *ngIf="registrationWidgetItem$ | async as registrationWidgetItem">
  <div class="reg-row components-avatars-row-height" role="row">
    <div class="components-avatars-initials label">
      <div class="label">{{initials}}</div>
    </div>
    <div class="group-10">
      <p class="welcome-first-name" test_id="welcomeText">{{registrationWidgetItem.userDetail.welcomeText}}, {{firstname}}</p>
      <div class="button-text contact-number-text" test_id="getInTouchText">
        <span><bb-content-html [html]="registrationWidgetItem.userDetail.getInTouchText"></bb-content-html></span>
        <span *ngIf="registrationWidgetItem.brandContactDetails">
          <bb-content-html [html]="registrationWidgetItem.brandContactDetails.brandWiseContactNumber"></bb-content-html></span>
      </div>
    </div>
  </div>
  <div class="reg-row" role="row" *ngIf="!mobileValid" test_id="helpToConfirmYourIdentity">
    <bb-content-html [html]="registrationWidgetItem.userDetail.helpToConfirmYourIdentity"></bb-content-html>
  </div>
  <div class="reg-row" role="row" *ngIf="mobileValid" test_id="authenticateHelpText">
    <bb-content-html [html]="registrationWidgetItem.userDetail.authenticateHelpText"></bb-content-html>
  </div>
  <div id="verify-by-sms" class="reg-row group-11-row-height verify-by-sms-margin-top clickable" (click)="getVerify('OTP','verify-sms')" *ngIf="mobileValid">
    <div class="group-11 rectangle-2-round-border">
      <div class="rectangle-copy"></div>
      <div class="rectangle-1-icon">
        <!-- <svg class="checked-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="#394D98" [attr.d]="svgSmsIcon" />
        </svg> -->
        <span class="wss-text-msg-icon"></span>
      </div>
      <div class="group-11-1 w-50">
        <div class="security-questions" test_id="verifyByTextMessageText">
          <bb-content-html [html]="registrationWidgetItem.userDetail.verifyByTextMessageText"></bb-content-html>
        </div>
        <div class="well-ask-some-quest" test_id="weWillSendYouCodeText">
          <bb-content-html [html]="registrationWidgetItem.userDetail.weWillSendYouCodeText"></bb-content-html>
        </div>
      </div>
      <div class="margin-all-auto">
        <span class="material-icons cherovon-right-material">chevron_right</span>
      </div>
    </div>
  </div>
  <div id="verify-by-security" class="reg-row group-11-row-height margin-top-security-questions clickable" (click)="getVerify('IIQ','security-intro')">
    <div class="group-11 rectangle-2-round-border">
      <div class="rectangle-copy"></div>
      <div class="rectangle-1-icon">
        <!-- <svg class="checked-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="#394D98" [attr.d]="svgQustIcon" />
        </svg> -->
        <span class="wss-security-que-icon"></span>
      </div>
      <div class="group-11-1 w-50">
        <div class="security-questions" test_id="verifyBySecurityQuestionsText">
          <bb-content-html [html]="registrationWidgetItem.userDetail.verifyBySecurityQuestionsText"></bb-content-html>
        </div>
        <div class="well-ask-some-quest" test_id="weWillAskSecurityQuestionsText">
          <bb-content-html [html]="registrationWidgetItem.userDetail.weWillAskSecurityQuestionsText"></bb-content-html>
        </div>
      </div>
      <div class="margin-all-auto">
        <span class="material-icons cherovon-right-material">chevron_right</span>
      </div>
    </div>
  </div>
  <div class="reg-row group-11-row-height">
    <div class="margin-all-auto need-help-link text-center mb-25px" test_id="helpSupportVerifyingAccountText">
      <bb-content-html [html]="registrationWidgetItem.userDetail.helpSupportVerifyingAccountText"></bb-content-html>
    </div>
  </div>
</div>
