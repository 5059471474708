import {Component, OnInit, OnDestroy} from "@angular/core";
import {ItemModel} from "@backbase/foundation-ang/core";
import {RegistrationStoreModelService} from "../../../../registration-store/src/registration-store-model.service";
import {ContentService as OldContentService} from "../../../../service/content.service";
import {ContentData, appConfig } from '../../../../config/wss-app-constants';
import {Observable, Subject} from "rxjs";
import {takeUntil} from 'rxjs/operators';
import { ContentService } from "@backbase/universal-ang/content";
import { RegistrationWidgetItem } from "@wss/model/registrationToken";
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  templateUrl: './created-credentials.component.html',
  selector: 'bb-ws-user-credential-created',
  providers: [ContentService]
})
export class CreatedCredentialsComponent implements OnInit, OnDestroy {
  
  readonly destroy$ = new Subject();

  registrationWidgetItem$: Observable<RegistrationWidgetItem | undefined> = this.bbContentService.getContent<RegistrationWidgetItem>('registrationWidgetItem');

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Registration',
        JourneyStepName: 'Credential Created',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();  
    this.destroy$.complete();    
  }

  constructor(
    private itemModel: ItemModel,
    private registrationService: RegistrationStoreModelService,
    private contentService: OldContentService,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService
  ) {
    this.itemModel.property('credentialCreated').pipe(takeUntil(this.destroy$)).subscribe(value => this.registrationService.updateTitle(value as string));
    this.registrationService.updatedStepper(4);
  }

  submit() {
    location.href = appConfig.loginURL;
  }

}
