<section class="cta-screen text-center col-md-7 col-12 p-40px" *ngIf="brWidgetItem$ | async as brWidgetItem">
    <div class="get-in-touch-icon mt-40px"></div>
    <div class="font-size-18 mb-25px">
        <bb-content-html [html]="brWidgetItem.balloonRefinanceCtaExpiredEocPeriod.title"></bb-content-html>
    </div>
    <div class="mb-30px">
        <bb-content-html [html]="brWidgetItem.balloonRefinanceCtaExpiredEocPeriod.bodyText"></bb-content-html>
    </div>
    <div class="d-flex flex-column align-items-center">
        <button class="wss-button m-0 d-flex align-items-center justify-content-center" test_id="primaryButton" (click)="callButtonHandler(brWidgetItem.brandContactDetails.brandWiseCfaNumberWithoutLink)">
            <span class="material-icons-outlined mr-1">call</span>
            <bb-content-html [html]="isMotorbike ? brWidgetItem.brandContactDetails.brandWiseBikeNumberWithoutLink : brWidgetItem.brandContactDetails.brandWiseNumberWithoutLink"></bb-content-html>
        </button>
        <button class="wss-button-secondary mb-0" *ngIf="brWidgetItem.balloonRefinanceCtaExpiredEocPeriod.secondaryButtonText">{{brWidgetItem.balloonRefinanceCtaExpiredEocPeriod.secondaryButtonText}}</button>
        <div class="mt-20px">
            <bb-content-html [html]="brWidgetItem.balloonRefinanceCtaExpiredEocPeriod.linkText"></bb-content-html>
        </div>
    </div>
</section>