<div class="d-flex common-header" test_id="commonheaderContent">
    <div class="mr-auto p-2 logo logo-login-style"></div>
    <div *ngIf="showMailIcon"
        [ngClass]="msgUnreadCount > 0? 'div-email-icon clickable': 'div-email-icon-empty clickable'"
        (click)="toInboxMsg()">
        <div class="d-flex" *ngIf="msgUnreadCount > 0">
            <span class="material-icons wss-email-icon">
                mail_outline
            </span>
            <div class="custom-badge">
                <span>{{msgUnreadCount}}</span>
            </div>
        </div>
        <span class="material-icons wss-email-icon" *ngIf="msgUnreadCount === 0">
            mail_outline
        </span>
    </div>
    <div class="p-2 short-name">
        <div id="popup-show" class="header-circle material-icons-outlined {{open? 'active': ''}}"
            (click)="toggle(open)">
            person
        </div>
    </div>
</div>

<div class="profile-wrapper overlay" *ngIf="open" id="commonheaderPopupContent" test_id="commonheaderPopupContent">
    <div class="common-header-popup-top" id="common-header-popup-top">
        <div class="d-flex justify-content-center">
            <div class="circle-bg text-center"><span class="circle-name" data-cs-mask>{{ personalDetails?.shortName }}</span>
            </div>
        </div>
        <div class="profile-user-name caps-title" test_id="commonheaderProfileContent" data-cs-mask>{{ personalDetails?.fullname }}
        </div>
    </div>

    <div class="profile-details">

        <div class="menu-list">
            <div *ngIf="!isArchive">
                <div class="menu-list-item" id="editIcon" (click)="navigatePage('header/update-contact')">
                    <div class="row">
                        <div class="col-1">
                            <span class="material-icons-outlined">call</span>
                        </div>
                        <div class="col-9">
                            <span> {{headerWidgetItem?.userDetailsprofile?.phoneNumberLabel}} </span>
                        </div>
                        <div class="col-1">
                            <span id="arrow-right-icon" class="material-icons">keyboard_arrow_right</span>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!isArchive">
                <div class="menu-list-item" id="editIcon" (click)="navigatePage('header/update-address')">
                    <div class="row">
                        <div class="col-1">
                            <span class="material-icons-outlined">home</span>
                        </div>
                        <div class="col-9">
                            <span> {{headerWidgetItem?.userDetailsprofile?.addressLabel}} </span>
                        </div>
                        <div class="col-1">
                            <span id="arrow-right-icon" class="material-icons">keyboard_arrow_right</span>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="menu-list-item" id="editIcon" (click)="navigatePage('header/update-email')">
                    <div class="row">
                        <div class="col-1">
                            <span class="material-icons-outlined">alternate_email</span>
                        </div>
                        <div class="col-9">
                            <span> {{headerWidgetItem?.userDetailsprofile?.emailAddressLabel}} </span>
                        </div>
                        <div class="col-1">
                            <span id="arrow-right-icon" class="material-icons">keyboard_arrow_right</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="menu-list-item" id="editIcon" (click)="navigatePage('header/change-password')">
                <div class="row">
                    <div class="col-1">
                        <span class="material-icons-outlined">vpn_key</span>
                    </div>
                    <div class="col-9">
                        <span> {{headerWidgetItem?.userDetailsprofile?.passwordLabel}} </span>
                    </div>
                    <div class="col-1">
                        <span id="arrow-right-icon" class="material-icons">keyboard_arrow_right</span>
                    </div>
                </div>
            </div>

            <div *ngIf="!isArchive && isContactPreferenceFeatureOn">
                <div class="menu-list-item" id="editIcon" (click)="navigatePage('contact-preference/landing')">
                    <div class="row">
                        <div class="col-1">
                            <span class="material-icons-outlined">message</span>
                        </div>
                        <div class="col-9">
                            <span> {{headerWidgetItem?.userDetailsprofile?.communicationPreferencesLabel}} </span>
                        </div>
                        <div class="col-1">
                            <span id="arrow-right-icon" class="material-icons">keyboard_arrow_right</span>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="(!isAccountCompleted && showMailIcon && !isArchive)">
                <div class="menu-list-item" id="editIcon" (click)="navigatePage('/digital-inbox/manage-preference')">
                    <div class="row">
                        <div class="col-1">
                            <span class="material-icons-outlined">email</span>
                        </div>
                        <div class="col-9">
                            <span>
                                {{headerWidgetItem?.userDetailsprofile?.paperlessPreferencesLabel}}
                            </span>
                        </div>
                        <div class="col-1">
                            <span id="arrow-right-icon" class="material-icons">keyboard_arrow_right</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row login-button-h" test_id="commonheaderBtnContent">
                <button type="button" class="wss-button-header" id="logout" (click)="logout()">
                    <mat-icon class="align-middle">exit_to_app</mat-icon>
                    {{headerWidgetItem?.userDetailsprofile?.logOffbuttonText}}
                </button>
            </div>

        </div>
    </div>
    <ng-template #content let-modal>
        <div class="container-column popup-modal" *ngIf="logoutPopupContent">
            <div class="container-row modal-header-c" test_id="popupClose">
                <span class="material-icons" (click)="modal.dismiss('Cross click');">clear</span>
            </div>
            <div class="container-column modal-body-c" test_id="unsavedChangesText" id="unsavedChangesText">
                <bb-content-html [html]="logoutPopupContent.unsavedChangesText">
                </bb-content-html>
            </div>
            <div class="container-row modal-footer-c" test_id="popupButtons">
                <button class="wss-button" id="logoutPopupLeaveButton"
                    (click)="leave();modal.dismiss('Cross click');">{{logoutPopupContent.leaveButtonText}}</button>
                <button class="wss-button-secondary" id="logoutPopupStayButton"
                    (click)="modal.dismiss('Cross click')">{{logoutPopupContent.stayBackButtonText}}</button>
            </div>
        </div>
    </ng-template>
</div>