import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RoutableWidget, RouterService } from "@backbase/foundation-ang/core";
import { PaymentHolidayLandingComponent }  from './components/payment-holiday-landing/payment-holiday-landing.component';
import { TriageQuestionComponent } from './components/triage-question/triage-question.component';
import { CohortFirstComponent } from './components/cohort-first/cohort-first.component';
import { CohortThirdComponent } from './components/cohort-third/cohort-third.component';
import { YourOptionsComponent } from './components/your-options/your-options.component';
import { TermExtensionLandingComponent } from './components/term-extension-landing/term-extension-landing.component';
import { CurrentAgreementComponent } from './components/current-agreement/current-agreement.component';
import { FinalizeAgreementComponent } from './components/finalize-agreement/finalize-agreement.component';
import { TermExtensionDeclinedComponent } from './components/term-extension-declined/term-extension-declined.component';
import { TermExtensionResultComponent } from './components/term-extension-result/term-extension-result.component';
import { DatastoreService } from '../../service/datastore.service';
import { MessageService } from '../../service/message.service';


@Component({
  selector: 'bb-payment-holiday-widget',
  templateUrl: 'payment-holiday-widget.component.html',
  providers: [RouterService]
})

@RoutableWidget({
  routes: [
    { path: '', redirectTo: 'landing', pathMatch: 'full' },
    { path: 'landing', component: PaymentHolidayLandingComponent },
    { path: 'triage-questions', component: TriageQuestionComponent },
    { path: 'cohort-1', component: CohortFirstComponent },
    { path: 'cohort-3', component: CohortThirdComponent },
    { path: 'your-options', component: YourOptionsComponent },
    { path: 'term-extension', component: TermExtensionLandingComponent },
    { path: 'current-agreement', component: CurrentAgreementComponent },
    { path: 'finalize-agreement', component: FinalizeAgreementComponent },
    { path: 'term-extension-declined', component: TermExtensionDeclinedComponent },
    { path: 'term-extension-result', component: TermExtensionResultComponent }
  ],
})
export class PaymentHolidayWidgetComponent implements OnInit {
  currentStep: number = 0;
  constructor(
    private datastoreService: DatastoreService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.datastoreService.sessionInvalid();
    this.updateStepper();
  }

  updateStepper(){
    this.messageService.getCurrentStep().subscribe(step => {
      this.currentStep = step;
      this.datastoreService.setStepperStep(step);
      this.changeDetectorRef.detectChanges();
    });
  }

}
