<div *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <div class="login-container">
    <bb-wss-common-icon [className]="'success-icon'" [imgText]="'Success'"></bb-wss-common-icon>
    <div class="reg-row text-center" role="row" test_id="thankYouText">
      <bb-content-html [html]="loginWidgetItem.userNameSuccessfulChanged.thankYouText"></bb-content-html>
    </div>
    <div class="reg-row text-center" role="row" test_id="emailRetrieveInfo">
      <span class="para-inline">
        <bb-content-html [html]="loginWidgetItem.userNameSuccessfulChanged.emailAddressInfoText"></bb-content-html>
        <br>
        <strong>{{userAlias}}</strong>
      </span>
    </div>
    <div class="text-center">
      <button id="username-successful-btn" bbButton type="button" aria-label="loginButton" class="wss-button btn-full-width btn-no-bottom-margin" (click)="submit()"
        test_id="loginBtnText">
        {{loginWidgetItem.userNameSuccessfulChanged.loginButtonText}}
      </button>
    </div>
  </div>
  <div class="register-link no-para-margin justify-content-center" id="needHelpLinkContent"
    test_id="loginWidgetItemneedHelpLinkContent">
    <bb-content-html [html]="loginWidgetItem.userNameSuccessfulChanged.needHelpLink"></bb-content-html>
  </div>
</div>