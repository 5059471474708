import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { MessageService } from "@wss/service/message.service";
import { Observable } from "rxjs";

@Injectable()
export class AuthGuard implements CanDeactivate<any> {
    constructor(private router: Router, private messageService: MessageService) { }

    canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot | undefined): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (nextState && nextState.url.indexOf('complaint') > 0) {
            this.messageService.setActiveNav("My account");
            this.router.navigate(['/../myaccount']);
            return false;
        }
        return true;
    }
}