import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { DigitalInboxWidgetComponent } from './digital-inbox-widget.component';
import { ContentModule, ContentWidgetModule } from '@backbase/universal-ang/content';
import { MatExpansionModule } from '@angular/material/expansion';
import { PreferenceLandingComponent } from './components/preference-landing/preference-landing.component';
import { AccountSelectionComponent } from './components/account-selection/account-selection.component';
import { PreferenceResultComponent } from './components/preference-result/preference-result.component';
import { ManagePreferenceComponent } from './components/manage-preference/manage-preference.component';
import { ConfimrPreferenceComponent } from './components/confirm-preference/confirm-preference.component';
import { WSSCommonModule } from '@wss/common/common.module';

@NgModule({
  declarations: [
    DigitalInboxWidgetComponent,
    PreferenceLandingComponent,
    AccountSelectionComponent,
    PreferenceResultComponent,
    ManagePreferenceComponent,
    ConfimrPreferenceComponent
  ],
  imports: [
    CommonModule,
    BackbaseCoreModule.withConfig({
      classMap: { DigitalInboxWidgetComponent }
    }),
    MatExpansionModule,
    ContentWidgetModule,
    ContentModule,
    WSSCommonModule
  ]
})
export class DigitalInboxWidgetModule { }