import {NgModule} from '@angular/core';
import {BackbaseStoreModule, StoreFactory} from '@backbase/foundation-ang/store';
import { Store } from '@ngrx/store';
import { BalloonRefinanceService } from '@wss/balloon-refinance-widget/src/services/balloon-refinance.service';
import { BalloonRefinanceEffects } from './balloonRefinance.effect';

@NgModule({
  declarations: [],
  imports: [
    BackbaseStoreModule
  ],
  providers: [BalloonRefinanceEffects, BalloonRefinanceService, Store]
})
export class BalloonRefinanceStoreModule { }
