<div class="static-pages-header-wrapper" test_id="welcome-header">
    <div class="d-flex header-wrapper-login responsive-header">
      <div class="logo logo-login-style"></div>
      <div *ngIf="(portalName === 'landrover' || portalName === 'jaguar' )" class="logo-text"></div>
    </div>

    <div class="static-pages-widget" *ngIf="privacyPolicyWidgetItem$ | async as privacyPolicyWidgetItem">
        <div class="static-page-container">
            <div class="section-title-container static-page-title">
                <span class="section-title" test_id="cookiesPolicyPageTitle">
                    {{privacyPolicyWidgetItem.pageTitle}}</span>
            </div>
            <div>
                <bb-content-html [html]="privacyPolicyWidgetItem.privacyPolicyIntro"></bb-content-html>
            </div>
            <div class="personal-info-law white-bg-container sp-white-bg-container">
                <bb-content-html [html]="privacyPolicyWidgetItem.personalInfoAndLaw"></bb-content-html>
            </div>
            <div class="who-we-are" id="whoWeAre">
                <bb-content-html [html]="privacyPolicyWidgetItem.whoWeAre"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="how-law-protects pdn-top" id="howLawProtectsYou">
                <bb-content-html [html]="privacyPolicyWidgetItem.howLawProtectsYou"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="serving-customer pdn-top" id="servingYouAsCustomerTitle">
                <bb-content-html [html]="privacyPolicyWidgetItem.servingYouAsCustomerTitle"></bb-content-html>
            </div>
            <div class="serving-customer white-bg-container sp-white-bg-container" id="servingYouAsCustomerContent">
                <bb-content-html [html]="privacyPolicyWidgetItem.servingYouAsCustomerContent"></bb-content-html>
            </div>
            <div class="business-improvement" id="businessImprovementTitle">
                <bb-content-html [html]="privacyPolicyWidgetItem.businessImprovementTitle"></bb-content-html>
            </div>
            <div class="business-improvement white-bg-container sp-white-bg-container" id="businessImprovementContent">
                <bb-content-html [html]="privacyPolicyWidgetItem.businessImprovementContent"></bb-content-html>
            </div>
            <div class="manage-operations" id="managingOperationsTitle">
                <bb-content-html [html]="privacyPolicyWidgetItem.managingOperationsTitle"></bb-content-html>
            </div>
            <div class="manage-operations white-bg-container sp-white-bg-container" id="managingOperationsContent">
                <bb-content-html [html]="privacyPolicyWidgetItem.managingOperationsContent"></bb-content-html>
            </div>
            <div class="business-improvement" id="managingSecurityRiskCrimeTitle">
                <bb-content-html [html]="privacyPolicyWidgetItem.managingSecurityRiskCrimeTitle"></bb-content-html>
            </div>
            <div class="business-improvement white-bg-container sp-white-bg-container" id="managingSecurityRiskCrimeContent">
                <bb-content-html [html]="privacyPolicyWidgetItem.managingSecurityRiskCrimeContent"></bb-content-html>
            </div>
            <div class="business-improvement" id="businessManagementTitle">
                <bb-content-html [html]="privacyPolicyWidgetItem.businessManagementTitle"></bb-content-html>
            </div>
            <div class="business-improvement white-bg-container sp-white-bg-container" id="businessManagementContent">
                <bb-content-html [html]="privacyPolicyWidgetItem.businessManagementContent"></bb-content-html>
            </div>
            <div class="business-improvement" id="processingSpecialCategoriesTitle">
                <bb-content-html [html]="privacyPolicyWidgetItem.processingSpecialCategoriesTitle"></bb-content-html>
            </div>
            <div class="business-improvement white-bg-container sp-white-bg-container" id="processingSpecialCategoriesContent">
                <bb-content-html [html]="privacyPolicyWidgetItem.processingSpecialCategoriesContent"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="groups-personal-info pdn-top" id="groupsPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.groupsPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info pdn-top" id="consentPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.consentPersonalInformation"></bb-content-html>
            </div>
            <div class="what-they-do" id="whatTheyDo">
                <bb-content-html [html]="privacyPolicyWidgetItem.whatTheyDo"></bb-content-html>
            </div>
            <div class="type-personal-info" id="contactPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.contactPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="socioDemographicPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.socioDemographicPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="transactionalPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.transactionalPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="contractualPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.contractualPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="locationalPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.locationalPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="behaviouralPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.behaviouralPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="communicationsPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.communicationsPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="technicalPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.technicalPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="socialRelationshipsPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.socialRelationshipsPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="openDataPublicRecordsPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.openDataPublicRecordsPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="usageDataPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.usageDataPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="documentaryDataPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.documentaryDataPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="specialCategoryDataPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.specialCategoryDataPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="consentsPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.consentsPersonalInformation"></bb-content-html>
            </div>
            <div class="type-personal-info" id="nationalIdentifierPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.nationalIdentifierPersonalInformation"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="collect-personal-info pdn-top" id="whereWeCollectPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.whereWeCollectPersonalInformation"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="howLongWeKeepYourInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.howLongWeKeepYourInformation"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="chooseNotToGivePersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.chooseNotToGivePersonalInformation"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="cookiesAndSimilarTrackingTechnologies">
                <bb-content-html [html]="privacyPolicyWidgetItem.cookiesAndSimilarTrackingTechnologies"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="howToComplain">
                <bb-content-html [html]="privacyPolicyWidgetItem.howToComplain"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="howToWithdrawConsent">
                <bb-content-html [html]="privacyPolicyWidgetItem.howToWithdrawConsent"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="lettingKnowIfPersonalInformationIsIncorrect">
                <bb-content-html [html]="privacyPolicyWidgetItem.lettingKnowIfPersonalInformationIsIncorrect"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="getCopyOfPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.getCopyOfPersonalInformation"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="wantToStopUsingPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.wantToStopUsingPersonalInformation"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="whoWeShareYourPersonalInformation">
                <bb-content-html [html]="privacyPolicyWidgetItem.whoWeShareYourPersonalInformation"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="workoutWhatMarketingYouReceive">
                <bb-content-html [html]="privacyPolicyWidgetItem.workoutWhatMarketingYouReceive"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="yourInformationToMakeAutomatedDecisions">
                <bb-content-html [html]="privacyPolicyWidgetItem.yourInformationToMakeAutomatedDecisions"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="creditReferenceAgencies">
                <bb-content-html [html]="privacyPolicyWidgetItem.creditReferenceAgencies"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="fraudPreventionAgencies">
                <bb-content-html [html]="privacyPolicyWidgetItem.fraudPreventionAgencies"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
            <div class="keep-your-info pdn-top" id="sendingDataOutsideUK">
                <bb-content-html [html]="privacyPolicyWidgetItem.sendingDataOutsideUK"></bb-content-html>
            </div>
            <div class="back-to-top" (click)="backToTop()">
                <span class="material-icons">arrow_upward</span><bb-content-html [html]="privacyPolicyWidgetItem.backToTopText"></bb-content-html>
            </div>
            <hr>
        </div>
    </div>
</div>