<section class="contact-preference-success" *ngIf="contactPreferenceWidgetItem$ | async as contactPreferenceWidgetItem">
    <div class="title-header">
        <span class="section-label-title">
            <bb-content-html [html]="contactPreferenceWidgetItem.contactPreferenceSuccess.pageName">
            </bb-content-html>
        </span>
        <hr />
    </div>

    <div class="contact-preference-card">
        <div class="text-center">
            <div class="success_icon-section">
                <span class="success_icon settings-updated"></span>
            </div>
            <div>
                <h4> 
                    <bb-content-html [html]="contactPreferenceWidgetItem.contactPreferenceSuccess.title">
                    </bb-content-html>  
                </h4>
            </div>
            <div class="sub-title">
                <bb-content-html [html]="contactPreferenceWidgetItem.contactPreferenceSuccess.subTitle1">
                </bb-content-html>
            </div>
            <div>
                <bb-content-html [html]="contactPreferenceWidgetItem.contactPreferenceSuccess.subTitle2">
                </bb-content-html>
            </div>
            <div class="actions">
                <button type="submit" bbButton class="wss-button" (click)="backToHome()" >
                    <bb-content-html [html]="contactPreferenceWidgetItem.contactPreferenceSuccess.buttonText">
                    </bb-content-html>
                </button>
            </div>
        </div>
    </div>
</section>