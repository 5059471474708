<div class="reg-container margin-questions" *ngIf="registrationWidgetItem$ | async as registrationWidgetItem" test_id="pageHeaderText">
  <div class="reg-row" role="alert" *ngIf="serviceError">         
    <bb-wss-banner-message [errorCode]="errorCode"></bb-wss-banner-message>
  </div>
  <bb-content-html [html]="registrationWidgetItem.securityQuestions.pageHeaderText"></bb-content-html>
  <form>
    <div class="question-cont" *ngFor="let question of questionList; let i = index">      
      <span class="title-section-page">
         Question {{i+1}}
      </span>
      <p>
        {{question.question}}
      </p>
      <div class="form-check" *ngFor="let answer of question.answers; let j = index">
        <input class="form-check-input" type="radio" name="{{question.question_id}}" value="{{answer.answer_id}}" (change)="getOptions(question.question_id, answer.answer_id)">
        <label class="form-check-label" for="question.question_id+j">
          {{answer.answer_text}}
        </label>
      </div>
    </div>    
  </form>
  <hr/>
  <div>
    <button id="bb-button-to" bbButton type="button" aria-label="Favourite" (click)="submitAnswer()"
      [className]="'wss-button'" [disabled]="submitBtn" test_id="submitAnswersButtonText">
      {{registrationWidgetItem.securityQuestions.submitAnswersButtonText}}
    </button>
  </div>
  <div class="need-help-link text-center mb-25px" test_id="needHelpLink">
    <bb-content-html [html]="registrationWidgetItem.securityQuestions.needHelpLink"></bb-content-html>
  </div>
</div>
