import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { MatIconModule } from '@angular/material/icon';
import { NgbModalModule, NgbPopoverModule }  from '@ng-bootstrap/ng-bootstrap';
import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule} from '@angular/material/radio';
import { WSSCommonModule } from '../../common/common.module';
import { ContactPreferenceWidgetComponent } from './contact-preference-widget.component';
import { ContactPreferenceLandingComponent } from './components/contact-preference-landing/contact-preference-landing.component';
import { ContactPreferenceSuccessComponent } from './components/contact-preference-success/contact-preference-success.component';
import { ContinueOrExitComponent } from './components/continue-or-exit/continue-or-exit.component';

@NgModule({
  declarations: [
    ContactPreferenceLandingComponent,
    ContactPreferenceWidgetComponent,
    ContactPreferenceSuccessComponent,
    ContinueOrExitComponent
  ],
  imports: [
    CommonModule,
    BackbaseCoreModule.withConfig({
      classMap: { ContactPreferenceWidgetComponent }
    }),
    MatIconModule,
    NgbModalModule,
    NgbPopoverModule,
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule,
    RouterModule.forRoot([], { initialNavigation: 'disabled', useHash: true, relativeLinkResolution: 'legacy' }),
    RouterModule,
    MatExpansionModule,
    FormsModule,
    MatRadioModule,
    ReactiveFormsModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ContactPreferenceWidgetModule { }