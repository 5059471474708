<svg [popoverTrigger]="popover" class="svg-margin-left info-icon-top mt-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
viewBox="0 0 24 24">
    <path fill="#107EB1" [attr.d]="svgInfoIcon" />
</svg>

<ng-template #popover>
    <span class="popover-arrow-up"></span>
    <div [id]="popOverId" class="popover-container d-flex justify-content-between">
        <bb-content-html [html]="popoverContent">
        </bb-content-html>
        <div class="tooplip-close">
            <span (click)="closePopover()" class="material-icons clear-icon"
                [attr.style]="tooltipCloseStyle">clear</span>
        </div>
    </div>    
</ng-template>
