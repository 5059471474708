import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserRepoService} from "../../service/user-repo.service";
import {ItemModel, RouterService} from "@backbase/foundation-ang/core";
import {ContentData} from '../../../../config/wss-app-constants';
import { Observable } from "rxjs";
import { ContentService } from '@backbase/universal-ang/content';
import {LoginWidgetItem} from '../../../../model/login-widget/login-widget';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-wss-check-email',
  templateUrl: './check-email.component.html',
  providers: [ ContentService ]
})

export class CheckEmailComponent implements OnInit {
  submitted = false;

  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>('loginWidgetItem');


  constructor(
    private router: Router,
    private userRepoService: UserRepoService,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
  ) {
    this.userRepoService.updateScreenTitle('Authenticate');
  }   

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Login',
        JourneyStepName: 'Check Email',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
  }

  submit() {
    this.userRepoService.updateScreenTitle('Create An Account');
    this.router.navigate(['/']);
  }

}
