<div *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <div class="login-container">
    <span class="section-label-size" test_id="loginWidgetItemtitleNameContent">
      {{loginWidgetItem.userLogin.titleName}}
    </span>
    <hr />
    <div *ngIf="errorDetails" test_id="loginWidgetItemhelpresetPasswordContent">
      <bb-wss-banner-message [showBannerLink]="true" [bannerLinkText]="loginWidgetItem.userLogin.helpresetPassword">
      </bb-wss-banner-message>
    </div>
    <form [formGroup]="loginForm" class="form" role="form" autocomplete="off" id="formLogin" novalidate="" method="POST"
      (ngSubmit)="onSubmit()">
      <div class="form-group login-input-field">
        <div class="form-group inner-icon right-icon login-input-field">
          <label for="email" test_id="loginWidgetItememailnameLabelContent">

            {{loginWidgetItem.userLogin.emailnameLabel}}
          </label>
          <div class="email-container">
            <input type="text" class="form-control form-control-lg rounded-2 form-input" formControlName="email"
              required="" data.placeHolder="name@domain.co.uk​" accesskey="m" placeHolder="name@domain.co.uk​"
              [ngClass]="{'is-invalid':submitted && f.email.errors}">
            <span (click)="showEmailInfo()" title="Email Info"
              class="material-icons info-icon">info_outlined</span>
            <div class="container-column info-popup" *ngIf="showEmailInfoPopup">
              <div class="email-info">
                <div class="container-row modal-header-c" test_id="emailInfoTitle">
                  <span class="material-icons" (click)="closeEmailInfoPopup()">clear</span>
                </div>
                <div class="container-column modal-body-c" test_id="emailInfo" id="emailInfo">
                  <bb-content-html [html]="loginWidgetItem.userLogin.emailInfo"></bb-content-html>
                </div>
              </div>
            </div>
            <div class="invalid-feedback" *ngIf="submitted && f.email.errors?.required"
              test_id="loginWidgetItememailRequiredContent">
              {{loginWidgetItem.userLogin.emailRequired}}
            </div>
            <div class="invalid-feedback" [ngClass]="{'show-error': !validEmail && showEmailErrors}"
              test_id="loginWidgetIteminvalidaddressEmailContent">
  
              {{loginWidgetItem.userLogin.invalidaddressEmail}}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group login-input-field" test_id="loginWidgetItemfieldContent">
        <bb-input-password-ui label="Password" placeholder="Enter password" formControlName="password"
          autocomplete="new-password" [aria-describedby]="'password-error'" [showVisibilityControl]="true">
        </bb-input-password-ui>
        <bb-input-validation-message-ui id="password-error" [showErrors]="submitted && !validPwd">
          <p test_id="loginWidgetpasswordenterTooContent">

            {{loginWidgetItem.userLogin.passwordenterToo}}
          </p>
        </bb-input-validation-message-ui>
      </div>
      <p id="loginWidgetItemforgottenPasswordContent" test_id="loginWidgetItemforgottenPasswordContent">
        <bb-content-html [html]="loginWidgetItem.userLogin.forgottenPassword"></bb-content-html>
      </p>
      <div id="login-screen-btn" class="margin-top-between-rows login-page-button text-center"
        test_id="loginWidgetItemlogInContent">
        <button id="bb-button-to" bbButton type="submit" aria-label="Favourite" [className]="'wss-button btn-full-width'"
          title="{{loginWidgetItem.userLogin.logIn}}" class="button-width-padding">
          {{loginWidgetItem.userLogin.logIn}}
        </button>
      </div>
    </form>
  </div>
  <div class="register-link no-para-margin justify-content-center" id="loginWidgetItemNeedHelpLinkContent"
    test_id="loginWidgetItemneedHelpLinkContent">
    <bb-content-html [html]="loginWidgetItem.userLogin.needHelpLink"></bb-content-html>
  </div>
</div>