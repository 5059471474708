import { Component, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { BalloonRefinanceGetdetailsItem } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'bb-brg-connection-failure-redirect',
  templateUrl: './brg-connection-failure-redirect.component.html'
})
export class BrgConnectionFailureRedirectComponent implements OnInit {
  lastDateOfDecision: any;
  accountDetails: any;

  brgItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');

  constructor(
    private readonly bbContentService: ContentService,
    private datastore: DatastoreService,
    private tealiumService: TealiumUtagService,
  ) { }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'Proposal - Connection failure redirect',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.datastore.setBrHideStepper(true);
    this.accountDetails = this.datastore.getAccountDetails();
    this.lastDateOfDecision = this.datastore.getLastDateForEocDecision(this.accountDetails?.final_payment_date);
  }

}
