<div class="login-header-wrapper" test_id="login-header" [attr.style]="loginContainerStyle">
  <div class="d-flex header-wrapper-login responsive-header">
    <div class="logo logo-login-style"></div>
    <div *ngIf="(portalName === 'landrover' || portalName === 'jaguar' )" class="logo-text"></div>
  </div>  
  <div class="login-main-content">
    <div *ngIf="portalName !== 'blackhorse'" class="login-screen-background">    
      <div class="login-screen-container">
        <div class="login-content" role="main">
          <bb-router-outlet></bb-router-outlet>
        </div>
      </div>
    </div>

    <!-- blachorse UI code -->
    <div *ngIf="portalName === 'blackhorse'" class="screen-background-container">
      <div class="screen-background-section1"></div>
      <div class="screen-background-section2"></div>
      <div class="screen-background-section3"></div>

      <div class="login-screen-container">
        <div class="login-content" role="main">
          <bb-router-outlet></bb-router-outlet>
        </div>
      </div>
    </div>
    
  </div>