import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ContentService } from '@backbase/universal-ang/content';
import { BalloonRefinanceGetdetailsItem, addressList, addressClass, addressDetails, ProposalRequest }
  from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { Router } from '@angular/router';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  balloonRefinance,
  commonOptions,
  appConfig,
  patternMap
} from '@wss/config/wss-app-constants';
import * as moment from 'moment';
import {
  getMonthDiff,
  getYearDiff,
  removeControlForm,
  addControlForm,
  addFormValidator,
  resetControlForm,
  getYears,
  injectBrandDetailIntoPage,
  chkDOBDates,
  atLeastOneFieldRequiredValidator,
  noWhitespaceValidator
} from '@wss/common/util/util';
import { BalloonRefinanceGetdetailsService } from '@wss/balloon-refinance-getdetails-widget/src/services/balloon-refinance-getdetails.service';
import { BalloonRefinanceService } from '@wss/balloon-refinance-widget/src/services/balloon-refinance.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wss-balloon-refinance-getdetails-about',
  templateUrl: './balloon-refinance-getdetails-about.component.html'
})

export class BalloonRefinanceGetdetailsAboutComponent implements OnInit {

  balloonRefinanceGetdetailsAboutItem$: Observable<BalloonRefinanceGetdetailsItem | undefined> = this.bbContentService.getContent<BalloonRefinanceGetdetailsItem>('balloonRefinanceGetdetailsWidgetItem');
  brGetdetailsWidgetItem: any = new BalloonRefinanceGetdetailsItem();
  previousPageLink: string = '/balloon-refinance-getdetails/vehicle';
  currentPage: number = 2;
  monthsData: any = appConfig.monthsData;
  yearsList: any = getYears();
  classLiveAddress: string = 'ptb-10x';
  commonNavTestID: string = 'wss-Brg-About-';
  addressData: Array<any> = [];
  addAddressLive: Array<any> = [];
  currentAddress: any = null;
  submitted: boolean = false;
  isAddressSearched: boolean = false;
  readonly destroy$ = new Subject();
  serviceError: boolean = false;
  idExpandArr: Array<any> = [
    'braExpandTitle',
    'braExpandFirstName',
    'braExpandSurname',
    'braExpandDOB',
    'braExpandNationality',
    'braExpandMarital',
    'braExpandDependant',
    'braExpandResidential',
    'braExpandCurrentAddress',
    'braExpandLiveAddress',
    'braExpandLblLive',
    'braExpandLblPrevAddress',
    'braExpandLblCurrentNewAddress',
    'braExpandLblCurrent',
    'braExpandLblCurPrevAddress',
    'braExpandLblAppAddress',
    'braExpandLblAppPrevAddress',
    'braExpandLblAppHistoryAddress',
    'braExpandLblNoPrevAddress',
    'braExpandLblYesPrevAppAddress',
    'braExpandLblNoPrevAppAddress',
    'braExpandLblNoPrevHistoryAddress',
    'braExpandLblNoPrevHistoryDate',
    'braExpandLblNoCurDate',
    'braExpandLblConfirmAddress',
    'braExpandLblMoveAddress'
  ];
  idCollapseArr: Array<any> = [
    'braCollapseTitle',
    'braCollapseFirstName',
    'braCollapseSurame',
    'braCollapseDOB',
    'braCollapseNationality',
    'braCollapseMarital',
    'braCollapseDependant',
    'braCollapseResidential',
    'braCollapseCurrentAddress',
    'braCollapseLiveAddress',
    'braCollapseLblLive',
    'braCollapseLblPrevAddress',
    'braCollapseLblCurrentNewAddress',
    'braCollapseLblCurrent',
    'braCollapseLblCurPrevAddress',
    'braCollapseLblAppAddress',
    'braCollapseLblAppPrevAddress',
    'braCollapseLblAppHistoryAddress',
    'braCollapseLblNoPrevAddress',
    'braCollapseLblYesPrevAppAddress',
    'braCollapseLblNoPrevAppAddress',
    'braCollapseLblNoPrevHistoryAddress',
    'braCollapseLblNoPrevHistoryDate',
    'braCollapseLblNoCurDate',
    'braCollapseLblConfirmAddress',
    'braCollapseLblMoveAddress'
  ];
  idPanelArr: Array<any> = [
    'braInforTitle',
    'braInforFirstName',
    'braInforSurname',
    'braInforDOB',
    'braInforNationality',
    'braInforMarital',
    'braInforDependant',
    'braInforResidential',
    'braInforCurrentAddress',
    'braInforLiveAddress',
    'braInforLblLive',
    'braInforLblPrevAddress',
    'braInforLblCurrentNewAddress',
    'braInforLblCurrent',
    'braInforLblCurPrevAddress',
    'braInforLblAppAddress',
    'braInforLblAppPrevAddress',
    'braInforLblAppHistoryAddress',
    'braInforLblNoPrevAddress',
    'braInforLblYesPrevAppAddress',
    'braInforLblNoPrevAppAddress',
    'braInforLblNoPrevHistoryAddress',
    'braInforLblNoPrevHistoryDate',
    'braInforLblNoCurDate',
    'braInforLblConfirmAddress',
    'braInforLblMoveAddress'
  ];
  aboutDetailsForm!: FormGroup;
  accountDetails: any;
  customerDetails: any;
  lastDateOfDecision: any;
  nationalities: any;
  maritalStatus: any;
  numberOfDependants: any;
  residentialStatus: any;
  title: string = '';
  firstName: string = '';
  surname: string = '';
  dayOfBirth: string = '';
  monthOfBirth: string = '';
  yearOfBirth: string = '';
  yesNo: any;
  dateLiveData: Array<Date> = [];
  addressDetails: addressDetails = {
    house_name: '',
    house_number: '',
    flat_number: '',
    street_name: '',
    district: '',
    post_town: '',
    county: '',
    post_code: ''
  };
  dataModel: any = {
    addNum: [],
    currentAddNo: 0,
    monthDiff: 0,
    yearDiff: 0
  };
  lblElemLiveObj: any = {
    hnameSearch: 'houseNameSearch',
    hnameSearchErr: 'buildingNameErr',
    postcodeSearch: 'postCodeSearch',
    postcodeSearchErr: 'postCodeSearchErr',
    searchPcodeErr: 'postCodeErr',
    searchBtn: 'searchBtn',
    enterFullAdrLink: 'enterFullAdrLink',
    searchByPcode: 'searchByPostcode',
    displayMonthYear: 'displayMonthYr',
    addressMonth: 'addressMonthAdd',
    addressYear: 'addressYearAdd',
    addressMonthAddErr: 'addressMonthAddError',
    addressYearAddErr: 'addressYearAddError',
    dateSelectErr: 'dateSelectError',
    dateSelectDOBErr: 'dateSelectDOBError',
    prevAddressErr: 'prevAddressErr',
    searchAddressErr: 'searchAddressErr',
    houseName: 'house_name',
    houseNameErr: 'houseNameErr',
    houseNumber: 'house_number',
    houseNumberErr: 'houseNumberErr',
    flatNumber: 'flat_number',
    flatNumberErr: 'flatNumberErr',
    streetName: 'street_name',
    streetNameErr: 'streetNameErr',
    district: 'district',
    districtErr: 'districtErr',
    postTown: 'post_town',
    postTownErr: 'postTownErr',
    county: 'county',
    countyErr: 'countyErr',
    postCode: 'post_code',
    postCodeErr: 'postCodeErr',
    notSubmittedAddressSearchErr: 'notSubmittedAddressSearchErr'
  };
  lblElemCurrentObj: any = {
    confirmCurrent: 'confirmCurrent',
    confirmCurrentErr: 'confirmCurrentErr'
  };
  errorsLiveObj: any = {
    showPrevAddressErr: new Array(5).fill(false),
    showSearchAddressErr: new Array(5).fill(false),
    compareCurrentDateErr: false,
    dateCheckErr: new Array(5).fill(false),
    dateCheckDOBErr: new Array(5).fill(false),
    fullAddressErr: new Array(5).fill(false)
  };
  errorsCurrentObj: any = {
    showPrevAddressErr: false,
    showSearchAddressErr: false,
    compareCurrentDateErr: false,
    compareDOBDateErr: false,
    failureAddressCapture: false,
    fullAddressErr: false
  };
  showElmLiveObj: any = {
    showFullAddress: new Array(5).fill(false),
    showaddressBlock: new Array(5).fill(false),
    showHistory: false,
    showAddressQuery: new Array(5).fill(false),
    successAddressTerm: false,
    showLblAddress: true,
    submitted: false,
    submitFullAddr: false,
    showCurConfirm: new Array(5).fill(false),
    addressSearchSubmitted: new Array(5).fill(false)
  };
  showElmCurrentObj: any = {
    showFullAddress: false,
    showaddressBlock: false,
    showConfirmYes: false
  };
  isReturnJourney: boolean = false;
  proposalData: ProposalRequest;
  addressHistoryDuration?: number;
  errorCode: any;

  constructor(
    private readonly bbContentService: ContentService,
    private router: Router,
    private datastore: DatastoreService,
    private formBuilder: FormBuilder,
    private tealiumService: TealiumUtagService,
    private changeDetectorRef: ChangeDetectorRef,
    private brGetDetailsSrv: BalloonRefinanceGetdetailsService,
    private balloonRefinanceSrv: BalloonRefinanceService
  ) {
    this.proposalData = this.datastore.getProposalRequest();
  }

  ngOnInit(): void {
    this.datastore.sessionInvalid();
    this.tealiumService.view(
      {
        JourneyName: 'Balloon Refinance',
        JourneyStepName: 'Proposal - About you',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.datastore.brReturnJourney$.subscribe((res: boolean) => {
      this.isReturnJourney = res;
      this.changeDetectorRef.detectChanges();
    });
    this.getContentData();
    this.initAboutForm();
    this.setCurentPage();
    this.accountDetails = this.datastore.getAccountDetails();
    this.lastDateOfDecision = this.datastore.getLastDateForEocDecision(this.accountDetails?.final_payment_date);
    this.customerDetails = this.datastore.getCustomerDetail();
    this.setCurentPage();
    this.initAboutForm();
    this.setFormData();
    this.getAdditionalAddressDetails();
  }

  setFormData() {
    if (this.proposalData?.nationality) {
      this.aboutDetailsForm.controls['nationality'].setValue(this.proposalData?.nationality);
      this.aboutDetailsForm.controls['marital'].setValue(this.proposalData?.marital);
      this.aboutDetailsForm.controls['dependants'].setValue(this.proposalData?.no_of_dependants === 10 ? '10+' : this.proposalData?.no_of_dependants);
      this.aboutDetailsForm.controls['residential'].setValue(this.proposalData?.resident);
      this.changeDetectorRef.detectChanges();
    }
  }

  getAdditionalAddressDetails() {
    let getAddressData;
    if (this.proposalData?.nationality) {
      this.brGetDetailsSrv.getAdditionalAddressDetails().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        if (res.length > 0) {
          getAddressData = res;
          this.setGetAddress(getAddressData);
          let currentAddressTemp = this.addressData.filter((address: any) => address.address_detail?.id === '0');
          if (currentAddressTemp.length > 0) {
            this.currentAddress = currentAddressTemp[0];
          }
          this.getCustomerDetails();
          this.setAddressData();
        }
        this.changeDetectorRef.detectChanges();
      }, (error: any) => {
        this.currentAddress = this.customerDetails.addresses;
        this.getCustomerDetails();
        if (error.error[0]?.code !== 12009) {
          this.showError();
        }
        this.changeDetectorRef.detectChanges();
      })
    } else {
      this.currentAddress = this.customerDetails.addresses;
      this.getCustomerDetails();
      this.changeDetectorRef.detectChanges();
    }
  }

  setGetAddress(addr: any) {
    let addressDataTemp: Array<any> = [];
    if (addr.length > 0) {
      for (let m = 0; m < addr.length; m++) {
        const appAddressGetTemp = new addressClass();
        this.setupAddress(appAddressGetTemp, addr[m].address_detail, addr[m].address_detail.id);
        this.setupDates(appAddressGetTemp, addr[m].moving_in_date, addr[m].months_at_address, addr[m].years_at_address);
        addressDataTemp[m] = appAddressGetTemp;
      }
    }
    this.addressData = addressDataTemp;
  }

  setAddressData() {
    this.dataModel.currentAddNo = 1;
    let curMn = moment(this.addressData[0]['moving_in_date'], 'DD/MM/YYYY').month();
    let curYr = moment(this.addressData[0]['moving_in_date'], 'DD/MM/YYYY').year();
    let mnVl = curMn <= 9 ? '0' + curMn : curMn;
    this.aboutDetailsForm.controls['liveAddressOpt'].setValue('yes');
    this.onLiveYes();
    this.dateLiveData[0] = new Date(curYr, curMn);
    this.aboutDetailsForm.controls['liveAddressMonth'].setValue(mnVl);
    this.aboutDetailsForm.controls['liveAddressYear'].setValue(curYr);
    const curDate = new Date();
    this.dataModel.monthDiff = getMonthDiff(curDate, this.dateLiveData[0]);
    if (this.addressData.length > 1) {
      this.showElmLiveObj.showHistory = true;
      for (let k = 0; k < (this.addressData.length - 1); k++) {
        this.dataModel.currentAddNo = (k + 1) + this.dataModel.currentAddNo;
        this.dataModel.addNum.push(k);
        this.showElmLiveObj.showAddressQuery[k] = true;
        const hnSearch = this.lblElemLiveObj.hnameSearch + k;
        const pcSearch = this.lblElemLiveObj.postcodeSearch + k;
        this.addSearchFld(hnSearch, pcSearch);
        this.showElmLiveObj.showaddressBlock[k] = true;
        const mnAddress = this.lblElemLiveObj.addressMonth + k;
        const yrAddress = this.lblElemLiveObj.addressYear + k;
        this.addDateFld(mnAddress, yrAddress);
        let dataMn = moment(this.addressData[k + 1]['moving_in_date'], 'DD/MM/YYYY').month();
        let dataYr = moment(this.addressData[k + 1]['moving_in_date'], 'DD/MM/YYYY').year();
        let mnData = dataMn <= 9 ? '0' + dataMn : dataMn;
        this.dateLiveData[k + 1] = new Date(dataYr, dataMn);
        this.dataModel.monthDiff = getMonthDiff(curDate, this.dateLiveData[k + 1]);
        this.aboutDetailsForm.controls[mnAddress].setValue(mnData);
        this.aboutDetailsForm.controls[yrAddress].setValue(dataYr);
      }
    }
  }

  setCurentPage() {
    this.datastore.setBRCurrentPage(this.currentPage);
  }

  initAboutForm() {
    this.aboutDetailsForm = this.formBuilder.group({
      title: ['', Validators.required],
      firstName: ['', Validators.required],
      surname: ['', Validators.required],
      dayOfBirth: ['', [Validators.required, Validators.minLength(2)]],
      monthOfBirth: ['', [Validators.required, Validators.minLength(2)]],
      yearOfBirth: ['', [Validators.required, Validators.minLength(4)]],
      nationality: [null, Validators.required],
      marital: [null, Validators.required],
      dependants: [null, Validators.required],
      residential: [null, Validators.required],
      liveAddressOpt: [null, Validators.required]
    })
  }

  getContentData() {
    this.balloonRefinanceGetdetailsAboutItem$.subscribe((pageItem: any) => {
      this.brGetdetailsWidgetItem = pageItem;
      this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsAbout = injectBrandDetailIntoPage(this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsAbout, this.brGetdetailsWidgetItem?.brandContactDetails)
      this.setDropdowns();
      this.changeDetectorRef.detectChanges();
    })
  }

  setDropdowns() {
    this.nationalities = balloonRefinance.nationalityArray;
    this.yesNo = commonOptions.yesNo;
    this.maritalStatus = [
      { value: 0, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsAbout.maritalOptionMarried },
      { value: 1, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsAbout.maritalOptionCoHabiting },
      { value: 2, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsAbout.maritalOptionSingle },
      { value: 3, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsAbout.maritalOptionSeparated },
      { value: 4, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsAbout.maritalOptionDivorced },
      { value: 5, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsAbout.maritalOptionWidowed }
    ];
    this.numberOfDependants = [...Array(10).keys()];
    this.numberOfDependants.push('10+')
    this.residentialStatus = [
      { value: 0, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsAbout.residentialOptionsOwner },
      { value: 1, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsAbout.residentialOptionsTenant },
      { value: 2, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsAbout.residentialOptionsParents },
      { value: 3, title: this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsAbout.residentialOptionsOther },
    ];
  }

  getCustomerDetails() {
    this.title = this.customerDetails?.individual.title
    this.firstName = this.customerDetails?.individual.first_name
    this.surname = this.customerDetails?.individual.surname
    this.dayOfBirth = this.customerDetails?.individual.date_of_birth.substring(0, 2);
    this.monthOfBirth = this.customerDetails?.individual.date_of_birth.substring(2, 4);
    this.yearOfBirth = this.customerDetails?.individual.date_of_birth.substring(4, 8);
    this.aboutDetailsForm.controls['title'].setValue(this.title);
    this.aboutDetailsForm.controls['firstName'].setValue(this.firstName);
    this.aboutDetailsForm.controls['surname'].setValue(this.surname);
    this.aboutDetailsForm.controls['dayOfBirth'].setValue(this.dayOfBirth);
    this.aboutDetailsForm.controls['monthOfBirth'].setValue(this.monthOfBirth);
    this.aboutDetailsForm.controls['yearOfBirth'].setValue(this.yearOfBirth);
    this.changeDetectorRef.detectChanges();
  }

  setupAddress(setAddress: addressList, cusAddress: any, num: number) {
    setAddress.id = num;
    setAddress.account_number = this.accountDetails.account_number;
    setAddress.moving_in_date = '';
    setAddress.months_at_address = 0;
    setAddress.years_at_address = 0;
    setAddress.address_detail = cusAddress;
  }

  setupDates(setAddress: addressList, dateMv: any, mn: any, yr: any) {
    setAddress.moving_in_date = dateMv;
    setAddress.months_at_address = mn;
    setAddress.years_at_address = yr;
  }

  handleLiveAddress(value: any) {
    this.submitted = false;
    if (value === 'no') {
      this.resetAllDate();
      this.onLiveNo();
    } else {
      this.resetAllDate();
      this.onLiveYes();
    }
  }

  onLiveNo() {
    this.addSearchFld('houseNameCurrent', 'postCodeCurent');
  }

  onLiveYes() {
    this.addDateFld('liveAddressMonth', 'liveAddressYear');
  }

  handleConfirmAddress(value: any) {
    if (value === 'no') {
      this.resetCurConfirmNo();
      this.addressData.splice(1);
    } else {
      this.submitted = false;
      this.baseMsgReset();
      this.errTerm(this.errorsLiveObj, false);
      this.resetCompleteHistory(0);
      this.dateLiveData = [];
      this.dataModel.addNum = [];
      this.addressData.splice(1);
      this.addDateFld('currentAddressMonth', 'currentAddressYear');
    }
  }

  handleCurAddress(num: number, val: string) {
    this.submitted = false;
    this.resetAddressNextCurAddr(num, val);
    if (val === 'yes') {
      this.saveAddress(num);
    } else {
      this.showElmLiveObj.showAddressQuery[num] = true;
      const hnSearch = this.lblElemLiveObj.hnameSearch + num;
      const pcSearch = this.lblElemLiveObj.postcodeSearch + num;
      this.addSearchFld(hnSearch, pcSearch);
      this.addValidatorSearch(hnSearch, pcSearch);
      this.showElmLiveObj.showFullAddress[num] = false;
      this.showElmLiveObj.showaddressBlock[num] = false;
    }
  }

  checkDate(elmMonth: string, elmYear: string, num: number) {
    const selMonth = this.aboutDetailsForm.controls[elmMonth]?.value;
    const selYear = this.aboutDetailsForm.controls[elmYear]?.value;
    this.submitted = false;
    this.errorsCurrentObj.compareDOBDateErr = false;
    let chkDate: boolean = chkDOBDates(parseInt(selMonth), selYear, this.monthOfBirth, this.yearOfBirth);
    if ((num === 0) && (this.aboutDetailsForm.controls.liveAddressOpt.value === 'yes') && (!chkDate)) {
      this.checkDateLive(num, selYear, selMonth);
    } else if ((num > 0) && (this.showElmLiveObj.showHistory)) {
      if ((selMonth !== '') && (selYear !== '')) {
        if (!chkDate) {
          this.checkDateHistory(num, selYear, selMonth);
        } else {
          this.baseMsgReset();
          this.errorsLiveObj.dateCheckDOBErr[num - 1] = true;
        }
      }
    } else if ((num === 0) && (this.aboutDetailsForm.controls.liveAddressOpt.value === 'no') && (!chkDate)) {
      this.checkDateCurrent(num, selYear, selMonth);
    }
    else {
      this.baseMsgReset();
      this.errorsCurrentObj.compareDOBDateErr = true;
    }
  }

  checkDateLive(nVal: number, sYear: any, sMonth: any) {
    const currentDate = new Date();
    if ((sMonth !== '') && (sYear !== '')) {
      this.dataModel.currentAddNo = 1;
      this.dateLiveData[nVal] = new Date(sYear, sMonth);
      this.dataModel.monthDiff = getMonthDiff(currentDate, this.dateLiveData[nVal]);
      if (this.dataModel.monthDiff > 0) {
        this.baseMsgReset();
        this.errTerm(this.errorsLiveObj, false);
        this.dataModel.yearDiff = getYearDiff(currentDate, this.dateLiveData[nVal]);
        const dateTemp = moment([sYear, sMonth, '01']).format('DD/MM/YYYY');
        const appAddressTemp = new addressClass();
        this.setReqData(nVal, this.showElmLiveObj, appAddressTemp, dateTemp);
        this.resetDateHistory(nVal, this.dataModel.addNum.length, this.lblElemLiveObj, this.errorsLiveObj);
        if (this.dataModel.monthDiff >= 36) {
          this.successTermMsg();
          this.resetAddressNext(nVal);
        } else {
          this.buildAddressBlocks(nVal, 1);
        }
      } else {
        this.errTerm(this.errorsLiveObj, true);
      }
    }
  }

  checkDateHistory(nVal: number, sYear: any, sMonth: any) {
    const currentDate = new Date();
    this.dateLiveData[nVal] = new Date(sYear, sMonth);
    const monthDiffTemp = getMonthDiff(this.dateLiveData[nVal - 1], this.dateLiveData[nVal]);
    this.dataModel.monthDiff = getMonthDiff(currentDate, this.dateLiveData[nVal]);
    if (monthDiffTemp <= 0) {
      this.errorsLiveObj.dateCheckErr[nVal - 1] = true;
      this.baseMsgReset();
    } else {
      this.baseMsgReset();
      this.errorsLiveObj.dateCheckDOBErr[nVal - 1] = false;
      this.errorsLiveObj.dateCheckErr[nVal - 1] = false;
      this.dataModel.yearDiff = getYearDiff(this.dateLiveData[nVal - 1], this.dateLiveData[nVal]);
      const dateTemp = moment([sYear, sMonth, '01']).format('DD/MM/YYYY');
      const appAddressTemp = new addressClass();
      this.setReqData(nVal, this.showElmLiveObj, appAddressTemp, dateTemp, this.lblElemLiveObj);
      this.resetDateHistory(nVal, this.dataModel.addNum.length, this.lblElemLiveObj, this.errorsLiveObj);
      if (this.dataModel.monthDiff >= 36) {
        this.successTermMsg();
        this.resetAddressNext(nVal);
      } else {
        if ((this.dataModel.addNum.length > appConfig.hCountMax) && (sMonth !== '') && (sYear !== '')) {
          this.failErr(true);
        } else {
          this.buildAddressBlocks(nVal, 1);
          if ((this.aboutDetailsForm.controls.liveAddressOpt.value === 'no')
            && ((this.aboutDetailsForm.controls[this.lblElemCurrentObj.confirmCurrent + (nVal - 1)])
              && (this.aboutDetailsForm.controls[this.lblElemCurrentObj.confirmCurrent + (nVal - 1)].value === 'no'))) {
            this.showElmLiveObj.showCurConfirm[nVal] = true;
            this.addOptions(this.lblElemCurrentObj.confirmCurrent + nVal);
          }
        }
      }
    }
  }

  checkDateCurrent(nVal: number, sYear: any, sMonth: any) {
    const currentDate = new Date();
    if ((sMonth !== '') && (sYear !== '')) {
      this.dataModel.currentAddNo = 1;
      this.dateLiveData[nVal] = new Date(sYear, sMonth);
      this.dataModel.monthDiff = getMonthDiff(currentDate, this.dateLiveData[nVal]);
      if (this.dataModel.monthDiff > 0) {
        this.baseMsgReset();
        this.errTerm(this.errorsCurrentObj, false);
        this.dataModel.yearDiff = getYearDiff(currentDate, this.dateLiveData[nVal]);
        const dateTemp = moment([sYear, sMonth, '01']).format('DD/MM/YYYY');
        const appAddressTemp = new addressClass();
        this.setReqData(nVal, this.showElmCurrentObj, appAddressTemp, dateTemp);
        if (this.dataModel.monthDiff >= 36) {
          this.successTermMsg();
          this.resetAddressNext(nVal);
        } else {
          this.showElmLiveObj.showCurConfirm[nVal] = true;
          this.showElmLiveObj.showHistory = true;
          this.showElmLiveObj.successAddressTerm = false;
          this.buildAddressBlocks(nVal, 1);
          this.addOptions(this.lblElemCurrentObj.confirmCurrent + nVal);
        }
      } else {
        this.errTerm(this.errorsCurrentObj, true);
      }
    }
  }

  saveAddress(num: number) {
    let appAddressTemp = new addressClass();
    this.setupAddress(appAddressTemp, this.currentAddress?.address_detail, num + 1);
    this.addressData[num + 1] = appAddressTemp;
    const mnAddress = this.lblElemLiveObj.addressMonth + num;
    const yrAddress = this.lblElemLiveObj.addressYear + num;
    this.showDateFld('multi', mnAddress, yrAddress, this.errorsLiveObj, this.showElmLiveObj, num);
    this.resetDateFldStatic(mnAddress, yrAddress);
    this.changeDetectorRef.markForCheck();
  }

  searchAddressCurrent(num: number) {
    this.showElmLiveObj.submitted = false;
    this.submitted = false;
    this.searchInit('houseNameCurrent', 'postCodeCurent');
    if ((this.aboutDetailsForm.controls['houseNameCurrent']?.status !== 'INVALID') &&
      (this.aboutDetailsForm.controls['postCodeCurent']?.status !== 'INVALID')) {
      this.isAddressSearched = true;
      const dataSearch = {
        house_name: this.aboutDetailsForm.controls['houseNameCurrent']?.value.toLowerCase(),
        post_code: this.aboutDetailsForm.controls['postCodeCurent']?.value.toLowerCase()
      };
      this.brGetDetailsSrv.getAddress(dataSearch).subscribe((res: any) => {
        let appAddressTemp = new addressClass();
        this.setupAddress(appAddressTemp, res, num);
        this.addressData[num] = appAddressTemp;
        this.showElmCurrentObj.showaddressBlock = true;
        this.errorsCurrentObj.showSearchAddressErr = false;
        this.showElmLiveObj.submitted = false;
        this.addOptions('confirmAddressOpt');
        this.changeDetectorRef.markForCheck();
      }, (err: any) => {
        this.searchError('static', this.errorsCurrentObj, num);
        this.resetCurConfirmNo();
        this.addressData = [];
        this.remConfirmOpt();
        this.showElmCurrentObj.showaddressBlock = false;
        this.changeDetectorRef.markForCheck();
      })
    }
  }

  searchAddress(num: number) {
    this.submitted = false;
    if (this.showElmLiveObj.showHistory === true) {
      const hnSearch = this.lblElemLiveObj.hnameSearch + num;
      const pcSearch = this.lblElemLiveObj.postcodeSearch + num;
      const mnAddress = this.lblElemLiveObj.addressMonth + num;
      const yrAddress = this.lblElemLiveObj.addressYear + num;
      this.searchInit(hnSearch, pcSearch);
      this.fillArrBln(this.errorsLiveObj.showSearchAddressErr);
      if ((this.aboutDetailsForm.controls[hnSearch].status !== 'INVALID') &&
        (this.aboutDetailsForm.controls[pcSearch].status !== 'INVALID')) {
        this.showElmLiveObj.addressSearchSubmitted[num] = true;
        const dataSearch = {
          house_name: this.aboutDetailsForm.controls[hnSearch].value.toLowerCase(),
          post_code: this.aboutDetailsForm.controls[pcSearch].value.toLowerCase()
        };
        this.brGetDetailsSrv.getAddress(dataSearch).subscribe((res: any) => {
          let appAddressTemp = new addressClass();
          this.setupAddress(appAddressTemp, res, num + 1);
          this.addressData[num + 1] = appAddressTemp;
          this.showDateFld('multi', mnAddress, yrAddress, this.errorsLiveObj, this.showElmLiveObj, num);
          this.resetDateHistory(num, this.dataModel.addNum.length, this.lblElemLiveObj, this.errorsLiveObj);
          this.changeDetectorRef.markForCheck();
        }, (err: any) => {
          this.resetAddressNextCurAddr(num, 'no', 'error');
          this.searchError('multi', this.errorsLiveObj, num);
          this.changeDetectorRef.markForCheck();
        })
      }
    }
  }

  searchInit(hname: string, pcode: string) {
    this.showElmLiveObj.submitted = true;
    this.addValidatorSearch(hname, pcode);
  }

  searchError(type: string, obj: any, num: number) {
    if (type === "static") {
      obj.showSearchAddressErr = true;
    } else {
      obj.showSearchAddressErr[num] = true;
    }
    this.showElmLiveObj.submitted = false;
  }

  showDateFld(type: string, month: string, year: string, errObj: any, shwObj: any, num: number) {
    if (type === "static") {
      shwObj.showaddressBlock = true;
      errObj.showSearchAddressErr = false;
    } else {
      shwObj.showaddressBlock[num] = true;
      errObj.showSearchAddressErr[num] = false;
    }
    this.addDateFld(month, year);
    this.showElmLiveObj.submitted = false;
  }

  addOptions(elm: string) {
    addControlForm(elm, this.aboutDetailsForm, this.formBuilder);
    addFormValidator(elm, [Validators.required], this.aboutDetailsForm);
  }

  remCurrentNo() {
    this.remSearchFldStatic('houseNameCurrent', 'postCodeCurent');
    this.remDateFldStatic('currentAddressMonth', 'currentAddressYear');
  }

  remConfirmOpt() {
    removeControlForm('confirmAddressOpt', this.aboutDetailsForm);
  }

  failErr(bln: boolean) {
    this.errorsCurrentObj.failureAddressCapture = bln;
  }

  setReqData(num: number, showObj: any, obj: any, dateFld: any, objLbl?: any) {
    if ((num > 0) && (this.showElmLiveObj.showHistory)) {
      let numTemp = num;
      if (showObj.showFullAddress[num - 1]) {
        this.addressDetails = this.setFullAddrValue(num - 1, objLbl);
        this.setupAddress(obj, this.addressDetails, numTemp);
        this.setupDates(obj, dateFld, this.dataModel.yearDiff.months, this.dataModel.yearDiff.years);
        if (this.addressData[numTemp] === '' || undefined) {
          this.addressData.push(obj);
        } else {
          this.addressData[numTemp] = obj;
        }
      } else {
        this.setupDates(this.addressData[numTemp], dateFld, this.dataModel.yearDiff.months, this.dataModel.yearDiff.years);
      }
    } else if ((this.aboutDetailsForm.controls.liveAddressOpt.value === 'yes') && (num === 0)) {
      this.setupAddress(obj, this.currentAddress.address_detail, num);
      this.setupDates(obj, dateFld, this.dataModel.yearDiff.months, this.dataModel.yearDiff.years);
      this.addressData[num] = obj;
      this.addressHistoryDuration = this.checkAddressHistoryDuration(num);
      if (this.addressHistoryDuration > (365 * 3)) {
        this.removePreviousAddresses(num);
      }
    } else if ((this.aboutDetailsForm.controls.liveAddressOpt.value === 'no') && (num === 0)) {
      this.addressData[num].is_address_updated = true;
      this.setupDates(this.addressData[num], dateFld, this.dataModel.yearDiff.months, this.dataModel.yearDiff.years);
    }
  }

  checkAddressHistoryDuration(num: number): number {
    return moment().diff(this.addressData[num].moving_in_date, 'days');
  }

  removePreviousAddresses(num: number) {
    this.addressData.splice(num + 1, Infinity)
  }

  setFullAddrValue(num: number, obj: any) {
    const setVal = {
      house_name: this.aboutDetailsForm.controls[obj.houseName + num]?.value.toLowerCase(),
      house_number: this.aboutDetailsForm.controls[obj.houseNumber + num]?.value.toLowerCase(),
      flat_number: this.aboutDetailsForm.controls[obj.flatNumber + num]?.value.toLowerCase(),
      street_name: this.aboutDetailsForm.controls[obj.streetName + num]?.value.toLowerCase(),
      district: this.aboutDetailsForm.controls[obj.district + num]?.value.toLowerCase(),
      post_town: this.aboutDetailsForm.controls[obj.postTown + num]?.value.toLowerCase(),
      county: this.aboutDetailsForm.controls[obj.county + num]?.value.toLowerCase(),
      post_code: this.aboutDetailsForm.controls[obj.postCode + num]?.value.toLowerCase()
    }
    return setVal;
  }

  errDate(obj: any, bln: boolean) {
    obj.dateCheckErr = bln;
  }

  errTerm(elmObj: any, bln: boolean) {
    elmObj.compareCurrentDateErr = bln;
  }

  buildAddressBlocks(num: number, addNum: number) {
    this.dataModel.currentAddNo = num + addNum;
    this.baseMsgReset();
    if (!this.showElmLiveObj.showHistory) {
      this.dataModel.addNum.push(this.dataModel.addNum.length);
      this.showElmLiveObj.showHistory = true;
      this.addBlksHistory(num, this.lblElemLiveObj, this.showElmLiveObj);
    } else if (this.showElmLiveObj.showHistory) {
      if (this.dataModel.currentAddNo - addNum === this.dataModel.addNum.length) {
        this.dataModel.addNum.push(this.dataModel.addNum.length);
        this.addBlksHistory(num, this.lblElemLiveObj, this.showElmLiveObj);
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  addBlksHistory(num: number, elemObj: any, showObj: any) {
    showObj.showAddressQuery[num] = true;
    const hnSearch = elemObj.hnameSearch + num;
    const pcSearch = elemObj.postcodeSearch + num;
    this.addSearchFld(hnSearch, pcSearch);
    this.changeDetectorRef.markForCheck();
  }

  addSearchFld(hname: string, pcode: string) {
    addControlForm(hname, this.aboutDetailsForm, this.formBuilder);
    addControlForm(pcode, this.aboutDetailsForm, this.formBuilder);
  }

  baseMsgReset() {
    this.showElmLiveObj.successAddressTerm = false;
    this.failErr(false);
  }

  remSearchFldStatic(hname: string, pcode: string) {
    removeControlForm(hname, this.aboutDetailsForm);
    removeControlForm(pcode, this.aboutDetailsForm);
  }

  remDateFldStatic(month: string, year: string) {
    removeControlForm(month, this.aboutDetailsForm);
    removeControlForm(year, this.aboutDetailsForm);
  }

  removeAllHistory(num: number, addLn: number, lblElm: any, showLbl: any, hLbl?: any) {
    if (addLn > 0) {
      for (let q = num; q < addLn; q++) {
        const hnSearch = lblElm.hnameSearch + q;
        const pcSearch = lblElm.postcodeSearch + q;
        const mnSearch = lblElm.addressMonth + q;
        const yrSearch = lblElm.addressYear + q;
        if (this.aboutDetailsForm.controls[this.lblElemCurrentObj.confirmCurrent + q]) {
          removeControlForm(this.lblElemCurrentObj.confirmCurrent + q, this.aboutDetailsForm);
        }
        this.remSearchFldStatic(hnSearch, pcSearch);
        if (showLbl.showFullAddress[q]) {
          this.removeManualAddrFields(mnSearch, yrSearch, q, lblElm);
        } else {
          this.remDateFldStatic(mnSearch, yrSearch);
        }
        showLbl.showaddressBlock[q] = false;
        showLbl.showFullAddress[q] = false;
        showLbl.showAddressQuery[q] = false;
      }
    }
  }

  successTermMsg() {
    this.showElmLiveObj.successAddressTerm = true;
    this.failErr(false);
  }

  resetDateHistory(num: number, dataLn: number, obj: any, errObj: any) {
    if (dataLn > 0) {
      for (let r = num; r < dataLn; r++) {
        const mnSearch = obj.addressMonth + r;
        const yrSearch = obj.addressYear + r;
        resetControlForm(mnSearch, this.aboutDetailsForm);
        resetControlForm(yrSearch, this.aboutDetailsForm);
      }
      this.fillArrBln(errObj.dateCheckErr);
    }
  }

  populateArrBln(elem: any, num: number) {
    for (let v = num; v < elem.length; v++) {
      elem[v] = false;
    }
  }

  fillArrBln(elem: any) {
    elem.fill(false);
  }

  resetAddressNext(num: number) {
    this.successTermMsg();
    if ((num > 0) && (this.showElmLiveObj.showHistory)) {
      this.resetCmYes(num);
      if (num < this.dateLiveData.length) {
        this.resetDataYes(num);
        this.dataModel.addNum.splice(num, this.dataModel.addNum.length - (num));
      }
    } else if ((num === 0) && (this.showElmLiveObj.showHistory)) {
      this.resetCompleteHistory(num);
    } else if ((num === 0) && (!this.showElmLiveObj.showHistory)) {
      this.dataModel.addNum = [];
    }
    this.dataModel.currentAddNo = num + 1;
  }

  resetAddressNextError(num: number) {
    this.baseMsgReset();
    if (this.dataModel.addNum.length > 1) {
      for (let q = num + 1; q < this.dataModel.addNum.length; q++) {
        const hnSearch = this.lblElemLiveObj.hnameSearch + q;
        const pcSearch = this.lblElemLiveObj.postcodeSearch + q;
        const mnSearch = this.lblElemLiveObj.addressMonth + q;
        const yrSearch = this.lblElemLiveObj.addressYear + q;
        this.remSearchFldStatic(hnSearch, pcSearch);
        if (this.showElmLiveObj.showFullAddress[q]) {
          this.removeManualAddrFields(mnSearch, yrSearch, q, this.lblElemLiveObj);
        } else {
          this.remDateFldStatic(mnSearch, yrSearch);
        }
        this.showElmLiveObj.showaddressBlock[q] = false;
        this.showElmLiveObj.showFullAddress[q] = false;
        this.showElmLiveObj.showAddressQuery[q] = false;
      }
    }
    if (num > 0) {
      this.resetCmYes(num);
      if (num < this.dateLiveData.length) {
        this.resetDataYes(num);
        this.dataModel.addNum.splice(num, this.dataModel.addNum.length - (num));
      }
    } else if ((num === 0) && (this.showElmLiveObj.showHistory)) {
      this.resetCompleteHistory(num);
    }
    this.dataModel.currentAddNo = num + 1;
  }

  resetAddressNextCurAddr(num: number, val: string, err?: string) {
    this.dataModel.currentAddNo = num + 1;
    if (this.dataModel.currentAddNo < this.dataModel.addNum.length) {
      if (err === 'error') {
        this.resetCmYesCurAddrError(num, val);
      } else {
        this.resetCmYesCurAddr(num, val);
      }
      if (num < this.dateLiveData.length) {
        this.resetDataYes(num);
        this.dataModel.addNum.splice(num + 1);
      }
    }
    this.baseMsgReset();
  }

  rmCurrentCurAddr(num: number, val: string, lblElm: any, showLbl: any) {
    const hnCurSearch = lblElm.hnameSearch + num;
    const pcCurSearch = lblElm.postcodeSearch + num;
    const mnCurSearch = lblElm.addressMonth + num;
    const yrCurSearch = lblElm.addressYear + num;
    if (val === 'yes') {
      this.remSearchFldStatic(hnCurSearch, pcCurSearch);
      if (showLbl.showFullAddress[num]) {
        this.removeManualAddrFields(mnCurSearch, yrCurSearch, num, lblElm);
      } else {
        this.remDateFldStatic(mnCurSearch, yrCurSearch);
      }
    }
    showLbl.showaddressBlock[num] = false;
    showLbl.showFullAddress[num] = false;
    showLbl.showAddressQuery[num] = false;
  }

  rmCurrentCurAddrError(num: number, lblElm: any, showLbl: any) {
    const mnCurSearch = lblElm.addressMonth + num;
    const yrCurSearch = lblElm.addressYear + num;
    this.remDateFldStatic(mnCurSearch, yrCurSearch);
    showLbl.showaddressBlock[num] = false;
    showLbl.showFullAddress[num] = false;
  }

  resetCmYesCurAddr(num: number, val: string) {
    this.fillArrBln(this.errorsLiveObj.showSearchAddressErr);
    this.fillArrBln(this.errorsLiveObj.dateCheckErr);
    this.removeAllHistoryCurAddr(num, this.dataModel.addNum.length, this.lblElemLiveObj, this.showElmLiveObj, val, 'reset');
  }

  resetCmYesCurAddrError(num: number, val: string) {
    this.populateArrBln(this.errorsLiveObj.showSearchAddressErr, num);
    this.populateArrBln(this.errorsLiveObj.dateCheckErr, num);
    this.removeAllHistoryCurAddr(num, this.dataModel.addNum.length, this.lblElemLiveObj, this.showElmLiveObj, val, 'error');
  }

  resetCmYes(num: number) {
    this.fillArrBln(this.errorsLiveObj.showSearchAddressErr);
    this.fillArrBln(this.errorsLiveObj.dateCheckErr);
    this.removeAllHistory(num, this.dataModel.addNum.length, this.lblElemLiveObj, this.showElmLiveObj);
  }

  removeAllHistoryCurAddr(num: number, addLn: number, lblElm: any, showLbl: any, val: string, err: string, hLbl?: any) {
    if (err === 'error') {
      this.rmCurrentCurAddrError(num, lblElm, showLbl);
    } else {
      this.rmCurrentCurAddr(num, val, lblElm, showLbl);
    }
    if (addLn > 1) {
      for (let q = num + 1; q < addLn; q++) {
        const curConfirm = this.lblElemCurrentObj.confirmCurrent + q;
        const hnSearch = lblElm.hnameSearch + q;
        const pcSearch = lblElm.postcodeSearch + q;
        const mnSearch = lblElm.addressMonth + q;
        const yrSearch = lblElm.addressYear + q;
        if (this.aboutDetailsForm.controls.liveAddressOpt.value === 'no') {
          removeControlForm(curConfirm, this.aboutDetailsForm);
        }
        this.remSearchFldStatic(hnSearch, pcSearch);
        if (showLbl.showFullAddress[q]) {
          this.removeManualAddrFields(mnSearch, yrSearch, q, lblElm);
        } else {
          this.remDateFldStatic(mnSearch, yrSearch);
        }
        showLbl.showaddressBlock[q] = false;
        showLbl.showFullAddress[q] = false;
        showLbl.showAddressQuery[q] = false;
      }
    }
  }

  resetDataCmn(num: number) {
    this.dateLiveData.splice(num + 1, this.dateLiveData.length - (num + 1));
    this.addressData.splice(num + 1, this.addressData.length - (num + 1));
    this.dataModel.addNum = [];
  }

  resetDataYes(num: number) {
    this.dateLiveData.splice(num + 1, this.dateLiveData.length - (num + 1));
    this.addressData.splice(num + 1, this.addressData.length - (num + 1));
  }

  resetCompleteHistory(num: number) {
    this.resetCmYes(num);
    this.dataModel.addNum = [];
    this.showElmLiveObj.showHistory = false;
  }

  resetDataAll() {
    this.dateLiveData = [];
    this.addressData = [];
    this.dataModel.addNum = [];
  }

  resetAllDate() {
    this.baseMsgReset();
    this.errTerm(this.errorsLiveObj, false);
    this.remDateFldStatic('liveAddressMonth', 'liveAddressYear');
    this.remCurrentNo();
    this.resetCompleteHistory(0);
    this.resetDataAll();
  }

  resetCurConfirmNo() {
    this.baseMsgReset();
    this.errTerm(this.errorsLiveObj, false);
    this.remDateFldStatic('currentAddressMonth', 'currentAddressYear');
    this.resetCompleteHistory(0);
    this.dateLiveData = [];
    this.dataModel.addNum = [];
  }

  addValidatorSearch(hsearch: string, psearch: string) {
    addFormValidator(hsearch, [Validators.required, Validators.maxLength(20)], this.aboutDetailsForm);
    addFormValidator(psearch, Validators.compose([Validators.required, Validators.pattern(patternMap.postCode)]), this.aboutDetailsForm);
  }

  checkFullAddress(num: number, lblElem: any, type: string, sLbl: any, errObj: any, term: number) {
    this.submitted = false;
    if (type === 'multiple') {
      const hnTemp = lblElem.houseName + num;
      const hnoTemp = lblElem.houseNumber + num;
      const fnTemp = lblElem.flatNumber + num;
      const snTemp = lblElem.streetName + num;
      const dTemp = lblElem.district + num;
      const ptTemp = lblElem.postTown + num;
      const cTemp = lblElem.county + num;
      const pcTemp = lblElem.postCode + num;
      if (((this.aboutDetailsForm.controls[hnTemp]?.value !== '') ||
        (this.aboutDetailsForm.controls[hnoTemp]?.value !== '') ||
        (this.aboutDetailsForm.controls[fnTemp]?.value !== '')) &&
        (this.aboutDetailsForm.controls[snTemp]?.status !== 'INVALID') &&
        (this.aboutDetailsForm.controls[dTemp]?.status !== 'INVALID') &&
        (this.aboutDetailsForm.controls[ptTemp]?.status !== 'INVALID') &&
        (this.aboutDetailsForm.controls[cTemp]?.status !== 'INVALID') &&
        (this.aboutDetailsForm.controls[pcTemp]?.status !== 'INVALID')) {
        if (this.aboutDetailsForm.controls[hnoTemp]?.value === '') {
          this.aboutDetailsForm.controls[hnoTemp].setValue(' ');
        }
        if (this.aboutDetailsForm.controls[hnTemp]?.value === '') {
          this.aboutDetailsForm.controls[hnTemp].setValue(' ');
        }
        if (this.aboutDetailsForm.controls[fnTemp]?.value === '') {
          this.aboutDetailsForm.controls[fnTemp].setValue(' ');
        }
        const resTemp = {
          house_name: this.aboutDetailsForm.controls[hnTemp]?.value,
          house_number: this.aboutDetailsForm.controls[hnoTemp]?.value,
          flat_number: this.aboutDetailsForm.controls[fnTemp]?.value,
          street_name: this.aboutDetailsForm.controls[snTemp]?.value,
          district: this.aboutDetailsForm.controls[dTemp]?.value,
          post_town: this.aboutDetailsForm.controls[ptTemp]?.value,
          county: this.aboutDetailsForm.controls[cTemp]?.value,
          post_code: this.aboutDetailsForm.controls[pcTemp]?.value
        }
        errObj.fullAddressErr[num] = false;
        const appAddressTemp = new addressClass();
        this.setupAddress(appAddressTemp, resTemp, num + 1);
        this.addAddressLive[num] = appAddressTemp;
        if (term === 1) {
          const mnTemp = this.lblElemLiveObj.addressMonth + num;
          const yrTemp = this.lblElemLiveObj.addressYear + num;
          this.addDateFld(mnTemp, yrTemp);
        }
        sLbl.showaddressBlock[num] = true;
      } else if ((this.aboutDetailsForm.controls[hnTemp]?.value !== '') ||
        (this.aboutDetailsForm.controls[hnoTemp]?.value !== '') ||
        (this.aboutDetailsForm.controls[fnTemp]?.value !== '')) {
        errObj.fullAddressErr[num] = false;
      } else {
        sLbl.showaddressBlock[num] = false;
        errObj.fullAddressErr[num] = true;
      }
    }
  }

  addDateFld(month: string, year: string) {
    addControlForm(month, this.aboutDetailsForm, this.formBuilder);
    addControlForm(year, this.aboutDetailsForm, this.formBuilder);
    addFormValidator(month, [Validators.required], this.aboutDetailsForm);
    addFormValidator(year, [Validators.required], this.aboutDetailsForm);
  }

  resetDateFldStatic(month: string, year: string) {
    resetControlForm(month, this.aboutDetailsForm);
    resetControlForm(year, this.aboutDetailsForm);
  }

  removeManualAddrFields(month: any, year: any, num?: number, lblElem?: any) {
    const hnTemp = lblElem.houseName + num;
    const hnoTemp = lblElem.houseNumber + num;
    const fnTemp = lblElem.flatNumber + num;
    const snTemp = lblElem.streetName + num;
    const dTemp = lblElem.district + num;
    const ptTemp = lblElem.postTown + num;
    const cTemp = lblElem.county + num;
    const pcTemp = lblElem.postCode + num;
    removeControlForm(hnTemp, this.aboutDetailsForm);
    removeControlForm(hnoTemp, this.aboutDetailsForm);
    removeControlForm(fnTemp, this.aboutDetailsForm);
    removeControlForm(snTemp, this.aboutDetailsForm);
    removeControlForm(dTemp, this.aboutDetailsForm);
    removeControlForm(ptTemp, this.aboutDetailsForm);
    removeControlForm(cTemp, this.aboutDetailsForm);
    removeControlForm(pcTemp, this.aboutDetailsForm);
    removeControlForm(month, this.aboutDetailsForm);
    removeControlForm(year, this.aboutDetailsForm);
  }

  addManualAddrFields(month: any, year: any, num?: number, lblElem?: any) {
    const hnTemp = lblElem.houseName + num;
    const hnoTemp = lblElem.houseNumber + num;
    const fnTemp = lblElem.flatNumber + num;
    const snTemp = lblElem.streetName + num;
    const dTemp = lblElem.district + num;
    const ptTemp = lblElem.postTown + num;
    const cTemp = lblElem.county + num;
    const pcTemp = lblElem.postCode + num;
    addControlForm(hnTemp, this.aboutDetailsForm, this.formBuilder);
    addControlForm(hnoTemp, this.aboutDetailsForm, this.formBuilder);
    addControlForm(fnTemp, this.aboutDetailsForm, this.formBuilder);
    addControlForm(snTemp, this.aboutDetailsForm, this.formBuilder);
    addControlForm(dTemp, this.aboutDetailsForm, this.formBuilder);
    addControlForm(ptTemp, this.aboutDetailsForm, this.formBuilder);
    addControlForm(cTemp, this.aboutDetailsForm, this.formBuilder);
    addControlForm(pcTemp, this.aboutDetailsForm, this.formBuilder);
    addFormValidator(hnTemp, [Validators.pattern(patternMap.alphanumericHypenAprostrophePattern)], this.aboutDetailsForm)
    addFormValidator(hnoTemp, [Validators.pattern(patternMap.alphanumericHypenForwardSlashPattern)], this.aboutDetailsForm);
    addFormValidator(fnTemp, [Validators.pattern(patternMap.alphanumericHypenPattern)], this.aboutDetailsForm);
    addFormValidator(snTemp, [Validators.required, Validators.pattern(patternMap.alphabeticApostropheHyphenPattern), noWhitespaceValidator], this.aboutDetailsForm);
    addFormValidator(dTemp, [Validators.required, Validators.pattern(patternMap.alphabeticApostropheHyphenPattern)], this.aboutDetailsForm);
    addFormValidator(ptTemp, [Validators.required, Validators.pattern(patternMap.alphabeticApostropheHyphenPattern)], this.aboutDetailsForm);
    addFormValidator(cTemp, [Validators.required, Validators.pattern(patternMap.alphabeticHyphenPattern)], this.aboutDetailsForm);
    addFormValidator(pcTemp, [Validators.required, Validators.pattern(patternMap.postCode), Validators.pattern(patternMap.alphanumericPattern)], this.aboutDetailsForm);
    this.aboutDetailsForm.setValidators(atLeastOneFieldRequiredValidator([hnTemp, hnoTemp]));
    removeControlForm(month, this.aboutDetailsForm);
    removeControlForm(year, this.aboutDetailsForm);
  }

  toggleSearchTypeHistory(num: number, month: any, year: any, obj: any, objShow: any) {
    this.submitted = false;
    objShow.showFullAddress[num] = !objShow.showFullAddress[num];
    this.showElmLiveObj.showaddressBlock[num] = false;
    if (objShow.showFullAddress[num]) {
      this.addManualAddrFields(month, year, num, obj);
      removeControlForm('houseNameSearch' + num, this.aboutDetailsForm);
      removeControlForm('postCodeSearch' + num, this.aboutDetailsForm);
    } else {
      this.removeManualAddrFields(month, year, num, obj);
      addControlForm('houseNameSearch' + num, this.aboutDetailsForm, this.formBuilder);
      addControlForm('postCodeSearch' + num, this.aboutDetailsForm, this.formBuilder);
      addFormValidator('houseNameSearch' + num, [Validators.required], this.aboutDetailsForm);
      addFormValidator('postCodeSearch' + num, [Validators.required], this.aboutDetailsForm);
    }
    this.changeDetectorRef.markForCheck();
  }

  saveAddresses(route: string) {
    this.brGetDetailsSrv.postAdditionalAddressDetails(this.addressData).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.addressData = this.datastore.getAddressDetails();
      this.saveProposal(this.proposalData, route);
    },
      (error: any) => {
        if (error.error[0]?.code === 113 || error.error[0]?.code === 64267) {
          this.errorCode = error.error[0]?.code ? error.error[0].code : error.status;
          this.showAddressError()
        } else {
          this.showError();
        }
        this.scrollToErrorMessage();
      });
  }

  saveProposal(proposalData: ProposalRequest, route: string) {
    this.balloonRefinanceSrv.postProposalDetails(proposalData).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.proposalData = this.datastore.getProposalRequest();
      this.isReturnJourney ?
        this.router.navigate(['/balloon-refinance-getdetails/summary']) :
        this.router.navigate([route])
    },
      (error: any) => {
        this.showError();
      });
  }

  showError(): void {
    this.datastore.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  showAddressError(): void {
    this.datastore.setErrorScreen(this.brGetdetailsWidgetItem.balloonRefinanceGetdetailsAbout.addressErrorText);
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  callToService(route: string) {
    this.proposalData.nationality = this.aboutDetailsForm.controls['nationality']?.value;
    this.proposalData.marital = this.aboutDetailsForm.controls['marital']?.value;
    this.proposalData.resident = this.aboutDetailsForm.controls['residential']?.value;
    this.proposalData.no_of_dependants = this.aboutDetailsForm.value.dependants === '10+' ? 10 : this.aboutDetailsForm.value.dependants;
    this.saveAddresses(route);
  }

  onSaveButtonClick(e: any) {
    this.router.navigate(['/myaccount']);
  }

  scrollToFirstInvalid() {
    setTimeout(() => {
      document.getElementsByClassName('ng-invalid')[1]?.scrollIntoView({ behavior: 'smooth' })
    }, 100);
  }

  scrollToErrorMessage() {
    document.getElementById('bannerMsgId')?.scrollIntoView({ behavior: 'smooth' })
  }

  checkDates(): boolean {
    let checkVal = false;
    if ((this.errorsLiveObj.compareCurrentDateErr || this.errorsCurrentObj.compareDOBDateErr) && !checkVal) {
      checkVal = true;
      this.baseMsgReset();
    }
    if (this.dataModel.currentAddNo > 0) {
      for (let u = 0; u < this.dataModel.currentAddNo; u++) {
        const mnSearch = this.lblElemLiveObj.addressMonth + u;
        const yrSearch = this.lblElemLiveObj.addressYear + u;
        const errDate = this.errorsLiveObj.dateCheckErr[u];
        const errDateDOB = this.errorsLiveObj.dateCheckDOBErr[u];
        if ((this.aboutDetailsForm.controls[mnSearch]?.value === '') ||
          (this.aboutDetailsForm.controls[yrSearch]?.value === '') &&
          ((errDate) ||
            (errDateDOB))) {
          if (!checkVal) {
            checkVal = true;
            this.baseMsgReset();
          }
        }
      }
    }
    return checkVal;
  }

  isFormValid(): boolean {
    return this.aboutDetailsForm.status !== 'INVALID' ? true : false;
  }

  onContinue(e: any) {
    this.submitted = true;
    if ((this.dataModel.monthDiff >= 36) && (!this.checkDates()) && (this.isFormValid())) {
      this.showElmLiveObj.submitted = true;
      this.callToService('/balloon-refinance-getdetails/employment');
    } else {
      this.showElmLiveObj.submitted = false;
      this.scrollToFirstInvalid()
    }
  }

  toGetinTouch() {
    this.router.navigate(['/help-centre/get-in-touch'])
  }
}