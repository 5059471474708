import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { HeaderContentWidgetComponent } from './header-content-widget.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BackbaseUiAngExamplesModule, ButtonModule, HeaderModule, InputDatepickerModule, InputPasswordModule, InputValidationMessageModule } from '@backbase/ui-ang';
import { HttpClientModule } from '@angular/common/http';
import { UserContactComponent } from './components/user-contact/user-contact.component';
import { UserDetailComponent } from './components/user-address/user-address.component';
import { UserEmailComponent } from './components/user-email/user-email.component';
import { WSSCommonModule } from '../../common/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentWidgetModule } from '@backbase/universal-ang/content';
import { ContentModule } from '@backbase/content-ang';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
  declarations: [
    HeaderContentWidgetComponent,
    UserContactComponent,
    UserDetailComponent,
    UserEmailComponent,
  ],
  imports: [
    CommonModule,
    CommonModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    BackbaseUiAngExamplesModule,
    ButtonModule, HeaderModule,  
    InputValidationMessageModule,
    InputDatepickerModule,
    HttpClientModule,
    ButtonModule,NgbModule,
    HeaderModule,
    InputPasswordModule,
    WSSCommonModule,
    ContentWidgetModule,
    ContentModule,
    MatExpansionModule,
    BackbaseCoreModule.withConfig({
      classMap: { HeaderContentWidgetComponent }
    })
  ]
})
export class HeaderContentWidgetModule { }

