<section class="balloon-refinance" *ngIf="brgWidgetItem$ | async as brgWidgetItem">
    <div>
        <div class="card pt-30px mb-30px">
            <div class="success-icon m-0"></div>
            <div class="text-center mt-20px mb-10px" test_id="title">
                <bb-content-html [html]="brgWidgetItem.brgModAgNextStep.pageTitle"></bb-content-html>
            </div>
            <div class="row border-top">
                <div class="col-md-6 border-right p-3 d-flex flex-column align-items-center">
                    <mat-icon class="text-black-50 mb-15px">date_range</mat-icon>
                    <span test_id="firstInstallmentDate" class="mb-20px">{{brgWidgetItem.brgModAgNextStep.firstInstallmentDate}}</span>
                    <span class="text-semibold">{{firstPaymentDate}}</span>
                </div>
                <div class="col-md-6 p-3 d-flex flex-column align-items-center">
                    <mat-icon class="text-black-50 mb-15px">credit_card</mat-icon>
                    <span test_id="firstInstallmentAmount" class="mb-20px">{{brgWidgetItem.brgModAgNextStep.firstInstallmentAmount}}</span>
                    <span class="text-semibold">&pound;{{firstPaymentAmount}}</span>
                </div>
            </div>
        </div>
        <div>
            <h2 test_id="headerText">{{brgWidgetItem.brgModAgNextStep.headerText}}</h2>
            <div test_id="description" class="pl-1">
                <bb-content-html [html]="brgWidgetItem.brgModAgNextStep.description"></bb-content-html>
            </div>
        </div>
        <button class="wss-button" test_id="buttonText" routerLink="/myaccount" id="wss-brg-mod-ag-next-steps-return-to-account-btn">
            {{brgWidgetItem.brgModAgNextStep.buttonText}}
        </button>
    </div>
</section>