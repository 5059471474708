import { Injectable } from "@angular/core";
import {User} from "../user";
import {BehaviorSubject} from "rxjs";
@Injectable({providedIn: 'root'})
export class UserRepoService {

  private data = new BehaviorSubject( {
    id: 0,
    firstName: '',
    lastName: '',
    phone: 0,
    dateOfBirth: '',
    postCode: ''
  });
  searchedUser = this.data.asObservable();

  private screenTitleDs = new BehaviorSubject('Create An Account');
  screenTitle = this.screenTitleDs.asObservable();


  add(user: User) {
    // this.data.next(user);
  }

  updateScreenTitle(screenTitle: string){
    this.screenTitleDs.next(screenTitle);
  }

}
