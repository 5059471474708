<div *ngIf="loginWidgetItem$ | async as loginWidgetItem">
  <div class="reg-login-container">
    <div class="pass-set-wrapper">
      <div class="ptl-10pt">
        <span class="section-label-size" test_id="which_account_label_id">
          {{loginWidgetItem.passwordReset.titleText}}
        </span>
        <hr />
      </div>
      <div class="reg-row padding-left-right" test_id="choosePass_id">
        <p class="we-have-verified-your">
          <bb-content-html [html]="loginWidgetItem.passwordReset.choosePass"></bb-content-html>
        </p>
      </div>

      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
        <div class="reg-row-col padding-left-right">
          <bb-input-password-ui label="Password" placeholder="Enter password" formControlName="password"
            autocomplete="new-password" [aria-describedby]="'password-error'" [showVisibilityControl]="true">
          </bb-input-password-ui>
        </div>

        <bb-wss-common-password-rules [containsAtleastEightChars]="atLeastContainsEightChars"
          [containsAtLeastOneLowerCase]="atLeastOneLowerCase" [containsAtLeastOneNumber]="atLeastOneNumber"
          [containsAtLeastOneUpperCase]="atLeastOneUpperCase"
          [passwordRules]="loginWidgetItem.passwordReset.passwordRules">
        </bb-wss-common-password-rules>

        <div class="invalid-feedback padding-left-right" test_id="loginWidgetIteminvalidPasswordContent"
          [ngClass]="{'show-error': showPasswordError}">
          <bb-content-html [html]="loginWidgetItem.passwordReset.invalidPassword"></bb-content-html>
        </div>
        <div class="reg-row padding-left-right password-screen-margin-top">
          <bb-input-password-ui label="Confirm password" placeholder="Enter password" formControlName="confirmPassword"
            autocomplete="new-password" onPaste="return false" [aria-describedby]="'password-error'"
            [showVisibilityControl]="true" [disabled]="passwordEntered">
          </bb-input-password-ui>
        </div>
        <div class="invalid-feedback padding-left-right" test_id="loginWidgetItempasswordMatchErrorContent"
          [ngClass]="{'show-error confirm-password': !passwordMatch}">
          <bb-content-html [html]="loginWidgetItem.passwordReset.passwordMatchError"></bb-content-html>
        </div>
        <div id="password-reset-btn" class="margin-top-between-rows password-submit-button"
          test_id="loginWidgetItemchangePasswordtextContent">
          <button bbButton type="submit" aria-label="Favourite" class="wss-button"
            [disabled]="buttonDisabled || passwordEntered" title="{{loginWidgetItem.passwordReset.changePassword}}">
            {{loginWidgetItem.passwordReset.changePassword}}
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="register-link no-para-margin" style="justify-content: center;"
    test_id="loginWidgetItemneedHelpLinktextContent" id="needHelpLinkContent">
    <bb-content-html [html]="loginWidgetItem.passwordReset.needhelpLink"></bb-content-html>
  </div>
</div>