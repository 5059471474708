<div *ngIf="complaintWidgetItem">
  <form [formGroup]="complaintYourContactDetailsForm" formInvlidControlScroll>
    <div class="container-column">
      <div class="help-centre-title">
        <span *ngIf="complaintConcernType == 'Vehicle' || ''" class="section-label-title"
          test_id="makeComplaintPageName">
          {{complaintWidgetItem.complaintRelateTo.pageTitle}}
        </span>
        <span *ngIf="complaintConcernType == 'FinanceAgreement'" class="section-label-title"
          test_id="makeComplaintPageName">
          {{complaintWidgetItem.complaintRelateTo.financeAgreementPageTitle}}
        </span>
      </div>
      <div class="reg-row plr-10pt" role="alert" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
      </div>
      <div class="sub-heading mt-30px" test_id="pageHeaderBelowText">
        <bb-content-html [html]="complaintWidgetItem.complaintContactDetails.pageHeaderBelowText"></bb-content-html>
      </div>

      <div class="form-group mt-10px">
        <div test_id="preferredContactMethod">
          <strong>{{complaintWidgetItem.complaintContactDetails.preferredContactMethod}}</strong>
        </div>

        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="complaintPreferredContactMethod"
              [ngClass]="{'has-Error-Radio': submitted && complaintYourContactDetailsForm.get('complaintPreferredContactMethod')?.errors && complaintYourContactDetailsForm.get('complaintPreferredContactMethod')?.invalid && complaintYourContactDetailsForm.get('complaintPreferredContactMethod')?.errors?.required}"
              value="{{complaintWidgetItem.complaintContactDetails.emailLabel}}"
              id="complaintPreferredContactMethodEmail">
            <span class="label"
              test_id="concernsRaiseWithDealerYesSelection">{{complaintWidgetItem.complaintContactDetails.emailLabel}}</span>
          </label>
        </div>
        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="complaintPreferredContactMethod"
              [ngClass]="{'has-Error-Radio': submitted && complaintYourContactDetailsForm.get('complaintPreferredContactMethod')?.errors && complaintYourContactDetailsForm.get('complaintPreferredContactMethod')?.invalid && complaintYourContactDetailsForm.get('complaintPreferredContactMethod')?.errors?.required}"
              value="{{complaintWidgetItem.complaintContactDetails.phoneLabel}}"
              id="complaintPreferredContactMethodPhone">
            <span class="label" test_id="phoneLabel">{{complaintWidgetItem.complaintContactDetails.phoneLabel}}</span>
          </label>
          <div class="form-group form-group-textarea mt-10px ps-30"
            *ngIf="complaintPreferredContactMethodFormControl?.value == 'Phone' && (customerDetail?.contact_details?.mobile_phone_number || customerDetail?.contact_details?.home_phone_number || customerDetail?.contact_details?.work_phone_number)">
            <div test_id="preferredContactNumberLabel">
              <strong>{{complaintWidgetItem.complaintContactDetails.preferredContactNumberLabel}}</strong>
            </div>
            <div class="radio mt-10px" *ngIf="customerDetail?.contact_details?.mobile_phone_number" id="mobileLabel">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" formControlName="complaintPreferredContactNumber"
                  [ngClass]="{'has-Error-Radio': submitted && complaintYourContactDetailsForm.get('complaintPreferredContactNumber')?.errors && complaintYourContactDetailsForm.get('complaintPreferredContactNumber')?.invalid && complaintYourContactDetailsForm.get('complaintPreferredContactNumber')?.errors?.required}"
                  value="{{complaintWidgetItem.complaintContactDetails.mobileLabel}}"
                  id="complaintPreferredContactNumberMobile">
                <span class="label"
                  test_id="mobileLabel">{{complaintWidgetItem.complaintContactDetails.mobileLabel}}</span>
              </label>
            </div>
            <div class="radio mt-10px" *ngIf="customerDetail?.contact_details?.home_phone_number" id="homePhoneLabel">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" formControlName="complaintPreferredContactNumber"
                  [ngClass]="{'has-Error-Radio': submitted && complaintYourContactDetailsForm.get('complaintPreferredContactNumber')?.errors && complaintYourContactDetailsForm.get('complaintPreferredContactNumber')?.invalid && complaintYourContactDetailsForm.get('complaintPreferredContactNumber')?.errors?.required}"
                  value="{{complaintWidgetItem.complaintContactDetails.homePhoneLabel}}"
                  id="complaintPreferredContactNumberHomePhone">
                <span class="label"
                  test_id="telephoneLabel">{{complaintWidgetItem.complaintContactDetails.telephoneLabel}}</span>
              </label>
            </div>
            <div class="radio mt-10px" *ngIf="customerDetail?.contact_details?.work_phone_number" id="workPhoneLabel">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" formControlName="complaintPreferredContactNumber"
                  [ngClass]="{'has-Error-Radio': submitted && complaintYourContactDetailsForm.get('complaintPreferredContactNumber')?.errors && complaintYourContactDetailsForm.get('complaintPreferredContactNumber')?.invalid && complaintYourContactDetailsForm.get('complaintPreferredContactNumber')?.errors?.required}"
                  value="{{complaintWidgetItem.complaintContactDetails.workPhoneLabel}}"
                  id="complaintPreferredContactNumberWorkPhone">
                <span class="label"
                  test_id="telephoneWorkLabel">{{complaintWidgetItem.complaintContactDetails.telephoneWorkLabel}}</span>
              </label>
            </div>

            <div class="form-group form-group-textarea mt-10px ps-30" id="preferredContactTime"
              *ngIf="complaintPreferredContactNumberFormControl?.value != ''">
              <div test_id="preferredContactTimeLabel">
                <strong>{{complaintWidgetItem.complaintContactDetails.preferredContactTimeLabel}}</strong>
              </div>
              <div class="mt-10px">
                <bb-content-html
                  [html]="complaintWidgetItem.complaintContactDetails.bestTimeSelectionText"></bb-content-html>
              </div>
              <div class="radio mt-10px">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" formControlName="complaintPreferredContactTime"
                    [ngClass]="{'has-Error-Radio': submitted && complaintYourContactDetailsForm.get('complaintPreferredContactTime')?.errors && complaintYourContactDetailsForm.get('complaintPreferredContactTime')?.invalid && complaintYourContactDetailsForm.get('complaintPreferredContactTime')?.errors?.required}"
                    value="{{complaintPreferredContactNumberFormControl.value}}>Morning"
                    id="complaintPreferredContactTimeMorning">
                  <span class="label"
                    test_id="morningTimeLabel">{{complaintWidgetItem.complaintContactDetails.morningTimeLabel}}</span>
                </label>
              </div>
              <div class="radio mt-10px">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" formControlName="complaintPreferredContactTime"
                    [ngClass]="{'has-Error-Radio': submitted && complaintYourContactDetailsForm.get('complaintPreferredContactTime')?.errors && complaintYourContactDetailsForm.get('complaintPreferredContactTime')?.invalid && complaintYourContactDetailsForm.get('complaintPreferredContactTime')?.errors?.required}"
                    value="{{complaintPreferredContactNumberFormControl.value}}>Afternoon"
                    id="complaintPreferredContactTimeAfternoon">
                  <span class="label"
                    test_id="afternoonTimeLabel">{{complaintWidgetItem.complaintContactDetails.afternoonTimeLabel}}</span>
                </label>
              </div>
              <div class="radio mt-10px">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" formControlName="complaintPreferredContactTime"
                    [ngClass]="{'has-Error-Radio': submitted && complaintYourContactDetailsForm.get('complaintPreferredContactTime')?.errors && complaintYourContactDetailsForm.get('complaintPreferredContactTime')?.invalid && complaintYourContactDetailsForm.get('complaintPreferredContactTime')?.errors?.required}"
                    value="{{complaintPreferredContactNumberFormControl.value}}>Evening"
                    id="complaintPreferredContactTimeEvening">
                  <span class="label"
                    test_id="eveningTimeLabel">{{complaintWidgetItem.complaintContactDetails.eveningTimeLabel}}</span>
                </label>
              </div>
              <div
                *ngIf="submitted && complaintYourContactDetailsFormControl.complaintPreferredContactTime.errors?.required">
                <small class="text-danger" test_id="preferredContactNumberLabelErrorMessage">
                  {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
                </small>
              </div>
            </div>
            <div
              *ngIf="submitted && complaintYourContactDetailsFormControl.complaintPreferredContactNumber.errors?.required">
              <small class="text-danger" test_id="preferredContactNumberLabelErrorMessage">
                {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
              </small>
            </div>
          </div>
        </div>
        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="complaintPreferredContactMethod"
              [ngClass]="{'has-Error-Radio': submitted && complaintYourContactDetailsForm.get('complaintPreferredContactMethod')?.errors && complaintYourContactDetailsForm.get('complaintPreferredContactMethod')?.invalid && complaintYourContactDetailsForm.get('complaintPreferredContactMethod')?.errors?.required}"
              value="Mail" id="complaintPreferredContactMethodLetter">
            <span class="label"
              test_id="concernsRaiseWithDealerYesSelection">{{complaintWidgetItem.complaintContactDetails.letterLabel}}</span>
          </label>
        </div>
        <div
          *ngIf="submitted && complaintYourContactDetailsForm.get('complaintPreferredContactMethod')?.errors?.required">
          <small class="text-danger" test_id="complaintPreferredContactMethodError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
          </small>
        </div>
      </div>

      <div class="form-group mt-20px">
        <div test_id="preferredContactMethod">
          <strong>{{complaintWidgetItem.complaintContactDetails.additionalSupportNeedsLabel}}</strong>
        </div>
        <div class="mt-10px">
          <bb-content-html
            [html]="complaintWidgetItem.complaintContactDetails.additionalSupportNeedsText"></bb-content-html>
        </div>
        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="needsAdditionalSupport"
              [ngClass]="{'has-Error-Radio': submitted && complaintYourContactDetailsForm.get('needsAdditionalSupport')?.errors && complaintYourContactDetailsForm.get('needsAdditionalSupport')?.invalid && complaintYourContactDetailsForm.get('needsAdditionalSupport')?.errors?.required}"
              value="Yes" id="needsAdditionalSupportYesOption">
            <span class="label"
              test_id="concernsRaiseWithDealerYesSelection">{{complaintWidgetItem.complaintContactDetails.yesText}}</span>
          </label>
        </div>
        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="needsAdditionalSupport"
              [ngClass]="{'has-Error-Radio': submitted && complaintYourContactDetailsForm.get('needsAdditionalSupport')?.errors && complaintYourContactDetailsForm.get('needsAdditionalSupport')?.invalid && complaintYourContactDetailsForm.get('needsAdditionalSupport')?.errors?.required}"
              value="No" id="needsAdditionalSupportNoOption">
            <span class="label"
              test_id="concernsRaiseWithDealerNoSelection">{{complaintWidgetItem.complaintContactDetails.noText}}</span>
          </label>
        </div>
        <div *ngIf="submitted && complaintYourContactDetailsForm.get('needsAdditionalSupport')?.errors?.required">
          <small class="text-danger" test_id="complaintTellUsOptionError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
          </small>
        </div>
      </div>

      <div class="form-group mt-20px">
        <div test_id="confirmEmailEvidenceCheckboxText" *ngIf="complaintConcernType == 'Vehicle' || ''">
          <strong>{{complaintWidgetItem.complaintContactDetails.confirmEmailEvidenceText}}</strong>
        </div>
        <div test_id="confirmEmailEvidenceCheckboxText" *ngIf="complaintConcernType == 'FinanceAgreement'">
          <strong>{{complaintWidgetItem.complaintContactDetails.confirmEmailEvidenceTextForServicing}}</strong>
        </div>
        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="evidenceDocumentsShared"
              [ngClass]="{'has-Error-Radio': submitted && complaintYourContactDetailsForm.get('evidenceDocumentsShared')?.errors && complaintYourContactDetailsForm.get('evidenceDocumentsShared')?.invalid && complaintYourContactDetailsForm.get('evidenceDocumentsShared')?.errors?.required}"
              value="Yes" id="evidenceDocumentsSharedYesOption">
            <span class="label"
              test_id="evidenceDocumentsSharedYesSelection">{{complaintWidgetItem.complaintContactDetails.yesText}}</span>
          </label>
        </div>
        <div class="form-group form-group-textarea mt-10px ps-30" id="evidenceDocumentsSharedFormValue"
          *ngIf="evidenceDocumentsSharedFormValue?.value == 'Yes'">
          <div id="complaintsEmailText" class="emailAddressText para-inline">
            <small *ngIf="complaintConcernType == 'Vehicle' || ''"><bb-content-html
                [html]="complaintsTellUsEmailAddressText"></bb-content-html></small>
            <small *ngIf="complaintConcernType == 'FinanceAgreement'"><bb-content-html
                [html]="complaintsTellUsEmailAddressTextForFinanceAgreement"></bb-content-html></small>
          </div>
        </div>
        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="evidenceDocumentsShared"
              [ngClass]="{'has-Error-Radio': submitted && complaintYourContactDetailsForm.get('evidenceDocumentsShared')?.errors && complaintYourContactDetailsForm.get('evidenceDocumentsShared')?.invalid && complaintYourContactDetailsForm.get('evidenceDocumentsShared')?.errors?.required}"
              value="No" id="evidenceDocumentsSharedNoOption">
            <span class="label"
              test_id="evidenceDocumentsSharedNoSelection">{{complaintWidgetItem.complaintContactDetails.noText}}</span>
          </label>
        </div>
        <div class="form-group form-group-textarea mt-10px ps-30" id="evidenceDocumentsSharedFormValue"
          *ngIf="evidenceDocumentsSharedFormValue?.value == 'No'">
          <bb-warning-alert
            [warningContent]="complaintWidgetItem.complaintContactDetails.warningTextInfo"></bb-warning-alert>
        </div>
        <div *ngIf="submitted && complaintYourContactDetailsForm.get('evidenceDocumentsShared')?.errors?.required">
          <small class="text-danger" test_id="complaintTellUsOptionError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
          </small>
        </div>
      </div>

      <div class="complaints-btn-group margin-top-20">
        <button class="wss-button-secondary" test_id="previousButton" id="contactDetailsPreviousButton"
          (click)="handlePreviousBtn()">
          {{complaintWidgetItem.complaintContactDetails.secondaryButtonText}}
        </button>
        <button class="wss-button" test_id="handleNextBtn" id="contactDetailsSubmitButton"
          (click)="handleNextBtn()">{{complaintWidgetItem.complaintContactDetails.primaryButtonText}}</button>
      </div>
    </div>
  </form>
</div>