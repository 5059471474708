import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "@backbase/foundation-ang/core";
import { FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { WSSAppConstant, ContentData, apiURI } from '@wss/config/wss-app-constants';
import { PaymentService } from '../../services/payment.service';
import { DatastoreService } from 'libs/service/datastore.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { isErrServer, isLPAgreement } from '@wss/common/util/util';
import { MessageService } from 'libs/service/message.service';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { PaymentsWidgetItem } from '@wss/model/payment';
import { DatePipe } from '@angular/common'
import { getHeaders } from '@wss/common/util/header-util';
import { RequestService } from '@wss/service/request.service';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
    selector: 'over-payment-options',
    templateUrl: './over-payment-options.component.html',
    styles: [
    ],
    providers: [ContentService]
})

export class OverPaymentOptionsComponent implements OnInit {

    settlementData: any;
    amountExceedflag: boolean = false;
    settlementQuoteWarningFlag = false;
    quoteAmount: any;
    overPaymentWarningFlag: boolean = false;
    accountIneligibleFlag: boolean = false;
    enableRequestSettlement: boolean = false;
    overpaymentException: any;
    overpaymentData: any = [];
    hpCustomerFlag: boolean = true;
    accountDetails: any;
    readonly destroy$ = new Subject();
    serviceError: boolean = false;
    overpaymentNotAllowed: boolean = false;
    dueDayExceptionFlag: boolean = false;
    localTimeHour = new Date().getHours();
    newPaymentValue: any = '';
    currentEndDateValue: any = '';
    newEndDateValue: any = '';
    overpaymentGenericError: boolean = false;
    pesQuoteAmendedAlreadyFlag: boolean = false;
    isLPAgreementFlag: any;
    pesQuoteSameAmountFlag: boolean = false;

    paymentsWidgetItem$: Observable<PaymentsWidgetItem | undefined> = this.bbContentService.getContent<PaymentsWidgetItem>('paymentsWidgetItem');
    
    constructor(private router: RouterService, private formBuilder: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef, private datepipe: DatePipe,
        private paymentService: PaymentService, private datastoreService: DatastoreService, private activatedRoute: ActivatedRoute,
        private messageService: MessageService, private readonly bbContentService: ContentService, private requestService: RequestService,
        private tealiumService: TealiumUtagService) {
    }

    ngOnInit() {
        this.tealiumService.view(
            {
                JourneyName: 'Payment',
                JourneyStepName: 'Overpayment Options',
                CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
            }
        );
        this.handleErrors();
        this.disableAllErrors();
        const amountExceeds30000: number = 30000;
        this.quoteAmount = sessionStorage.getItem('settlementAmount');
        this.accountDetails = this.datastoreService.getAccountDetails();
        this.isLPAgreementFlag = isLPAgreement(this.accountDetails);
        let intBalanceOutstanding = this.accountDetails.current_balance ? parseInt(this.accountDetails.current_balance.replace('£', '').replaceAll(',', '')) : 0;
        if (this.quoteAmount >= intBalanceOutstanding && this.quoteAmount <= amountExceeds30000) {
            this.disableAllErrors();
            this.enableRequestSettlement = true;
        } else if (this.quoteAmount > amountExceeds30000) {
            this.disableAllErrors();
            this.overPaymentWarningFlag = true;
        }
        this.overpaymentException = sessionStorage.getItem('overpayment-exception');
        if (this.overpaymentException === 'accountIneligible') {
            this.disableAllErrors();
            this.accountIneligibleFlag = true;
        }
        else if (this.overpaymentException === 'dueDayException') {
            this.disableAllErrors();
            this.dueDayExceptionFlag = true;
        }
        if (this.accountDetails && (this.accountDetails.scheme_code == '01' || this.accountDetails.scheme_code == '1')) {
            this.hpCustomerFlag = true;
        } else {
            this.hpCustomerFlag = false;
        }
        this.checkLocalTime();
        if (!this.overPaymentWarningFlag && !this.enableRequestSettlement && !this.accountIneligibleFlag
            && !this.dueDayExceptionFlag && !this.overpaymentNotAllowed){
            this.getOverpaymentResponse();
        }
        this.messageService.setCurrentStep(0);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    disableAllErrors() {
        this.overpaymentNotAllowed = false;
        this.dueDayExceptionFlag = false;
        this.accountIneligibleFlag = false;
        this.enableRequestSettlement = false;
        this.overPaymentWarningFlag = false;
        this.overpaymentGenericError = false;
        this.pesQuoteAmendedAlreadyFlag = false;
        this.pesQuoteSameAmountFlag = false;
    }

    checkLocalTime() {
        if ((this.localTimeHour < 22) && (this.localTimeHour >= 8)) {
            this.overpaymentNotAllowed = false;
        }
        else {
            this.disableAllErrors();
            this.overpaymentNotAllowed = true;
        }
    }

    changeOptionClick(calculationType?: string) {
        if(calculationType) {
            sessionStorage.setItem('payment-or-term', calculationType);
        }
        localStorage.setItem('settlementAmount', this.quoteAmount);
        this.router.navigate(['payment-debit-card']);
    }

    changeOverpaymentAmount() {
        this.router.navigate(['over-payment']);
    }

    backToMyAccountClick() {
        this.router.navigate(['/../myaccount']);
    }

    requestSettlementClick() {
        this.router.navigate(['payment-settlement']);
    }

    getOverpaymentResponse() {
        var currentDate = new Date();
        var tomorrow = new Date();
        tomorrow.setDate(currentDate.getDate()+1);
        var tomorrowDate = tomorrow.getDate()
        var month = tomorrow.getMonth() + 1;
        var year = tomorrow.getFullYear();
        let overpaymentDate: any = this.dateFormat(tomorrowDate) + '/' + this.dateFormat(month) + '/' + year; //(US)
        sessionStorage.setItem('overpayment-date', overpaymentDate);
        sessionStorage.setItem('settlementAmount', this.quoteAmount);
        sessionStorage.removeItem('payment-or-term');
        this.currentEndDateValue = this.accountDetails ? this.accountDetails.final_payment_date : '';

        this.paymentService.getOverpaymentResponse().pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
            this.datastoreService.setOverpaymentDetails(data);
            this.overpaymentData = data;
            this.newPaymentValue = data[0].new_rental_sets[0].new_rental_value;
            this.newEndDateValue = data[1]?.new_rental_sets[1].new_rental_date;
            this.changeDetectorRef.detectChanges();
        },
        (error: any) => {
        });
    }

    dateFormat(dateData: any) {
        return dateData < 10 ? '0' + dateData : dateData
    }

    postOverpaymentResponse() {
        this.paymentService.fetchOverpaymentResponse();
    }

    showError(errStatus: number): void {
        if (isErrServer(errStatus)) {
            this.datastoreService.setErrorServer();
            this.serviceError = true;
            this.changeDetectorRef.detectChanges();
        }
    }

    handleErrors() {
        this.messageService.getServiceError().subscribe((error: any) => {
            if(error && error.status && isErrServer(error.status)) {
                this.showError(error.status);
            } else {
                this.disableAllErrors();
                if (error?.code === 60020) {
                    this.pesQuoteAmendedAlreadyFlag = true;
                } else if (error?.code === 61299) { 
                    this.pesQuoteSameAmountFlag = true;
                } else {
                    this.overpaymentGenericError = true;
                }
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    getInTouchCall() {
        this.router.navigate(['help-centre/get-in-touch']);
        window.scroll(0, 0);
    }

    backToHomeClick() {
        this.messageService.setActiveNav("My account");
        this.router.navigate(['/../myaccount']);
    }

    backToPaymentsClick() {
        this.messageService.setActiveNav("Payments");
        this.router.navigate(['payment-menu']);
    }

    makePaymentFaqsClick() {
        this.messageService.setActiveNav("Help centre");
        this.router.navigate(['/help-centre/make-payment']);
    }

}

