import { Component, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { appConfig } from '@wss/config/wss-app-constants';
import { BalloonRefinanceItem } from '@wss/model/balloon-refinance/balloon-refinance';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'bb-cta-expired-eoc-period',
  templateUrl: './cta-expired-eoc-period.component.html'
})
export class CtaExpiredEocPeriodComponent implements OnInit {

  brWidgetItem$: Observable<BalloonRefinanceItem | undefined> = this.bbContentService.getContent<BalloonRefinanceItem>('balloonRefinanceWidgetItem');
  accountDetails: any;
  isMotorbike: boolean = false;

  constructor(
    private bbContentService: ContentService,
    private datastore: DatastoreService
  ) { }

  ngOnInit(): void {
    this.accountDetails = this.datastore.getAccountDetails();
    this.checkIfGdsCodeIsMotorbike();
  }

  callButtonHandler(contactNumber: string) {
    window.open('tel:' + contactNumber, '_self');
  }

  checkIfGdsCodeIsMotorbike(){
    let gdsCode = this.accountDetails?.goods_code;
    if(appConfig.motorbikeGdsCodes.includes(gdsCode)){
      this.isMotorbike = true;
    }
  }

}
