import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { RegistrationWidgetItem } from '@wss/model/registrationToken';
import { ContentService } from '@backbase/universal-ang/content';
import { TealiumUtagService } from '@wss/service/utag.service';
import { injectBrandContactContent, injectBrandDetailIntoPage } from '@wss/common/util/util';

@Component({
  selector: 'bb-create-account-faq',
  templateUrl: './create-account-faq.component.html',
  providers: [ContentService]
})

export class CreateAccountFaqComponent implements OnInit, OnDestroy {
  readonly destroy$ = new Subject();
  acordanceContent: any = "";
  pageHeaderText: any = "";

  registrationWidgetItem$: Observable<RegistrationWidgetItem | undefined> = this.bbContentService.getContent<RegistrationWidgetItem>('registrationWidgetItem');

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'Registration',
        JourneyStepName: 'Create Account FAQs',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    }
    );
    this.registrationWidgetItem$.subscribe((pageItem: any) => {
      this.acordanceContent = injectBrandContactContent(pageItem.createAccountFaq.createAccountAccordionContent, pageItem.brandContactDetails);
      this.pageHeaderText = injectBrandDetailIntoPage({pageHeaderText: pageItem.createAccountFaq.pageHeaderText}, pageItem.brandContactDetails);
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  constructor(
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService, private changeDetectorRef: ChangeDetectorRef
  ) { }


}
