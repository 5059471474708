<!-- <ng-template let-modal> -->
<div class="modal-header">
    <span class="modal-title sms-title-content" id="smsModalLabel">
        <!-- {{smsModal?.title}} -->
        <bb-content-html [html]="sendSMS.sendSMSTitleText"></bb-content-html>
    </span>
    <span class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </span>
</div>
<div class="modal-body">
    <div>
        <!-- {{smsModal?.subtitle}} -->
        <bb-content-html [html]="sendSMS.sendSMSTopBodyText"></bb-content-html>
    </div>
    <form [formGroup]="messageForm" class="form" role="form" autocomplete="off" id="formMessage">
        <div class="mobile-number-input">
            <label>
                <!-- {{content[0]?.mobileLable}} -->
                <bb-content-html [html]="sendSMS.mobileNumberLabelText"></bb-content-html>
            </label>
            <!-- <input type="text" tabindex="0" class="form-control form-control-lg rounded-2 amount-input"
                formControlName="mobileNumber" placeholder="{{content[0]?.phmobileno}}" (focusout)="showHideError()"> -->
            <input type="text" tabindex="0" class="form-control form-control-lg rounded-2 amount-input"
                formControlName="mobileNumber" placeholder="{{sendSMS.mobileNumberPlaceholderText}}" (focusout)="showHideError()">
        </div>
        <span class="error-text" *ngIf="hasMobileNumberError">
            <!-- {{content[0]?.mobileErrorMsg}}! -->
            <bb-content-html [html]="sendSMS.mobileNumberErrorMessageText"></bb-content-html>
        </span>
    </form>
</div>
<div class="modal-footer">
    <!-- <button *ngIf="(messageForm.controls.mobileNumber.value === '') || !messageForm.controls.mobileNumber.valid"
        type="button" class="wss-button"><span [ngClass]="{disabled: messageForm.controls.mobileNumber.value === '' || 
                    !messageForm.controls.mobileNumber.valid}">
            {{content[0]?.paymentSendTextBtn}}
        </span>
    </button> -->
    <button role="button" tabindex="0"
        [disabled]="(messageForm.controls.mobileNumber.value === '') || !messageForm.controls.mobileNumber.valid"
        type="button" class="wss-button" (click)="sendSMSClick()"><span>
            <!-- {{content[0]?.paymentSendTextBtn}} -->
            {{sendSMS.sendSMSButtonText}}
        </span>
    </button>
    <!-- <span *ngIf="helpNote !== ''" [innerHTML]="helpNote"></span> -->
    <span *ngIf="sendSMS.sendSMSHelpNoteText !== ''" [innerHTML]="sendSMS.sendSMSHelpNoteText"></span>
</div>
<!-- </ng-template> -->