import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

declare var document: any;

@Injectable()
export class wssInterceptor implements HttpInterceptor {

  requestCounter: number = 0;
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requestCounter++;
    document.getElementById("appLoader").style.display = "block";
    document.querySelector("body").classList.add("block-ui");
    return next.handle(httpRequest).pipe(finalize(() => {
      this.requestCounter--;
      if(this.requestCounter === 0){
        document.getElementById("appLoader").style.display = "none";
        document.querySelector("body").classList.remove("block-ui");
      }
    }));
  }
}